import React from 'react';
import {
    ButtonBase,
    Divider,
    Grid,
    Paper,
    Popover,
    PopoverProps
} from '@material-ui/core';
import Logo from './Logo';
import {WgTypography} from '../../styles/CustomComponents';
import {ActionButtonProps} from '../../styles/CustomComponents/WgSwipeable';

export interface PopoverActionButtonsProps extends PopoverProps {
    actions: ActionButtonProps[];
    setAnchorEl: Function;
}

export default function PopoverActionButtons({
    actions,
    open,
    id,
    anchorEl,
    setAnchorEl
}: PopoverActionButtonsProps) {
    return (
        <Popover
            open={open}
            id={id}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            anchorEl={anchorEl}>
            <Paper
                elevation={2}
                style={{
                    borderRadius: '4px',
                    width: '200px',
                    position: 'relative'
                }}>
                <Grid container style={{paddingLeft: 0, paddingRight: 0}}>
                    {actions.map(renderActionButton)}
                </Grid>
            </Paper>
        </Popover>
    );

    function renderActionButton(action: any, index: number) {
        return (
            <React.Fragment key={index}>
                <ButtonBase
                    onClick={handleClick}
                    style={{
                        padding:
                            index === 0 ? '0.5rem 1rem 4px' : '4px 1rem 0.5rem',
                        width: '100%',
                        borderRadius: '4px'
                    }}>
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        justify="flex-start">
                        <Grid item xs={3}>
                            <Logo defaultImage={action.src} />
                        </Grid>
                        <Grid item xs={9} style={{paddingLeft: '8px'}}>
                            <WgTypography
                                align="left"
                                color="textSecondary"
                                header="uppercase">
                                {action.text}
                            </WgTypography>
                        </Grid>
                    </Grid>
                </ButtonBase>
                {(index + 1) % actions.length !== 0 && (
                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>
                )}
            </React.Fragment>
        );

        async function handleClick() {
            await setAnchorEl(null);
            action.onClick();
        }
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }
}

import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    ButtonGroupProps,
    useMediaQuery
} from '@material-ui/core';
import {Add, Remove} from '@material-ui/icons';

export interface WgToggleButtonProps extends ButtonGroupProps {
    handleChange: Function;
    initial: boolean;
}

export default function WgToggleSignButton({
    handleChange,
    initial,
    ...props
}: WgToggleButtonProps) {
    const [negative, setNegative] = useState(initial);
    const isIphone5 = useMediaQuery('@media (max-width: 320px)');

    useEffect(() => {
        handleChange(negative);
    }, [negative, handleChange]);

    return (
        <ButtonGroup {...props}>
            <Button
                style={{
                    padding: 0,
                    minWidth: isIphone5 ? '20px' : '28px',
                    height: '40px'
                }}
                onClick={() => setNegative(false)}>
                <Add color={!negative ? 'secondary' : 'disabled'} />
            </Button>
            <Button
                style={{
                    padding: 0,
                    minWidth: isIphone5 ? '20px' : '28px',
                    height: '40px'
                }}
                onClick={() => setNegative(true)}>
                <Remove color={negative ? 'error' : 'disabled'} />
            </Button>
        </ButtonGroup>
    );
}

import {Divider, Grid, Paper} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {WgInputField} from '../../../../../styles/CustomComponents';
import React from 'react';
import {IContactData} from '../../../../../models/userInterface';
import {GenderRadioGroup} from './GenderRadioGroup';
import {NameInputs} from './NameInputs';
import {inputNotFilled} from './index';
import {CompanyDataInput} from './CompanyDataInput';

export interface PersonalDataInputsProps extends IContactData {
    handleChange: (event: React.ChangeEvent<any> | Date) => void;
    advanced?: boolean; // if true, phone numbers and birthDate inputs are also displayed
    isCommercial?: boolean;
}

export function PersonalDataInputs(props: PersonalDataInputsProps) {
    const {
        isCommercial,
        advanced,
        handleChange,
        gender,
        telephoneNumber,
        firstName,
        lastName,
        email,
        dateOfBirth,
        companyName
    } = props;

    let inputs: any[];
    if (advanced) {
        inputs = [
            {
                label: 'E-Mail',
                type: 'email',
                defaultValue: email,
                name: 'email'
            },
            {
                label: 'Telefonnummer',
                type: 'tel',
                defaultValue: telephoneNumber,
                name: 'telephoneNumber'
            },
            {
                label: 'Geburtsdatum',
                type: 'date',
                name: 'dateOfBirth',
                defaultValue: dateOfBirth
            }
        ];
    } else {
        inputs = [
            {
                label: 'E-Mail',
                type: 'email',
                defaultValue: email,
                name: 'email'
            }
        ];
    }

    return (
        <Paper elevation={0}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container direction="column">
                    <GenderRadioGroup
                        gender={gender}
                        salutation
                        handleChange={handleChange}
                    />
                    <Divider variant="middle" light />
                    {isCommercial && (
                        <CompanyDataInput
                            companyName={companyName}
                            handleChange={handleChange}
                        />
                    )}
                    <Grid item container>
                        <NameInputs
                            handleChange={handleChange}
                            firstName={firstName}
                            lastName={lastName}
                        />
                    </Grid>
                    <Divider variant="middle" light />
                    {inputs.map(renderInput)}
                </Grid>
            </MuiPickersUtilsProvider>
        </Paper>
    );

    function renderInput(item: any, index: number) {
        return (
            <Grid key={item.name} item xs={12}>
                <WgInputField
                    required
                    error={inputNotFilled(item.defaultValue)}
                    helperText={
                        inputNotFilled(item.defaultValue) &&
                        'Diese Eingabe ist erforderlich.'
                    }
                    handleChange={handleChange}
                    {...item}
                />
                {index !== inputs.length - 1 && (
                    <Divider variant="middle" light />
                )}
            </Grid>
        );
    }
}

export const noClaimDiscountList = [
    {
        shortName: '0',
        name:
            'Fahranfänger unter 3 Jahre Erfahrung, erstmalige Versicherung eines Fahrzeugs'
    },
    {
        shortName: 'M',
        name: 'Fahranfänger und in letztem Halbjahr Unfall verursacht'
    },
    {shortName: 'S', name: 'Nach Rückstufung aus SF1'},
    {
        shortName: 'SF1/2',
        name:
            'Min. 3 Jahre EU-Führerschein, erstmalige Versicherung eines Fahrzeugs'
    },
    {shortName: 'SF1', name: '1 Jahr unfallfrei'},
    {shortName: 'SF2', name: '2 Jahre unfallfrei'},
    {shortName: 'SF3', name: '3 Jahre unfallfrei'},
    {shortName: 'SF4', name: '4 Jahre unfallfrei'},
    {shortName: 'SF5', name: '5 Jahre unfallfrei'},
    {shortName: 'SF6', name: '6 Jahre unfallfrei'},
    {shortName: 'SF7', name: '7 Jahre unfallfrei'},
    {shortName: 'SF8', name: '8 Jahre unfallfrei'},
    {shortName: 'SF9', name: '9 Jahre unfallfrei'},
    {shortName: 'SF10', name: '10 Jahre unfallfrei'},
    {shortName: 'SF11', name: '11 Jahre unfallfrei'},
    {shortName: 'SF12', name: '12 Jahre unfallfrei'},
    {shortName: 'SF13', name: '13 Jahre unfallfrei'},
    {shortName: 'SF14', name: '14 Jahre unfallfrei'},
    {shortName: 'SF15', name: '15 Jahre unfallfrei'},
    {shortName: 'SF16', name: '16 Jahre unfallfrei'},
    {shortName: 'SF17', name: '17 Jahre unfallfrei'},
    {shortName: 'SF18', name: '18 Jahre unfallfrei'},
    {shortName: 'SF19', name: '19 Jahre unfallfrei'},
    {shortName: 'SF20', name: '20 Jahre unfallfrei'},
    {shortName: 'SF21', name: '21 Jahre unfallfrei'},
    {shortName: 'SF22', name: '22 Jahre unfallfrei'},
    {shortName: 'SF23', name: '23 Jahre unfallfrei'},
    {shortName: 'SF24', name: '24 Jahre unfallfrei'},
    {shortName: 'SF25', name: '25 Jahre unfallfrei'},
    {shortName: 'SF26', name: '26 Jahre unfallfrei'},
    {shortName: 'SF27', name: '27 Jahre unfallfrei'},
    {shortName: 'SF28', name: '28 Jahre unfallfrei'},
    {shortName: 'SF29', name: '29 Jahre unfallfrei'},
    {shortName: 'SF30', name: '30 Jahre unfallfrei'},
    {shortName: 'SF31', name: '31 Jahre unfallfrei'},
    {shortName: 'SF32', name: '32 Jahre unfallfrei'},
    {shortName: 'SF33', name: '33 Jahre unfallfrei'},
    {shortName: 'SF34', name: '34 Jahre unfallfrei'},
    {shortName: 'SF35', name: '35 Jahre unfallfrei'},
    {shortName: 'SF36', name: '36 Jahre unfallfrei'},
    {shortName: 'SF37', name: '37 Jahre unfallfrei'},
    {shortName: 'SF38', name: '38 Jahre unfallfrei'},
    {shortName: 'SF39', name: '39 Jahre unfallfrei'},
    {shortName: 'SF40', name: '40 Jahre unfallfrei'},
    {shortName: 'SF41', name: '41 Jahre unfallfrei'},
    {shortName: 'SF42', name: '42 Jahre unfallfrei'},
    {shortName: 'SF43', name: '43 Jahre unfallfrei'},
    {shortName: 'SF44', name: '44 Jahre unfallfrei'},
    {shortName: 'SF45', name: '45 Jahre unfallfrei'},
    {shortName: 'SF46', name: '46 Jahre unfallfrei'},
    {shortName: 'SF47', name: '47 Jahre unfallfrei'},
    {shortName: 'SF48', name: '48 Jahre unfallfrei'},
    {shortName: 'SF49', name: '49 Jahre unfallfrei'},
    {shortName: 'SF50', name: '50 Jahre unfallfrei'}
];

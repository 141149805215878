import {IAccount} from '../../models/accountInterface';

export const transactionArray = [
    {
        id: 16000,
        parentId: 166609226,
        valueDate: '2020-07-20',
        bankBookingDate: new Date(),
        amount: -42.69506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1000
    },
    {
        id: 61234000,
        parentId: 166609226,
        valueDate: '2020-07-20',
        bankBookingDate: new Date(),
        amount: -15.64506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1000
    },
    {
        id: 606123500,
        parentId: 166609226,
        valueDate: '2020-01-29',
        bankBookingDate: new Date(),
        amount: 0.64506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1000
    },
    {
        id: 607345700,
        parentId: 166609226,
        valueDate: '2019-11-29',
        bankBookingDate: new Date(),
        amount: 16.64506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1000
    },
    {
        id: 62346234000,
        parentId: 166609226,
        valueDate: '2019-11-29',
        bankBookingDate: new Date(),
        amount: 6.64506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1000
    },
    {
        id: 6001,
        parentId: 166609226,
        valueDate: '2019-12-29',
        bankBookingDate: new Date(),
        amount: 11.64506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1001
    },
    {
        id: 6099,
        parentId: 166609226,
        valueDate: '2019-10-29',
        bankBookingDate: new Date(),
        amount: 8.64506943479375,
        purpose: ' Lohn',
        categoryId: 4132,
        accountId: 1002
    },
    {
        id: 6847,
        parentId: 166609226,
        valueDate: '2019-10-31T19:51:18.863Z',
        bankBookingDate: '2020-01-01T19:51:18.863Z',
        amount: 11.00269684233916,
        purpose: '1. Wechselgott Gehalt',
        counterpartName: 'Wechselgott GmbH',
        categoryId: 4131,
        accountId: 1003
    },
    {
        id: 6848,
        parentId: 166609226,
        valueDate: '2019-11-31T19:51:18.863Z',
        bankBookingDate: '2020-01-01T19:51:18.863Z',
        amount: 5.00269684233916,
        purpose: '3. Wechselgott Gehalt',
        counterpartName: 'Wechselgott GmbH',
        categoryId: 4133,
        accountId: 1004
    },
    {
        id: 6817,
        parentId: 166609226,
        valueDate: '2020-02-01T19:51:18.863Z',
        bankBookingDate: '2020-01-01T19:51:18.863Z',
        amount: 15.00269684233916,
        purpose: '2. Wechselgott Gehalt',
        counterpartName: 'Wechselgott GmbH',
        categoryId: 4132,
        accountId: 1005
    },
    {
        id: 123303842,
        parentId: 166609226,
        valueDate: '2020-01-01T19:51:18.863Z',
        bankBookingDate: '2020-01-01T19:51:18.863Z',
        amount: -5.00269684233916,
        purpose: '4. Wechselgott Gehalt',
        counterpartName: 'Wechselgott GmbH',
        categoryId: 4133,
        accountId: 1006
    },
    {
        id: 160581972,
        parentId: 166609226,
        valueDate: '2020-02-01T19:51:18.863Z',
        bankBookingDate: '2020-01-04T19:51:18.863Z',
        amount: -10.80169203072724,
        purpose: ' EDEKA LEBENSMITTEL 00',
        categoryId: 4421,
        accountId: 1007
    },
    {
        id: 74741297,
        parentId: 166609226,
        valueDate: '2020-01-09T19:51:18.863Z',
        bankBookingDate: '2020-01-05T19:51:18.863Z',
        amount: 19.58492090892085,
        purpose: ' EDEKA LEBENSMITTEL 00',
        categoryId: 4421,
        accountId: 1008
    },
    {
        id: 18032784,
        parentId: 166609226,
        valueDate: '2020-01-17T19:51:18.863Z',
        bankBookingDate: '2020-01-06T19:51:18.863Z',
        amount: 9.52824900749115,
        purpose: ' EDEKA LEBENSMITTEL 00',
        categoryId: 4421,
        accountId: 1009
    },
    {
        id: 65541647,
        parentId: 166609226,
        valueDate: '2020-01-07T19:51:18.863Z',
        bankBookingDate: '2020-01-07T19:51:18.863Z',
        amount: 4.032218201830062,
        purpose: ' EDEKA LEBENSMITTEL 00',
        categoryId: 4421,
        accountId: 1010
    }
];

export const someAccounts: IAccount[] = [
    {
        id: 1000,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -31.20956591806383,
        accountNumber: '3703770',
        accountType: 1,
        overdraft: 100,
        overdraftLimit: 1000
    },
    {
        id: 1001,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Mitgliedschaft',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 28.63995452431436,
        accountNumber: '3703770',
        accountType: 1,
        overdraft: 100,
        overdraftLimit: 1000,
        iban: 'DE12312312312327522'
    }
];

export const singleAccount: IAccount = {
    id: 1002,
    logoPath: '1692.svg',
    bankId: 420,
    bankConnectionId: 6969,
    iban: 'DE123123123123275222',
    name: 'Mitgliedschaft2',
    accountHolderName: 'Max Mustermann2',
    accountCurrency: 'EUR',
    balance: 28.63995452431436,
    accountNumber: '3703770',
    accountType: 1,
    overdraft: 1002,
    overdraftLimit: 10002
};

export const accountArray: IAccount[] = [
    {
        id: 1000,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -31.20956591806383,
        accountNumber: '3703770',
        accountType: 1,
        overdraft: 100,
        overdraftLimit: 1000
    },
    {
        id: 1001,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Mitgliedschaft',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 28.63995452431436,
        accountNumber: '3703770',
        accountType: 1,
        overdraft: 100,
        overdraftLimit: 1000,
        iban: 'DE12312312312327522'
    },
    {
        id: 1002,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -69.36880244100878,
        accountNumber: '3703770',
        accountType: 1,
        overdraft: 100,
        overdraftLimit: 1000
    },
    {
        id: 1003,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -60.43690516812068,
        accountNumber: '3703770',
        accountType: 1
    },
    {
        id: 1004,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Mitgliedschaft',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 31.144131817447462,
        accountNumber: '3703770',
        accountType: 1,
        visible: false,
        iban: 'DE123123123123'
    },
    {
        id: 1005,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 7.1575966090754894,
        accountNumber: '3703770',
        accountType: 1,
        visible: true
    },
    {
        id: 1006,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -89.90240986194141,
        accountNumber: '3703770',
        accountType: 1
    },
    {
        id: 1007,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6769420,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -1.7594890297020216,
        accountNumber: '3703770',
        accountType: 1
    },
    {
        id: 1008,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6769420,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 68.75454622678214,
        accountNumber: '3703770',
        accountType: 1,
        visible: true
    },
    {
        id: 1009,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6769420,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 83.60602756822892,
        accountNumber: '3703770',
        accountType: 1
    },
    {
        id: 1010,
        logoPath: '169.svg',
        bankId: 420,
        bankConnectionId: 6769420,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 14.785014816944342,
        accountNumber: '3703770',
        accountType: 1,
        visible: false
    },
    {
        id: 1011,
        logoPath: '142.svg',
        bankId: 420,
        bankConnectionId: 696969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -45.780983504043384,
        accountNumber: '3703770',
        accountType: 4,
        absoluteDifference: 1272.7754164938706,
        percentageDifference: 11.486624408567202,
        visible: true
    },
    {
        id: 1012,
        logoPath: '142.svg',
        bankId: 420,
        bankConnectionId: 696969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: -17.324783207197903,
        accountNumber: '3703770',
        accountType: 4,
        absoluteDifference: -61.485146511769926,
        percentageDifference: -3.9740651421306117,
        visible: false
    },
    {
        id: 1013,
        logoPath: '142.svg',
        bankId: 420,
        bankConnectionId: 696969,
        name: 'Kontokorrent',
        iban: 'DE29172300000003817837',
        accountHolderName: 'Max Mustermann',
        accountCurrency: 'EUR',
        balance: 100.324783207197903,
        accountNumber: '3703770',
        accountType: 4,
        absoluteDifference: 0,
        percentageDifference: 0,
        visible: false
    }
];
export const fetchDepotFromAccountIdData = [
    {
        securityId: 1363346,
        name: 'LYX.WORLD WATER U.ETF D',
        isin: 'FR0010527275',
        buyValuePerUnit: 44.558769,
        totalBuyValue: 1195.17000651177,
        currentValuePerUnit: 42.0,
        totalCurrentValue: 1126.5378600000001,
        currentUnitCount: 26.82233,
        percentDifference: -5.742458908593273,
        absoluteDifference: -2.558768999999998,
        currency: 'EUR'
    },
    {
        securityId: 1363343,
        name: 'PINEBR.GL-INDIA EQUITY A',
        isin: 'IE00B0JY6M65',
        buyValuePerUnit: 48.91,
        totalBuyValue: 48.91,
        currentValuePerUnit: 47.245,
        totalCurrentValue: 47.245,
        currentUnitCount: 1.0,
        percentDifference: -3.404211817624203,
        absoluteDifference: -1.6649999999999991,
        currency: 'EUR'
    },
    {
        securityId: 1363345,
        name: 'MUL-L.HONG KONG EOD',
        isin: 'LU1900067940',
        buyValuePerUnit: 30.02,
        totalBuyValue: 30.02,
        currentValuePerUnit: 29.09,
        totalCurrentValue: 29.09,
        currentUnitCount: 1.0,
        percentDifference: -3.0979347101932064,
        absoluteDifference: -0.9299999999999997,
        currency: 'EUR'
    },
    {
        securityId: 1363344,
        name: 'C.-IB.E.S.I-L.E-I.U.ETF I',
        isin: 'LU0444607187',
        buyValuePerUnit: 136.53,
        totalBuyValue: 273.06,
        currentValuePerUnit: 141.401,
        totalCurrentValue: 282.802,
        currentUnitCount: 2.0,
        percentDifference: 3.5677140555189446,
        absoluteDifference: 4.871000000000009,
        currency: 'EUR'
    },
    {
        securityId: 1363346,
        name: 'ZERO Value Test',
        isin: 'FR0010527275',
        buyValuePerUnit: 44.558769,
        totalBuyValue: 1195.17000651177,
        currentValuePerUnit: 42.0,
        totalCurrentValue: 1126.5378600000001,
        currentUnitCount: 26.82233,
        percentDifference: 0,
        absoluteDifference: 0,
        currency: 'EUR'
    }
];

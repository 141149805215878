import React, {useEffect, useState} from 'react';

export interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    image?: string;
    logoPath?: string;
    defaultImage: string;
    rounded?: string;
    maxHeight?: string;
    maxWidth?: string;
}

const Logo = ({
    image,
    logoPath,
    rounded,
    defaultImage,
    maxHeight,
    maxWidth,
    ...other
}: LogoProps) => {
    const [src, setSrc] = useState(() =>
        setImageSource({defaultImage, logoPath})
    );

    useEffect(() => {
        setSrc(setImageSource({defaultImage, logoPath}));
    }, [defaultImage, logoPath]);

    return (
        <img
            // NOTE: this is hard coded right now.. maybe improve in future?
            style={{
                borderRadius: `${rounded ? rounded : 0}`,
                maxHeight: `${maxHeight ? maxHeight : '42px'}`,
                maxWidth: `${maxWidth ? maxWidth : '42px'}`,
                objectFit: 'contain'
            }}
            src={src}
            onError={() => setSrc(defaultImage)}
            alt="Logo"
            {...other}
        />
    );
};

function setImageSource({defaultImage, logoPath}: LogoProps) {
    return typeof logoPath !== 'undefined'
        ? '/logos/' + logoPath
        : defaultImage;
}

export default Logo;

import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {ProfileListItem} from '../../utils';
import {userActions} from '../../../store/actions';
import {IAccount} from '../../../models/accountInterface';
import {
    allAccountsSelector,
    getUserUserData
} from '../../../store/reducers/selectors';
import {IUserData} from '../../../models/userInterface';
import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../styles/CustomComponents';
import useMediaQuery from '../../../hooks/useMediaQuery';

const {performLogout} = userActions;

interface ProfileProps {
    userData: IUserData;
    accounts: IAccount[];
    performLogout: Function;
}

function Profile(props: ProfileProps) {
    const {isUp} = useMediaQuery('md');
    const profileMapping = [
        {
            text: 'Persönliche Daten',
            link: '/profile/private',
            icon: '/img/profile/icon-user.svg'
        },
        {
            text: 'Dokumente',
            link: '/profile/documents',
            icon: '/img/profile/icon-documents.svg'
        },
        {
            text: 'Passwort',
            link: '/profile/password',
            icon: '/img/profile/icon-lock.svg'
        },
        {
            text: 'Kundensupport',
            link: '/profile/contact',
            icon: '/img/profile/icon-support.svg'
        }
    ];

    const settingsMapping = [
        {
            text: 'Vertragsoptimierung',
            icon: '/img/profile/icon-optimize.svg',
            link: '/profile/energy'
        }
    ];

    return (
        <WgContainer bodyApp>
            <WgHeader headerText="Mein Profil" />

            <WgContainer bodyContent>
                {props.userData && profileInfo(props.userData)}

                <WgContainer disableGutters={false}>
                    <WgTypography
                        gutterTop="3rem"
                        gutterBottom
                        header="uppercase"
                        color="textSecondary">
                        Informationen
                    </WgTypography>
                </WgContainer>
                <Grid container spacing={isUp ? 2 : 0}>
                    {profileMapping.map(renderProfileItem)}
                </Grid>

                <WgContainer disableGutters={false}>
                    <WgTypography
                        gutterTop="3rem"
                        gutterBottom
                        header="uppercase"
                        color="textSecondary">
                        Einstellungen
                    </WgTypography>
                </WgContainer>
                <Grid container spacing={isUp ? 2 : 0}>
                    {props.accounts && props.accounts.length > 0
                        ? settingsMapping.map(renderProfileItem)
                        : renderProfileItem(settingsMapping[0], 0)}
                </Grid>
                <Box marginTop={{xs: '1.5rem', md: '3rem'}}>
                    <WgTypography
                        align="center"
                        text="link"
                        color="textSecondary"
                        onClick={() => props.performLogout()}>
                        Ausloggen
                    </WgTypography>
                </Box>
            </WgContainer>
        </WgContainer>
    );

    function renderProfileItem(item: any, index: number) {
        return (
            <Grid item xs={12} md={6} key={item.text + item.icon}>
                <ProfileListItem
                    dividerBefore={index === 0}
                    dividerAfter
                    {...item}
                />
            </Grid>
        );
    }

    function profileInfo(userData: IUserData) {
        return (
            <Box
                display="flex"
                flexDirection={{xs: 'column', md: 'row'}}
                alignItems="center">
                <Box m={{xs: '0 0 1rem 0', md: '0 1.5rem 0 0'}}>
                    <img
                        alt="profile"
                        src="/img/profile/icon-user-circle-big.svg"
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems={{xs: 'center', md: 'flex-start'}}>
                    <WgTypography
                        gutterBottom
                        color="textPrimary"
                        text="content"
                        align="center">
                        {userData?.title ?? ''} {userData.firstName}{' '}
                        {userData.lastName}
                    </WgTypography>
                    <WgTypography
                        color="textSecondary"
                        align="center"
                        text="info">
                        {userData.email}
                    </WgTypography>
                </Box>
            </Box>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        userData: getUserUserData(state),
        accounts: allAccountsSelector(state)
    };
}

export default connect(mapStateToProps, {performLogout})(Profile);

import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {
    WgCheckbox,
    WgInputField,
    WgPaperArea
} from '../../../../../../styles/CustomComponents';
import DateFnsUtils from '@date-io/date-fns';

import {IDateFieldExtended} from '../../../../../../models/sharedInterface';
import {IPolicyData} from '../../../../../../models/productInterface';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {isInputNotFilled} from '../../../../../utils/Validate';

interface CarInsuranceSwitchDateProps {
    policyData: IPolicyData;
    updatePolicyData: Function;
    validationCallback: (isValid: boolean) => void;
    validationEnabled: boolean;
}

export default function CarInsuranceSwitchDate({
    policyData,
    updatePolicyData,
    validationCallback,
    validationEnabled
}: CarInsuranceSwitchDateProps) {
    useEffect(() => {
        validateAll(policyData);
    });

    const [isChecked, setIsChecked] = useState(true);
    const [isInit, setIsInit] = useState(true);

    useEffect(() => {
        if (isInit) {
            updatePolicyData({
                ...policyData
            });
            setIsInit(false);
        }
    }, [updatePolicyData, policyData, isInit, setIsInit]);

    function handleDateChange(date: any | IDateFieldExtended) {
        updatePolicyData({
            ...policyData,
            startDate: date
        });
    }

    function handleCheckboxChange(event: any | IDateFieldExtended) {
        setIsChecked(!isChecked);

        if (isChecked)
            updatePolicyData({
                ...policyData
            });
    }

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgPaperArea
                header="Wann möchtest du wechseln?"
                elevation={1}
                paperStyle={{padding: '1rem 1rem 0.5rem'}}>
                <Grid container direction="column" spacing={1}>
                    <WgCheckbox
                        key="switchDate"
                        label="Ab sofort"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <WgInputField
                        label="Wechseldatum"
                        type="date"
                        name="startDate"
                        disablePast
                        defaultValue={policyData.startDate || null}
                        required={!isChecked}
                        disabled={isChecked}
                        isDateExtendedDataNeeded
                        error={
                            validationEnabled &&
                            !validateDate(policyData.startDate)
                        }
                        helperText={
                            validationEnabled &&
                            !validateDate(policyData.startDate) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        handleChange={handleDateChange}
                    />
                </MuiPickersUtilsProvider>
            </WgPaperArea>
        </Grid>
    );

    function validateAll(data: IPolicyData) {
        const isValid = checkIsValid(data, isChecked);

        validationCallback(isValid);
    }
}

function validateDate(value?: Date) {
    return !isInputNotFilled(value);
}

function checkIsValid(data: IPolicyData, dateIsChecked: boolean) {
    let isValid = true;
    isValid = !isValid
        ? isValid
        : dateIsChecked
        ? isValid
        : validateDate(data.startDate);

    return isValid;
}

import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import createPalette, {Palette} from '@material-ui/core/styles/createPalette';
import {SCSS_VARS} from './scss-variables.generated';
import {Shadows} from '@material-ui/core/styles/shadows';

/**
 * @params: shadow[1] is our default shadow
 */
export const shadows: Shadows = [
    'none',
    '0 0 10px 0 rgba(0, 0, 0, 0.05),0 1px 3px 0 rgba(0, 0, 0, 0.08)', // default WgShadow
    '0px 2px 5px 0px rgba(0, 0, 0, 0.3),0px 2px 2px 0px rgba(0, 0, 0, 0.06),0px 3px 1px -2px rgba(0, 0, 0, 0.04)',
    '0px 2px 9px 0px rgba(0, 0, 0, 0.3),0px 1px 3px 0px rgba(0, 0, 0, 0.06),0px 3px 3px -2px rgba(0, 0, 0, 0.04)',
    '0px 4px 4px -1px rgba(0, 0, 0, 0.3),0px 0px 5px 0px rgba(0, 0, 0, 0.06),0px 1px 10px 0px rgba(0, 0, 0, 0.04)',
    '0px 6px 6px -1px rgba(0, 0, 0, 0.3),0px -1px 10px 0px rgba(0, 0, 0, 0.06),0px 1px 14px 0px rgba(0, 0, 0, 0.04)',
    '0px 6px 6px -1px rgba(0, 0, 0, 0.3),0px -2px 12px 0px rgba(0, 0, 0, 0.06),0px 1px 18px 0px rgba(0, 0, 0, 0.04)',
    '0px 7px 6px -2px rgba(0, 0, 0, 0.3),0px -1px 12px 1px rgba(0, 0, 0, 0.06),0px 2px 16px 1px rgba(0, 0, 0, 0.04)',
    '0px 10px 6px -3px rgba(0, 0, 0, 0.3),0px 0px 12px 1px rgba(0, 0, 0, 0.06),0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
    '0px 10px 7px -3px rgba(0, 0, 0, 0.3),0px 1px 14px 1px rgba(0, 0, 0, 0.06),0px 3px 16px 2px rgba(0, 0, 0, 0.04)',
    '0px 11px 7px -3px rgba(0, 0, 0, 0.3),0px 2px 16px 1px rgba(0, 0, 0, 0.06),0px 4px 18px 3px rgba(0, 0, 0, 0.04)',
    '0px 11px 8px -4px rgba(0, 0, 0, 0.3),0px 3px 17px 1px rgba(0, 0, 0, 0.06),0px 4px 20px 3px rgba(0, 0, 0, 0.04)',
    '0px 13px 9px -4px rgba(0, 0, 0, 0.3),0px 4px 19px 2px rgba(0, 0, 0, 0.06),0px 5px 22px 4px rgba(0, 0, 0, 0.04)',
    '0px 13px 9px -4px rgba(0, 0, 0, 0.3),0px 5px 21px 2px rgba(0, 0, 0, 0.06),0px 5px 24px 4px rgba(0, 0, 0, 0.04)',
    '0px 13px 10px -4px rgba(0, 0, 0, 0.3),0px 6px 23px 2px rgba(0, 0, 0, 0.06),0px 5px 26px 4px rgba(0, 0, 0, 0.04)',
    '0px 15px 10px -5px rgba(0, 0, 0, 0.3),0px 7px 24px 2px rgba(0, 0, 0, 0.06),0px 6px 28px 5px rgba(0, 0, 0, 0.04)',
    '0px 15px 12px -5px rgba(0, 0, 0, 0.3),0px 8px 26px 2px rgba(0, 0, 0, 0.06),0px 6px 30px 5px rgba(0, 0, 0, 0.04)',
    '0px 15px 13px -5px rgba(0, 0, 0, 0.3),0px 9px 28px 2px rgba(0, 0, 0, 0.06),0px 6px 32px 5px rgba(0, 0, 0, 0.04)',
    '0px 17px 13px -5px rgba(0, 0, 0, 0.3),0px 10px 30px 2px rgba(0, 0, 0, 0.06),0px 7px 34px 6px rgba(0, 0, 0, 0.04)',
    '0px 17px 14px -6px rgba(0, 0, 0, 0.3),0px 11px 31px 2px rgba(0, 0, 0, 0.06),0px 7px 36px 6px rgba(0, 0, 0, 0.04)',
    '0px 19px 15px -6px rgba(0, 0, 0, 0.3),0px 12px 33px 3px rgba(0, 0, 0, 0.06),0px 8px 38px 7px rgba(0, 0, 0, 0.04)',
    '0px 19px 15px -6px rgba(0, 0, 0, 0.3),0px 13px 35px 3px rgba(0, 0, 0, 0.06),0px 8px 40px 7px rgba(0, 0, 0, 0.04)',
    '0px 19px 16px -6px rgba(0, 0, 0, 0.3),0px 14px 37px 3px rgba(0, 0, 0, 0.06),0px 8px 42px 7px rgba(0, 0, 0, 0.04)',
    '0px 20px 16px -7px rgba(0, 0, 0, 0.3),0px 15px 38px 3px rgba(0, 0, 0, 0.06),0px 9px 44px 8px rgba(0, 0, 0, 0.04)',
    '0px 20px 18px -7px rgba(0, 0, 0, 0.3),0px 16px 40px 3px rgba(0, 0, 0, 0.06),0px 9px 46px 8px rgba(0, 0, 0, 0.04)'
];

export const lightThemePalette: Palette = createPalette({
    primary: {main: SCSS_VARS.$tealish},
    secondary: {main: SCSS_VARS['$weird-green']},
    warning: {main: SCSS_VARS['$flamenco'], light: SCSS_VARS['$light-orange']},
    error: {main: SCSS_VARS['$bittersweet'], light: SCSS_VARS['$orange-red']},
    success: {main: SCSS_VARS['$weird-green']},
    highlight: {main: SCSS_VARS['$white']},
    gray: {
        normal: SCSS_VARS['$gray'],
        dark: SCSS_VARS['$dark-gray'],
        middle: SCSS_VARS['$middle-gray'],
        light: SCSS_VARS['$light-gray'],
        bright: SCSS_VARS['$bright-gray'],
        veryLight: SCSS_VARS['$very-light-gray']
    },
    action: {
        active: SCSS_VARS['$weird-green'],
        hover: SCSS_VARS['$tealish'],
        selected: SCSS_VARS['$tealish'],
        disabled: SCSS_VARS['$bright-gray'],
        disabledBackground: SCSS_VARS['$very-light-gray']
    },
    text: {
        primary: SCSS_VARS['$dark-gray'],
        secondary: SCSS_VARS['$gray'],
        hint: SCSS_VARS['$very-light-gray']
    },
    background: {
        default: SCSS_VARS['$app-background'],
        paper: SCSS_VARS['$white']
    }
});

export const darkThemePalette: Palette = createPalette({
    ...lightThemePalette,
    highlight: {main: SCSS_VARS['$light-black']},
    action: {
        active: SCSS_VARS['$weird-green'],
        hover: SCSS_VARS['$tealish'],
        selected: SCSS_VARS['$tealish'],
        disabled: SCSS_VARS['$bright-gray'],
        disabledBackground: SCSS_VARS['$gray']
    },
    text: {
        primary: SCSS_VARS['$white'],
        secondary: SCSS_VARS['$very-light-gray'],
        hint: SCSS_VARS['$light-gray']
    },
    background: {
        default: SCSS_VARS['$almost-black'],
        paper: SCSS_VARS['$light-black']
    }
});

export const createGlobalTheme = (palette: Palette, shadows: Shadows) =>
    responsiveFontSizes(
        createMuiTheme({
            palette: palette,
            shadows: shadows,
            typography: {
                fontFamily: 'Muli'
            },
            overrides: {
                MuiFormControl: {
                    root: {
                        width: '100%',
                        fontFamily: 'Muli'
                    }
                },

                MuiAppBar: {
                    colorDefault: {
                        backgroundColor: palette.highlight.main
                    }
                },
                MuiSelect: {
                    filled: {
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }
                    }
                },
                MuiFilledInput: {
                    root: {
                        fontFamily: 'Muli',
                        borderRadius: 0,
                        backgroundColor: 'transparent',
                        transition: 'none',
                        '&:hover': {
                            backgroundColor: 'transparent !important'
                        },
                        '&$focused': {
                            backgroundColor: 'transparent !important'
                        },
                        '&$disabled': {
                            backgroundColor: 'transparent !important'
                        }
                    },
                    input: {
                        padding: '27px 0 10px',
                        fontFamily: 'Muli',
                        '&$disabled': {
                            cursor: 'default'
                        },
                        '&:-webkit-autofill': {
                            '-webkit-box-shadow':
                                '0 0 0 30px white inset !important',
                            boxShadow: '0 0 0 30px white inset !important'
                        }
                    },
                    multiline: {
                        padding: '27px 0 10px'
                    },
                    underline: {
                        '&:before': {
                            borderBottom: 'none',
                            borderBottomColor: palette.primary.main,
                            transition: 'none'
                        },
                        '&:after': {
                            borderBottom: 'none'
                        },
                        '&:hover:not($disabled):not($focused):not($error):before': {
                            borderBottom: 'none !important'
                        },
                        '&:hover:not($disabled):before': {
                            // borderBottom: `2px solid ${palette.primary.main} !important`
                            borderBottom: 'none !important'
                        },
                        '&$disabled:before': {
                            // borderBottom: `2px solid ${palette.primary.main} !important`
                            borderBottom: 'none !important'
                        }
                    },
                    adornedEnd: {
                        paddingRight: 0
                    }
                },
                MuiPaper: {
                    elevation2: {
                        boxShadow:
                            '0 0 10px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.08)'
                    },
                    rounded: {
                        borderRadius: '5px'
                    }
                },
                MuiInput: {
                    root: {
                        fontFamily: 'Muli',
                        borderRadius: 0,
                        margin: '0 !important'
                    },
                    input: {
                        padding: '27px 0 10px',
                        fontFamily: 'Muli'
                    },
                    multiline: {
                        padding: '27px 0 10px'
                    },
                    underline: {
                        '&:before': {
                            borderBottom: 'none',
                            borderBottomColor: palette.primary.main,
                            transition:
                                'border-bottom-color 2000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                        },
                        '&:after': {
                            borderBottom: 'none'
                        },
                        '&:hover:not($disabled):not($focused):not($error):before': {
                            borderBottom: 'none !important'
                        },
                        '&:hover:not($disabled):before': {
                            borderBottom: 'none !important'
                        },
                        '&$disabled:before': {
                            borderBottomStyle: 'none'
                        }
                    }
                },
                MuiInputLabel: {
                    root: {
                        fontFamily: 'Muli'
                    },
                    shrink: {
                        transform:
                            'translate(0px, 10px) scale(0.75) !important',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    },
                    filled: {
                        transform: 'translate(0px, 20px) scale(1)'
                    }
                },
                MuiFormHelperText: {
                    contained: {
                        marginLeft: 0,
                        marginRight: 0
                    },
                    root: {
                        color: palette.error.main,
                        marginTop: '-12px'
                    }
                },
                MuiList: {
                    padding: {
                        paddingTop: '0',
                        paddingBottom: '0'
                    }
                },

                MuiListItem: {
                    root: {
                        '&$disabled': {
                            pointerEvents: 'all'
                        }
                    }
                },
                MuiFormControlLabel: {
                    root: {
                        marginLeft: '0px',
                        marginRight: '0px'
                    },
                    label: {
                        fontFamily: 'Muli !important',
                        fontSize: '16px !important',
                        lineHeight: 'normal !important',
                        color: palette.text.primary
                    }
                },

                MuiButton: {
                    root: {
                        borderRadius: '25px',
                        textTransform: 'none',
                        height: '50px',
                        fontFamily: 'Muli',
                        fontWeight: 600,
                        fontSize: '16px',
                        maxWidth: '420px',
                        boxShadow: 'none !important'
                    },
                    containedPrimary: {
                        color: palette.common.white,
                        backgroundColor: palette.secondary.main,
                        '&:hover, &:focus': {
                            backgroundColor: palette.action.hover
                        },
                        '&$disabled': {
                            color: palette.action.disabled,
                            backgroundColor: palette.action.disabledBackground
                        }
                    },
                    containedSecondary: {
                        color: palette.secondary.main,
                        backgroundColor: palette.common.white,
                        '&:hover, &:focus': {
                            backgroundColor: palette.action.hover
                        },
                        '&$disabled': {
                            color: palette.action.disabledBackground
                        }
                    },
                    contained: {
                        backgroundColor: palette.text.hint,
                        color: palette.text.secondary,
                        '&:hover, &:focus': {
                            backgroundColor: palette.primary.main,
                            color: palette.common.white
                        },
                        '&$disabled': {
                            color: palette.action.disabled,
                            backgroundColor: palette.action.disabledBackground
                        }
                    },
                    outlinedPrimary: {
                        color: palette.common.white,
                        borderColor: palette.common.white,
                        borderWidth: '2px',
                        '&:hover, &:focus': {
                            backgroundColor: palette.primary.main
                        },
                        '&$disabled': {
                            color: palette.action.disabled,
                            borderColor: palette.action.disabledBackground
                        }
                    },
                    outlinedSecondary: {
                        color: palette.secondary.main,
                        borderColor: palette.secondary.main,
                        borderWidth: '2px',
                        '&:hover, &:focus': {
                            backgroundColor: palette.primary.main,
                            color: palette.common.white
                        },
                        '&$disabled': {
                            color: palette.action.disabledBackground,
                            borderColor: palette.action.disabledBackground
                        }
                    }
                },

                MuiMobileStepper: {
                    root: {
                        background: palette.background.default
                    },
                    dot: {
                        border: 'solid 2px',
                        borderColor: palette.gray.light,
                        backgroundColor: palette.common.white,
                        width: '9px',
                        height: '9px'
                    },
                    dotActive: {
                        borderColor: SCSS_VARS['$aqua-marine'],
                        backgroundColor: palette.common.white
                    }
                },

                /*Button or Listitem, etc: Click animation color*/
                MuiTouchRipple: {
                    ripple: {
                        color: palette.gray.normal
                    }
                },

                MuiExpansionPanel: {
                    root: {
                        boxShadow: 'none',

                        '&$expanded': {
                            margin: 'auto'
                        },
                        '&:before': {
                            backgroundColor: 'transparent'
                        }
                    }
                },

                MuiExpansionPanelSummary: {
                    root: {
                        padding: '0'
                    },
                    content: {
                        '&$expanded': {
                            margin: '12px 0 !important'
                        },
                        width: 'calc(100% - 16px)'
                    },
                    expandIcon: {
                        marginRight: 0
                    }
                },

                MuiExpansionPanelDetails: {
                    root: {
                        padding: '8px 18px 0 18px',
                        width: '100%',
                        flexDirection: 'column'
                    }
                },

                MuiContainer: {
                    root: {
                        '@media (max-width: 959px)': {
                            padding: '0 16px'
                        },
                        '@media (min-width: 960px)': {
                            padding: 0
                        }
                    }
                },

                MuiIconButton: {
                    root: {
                        color: palette.text.primary
                    }
                },

                MuiChip: {
                    root: {
                        fontFamily: 'Muli' // TODO refactor
                    },
                    icon: {
                        marginTop: '2px'
                    },
                    sizeSmall: {
                        fontSize: '0.75rem',
                        height: '20px'
                    }
                },

                MuiDivider: {
                    root: {
                        backgroundColor: palette.text.secondary
                    },
                    light: {
                        backgroundColor: `${palette.text.secondary}30`
                    },
                    middle: {
                        marginLeft: '1.25rem',
                        marginRight: '1.25rem'
                    }
                },

                MuiSnackbar: {
                    anchorOriginBottomCenter: {
                        bottom: '40px'
                    }
                }
            }
        })
    );

export const lightTheme = createGlobalTheme(lightThemePalette, shadows);
// noinspection JSUnusedGlobalSymbols
export const darkTheme = createGlobalTheme(darkThemePalette, shadows);

import {auth, getAuthConfig, performApiCall} from './api';
import {displayNotification} from './notificationActions';
import {LoadingPriorityCodes} from '../reducers/notification';
import WgCircularProgress from '../../containers/app/loading_components/WgCircularProgress';

export const sendFinancialAnalysis: any = (email: string) =>
    performApiCall(
        performSendFinancialAnalysis(email, displayNotification),
        'Ein Fehler beim Senden der Finanzanalyse ist aufgetreten. Versuche es später noch einmal.',
        undefined,
        true,
        'sendFinancialAnalysisLinear',
        {
            id: 'sendFinancialAnalysis',
            priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
            component: WgCircularProgress
        }
    );

export const performSendFinancialAnalysis: Function = (
    email: string,
    displayNotification: Function
) => async (dispatch: Function, getState: Function) => {
    if (!getState()?.user?.token || !getState()?.user?.id) {
        return;
    }
    const token = getState().user.token;
    const id = getState().user.id;
    await auth.put(
        `/users/${id}/financialanalysismail`,
        '"' + email + '"',
        getAuthConfig(token, {'Content-type': 'application/json'})
    );
    dispatch(
        displayNotification(
            'Erfolgreich angefragt',
            'In den nächsten Minuten wirst du eine E-Mail mit deiner individuellen Finanzanalyse erhalten.',
            true,
            '/img/illustration-financialanalysis.png',
            'dialog-container-withImage'
        )
    );
};

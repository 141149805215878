import React from 'react';
import {Box, useMediaQuery, useTheme} from '@material-ui/core';
import Logo from '../../containers/utils/Logo';
import {WgTypography} from './index';
import {formatMeterType, MeterType} from '../../models/productInterface';

export interface WgIconSelectionButtonProps {
    type?: MeterType;
    description?: number;
    logoType?: 'house' | 'people';
    active?: boolean;
    setActive: Function;
    logo: string;
    width?: string;
    height?: string;
}

export default function WgIconSelectionButton({
    type,
    logoType,
    description,
    active,
    setActive,
    logo,
    width,
    height
}: WgIconSelectionButtonProps) {
    const theme = useTheme();
    const isSmallPhone = useMediaQuery('@media (max-width: 320px)');
    let logoPath;
    if (typeof logoType !== 'undefined') {
        logoPath = `/img/recurringpayment/icon-${description}-${logoType}-${
            active ? 'green' : 'grey'
        }.svg`;
    } else {
        logoPath = `${logo}-${active ? 'green' : 'grey'}.svg`;
    }
    return (
        <Box
            onClick={() =>
                typeof type !== 'undefined'
                    ? setActive(type)
                    : setActive(description)
            }
            style={{cursor: 'pointer'}}
            width={isSmallPhone ? '54px' : width ?? '64px'}
            height={isSmallPhone ? '54px' : height ?? '64px'}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="0.5rem"
            bgcolor="transparent"
            borderRadius="8px"
            border="2px solid"
            borderColor={
                active ? theme.palette.secondary.main : theme.palette.gray.light
            }>
            <Box
                flex="1 1 60%"
                display="flex"
                justifyContent="center"
                alignItems="center">
                <Logo
                    defaultImage={logoPath}
                    maxWidth={isSmallPhone ? '32px' : '42px'}
                    maxHeight={isSmallPhone ? '32px' : '42px'}
                />
            </Box>
            <Box flex="auto">
                <WgTypography
                    align="center"
                    color={active ? 'secondary' : 'textSecondary'}
                    fontSize={isSmallPhone ? '0.325rem' : '0.6125rem'}>
                    {typeof type !== 'undefined'
                        ? formatMeterType(type)
                        : logoType === 'house' &&
                          typeof description !== 'undefined'
                        ? description * 40 + 'm2'
                        : description === 4
                        ? 'mehr'
                        : description ?? ''}
                </WgTypography>
            </Box>
        </Box>
    );
}

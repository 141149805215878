export const loadingIdAllAccounts = 'Accounts';
export const loadingIdAllTransaction = 'Transaction';
export const loadingIdDepotFromAccount = (id: number) => `Depot-${id}`;
export const loadingIdTransactionFromAccount = (id: number) =>
    `Transaction-${id}`;
export const loadingIdAllContracts = 'Contracts';
export const loadingIdAllRecurringPayments = 'RecurringPayment';
export const loadingIdFinApiDialogFromBank = (id: number) => `Bank-${id}`;
export const loadingIdRefreshBankConnection = (id: number) => `Accounts-${id}`;
export const loadingIdFetchOffersFromRecurringPayment = (id: number) =>
    `Offers-${id}`;

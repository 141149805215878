import React from 'react';
import {render} from 'react-dom';
import App from './containers/app/App';
import * as serviceWorker from './serviceWorker';
import Setup from './Setup';

import 'sanitize.css/sanitize.css';
import './styles/index.scss';

const target = document.querySelector('#root');

render(
    <Setup>
        <App />
    </Setup>,
    target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()
serviceWorker.unregister();

// *** ACCOUNT ***
export const FETCH_ALL_ACCOUNTS_SUCCESS = 'FETCH_ALL_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_BY_ID_SUCCESS = 'FETCH_ACCOUNTS_BY_ID_SUCCESS';
export const FETCH_TRANSACTIONS_FROM_ACCOUNT_ID_SUCCESS =
    'FETCH_TRANSACTIONS_FROM_ACCOUNT_ID_SUCCESS';
export const SET_ACCOUNT_VISIBILITY_SUCCESS = 'SET_ACCOUNT_VISIBILITY_SUCCESS';

// *** DEPOT ***
export const FETCH_DEPOTS_FROM_ACCOUNT_ID_SUCCESS =
    'FETCH_DEPOTS_FROM_ACCOUNT_ID_SUCCESS';

// *** CONTRACT ***
export const UPDATE_RECURRINGPAYMENT_SUCCESS =
    'UPDATE_RECURRINGPAYMENT_SUCCESS';
export const DELETE_RECURRINGPAYMENT = 'DELETE_RECURRINGPAYMENT';
export const FETCH_ALL_CONTRACT_SUCCESS = 'FETCH_ALL_CONTRACT_SUCCESS';
export const SUBMIT_NEW_CONTRACT_SUCCESS = 'SUBMIT__NEW_CONTRACT_SUCCESS';
export const FETCH_CANCELLATION_URL_SUCCESS = 'FETCH_CANCELLATION_URL_SUCCESS';

// *** OFFER ***
export const FETCH_ENERGY_OFFER_SUCCESS = 'FETCH_ENERGY_OFFER_SUCCESS';
export const FETCH_ENERGY_OFFER_CONTRACT_VALIDATION_DATA_SUCCESS =
    'FETCH_ENERGY_OFFER_CONTRACT_VALIDATION_DATA_SUCCESS';

// *** BANK ***
export const FETCH_ALL_BANKS_SUCCESS = 'FETCH_ALL_BANKS_SUCCESS';
export const FETCH_INITIAL_BANKS_SUCCESS = 'FETCH_INITIAL_BANKS_SUCCESS';
export const FETCH_ALL_BANK_CONNECTIONS_SUCCESS =
    'FETCH_ALL_BANK_CONNECTIONS_SUCCESS';
export const UPDATE_BANK_CONNECTION_SUCCESS = 'UPDATE_BANK_CONNECTION_SUCCESS';

// *** AUTH ***
export const SET_TOKEN = 'SET_TOKEN';

// *** USER ***
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_BROKERMANDATE = 'SET_USER_BROKERMANDATE';
export const SET_USER_TMP_BROKERMANDATE = 'SET_USER_TMP_BROKERMANDATE';
export const SET_REQUIRED_INFORMATIONS = 'SET_REQUIRED_INFORMATIONS';

// *** CATEGORY ***
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

// *** COMPANY ***
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';

// *** THEME ***
export const UPDATE_THEME = 'UPDATE_THEME';

// *** NOTIFICATION ***
export const NOTIFICATION_SET_DIALOG = 'NOTIFICATION_SET_DIALOG';
export const NOTIFICATION_SET_SNACKBAR = 'NOTIFICATION_SET_SNACKBAR';
export const NOTIFICATION_UPDATE_DIALOG = 'NOTIFICATION_UPDATE_DIALOG';
export const NOTIFICATION_UPDATE_SNACKBAR = 'NOTIFICATION_UPDATE_SNACKBAR';
export const NOTIFICATION_SET_REGISTER_STATUS =
    'NOTIFICATION_SET_REGISTER_STATUS';
export const NOTIFICATION_ADD_LOADING = 'NOTIFICATION_ADD_LOADING';
export const NOTIFICATION_REMOVE_LOADING = 'NOTIFICATION_REMOVE_LOADING';
export const NOTIFICATION_ADD_MESSAGES = 'NOTIFICATION_ADD_MESSAGES';
export const NOTIFICATION_REMOVE_MESSAGE = 'NOTIFICATION_REMOVE_MESSAGE';

// *** ROOT REDUCER ***
export const USER_LOGOUT = 'USER_LOGOUT';

// *** PRODUCTS/INSURANCE ***
export const FETCH_INSURANCE_PRODUCTS = 'FETCH_INSURANCE_PRODUCTS';
export const FETCH_INSURANCE_PRODUCT_URL = 'FETCH_INSURANCE_PRODUCT_URL';
export const FETCH_INSURANCE_POLICIES = 'FETCH_INSURANCE_POLICIES';
export const FETCH_EXISTING_PRODUCT_FILES = 'FETCH_EXISTING_PRODUCT_FILES';
export const FETCH_EXISTING_PRODUCTS = 'FETCH_EXISTING_PRODUCTS';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';

// *** CAR INSURANCE ***
export const SET_VEHICLE_DATA = 'SET_VEHICLE_DATA';
export const SET_CAR_INSURANCE_SUBMIT_RESPONSE =
    'SET_CAR_INSURANCE_SUBMIT_RESPONSE';
export const SET_CAR_INSURANCE_SPECIFIC_DATA =
    'SET_CAR_INSURANCE_SPECIFIC_DATA';
export const SET_CAR_INSURANCE_NO_CLAIM_DISCOUNT =
    'SET_CAR_INSURANCE_NO_CLAIM_DISCOUNT';
export const SET_CAR_INSURANCE_POLICY_DATA = 'SET_CAR_INSURANCE_POLICY_DATA';
export const SET_CAR_INSURANCE_SUMMARY_DATA = 'SET_CAR_INSURANCE_SUMMARY_DATA';
export const SELECT_CAR_INSURANCE_PAYMENT_ACCOUNT =
    'SELECT_CAR_INSURANCE_PAYMENT_ACCOUNT';
export const SET_CAR_INSURANCE_MANUAL_PAYMENT =
    'SET_CAR_INSURANCE_MANUAL_PAYMENT';
export const SET_CAR_INSURANCE_VEHICLE_HOLDER_DATA =
    'SET_CAR_INSURANCE_VEHICLE_HOLDER_DATA';
export const SET_CAR_INSURANCE_PROPOSAL = 'SET_INSURANCE_PROPOSAL';
export const SET_USER_PROPOSAL_CHOICE = 'SET_USER_PROPOSAL_CHOICE';
export const SET_PREVIOUS_CAR_INSURANCE_POLICY_DATA =
    'SET_PREVIOUS_CAR_INSURANCE_POLICY_DATA';

// *** TUTORIALS ***
export const TUTORIAL_SEEN = 'TUTORIAL_SEEN';

import {
    NOTIFICATION_ADD_LOADING,
    NOTIFICATION_ADD_MESSAGES,
    NOTIFICATION_REMOVE_LOADING,
    NOTIFICATION_REMOVE_MESSAGE,
    NOTIFICATION_SET_DIALOG,
    NOTIFICATION_SET_REGISTER_STATUS,
    NOTIFICATION_SET_SNACKBAR,
    NOTIFICATION_UPDATE_DIALOG,
    NOTIFICATION_UPDATE_SNACKBAR
} from '../actions/actionTypes';
import get from 'lodash.get';
import {
    ILoading,
    IMessage,
    INotification
} from '../../models/notificationInterface';
import isEqual from 'lodash.isequal';
import {console} from '../../console';

const temp = {}; //could be used for notifications form the BackEnd

export const RegisterStatus = {
    NONE: 0,
    SUCCESS: 1,
    FAIL: 2
};

export const LoadingPriorityCodes = {
    NOT_LOADING: 0,
    LINEAR_PROGRESS: 1,
    CIRCULAR_PROGRESS: 1000,
    LOADING_DIALOG: 1005,
    GIFLOADING_PROGRESS: 1010,
    NOTIFICATION_DIALOG: 1020
};

export const DEFAULT_TIMOUT = 30 * 1000;

export const initialState: INotification = get(temp, 'notification', {
    messages: [],
    snackbar: {
        open: false
    },
    dialog: {
        title: undefined,
        text: undefined,
        open: false,
        isAgreeable: false,
        isDisagreeable: false,
        className: undefined
    },
    registerStatus: 0,
    loading: {
        queue: []
    }
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case NOTIFICATION_ADD_MESSAGES:
            if (!Array.isArray(action.payload)) {
                return state;
            }

            const newMessages = action.payload;
            const oldMessages = [...state.messages];

            const filteredMessages = newMessages.filter(
                (x: IMessage) =>
                    !oldMessages.some(
                        y => y.notificationId === x.notificationId
                    )
            );

            return {
                ...state,
                messages: [...state.messages, ...filteredMessages]
            };
        case NOTIFICATION_REMOVE_MESSAGE:
            const messages = [...state.messages];
            messages.splice(0, 1);
            return {
                ...state,
                messages
            };
        case NOTIFICATION_SET_DIALOG:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.open !== 'boolean'
            ) {
                return state;
            }

            return {
                ...state,
                dialog: {...action.payload}
            };
        case NOTIFICATION_SET_SNACKBAR:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.open !== 'boolean'
            ) {
                return state;
            }
            return {
                ...state,
                snackbar: {...action.payload}
            };
        case NOTIFICATION_UPDATE_DIALOG:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.open !== 'boolean'
            ) {
                return state;
            }
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    ...action.payload
                }
            };
        case NOTIFICATION_UPDATE_SNACKBAR:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.open !== 'boolean'
            ) {
                return state;
            }
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    ...action.payload
                }
            };
        case NOTIFICATION_SET_REGISTER_STATUS:
            if (typeof action.payload !== 'number') return state;

            return {
                ...state,
                registerStatus: action.payload
            };

        case NOTIFICATION_ADD_LOADING: {
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.id !== 'string' ||
                (typeof action.payload.component !== 'object' &&
                    typeof action.payload.component !== 'function') ||
                (typeof action.payload.killAt !== 'undefined' &&
                    !(action.payload.killAt instanceof Date)) ||
                (typeof action.payload.priority !== 'undefined' &&
                    typeof action.payload.priority !== 'number') ||
                (typeof action.payload.killInMilliseconds !== 'undefined' &&
                    typeof action.payload.killInMilliseconds !== 'number') ||
                (typeof action.payload.props !== 'undefined' &&
                    action.payload.props?.constructor !== Object)
            ) {
                console.warn(
                    'NOTIFICATION_ADD_LOADING ignoring: ',
                    action.payload
                );
                return state;
            }

            const queue = [...state.loading.queue];
            const payload = action.payload as ILoading;
            const killInMilliseconds =
                action.payload?.killInMilliseconds ?? DEFAULT_TIMOUT;
            const killAt =
                action.payload?.killAt ?? setMilliseconds(killInMilliseconds);

            queue.push({...payload, killAt});
            queue.sort((a: ILoading, b: ILoading) => b.priority - a.priority);
            return {
                ...state,
                loading: {
                    ...state.loading,
                    queue
                }
            };
        }

        case NOTIFICATION_REMOVE_LOADING:
            const queue = [...state.loading.queue];
            const id = action.payload as string;
            const filtersQueue = queue.filter(
                (loading: ILoading) => !isEqual(loading.id, id)
            );
            return {
                ...state,
                loading: {
                    ...state.loading,
                    queue: filtersQueue
                }
            };

        default:
            return state;
    }
};

function setMilliseconds(seconds = 0, date = new Date()): Date {
    const d = new Date(date);
    d.setMilliseconds(d.getMilliseconds() + seconds);
    return d;
}

import {FETCH_CATEGORIES_SUCCESS} from '../actions/actionTypes';

export const initialState = [];

export default (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_CATEGORIES_SUCCESS:
            if (!Array.isArray(action.payload)) {
                return state;
            }
            return [...action.payload];
        default:
            return state;
    }
};

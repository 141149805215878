import React from 'react';
import List, {ListProps} from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import {WgTypography} from '.';
import {Box} from '@material-ui/core';

export interface WgEnumerationListProps extends ListProps {
    background?: 'paper' | 'default' | 'primary' | 'secondary' | 'transparent';
    content?: string[];
    headline?: string;
    enumerationIcon?: React.ReactNode;
    children?: React.ReactNode[] | React.ReactNode;
    hasActionButton?: boolean;
    boldHeadline?: boolean;
    centered?: boolean;
    noListItemPadding?: boolean;
    fullWidth?: boolean;
    textColor?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'initial'
        | 'textPrimary'
        | 'textSecondary'
        | 'error'
        | undefined;
}

export default function WgEnumerationList({
    children,
    dense,
    content,
    enumerationIcon,
    hasActionButton,
    background,
    headline,
    key,
    onClick,
    boldHeadline,
    centered,
    noListItemPadding,
    fullWidth,
    textColor,
    ...other
}: WgEnumerationListProps) {
    const defaultIcon = <CheckIcon />;
    const ListIcon =
        typeof enumerationIcon !== 'undefined' ? enumerationIcon : defaultIcon;
    const bgColor =
        background !== 'default' ? 'background.paper' : 'background.default';
    return (
        <Box>
            <Grid item xs={12} style={{margin: centered ? 'auto' : ''}}>
                {headline && (
                    <WgTypography color="textPrimary" text="content">
                        {boldHeadline ? <strong>{headline}</strong> : headline}
                    </WgTypography>
                )}
                <Box m="4px 0" bgcolor={bgColor}>
                    <List dense={dense} {...other}>
                        {content?.map((value: String, index: Number) => (
                            <ListItem
                                style={noListItemPadding ? {padding: 0} : {}}
                                key={value + String(index)}>
                                <ListItemIcon>{ListIcon}</ListItemIcon>
                                <WgTypography
                                    color={textColor ?? 'textPrimary'}>
                                    <ListItemText primary={value} />
                                </WgTypography>
                            </ListItem>
                        )) ?? <React.Fragment />}
                    </List>
                </Box>
            </Grid>
        </Box>
    );
}

import React from 'react';
import {
    Button,
    ButtonProps,
    createStyles,
    Theme,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import MyLink from '../../containers/utils/MyLink';

const styles = (theme: Theme) =>
    createStyles({
        default: {
            [theme.breakpoints.up('md')]: {
                width: '325px'
            }
        },
        error: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.light
            }
        }
    });

export interface WgButtonProps extends ButtonProps {
    classes: any;
    componentProps?: any;
    defaultButton?: boolean;
    error?: boolean;
    link?: string;
}

function WgButton({
    classes,
    defaultButton,
    componentProps,
    error,
    link,
    children,
    ...other
}: WgButtonProps) {
    const componentLink = link
        ? componentProps
            ? MyLink(link, undefined, componentProps)
            : MyLink(link)
        : 'button';
    return (
        <Button
            component={componentLink}
            className={clsx({
                [classes['default']]: defaultButton,
                [classes['error']]: error
            })}
            {...other}>
            {children}
        </Button>
    );
}

WgButton.defaultProps = {
    fullWidth: true
};

export default withStyles(styles)(WgButton);

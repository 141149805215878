import {
    IContractManualAddition,
    IntervalType,
    IRecurringpayment
} from '../../models/recurringpaymentInterface';
import {IEnergyContractPreferences} from '../../models/offerInterface';
import {MeterType} from '../../models/productInterface';

export const allRecurringpaymentsData = [
    {
        transactionIds: [65541647, 74741297],
        recurringPaymentId: 34324123451234507,
        currency: 'EUR',
        accountId: 1005,
        active: true,
        averageAmount: -19.0,
        estimatedNextAmount: -19.0,
        estimatedNextBookingDate: '2020-01-01T12:00:00',
        firstKnownBookingDate: '2019-08-05T00:00:00',
        lastKnownBookingDate: '2019-09-05T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'VATTENFALL EUROPE SALES',
        receiverIban: 'DE00000RECEIVER000IBAN',
        purpose: 'Strom Abschlag',
        categoryId: 4350,
        categoryName: 'Strom',
        isContract: true,
        isInsurance: false,
        cancellationStatus: 'Cancellable',
        lastAmount: -19.0,
        changeData: {
            changeState: 'available'
        }
    },
    {
        transactionIds: [65541647, 74741297],
        recurringPaymentId: 34324123451232344507,
        currency: 'EUR',
        accountId: 1009,
        active: true,
        averageAmount: -42.0,
        estimatedNextAmount: -42.0,
        estimatedNextBookingDate: '2020-01-01T12:00:00',
        firstKnownBookingDate: '2019-08-05T00:00:00',
        lastKnownBookingDate: '2019-09-05T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'VATTENFALL EUROPE SALES',
        receiverIban: 'DE00000RECEIVER000IBAN',
        purpose: 'Strom Abschlag',
        categoryId: 4350,
        categoryName: 'Strom',
        isContract: true,
        isInsurance: false,
        cancellationStatus: 'Cancellable',
        lastAmount: -19.0,
        changeData: {
            changeState: 'onGoing',
            startDate: '2020-05-25T00:00:00',
            providerName: 'N-Ergie',
            yearlyCosts: 269,
            estimatedYearlySavings: 42
        }
    },
    {
        transactionIds: [65541647, 74741297],
        recurringPaymentId: 32,
        currency: 'EUR',
        accountId: 1005,
        active: true,
        averageAmount: -190.0,
        estimatedNextAmount: -190.0,
        estimatedNextBookingDate: '2019-10-04T00:00:00',
        firstKnownBookingDate: '2019-08-05T00:00:00',
        lastKnownBookingDate: '2019-09-05T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'Alter Strom Anbieter',
        receiverIban: 'DE00000RECEIVER000IBAN',
        purpose: 'Strom Abschlag',
        categoryId: 4350,
        categoryName: 'Strom',
        isContract: true,
        isInsurance: false,
        cancellationStatus: 'Cancellable',
        lastAmount: -190.0,
        changeData: {
            changeState: 'success',
            startDate: '2020-05-29T00:00:00',
            providerName: 'Vattenfall',
            yearlyCosts: 395.39,
            estimatedYearlySavings: 50.98
        }
    },
    {
        transactionIds: [65541647, 74741297],
        recurringPaymentId: 33,
        currency: 'EUR',
        accountId: 1005,
        active: true,
        averageAmount: -190.0,
        estimatedNextAmount: -190.0,
        estimatedNextBookingDate: '2019-10-04T00:00:00',
        firstKnownBookingDate: '2019-08-05T00:00:00',
        lastKnownBookingDate: '2019-09-05T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'Alter Strom Anbieter',
        receiverIban: 'DE00000RECEIVER000IBAN',
        purpose: 'Strom Abschlag',
        categoryId: 4350,
        categoryName: 'Strom',
        isContract: true,
        isInsurance: false,
        lastAmount: -190.0,
        changeData: {
            changeState: 'available',
            startDate: '2020-05-29T00:00:00',
            providerName: 'ONE',
            yearlyCosts: 395.39,
            estimatedYearlySavings: 50.98
        }
    },
    {
        companyId: 8712,
        transactionIds: [6000, 6001, 6099],
        recurringPaymentId: 212349,
        currency: 'EUR',
        accountId: 1009,
        active: true,
        averageAmount: 187.95,
        estimatedNextAmount: 187.95,
        estimatedNextBookingDate: '2019-01-29T00:00:00',
        firstKnownBookingDate: '2019-07-12T00:00:00',
        lastKnownBookingDate: '2019-12-29T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'Wechselgott GmbH',
        purpose: 'Restgehalt 2019',
        categoryId: 4133,
        isInsurance: false,
        categoryName: 'Lohn & Gehalt (Minijob)',
        isContract: false,
        cancellationStatus: 'Permanent',
        lastAmount: 194.6,
        changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
    },
    {
        transactionIds: [6817, 6848, 6847],
        recurringPaymentId: 342713461,
        currency: 'EUR',
        accountId: 1009,
        active: true,
        averageAmount: 187.95,
        estimatedNextAmount: 187.95,
        estimatedNextBookingDate: '2019-01-31T00:00:00',
        firstKnownBookingDate: '2019-07-12T00:00:00',
        lastKnownBookingDate: '2019-12-31T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'Wechselgott GmbH',
        purpose: 'Restgehalt 2019',
        categoryId: 4133,
        isInsurance: false,
        categoryName: 'Lohn & Gehalt (Minijob)',
        isContract: false,
        isCancable: false,
        lastAmount: 150.6,
        changeData: {
            changeState: 'meterNumberMissing',
            startDate: '0001-01-01T00:00:00'
        }
    },
    {
        transactionIds: [65541647, 160581972],
        recurringPaymentId: 3428,
        currency: 'EUR',
        accountId: 1009,
        active: true,
        averageAmount: 204.0,
        estimatedNextAmount: 204.0,
        estimatedNextBookingDate: '2019-12-10T00:00:00',
        firstKnownBookingDate: '2019-07-25T00:00:00',
        lastKnownBookingDate: '2019-09-10T00:00:00',
        paymentInterval: 'QUARTERLY',
        receiverName: 'Eltern',
        purpose: 'KINDERGELD AUGUST 2019 204,00 RESTJULI 2019 10,00',
        categoryId: 4168,
        categoryName: 'Kindergeld',
        isContract: false,
        isInsurance: false,
        cancellationStatus: 'Permanent',
        lastAmount: 214.0,
        changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
    },
    {
        transactionIds: [65541647, 160581972, 18032784, 123303842, 74741297],
        recurringPaymentId: 3423,
        currency: 'EUR',
        accountId: 1009,
        active: false,
        averageAmount: -360.27,
        estimatedNextAmount: -360.27,
        estimatedNextBookingDate: '2019-10-04T00:00:00',
        firstKnownBookingDate: '2019-06-20T00:00:00',
        lastKnownBookingDate: '2019-12-20T00:00:00',
        paymentInterval: 'BIWEEKLY',
        receiverName: 'MD Ausbaugesellschaft, Feucht',
        receiverIban: 'DE15762500000009991258',
        purpose: 'Miete Wohnung',
        categoryId: 4377,
        categoryName: 'Miete & Mietnebenkosten (Ausgaben)',
        isContract: true,
        isInsurance: false,
        cancellationStatus: 'Cancellable',
        lastAmount: -599.0,
        changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
    },
    {
        companyId: 8721,
        transactionIds: [65541647, 160581972, 18032784],
        recurringPaymentId: 3431,
        currency: 'EUR',
        accountId: 1005,
        active: true,
        averageAmount: -57.1,
        estimatedNextAmount: -570.1,
        estimatedNextBookingDate: '2019-12-19T00:00:00',
        firstKnownBookingDate: '2019-07-02T00:00:00',
        lastKnownBookingDate: '2019-09-03T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: '1u1 Telecom GmbH',
        receiverIban: 'DE97500400000589011600',
        purpose: 'KD-Nr. K9999999/ RG-Nr. 99999999',
        categoryId: 4465,
        isInsurance: false,
        categoryName: 'Sonstige Telekommunikationskosten',
        isContract: true,
        cancellationStatus: 'Permanent',
        lastAmount: -48.77,
        changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
    },
    {
        transactionIds: [65541647, 160581972],
        recurringPaymentId: 3420,
        currency: 'EUR',
        cellPhoneNumber: '4915732251404',
        accountId: 1009,
        active: true,
        averageAmount: -10.0,
        estimatedNextAmount: -100.0,
        estimatedNextBookingDate: '2019-12-24T00:00:00',
        firstKnownBookingDate: '2019-06-12T00:00:00',
        lastKnownBookingDate: '2019-07-10T00:00:00',
        paymentInterval: 'MONTHLY',
        isInsurance: false,
        receiverName: 'E-Plus Service GmbH',
        purpose:
            'Einmalige Aufladung fuer Ihr Prepaid.Konto 9999999.9999. Aldi Talk sagt DANKE',
        categoryId: 4468,
        categoryName: 'Mobilfunk (prepaid)',
        isContract: true,
        cancellationStatus: 'Permanent',
        lastAmount: -5.0,
        changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
    }
];

export const singleRecurringPayment: IRecurringpayment = {
    companyId: 8712,
    transactionIds: [6000, 6001, 6099],
    recurringPaymentId: 3427,
    currency: 'EUR',
    accountId: 1009,
    active: true,
    averageAmount: 187.95,
    estimatedNextAmount: 187.95,
    estimatedNextBookingDate: '2019-01-29T00:00:00',
    firstKnownBookingDate: '2019-07-12T00:00:00',
    lastKnownBookingDate: '2019-12-29T00:00:00',
    paymentInterval: 'MONTHLY',
    receiverName: 'Wechselgott GmbH',
    purpose: 'Restgehalt 2019',
    categoryId: 4133,
    isInsurance: false,
    categoryName: 'Lohn & Gehalt (Minijob)',
    isContract: false,
    cancellationStatus: 'Permanent',
    lastAmount: 194.6,
    changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
};

export const singleEnergyRecurringPayment: IRecurringpayment = {
    transactionIds: [65541647, 74741297],
    recurringPaymentId: 34324123451234507,
    currency: 'EUR',
    accountId: 1005,
    active: true,
    averageAmount: -19.0,
    estimatedNextAmount: -19.0,
    estimatedNextBookingDate: '2020-01-01T12:00:00',
    firstKnownBookingDate: '2019-08-05T00:00:00',
    lastKnownBookingDate: '2019-09-05T00:00:00',
    paymentInterval: 'MONTHLY',
    receiverName: 'VATTENFALL EUROPE SALES',
    receiverIBAN: 'DE00000RECEIVER000IBAN',
    purpose: 'DAS IST DAS FALSCHE ELEMENT',
    categoryId: 4350,
    categoryName: 'Strom',
    isContract: true,
    isInsurance: false,
    cancellationStatus: 'Cancellable',
    lastAmount: -19.0,
    changeData: {
        changeState: 'available'
    }
};

export const recurringPaymentPostData: IContractManualAddition = {
    amount: -12.2352,
    categoryId: 69,
    companyId: 420,
    paymentInterval: IntervalType.MONTHLY
};

export const recurringPaymentEnergyPostData: IContractManualAddition = {
    amount: -12.2352,
    categoryId: 4350,
    companyId: 420,
    paymentInterval: IntervalType.MONTHLY
};

export const energyGetOfferData: IEnergyContractPreferences = {
    meterType: MeterType.Electricity,
    isCommercial: true,
    deliveryPostcode: '90489',
    oldProviderName: 'N-Ergie'
};

export const recurringPaymentFailureDialog = {
    dialog: {
        component: undefined
    },
    isAgreeable: true,
    onAgree: () => {},
    open: true,
    text:
        'Beim Speichern des hinzugefügten Vertrages ist ein Fehler aufgetreten.',
    title: 'Hinzufügen des neuen Vertrages nicht erfolgreich.'
};

export const recurringPaymentDialog = {
    dialog: {
        component: undefined
    },
    isAgreeable: true,
    onAgree: () => {},
    open: true,
    text: 'Die neue Buchung wird nun in deiner Vertragswelt gelistet.',
    title: 'Erfolgreich hinzugefügt!'
};

export const recurringPaymentDialogError = {
    ...recurringPaymentDialog,
    text:
        'Beim Speichern des hinzugefügten Vertrages ist ein Fehler aufgetreten.',
    title: 'Hinzufügen des neuen Vertrages nicht erfolgreich.'
};

export const recurringPaymentEnergyDialog = {
    agreeText: 'Jetzt vergleichen',
    dialog: {},
    disagreeText: 'Abbrechen',
    isAgreeable: true,
    isDisagreeable: true,
    onAgree: () => {},
    onDisagree: () => {},
    open: true,
    text:
        'Dein Vertrag befindet sich ab sofort in der Vertragswelt. Möchtest du jetzt günstigere Angebote einholen und vergleichen?',
    title: 'Erfolgreich hinzugefügt!'
};

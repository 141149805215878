import {createDeepEqualSelector} from './utils';
import {IRecurringpayment} from '../../../models/recurringpaymentInterface';
import {
    accountsIdSelector,
    allAccountsSelector,
    singleAccountSelector
} from './accountSelector';
import {policySelector} from './productSelector';
import {IExistingInsuranceProduct} from '../../../models/productInterface';
import {ICategory} from '../../../models/categoryInterface';
import {IAccount} from '../../../models/accountInterface';

export const getAllRecurringpayments = (state: any): IRecurringpayment[] => {
    return state.recurringpayments?.allRecurringpayments?.length
        ? state.recurringpayments.allRecurringpayments
        : [];
};

export const activeRecurringpaymentSelector = createDeepEqualSelector(
    [getAllRecurringpayments, accountsIdSelector],
    (recurringpayments: IRecurringpayment[], ids: number[]) =>
        recurringpayments.filter(
            (recurringpayment: IRecurringpayment) =>
                recurringpayment.active &&
                ids.includes(recurringpayment.accountId) &&
                recurringpayment.estimatedNextBookingDate
        )
);

export const recurringPaymentWithoutAccountSelector = createDeepEqualSelector(
    [getAllRecurringpayments, allAccountsSelector],
    (recurringPayments: IRecurringpayment[], accounts: IAccount[]) => {
        const accountIds = accounts?.map(acc => acc.id) ?? [];
        return recurringPayments.filter(
            rp => !accountIds.includes(rp.accountId) && !rp.isArchived
        );
    }
);

export const visibleRecurringpaymentSelector = createDeepEqualSelector(
    [
        getAllRecurringpayments,
        accountsIdSelector,
        recurringPaymentWithoutAccountSelector
    ],
    (
        recurringpayments: IRecurringpayment[],
        ids: number[],
        recurringPaymentsWithoutAccount: IRecurringpayment[]
    ) =>
        recurringpayments
            .filter(
                (recurringpayment: IRecurringpayment) =>
                    ids.includes(recurringpayment.accountId) &&
                    !recurringpayment.isArchived
            )
            .concat(recurringPaymentsWithoutAccount)
);

export const recurringPaymentCategoryJoinSelector = createDeepEqualSelector(
    [visibleRecurringpaymentSelector, (state: any) => state.categories],
    (
        recurringpayments: IRecurringpayment[],
        categories: ICategory[] | undefined
    ) =>
        recurringpayments.map((recurringpayment: IRecurringpayment) => {
            const category =
                categories &&
                categories.find(
                    (category: ICategory) =>
                        category.id === recurringpayment.categoryId
                );
            recurringpayment.categoryName = category?.name ?? '';
            return recurringpayment;
        })
);

export const recurringPaymentByIdSelector = createDeepEqualSelector(
    [
        recurringPaymentCategoryJoinSelector,
        (_: any, props: any) => props.recurringPaymentId
    ],
    (recurringPayments: IRecurringpayment[], id: number) =>
        recurringPayments.find(
            (rec: IRecurringpayment) =>
                Number(rec.recurringPaymentId) === Number(id)
        )
);

export const recurringPaymentByIdNameSelector = createDeepEqualSelector(
    [recurringPaymentByIdSelector],
    (recurringPayment: IRecurringpayment | undefined) =>
        recurringPayment &&
        (recurringPayment.receiverName || recurringPayment.purpose)
);

export const allRecurringPaymentContractSelector = createDeepEqualSelector(
    [recurringPaymentCategoryJoinSelector],
    (recurringpayments: IRecurringpayment[]) =>
        recurringpayments.filter((c: {isContract: boolean}) => c.isContract)
);

export const allRecurringPaymentMiscSelector = createDeepEqualSelector(
    [recurringPaymentCategoryJoinSelector],
    (recurringpayments: IRecurringpayment[]) =>
        recurringpayments
            .filter((c: {isContract: boolean}) => !c.isContract)
            .sort((current: IRecurringpayment, next: IRecurringpayment) => {
                //Sorting Array with the hightest negative value as the first object
                return (current.lastAmount ?? 0) - (next.lastAmount ?? 0);
            })
);

const filterInsurancesFromRecurringPaymentsAndSort = (
    recurringpayments: IRecurringpayment[],
    policies: IExistingInsuranceProduct[]
) =>
    recurringpayments
        .filter((recurringPayment: IRecurringpayment) => {
            return !policies?.find(
                (product: IExistingInsuranceProduct) =>
                    recurringPayment.recurringPaymentId ===
                    product.recurringPaymentId
            );
        })
        .sort((current: IRecurringpayment, next: IRecurringpayment) => {
            //Sorting Array with the hightest negative value as the first object
            return (current.lastAmount ?? 0) - (next.lastAmount ?? 0);
        });

export const allRecurringPaymentWithoutInsurancesSelector = createDeepEqualSelector(
    [recurringPaymentCategoryJoinSelector, policySelector],
    filterInsurancesFromRecurringPaymentsAndSort
);

export const allContractWithoutInsurancesSelector = createDeepEqualSelector(
    [allRecurringPaymentContractSelector, policySelector],
    filterInsurancesFromRecurringPaymentsAndSort
);

export const accByRecurringPaymentSelector = createDeepEqualSelector(
    [recurringPaymentByIdSelector, state => state],
    (recurringPayment: IRecurringpayment | undefined, state) => {
        let account: IAccount | undefined;
        if (typeof recurringPayment !== 'undefined') {
            account = singleAccountSelector(state, {
                accountId: recurringPayment.accountId
            });
        }
        return {recurringPayment, account};
    }
);

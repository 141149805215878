import React, {Component} from 'react';
import {Area, AreaChart, XAxis, YAxis} from 'recharts';
import {CustomTickSkeleton} from './CustomTickSkeleton';
import {Box} from '@material-ui/core';

interface IAreaChartData {
    name: string;
    balance: number;
}

interface IProps {}

interface IState {
    chartWidth: number;
    chartHeight: number;
    gradientOffset: number;
    dataMax: number;
    dataMin: number;
    chartData: IAreaChartData[];
    animationInterval: number;
}

export default class WgSkeletonChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        //Responsivness
        this.state = this.calculateChartDimensions();
    }

    calculateChartDimensions() {
        //subtract 2vw from viewport because of margin
        const verticalWidth = window.innerWidth / 100;
        //max viewport in windows mode
        const maxWidth = 860 - verticalWidth * 4;

        const width =
            window.innerWidth > maxWidth
                ? maxWidth
                : window.innerWidth - verticalWidth * 4; //max size of the viewport

        const scaleFactor = 0.25;
        const height = window.innerHeight * scaleFactor;

        const chartData = this.calculateChartData();
        const dataMax = Math.max(...chartData.map(i => i.balance));
        const dataMin = Math.min(...chartData.map(i => i.balance));

        return {
            chartHeight: height,
            chartWidth: width,
            dataMax: dataMax,
            dataMin: dataMin,
            gradientOffset: this.gradientOffset(dataMax, dataMin),
            chartData: chartData,
            animationInterval: 2500
        };
    }

    calculateChartData() {
        let chartData: IAreaChartData[] = [];
        for (let i = 0; i < 15; i++) {
            chartData.push({
                name: this.formatNumberToTickString(i + 1),
                balance: this.generateRandomValue(0)
            });
        }
        return chartData;
    }

    componentDidMount() {
        //@ts-ignore
        this.interval = setInterval(
            () => this.setState({chartData: this.calculateChartData()}),
            this.state.animationInterval
        );
    }

    componentWillUnmount() {
        //@ts-ignore
        clearInterval(this.interval);
    }

    formatNumberToTickString(name: number): string {
        return ('0' + name).slice(-2);
    }
    generateRandomValue(value: number): number {
        return Math.floor(Math.random() * 11) + value;
    }

    gradientOffset = (dataMax: number, dataMin: number) => {
        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }

        return dataMax / (dataMax - dataMin);
    };

    render() {
        return (
            <Box display="flex" justifyContent="center">
                <AreaChart
                    height={this.state.chartHeight}
                    width={this.state.chartWidth}
                    data={this.state.chartData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 6,
                        bottom: 10
                    }}>
                    <defs>
                        <linearGradient
                            id="colorArea"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1">
                            <stop
                                offset={0}
                                stopColor="#dadada"
                                stopOpacity={0.9}
                            />
                            <stop
                                offset={this.state.gradientOffset}
                                stopColor="#dadada"
                                stopOpacity={0.0}
                            />
                            <stop
                                offset={this.state.gradientOffset}
                                stopColor="#dadada"
                                stopOpacity={0.0}
                            />
                            <stop
                                offset={1}
                                stopColor="#dadada"
                                stopOpacity={0.9}
                            />
                        </linearGradient>
                    </defs>
                    <YAxis hide />
                    <XAxis
                        interval={0}
                        tick={
                            <CustomTickSkeleton
                                pastData={1}
                                futureTick={false}
                            />
                        }
                        tickMargin={6}
                        textAnchor="end"
                        axisLine={false}
                        tickLine={false}
                        stroke="#BFBFBF"
                        dataKey="name"
                    />
                    <Area
                        type="monotone"
                        dataKey="balance"
                        stroke="#dadada"
                        fill="url(#colorArea)"
                        animationBegin={0}
                        animationDuration={this.state.animationInterval}
                    />
                </AreaChart>
            </Box>
        );
    }
}

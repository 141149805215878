import {createSelectorCreator, defaultMemoize} from 'reselect';
import isEqual from 'lodash.isequal';
import {
    compareDateByDay,
    formatDateWithoutTime,
    getLastDayOfMonthDate,
    getNumberOfWeek
} from './transactionUtils';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

function getBool(val: any) {
    if (typeof val === 'undefined') return false;
    return !!JSON.parse(String(val).toLowerCase());
}

export {
    createDeepEqualSelector,
    getNumberOfWeek,
    formatDateWithoutTime,
    compareDateByDay,
    getLastDayOfMonthDate,
    getBool
};

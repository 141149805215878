import {
    Box,
    BoxProps,
    createStyles,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Theme,
    withStyles,
    Collapse,
    Divider
} from '@material-ui/core';
import React from 'react';
import {WgTypography} from './index';
import clsx from 'clsx';

const styles = (theme: Theme) =>
    createStyles({
        label: {
            width: '-moz-available'
        }
    });

export interface WgRadioGroupProps extends BoxProps {
    classes: any;
    header?: string;
    selected: string | any;
    row?: boolean;
    name: string;
    group: {
        label: string;
        value: string | any;
    }[];
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    isDividerNeeded?: boolean;
    background?: 'paper' | 'default';
    helperText?: React.ReactNode;
    error?: boolean;
}

interface ButtonRef {
    label: string;
    ref: any;
}

function WgRadioGroup({
    classes,
    header,
    name,
    selected,
    group,
    handleChange,
    row,
    disabled,
    isDividerNeeded,
    background,
    helperText,
    error,
    ...other
}: WgRadioGroupProps) {
    // contains references to all buttons in this group in the form of [{label: ..., ref: ...}, ...]
    let buttons: Array<ButtonRef> = [];

    return (
        <Box {...other}>
            <FormControl component="fieldset">
                {header && (
                    <WgTypography color="textSecondary" text="sentenceSmall">
                        {header}
                    </WgTypography>
                )}
                <RadioGroup
                    aria-label={header}
                    name={name}
                    value={selected}
                    onChange={event => {
                        handleChange && handleChange(event);
                    }}
                    row={row}>
                    {group.map((item, index) =>
                        background === 'paper'
                            ? renderRadioButtonOnPaper(
                                  item,
                                  !!isDividerNeeded,
                                  index === group.length - 1
                              )
                            : renderRadioButton(
                                  item,
                                  !!isDividerNeeded,
                                  index === group.length - 1
                              )
                    )}
                </RadioGroup>
            </FormControl>
            {row && group.map(item => renderChild(item))}
            {helperText && (
                <WgTypography
                    color={error ? 'error' : 'textSecondary'}
                    text="info"
                    style={{paddingLeft: '1.25rem', fontSize: '0.75rem'}}>
                    {helperText}
                </WgTypography>
            )}
        </Box>
    );

    function renderRadioButton(
        item: any,
        isDividerNeeded: boolean,
        isLast: boolean
    ) {
        return (
            <Box>
                <FormControlLabel
                    className={clsx({
                        [classes.label]: background === 'paper'
                    })}
                    key={item.label}
                    {...item}
                    ref={input => buttons.push({label: item.label, ref: input})}
                    control={<Radio disabled={disabled} color="primary" />}
                />
                {!row && renderChild(item)}
                {isDividerNeeded && !isLast && <Divider light />}
            </Box>
        );
    }

    function renderChild(item: any) {
        return <Collapse in={selected === item.value}>{item.child}</Collapse>;
    }

    function renderRadioButtonOnPaper(
        item: any,
        isDividerNeeded: boolean,
        isLast: boolean
    ) {
        return (
            <Paper
                key={item.label}
                elevation={1}
                style={{
                    marginTop: '0.75rem',
                    padding: '0.5rem',
                    cursor: 'pointer'
                }}
                onClick={() => triggerRadioButton(item)}>
                {renderRadioButton(item, isDividerNeeded, isLast)}
            </Paper>
        );
    }

    function triggerRadioButton(item: any) {
        // force a click event on the radio button underneath
        const found = buttons.find(button => button.label === item.label);
        if (found) {
            found.ref.click();
        }
    }
}

export default withStyles(styles)(WgRadioGroup);

export function setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
        cname + '=' + cvalue + ';' + expires + ';path=/;SameSite=Lax';
}

export function getCookie(cname: string) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}

export function getConsent() {
    return getCookie('consent') ?? 'essentials';
}

export function deleteCookie(name: string) {
    setCookie(name, '', -1);
}

export function deleteAllCookies() {
    const cookies = document.cookie.split(';');

    function isCookieOnBlackList(name: string) {
        const blackList = ['consent'];
        return blackList.includes(name);
    }

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (!isCookieOnBlackList(name)) {
            deleteCookie(name);
        }
    }
}

export function getSearchObject(href = window.location.search) {
    const vars: any = {};
    href.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        // @ts-ignore
        (_: any, key: string, value: string) => {
            vars[key] = value;
        }
    );
    return vars;
}

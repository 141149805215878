import React from 'react';
import {Box, Grid} from '@material-ui/core';
import InsuranceListItem from '../../insurance/components/InsuranceListItem';
import Logo from '../../../utils/Logo';
import {
    IRecurringpayment,
    isRecurringPayment
} from '../../../../models/recurringpaymentInterface';
import {ICategory} from '../../../../models/categoryInterface';
import {IExistingInsuranceProduct} from '../../../../models/productInterface';
import {useSelector} from 'react-redux';
import {categoryColorByIdSelector} from '../../../../store/reducers/selectors/';

export interface RecurringPaymentHeaderProps {
    object: IRecurringpayment | IExistingInsuranceProduct;
    category?: ICategory;
    setEditCategory?: (isEditMode: boolean) => void;
}

export default function RecurringPaymentHeader({
    object,
    category,
    setEditCategory
}: RecurringPaymentHeaderProps) {
    const categoryColor = useSelector(state =>
        categoryColorByIdSelector(state, {id: category?.id})
    );
    return (
        <Grid
            container
            direction="column"
            style={{position: 'relative', flexWrap: 'unset'}}>
            <Grid
                item
                style={{flex: '1 1 auto'}}
                container
                justify="center"
                alignItems="center">
                <Box p="2rem 3rem 4rem 3rem">
                    <Logo
                        rounded="5px"
                        maxWidth="64px"
                        maxHeight="64px"
                        width="100%"
                        logoPath={
                            isRecurringPayment(object)
                                ? object.logoPath
                                : object.recurringPayment?.logoPath
                        }
                        defaultImage="/img/bank.svg"
                    />
                </Box>
            </Grid>
            <Grid item style={{flex: '0 1 0%'}}>
                <InsuranceListItem
                    shadow
                    placeholder="Kategorie hinzufügen"
                    onClick={() => setEditCategory && setEditCategory(true)}
                    hasIcon={isRecurringPayment(object)}
                    data={category}
                />
            </Grid>
            {renderBackground()}
        </Grid>
    );

    function renderBackground() {
        return (
            <Box
                position="absolute"
                zIndex={-2}
                marginLeft="-16px"
                width="calc(100% + 32px)"
                bottom="-16px"
                height="calc(100% + 32px)"
                style={{
                    background: `linear-gradient(180deg, #fff 10%, ${categoryColor +
                        '42'} 100%)`
                }}>
                <Box
                    position="absolute"
                    zIndex={-1}
                    width="100%"
                    height="50%"
                    bgcolor="background.paper"
                    top={0}
                    style={{clipPath: 'ellipse(65% 50% at 50% 10%)'}}
                />
            </Box>
        );
    }
}

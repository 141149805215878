import {push} from 'connected-react-router';
import {createAction} from 'redux-actions';
import {
    IContractManualAddition,
    IRecurringpayment,
    isRecurringPayment
} from '../../models/recurringpaymentInterface';
import {
    categoryIsEnergySelector,
    policySelector,
    recurringPaymentByIdSelector
} from '../reducers/selectors/';
import {
    FETCH_ALL_CONTRACT_SUCCESS,
    FETCH_INSURANCE_POLICIES,
    SUBMIT_NEW_CONTRACT_SUCCESS,
    UPDATE_RECURRINGPAYMENT_SUCCESS
} from './actionTypes';
import {
    api,
    getAuthConfig,
    isErrorResponseStatusTreatable,
    performApiCall
} from './api';
import {
    loadingIdAllContracts,
    loadingIdAllRecurringPayments
} from './loadingIds';
import {
    addLoading,
    addLoadingDialog,
    setNotification
} from './notificationActions';
import {
    addLoadingGif,
    errorNotification,
    fetchEnergyOffers,
    removeLoading,
    submitEnergyContractPreferences
} from './offerActions';
import {
    EnergyStep,
    IEnergyContractPreferences
} from '../../models/offerInterface';
import {IPolicyManualAddition} from '../../models/productInterface';
import {LoadingPriorityCodes} from '../reducers/notification';
import WgCircularProgress from '../../containers/app/loading_components/WgCircularProgress';

// *** ACTION CREATORS ***
export const updateRecurringpaymentSuccess = createAction(
    UPDATE_RECURRINGPAYMENT_SUCCESS
);
export const fetchAllRecurringpaymentsSuccess = createAction(
    FETCH_ALL_CONTRACT_SUCCESS
);
export const submitRecurringPaymentSuccess = createAction(
    SUBMIT_NEW_CONTRACT_SUCCESS
);
export const fetchPoliciesSuccess = createAction(FETCH_INSURANCE_POLICIES);

const invalidId = -1;

export const fetchAllRecurringpayments: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const response = await api.get(
                '/recurringpayments',
                getAuthConfig(token, {Accept: 'application/json'})
            );
            dispatch(fetchAllRecurringpaymentsSuccess(response.data));
        },
        'Wiederkehrende Buchungen können vorübergehend nicht geladen werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        loadingIdAllRecurringPayments
    );

export const submitRecurringPayment = (
    recurringPaymentData: IContractManualAddition,
    preferences: IEnergyContractPreferences
) =>
    performApiCall(
        performSubmitRecurringPayment(recurringPaymentData, preferences),
        'Beim Speichern des hinzugefügten Vertrages ist ein Fehler aufgetreten.'
    );

export const performSubmitRecurringPayment: any = (
    recurringPaymentData: IContractManualAddition,
    preferences?: IEnergyContractPreferences
) => async (dispatch: Function, getState: Function) => {
    try {
        if (!getState()?.user?.token) {
            return;
        }
        const token = getState().user.token;
        await dispatch(
            addLoadingGif(
                'submitRecurringPayment',
                'Dein Vertrag wird angelegt.'
            )
        );
        let payload = recurringPaymentData;
        if (typeof preferences !== 'undefined') {
            payload = {
                ...payload,
                ...preferences
            };
        }
        const response = await api.post(
            `/recurringpayments`,
            payload,
            getAuthConfig(token, {})
        );
        if (!isRecurringPayment(response.data)) throw new Error('no rp');

        await dispatch(submitRecurringPaymentSuccess(response.data));
        await dispatch(removeLoading('submitRecurringPayment'));

        const isEnergy = categoryIsEnergySelector(getState(), {
            id: recurringPaymentData.categoryId
        });
        if (isEnergy) {
            await dispatch(energyNotification(response));
        } else {
            await dispatch(successNotification(response));
        }
    } catch (error) {
        if (isErrorResponseStatusTreatable(error)) {
            throw error;
        }

        await dispatch(removeLoading('submitRecurringPayment'));
        dispatch(failureNotification());
    }

    function successNotification(response: any) {
        if (typeof response.data?.recurringPaymentId === 'undefined')
            throw new Error('unknown error');
        return setNotification({
            title: 'Erfolgreich hinzugefügt!',
            text: 'Die neue Buchung wird nun in deiner Vertragswelt gelistet.',
            isAgreeable: true,
            onAgree: async () => {
                await dispatch(
                    push(
                        response.data.recurringPaymentId
                            ? `/recurringpayments/details/${response.data.recurringPaymentId}`
                            : `/recurringpayments`
                    )
                );
            },
            open: true
        });
    }

    function failureNotification() {
        return setNotification({
            title: 'Hinzufügen des neuen Vertrages nicht erfolgreich.',
            text:
                'Beim Speichern des hinzugefügten Vertrages ist ein Fehler aufgetreten.',
            isAgreeable: true,
            onAgree: () => {
                dispatch(push('/recurringpayments'));
            },
            open: true
        });
    }

    function energyNotification(response: any) {
        return setNotification({
            title: 'Erfolgreich hinzugefügt!',
            agreeText: 'Jetzt vergleichen',
            disagreeText: 'Abbrechen',
            text:
                'Dein Vertrag befindet sich ab sofort in der Vertragswelt. Möchtest du jetzt günstigere Angebote einholen und vergleichen?',
            isAgreeable: true,
            onAgree: async () => {
                if (typeof response?.data?.recurringPaymentId !== 'undefined') {
                    await dispatch(
                        submitEnergyContractPreferences(
                            response.data.recurringPaymentId,
                            preferences,
                            true,
                            true,
                            `/recurringpayments/${response.data.recurringPaymentId}/add/energy/${EnergyStep.PRODUCT_COMPARE}`
                        )
                    );
                } else {
                    dispatch(push('/recurringpayments'));
                }
            },
            onDisagree: async () => {
                await dispatch(push(`/recurringpayments`));
                await dispatch(
                    push(
                        response.data.recurringPaymentId
                            ? `/recurringpayments/details/${response.data.recurringPaymentId}`
                            : '/recurringpayments'
                    )
                );
            },
            isDisagreeable: true,
            open: true
        });
    }
};

export const updateMeterNumber: any = (
    recurringPaymentId: number,
    meterNumber: string
) =>
    performApiCall(
        performUpdateMeterNumber(recurringPaymentId, meterNumber),
        'Wiederkehrende Buchungen können vorübergehend nicht geändert werden. Bitte versuche es später noch einmal.'
    );

export const performUpdateMeterNumber: any = (
    recurringPaymentId: number,
    meterNumber: string
) => async (dispatch: Function, getState: Function) => {
    if (!getState()?.user?.token) {
        return;
    }
    const token = getState().user.token;
    const id = `performUpdateMeterNumber`;
    await dispatch(addLoadingDialog(id));

    const recurringPayment = recurringPaymentByIdSelector(getState(), {
        recurringPaymentId: Number(recurringPaymentId)
    });

    dispatch(
        updateRecurringpaymentSuccess({
            ...recurringPayment,
            changeData: {changeState: 'none'}
        })
    );

    const response = await api.patch(
        `/recurringpayments/${recurringPaymentId}`,
        {meterNumber: meterNumber},
        getAuthConfig(token, {Accept: 'application/json'})
    );

    if (isRecurringPayment(response.data)) {
        dispatch(updateRecurringpaymentSuccess(response.data));
    } else {
        errorNotification(
            `/recurringpayments/details/${recurringPaymentId}`,
            'Leider ist beim Nachreichen deiner Zählernummer etwas schief gelaufen. Bitte versuche es später noch einmal.',
            dispatch
        );
        return;
    }
    await dispatch(removeLoading(id));

    dispatch(
        setNotification({
            title: 'Erfolgreich abgeschickt',
            text:
                'Die Zählernummer wurde erfolgreich eingereicht! Nun können wir deinen neuen Vertrag für dich abschließen!',
            isAgreeable: true,
            onAgree: async () => {
                await dispatch(
                    push(`/recurringpayments/details/${recurringPaymentId}`)
                );
            },
            open: true
        })
    );
};

export const updateRecurringPayment: any = (
    recurringPayment: IRecurringpayment
) =>
    performApiCall(
        performUpdateRecurringPayment(recurringPayment),
        'Wiederkehrende Buchungen können vorübergehend nicht geändert werden. Bitte versuche es später noch einmal.'
    );

export const performUpdateRecurringPayment: any = (
    recurringPayment: IRecurringpayment
) => async (dispatch: Function, getState: Function) => {
    if (
        typeof recurringPayment === 'undefined' ||
        typeof recurringPayment.paymentInterval === 'undefined' ||
        typeof recurringPayment.categoryId === 'undefined' ||
        recurringPayment.categoryId === invalidId
    )
        return;

    if (!getState()?.user?.token) {
        return;
    }
    const token = getState().user.token;

    dispatch(updateRecurringpaymentSuccess(recurringPayment));

    const edit = {
        isArchived: recurringPayment?.isArchived,
        categoryId: recurringPayment?.categoryId,
        paymentInterval: recurringPayment?.paymentInterval
    };

    const response = await api.patch(
        `/recurringpayments/${recurringPayment.recurringPaymentId}`,
        edit,
        getAuthConfig(token, {Accept: 'application/json'})
    );

    if (isRecurringPayment(response.data)) {
        dispatch(updateRecurringpaymentSuccess(response.data));

        if (!edit.isArchived) {
            const isEnergy = categoryIsEnergySelector(getState(), {
                id: response.data.categoryId
            });
            if (
                isEnergy &&
                response.data?.changeData?.changeState === 'available'
            ) {
                dispatch(
                    fetchEnergyOffers(Number(response.data?.recurringPaymentId))
                );
            }
        }
    }
};

export const deleteRecurringPayment: any = (
    recurringPayment: IRecurringpayment
) =>
    performApiCall(
        performDeleteRecurringPayment(recurringPayment),
        'Wiederkehrende Buchungen können vorübergehend nicht gelöscht werden. Bitte versuche es später noch einmal.'
    );

export const performDeleteRecurringPayment: any = (
    recurringPayment: IRecurringpayment
) => async (dispatch: Function, getState: Function) => {
    const theme = getState().theme;

    dispatch(
        setNotification({
            title: 'Vertrag löschen',
            text:
                'Soll dieser Vertrag wirklich gelöscht werden? ' +
                'Er wird anschließend nicht mehr in der Vertragswelt gelistet oder im Dashboard berücksichtigt.',
            isAgreeable: true,
            isDisagreeable: true,
            agreeText: 'Löschen',
            agreeColor: theme.palette.error.main,
            disagreeText: 'Abbrechen',
            onAgree: async () => {
                dispatch(
                    performUpdateRecurringPayment({
                        ...recurringPayment,
                        isArchived: true
                    })
                );
                await dispatch(push('/recurringpayments'));
            },
            onDisagree: async () => {
                await dispatch(
                    push(
                        `/recurringpayments/details/${recurringPayment.recurringPaymentId}`
                    )
                );
            },
            open: true
        })
    );
};

/* POLICY ACTIONS */
export const fetchPolicies: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const response = await api.get(
                `/contracts/`,
                getAuthConfig(token, {Accept: 'application/json'})
            );
            dispatch(fetchPoliciesSuccess(response.data));
        },
        'Verträge konnten nicht geladen werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        loadingIdAllContracts
    );

export const submitPolicy: any = (contractInfo: IPolicyManualAddition) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const id = `submitPolicy`;

        try {
            await dispatch(
                addLoading({
                    id,
                    priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                    component: WgCircularProgress
                })
            );

            const token = getState().user.token;
            const response = await api.post(
                `/contracts/`,
                contractInfo,
                getAuthConfig(token, {})
            );
            dispatch(
                fetchPoliciesSuccess([
                    ...policySelector(getState(), {}),
                    response.data
                ])
            );

            await dispatch(removeLoading(id));

            await dispatch(
                setNotification({
                    title: 'Erfolgreich hinzugefügt!',
                    text:
                        'Dein Antrag befindet sich ab sofort in Bearbeitung. In Kürze wird deine bestehende Versicherung in der Versicherungswelt gelistet.',
                    isAgreeable: true,
                    onAgree: () => {
                        dispatch(push(`/products/`));
                    },
                    open: true
                })
            );
        } catch (error) {
            await dispatch(removeLoading(id));

            if (isErrorResponseStatusTreatable(error)) {
                throw error;
            }

            dispatch(
                setNotification({
                    title: 'Hinzufügen des neuen Vertrages nicht erfolgreich.',
                    text:
                        'Beim Speichern des hinzugefügten Vertrages ist ein Fehler aufgetreten.',
                    isAgreeable: true,
                    onAgree: () => {
                        dispatch(push('/products/'));
                    },
                    open: true
                })
            );
        }
    }, 'Informationen konnten nicht übertragen werden');

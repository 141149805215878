import React from 'react';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {WgInputField} from './index';
import {WgInputFieldProps} from './WgInputField';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontSize: '2rem',
            color: theme.palette.action.disabled
        },
        inputLabel: {
            fontSize: '2rem',
            color: theme.palette.text.secondary
            // '&:$shrink': {
            //   transform:
            //     'translate(0px, 10px) scale(0.5) !important'
            // }
        }
    });

export interface WgLargeInputProps extends WgInputFieldProps {
    classes: any;
}

function WgLargeInput({
    classes,
    InputProps,
    InputLabelProps,
    ...textFieldProps
}: WgLargeInputProps) {
    return (
        <WgInputField
            fullWidth
            InputLabelProps={{
                className: classes.inputLabel,
                ...InputLabelProps
            }}
            InputProps={{
                className: clsx(classes.root),
                ...InputProps
            }}
            id={`wg-large-input-${textFieldProps.label}-${textFieldProps.placeholder}`}
            {...textFieldProps}
        />
    );
}

export default withStyles(styles)(WgLargeInput);

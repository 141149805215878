import React from 'react';
import {createStyles, Grid, withStyles} from '@material-ui/core';
import {ButtonBaseProps} from '@material-ui/core/ButtonBase';

const styles = () =>
    createStyles({
        image: {
            marginLeft: '1rem',
            maxWidth: '3rem',
            maxHeight: '100%'
        }
    });

export interface WgListItemIconProps extends ButtonBaseProps {
    defaultImage?: string;
    imagePath?: string;
    classes: any;
}

const WgListItemIcon = ({classes, children}: WgListItemIconProps) => {
    return (
        <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={3}
            className={classes['image']}>
            {children}
        </Grid>
    );
};

export default withStyles(styles)(WgListItemIcon);

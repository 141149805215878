import {
    Box,
    Checkbox,
    CheckboxProps,
    createStyles,
    FormControlLabel,
    Paper,
    Theme,
    withStyles
} from '@material-ui/core';
import React from 'react';

const styles = (theme: Theme) =>
    createStyles({
        label: {
            width: '-moz-available'
        }
    });

export interface WgCheckboxProps extends CheckboxProps {
    classes: any;
    key: string;
    label: string;
    checked: boolean;
    background?: 'paper' | 'default';
}

function WgCheckbox({
    classes,
    key,
    label,
    checked,
    background,
    ...other
}: WgCheckboxProps) {
    return (
        <React.Fragment>
            {background === 'paper'
                ? renderRadioButtonOnPaper()
                : renderRadioButton()}
        </React.Fragment>
    );

    function renderRadioButtonOnPaper() {
        return (
            <Box key={key} marginTop="1.125rem">
                <Paper style={{padding: '0.5rem'}} elevation={1}>
                    {renderRadioButton()}
                </Paper>
            </Box>
        );
    }

    function renderRadioButton() {
        return (
            <FormControlLabel
                className={classes.label}
                control={<Checkbox {...other} checked={checked} />}
                label={label}
            />
        );
    }
}

export default withStyles(styles)(WgCheckbox);

import React from 'react';
import {WgPaperArea} from '../../../../styles/CustomComponents';
import {ICategory} from '../../../../models/categoryInterface';
import {Grid} from '@material-ui/core';
import InsuranceListItem from '../../insurance/components/InsuranceListItem';
import {ICompany} from '../../../../models/companyInterface';
import {IAccount} from '../../../../models/accountInterface';
import AmountIntervalInput from '../change/Components/AmountIntervalInput';
import {
    IContractManualAddition,
    IntervalType
} from '../../../../models/recurringpaymentInterface';
import {ContractAdditionStep} from './ContractAdditionController';

export interface ContractAdditionInputOverviewProps {
    category?: ICategory;
    company?: ICompany;
    account?: IAccount;
    amount?: number;
    paymentInterval?: IntervalType;
    handleChange: (name: keyof IContractManualAddition) => (value: any) => void;
    handleAmountChange: Function;
    handleEdit: (nextStep: ContractAdditionStep) => void;
}

export default function ContractAdditionInputOverview({
    category,
    company,
    account,
    amount,
    paymentInterval,
    handleChange,
    handleAmountChange,
    handleEdit
}: ContractAdditionInputOverviewProps) {
    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            spacing={2}
            style={{flex: 1}}
            justify="center">
            <Grid item style={{flex: '0 1 0%'}}>
                {renderCompanyListItem()}
            </Grid>
            <Grid item style={{flex: '0 1 0%'}}>
                {renderCategoryListItem()}
            </Grid>
            <Grid item style={{flex: '0 1 0%'}}>
                {renderContent()}
            </Grid>
            <Grid item style={{flex: '0 1 0%'}}>
                {renderAccountListItem()}
            </Grid>
        </Grid>
    );

    function renderAccountListItem() {
        return (
            <InsuranceListItem
                placeholder="Bankkonto verknüpfen"
                shadow
                onClick={() =>
                    handleEdit(ContractAdditionStep.ACCOUNT_SELECTION)
                }
                data={account}
                hasIcon
            />
        );
    }

    function renderCategoryListItem() {
        return (
            <InsuranceListItem
                hasIcon
                placeholder="Kategorie hinzufügen"
                shadow
                onClick={() =>
                    handleEdit(ContractAdditionStep.CATEGORY_SELECTION)
                }
                data={category}
            />
        );
    }

    function renderCompanyListItem() {
        return (
            <InsuranceListItem
                hasIcon
                placeholder="Empfänger/Sender hinzufügen"
                shadow
                onClick={() =>
                    handleEdit(ContractAdditionStep.COMPANY_SELECTION)
                }
                data={company}
            />
        );
    }

    function renderContent() {
        return (
            <WgPaperArea
                header="Daten deines Vertrages"
                elevation={1}
                paperStyle={{padding: '1rem 1rem 0.5rem'}}>
                <Grid container direction="column">
                    <AmountIntervalInput
                        signButton
                        amount={amount ?? 0}
                        paymentInterval={paymentInterval}
                        handleAmountChange={handleAmountChange}
                        handleChange={handleChange}
                    />
                </Grid>
            </WgPaperArea>
        );
    }
}

import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, Grid} from '@material-ui/core';
import {formatEcoOption, formatMoney} from '../../../../utils/Format';
import {
    WgEnumerationList,
    WgExpansionPanel,
    WgPaperList,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {
    formatOfferState,
    IEnergyProduct,
    IOffer,
    isInErrorState,
    OfferState
} from '../../../../../models/offerInterface';
import {Redirect} from 'react-router-dom';
import {setNotification} from '../../../../../store/actions/notificationActions';
import {push} from 'connected-react-router';
import {MyLink} from '../../../../utils';

const invalidId = -1;

interface OfferSelectionProps {
    offer?: IOffer;
    handleOfferSelection: (energyProduct: IEnergyProduct) => void;
}

export default function OfferSelection({
    offer,
    handleOfferSelection
}: OfferSelectionProps) {
    const dispatch = useDispatch();

    if (typeof offer === 'undefined') return returnToRecurringPayment(); // TODO error handling?
    const {eco, price} = offer;
    if (typeof price === 'undefined' || isInErrorState(offer)) {
        dispatch(
            setNotification({
                title: 'Dein Vertrag ist bereits optimal!',
                text:
                    'Leider konnten wir kein günstigeres Angebot für dich finden. ' +
                    'Wir benachrichtigen dich, sobald sich dies ändert.',
                isAgreeable: true,
                onAgree: () => {
                    dispatch(
                        push(
                            `/recurringpayments/details/${offer.recurringPaymentId}`
                        )
                    );
                },
                open: true
            })
        );
        return null;
    }

    return (
        <Box paddingX={{xs: '1rem', md: 0}} marginBottom="2rem">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {renderProduct(price)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {offer.offerState === OfferState.ECO_NOT_FOUND
                        ? renderEcoProductUnavailable()
                        : renderProduct(eco)}
                </Grid>
                <Grid item xs={12} style={{padding: '0 12px'}}>
                    <WgTypography
                        gutterTop="1rem"
                        text="info"
                        color="textSecondary">
                        In deinem Profil kannst du die Tarifart jederzeit
                        ändern.
                    </WgTypography>
                    <Box
                        marginTop="1rem"
                        style={{textDecoration: 'none'}}
                        component={MyLink(
                            '/recurringpayments/energy/info',
                            undefined,
                            {
                                oldProviderName:
                                    price.oldProviderName ??
                                    eco?.oldProviderName,
                                oldProviderYearlyCosts:
                                    price.oldProviderYearlyCosts ??
                                    eco?.oldProviderYearlyCosts,
                                oldProviderMonthlyServiceCharge:
                                    price.oldProviderMonthlyServiceCharge ??
                                    eco?.oldProviderMonthlyServiceCharge,
                                oldProviderChargeCtPerKwh:
                                    price.oldProviderChargeCtPerKwh ??
                                    eco?.oldProviderChargeCtPerKwh,
                                estimatedUsageInKwh:
                                    price.estimatedUsageInKwh ??
                                    eco?.estimatedUsageInKwh
                            }
                        )}>
                        <WgTypography
                            gutterTop="0.5rem"
                            color="primary"
                            text="content">
                            Wie funktioniert der Wechselprozess?
                        </WgTypography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );

    function isBetterRecurringpayment(energyProduct: IEnergyProduct) {
        return (
            energyProduct.oldProviderYearlyCosts >
            energyProduct.betterProviderYearlyCosts
        );
    }

    function renderProduct(energyProduct?: IEnergyProduct) {
        if (typeof energyProduct === 'undefined')
            return renderEcoProductUnavailable();

        const productInfoBulletPoints = [
            'Wechsel rechtzeitig zur Kündigungsfrist',
            '12 Monate maximale Laufzeit'
        ];
        if (!energyProduct.eco) {
            productInfoBulletPoints.unshift('Immer der günstigste Preis');
            productInfoBulletPoints.push('Jederzeit zu Öko-Tarifen wechselbar');
        } else {
            productInfoBulletPoints.unshift('Nur Öko-Tarife');
        }

        return (
            <WgExpansionPanel
                title={formatEcoOption(energyProduct.eco) as string}
                subTitle={energyProduct.betterProviderName}
                headerImage={energyProduct.logoPath}
                currentAmount={
                    formatMoney(energyProduct.betterProviderYearlyCosts / 12) +
                    ' / Monat'
                }
                footerContent={{
                    buttonText: 'Tarif wählen',
                    handleButtonClick: () =>
                        handleOfferSelection(energyProduct),
                    text: isBetterRecurringpayment(energyProduct)
                        ? 'Jährliche Ersparnis'
                        : 'Keine Ersparnis',
                    amount: energyProduct.estimatedSavings
                }}
                infoPopover={{
                    text:
                        'Ersparnis anhand deines geschätzten Verbrauches. Beinhaltet alle Boni, Prämien, sowie den günstigeren Tarifpreis. Deine Ersparnis kann durch geänderte Verbrauchsdaten oder Ähnliches variieren. Der günstigere Tarif ist dir natürlich sicher!'
                }}>
                <WgEnumerationList
                    dense={true}
                    enumerationIcon={
                        <img src="/img/icon-check-rounded.svg" alt="✓" />
                    }
                    background="default"
                    content={productInfoBulletPoints}
                />
            </WgExpansionPanel>
        );
    }

    function renderEcoProductUnavailable() {
        if (typeof offer === 'undefined') return <div />;
        const {title, description} = formatOfferState(offer);

        return (
            <WgExpansionPanel
                title={title}
                headerImage="/img/icon-recurringpayment-circle.svg"
                footerContent={{
                    buttonText: 'Tarif wählen',
                    buttonDisabled: true
                }}>
                <WgPaperList
                    link={''}
                    background="default"
                    keyValueMapping={{
                        '': description
                    }}
                />
            </WgExpansionPanel>
        );
    }

    function returnToRecurringPayment() {
        return (
            <Redirect
                to={
                    offer?.recurringPaymentId
                        ? `/recurringpayments/details/${offer.recurringPaymentId ??
                              invalidId}`
                        : '/recurringpayments'
                }
            />
        );
    }
}

import {Divider, Box} from '@material-ui/core';
import {WgTypography} from '../../../../../../styles/CustomComponents';
import React from 'react';

export interface ListItemCompletionProps {
    primary: string;
    secondary?: string;
    lastElement?: Boolean;
}

export default function ListItemCompletion({
    primary,
    secondary,
    lastElement
}: ListItemCompletionProps) {
    return (
        <React.Fragment>
            <Box paddingY="1rem">
                <WgTypography color="textSecondary" text="subContent">
                    {primary}
                </WgTypography>
                {secondary && (
                    <WgTypography color="textPrimary">{secondary}</WgTypography>
                )}
            </Box>
            {lastElement !== true && <Divider light />}
        </React.Fragment>
    );
}

import React from 'react';
import NumberFormat from 'react-number-format';

export interface WgNumberFormatProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: {target: {name: string; value: string}}) => void;
    type?: 'text' | 'tel' | 'password' | 'currency';
    name: string;
}

export default function WgNumberFormat(props: WgNumberFormatProps) {
    const {inputRef, onChange, type, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            isNumericString
            type={type === 'currency' ? 'text' : type}
        />
    );
}

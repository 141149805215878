import React from 'react';
import {
    Container,
    ContainerProps,
    createStyles,
    Theme,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme: Theme) =>
    createStyles({
        bodyApp: {
            position: 'fixed',
            width: '100%',
            alignContent: 'flex-start',
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.up('md')]: {
                overflow: 'auto',
                '-webkit-overflow-scrolling': 'touch',
                order: 2,
                justifyContent: 'center',
                height: 'calc(100% - 76px)',
                top: '76px',
                display: 'grid',
                gridTemplateRows: 'auto 1fr'
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                height: 'calc(100% - 50px)',
                display: 'flex',
                flexDirection: 'column',
                // NOTE: This disables text selection everywhere.
                // For list items or views where a user might want to copy stuff we should add it again.
                // We need this so the iOS web view doesn't break when the user selects something. Hacky I know...
                '-webkit-touch-callout': 'none' /* iOS Safari */,
                '-webkit-user-select': 'none' /* Safari */
            }
        },
        bodyContent: {
            height: '100%',
            [theme.breakpoints.up('md')]: {
                width: '860px',
                paddingBottom: '2rem',
                paddingTop: '2.5rem'
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                width: '100%',
                paddingTop: '1.5rem',
                overflowY: 'auto',
                overflowX: 'hidden',
                '-webkit-overflow-scrolling': 'touch'
            },
            [theme.breakpoints.only('xs')]: {
                marginTop: '56px'
            },
            [theme.breakpoints.only('sm')]: {
                marginTop: '64px'
            }
        },
        bodyContentEnableGutters: {
            [theme.breakpoints.between('xs', 'sm')]: {
                '&::after': {
                    //Because firefox and IE11 doesn't want overflow with paddingBottom
                    content: "''",
                    paddingBottom: '1.25rem',
                    display: 'block'
                }
            }
        },
        publicBodyApp: {
            height: '100%',
            width: '100%',
            position: 'fixed',
            '-webkit-overflow-scrolling': 'touch',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default
        },
        publicBodyContent: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            [theme.breakpoints.up('md')]: {
                width: '860px',
                marginTop: 0
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                // marginTop: '60px',
                overflow: 'auto',
                '-webkit-overflow-scrolling': 'touch',
                width: '100%'
            },
            [theme.breakpoints.only('xs')]: {
                marginTop: '56px'
            },
            [theme.breakpoints.only('sm')]: {
                marginTop: '64px'
            }
        },
        backgroundImage: {
            backgroundImage: 'url(/img/background/wolken-bg-smartphone.jpg)',
            backgroundPosition: 'bottom',
            backgroundSize: 'cover',
            [theme.breakpoints.up('md')]: {
                backgroundImage: 'url(/img/background/wolken-bg-original.jpg)'
            }
        },
        zeroPaddingTopMobile: {
            [theme.breakpoints.between('xs', 'sm')]: {
                paddingTop: 0
            }
        }
    });

export interface WgContainerProps extends ContainerProps {
    classes: any;
    bodyApp?: boolean;
    bodyContent?: boolean;
    backgroundImage?: boolean;
    publicBodyApp?: boolean;
    publicBodyContent?: boolean;
    zeroPaddingTopMobile?: boolean;
    zeroPaddingBottomMobile?: boolean;
}

function WgContainer({
    classes,
    children,
    bodyApp,
    bodyContent,
    publicBodyApp,
    publicBodyContent,
    backgroundImage,
    zeroPaddingTopMobile,
    zeroPaddingBottomMobile,
    ...other
}: WgContainerProps) {
    return (
        <Container
            id={bodyContent ? 'content' : 'app'}
            maxWidth={false}
            className={clsx({
                [classes['bodyApp']]: bodyApp,
                [classes['backgroundImage']]: backgroundImage,
                [classes['publicBodyApp']]: publicBodyApp,
                [classes['publicBodyContent']]: publicBodyContent,
                [classes['bodyContent']]: bodyContent,
                [classes['bodyContentEnableGutters']]:
                    bodyContent && !zeroPaddingBottomMobile,
                [classes['zeroPaddingTopMobile']]: zeroPaddingTopMobile
            })}
            disableGutters
            {...other}>
            {children}
        </Container>
    );
}

export default withStyles(styles)(WgContainer);

import React, {Component} from 'react';
import {AppBar, Button, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MyLink from '../utils/MyLink';
import './Navigation.scss';

import {ReactSVG} from 'react-svg';
import {connect} from 'react-redux';
import {getIsTokenValid} from '../../store/actions/api';

interface IProps {
    pathname: string;
}

class Navigation extends Component<IProps, {}> {
    render() {
        if (getIsTokenValid())
            return (
                <AppBar
                    id="footer"
                    color="default"
                    className="navigation-component">
                    <div className="navigation-header-content">
                        <ReactSVG
                            className="navigation-wechselgott-image"
                            src="/img/logowechselgott.svg"
                        />

                        <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="baseline"
                            className="navigation-container-header-buttons">
                            <Grid item>
                                <Button
                                    className="navigation-header-item"
                                    component={MyLink(
                                        '/recurringpayments',
                                        this.props.pathname
                                    )}>
                                    <ReactSVG
                                        className="nav-svg"
                                        src="/img/navigation/nav-fixedpostings.svg"
                                    />
                                    <Typography className="navigation-text-header navigation-header-item-text">
                                        Vertragswelt
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    className="navigation-header-item"
                                    component={MyLink(
                                        '/products',
                                        this.props.pathname
                                    )}>
                                    <ReactSVG
                                        className="nav-svg"
                                        src="/img/navigation/nav-insurance.svg"
                                    />
                                    <Typography className="navigation-text-header navigation-header-item-text">
                                        Versicherungswelt
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    className="navigation-header-item"
                                    component={MyLink(
                                        '/accounts',
                                        this.props.pathname
                                    )}>
                                    <ReactSVG
                                        className="nav-svg"
                                        src="/img/navigation/nav-accounts.svg"
                                    />
                                    <Typography className="navigation-text-header navigation-header-item-text">
                                        Konten
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    className="navigation-header-item"
                                    component={MyLink(
                                        '/profile',
                                        this.props.pathname
                                    )}>
                                    <ReactSVG
                                        className="nav-svg"
                                        src="/img/navigation/nav-profile.svg"
                                    />
                                    <Typography className="navigation-text-header navigation-header-item-text">
                                        Profil
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </AppBar>
            );
        else return <div />; //Do not delete! For Layout
    }
}

function mapStateToProps(state: any) {
    return {
        pathname: state?.router?.location?.pathname
    };
}

export default connect(mapStateToProps, {})(Navigation);

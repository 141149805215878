import React, {Component} from 'react';
import {Box, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {policySelector} from '../../../store/reducers/selectors';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgSplitButton,
    WgTypography
} from '../../../styles/CustomComponents';

import {AccountListItem} from '../../utils';
import {IExistingInsuranceProduct} from '../../../models/productInterface';
import {formatPaymentInterval} from '../../utils/Format';
import {loadingIdAllContracts} from '../../../store/actions/loadingIds';

interface IProps {
    products: IExistingInsuranceProduct[];
}
//ToDo: Refactoring as Functional Component
class Products extends Component<IProps, {}> {
    render() {
        return (
            <WgContainer bodyApp>
                <WgHeader headerText={'Versicherungswelt'} />
                {this.props.products && this.props.products.length > 0
                    ? this.renderProductsAvailable()
                    : this.renderProductsNotAvailable()}
            </WgContainer>
        );
    }

    private mapProducts(product: IExistingInsuranceProduct, index: number) {
        const link = '/products/details/' + product.id;
        return (
            <Grid key={index} item xs={12} md={6}>
                <AccountListItem
                    loadingId={loadingIdAllContracts}
                    header={product.insuranceType}
                    subHeader={product.company?.name || 'in Bearbeitung...'}
                    amount={
                        product.recurringPayment
                            ? product.recurringPayment?.lastAmount
                            : product.amount
                    }
                    subAmountString={formatPaymentInterval(
                        product.recurringPayment
                            ? product.recurringPayment.paymentInterval
                            : product.paymentInterval
                    )}
                    key={index.toString()}
                    link={link}
                    logoPath={
                        product.recurringPayment
                            ? product.recurringPayment?.logoPath
                            : product.logoPath
                    }
                    defaultImage={'/img/insurance.svg'}
                />
            </Grid>
        );
    }

    private renderProductsAvailable() {
        return (
            <WgContainer bodyContent disableGutters={false}>
                <WgSplitButton
                    leftText="Bestehende Versicherung hinzufügen"
                    rightText="Neue Versicherung abschließen"
                    leftLink="/products/existing/0"
                    rightLink="/products/new"
                />
                <Grid container spacing={1} style={{paddingTop: '4px'}}>
                    {this.props.products &&
                        this.props.products instanceof Array &&
                        this.props.products.map(
                            (
                                product: IExistingInsuranceProduct,
                                index: number
                            ) => this.mapProducts(product, index)
                        )}
                </Grid>
            </WgContainer>
        );
    }

    private renderProductsNotAvailable() {
        return (
            <WgContainer
                bodyContent
                disableGutters={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                <Grid container direction="column" item xs wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '0.5 1 0%'}}
                        container
                        alignItems="flex-end"
                        justify="center">
                        <Box m="1rem 0" paddingX="4px">
                            <WgTypography
                                color="primary"
                                text="contentInfo"
                                align="center">
                                Noch keine <br /> Versicherungen hier...
                            </WgTypography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        style={{flex: '0.5 1 0%'}}
                        container
                        alignItems="flex-start">
                        <WgTypography
                            color="textSecondary"
                            text="content"
                            align="center">
                            WechselGott erkennt aus deinen Umsatzdaten welche
                            Versicherungen du hast und wo du zu viel Geld
                            ausgibst.
                            <br />
                            <br />
                            Füge jetzt deine bestehenden Versicherungen hinzu
                            oder schließe in wenigen Klicks eine neue ab!
                        </WgTypography>
                    </Grid>
                    <Grid item container xs>
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent={{xs: 'flex-end', md: 'flex-start'}}>
                            <WgButton
                                aria-label="signup"
                                variant="contained"
                                color="primary"
                                fullWidth
                                link={'/products/existing/0'}>
                                <img
                                    style={{marginRight: '0.5rem'}}
                                    src="/img/navigation/add-white.svg"
                                    alt=""
                                />
                                Versicherung hinzufügen
                            </WgButton>
                            <WgButton
                                style={{marginTop: '1rem'}}
                                aria-label="signup"
                                variant="contained"
                                color="primary"
                                fullWidth
                                link={'/products/new'}>
                                <img
                                    style={{marginRight: '0.5rem'}}
                                    src="/img/navigation/add-white.svg"
                                    alt=""
                                />
                                Versicherung abschließen
                            </WgButton>
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        products: policySelector(state, {})
    };
}

export default connect(mapStateToProps, {})(Products);

import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {useMediaQuery as uMQ, useTheme} from '@material-ui/core';

export default function useMediaQuery(key?: Breakpoint | number) {
    const theme = useTheme();
    return {
        isUp: key && uMQ(theme.breakpoints.up(key)),
        isMobile: Boolean(
            navigator.userAgent.match(
                /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/gi
            )
        )
    };
}

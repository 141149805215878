import React from 'react';
import {
    Box,
    BoxProps,
    createStyles,
    Grid,
    IconButton,
    Theme,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import {WgTypography} from './index';
import CloseIcon from '@material-ui/icons/Close';
import {useRouteMatch} from 'react-router';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: '10px 0 8px 20px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: '3px 3px 7px 0 rgba(0, 0, 0, 0.15)',
            borderWidth: '0 0 0 6px',
            borderStyle: 'solid',
            borderRadius: '5px',
            position: 'relative'
        },
        success: {
            borderColor: theme.palette.primary.main
        },
        error: {
            borderColor: theme.palette.error.light
        }
    });

export interface WgToastProps extends BoxProps {
    variant?: 'success' | 'error';
    text?: string;
    classes: any;
    handleClose?: () => void;
}

function WgToast({
    variant,
    text,
    classes,
    handleClose,
    ...other
}: WgToastProps) {
    const isLoggedOut = useRouteMatch(['/login', '/signup/:step']);
    return (
        <Box
            m={isLoggedOut ? '20px 20px 60px' : '20px'}
            className={clsx(classes['root'], {
                [classes[`${variant}`]]: variant
            })}
            {...other}>
            <Grid container wrap="nowrap">
                <Grid item xs={10} container alignItems="center">
                    <WgTypography color="textPrimary" text="sentence">
                        {text}
                    </WgTypography>
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    justify="flex-end"
                    alignItems="center">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon color="disabled" fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default withStyles(styles)(WgToast);

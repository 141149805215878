import React from 'react';
import {IRecurringpayment} from '../../../../models/recurringpaymentInterface';
import {Divider} from '@material-ui/core';
import {connect} from 'react-redux';
import {cancellationContractActions} from '../../../../store/actions';
import InsuranceListItem from '../../insurance/components/InsuranceListItem';

const {fetchContractCancellation} = cancellationContractActions;

interface ICancelContract {
    recurringPayment: IRecurringpayment | undefined;
    fetchContractCancellation?: Function;
}

function CancellableContract({
    recurringPayment,
    fetchContractCancellation
}: ICancelContract) {
    return recurringPayment &&
        recurringPayment.cancellationStatus === 'Cancellable' ? (
        <React.Fragment>
            <InsuranceListItem
                textColor="error"
                disableStartIcon
                placeholder="Vertrag kündigen"
                link={`/recurringpayments/cancellation/${recurringPayment.recurringPaymentId}`}
                onClick={() =>
                    fetchContractCancellation &&
                    fetchContractCancellation(
                        recurringPayment.recurringPaymentId
                    )
                }
                hasIcon
            />
            <Divider light />
        </React.Fragment>
    ) : (
        <React.Fragment />
    );
}

export default connect(null, {
    fetchContractCancellation
})(CancellableContract);

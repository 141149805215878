import React from 'react';
import {SignupStep} from './SignupStep';
import {connect} from 'react-redux';
import VerificationCodeField from '../../../utils/VerificationCodeField';
import {getUser} from '../../../../store/reducers/selectors';

class SignupStep10 extends SignupStep {
    renderStep(): React.ReactNode {
        return (
            <React.Fragment>
                <VerificationCodeField
                    autoFocus
                    name="code"
                    onChange={this.onChange.bind(this)}
                    defaultValue={this.props?.state?.code}
                />
            </React.Fragment>
        );
    }

    areRequirementsMet(props: any) {
        return (
            super.areRequirementsMet(props) &&
            this.props?.state?.code?.length >= 6 &&
            // @ts-ignore
            this.props.isValid
        );
    }

    private async onChange(event: any) {
        let code = event.target.value;
        if (code.length >= 6) {
            this.validate();
        }
    }
}

function mapStateToProps(state: any) {
    return {
        isValid: getUser(state).id
    };
}

export default connect(mapStateToProps, {})(SignupStep10);

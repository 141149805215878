import {createAction} from 'redux-actions';
import {api, getAuthConfig, performApiCall} from './api';
import {
    FETCH_ACCOUNTS_BY_ID_SUCCESS,
    FETCH_ALL_ACCOUNTS_SUCCESS,
    FETCH_DEPOTS_FROM_ACCOUNT_ID_SUCCESS,
    FETCH_TRANSACTIONS_FROM_ACCOUNT_ID_SUCCESS,
    SET_ACCOUNT_VISIBILITY_SUCCESS
} from './actionTypes';
import {
    loadingIdAllAccounts,
    loadingIdAllTransaction,
    loadingIdDepotFromAccount,
    loadingIdTransactionFromAccount
} from './loadingIds';
import {ITransaction} from '../../models/transactionInterface';
import {refreshLocalStorage} from '../../rootReducer';

// *** ACTION CREATORS ***
export const fetchAllAccountsSuccess = createAction(FETCH_ALL_ACCOUNTS_SUCCESS);
export const fetchAccountsByIdSuccess = createAction(
    FETCH_ACCOUNTS_BY_ID_SUCCESS
);
export const setAccountVisibilitySuccess = createAction(
    SET_ACCOUNT_VISIBILITY_SUCCESS
);
export const fetchTransactionsFromAccountIdSuccess = createAction(
    FETCH_TRANSACTIONS_FROM_ACCOUNT_ID_SUCCESS
);
export const fetchDepotsFromAccountIdSuccess = createAction(
    FETCH_DEPOTS_FROM_ACCOUNT_ID_SUCCESS
);

export const fetchAllAccounts: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            refreshLocalStorage(`/accounts`);
            const response = await api.get(
                '/accounts/',
                getAuthConfig(token, {Accept: 'application/json'})
            );
            await dispatch(fetchAllAccountsSuccess(response.data));
            await dispatch(fetchAllTransactions());
        },
        'Laden der Konten fehlgeschlagen. Versuche es später noch einmal.',
        undefined,
        true,
        loadingIdAllAccounts
    );

export const fetchAccountsById: any = (ids: number[]) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            if (!(ids instanceof Array) || ids.length === 0) return;
            const token = getState().user.token;

            refreshLocalStorage(`/accounts`);
            refreshLocalStorage('/transactions');

            const response = await api.get('/accounts/', {
                ...getAuthConfig(token, {Accept: 'application/json'}),
                params: {
                    ids: JSON.stringify(ids)
                        .replace('[', '')
                        .replace(']', '')
                }
            });
            await dispatch(fetchAccountsByIdSuccess(response.data));
            await dispatch(fetchTransactionsFromAccountId(ids));
        },
        'Laden der Konten fehlgeschlagen. Versuche es später noch einmal.',
        undefined,
        true,
        loadingIdAllAccounts
    );

export const fetchAllTransactions: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const response = await api.get(
                '/transactions/',
                getAuthConfig(token, {Accept: 'application/json'})
            );
            const uniqueAccountIds = response.data
                .map((transaction: ITransaction) => transaction.accountId)
                .filter(
                    (v: number, i: number, a: Array<number>) =>
                        a.indexOf(v) === i
                );
            uniqueAccountIds.forEach((accountId: number) => {
                dispatch(
                    fetchTransactionsFromAccountIdSuccess({
                        data: response.data.filter(
                            (transaction: ITransaction) =>
                                transaction.accountId === accountId
                        ),
                        id: accountId
                    })
                );
            });
        },
        'Überweisungen konnten nicht geladen werden. Versuche es später noch einmal.',
        undefined,
        true,
        loadingIdAllTransaction
    );

export const fetchTransactionsFromAccountId: any = (accountIds: number[]) => {
    if (!Array.isArray(accountIds)) {
        return;
    }
    return performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const response = await api.get(
                '/transactions?accountIds=' + accountIds.join(','),
                getAuthConfig(token, {Accept: 'application/json'})
            );
            accountIds.forEach((accountId: number) => {
                dispatch(
                    fetchTransactionsFromAccountIdSuccess({
                        data: response.data.filter(
                            (transaction: ITransaction) =>
                                transaction.accountId === accountId
                        ),
                        id: accountId
                    })
                );
            });
        },
        'Überweisungen konnten nicht geladen werden. Versuche es später noch einmal.',
        undefined,
        true,
        accountIds
            .map((id: number) => loadingIdTransactionFromAccount(id))
            .join(',')
    );
};

export const fetchDepotFromAccountId: any = (accountId: number) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const response = await api.get(
                '/accounts/' + accountId + '/securities',
                getAuthConfig(token, {Accept: 'application/json'})
            );
            dispatch(
                fetchDepotsFromAccountIdSuccess({
                    data: response.data,
                    id: accountId
                })
            );
        },
        'Depotwerte konnten nicht geladen werden. Versuche es später noch einmal.',
        undefined,
        true,
        loadingIdDepotFromAccount(accountId)
    );

export const setAccountVisibility: any = (
    accountId: number,
    visible: boolean
) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        localStorage.removeItem('/accounts/');
        dispatch(
            setAccountVisibilitySuccess({
                accountId: accountId,
                visible: visible
            })
        );
        const token = getState().user.token;
        if (visible) {
            await api.put(
                '/accounts/' + accountId + '/visible',
                null,
                getAuthConfig(token, {Accept: 'application/json'})
            );
        } else {
            await api.delete(
                '/accounts/' + accountId + '/visible',
                getAuthConfig(token, {Accept: 'application/json'})
            );
        }
    }, 'Ein Fehler beim Ein-/ Ausblenden der Konten ist aufgetreten. Versuche es später noch einmal.');

import {
    FETCH_INSURANCE_PRODUCT_URL,
    FETCH_INSURANCE_PRODUCTS
} from '../actions/actionTypes';
import get from 'lodash.get';

const temp = {}; //could be used for notifications form the BackEnd

export const initialState = get(temp, 'products', {
    insuranceProducts: [],
    url: {}
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_INSURANCE_PRODUCTS:
            if (!Array.isArray(action.payload)) {
                return state;
            }

            return {
                ...state,
                insuranceProducts: action.payload
            };
        case FETCH_INSURANCE_PRODUCT_URL:
            if (!isObjectContainingType('string', action.payload)) {
                return state;
            }

            return {
                ...state,
                url: {
                    ...state.url,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

function isObjectContainingType(type: string, object?: any) {
    let out = true;
    if (typeof object !== 'object' || Object.keys(object).length === 0) {
        out = false;
    } else {
        for (const key of Object.keys(object)) {
            if (typeof object[key] !== 'string') {
                out = false;
            }
        }
    }
    return out;
}

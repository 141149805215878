import {IAccount} from '../../models/accountInterface';
import {DateFormState} from './DateForm';
import {formatISO, parseISO} from 'date-fns';
import {IntervalType} from '../../models/recurringpaymentInterface';
import {CarInsuranceOneProductType} from '../../models/productInterface';

export function formatMoney(
    money = 0,
    currency = 'EUR',
    displaySign = true
): string {
    if (!displaySign)
        return money.toLocaleString('de-DE', {minimumFractionDigits: 2});
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency
    }).format(money);
}

export function formatPercent(percent = 0): string {
    return new Intl.NumberFormat('de-DE', {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    }).format(percent / 100);
}

export function formatIBAN(iban: string): string {
    return iban && iban.replace(/(\w{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
}

export function formatPaymentInterval(paymentInterval?: string): string {
    const rpInterval = paymentInterval && paymentInterval.toUpperCase();
    if (rpInterval === 'WEEKLY') return 'wöchentlich';
    else if (rpInterval === 'BIWEEKLY') return 'zweiwöchentlich';
    else if (rpInterval === 'MONTHLY') return 'monatlich';
    else if (rpInterval === 'QUARTERLY') return 'vierteljährlich';
    else if (rpInterval === 'SEMIANNUAL') return 'halbjährlich';
    else if (rpInterval === 'ANNUAL') return 'jährlich';
    else if (rpInterval === 'BIANNUAL') return 'zweijährlich';

    return paymentInterval || '';
}

export function formatDeductible(deductible?: string): string {
    const input = deductible && deductible.toUpperCase();
    if (input === 'D0') return '0';
    else if (input === 'D150') return '150';
    else if (input === 'D300') return '300';
    else if (input === 'D500') return '500';
    else if (input === 'D1000') return '1000';
    else if (input === 'D2500') return '2500';

    return deductible || '';
}

export function convertStringToPaymentInterval(
    paymentInterval?: string
): IntervalType | undefined {
    if (typeof paymentInterval === 'undefined') return;
    const rpInterval = paymentInterval && paymentInterval.toUpperCase();
    return rpInterval as IntervalType;
}

export function formatDateFromString(valueDate: string | undefined) {
    if (typeof valueDate === 'undefined') return '';
    const date = new Date(valueDate);

    return formatDateFromDate(date);
}

export function formatDateFromDate(valueDate: Date | undefined) {
    if (typeof valueDate === 'undefined') return '';

    const optionsDate = {year: 'numeric', month: 'long', day: '2-digit'};

    return valueDate.toLocaleDateString('de-DE', optionsDate);
}

export function formatDateWithTime(
    valueDate?: string | Date,
    withTime?: boolean
) {
    if (valueDate === undefined) return valueDate;

    if (!(valueDate instanceof Date)) valueDate = new Date(valueDate);

    const optionsDate = {year: 'numeric', month: '2-digit', day: '2-digit'};
    const optionsTime = {hour: '2-digit', minute: '2-digit'};

    return (
        valueDate.toLocaleDateString('de-DE', optionsDate) +
        (withTime
            ? ' um ' + valueDate.toLocaleTimeString('de-DE', optionsTime)
            : '')
    );
}

export function formatEcoOption(isEco?: boolean): string {
    if (typeof isEco !== 'boolean') return '';
    return isEco ? 'Öko-Tarif' : 'Bester Preis';
}

export function formatONEInsuranceOption(
    processType: CarInsuranceOneProductType,
    eco?: boolean,
    specialText?: boolean
): string {
    switch (processType) {
        case CarInsuranceOneProductType.NEW_VEHICLE:
            return formatOneInsuranceTariffName(eco);
        case CarInsuranceOneProductType.SWITCH_INSURANCE:
            return formatONEInsuranceSwitchTariffName(eco, specialText);
        default:
            return formatEcoOption(eco);
    }
}

export function formatOneInsuranceTariffName(eco?: boolean): string {
    return 'Comfort' + (eco ? ' Green' : '');
}

export function formatONEInsuranceSwitchTariffName(
    eco?: boolean,
    specialText?: boolean
): string {
    if (specialText) return eco ? 'Zum grünen Vertrag' : 'Einfach 5% sparen';
    else return eco ? 'Switch Green' : 'Switch Basic';
}

export function formatGender(value: string, isTitle = false) {
    //@ts-ignore
    if (value.toLowerCase() === 'male') return isTitle ? 'Herr' : 'männlich';
    //@ts-ignore
    else if (value.toLowerCase() === 'female')
        return isTitle ? 'Frau' : 'weiblich';
    //@ts-ignore
    else if (value.toLowerCase() === 'divers')
        return isTitle ? 'Divers' : 'divers';

    return value || '';
}

export function formatProductType(productType: string) {
    if (productType === 'vehicle-liability') return 'Haftpflicht';
    else if (productType === 'partial-comprehensive') return 'Teilkasko';
    else if (productType === 'fully-comprehensive') return 'Vollkasko';

    return productType || '';
}

export function formatRecurringpaymentChangeState(changestate: string): string {
    if (changestate === 'available') return 'verfügbar';
    else if (changestate === 'onGoing') return 'in Bearbeitung';
    else if (changestate === 'success') return 'erfolgreich';
    else if (changestate === 'failure') return 'fehlgeschlagen';
    else if (changestate === 'none') return '';

    return changestate || '';
}

export function dateAndName(
    firstName: string,
    lastName: string,
    title?: string
) {
    const thisTitle = typeof title !== 'undefined' ? title + ' ' : '';

    return (
        formatDateWithTime(new Date(), true) +
        ', ' +
        thisTitle +
        firstName +
        ' ' +
        lastName
    );
}

export function groupBy<R>(
    list: R[],
    keyGetter: (item: R) => R[keyof R]
): Map<any, R[]> {
    if (!Array.isArray(list)) {
        return new Map();
    }

    const map = new Map();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function calculateAccountsBalance(accounts?: IAccount[]): number {
    return (
        accounts
            ?.map((account: IAccount) => account.balance)
            .reduce((total: number, current: number) => total + current, 0) ?? 0
    );
}

export function isDateInFuture(date: Date): boolean {
    return date >= new Date(Date.now());
}

export function isDateInPast(date: Date): boolean {
    return date <= new Date(Date.now());
}

export function calculateAge(birthdate: Date): number {
    const ageDate = new Date(Date.now() - birthdate.getTime());
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function dateString(state: DateFormState) {
    const isoMonth =
        (Number(state.month) <= 9 ? '0' : '') + Number(state.month);
    const isoDay = (Number(state.day) <= 9 ? '0' : '') + Number(state.day);

    return `${state.year}-${isoMonth}-${isoDay}`;
}

export function splitDate(date?: string | Date | number) {
    if (typeof date === 'undefined') {
        return [undefined, undefined, undefined];
    }
    let value;
    try {
        if (typeof date === 'string') {
            value = date;
        } else {
            value = formatISO(date, {representation: 'date'});
        }

        return value.split('T')[0].split('-');
    } catch (e) {
        return [undefined, undefined, undefined];
    }
}

export function isValidDateString(dateString: string): boolean {
    const date = parseISO(dateString);
    // @ts-ignore
    return date instanceof Date && !isNaN(date);
}

import {
    WgButton,
    WgContainer,
    WgEnumerationList,
    WgHeader,
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgPaperList,
    WgTypography
} from '../../../../../styles/CustomComponents';
import React from 'react';
import {useHistory, useLocation} from 'react-router';
import {IEnergyOldProviderData} from '../../../../../models/offerInterface';
import {
    Box,
    Card,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Paper
} from '@material-ui/core';
import Logo from '../../../../utils/Logo';
import {formatMoney} from '../../../../utils/Format';

export default function EnergyInfoText() {
    const location = useLocation<IEnergyOldProviderData>();
    const old = location.state;
    const history = useHistory();

    const oldProviderMetaData = {
        'Geschätzter Verbrauch':
            typeof old?.estimatedUsageInKwh !== 'undefined'
                ? `${old?.estimatedUsageInKwh} kWh/Jahr`
                : undefined,
        'Geschätzter Arbeitspreis':
            typeof old?.oldProviderChargeCtPerKwh !== 'undefined'
                ? `${old?.oldProviderChargeCtPerKwh} ct/kWh`
                : undefined,
        'Geschätzter Grundpreis':
            typeof old?.oldProviderMonthlyServiceCharge !== 'undefined'
                ? formatMoney(old?.oldProviderMonthlyServiceCharge)
                : undefined
    };
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Wechselprozess"
                hasBackButton
                backText="Energievertrag wechseln"
            />
            <WgContainer bodyContent>
                <Grid
                    container
                    item
                    direction="column"
                    style={{flex: 1}}
                    wrap="nowrap">
                    <Box paddingX="1rem">
                        <WgTypography
                            gutterBottom
                            color="primary"
                            text="contentInfo">
                            Wie funktioniert unser Wechselprozess?
                        </WgTypography>
                    </Box>
                    <img
                        src="/img/recurringpayment/energy/energy-info-demo.svg"
                        alt="Wechselprozess"
                    />
                    {renderInfoListItem({
                        title: 'Vertragserkennung',
                        description:
                            'Dein bestehender Vertrag wird vollautomatisch über dein Bankkonto oder via manueller Eingabe von dir erfasst.',
                        logoPath:
                            '/img/recurringpayment/energy/energy-info-search.svg'
                    })}
                    {renderInfoListItem({
                        title: 'Tarif-Check',
                        description:
                            'Wir vergleichen deinen Tarif mit über 12.500 Angeboten und finden den optimalen Tarif für dich und deinen Verbrauch.',
                        logoPath:
                            '/img/recurringpayment/energy/energy-info-check.svg'
                    })}
                    {renderOldProviderInfo()}
                    {renderInfoListItem({
                        title: 'Anbieterwechsel',
                        description:
                            'Du entscheidest dich für eine unserer Tarifempfehlungen, du bestätigst kurz deine Daten. Wir kümmern uns im Anschluss um den kompletten Wechselprozess. Du rührst dabei keinen Finger.',
                        logoPath:
                            '/img/recurringpayment/energy/energy-info-switch.svg'
                    })}
                    {renderInfoListItem({
                        title: 'Zurücklehnen & Sparen',
                        description:
                            'Rechtzeitig vor dem nächsten Wechsel erhälst du erneut günstige Angebote.',
                        logoPath:
                            '/img/recurringpayment/energy/energy-info-finished.svg'
                    })}
                    {renderInfoCard()}
                    {renderBackButton()}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function renderInfoListItem(item: {
        title: string;
        description: string;
        logoPath: string;
    }) {
        return (
            <WgListItem
                shadow
                style={{
                    minWidth: '100%',
                    margin:
                        item.title === 'Vertragsart' ? '0 0 0.5rem' : '0.5rem 0'
                }}
                key={item.title}
                background={'paper'}
                paddingRight="1rem">
                <WgListItemIcon>
                    <Logo
                        rounded="4px"
                        defaultImage={item.logoPath}
                        alt="Info-Logo"
                    />
                </WgListItemIcon>
                <WgListItemContent>
                    <Box>
                        <WgTypography
                            gutterBottom
                            color="textPrimary"
                            text="content">
                            <strong>{item.title}</strong>
                        </WgTypography>
                        <WgTypography color="textSecondary" text="info">
                            {item.description}
                        </WgTypography>
                    </Box>
                </WgListItemContent>
            </WgListItem>
        );
    }

    function renderOldProviderInfo() {
        return (
            <Paper style={{margin: '0.5rem 1rem', borderRadius: '4px'}}>
                <ExpansionPanel expanded>
                    <ExpansionPanelSummary
                        aria-controls="old-provider-panel-content"
                        id="old-provider-panel-content">
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            style={{padding: '0 1rem'}}>
                            <WgTypography
                                gutterBottom
                                color="textPrimary"
                                text="content">
                                <strong>Aktueller Anbieter</strong>
                            </WgTypography>
                            <WgTypography color="textSecondary" text="content">
                                {old.oldProviderName}
                            </WgTypography>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{padding: 0}}>
                        <WgPaperList
                            keyValueMapping={oldProviderMetaData}
                            background="default"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Grid
                    container
                    justify="space-between"
                    style={{padding: '1rem'}}>
                    <Grid item xs={6}>
                        <WgTypography color="textSecondary" text="info" noWrap>
                            Bisherige Kosten
                        </WgTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <WgTypography
                            align="right"
                            color="textPrimary"
                            text="content">
                            <strong>
                                {typeof old.oldProviderYearlyCosts !==
                                'undefined'
                                    ? formatMoney(
                                          old.oldProviderYearlyCosts / 12
                                      )
                                    : '-,-- €'}{' '}
                                / Monat
                            </strong>
                        </WgTypography>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    function renderInfoCard() {
        return (
            <Card elevation={2} style={{padding: '1rem', margin: '0.5rem 0'}}>
                <WgEnumerationList
                    noListItemPadding
                    enumerationIcon={
                        <img src="/img/icon-check-rounded.svg" alt="✓" />
                    }
                    background="paper"
                    content={[
                        'Nur wenn du gespart hast, berechnen wir nach zwölf Monaten 20 % deiner Ersparnis als Servicegebühr.',
                        'Wir stoßen den Wechsel-Prozess jedes Jahr automatisch für dich an, damit du ohne Aufwand Jahr für Jahr sparen kannst!'
                    ]}
                />
                <WgTypography
                    gutterTop="1.5rem"
                    color="textSecondary"
                    text="content">
                    Das war's! Es gibt keine versteckten Kosten. Wir verdienen
                    nur Geld wenn du auch sparst.
                </WgTypography>
            </Card>
        );
    }

    function renderBackButton() {
        return (
            <Box
                p="1rem 1rem 0"
                display="flex"
                justifyContent="center"
                maxHeight="fit-content"
                flex={1}>
                <WgButton
                    onClick={() => history.goBack()}
                    variant="contained"
                    color="primary"
                    fullWidth
                    aria-label="back">
                    Zurück
                </WgButton>
            </Box>
        );
    }
}

import {
    Box,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelProps,
    ExpansionPanelSummary,
    Grid,
    IconButton,
    Paper,
    Popover
} from '@material-ui/core';
import React from 'react';
import {ExpandMore, HelpOutline} from '@material-ui/icons';
import {
    WgButton,
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from './index';
import Logo from '../../containers/utils/Logo';
import {makeStyles} from '@material-ui/core/styles';

export interface WgExpansionPanelProps extends ExpansionPanelProps {
    title: string;
    subTitle?: string;
    headerImage?: string;
    currentAmount?: string;
    footerContent?: {
        text?: string;
        amount?: number;
        amountSuffix?: string;
        buttonText?: string;
        buttonLink?: string;
        handleButtonClick?: Function;
        buttonDisabled?: boolean;
        componentProps?: any;
    };
    icon?: React.ReactNode;
    infoPopover?: {text: string};
}

export interface WgExpansionPanelHeaderProps {
    title: string;
    subTitle?: string;
    headerImage?: string;
    icon?: React.ReactNode;
}

export function WgExpansionPanelHeader(props: WgExpansionPanelHeaderProps) {
    return (
        <WgListItem background="transparent">
            <WgListItemIcon>
                {props.headerImage && (
                    <Logo
                        logoPath={props.headerImage}
                        defaultImage={props.headerImage}
                    />
                )}
                {props?.icon ?? ''}
            </WgListItemIcon>
            <WgListItemContent>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <WgTypography
                            gutterBottom
                            color="textPrimary"
                            text="content">
                            {props.title}
                        </WgTypography>
                    </Grid>
                    {props.subTitle && (
                        <Grid item xs={12}>
                            <WgTypography
                                noWrap
                                color="textSecondary"
                                text="subContent">
                                {props.subTitle}
                            </WgTypography>
                        </Grid>
                    )}
                </Grid>
            </WgListItemContent>
        </WgListItem>
    );
}

/**
 * This style ensures that the icon/text part of the Expansion-Header will not grow too big
 * and push the collapse-arrow outside of the parent.
 */
const useSummaryStyles = makeStyles({
    content: {
        width: '75%'
    }
});

export default function WgExpansionPanel(props: WgExpansionPanelProps) {
    let {
        infoPopover,
        classes,
        title,
        subTitle,
        headerImage,
        footerContent,
        currentAmount,
        icon,
        children,
        ...other
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const summaryStyles = useSummaryStyles();

    return (
        <Paper>
            <ExpansionPanel square {...other}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore fontSize="large" />}
                    classes={{
                        content: summaryStyles.content
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <WgExpansionPanelHeader
                        title={title}
                        subTitle={subTitle}
                        headerImage={headerImage}
                        icon={icon}
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{padding: 0}}>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
            {(footerContent || currentAmount) && renderFooterContent()}
        </Paper>
    );

    function renderFooterContent() {
        return (
            <Box
                marginX="1.125rem"
                display="flex"
                flexDirection="column"
                alignItems="center">
                {currentAmount && (
                    <Box
                        marginY="1rem"
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center">
                        <WgTypography color="textSecondary" text="subContent">
                            Neuer Preis
                        </WgTypography>
                        <WgTypography money>{currentAmount}</WgTypography>
                    </Box>
                )}
                {footerContent && (
                    <React.Fragment>
                        <Divider
                            style={{width: '100%'}}
                            light
                            variant="middle"
                        />
                        <Grid
                            style={{marginTop: '1rem', width: '100%'}}
                            container
                            justify="space-between">
                            <Grid
                                item
                                xs={8}
                                container
                                wrap="nowrap"
                                justify="flex-start">
                                {footerContent.text && (
                                    <WgTypography
                                        noWrap
                                        color="textPrimary"
                                        text="content">
                                        {footerContent.text}
                                    </WgTypography>
                                )}
                                {infoPopover && (
                                    <IconButton
                                        aria-owns="info-popover"
                                        aria-haspopup="true"
                                        onClick={handlePopoverOpen}
                                        style={{
                                            padding: 0,
                                            marginLeft: '4px',
                                            marginBottom: '2px'
                                        }}>
                                        <HelpOutline
                                            color="primary"
                                            fontSize="default"
                                        />
                                    </IconButton>
                                )}
                                {renderPopover()}
                            </Grid>
                            {footerContent.amount && (
                                <Grid item xs container justify="flex-end">
                                    <WgTypography money colored withSign>
                                        {footerContent.amount}
                                    </WgTypography>
                                </Grid>
                            )}
                        </Grid>

                        <Box
                            marginY="1rem"
                            width="100%"
                            display="flex"
                            justifyContent="center">
                            <WgButton
                                disabled={footerContent.buttonDisabled}
                                className="button-standard"
                                variant="contained"
                                color="primary"
                                fullWidth
                                aria-label="changevalues"
                                link={footerContent.buttonLink}
                                componentProps={footerContent.componentProps}
                                onClick={() =>
                                    typeof footerContent?.buttonLink ===
                                    'undefined'
                                        ? footerContent &&
                                          footerContent.handleButtonClick &&
                                          footerContent.handleButtonClick()
                                        : {}
                                }>
                                {footerContent && footerContent.buttonText}
                            </WgButton>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        );
    }

    function renderPopover() {
        return (
            <Popover
                style={{maxWidth: '80%', marginTop: '4px'}}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                onBackdropClick={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                <Box p="8px 12px">
                    <WgTypography color="textPrimary" text="info">
                        {infoPopover?.text}
                    </WgTypography>
                </Box>
            </Popover>
        );
    }

    function handlePopoverOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }
}

import React, {Component} from 'react';
import {Box, Grid} from '@material-ui/core';
import './Welcome.scss';
import {connect} from 'react-redux';
import {performLogin} from '../../store/actions/userActions';
import {WgButton, WgTypography} from '../../styles/CustomComponents';

interface IProps {
    performLogin: Function;
}

class Welcome extends Component<IProps, {}> {
    constructor(props: Readonly<IProps>) {
        super(props);

        props.performLogin();
    }

    render() {
        return (
            <div id="Register" className="welcome-component">
                <picture>
                    <source
                        media="(min-width: 960px)"
                        srcSet="/img/background/mountain-lake-bg-original.jpg"
                    />
                    <img
                        src="/img/background/mountain-lake-bg-smartphone.jpg"
                        className="main-image"
                        alt="Mountain Lake Background for SmartPhone"
                    />
                </picture>

                <div
                    className="welcome-container"
                    style={{justifyContent: 'center'}}>
                    <img
                        src="/img/logowechselgott.svg"
                        className="welcome-img"
                        alt="WechselGott Logo"
                    />
                    <Box m="2.25rem 0 0.625rem 0">
                        <WgTypography
                            color="primary"
                            align="center"
                            text="contentInfo"
                            style={{fontWeight: 800, fontSize: '1.625rem'}}>
                            WechselGott
                        </WgTypography>
                    </Box>
                    <div className="welcome-cloud">
                        <WgTypography
                            noWrap
                            align="center"
                            color="primary"
                            header="uppercaseSpacing"
                            style={{
                                fontWeight: 500,
                                letterSpacing: '3px',
                                color: '#3ccfc7'
                            }}>
                            {' '}
                            Der einzig wahre
                            <br />
                            Vertragsmanager{' '}
                        </WgTypography>
                    </div>
                </div>
                <Grid container direction="column" alignItems="center">
                    <Grid
                        zeroMinWidth
                        wrap="nowrap"
                        item
                        xs
                        style={{padding: '0 1.125rem'}}
                        container
                        direction="column"
                        alignItems="center"
                        spacing={2}>
                        <Grid item container justify="center">
                            <WgButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                aria-label="signup"
                                link="/signup/1">
                                Jetzt starten
                            </WgButton>
                        </Grid>
                        <Grid item container justify="center">
                            <WgButton
                                variant="outlined"
                                color="primary"
                                fullWidth
                                aria-label="info"
                                link="/info">
                                Mehr erfahren
                            </WgButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs
                        style={{padding: '1rem 1.125rem 1.5rem 1.125rem'}}
                        container
                        justify="center"
                        alignItems="flex-end">
                        <Box
                            p="0.5rem"
                            flex={1}
                            display="flex"
                            justifyContent="center">
                            <WgButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                aria-label="login"
                                link="/login">
                                Einloggen
                            </WgButton>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {performLogin})(Welcome);

import React, {useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {connect, useDispatch} from 'react-redux';
import AccountList from './AccountList';
import Dashboards from './dashboard/Dashboards';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../styles/CustomComponents';
import {IMessage} from '../../../models/notificationInterface';
import Message from '../../utils/Message';
import {performCloseMessage} from '../../../store/actions/notificationActions';

export interface AccountsProps {
    hasAccounts?: boolean;
    message?: IMessage;
}

function Accounts({hasAccounts, message}: AccountsProps) {
    const [selectedStep, handleStepChange] = useState('month'); // this needs to be in accounts because it changes the Dashboards props --> selector is fired in Dashboards based on props
    const dispatch = useDispatch();
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Meine Konten"
                buttonRight={{
                    icon: '/img/navigation/add.svg',
                    link: 'accounts/add'
                }}
            />
            {hasAccounts ? (
                <WgContainer bodyContent>
                    <Box width="100%" overflow={{xs: 'hidden', md: 'visible'}}>
                        {message ? (
                            <Message
                                message={message}
                                onClose={() =>
                                    dispatch(
                                        performCloseMessage(
                                            message.notificationId
                                        )
                                    )
                                }
                            />
                        ) : (
                            <Dashboards
                                selectedStep={selectedStep}
                                handleStepChange={handleStepChange}
                            />
                        )}
                        <AccountList isCustomizable={false} />
                    </Box>
                </WgContainer>
            ) : (
                renderNoAccounts()
            )}
        </WgContainer>
    );

    function renderNoAccounts() {
        return (
            <WgContainer
                bodyContent
                disableGutters={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                <Grid container direction="column" item xs wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '0.5 1 0%'}}
                        container
                        justify="center"
                        alignItems="flex-end">
                        <Box m="1rem 0" paddingX="4px">
                            <WgTypography
                                text="contentInfo"
                                color="primary"
                                align="center">
                                Noch keine Konten hier...
                            </WgTypography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        style={{flex: '0.5 1 0%'}}
                        container
                        alignItems="flex-start">
                        <WgTypography
                            text="content"
                            color="textSecondary"
                            align="center">
                            WechselGott erkennt aus deinen Umsatzdaten welche
                            Verträge du hast und wo du zu viel Geld ausgibst.
                            <br />
                            <br />
                            Verknüpfe jetzt dein Konto und finde heraus, wie
                            viel du sparen kannst!
                        </WgTypography>
                    </Grid>
                    <Grid item container xs>
                        <Box
                            flex={1}
                            display="flex"
                            justifyContent="center"
                            alignItems={{xs: 'flex-end', md: 'flex-start'}}>
                            <WgButton
                                aria-label="signup"
                                variant="contained"
                                color="primary"
                                fullWidth
                                link={'/accounts/add'}>
                                <img
                                    style={{marginRight: '0.5rem'}}
                                    src="/img/navigation/add-white.svg"
                                    alt=""
                                />
                                Konto verknüpfen
                            </WgButton>
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        hasAccounts: state.accounts?.allAccounts.length > 0,
        message: state.notification?.messages[0]
    };
}

export default connect(mapStateToProps, {})(Accounts);

import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import {
    WgCurrencyInput,
    WgToggleSignButton
} from '../../styles/CustomComponents';

export interface AmountSignInputProps {
    handleAmountChange: Function;
    amount: number;
}

export default function AmountSignInput({
    handleAmountChange,
    amount
}: AmountSignInputProps) {
    const [negative, setNegative] = useState(amount <= 0);

    return (
        <Grid
            item
            container
            justify="space-between"
            wrap="nowrap"
            alignItems="center">
            <Grid item xs={4} md={2}>
                <WgToggleSignButton
                    initial={negative}
                    handleChange={setNegative}
                    orientation="vertical"
                    size="small"
                    variant="text"
                />
            </Grid>
            <Grid item xs={8} md={10}>
                <WgCurrencyInput
                    colored
                    amount={amount}
                    negative={negative}
                    handleAmountChange={handleAmountChange}
                />
            </Grid>
        </Grid>
    );
}

import {createAction} from 'redux-actions';
import {api, getAuthConfig, performApiCall} from './api';
import {FETCH_CATEGORIES_SUCCESS} from './actionTypes';

export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);

export const fetchCategories: any = () =>
    performApiCall(
        performFetchCategories(),
        'Kategorien können vorübergehend nicht geladen werden.'
    );

export const performFetchCategories: Function = () => async (
    dispatch: Function,
    getState: Function
) => {
    if (!getState()?.user?.token) {
        return;
    }
    const token = getState().user.token;
    const response = await api.get(
        '/categories',
        getAuthConfig(token, {Accept: 'application/json'})
    );
    dispatch(fetchCategoriesSuccess(response.data));
};

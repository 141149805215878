import React, {useState} from 'react';
import {Box, Grid, Paper} from '@material-ui/core';
import {connect} from 'react-redux';
import {userActions} from '../../../../store/actions';
import {validateEmail} from '../../../utils/Validate';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgInputField,
    WgTypography
} from '../../../../styles/CustomComponents';

const {performResetPassword} = userActions;

interface IProps {
    performResetPassword: Function;
}

function Reset({performResetPassword}: IProps) {
    const [email, setEmail] = useState('');

    return (
        <WgContainer publicBodyApp backgroundImage>
            <WgHeader
                headerText="Passwort zurücksetzen"
                hasBackButton
                backText="Login"
            />
            <WgContainer publicBodyContent disableGutters={false}>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    style={{flex: 1, maxWidth: '600px', alignSelf: 'center'}}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: 1}}
                        container
                        direction="column"
                        justify="space-evenly">
                        <Grid item>
                            <WgTypography
                                gutterTop="1rem"
                                gutterBottom
                                color="textSecondary"
                                text="info">
                                Gib hier deine E-Mail-Adresse ein. Wir senden
                                dir einen Code an deine hinterlegte
                                Telefonnummer mit dem du dein Passwort erneuern
                                kannst.
                            </WgTypography>
                        </Grid>

                        <Grid
                            item
                            style={{flex: 1}}
                            container
                            alignItems="center">
                            <Box
                                display="flex"
                                marginTop="1rem"
                                style={{flex: 1}}>
                                <Paper elevation={2} style={{flex: 1}}>
                                    <form onSubmit={resetPassword}>
                                        <WgInputField
                                            handleChange={updateEmail}
                                            label="E-Mail"
                                            type="email"
                                            name="email"
                                        />
                                    </form>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item container>
                        <Box
                            display="flex"
                            justifyContent="center"
                            m="1.5rem 0"
                            flex={1}>
                            <WgButton
                                style={{maxWidth: '100%'}}
                                variant="contained"
                                color="primary"
                                defaultButton
                                aria-label="next"
                                disabled={!validateEmail(email)}
                                onClick={resetPassword}>
                                Code senden
                            </WgButton>
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function updateEmail(event: any) {
        return setEmail(event.target.value);
    }

    function resetPassword(event: any) {
        event.preventDefault();
        return performResetPassword(email);
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {performResetPassword})(Reset);

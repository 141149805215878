import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Logo from '../../../utils/Logo';
import MyLink from '../../../utils/MyLink';
import {WgTypography} from '../../../../styles/CustomComponents';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        media: {
            height: '42px',
            width: '80%',
            margin: '2vh auto 1vh auto',
            maxWidth: '100%',
            maxHeight: '100%',
            backgroundSize: 'contain'
        },
        cardStyle: {
            padding: theme.spacing(1),
            paddingTop: '0.5vh'
        }
    })
);

export interface WgCardProps {
    logoPath?: string;
    name: string;
    isPriceVisible?: boolean;
    redirectLink?: string;
    price?: string;
    id?: number;
    linkState?: any;
    handleChange?: (value: any) => void;
}

export default function WechselCard(props: WgCardProps) {
    const classes = useStyles();

    const {
        logoPath,
        name,
        isPriceVisible,
        redirectLink,
        linkState,
        price,
        handleChange
    } = props;
    const Link = MyLink(redirectLink, undefined, linkState);
    return (
        <Card>
            {typeof redirectLink !== 'undefined' ? (
                <Link style={{textDecoration: 'none', color: 'inherit'}}>
                    {renderContent()}
                </Link>
            ) : (
                renderContent()
            )}
        </Card>
    );

    function renderContent() {
        return (
            <CardActionArea
                onClick={() => handleChange && handleChange(props.id)}>
                <Box display="flex" justifyContent="center">
                    <Logo
                        className={classes.media}
                        defaultImage={'/img/bank.svg'}
                        logoPath={logoPath}
                        maxHeight={'100%'}
                        maxWidth={'100%'}
                    />
                </Box>
                <CardContent className={classes.cardStyle}>
                    <WgTypography
                        align="center"
                        noWrap
                        color="textPrimary"
                        text="subContent">
                        {name}
                    </WgTypography>
                </CardContent>
                {isPriceVisible && (
                    <Box
                        bgcolor="secondary.main"
                        p="0.25rem 0.5rem"
                        color="white">
                        <WgTypography color="inherit" text="subContent">
                            {`ab ${price} pro  Monat`}
                        </WgTypography>
                    </Box>
                )}
            </CardActionArea>
        );
    }
}

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Navigation from './Navigation';
import Dialog from './Dialog';
import TestComponent from './TestComponent';
import {
    Account,
    Accounts,
    AccountTurnover,
    AddBank,
    AddPolicyController,
    BrokerMandate,
    BrokerMandateEdit,
    BrokerMandateText,
    Cancellation,
    CancelledContract,
    Contact,
    ContractAdditionController,
    Depot,
    Documents,
    Energy,
    EnergyController,
    EnergyInfoText,
    FinancialAnalysis,
    Info,
    CarInsuranceOfferSelection,
    CarInsuranceOfferDetails,
    Login,
    Password,
    PrivacyLoggedIn,
    PrivacyNotLoggedIn,
    Private,
    PrivateEdit,
    ProductDetails,
    ProductDocuments,
    ProductForm,
    CarInsuranceOneProduct,
    CarInsuranceController,
    CarSwitchInsuranceController,
    VehicleHolder,
    CarInsuranceNecessaryData,
    CarInsuranceNoClaimDiscount,
    CarInsuranceNoClaimDiscountSelection,
    CarInsurancePayment,
    CarInsuranceProductType,
    CarInsuranceCompletion,
    Products,
    ProductsNew,
    Profile,
    Recurringpayment,
    Recurringpayments,
    RecurringpaymentTurnover,
    RecurringpaymentTurnovers,
    ResetEmail,
    ResetPassword,
    Signup,
    TermsLoggedIn,
    TermsNotLoggedIn,
    Welcome
} from '../views';
import Loading from './Loading';
import Redirector from '../utils/Redirector';
import {CssBaseline} from '@material-ui/core';
import WgSnackBar from './WgSnackBar';
import {getConsent} from '../utils/Cookie';
import AccountSelection from '../utils/AccountSelection';
import CategorySelection from '../utils/CategorySelection';
import {useDispatch} from 'react-redux';
import {getBankingStateAsDialog} from '../../store/actions/bankActions';
import {push} from 'connected-react-router';

export default function App() {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <CssBaseline />
            <Dialog />
            <WgSnackBar />
            <Route exact path="/test" component={TestComponent} />
            <Redirector
                publicViews={renderPublic()}
                protectedViews={renderProtected()}
            />
            <Loading />
            <Navigation />
        </React.Fragment>
    );

    function renderPublic() {
        return (
            <Switch>
                <Route exact path="/" component={Welcome} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/resetpassword/1" component={ResetEmail} />
                <Route
                    exact
                    path="/resetpassword/2"
                    component={ResetPassword}
                />
                <Route exact path="/signup/:step" component={Signup} />
                <Route exact path="/info" component={Info} />
                <Route exact path="/info/terms" component={TermsNotLoggedIn} />
                <Route
                    exact
                    path="/info/privacy"
                    component={PrivacyNotLoggedIn}
                />
                <Route
                    render={() => <Redirect to={`/?consent=${getConsent()}`} />}
                />
            </Switch>
        );
    }

    function renderProtected() {
        return (
            <Switch>
                <Route
                    exact
                    path="/recurringpayments"
                    component={Recurringpayments}
                />
                <Route
                    exact
                    path="/recurringpayments/cancelled"
                    component={CancelledContract}
                />
                <Route
                    exact
                    path="/recurringpayments/cancellation/:recurringpaymentId"
                    component={Cancellation}
                />
                <Route
                    exact
                    path="/recurringpayments/details/:recurringpaymentId"
                    component={Recurringpayment}
                />
                <Route
                    exact
                    path="/recurringpayments/details/:recurringpaymentId/edit/:categoryId"
                    component={CategorySelection}
                />
                <Route
                    exact
                    path="/recurringpayments/transactions/:recurringpaymentId"
                    component={RecurringpaymentTurnovers}
                />
                <Route
                    exact
                    path="/recurringpayments/transactions/:recurringpaymentId/:turnover"
                    component={RecurringpaymentTurnover}
                />

                <Route
                    exact
                    path="/recurringpayments/add/:currentStep"
                    component={ContractAdditionController}
                />

                <Route
                    exact
                    path="/recurringpayments/:recurringPaymentId/add/energy/:currentStep"
                    component={EnergyController}
                />

                {/*used for manually adding a contract because there is no recurring payment*/}
                <Route
                    exact
                    path="/recurringpayments/add/energy/:currentStep"
                    component={EnergyController}
                />
                <Route
                    exact
                    path="/recurringpayments/energy/info"
                    component={EnergyInfoText}
                />

                <Route exact path="/accounts" component={Accounts} />
                <Route exact path="/accounts/add" component={AddBank} />
                <Route
                    exact
                    path="/accounts/select"
                    component={AccountSelection}
                />

                <Route
                    exact
                    path="/accounts/add/:bankSearchString/:id"
                    component={AddBank}
                />
                <Route
                    exact
                    path="/accounts/details/:account"
                    component={Account}
                />
                <Route exact path="/depot/details/:depot" component={Depot} />
                <Route
                    exact
                    path="/accounts/details/:account/:turnover"
                    component={AccountTurnover}
                />

                <Route exact path="/profile" component={Profile} />
                <Route exact path="/profile/energy" component={Energy} />
                <Route exact path="/profile/private" component={Private} />
                <Route
                    exact
                    path="/profile/private/edit"
                    component={PrivateEdit}
                />
                <Route exact path="/profile/documents" component={Documents} />
                <Route
                    exact
                    path="/profile/brokermandate"
                    component={BrokerMandate}
                />
                <Route
                    exact
                    path="/profile/brokermandateedit"
                    component={BrokerMandateEdit}
                />
                <Route
                    exact
                    path="/profile/brokermandatetext"
                    component={BrokerMandateText}
                />
                <Route exact path="/profile/terms" component={TermsLoggedIn} />
                <Route
                    exact
                    path="/profile/privacy"
                    component={PrivacyLoggedIn}
                />
                <Route
                    exact
                    path="/profile/financialAnalysis"
                    component={FinancialAnalysis}
                />
                <Route exact path="/profile/password" component={Password} />

                <Route exact path="/products" component={Products} />
                <Route
                    exact
                    path="/products/existing/:currentStep"
                    component={AddPolicyController}
                />
                <Route
                    exact
                    path="/products/existing/:categoryId/:currentStep"
                    component={AddPolicyController}
                />
                <Route exact path="/products/new" component={ProductsNew} />
                <Route
                    exact
                    path="/products/purchase/car/proposal"
                    component={CarInsuranceOfferSelection}
                />
                <Route
                    exact
                    path="/products/purchase/car/offerdetails"
                    component={CarInsuranceOfferDetails}
                />
                <Route
                    exact
                    path="/products/available/:product"
                    component={ProductForm}
                />
                <Route
                    exact
                    path="/products/purchase/car"
                    component={CarInsuranceOneProduct}
                />
                <Route
                    exact
                    path="/products/purchase/car/:currentStep"
                    component={CarInsuranceController}
                />
                <Route
                    exact
                    path="/products/:productId/purchase/car/switch/:currentStep"
                    component={CarSwitchInsuranceController}
                />
                <Route
                    exact
                    path="/products/purchase/car/vehicleholder"
                    component={VehicleHolder}
                />
                <Route
                    exact
                    path="/products/purchase/car/necessarydata"
                    component={CarInsuranceNecessaryData}
                />
                <Route
                    exact
                    path="/products/purchase/car/noclaimdiscount"
                    component={CarInsuranceNoClaimDiscount}
                />
                <Route
                    exact
                    path="/products/purchase/car/noclaimdiscountselection"
                    component={CarInsuranceNoClaimDiscountSelection}
                />
                <Route
                    exact
                    path="/products/purchase/car/payment"
                    component={CarInsurancePayment}
                />
                <Route
                    exact
                    path="/products/purchase/car/kindofinsurance"
                    component={CarInsuranceProductType}
                />
                <Route
                    exact
                    path="/products/purchase/car/completion"
                    component={CarInsuranceCompletion}
                />
                <Route
                    exact
                    path="/products/details/:productId"
                    component={ProductDetails}
                />
                <Route
                    exact
                    path="/products/documents/:productId"
                    componente={ProductDocuments}
                />

                <Route exact path="/profile/contact" component={Contact} />
                <Route
                    render={props => {
                        dispatch(push('/accounts'));
                        if (props?.location?.search?.length !== 0) {
                            const search = new URLSearchParams(
                                props.location.search
                            );
                            const dialogId = search.get('dialogId');
                            const action = search.get('action');
                            dispatch(getBankingStateAsDialog(dialogId, action));
                        }
                        return (
                            <Redirect
                                to={`/accounts?consent=${getConsent()}`}
                            />
                        );
                    }}
                />
            </Switch>
        );
    }
}

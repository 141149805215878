import React, {useState} from 'react';
import {connect} from 'react-redux';
import {WgTypography} from '../../styles/CustomComponents';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import {Box, Button, Divider, Hidden, Link, useTheme} from '@material-ui/core';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {withStyles} from '@material-ui/core/styles';
import {setCookie} from './Cookie';
import {ExpandMore} from '@material-ui/icons';

const ExpansionPanel = withStyles({
    root: {
        boxShadow: 'none'
    },
    expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(() => ({
    root: {
        minHeight: '0px',

        '&$expanded': {
            minHeight: '0px'
        }
    },
    content: {
        justifyContent: 'flex-end',
        margin: '8px 12px',
        '&$expanded': {
            margin: '8px 12px !important'
        }
    },
    expanded: {
        minHeight: 0
    },
    expandIcon: {
        marginRight: '5px',
        padding: '0px'
    }
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
    root: {
        padding: '20px 0px 0px 0px'
    }
}))(MuiExpansionPanelDetails);

interface IProps {
    onClose: Function;
}

function CookieBanner(props: IProps) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const linkStyle = {
        color: theme.palette.text.secondary,
        textDecoration: 'none'
    };
    return (
        <Box m="20px 20px 0 20px">
            <WgTypography noWrap text="contentInfo" color="primary">
                Cookie-Einstellungen
            </WgTypography>

            <Box marginTop="0.75rem" marginBottom="1.25rem">
                <WgTypography color="textPrimary" text="sentenceSmall">
                    Wir setzen auf unserer Website Cookies ein. Einige von ihnen
                    sind essenziell, während andere uns helfen unser
                    Onlineangebot zu verbessern und wirtschaftlich zu betreiben.
                    Du kannst dies akzeptieren oder per Klick auf die
                    Schaltfläche "Nur essenzielle Cookies erlauben" ablehnen,
                    sowie diese Einstellungen jederzeit aufrufen und Cookies
                    auch nachträglich jederzeit abwählen (im Fußbereich unserer
                    Website). Nähere Hinweise erhältst du in unserer
                    Datenschutzerklärung.
                </WgTypography>
            </Box>
            <Box marginBottom="1rem">
                <WgTypography
                    color="textSecondary"
                    text="link"
                    component="span">
                    <Link
                        style={linkStyle}
                        target=" _blank"
                        href="https://wechselgott.com/AGB/">
                        AGB
                    </Link>{' '}
                    &nbsp; | &nbsp;{' '}
                    <Link
                        style={linkStyle}
                        target=" _blank"
                        href="https://wechselgott.com/Datenschutz/">
                        Datenschutz
                    </Link>{' '}
                    &nbsp; | &nbsp;{' '}
                    <Link
                        style={linkStyle}
                        target=" _blank"
                        href="https://wechselgott.com/impressum/">
                        Impressum
                    </Link>
                </WgTypography>
            </Box>

            <ExpansionPanel
                onChange={(_: any, expanded: boolean) => setOpen(expanded)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <WgTypography
                        color="textPrimary"
                        text="content"
                        fontSize="1.125rem">
                        Details
                    </WgTypography>
                </ExpansionPanelSummary>
                <Divider light />
                <ExpansionPanelDetails>
                    <WgTypography
                        gutterBottom
                        color="textPrimary"
                        text="content">
                        Präferenz-Cookies
                    </WgTypography>
                    <WgTypography color="textPrimary" text="sentenceSmall">
                        Präferenz-Cookies ermöglichen einer Webseite sich an
                        Informationen zu erinnern, die die Art beeinflussen, wie
                        sich eine Webseite verhält oder aussieht, wie z. B. Ihre
                        bevorzugte Sprache oder die Region in der Sie sich
                        befinden.
                    </WgTypography>
                    <WgTypography
                        gutterBottom
                        gutterTop="1.25rem"
                        color="textPrimary"
                        text="content">
                        Statistik-Cookies
                    </WgTypography>
                    <WgTypography color="textPrimary" text="sentenceSmall">
                        Statistik-Cookies helfen Webseiten-Besitzern zu
                        verstehen, wie Besucher mit Webseiten interagieren,
                        indem Informationen anonym gesammelt und gemeldet
                        werden.
                    </WgTypography>
                    <WgTypography
                        gutterBottom
                        gutterTop="1.25rem"
                        color="textPrimary"
                        text="content">
                        Marketing-Cookies
                    </WgTypography>
                    <WgTypography color="textPrimary" text="sentenceSmall">
                        Marketing-Cookies werden verwendet, um Besuchern auf
                        Webseiten zu folgen. Die Absicht ist, Anzeigen zu
                        zeigen, die relevant und ansprechend für den einzelnen
                        Benutzer sind und daher wertvoller für Publisher und
                        werbetreibende Drittparteien sind.
                    </WgTypography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Hidden xsUp={open}>
                <Divider light />
            </Hidden>
            <Box
                display="flex"
                justifyContent="center"
                m="1.5rem 0"
                width="100%">
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    aria-label="accept"
                    onClick={() => saveChanges('all')}>
                    Alle Cookies
                </Button>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                m="1.5rem 0"
                width="100%">
                <Button
                    variant="outlined"
                    fullWidth
                    aria-label="only essencials"
                    onClick={() => saveChanges('essentials')}>
                    Essenzielle Cookies
                </Button>
            </Box>
        </Box>
    );

    function saveChanges(consent: string) {
        setCookie('consent', consent, 30);
        props.onClose();
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {})(CookieBanner);

import {Box, Divider, Grid} from '@material-ui/core';
import {WgInputField} from '../../../../../styles/CustomComponents';
import React from 'react';
import {inputNotFilled} from './index';

export interface NameInputsProps {
    handleChange: (event: React.ChangeEvent<any>) => void;
    firstName?: string;
    lastName?: string;
    validationEnabled?: boolean;
}

export function NameInputs({
    handleChange,
    firstName,
    lastName,
    validationEnabled
}: NameInputsProps) {
    return (
        <Grid container wrap="nowrap">
            <Grid style={{width: '100%'}} item xs={6}>
                <WgInputField
                    required
                    error={validationEnabled && inputNotFilled(firstName)}
                    helperText={
                        validationEnabled &&
                        inputNotFilled(firstName) &&
                        'Diese Eingabe ist erforderlich.'
                    }
                    handleChange={handleChange}
                    label="Vorname"
                    name="firstName"
                    type="text"
                    defaultValue={firstName ?? ''}
                />
            </Grid>
            <Box display="flex" alignItems="center">
                <Divider orientation="vertical" light style={{height: '75%'}} />
            </Box>
            <Grid style={{width: '100%'}} item>
                <WgInputField
                    required
                    error={validationEnabled && inputNotFilled(lastName)}
                    helperText={
                        validationEnabled &&
                        inputNotFilled(lastName) &&
                        'Diese Eingabe ist erforderlich.'
                    }
                    handleChange={handleChange}
                    label="Nachname"
                    name="lastName"
                    type="text"
                    defaultValue={lastName ?? ''}
                />
            </Grid>
        </Grid>
    );
}

import React from 'react';
import {Divider, Grid} from '@material-ui/core';
import {WgRadioGroup} from '../../../../../styles/CustomComponents';
import {
    formatMeterType,
    MeterType
} from '../../../../../models/productInterface';

export interface EnergyRadiosProps {
    handleChange: (event: React.ChangeEvent<any>) => void;
    meterType: MeterType;
    isCommercial: boolean;
    disabled?: boolean;
    divider?: boolean;
}

export default function EnergyRadios({
    handleChange,
    meterType,
    isCommercial,
    disabled,
    divider
}: EnergyRadiosProps) {
    const commercialGroup = [
        {
            label: 'Gewerblich',
            value: 'true'
        },
        {
            label: 'Privat',
            value: 'false'
        }
    ];

    const meterTypeGroup = [
        {
            label: formatMeterType(MeterType.Gas),
            value: MeterType.Gas
        },
        {
            label: formatMeterType(MeterType.Electricity),
            value: MeterType.Electricity
        }
    ];

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <WgRadioGroup
                    disabled={disabled}
                    row
                    header="Nutzung des Vertrags"
                    handleChange={handleChange}
                    name="isCommercial"
                    selected={String(isCommercial)}
                    group={commercialGroup}
                />
            </Grid>
            {divider && (
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
            )}
            <Grid item xs={12}>
                <WgRadioGroup
                    disabled={disabled}
                    row
                    header="Vertragsart"
                    handleChange={handleChange}
                    name="meterType"
                    selected={meterType}
                    group={meterTypeGroup}
                />
            </Grid>
        </React.Fragment>
    );
}

import {Euro} from '@material-ui/icons';
import {WgInputField} from './index';
import React, {useEffect, useState} from 'react';
import {Box, BoxProps, useTheme} from '@material-ui/core';
import {inputNotFilled} from '../../containers/views/recurringpayments/change/Components';

export interface WgCurrencyInputProps extends BoxProps {
    handleAmountChange: Function;
    amount: number;
    negative?: boolean;
    colored?: boolean;
    zeroNotAllowed?: boolean;
}

export default function WgCurrencyInput({
    amount,
    handleAmountChange,
    negative,
    colored,
    zeroNotAllowed,
    ...boxProps
}: WgCurrencyInputProps) {
    const theme = useTheme();

    const [amountString, setAmountString] = useState(
        (Math.round(Math.abs(amount) * 100) / 100).toFixed(2)
    );

    useEffect(() => {
        handleAmountChange(negative ? -1 * Math.abs(amount) : Math.abs(amount));
    }, [negative, handleAmountChange, amount]);

    return (
        <Box {...boxProps}>
            <WgInputField
                number
                fullWidth
                required
                error={inputNotFilled(
                    zeroNotAllowed && amount <= 0 ? '' : amount
                )}
                helperText={
                    inputNotFilled(
                        zeroNotAllowed && amount <= 0 ? '' : amount
                    ) && 'Diese Eingabe ist erforderlich.'
                }
                name="amount"
                label="Beitrag"
                type="currency"
                onFocus={() => (amount === 0 ? setAmountString('') : {})}
                onBlur={addDecimals}
                handleChange={handleInputChange}
                defaultValue={amountString}
                InputProps={{
                    style: {
                        color:
                            amount !== 0
                                ? colored
                                    ? negative
                                        ? theme.palette.error.main
                                        : theme.palette.secondary.main
                                    : theme.palette.text.primary
                                : theme.palette.error.main
                    },
                    endAdornment: (
                        <Euro
                            fontSize="small"
                            color="disabled"
                            style={{
                                paddingRight: 0,
                                paddingLeft: '4px',
                                marginTop: '4px'
                            }}
                        />
                    )
                }}
            />
        </Box>
    );

    function handleInputChange(event: React.ChangeEvent<any>) {
        let value = event.target.value;
        setAmountString(String(value) ?? '');

        value = Number(value);
        value = negative ? -1 * Math.abs(value) : value;
        handleAmountChange(value);
    }

    function addDecimals(event: React.ChangeEvent<any>) {
        let value: string | number = convertToNumber(event.target.value);
        value = (Math.round(value * 100) / 100).toFixed(2);
        setAmountString(value);
    }

    function convertToNumber(value: string): number {
        // remove thousand separator
        let val: string | number = value.replace(/\./g, '');

        // replace decimal seperator
        return Number(val.replace(',', '.') ?? 0);
    }
}

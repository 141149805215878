let RGA = require('react-ga');

function initReactGA() {
    const trackingCode =
        document.location.host === process.env.REACT_APP_HOST_PROD
            ? process.env.REACT_APP_GA
            : process.env.REACT_APP_GA_TEST;
    RGA.initialize(trackingCode);
    RGA.set({anonymizeIp: true});
}

export function initTracking() {
    initReactGA();

    const nextPage = window.location.pathname + window.location.search;
    RGA.pageview(nextPage);
}

export const ReactGA = {
    ga: function() {
        return window.ga ? RGA.ga.apply(this, arguments) : null;
    },
    set: function() {
        return window.ga ? RGA.set.apply(this, arguments) : null;
    },
    send: function() {
        return window.ga ? RGA.send.apply(this, arguments) : null;
    },
    pageview: function() {
        return window.ga ? RGA.pageview.apply(this, arguments) : null;
    },
    modalview: function() {
        return window.ga ? RGA.modalview.apply(this, arguments) : null;
    },
    timing: function() {
        return window.ga ? RGA.timing.apply(this, arguments) : null;
    },
    event: function() {
        arguments[0] = JSON.parse(
            JSON.stringify(arguments[0]).replace(/@/g, '')
        );
        return window.ga ? RGA.event.apply(this, arguments) : null;
    },
    exception: function() {
        return window.ga ? RGA.exception.apply(this, arguments) : null;
    }
};

import {createDeepEqualSelector} from './utils';
import {
    ICarInsuranceCompletionResponse,
    ICarInsuranceProductFullData,
    ICarInsuranceProductRequestData,
    ICarInsuranceSummary,
    ICarInsuranceVehicleDataDetails,
    IDriverData,
    IPaymentData,
    IPolicyData,
    IPolicyFormData,
    IPolicyRequestData,
    IPreviousPolicyData,
    IProposal,
    IVehicleData,
    IVehicleHolder,
    PaymentMethod
} from '../../../models/productInterface';
import {formatOneInsuranceTariffName} from '../../../containers/utils/Format';

export const getCarInsurance = (state: any) => state.carInsurance;
export const getAccounts = (state: any) => state.accounts.allAccounts;
export const getVehicleData = (state: any) =>
    getCarInsurance(state)?.vehicleData;
export const getDriverData = (state: any) =>
    getCarInsurance(state)?.vehicleHolder;
export const getCarInsuranceProposal = (state: any) =>
    getCarInsurance(state)?.proposal;
export const getPolicyData = (state: any) => getCarInsurance(state)?.policyData;
export const getSubmitResponse = (state: any) =>
    getCarInsurance(state)?.submitResponse;
export const getVehicleHolderData = (state: any) =>
    getCarInsurance(state)?.vehicleHolder;
export const getCarInsuranceOptions = (state: any) =>
    getCarInsurance(state).options;
export const getVehicleDataDetails = (state: any) =>
    getCarInsurance(state)?.vehicleDataDetails;
export const getNoClaimDiscount = (state: any) =>
    getCarInsurance(state)?.noClaimDiscount;
export const getCarInsurancePayment = (state: any) =>
    getCarInsurance(state)?.paymentData;
export const getPreviousPolicyData = (state: any) =>
    getCarInsurance(state)?.previousPolicyData;

export const carInsuranceRequestDataSelector = createDeepEqualSelector(
    [
        getVehicleData,
        getCarInsurance,
        getPolicyData,
        getVehicleHolderData,
        getVehicleDataDetails,
        getNoClaimDiscount
    ],
    (
        vehicleData: IVehicleData,
        carInsuranceData: ICarInsuranceProductFullData,
        policyData: IPolicyData,
        vehicleHolder: IVehicleHolder,
        vehicleDataDetails: ICarInsuranceVehicleDataDetails
    ) => {
        const policyRequestData = createPolicyRequestData(policyData);
        const returnData = {
            vehicleData: {
                ...vehicleData,
                kmPerYear: vehicleDataDetails.kmPerYear
            },
            calculation: {
                id: carInsuranceData.proposalSelectedId
            },
            policyData: {
                ...policyRequestData,
                startDate: policyData.startDate
            },
            driverData: {
                otherDriver: vehicleDataDetails.otherDriver,
                youngestDriverAge: vehicleDataDetails.youngestDriverAge,
                insuranceHolder: {
                    ...vehicleHolder
                }
            } as IDriverData,
            paymentData: {
                paymentMethod: carInsuranceData.paymentData.paymentMethod,
                iban: carInsuranceData.paymentData.iban,
                accountHolderName:
                    carInsuranceData.paymentData.accountHolderName
            } as IPaymentData
        } as ICarInsuranceProductRequestData;

        return returnData;
    }
);

export const carInsuranceSwitchDataSelector = createDeepEqualSelector(
    [
        getVehicleData,
        getCarInsurance,
        getPreviousPolicyData,
        getPolicyData,
        getVehicleHolderData,
        getVehicleDataDetails,
        getNoClaimDiscount
    ],
    (
        vehicleData: IVehicleData,
        carInsuranceData: ICarInsuranceProductFullData,
        previousPolicyData: IPreviousPolicyData,
        policyData: IPolicyData,
        vehicleHolder: IVehicleHolder,
        vehicleDataDetails: ICarInsuranceVehicleDataDetails
    ) => {
        const policyRequestData = createPolicyRequestData(policyData);

        const returnData = {
            vehicleData: {
                ...vehicleData,
                kmPerYear: vehicleDataDetails.kmPerYear
            },
            previousPolicyData: {
                insurer: previousPolicyData.insurer,
                contractId: previousPolicyData.contractId
            } as IPreviousPolicyData,
            policyData: {
                ...policyRequestData,
                startDate: policyData.startDate,
                amount: policyData.amount,
                paymentInterval: policyData.paymentInterval
            },
            driverData: {
                otherDriver: vehicleDataDetails.otherDriver,
                youngestDriverAge: vehicleDataDetails.youngestDriverAge,
                insuranceHolder: {
                    ...vehicleHolder
                }
            } as IDriverData,
            paymentData: {
                paymentMethod: PaymentMethod.InApp,
                iban: carInsuranceData.paymentData.iban,
                accountHolderName:
                    carInsuranceData.paymentData.accountHolderName
            } as IPaymentData
        };

        return returnData;
    }
);

export const carInsuranceProposalRequestDataSelector = createDeepEqualSelector(
    [getVehicleData, getDriverData, getPolicyData],
    (
        vehicleData: IVehicleData,
        driverData: IDriverData,
        policyData: IPolicyData
    ) => {
        const policyRequestData = createPolicyRequestData(policyData);

        const returnData = {
            vehicleData: {
                tsn: vehicleData.tsn,
                hsn: vehicleData.hsn,
                kmPerYear: vehicleData.kmPerYear,
                firstRegistrationDate: vehicleData.firstRegistrationDate,
                firstInsuredDate: vehicleData.firstInsuredDate
            },
            driverData: driverData,
            policyData: policyRequestData
        };

        return returnData;
    }
);

export const policyFormDataSelector = createDeepEqualSelector([], () => {
    const productType = [
        'vehicle-liability',
        'partial-comprehensive',
        'fully-comprehensive'
    ];
    const deductiblePartial = [0, 150, 300, 500, 1000];
    const deductibleFull = [...deductiblePartial, 2500];
    const noClaimDiscount = ['S', 'M', '0', 'SF1/2'];
    for (let index = 1; index <= 50; index++) {
        noClaimDiscount.push(`SF${index}`);
    }

    const formData = {
        productType: productType,
        deductibleFull: deductibleFull,
        deductiblePartial: deductiblePartial,
        noClaimDiscountPartial: noClaimDiscount,
        noClaimDiscountFull: noClaimDiscount
    } as IPolicyFormData;

    return formData;
});

export const carInsuranceSummarySelector = createDeepEqualSelector(
    [
        getSubmitResponse,
        getCarInsurance,
        getCarInsuranceProposal,
        getVehicleData,
        getVehicleDataDetails
    ],
    (
        submitResponse: ICarInsuranceCompletionResponse,
        carInsuranceData: ICarInsuranceProductFullData,
        proposal: IProposal,
        vehicleData: IVehicleData,
        vehicleDataDetails: ICarInsuranceVehicleDataDetails
    ) => {
        const selectedTariffName = formatOneInsuranceTariffName(
            proposal.green.calculationId === carInsuranceData.proposalSelectedId
        );

        const summaryData = {
            price: submitResponse.price,
            currency: submitResponse.currency,
            insurerName: proposal.insurerName,
            insuranceNumber: '',
            tariffName: selectedTariffName,
            plateNumber: vehicleData.plateNumber,
            startDate: vehicleDataDetails?.startDate
        } as ICarInsuranceSummary;

        return summaryData;
    }
);

const createPolicyRequestData = (policyData: IPolicyData) => {
    const policyRequestData = {} as IPolicyRequestData;
    policyRequestData.productType = policyData.productType;
    policyRequestData.packageType = policyData.packageType;

    switch (policyRequestData.productType) {
        case 'partial-comprehensive':
            policyRequestData.deductible = policyData.deductiblePartial;
            policyRequestData.noClaimDiscount =
                policyData.noClaimDiscountPartial;
            break;
        case 'fully-comprehensive':
            policyRequestData.deductible = policyData.deductibleFull;
            policyRequestData.noClaimDiscount = policyData.noClaimDiscountFull;
            break;
        default:
            policyRequestData.deductible = 0;
            policyRequestData.noClaimDiscount = '0';
            break;
    }

    return policyRequestData;
};

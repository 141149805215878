import React, {useEffect} from 'react';
import {Box, Divider, Grid} from '@material-ui/core';
import {IVehicleData} from '../../../../../models/productInterface';
import {
    IDateFieldExtended,
    isDate
} from '../../../../../models/sharedInterface';
import {
    WgInputField,
    WgPaperArea,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {
    isDigitLetterOnlySameLength,
    isDigitsOnlySameLength,
    isInputNotFilled,
    isPattern
} from '../../../../utils/Validate';

export interface VehicleDataProps {
    vehicleData: IVehicleData;
    setVehicleData: Function;
    validationCallback: (isValid: boolean) => void;
    validationEnabled: boolean;
}

export default function VehicleData({
    vehicleData: data,
    setVehicleData,
    validationCallback,
    validationEnabled
}: VehicleDataProps) {
    useEffect(() => {
        validateAll(data);
    });

    const handleDateChange = (isFirstInsuredDate: boolean) => (
        date: any | IDateFieldExtended
    ) => {
        if (!isDate(date)) return;

        const name = isFirstInsuredDate
            ? 'firstInsuredDate'
            : 'firstRegistrationDate';
        setVehicleData({...data, [name]: date});
        validateAll({
            ...data,
            [name]: date
        });
    };

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Zuerst benötigen wir einige Angaben über dein Fahrzeug.
            </WgTypography>
            <Box marginTop="1rem" marginBottom="1rem">
                <WgPaperArea
                    header="Angaben zum Fahrzeug"
                    subHeader="Du findest alle Daten in deinem Fahrzeugschein"
                    elevation={1}
                    paperStyle={{padding: '1rem 1.25rem'}}>
                    <WgInputField
                        fullWidth
                        label="Herstellerschlüsselnummer (HSN)"
                        name="hsn"
                        type="text"
                        defaultValue={data.hsn}
                        required
                        error={validationEnabled && !validateHsn(data.hsn)}
                        helperText={
                            validationEnabled &&
                            !validateHsn(data.hsn) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        pattern={/\d{0,4}/}
                        handleChange={onChange}
                    />
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Typschlüsselnummer (TSN)"
                        name="tsn"
                        type="text"
                        defaultValue={data.tsn}
                        required
                        error={validationEnabled && !validateTsn(data.tsn)}
                        isUpperCase
                        helperText={
                            validationEnabled &&
                            !validateTsn(data.tsn) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        pattern={
                            /[\d\w\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{0,3}/
                        }
                        handleChange={onChange}
                    />
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Fahrzeug-Identifizierungsnummer (FIN)"
                        name="vehicleId"
                        type="text"
                        defaultValue={data.vehicleId}
                        required
                        isUpperCase
                        error={
                            validationEnabled &&
                            !validateVehicleId(data.vehicleId)
                        }
                        helperText={
                            validationEnabled &&
                            !validateVehicleId(data.vehicleId) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        pattern={/[\d\w]{0,17}/}
                        handleChange={onChange}
                    />
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Kennzeichen"
                        name="plateNumber"
                        type="text"
                        defaultValue={data.plateNumber ?? ''}
                        required
                        error={
                            validationEnabled &&
                            !validatePlateNumber(data.plateNumber ?? '')
                        }
                        helperText={
                            validationEnabled &&
                            !validatePlateNumber(data.plateNumber ?? '') &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        isUpperCase
                        pattern={
                            /[\w\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{0,3}[\s-]?[\w\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{0,2}[\s-]?\d{0,4}[eE]?/
                        }
                        handleChange={onChange}
                    />
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Landkreis"
                        name="area"
                        type="text"
                        defaultValue={data.area}
                        required
                        error={validationEnabled && !validateArea(data.area)}
                        helperText={
                            validationEnabled &&
                            !validateArea(data.area) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        handleChange={onChange}
                    />
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Erstzulassung"
                        name="firstRegistrationDate"
                        type="date"
                        isDateExtendedDataNeeded
                        defaultValue={data.firstRegistrationDate || null}
                        required
                        error={
                            (validationEnabled &&
                                !validateDate(data.firstRegistrationDate)) ||
                            (validateDate(data.firstInsuredDate)
                                ? !validateDateDependency(
                                      data.firstInsuredDate,
                                      data.firstRegistrationDate
                                  )
                                : false)
                        }
                        helperText={
                            validationEnabled &&
                            !validateDate(data.firstRegistrationDate) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        handleChange={handleDateChange(false)}
                    />
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Erstmalig versichert auf dich als Fahrzeughalter"
                        name="firstInsuredDate"
                        type="date"
                        isDateExtendedDataNeeded
                        defaultValue={data.firstInsuredDate || null}
                        required
                        error={
                            validationEnabled &&
                            (!validateDate(data.firstInsuredDate) ||
                                !validateDateDependency(
                                    data.firstInsuredDate,
                                    data.firstRegistrationDate
                                ))
                        }
                        helperText={
                            validationEnabled &&
                            ((!validateDate(data.firstInsuredDate) &&
                                'Bitte überprüfe deine Eingabe.') ||
                                (!validateDateDependency(
                                    data.firstInsuredDate,
                                    data.firstRegistrationDate
                                ) &&
                                    'Dieses Datum muss nach der Erstzulassung liegen.'))
                        }
                        handleChange={handleDateChange(true)}
                    />
                </WgPaperArea>
            </Box>
        </Grid>
    );

    function onChange(event: any | IDateFieldExtended) {
        const {value, name} = event.target;
        setVehicleData({...data, [name]: value});
        validateAll({
            ...data,
            [name]: value
        });
    }

    function validateAll(data: IVehicleData) {
        const isValid = checkIsValid(data);

        validationCallback(isValid);
    }
}

function validateHsn(value: string) {
    return isDigitsOnlySameLength(value, 4) && !isInputNotFilled(value);
}

function validateTsn(value: string) {
    return isDigitLetterOnlySameLength(value, 3) && !isInputNotFilled(value);
}

function validatePlateNumber(value: string) {
    return (
        isPattern(
            value,
            /[\w\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{1,3}[\s-]?[\w\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]{1,2}[\s-]?\d{1,4}[E]?/
        ) && !isInputNotFilled(value)
    );
}

function validateVehicleId(value: string) {
    return isPattern(value, /[\d\w]{17}/) && !isInputNotFilled(value);
}

function validateDateDependency(
    firstInsuredDate?: Date,
    firstRegistrationDate?: Date
) {
    if (firstInsuredDate === undefined || firstRegistrationDate === undefined)
        return false;
    else return firstInsuredDate >= firstRegistrationDate;
}

function validateDate(value?: Date) {
    return !isInputNotFilled(value);
}

function validateArea(value: string) {
    return !isInputNotFilled(value);
}

function checkIsValid(data: IVehicleData) {
    let isValid = true;
    isValid = !isValid ? isValid : validateHsn(data.hsn);
    isValid = !isValid ? isValid : validateTsn(data.tsn);
    isValid = !isValid ? isValid : validatePlateNumber(data.plateNumber);
    isValid = !isValid ? isValid : validateVehicleId(data.vehicleId);
    isValid = !isValid ? isValid : validateDate(data.firstInsuredDate);
    isValid = !isValid ? isValid : validateDate(data.firstRegistrationDate);
    isValid = !isValid
        ? isValid
        : validateDateDependency(
              data.firstInsuredDate,
              data.firstRegistrationDate
          );
    isValid = !isValid ? isValid : validateArea(data.area);

    return isValid;
}

import {ReactElement} from 'react';
import {createAction} from 'redux-actions';
import {
    api,
    getAuthConfig,
    isErrorResponseStatusTreatable,
    performApiCall
} from './api';
import {
    FETCH_ENERGY_OFFER_CONTRACT_VALIDATION_DATA_SUCCESS,
    FETCH_ENERGY_OFFER_SUCCESS,
    NOTIFICATION_ADD_LOADING,
    NOTIFICATION_REMOVE_LOADING
} from './actionTypes';
import {push} from 'connected-react-router';
import {addLoadingDialog, setNotification} from './notificationActions';
import {
    IRecurringpayment,
    isRecurringPayment
} from '../../models/recurringpaymentInterface';
import {LoadingPriorityCodes} from '../reducers/notification';
import {ReactGA} from '../../tracking';
import {
    formatOfferState,
    IEnergyContractPreferences,
    IEnergyOfferContractValidation,
    IEnergyProduct,
    IOffer,
    isOffer,
    OfferState
} from '../../models/offerInterface';
import {loadingIdFetchOffersFromRecurringPayment} from './loadingIds';
import {getUserDeviceInfo} from '../../containers/app/ErrorBoundary';
import {FetchOfferLoadingContent} from '../../containers/views/recurringpayments/change/Components';
import {updateRecurringpaymentSuccess} from './recurringpaymentActions';
import WgLoadingDialog from '../../containers/app/loading_components/WgLoadingDialog';

// *** ACTION CREATORS ***
export const addLoading = createAction(NOTIFICATION_ADD_LOADING);
export const removeLoading = createAction(NOTIFICATION_REMOVE_LOADING);

export const fetchEnergyOfferSuccess = createAction(FETCH_ENERGY_OFFER_SUCCESS);
export const fetchEnergyOfferContractValidationDataSuccess = createAction(
    FETCH_ENERGY_OFFER_CONTRACT_VALIDATION_DATA_SUCCESS
);

export function errorNotification(
    routerPath: string,
    errorDescription: string,
    dispatch: Function,
    title?: string
) {
    dispatch(
        setNotification({
            title: title ?? 'Das sollte nicht passieren.',
            text: errorDescription,
            isAgreeable: true,
            onAgree: () => {
                dispatch(push(routerPath));
            },
            open: true
        })
    );
}

export function addLoadingGif(
    id: string,
    content?: string | ReactElement,
    hasAgreeButton: boolean = false
) {
    return addLoading({
        id,
        priority: LoadingPriorityCodes.GIFLOADING_PROGRESS,
        component: WgLoadingDialog,
        props: {
            loadingIdList: [
                'fetchEnergyPriceOffer',
                'fetchEnergyEcoOffer',
                'submitEnergyOfferChoice',
                'fetchEnergyOffers',
                'submitRecurringPayment'
            ],
            content: content,
            hasAgreeButton: hasAgreeButton
        }
    });
}

export const fetchEnergyOffers: any = (recurringpaymentId?: number) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        if (typeof recurringpaymentId === 'undefined') {
            handleUnexpectedError(dispatch);
            return;
        }
        const token = getState().user.token;
        const response = await api.get(
            `/recurringpayments/${recurringpaymentId}/offers`,
            {
                ...getAuthConfig(token, {Accept: 'application/json'})
            }
        );
        dispatch(fetchEnergyOfferSuccess(response.data));
    }, 'Vertragswechsel ist vorübergehend nicht verfügbar. Versuche es später noch einmal.');

export const submitEnergyContractCompletion: any = (
    contractDetails: IEnergyOfferContractValidation,
    recurringPaymentId: number
) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const state = getState();
        const token = state.user.token;
        const isMeterNumberMissing =
            typeof contractDetails?.meterNumber === 'undefined' ||
            contractDetails.meterNumber.length === 0;

        const id = `submitEnergyContractCompletion`;
        await dispatch(addLoadingDialog(id));

        try {
            const response = await api.post(
                '/recurringpayments/' + recurringPaymentId + '/change',
                contractDetails,
                getAuthConfig(token, {Accept: 'application/json'})
            );
            if (isRecurringPayment(response.data)) {
                dispatch(updateRecurringpaymentSuccess(response.data));
            } else {
                throw new Error('Wechsel nicht erfolgreich.');
            }
        } catch (error) {
            if (isErrorResponseStatusTreatable(error)) {
                throw error;
            }
            errorNotification(
                `/recurringpayments/details/${recurringPaymentId}`,
                'Leider ist beim Abschluss deines neuen Stromvertrages etwas schief gelaufen. Wir arbeiten bereits an der Behebung des Fehlers.',
                dispatch
            );
            return;
        }
        await dispatch(removeLoading(id));

        ReactGA.event({
            category: 'Recurringpayment',
            action: `change ${contractDetails.eco ? 'eco' : 'non-eco'} ${
                contractDetails.meterType
            } ${
                contractDetails.isCommercial ? 'commercial' : 'non-commercial'
            } to provider ${contractDetails?.betterProviderName} with savings ${
                contractDetails?.estimatedSavings
            }`,
            label: JSON.stringify(getUserDeviceInfo()),
            nonInteraction: true,
            value: 1
        });

        dispatch(
            setNotification({
                title: isMeterNumberMissing
                    ? 'Der erste Schritt ist gemacht!'
                    : 'Erfolgreich abgeschickt!',
                text: isMeterNumberMissing
                    ? 'Vielen Dank für dein Vertrauen. Sobald du die Zählernummer nachgereicht hast, können wir den Wechsel starten.'
                    : 'Vielen Dank für dein Vertrauen. Der Vertragswechsel wurde erfolgreich eingereicht und wird bearbeitet.',
                isAgreeable: true,
                onAgree: async () => {
                    await dispatch(
                        push(`/recurringpayments/details/${recurringPaymentId}`)
                    );
                },
                open: true
            })
        );
    }, 'Vertragswechsel ist vorübergehend nicht verfügbar. Versuche es später noch einmal.');

export const submitEnergyOfferChoice: any = (
    recurringPaymentId: number,
    energyProduct: IEnergyProduct,
    hasLoadingGif: boolean = false,
    nextRoute?: string
) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        if (hasLoadingGif) {
            await dispatch(
                addLoadingGif(
                    'submitEnergyOfferChoice',
                    'WechselGott bereitet den gewählten Tarif für dich vor. Gleich geht es weiter.'
                )
            );
        }
        const state = getState();
        const token = state.user.token;
        const response = await api.get(
            '/recurringpayments/' + recurringPaymentId + '/change',
            {
                ...getAuthConfig(token, {Accept: 'application/json'}),
                params: energyProduct
            }
        );
        await dispatch(
            fetchEnergyOfferContractValidationDataSuccess({
                data: await response.data,
                recurringPaymentId: recurringPaymentId
            })
        );

        const recurringpayment = state.recurringpayments.allRecurringpayments.find(
            (c: IRecurringpayment) =>
                c.recurringPaymentId === recurringPaymentId
        );

        if (hasLoadingGif) {
            await dispatch(removeLoading('submitEnergyOfferChoice'));
        }
        if (typeof nextRoute !== 'undefined') {
            dispatch(push(nextRoute));
            return;
        }
        await dispatch(
            push(
                `/recurringpayments/energy/completion/${recurringPaymentId}?categoryId=${recurringpayment.categoryId}&eco=${energyProduct.eco}`
            )
        );
    }, 'Vertragswechsel ist vorübergehend nicht verfügbar. Versuche es später noch einmal.');

export const submitEnergyContractPreferences: any = (
    recurringpaymentId?: number,
    preferences?: IEnergyContractPreferences,
    hasLoadingGif: boolean = false,
    hasErrorNotification: boolean = false,
    nextRoute?: string
) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            if (typeof recurringpaymentId === 'undefined') {
                handleUnexpectedError(dispatch);
                return;
            }
            if (hasLoadingGif) {
                await dispatch(
                    addLoadingGif(
                        'fetchEnergyOffers',
                        FetchOfferLoadingContent()
                    )
                );
            }
            const token = getState().user.token;
            try {
                const response = await api.post(
                    `/recurringpayments/${recurringpaymentId}/offers`,
                    preferences,
                    getAuthConfig(token, {Accept: 'application/json'})
                );
                if (hasLoadingGif) {
                    await dispatch(removeLoading('fetchEnergyOffers'));
                }
                if (
                    isOffer(response.data) &&
                    response.data?.offerState !== OfferState.AVAILABLE &&
                    response.data?.offerState !== OfferState.ECO_NOT_FOUND
                ) {
                    validateEnergyOffer(dispatch, response.data);
                    return;
                }
                dispatch(fetchEnergyOfferSuccess(response.data));
                dispatch(
                    updateRecurringpaymentSuccess(
                        response?.data?.recurringPayment
                    )
                );
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }

                await dispatch(removeLoading('fetchEnergyOffers'));
                if (hasErrorNotification) {
                    handleUnexpectedError(dispatch);
                    return;
                } else {
                    return;
                }
            }
            if (typeof nextRoute !== 'undefined') {
                dispatch(push(nextRoute));
                return;
            }
        },
        'Vertragswechsel ist vorübergehend nicht verfügbar. Versuche es später noch einmal.',
        undefined,
        true,
        loadingIdFetchOffersFromRecurringPayment(recurringpaymentId ?? -1)
    );

export function validateEnergyOffer(dispatch: Function, offer?: IOffer) {
    const {title, description, step} = formatOfferState(offer);
    if (typeof offer === 'undefined') {
        errorNotification('/recurringpayments', description, dispatch, title);
        return;
    }
    errorNotification(
        typeof step !== 'undefined'
            ? `/recurringpayments/${offer.recurringPaymentId}/add/energy/${step}`
            : `/recurringpayments/details/${offer.recurringPaymentId}`,
        description,
        dispatch,
        title
    );
}

export function handleUnexpectedError(dispatch: Function) {
    errorNotification(
        `/recurringpayments`,
        'Wir arbeiten bereits an der Behebung des Fehlers. Bitte versuche es später noch einmal.',
        dispatch,
        'Ein unerwarteter Fehler ist aufgetreten.'
    );
    return;
}

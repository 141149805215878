import {ICompany} from './companyInterface';
import {IntervalType, IRecurringpayment} from './recurringpaymentInterface';
import {CarInsuranceStep} from '../containers/views/insurance/views/kfz/CarInsuranceController';
import {CarSwitchInsuranceStep} from '../containers/views/insurance/views/kfz/CarSwitchInsuranceController';

export enum PaymentMethod {
    InApp,
    ManualIban
}

export function formatPaymentMethod(method: PaymentMethod) {
    return method === PaymentMethod.InApp
        ? 'Konto auswählen'
        : 'Manuell eingeben';
}

//Function will be extended in the future
export function isCarOfferEco(eco: boolean) {
    return eco ? 'Green_Comfort' : 'Comfort';
}

export interface IPreviousPolicyData {
    deductible?: number;
    noClaimDiscount?: string;
    insurer: string;
    contractId: number;
}

export interface ICarInsuranceProductRequestData {
    vehicleData: IVehicleData;
    policyData: IPolicyRequestData;
    calculation: ICarInsuranceCalculationRequest;
    driverData: IDriverData;
    paymentData: IPaymentData;
}

export interface ICarInsuranceSwitchProductData {
    vehicleData: IVehicleData;
    policyData: IPolicyRequestData;
    previousInsurer: IPreviousPolicyData;
    driverData: IDriverData;
    paymentData: IPaymentData;
}

export interface INoClaimDiscount {
    shortName: string;
    name: string;
    id?: number;
}

export interface IPolicyRequestData {
    productType: string;
    packageType: 'Compact' | 'Comfort' | 'Green_Compact' | 'Green_Comfort'; //probably better as enumeration
    deductible: number;
    noClaimDiscount: string;
    startDate: Date;
}

export interface ICarInsuranceCalculationRequest {
    id: string;
}

export interface ICarInsuranceProductFullData {
    mainData: IInsuranceProduct;
    vehicleData: IVehicleData;
    proposal: IProposal;
    submitResponse: ICarInsuranceCompletionResponse;
    proposalSelectedId: string;
    vehicleHolder: IVehicleHolder;
    paymentData: IPaymentData;
}

export enum MeterType {
    Electricity,
    Gas,
    HeatPump,
    ElectricityHeatingWithoutOwnMeter,
    ElectricityHeatingWithOwnMeter,
    Invalid
}

export function formatMeterType(type: MeterType) {
    switch (type) {
        case MeterType.Gas:
            return 'Gas';
        case MeterType.Electricity:
            return 'Strom';
        case MeterType.ElectricityHeatingWithoutOwnMeter:
            return 'Keine Besonderheit';
        case MeterType.ElectricityHeatingWithOwnMeter:
            return 'Nachtspeicher';
        case MeterType.HeatPump:
            return 'Wärmepumpe';
        default:
            return '';
    }
}

export interface IInsuranceProduct {
    name: string;
    logoPath: string;
    id: number;
    price?: string;
    url?: string;
}

export interface ICarInsuranceOptions {
    isVehicleHolderDataInit: boolean;
}

export interface IExistingInsuranceProduct {
    id: number;
    recurringPaymentId: number;
    recurringPayment?: IRecurringpayment;
    accountId?: number;
    companyId?: number;
    company?: ICompany;
    status?: 'available' | 'pending' | 'none';
    categoryId?: number;
    insuranceType: string;
    amount?: number;
    paymentInterval?: string;
    insuranceNumber?: number;
    tariffName?: string;
    startDate?: Date;
    endDate?: Date;
    insurant?: string[];
    insuredRisk?: string;
    logoPath: string;
    vbNumber?: string;
}

export interface IPolicyManualAddition {
    companyId?: number;
    categoryId?: number;
    insuranceNumber: string;
}

export interface ICarInsuranceCompletionResponse {
    calculationId: string;
    currency: string;
    id: string;
    price: number;
    status: string;
    paymentPeriod: string;
    tariffDescription: string;
    insuranceStartDate: Date;
    insuredRisk: string;
    insuranceNumber: string;
    contractId: number;
    vbNumber?: string;
}

export interface ICarInsuranceSummary {
    price: number;
    currency: string;
    insurerName: string;
    insuranceNumber: string;
    tariffName: string;
    startDate: Date;
    plateNumber: string;
}

export interface IVehicleData {
    hsn: string;
    tsn: string;
    vehicleId: string;
    kmPerYear: number;
    firstRegistrationDate?: Date;
    firstInsuredDate?: Date;
    area: string;
    plateNumber: string;
}

export interface ICarContractCompletion {
    vehicleData: IVehicleData;
}

export interface IAccountData {
    iban?: string;
    paymentInterval: IntervalType;
}

export interface IDriverData {
    otherDriver: boolean;
    youngestDriverAge?: number;
    insuranceHolder: IVehicleHolder;
}

export interface IPaymentData {
    accountHolderName: string;
    iban: string;
    paymentMethod: PaymentMethod;
}

export interface ICarInsurancePaymentAccount extends IPaymentData {
    accountId?: number;
}

export interface ICarInsuranceVehicleDataDetails extends IDriverData {
    kmPerYear?: number;
    startDate?: Date;
}

export interface ICoverage {
    label: string;
    name: string;
    lineId: number;
    type: string;
    value: number;
    comment?: string;
}

export interface IProposal {
    coverages: ICoverage[];
    currency: string;
    normal: IProposalTariff;
    green: IProposalTariff;
    expirationDatetime: Date;
    insurerName: string;
}

export interface IProposalTariff {
    calculationId: string;
    tariffWithTax: number;
    productId: string;
}

export interface ICarInsuranceNoClaimDiscount {
    noClaimDiscountAvailable?: boolean;
    noClaimDiscountValue?: string;
    preInsurer?: string;
}

export interface IPolicyData {
    productType: string;
    packageType: 'Compact' | 'Comfort' | 'Green_Compact' | 'Green_Comfort'; //probably better as enumeration
    deductiblePartial: number;
    deductibleFull: number;
    noClaimDiscountPartial: string;
    noClaimDiscountFull: string;
    paymentInterval: string;
    amount: number;
    startDate?: Date;
}

export interface IVehicleHolder {
    firstName: string;
    familyName: string;
    birthDate?: Date;
    gender?: string;
    email?: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
}

export interface IPolicyFormData {
    productType: string[];
    deductiblePartial: number[];
    deductibleFull: number[];
    noClaimDiscountPartial: string[];
    noClaimDiscountFull: string[];
}

export interface ICarInsuranceFinalResult {
    accountId: number;
    companyId: number;
    categoryId: number;
    logoPath: string;
    insurant: string[];
    interval: string;
}

export enum CarInsuranceOneProductType {
    NEW_VEHICLE,
    SWITCH_INSURANCE
}

export function formatCarInsuranceOneProductType(
    type: CarInsuranceOneProductType
) {
    return type === CarInsuranceOneProductType.NEW_VEHICLE
        ? 'Neues Fahrzeug versichern'
        : 'Versicherung deines Fahrzeugs wechseln \n (bald verfügbar)';
}

export interface ICarInsuranceOneProduct {
    oneProductType: CarInsuranceOneProductType;
}

export interface ICarInsuranceOfferDetails {
    productName: string;
    productType: string;
    proposalTariff: IProposalTariff;
    eco: boolean;
}

//Kack wording aber aus Konsistenz so gelassen
export function isExistingInsuranceProduct(
    object: IExistingInsuranceProduct
): object is IExistingInsuranceProduct {
    return (
        typeof object?.id !== 'undefined' &&
        typeof object?.insuranceType !== 'undefined'
    );
}

//Kack wording aber aus Konsistenz so gelassen
export function isDirectSaleOneResponse(
    object: ICarInsuranceCompletionResponse
): object is ICarInsuranceCompletionResponse {
    return (
        typeof object?.id !== 'undefined' &&
        typeof object?.vbNumber !== 'undefined' &&
        typeof object?.contractId !== 'undefined'
    );
}

export function getCarInsuranceWording(step?: CarInsuranceStep): string {
    if (typeof step === 'undefined') {
        return 'Kfz-Versicherung';
    }
    switch (step) {
        case CarInsuranceStep.VEHICLE_DATA_SELECTION:
            return 'Dein Fahrzeug';
        case CarInsuranceStep.VEHICLE_HOLDER_SELECTION:
            return 'Fahrzeughalter';
        case CarInsuranceStep.NECESSARY_DATA_SELECTION:
            return 'Notwendige Daten';
        case CarInsuranceStep.NO_CLAIM_DISCOUNT:
            return 'Schadensfreiheitsklasse';
        case CarInsuranceStep.PREINSUREER_SELECTION:
            return 'Versicherer auswählen';
        case CarInsuranceStep.NO_CLAIM_DISCOUNT_SELECTION:
            return 'Schadensfreiheitsklasse';
        case CarInsuranceStep.CAR_PRODUCT_TYPE_SELECTION:
            return 'Auswahl';
        case CarInsuranceStep.OFFER_SELECTION:
            return 'Vergleich';
        case CarInsuranceStep.PAYMENT_SELECTION:
            return 'Bezahlung';
        case CarInsuranceStep.ACCOUNT_SELECTION:
            return 'Bankkonto auswählen';
        case CarInsuranceStep.DATA_VALIDATION:
            return 'Abschluss';
        default:
            return 'Kfz Versicherung';
    }
}

export function getCarSwitchInsuranceWording(
    step?: CarSwitchInsuranceStep
): string {
    if (typeof step === 'undefined') {
        return 'Kfz-Versicherung';
    }

    switch (step) {
        case CarSwitchInsuranceStep.EXISTING_CONTRACT_VALIDATION:
            return 'Vertragsdetails';
        case CarSwitchInsuranceStep.START_DATE_VALIDATION:
            return 'Wechseldatum';
        case CarSwitchInsuranceStep.OFFER_SELECTION:
            return 'Vergleich';
        case CarSwitchInsuranceStep.PAYMENT_SELECTION:
            return 'Bezahlung';
        case CarSwitchInsuranceStep.ACCOUNT_SELECTION:
            return 'Bankkonto auswählen';
        case CarSwitchInsuranceStep.DATA_VALIDATION:
            return 'Abschluss';
        default:
            return 'Kfz Versicherung';
    }
}

import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {userAuthentication} from '../store/actions/userActions';
import {getIsTokenValid, init} from '../store/actions/api';

export default function useAuth(): {isAuthenticated: boolean} {
    const dispatch = useDispatch();
    const isAuthenticated: boolean = useSelector(() => getIsTokenValid());
    const auth = useCallback(async () => {
        if (!isAuthenticated) {
            await dispatch(userAuthentication());
        }
        dispatch(init());
    }, [dispatch, isAuthenticated]);

    useEffect(() => {
        auth();
    }, [auth]);

    return {isAuthenticated};
}

import {ICompany} from './companyInterface';
import {ICategory} from './categoryInterface';
import {IBankConnection} from './bankInterface';

export interface IAccount {
    id: number;
    bankId: number;
    bankConnectionId: number;
    bankConnection?: IBankConnection; // this doesn't come from the backend, it's joined by an account selector
    name: string;
    iban: string;
    logoPath?: string;
    accountHolderName: string;
    accountCurrency: string;
    balance: number;
    overdraft?: number;
    overdraftLimit?: number;
    accountNumber: string;
    accountType: number;
    percentageDifference?: number;
    absoluteDifference?: number;
    visible?: boolean;
}

export function isAccount(
    object: IAccount | ICompany | ICategory
): object is IAccount {
    return typeof (object as IAccount)?.iban !== 'undefined';
}

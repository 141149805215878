import {History} from 'history';
import {createBlacklistFilter} from 'redux-persist-transform-filter';
// @ts-ignore
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import {otherReducers} from './store/reducers';
import {persistReducer} from 'redux-persist';
import {USER_LOGOUT} from './store/actions/actionTypes';
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {deleteAllCookies} from './containers/utils/Cookie';
import {Transform} from 'redux-persist/es/types';

// you want to remove some keys before you save
const userBlacklistFilter = createBlacklistFilter('user', [
    'userData.password'
]);

const bankBlacklistFilter = createBlacklistFilter('banks', ['allBanks']);

// noinspection JSUnusedGlobalSymbols
export const encryptor = createEncryptor({
    secretKey: process.env.REACT_ENCRYPTOR_KEY || 'dev',
    onError: function(error: Error) {
        console.error('createEncryptor', error);
    }
});

const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: ['notification'],
    transforms: [userBlacklistFilter, bankBlacklistFilter, encryptor]
};

export function applyBlacklists(store?: any): any {
    if (!store) {
        return undefined;
    }

    const blacklist = [
        ...rootPersistConfig.blacklist,
        'companies',
        'theme',
        'accounts',
        'categories',
        'products',
        'recurringpayments',
        'offers'
    ];
    const transforms = [
        ...rootPersistConfig.transforms,
        createBlacklistFilter('user', ['userData'])
    ];

    const state = {...store};
    for (const key in state) {
        if (blacklist.includes(key)) {
            delete state[key];
        } else {
            state[key] = applyBlacklistFilter(transforms, key, state);
        }
    }
    return state;
}

function applyBlacklistFilter(
    transforms: (Transform<any, any> | any)[],
    key: string,
    state: any
) {
    return transforms.reduce((subState, transformer) => {
        if (transformer === encryptor) {
            return subState;
        }
        return transformer.in(subState, key, {});
    }, state[key]);
}

function refreshLocalStorageStore(key: string) {
    localStorage.removeItem(key);
}

export function refreshLocalStorage(key: string) {
    for (const lKey of Object.keys(localStorage)) {
        if (lKey.includes(key)) {
            refreshLocalStorageStore(lKey);
        }
    }
}

const rootReducer = (history: History<any>) => {
    const initialReducers = combineReducers({
        ...otherReducers,
        router: connectRouter(history)
    });

    const rootReducers = (state: any, action: any) => {
        try {
            if (action.type === USER_LOGOUT) {
                localStorage.clear();
                deleteAllCookies();
                state = undefined;
            }
            return initialReducers(state, action);
        } catch (e) {
            console.error(e);
        }
    };

    return persistReducer(rootPersistConfig, rootReducers);
};

export default rootReducer;

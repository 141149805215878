import {createAction} from 'redux-actions';
import {api, getAuthConfig, performApiCall} from './api';
import {
    FETCH_ALL_BANK_CONNECTIONS_SUCCESS,
    FETCH_ALL_BANKS_SUCCESS,
    FETCH_INITIAL_BANKS_SUCCESS,
    UPDATE_BANK_CONNECTION_SUCCESS
} from './actionTypes';
import {
    loadingIdFinApiDialogFromBank,
    loadingIdRefreshBankConnection
} from './loadingIds';
import {accountActions, recurringpaymentActions} from '.';
import {
    AddBankAccountSuccessCodes,
    IBankConnection,
    isBankingState
} from '../../models/bankInterface';
import {refreshLocalStorage} from '../../rootReducer';
import {addNotificationDialog} from './notificationActions';

// *** ACTION CREATORS ***
export const fetchAllBanksSuccess = createAction(FETCH_ALL_BANKS_SUCCESS);
export const fetchInitialBanksSuccess = createAction(
    FETCH_INITIAL_BANKS_SUCCESS
);
export const fetchAllBankConnectionsSuccess = createAction(
    FETCH_ALL_BANK_CONNECTIONS_SUCCESS
);
export const updateBankConnectionSuccess = createAction(
    UPDATE_BANK_CONNECTION_SUCCESS
);

export enum BankingStateAction {
    ADD = 'add',
    UPDATE = 'update',
    UNKNOWN = 'unknown'
}

export const fetchInitialBanks: any = () =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;
        const res = await api.get(
            '/banks?initialView=true',
            getAuthConfig(token, {Accept: 'application/json'})
        );
        dispatch(fetchInitialBanksSuccess(res.data));
    }, 'Banken konnten nicht geladen werden. Versuche es später noch einmal.');

export const fetchAllBanks: any = () =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;
        const res = await api.get(
            '/banks/',
            getAuthConfig(token, {Accept: 'application/json'})
        );
        dispatch(fetchAllBanksSuccess(res.data));
    }, 'Banken konnten nicht geladen werden.');

export const fetchFinApiDialogFromBank: any = (bankId: number) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;

            const res = await api.get(
                '/banks/' + bankId + '/receiveBindingLocation',
                getAuthConfig(token, {Accept: 'application/json'})
            );
            refreshLocalStorage('/accounts');
            refreshLocalStorage('/recurringpayments');
            refreshLocalStorage('/transactions');
            window.open(res.data, '_self');
        },
        'Bankverbindung konnte nicht aufgerufen werden.',
        undefined,
        true,
        loadingIdFinApiDialogFromBank(bankId)
    );

export const fetchAllBankConnections: any = () =>
    performApiCall(
        performFetchAllBankConnections(),
        'Bankverbindungen konnten nicht geladen werden.'
    );

export const performFetchAllBankConnections: any = () => async (
    dispatch: Function,
    getState: Function
) => {
    if (!getState()?.user?.token) {
        return;
    }

    const token = getState()?.user?.token ?? null;

    const response = await api.get(
        '/bankconnections',
        getAuthConfig(token, {Accept: 'application/json'})
    );

    dispatch(fetchAllBankConnectionsSuccess(response.data));
};

export const fetchBankConnectionById: any = (bankConnectionId: number) =>
    performApiCall(
        performFetchBankConnectionById(bankConnectionId),
        'Bankverbindung konnten nicht geladen werden.'
    );

export const performFetchBankConnectionById: any = (
    bankConnectionId: number
) => async (dispatch: Function, getState: Function) => {
    // TODO: return if (!getState()?.user?.token)
    const token = getState()?.user?.token ?? null;

    const response = await api.get(
        `/bankconnections/${bankConnectionId}`,
        getAuthConfig(token, {Accept: 'application/json'})
    );

    dispatch(updateBankConnectionSuccess(response.data));
};

export const refreshBankConnection: any = (bankConnection?: IBankConnection) =>
    performApiCall(
        performRefreshBankConnection(bankConnection),
        'Bankverbindung konnte nicht aktualisiert werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        loadingIdRefreshBankConnection(bankConnection?.bankConnectionId ?? 0)
    );

export const performRefreshBankConnection: any = (
    bankConnection?: IBankConnection
) => async (dispatch: Function, getState: Function) => {
    if (!getState()?.user?.token || typeof bankConnection === 'undefined') {
        return;
    }
    const token = getState().user.token;
    const response = await api.get(
        '/bankconnections/' + bankConnection.bankConnectionId + '/update',
        getAuthConfig(token, {Accept: 'application/json'})
    );

    if (response.data != null) {
        window.open(response.data, '_self');
    } else {
        dispatch(accountActions.fetchAccountsById(bankConnection.accountIds));
        dispatch(recurringpaymentActions.fetchAllRecurringpayments());
        dispatch(recurringpaymentActions.fetchPolicies());
        dispatch(
            fetchBankConnectionById(bankConnection.bankConnectionId ?? -1)
        );
    }
};

export const getBankingStateAsDialog: any = (
    dialogId: string | null,
    bankingStateAction: string | null
) =>
    performApiCall(
        performGetBankingStateAsDialog(dialogId, bankingStateAction)
    );

export const performGetBankingStateAsDialog: any = (
    dialogId: string | null,
    bankingStateAction: string | null
) => async (dispatch: Function, getState: Function) => {
    const token = getState()?.user?.token;
    if (!token) {
        return;
    }

    if (dialogId == null || bankingStateAction == null) {
        return;
    }
    const action =
        bankingStateAction === 'add'
            ? BankingStateAction.ADD
            : bankingStateAction === 'update'
            ? BankingStateAction.UPDATE
            : BankingStateAction.UNKNOWN;
    if (action === BankingStateAction.UNKNOWN) {
        return;
    }
    const response = await api.get(
        `/dialog/${action}/${Number(dialogId)}`,
        getAuthConfig(token, {Accept: 'application/json'})
    );

    if (!isBankingState(response.data)) {
        await dispatch(
            addNotificationDialog(
                'bankingStateAsDialog',
                'Konto hinzufügen nicht erfolgreich.',
                'Ein unerwarteter Fehler ist aufgetreten. Wir arbeiten bereits an einer Lösung. Bitte versuche es später noch einmal.'
            )
        );
    } else {
        const state = response.data;
        await dispatch(
            addNotificationDialog(
                'bankingStateAsDialog',
                state.successCode === AddBankAccountSuccessCodes.Success
                    ? action === BankingStateAction.ADD
                        ? 'Erfolgreich hinzugefügt.'
                        : 'Erfolgreich aktualisiert.'
                    : 'Ein Fehler ist aufgetreten.',
                state.message
            )
        );
    }
};

import {
    Box,
    FormControl,
    MenuItem,
    StandardTextFieldProps,
    TextField
} from '@material-ui/core';
import React, {useState} from 'react';
import {WgNumberFormat} from './index';
import {IDateFieldExtended} from '../../models/sharedInterface';
import DateForm from '../../containers/utils/DateForm';

function autoFillIds(name: string): string {
    switch (name) {
        case 'firstName':
            return 'given-name';
        case 'lastName':
            return 'family-name';
        case 'street':
            return 'address-line1';
        case 'houseNumber':
            return 'address-line2';
        case 'addressAdditional':
            return 'address-line3';
        case 'city':
            return 'address-level2';
        case 'postcode':
            return 'postal-code';
        case 'password':
        case 'password_confirm':
            return 'current-password';
        default:
            return name;
    }
}

export interface WgInputFieldBaseProps extends StandardTextFieldProps {
    number?: boolean;
    fullWidth?: boolean;
    label?: string;
    dayLabel?: string;
    type: string;
    name: string;
    isDateExtendedDataNeeded?: boolean;
    disableFuture?: boolean;
    disablePast?: boolean; //ToDo: Replace isPast && isFutureDateNeeded by an enumeration to select the desired timeFrame; sorry guys schlagt mich nicht
    isUpperCase?: boolean;
    options?: {
        value: string;
        label: string;
    }[];
    defaultValue?: string | Date | number | null;
    shrinkLabel?: boolean;
    background?: 'paper' | 'default';
    birthDate?: boolean;
}

export interface WgInputFieldProps extends WgInputFieldBaseProps {
    handleChange?: (event: any | Date | IDateFieldExtended) => void;
    pattern?: RegExp;
}

export default function WgInputField(props: WgInputFieldProps) {
    const {
        defaultValue,
        fullWidth,
        dayLabel,
        handleChange,
        variant,
        InputProps,
        isDateExtendedDataNeeded,
        isUpperCase,
        disableFuture,
        disablePast,
        number,
        pattern,
        disabled,
        shrinkLabel,
        background,
        birthDate,
        ...rest
    } = props;
    let selectValue;
    if (props.options && typeof props.defaultValue === 'string') {
        selectValue =
            props.name === 'gender'
                ? props.defaultValue.toLowerCase()
                : props.defaultValue;
    } else {
        selectValue = props.defaultValue;
    }

    const [previousValue, setPreviousValue] = useState(selectValue);

    function handleChangeWithValidate(event: any | Date) {
        let value = event?.target?.value;
        value =
            typeof value === 'string' && isUpperCase
                ? value.toUpperCase()
                : value;

        const currentCursorPosition = event?.target?.selectionStart;
        const formattedPattern = formatRegexpPattern(pattern);

        if (!!value && !!formattedPattern && !formattedPattern.test(value)) {
            event.target.value = previousValue;
        } else {
            event.target.value = value;

            if (typeof value === 'string' && isUpperCase) {
                event.target.selectionStart = currentCursorPosition;
                event.target.selectionEnd = currentCursorPosition;
            }

            handleChange && handleChange(event);
            setPreviousValue(value);
        }
    }

    if (number) {
        return (
            <FormControl>
                <TextField
                    InputLabelProps={{shrink: shrinkLabel}}
                    variant="filled"
                    value={defaultValue}
                    onChange={handleChange}
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: WgNumberFormat as any,
                        ...InputProps
                    }}
                    {...rest}
                />
            </FormControl>
        );
    }

    return props.type === 'date' ? (
        <DateForm
            value={props.defaultValue}
            onChange={(date?: Date) => handleChange && handleChange(date)}
            label={props.label}
            dayLabel={props.dayLabel}
            error={props.error}
            helperText={props.helperText}
            required={props.required}
            disabled={props.disabled}
            background={props.background}
            birthDate={props.birthDate}
            disableFuture={props.disableFuture}
            disablePast={props.disablePast}
        />
    ) : (
        <Box key={props.name} paddingX={fullWidth ? 0 : '1.25rem'} width="100%">
            <FormControl>
                <TextField
                    InputLabelProps={{shrink: shrinkLabel}}
                    onPaste={props.onPaste}
                    variant="filled"
                    disabled={props.disabled}
                    {...rest}
                    select={typeof props.options !== 'undefined'}
                    value={selectValue}
                    InputProps={{
                        readOnly: props.name === 'country',
                        autoComplete: autoFillIds(props.name),
                        ...InputProps
                    }}
                    onChange={handleChangeWithValidate}>
                    {props.options &&
                        props.options.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                </TextField>
            </FormControl>
        </Box>
    );
}

function formatRegexpPattern(pattern?: RegExp) {
    let formattedPattern = pattern;

    if (!!formattedPattern) {
        let patternString = pattern + '';
        patternString = patternString.substring(1, patternString.length - 1);

        patternString =
            patternString.charAt(0) === '^'
                ? patternString
                : '^' + patternString;
        patternString =
            patternString.charAt(patternString.length - 1) === '$'
                ? patternString
                : patternString + '$';

        formattedPattern = new RegExp(patternString);
    }

    return formattedPattern;
}

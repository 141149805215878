import React from 'react';
import {WgTypography} from '../../../styles/CustomComponents';

export default function PrivacyText() {
    return (
        <WgTypography component="span" color="textSecondary" text="info">
            Diese Datenschutzerklärung informiert Sie über die Art, Umfang und
            Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
            als „Daten“ bezeichnet) im Rahmen unseres Onlineangebotes inklusive
            Social Media. Bitte beachten Sie, dass wir bei den verwendeten
            Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“,
            „Personenbezogene Daten“, „Verarbeitung“, „Pseudonymisierung“,
            „Profiling“ oder „Auftragsverarbeiter“ die Begrifflichkeiten der
            Datenschutzgrundverordnung (DSGVO) verwenden.
            <br />
            <br />
            <b>Verantwortlicher</b>
            <br />
            <br />
            Wechselgott GmbH (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wechselgott.com/impressum/">
                Impressum
            </a>
            ).
            <br />
            <br />
            Sie erreichen unseren Datenschutzbeauftragten unter{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:datenschutz@wechselgott.com">
                datenschutz@wechselgott.com
            </a>
            .
            <br />
            <br />
            <b>Arten der verarbeiteten Daten</b>
            <br />
            <br />
            Wir verarbeiten die folgenden Arten von personenbezogenen Daten
            <br />
            - Bestandsdaten (z.B. Personen-Stammdaten, Namen oder Adressen).
            <br />
            - Kontaktdaten (z.B. E-Mail, Telefonnummern).
            <br />
            - Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
            <br />
            - Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten).
            <br />
            - Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
            IP-Adressen).
            <br />
            - Daten für Zugangskonten
            <br />
            <br />
            <b>Kategorien betroffener Personen</b>
            <br />
            <br />
            Betroffen von der Verarbeitung sind die Besucher und Nutzer unseres
            Onlineangebotes.
            <br />
            <br />
            <b>Zweck der Verarbeitung</b>
            <br />
            <br />
            Wir verarbeiten personenbezogene Daten zu folgenden Zwecken:
            <br />
            - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
            Inhalte.
            <br />
            - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
            <br />
            - Abschluss von Verträgen
            <br />
            - Sicherheitsmaßnahmen.
            <br />
            - Reichweitenmessung/Marketing
            <br />
            <br />
            <b>Maßgebliche Rechtsgrundlagen</b>
            <br />
            <br />
            Wie durch Artikel 13 DSGVO vorgeschrieben, informieren wir Sie über
            die Rechtsgrundlagen unserer Datenverarbeitungen.
            <ul>
                <li>
                    Für die Einholung von Einwilligungen, Support bei
                    technischen Problemen und allgemeine Kommunikation ist
                    Rechtsgrundlage Artikel 6 Abs. 1 lit. a und Artikel 7 DSGVO;
                </li>
                <li>
                    Für die Verarbeitung zur Erfüllung unserer Leistungen und
                    Durchführung vertraglicher Maßnahmen sowie Beantwortung von
                    Anfragen ist Rechtsgrundlage Artikel 6 Abs. 1 lit. b DSGVO;
                </li>
                <li>
                    Für die Verarbeitung zur Erfüllung unserer rechtlichen
                    Verpflichtungen (Abschluss von Verträgen) ist
                    Rechtsgrundlage Artikel 6 Abs. 1 lit. c DSGVO;
                </li>
                <li>
                    Für den Fall, dass lebenswichtige Interessen der betroffenen
                    Person oder einer anderen natürlichen Person eine
                    Verarbeitung personenbezogener Daten erforderlich machen,
                    beziehen wir uns als Rechtsgrundlage auf Artikel 6 Abs. 1
                    lit. d DSGVO.
                </li>
                <li>
                    Für die Verarbeitung zur Wahrung unserer berechtigten
                    Interessen ist Rechtsgrundlage Artikel 6 Abs. 1 lit. f
                    DSGVO.
                </li>
            </ul>
            Sofern wir Daten zu anderen Zwecken als denen, zu denen sie erhoben
            wurden, verarbeiten sollten, richten wir uns nach den Vorgaben des
            Artikel 6 Abs. 4 DSGVO. Selbstverständlich beachten wir bei der
            Verarbeitung von besonderen Kategorien von Daten, aufgeführt in
            Artikel 9 Abs. 1 DSGVO, die darin enthaltenen gesetzlichen Vorgaben.
            <br />
            <br />
            <b>Sicherheitsmaßnahmen</b>
            <br />
            <br />
            Selbstverständlich treffen wir nach Maßgabe der gesetzlichen
            Vorgaben - unter Berücksichtigung des Stands der Technik, der
            Implementierungskosten und der Art, des Umfangs, der Umstände und
            der Zwecke der Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte
            und Freiheiten natürlicher Personen -  geeignete technische und
            organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
            <br />
            <br />
            Dazu gehören insbesondere die Sicherung der Vertraulichkeit,
            Integrität und Verfügbarkeit von Daten durch Kontrolle des
            physischen Zugangs zu den Daten, als auch des sie betreffenden
            Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit
            und ihrer Trennung. Zusätzlich benutzen wir Verfahren, die eine
            Wahrnehmung der Rechte der Betroffenenrechten, Löschung von Daten
            und Reaktion auf Gefährdung der Daten sicherstellen. Auch
            berücksichtigen wir bereits bei der Entwicklung, bzw. Auswahl von
            Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
            Datenschutzes durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen zum Schutz von Daten.
            <br />
            <br />
            <b>
                Zusammenarbeit mit Auftragsverarbeitern, gemeinsam
                Verantwortlichen und Dritten
            </b>
            <br />
            <br />
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber Dritten,
            insbesondere Auftragsverarbeitern oder gemeinsam Verantwortlichen,
            offenlegen oder sie an diese übermitteln oder ihnen sonst darauf
            Zugriff gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
            Erlaubnis . Das kann der Fall sein, wenn eine Übermittlung der Daten
            an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung
            erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
            Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten
            Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
            Wir nutzen die Dienste von Amazon Web Services, mit diesen haben wir
            eine Auftragsverarbeitungsvereinbarung . Zu den
            Datenschutzinformationen von AWS finden Sie hier weitere
            Informationen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://aws.amazon.com/de/privacy/">
                https://aws.amazon.com/de/privacy/
            </a>
            <br />
            <br />
            <span>
                Weiterhin geben wir Ihre personenbezogenen Daten an unsere
                Partner Aboalarm GmbH aus München und Wechselpilot GmbH aus
                Hamburg weiter, wenn Sie im Rahmen unserer App deren Dienste
                nutzen. Hierauf weisen wir Sie bei der Nutzung der App hin.
                Deren Datenschutzerklärung finden Sie hier{' '}
            </span>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.aboalarm.de/datenschutz">
                https://www.aboalarm.de/datenschutz
            </a>
            <span> und hier </span>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.wechselpilot.com/datenschutzbedingungen/">
                https://www.wechselpilot.com/datenschutzbedingungen/
            </a>
            <span>
                . Zusätzlich erhalten wir durch den Finanzdienstleister FinAPI
                GmbH in München Zugriff auf Ihre Kontendaten. Deren
                Datenschutzerklärung finden Sie hier:{' '}
            </span>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.finapi.io/datenschutz/">
                https://www.finapi.io/datenschutz/
            </a>
            <br />
            <br />
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
            Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der
            Schweizer Eidgenossenschaft) verarbeiten oder dies im Rahmen der
            Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw.
            Übermittlung von Daten an andere Personen oder Unternehmen
            geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
            (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
            aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer
            berechtigten Interessen geschieht. Vorbehaltlich ausdrücklicher
            Einwilligung oder vertraglich erforderlicher Übermittlung,
            verarbeiten oder lassen wir die Daten nur in Drittländern mit einem
            anerkannten Datenschutzniveau, zu denen die unter dem
            "Privacy-Shield" zertifizierten US-Verarbeiter gehören oder auf
            Grundlage besonderer Garantien, wie z.B. vertraglicher Verpflichtung
            durch sogenannte Standardschutzklauseln der EU-Kommission, dem
            Vorliegen von Zertifizierungen oder verbindlichen internen
            Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de">
                Informationsseite der EU-Kommission
            </a>
            ).
            <br />
            <br />
            <b>Rechte der betroffenen Personen</b>
            <br />
            <br />
            Nachfolgend möchten wir Sie Informieren, welche Rechte Sie als
            Nutzer unseres Online-Angebots haben, wenn wir Ihre Daten
            verarbeiten:
            <br />
            <br />
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Daten verarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend den gesetzlichen Vorgaben.
            <br />
            <br />
            Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die
            Vervollständigung der Sie betreffenden Daten oder die Berichtigung
            der Sie betreffenden unrichtigen Daten zu verlangen.
            <br />
            <br />
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu
            verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw.
            alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung
            der Verarbeitung der Daten zu verlangen.
            <br />
            <br />
            Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten,
            die Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen
            Vorgaben zu erhalten und deren Übermittlung an andere
            Verantwortliche zu fordern.
            <br />
            <br />
            Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht,
            eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
            <br />
            <br />
            Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die
            Zukunft zu widerrufen.
            <br />
            <br />
            <b>
                Sie können der künftigen Verarbeitung der Sie betreffenden Daten
                innerhalb der gesetzlichen Vorgaben jederzeit widersprechen.
                Dieser Widerspruch kann insbesondere gegen die Verarbeitung der
                Daten für Zwecke der Direktwerbung erfolgen.
            </b>
            <br />
            <br />
            <b>Ein Profiling findet nicht statt.</b>
            <br />
            <br />
            <b>Cookies und Widerspruchsrecht bei Direktwerbung</b>
            <br />
            <br />
            Sog. Cookies sind kleine Dateien, die auf den Computern der Nutzer
            gespeichert werden. Diese können zu unterschiedlichen Angaben
            gespeichert werden. Ein Cookie dient hauptsächlich dazu, die Angaben
            zu einem Nutzer bzw. dessen Computer während oder auch nach dessen
            Besuch innerhalb eines Onlineangebotes zu speichern. Temporäre
            Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, sind
            solche Cookies, die gelöscht werden, nachdem der Nutzer das
            aufgerufene Onlineangebot verlässt und das Browserfenster schließt.
            In einem solchen Cookie können z.B. der Inhalt des Warenkorbs in
            einem Webshop oder ein Login-Status gespeichert werden. Als
            „permanent“ oder „persistent“ bezeichnet man die Cookies, die auch
            nach dem Schließen des Browsers gespeichert bleiben. Durch sie kann
            z.B. der Login-Status gespeichert werden, wenn die Nutzer die
            aufgerufene Seite nach mehreren Tagen wieder aufsuchen. Ebenso
            können in einem solchen persistenten Cookie die Interessen des
            Nutzers gespeichert werden, die zu Reichweitenmessungen oder
            Marketingzwecken verwendet werden. Als „Third-Party-Cookie“
            bezeichnet man Cookies, die von anderen Anbietern als dem
            Verantwortlichen, der das Onlineangebot betreibt, angeboten werden.
            <br />
            <br />
            Wir setzen temporäre und permanente Cookies ein und möchten Sie
            hierüber im Rahmen dieser Datenschutzerklärung informieren.
            <br />
            <br />
            Sofern wir unsere Nutzer um eine Einwilligung zum Einsatz von
            Cookies bitten (etwa im Rahmen einer Cookie-Einwilligung), beziehen
            wir uns auf Artikel 6 Abs. 1 lit. a. DSGVO als Rechtsgrundlage.
            Geschieht dies nicht, werden die Cookies der Nutzer entsprechend den
            nachfolgenden Erläuterungen im Rahmen dieser Datenschutzerklärung
            auf Grundlage unserer berechtigten Interessen gemäß Artikel 6 Abs. 1
            lit. f DSGVO, d.h. unser Interesse an der Analyse, Optimierung und
            dem wirtschaftlichen Betrieb unseres Onlineangebotes oder sofern,
            das Setzen von Cookies zur Erbringung unserer vertragsbezogenen
            Leistungen erforderlich ist, gemäß Artikel 6 Abs. 1 lit. b. DSGVO
            verarbeitet.
            <br />
            <br />
            Falls es die Nutzer unseres Onlineangebots nicht möchten, dass
            Cookies auf ihren Computern gespeichert werden, werden sie gebeten,
            die entsprechende Option in den Systemeinstellungen ihres Browsers
            zu deaktivieren. Bereits gespeicherte Cookies können in den
            Systemeinstellungen des Browsers gelöscht werden. Durch den
            Ausschluss von Cookies kann es allerdings zu
            Funktionseinschränkungen unseres Onlineangebotes führen.
            <br />
            <br />
            Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des
            Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der
            Dienste, vor allem im Fall des Trackings, über die US-amerikanische
            Seite{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.aboutads.info/choices/">
                http://www.aboutads.info/choices/
            </a>{' '}
            oder die EU-Seite{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.youronlinechoices.com/">
                http://www.youronlinechoices.com/
            </a>{' '}
            erklärt werden. Weiterhin kann die Speicherung von Cookies in den
            Einstellungen des Browsers durch eine generelle Abschaltung erreicht
            werden. Geschieht dies, können gegebenenfalls nicht alle Funktionen
            unseres Onlineangebotes genutzt werden.
            <br />
            <br />
            <b>Löschung von Daten</b>
            <br />
            <br />
            Wir löschen die von uns verarbeiteten Daten nach Maßgabe der
            gesetzlichen Vorgaben oder schränken sie in ihrer Verarbeitung ein.
            Sofern wir im Rahmen dieser Datenschutzerklärung nicht ausdrücklich
            angegeben wann, werden die bei uns gespeicherten Daten umgehend
            gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr
            erforderlich sind und der Löschung keine gesetzlichen
            Aufbewahrungspflichten entgegenstehen.
            <br />
            <br />
            Sofern die Daten nicht gelöscht werden können, weil sie für andere
            und gesetzlich zulässige Zwecke erforderlich sind, schränken wir
            deren Verarbeitung ein. Das bedeutet, die Daten werden gesperrt und
            nicht für andere Zwecke verarbeitet. Das gilt insbesondere für
            Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt
            werden müssen.
            <br />
            <br />
            <b>Änderungen und Aktualisierungen der Datenschutzerklärung</b>
            <br />
            <br />
            Wir passen die Datenschutzerklärung an, sobald die Änderungen der
            von uns durchgeführten Datenverarbeitungen dies erforderlich machen.
            Daher bitten wir Sie, sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren und diese gegebenenfalls öfter
            aufzusuchen. Wir informieren Sie unaufgefordert, sobald durch die
            Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung)
            oder eine sonstige individuelle Benachrichtigung erforderlich wird.
            <br />
            <br />
            <b>Geschäftsbezogene Verarbeitung</b>
            <br />
            <br />
            Zusätzlich verarbeiten wir
            <br />
            - Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
            Kundenkategorie).
            <br />
            - Zahlungsdaten (z.B. Bankverbindung, Zahlungshistorie)
            <br />
            von unseren Kunden, Interessenten und Geschäftspartner zwecks
            Erbringung vertraglicher Leistungen, Service und Kundenpflege,
            Marketing, Werbung und Marktforschung
            <br />
            <br />
            <b>Agenturdienstleistungen</b>
            <br />
            <br />
            Wir verarbeiten die Daten unserer Kunden im Rahmen unserer
            vertraglichen Leistungen zu denen konzeptionelle und strategische
            Beratung, Kampagnenplanung, Software- und
            Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen und
            Prozessen/ Handling, Serveradministration, Datenanalyse/
            Beratungsleistungen und Schulungsleistungen gehören.
            <br />
            <br />
            Für die Agenturdienstleistungen verarbeiten wir Bestandsdaten (z.B.
            Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B.
            E-Mail, Telefonnummern), Inhaltsdaten (z.B. Texteingaben,
            Fotografien, Videos), Vertragsdaten (z.B. Vertragsgegenstand,
            Laufzeit), Zahlungsdaten (z.B. Bankverbindung, Zahlungshistorie),
            sowie Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung und
            Erfolgsmessung von Marketingmaßnahmen). Besondere Kategorien
            personenbezogener Daten im Sinne der Datenschutzgrundverordnung
            verarbeiten wir über unser Online-Angebot nicht. Zu den Betroffenen
            gehören unsere Kunden, Interessenten sowie deren Kunden, Nutzer,
            Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der
            Verarbeitung besteht in der Erbringung von Vertragsleistungen,
            Abrechnung und unserem Kundenservice. Rechtsgrundlagen der
            Verarbeitung sind Artikel 6 Abs. 1 lit. b DSGVO (vertragliche
            Leistungen), Artikel. 6 Abs. 1 lit. f DSGVO (Unser berechtigtes
            Interesse liegt in der Analyse, Statistik, Optimierung und
            Sicherheitsmaßnahmen im Rahmen unseres Onlineangebotes). Wir
            verarbeiten Daten, die zur Begründung und Erfüllung der
            vertraglichen Leistungen erforderlich sind und weisen auf die
            Erforderlichkeit ihrer Angabe hin. Eine Offenlegung an Dritte
            erfolgt nur, wenn sie im Rahmen eines Auftrags erforderlich ist. Bei
            der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten
            handeln wir entsprechend den Weisungen der Auftraggeber sowie der
            gesetzlichen Vorgaben einer Auftragsverarbeitung gemäß Artikel 28
            DSGVO und verarbeiten die Daten zu keinen anderen, als den
            auftragsgemäßen Zwecken.
            <br />
            <br />
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
            vergleichbarer Pflichten. Die Erforderlichkeit der Aufbewahrung der
            Daten überprüfen wir alle drei Jahre; im Fall der gesetzlichen
            Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6
            Jahre gemäß § 257 Abs. 1 HGB, 10 Jahre gemäß § 147 Abs. 1 AO). Im
            Fall von Daten, die uns gegenüber im Rahmen eines Auftrags durch den
            Auftraggeber offengelegt wurden, löschen wir die Daten entsprechend
            den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
            <br />
            <br />
            <b>Maklerleistungen</b>
            <br />
            <br />
            Wir verarbeiten die Daten unserer Kunden und Interessenten
            (nachfolgend bezeichnet als „Kunden“) entsprechen Artikel 6 Abs. 1
            lit. b. DSGVO, um ihnen gegenüber unsere vertraglichen oder
            vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten
            Daten, die Art, der Umfang und der Zweck sowie die Erforderlichkeit
            ihrer Verarbeitung bestimmen sich nach dem zugrundeliegenden
            Auftrag. Dazu gehören grundsätzlich Bestands- und Stammdaten der
            Kunden (Name, Adresse, etc.), als auch die Kontaktdaten
            (E-Mailadresse, Telefon, etc.), die Vertragsdaten (Inhalt der
            Beauftragung, Entgelte, Laufzeiten, Angaben zu den vermittelten
            Unternehmen/ Versicherern/ Leistungen) und Zahlungsdaten
            (Provisionen, Zahlungshistorie, etc.). Wir können ferner die Angaben
            zu den Eigenschaften und Umständen von Personen oder ihnen
            gehörenden Sachen verarbeiten, wenn dies zum Gegenstand unseres
            Auftrags gehört. Dies können z.B. Angaben zu persönlichen
            Lebensumständen, mobilen oder immobilen Sachgütern sein.
            <br />
            <br />
            In Rahmen unserer Beauftragung kann es auch erforderlich sein, dass
            wir besondere Kategorien von Daten gemäß Artikel. 9 Abs. 1 DSGVO
            verarbeiten, hier insbesondere Angaben zur Gesundheit einer Person.
            Zu diesem Zweck holen wir, sofern es gesetzlich erforderlich ist,
            gemäß Artikel 6 Abs. 1 lit a., Artikel 7, Artikel 9 Abs. 2 lit. a
            DSGVO eine ausdrückliche Einwilligung unserer Kunden ein.
            <br />
            <br />
            Sofern es für die Vertragserfüllung oder gesetzlich erforderlich
            ist, offenbaren oder übermitteln wir die Daten der Kunden im Rahmen
            von Deckungsanfragen, Abschlüssen und Abwicklungen von Verträgen
            Daten an Anbieter der vermittelten Leistungen/ Objekte, Versicherer,
            Rückversicherer, Maklerpools, technische Dienstleister, sonstige
            Dienstleister, wie z.B. kooperierende Verbände, sowie
            Finanzdienstleister, Kreditinstitute und Kapitalanlagegesellschaften
            sowie Sozialversicherungsträger, Steuerbehörden, Steuerberater,
            Rechtsberater, Wirtschaftsprüfer, Versicherungs-Ombudsmänner und die
            Anstalten Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin).
            Wir holen eine Einwilligung unserer Kunden ein, sofern diese zur
            Offenbarung/ Übermittlung erforderlich ist, dies kann z.B. im Fall
            von besonderen Kategorien von Daten gemäß Artikel 9 DSGVO der Fall
            sein.
            <br />
            <br />
            Die Löschung der Daten erfolgt nach Ablauf der gesetzlichen
            Gewährleistungs- und vergleichbarer Pflichten, wobei die
            Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre
            überprüft wird; im Übrigen gelten die gesetzlichen
            Aufbewahrungspflichten.
            <br />
            <br />
            Im Fall der Anwendbarkeit gesetzlichen Archivierungspflichten
            löschen wir die Daten nach deren Ablauf. Aufbewahrungspflichtig sind
            insbesondere nach deutschem Recht in der Versicherungs- und
            Finanzbranche Beratungsprotokolle für 5 Jahre, Maklerschlussnoten
            für 7 Jahre und Maklerverträge für 5 Jahres sowie generell 6 Jahre
            für handelsrechtlich relevante Unterlagen und 10 Jahre für
            steuerrechtlich relevante Unterlagen.
            <br />
            <br />
            <b>Vertragliche Leistungen</b>
            <br />
            <br />
            Wir verarbeiten die Daten unserer Vertragspartner und Interessenten
            sowie anderer Auftraggeber, Kunden oder Vertragspartner (nachfolgend
            bezeichnet als „Vertragspartner“) entsprechend Artikel 6 Abs. 1 lit.
            b. DSGVO, um ihnen gegenüber unsere vertraglichen oder
            vorvertraglichen Leistungen erbringen zu können. Die hierbei
            verarbeiteten Daten, die Art, der Umfang und der Zweck sowie die
            Erforderlichkeit der Verarbeitung, bestimmen sich nach dem
            zugrundeliegenden Vertragsverhältnis.
            <br />
            <br />
            Zu den verarbeiteten Daten gehören die Stammdaten unserer
            Vertragspartner (z.B. Namen und Adressen), Kontaktdaten (z.B.
            E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B. in
            Anspruch genommene Leistungen, Vertragsinhalte, vertragliche
            Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B.
            Bankverbindungen, Zahlungshistorie).
            <br />
            <br />
            Besondere Kategorien personenbezogener Daten verarbeiten wir
            grundsätzlich nicht, außer wenn diese Bestandteile einer
            beauftragten oder vertragsgemäßen Verarbeitung sind.
            <br />
            <br />
            Wir verarbeiten Daten, die zur Begründung und Erfüllung der
            vertraglichen Leistungen erforderlich sind und weisen auf die
            Erforderlichkeit ihrer Angabe, sofern diese für die Vertragspartner
            nicht evident ist, hin. Eine Offenlegung an externe Personen oder
            Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags
            erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines
            Auftrags überlassenen Daten, handeln wir entsprechend den Weisungen
            der Auftraggeber sowie der gesetzlichen Vorgaben.
            <br />
            <br />
            Im Rahmen der Inanspruchnahme unserer Onlinedienste, können wir die
            IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung
            speichern. Die Speicherung erfolgt auf Grundlage unserer
            berechtigten Interessen, als auch der Interessen der Nutzer am
            Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
            Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer
            sie ist zur Verfolgung unserer Ansprüche gemäß Artikel 6 Abs. 1 lit.
            f. DSGVO erforderlich oder es besteht hierzu eine gesetzliche
            Verpflichtung gemäß Art. 6 Abs. 1 lit. c. DSGVO.
            <br />
            <br />
            Die Löschung der Daten erfolgt, wenn wir die Daten zur Erfüllung
            vertraglicher oder gesetzlicher Fürsorgepflichten sowie für den
            Umgang mit etwaigen Gewährleistungs- und vergleichbaren Pflichten
            nicht mehr benötigen. Wir überprüfen die Erforderlichkeit der
            Aufbewahrung der Daten alle drei Jahre; im Übrigen gelten die
            gesetzlichen Aufbewahrungspflichten, sofern es diese für die
            entsprechenden Daten gibt.
            <br />
            <br />
            <b>Finanzbuchhaltung, Büroorganisation sowie Kontaktverwaltung</b>
            <br />
            <br />
            Wir verarbeiten Daten im Rahmen der Verwaltung sowie Organisation
            unseres Unternehmens, Finanzbuchhaltung sowie zur Befolgung der
            gesetzlichen Vorgaben, wie z.B. der Archivierung. Hierbei
            verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung
            unserer vertraglichen Leistungen verarbeiten. Die Rechtsgrundlagen
            für die Verarbeitung sind Artikel 6 Abs. 1 lit. c. DSGVO und
            Artikel. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden,
            Interessenten, Geschäftspartner und Besucher unseres Online-Angebots
            betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt
            in der Verwaltung, Büroorganisation, Finanzbuchhaltung, sowie
            Archivierung von Daten. Dies sind Aufgaben die der Aufrechterhaltung
            unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und
            Erbringung unserer Leistungen dienen. Die Löschung der Daten im
            Hinblick auf vertragliche Leistungen und die vertragliche
            Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten
            genannten Angaben.
            <br />
            <br />
            Wir übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie
            z.B. Steuerberater oder Wirtschaftsprüfer sowie weitere
            Gebührenstellen und Zahlungsdienstleister.
            <br />
            <br />
            Darüber hinaus speichern wir auf Grundlage unserer
            betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
            Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer
            Kontaktaufnahme. Diese überwiegend unternehmensbezogenen Daten
            speichern wir, sofern wir nicht zur Löschung aufgefordert werden,
            dauerhaft.
            <br />
            <br />
            <b>Betriebswirtschaftliche Analysen und Marktforschung</b>
            <br />
            <br />
            Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Wünsche
            der Vertragspartner und Nutzer erkennen zu können, analysieren wir
            die uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen,
            Anfragen, etc. Wir verarbeiten dabei Bestandsdaten,
            Kommunikationsdaten, Vertragsdaten, Zahlungsdaten, Nutzungsdaten,
            Metadaten auf Grundlage des Artikel 6 Abs. 1 lit. f. DSGVO, wobei zu
            den betroffenen Personen Vertragspartner, Interessenten, Kunden,
            Besucher und Nutzer unseres Onlineangebotes gehören.
            <br />
            <br />
            Die Analysen erfolgen zum Zweck betriebswirtschaftlicher
            Auswertungen, des Marketings und der Marktforschung. Dabei können
            wir die Profile der registrierten Nutzer mit Angaben, z.B. zu deren
            in Anspruch genommenen Leistungen, berücksichtigen. Die Analysen
            dienen uns zur Steigerung der Nutzerfreundlichkeit, der Optimierung
            unseres Angebotes und der Betriebswirtschaftlichkeit. Die Analysen
            dienen alleine uns und werden nicht extern offenbart, sofern es sich
            nicht um anonyme Analysen mit zusammengefassten Werten handelt.
            <br />
            <br />
            Sofern diese Analysen oder Profile personenbezogen sind, werden sie
            mit Kündigung der Nutzer gelöscht oder anonymisiert, sonst nach zwei
            Jahren ab Vertragsschluss. Im Übrigen werden die
            gesamtbetriebswirtschaftlichen Analysen und allgemeine
            Tendenzbestimmungen nach Möglichkeit anonym erstellt.
            <br />
            <br />
            <b>Registrierfunktion</b>
            <br />
            <br />
            Nutzer können im Rahmen der Nutzung unseres Online-Angebotes ein
            Nutzerkonto anlegen. Im Rahmen der Registrierung teilen wir die
            erforderlichen Pflichtangaben den Nutzern mit und verarbeiten diese
            auf Grundlage des Artikel 6 Abs. 1 lit. b DSGVO zu Zwecken der
            Bereitstellung des Nutzerkontos. Zu diesen Daten gehören
            insbesondere Login-Informationen, also (Name, Passwort sowie eine
            E-Mailadresse. Die im Rahmen der Registrierung eingegebenen Daten
            werden lediglich für die Zwecke der Nutzung des Nutzerkontos und
            dessen Zwecks verwendet.
            <br />
            <br />
            Die Nutzer werden über Informationen, die für ihr Nutzerkonto
            relevant sind, beispielsweise technische Änderungen, per E-Mail
            informiert. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden
            deren Daten im Hinblick auf das Nutzerkonto, vorbehaltlich einer
            gesetzlichen Aufbewahrungspflicht, gelöscht. Es obliegt den Nutzern,
            ihre Daten bei erfolgter Kündigung vor dem Vertragsende zu sichern.
            Wir sind berechtigt, sämtliche während der Vertragsdauer
            gespeicherten Daten des Nutzers unwiederbringlich zu löschen, sofern
            nicht sich aus anderen Rechtsgrundlagen ergebende
            Aufbewahrungspflichten bestehen, etwa aus Vertragsbeziehungen zu
            uns.
            <br />
            <br />
            Im Rahmen der Inanspruchnahme unserer Registrierungs- und
            Anmeldefunktionen sowie der Nutzung des Nutzerkontos, speichern wir
            die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
            Speicherung erfolgt auf Grundlage unserer berechtigten Interessen,
            als auch der Nutzer an Schutz vor Missbrauch und sonstiger
            unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
            grundsätzlich nicht, außer sie ist zur Verfolgung unserer Ansprüche
            erforderlich oder es besteht hierzu besteht eine gesetzliche
            Verpflichtung gem. Artikel 6 Abs. 1 lit. c. DSGVO. Die IP-Adressen
            werden spätestens nach 7 Tagen anonymisiert oder gelöscht.
            <br />
            <br />
            <b>Akismet Anti-Spam-Prüfung</b>
            <br />
            <br />
            Im Rahmen unseres Online-Angebotes nutzen wir den Dienst „Akismet“,
            angeboten von der Automattic Inc., 60 29th Street #343, San
            Francisco, CA 94110, USA, angeboten. Die Nutzung des Dienstes
            erfolgt auf Grundlage unserer berechtigten Interessen im Sinne des
            Art. 6 Abs. 1 lit. f) DSGVO: Mit Hilfe dieses Dienstes können
            Kommentare echter Menschen von Spam-Kommentaren unterschieden
            werden. Dazu werden alle Kommentarangaben an einen Server in den USA
            verschickt, wo sie analysiert und für Vergleichszwecke vier Tage
            lang gespeichert werden. Wird ein Kommentar als Spam eingestuft,
            werden die Daten länger als die vier Tage gespeichert. Zu diesen
            Angaben gehören der eingegebene Name, die E-Mail-Adresse, die
            IP-Adresse, der Kommentarinhalt, der Referrer, Angaben zum
            verwendeten Browser sowie dem Computersystem und die Zeit des
            Eintrags.
            <br />
            <br />
            Nähere Informationen zur Erhebung und Nutzung von Daten durch
            Akismet finden sich in den Datenschutzhinweisen von Automattic:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://automattic.com/privacy/">
                https://automattic.com/privacy/
            </a>
            .
            <br />
            <br />
            Nutzer können Pseudonyme nutzen oder auf die Eingabe des Namens oder
            der E-Mail-Adresse verzichten. Sie können die Übertragung der Daten
            komplett verhindern, indem sie keine Kommentare auf unserer Seite
            abgeben.
            <br />
            <br />
            <b>Trustpilot-Bewertungen</b>
            <br />
            <br />
            Wir nutzen das Bewertungsverfahren des Anbieters Trustpilot A/S,
            Pilestræde 58, 5, 1112 Kopenhagen, Dänemarkl.
            <br />
            <br />
            Trustpilot bietet Nutzern unseres Online-Angebots die Möglichkeit,
            unsere Leistungen zu bewerten. Nutzer die unsere Leistung in
            Anspruch genommen haben, werden um eine Einwilligung in die
            Zusendung der Bewertungsanfrage gebeten. Sofern die Nutzer eine
            entsprechende Einwilligung abgegeben haben (etwa durch Klick auf ein
            Kontrollkästchen oder einen Link), erhalten sie eine
            Bewertungsanfrage mit einem Link zu einer Bewertungsseite. Um
            sicherzustellen, dass die Nutzer tatsächlich unsere Leistungen in
            Anspruch genommen haben, übermitteln wir an Trustpilot die hierzu
            erforderlichen Daten im Hinblick auf den Nutzer und in Anspruch
            genommene Leistung (hierzu gehört der Name, die E-Mail-Adresse, und
            eine Referenznummer). Diese Daten werden alleine zur Verifizierung
            der Authentizität und Ansprache des Nutzers verwendet.
            <br />
            <br />
            Die Rechtsgrundlage für die Verarbeitung der Daten des Nutzers im
            Rahmen des Bewertungsverfahrens ist die Einwilligung der Nutzer
            gemäß Artikel. 6 Abs. 1 lit. a. DSGVO.
            <br />
            <br />
            Um eine Bewertung abzugeben, ist die Eröffnung eines Kundenkontos
            bei Trustpilot erforderlich. In diesem Fall gelten die
            Geschäftsbedingungen und Datenschutzhinweise von Trustpilot. Um die
            Neutralität und Objektivität der Bewertungen zu wahren, haben wir
            keinen unmittelbaren Einfluss auf die Bewertungen und können sie
            selbst nicht löschen. Sollten Nutzer einzelne Bewertungen gelöscht
            haben wollen, müssen sie sich an Trustpilot direkt wenden.
            <br />
            <br />
            Ferner können wir in unserer Website das Trustpilot-Widget
            einbinden. Ein Widget ist ein innerhalb unseres Online-Angebotes
            eingebundenes Funktions- und Inhaltselement, das veränderliche
            Informationen anzeigt. Dabei wird der entsprechende Inhalt zwar
            innerhalb unseres Online-Angebotes dargestellt, er wird aber in
            diesem Moment von den Servern von Trustpilot abgerufen. Nur so kann
            immer der aktuelle Inhalt gezeigt werden, insbesondere die jeweils
            aktuelle Bewertung. Dafür muss eine Datenverbindung von der
            innerhalb unseres Online-Angebotes aufgerufenen Webseite zu
            Trustpilot aufgebaut werden und Trustpilot erhält technische Daten
            (Zugriffsdaten, beinhaltend die IP-Adresse). Diese sind nötig, damit
            der Inhalt angezeigt werden kann. Des Weiteren erhält Trustpilot
            Informationen darüber, dass Nutzer unser Online-Angebot besucht
            haben. Diese Informationen können in einem Cookie gespeichert und
            von Trustpilot verwendet werden, um zu erkennen, welche
            Onlineangebote, die am Trustpilot-Bewertungsverfahren teilnehmen,
            von dem Nutzer besucht worden sind. Die Informationen können in
            einem Nutzerprofil gespeichert und für Werbe- oder
            Marktforschungszwecke verwendet werden.
            <br />
            <br />
            Die Rechtsgrundlage für die Verarbeitung der Daten des Nutzers im
            Rahmen der Einbindung des Widgets, sind unsere berechtigten
            Interessen an der Information unserer Nutzer über die Qualität
            unserer Leistungen gem. Artikel 6 Abs. 1 lit. f. DSGVO. Sofern wir
            die Nutzer um eine Einwilligung in die Verarbeitung Ihrer Daten
            durch den Cookie-Einsatz bitten, ist die Rechtsgrundlage der
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO.
            <br />
            <br />
            Weitere Informationen zu der Verarbeitung ihrer Daten durch
            Trustpilot sowie über deren Widerspruchsrechte und weitere
            Betroffenenrechte, erhalten Nutzer in der Datenschutzerklärung von
            Trustpilot:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://de.legal.trustpilot.com/end-user-privacy-terms">
                https://de.legal.trustpilot.com/end-user-privacy-terms
            </a>
            .
            <br />
            <br />
            <b>Newsletter</b>
            <br />
            <br />
            Wir versenden Newsletter, E-Mails und weitere elektronische
            Benachrichtigungen mit werblichen Informationen (nachfolgend
            „Newsletter“) nur mit der Einwilligung der Empfänger oder einer
            gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum
            Newsletter dessen Inhalte konkret umschrieben werden, sind sie für
            die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere
            Newsletter Informationen zu unseren Leistungen und uns.
            <br />
            <br />
            Die Anmeldung zu unserem Newsletter erfolgt in einem sog.
            Double-Opt-In-Verfahren. D.h. die Nutzer erhalten nach der Anmeldung
            eine E-Mail, in der sie um die Bestätigung der Anmeldung gebeten
            werden. Diese Bestätigung ist notwendig, damit sich niemand mit
            fremden E-Mailadressen anmelden kann. Die Anmeldungen zum Newsletter
            werden protokolliert, um den Anmeldeprozess entsprechend den
            notwendigen Anforderungen nachweisen zu können. Hierzu gehört die
            Speicherung des Anmelde- und des Bestätigungszeitpunkts, als auch
            der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem
            Versanddienstleister gespeicherten Daten protokolliert.
            <br />
            <br />
            Um sich für den Newsletter anzumelden, reicht es aus, eine
            E-Mail-Adresse angeben. Optional können Nutzer einen Namen zwecks
            persönlicher Ansprache im Newsletter anzugeben.
            <br />
            <br />
            Der Versand des Newsletters und die mit ihm verbundene
            Erfolgsmessung erfolgen auf Grundlage einer Einwilligung der
            Empfänger gemäß Artikel 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs.
            2 Nr. 3 UWG oder falls eine Einwilligung nicht erforderlich ist, auf
            Grundlage unserer berechtigten Interessen am Direktmarketing gemäß
            Artikel 6 Abs. 1 lt. f. DSGVO i.V.m. § 7 Abs. 3 UWG.
            <br />
            <br />
            Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
            unserer berechtigten Interessen gemäß Artikel 6 Abs. 1 lit. f DSGVO.
            Unser Interesse richtet sich auf den Einsatz eines
            nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl
            unseren geschäftlichen Interessen dient, als auch den Erwartungen
            der Nutzer entspricht und uns ferner den Nachweis von Einwilligungen
            erlaubt.
            <br />
            <br />
            Nutzer können den Empfang unseres Newsletters jederzeit abbestellen,
            d.h. ihre Einwilligungen widerrufen. Einen Link zur Abbestellung des
            Newsletters findet sich am Ende eines jeden Newsletters. Wir
            speichern die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf
            Grundlage unserer berechtigten Interessen in Form einer Blacklist,
            bevor wir sie löschen, um eine ehemals gegebene Einwilligung
            nachweisen zu können. Die Verarbeitung dieser Daten wird auf den
            Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein
            individueller Löschungsantrag ist jederzeit möglich, sofern zugleich
            das ehemalige Bestehen einer Einwilligung bestätigt wird.
            <br />
            <br />
            <b>Newsletter - Mailchimp</b>
            <br />
            <br />
            Der Versand der Newsletter erfolgt mittels „MailChimp“, einer
            Newsletterversandplattform des US-Anbieters Rocket Science Group,
            LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA.
            <br />
            <br />
            Die E-Mail-Adressen unserer Newsletterempfänger, als auch deren
            weitere, im Rahmen dieser Hinweise beschriebenen Daten, werden auf
            den Servern von MailChimp in den USA gespeichert. MailChimp
            verwendet diese Informationen zum Versand und zur Auswertung der
            Newsletter in unserem Auftrag. Des Weiteren kann MailChimp nach
            eigenen Informationen diese Daten zur Optimierung oder Verbesserung
            der eigenen Services nutzen, z.B. zur technischen Optimierung des
            Versandes und der Darstellung der Newsletter oder für
            wirtschaftliche Zwecke, um zu bestimmen aus welchen Ländern die
            Empfänger kommen. MailChimp nutzt die Daten unserer
            Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder
            an Dritte weiterzugeben.
            <br />
            <br />
            Wir vertrauen auf die Zuverlässigkeit und die IT- sowie
            Datensicherheit von MailChimp. MailChimp ist unter dem
            US-EU-Datenschutzabkommen „Privacy Shield“ zertifiziert und
            verpflichtet sich damit die EU-Datenschutzvorgaben einzuhalten. Des
            Weiteren haben wir mit MailChimp ein „Data-Processing-Agreement“
            abgeschlossen. Dabei handelt es sich um einen Vertrag, in dem sich
            MailChimp dazu verpflichtet, die Daten unserer Nutzer zu schützen,
            entsprechend dessen Datenschutzbestimmungen in unserem Auftrag zu
            verarbeiten und insbesondere nicht an Dritte weiter zu geben. Die
            Datenschutzbestimmungen von MailChimp können Sie hier einsehen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://mailchimp.com/legal/privacy/">
                https://mailchimp.com/legal/privacy/
            </a>
            .
            <br />
            <br />
            <b>Newsletter - Erfolgsmessung</b>
            <br />
            <br />
            Unsere Newsletter enthalten einen sog. „web-beacon“, das ist eine
            pixelgroße Datei, die beim Öffnen des Newsletters von unserem oder
            dem Server unseres Versanddienstleisters abgerufen wird. Im Rahmen
            dieses Abrufs werden zunächst technische Informationen, wie
            Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse
            und Zeitpunkt des Abrufs erhoben.
            <br />
            <br />
            Diese Informationen werden zur technischen Verbesserung der Services
            anhand der technischen Daten oder der Zielgruppen und ihres
            Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse
            bestimmbar sind) oder der Zugriffszeiten genutzt. Zu den
            statistischen Erhebungen gehört ebenfalls die Feststellung, ob die
            Newsletter geöffnet werden, wann sie geöffnet werden und welche
            Links geklickt werden. Diese Informationen können aus technischen
            Gründen zwar den einzelnen Newsletter-Empfängern zugeordnet werden.
            Es ist jedoch weder unser Bestreben, noch, sofern eingesetzt, das
            des Versanddienstleisters, einzelne Nutzer zu beobachten. Die
            Auswertungen dienen uns viel mehr dazu, die Lesegewohnheiten unserer
            Nutzer zu erkennen und unsere Inhalte auf sie anzupassen oder
            unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer
            zu versenden.
            <br />
            <br />
            Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich.
            Nutzer die die Erfolgsmessung nicht wünschen, müssen das gesamte
            Newsletterabonnement kündigen.
            <br />
            <br />
            <b>Hosting und E-Mail-Versand</b>
            <br />
            <br />
            Wir nutzen Hosting-Leistungen zur Zurverfügungstellung der folgenden
            Leistungen: Infrastruktur- und Plattformdienstleistungen,
            Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand,
            Sicherheitsleistungen sowie technische Wartungsleistungen, die wir
            zum Zwecke des Betriebs dieses Online-Angebotes einsetzen.
            <br />
            <br />
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
            Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
            Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
            Onlineangebotes auf Grundlage unserer berechtigten Interessen an
            einer effizienten und sicheren Zurverfügungstellung dieses
            Online-Angebotes gemäß Artikel 6 Abs. 1 lit. f DSGVO i.V.m. Artikel
            28 DSGVO. Wir haben mit unseren Hosting-Dienstleistern eine
            Auftragsverabeitungsvereinbarung abgeschlossen,
            <br />
            <br />
            <b>Erhebung von Zugriffsdaten und Logfiles</b>
            <br />
            <br />
            Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
            berechtigten Interessen im Sinne des Artikel 6 Abs. 1 lit. f. DSGVO
            Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
            befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören
            Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs,
            übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp
            nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
            zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
            <br />
            <br />
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
            Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
            von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
            weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
            endgültigen Klärung des jeweiligen Vorfalls von der Löschung
            ausgenommen.
            <br />
            <br />
            <b>Google Tag Manager</b>
            <br />
            <br />
            Wir setzen Google Tag Manager ein. Dies ist eine Lösung, mit der wir
            sog. Website-Tags über eine Oberfläche verwalten können (und so z.B.
            Google Analytics sowie andere Google-Marketing-Dienste in unser
            Onlineangebot einbinden). Der Tag Manager selbst (welches die Tags
            implementiert) verarbeitet keine Daten der Nutzer. Im Hinblick auf
            die Verarbeitung der Daten der Nutzer wird auf die folgenden Angaben
            zu den Google-Diensten verwiesen. Nutzungsrichtlinien:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/intl/de/tagmanager/use-policy.html">
                https://www.google.com/intl/de/tagmanager/use-policy.html
            </a>
            .
            <br />
            <br />
            <b>Google Analytics</b>
            <br />
            <br />
            Wir setzen Google Analytics, einen Webanalysedienst der Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
            („Google“) ein. Google verwendet Cookies. Die durch das Cookie
            erzeugten Informationen über Benutzung des Online-Angebotes durch
            die Nutzer werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert.
            <br />
            <br />
            Google wird diese Informationen in unserem Auftrag benutzen, um die
            Nutzung unseres Online-Angebotes durch die Nutzer auszuwerten, um
            Reports über die Aktivitäten innerhalb dieses Online-Angebotes
            zusammenzustellen und um weitere, mit der Nutzung dieses
            Online-Angebotes und der Internetnutzung verbundene
            Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den
            verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
            werden.
            <br />
            <br />
            Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung
            ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google
            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
            Server von Google in den USA übertragen und dort gekürzt.
            <br />
            <br />
            Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht
            mit anderen Daten von Google zusammengeführt. Die Nutzer können die
            Speicherung der Cookies durch eine entsprechende Einstellung ihrer
            Browser-Software verhindern; die Nutzer können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des
            Online-Angebotes bezogenen Daten an Google sowie die Verarbeitung
            dieser Daten durch Google verhindern, indem sie das unter folgendem
            Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://tools.google.com/dlpage/gaoptout?hl=de">
                http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung fragen, etwa im Rahmen
            einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            Daten der Nutzer auf Grundlage unserer berechtigten Interessen, also
            des Interesse an der Analyse, Optimierung und wirtschaftlichem
            Betrieb unseres Online-Angebotes im Sinne des Artikel 6 Abs. 1 lit.
            f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin,
            dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
            hierdurch zusichert, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs-
            und Widerspruchsmöglichkeiten, erfahren Sie in der
            Datenschutzerklärung von Google (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
            </a>
            ) sowie in den Einstellungen für die Darstellung von
            Werbeeinblendungen durch Google{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
            </a>
            ).
            <br />
            <br />
            Die Daten der Nutzer werden nach 14 Monaten gelöscht oder
            anonymisiert.
            <br />
            <br />
            <b>Google Universal Analytics</b>
            <br />
            <br />
            Wir setzen Google Analytics in der Ausgestaltung als „
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376">
                Universal-Analytics
            </a>
            “ ein. „Universal Analytics“ bezeichnet ein Verfahren von Google
            Analytics, bei dem die Nutzeranalyse auf Grundlage einer pseudonymen
            Nutzer-ID erfolgt und damit ein pseudonymes Profil des Nutzers mit
            Informationen aus der Nutzung verschiedener Geräten erstellt wird
            (sog. „Cross-Device-Tracking“).
            <br />
            <br />
            <b>Zielgruppenbildung mit Google Analytics</b>
            <br />
            <br />
            Wir nutzen Google Analytics, um die durch innerhalb von
            Werbediensten Googles und seiner Partner geschalteten Anzeigen, nur
            denjenigen Nutzern anzuzeigen, die auch ein Interesse an unserem
            Online-Angebot gezeigt haben oder die bestimmte Merkmale (z.B.
            Interessen an bestimmten Themen oder Produkten, die anhand der
            besuchten Webseiten bestimmt werden) aufweisen, die wir an Google
            übermitteln (sog. „Remarketing-“, bzw.
            „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences
            möchten wir auch sicherstellen, dass unsere Anzeigen dem
            potentiellen Interesse der Nutzer entsprechen.
            <br />
            <br />
            <b>Google Adsense mit personalisierten Anzeigen</b>
            <br />
            <br />
            Wir nutzen die Dienste der Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland („Google“).
            <br />
            <br />
            Wir Nutzen den Dienst AdSense, mit dessen Hilfe Anzeigen in unsere
            Webseite eingeblendet und wir für deren Einblendung oder sonstige
            Nutzung eine Entlohnung erhalten. Zu diesen Zwecken werden
            Nutzungsdaten, wie z.B. der Klick auf eine Anzeige und die
            IP-Adresse der Nutzer verarbeitet, wobei die IP-Adresse um die
            letzten beiden Stellen gekürzt wird. Daher erfolgt die Verarbeitung
            der Daten der Nutzer pseudonymisiert.
            <br />
            <br />
            Wir setzen Adsense mit personalisierten Anzeigen ein. Dabei zieht
            Google auf Grundlage der von Nutzern besuchten Websites oder
            verwendeten Apps und den so erstellten Nutzerprofilen Rückschlüsse
            auf deren Interessen. Werbetreibende nutzen diese Informationen, um
            ihre Kampagnen an diesen Interessen auszurichten, was für Nutzer und
            Werbetreibende gleichermaßen von Vorteil ist. Für Google sind
            Anzeigen dann personalisiert, wenn erfasste oder bekannte Daten die
            Anzeigenauswahl bestimmen oder beeinflussen. Dazu zählen unter
            anderem frühere Suchanfragen, Aktivitäten, Websitebesuche, die
            Verwendung von Apps, demografische und Standortinformationen. Im
            Einzelnen umfasst dies: demografisches Targeting, Targeting auf
            Interessenkategorien, Remarketing sowie Targeting auf Listen zum
            Kundenabgleich und Zielgruppenlisten, die in DoubleClick Bid Manager
            oder Campaign Manager hochgeladen wurden.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen
            einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            personenbezogenen Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
            und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
            Artikel 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin,
            dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
            hierdurch zusichert, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs-
            und Widerspruchsmöglichkeiten, erfahren Sie in der
            Datenschutzerklärung von Google (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von
            Werbeeinblendungen durch Google{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
            </a>
            ).
            <br />
            <br />
            <b>Google Adsense mit nicht-personalisierten Anzeigen</b>
            <br />
            <br />
            Wir nutzen die Dienste der Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland („Google“).
            <br />
            <br />
            Wir Nutzen den Dienst AdSense, mit dessen Hilfe Anzeigen in unsere
            Webseite eingeblendet und wir für deren Einblendung oder sonstige
            Nutzung eine Entlohnung erhalten. Zu diesen Zwecken werden
            Nutzungsdaten, wie z.B. der Klick auf eine Anzeige und die
            IP-Adresse der Nutzer verarbeitet, wobei die IP-Adresse um die
            letzten beiden Stellen gekürzt wird. Daher erfolgt die Verarbeitung
            der Daten der Nutzer pseudonymisiert.
            <br />
            <br />
            Wir setzen Adsense mit nicht-personalisierten Anzeigen ein. Dabei
            werden die Anzeigen nicht auf Grundlage von Nutzerprofilen
            angezeigt. Nicht personalisierte Anzeigen basieren nicht auf
            früherem Nutzerverhalten. Beim Targeting werden Kontextinformationen
            herangezogen, unter anderem ein grobes (z. B. auf Ortsebene)
            geografisches Targeting basierend auf dem aktuellen Standort, dem
            Inhalt auf der aktuellen Website oder der App sowie aktuelle
            Suchbegriffe. Google unterbindet jedwedes personalisierte Targeting,
            also auch demografisches Targeting und Targeting auf Basis von
            Nutzerlisten.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen
            einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser
            Verarbeitung Artikel. 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            personenbezogenen Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung
            und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des
            Artikel 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin,
            dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
            hierdurch zusichert, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs-
            und Widerspruchsmöglichkeiten, erfahren Sie in der
            Datenschutzerklärung von Google (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von
            Werbeeinblendungen durch Google{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
            </a>
            ).
            <br />
            <br />
            <b>Google AdWords und Conversion-Messung</b>
            <br />
            <br />
            Wir nutzen das Online-Marketingverfahren Google "AdWords", um
            Anzeigen im Google-Werbe-Netzwerk zu platzieren, etwa in
            Suchergebnissen, in Videos, auf Webseiten, etc., damit sie Nutzern
            angezeigt werden, die ein mutmaßliches Interesse an unseren Anzeigen
            haben. Dies erlaubt uns, Anzeigen für und innerhalb unseres
            Onlineangebotes gezielter anzuzeigen, um Nutzern nur die Anzeigen zu
            präsentieren, die potentiell deren Interessen entsprechen. Falls
            einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er
            sich auf anderen Online-Angeboten interessiert hat, spricht man
            hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer
            und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv
            ist, unmittelbar durch Google ein Code von Google ausgeführt und es
            werden sog. Remarketing-Tags (unsichtbare Grafiken oder Code, auch
             „Web Beacons" genannt) in die Webseite eingebunden. Mit deren Hilfe
            wird auf dem Gerät der Nutzer ein individuelles Cookie, oder
            vergleichbare Technologien, abgespeichert.. In dieser Datei wird
            vermerkt, welche Webseiten der Nutzer aufgesucht, für welche Inhalte
            er sich interessiert und welche Angebote der Nutzer geklickt hat,
            ferner technische Informationen zum Browser und Betriebssystem,
            verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
            des Online-Angebotes.
            <br />
            <br />
            Ferner erhalten wir ein individuelles „Conversion-Cookie“, mit
            dessen  Hilfe es Google ermöglicht wird, Conversion-Statistiken für
            uns zu erstellen. Wir erhalten jedoch nur die anonyme Gesamtanzahl
            der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit
            einem Conversion-Tracking-Tag versehenen Seite weitergeleitet
            wurden. Wir erhalten jedoch keine Informationen, mit denen sich
            Nutzer persönlich identifizieren lassen.
            <br />
            <br />
            Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks
            pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B.
            nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet
            die relevanten Daten cookie-bezogen innerhalb pseudonymer
            Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen
            nicht für eine konkret identifizierte Person verwaltet und
            angezeigt, sondern für den Cookie-Inhaber, unabhängig davon wer
            dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google
            ausdrücklich erlaubt hat, die Daten ohne eine solche
            Pseudonymisierung zu verarbeiten. Die über die Nutzer gesammelten
            Informationen werden an Google übermittelt und auf Googles Servern
            in den USA gespeichert.
            <br />
            <br />
            Sofern wir die Nutzer etwa im Rahmen einer Cookie-Einwilligung um
            eine Einwilligung bitten, ist die Rechtsgrundlage unserer
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            personenbezogenen Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen, also unser Interesse an der Analyse,
            Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im
            Sinne des Artikel 6 Abs. 1 lit. f. DSGVO verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin,
            dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
            hierdurch zusichert, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs-
            und Widerspruchsmöglichkeiten, erfahren Sie in der
            Datenschutzerklärung von Google (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von
            Werbeeinblendungen durch Google{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
            </a>
            ).
            <br />
            <br />
            <b>Google Doubleclick</b>
            <br />
            <br />
            Wir nutzen die Dienste der Google Ireland Limited, Gordon House,
            Barrow Street, Dublin 4, Irland („Google“).
            <br />
            <br />
            Wir nutzen das Online-Marketingverfahren Google "Doubleclick", um
            Anzeigen im Google-Werbe-Netzwerk zu platzieren, etwa in in
            Suchergebnissen, in Videos, auf Webseiten, etc. Double Click
            zeichnet sich dadurch aus, dass Anzeigen in Echtzeit anhand
            mutmaßlicher Interessen der Nutzer angezeigt werden. Dies erlaubt
            uns Anzeigen für und innerhalb unseres Online-Angebotes gezielter
            anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die potentiell
            deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für
            Produkte angezeigt werden, für die er sich auf anderen
            Online-Angeboten interessiert hat, spricht man hierbei vom
            „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer
            Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist,
            unmittelbar durch Google ein Code von Google ausgeführt und es
            werden sog. Remarketing-Tags (unsichtbare Grafiken oder Web Beacons)
            in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der
            Nutzer ein individuelles Cookie, d.h. eine kleine Datei
            abgespeichert (Cookies  In dieser Datei wird vermerkt, welche
            Webseiten der Nutzer aufgesucht, für welche Inhalte er sich
            interessiert und welche Angebote der Nutzer geklickt hat, ferner
            technische Informationen zum Browser und Betriebssystem, verweisende
            Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des
            Online-Angebotes.
            <br />
            <br />
            Es wird ebenfalls die IP-Adresse der Nutzer erfasst, wobei diese
            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt und nur in Ausnahmefällen ganz an einen Server von Google in
            den USA übertragen und dort gekürzt wird. Die vorstehend genannten
            Informationen können seitens Google auch mit solchen Informationen
            aus anderen Quellen verbunden werden. Wenn der Nutzer anschließend
            andere Webseiten besucht, können ihm entsprechend seiner
            mutmaßlichen Interessen auf Grundlage seines Nutzerprofiles auf ihn
            abgestimmten Anzeigen angezeigt werden.
            <br />
            <br />
            Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks
            pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B.
            nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet
            die relevanten Daten cookie-bezogen innerhalb pseudonymer
            Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen
            nicht für eine konkret identifizierte Person verwaltet und
            angezeigt, sondern für den Cookie-Inhaber, unabhängig davon wer
            dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google
            ausdrücklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu
            verarbeiten. Die von Google-Marketing-Services über die Nutzer
            gesammelten Informationen werden an Google übermittelt und auf
            Googles Servern in den USA gespeichert.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten, etwa im Rahmen
            einer Cookie-Einwilligung, ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            Daten der Nutzer auf Grundlage unserer berechtigten Interessen, also
            dem Interesse an der Analyse, Optimierung und wirtschaftlichem
            Betrieb unseres Online-Angebotes im Sinne des Artikel 6 Abs. 1 lit.
            f. DSGVO, verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin,
            dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
            hierdurch zusichert, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs-
            und Widerspruchsmöglichkeiten, erfahren Sie in der
            Datenschutzerklärung von Google (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von
            Werbeeinblendungen durch Google{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
            </a>
            ).
            <br />
            <br />
            <b>Google-Firebase</b>
            <br />
            <br />
            Wir nutzen die Entwicklerplattform „Google Firebase“ und die mit ihr
            verbundenen Funktionen und Dienste, angeboten von Google Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland.
            <br />
            <br />
            Bei Google Firebase handelt es sich um eine Plattform für Entwickler
            von Applikationen (kurz „Apps“) für mobile Geräte und Webseiten.
            Google Firebase bietet eine Vielzahl von Funktionen, die auf der
            folgenden Übersichtsseite dargestellt werden:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://firebase.google.com/products/">
                https://firebase.google.com/products/
            </a>
            .
            <br />
            <br />
            Die Funktionen umfassen unter anderem die Speicherung von Apps
            inklusive personenbezogener Daten der Applikationsnutzer, wie z.B.
            von ihnen erstellter Inhalte oder Informationen betreffend ihre
            Interaktion mit den Apps (sog. „Cloud Computing“). Google Firebase
            bietet daneben Schnittstellen, die eine Interaktion zwischen den
            Nutzern der App und anderen Diensten erlauben, z.B. die
            Authentifizierung mittels Diensten Dritter wie Facebook, Twitter
            oder mittels einer E-Mail-Passwort-Kombination.
            <br />
            <br />
            Die Auswertung der Interaktionen der Nutzer kann mithilfe des
            Analyse-Dienstes „Firebase Analytics“ erfolgen. Firebase Analytics
            ist darauf gerichtet zu erfassen, wie Nutzer mit einer App
            interagieren. Dabei werden Ereignisse (sog „Events“) erfasst, wie
            z.B. das erstmalige Öffnen der App, Deinstallation, Update, Absturz
            oder Häufigkeit der Nutzung der App. Mit den Events können auch
            weitere Nutzerinteressen, z.B. für bestimmte Funktionen der
            Applikationen oder bestimmte Themengebiete erfasst werden. Hierdurch
            können auch Nutzerprofile erstellt werden, die z.B. als Grundlage
            für die Darstellung von auf Nutzer zugeschnittenen Werbehinweisen,
            verwendet werden können.
            <br />
            <br />
            Google Firebase und die mittels von Google Firebase verarbeiteten
            personenbezogenen Daten der Nutzer können ferner zusammen mit
            weiteren Diensten von Google, wie z.B. Google Analytics und den
            Google-Marketing-Diensten und Google Analytics verwendet werden (in
            diesem Fall werden auch gerätebezogene Informationen, wie „Android
            Advertising ID“ and „Advertising Identifier for iOS“ verarbeitet, um
            mobile Geräte der Nutzer zu identifizieren).
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten, etwa im Rahmen
            einer Cookie-Einwilligung, ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            Daten der Nutzer auf Grundlage unserer berechtigten Interessen, also
            dem  Interesse an der Analyse, Optimierung und wirtschaftlichem
            Betrieb unseres Online-Angebotes im Sinne des Artikel 6 Abs. 1 lit.
            f. DSGVO, verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin,
            dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und
            hierdurch zusichert, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Die Datenschutzerklärung von Google ist unter{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/privacy">
                https://policies.google.com/privacy
            </a>{' '}
            abrufbar. Weitere Informationen zur Datennutzung zu Marketingzwecken
            durch Google, erfahren Nutzer auf der Übersichtsseite:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads?hl=de">
                https://policies.google.com/technologies/ads?hl=de
            </a>
            ,
            <br />
            <br />
            Wenn Nutzer der interessensbezogenen Werbung durch
            Google-Marketing-Dienste widersprechen möchten, können Nutzer die
            von Google gestellten Einstellungs- und Opt-Out-Möglichkeiten
            nutzen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/">
                https://adssettings.google.com/
            </a>
            <br />
            <br />
            <b>Jetpack (WordPress Stats)</b>
            <br />
            <br />
            Wir nutzen das Plugin Jetpack (hier die Unterfunktion „Wordpress
            Stats“), das ein Tool zur statistischen Auswertung der
            Besucherzugriffe einbindet und von Automattic Inc., 60 29th Street
            #343, San Francisco, CA 94110, USA. Jetpack verwendet sog. Cookies,
            die eine Analyse der Benutzung der Website durch Sie ermöglichen.
            <br />
            <br />
            Die durch das Cookie erzeugten Informationen über Ihre Benutzung
            dieses Onlineangebotes werden auf einem Server in den USA
            gespeichert. Dabei können aus den verarbeiteten Daten
            Nutzungsprofile der Nutzer erstellt werden, wobei diese nur zu
            Analyse- und nicht zu Werbezwecken eingesetzt werden. Weitere
            Informationen erhalten Sie in den Datenschutzerklärungen von
            Automattic:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://automattic.com/privacy/">
                https://automattic.com/privacy/
            </a>{' '}
            und Hinweisen zu Jetpack-Cookies:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://jetpack.com/support/cookies/">
                https://jetpack.com/support/cookies/
            </a>
            .
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten, etwa im Rahmen
            einer Cookie-Einwilligung, ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            Daten der Nutzer auf Grundlage unserer berechtigten Interessen, also
            unser Interesse an der Analyse, Optimierung und wirtschaftlichem
            Betrieb unseres Online-Angebotes im Sinne des Art. 6 Abs. 1 lit. f.
            DSGVO, verarbeitet.
            <br />
            <br />
            <b>Facebook-Pixel, Custom Audiences und Facebook-Conversion</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes wird das sog. "Facebook-Pixel"
            des sozialen Netzwerkes Facebook, das von der Facebook Ireland Ltd.,
            4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
            betrieben wird “Facebook"), eingesetzt.
            <br />
            <br />
            Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die
            Besucher unseres Online-Angebotes als Zielgruppe für die Darstellung
            von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend
            setzen wir das Facebook-Pixel ein, um die durch uns geschalteten
            Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein
            Interesse an unserem Online-Angebot gezeigt haben oder die bestimmte
            Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die
            anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir
            an Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des
            Facebook-Pixels möchten wir auch sicherstellen, dass unsere
            Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und
            nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir
            ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische
            und Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer
            nachdem Klick auf eine Facebook-Werbeanzeige auf unsere Website
            weitergeleitet wurden (sog. „Conversion“). Die Verarbeitung der
            Daten durch Facebook erfolgt im Rahmen von Facebooks
            Datenverwendungsrichtlinie. Dementsprechend generelle Hinweise zur
            Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von
            Facebook:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/policy">
                https://www.facebook.com/policy
            </a>
            . Spezielle Informationen und Details zum Facebook-Pixel und seiner
            Funktionsweise erhalten Sie im Hilfebereich von Facebook:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/business/help/651294705016616">
                https://www.facebook.com/business/help/651294705016616
            </a>
            .
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten, etwa im Rahmen
            einer Cookie-Einwilligung, ist die Rechtsgrundlage dieser
            Verarbeitung Artikel. 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            Daten der Nutzer auf Grundlage unserer berechtigten Interessen, also
            unserem Interesse an der Analyse, Optimierung und wirtschaftlichem
            Betrieb unseres Online-Angebotes im Sinne des Artikel 6 Abs. 1 lit.
            f. DSGVO, verarbeitet.
            <br />
            <br />
            Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und
            sichert hierdurch zu, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Sie können der Erfassung durch den Facebook-Pixel und Verwendung
            Ihrer Daten zur Darstellung von Facebook-Ads widersprechen. Um
            einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von
            Facebook angezeigt werden, können Sie die von Facebook eingerichtete
            Seite aufrufen und dort die Hinweise zu den Einstellungen
            nutzungsbasierter Werbung befolgen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/settings?tab=ads">
                https://www.facebook.com/settings?tab=ads
            </a>
            . Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden
            für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
            <br />
            <br />
            Sie können dem Einsatz von Cookies, die der Reichweitenmessung und
            Werbezwecken dienen, ferner über die Deaktivierungsseite der
            Netzwerkwerbeinitiative (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://optout.networkadvertising.org/">
                http://optout.networkadvertising.org/
            </a>
            ) und zusätzlich die US-amerikanische Webseite (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.aboutads.info/choices">
                http://www.aboutads.info/choices
            </a>
            ) oder die europäische Webseite (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.youronlinechoices.com/uk/your-ad-choices/">
                http://www.youronlinechoices.com/uk/your-ad-choices/
            </a>
            ) widersprechen.
            <br />
            <br />
            <b>Bing Ads</b>
            <br />
            <br />
            Wir setzen innerhalb unseres Online-Angebotes das Conversion- und
            Tracking-Tool „Bing Ads“ der Microsoft Corporation, One Microsoft
            Way, Redmond, WA 98052-6399, USA, ein. Dabei werden von Microsoft
            Cookies auf den Geräten der Nutzer gespeichert, um eine Analyse der
            Benutzung unseres Onlineangebotes durch die Nutzer zu ermöglichen,
            sofern Nutzer über eine Microsoft-Bing-Anzeige auf unser
            Onlineangebot gelangt sind (sog. "Conversion-Messung"). Microsoft
            und wir können auf diese Weise erkennen, dass jemand auf eine
            Anzeige geklickt hat, zu unserem Onlineangebot weitergeleitet wurde
            und eine vorher bestimmte Zielseite (sog "Conversion Seite")
            erreicht hat. Wir erfahren dabei nur die Gesamtzahl der Nutzer, die
            auf eine Bing Anzeige geklickt haben und dann zu der Conversion
            Seite weitergeleitet wurden. Es werden keine IP-Adressen
            gespeichert. Es werden keine personenbezogenen Informationen zur
            Identität der Nutzer mitgeteilt.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten, etwa im Rahmen
            einer Cookie-Einwilligung, ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            Daten der Nutzer auf Grundlage unserer berechtigten Interessen, also
            unserem Interesse an der Analyse, Optimierung und wirtschaftlichem
            Betrieb unseres Online-Angebotes im Sinne des Art. 6 Abs. 1 lit. f.
            DSGVO, verarbeitet.
            <br />
            <br />
            Microsoft ist unter dem Privacy-Shield-Abkommen zertifiziert und
            sichert hierdurch zu, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Wenn Nutzer nicht an dem Tracking-Verfahren von Bing Ads teilnehmen
            möchten, können Sie auch das hierfür erforderliche Setzen eines
            Cookies per Browser-Einstellung deaktivieren oder die Opt-Out-Seite
            von Microsoft nutzen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://choice.microsoft.com/de-DE/opt-out">
                http://choice.microsoft.com/de-DE/opt-out
            </a>
            .
            <br />
            <br />
            Weitere Informationen zum Datenschutz und zu den eingesetzten
            Cookies bei Microsoft Bing Ads finden Nutzer in der
            Datenschutzerklärung von Microsoft:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://privacy.microsoft.com/de-de/privacystatement">
                https://privacy.microsoft.com/de-de/privacystatement
            </a>
            .
            <br />
            <br />
            <b>Criteo</b>
            <br />
            <br />
            Wir setzen die Onlinemarketingleistungen des Anbieters Criteo GmbH,
            Gewürzmühlstr. 11, 80538 München, Deutschland ein.
            <br />
            <br />
            Die Leistungen von Criteo erlauben uns Werbeanzeigen für und auf
            unserer Website gezielter anzuzeigen, um Nutzern nur Anzeigen zu
            präsentieren, die potentiell deren Interessen entsprechen. Falls
            einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er
            sich auf anderen Webseiten interessiert hat, spricht man hierbei vom
            „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer
            Webseiten, auf denen Criteo aktiv ist, unmittelbar durch Criteo ein
            Code von Criteo ausgeführt und es werden in die Website sog.
            (Re)marketing-Tags (unsichtbare Grafiken oder Web Beacons)
            eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein
            individuelles Cookie oder eine vergleichbare
            Technologienabgespeichert. In dieser Datei wird vermerkt, welche
            Webseiten der Nutzer aufgesucht, für welche Inhalte er sich
            interessiert und welche Angebote er geklickt hat, ferner technische
            Informationen zum Browser und Betriebssystem, verweisende Webseiten,
            Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes.
            Die vorstehend genannten Informationen können seitens Criteo auch
            mit solchen Informationen aus anderen Quellen verbunden werden. Wenn
            der Nutzer anschließend andere Webseiten besucht, können ihm
            entsprechend seiner Interessen die auf ihn abgestimmten Anzeigen
            angezeigt werden.
            <br />
            <br />
            Die Verarbeitung der Daten der Nutzer erfolgt pseudonym, d.h. es
            werden keine Klardaten der Nutzer (wie z.B. Namen) verarbeitet und
            IP-Adressen der Nutzer werden verkürzt. Die Verarbeitung erfolgt nur
            auf der Grundlage einer Onlinekennung, einer technischen ID. Etwaige
            Criteo mitgeteilte IDs (z.B. eines Kundenbetreuungssystems) oder
            E-Mailadressen, werden als so genannte Hashwerte verschlüsselt und
            als eine Serie von Zeichen, die keine Identifizierung erlauben,
            gespeichert.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten, etwa im Rahmen
            einer Cookie-Einwilligung, ist die Rechtsgrundlage dieser
            Verarbeitung Artikel 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die
            personenbezogenen Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen, also unserem Interesse an der Analyse,
            Optimierung und wirtschaftlichem Betrieb unseres Online-Angebotes im
            Sinne des Artikel 6 Abs. 1 lit. f. DSGVO, verarbeitet.
            <br />
            <br />
            Weitere Informationen ebenso wie Widerspruchsmöglichkeiten (Opt-Out)
            in die Erfassung durch Criteo finden Sie in den
            Datenschutzbestimmungen von Criteo:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.criteo.com/de/privacy/">
                https://www.criteo.com/de/privacy/
            </a>
            .
            <br />
            <br />
            <b>Online-Angebote in sozialen Medien</b>
            <br />
            <br />
            Wir unterhalten Online-Angebote innerhalb sozialer Netzwerke und
            Plattformen, um mit den dort aktiven Kunden, Interessenten und
            Nutzern kommunizieren und sie dort über unsere Leistungen
            informieren zu können.
            <br />
            <br />
            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
            Raumes der Europäischen Union verarbeitet werden können. Hierdurch
            können sich für die Nutzer Risiken ergeben, weil so z.B. die
            Durchsetzung der Rechte der Nutzer erschwert werden könnte. Im
            Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert
            sind, weisen wir darauf hin, dass sie sich damit verpflichten, die
            Datenschutzstandards der EU einzuhalten.
            <br />
            <br />
            Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs-
            und Werbezwecke verarbeitet. So können z.B. aus dem
            Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer
            Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum
            verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der
            Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer
            entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den
            Computern der Nutzer gespeichert, in denen das Nutzungsverhalten und
            die Interessen der Nutzer gespeichert werden. Ferner können in den
            Nutzungsprofilen auch Daten unabhängig der von den Nutzern
            verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer
            Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt
            sind).
            <br />
            <br />
            Die Verarbeitung der Daten der Nutzer erfolgt auf Grundlage unserer
            berechtigten Interessen an einer effektiven Information der Nutzer
            und Kommunikation mit den Nutzern gemäß Artikel 6 Abs. 1 lit. f.
            DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen
            um eine Einwilligung in die Datenverarbeitung gebeten werden, ist
            die Rechtsgrundlage der Verarbeitung Artikel 6 Abs. 1 lit. a.,
            Artikel 7 DSGVO.
            <br />
            <br />
            Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und
            der Widerspruchsmöglichkeiten (Opt-Out), verweisen wir auf die
            nachfolgend verlinkten Angaben der Anbieter.
            <br />
            <br />
            Auch im Fall von Auskunftsanfragen und der Geltendmachung von
            Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei
            den Anbietern geltend gemacht werden können. Nur die Anbieter haben
            jeweils Zugriff auf die Daten der Nutzer und können direkt
            entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
            dennoch Hilfe benötigen, können Sie sich gerne jederzeit an uns
            wenden.
            <ul>
                <li>
                    Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand
                    Canal Square, Grand Canal Harbour, Dublin 2, Irland) auf
                    Grundlage einer{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/legal/terms/page_controller_addendum">
                        Vereinbarung über gemeinsame Verarbeitung
                        personenbezogener Daten
                    </a>{' '}
                    - Datenschutzerklärung:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/about/privacy/">
                        https://www.facebook.com/about/privacy/
                    </a>
                    , speziell für Seiten:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/legal/terms/information_about_page_insights_data">
                        https://www.facebook.com/legal/terms/information_about_page_insights_data
                    </a>{' '}
                    , Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/settings?tab=ads">
                        https://www.facebook.com/settings?tab=ads
                    </a>{' '}
                    und{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://www.youronlinechoices.com">
                        http://www.youronlinechoices.com
                    </a>
                    , Privacy Shield:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">
                        https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
                    </a>
                    .
                </li>
                <li>
                    Google/ YouTube (Google Ireland Limited, Gordon House,
                    Barrow Street, Dublin 4, Irland) – Datenschutzerklärung:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://policies.google.com/privacy">
                        https://policies.google.com/privacy
                    </a>
                    , Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://adssettings.google.com/authenticated">
                        https://adssettings.google.com/authenticated
                    </a>
                    , Privacy Shield:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
                    </a>
                    .
                </li>
                <li>
                    Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA,
                    94025, USA) – Datenschutzerklärung/ Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://instagram.com/about/legal/privacy/">
                        http://instagram.com/about/legal/privacy/
                    </a>
                    .
                </li>
                <li>
                    Twitter (Twitter Inc., 1355 Market Street, Suite 900, San
                    Francisco, CA 94103, USA) - Datenschutzerklärung:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/de/privacy">
                        https://twitter.com/de/privacy
                    </a>
                    , Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/personalization">
                        https://twitter.com/personalization
                    </a>
                    , Privacy Shield:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">
                        https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
                    </a>
                    .
                </li>
                <li>
                    Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA,
                    94301, USA) – Datenschutzerklärung/ Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://about.pinterest.com/de/privacy-policy">
                        https://about.pinterest.com/de/privacy-policy
                    </a>
                    .
                </li>
                <li>
                    LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place,
                    Dublin 2, Irland) - Datenschutzerklärung{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/legal/privacy-policy">
                        https://www.linkedin.com/legal/privacy-policy
                    </a>{' '}
                    , Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                    </a>
                    , Privacy Shield:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active">
                        https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active
                    </a>
                    .
                </li>
                <li>
                    Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg,
                    Deutschland) - Datenschutzerklärung/ Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://privacy.xing.com/de/datenschutzerklaerung">
                        https://privacy.xing.com/de/datenschutzerklaerung
                    </a>
                    .
                </li>
                <li>
                    Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3
                    4PR, United Kingdom) - Datenschutzerklärung/ Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://wakelet.com/privacy.html">
                        https://wakelet.com/privacy.html
                    </a>
                    .
                </li>
                <li>
                    Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77,
                    10115 Berlin, Deutschland) - Datenschutzerklärung/ Opt-Out:{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://soundcloud.com/pages/privacy">
                        https://soundcloud.com/pages/privacy
                    </a>
                    .
                </li>
            </ul>
            <b>Einbindung von Diensten und Inhalten Dritter</b>
            <br />
            <br />
            Wir setzen innerhalb unseres Online-Angebotes auf Grundlage unserer
            berechtigten Interessen, also unserem Interesse an der Analyse,
            Optimierung und wirtschaftlichem Betrieb unseres Online-Angebotes im
            Sinne des Artikel 6 Abs. 1 lit. f. DSGVO, Inhalts- oder
            Serviceangebote von Drittanbietern ein, um deren Dienste, wie z.B.
            Videos oder Schriftarten einzubinden (nachfolgend einheitlich
            bezeichnet als “Inhalte”).
            <br />
            <br />
            Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die
            IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die
            Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
            damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen
            uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
            IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
            Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare
            Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
            Marketingzwecke verwenden. Durch die "Pixel-Tags" können
            Informationen, wie der Besucherverkehr auf den Seiten dieser Website
            ausgewertet werden. Die pseudonymen Informationen können ferner in
            Cookies auf dem Gerät der Nutzer gespeichert werden und unter
            anderem technische Informationen zum Browser und Betriebssystem,
            verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung
            unseres Onlineangebotes enthalten, als auch mit solchen
            Informationen aus anderen Quellen verbunden werden.
            <br />
            <br />
            <b>Youtube</b>
            <br />
            <br />
            Wir binden die Videos der Plattform “YouTube” des Anbieters Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
            Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/policies/privacy/">
                https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                https://adssettings.google.com/authenticated
            </a>
            .
            <br />
            <br />
            <b>Google Fonts</b>
            <br />
            <br />
            Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
            Nach Angaben von Google werden die Daten der Nutzer allein zu
            Zwecken der Darstellung der Schriftarten im Browser der Nutzer
            verwendet. Die Einbindung erfolgt auf Grundlage unserer berechtigten
            Interessen an einer technisch sicheren, wartungsfreien und
            effizienten Nutzung von Schriftarten, deren einheitlicher
            Darstellung sowie Berücksichtigung möglicher lizenzrechtlicher
            Restriktionen für deren Einbindung. Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/policies/privacy/">
                https://www.google.com/policies/privacy/
            </a>
            .
            <br />
            <br />
            <b>Google ReCaptcha</b>
            <br />
            <br />
            Wir binden die Funktion zur Erkennung von Bots, z.B. bei Eingaben in
            Onlineformularen ("ReCaptcha") des Anbieters GGoogle Ireland
            Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
            Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/policies/privacy/">
                https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                https://adssettings.google.com/authenticated
            </a>
            .
            <br />
            <br />
            <b>Google Maps</b>
            <br />
            <br />
            Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland, ein. Zu den verarbeiteten Daten können insbesondere
            IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht
            ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen
            ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in
            den USA verarbeitet werden. Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/policies/privacy/">
                https://www.google.com/policies/privacy/
            </a>
            , Opt-Out:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                https://adssettings.google.com/authenticated
            </a>
            .
            <br />
            <br />
            <b>OpenStreetMap</b>
            <br />
            <br />
            Wir binden die Landkarten des Dienstes "OpenStreetMap" ein (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.openstreetmap.de">
                https://www.openstreetmap.de
            </a>
            ), die auf Grundlage der Open Data Commons Open Database Lizenz
            (ODbL) durch die OpenStreetMap Foundation (OSMF) angeboten werden.
            Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wiki.openstreetmap.org/wiki/Privacy_Policy">
                https://wiki.openstreetmap.org/wiki/Privacy_Policy
            </a>
            .
            <br />
            <br />
            Nach unserer Kenntnis werden die Daten der Nutzer durch
            OpenStreetMap ausschließlich zu Zwecken der Darstellung der
            Kartenfunktionen und Zwischenspeicherung der gewählten Einstellungen
            verwendet. Zu diesen Daten können insbesondere IP-Adressen und
            Standortdaten der Nutzer gehören, die jedoch nicht ohne deren
            Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer
            Mobilgeräte vollzogen), erhoben werden.
            <br />
            <br />
            Die Daten können in den USA verarbeitet werden. Weitere
            Informationen können Sie der Datenschutzerklärung von OpenStreetMap
            entnehmen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wiki.openstreetmap.org/wiki/Privacy_Policy">
                https://wiki.openstreetmap.org/wiki/Privacy_Policy
            </a>
            .
            <br />
            <br />
            <b>Verwendung von Facebook Social Plugins</b>
            <br />
            <br />
            Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h.
            Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
            unseres Online-Angebotes im Sinne des Artikel 6 Abs. 1 lit. f.
            DSGVO) Social Plugins ("Plugins") des sozialen Netzwerkes
            facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal
            Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird
            („Facebook").
            <br />
            <br />
            Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und
            Schaltflächen gehören, mit denen Nutzer Inhalte dieses
            Online-Angebotes innerhalb von Facebook teilen können. Die Liste und
            das Aussehen der Facebook Social Plugins kann hier eingesehen
            werden:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://developers.facebook.com/docs/plugins/">
                https://developers.facebook.com/docs/plugins/
            </a>
            .
            <br />
            <br />
            Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und
            bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Wenn ein Nutzer eine Funktion dieses Online-Angebotes aufruft, die
            ein solches Plugin enthält, baut sein Gerät eine direkte Verbindung
            mit den Servern von Facebook auf. Der Inhalt des Plugins wird von
            Facebook direkt an das Gerät des Nutzers übermittelt und von diesem
            in das Online-Angebot eingebunden. Dabei können aus den
            verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir
            haben daher keinen Einfluss auf den Umfang der Daten, die Facebook
            mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher
            entsprechend unserem Kenntnisstand.
            <br />
            <br />
            Durch die Einbindung der Plugins erhält Facebook die Information,
            dass ein Nutzer die entsprechende Seite des Online-Angebotes
            aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann
            Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit
            den Plugins interagieren, zum Beispiel den Like Button betätigen
            oder einen Kommentar abgeben, wird die entsprechende Information von
            Ihrem Gerät direkt an Facebook übermittelt und dort gespeichert.
            Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem
            die Möglichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt
            und speichert. Laut Facebook wird in Deutschland nur eine
            anonymisierte IP-Adresse gespeichert.
            <br />
            <br />
            Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und
            Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte
            und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der
            Nutzer, können diese den Datenschutzhinweisen von Facebook
            entnehmen:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/about/privacy/">
                https://www.facebook.com/about/privacy/
            </a>
            .
            <br />
            <br />
            Wenn ein Nutzer Facebook-Mitglied ist und nicht möchte, dass
            Facebook über dieses Online-Angebot Daten über ihn sammelt und mit
            seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er
            sich vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen
            und seine Cookies löschen. Weitere Einstellungen und Widersprüche
            zur Nutzung von Daten für Werbezwecke, sind innerhalb der
            Facebook-Profileinstellungen möglich:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/settings?tab=ads">
                https://www.facebook.com/settings?tab=ads
            </a>{' '}
            oder über die US-amerikanische Seite{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.aboutads.info/choices/">
                http://www.aboutads.info/choices/
            </a>{' '}
            oder die EU-Seite{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.youronlinechoices.com/">
                http://www.youronlinechoices.com/
            </a>
            . Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden
            für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
            <br />
            <br />
            <b>Twitter</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes können Funktionen und Inhalte des
            Dienstes Twitter, angeboten durch die Twitter Inc., 1355 Market
            Street, Suite 900, San Francisco, CA 94103, USA, eingebunden werden.
            Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und
            Schaltflächen gehören, mit denen Nutzer Inhalte dieses
            Online-Angebotes innerhalb von Twitter teilen können.
            <br />
            <br />
            Sofern die Nutzer Mitglieder der Plattform Twitter sind, kann
            Twitter den Aufruf der o.g. Inhalte und Funktionen den dortigen
            Profilen der Nutzer zuordnen. Twitter ist unter dem
            Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
            Garantie, das europäische Datenschutzrecht einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active
            </a>
            ). Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/de/privacy">
                https://twitter.com/de/privacy
            </a>
            , Opt-Out:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/personalization">
                https://twitter.com/personalization
            </a>
            .
            <br />
            <br />
            <b>Instagram</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes können Funktionen und Inhalte des
            Dienstes Instagram, angeboten durch die Instagram Inc., 1601 Willow
            Road, Menlo Park, CA, 94025, USA, eingebunden werden. Hierzu können
            z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
            gehören, mit denen Nutzer Inhalte dieses Online-Angebotes innerhalb
            von Instagram teilen können. Sofern die Nutzer Mitglieder der
            Plattform Instagram sind, kann Instagram den Aufruf der o.g. Inhalte
            und Funktionen den dortigen Profilen der Nutzer zuordnen.
            Datenschutzerklärung von Instagram:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://instagram.com/about/legal/privacy/">
                http://instagram.com/about/legal/privacy/
            </a>
            .
            <br />
            <br />
            <b>Pinterest</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes können Funktionen und Inhalte des
            Dienstes Pinterest, angeboten durch die Pinterest Inc., 635 High
            Street, Palo Alto, CA, 94301, USA, eingebunden werden. Hierzu können
            z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
            gehören, mit denen Nutzer Inhalte dieses Online-Angebotes innerhalb
            von Pinterest teilen können. Sofern die Nutzer Mitglieder der
            Plattform Pinterest sind, kann Pinterest den Aufruf der o.g. Inhalte
            und Funktionen den dortigen Profilen der Nutzer zuordnen.
            Datenschutzerklärung von Pinterest:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://about.pinterest.com/de/privacy-policy">
                https://about.pinterest.com/de/privacy-policy
            </a>
            .
            <br />
            <br />
            <b>Xing</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes können Funktionen und Inhalte des
            Dienstes Xing, angeboten durch die XING AG, Dammtorstraße 29-32,
            20354 Hamburg, Deutschland, eingebunden werden. Hierzu können z.B.
            Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit
            denen Nutzer Inhalte dieses Onlineangebotes innerhalb von Xing
            teilen können. Sofern die Nutzer Mitglieder der Plattform Xing sind,
            kann Xing den Aufruf der o.g. Inhalte und Funktionen den dortigen
            Profilen der Nutzer zuordnen. Datenschutzerklärung von Xing:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://privacy.xing.com/de/datenschutzerklaerung">
                https://privacy.xing.com/de/datenschutzerklaerung
            </a>
            .
            <br />
            <br />
            <b>LinkedIn</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes können Funktionen und Inhalte des
            Dienstes LinkedIn, angeboten durch die LinkedIn Ireland Unlimited
            Company Wilton Place, Dublin 2, Irland, eingebunden werden. Hierzu
            können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
            gehören, mit denen Nutzer Inhalte dieses Online-Angebotes innerhalb
            von LinkedIn teilen können. Sofern die Nutzer Mitglieder der
            Plattform LinkedIn sind, kann LinkedIn den Aufruf der o.g. Inhalte
            und Funktionen den dortigen Profilen der Nutzer zuordnen.
            Datenschutzerklärung von LinkedIn:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/legal/privacy-policy">
                https://www.linkedin.com/legal/privacy-policy.
            </a>
            . LinkedIn ist unter dem Privacy-Shield-Abkommen zertifiziert und
            bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active
            </a>
            ). Datenschutzerklärung:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/legal/privacy-policy">
                https://www.linkedin.com/legal/privacy-policy
            </a>
            , Opt-Out:{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
            </a>
            .
            <br />
            <br />
            <b>Google+</b>
            <br />
            <br />
            Innerhalb unseres Online-Angebotes können Funktionen und Inhalte der
            Plattform Google+, angeboten durch die Google Ireland Limited,
            Gordon House, Barrow Street, Dublin 4, Irland („Google“),
            eingebunden werden. Hierzu können z.B. Inhalte wie Bilder, Videos
            oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte
            dieses Onlineangebotes innerhalb von Google teilen können. Sofern
            die Nutzer Mitglieder der Plattform Google+ sind, kann Google den
            Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
            Nutzer zuordnen.
            <br />
            <br />
            Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
            hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
                https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ). Weitere Informationen zur Datennutzung durch Google,
            Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der
            Datenschutzerklärung von Google (
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://policies.google.com/technologies/ads">
                https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von
            Werbeeinblendungen durch Google{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://adssettings.google.com/authenticated">
                (https://adssettings.google.com/authenticated
            </a>
            ).
        </WgTypography>
    );
}

import React from 'react';
import {
    Chip,
    ChipProps,
    createStyles,
    Theme,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            float: 'inline-end',
            '& .MuiChip-labelSmall': {
                paddingRight: 0
            },
            '& .MuiChip-iconSmall': {
                marginTop: 0,
                marginRight: '-8px'
            }
        },
        number: {
            fontFamily: 'Rubik',
            fontWeight: 400,
            borderColor: 'transparent'
        },
        rootWarning: {
            color: theme.palette.error.main
        },
        rootSecondary: {
            color: theme.palette.success.main
        },
        rootSecondaryOutlined: {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: `${theme.palette.secondary.main}19`,
            color: theme.palette.text.secondary,
            textTransform: 'uppercase',
            fontSize: '0.625rem',
            letterSpacing: '1px',
            fontFamily: 'Montserrat',
            fontWeight: 600
        },
        rootDisabled: {
            color: theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: theme.palette.text.secondary,
                color: theme.palette.text.disabled
            }
        },
        rootBrightDisabled: {
            color: theme.palette.action.disabled
        },
        filled: {
            color: theme.palette.common.white,
            paddingRight: '8px' // needed because chip is overridden in theme with paddingRight: 0
        },
        filledWarning: {
            backgroundColor: theme.palette.error.light
        },
        filledSecondary: {
            backgroundColor: theme.palette.secondary.main
        },
        filledDisabled: {
            backgroundColor: theme.palette.text.secondary
        },
        filledBrightDisabled: {
            backgroundColor: theme.palette.action.disabled
        }
    });

export type WgChipColors =
    | 'warning'
    | 'secondary'
    | 'secondaryOutlined'
    | 'brightDisabled'
    | 'disabled';
export interface WgChipProps extends ChipProps {
    filled?: boolean;
    classes?: any;
    number?: boolean;
    customColor: WgChipColors;
}

const WgChip = ({
    filled,
    customColor,
    classes,
    number,
    ...other
}: WgChipProps) => {
    let color = customColor.replace(/^./, customColor[0].toUpperCase());
    return (
        <Chip
            className={clsx(classes['root'], {
                [classes['number']]: number,
                [`${classes['filled']} ${classes['filled' + color]}`]: filled,
                [`${classes['root' + color]}`]: true
            })}
            variant={filled ? 'default' : 'outlined'}
            {...other}
        />
    );
};

export default withStyles(styles)(WgChip);

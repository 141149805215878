import React from 'react';
import {Box} from '@material-ui/core';
import {WgTypography} from '../../styles/CustomComponents';
import './Welcome.scss';
import BuildIcon from '@material-ui/icons/Build';

export function Maintenance() {
    return (
        <div id="Register" className="welcome-component">
            <picture>
                <source
                    media="(min-width: 960px)"
                    srcSet="/img/background/mountain-lake-bg-original.jpg"
                />
                <img
                    src="/img/background/mountain-lake-bg-smartphone.jpg"
                    className="main-image"
                    alt="Mountain Lake Background for SmartPhone"
                />
            </picture>

            <div
                className="welcome-container"
                style={{justifyContent: 'center'}}>
                <Box
                    alignSelf="center"
                    maxWidth="fit-content"
                    position="relative">
                    <img
                        src="/img/logowechselgott.svg"
                        className="welcome-img"
                        alt="WechselGott Logo"
                    />
                    <Box position="absolute" top="-28px" right="-12px">
                        <BuildIcon fontSize="large" color="primary" />
                    </Box>
                </Box>
                <Box m="1.125rem 0">
                    <WgTypography
                        color="primary"
                        align="center"
                        text="contentInfo"
                        style={{fontWeight: 900}}>
                        WechselGott
                    </WgTypography>
                </Box>
                <div className="welcome-cloud">
                    <WgTypography
                        noWrap
                        align="center"
                        color="primary"
                        header="uppercaseSpacing"
                        style={{
                            fontWeight: 800,
                            letterSpacing: '3px',
                            color: '#3ccfc7'
                        }}>
                        Wir sind gleich <br /> wieder für dich da!
                    </WgTypography>
                </div>
            </div>
        </div>
    );
}

import React, {useCallback, useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {Box, Grid} from '@material-ui/core';
import {push} from 'connected-react-router';
import {useHistory, useLocation, useParams} from 'react-router';
import {MeterType} from '../../../../../models/productInterface';
import {setNotification} from '../../../../../store/actions/notificationActions';
import {
    categoryByMeterTypeSelector,
    companyByIdSelector,
    offerByIdSelector,
    recurringPaymentByIdSelector,
    singleAccountSelector
} from '../../../../../store/reducers/selectors';
import {
    IContractManualAddition,
    IntervalType,
    IRecurringpayment
} from '../../../../../models/recurringpaymentInterface';
import {
    EnergyStep,
    getTitleFromEnergyStep,
    IEnergyContractPreferences,
    IEnergyOfferContractValidation,
    IEnergyProduct,
    IOffer
} from '../../../../../models/offerInterface';
import MeterTypeInput from './MeterTypeInput';
import AreaInput from './AreaInput';
import {CompanyType} from '../../../../../models/companyInterface';
import CompanySelection from '../../../../utils/CompanySelection';
import CostOrUsageInput from './CostOrUsageInput';
import OfferSelection from './OfferSelection';
import {
    handleUnexpectedError,
    submitEnergyContractCompletion,
    submitEnergyContractPreferences,
    submitEnergyOfferChoice
} from '../../../../../store/actions/offerActions';
import ContractCompletion from './ContractCompletion';
import {existsBrokermandate} from '../../../../../store/reducers/selectors/userSelector';
import MeterNumberInput from './MeterNumberInput';
import {
    submitRecurringPayment,
    updateMeterNumber
} from '../../../../../store/actions/recurringpaymentActions';
import {formatISO} from 'date-fns';
import {fetchRequiredInformations} from '../../../../../store/actions/userActions';
import BrokerMandateNoMandateExistsText from '../../../profile/documents/BrokerMandateNoMandateExistsText';
import {BrokerMandateEdit} from '../../../index';
import AccountSelection from '../../../../utils/AccountSelection';
import {convertStringToPaymentInterval} from '../../../../utils/Format';

const invalidId = -1;

export interface EnergyControllerProps {
    postcode?: string;
    offer?: IOffer;
    push: Function;
    fetchRequiredInformations: Function;
    submitEnergyOfferChoice: Function;
    updateRecurringPayment: (recurringPayment: IRecurringpayment) => void;
    updateMeterNumber: Function;
    submitEnergyContractCompletion: Function;
    submitRecurringPayment: (
        recurringPaymentDetails: IContractManualAddition,
        energyData: IEnergyContractPreferences
    ) => void;
    submitEnergyContractPreferences: Function;
    recurringPayment?: IRecurringpayment;
    match: any;
    hasBrokermandate?: boolean;
}

function EnergyController({
    postcode,
    offer,
    push,
    fetchRequiredInformations,
    submitEnergyOfferChoice,
    submitEnergyContractCompletion,
    submitRecurringPayment,
    updateMeterNumber,
    recurringPayment,
    hasBrokermandate,
    submitEnergyContractPreferences
}: EnergyControllerProps) {
    const {currentStep} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const activeStep: EnergyStep = currentStep
        ? Number(currentStep)
        : EnergyStep.METER_TYPE_INPUT;
    const location = useLocation<{
        meterType: MeterType;
        isManualAddition?: boolean;
        categoryId?: number;
        isOnlyMeterNumberSubmission?: boolean;
    }>();
    const [isManualAddition] = useState(location.state?.isManualAddition);
    const isContractConclusion =
        activeStep === EnergyStep.METER_NUMBER &&
        typeof offer?.dataValidation !== 'undefined';
    const [isOnlyMeterNumberSubmission] = useState(
        location?.state?.isOnlyMeterNumberSubmission
    );

    const headerDescription = getTitleFromEnergyStep(
        activeStep,
        isManualAddition,
        isContractConclusion
    );

    const [inputValid, setInputValid] = useState(true);
    const [providerNotFound, setProviderNotFound] = useState(false);

    const [offerData, setOfferData] = useState<IEnergyContractPreferences>(
        () => {
            return {
                meterType:
                    location?.state?.meterType ??
                    (recurringPayment?.categoryName === 'Gas'
                        ? MeterType.Gas
                        : MeterType.Electricity),
                amount: recurringPayment?.lastAmount,
                paymentInterval:
                    convertStringToPaymentInterval(
                        recurringPayment?.paymentInterval
                    ) ?? IntervalType.MONTHLY,
                isCommercial: offer?.preference?.isCommercial ?? false,
                deliveryPostcode:
                    offer?.preference?.deliveryPostcode ?? postcode ?? '',
                companyId: recurringPayment?.companyId,
                oldProviderName:
                    offer?.preference?.oldProviderName ??
                    recurringPayment?.receiverName,
                categoryId:
                    location?.state?.categoryId ??
                    offer?.recurringPayment?.categoryId
            };
        }
    );

    const handleAmountChange = useCallback((amount: number) => {
        setOfferData((state: IEnergyContractPreferences) => ({
            ...state,
            amount: -1 * Math.abs(amount)
        }));
    }, []);

    const [contractData, setContractData] = useState<
        IEnergyOfferContractValidation | undefined
    >(offer?.dataValidation);

    const {company, account, category} = useSelector(reduxState => {
        return {
            company: companyByIdSelector(reduxState, {
                companyId: offerData.companyId
            }),
            account: singleAccountSelector(reduxState, {
                accountId: offerData?.accountId
            }),
            category: categoryByMeterTypeSelector(reduxState, {
                meterType: offerData.meterType
            })
        };
    });

    useEffect(() => {
        if (typeof offer?.dataValidation !== 'undefined') {
            setContractData((data: any) => {
                return typeof data !== 'undefined'
                    ? {...offer.dataValidation, ...data}
                    : {...offer.dataValidation};
            });
        }
    }, [offer]);

    useEffect(() => {
        if (typeof category !== 'undefined') {
            setOfferData(state => ({...state, categoryId: category.id}));
        }
    }, [category]);

    useEffect(() => {
        if (!hasBrokermandate) {
            fetchRequiredInformations();
        }
    }, [hasBrokermandate, fetchRequiredInformations]);

    const steps = [
        <MeterTypeInput
            handleChange={handleOfferDataChange}
            meterType={offerData.meterType}
            company={company}
            handleStepChange={handleStepChange}
            oldProviderName={offerData?.oldProviderName}
            setInputValid={setInputValid}
            isManualAddition={isManualAddition}
        />,
        <AreaInput
            setInputValid={setInputValid}
            handleChange={handleOfferDataChange}
            isCommercial={offerData?.isCommercial}
            deliveryPostcode={offerData?.deliveryPostcode ?? ''}
            isManualAddition={isManualAddition}
        />,
        <CostOrUsageInput
            setInputValid={setInputValid}
            amount={offerData?.amount}
            paymentInterval={offerData?.paymentInterval}
            handleChange={handleOfferDataChange}
            handleAmountChange={handleAmountChange}
            handleStepChange={handleStepChange}
            isManualAddition={isManualAddition}
            account={account}
        />,
        <OfferSelection
            handleOfferSelection={handleOfferSelection}
            offer={offer}
        />,
        <ContractCompletion
            handleChange={handleContractDataChange}
            contractData={contractData}
            setContractData={setContractData}
            setInputValid={setInputValid}
            inputValid={inputValid}
        />,
        <MeterNumberInput
            meterNumber={contractData?.meterNumber ?? ''}
            handleChange={handleMeterNumberChange}
            setInputValid={setInputValid}
            isOnlyMeterNumberSubmission={isOnlyMeterNumberSubmission}
        />,
        <CompanySelection
            notFoundComponent={renderProviderNotFound()}
            searchType={CompanyType.ENERGY}
            handleChange={handleOfferDataChange}
            setNotFound={isManualAddition ? undefined : setProviderNotFound}
            setInputValid={setInputValid}
        />,
        <AccountSelection handleChange={handleOfferDataChange('accountId')} />
    ];

    if (!hasBrokermandate && activeStep === EnergyStep.METER_NUMBER) {
        return (
            <BrokerMandateEdit backNavigationOnSave={false}>
                <BrokerMandateNoMandateExistsText />
            </BrokerMandateEdit>
        );
    }

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={headerDescription?.title ?? 'Vertragswelt'}
                backText={headerDescription?.backText ?? 'Vertragswelt'}
                save={handleSubmit}
                hasBackButton
            />
            <WgContainer
                bodyContent
                disableGutters={
                    activeStep === EnergyStep.PRODUCT_COMPARE ||
                    activeStep === EnergyStep.DATA_VALIDATION
                }>
                <Grid
                    container
                    item
                    direction="column"
                    //TODO this depends on WgContainer padding bottom - needs refactoring because ::after is garbage
                    style={{
                        height: 'calc(100% - 1.5rem)',
                        minHeight: 'fit-content'
                    }}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '1 1 80%', minHeight: 'fit-content'}}
                        container
                        justify="center"
                        wrap="nowrap">
                        {steps[activeStep]}
                    </Grid>
                    {activeStep !== EnergyStep.PRODUCT_COMPARE &&
                        activeStep !== EnergyStep.ACCOUNT_SELECTION && (
                            <Grid
                                item
                                style={{flex: 'auto'}}
                                container
                                direction="column"
                                justify="center">
                                <Box
                                    display="flex"
                                    flexWrap="nowrap"
                                    paddingTop="1rem"
                                    paddingBottom="1rem"
                                    marginTop={
                                        activeStep ===
                                        EnergyStep.DATA_VALIDATION
                                            ? '1rem'
                                            : '0'
                                    }
                                    paddingX={{
                                        xs:
                                            activeStep ===
                                            EnergyStep.DATA_VALIDATION
                                                ? '1rem'
                                                : '0',
                                        md: 0
                                    }}
                                    flex={1}
                                    flexDirection={{xs: 'column', md: 'row'}}
                                    justifyContent={{
                                        xs: 'flex-end',
                                        md: 'space-between'
                                    }}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        maxHeight="fit-content"
                                        alignItems={{
                                            xs: 'flex-end',
                                            md: 'center'
                                        }}
                                        flex={1}
                                        marginBottom={{xs: '1rem', md: 0}}
                                        order={{xs: 1, md: 2}}>
                                        <WgButton
                                            disabled={!inputValid}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleSubmit}
                                            aria-label="next">
                                            {formatButtonLabel(
                                                activeStep,
                                                isManualAddition
                                            )}
                                        </WgButton>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        maxHeight="fit-content"
                                        alignItems={{
                                            xs: 'flex-start',
                                            md: 'center'
                                        }}
                                        flex={1}
                                        order={{xs: 2, md: 1}}>
                                        <WgButton
                                            onClick={
                                                activeStep ===
                                                EnergyStep.METER_TYPE_INPUT
                                                    ? handleCancel
                                                    : handleBack
                                            }
                                            variant="contained"
                                            color="default"
                                            fullWidth
                                            aria-label="back">
                                            {activeStep ===
                                            EnergyStep.METER_TYPE_INPUT
                                                ? 'Abbrechen'
                                                : 'Zurück'}
                                        </WgButton>
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function renderProviderNotFound() {
        return (
            <React.Fragment>
                <WgTypography
                    gutterBottom
                    align="center"
                    color="primary"
                    text="contentInfo">
                    Keinen Energieversorger gefunden...
                </WgTypography>
                <WgTypography
                    gutterTop="1rem"
                    color="textSecondary"
                    align="center"
                    text="content">
                    Die manuelle Eingabe eines Versorgers kann zu fehlerhaften
                    Angeboten führen. Bist du dir sicher, dass dein Versorger
                    nicht gefunden werden kann?
                </WgTypography>
            </React.Fragment>
        );
    }

    function handleCancel() {
        dispatch(
            setNotification({
                title: 'Bist du dir sicher?',
                text:
                    'Du musst diese Fragen nur einmalig beantworten. Anschließend wechseln wir für dich jährlich voll automatisiert. Bist du dir sicher, dass du abbrechen möchtest?',
                isAgreeable: true,
                onAgree: () => {
                    handleSubmit();
                    push('/recurringpayments');
                },
                onDisagree: () => {},
                isDisagreeable: true,
                open: true,
                agreeText: 'JA',
                disagreeText: 'NEIN'
            })
        );
    }

    function handleBack() {
        history.goBack();
    }

    function handleSubmit() {
        if (
            (!isManualAddition &&
                activeStep === EnergyStep.USAGE_OR_COST_INPUT) ||
            (isManualAddition && activeStep === EnergyStep.AREA_INPUT)
        ) {
            handleFetchOffers();
            return;
        } else if (activeStep === EnergyStep.METER_NUMBER) {
            handleMeterNumberSubmission();
            return;
        } else if (activeStep === EnergyStep.PROVIDER_INPUT) {
            if (
                providerNotFound &&
                typeof offerData.oldProviderName !== 'undefined'
            ) {
                setOfferData(data => ({...data, companyId: undefined}));
            }
            handleStepChange(EnergyStep.METER_TYPE_INPUT);
            return;
        }
        if (isManualAddition) {
            if (activeStep === EnergyStep.METER_TYPE_INPUT) {
                handleStepChange(EnergyStep.USAGE_OR_COST_INPUT);
                return;
            } else if (activeStep === EnergyStep.USAGE_OR_COST_INPUT) {
                handleStepChange(EnergyStep.AREA_INPUT);
                return;
            }
        }
        handleStepChange(activeStep + 1);
    }

    function handleStepChange(nextStep: number) {
        push(setStepUrl(nextStep));
    }

    function setStepUrl(nextStep: number): string {
        const path = location.pathname.slice(0, -1);
        return path + nextStep;
    }

    function handleFetchOffers() {
        if (isManualAddition) {
            submitRecurringPayment(
                {
                    ...offerData,
                    paymentInterval:
                        offerData.paymentInterval ?? IntervalType.MONTHLY,
                    amount: offerData?.amount ?? 0
                },
                offerData
            );
            return;
        } else {
            submitOfferData();
        }
    }

    function submitOfferData() {
        if (typeof recurringPayment === 'undefined') {
            handleUnexpectedError(dispatch);
            return;
        }
        submitEnergyContractPreferences(
            recurringPayment?.recurringPaymentId,
            offerData,
            true,
            true,
            `/recurringpayments/${recurringPayment.recurringPaymentId}/add/energy/${EnergyStep.PRODUCT_COMPARE}`
        );
    }

    async function handleOfferSelection(energyProduct: IEnergyProduct) {
        if (typeof recurringPayment === 'undefined') {
            handleUnexpectedError(dispatch);
            return;
        }
        await submitEnergyOfferChoice(
            recurringPayment?.recurringPaymentId,
            energyProduct,
            true,
            `/recurringpayments/${recurringPayment.recurringPaymentId}/add/energy/${EnergyStep.DATA_VALIDATION}`
        );
    }

    function handleMeterNumberSubmission() {
        if (typeof recurringPayment === 'undefined') {
            handleUnexpectedError(dispatch);
            return;
        }
        if (
            isOnlyMeterNumberSubmission &&
            contractData?.meterNumber?.length === 0
        ) {
            return;
        } else if (!isOnlyMeterNumberSubmission && isContractConclusion) {
            submitEnergyContractCompletion(
                contractData,
                recurringPayment.recurringPaymentId
            );
        } else if (isOnlyMeterNumberSubmission) {
            updateMeterNumber(
                recurringPayment.recurringPaymentId,
                contractData?.meterNumber
            );
        }
    }

    function handleOfferDataChange(name: keyof IEnergyContractPreferences) {
        return (value: any) => {
            if (name === 'companyId') {
                setOfferData({
                    ...offerData,
                    oldProviderName: undefined,
                    [name]: value
                });
            } else {
                if (name === 'accountId') value = value.id;
                setOfferData({
                    ...offerData,
                    [name]: value
                });
            }

            if (name === 'companyId') {
                handleStepChange(EnergyStep.METER_TYPE_INPUT);
            } else if (name === 'accountId') {
                handleStepChange(EnergyStep.USAGE_OR_COST_INPUT);
            }
        };
    }

    function handleMeterNumberChange(value: any) {
        setContractData((data: any) =>
            typeof data !== 'undefined'
                ? {...data, meterNumber: value}
                : {meterNumber: value}
        );
    }

    function handleContractDataChange(
        name: keyof IEnergyOfferContractValidation | undefined = undefined
    ) {
        return (event: React.ChangeEvent<any> | Date) => {
            if (typeof contractData === 'undefined') return;
            if (event instanceof Date) {
                if (typeof name === 'undefined') return;
                setContractData({
                    ...contractData,
                    [name]: {
                        ...(contractData[name] as Partial<
                            IEnergyOfferContractValidation
                        >),
                        dateOfBirth: formatISO(event, {representation: 'date'})
                    }
                });
            } else {
                const value =
                    event.target.type === 'checkbox'
                        ? Boolean(event.target.checked)
                        : String(event.target.value);

                const updated: Partial<IEnergyOfferContractValidation> = name
                    ? {
                          [name]: {
                              ...(contractData[name] as Partial<
                                  IEnergyOfferContractValidation
                              >),
                              [event.target.name]: value ?? ''
                          }
                      }
                    : {
                          [event.target.name]: value ?? ''
                      };

                setContractData({
                    ...contractData,
                    ...updated
                });
            }
        };
    }

    function formatButtonLabel(
        step: EnergyStep,
        isManualContractAddition?: boolean
    ) {
        switch (step) {
            case EnergyStep.USAGE_OR_COST_INPUT:
                return isManualContractAddition
                    ? 'Weiter'
                    : 'Jetzt vergleichen';
            case EnergyStep.METER_NUMBER:
                if (isOnlyMeterNumberSubmission) return 'Weiter';
                return typeof contractData?.meterNumber !== 'undefined' &&
                    contractData.meterNumber.length > 0
                    ? 'Weiter'
                    : 'Überspringen';
            case EnergyStep.PROVIDER_INPUT:
                return providerNotFound && !isManualContractAddition
                    ? `Weiter mit "${offerData.oldProviderName}"`
                    : 'Weiter';
            case EnergyStep.AREA_INPUT:
                return isManualContractAddition
                    ? 'Vertrag hinzufügen'
                    : 'Weiter';
            default:
                return 'Weiter';
        }
    }
}

function mapStateToProps(state: any, ownProps: EnergyControllerProps) {
    const {recurringPaymentId} = ownProps.match.params;
    return {
        postcode: state?.user?.userData.postcode ?? '',
        offer: offerByIdSelector(state, {
            recurringPaymentId:
                (recurringPaymentId && Number(recurringPaymentId)) ?? invalidId
        }),
        recurringPayment: recurringPaymentByIdSelector(state, {
            recurringPaymentId: Number(recurringPaymentId)
        }),
        hasBrokermandate: existsBrokermandate(state)
    };
}

export default connect(mapStateToProps, {
    push,
    submitEnergyOfferChoice,
    submitEnergyContractPreferences,
    updateMeterNumber,
    submitEnergyContractCompletion,
    submitRecurringPayment,
    fetchRequiredInformations
})(EnergyController);

import React, {ReactNode} from 'react';
import {
    Card,
    CardActionArea,
    createStyles,
    Grid,
    Theme,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import {CardProps} from '@material-ui/core/Card';
import MyLink from '../../../containers/utils/MyLink';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: '64px',
            width: '100%',
            overflow: 'visible',
            position: 'relative',
            boxShadow: theme.shadows[0]
        },
        noHover: {
            '& > .MuiCardActionArea-focusHighlight': {
                opacity: '0 !important'
            }
        },
        disabled: {
            backgroundColor: theme.palette.gray.light,
            opacity: '.6'
        },
        listItemAction: {
            paddingRight: 0
        },
        paperBackground: {
            backgroundColor: theme.palette.background.paper
        },
        defaultBackground: {
            backgroundColor: theme.palette.background.default
        },
        primaryBackground: {
            backgroundColor: theme.palette.primary.main
        },
        secondaryBackground: {
            backgroundColor: theme.palette.secondary.main
        },
        transparentBackground: {
            backgroundColor: 'transparent'
        },
        shadow: {
            boxShadow: theme.shadows[1]
        },
        flag: {
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            position: 'absolute',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 2000,
            cursor: 'pointer'
        },
        infoFlag: {
            backgroundColor: theme.palette.success.main
        },
        warningFlag: {
            backgroundColor: theme.palette.warning.main
        },
        leftFlag: {
            left: '0',
            bottom: 'calc(50% - 10px)',
            marginLeft: '-8px'
        },
        rightFlag: {
            right: '0',
            bottom: 'calc(50% - 10px)',
            marginRight: '-8px'
        },
        topRightFlag: {
            top: '2%',
            right: '0',
            marginRight: '-8px'
        },
        topLeftFlag: {
            top: '2%',
            left: '0',
            marginLeft: '-8px'
        }
    });

export type FlagDirection = 'left' | 'right' | 'topLeft' | 'topRight';
export interface WgListItemProps extends CardProps {
    shadow?: boolean;
    warningFlag?: FlagDirection;
    infoFlag?: FlagDirection;
    background?: 'paper' | 'default' | 'primary' | 'secondary' | 'transparent';
    classes: any;
    children?: React.ReactNode[] | React.ReactNode;
    link?: string;
    linkState?: any;
    noHover?: boolean;
    hasActionButton?: boolean;
    disabled?: boolean;
    paddingRight?: string | number;
}

function WgListItem({
    children,
    classes,
    hasActionButton,
    infoFlag,
    warningFlag,
    background,
    shadow,
    onClick,
    link,
    linkState,
    noHover,
    disabled,
    paddingRight,
    ...other
}: WgListItemProps) {
    const childArray = React.Children.toArray(children);
    const lastChild: ReactNode =
        childArray?.length && childArray[childArray.length - 1];
    if (lastChild == null) {
        throw new Error('A God should always have a child...');
    }

    const actionChild = hasActionButton && childArray.pop();
    return (
        <Card
            onClick={onClick}
            {...other}
            className={clsx(`${classes['root']}`, {
                [background && classes[`${background}Background`]]: background,
                [classes['shadow']]: shadow,
                [classes[`listItemAction`]]: hasActionButton
            })}>
            {infoFlag && (
                <div
                    className={`${classes['flag']} ${classes['infoFlag']} ${
                        classes[infoFlag + 'Flag']
                    }`}>
                    i
                </div>
            )}
            {warningFlag && (
                <div
                    className={`${classes['flag']} ${classes['warningFlag']} ${
                        classes[warningFlag + 'Flag']
                    }`}>
                    !
                </div>
            )}
            <Grid
                style={{height: '100%'}}
                container
                direction="row"
                justify="space-between"
                wrap="nowrap">
                <CardActionArea
                    disabled={
                        disabled ||
                        (typeof link === 'undefined' &&
                            typeof onClick === 'undefined')
                    }
                    disableRipple={
                        typeof link === 'undefined' &&
                        typeof onClick === 'undefined'
                    }
                    component={
                        (link && MyLink(link, undefined, linkState)) || 'div'
                    }
                    style={{minWidth: 0, minHeight: '64px'}}
                    className={clsx({
                        [classes['noHover']]: noHover
                    })}>
                    <Grid
                        style={{
                            minHeight: '100%',
                            paddingRight: paddingRight ?? ''
                        }}
                        className={clsx({
                            [classes['disabled']]: disabled
                        })}
                        container
                        direction="row"
                        justify="space-between"
                        wrap="nowrap">
                        {childArray}
                    </Grid>
                </CardActionArea>
                {actionChild}
            </Grid>
        </Card>
    );
}

export default withStyles(styles)(WgListItem);

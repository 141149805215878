import React, {useEffect} from 'react';
import {animated, useSpring} from 'react-spring';
import {useDrag} from 'react-use-gesture';
import {Box, Grid} from '@material-ui/core';
import {WgIconButton} from './index';
import {connect} from 'react-redux';
import {setTutorialSeen} from '../../store/actions/tutorialActions';
import {Tutorial} from '../../store/reducers/tutorials';
import useMediaQuery from '../../hooks/useMediaQuery';

export interface WgSwipeableProps {
    disabled?: boolean;
    children: any;
    actions: ActionButtonProps[];
    hint?: boolean;
    swipeHintSeen?: boolean;
    setTutorialSeen?: Function;
}

export interface ActionButtonProps {
    text: string;
    onClick: Function;
    src: string;
    bgColor?: 'secondary' | 'error';
}

function WgSwipeable({
    children,
    disabled,
    actions,
    swipeHintSeen,
    hint,
    setTutorialSeen
}: WgSwipeableProps) {
    const {isMobile} = useMediaQuery('sm');
    const windowOffset = window.innerWidth * 0.25 * actions.length; // 2 buttons -> 0.5offset, 1 button -> 0.25offset
    const [{x}, set] = useSpring(() => ({x: 0}));

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(
        ({down, offset: [x], tap}) => {
            if (!tap) {
                set({
                    x: down ? x : x > -windowOffset * 0.5 ? 0 : -windowOffset
                });
            } else {
                set({x: 0});
            }
        },
        {
            threshold: 10,
            axis: 'x',
            bounds: {left: -windowOffset - 20, right: 20, top: 0, bottom: 0},
            rubberband: true
        }
    );

    useEffect(() => {
        if (isMobile && !swipeHintSeen && hint) {
            shake();
        }

        function shake() {
            let counter = 0;
            let left = true;
            let intervalId = setInterval(() => {
                if (counter === 10) {
                    clearInterval(intervalId);
                    setTutorialSeen &&
                        setTutorialSeen(Tutorial.accountSwipeGesture);
                } else {
                    set({
                        x: left ? -15 : 0,
                        config: {mass: 1, tension: 500, friction: 10}
                    });
                    left = !left;
                    counter++;
                }
            }, 200);
        }
    }, [hint, isMobile, set, swipeHintSeen, setTutorialSeen]);

    if (disabled) return children;

    if (!isMobile) {
        return children;
    } else {
        return (
            <animated.div {...bind()} style={{x, touchAction: 'pan-y'}}>
                {renderItem()}
            </animated.div>
        );
    }

    function renderItem() {
        return (
            <Box display="flex" flexWrap="nowrap" overflow="visible">
                {children}
                {actions && (
                    <Grid
                        style={{marginLeft: '4.5vw'}}
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={2}>
                        {actions.map(
                            ({
                                onClick,
                                text,
                                bgColor,
                                ...item
                            }: ActionButtonProps) => (
                                <Grid
                                    key={item.src}
                                    item
                                    container
                                    alignItems="center">
                                    <WgIconButton
                                        onClick={() => handleClick(onClick)}
                                        {...item}
                                        backgroundColor={bgColor ?? 'secondary'}
                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                )}
            </Box>
        );
    }

    function handleClick(action: Function) {
        set({x: 0});
        setTimeout(() => {
            action();
        }, 500);
    }
}

const mapStateToProps = (state: any) => {
    return {
        swipeHintSeen: state.tutorials[0]
    };
};

export default connect(mapStateToProps, {setTutorialSeen})(WgSwipeable);

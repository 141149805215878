import React, {Component} from 'react';
import {connect} from 'react-redux';
import {verifyUser} from '../../store/actions/userActions';
import {
    fetchAllBanks,
    fetchFinApiDialogFromBank
} from '../../store/actions/bankActions';
import {fetchAllAccounts} from '../../store/actions/accountActions';
import {
    addLoading,
    displayErrorNotification,
    setNotification,
    setSnackbar
} from '../../store/actions/notificationActions';
import {getUser} from '../../store/reducers/selectors';
import WgCircularProgress from './loading_components/WgCircularProgress';
import {LoadingPriorityCodes} from '../../store/reducers/notification';
import WgLinearProgress from './loading_components/WgLinearProgress';
import {WgToast} from '../../styles/CustomComponents';
import {
    loadingTestData,
    snackbarTestData
} from '../../store/actions/notificationActionsDEVDataTest';

interface Props {
    verifyUser: Function;
    fetchAllBanks: Function;
    fetchFinApiDialogFromBank: Function;
    fetchAllAccounts: Function;
    setNotification: Function;
    setSnackbar: Function;
    displayErrorNotification: Function;
    addLoading: Function;
    id: string;
}

class TestComponent extends Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    verify = () => {
        this.props.verifyUser(123456);
    };

    fetchAllBanks = () => {
        this.props.fetchAllBanks();
    };

    fetchFinApiDialogFromBank = () => {
        this.props.fetchFinApiDialogFromBank(10080);
    };

    fetchAllAccounts = () => {
        this.props.fetchAllAccounts();
    };

    setNotification = () => {
        this.props.setNotification({
            text: 'text',
            title: 'title',
            open: true
        });
    };

    setSnackbar = () => {
        this.props.setSnackbar(snackbarTestData);
    };
    displayErrorNotification = () => {
        this.props.displayErrorNotification(
            'Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfe deine Internetverbindung!'
        );
    };
    addLoadingLinear = () => {
        this.props.addLoading({
            id: 'TEST',
            priority: LoadingPriorityCodes.LINEAR_PROGRESS,
            component: WgLinearProgress
        });
    };
    addLoadingCircular = () => {
        this.props.addLoading({
            id: 'TEST',
            priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
            component: WgCircularProgress
        });
    };
    addLoadingGif = () => {
        this.props.addLoading(loadingTestData);
    };

    render() {
        return (
            <div>
                <button onClick={this.verify}> verify User</button>
                <button onClick={this.fetchAllBanks}> Get all Banks</button>
                <button onClick={this.fetchFinApiDialogFromBank}>
                    {' '}
                    fetchFinApiDialogFromBank
                </button>
                <button onClick={this.fetchAllAccounts}>
                    {' '}
                    fetchAllAccounts
                </button>
                <button onClick={this.setNotification}> setNotification</button>
                <button onClick={this.setSnackbar}> setSnackbar</button>
                <button onClick={this.displayErrorNotification}>
                    {' '}
                    displayErrorNotification
                </button>
                <button onClick={this.addLoadingLinear}>
                    {' '}
                    addLoadingLinear
                </button>
                <button onClick={this.addLoadingCircular}>
                    {' '}
                    addLoadingCircular
                </button>
                <button onClick={this.addLoadingGif}> addLoadingGif</button>

                <WgToast
                    text={
                        'Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfe deine Internetverbindung!'
                    }
                />
                <WgToast
                    variant="success"
                    text={
                        'Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfe deine Internetverbindung!'
                    }
                />
                <WgToast
                    variant="error"
                    text={
                        'Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfe deine Internetverbindung!'
                    }
                />
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        id: getUser(state).id
    };
}

export default connect(mapStateToProps, {
    verifyUser,
    fetchAllBanks,
    fetchFinApiDialogFromBank,
    fetchAllAccounts,
    setSnackbar,
    displayErrorNotification,
    addLoading,
    setNotification
})(TestComponent);

import {Divider, Grid, Box} from '@material-ui/core';
import React from 'react';
import {IVehicleData} from '../../../../../../models/productInterface';
import {formatDateWithTime} from '../../../../../utils/Format';
import ListItemCompletion from '../Components/ListItemCompletion';

export interface VehicleDataCompletionProps {
    vehicleData?: IVehicleData;
}

export default function VehicleDataCompletion(
    props: VehicleDataCompletionProps
) {
    const {vehicleData} = props;

    const keyValueMapping = {
        'Fahrzeug-Identifizierungsnummer (FIN)': vehicleData?.vehicleId,
        Kennzeichen: vehicleData?.plateNumber,
        Landkreis: vehicleData?.area,
        Erstzulassung: formatDateWithTime(
            vehicleData?.firstRegistrationDate,
            false
        ),
        'Erster Versicherungstag (vor Erstzulassung)': formatDateWithTime(
            vehicleData?.firstInsuredDate,
            false
        )
    };

    let keyValueLength = Object.keys(keyValueMapping).length;
    let countValues = 0;

    return (
        <Box margin="0 1rem 1rem 1rem">
            <Grid container wrap="nowrap">
                <Grid style={{width: '100%'}} item>
                    <ListItemCompletion
                        primary="HSN"
                        secondary={vehicleData?.hsn}
                        lastElement
                    />
                </Grid>
                <Box display="flex" alignItems="center">
                    <Divider
                        orientation="vertical"
                        light
                        style={{height: '50%'}}
                    />
                </Box>
                <Grid style={{width: '100%', paddingLeft: '1rem'}} item xs={6}>
                    <ListItemCompletion
                        primary="TSN"
                        secondary={vehicleData?.tsn}
                        lastElement
                    />
                </Grid>
            </Grid>
            <Divider light />
            <Box>
                {keyValueMapping &&
                    Object.entries(keyValueMapping).map((entry: any) => {
                        countValues = countValues + 1;
                        return (
                            <ListItemCompletion
                                key={`${countValues}VehicleDataCompletion`}
                                primary={entry[0]}
                                secondary={entry[1]}
                                lastElement={countValues === keyValueLength}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
}

import React from 'react';
import {Grid} from '@material-ui/core';
import {
    formatCarInsuranceOneProductType,
    ICarInsuranceOneProduct,
    CarInsuranceOneProductType
} from '../../../../../../models/productInterface';
import WgSelectorButton from '../../../../../../styles/CustomComponents/WgSelectorButton';

export interface OneProductTypeProps {
    handleChange: (
        name: keyof ICarInsuranceOneProduct
    ) => (value: CarInsuranceOneProductType | React.ChangeEvent<any>) => void;
    carInsuranceOneProduct: ICarInsuranceOneProduct;
}
export default function OneProductType({
    handleChange,
    carInsuranceOneProduct
}: OneProductTypeProps) {
    return (
        <Grid container spacing={1} style={{marginTop: '0.5rem'}}>
            {renderButton(CarInsuranceOneProductType.NEW_VEHICLE, true, true)}
            {renderButton(
                CarInsuranceOneProductType.SWITCH_INSURANCE,
                true,
                false
            )}
        </Grid>
    );

    //Just delete active when next insurance will be released
    function renderButton(
        type: CarInsuranceOneProductType,
        fullWidth = false,
        active = true
    ) {
        return (
            <Grid
                key={`carInsuranceOneProduct-${type}`}
                item
                xs={fullWidth ? 12 : 6}>
                <WgSelectorButton
                    handleChange={handleChange('oneProductType')}
                    active={active} //type === carInsuranceOneProduct.oneProductType
                    value={type}
                    text={formatCarInsuranceOneProductType(type)}
                />
            </Grid>
        );
    }
}

import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardMedia,
    Checkbox,
    createStyles,
    Divider,
    FormControlLabel,
    IconButton,
    makeStyles,
    Paper,
    Theme
} from '@material-ui/core';
import {ReactSVG} from 'react-svg';
import MyLink from '../../../utils/MyLink';
import {connect, useStore} from 'react-redux';
import {getUserUserData} from '../../../../store/reducers/selectors';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../styles/CustomComponents';
import {dateAndName} from '../../../utils/Format';
import {displayErrorNotification} from '../../../../store/actions/notificationActions';
import BrokerMandateSignature, {
    isCanvasBlank,
    resizeImageOnCanvas
} from './BrokerMandateSignature';
import {
    getBrokermandate,
    getTmpBrokermandate
} from '../../../../store/reducers/selectors/userSelector';
import {
    fetchBrokermandate,
    updateBrokermandate,
    updateTmpBrokermandate
} from '../../../../store/actions/userActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        signatureLeftBtn: {
            backgroundColor: theme.palette.secondary.main,
            width: '3rem',
            height: '3rem',
            padding: 0,
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        },
        signatureRefreshIcon: {
            '& > div': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& > svg': {
                    color: theme.palette.common.white
                }
            }
        },
        attachmentFileInput: {
            width: 'inherit',
            opacity: '0',
            cursor: 'pointer'
        },
        attachmentButton: {
            height: '36px', //24px + margin (6+6)
            minWidth: '40px', //24px + margin(8+8)
            margin: '-6px -8px'
        },
        attachmentIcon: {
            '& > div': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& > svg': {
                    height: '24px'
                }
            }
        }
    })
);

interface IProps {
    title: string;
    firstName: string;
    lastName: string;
    updateBrokermandate: Function;
    updateTmpBrokermandate: Function;
    fetchBrokermandate?: Function;
    brokermandate?: string;
    tmpBrokermandate?: any; // This brokermandate (image, isChecked) is persisted in the redux-store locally for the case of a page-change.
    displayErrorNotification: Function;
    children?: React.ReactNode;
    backNavigationOnSave?: boolean;
}

interface IState {
    hasReadMandate: boolean;
    isInputValid: boolean;
    tmpImage: any;
    hasAskToReturn: boolean;
}

function BrokerMandateEdit({
    title,
    firstName,
    lastName,
    updateBrokermandate,
    updateTmpBrokermandate,
    fetchBrokermandate,
    brokermandate,
    tmpBrokermandate,
    displayErrorNotification,
    children,
    backNavigationOnSave = true
}: IProps) {
    const [openSignature, setOpenSignature] = useState<boolean>(false);
    const [state, setState] = useState<IState>({
        isInputValid: false,
        hasReadMandate: false,
        hasAskToReturn: false,
        tmpImage: null
    });

    const store = useStore();

    if (tmpBrokermandate) {
        if (state.hasReadMandate !== tmpBrokermandate.isChecked) {
            setState({
                ...state,
                hasReadMandate: tmpBrokermandate.isChecked
            });
        }
        if (state.tmpImage !== tmpBrokermandate.image) {
            setState({
                ...state,
                tmpImage: tmpBrokermandate.image
            });
        }
    }

    /**
     * Upon Mounting, this effect will reset the tmpBrokermandate if "resetOnReenter" is set to 1.
     * Upon Unmounting, this effect will set a flag that will trigger a reset of tmpBrokermandate on the next mount, if it was not explicitly set not to.
     */
    useEffect(() => {
        const tmpBrokermandate = getTmpBrokermandate(store.getState());
        if (tmpBrokermandate?.resetOnReenter === 1) {
            // reset the signature and checkbox state
            updateTmpBrokermandate(null, false, 2);
        } else if (tmpBrokermandate?.resetOnReenter === 0) {
            // don't reset signature and checkbox state
            updateTmpBrokermandate(undefined, undefined, 2);
        }

        // Unmounting: If "resetOnReenter" of tmpBrokermandate has not been set explicitly to 0, reset on the next enter!
        return () => {
            // Get the most current tmpBrokermandate from the store
            let tmp = getTmpBrokermandate(store.getState());
            if (tmp?.resetOnReenter === 2) {
                // Force reset the tmpBrokermandate as something else than the Brokermandate-Text is being opened.
                updateTmpBrokermandate(undefined, undefined, 1);
            }
        };
    }, [store, updateTmpBrokermandate]);

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            fetchBrokermandate && (await fetchBrokermandate());
        })();
    }, [fetchBrokermandate]);

    const validate = useCallback(() => {
        state.hasReadMandate && state.tmpImage
            ? setState(state => ({...state, isInputValid: true}))
            : setState(state => ({...state, isInputValid: false}));
    }, [state.hasReadMandate, state.tmpImage]);

    useEffect(() => {
        validate();
    }, [state.hasReadMandate, state.tmpImage, state.hasAskToReturn, validate]);

    let LinkMandate = MyLink('/profile/brokermandatetext');

    return (
        <WgContainer bodyApp>
            {
                <BrokerMandateSignature
                    openSignature={openSignature}
                    setOpenSignature={setOpenSignature}
                    dateAndName={() => dateAndName(firstName, lastName, title)}
                    saveTmpImage={saveTmpImage}
                />
            }
            <WgHeader
                headerText="Mandat erteilen"
                hasBackButton
                backText="Maklermandat"
                hasAskToReturn={state.hasAskToReturn}
                save={save}
            />

            <WgContainer bodyContent disableGutters={false}>
                {children ?? renderDefaultText()}

                <Box marginTop="1.5rem" maxWidth="420px">
                    <Card style={{cursor: 'pointer', position: 'relative'}}>
                        <CardMedia
                            onClick={() => setOpenSignature(true)}
                            style={{
                                height: '120px',
                                justifyContent: 'start',
                                display: 'flex'
                            }}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                flex={1}>
                                {state.tmpImage && (
                                    <img src={state.tmpImage} alt="Signature" />
                                )}
                                {state.tmpImage == null && renderSignButton()}
                            </Box>
                        </CardMedia>
                        {state.tmpImage != null && renderPenButton()}
                        <Box display="flex" flexDirection="column">
                            <Divider variant="middle" light />
                            <Box
                                display="flex"
                                p="0.5rem 1.25rem"
                                justifyContent="space-between">
                                <WgTypography
                                    color="textSecondary"
                                    text="subContent">
                                    {dateAndName(firstName, lastName, title)}
                                </WgTypography>

                                <Box width="24px" height="24px" display="flex">
                                    <input
                                        id="inputAttachment"
                                        type="file"
                                        className={classes.attachmentFileInput}
                                        title=""
                                        onChange={uploadFile}
                                    />
                                    <Box
                                        position="absolute"
                                        width="inherit"
                                        height="inherit">
                                        <WgButton
                                            onClick={() =>
                                                handleInputOnChange()
                                            }
                                            className={
                                                classes.attachmentButton
                                            }>
                                            <ReactSVG
                                                className={
                                                    classes.attachmentIcon
                                                }
                                                src="/img/profile/icon-paperclip.svg"
                                            />
                                        </WgButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>

                <Box marginY="2rem">
                    <Paper>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.hasReadMandate}
                                    name="readMandate"
                                    onChange={(event: any) => {
                                        const isChecked = event.target.checked;
                                        mandateReadChanged(isChecked);
                                    }}
                                />
                            }
                            label={
                                <WgTypography
                                    color="textPrimary"
                                    component="span">
                                    Ich habe das{' '}
                                    <LinkMandate onClick={handleLinkClick}>
                                        Maklermandat
                                    </LinkMandate>{' '}
                                    gelesen
                                </WgTypography>
                            }
                        />
                    </Paper>
                </Box>

                <Box
                    display="flex"
                    justifyContent={{xs: 'center', md: 'flex-end'}}>
                    <WgButton
                        defaultButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        aria-label="save"
                        onClick={save}
                        disabled={!state.isInputValid}>
                        Speichern
                    </WgButton>
                </Box>
            </WgContainer>
        </WgContainer>
    );

    function renderPenButton() {
        return (
            <Box position="absolute" top="4px" right="4px">
                <IconButton
                    className={classes.signatureLeftBtn}
                    color="primary"
                    aria-label="Clear"
                    onClick={() => setOpenSignature(true)}>
                    <ReactSVG
                        className={classes.signatureRefreshIcon}
                        src="/img/profile/icon-pen-white.svg"
                    />
                </IconButton>
            </Box>
        );
    }

    function renderSignButton() {
        return (
            <Box marginX="1rem">
                <WgButton
                    startIcon={
                        <ReactSVG src="/img/profile/icon-pen-white.svg" />
                    }
                    defaultButton
                    variant="contained"
                    style={{width: '100%'}}
                    color="primary"
                    aria-label="sign"
                    onClick={() => setOpenSignature(true)}>
                    unterschreiben
                </WgButton>
            </Box>
        );
    }

    function renderDefaultText() {
        let LinkMandate = MyLink('/profile/brokermandatetext');
        return (
            <WgTypography gutterBottom color="textSecondary" text="info">
                Damit wir deine Vertragsdetails bei den Anbietern anfordern
                können und uns künftig als Makler um deine Verträge kümmern
                dürfen, bitten wir dich darum das{' '}
                <LinkMandate onClick={handleLinkClick}>
                    Maklermandat
                </LinkMandate>{' '}
                digital zu unterzeichnen.
            </WgTypography>
        );
    }

    function handleLinkClick() {
        // save in the redux-store, that the current brokermandate must not be removed
        updateTmpBrokermandate(state.tmpImage, state.hasReadMandate, 0);
    }

    function mandateReadChanged(isChecked: boolean) {
        if (state.hasReadMandate === isChecked) return;

        setState({
            ...state,
            hasReadMandate: isChecked
        });
        updateTmpBrokermandate(state.tmpImage, isChecked);
    }

    function saveTmpImage() {
        let canvas = document.getElementsByTagName('canvas')[0];
        const canvasIsBlank = isCanvasBlank(canvas);
        const image = canvasIsBlank ? null : canvas.toDataURL('image/png');
        const hasAskToReturn = !canvasIsBlank;
        persistTmpImage(image);

        setState(state => ({
            ...state,
            tmpImage: image,
            hasAskToReturn: hasAskToReturn
        }));
    }

    function handleInputOnChange() {
        let input = document.getElementById('inputAttachment');
        input?.click();
    }

    function convertFileToTmpImage(e: Event) {
        //@ts-ignore
        const origImage = e.target.result;
        let image = new Image();

        // encode image to data-uri with base64 version of compressed image
        image.onload = () => {
            const tmpImage = resizeImageOnCanvas(image).toDataURL();
            persistTmpImage(tmpImage);
            setState(state => ({
                ...state,
                isInputValid: true,
                tmpImage: tmpImage,
                hasAskToReturn: true
            }));
        };
        image.src = origImage;
    }

    function uploadFile(event: any) {
        let file = event.target.files[0];
        if (file === undefined) return;
        if (!(file && file.type.match('image.*'))) {
            displayErrorNotification('Die Datei hat das falsche Format.');
            return;
        }
        let reader = new FileReader();
        reader.onload = convertFileToTmpImage;
        reader.readAsDataURL(file);
    }

    function save() {
        if (state.tmpImage && state.isInputValid) {
            updateBrokermandate(state.tmpImage);
            state.hasAskToReturn = false;
            if (backNavigationOnSave) {
                window.history.back();
            }
            return true;
        } else {
            console.warn('You havent changed your Brokermandate');
            return false;
        }
    }

    /**
     * "Persists" an image in the redux-store's user.tmpBrokermandate-variable
     * @param image The image (or null if it should be removed) that should be saved temporarily
     */
    function persistTmpImage(image: any) {
        updateTmpBrokermandate(image, state.hasReadMandate);
    }
}

function mapStateToProps(state: any) {
    return {
        brokermandate: getBrokermandate(state),
        tmpBrokermandate: getTmpBrokermandate(state),
        title: getUserUserData(state)?.title,
        lastName: getUserUserData(state)?.lastName,
        firstName: getUserUserData(state)?.firstName
    };
}

export default connect(mapStateToProps, {
    updateBrokermandate,
    updateTmpBrokermandate,
    fetchBrokermandate,
    displayErrorNotification
})(BrokerMandateEdit);

export const getUser = (state: any) => state?.user;
export const getUserIsLoggedIn = (state: any) => getUser(state)?.isLoggedIn;
export const getUserUserData = (state: any) => getUser(state)?.userData;
export const getBrokermandate = (state: any): string | undefined =>
    getUser(state)?.brokermandate;
export const existsBrokermandate = (state: any): boolean => {
    return (
        typeof getBrokermandate(state) === 'string' ||
        !(
            getUser(state)?.requiredInformations?.includes('BrokerMandate') ??
            true
        )
    );
};
export const getTmpBrokermandate = (state: any): any | undefined => {
    return getUser(state)?.tmpBrokermandate;
};
export const existsTmpBrokermandate = (state: any): boolean => {
    return typeof getTmpBrokermandate(state) === 'string';
};

import React from 'react';
import {
    Box,
    createStyles,
    IconButton,
    Paper,
    Theme,
    withStyles
} from '@material-ui/core';
import {WgListItem, WgListItemContent, WgTypography} from './index';
import {ChevronRight} from '@material-ui/icons';
import clsx from 'clsx';
import {WgListItemProps} from './WgList/WgListItem';
import useMediaQuery from '../../hooks/useMediaQuery';

const styles = (theme: Theme) =>
    createStyles({
        singleItem: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.background.default,
                justifyContent: 'flex-start',
                width: '325px',
                '&:hover': {
                    backgroundColor: theme.palette.background.default
                }
            }
        },
        default: {
            backgroundColor: theme.palette.background.default
        }
    });

export interface RecurringPaymentPaperListProps extends WgListItemProps {
    keyValueMapping?: any;
    link?: string;
    singleItem?: boolean;
    classes: any;
    shadow?: boolean;
    textMargin?: string;
}
function WgPaperList({
    keyValueMapping,
    link,
    singleItem,
    shadow,
    background,
    textMargin,
    classes
}: RecurringPaymentPaperListProps) {
    const {isUp} = useMediaQuery('md');
    return (
        <Paper
            elevation={shadow && isUp ? 1 : 0}
            className={clsx({
                [classes['singleItem']]: singleItem,
                [background && classes[background]]: background
            })}>
            <Box
                width="100%"
                display="flex"
                flexDirection={{xs: 'column', md: 'row'}}
                flexWrap="wrap">
                {keyValueMapping &&
                    Object.entries(keyValueMapping).map((entry: any) => {
                        if (typeof entry[1] === 'undefined') return null;
                        return mapToListItem(
                            entry[0],
                            entry[1],
                            entry[1] instanceof Array
                        );
                    })}
            </Box>
        </Paper>
    );

    function mapToListItem(
        primary: string,
        secondary?: string | string[],
        isLink: boolean = false
    ) {
        if (typeof secondary === 'undefined') return null;
        if (primary === 'Verknüpftes Konto') return secondary;
        return (
            <WgListItem
                noHover
                key={primary}
                background={singleItem && isUp ? 'transparent' : background}
                elevation={0}
                link={(isLink && link) || undefined}>
                <WgListItemContent>
                    <Box margin={textMargin ? textMargin : ''}>
                        <WgTypography color="textSecondary" text="info">
                            {primary}
                        </WgTypography>
                        {isLink
                            ? secondary && (
                                  <Box display="flex" flexDirection="column">
                                      <WgTypography color="textPrimary">
                                          {secondary[0]}
                                      </WgTypography>
                                      <WgTypography color="textPrimary">
                                          {secondary[1]}
                                      </WgTypography>
                                  </Box>
                              )
                            : secondary && (
                                  <WgTypography color="textPrimary">
                                      {secondary}
                                  </WgTypography>
                              )}
                    </Box>
                    {isLink && link?.length && (
                        <IconButton style={{padding: 0}}>
                            <ChevronRight color="disabled" fontSize="large" />
                        </IconButton>
                    )}
                </WgListItemContent>
            </WgListItem>
        );
    }
}

export default withStyles(styles)(WgPaperList);

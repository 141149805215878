import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createBrowserHistory, History} from 'history';
import reactGAMiddleware from './store/middlewares/reactGAMiddleware';
import rootReducer from './rootReducer';
import {routerMiddleware} from 'connected-react-router';
import {persistStore} from 'redux-persist';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

export const history = createBrowserHistory();
export const store = configureStore(history);
export const persistor = persistStore(store);

export function configureStore(history: History) {
    //Init middlewares
    const middlewares = [thunk, routerMiddleware(history), reactGAMiddleware];

    //Init enhancer
    const composeEnhancers = composeWithDevTools({
        trace: true,
        traceLimit: 25
    });

    return createStore(
        rootReducer(history),
        composeEnhancers(applyMiddleware(...middlewares))
    );
}

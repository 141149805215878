import React from 'react';
import TermsText from './TermsText';
import {WgContainer, WgHeader} from '../../../styles/CustomComponents';

export default function TermsLoggedIn() {
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Allgemeine Geschäftsbedingungen"
                hasBackButton
                backText="Dokumente"
            />
            <WgContainer bodyContent disableGutters={false}>
                <TermsText />
            </WgContainer>
        </WgContainer>
    );
}

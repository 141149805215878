import {TUTORIAL_SEEN} from '../actions/actionTypes';

export enum Tutorial {
    accountSwipeGesture
}

export const initialState = {};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case TUTORIAL_SEEN:
            return {
                ...state,
                [action.payload]: true
            };

        default:
            return state;
    }
};

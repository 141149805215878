import React from 'react';
import {WgTypography} from '../../../styles/CustomComponents';

export default function TermsText() {
    return (
        <WgTypography color="textSecondary" text="info">
            <b>Präambel</b>
            <br />
            <br />
            Die Wechselgott GmbH stellt Ihnen mit der WechselGott–App eine
            Finanz-Applikation zur Verfügung, mit der Sie einfach und
            unkompliziert bestehende Verträge auf Optimierungsmöglichkeiten
            überprüfen können. Selbstverständlich legen wir höchsten Wert auf
            Datensicherheit und Datenschutz und arbeiten nach den hohen
            gesetzlich vorgegebenen Standards. Mehr dazu können Sie in unseren
            Datenschutzhinweisen erfahren.
            <br />
            <br />
            <b>1 Allgemeines und Geltungsbereich</b>
            <br />
            <br />
            1.1 Die Wechselgott GmbH (nachfolgend „WechselGott“ oder wir/uns)
            erbringt gegenüber Ihnen als Nutzer unserer WechselGott–App
            Leistungen, die wir Ihnen in diesen Allgemeinen Geschäftsbedingungen
            näher erläutern.
            <br />
            <br />
            1.2 Unsere Allgemeinen Geschäftsbedingungen gelten ausschließlich.
            Entgegenstehende oder abweichende Geschäftsbedingungen der Nutzer
            der Wechselgott–App können nur dann Vertragsbestandteil werden, wenn
            sie mit unseren vorliegenden Allgemeinen Geschäftsbedingungen
            inhaltlich übereinstimmen oder wir sie ausdrücklich schriftlich
            anerkennen. Dies gilt auch, wenn wir trotz Kenntnis
            entgegenstehender oder abweichender Geschäftsbedingungen von Ihnen
            unsere Leistungen vorbehaltlos erbringen.
            <br />
            <br />
            <b>2 Zugang und Verfügbarkeit sowie Inhalt der WechselGott-App</b>
            <br />
            <br />
            2.1 Um die Wechselgott-App nutzen zu können, brauchen Sie ein
            onlinefähiges Girokonto bei einer europäischen Bank und Verträge mit
            deutschen Anbietern. Zudem müssen Sie sich gegenüber der
            Bankenschnittstelle unter Nutzung Ihrer Login-Daten
            authentifizieren.
            <br />
            <br />
            2.2 Wir behalten uns jedoch das Recht vor, unsere Leistungen ohne
            weitere Begründung einzustellen.
            <br />
            <br />
            2.3 Über die WechselGott-App können Sie eine Übersicht über die
            Finanzbewegungen auf den von Ihnen eingepflegten Girokonten erhalten
            und mit uns einen Maklervertrag schließen, mit dem wir Ihre
            Interessen in den Bereichen Energie, Haushaltsverträge und
            Versicherung vertreten. Wir können die WechselGott-App jederzeit
            ein- oder umzustellen oder in sonstiger Weise ändern. Dies gilt
            insbesondere für den Fall, dass sich die gesetzlichen oder
            regulatorischen Anforderungen für die Erbringung, oder in
            Zusammenhang mit den Leistungen der WechselGott-App ändern.
            <br />
            <br />
            2.4 Wir bemühen uns, die WechselGott-App mit so wenig
            Unterbrechungen wie möglich zur Verfügung zu stellen. Allerdings
            übernehmen wir keine Haftung für die ständige Verfügbarkeit der
            WechselGott-App. Zudem können wir die Verfügbarkeit zeitweilig
            beschränken, wenn dies im Hinblick auf Kapazitätsgrenzen, die
            Sicherheit oder Integrität der Server oder zur Durchführung
            technischer Wartungs- oder Instandsetzungsmaßnahmen erforderlich ist
            und dies der ordnungsgemäßen oder verbesserten Erbringung der
            Leistungen dient. Wir berücksichtigen in diesen Fällen Ihre
            berechtigten Interessen, insbesondere durch Vorabinformationen. Die
            Haftung der Wechselgott GmbH wegen Verschulden nach Ziffer 9 bleibt
            unberührt.
            <br />
            <br />
            2.5 Wir übernehmen keinerlei Gewähr für die Aktualität, Richtigkeit,
            Vollständigkeit oder Qualität der in der WechselGott-App
            bereitgestellten Informationen. Haftungsansprüche gegen uns, die
            sich auf Schäden materieller oder ideeller Art beziehen, die durch
            die Nutzung oder Nichtnutzung der gegebenen Informationen bzw. durch
            die Nutzung fehlerhafter und unvollständiger Informationen
            verursacht wurden, sind grundsätzlich ausgeschlossen, sofern von uns
            nicht nachweislich vorsätzliches oder grob fahrlässiges Verschulden
            vorliegt.
            <br />
            <br />
            <b>3 Leistungen der WechselGott-App</b>
            <br />
            <br />
            3.1 Die WechselGott-App erbringt Ihnen über multibankingfähige, auf
            mobilen Endgeräten und als Webportal nutzbare Applikation, einen
            Service zur Analyse der hinterlegten Konten zur Erstellung einer
            Übersicht über alle Ihre Verträge und Ihrer finanziellen Situation.
            Darüber hinaus bietet die Applikation weiter Funktionen, wie z.B.
            eine integrierte Kündigungsfunktion, nach Maßgabe dieser Allgemeinen
            Geschäftsbedingungen sowie der gesonderten Datenschutzhinweise.
            <br />
            <br />
            3.2 Um die WechselGott-App zwecks Durchführung der Finanzanalyse
            nutzen zu können, analysieren wir Ihr Bankkonto. Diese Kontoanalyse
            führen wir wie folgt durch:
            <br />
            <br />
            3.2.1 Ihr Bankkonto wird auf regelmäßige Gutschriften,
            SEPA-Lastschriften sowie Daueraufträge über standardisierte
            Schnittstellen in der Finanzindustrie abgerufen und geprüft.
            <br />
            <br />
            3.2.2 Damit die Daten über diese Bankenschnittstelle abgerufen
            werden können, müssen Sie sich zunächst als Kontoinhaber mit Ihren
            Login-Daten für das Online-Banking (in der Regel Kontonummer und
            PIN) gegenüber der Bankenschnittstelle authentifizieren.
            Anschließend werden die Umsätze der letzten Zeit übermittelt. Der
            Zeitraum ist davon abhängig, wie lange Ihre geschäftsführende Bank
            die Daten übermittelt. Dies sind zumindest 90 Tage.
            <br />
            <br />
            3.2.3 Unser Vertragspartner Aboalarm strukturiert und kategorisiert
            aus den uns übermittelten Umsatzdaten die für unsere Tätigkeit
            relevanten Lastschriften, Gutschriften und Daueraufträge.
            Kategorisierung heißt, dass die von Ihnen getätigten Umsätze
            unterschiedlichen Ausgaben zugeordnet werden, wie etwa Miete oder
            Mobilität. Auf dieser Analyse erstellen wir eine digitale Übersicht
            Ihrer Verträge.
            <br />
            <br />
            3.2.4 Um den Registrierungsprozess zu vereinfachen, kann zusätzlich
            zu den Umsatzdaten bei manchen Bankenschnittstellen optional auch
            die bei der Bank gespeicherten personenbezogenen Informationen (z.B.
            Vor- und Nachname, Postadresse und E-Mail-Adresse) an uns übertragen
            werden. Dieser Übermittlung müssen Sie ausdrücklich zustimmen.
            <br />
            <br />
            3.2.5 Die Nutzung der WechselGott-App setzt voraus, dass Sie den
            gesonderten Datenschutzhinweisen zugestimmt haben. Die
            Datenschutzhinweise enthalten auch ausdrücklich Ihr Recht, die
            Zustimmung jederzeit ganz oder teilweise zu widerrufen. Sollten Sie
            dieses Recht vollständigen oder teilweise ausüben, ist es allerdings
            nicht mehr möglich, die WechselGott-App vollständig oder teilweise
            weiter zu nutzen.
            <br />
            <br />
            3.2.6 Sie können die Leistungen der WechselGott-App nur dann nutzen,
            wenn Sie vor der Authentifizierung mit Ihrem Girokonto ausdrücklich
            die Allgemeinen Geschäftsbedingungen und die gesonderten
            Datenschutzhinweise bestätigt haben.
            <br />
            <br />
            3.3 Sie können bestehende Verträge über unsere Technologiepartner
            Aboalarm kündigen. Dazu müssen Sie die Schaltfläche „Kündigung
            abschicken“ anklicken. Dies führt zu weiteren Kosten. Die Kosten
            werden entsprechend ausgewiesen und Sie müssen dies ausdrücklich
            bestätigen.
            <br />
            <br />
            <b>4 Informationspflichten und Vertragsabschluss</b>
            <br />
            <br />
            4.1 Ihr Vertragspartner ist die Wechselgott GmbH, unsere Anschrift
            und die weiteren Angaben entnehmen Sie dem{' '}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://wechselgott.com/impressum/">
                Impressum
            </a>
            .
            <br />
            <br />
            4.2 Durch die Registrierung durch Eingabe von Name, Adresse,
            Telefonnummer und E-Mail Adresse, Einbeziehung der AGB und der
            Kenntnisnahme von unseren Datenschutzhinweisen durch Anklicken,
            schließen Sie mit uns einen Nutzungsvertrag über den Zugriff auf die
            Leistungen der WechselGott-App, der auch das Empfangen von
            Mitteilungen von WechselGott über Nachrichten über die
            WechselGott-App enthält, sowie die Verarbeitung Ihrer Daten sowie
            zur Entgegennahme unserer Leistungen nach Maßgabe dieser Allgemeinen
            Geschäftsbedingungen und der gesonderten Datenschutzhinweise durch
            die Wechselgott GmbH. Dieser Vertrag ist für Sie kostenfrei und ohne
            weitere, über die Nutzungsmöglichkeit hinausgehende Verpflichtungen.
            Durch die Eingabe Ihrer gültigen deutschen Bankverbindungen erhalten
            Sie in der App die Möglichkeit, sich Ihre Kontodaten aufbereiten zu
            lassen. Erst durch das Erteilen eines Maklermandates über die
            WechselGott–App, was Sie in der App gesondert erteilen müssen, geben
            Sie uns die Möglichkeit, für Sie tätig zu werden und Ihre Interessen
            im Bereich Energie, Haushaltsverträge und Versicherung zu vertreten.
            Sie können die Eingabe Ihrer Daten vor dem jeweiligen Absenden
            überprüfen und Fehler bei der Eingabe gegebenenfalls korrigieren.
            <br />
            <br />
            4.3 Sobald Sie die WechselGott-App von Ihrem Gerät löschen, kündigen
            Sie den Nutzungsvertrag über die Nutzung der WechselGott-App. Wir
            werden dann nicht mehr aktiv für Sie tätig, dies berührt jedoch
            nicht die Wirksamkeit des Maklervertrages. Dieser Maklervertrag ist
            so lange wirksam, bis Sie ihn uns gegenüber schriftlich kündigen.
            <br />
            <br />
            4.4 WechselGott speichert in der App keine Vertragstexte oder hält
            diese dauerhaft für den Nutzer zum Abruf bereit. Sollte es für die
            Vertragserbringung nötig sein, fragt WechselGott diese beim
            Versicherer in Abstimmung mit Ihnen an. Vertragssprache ist deutsch.
            <br />
            <br />
            4.5 Über unseren Vertragspartner „Wechselpilot“ können Sie durch das
            Anklicken und Bestätigen dieser Möglichkeiten auch Strom- und/oder
            Gaslieferverträge automatisch kündigen und neu abschließen lassen.
            Über dabei eventuell anfallende Kosten für Sie werden Sie
            informieren, bevor Sie hier rechtsverbindliche Verpflichtungen
            eingehen.
            <br />
            <br />
            <b>5 Ihre Mitwirkungsleistungen</b>
            <br />
            <br />
            5.1 Sie sind zur angemessenen Mitwirkung bei der Nutzung der
            Leistungen der WechselGott-App verpflichtet. Dazu zählt insbesondere
            die Authentifizierung mit den Login-Daten Ihres Online-Bankings
            (z.B. Kontonummer und PIN) gegenüber der Bankenschnittstelle.
            <br />
            <br />
            5.2 Sie dürfen kein Konto einer Drittperson ohne deren ausdrückliche
            Erlaubnis in der WechselGott-App hinterlegen. Auch dürfen Sie keine
            Sicherheitsfunktionen der WechselGott-App umgehen oder dies
            versuchen.
            <br />
            <br />
            5.3 Verstoßen Sie gegen Ihre Pflichten aus diesen Allgemeinen
            Geschäftsbedingungen, sind wir berechtigt, die betroffenen
            Leistungen abzubrechen oder den gewünschten Service nicht
            durchzuführen.
            <br />
            <br />
            <b>6 Vergütung</b>
            <br />
            <br />
            6.1 Wir bieten Ihnen die Nutzung der WechselGott-App kostenfrei an.
            Wir weisen darauf hin, dass wir bei der Erbringung von
            Maklerdienstleistungen von Versicherern Provision erhalten.
            <br />
            <br />
            6.2 Bei Nutzung der Optimierungsfunktion Ihrer Energieverträge,
            erhalten wir und unser Partner Wechselpilot von Ihnen jeweils ein
            Honorar in Höhe von 10 % der für Sie gewonnenen jährlichen
            Ersparnis, bei Vermittlung provisionsfreier Verträge.
            <br />
            <br />
            6.3 Weitere Funktionen, wie z.B. die Nutzung der Kündigungsfunktion
            unseres Technologiepartners Aboalarm sind kostenpflichtig. Sollte
            eine weitere Funktion kostenpflichtig sein, werden wir Sie darüber
            informieren und das Entgelt transparent darstellen. Ohne Ihre
            Bestätigung der Funktion können Sie diese nicht nutzen, so dass
            keine Kosten für Sie anfallen.
            <br />
            <br />
            <b>7 Datenschutz</b>
            <br />
            <br />
            7.1 Die Wechselgott GmbH verarbeitet Ihre personenbezogenen Daten
            ausschließlich zum Zweck der Erbringung der Leistungen der
            Wechselgott App. Eine darüberhinausgehende Verwendung Ihrer
            personenbezogenen Daten erfolgt nicht. Hinsichtlich der Einzelheiten
            wird auf die Datenschutzbedingungen der Wechselgott-App verwiesen,
            die auf der Webseite der Wechselgott GmbH und in der Wechselgott App
            hinterlegt sind und dort jederzeit abgerufen werden können.
            <br />
            <br />
            7.2 Weiterhin kann es auch zu einer Verarbeitung von anonymisierten
            Banktransaktionsdaten kommen, die wir zum Trainieren der Algorithmen
            für die Wechselgott App zwecks Verbesserung oder Erweiterung unseres
            Services benötigen. Dabei werden keine personenbezogenen Daten mehr
            verarbeitet, rein hilfsweise wäre dies auch zur Wahrung der
            berechtigten Interessen der Wechselgott GmbH nach Artikel 6 Abs. 1
            lit. f) DSGVO zulässig.
            <br />
            <br />
            <b>8 Haftung der Wechselgott GmbH</b>
            <br />
            <br />
            8.1 Die Wechselgott GmbH haftet auf Schadensersatz nach den
            gesetzlichen Bestimmungen für Personenschäden und für Schäden nach
            dem Produkthaftungsgesetz. Ebenso haften wir nach den gesetzlichen
            Bestimmungen für Schäden, die durch arglistiges Verhalten verursacht
            wurden, sowie für Schäden, die durch Vorsatz oder grobe
            Fahrlässigkeit von WechselGott, den gesetzlichen Vertreter oder den
            leitenden Angestellten im Sinne des Betriebsverfassungsgesetzes der
            Wechselgott GmbH verursacht wurden.
            <br />
            <br />
            8.2 Die Wechselgott GmbH haftet ausdrücklich nicht für die
            Richtigkeit unserer Analysen, da diese auf Umsatzdaten der
            Vergangenheit beruhen und wir versuchen, erkennbare Muster auf die
            Zukunft zu übertragen. Dabei können dabei Änderungen oder Ereignisse
            auftreten, die außerhalb unseres Einflussbereiches liegen.
            <br />
            <br />
            8.3 Für sonstige Schäden haften wir, sofern sich nicht aus einer von
            WechselGott übernommenen Garantie etwas anderes ergibt, nach Maßgabe
            dieser Ziffer 8.
            <br />
            <br />
            8.4 Die Wechselgott GmbH haftet auf Schadensersatz begrenzt auf die
            Höhe des vertragstypischen vorhersehbaren Schadens für Schäden aus
            einer leicht fahrlässigen Verletzung wesentlicher Vertragspflichten
            oder von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung
            des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
            Nutzer regelmäßig vertrauen darf (Kardinalpflichten), sowie für
            Schäden, die von einfachen Erfüllungsgehilfen von WechselGott
            vorsätzlich oder grob fahrlässig verursacht wurden. Im Übrigen ist
            jegliche Haftung von WechselGott für leicht fahrlässig verursachte
            Schäden ausgeschlossen.
            <br />
            <br />
            8.5 Unbeschadet der vorhergehenden Bestimmungen mindert ein
            Mitverschulden des Nutzers – insbesondere die unzureichende
            Erbringung von Mitwirkungsleistungen, Organisationsfehler,
            unzureichende Datensicherung oder ein Verstoß gegen sonstige
            Vertragspflichten – die Höhe eines etwaigen Schadensersatzanspruchs.
            <br />
            <br />
            8.6 Der Nutzer ist verpflichtet, etwaige Schäden im Sinne
            vorstehender Haftungsregelungen unverzüglich gegenüber WechselGott
            schriftlich anzuzeigen oder von WechselGott aufnehmen zu lassen, so
            dass WechselGott möglichst frühzeitig informiert ist und eventuell
            gemeinsam mit dem Nutzer noch Schadensminderung betreiben kann.
            <br />
            <br />
            <b>9 Sonstiges</b>
            <br />
            <br />
            9.1 Diese Geschäftsbedingungen und alle unter Einbeziehung dieser
            Geschäftsbedingungen zwischen WechselGott und dem Nutzer
            geschlossenen Verträge unterliegen dem Recht der Bundesrepublik
            Deutschland unter Ausschluss des Kollisionsrechts und des
            Übereinkommens der Vereinten Nationen über Verträge über den
            internationalen Warenkauf.
            <br />
            <br />
            9.2 Änderungen und Ergänzungen dieser Geschäftsbedingungen bedürfen
            der Schriftform. Dies gilt auch für eine Änderung oder Aufhebung
            dieses Schriftformerfordernisses.
            <br />
            <br />
            9.3 Sollten einzelne Bestimmungen dieser Geschäftsbedingungen
            unwirksam oder nicht durchsetzbar sein oder werden oder eine Lücke
            enthalten, so bleiben die übrigen Bestimmungen hiervon unberührt.
            Die Parteien verpflichten sich, anstelle der unwirksamen oder nicht
            durchsetzbaren Regelung eine wirksame und durchsetzbare Regelung zu
            treffen, die dem wirtschaftlichen Zweck der unwirksamen oder nicht
            durchsetzbaren Regelung am nächsten kommt bzw. diese Lücke ausfüllt.
            <br />
            <br />
            9.4 Im Rahmen der Verordnung über Online-Streitbeilegung zu
            Verbraucherangelegenheiten steht Ihnen unter
            http://ec.europa.eu/consumers/odr/ eine
            Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.
            Wir weisen Sie darauf hin, dass wir gesetzlich verpflichtet sind,
            diesen Hinweis zugeben, aber weder bereit noch verpflichtet sind, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
            <br />
            <br />
            9.5 Alternative Streitbeilegung gemäß
            Verbraucherstreitbeilegungsgesetz: Wir weisen Sie darauf hin, dass
            wir nicht bereit und nicht verpflichtet sind, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
        </WgTypography>
    );
}

import React from 'react';
import {WgTypography} from '../../../../styles/CustomComponents';
import MyLink from '../../../utils/MyLink';

export default function BrokerMandateNoMandateExistsText() {
    let LinkMandate = MyLink('/profile/brokermandatetext');
    return (
        <WgTypography gutterBottom color="textSecondary" text="info">
            Damit wir deine Vertragsdetails bei den Anbietern anfordern können
            und uns künftig als Makler um deine Verträge kümmern dürfen, bitten
            wir dich darum das <LinkMandate>Maklermandat</LinkMandate> digital
            zu unterzeichnen (Das ist nur einmalig notwendig).
        </WgTypography>
    );
}

import React, {useCallback, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {
    WgButton,
    WgContainer,
    WgHeader
} from '../../../../styles/CustomComponents';
import {Box, Grid} from '@material-ui/core';
import {push} from 'connected-react-router';
import {Redirect, useHistory, useLocation, useParams} from 'react-router';
import {
    IContractManualAddition,
    IntervalType
} from '../../../../models/recurringpaymentInterface';
import CategorySelection from '../../../utils/CategorySelection';
import CompanySelection from '../../../utils/CompanySelection';
import {
    categoryByIdSelector,
    categoryIsEnergySelector,
    companyByIdSelector,
    singleAccountSelector
} from '../../../../store/reducers/selectors';
import {ICategory} from '../../../../models/categoryInterface';
import {MeterType} from '../../../../models/productInterface';
import {ContractAdditionInputOverview} from '../../index';
import AccountSelection from '../../../utils/AccountSelection';
import {getBool} from '../../../../store/reducers/selectors/utils';
import {submitRecurringPayment} from '../../../../store/actions/recurringpaymentActions';
import {CompanyType} from '../../../../models/companyInterface';
import {AddPolicyStep} from '../../insurance/views/AddExisting/AddPolicyController';
import {EnergyStep} from '../../../../models/offerInterface';

function getMeterTypeFromCategory(category?: ICategory): MeterType {
    if (typeof category === 'undefined') return MeterType.Electricity;
    return category.name === 'Gas' ? MeterType.Gas : MeterType.Electricity;
}

export enum ContractAdditionStep {
    CATEGORY_SELECTION,
    COMPANY_SELECTION,
    INPUT_OVERVIEW,
    ACCOUNT_SELECTION
}

export interface ContractAdditionControllerProps {
    push: Function;
    submitRecurringPayment: Function;
    match: any;
}

function ContractAdditionController({
    push,
    submitRecurringPayment
}: ContractAdditionControllerProps) {
    const {currentStep} = useParams();
    const history = useHistory();
    const activeStep: ContractAdditionStep = currentStep
        ? Number(currentStep)
        : ContractAdditionStep.CATEGORY_SELECTION;
    const location = useLocation();
    const [state, setState] = useState<IContractManualAddition>({
        amount: 0,
        paymentInterval: IntervalType.MONTHLY
    });

    const [editMode, setEditMode] = useState(false);
    const [categoryIdChanged, setCategoryIdChanged] = useState(false);

    const {isEnergy, category, company, account} = useSelector(reduxState => {
        return {
            isEnergy: categoryIsEnergySelector(reduxState, {
                id: state?.categoryId
            }),
            category: categoryByIdSelector(reduxState, {id: state?.categoryId}),
            company: companyByIdSelector(reduxState, {
                companyId: state?.companyId
            }),
            account: singleAccountSelector(reduxState, {
                accountId: state?.accountId
            })
        };
    });

    useEffect(() => {
        if (isEnergy) {
            push(
                `/recurringpayments/add/energy/${EnergyStep.METER_TYPE_INPUT}`,
                {
                    meterType: getMeterTypeFromCategory(category),
                    isManualAddition: true,
                    categoryId: category?.id
                }
            );
        }
    }, [isEnergy, push, category]);

    useEffect(() => {
        if (categoryIdChanged) {
            if (!isEnergy) {
                push(
                    `/recurringpayments/add/${
                        editMode
                            ? ContractAdditionStep.INPUT_OVERVIEW
                            : ContractAdditionStep.COMPANY_SELECTION
                    }`
                );
            }
            setCategoryIdChanged(false);
        }
    }, [categoryIdChanged, editMode, isEnergy, push]);

    const handleAmountChange = useCallback((amount: number) => {
        setState((state: IContractManualAddition) => ({
            ...state,
            amount: amount
        }));
    }, []);

    const handleChange = (name: keyof IContractManualAddition) => (
        value: any
    ) => {
        if (name === 'accountId') value = value.id;
        setState(shadowedState => ({
            ...shadowedState,
            [name]: value
        }));
        if (name === 'categoryId') {
            setCategoryIdChanged(true);
        } else if (name === 'companyId' || name === 'accountId') {
            handleStepChange(ContractAdditionStep.INPUT_OVERVIEW);
        }
    };

    const steps = [
        <CategorySelection handleChange={handleChange} />,
        <CompanySelection
            handleChange={handleChange}
            searchType={CompanyType.MISC}
        />,
        <ContractAdditionInputOverview
            category={category}
            company={company}
            account={account}
            amount={state?.amount}
            paymentInterval={state?.paymentInterval}
            handleEdit={handleEdit}
            handleAmountChange={handleAmountChange}
            handleChange={handleChange}
        />,
        <AccountSelection handleChange={handleChange('accountId')} />
    ];

    if (getBool(category?.isInsurance)) {
        return (
            <Redirect
                to={
                    typeof category !== 'undefined'
                        ? `/products/existing/${category.id}/${AddPolicyStep.COMPANY_SELECTION}`
                        : `/products/existing/0`
                }
            />
        );
    }

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={'Mein Vertrag'}
                save={handleSubmit}
                hasBackButton
            />
            <WgContainer bodyContent disableGutters={false}>
                <Grid
                    container
                    item
                    direction="column"
                    //TODO this depends on WgContainer padding bottom - needs refactoring because ::after is garbage
                    style={{
                        height: 'calc(100% - 1.5rem)',
                        minHeight: 'fit-content'
                    }}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '1 1 80%', minHeight: 'fit-content'}}
                        container
                        justify="center"
                        wrap="nowrap">
                        {steps[activeStep]}
                    </Grid>
                    {activeStep !== ContractAdditionStep.ACCOUNT_SELECTION && (
                        <Grid
                            item
                            style={{flex: 'auto'}}
                            container
                            direction="column"
                            justify="center">
                            <Box
                                display="flex"
                                flexWrap="nowrap"
                                paddingTop="1rem"
                                flex={1}
                                flexDirection={{xs: 'column', md: 'row'}}
                                justifyContent={{
                                    xs: 'flex-end',
                                    md: 'space-between'
                                }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-end',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    marginBottom={{xs: '1rem', md: 0}}
                                    order={{xs: 1, md: 2}}>
                                    {activeStep !==
                                        ContractAdditionStep.CATEGORY_SELECTION && (
                                        <WgButton
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleSubmit}
                                            aria-label="next">
                                            {activeStep ===
                                            ContractAdditionStep.INPUT_OVERVIEW
                                                ? 'Vertrag hinzufügen'
                                                : activeStep ===
                                                  ContractAdditionStep.COMPANY_SELECTION
                                                ? 'Überspringen'
                                                : 'Weiter'}
                                        </WgButton>
                                    )}
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-start',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    order={{xs: 2, md: 1}}>
                                    <WgButton
                                        onClick={
                                            activeStep ===
                                            ContractAdditionStep.CATEGORY_SELECTION
                                                ? handleCancel
                                                : handleBack
                                        }
                                        variant="contained"
                                        color="default"
                                        fullWidth
                                        aria-label="back">
                                        {activeStep ===
                                        ContractAdditionStep.CATEGORY_SELECTION
                                            ? 'Abbrechen'
                                            : 'Zurück'}
                                    </WgButton>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function handleCancel() {
        push('/recurringpayments');
    }

    function handleBack() {
        history.goBack();
    }

    function handleSubmit() {
        if (activeStep === ContractAdditionStep.INPUT_OVERVIEW) {
            submitRecurringPayment(state);
        } else {
            handleStepChange(activeStep + 1);
        }
    }

    function handleStepChange(nextStep: ContractAdditionStep) {
        push(setStepUrl(nextStep));
    }

    function setStepUrl(nextStep: number): string {
        const path = location.pathname.slice(0, -1);
        return path + nextStep;
    }

    function handleEdit(nextStep: ContractAdditionStep) {
        setEditMode(true);
        handleStepChange(nextStep);
    }
}

export default connect(() => ({}), {
    push,
    submitRecurringPayment
})(ContractAdditionController);

import {createDeepEqualSelector} from './utils';
import {
    IExistingInsuranceProduct,
    IInsuranceProduct
} from '../../../models/productInterface';
import {
    accountsIdSelector,
    companyByIdSelector,
    recurringPaymentCategoryJoinSelector
} from '.';

const productIdSelector = (state: any, props: {productId: number | string}) =>
    props.productId;

const policiesSelector = (state: any) =>
    state.recurringpayments?.policies ?? [];

const insuranceProductsSelector = (state: any) =>
    state.products?.insuranceProducts ?? [];

const visiblePoliciesSelector = createDeepEqualSelector(
    [policiesSelector, accountsIdSelector],
    (
        products: IExistingInsuranceProduct[],
        accountIds: number[]
    ): IExistingInsuranceProduct[] => {
        return products.filter(product => {
            return (
                typeof product.accountId === 'undefined' ||
                accountIds.includes(product.accountId)
            );
        });
    }
);

export const policySelector = createDeepEqualSelector(
    [visiblePoliciesSelector, (state: any) => state],
    (products: IExistingInsuranceProduct[], state: any) => {
        const recurringPayments = recurringPaymentCategoryJoinSelector(
            state,
            {}
        );
        const visibleRecurringPaymentIds: number[] = recurringPayments.map(
            rp => rp.recurringPaymentId
        );
        if (products && products instanceof Array) {
            return products
                .filter(
                    product =>
                        typeof product.recurringPaymentId === 'undefined' ||
                        visibleRecurringPaymentIds.includes(
                            product.recurringPaymentId
                        )
                )
                .map((product: IExistingInsuranceProduct) => {
                    product.company = companyByIdSelector(state, {
                        companyId: product.companyId
                    });
                    product.recurringPayment = recurringPayments.find(
                        rp =>
                            rp.recurringPaymentId === product.recurringPaymentId
                    );
                    return product;
                })
                .sort(
                    (
                        current: IExistingInsuranceProduct,
                        next: IExistingInsuranceProduct
                    ) => {
                        //Sorting Array with the hightest negative value as the first object
                        return (
                            (current.recurringPayment?.lastAmount ?? 0) -
                            (next.recurringPayment?.lastAmount ?? 0)
                        );
                    }
                );
        } else {
            return [];
        }
    }
);

export const allInsuranceProductsSelector = createDeepEqualSelector(
    [insuranceProductsSelector],
    (products: any): IInsuranceProduct[] => {
        const prices = [
            '5,06€',
            '29,90€',
            '1,86€',
            '2,83€',
            '2,53€',
            '3,32€',
            '5,55€'
        ];
        if (products && products instanceof Array)
            return products.map((product: IInsuranceProduct, index: number) => {
                return {...product, price: prices[index]};
            });
        else return [];
    }
);

export const productByIdSelector = createDeepEqualSelector(
    [policySelector, productIdSelector],
    (
        products: IExistingInsuranceProduct[],
        id: number | string
    ): IExistingInsuranceProduct | undefined => {
        return products.find(
            (product: IExistingInsuranceProduct) =>
                Number(product.id) === Number(id)
        );
    }
);

import React from 'react';
import {Backdrop, Box, CircularProgress} from '@material-ui/core';

export default function WgCircularProgressTokenCheck() {
    return (
        <Box position="fixed" zIndex={1399} top="40%" left="calc(50% - 20px)">
            <Backdrop open style={{backgroundColor: 'rgba(0, 0, 40, 0.15)'}} />
            <CircularProgress />
        </Box>
    );
}

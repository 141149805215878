import React from 'react';
import {Box, Grid, Divider} from '@material-ui/core';
import {
    IPolicyFormData,
    IPolicyData,
    CarInsuranceOneProductType
} from '../../../../../../models/productInterface';
import {formatMoney, formatDeductible} from '../../../../../utils/Format';
import {
    WgInputField,
    WgRadioGroup
} from '../../../../../../styles/CustomComponents';

interface ProductTypeSelectionProps {
    formData: IPolicyFormData;
    policyData: IPolicyData;
    updatePolicyData: Function;
    process: CarInsuranceOneProductType;
    helperText?: React.ReactNode;
    error?: boolean;
}

export default function ProductTypeSelection({
    formData,
    policyData,
    updatePolicyData,
    process,
    helperText,
    error
}: ProductTypeSelectionProps) {
    const deductiblePartiallySelection = {
        name: 'deductiblePartial',
        label: 'Selbstbeteiligung (Euro)',
        type: 'text',
        defaultValue: policyData.deductiblePartial,
        handleChange: {handleChange},
        options: formData.deductiblePartial.map(key => {
            return {
                label: formatMoney(Number(formatDeductible(key.toString()))),
                value: key.toString()
            };
        })
    };
    const deductibleFullySelection = {
        name: 'deductibleFull',
        label: 'Selbstbeteiligung (Euro)',
        type: 'text',
        defaultValue: policyData.deductibleFull,
        handleChange: {handleChange},
        options: formData.deductibleFull.map(key => {
            return {
                label: formatMoney(Number(formatDeductible(key.toString()))),
                value: key.toString()
            };
        })
    };

    const kinds = [
        {
            label: 'Haftpflicht',
            value: 'vehicle-liability'
        },
        {
            label: 'Teilkasko',
            value: 'partial-comprehensive',
            child:
                process !== CarInsuranceOneProductType.SWITCH_INSURANCE ? (
                    <Box>
                        <Divider light />
                        <Box paddingLeft="1.5rem">
                            <WgInputField
                                fullWidth
                                required
                                {...deductiblePartiallySelection}
                                handleChange={handleChange}
                            />
                        </Box>
                    </Box>
                ) : (
                    <React.Fragment />
                )
        },
        {
            label: 'Vollkasko',
            value: 'fully-comprehensive',
            child:
                process !== CarInsuranceOneProductType.SWITCH_INSURANCE ? (
                    <Box>
                        <Divider light />
                        <Box paddingLeft="1.5rem">
                            <WgInputField
                                fullWidth
                                required
                                {...deductibleFullySelection}
                                handleChange={handleChange}
                            />
                        </Box>
                    </Box>
                ) : (
                    <React.Fragment />
                )
        }
    ];

    return (
        <Grid container direction="column" spacing={1}>
            <WgRadioGroup
                aria-label="Kind of insurance"
                name="productType"
                selected={policyData.productType}
                handleChange={handleChange}
                isDividerNeeded
                group={kinds}
                helperText={helperText}
                error={error}
            />
        </Grid>
    );

    function handleChange(event: React.ChangeEvent<any>) {
        const paramName = event.target.name;
        let paramValue = event.target.value;
        if (paramName.includes('deductible')) {
            paramValue = +event.target.value;
        }

        updatePolicyData({
            ...policyData,
            [paramName]: paramValue
        });
    }
}

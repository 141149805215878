import React from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {WgButton} from './index';
import {Grid} from '@material-ui/core';

export interface WgSplitButtonProps {
    leftText: string;
    rightText: string;
    leftLink: string;
    rightLink: string;
    disabled?: 'left' | 'right' | 'all';
}

const useStyles = makeStyles(() =>
    createStyles({
        splitButton: {
            maxWidth: 'unset',
            height: '64px',
            borderRadius: '4px',
            lineHeight: '1.3',
            fontSize: '0.8rem'
        }
    })
);

export default function WgSplitButton({
    leftText,
    rightText,
    leftLink,
    rightLink,
    disabled
}: WgSplitButtonProps) {
    const classes = useStyles();
    return (
        <Grid container wrap="nowrap" justify="space-between" spacing={1}>
            <Grid item xs={6}>
                <WgButton
                    style={{textAlign: 'center'}}
                    disabled={disabled === ('left' || 'all')}
                    className={classes.splitButton}
                    variant="contained"
                    color="primary"
                    link={leftLink}>
                    {leftText}
                </WgButton>
            </Grid>
            <Grid item xs={6}>
                <WgButton
                    style={{textAlign: 'center'}}
                    disabled={disabled === ('right' || 'all')}
                    className={classes.splitButton}
                    variant="contained"
                    color="primary"
                    link={rightLink}>
                    {rightText}
                </WgButton>
            </Grid>
        </Grid>
    );
}

import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import RadioButtonEnergy from './RadioButtonEnergy';
import {connect} from 'react-redux';
import {userActions} from '../../../../store/actions';
import {getUserUserData} from '../../../../store/reducers/selectors';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../styles/CustomComponents';

const {updateUser} = userActions;

interface IProps {
    updateUser: Function;
    energy: string;
}

function Energy({energy, updateUser}: IProps) {
    const [energyType, setEnergy] = useState({energy: energy || 'price'});
    const [hasAskToReturn, setHasAskToReturn] = useState(false);

    const save = () => {
        updateUser(energyType);
        setHasAskToReturn(false);
        window.history.back();
    };
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Vertragsoptimierung"
                hasBackButton
                backText="Mein Profil"
                hasAskToReturn={hasAskToReturn}
                save={save}
            />

            <WgContainer bodyContent disableGutters={false}>
                <WgTypography color="textSecondary" text="info">
                    Hier kannst du einstellen, ob deine Stromverträge in
                    Hinblick auf Umweltfreundlichkeit oder Preis optimiert
                    werden sollen.
                </WgTypography>

                <Box marginTop="1.5rem">
                    <RadioButtonEnergy
                        value={energyType.energy}
                        onChange={(event: any) => {
                            setHasAskToReturn(true);
                            return setEnergy({energy: event.target.value});
                        }}
                        handleChange={(newEnergyType: string) => {
                            setHasAskToReturn(true);
                            return setEnergy({energy: newEnergyType});
                        }}
                    />
                </Box>

                <Box
                    display="flex"
                    justifyContent={{xs: 'center', md: 'flex-end'}}
                    marginTop="1.5rem">
                    <WgButton
                        defaultButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        aria-label="save"
                        onClick={save}>
                        Speichern
                    </WgButton>
                </Box>
            </WgContainer>
        </WgContainer>
    );
}

function mapStateToProps(state: any) {
    return {
        ...getUserUserData(state)
    };
}

export default connect(mapStateToProps, {updateUser})(Energy);

import React from 'react';
import {createStyles, Theme, Typography, withStyles} from '@material-ui/core';
import {TypographyProps} from '@material-ui/core/Typography/Typography';
import clsx from 'clsx';
import {formatMoney, formatPercent} from '../../containers/utils/Format';

const styles = (theme: Theme) =>
    createStyles({
        // General style
        fitContent: {
            maxWidth: 'fit-content'
        },

        // Text Style
        number: {
            fontFamily: 'Rubik !important'
        },
        coloredRed: {
            color: theme.palette.error.main
        },
        coloredOrange: {
            color: theme.palette.warning.main
        },
        coloredGreen: {
            color: theme.palette.success.main
        },
        uppercase: {
            textTransform: 'uppercase',
            fontSize: '0.625rem',
            letterSpacing: '1px'
        },
        uppercaseSpacing: {
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            letterSpacing: '1.5px'
        },
        content: {
            fontWeight: 600,
            lineHeight: 'normal'
        },
        contentInfo: {
            fontSize: '1.5rem',
            fontWeight: 800,
            lineHeight: 'normal',
            '@media (max-width: 320px)': {
                fontSize: '1.25rem'
            }
        },
        subContent: {
            fontSize: '0.75rem',
            fontWeight: 600,
            lineHeight: 'normal'
        },
        sentence: {
            fontWeight: 600,
            lineHeight: 1.25
        },
        sentenceSmall: {
            fontSize: '0.75rem'
        },
        info: {
            fontSize: '0.875rem'
        },
        link: {
            fontSize: '0.875rem',
            cursor: 'pointer'
        },
        tooltip: {
            fontSize: '0.75rem',
            letterSpacing: '1px',
            fontWeight: 600,
            fontFamily: 'Muli !important',
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem'
            }
        },

        // Number Style
        list: {
            fontSize: '0.875rem',
            lineHeight: 'normal'
        },
        formHeader: {
            fontSize: '2.25rem',
            fontWeight: 200
        },
        amount: {
            fontSize: '1.5rem',
            fontWeight: 200
        },

        // Money Style
        money: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'baseline'
        },

        // Header Style
        header: {
            fontFamily: 'Montserrat !important'
        },
        headerDefault: {
            fontSize: '1.25rem',
            fontWeight: 800,
            letterSpacing: '1.3px',
            textTransform: 'uppercase',
            '@media (max-width: 320px)': {
                fontSize: '1.125rem'
            }
        },
        subHeader: {
            fontWeight: 600
        },
        subTitle: {
            letterSpacing: '1px'
        }
    });

export interface WgTypographyProps extends TypographyProps {
    text?:
        | 'content'
        | 'contentInfo'
        | 'subContent'
        | 'sentence'
        | 'sentenceSmall'
        | 'link'
        | 'tooltip'
        | 'info';
    header?:
        | 'subHeader'
        | 'headerDefault'
        | 'subTitle'
        | 'uppercase'
        | 'uppercaseSpacing';
    number?: 'list' | 'amount' | 'formHeader';
    colored?: boolean | 'rp';
    classes: any;
    gutterTop?: string;
    money?: boolean | 'decimalsLowercase';
    percent?: boolean;
    fontSize?: string;
    fitContent?: boolean;
    withSign?: boolean;
    suffix?: string;
    component?: 'div' | 'span' | 'p';
}

function WgTypography({
    children,
    classes,
    text,
    header,
    number,
    colored,
    gutterTop,
    money,
    percent,
    fontSize,
    fitContent,
    withSign,
    suffix,
    component,
    ...other
}: WgTypographyProps) {
    let color = calculateColor();
    let formattedNumber = calculateFormattedNumber();
    if (formattedNumber) {
        return formattedNumber;
    }
    children = calculateDecimalsLowercase();
    children = calculateChildrenWithSign();
    children = calculateChildrenWithSuffix();
    return (
        <Typography
            component={component ? component : 'p'}
            style={{
                marginTop: gutterTop,
                fontSize: `${fontSize || ''}`
            }}
            className={clsx({
                [`${text && classes[text]}`]: text,
                [`${classes['header']} ${header && classes[header]}`]: header,
                [`${classes['number']} ${number && classes[number]}`]: number,
                [classes[`colored${color}`]]: colored,
                [classes['fitContent']]: fitContent
            })}
            {...other}>
            {children}
        </Typography>
    );

    function calculateColor() {
        let color;
        if (colored && typeof children === 'number') {
            color =
                children < 0 ? (colored === 'rp' ? 'Orange' : 'Red') : 'Green';
        }
        return color;
    }

    function calculateFormattedNumber() {
        let formattedNumber;
        if (money && typeof children === 'number') {
            let value = formatMoney(children);
            if (money === 'decimalsLowercase') {
                const [number, sign] = value.split(' ');
                const [digit, decimals] = number.split(',');
                formattedNumber = (
                    <span
                        className={clsx({
                            [`${classes['number']} ${number &&
                                classes[number]}`]: number,
                            [classes[`colored${color}`]]: colored,
                            [classes['money']]: money
                        })}>
                        {digit + ','}
                        <Typography style={{fontSize: '0.75em'}}>
                            {decimals}
                        </Typography>
                        {sign}
                    </span>
                );
            }
        }
        return formattedNumber;
    }

    function calculateDecimalsLowercase() {
        if (money && typeof children === 'number') {
            let value = formatMoney(Number(children));
            if (money !== 'decimalsLowercase') {
                children = value;
            }
        }
        return children;
    }

    function calculateChildrenWithSign() {
        if (percent && typeof children === 'number') {
            let sign = children < 0 ? '- ' : '+ ';
            return sign + formatPercent(children); // TODO
        }
        return children;
    }

    function calculateChildrenWithSuffix() {
        if (typeof children === 'number') {
            let sign = children < 0 ? '-' : '+';
            if (withSign) {
                children = `${sign} ${children}`;
            }
            if (suffix) {
                children = `${children} ${suffix}`;
            }
        }
        return children;
    }
}

export default withStyles(styles)(WgTypography);

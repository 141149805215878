import {IBank} from '../models/bankInterface';
import {
    AllSubstringsIndexStrategy,
    LowerCaseSanitizer,
    Search
} from 'js-search';
import {useEffect, useState} from 'react';
import {ICategory} from '../models/categoryInterface';
import {ICompany} from '../models/companyInterface';
import useDebounce from './useDebounce';

export let search: Search;

export const setSearchBankParams = (
    banks: IBank[],
    initialSearchString?: string,
    searchTerm?: string
) => {
    const banksFromSearchString = initialSearchString
        ? banks.filter(
              bank =>
                  bank.additionalSearchString &&
                  bank.additionalSearchString === initialSearchString
          )
        : banks;

    // set search parameters on first render
    search = new Search('name');
    search.indexStrategy = new AllSubstringsIndexStrategy();
    search.sanitizer = new LowerCaseSanitizer();
    search.addIndex('name');
    search.addIndex('blz');
    search.addIndex('bic');
    search.addIndex('additionalSearchString');
    search.addDocuments(banksFromSearchString);
    return searchTerm ? search.search(searchTerm) : banks;
};

export const setSearchInsuranceParams = (
    objects?: ICategory[] | ICompany[],
    categoryId?: any,
    searchTerm?: string
): any[] => {
    if (!objects) {
        return [];
    }

    // set search parameters on first render
    search = new Search('name');
    search.indexStrategy = new AllSubstringsIndexStrategy();
    search.sanitizer = new LowerCaseSanitizer();
    search.addIndex('name');
    if (typeof categoryId !== 'undefined') {
        search.addIndex('parentName');
    }
    search.addDocuments(objects);
    return searchTerm ? search.search(searchTerm) : objects;
};

export default function useDebounceSearch(searchTerm: string, delay: number) {
    const debouncedSearchTerm = useDebounce(searchTerm, delay);
    const [isSearching, setIsSearching] = useState(false);
    const [results, setResults] = useState<any[]>([]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            setIsSearching(true);
            searchCharacters(debouncedSearchTerm, search).then(
                (results: any) => {
                    setIsSearching(false);
                    setResults(results);
                }
            );
        } else {
            setResults([]);
        }
    }, [debouncedSearchTerm]);

    return {debouncedSearchTerm, isSearching, results, setResults};
}

function searchCharacters(searchTerm: string, search: Search) {
    return new Promise(function(resolve, reject) {
        try {
            const result = search.search(searchTerm);
            resolve(result);
        } catch (e) {
            reject(e);
        }
    });
}

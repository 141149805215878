import {createAction} from 'redux-actions';
import {
    NOTIFICATION_ADD_LOADING,
    NOTIFICATION_ADD_MESSAGES,
    NOTIFICATION_REMOVE_LOADING,
    NOTIFICATION_REMOVE_MESSAGE,
    NOTIFICATION_SET_DIALOG,
    NOTIFICATION_SET_REGISTER_STATUS,
    NOTIFICATION_SET_SNACKBAR,
    NOTIFICATION_UPDATE_DIALOG,
    NOTIFICATION_UPDATE_SNACKBAR
} from './actionTypes';
import {
    IDialog,
    ILoading,
    IMessage,
    INotification,
    ISnackbar
} from '../../models/notificationInterface';
import CookieBanner from '../../containers/utils/CookieBanner';
import {initTracking} from '../../tracking';
import {
    categoryByIdSelector,
    getUserUserData,
    offerByIdSelector,
    queueSelector,
    recurringPaymentByIdSelector
} from '../reducers/selectors';
import {
    getCookie,
    getSearchObject,
    setCookie
} from '../../containers/utils/Cookie';
import {api, getAuthConfig, performApiCall} from './api';
import {fetchEnergyOffers} from './offerActions';
import {Dispatch} from 'redux';
import {getAvailableOffer, OfferState} from '../../models/offerInterface';
import WgLoadingDialog from '../../containers/app/loading_components/WgLoadingDialog';
import WgNotificationDialog from '../../containers/app/loading_components/WgNotificationDialog';
import {LoadingPriorityCodes} from '../reducers/notification';
import {ReactElement} from 'react';

// *** ACTION CREATORS ***
export const notification_set_dialog = createAction(NOTIFICATION_SET_DIALOG);
export const notification_set_snackbar = createAction(
    NOTIFICATION_SET_SNACKBAR
);
export const notification_update_dialog = createAction(
    NOTIFICATION_UPDATE_DIALOG
);
export const notification_update_snackbar = createAction(
    NOTIFICATION_UPDATE_SNACKBAR
);
export const notification_add_messages = createAction(
    NOTIFICATION_ADD_MESSAGES
);
export const notification_remove_message = createAction(
    NOTIFICATION_REMOVE_MESSAGE
);
export const setRegisterStatus = createAction(NOTIFICATION_SET_REGISTER_STATUS);
export const addLoading = createAction(NOTIFICATION_ADD_LOADING);
export const removeLoading = createAction(NOTIFICATION_REMOVE_LOADING);

export const setNotification: any = (notification: INotification) => async (
    dispatch: Function,
    getState: Function
) => {
    const dialog = {...notification.dialog, component: undefined};
    setDialog({...notification, dialog})(dispatch, getState);
};

export function addNotificationDialog(
    id: string,
    title: string,
    description: string
) {
    return addLoading({
        id,
        priority: LoadingPriorityCodes.NOTIFICATION_DIALOG,
        component: WgNotificationDialog,
        props: {
            id: id,
            title: title,
            description: description,
            hasAgreeButton: true,
            isAgreeable: true,
            open: true
        }
    });
}

export const setDialog: any = (dialog: IDialog) => async (
    dispatch: Function
) => {
    dispatch(notification_set_dialog(dialog));
};

export const setOpenDialog: any = (open: boolean) => async (
    dispatch: Function
) => {
    dispatch(notification_update_dialog({open}));
};

export const setOpenSnackbar: any = (open: boolean) => async (
    dispatch: Function
) => {
    dispatch(notification_update_snackbar({open}));
};

export const setSnackbar: any = (snackbar: ISnackbar) => async (
    dispatch: Function
) => {
    dispatch(notification_set_snackbar(snackbar));
};

export const displayErrorNotification = (
    text: string,
    afterClose?: Function
) => async (dispatch: Function) => {
    dispatch(
        setSnackbar({
            text,
            afterClose,
            variant: 'error',
            open: true
        })
    );
};

export const displayNotification: Function = (
    title: string,
    text = '',
    isAgreeable = true,
    image?: string,
    className?: string
) => (dispatch: Function) => {
    dispatch(
        notification_set_dialog({
            title: title,
            text: text,
            isAgreeable: isAgreeable,
            open: true,
            image: image,
            className: className
        })
    );
};

export const showCookieBanner: any = () => async (dispatch: Function) => {
    const consent = getCookie('consent') || getSearchObject().consent;
    switch (consent) {
        case 'all':
        case 'essentials':
            setCookie('consent', consent, 30);
            initTracking();
            break;
        default:
            dispatch(
                setDialog({
                    component: CookieBanner,
                    open: true,
                    disableBackdropClick: true,
                    afterClose: initTracking
                })
            );
    }
};

export const performCloseMessage: any = (notificationId: number) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;

        await dispatch(notification_remove_message());
        await api.delete(
            '/notifications/' + notificationId,
            getAuthConfig(token, {Accept: 'application/json'})
        );
    }, 'Ein Fehler beim Ausblenden des Hinweises ist aufgetreten. Versuche es später noch einmal.');

export const fetchMessages: any = () =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;
        const response = await api.get(
            '/notifications',
            getAuthConfig(token, {Accept: 'application/json'})
        );
        const notifications = response.data;
        await notifications.forEach(loopNotification);

        async function loopNotification(notification: any) {
            await dispatch(fetchEnergyOffers(notification.recurringPaymentId));
            const message = buildMessage(getState, notification);
            if (typeof message !== 'undefined') {
                await dispatch(notification_add_messages([message]));
            }
        }
    });

function buildMessage(
    getState: Function,
    notification: any
): IMessage | undefined {
    const recurringPaymentId = notification.recurringPaymentId;
    const recurringPayment = recurringPaymentByIdSelector(getState(), {
        recurringPaymentId
    });
    const category = categoryByIdSelector(getState(), {
        id: recurringPayment?.categoryId
    });
    const offer = offerByIdSelector(getState(), {
        recurringPaymentId: recurringPaymentId
    });
    if (
        typeof offer?.offerState === 'undefined' ||
        offer.offerState > OfferState.ECO_NOT_FOUND
    )
        return;

    return {
        notificationId: notification.id,
        text: '',
        link: `/recurringpayments/details/${recurringPaymentId}?categoryId=${recurringPayment?.categoryId}`,
        categoryId: recurringPayment?.categoryId,
        linkText: category?.name,
        logoPath: category?.logoPath,
        defaultImage: '/img/icon-recurringpayment-circle.svg',
        estimatedSavings: getAvailableOffer(offer)?.estimatedSavings,
        userName: getUserUserData(getState()).firstName,
        ...buildChanges(notification.notificationType)
    };
}

function buildChanges(notificationType: string) {
    if (notificationType === 'EnergyChangeAvailable') {
        return {
            text: 'ein Vertrag von dir ist zu teuer.',
            subtext: 'Du kannst Geld sparen:'
        };
    } else {
        return {
            text: '',
            subtext: undefined
        };
    }
}

export const cleanLoadingQueue = () => async (
    dispatch: Dispatch<any>,
    getState: Function
) => {
    const now = new Date();
    const toBeKilled =
        queueSelector(getState())?.filter(
            (loading: ILoading) => loading.killAt && loading.killAt <= now
        ) ?? [];

    for (const loading of toBeKilled) {
        await dispatch(removeLoading(loading.id));
    }
};

export function addLoadingDialog(
    id: string,
    content?: string | ReactElement,
    hasGif = false
) {
    return addLoading({
        id,
        priority: LoadingPriorityCodes.LOADING_DIALOG,
        component: WgLoadingDialog,
        props: {
            content,
            hasGif
        }
    });
}

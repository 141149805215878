import {ILoading, INotification} from '../../../models/notificationInterface';
import {createDeepEqualSelector} from './utils';

export const getNotification = (state: any): INotification =>
    state?.notification;

export const queueSelector = (state: any): ILoading[] =>
    getNotification(state)?.loading?.queue ?? [];

//Not as Selector chache state of
export const queueItemByIdSelector = createDeepEqualSelector(
    [queueSelector, (_: any, props: {queueId: string}) => props.queueId],
    getLoadingOfQueueId
);

export function getLoadingOfQueueId(
    queue: ILoading[],
    queueId: string
): boolean {
    if (!Array.isArray(queue) || typeof queueId !== 'string') {
        return false;
    }

    const [, id] = queueId.split('-');
    return Boolean(
        queue.find((loading: ILoading) => {
            // split loading id so that Accounts-1 and Accounts-10 doesn't match
            if (typeof id !== 'undefined') {
                // if an id like 'Accounts-10' with a suffix is in the queue then check for length
                // this avoids that 'Accounts-1' and 'Accounts-10' matches
                return loading.id === queueId;
            } else {
                // an id like 'Accounts' without a suffix is in queue. Everything regardless of suffix should match --> includes
                return loading.id.includes(queueId);
            }
        })
    );
}

import React, {Component} from 'react';
import {Area, AreaChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CustomToolTip} from '../extensions/CustomToolTip';
import {CustomActiveDot} from '../extensions/CustomActiveDot';
import {CustomTick} from '../extensions/CustomTick';
import {IChartData} from '../../../../../models/statistics/dashBoardInterface';

interface IProps {
    activeIndex: number;
    pastData: IChartData[];
    forecastData: IChartData[];
    height: number;
    width: number;
    dataKey?: string;
    currentDate: Date;
    initialBalance: number;
}

interface IState {
    pastChartWidth: number;
    forecastChartWidth: number;
    pastStroke: string;
    forecastStroke: string;
    dataMax: number;
    dataMin: number;
    pastDataMaxDate: string;
    pastDataMinDate: string;
    gradientOffsetPast: number;
    gradientOffsetForecast: number;
    hideReferenceLineZero: boolean;
    animate: boolean;
}
export default class CustomAreaChart extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        //Responsivness
        this.state = this.calculateChartDimensions(props);
    }

    componentWillReceiveProps(nextProps: Readonly<IProps>): void {
        this.setState(this.calculateChartDimensions(nextProps));
    }

    calculateChartDimensions(props: IProps) {
        const pastDataBalances = props.pastData.map(i => i.balance);
        const pastDataMax = Math.max(...pastDataBalances);
        const pastDataMin = Math.min(...pastDataBalances);

        const forecastDataBalances = props.forecastData.map(i => i.balance);
        const forecastDataMax = Math.max(...forecastDataBalances);
        const forecastDataMin = Math.min(...forecastDataBalances);

        //Default: use Gradient
        let pastStroke = 'url(#colorStroke)';
        //Default: Color
        let forecastStroke = '#85C1E9';

        let dataMax = Math.max(pastDataMax, forecastDataMax);
        dataMax += (Math.abs(pastDataMin) + Math.abs(pastDataMax)) * 0.25;

        let dataMin = Math.min(pastDataMin, forecastDataMin);

        const containerWidth = props.width;
        const totalDataLength =
            props.pastData.length + props.forecastData.length;
        let pastChartWidth = Math.round(
            (props.pastData.length / totalDataLength) * containerWidth
        );
        let forecastChartWidth = Math.round(
            (props.forecastData.length / totalDataLength) * containerWidth
        );

        // Dont remove this Comments!
        // 1. Edge Case: The chart has to start at 0 - always, otherwise it wont render!
        // 2. Edge Case:
        // Set Stroke as gradient only if we crossing the null reference line, otherwise it wont render the stroke - only necessary in pastChart
        if (dataMin >= 0 && dataMax >= 0) {
            dataMin = 0;
            pastStroke = '#3fd298';
        } else if (dataMin <= 0 && dataMax <= 0) {
            dataMax = 0;
            pastStroke = '#ff6666';
        }
        // 3. Edge Case:
        // Only necessary in current month
        // During the first day of the month => no pastChart, last day of the month => no futureChart
        if (this.props.pastData.length === 1) pastChartWidth = 0;
        else if (this.props.forecastData.length === 1) forecastChartWidth = 0;

        const pastDataMinDate = props.pastData.find(
            (element: any) => element.balance === pastDataMin
        );
        const pastDataMaxDate = props.pastData.find(
            (element: any) => element.balance === pastDataMax
        );

        return {
            dataMax: dataMax,
            dataMin: dataMin,
            pastStroke: pastStroke,
            forecastStroke: forecastStroke,
            pastDataMinDate: pastDataMinDate?.name || '',
            pastDataMaxDate: pastDataMaxDate?.name || '',
            pastChartWidth: pastChartWidth,
            forecastChartWidth: forecastChartWidth,
            gradientOffsetPast: this.gradientOffset(pastDataMax, pastDataMin),
            gradientOffsetForecast: this.gradientOffset(
                forecastDataMax,
                forecastDataMin
            ),
            hideReferenceLineZero: dataMax <= 0 || dataMin >= 0,
            animate: true,
            initialBalance: this.props.initialBalance
        };
    }

    gradientOffset = (dataMax: number, dataMin: number) => {
        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }

        return dataMax / (dataMax - dataMin);
    };

    render() {
        let skipIndexPast = this.props.pastData
            ? this.props.pastData.length
            : 0;
        let skipIndexForecast = this.props.forecastData
            ? this.props.forecastData.length
            : 0;

        return (
            <React.Fragment>
                <AreaChart
                    height={this.props.height}
                    width={this.state.pastChartWidth}
                    data={this.props.pastData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 6,
                        bottom: 10
                    }}>
                    <defs>
                        <linearGradient
                            id="colorArea"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1">
                            <stop
                                offset={0}
                                stopColor="#3fd298"
                                stopOpacity={0.9}
                            />
                            <stop
                                offset={this.state.gradientOffsetPast}
                                stopColor="#3fd298"
                                stopOpacity={0.0}
                            />
                            <stop
                                offset={this.state.gradientOffsetPast}
                                stopColor="#ff6666"
                                stopOpacity={0.0}
                            />
                            <stop
                                offset={1}
                                stopColor="#ff6666"
                                stopOpacity={0.9}
                            />
                        </linearGradient>
                        <linearGradient
                            id="colorStroke"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1">
                            <stop
                                offset={0}
                                stopColor="#3fd298"
                                stopOpacity={0.9}
                            />
                            <stop
                                offset={this.state.gradientOffsetPast}
                                stopColor="#3fd298"
                                stopOpacity={0.9}
                            />
                            <stop
                                offset={this.state.gradientOffsetPast}
                                stopColor="#ff6666"
                                stopOpacity={0.9}
                            />
                            <stop
                                offset={1}
                                stopColor="#ff6666"
                                stopOpacity={0.9}
                            />
                        </linearGradient>
                    </defs>
                    <YAxis
                        domain={[this.state.dataMin, this.state.dataMax]}
                        hide
                    />
                    <XAxis
                        interval={0}
                        tickMargin={5}
                        textAnchor="end"
                        axisLine={false}
                        tickLine={false}
                        stroke="#BFBFBF"
                        dataKey="name"
                        tick={
                            <CustomTick
                                skipIndex={skipIndexPast}
                                pastData={1}
                                futureTick={false}
                                currentDate={this.props.currentDate}
                            />
                        }
                    />
                    <Tooltip
                        wrapperStyle={{zIndex: 1000}}
                        content={
                            <CustomToolTip
                                data={this.props.pastData}
                                labelHint=""
                                initialBalance={this.props.initialBalance}
                            />
                        }
                    />
                    {this.state.hideReferenceLineZero ? (
                        <React.Fragment />
                    ) : (
                        <ReferenceLine
                            y={0}
                            stroke="#bfbfbf"
                            strokeDasharray="4"
                        />
                    )}
                    <ReferenceLine
                        x={
                            this.props.pastData.length > 0 &&
                            this.props.forecastData.length > 0
                                ? this.props.pastData[
                                      this.props.pastData.length - 1
                                  ].name
                                : ''
                        }
                        stroke="#bfbfbf"
                        strokeDasharray="4"
                    />
                    <Area
                        isAnimationActive={this.state.animate}
                        type="monotone"
                        dataKey="balance"
                        stroke={this.state.pastStroke}
                        fill="url(#colorArea)"
                        animationBegin={0}
                        animationDuration={400}
                        activeDot={<CustomActiveDot dotColor="#3fd298" />}
                    />
                </AreaChart>
                <AreaChart
                    width={this.state.forecastChartWidth}
                    height={this.props.height}
                    data={this.props.forecastData}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 10
                    }}>
                    <defs>
                        <linearGradient
                            id="colorAreaFuture"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1">
                            <stop
                                offset={0}
                                stopColor="#85C1E9"
                                stopOpacity={0.9}
                            />
                            <stop
                                offset={this.state.gradientOffsetForecast}
                                stopColor="#85C1E9"
                                stopOpacity={0.0}
                            />
                            <stop
                                offset={this.state.gradientOffsetForecast}
                                stopColor="#85C1E9"
                                stopOpacity={0.0}
                            />
                            <stop
                                offset={1}
                                stopColor="#85C1E9"
                                stopOpacity={0.9}
                            />
                        </linearGradient>
                    </defs>
                    <XAxis
                        interval={0}
                        tickMargin={5}
                        textAnchor="end"
                        axisLine={false}
                        tickLine={false}
                        stroke="#BFBFBF"
                        dataKey="name"
                        label={{offset: 20}}
                        tick={
                            <CustomTick
                                skipIndex={skipIndexForecast}
                                pastData={this.props.pastData.length}
                                futureTick
                                currentDate={this.props.currentDate}
                            />
                        }
                    />
                    <YAxis
                        domain={[this.state.dataMin, this.state.dataMax]}
                        hide
                    />
                    <Tooltip
                        wrapperStyle={{zIndex: 1000}}
                        content={
                            <CustomToolTip
                                data={this.props.forecastData}
                                labelHint=""
                                initialBalance={this.props.initialBalance}
                            />
                        }
                    />
                    {this.state.hideReferenceLineZero ? (
                        <React.Fragment />
                    ) : (
                        <ReferenceLine
                            y={0}
                            stroke="#bfbfbf"
                            strokeDasharray="4"
                        />
                    )}
                    <ReferenceLine
                        x={
                            this.props.forecastData.length > 0
                                ? this.props.forecastData[0].name
                                : ''
                        }
                        stroke="#bfbfbf"
                        strokeDasharray="4"
                    />
                    <Area
                        type="monotone"
                        dataKey="balance"
                        stroke={this.state.forecastStroke}
                        fill="url(#colorAreaFuture)"
                        animationBegin={400}
                        animationDuration={400}
                        activeDot={<CustomActiveDot dotColor="#85C1E9" />}
                    />
                </AreaChart>
            </React.Fragment>
        );
    }
}

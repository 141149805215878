import React, {FormEvent} from 'react';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    createStyles,
    Grid,
    Theme
} from '@material-ui/core';

import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {ReactSVG} from 'react-svg';
import {WgTypography} from '../../../../styles/CustomComponents';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconRoot: {
            maxHeight: '72px'
        },
        iconSelected: {
            color: theme.palette.primary.main
        },
        iconDisabled: {
            color: theme.palette.text.secondary
        }
    })
);

interface IProps {
    value: string;
    onChange?: (event: FormEvent) => void;
    name?: string;
    handleChange?: Function;
}

export default function RadioButtonEnergy({value, handleChange}: IProps) {
    const classes = useStyles();
    const renderCard = (
        energyType: string,
        header: string,
        description: string,
        icon: string
    ) => {
        const isSelected = value === energyType;
        return (
            <Grid item xs={12} md={6}>
                <Card raised={value === energyType}>
                    <CardActionArea
                        onClick={() =>
                            handleChange && handleChange(energyType)
                        }>
                        <CardContent>
                            <Box
                                style={{padding: '0.5rem 0'}}
                                display="flex"
                                justifyContent="flex-start">
                                <Box
                                    style={{minWidth: '5rem'}}
                                    display="flex"
                                    alignItems="center">
                                    <ReactSVG
                                        src={icon}
                                        className={clsx(
                                            classes.iconRoot,
                                            isSelected
                                                ? classes.iconSelected
                                                : classes.iconDisabled
                                        )}
                                    />
                                </Box>
                                <Box
                                    style={{
                                        marginTop: '0.5rem',
                                        marginLeft: '1rem'
                                    }}>
                                    <WgTypography
                                        gutterBottom
                                        color={
                                            isSelected
                                                ? 'textPrimary'
                                                : 'textSecondary'
                                        }
                                        text="content">
                                        {header}
                                    </WgTypography>
                                    <WgTypography
                                        color={
                                            isSelected
                                                ? 'textPrimary'
                                                : 'textSecondary'
                                        }
                                        fontSize="0.75rem">
                                        {description}
                                    </WgTypography>
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    };

    return (
        <Grid container spacing={2} justify="center">
            {renderCard(
                'environmental',
                'Öko-Tarif',
                'Der günstigste Bio-Stromanbieter aus deiner Region.',
                '/img/eco-gray.svg'
            )}
            {renderCard(
                'price',
                'Bester Preis',
                'Der garantiert günstigste Stromanbieter aus deiner Region.',
                '/img/price-gray.svg'
            )}
        </Grid>
    );
}

import React from 'react';
import {
    Box,
    BoxProps,
    ButtonBase,
    createStyles,
    Theme,
    withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import {ButtonBaseProps} from '@material-ui/core/ButtonBase';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            width: '100%',
            height: '100%',
            padding: 0
        },
        defaultColor: {
            backgroundColor: theme.palette.background.default,
            '&:hover': {
                backgroundColor: theme.palette.text.hint
            }
        },
        secondaryColor: {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        },
        leftBorderRadius: {
            borderRadius: `4px 0 0 4px`, // static radius, maybe define through theme spacing
            marginLeft: 0
        },
        rightBorderRadius: {
            borderRadius: `0 4px 4px 0`, // static radius, maybe define through theme spacing
            marginRight: 0
        },
        bottomBorderRadius: {
            borderRadius: `0px 0px 4px 4px`, // static radius, maybe define through theme spacing
            marginLeft: 0
        },
        allBorderRadius: {
            borderRadius: '4px'
        },
        link: {
            textDecoration: 'none'
        }
    });

export interface WgButtonBoxProps extends ButtonBaseProps {
    classes: any;
    bgColor?: 'default' | 'secondary';
    BoxProps?: BoxProps;
    borderRadius?: 'all' | 'left' | 'right' | 'bottom';
    component?: any;
    clone?: boolean;
    height?: any;
    button?: any;
}

function WgButtonBox({
    children,
    classes,
    borderRadius,
    bgColor,
    BoxProps,
    component,
    clone,
    button,
    height,
    ...other
}: WgButtonBoxProps) {
    return (
        <Box
            className={clsx(classes['link'], {
                [classes[`${borderRadius}BorderRadius`]]: borderRadius
            })}
            height={height}
            width="100%"
            clone={clone}
            borderRadius="borderRadius"
            display="flex"
            justifyContent="center"
            component={component ?? 'div'}
            {...BoxProps}>
            <ButtonBase
                className={clsx(classes['button'], {
                    [classes[`${borderRadius}BorderRadius`]]: borderRadius,
                    [classes[`${bgColor}Color`]]: bgColor,
                    [classes[`defaultColor`]]: bgColor === undefined
                })}
                {...other}>
                {children}
            </ButtonBase>
        </Box>
    );
}

export default withStyles(styles)(WgButtonBox);

import React from 'react';
import ObjectSearch from '../views/insurance/views/AddExisting/ObjectSearch';
import {
    allCategorySelector,
    categoryInsuranceSelector,
    initialCategoriesByTypeSelector
} from '../../store/reducers/selectors';
import {useSelector} from 'react-redux';
import {CompanyType} from '../../models/companyInterface';

export interface CategorySelectionProps {
    initialText?: string;
    searchType?: CompanyType;
    handleChange?: (name: 'categoryId' | 'companyId') => (value: any) => void;
}

export default function CategorySelection({
    initialText,
    searchType,
    handleChange
}: CategorySelectionProps) {
    const {categories, initialCategories} = useSelector(reduxState => ({
        categories:
            searchType === CompanyType.INSURANCE
                ? categoryInsuranceSelector(reduxState)
                : allCategorySelector(reduxState),
        initialCategories: initialCategoriesByTypeSelector(reduxState, {
            isInsurance: searchType === CompanyType.INSURANCE
        })
    }));

    return (
        <ObjectSearch
            handleChange={handleChange}
            backText={'Meine Verträge'}
            headerText="Kategorie wählen"
            objects={categories}
            initialObjects={initialCategories}
            initialText={initialText ?? 'Häufig gewählte Kategorien'}
            notFoundText="Keine Kategorie gefunden..."
            searchBarPlaceholder="Kategorie suchen..."
        />
    );
}

import React, {useEffect, useState} from 'react';
import {WgTypography} from '../../../../../styles/CustomComponents';
import {Box, Grid, Paper} from '@material-ui/core';
import BrokerMandateEdit from '../../../profile/documents/BrokerMandateEdit';
import {SearchBar} from '../../../../utils';
import InsuranceListItem from '../../components/InsuranceListItem';
import {ICategory, isCategory} from '../../../../../models/categoryInterface';
import WechselCard from '../../components/WechselCard';
import {ICompany} from '../../../../../models/companyInterface';
import useDebounceSearch, {
    setSearchInsuranceParams
} from '../../../../../hooks/useDebounceSearch';
import BrokerMandateNoMandateExistsText from '../../../profile/documents/BrokerMandateNoMandateExistsText';

interface ObjectSearchProps {
    hasBrokermandate?: boolean;
    objects?: ICategory[] | ICompany[];
    initialObjects?: ICategory[] | ICompany[];
    headerText: string;
    backText: string;
    searchBarPlaceholder: string;
    subLink?: string;
    categoryId?: number;
    initialText: string;
    notFoundText: string;
    handleChange?: (
        name: 'categoryId' | 'companyId' | any
    ) => (value: any) => void;
    notFoundComponent?: React.ReactNode;
    setNotFound?: Function;
    setInputValid?: Function;
}

export default function ObjectSearch({
    hasBrokermandate,
    objects,
    initialObjects,
    searchBarPlaceholder,
    categoryId,
    initialText,
    notFoundText,
    notFoundComponent,
    handleChange,
    setNotFound,
    setInputValid
}: ObjectSearchProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const {
        debouncedSearchTerm,
        isSearching,
        results,
        setResults
    } = useDebounceSearch(searchTerm, 500);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setSearchTerm('');
    }, [categoryId]);

    useEffect(
        () => {
            setResults(
                setSearchInsuranceParams(objects, categoryId, searchTerm)
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [objects, categoryId, setResults]
    );

    useEffect(() => {
        if (typeof setNotFound !== 'undefined') {
            const hasSearchTerm = searchTerm.length > 0;
            setNotFound(hasSearchTerm);
            setInputValid && setInputValid(hasSearchTerm);
            if (hasSearchTerm) {
                handleChange && handleChange('oldProviderName')(searchTerm);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results, setNotFound, searchTerm, setInputValid]);

    if (typeof hasBrokermandate !== 'undefined' && !hasBrokermandate) {
        return (
            <BrokerMandateEdit backNavigationOnSave={false}>
                <BrokerMandateNoMandateExistsText />
            </BrokerMandateEdit>
        );
    }

    return (
        <Grid container direction="column" wrap="nowrap" style={{flex: 1}}>
            {renderSearchBar()}
            <Box marginTop="1rem">{renderContent()}</Box>
        </Grid>
    );

    function renderSearchBar() {
        return (
            <SearchBar
                open={open}
                setOpen={setOpen}
                searchString={searchTerm}
                isLoading={isSearching}
                placeholder={searchBarPlaceholder}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(event.target.value)
                }
            />
        );
    }

    function renderList(results: ICategory[] | ICompany[]) {
        return [...results]
            .splice(0, 60)
            .map((object: ICategory | ICompany) => {
                return (
                    <InsuranceListItem
                        onClick={() =>
                            handleChange &&
                            handleChange(
                                isCategory(object) ? 'categoryId' : 'companyId'
                            )(object.id)
                        }
                        square
                        key={
                            object.id + (object?.name ?? '') + object?.logoPath
                        }
                        data={object}
                    />
                );
            });
    }

    function renderInitial() {
        return (
            <Box
                display="flex"
                marginTop="2vh"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <WgTypography align="center" color="primary" text="contentInfo">
                    {initialText}
                </WgTypography>
                <Grid style={{marginTop: '1.5rem'}} container spacing={2}>
                    {typeof initialObjects !== 'undefined' &&
                        renderInitialObjects(initialObjects)}
                </Grid>
            </Box>
        );
    }

    function renderInitialObjects(items: ICategory[] | ICompany[]) {
        return (
            items &&
            [...items].map((obj: ICategory | ICompany) => {
                return (
                    <Grid
                        key={`${obj.id}-${obj.logoPath}-${obj.name}`}
                        item
                        xs={4}>
                        <WechselCard
                            handleChange={
                                handleChange &&
                                handleChange(
                                    isCategory(obj) ? 'categoryId' : 'companyId'
                                )
                            }
                            name={obj.name ?? ''}
                            id={obj.id}
                            logoPath={obj.logoPath}
                        />
                    </Grid>
                );
            })
        );
    }

    function renderContent() {
        const errorMessage = (
            <Box
                marginTop="4rem"
                display="flex"
                flexDirection="column"
                justifyContent="center">
                {typeof notFoundComponent !== 'undefined' ? (
                    notFoundComponent
                ) : (
                    <WgTypography
                        gutterBottom
                        align="center"
                        color="textSecondary"
                        text="sentence">
                        {notFoundText}
                    </WgTypography>
                )}
            </Box>
        );

        if (open && searchTerm.length > 0 && results.length > 0) {
            return (
                <Paper elevation={2} style={{marginTop: '0.5rem'}}>
                    {renderList(results)}
                </Paper>
            );
        } else if (
            open &&
            searchTerm.length > 0 &&
            debouncedSearchTerm.length > 0 &&
            !isSearching
        ) {
            return errorMessage;
        } else {
            return renderInitial();
        }
    }
}

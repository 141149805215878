import React from 'react';
import Logo from './Logo';
import {
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from '../../styles/CustomComponents';

interface IProps {
    link: string;
    logoPath?: string;
    categoryId?: number;
    companyId?: number;
    defaultImage: string;
    header?: string;
    subHeader?: string;
    amount: number;
}

export default function TransactionListItem(props: IProps) {
    return (
        <WgListItem style={{width: '100%'}} square link={props.link}>
            <WgListItemIcon>
                <Logo
                    logoPath={props.logoPath}
                    defaultImage={props.defaultImage}
                    alt="transaction-logo"
                />
            </WgListItemIcon>
            <WgListItemContent>
                <div>
                    <WgTypography noWrap text="content" color="textPrimary">
                        {props.header}
                    </WgTypography>
                    <WgTypography
                        noWrap
                        text="subContent"
                        color="textSecondary">
                        {props.subHeader}
                    </WgTypography>
                </div>
                <WgTypography number="list" colored money>
                    {props.amount}
                </WgTypography>
            </WgListItemContent>
        </WgListItem>
    );
}

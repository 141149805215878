import React from 'react';
import {Backdrop, Box, CircularProgress} from '@material-ui/core';

export default function WgCircularProgress() {
    return (
        <Box position="fixed" zIndex={1299} top="50%" left="calc(50% - 20px)">
            <Backdrop open style={{backgroundColor: 'rgba(0, 0, 40, 0.15)'}} />
            <CircularProgress />
        </Box>
    );
}

import {createDeepEqualSelector} from './utils';
import {IAccount} from '../../../models/accountInterface';
import {IBankConnection} from '../../../models/bankInterface';
import {groupBy} from '../../../containers/utils/Format';
import {bankSelector} from './index';
import {ISecurity} from '../../../models/securityInterface';

export const ACCOUNT_TYPES = {
    DEFAULT: 1,
    SAVINGS: 4
};

export const getAccounts = (state: any) => state?.accounts;

export const allAccountsSelector = (state: any) =>
    getAccounts(state)?.allAccounts ?? [];

export const allBankConnectionSelector = (state: any) =>
    bankSelector(state)?.bankConnections ?? [];

export const accountsBankConnectionJoinSelector = createDeepEqualSelector(
    [allAccountsSelector, allBankConnectionSelector],
    (accounts: IAccount[], bankConnections: IBankConnection[]): IAccount[] => {
        return Array.isArray(accounts) &&
            Array.isArray(bankConnections) &&
            bankConnections.length > 0
            ? accounts.map(account => {
                  const bankConnection = bankConnections.find(
                      bc => bc.bankConnectionId === account.bankConnectionId
                  );
                  return {...account, bankConnection: bankConnection};
              })
            : accounts;
    }
);

export const allVisibleAccountsSelector = createDeepEqualSelector(
    [accountsBankConnectionJoinSelector],
    (accounts: IAccount[]) => {
        return accounts instanceof Array && accounts.length
            ? accounts.filter(
                  (account: IAccount) =>
                      account.visible || typeof account.visible === 'undefined'
              )
            : [];
    }
);

export const allHiddenAccountsSelector = createDeepEqualSelector(
    [accountsBankConnectionJoinSelector],
    (accounts: IAccount[]): IAccount[] => {
        return accounts instanceof Array && accounts.length
            ? accounts.filter(
                  (account: IAccount) =>
                      typeof account.visible !== 'undefined' && !account.visible
              )
            : [];
    }
);

export const visibleAccountsGroupedByBankConnectionSelector = createDeepEqualSelector(
    [allVisibleAccountsSelector],
    groupAccounts
);

export const hiddenAccountsGroupedByBankConnectionSelector = createDeepEqualSelector(
    [allHiddenAccountsSelector],
    groupAccounts
);

function groupAccounts(accounts: IAccount[]) {
    const grouped = groupBy(
        accounts,
        (account: IAccount) => account.bankConnectionId
    );
    return Array.from(grouped.entries());
}

export const accountsIdSelector = createDeepEqualSelector(
    [
        allVisibleAccountsSelector,
        (_: any, props: any) => props.selectedAccounts
    ],
    (accounts: IAccount[], selectedAccounts: number[]) => {
        if (!Array.isArray(accounts)) {
            return [];
        }

        const filteredAccountIds = accounts.map((acc: IAccount) => acc.id);
        return Array.isArray(selectedAccounts) && selectedAccounts.length > 0
            ? filteredAccountIds.filter((id: number) =>
                  selectedAccounts.includes(id)
              )
            : filteredAccountIds;
    }
);

export const singleAccountIdSelector = (
    _: any,
    props: {accountId: number | undefined}
) => props?.accountId;

export const accountByIdSelector = createDeepEqualSelector(
    [accountsIdSelector, accountsBankConnectionJoinSelector],
    (accountIds: number[], accounts: IAccount[]) => {
        if (!Array.isArray(accountIds)) {
            return [];
        }

        return accountIds.reduce((acc: IAccount[], id: number) => {
            const account = findAccountById(accounts, id);
            if (typeof account !== 'undefined') return [...acc, account];
            else return acc;
        }, []);
    }
);

export const accountByIdBalanceSelector = createDeepEqualSelector(
    [accountByIdSelector],
    (accounts: IAccount[]) => {
        if (!Array.isArray(accounts)) {
            return 0;
        }

        return accounts.reduce(
            (prev: number, account: IAccount) => prev + account.balance,
            0
        );
    }
);

export const singleAccountSelector = createDeepEqualSelector(
    [accountsBankConnectionJoinSelector, singleAccountIdSelector],
    findAccountById
);

function findAccountById(accounts: IAccount[], id?: number) {
    if (!Array.isArray(accounts)) {
        return undefined;
    }

    return accounts?.find((account: IAccount) => account.id === id);
}

export const hiddenNormalAccountsSelector = createDeepEqualSelector(
    [allHiddenAccountsSelector],
    (accounts: IAccount[]): IAccount[] =>
        filterByAccountType({accounts, isNotAccountType: ACCOUNT_TYPES.SAVINGS})
);

export const hiddenSavingsAccountsSelector = createDeepEqualSelector(
    [allHiddenAccountsSelector],
    (accounts: IAccount[]): IAccount[] =>
        filterByAccountType({accounts, isAccountType: ACCOUNT_TYPES.SAVINGS})
);

export const visibleNormalAccountsSelector = createDeepEqualSelector(
    [allVisibleAccountsSelector],
    (accounts: IAccount[]): IAccount[] =>
        filterByAccountType({accounts, isNotAccountType: ACCOUNT_TYPES.SAVINGS})
);

export const visibleSavingsAccountsSelector = createDeepEqualSelector(
    [allVisibleAccountsSelector],
    (accounts: IAccount[]): IAccount[] =>
        filterByAccountType({accounts, isAccountType: ACCOUNT_TYPES.SAVINGS})
);

function filterByAccountType({
    accounts,
    isAccountType,
    isNotAccountType
}: {
    accounts: IAccount[];
    isAccountType?: number;
    isNotAccountType?: number;
}) {
    if (!Array.isArray(accounts)) {
        return [];
    }

    if (typeof isAccountType !== 'undefined') {
        return (
            accounts?.filter(
                (account: IAccount) => account.accountType === isAccountType
            ) ?? []
        );
    } else if (typeof isNotAccountType !== 'undefined') {
        return (
            accounts?.filter(
                (account: IAccount) => account.accountType !== isNotAccountType
            ) ?? []
        );
    }
    return [];
}

export const allSecuritiesByAccountIdSelector = createDeepEqualSelector(
    [
        (state: any) => state.accounts?.depots,
        (_: any, props: {accountId: number}) => props.accountId
    ],
    (depots: any, accountId: number): ISecurity[] => {
        const securities: ISecurity[] =
            depots && Object.keys(depots) && Object.keys(depots).length > 0
                ? depots[accountId]
                : [];
        return typeof securities !== 'undefined'
            ? securities.sort(
                  (a: ISecurity, b: ISecurity) =>
                      b.absoluteDifference - a.absoluteDifference
              )
            : [];
    }
);

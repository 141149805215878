import {createAction} from 'redux-actions';
import {
    api,
    getAuthConfig,
    isErrorResponseStatusTreatable,
    performApiCall
} from './api';
import {
    DELETE_RECURRINGPAYMENT,
    FETCH_EXISTING_PRODUCT_FILES,
    FETCH_EXISTING_PRODUCTS,
    FETCH_INSURANCE_PRODUCT_URL,
    FETCH_INSURANCE_PRODUCTS,
    SELECT_CAR_INSURANCE_PAYMENT_ACCOUNT,
    SET_CAR_INSURANCE_MANUAL_PAYMENT,
    SET_CAR_INSURANCE_NO_CLAIM_DISCOUNT,
    SET_CAR_INSURANCE_POLICY_DATA,
    SET_CAR_INSURANCE_PROPOSAL,
    SET_CAR_INSURANCE_SPECIFIC_DATA,
    SET_CAR_INSURANCE_SUBMIT_RESPONSE,
    SET_CAR_INSURANCE_SUMMARY_DATA,
    SET_CAR_INSURANCE_VEHICLE_HOLDER_DATA,
    SET_PREVIOUS_CAR_INSURANCE_POLICY_DATA,
    SET_USER_PROPOSAL_CHOICE,
    SET_VEHICLE_DATA,
    UPDATE_CONTRACT_SUCCESS
} from './actionTypes';
import {loadingIdAllContracts} from './loadingIds';
import {push} from 'connected-react-router';
import {
    addLoading,
    removeLoading,
    setNotification
} from './notificationActions';
import {
    carInsuranceProposalRequestDataSelector,
    carInsuranceRequestDataSelector,
    getUserUserData
} from '../reducers/selectors';
import {
    ICarInsurancePaymentAccount,
    IExistingInsuranceProduct,
    isDirectSaleOneResponse,
    isExistingInsuranceProduct,
    IVehicleData,
    IVehicleHolder,
    PaymentMethod
} from '../../models/productInterface';
import {formatOneInsuranceTariffName} from '../../containers/utils/Format';
import {carInsuranceSwitchDataSelector} from '../reducers/selectors/carInsuranceSelector';
import {ReactElement} from 'react';
import {LoadingPriorityCodes} from '../reducers/notification';
import WgCircularProgress from '../../containers/app/loading_components/WgCircularProgress';
import {errorNotification} from './offerActions';
import WgLoadingDialog from '../../containers/app/loading_components/WgLoadingDialog';

// *** ACTION CREATORS ***
export const fetchInsuranceProductsSuccess = createAction(
    FETCH_INSURANCE_PRODUCTS
);
export const fetchInsuranceProductUrlSuccess = createAction(
    FETCH_INSURANCE_PRODUCT_URL
);
export const fetchExistingInsuranceProductsSuccess = createAction(
    FETCH_EXISTING_PRODUCTS
);
export const fetchExistingProductFilesSuccess = createAction(
    FETCH_EXISTING_PRODUCT_FILES
);

export const setCarInsuranceVehicleData = createAction(SET_VEHICLE_DATA);
export const setCarInsuranceVehicleDataDetails = createAction(
    SET_CAR_INSURANCE_SPECIFIC_DATA
);
export const setCarInsuranceNoClaimDiscount = createAction(
    SET_CAR_INSURANCE_NO_CLAIM_DISCOUNT
);
export const setCarInsurancePolicyData = createAction(
    SET_CAR_INSURANCE_POLICY_DATA
);
export const setPreviousCarInsurancePolicyData = createAction(
    SET_PREVIOUS_CAR_INSURANCE_POLICY_DATA
);
export const setCarInsuranceProposal = createAction(SET_CAR_INSURANCE_PROPOSAL);
export const setCarInsuranceInsuranceSummaryData = createAction(
    SET_CAR_INSURANCE_SUMMARY_DATA
);
export const setCarInsuranceVehicleHolder = createAction(
    SET_CAR_INSURANCE_VEHICLE_HOLDER_DATA
);
export const setUserProposalChoice = createAction(SET_USER_PROPOSAL_CHOICE);
export const deleteReccuringPayment = createAction(DELETE_RECURRINGPAYMENT);
export const setCarInsuranceSubmitResponse = createAction(
    SET_CAR_INSURANCE_SUBMIT_RESPONSE
);
export const selectCarInsurancePayment = createAction(
    SELECT_CAR_INSURANCE_PAYMENT_ACCOUNT
);
export const setManualPayment = createAction(SET_CAR_INSURANCE_MANUAL_PAYMENT);
export const updateContract = createAction(UPDATE_CONTRACT_SUCCESS);

export const fetchAllInsuranceProducts: any = () =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;
        const response = await api.get(
            '/products',
            getAuthConfig(token, {Accept: 'application/json'})
        );

        dispatch(fetchInsuranceProductsSuccess(response.data));
    }, 'Aufgrund von technischen Problemen konnten wir leider keine Versicherungsprodukte laden');

export const fetchInsuranceProductURL: any = (id: number) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;
        try {
            const response = await api.get(
                `/products/${id}/getLink`,
                getAuthConfig(token, {Accept: 'application/json'})
            );
            dispatch(fetchInsuranceProductUrlSuccess({[id]: response.data}));
        } catch (error) {
            if (isErrorResponseStatusTreatable(error)) {
                throw error;
            }

            if (error.response.status === 416 || error.response.status === 404)
                dispatch(
                    setNotification({
                        title: 'Fehlendes Maklermandat',
                        text:
                            'Leider besitzen wir noch kein Maklermandat von Ihnen um diesen Vorgang zu ermöglichen',
                        isAgreeable: true,
                        onAgree: () => {
                            dispatch(push('/profile/brokermandate'));
                        },
                        open: true
                    })
                );
            else {
                throw error;
            }
        }
    }, 'Aufgrund von technischen Problemen konnten wir leider keine Versicherungsprodukte laden');

export const submitProductInformation: any = (id: number) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        // Bitte nicht löschen wird temporär nicht benötigt bis ein bugfix zufinden ist
        // const token = getState().user.token;
        // var res = await api.post(
        //     `/products/${id}/confirm`,
        //     {},
        //     getAuthConfig(token, {})
        // );

        dispatch(
            setNotification({
                title: 'Herzlichen Glückwunsch!',
                text:
                    'Dein Antrag befindet sich ab sofort in Bearbeitung. In Kürze wird dein brandneuer Vertrag hier dargestellt.',
                isAgreeable: true,
                onAgree: () => {
                    const token = getState().user.token;
                    api.post(
                        `/products/${id}/confirm`,
                        {},
                        getAuthConfig(token, {})
                    );
                    dispatch(push(`/products/`));
                },
                open: true
            })
        );
    });

export const fetchExistingProductFiles: any = (id: number, fileId: number) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const token = getState().user.token;
        const response = await api.get(
            `/products/${id}/files/${fileId}`,
            getAuthConfig(token, {Accept: 'application/json'})
        );
        dispatch(fetchExistingProductFilesSuccess(response.data));
    }, 'Information konnten nicht übertragen werden');

export const fetchAllExistingInsuranceProducts: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const response = await api.get(
                `/contracts/`,
                getAuthConfig(token, {Accept: 'application/json'})
            );
            dispatch(fetchExistingInsuranceProductsSuccess(response.data));
        },
        'Verträge konnten nicht geladen werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        loadingIdAllContracts
    );

export const submitCarInsuranceRequest: any = (callback: Function) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const id = `submitCarInsuranceRequest`;
        dispatch(
            addLoading({
                id,
                priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                component: WgCircularProgress
            })
        );

        try {
            const token = getState().user.token;
            const carInsuranceData = carInsuranceRequestDataSelector(
                getState()
            );
            const response = await api.post(
                `/products/car/request`,
                carInsuranceData,
                getAuthConfig(token, {})
            );
            if (isDirectSaleOneResponse(response.data)) {
                dispatch(setCarInsuranceSubmitResponse(response.data));
                await getcarInsuranceSubmitData(
                    response.data,
                    dispatch,
                    getState,
                    callback
                );
                dispatch(
                    setNotification({
                        title: 'Anfrage erfolgreich!',
                        text:
                            'Vielen Dank für dein Vertrauen, WechselGott kümmert sich um deinen Abschluss.',
                        isAgreeable: true,
                        onAgree: async () => {
                            dispatch(
                                push(
                                    `/products/details/${response.data.contractId}`
                                )
                            );
                        },
                        open: true
                    })
                );
            } else {
                throw new Error('Fehler beim Direktabschluss');
            }
        } catch (error) {
            if (isErrorResponseStatusTreatable(error)) {
                throw error;
            }
            errorNotification(
                `/products`,
                'Leider ist beim Abschluss deiner neuen Versicherung etwas schief gelaufen. Wir arbeiten bereits an der Behebung des Fehlers.',
                dispatch
            );
        } finally {
            await dispatch(removeLoading(id));
        }
    }, 'Informationen konnten nicht übertragen werden');

export const submitCarInsuranceSwitchRequest: any = (
    contract: IExistingInsuranceProduct,
    callback: Function
) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const id = `submitCarInsuranceSwitchRequest`;
        updateCarSwitchContract(contract, dispatch, getState);

        try {
            dispatch(
                addLoading({
                    id,
                    priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                    component: WgCircularProgress
                })
            );
            const token = getState().user.token;
            let carInsuranceSwitchData = carInsuranceSwitchDataSelector(
                getState()
            );
            carInsuranceSwitchData.policyData = {
                ...carInsuranceSwitchData.policyData,
                amount: contract.amount ?? 0,
                paymentInterval: contract.paymentInterval ?? 'ANNUAL'
            };

            const response = await api.post(
                `products/car/switch`,
                carInsuranceSwitchData,
                getAuthConfig(token, {})
            );

            if (response.status === 200) {
                dispatch(
                    setNotification({
                        title: 'Anfrage erfolgreich!',
                        text:
                            'Vielen Dank für dein Vertrauen, WechselGott kümmert sich um deinen Wechsel.',
                        isAgreeable: true,
                        onAgree: async () => {
                            callback();
                        },
                        open: true
                    })
                );
            } else
                throw new Error('Fehler beim Wechsel deiner Autoversicherung');
        } catch (error) {
            if (isErrorResponseStatusTreatable(error)) {
                throw error;
            }
            errorNotification(
                `/products`,
                'Leider ist beim Abschluss deiner neuen Versicherung etwas schief gelaufen. Wir arbeiten bereits an der Behebung des Fehlers.',
                dispatch
            );
        }

        await dispatch(removeLoading(id));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCarInsuranceVehicleData: any = (vehicleData: any) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setCarInsuranceVehicleData(vehicleData));
    }, 'Informationen konnten nicht übertragen werden');

export const fetchCarInsuranceVehicleHolderData: any = () =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        // ToDo: Remove entire myData object and use user data
        //
        // const token = getState().user.token;
        // const response = await api.get(
        //     `/mydata`,
        //     getAuthConfig(token, {Accept: 'application/json'})
        // );

        const userData = getUserUserData(getState());
        const vehicleHolder: IVehicleHolder = {
            birthDate: userData.birthDate,
            familyName: userData.lastName,
            firstName: userData.firstName,
            city: userData.city,
            email: userData.email,
            gender: userData.gender,
            houseNumber: userData.houseNumber,
            postalCode: userData.postcode,
            street: userData.street
        };
        dispatch(setCarInsuranceVehicleHolder(vehicleHolder));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCarInsuranceVehicleDataDetails: any = (
    vehicleDataDetails: any
) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setCarInsuranceVehicleDataDetails(vehicleDataDetails));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCarInsuranceVehicleHolder: any = (data: IVehicleHolder) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setCarInsuranceVehicleHolder(data));
    }, 'Informationen konnten nicht übertragen werden');

export const selectCarInsurancePaymentAccount: any = (accountId: number) =>
    performApiCall((dispatch: Function, getState: Function) => {
        const accounts = getState().accounts.allAccounts;
        const selectedAccount = accounts.filter(
            (acc: any) => (acc.id as number) === accountId
        );

        if (!selectedAccount || !selectedAccount.length) {
            throw new Error(`account with id ${accountId} was not found`);
        }

        const carInsuranceAccData = {
            accountId: accountId,
            iban: selectedAccount[0].iban,
            accountHolderName: selectedAccount[0].accountHolderName,
            paymentMethod: PaymentMethod.InApp
        } as ICarInsurancePaymentAccount;
        dispatch(setCarInsuranceVehicleHolder(carInsuranceAccData));
    }, 'Informationen konnten nicht übertragen werden');

export const setCarInsuranceManualPayment: any = (
    data: ICarInsurancePaymentAccount
) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        const carInsuranceAccData = {
            iban: data.iban,
            accountHolderName: data.accountHolderName,
            paymentMethod: data.paymentMethod
        } as ICarInsurancePaymentAccount;
        dispatch(setManualPayment(carInsuranceAccData));
    }, 'Informationen konnten nicht übertragen werden');

export const getCarInsuranceProposals: any = (vehicleData: IVehicleData) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        await dispatch(
            addLoadingGif(
                'getCarInsuranceProposals',
                'Wir berechnen deine Angebote.'
            )
        );
        const token = getState().user.token;
        const requestObject = carInsuranceProposalRequestDataSelector(
            getState()
        );

        const response = await api.post(
            `/products/car/proposal`,
            requestObject,
            getAuthConfig(token, {})
        );

        await dispatch(setCarInsuranceProposal(response.data));

        await dispatch(removeLoading('getCarInsuranceProposals'));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCarInsuranceNoClaimDiscount: any = (noClaimDiscount: any) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setCarInsuranceNoClaimDiscount(noClaimDiscount));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCalculationId: any = (calculationId: string) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setUserProposalChoice(calculationId));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCarInsurancePolicyData: any = (policyData: any) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setCarInsurancePolicyData(policyData));
    }, 'Informationen konnten nicht übertragen werden');

export const updateCarInsurancePreviousPolicyData: any = (policyData: any) =>
    performApiCall(async (dispatch: Function, getState: Function) => {
        dispatch(setPreviousCarInsurancePolicyData(policyData));
    }, 'Informationen konnten nicht übertragen werden');
export function addLoadingGif(
    id: string,
    content: string | ReactElement,
    hasAgreeButton: boolean = false
) {
    return addLoading({
        id,
        priority: LoadingPriorityCodes.GIFLOADING_PROGRESS,
        component: WgLoadingDialog,
        props: {
            loadingIdList: ['getCarInsuranceProposals'],
            content: content,
            hasAgreeButton: hasAgreeButton
        }
    });
}
export const getcarInsuranceSubmitData: any = async (
    contract: any,
    dispatch: Function,
    getState: Function,
    callback?: Function
) => {
    const {contractId, price} = contract;
    try {
        const token = getState().user.token;
        const response = await api.get<IExistingInsuranceProduct>(
            `/contracts/${contractId}`,
            getAuthConfig(token, {Accept: 'application/json'})
        );
        if (isExistingInsuranceProduct(response.data)) {
            const carInsurance = getState().carInsurance;

            const isGreenTariff =
                carInsurance.proposal.green.calculationId ===
                carInsurance.proposalSelectedId;
            const tariffName = formatOneInsuranceTariffName(isGreenTariff);

            //Do not insert accountID here, otherwise the contract will be filtered by a selector
            const policy = {
                id: contractId,
                categoryId: response.data.categoryId,
                //accountId = response.data.accountId,
                insuranceType: carInsurance.insuranceType ?? 'Kfz-Versicherung',
                amount: price,
                insuredRisk: carInsurance.vehicleData.plateNumber,
                companyId: response.data.companyId,
                paymentInterval: response.data.paymentInterval ?? 'MONTHLY',
                startDate: carInsurance.submitResponse.insuranceStartDate,
                insuranceNumber: carInsurance.submitResponse.insuranceNumber,
                tariffName: tariffName,
                status: 'pending',
                vbNumber: carInsurance.submitResponse.vbNumber
            } as IExistingInsuranceProduct;

            await dispatch(setCarInsuranceInsuranceSummaryData({policy}));
        } else {
            throw new Error('Wechsel nicht erfolgreich.');
        }
    } catch (error) {
        if (isErrorResponseStatusTreatable(error)) {
            throw error;
        }
        errorNotification(
            `/products`,
            'Leider ist beim Abschluss deiner neuen Versicherung etwas schief gelaufen. Wir arbeiten bereits an der Behebung des Fehlers.',
            dispatch
        );
        return;
    }
};

export const updateCarSwitchContract: any = async (
    contract: IExistingInsuranceProduct,
    dispatch: Function,
    getState: Function
) => {
    const token = getState().user.token;

    const response = await api.patch(
        `/contracts/${contract.id}`,
        {
            amount: contract.amount,
            paymentInterval: contract.paymentInterval
        },
        getAuthConfig(token, {Accept: 'application/json'})
    );
    if (response.status === 200)
        dispatch(updateContract({...contract, status: 'pending'}));
    else
        throw new Error(
            'Fehler beim aktualisieren deines Vertrages aufgetreten'
        );
};

import React, {useCallback, useEffect, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {
    WgCheckbox,
    WgPaperArea,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {ICarInsuranceNoClaimDiscount} from '../../../../../models/productInterface';
import {CarInsuranceStep} from './CarInsuranceController';
import InsuranceListItem from '../../components/InsuranceListItem';
import {isInputNotFilled} from '../../../../utils/Validate';

interface CarInsuranceNoClaimDiscountProps {
    updateCarInsuranceNoClaimDiscount: Function;
    carInsuranceNoClaimDiscount: ICarInsuranceNoClaimDiscount;
    getInsurerDataForListItem: Function;
    setIsValid: Function;
    validationEnabled: boolean;
}

export default function CarInsuranceNoClaimDiscount({
    carInsuranceNoClaimDiscount: noClaimDiscount,
    updateCarInsuranceNoClaimDiscount,
    setIsValid,
    getInsurerDataForListItem,
    validationEnabled
}: CarInsuranceNoClaimDiscountProps) {
    const [isDisabled, setIsDisabled] = useState(
        noClaimDiscount?.noClaimDiscountAvailable ?? false
    );

    const data = getInsurerDataForListItem();
    const preInsurerPlaceholder = !isInputNotFilled(data.name)
        ? data.name
        : 'Vorversicherer wählen';

    const noClaimDiscountPlaceholder =
        typeof noClaimDiscount?.noClaimDiscountValue !== 'undefined'
            ? 'Schadensklasse: ' + noClaimDiscount.noClaimDiscountValue
            : 'Schadensklasse auswählen';

    const validateFields = useCallback(() => {
        if (
            validateNoClaimDiscountAvailable(
                noClaimDiscount?.noClaimDiscountAvailable
            ) ||
            (validateNoClaimDiscountValue(
                noClaimDiscount?.noClaimDiscountValue
            ) &&
                validatePreInsurer(noClaimDiscount?.preInsurer))
        )
            setIsValid(true);
        else {
            setIsValid(false);
        }
    }, [noClaimDiscount, setIsValid]);

    useEffect(() => {
        validateFields();
        if (
            typeof noClaimDiscount?.noClaimDiscountAvailable !== 'undefined' &&
            noClaimDiscount.noClaimDiscountAvailable === false
        )
            setIsDisabled(true);
        else setIsDisabled(false);
    }, [noClaimDiscount, validateFields]);

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Die Schadensfreiheitsklasse (SF-Klasse) ermöglicht dir einen
                Rabatt, wenn du für längere Zeit unfallfrei gefahren bist.
            </WgTypography>
            <Box marginTop="1rem">
                <WgCheckbox
                    key="noClaimDiscountAvailable"
                    label="Keine SF-Klasse vorhanden."
                    checked={isDisabled}
                    background="paper"
                    onChange={handleChange}
                />
                <WgPaperArea padding={'0rem'} marginY="1rem">
                    <InsuranceListItem
                        warningFlag={
                            validationEnabled &&
                            !isDisabled &&
                            isInputNotFilled(
                                noClaimDiscount?.noClaimDiscountValue
                            )
                                ? 'left'
                                : undefined
                        }
                        key={'noClaim'}
                        disabled={isDisabled}
                        disableStartIcon={true}
                        placeholder={noClaimDiscountPlaceholder}
                        link={`/products/purchase/car/${CarInsuranceStep.NO_CLAIM_DISCOUNT_SELECTION}`}
                    />
                </WgPaperArea>
                <Box marginBottom="1rem">
                    <WgTypography color="textSecondary" text="info">
                        Bei welchem Versicherer warst du bisher?
                    </WgTypography>
                    <WgPaperArea padding={'0rem'}>
                        <InsuranceListItem
                            warningFlag={
                                validationEnabled &&
                                !isDisabled &&
                                isInputNotFilled(data?.name)
                                    ? 'left'
                                    : undefined
                            }
                            key={'PreInsurer'}
                            disabled={isDisabled}
                            placeholder={preInsurerPlaceholder}
                            logoPath={data.logoPath}
                            hasIcon
                            link={`/products/purchase/car/${CarInsuranceStep.PREINSUREER_SELECTION}`}
                        />
                    </WgPaperArea>
                </Box>
            </Box>
        </Grid>
    );

    //ToDo: WgCheckbox Handler funktioniert nicht passend -->
    function handleChange(event: any) {
        setIsDisabled(!isDisabled);

        isDisabled
            ? updateCarInsuranceNoClaimDiscount({
                  ...noClaimDiscount,
                  noClaimDiscountAvailable: isDisabled
              })
            : updateCarInsuranceNoClaimDiscount({
                  ...noClaimDiscount,
                  noClaimDiscountAvailable: isDisabled,
                  noClaimDiscountValue: undefined,
                  preInsurer: ''
              });

        validateFields();
    }
}

function validateNoClaimDiscountAvailable(noClaimDiscountAvailable?: boolean) {
    if (typeof noClaimDiscountAvailable === 'undefined') return false;
    else if (noClaimDiscountAvailable === true) return false;
    else if (noClaimDiscountAvailable === false) return true;
    else return false;
}

function validatePreInsurer(preInsurer?: string) {
    if (typeof preInsurer === 'undefined') return false;
    else if (preInsurer.length > 0) return true;
    else return false;
}

function validateNoClaimDiscountValue(noClaimDiscountValue?: string) {
    if (typeof noClaimDiscountValue === 'undefined') return false;
    else if (noClaimDiscountValue.length > 0) return true;
    else return false;
}

import React, {useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {userActions} from '../../../../store/actions';
import VerificationCodeField from '../../../utils/VerificationCodeField';
import PasswordForm, {passwordStatus} from '../../../utils/PasswordForm';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../styles/CustomComponents';

const {performChangePasswordEmail} = userActions;

interface IProps {
    performChangePasswordEmail: Function;
}

function ResetPassword({performChangePasswordEmail}: IProps) {
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState(passwordStatus.EMPTY);
    const [code, setCode] = useState('');

    return (
        <WgContainer publicBodyApp backgroundImage style={{overflowY: 'auto'}}>
            <WgHeader
                headerText="Passwort festlegen"
                hasBackButton
                backText="Passwort zurücksetzen"
            />
            <WgContainer
                onKeyPress={event => {
                    if (event.key === 'Enter' && areRequirementsMet()) {
                        submit(event);
                    }
                }}
                publicBodyContent
                disableGutters={false}
                style={{flex: 1, minHeight: 'fit-content'}}>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    style={{flex: 1, maxWidth: '600px', alignSelf: 'center'}}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: 1}}
                        container
                        direction="column"
                        justify="space-evenly">
                        <Grid item>
                            <WgTypography
                                gutterTop="1rem"
                                gutterBottom
                                color="textSecondary"
                                text="info">
                                Der Code wurde an deine Handynummer geschickt.{' '}
                                <br />
                                Solltest du keine SMS erhalten haben, wende dich
                                bitte an unseren Support. <br />
                                Wähle ein Passwort mit min. acht Zeichen - davon
                                min. ein Kleinbuchstabe und eine Ziffer. <br />
                                Unsere Empfehlung: Min. elf Zeichen, Groß- und
                                Kleinschreibung und Sonderzeichen.
                            </WgTypography>
                        </Grid>

                        <Grid
                            item
                            container
                            justify="space-between"
                            style={{marginTop: '1rem', flex: 1}}>
                            <Grid item xs={12} container alignItems="center">
                                <VerificationCodeField
                                    autoFocus
                                    name="code"
                                    onChange={updateCode}
                                />
                            </Grid>

                            <Grid item xs={12} style={{marginTop: '1rem'}}>
                                <PasswordForm
                                    onChange={password => setPassword(password)}
                                    setStatus={handleStatus}
                                />
                                <WgTypography color="textSecondary" text="info">
                                    {getStatusMessage()}
                                </WgTypography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container>
                        <Box
                            display="flex"
                            justifyContent="center"
                            m="1.5rem 0"
                            flex={1}>
                            <WgButton
                                style={{maxWidth: '100%'}}
                                variant="contained"
                                color="primary"
                                type="submit"
                                defaultButton
                                aria-label="next"
                                disabled={!areRequirementsMet()}
                                onClick={submit}>
                                Weiter
                            </WgButton>
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function handleStatus(value: number) {
        setStatus(value);
    }

    function updateCode(event: any) {
        setCode(event.target.value);
    }

    function areRequirementsMet() {
        return code.length >= 6 && status === passwordStatus.SUCCESS;
    }

    function getStatusMessage() {
        if (status === passwordStatus.NOT_STRONG_ENOUGH)
            return 'Das Passwort ist zu schwach.';

        if (status === passwordStatus.DIFFERENT)
            return 'Die Passwörter müssen übereinstimmen.';
    }

    function submit(event: any) {
        event.preventDefault();
        performChangePasswordEmail({code, password});
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {performChangePasswordEmail})(
    ResetPassword
);

import React, {useEffect, useState} from 'react';
import {IDashboard} from '../../../../models/statistics/dashBoardInterface';
import Dashboard from './Dashboard';
import DashboardTimeSwitch from './Components/DashboardTimeSwitch';
import {connect} from 'react-redux';
import DashboardHeader from './Components/DashboardHeader';
import WgSkeletonChart from '../../../../styles/CustomComponents/Skeletons/WgSkeletonChart';
import DashboardTimeSwitchSkeleton from '../../../../styles/CustomComponents/Skeletons/DashboardTimeSwitchSkeleton';
import DashboardHeaderSkeleton from '../../../../styles/CustomComponents/Skeletons/DashboardHeaderSkeleton';
import {
    dashboardChartDataSelector,
    queueItemByIdSelector,
    allVisibleAccountsSelector
} from '../../../../store/reducers/selectors';
import {loadingIdAllTransaction} from '../../../../store/actions/loadingIds';
import {calculateAccountsBalance} from '../../../utils/Format';

export interface DashboardsProps {
    dashboardData?: IDashboard[] | null;
    selectedStep: string;
    handleStepChange: Function;
    isLoading?: number;
    currentBalance?: number;
}

function Dashboards({
    selectedStep,
    handleStepChange,
    dashboardData,
    isLoading,
    currentBalance
}: DashboardsProps) {
    const [activeIndex, setIndex] = useState(
        (dashboardData && dashboardData.length && dashboardData.length - 1) ?? 0
    );

    useEffect(() => {
        setIndex(
            (dashboardData &&
                dashboardData.length &&
                dashboardData.length - 1) ??
                0
        );
    }, [selectedStep, dashboardData]);

    if (isLoading)
        return (
            <React.Fragment>
                <DashboardHeaderSkeleton />
                <WgSkeletonChart />
                <DashboardTimeSwitchSkeleton />
            </React.Fragment>
        );
    if (isDashboardAvailable() && dashboardData != null) {
        const activeDashboard = dashboardData[activeIndex];
        // TODO: set active dashboard via state and useEffect?
        if (typeof activeDashboard === 'undefined') {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                <DashboardHeader
                    income={activeDashboard.income}
                    outgoing={activeDashboard.outgoing}
                    disposable={activeDashboard.disposable}
                    selectedStep={selectedStep}
                    selectedDate={activeDashboard.currentDate}
                    handleStepChange={handleStepChange}
                />
                <Dashboard
                    activeIndex={activeIndex}
                    dashboard={{...activeDashboard}}
                    initialBalance={computeInitialBalance(
                        activeIndex,
                        currentBalance,
                        dashboardData
                    )}
                />
                <DashboardTimeSwitch
                    dashboardData={dashboardData}
                    activeIndex={activeIndex}
                    handleMonthChange={handleMonthChange}
                />
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }

    function isDashboardAvailable(): boolean {
        if (dashboardData == null) {
            handleStepChange('month');
            return false;
        }
        return dashboardData && dashboardData.length > 0;
    }

    function handleMonthChange(month: number) {
        if (isDashboardAvailable() && dashboardData != null) {
            let maxIndex = dashboardData.length - 1;
            const updatedIndex = activeIndex + month;
            if (updatedIndex <= maxIndex && updatedIndex >= 0) {
                setIndex(updatedIndex);
            }
        }
    }

    /**
     * Computes the initial balance for the chosen month depending on today's balance
     * @param monthIndex The index of the month that should be displayed
     * @param todaysBalance The most current total balance of all accounts that is also displayed at "Gesamtguthaben" below the dashboards
     * @param monthData The cashflows of the past months
     */
    function computeInitialBalance(
        monthIndex: number,
        todaysBalance: number | undefined,
        monthData: IDashboard[]
    ): number {
        // Early out if today's balance is undefined
        if (typeof todaysBalance === 'undefined') return 0;

        // start with today's balance and walk backwards from there
        let initialBalance = todaysBalance;

        for (let i = monthData.length - 1; i >= monthIndex; --i) {
            const pastData = monthData[i].pastData;
            // The initial balance for the current month is the initial balance of the succeeding month
            // minus the last entry's balance-change in the past data
            if (pastData.length > 0) {
                initialBalance -= pastData[pastData.length - 1].balance;
            }
        }

        return initialBalance;
    }
}

function mapStateToProps(state: any, ownProps: DashboardsProps) {
    return {
        dashboardData: dashboardChartDataSelector(state, ownProps),
        isLoading: Boolean(
            queueItemByIdSelector(state, {
                queueId: loadingIdAllTransaction
            })
        ),
        currentBalance: calculateAccountsBalance(
            allVisibleAccountsSelector(state)
        )
    };
}

export default connect(
    mapStateToProps,
    {}
    //@ts-ignore
)(Dashboards);

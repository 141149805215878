import {createAction} from 'redux-actions';
import {TUTORIAL_SEEN} from './actionTypes';
import {Tutorial} from '../reducers/tutorials';

export const tutorialSeen = createAction(TUTORIAL_SEEN);

export const setTutorialSeen: Function = (tutorial: Tutorial) => async (
    dispatch: Function
) => {
    dispatch(tutorialSeen(tutorial));
};

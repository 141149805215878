import {
    IEnergyOfferContractValidation,
    IEnergyProduct,
    IOffer,
    OfferState
} from '../../models/offerInterface';
import {MeterType} from '../../models/productInterface';

export const fetchEnergyPriceOfferData: IEnergyProduct = {
    betterProviderName: 'empfehlungName',
    betterProviderYearlyCosts: 1100,
    betterProviderMonthlyCosts: 100,
    estimatedUsageInKwh: 1212,
    estimatedFeeWp: 80,
    estimatedSavings: 300,
    oldProviderName: 'alterVersorgerName',
    oldProviderYearlyCosts: 1500,
    oldProviderChargeCtPerKwh: 14.23,
    oldProviderMonthlyServiceCharge: 9.5,
    meterType: MeterType.Electricity,
    isCommercial: false,
    deliveryPostcode: '90441',
    eco: false
};
export const fetchEnergyEcoOfferData: IEnergyProduct = {
    betterProviderName: 'empfehlungNameEco',
    betterProviderYearlyCosts: 1100,
    betterProviderMonthlyCosts: 100,
    estimatedFeeWp: 80,
    estimatedSavings: 300,
    estimatedUsageInKwh: 1442,
    oldProviderName: 'alterVersorgerName',
    oldProviderYearlyCosts: 1500,
    oldProviderChargeCtPerKwh: 14.23,
    oldProviderMonthlyServiceCharge: 9.5,
    meterType: MeterType.Electricity,
    isCommercial: false,
    deliveryPostcode: '90441',
    eco: true
};

export const contractValidationData: IEnergyOfferContractValidation = {
    meterType: MeterType.Gas,
    isCommercial: false,
    customer: {
        companyName: 'WechselGott GmbH',
        lastName: 'Mustermann',
        firstName: 'Max',
        dateOfBirth: '1993-04-17',
        gender: 'male',
        street: 'Musterstrasse',
        houseNumber: '5a',
        postcode: '90489',
        city: 'Nuernberg',
        iban: 'DE1234567890123',
        email: 'Energy@wechselgott.com',
        telephoneNumber: '+4948274827492'
    },
    delivery: {
        companyName: 'WechselGott GmbH',
        gender: 'male',
        firstName: 'Max',
        lastName: 'Mustermann',
        street: 'Maximilianstraße',
        houseNumber: '1',
        postcode: '90441',
        city: 'Nürnberg'
    },
    billing: {
        companyName: 'WechselGott GmbH',
        gender: 'male',
        firstName: 'Max',
        lastName: 'Mustermann',
        street: 'Maximilianstraße',
        houseNumber: '1',
        postcode: '90441',
        city: 'Nürnberg'
    },
    accountHolder: {
        companyName: 'WechselGott GmbH',
        iban: 'DE38273957264837264857',
        firstName: 'Max',
        lastName: 'Mustermann'
    },
    movingAt: '2019-07-01T17:31:12',
    switchAt: '2019-07-01T17:31:12',
    meterNumber: '12765432345676543',
    eco: true,
    betterProviderName: 'empfehlungName',
    betterProviderYearlyCosts: 1100,
    betterProviderMonthlyCosts: 100,
    estimatedFeeWp: 80,
    estimatedSavings: 300,
    oldProviderName: 'Vattenfall',
    oldProviderChargeCtPerKwh: 27.3,
    oldProviderYearlyCosts: 1500,
    oldProviderMonthlyServiceCharge: 9.5
};

export const existingOffers: IOffer[] = [
    {
        recurringPaymentId: 1000,
        offerState: OfferState.AVAILABLE,
        eco: fetchEnergyEcoOfferData,
        price: fetchEnergyPriceOfferData
    },
    {
        recurringPaymentId: 1001,
        offerState: OfferState.AVAILABLE,
        eco: fetchEnergyEcoOfferData,
        price: fetchEnergyPriceOfferData
    },
    {
        recurringPaymentId: 1002,
        offerState: OfferState.AVAILABLE,
        eco: fetchEnergyEcoOfferData,
        price: fetchEnergyPriceOfferData
    },
    {
        recurringPaymentId: 1003,
        offerState: OfferState.AVAILABLE,
        eco: fetchEnergyEcoOfferData,
        price: fetchEnergyPriceOfferData
    },
    {
        recurringPaymentId: 1004,
        offerState: OfferState.AVAILABLE,
        eco: fetchEnergyEcoOfferData,
        price: fetchEnergyPriceOfferData
    }
];

export const defaultOffer: IOffer = {
    recurringPaymentId: 34324123451234508,
    offerState: OfferState.AVAILABLE,
    eco: fetchEnergyEcoOfferData,
    price: fetchEnergyPriceOfferData
};

export const defaultValidatedOffer: IOffer = {
    recurringPaymentId: 34324123451234508,
    offerState: OfferState.AVAILABLE,
    eco: fetchEnergyEcoOfferData,
    price: fetchEnergyPriceOfferData,
    dataValidation: contractValidationData
};

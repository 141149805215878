import React from 'react';
import {connect} from 'react-redux';
import {FormControl, Paper} from '@material-ui/core';
import {SignupStep} from './SignupStep';
import 'react-phone-number-input/style.css';
import PhoneInput, {
    parsePhoneNumber,
    getCountryCallingCode
} from 'react-phone-number-input';
import {push} from 'connected-react-router';
import {RegisterStatus} from '../../../../store/reducers/notification';
import {getNotification} from '../../../../store/reducers/selectors';

const countryList = [
    {
        country: 'DE',
        number: '+49'
    },
    {
        country: 'UA',
        number: '+380'
    }
];

class SignupStep9 extends SignupStep {
    // @ts-ignore
    state = {
        ...this.state,
        prefix: '',
        prefixCountry: '',
        number: ''
    };
    noCountry = 'ZZ';

    constructor(props: any) {
        super(props);

        let defaultCountry = 'DE';
        let defaultNumber = '';
        let propNumber = this.props.state.phone;

        if (propNumber !== '' && propNumber !== undefined) {
            const phoneNumber = parsePhoneNumber(propNumber);
            defaultCountry = phoneNumber?.country ?? defaultCountry;
            defaultNumber = phoneNumber?.nationalNumber ?? defaultNumber;
        }

        this.state = {
            ...this.state,
            prefix: this.getPrefix(defaultCountry),
            prefixCountry: defaultCountry,
            number: defaultNumber
        };
    }

    renderStep(): React.ReactNode {
        return (
            <React.Fragment>
                <Paper elevation={1} style={{padding: '0 1.25rem'}}>
                    <FormControl style={{padding: '1.1rem 0'}}>
                        <PhoneInput
                            autoFocus
                            placeholder="0123 456789"
                            value={this.state.number}
                            onChange={(phone?: string) =>
                                this.handleChangePhoneNumber(phone)
                            }
                            autoComplete="tel"
                            id="code-input"
                            name="phone"
                            defaultCountry={
                                this.state.prefixCountry
                            }></PhoneInput>
                    </FormControl>
                </Paper>
            </React.Fragment>
        );
    }

    getPrefix(country: string) {
        return getCountryCallingCode(country);
    }

    handleChangePhoneNumber(phone?: string) {
        const event = {target: {value: phone}};
        this.handleChange('phone')(
            // @ts-ignore
            event,
            this.validate.bind(this)
        );
    }

    handleInput(event: any) {
        if (event.target.tagName === 'SELECT') {
            this.setCountryInState(event.target.value);
        } else {
            const select = event.target.previousSibling.firstChild;
            if (select !== undefined) {
                this.setCountryInState(select.value);
            }
        }
    }

    setCountryInState(prefixCountry: string) {
        // @ts-ignore
        this.setState({prefixCountry: prefixCountry});
        if (prefixCountry !== this.noCountry) {
            // @ts-ignore
            this.setState({prefix: this.getPrefix(prefixCountry)});
        }
    }

    renderStepText(value = '') {
        // @ts-ignore
        if (this.props.registerStatus === RegisterStatus.FAIL) {
            super.renderStepText(
                'Verbindung zum Server fehlgeschlagen. Bitte überprüfe deine Internetverbindung.',
                true
            );
        } else if (
            this.validatePhoneNumberForFailureText(this.props.state.phone) ===
            false
        ) {
            return super.renderStepText(
                'Wir bieten zur Zeit nur deutsche und ukrainische Handynummern an.',
                true
            );
        }

        return super.renderStepText(value);
    }

    validatePhoneNumberForFailureText(number: string) {
        let valid = true;
        if (
            !this.isCountryValid(this.state.prefixCountry) &&
            this.state.prefixCountry !== this.noCountry
        ) {
            valid = false;
        }
        if (number !== '' && number !== undefined) {
            if (
                this.state.prefixCountry !== this.noCountry &&
                this.isNumberValid(number)
            ) {
                valid = true;
            }
            const phoneNumber = parsePhoneNumber(number);
            if (phoneNumber === undefined) {
                valid = true;
            }
            if (phoneNumber?.country !== undefined) {
                valid = this.isCountryValid(phoneNumber?.country);
            }
        }
        return valid;
    }

    isCountryValid(country: any) {
        return (
            countryList.find((element: any) => {
                return element.country === country;
            }) !== undefined
        );
    }
    isNumberValid(inputNumber: any) {
        return (
            countryList.find((element: any) => {
                return inputNumber.startsWith(element.number);
            }) !== undefined
        );
    }

    areRequirementsMet(props: any) {
        return (
            super.areRequirementsMet(props) &&
            props.state.phone !== '' &&
            this.validatePhoneNumber(props.state.phone)
        );
    }

    validatePhoneNumber(number: string) {
        if (number !== '' && number !== undefined) {
            const phoneNumber = parsePhoneNumber(number);
            if (phoneNumber?.country !== undefined) {
                return this.isCountryValid(phoneNumber?.country);
            }
        }
        return false;
    }
}

function mapStateToProps(state: any) {
    return {
        registerStatus: getNotification(state)?.registerStatus
    };
}

export default connect(mapStateToProps, {push})(SignupStep9);

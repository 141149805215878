import React from 'react';
import {IAccount} from '../../../../models/accountInterface';
import {IBankConnection} from '../../../../models/bankInterface';
import {queueItemByIdSelector} from '../../../../store/reducers/selectors/notificationSelector';
import {loadingIdRefreshBankConnection} from '../../../../store/actions/loadingIds';
import {Box, Container, Grid, IconButton} from '@material-ui/core';
import AccountGroupDetailsSkeleton from '../../../../styles/CustomComponents/Skeletons/AccountGroupDetailsSkeleton';
import {WgTypography} from '../../../../styles/CustomComponents';
import Logo from '../../../utils/Logo';
import {connect} from 'react-redux';
import {refreshBankConnection} from '../../../../store/actions/bankActions';
import AccountListItem from '../../../utils/AccountListItem';
import {formatIBAN} from '../../../utils/Format';
import DepotYieldLabel from '../../../utils/DepotYieldLabel';

export interface BankConnectionAccountGroupProps {
    header: string;
    accounts: IAccount[];
    accountBalance?: number;
    subTitle?: string;
    bankConnection?: IBankConnection;
    refreshBankConnection?: Function;
    isGroupLoading?: boolean;
    isCustomizable?: boolean;
}

function BankConnectionAccountGroup({
    header,
    accounts,
    accountBalance,
    subTitle,
    bankConnection,
    refreshBankConnection,
    isGroupLoading,
    isCustomizable
}: BankConnectionAccountGroupProps) {
    return (
        <Container
            key={`${header}-${
                bankConnection?.bankConnectionId
            }-${accountBalance ?? 0}`}
            style={{marginBottom: '1rem'}}>
            <Box
                display="flex"
                justifyContent="space-between"
                paddingBottom="4px">
                {isGroupLoading ? (
                    <AccountGroupDetailsSkeleton />
                ) : (
                    <Grid
                        style={{paddingTop: '1rem', flex: 1}}
                        container
                        wrap="nowrap"
                        justify="space-between"
                        alignItems="center">
                        <Grid
                            zeroMinWidth
                            item
                            xs
                            container
                            direction="column"
                            style={{marginLeft: '4px', overflow: 'hidden'}}>
                            <WgTypography
                                style={{maxWidth: '100%'}}
                                header="uppercase"
                                noWrap>
                                {header}
                            </WgTypography>
                            {subTitle && (
                                <WgTypography
                                    style={{maxWidth: '100%'}}
                                    header="uppercase"
                                    noWrap>
                                    {subTitle}
                                </WgTypography>
                            )}
                        </Grid>
                        {typeof bankConnection !== 'undefined' && (
                            <Grid
                                item
                                xs={2}
                                container
                                justify="flex-end"
                                alignItems="center">
                                {renderRefreshButton()}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Box>
            <Grid container justify="space-between" spacing={1}>
                {mapAccountsToListItem(accounts)}
            </Grid>
        </Container>
    );

    function renderRefreshButton() {
        return (
            <IconButton
                onClick={() =>
                    refreshBankConnection &&
                    refreshBankConnection(bankConnection)
                }
                size="small"
                style={{
                    backgroundColor: 'transparent',
                    padding: 0
                }}>
                <Logo
                    width="32px"
                    height="32px"
                    defaultImage="/img/navigation/icon-reload-green.svg"
                />
            </IconButton>
        );
    }

    function mapAccountsToListItem(accounts: IAccount[]) {
        return accounts.map((account: IAccount, index: number) => (
            <Grid item xs={12} md={6} key={index}>
                {renderListItem(
                    account,
                    Boolean(isCustomizable),
                    account.accountType === 4,
                    account.visible,
                    index === 0
                )}
            </Grid>
        ));
    }

    function renderListItem(
        account: IAccount,
        isCustomizable: boolean,
        isDepot: boolean = false,
        active?: boolean,
        hasSwipeableHint?: boolean
    ) {
        return (
            <AccountListItem
                bankConnection={account.bankConnection}
                hasSwipeableHint={hasSwipeableHint}
                isCustomizable={isCustomizable}
                active={active}
                key={account.id}
                accountId={account.id}
                loadingId={loadingIdRefreshBankConnection(
                    account.bankConnectionId
                )}
                link={
                    isDepot
                        ? '/depot/details/' + account.id
                        : '/accounts/details/' + account.id
                }
                logoPath={account.logoPath}
                defaultImage={'/img/icon-bank-circle.svg'}
                header={account.name}
                subHeader={formatIBAN(account.iban)}
                amount={account.balance}
                subAmountAny={
                    isDepot &&
                    account.percentageDifference !== undefined && (
                        <DepotYieldLabel value={account.percentageDifference} />
                    )
                }
                isRefreshAccount
            />
        );
    }
}

function mapStateToProps(
    state: any,
    ownProps: BankConnectionAccountGroupProps
) {
    return {
        isGroupLoading: queueItemByIdSelector(state, {
            queueId: loadingIdRefreshBankConnection(
                ownProps.bankConnection?.bankConnectionId ?? -1
            )
        })
    };
}

export default connect(mapStateToProps, {refreshBankConnection})(
    BankConnectionAccountGroup
);

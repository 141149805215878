export enum CompanyType {
    MISC,
    INSURANCE,
    ENERGY
}

export interface ICompany {
    id: number;
    logoPath?: string;
    name?: string;
    categoryIds?: number[];
    isInitial?: boolean;
    companyType: CompanyType;
}

import {Box, BoxProps, Paper} from '@material-ui/core';
import {WgTypography} from './index';
import React from 'react';

export interface WgPaperAreaProps extends BoxProps {
    header?: string;
    subHeader?: string;
    elevation?: number;
    paperStyle?: any;
}

export default function WgPaperArea({
    header,
    subHeader,
    children,
    elevation,
    paperStyle,
    padding,
    ...boxProps
}: WgPaperAreaProps) {
    const defaultPadding = '1rem 1rem 1.125rem';

    return (
        <Box {...boxProps}>
            <Paper
                elevation={elevation}
                style={paperStyle ?? {padding: padding ?? defaultPadding}}>
                {header && (
                    <Box marginBottom={2}>
                        <WgTypography header="subHeader">{header}</WgTypography>
                        {subHeader && (
                            <WgTypography
                                color="textSecondary"
                                text="subContent">
                                {subHeader}
                            </WgTypography>
                        )}
                    </Box>
                )}
                {children}
            </Paper>
        </Box>
    );
}

import React, {Component} from 'react';
import {userActions} from '../../store/actions';
import {connect} from 'react-redux';
import {getCookie} from '../utils/Cookie';
import {Maintenance} from '../views/Maintenance';
import {ReactGA} from '../../tracking';
import {withRouter} from 'react-router';
import {applyBlacklists} from '../../rootReducer';

interface IProps {
    performLogout?: Function;
    state?: any;
}

interface IState {
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

export function getUserDeviceInfo() {
    return {
        uuid: getCookie('uuid'),
        userAgent: window.navigator.userAgent
    };
}

export function buildTrackingMessage(
    action?: any,
    state?: any,
    error?: Error,
    errorInfo?: React.ErrorInfo,
    withUserDeviceInfo: boolean = false
) {
    const userDeviceInfo = withUserDeviceInfo ? getUserDeviceInfo() : {};

    return {
        ...userDeviceInfo,
        stack: error?.stack,
        componentStack: errorInfo?.componentStack,
        actionType: action?.type,
        state: applyBlacklists(state)
    };
}

export class ErrorComponent extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {error: undefined, errorInfo: undefined};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        const trackingMessage = buildTrackingMessage(
            undefined,
            this.props.state,
            error,
            errorInfo,
            true
        );
        console.error('ErrorBoundary: ', trackingMessage, this.props.state);
        ReactGA.exception({
            description: JSON.stringify(trackingMessage),
            fatal: true
        });

        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        this.props.performLogout && this.props.performLogout();
    }

    render() {
        if (this.state.errorInfo) {
            return <Maintenance />;
        }
        return this.props.children;
    }
}

function mapStateToProps(state: any) {
    return {state};
}

export default withRouter(
    connect(mapStateToProps, {
        performLogout: userActions?.performLogout
    })(ErrorComponent)
);

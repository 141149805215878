import {IBank} from '../../../../models/bankInterface';
import {Box, BoxProps} from '@material-ui/core';
import React from 'react';
import {MyLink} from '../../../utils';
import Logo from '../../../utils/Logo';

export interface BankLogoProps extends BoxProps {
    bank: IBank;
    hasSelectorLink?: boolean;
    callFinAPI?: (id: number, isAvailable: boolean) => void;
    handleClick?: Function;
}

export default function BankLogo({
    bank,
    hasSelectorLink,
    callFinAPI,
    handleClick,
    ...other
}: BankLogoProps) {
    return (
        <Box
            component={
                hasSelectorLink
                    ? MyLink(
                          `/accounts/add/${bank.searchString}/${bank.logoPath}`
                      )
                    : 'div'
            }
            key={bank.id}
            boxShadow={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={4}
            p="0.5rem"
            width="4rem"
            height="4rem"
            bgcolor="common.white"
            style={{cursor: 'pointer'}}
            {...other}>
            <Logo
                rounded="4px"
                key={bank.id}
                aria-label={bank.name}
                alt={bank.name}
                logoPath={bank.logoPath}
                defaultImage={'/img/bank.svg'}
                onClick={() =>
                    !hasSelectorLink
                        ? callFinAPI && callFinAPI(bank.id, bank.available)
                        : handleClick && handleClick(bank)
                }
            />
        </Box>
    );
}

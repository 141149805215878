import {createAction} from 'redux-actions';
import {
    auth,
    getAuthConfig,
    isErrorResponseStatusTreatable,
    performApiCall,
    refreshToken
} from './api';
import {
    SET_REQUIRED_INFORMATIONS,
    SET_TOKEN,
    SET_USER_BROKERMANDATE,
    SET_USER_DATA,
    SET_USER_ID,
    SET_USER_TMP_BROKERMANDATE,
    USER_LOGOUT
} from './actionTypes';
import {notificationActions} from './index';
import {LoadingPriorityCodes, RegisterStatus} from '../reducers/notification';
import {
    addLoading,
    displayErrorNotification,
    removeLoading,
    setRegisterStatus,
    setSnackbar
} from './notificationActions';
import {push} from 'connected-react-router';
import {validateEmail} from '../../containers/utils/Validate';
import {
    deleteCookie,
    getCookie,
    setCookie
} from '../../containers/utils/Cookie';
import {getUserDeviceInfo} from '../../containers/app/ErrorBoundary';
import {
    IAuthenticationUserInfo,
    IChangePasswordBody,
    IRegisterUser,
    IUserData
} from '../../models/userInterface';
import WgCircularProgress from '../../containers/app/loading_components/WgCircularProgress';
import WgCircularProgressTokenCheck from '../../containers/app/loading_components/WgCircularProgressTokenCheck';
import {ReactGA} from '../../tracking';
import {
    getBrokermandate,
    getTmpBrokermandate
} from '../reducers/selectors/userSelector';

// *** ACTION CREATORS ***
export const logout = createAction(USER_LOGOUT);
export const setUserData = createAction(SET_USER_DATA);
export const setUserBrokermandate = createAction(SET_USER_BROKERMANDATE);
export const setUserTmpBrokermandate = createAction(SET_USER_TMP_BROKERMANDATE);
export const setRequiredInformations = createAction(SET_REQUIRED_INFORMATIONS);
export const userSetToken = createAction(SET_TOKEN);
export const setUserId = createAction(SET_USER_ID);

export const registerUser: any = (userData: IRegisterUser) => {
    return performApiCall(
        async (dispatch: Function) => {
            if (!userData?.password)
                return dispatch(
                    displayErrorNotification(
                        'Aus Sicherheitsgründen speichern wir dein Passwort nicht. Bitte gib es erneut ein.',
                        () => dispatch(push('/signup/7'))
                    )
                );

            const id = `registerUser`;
            try {
                await dispatch(
                    addLoading({
                        id,
                        priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                        component: WgCircularProgress
                    })
                );
                dispatch(addUserData(userData));
                dispatch(setRegisterStatus(RegisterStatus.NONE));
                const response = await auth.post('/users', userData);
                dispatch(setUserId(response.data.id));
                dispatch(setRegisterStatus(RegisterStatus.SUCCESS));
                await dispatch(removeLoading(id));
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }

                await dispatch(removeLoading(id));
                if (error.response && error.response.status === 400) {
                    switch (error.response.data) {
                        case 'BirthDateFormat':
                            dispatch(
                                displayErrorNotification(
                                    'Dein angegebenes Geburtsdatum scheint nicht korrekt zu sein. Bitte überprüfe deine Altersangabe.',
                                    () => dispatch(push('/signup/3'))
                                )
                            );
                            break;
                        case 'Address':
                            dispatch(
                                displayErrorNotification(
                                    'Deine Adresse enthält scheinbar fehlerhafte Angaben. Bitte korrigiere deine Angabe.',
                                    () => dispatch(push('/signup/5'))
                                )
                            );
                            break;
                        case 'PhoneNumber':
                            dispatch(
                                displayErrorNotification(
                                    'Deine angegebene Telefonnummer enthält ungültige Werte. Bitte prüfe deine Eingabe.',
                                    () => dispatch(push('/signup/9'))
                                )
                            );
                            break;
                        default:
                            dispatch(
                                displayErrorNotification(error.response.data)
                            );
                    }
                } else {
                    throw new Error('Der User konnte nicht angelegt werden.');
                }
            }
        },
        'Registrieren fehlgeschlagen. Bitte versuche es später noch einmal.',
        (dispatch: Function) =>
            dispatch(setRegisterStatus(RegisterStatus.FAIL)),
        true,
        undefined,
        undefined,
        false
    );
};

export const userAuthentication: any = (
    userInfo?: IAuthenticationUserInfo
) => async (dispatch: Function) => {
    const id = `userAuthentication`;
    await dispatch(
        addLoading({
            id,
            priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
            component: WgCircularProgressTokenCheck
        })
    );
    const data = userInfo || {uuid: getCookie('uuid')};
    if (Object.values(data).includes(undefined)) {
        await dispatch(removeLoading(id));
        return;
    } else {
        try {
            const res = await auth.post('/authentication', data);
            await dispatch(userSetToken(res.data));
            await authenticateViaToken(res.data, dispatch);
            setCookie('tokenValid', new Date().toISOString(), 7);
            await dispatch(removeLoading(id));
        } catch (error) {
            if (error.response?.status === 504) {
                throw error;
            }

            await dispatch(removeLoading(id)); // TODO does it make sense to move all removals of loading state to performApiCall?
            deleteCookie('tokenValid');
            throw error;
        }
    }
};

export const verifyUser: any = (smsCode: string) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const userId = getState().user.id;

            const id = 'verifyUser';
            await dispatch(
                addLoading({
                    id,
                    priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                    component: WgCircularProgress
                })
            );

            await auth.put(`/users/${userId}/verify`, smsCode);

            const {email, password} = getState().user.userData;
            const userInfo = {email, password};

            await dispatch(userAuthentication(userInfo));
            await dispatch(removeLoading(id));
            deleteCookie('userData');

            ReactGA.event({
                category: 'User',
                action: 'verifyUser',
                label: JSON.stringify(getUserDeviceInfo()),
                nonInteraction: true,
                value: 1
            });
        },
        'Verifizieren fehlgeschlagen. Bitte versuche es noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const sendAgain: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const id = `registerUser`;
            await dispatch(
                addLoading({
                    id,
                    priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                    component: WgCircularProgress
                })
            );
            try {
                await auth.delete(`/users/${getState().user.id}/verify`);
                //TODO add FeedBack to User
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }
                throw error;
            } finally {
                await dispatch(removeLoading(id));
            }
        },
        'Es kann vorübergehend kein Verifizierungscode gesendet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const performLoginOnLogin: any = (userInfo: IAuthenticationUserInfo) =>
    performApiCall(
        async (dispatch: Function) => {
            await dispatch(
                performLogin(
                    userInfo,
                    'E-Mail-Adresse und Passwort stimmen nicht überein. Bitte kontrolliere deine Eingabe.',
                    async (dispatch: Function, getState: Function, e: any) => {
                        const res = e.response;
                        if (res && res.status === 401) {
                            await dispatch(removeLoading(`userAuthentication`));
                            deleteCookie('tokenValid');
                        } else throw e;
                    }
                )
            );
        },
        'Unser Server ist zurzeit nicht erreichbar. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const performLogin: any = (
    userInfo?: IAuthenticationUserInfo,
    errorMessage?: string,
    onError?: Function
) =>
    performApiCall(
        async (dispatch: Function) => {
            await dispatch(userAuthentication(userInfo));
        },
        errorMessage,
        onError,
        true,
        undefined,
        undefined,
        false
    );

async function authenticateViaToken<Payload>(
    token: string,
    dispatch?: Function
) {
    const response = await auth.get(
        '/authentication',
        getAuthConfig(token, {Accept: 'application/json'})
    );
    dispatch && dispatch(setUserId(response.data.id));
    return response;
}

export const performLoginCookie: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            try {
                const token = getState().user.token;
                await authenticateViaToken(token, dispatch);
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }
                await refreshToken(getState, dispatch);
            }
        },
        'Anmelden via Cookie fehlgeschlagen.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const fetchUserData: any = () => async (
    dispatch: Function,
    getState: Function
) => {
    const token = getState().user.token;
    let res;
    try {
        res = await authenticateViaToken(token, dispatch);
    } catch (error) {
        if (isErrorResponseStatusTreatable(error)) {
            throw error;
        }
        throw new Error('Authentification via Token fehlgeschlagen.');
    }

    try {
        res = await auth.get(
            '/users/' + res.data.id,
            getAuthConfig(token, {Accept: 'application/json'})
        );
        dispatch(setUserData(res.data));
    } catch (error) {
        if (isErrorResponseStatusTreatable(error)) {
            throw error;
        }
        throw new Error('Daten konnten nicht geladen werden.');
    }
};
export const performLogout: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState()?.user?.token;
            await dispatch(logout());
            await auth.delete(
                '/authentication',
                getAuthConfig(token, {Accept: 'application/json'})
            );
        },
        'Deine Anfrage kann im Moment nicht bearbeitet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const updateUser: any = (userData: IUserData) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const token = getState().user.token;
            const userId = getState().user.id;
            await auth.put(`/users/${userId}`, userData, getAuthConfig(token));

            addUserData(userData)(dispatch, getState);
            dispatch(
                notificationActions.notification_set_dialog({
                    title: 'Speichern erfolgreich',
                    isAgreeable: true,
                    open: true
                })
            );
        },
        'Deine Anfrage kann im Moment nicht bearbeitet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const deleteWechselGottAccount: any = () =>
    performApiCall(
        async (dispatch: Function) => {
            dispatch(
                notificationActions.notification_set_dialog({
                    title: 'Konto löschen',
                    text:
                        'Wenn du dein Konto löschen möchtest, formuliere bitte eine schriftliche Kündigung an die folgende E-Mail-Adresse: kontakt@wechselgott.com',
                    isAgreeable: true,
                    open: true
                })
            );
        },
        undefined,
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const updateBrokermandate: any = (image: string) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            if (!getState()?.user?.token || !getState()?.user?.id) return;

            const id = `updateBrokermandate`;
            await dispatch(
                addLoading({
                    id,
                    priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                    component: WgCircularProgress
                })
            );

            const token = getState().user.token;
            const userId = getState().user.id;
            const brokermandate = getBrokermandate(getState());
            await auth.put(
                `/users/${userId}/brokermandate`,
                image,
                getAuthConfig(token)
            );

            if (!brokermandate) {
                ReactGA.event({
                    category: 'Mandate',
                    action: 'add new Mandate',
                    label: JSON.stringify(getUserDeviceInfo()),
                    nonInteraction: true,
                    value: 1
                });
            }

            await dispatch(setUserBrokermandate(image));
            await dispatch(removeLoading(id));
            dispatch(
                notificationActions.notification_set_dialog({
                    title: 'Speichern erfolgreich',
                    isAgreeable: true,
                    open: true
                })
            );
        },
        'Deine Anfrage kann im Moment nicht bearbeitet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const fetchBrokermandate: any = () =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            if (!getState()?.user?.token || !getState()?.user?.id) return;
            const token = getState().user.token;
            const userId = getState().user.id;

            try {
                const res = await auth.get(
                    `/users/${userId}/brokermandate`,
                    getAuthConfig(token)
                );
                if (res.data) {
                    await dispatch(setUserBrokermandate(res.data));
                }
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }
                if (!isStatusToIgnore(error)) {
                    throw error;
                }
            }

            function isStatusToIgnore(e: any) {
                const codes = [404, 416];
                return codes.includes(e.response.status);
            }
        },
        'Deine Anfrage kann im Moment nicht bearbeitet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

interface TmpBrokerMandate {
    image: string | null;
    isChecked: boolean;
    resetOnReenter: number;
}

export const updateTmpBrokermandate: any = (
    image?: string | null,
    isChecked?: boolean,
    resetOnReenter?: number
) => async (dispatch: Function, getState: Function) => {
    // resetOnReenter values: 0: don't reset, 1: reset, 2: action is yet to be chosen

    let lastState = getTmpBrokermandate(getState());
    let object: TmpBrokerMandate = {
        image: null,
        isChecked: false,
        resetOnReenter: 2
    };
    if (lastState) {
        if (image === undefined) {
            object.image = lastState.image;
        } else {
            object.image = image;
        }
        if (isChecked === undefined) {
            object.isChecked = lastState.isChecked;
        } else {
            object.isChecked = isChecked;
        }
        if (resetOnReenter === undefined) {
            object.resetOnReenter = lastState.resetOnReenter;
        } else {
            object.resetOnReenter = resetOnReenter;
        }
    }
    dispatch(setUserTmpBrokermandate(object));
};

export const addUserData: any = (userData: any) => async (
    dispatch: Function,
    getState: Function
) => {
    await dispatch(
        setUserData({
            ...getState().user.userData,
            ...userData
        })
    );
};

export const performResetPassword: any = (email?: string) =>
    performApiCall(
        async (dispatch: Function) => {
            const id = `performResetPassword`;
            try {
                if (!email || !validateEmail(email))
                    return dispatch(push('/resetpassword/1'));
                const emailBase64 = btoa(email);
                await dispatch(
                    addLoading({
                        id,
                        priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                        component: WgCircularProgress
                    })
                );
                await auth.delete(`/users/by-email/${emailBase64}/password`);
                await dispatch(addUserData({email}));
                await dispatch(push('/resetpassword/2'));
                await dispatch(removeLoading(id));
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }
                await dispatch(removeLoading(id));
                throw error;
            }
        },
        'Das Passwort konnte nicht zurückgesetzt werden. Möglicherweise ist eingegebene E-Mail-Adresse falsch.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const performChangePasswordEmail: any = (data: {
    code: string;
    password: string;
}) =>
    performApiCall(
        async (dispatch: Function, getState: Function) => {
            const id = `performChangePasswordEmail`;
            await dispatch(
                addLoading({
                    id,
                    priority: LoadingPriorityCodes.CIRCULAR_PROGRESS,
                    component: WgCircularProgress
                })
            );
            try {
                const email = getState().user.userData.email;
                const emailBase64 = btoa(email);
                await auth.put(`/users/by-email/${emailBase64}/password`, data);
                const {password} = data;
                await dispatch(performLoginOnLogin({email, password}));
                await dispatch(removeLoading(id));
            } catch (error) {
                if (isErrorResponseStatusTreatable(error)) {
                    throw error;
                }
                await dispatch(removeLoading(id));
                throw error;
            }
        },
        'Deine Anfrage kann im Moment nicht bearbeitet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const performChangePassword: any = (body: IChangePasswordBody) =>
    performApiCall(
        changePassword(body),
        'Deine Anfrage kann im Moment nicht bearbeitet werden. Bitte versuche es später noch einmal.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const changePassword: any = (body: IChangePasswordBody) => async (
    dispatch: Function,
    getState: Function
) => {
    if (!getState()?.user?.token || !getState()?.user?.id) {
        return;
    }

    try {
        const token = getState().user.token;
        const userId = getState().user.id;
        await auth.put(`/users/${userId}/password`, body, getAuthConfig(token));
        dispatch(
            setSnackbar({
                text: 'Dein Passwort wurde erfolgreich geändert',
                afterClose: () => window.history.back(),
                variant: 'success',
                open: true
            })
        );
    } catch (error) {
        if (isErrorResponseStatusTreatable(error)) {
            throw error;
        }
        if (error.response?.status === 400) {
            dispatch(
                displayErrorNotification(
                    'Dein altes Passwort ist nicht korrekt.'
                )
            );
        } else {
            throw error;
        }
    }
};

export const fetchRequiredInformations: any = () =>
    performApiCall(
        performFetchRequiredInformations(),
        'Daten konnten nicht geladen werden.',
        undefined,
        true,
        undefined,
        undefined,
        false
    );

export const performFetchRequiredInformations: any = () => async (
    dispatch: Function,
    getState: Function
) => {
    if (!getState()?.user?.token || !getState()?.user?.id) {
        return;
    }
    const token = getState().user.token;
    const userId = getState().user.id;

    const response = await auth.get(
        `/users/${userId}/requiredinformations`,
        getAuthConfig(token)
    );

    dispatch(setRequiredInformations(response.data));
};

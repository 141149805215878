import React from 'react';
import {connect} from 'react-redux';
import Logo from './Logo';
import {
    WgButtonBox,
    WgListItem,
    WgListItemAction,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from '../../styles/CustomComponents';
import AccountListItemSkeleton from '../../styles/CustomComponents/Skeletons/AccountListItemSkeleton';
import {queueItemByIdSelector} from '../../store/reducers/selectors';
import WgSwipeable, {
    ActionButtonProps
} from '../../styles/CustomComponents/WgSwipeable';
import PopoverActionButtons from './PopoverActionButtons';
import useMediaQuery from '../../hooks/useMediaQuery';
import {refreshBankConnection} from '../../store/actions/bankActions';
import {setAccountVisibility} from '../../store/actions/accountActions';
import {IBankConnection} from '../../models/bankInterface';
import {loadingIdAllAccounts} from '../../store/actions/loadingIds';

interface IProps {
    swipeActions?: ActionButtonProps[];
    link: string | undefined;
    image?: string;
    logoPath?: string;
    defaultImage: string;
    header: string;
    loadingId?: string;
    subHeader?: string;
    amount?: number;
    subAmountString?: string;
    subAmountAny?: any;
    warning?: boolean;
    info?: boolean;
    isRefreshAccount?: boolean;
    accountId?: number;
    refreshBankConnection?: Function;
    setAccountVisibility?: Function;
    active?: boolean;
    isCustomizable?: boolean;
    hasSwipeableHint?: boolean;
    loadingState?: boolean;
    bankConnection?: IBankConnection;
}

function AccountListItem(props: IProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const open = Boolean(anchorEl);

    const hasActionButton =
        (props.isRefreshAccount && !props.isCustomizable) ||
        props.isCustomizable;

    const {isMobile} = useMediaQuery('sm');

    const actions: ActionButtonProps[] = props.swipeActions ?? [
        {
            src: `/img/navigation/icon-reload-${
                !isMobile ? 'green' : 'white'
            }.svg`,
            onClick: () =>
                props.refreshBankConnection &&
                props.refreshBankConnection(props.bankConnection),
            text: 'Aktualisieren'
        },
        {
            src: `/img/navigation/icon-eye-${
                props.active ? 'invisible' : 'visible'
            }-${!isMobile ? 'green' : 'white'}.svg`,
            onClick: () =>
                props.setAccountVisibility &&
                props.setAccountVisibility(props.accountId, !props.active),
            text: props.active ? 'Ausblenden' : 'Einblenden'
        }
    ];

    if (props.loadingState) return <AccountListItemSkeleton />;
    return (
        <WgSwipeable
            hint={props.hasSwipeableHint}
            disabled={!hasActionButton}
            actions={actions}>
            <WgListItem
                hasActionButton={!isMobile && hasActionButton}
                style={{minWidth: '100%'}}
                link={props.link}
                key={props.accountId}
                shadow
                background={'paper'}
                warningFlag={props.warning ? 'left' : undefined}>
                <WgListItemIcon>
                    <Logo
                        rounded="4px"
                        logoPath={props.logoPath}
                        defaultImage={props.defaultImage}
                        alt="logo"
                    />
                </WgListItemIcon>
                <WgListItemContent>
                    <div>
                        <WgTypography
                            gutterBottom
                            noWrap
                            text="content"
                            color="textPrimary">
                            {props.header}
                        </WgTypography>
                        <WgTypography
                            noWrap
                            color="textSecondary"
                            text="subContent">
                            {props.subHeader}
                        </WgTypography>
                    </div>
                    <div>
                        {typeof props.amount !== 'undefined' && (
                            <WgTypography
                                money
                                align="right"
                                noWrap
                                color="textPrimary"
                                number="list">
                                {props.amount}
                            </WgTypography>
                        )}
                        {props.subAmountString && (
                            <WgTypography
                                money
                                align="right"
                                noWrap
                                color="textSecondary"
                                text="subContent">
                                {props.subAmountString}
                            </WgTypography>
                        )}
                        {props.subAmountAny}
                    </div>
                </WgListItemContent>
                {!isMobile && hasActionButton && (
                    <WgListItemAction>
                        <WgButtonBox
                            aria-describedby="action-popover"
                            onClick={handlePopoverClick}
                            borderRadius="right"
                            BoxProps={{maxWidth: '42px', minWidth: '42px'}}>
                            <Logo
                                defaultImage={`/img/navigation/icon-more${
                                    open ? '-green' : ''
                                }.svg`}
                            />
                        </WgButtonBox>
                        <PopoverActionButtons
                            id="action-popover"
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            open={open}
                            actions={actions}
                        />
                    </WgListItemAction>
                )}
            </WgListItem>
        </WgSwipeable>
    );

    function handlePopoverClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }
}

function mapStateToProps(state: any, props: IProps) {
    // Check for initial login
    const loadingId =
        state.accounts.allAccounts.length === 0
            ? loadingIdAllAccounts
            : props.loadingId;

    return {
        loadingState: queueItemByIdSelector(state, {
            queueId: loadingId ?? ''
        })
    };
}

export default connect(mapStateToProps, {
    refreshBankConnection,
    setAccountVisibility
})(AccountListItem);

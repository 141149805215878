import React from 'react';
import {Grid, Link} from '@material-ui/core';
import {WgContainer, WgHeader} from '../../../../styles/CustomComponents';
import {ProfileListItem} from '../../../utils';
import useMediaQuery from '../../../../hooks/useMediaQuery';

export default function Contact() {
    const {isUp} = useMediaQuery('md');

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Kundensupport"
                hasBackButton
                backText="Mein Profil"
            />
            <WgContainer bodyContent>
                <Grid spacing={isUp ? 2 : 0} container justify="space-between">
                    <Grid item xs={12} md={6}>
                        <Link
                            underline="none"
                            href="mailto:info@wechselgott.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            <ProfileListItem
                                link=""
                                icon="/img/profile/icon-mail.svg"
                                text="Per E-Mail"
                                subtext="info@wechselgott.com"
                                hasIcon
                                dividerBefore
                                dividerAfter
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link
                            underline="none"
                            href="https://wa.me/4917657800298"
                            target="_blank"
                            rel="noopener noreferrer">
                            <ProfileListItem
                                link=""
                                icon="/img/profile/icon-whatsapp.svg"
                                text="Per WhatsApp"
                                subtext="+49 176 57800298"
                                hasIcon
                                dividerAfter
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Link underline="none" href="tel:+4917657800298">
                            <ProfileListItem
                                link=""
                                icon="/img/profile/icon-telephone.svg"
                                text="Per Telefon"
                                subtext="+49 176 57800298"
                                hasIcon
                                dividerAfter
                            />
                        </Link>
                    </Grid>
                </Grid>
            </WgContainer>
        </WgContainer>
    );
}

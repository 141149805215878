import {createDeepEqualSelector} from './utils';
import {IOffer} from '../../../models/offerInterface';

export const offersSelector = (state: any) => state?.offers;

export const allEnergyOffers = (state: any) =>
    offersSelector(state)?.energy || [];

export const offerByIdSelector = createDeepEqualSelector(
    [
        allEnergyOffers,
        (_: any, props: {recurringPaymentId: number}) =>
            props.recurringPaymentId
    ],
    (offers: IOffer[], recurringPaymentId: number): IOffer | undefined => {
        if (!Array.isArray(offers) || !recurringPaymentId) {
            return undefined;
        }

        return offers.find(
            (offer: IOffer) =>
                Number(offer.recurringPaymentId) === Number(recurringPaymentId)
        );
    }
);

export interface IDateFieldExtended {
    date: Date;
    fieldName: string;
}

export interface IValidationRule {
    field: string;
    validationRule: Function;
}

export function isDate(date: Date): boolean {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        if (isNaN(date.getTime())) {
            return false; // date is not valid
        } else {
            return true;
        }
    } else {
        return false; //not a Date
    }
}

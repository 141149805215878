import React from 'react';
import {
    Box,
    BoxProps,
    createStyles,
    Theme,
    withStyles
} from '@material-ui/core';
import {WgTypography} from './index';
import clsx from 'clsx';

const styles = (theme: Theme) =>
    createStyles({
        normal: {
            borderBottom: `1rem solid ${theme.palette.text.hint}`
        },
        error: {
            borderBottom: `1rem solid ${theme.palette.error.light}1A`
        },
        errorBackground: {
            backgroundColor: `${theme.palette.error.light}1A`
        },
        normalBackground: {
            backgroundColor: theme.palette.text.hint
        },
        errorText: {
            color: theme.palette.error.light
        }
    });

export interface WgSpeechBubbleProps extends BoxProps {
    isError?: boolean;
    text?: string | React.ReactNode;
    classes: any;
}

function WgSpeechBubble({
    text,
    isError,
    classes,
    ...other
}: WgSpeechBubbleProps) {
    return (
        <Box {...other} display="flex" flexDirection="column">
            <Box
                className={clsx(classes.root, {
                    [classes['normal']]: !isError,
                    [classes['error']]: isError
                })}
                borderLeft="1rem solid transparent"
                borderRight="1rem solid transparent"
                marginTop="1.5rem"
                alignSelf="center"
                width={0}
                height={0}
            />
            <Box
                borderRadius="15px"
                p="1.25rem"
                className={clsx({
                    [classes['normalBackground']]: !isError,
                    [classes['errorBackground']]: isError
                })}>
                <WgTypography
                    align="center"
                    className={clsx(classes.root, {
                        [classes['errorText']]: isError
                    })}
                    color={isError ? undefined : 'textPrimary'}
                    text="sentence">
                    {text}
                </WgTypography>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(WgSpeechBubble);

import React from 'react';
import {Box, Grid, Paper} from '@material-ui/core';
import {connect} from 'react-redux';
import {AmountForm, TransactionListItem} from '../../utils';
import {formatDateFromString} from '../../utils/Format';
import {
    singleAccountSelector,
    allTransactionsFromAccountGroupedByDaySelector
} from '../../../store/reducers/selectors';
import {ITransaction} from '../../../models/transactionInterface';
import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../styles/CustomComponents';

// @ts-ignore
export default ({match}) => <ConnectAccount accountId={match.params.account} />;

export interface AccountProps {
    accountId: string;
    accountName?: string;
    balance?: number;
    groupedTransactions?: any;
    isVisible: boolean;
}

function Account(props: AccountProps) {
    let transactions: JSX.Element;
    if (!props.isVisible) {
        transactions = renderTransactionsAreHidden();
    } else if (
        props.groupedTransactions &&
        props.groupedTransactions.length > 0
    ) {
        transactions = props.groupedTransactions.map(
            ([date, values]: [string, ITransaction[]]) =>
                renderTransactionGroup(date, values)
        );
    } else {
        transactions = renderNoTransactionsFound();
    }

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={props.accountName}
                hasBackButton
                backText="Meine Konten"
            />
            <WgContainer
                bodyContent
                disableGutters
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                {props.balance !== undefined && (
                    <AmountForm
                        m="1.5rem 1.25rem 3rem 1.25rem"
                        amount={props.balance}
                        text="Kontostand"
                        isColored
                    />
                )}
                {transactions}
            </WgContainer>
        </WgContainer>
    );

    function mapAccount(transaction: ITransaction) {
        return (
            <TransactionListItem
                key={String(transaction.id)}
                link={`/accounts/details/${props.accountId}/${transaction.id}`}
                categoryId={transaction.categoryId}
                companyId={transaction.companyId}
                logoPath={transaction.logoPath}
                defaultImage={
                    transaction.amount < 0
                        ? '/img/sending.svg'
                        : '/img/receiving.svg'
                }
                header={
                    transaction.counterpartName
                        ? transaction.counterpartName
                        : transaction.purpose
                }
                subHeader={formatDateFromString(transaction.valueDate)}
                amount={transaction.amount}
            />
        );
    }

    function renderTransactionGroup(
        date: string,
        transactions: ITransaction[]
    ) {
        return (
            <Grid key={date} container style={{minHeight: 'fit-content'}}>
                <Grid
                    item
                    xs={12}
                    container
                    wrap="nowrap"
                    style={{padding: '2rem 1rem 0.5rem'}}>
                    <Grid item xs={8}>
                        <WgTypography
                            style={{maxWidth: '100%'}}
                            header="uppercase"
                            noWrap>
                            {formatDateFromString(date)}
                        </WgTypography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        container
                        wrap="nowrap"
                        justify="flex-end">
                        <WgTypography noWrap money header="uppercase">
                            {transactions.reduce(
                                (acc: number, trans) => acc + trans.amount,
                                0
                            )}
                        </WgTypography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={1}>
                        {transactions &&
                            transactions.map((transaction: ITransaction) =>
                                mapAccount(transaction)
                            )}
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    function renderNoTransactionsFound() {
        return (
            <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center">
                <WgTypography align="center" color="textSecondary" text="info">
                    Keine Transaktionen erkannt.
                </WgTypography>
            </Box>
        );
    }

    function renderTransactionsAreHidden() {
        return (
            <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center">
                <WgTypography align="center" color="textSecondary" text="info">
                    Die Transaktionen dieses Kontos sind ausgeblendet.
                </WgTypography>
            </Box>
        );
    }
}

function makeMapStateToProps() {
    return mapStateToProps;

    function mapStateToProps(state: any, ownProps: AccountProps) {
        let accountId = Number(ownProps.accountId);
        const account = singleAccountSelector(state, {accountId});

        let isVisible: boolean = true;
        if (account) {
            // if account.visible is undefined, the account is actually visible.
            isVisible = account.visible === undefined ? true : account?.visible;
        }

        return {
            accountName: account && account.name,
            balance: account && account.balance,
            groupedTransactions: allTransactionsFromAccountGroupedByDaySelector(
                state,
                {selectedAccounts: account ? [account.id] : []}
            ),
            isVisible: isVisible
        };
    }
}

const ConnectAccount = connect(makeMapStateToProps, {})(Account);

import React from 'react';
import {
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from '../../styles/CustomComponents';
import Logo from './Logo';
import {Box, Divider, Hidden, IconButton} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';
import useMediaQuery from '../../hooks/useMediaQuery';

export interface ProfileListItemProps {
    icon: string;
    text: string;
    subtext?: string;
    link: string;
    hasIcon?: boolean;
    dividerBefore?: boolean;
    dividerAfter?: boolean;
}

export default function ProfileListItem({
    icon,
    text,
    subtext,
    link,
    hasIcon,
    dividerBefore,
    dividerAfter
}: ProfileListItemProps) {
    const {isUp} = useMediaQuery('md');
    return (
        <React.Fragment>
            {dividerBefore && (
                <Hidden mdUp>
                    <Divider light />
                </Hidden>
            )}
            <Box key={icon + text + link}>
                <WgListItem shadow link={link} square={!isUp}>
                    <WgListItemIcon>
                        <Logo
                            width="42px"
                            height="42px"
                            maxWidth="42px"
                            maxHeight="42px"
                            defaultImage={icon}
                            alt="logo"
                        />
                    </WgListItemIcon>
                    <WgListItemContent>
                        <Box>
                            <WgTypography
                                gutterBottom={typeof subtext !== 'undefined'}
                                noWrap
                                text="content"
                                color="textPrimary">
                                {text}
                            </WgTypography>
                            {subtext && (
                                <WgTypography
                                    noWrap
                                    color="textSecondary"
                                    text="subContent">
                                    {subtext}
                                </WgTypography>
                            )}
                        </Box>
                        {hasIcon && (
                            <IconButton style={{padding: 0}}>
                                <ChevronRight />
                            </IconButton>
                        )}
                    </WgListItemContent>
                </WgListItem>
            </Box>

            {dividerAfter && (
                <Hidden mdUp>
                    <Divider light />
                </Hidden>
            )}
        </React.Fragment>
    );
}

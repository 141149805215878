import {IContactData} from './userInterface';
import {MeterType} from './productInterface';
import {IntervalType, IRecurringpayment} from './recurringpaymentInterface';

const invalidAmount = -69;

export enum OfferType {
    ENERGY,
    INSURANCE
}

export enum EnergyStep {
    METER_TYPE_INPUT,
    AREA_INPUT,
    USAGE_OR_COST_INPUT,
    PRODUCT_COMPARE,
    DATA_VALIDATION,
    METER_NUMBER,
    PROVIDER_INPUT,
    ACCOUNT_SELECTION
}

export enum OfferState {
    AVAILABLE,
    NOT_FOUND,
    ECO_NOT_FOUND,
    POSTCODE_INVALID,
    AMOUNT_INVALID,
    KWH_USAGE_INVALID,
    UNKNOWN
}

export function getTitleFromEnergyStep(
    step?: EnergyStep,
    isManualAddition?: boolean,
    isContractConclusion?: boolean
): {title: string; backText: string} {
    if (typeof step === 'undefined') {
        return {
            title: 'Vertragswelt',
            backText: 'Vertragswelt'
        };
    }
    switch (step) {
        case EnergyStep.METER_TYPE_INPUT:
            return {
                title: 'Vertragsart',
                backText: isManualAddition
                    ? 'Kategorie wählen'
                    : 'Vertragsdetails'
            };
        case EnergyStep.AREA_INPUT:
            return {
                title: 'Tarifnutzung',
                backText: isManualAddition ? 'Beitrag' : 'Vertragsart'
            };
        case EnergyStep.USAGE_OR_COST_INPUT:
            return {
                title: 'Beitrag',
                backText: isManualAddition ? 'Vertragsart' : 'Tarifnutzung'
            };
        case EnergyStep.PRODUCT_COMPARE:
            return {
                title: 'Angebot wählen',
                backText: isManualAddition ? 'Tarifnutzung' : 'Beitrag'
            };
        case EnergyStep.DATA_VALIDATION:
            return {title: 'Daten prüfen', backText: 'Angebot wählen'};
        case EnergyStep.METER_NUMBER:
            return {
                title: 'Zählernummer',
                backText: isContractConclusion
                    ? 'Daten prüfen'
                    : 'Vertragsdetails'
            };
        case EnergyStep.PROVIDER_INPUT:
            return {title: 'Versorger wählen', backText: 'Vertragsart'};
        case EnergyStep.ACCOUNT_SELECTION:
            return {title: 'Bankkonto verknüpfen', backText: 'Beitrag'};
    }
}

export function formatOfferState(
    offer?: IOffer
): {title: string; description: string; step?: EnergyStep} {
    if (typeof offer === 'undefined') {
        return {
            title: 'Der Vergleich war nicht erfolgreich',
            description:
                'Leider konnten wir kein Angebot für dich finden. Wir sind aber weiterhin auf der Suche!'
        };
    }
    switch (offer.offerState) {
        case OfferState.NOT_FOUND:
            return {
                title: 'Dein Vertrag ist bereits optimal!',
                description:
                    'Leider konnten wir kein günstigeres Angebot für dich finden. ' +
                    'Wir benachrichtigen dich, sobald sich dies ändert.'
            };
        case OfferState.ECO_NOT_FOUND:
            return {
                title: 'Keinen Öko-Tarif gefunden.',
                description:
                    'Leider konnten wir in deinem Gebiet keinen passenden Öko-Tarif für dich finden.'
            };
        case OfferState.AVAILABLE:
            return {
                title: 'Angebot gefunden.',
                description:
                    'Wir haben basierend auf deinen Angaben passende Angebote für dich gefunden!'
            };
        case OfferState.AMOUNT_INVALID:
            return {
                title: 'Es gab ein Problem beim Vergleich',
                description:
                    'Dein angegebener Beitrag scheint ungültig zu sein. ' +
                    'Bitte überprüfe ihn noch einmal.',
                step: EnergyStep.USAGE_OR_COST_INPUT
            };
        case OfferState.POSTCODE_INVALID:
            return {
                title: 'Es gab ein Problem beim Vergleich',
                description:
                    'Für die von dir angegebene Postleitzahl konnten wir leider keine Angebote finden. ' +
                    'Bitte überprüfe sie noch einmal.',
                step: EnergyStep.AREA_INPUT
            };
        case OfferState.KWH_USAGE_INVALID:
        case OfferState.UNKNOWN:
        default:
            return {
                title: 'Der Vergleich war nicht erfolgreich',
                description:
                    'Leider konnten wir kein Angebot für dich finden. Wir sind aber weiterhin auf der Suche!'
            };
    }
}

export function offerInputInvalid(offer?: IOffer) {
    return (
        typeof offer === 'undefined' ||
        offer.offerState === OfferState.AMOUNT_INVALID ||
        offer.offerState === OfferState.POSTCODE_INVALID ||
        offer.offerState === OfferState.KWH_USAGE_INVALID
    );
}

export function isInErrorState(offer?: IOffer) {
    return (
        typeof offer === 'undefined' ||
        offer.offerState === OfferState.NOT_FOUND ||
        offer.offerState === OfferState.UNKNOWN
    );
}

export function getAvailableOffer(offer?: IOffer): IEnergyProduct | undefined {
    if (
        typeof offer === 'undefined' ||
        isInErrorState(offer) ||
        typeof offer.price === 'undefined'
    )
        return undefined;
    if (offer.offerState === OfferState.ECO_NOT_FOUND) {
        return offer.price;
    } else if (offer.offerState === OfferState.AVAILABLE) {
        return offer.price.estimatedSavings >
            (offer.eco?.estimatedSavings ?? invalidAmount)
            ? offer.price
            : offer.eco;
    } else {
        return undefined;
    }
}

export interface IOffer {
    productId?: number;
    preference?: IEnergyProductPreference;
    recurringPaymentId?: number;
    recurringPayment?: IRecurringpayment;
    offerState: OfferState;
    price?: IEnergyProduct;
    eco?: IEnergyProduct;
    dataValidation?: IEnergyOfferContractValidation;
    isManualProvider?: boolean;
}

// needed for fetching an energy offer
export interface IEnergyProductPreference {
    meterType: MeterType;
    isCommercial: boolean;
    deliveryPostcode?: string;
    oldProviderName?: string;
}

export interface IEnergyContractPreferences extends IEnergyProductPreference {
    companyId?: number;
    accountId?: number;
    categoryId?: number;
    amount?: number;
    paymentInterval?: IntervalType;
}

export interface IEnergyProduct extends IEnergyContractPreferences {
    logoPath?: string;
    estimatedSavings: number;
    estimatedUsageInKwh?: number;
    estimatedFeeWp: number;
    betterProviderName: string;
    betterProviderYearlyCosts: number;
    betterProviderMonthlyCosts: number;
    oldProviderYearlyCosts: number;
    oldProviderMonthlyServiceCharge?: number;
    oldProviderChargeCtPerKwh?: number;
    oldProviderMonthlyServiceChargeEurPerMonth?: number;
    eco: boolean;
}

export interface IEnergyOfferContractValidation
    extends Omit<IEnergyProduct, 'deliveryPostcode'> {
    newMeter?: boolean;
    movingAt: string;
    switchAt: string;
    meterNumber: string;
    customer: IContactData;
    billing: IContactData;
    delivery: IContactData;
    accountHolder: IContactData;
}

export interface IEnergyOldProviderData {
    oldProviderName: string;
    oldProviderYearlyCosts: number;
    oldProviderMonthlyServiceCharge?: number;
    oldProviderChargeCtPerKwh?: number;
    estimatedUsageInKwh?: number;
}

export function isEnergyProduct(
    object: IEnergyProduct | any
): object is IEnergyProduct {
    return (
        typeof (object as IEnergyProduct)?.deliveryPostcode !== 'undefined' &&
        typeof (object as IEnergyProduct)?.eco !== 'undefined'
    );
}

export function isOffer(object: IOffer | any): object is IOffer {
    const offer = object as IOffer;
    return (
        typeof offer?.offerState !== 'undefined' &&
        typeof offer?.recurringPaymentId !== 'undefined' &&
        typeof offer?.recurringPaymentId === 'number' &&
        (typeof offer?.price === 'undefined' || isEnergyProduct(offer.price)) &&
        (typeof offer?.eco === 'undefined' || isEnergyProduct(offer.eco))
    );
}

export function isEnergyContractValidation(
    object: IEnergyOfferContractValidation | any
): object is IEnergyOfferContractValidation {
    return (
        typeof (object as IEnergyOfferContractValidation)?.customer !==
            'undefined' &&
        typeof (object as IEnergyOfferContractValidation)?.delivery !==
            'undefined' &&
        typeof (object as IEnergyOfferContractValidation)?.billing !==
            'undefined' &&
        typeof (object as IEnergyOfferContractValidation)?.accountHolder !==
            'undefined'
    );
}

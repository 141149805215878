import {Box} from '@material-ui/core';
import React from 'react';
import {
    IPolicyData,
    IPaymentData,
    PaymentMethod
} from '../../../../../../models/productInterface';
import ListItemCompletion from './ListItemCompletion';
import InsuranceListItem from '../../../components/InsuranceListItem';
import {CarInsuranceStep} from '../CarInsuranceController';
import {IAccount} from '../../../../../../models/accountInterface';
import {formatPaymentInterval, formatIBAN} from '../../../../../utils/Format';

export interface AccountDataCompletionProps {
    policyData?: IPolicyData;
    paymentData?: IPaymentData;
    account?: IAccount;
}

export default function AccountDataCompletion(
    props: AccountDataCompletionProps
) {
    const {policyData, paymentData, account} = props;

    let keyValueMapping = {
        Zahlungsintervall: formatPaymentInterval(policyData?.paymentInterval),
        Kontoinhaber: paymentData?.accountHolderName
    };

    let keyValueLength = Object.keys(keyValueMapping).length;
    let countValues = 0;

    return (
        <Box margin="0 1rem 1rem 1rem">
            <Box>
                {keyValueMapping &&
                    Object.entries(keyValueMapping).map((entry: any) => {
                        countValues = countValues + 1;
                        return (
                            <ListItemCompletion
                                key={`${countValues}AccountDataCompletion`}
                                primary={entry[0]}
                                secondary={entry[1]}
                                lastElement={countValues === keyValueLength}
                            />
                        );
                    })}
                {paymentData?.paymentMethod === PaymentMethod.InApp ? (
                    <InsuranceListItem
                        placeholder="Bankkonto auswählen"
                        link={`/products/purchase/car/${CarInsuranceStep.ACCOUNT_SELECTION}`}
                        data={account}
                        disabled
                        hasIcon
                    />
                ) : (
                    <ListItemCompletion
                        key={`IBANAccountDataCompletion`}
                        primary={'IBAN'}
                        secondary={
                            paymentData?.iban
                                ? formatIBAN(paymentData?.iban)
                                : ''
                        }
                        lastElement
                    />
                )}
            </Box>
        </Box>
    );
}

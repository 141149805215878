import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {Box, Divider, Grid, Paper, useTheme} from '@material-ui/core';
import {formatDateFromString, formatDateWithTime} from '../../utils/Format';
import {
    IRecurringpayment,
    IRecurringPaymentUpdate
} from '../../../models/recurringpaymentInterface';
import {IOffer} from '../../../models/offerInterface';
import {IAccount} from '../../../models/accountInterface';
import {
    accByRecurringPaymentSelector,
    categoryByIdSelector,
    offerByIdSelector
} from '../../../store/reducers/selectors';
import {
    WgContainer,
    WgHeader,
    WgPaperList
} from '../../../styles/CustomComponents';
import CancellableContract from './Components/CancellableContract';
import CompareableContract from './Components/CompareableContract';
import MissingMeterNumberContract from './Components/MissingMeterNumberContract';
import RecurringPaymentHeader from './Components/RecurringPaymentHeader';
import {ICategory} from '../../../models/categoryInterface';
import {IUserData} from '../../../models/userInterface';
import InsuranceListItem from '../insurance/components/InsuranceListItem';

import {offerActions, recurringpaymentActions} from '../../../store/actions';
import {RecurringPaymentAmount} from './Components/RecurringPaymentAmount';
import {withRouter} from 'react-router';
import Logo from '../../utils/Logo';
import CategorySelection from '../../utils/CategorySelection';

export const energyCategories = ['Gas', 'Strom', 'Energie'];

const {
    deleteRecurringPayment,
    updateRecurringPayment
} = recurringpaymentActions;
const {fetchEnergyOffers} = offerActions;

const ENERGY_SWITCH_WORDING = [
    'Dauerhaft jährlich sparen mit nur wenigen Klicks',
    'Vollautomatischer Wechselservice'
];

interface IProps {
    location: any;
    match?: any;
    recurringPayment?: IRecurringpayment;
    account?: IAccount;
    user?: IUserData;
    fetchEnergyOffers?: Function;
    deleteRecurringPayment?: Function;
    updateRecurringPayment?: Function;
    offer?: IOffer;
}

function Recurringpayment({
    recurringPayment,
    account,
    deleteRecurringPayment,
    updateRecurringPayment,
    fetchEnergyOffers,
    offer
}: IProps) {
    const theme = useTheme();
    const [update, setUpdate] = useState<IRecurringPaymentUpdate>({
        paymentInterval: recurringPayment?.paymentInterval,
        categoryId: recurringPayment?.categoryId
    });
    const [editCategory, setEditCategory] = useState(false);

    const category: ICategory | undefined = useSelector(state =>
        categoryByIdSelector(state, {
            id: update.categoryId ?? recurringPayment?.categoryId
        })
    );

    useEffect(() => {
        if (
            typeof recurringPayment !== 'undefined' &&
            (update.paymentInterval !== recurringPayment.paymentInterval ||
                update.categoryId !== recurringPayment.categoryId)
        ) {
            updateRecurringPayment &&
                updateRecurringPayment({...recurringPayment, ...update});
        }
    }, [update, recurringPayment, updateRecurringPayment]);

    useEffect(() => {
        if (
            typeof recurringPayment !== 'undefined' &&
            energyCategories.includes(recurringPayment.categoryName) &&
            recurringPayment.changeData.changeState === 'available' &&
            typeof offer === 'undefined'
        ) {
            fetchEnergyOffers &&
                fetchEnergyOffers(Number(recurringPayment.recurringPaymentId));
        }
    }, [offer, fetchEnergyOffers, recurringPayment, category]);

    if (typeof recurringPayment === 'undefined') {
        return null; // TODO add loading skeleton
    }
    const isChangeable =
        recurringPayment?.changeData.changeState === 'available';
    let receiverKey =
        recurringPayment.lastAmount < 0 ? 'Empfänger' : 'Absender';

    const recPaymentDetails = {
        [receiverKey]:
            recurringPayment.receiverName !== ''
                ? recurringPayment.receiverName
                : undefined,
        'Letztes Buchungsdatum':
            typeof recurringPayment === 'undefined' ||
            recurringPayment.lastKnownBookingDate === '0001-01-01T00:00:00'
                ? undefined
                : formatDateFromString(recurringPayment.lastKnownBookingDate),
        'Verknüpftes Konto': account && (
            <React.Fragment key={account?.id ?? ''}>
                <Divider light />
                <InsuranceListItem
                    key={account?.id ?? 'Verknüpftes Konto'}
                    hasIcon
                    data={account}
                    link={`/accounts/details/${account?.id ?? ''}`}
                />
            </React.Fragment>
        )
    };

    return (
        <WgContainer
            bodyApp
            style={{backgroundColor: theme.palette.background.paper}}>
            <WgHeader
                headerText={
                    recurringPayment.receiverName || recurringPayment.purpose
                }
                hasBackButton
                backText="Vertragswelt"
                historyState={{redirectLink: '/recurringpayments'}}
            />
            <WgContainer
                zeroPaddingTopMobile={editCategory}
                zeroPaddingBottomMobile
                bodyContent
                disableGutters
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                {editCategory ? (
                    <CategorySelection
                        handleChange={(_: 'categoryId' | 'companyId') => (
                            value: any
                        ) => handleCategoryChange(value)}
                    />
                ) : (
                    renderContent()
                )}
            </WgContainer>
        </WgContainer>
    );

    function renderContent() {
        if (typeof recurringPayment === 'undefined') return null;
        return (
            <Grid container direction="column" wrap="nowrap" style={{flex: 1}}>
                <Grid item style={{flex: '0 1 30%'}}>
                    <Box p="0 1rem 1rem 1rem">
                        <RecurringPaymentHeader
                            object={recurringPayment}
                            category={category}
                            setEditCategory={setEditCategory}
                        />
                    </Box>
                </Grid>
                <Paper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                        flex: '1 1 0%'
                    }}>
                    <Grid
                        item
                        style={{flex: '1 1 0%'}}
                        container
                        direction="column"
                        wrap="nowrap">
                        <RecurringPaymentAmount
                            setUpdate={setUpdate}
                            chipText={
                                update?.paymentInterval ??
                                recurringPayment.paymentInterval
                            }
                            colored={isChangeable}
                            subTitle={
                                isChangeable
                                    ? 'Letzter Beitrag'
                                    : 'Abgeschlossene Transaktion'
                            }
                            amount={recurringPayment?.lastAmount}
                            info={
                                !isChangeable &&
                                typeof recurringPayment !== 'undefined' &&
                                recurringPayment.lastKnownBookingDate !==
                                    '0001-01-01T00:00:00'
                                    ? formatDateWithTime(
                                          recurringPayment?.lastKnownBookingDate
                                      )
                                    : undefined
                            }
                        />
                        <CompareableContract
                            offer={offer}
                            recurringPayment={recurringPayment}
                            content={ENERGY_SWITCH_WORDING}
                        />
                        <MissingMeterNumberContract
                            recurringPayment={recurringPayment}
                        />
                        <Divider light />
                        <WgPaperList
                            keyValueMapping={{
                                '': ['Alle Buchungen', '']
                            }}
                            link={`/recurringpayments/transactions/${recurringPayment.recurringPaymentId}`}
                        />
                        <Divider light />
                        {account && (
                            <React.Fragment>
                                <WgPaperList
                                    keyValueMapping={recPaymentDetails}
                                    link={'/accounts/details/' + account.id}
                                />
                                <Divider light />
                            </React.Fragment>
                        )}
                        <CancellableContract
                            recurringPayment={recurringPayment}
                        />
                        <InsuranceListItem
                            link=""
                            textColor="error"
                            disableStartIcon
                            placeholder="Vertrag aus Vertragswelt löschen"
                            onClick={() =>
                                deleteRecurringPayment &&
                                deleteRecurringPayment(recurringPayment)
                            }
                            hasIcon
                            icon={
                                <Logo
                                    width="42px"
                                    height="42px"
                                    defaultImage="/img/navigation/icon-trash-red.svg"
                                />
                            }
                        />
                    </Grid>
                </Paper>
            </Grid>
        );
    }

    function handleCategoryChange(value: any) {
        setUpdate({...update, categoryId: value});
        setEditCategory(false);
    }
}

function mapStateToProps(state: any, ownProps: IProps) {
    const recurringPaymentId = ownProps.match.params.recurringpaymentId;

    // return IRecurringPayment and IAccount dependent on rp
    const {recurringPayment, account} = accByRecurringPaymentSelector(state, {
        recurringPaymentId: Number(recurringPaymentId)
    });
    return {
        offer: offerByIdSelector(state, {
            recurringPaymentId: Number(recurringPaymentId) ?? 0
        }),
        recurringPayment,
        account
    };
}

export default withRouter(
    connect(mapStateToProps, {
        fetchEnergyOffers,
        deleteRecurringPayment,
        updateRecurringPayment
    })(Recurringpayment)
);

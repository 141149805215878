import React from 'react';
import {IChartData} from '../../../../../models/statistics/dashBoardInterface';
import {
    Box,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Theme,
    Typography
} from '@material-ui/core';
import {formatMoney} from '../../../../utils/Format';
import {WgTypography} from '../../../../../styles/CustomComponents';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            backgroundColor: `${theme.palette.background.paper}E0`,
            minWidth: '128px',
            maxHeight: '96px'
        },
        text: {
            fontFamily: "'Montserrat' !important",
            fontSize: '0.75rem'
        },
        header: {
            fontSize: '0.8125rem'
        },
        divider: {
            width: '1px',
            height: '1rem',
            margin: '0 6px'
        }
    })
);

interface CustomToolTipProps {
    data: IChartData[];
    active?: boolean;
    payload?: any[];
    label?: string;
    labelHint: string;
    income?: number;
    outgoing?: number;
    initialBalance: number;
}

// TODO change colors to theme colors
export function CustomToolTip(props: CustomToolTipProps) {
    const classes = useStyles();
    const {active, payload, data} = props;

    if (active && payload) {
        const currentDataPoint: IChartData | undefined = data.find(
            (element: IChartData) =>
                element.name ===
                (payload && payload.length && payload[0].payload.name)
        );
        if (typeof currentDataPoint === 'undefined') {
            return <Typography>Well well well</Typography>;
        }
        return (
            <Paper className={classes.paper} elevation={2}>
                <Box p="0.25rem">
                    <Grid wrap="nowrap" container direction="column">
                        <WgTypography
                            align="center"
                            gutterBottom
                            noWrap
                            color="textPrimary"
                            className={classes.text + ' ' + classes.header}>
                            {currentDataPoint.valueDate.toLocaleDateString(
                                'DE-de',
                                {
                                    weekday: 'short',
                                    day: '2-digit',
                                    month: 'short'
                                }
                            )}
                        </WgTypography>
                        <Grid
                            item
                            container
                            justify="space-evenly"
                            wrap="nowrap">
                            <WgTypography
                                noWrap
                                className={classes.text}
                                color="secondary">
                                {formatMoney(currentDataPoint.incomeOfDay)}
                            </WgTypography>
                            <Divider
                                light
                                orientation="vertical"
                                className={classes.divider}
                            />
                            <WgTypography
                                noWrap
                                className={classes.text}
                                color="error">
                                {formatMoney(currentDataPoint.outgoingOfDay)}
                            </WgTypography>
                        </Grid>
                        <WgTypography
                            align="center"
                            color="textPrimary"
                            className={classes.text}>
                            {formatMoney(
                                currentDataPoint.balance + props.initialBalance
                            )}
                        </WgTypography>
                    </Grid>
                </Box>
            </Paper>
        );
    }

    return null;
}

import React from 'react';
import {Grid} from '@material-ui/core';
import {ProfileListItem} from '../../../utils';
import {WgContainer, WgHeader} from '../../../../styles/CustomComponents';
import useMediaQuery from '../../../../hooks/useMediaQuery';

export default function Documents() {
    const {isUp} = useMediaQuery('md');

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Dokumente"
                hasBackButton
                backText="Mein Profil"
            />

            <WgContainer bodyContent>
                <Grid container spacing={isUp ? 2 : 0}>
                    <Grid item xs={12} md={6}>
                        <ProfileListItem
                            text="Finanzanalyse"
                            icon="/img/profile/icon-financialanalysis.svg"
                            link="/profile/financialAnalysis"
                            dividerBefore
                            dividerAfter
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProfileListItem
                            text="Maklermandat"
                            icon="/img/profile/icon-contract.svg"
                            link="/profile/brokermandate"
                            dividerAfter
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProfileListItem
                            text="Allgemeine Geschäftsbedingungen"
                            icon="/img/profile/icon-documents.svg"
                            link="/profile/terms"
                            dividerAfter
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProfileListItem
                            text="Datenschutzerklärung"
                            icon="/img/profile/icon-lock.svg"
                            link="/profile/privacy"
                            dividerAfter
                        />
                    </Grid>
                </Grid>
            </WgContainer>
        </WgContainer>
    );
}

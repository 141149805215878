import React from 'react';
import {connect, useSelector} from 'react-redux';
import {formatDateWithTime} from '../../../utils/Format';
import ContractState from '../../../utils/ContractState';
import {IExistingInsuranceProduct} from '../../../../models/productInterface';
import {IRecurringpayment} from '../../../../models/recurringpaymentInterface';
import {ICompany} from '../../../../models/companyInterface';
import {IAccount} from '../../../../models/accountInterface';
import {
    categoryByIdSelector,
    companyByIdSelector,
    productByIdSelector,
    recurringPaymentByIdSelector,
    singleAccountSelector
} from '../../../../store/reducers/selectors';
import {Box, Divider, Grid, Paper, Link} from '@material-ui/core';
import {
    WgContainer,
    WgHeader,
    WgPaperList
} from '../../../../styles/CustomComponents';
import CancellableContract from '../../recurringpayments/Components/CancellableContract';
import RecurringPaymentDetails from '../../recurringpayments/Components/RecurringPaymentDetails';
import RecurringPaymentHeader from '../../recurringpayments/Components/RecurringPaymentHeader';
import {RecurringPaymentAmount} from '../../recurringpayments/Components/RecurringPaymentAmount';
import InsuranceListItem from '../components/InsuranceListItem';
import {ICategory} from '../../../../models/categoryInterface';
import CompareableContract from '../../recurringpayments/Components/CompareableContract';
import {CarInsuranceStep} from './kfz/CarInsuranceController';
import {ProfileListItem} from '../../../utils';

const CAR_SWITCH_WORDING = [
    'Deine bisherigen Vertragskonditionen werden übernommen',
    'Spare 5 % oder wechsle in einen grünen Tarif'
];

interface ProductDetailsProps {
    product?: IExistingInsuranceProduct;
    recurringPayment?: IRecurringpayment;
    insuranceCompany?: ICompany;
    account?: IAccount;
    match?: any;
    energyPriceOffer?: any;
}

function ProductDetails({
    product,
    recurringPayment,
    account,
    insuranceCompany,
    energyPriceOffer
}: ProductDetailsProps) {
    const placeholder = '-';
    const isChangeable =
        recurringPayment?.changeData.changeState === 'available';

    const category: ICategory | undefined = useSelector(state =>
        categoryByIdSelector(state, {
            id: product?.categoryId ?? -1
        })
    );

    if (typeof product === 'undefined') return null;
    const periodParamName =
        'Versicherungsbeginn' + (product.endDate ? ' /-ende' : '');

    const insurantKey = product.insuredRisk
        ? 'Versichertes Risiko'
        : 'Versicherte(s) Person/Risiko';
    const insurantValue =
        (product.insuredRisk ?? product.insurant) || placeholder;

    let productDetails = {
        Versicherer: insuranceCompany?.name || placeholder,
        Versicherungsnummer: product.insuranceNumber || placeholder,
        'eVB-Nummer': product.vbNumber || null,
        Tarif: product.tariffName || placeholder,
        [periodParamName]: `${
            product.startDate
                ? formatDateWithTime(product.startDate, false)
                : ''
        }${(product.endDate &&
            ` - ${formatDateWithTime(product.endDate, false)}`) ||
            ''}`,
        [insurantKey]: insurantValue
    };

    function cleanKeyValueMapping(obj: any) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    cleanKeyValueMapping(productDetails);

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={product.insuranceType}
                hasBackButton
                backText="Versicherungswelt"
                historyState={{redirectLink: '/products'}}
            />
            <WgContainer
                zeroPaddingBottomMobile
                bodyContent
                disableGutters
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 0
                }}>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{flex: 1}}>
                    <Grid item style={{flex: '0 1 30%'}}>
                        <Box p="0 1rem 1rem 1rem">
                            <RecurringPaymentHeader
                                object={product}
                                category={category}
                            />
                        </Box>
                    </Grid>
                    <Paper
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            flex: '1 1 0%'
                        }}>
                        <Grid
                            item
                            style={{flex: '1 1 0%'}}
                            container
                            direction="column"
                            wrap="nowrap">
                            <ContractState state={product.status} />
                            <RecurringPaymentAmount
                                chipDisabled
                                chipText={product?.paymentInterval ?? 'MONTHLY'}
                                colored={isChangeable}
                                subTitle={
                                    recurringPayment
                                        ? 'Letzte Transaktion'
                                        : 'Beitrag'
                                }
                                amount={product?.amount}
                                info={
                                    recurringPayment
                                        ? formatDateWithTime(
                                              recurringPayment?.lastKnownBookingDate
                                          )
                                        : undefined
                                }
                            />
                            {(recurringPayment || product) && (
                                <CompareableContract
                                    recurringPayment={recurringPayment}
                                    product={product}
                                    content={CAR_SWITCH_WORDING}
                                    redirectLink={`/products/${product.id}/purchase/car/switch/${CarInsuranceStep.VEHICLE_DATA_SELECTION}`}
                                />
                            )}
                            {product.recurringPaymentId &&
                                product.recurringPayment && (
                                    <React.Fragment>
                                        <RecurringPaymentDetails
                                            recurringPayment={
                                                product.recurringPayment
                                            }
                                            changeValues={energyPriceOffer}
                                        />
                                        <Divider light />
                                        <WgPaperList
                                            keyValueMapping={{
                                                '': ['Alle Buchungen', '']
                                            }}
                                            link={`/recurringpayments/transactions/${product.recurringPayment.recurringPaymentId}`}
                                        />
                                        <Divider light />
                                    </React.Fragment>
                                )}
                            <React.Fragment>
                                <WgPaperList keyValueMapping={productDetails} />
                            </React.Fragment>
                            {product.insuranceType === 'Kfz-Versicherung' && (
                                <React.Fragment>
                                    <Link
                                        underline="none"
                                        href="tel:+4917657800298">
                                        <ProfileListItem
                                            link=""
                                            icon="/img/profile/icon-telephone.svg"
                                            text="Kundenservice anrufen"
                                            subtext="+49 176 57800298"
                                            hasIcon
                                        />
                                    </Link>
                                    <Divider light />
                                </React.Fragment>
                            )}
                            {account && (
                                <React.Fragment key={account?.id ?? ''}>
                                    <Divider light />
                                    <InsuranceListItem
                                        key={account?.id ?? 'Verknüpftes Konto'}
                                        hasIcon
                                        data={account}
                                        link={`/accounts/details/${account?.id ??
                                            ''}`}
                                    />
                                    <Divider light />
                                </React.Fragment>
                            )}
                            <CancellableContract
                                recurringPayment={product.recurringPayment}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </WgContainer>
        </WgContainer>
    );
}

function mapStateToProps(state: any, ownProps: ProductDetailsProps) {
    const productId = ownProps.match.params.productId;
    const product = productByIdSelector(state, {productId: productId});
    const recurringPayment =
        product &&
        recurringPaymentByIdSelector(state, {
            recurringPaymentId: product.recurringPaymentId
        });
    let account =
        recurringPayment &&
        singleAccountSelector(state, {
            accountId: recurringPayment.accountId || 0
        });
    let insuranceCompany = companyByIdSelector(state, {
        companyId: product?.companyId
    });

    let changeRecurringpayment = state.changeRecurringpayment?.recurringpayments.find(
        (c: {id: number}) =>
            c.id === Number(recurringPayment?.recurringPaymentId)
    );

    const energyPriceOffer =
        changeRecurringpayment && changeRecurringpayment.energyPriceOffer;

    return {
        product,
        recurringPayment,
        account,
        insuranceCompany,
        energyPriceOffer
    };
}

export default connect(mapStateToProps, {})(ProductDetails);

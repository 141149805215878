import {Box, Grid, Divider} from '@material-ui/core';
import React from 'react';
import {
    IProposalTariff,
    IPolicyData,
    ICarInsuranceVehicleDataDetails,
    CarInsuranceOneProductType,
    ICarInsuranceNoClaimDiscount
} from '../../../../../../models/productInterface';
import ListItemCompletion from './ListItemCompletion';
import {WgExpansionPanelHeader} from '../../../../../../styles/CustomComponents/WgExpansionPanel';
import {
    formatONEInsuranceOption,
    formatProductType,
    formatMoney,
    formatDateWithTime
} from '../../../../../utils/Format';
import {WgTypography} from '../../../../../../styles/CustomComponents';

export interface ContractDataCompletionProps {
    proposalTariff?: IProposalTariff;
    eco?: boolean;
    policyData?: IPolicyData;
    vehicleDataDetails?: ICarInsuranceVehicleDataDetails;
    noClaimDiscount?: ICarInsuranceNoClaimDiscount;
    location?: any;
    insurerName?: string;
    process: CarInsuranceOneProductType;
}

export default function ContractDataCompletion(
    props: ContractDataCompletionProps
) {
    const {
        policyData,
        proposalTariff,
        eco,
        vehicleDataDetails,
        noClaimDiscount,
        insurerName,
        process
    } = props;

    const startDateFormatted = vehicleDataDetails?.startDate
        ? formatDateWithTime(vehicleDataDetails?.startDate, false)
        : 'ab sofort';

    const keyValueMappingA = {
        Versicherungsbeginn: startDateFormatted,
        Versicherungsart: policyData?.productType
            ? formatProductType(policyData?.productType)
            : undefined
    };
    let countValuesA = 0;

    const keyValueMappingB: any = {
        'Andere Fahrer mitversichert':
            vehicleDataDetails?.otherDriver !== undefined
                ? vehicleDataDetails?.otherDriver
                    ? 'ja'
                    : 'nein'
                : null,
        'SF-Klasse (Schadensfreiheits-Rabatt)':
            noClaimDiscount?.noClaimDiscountValue ?? null,
        'Jährliche Kilometerleistung': vehicleDataDetails?.kmPerYear
            ? vehicleDataDetails?.kmPerYear + ' km'
            : null,
        Selbstbeteiligung: getDeductible(policyData)
    };

    let keyValueLengthB = Object.keys(keyValueMappingB).length;
    Object.keys(keyValueMappingB).forEach((entry: any) => {
        if (
            keyValueMappingB[entry] === null ||
            keyValueMappingB[entry] === undefined
        )
            keyValueLengthB--;
    });
    let countValuesB = 0;

    return (
        <Box margin="0 1rem 1rem 1rem">
            <Box>
                {keyValueMappingA &&
                    Object.entries(keyValueMappingA).map((entry: any) => {
                        if (entry[1] !== null && entry[1] !== undefined) {
                            countValuesA = countValuesA + 1;
                            return (
                                <ListItemCompletion
                                    key={`${countValuesA}ContractDataCompletion`}
                                    primary={entry[0]}
                                    secondary={entry[1]}
                                />
                            );
                        }
                        return null;
                    })}
            </Box>
            <Grid item xs={12} style={{marginLeft: '-1rem', paddingTop: 0}}>
                <WgExpansionPanelHeader
                    title={formatONEInsuranceOption(process, eco)}
                    subTitle={insurerName}
                    headerImage="/img/one-insurance.webp"
                />
            </Grid>
            <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1rem">
                <WgTypography color="textSecondary" text="subContent">
                    Gesamtkosten
                </WgTypography>
                <WgTypography money>
                    {formatMoney(proposalTariff?.tariffWithTax) + ' / Jahr'}
                </WgTypography>
            </Box>
            {countValuesB !== 0 && (
                <Box>
                    <Divider light />
                </Box>
            )}
            <Box>
                {keyValueMappingB &&
                    Object.entries(keyValueMappingB).map((entry: any) => {
                        if (entry[1] !== null && entry[1] !== undefined) {
                            countValuesB = countValuesB + 1;
                            return (
                                <ListItemCompletion
                                    key={`${countValuesB}ContractDataCompletion`}
                                    primary={entry[0]}
                                    secondary={entry[1]}
                                    lastElement={
                                        countValuesB === keyValueLengthB
                                    }
                                />
                            );
                        }
                        return null;
                    })}
            </Box>
        </Box>
    );

    function getDeductible(policyData?: IPolicyData) {
        if (policyData?.productType === 'fully-comprehensive')
            return formatMoney(policyData.deductibleFull);
        else if (policyData?.productType === 'partial-comprehensive')
            return formatMoney(policyData.deductiblePartial);
        else if (policyData?.productType === 'vehicle-liability') return null;

        return null;
    }
}

import React, {useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {
    CarInsuranceOneProductType,
    ICarInsuranceOneProduct
} from '../../../../../models/productInterface';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgPaperArea
} from '../../../../../styles/CustomComponents';
import OneProductType from './directSale/OneProductType';
import {CarInsuranceStep} from './CarInsuranceController';

interface CarInsuranceOneProductProps {
    oneProduct: ICarInsuranceOneProduct;
}

export default function CarInsuranceOneProduct(
    props: CarInsuranceOneProductProps
) {
    const [oneProductType, setOneProductType] = useState<
        ICarInsuranceOneProduct
    >({
        ...props.oneProduct
    });

    const handleChange = (name: keyof ICarInsuranceOneProduct) => (
        value: CarInsuranceOneProductType | any
    ) => {
        setOneProductType(insuranceTypeState => ({
            ...insuranceTypeState,
            [name]: value instanceof Object ? value.target.value : value
        }));
    };

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Kfz-Versicherung"
                hasBackButton
                backText="Versicherungsauswahl"
            />
            <WgContainer bodyContent disableGutters={false}>
                <Grid
                    container
                    item
                    direction="column"
                    //TODO this depends on WgContainer padding bottom - needs refactoring because ::after is garbage
                    style={{
                        height: 'calc(100% - 1.5rem)',
                        minHeight: 'fit-content'
                    }}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '1 1 80%', minHeight: 'fit-content'}}
                        container
                        justify="center"
                        wrap="nowrap">
                        <WgPaperArea
                            display="flex"
                            alignItems="center"
                            header="Was möchtest du tun?"
                            elevation={1}
                            paperStyle={{padding: '1rem 0.5rem'}}>
                            <OneProductType
                                carInsuranceOneProduct={oneProductType}
                                handleChange={handleChange}
                            />
                        </WgPaperArea>
                    </Grid>
                    <Grid
                        item
                        style={{flex: 'auto'}}
                        container
                        direction="column"
                        justify="center">
                        <Box
                            display="flex"
                            flexWrap="nowrap"
                            flex={1}
                            flexDirection={{xs: 'column', md: 'row'}}
                            justifyContent={{
                                xs: 'flex-end',
                                md: 'space-between'
                            }}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                maxHeight="fit-content"
                                alignItems={{xs: 'flex-end', md: 'center'}}
                                flex={1}
                                marginBottom={{xs: '1rem', md: 0}}
                                order={{xs: 1, md: 2}}>
                                <WgButton
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    aria-label="next"
                                    link={`/products/purchase/car/${CarInsuranceStep.VEHICLE_DATA_SELECTION}`}>
                                    Weiter
                                </WgButton>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center"
                                maxHeight="fit-content"
                                alignItems={{xs: 'flex-start', md: 'center'}}
                                flex={1}
                                order={{xs: 2, md: 1}}>
                                <WgButton
                                    variant="contained"
                                    color="default"
                                    fullWidth
                                    aria-label="skip"
                                    link="/products/new">
                                    Abbrechen
                                </WgButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        </WgContainer>
    );
}

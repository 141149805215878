import React from 'react';
import {Box, Paper} from '@material-ui/core';
import {connect} from 'react-redux';
import {AmountForm, TransactionListItem} from '../../utils';
import {formatDateFromString} from '../../utils/Format';
import {ITransaction} from '../../../models/transactionInterface';
import {
    makeAllTransactionsFromRecurringpaymentSelector,
    recurringPaymentByIdNameSelector,
    recurringPaymentByIdSelector
} from '../../../store/reducers/selectors';
import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../styles/CustomComponents';

// @ts-ignore
export default ({match}) => (
    <ConnectRecurringpayment
        recurringPaymentId={Number(match.params.recurringpaymentId)}
    />
);

interface IProps {
    recurringPaymentId: number;
    recurringpaymentName?: string;
    balance?: number;
    transactions?: ITransaction[];
    fetchAllRecurringpayments?: Function;
}

function RecurringpaymentTurnovers({
    recurringPaymentId,
    balance,
    transactions,
    recurringpaymentName
}: IProps) {
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={recurringpaymentName}
                hasBackButton
                backText="Details"
            />
            {transactions && transactions.length > 0 ? (
                <WgContainer bodyContent disableGutters>
                    {balance && (
                        <AmountForm
                            m="1.5rem 1.25rem 3rem 1.25rem"
                            amount={balance}
                            text="Gesamte Buchung"
                            isColored
                        />
                    )}
                    <Paper elevation={1}>
                        {transactions.map(mapRecurringpayment)}
                    </Paper>
                </WgContainer>
            ) : (
                renderNoTransactionsFound()
            )}
        </WgContainer>
    );

    function renderNoTransactionsFound() {
        return (
            <WgContainer
                bodyContent
                disableGutters={false}
                style={{display: 'flex'}}>
                <Box
                    flex={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <WgTypography
                        align="center"
                        color="textSecondary"
                        text="info">
                        Keine Transaktionen erkannt.
                    </WgTypography>
                </Box>
            </WgContainer>
        );
    }

    function mapRecurringpayment(transaction: ITransaction) {
        return (
            <TransactionListItem
                key={String(transaction.id)}
                link={`/recurringpayments/transactions/${recurringPaymentId}/${transaction.id}`}
                categoryId={transaction.categoryId}
                logoPath={transaction.logoPath}
                defaultImage={
                    transaction.amount < 0
                        ? '/img/sending.svg'
                        : '/img/receiving.svg'
                }
                header={
                    transaction.counterpartName
                        ? transaction.counterpartName
                        : transaction.purpose
                }
                subHeader={formatDateFromString(transaction.valueDate)}
                amount={transaction.amount}
            />
        );
    }
}

function makeMapStateToProps() {
    const transactionsFromAccountSelector = makeAllTransactionsFromRecurringpaymentSelector();
    return mapStateToProps;

    function mapStateToProps(state: any, ownProps: IProps) {
        const recurringpayment = recurringPaymentByIdSelector(state, ownProps);

        let accountIds = recurringpayment ? [recurringpayment.accountId] : [];
        let transactionIds = recurringpayment
            ? recurringpayment.transactionIds
            : [];

        const recurringpaymentName = recurringPaymentByIdNameSelector(
            state,
            ownProps
        );
        const transactions = transactionsFromAccountSelector(state, {
            selectedAccounts: accountIds,
            transactionIds: transactionIds ?? []
        }).reverse();

        const balance =
            (transactions.length > 0 &&
                transactions.reduce(
                    (prev: number, transaction: ITransaction) =>
                        transaction.amount + prev,
                    0
                )) ||
            undefined;

        return {
            recurringpaymentName,
            transactions,
            balance
        };
    }
}

const ConnectRecurringpayment = connect(
    makeMapStateToProps,
    {}
)(RecurringpaymentTurnovers);

import React from 'react';
import {connect} from 'react-redux';
import {
    categoryByIdSelector,
    singleAccountSelector,
    singleTransactionByIdSelector
} from '../../../store/reducers/selectors';
import {ITurnoverProps, Turnover} from './Turnover';

// @ts-ignore
export default ({match}) => (
    <ConnectTurnover
        accountId={Number(match.params.account)}
        turnoverID={Number(match.params.turnover)}
    />
);

function makeMapStateToProps() {
    return (state: any, ownProps: ITurnoverProps) => {
        const turnover = singleTransactionByIdSelector(state, {
            ...ownProps,
            selectedAccounts: [ownProps.accountId]
        }) || {categoryId: 0};
        const category = categoryByIdSelector(state, {id: turnover.categoryId});

        return {
            account: singleAccountSelector(state, ownProps),
            turnover: turnover,
            categoryName: category && category.name
        };
    };
}

const ConnectTurnover = connect(
    makeMapStateToProps,
    {}
    //@ts-ignore
)(Turnover);

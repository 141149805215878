import React from 'react';
import {createStyles, Theme, withStyles} from '@material-ui/core';
import {Skeleton, SkeletonProps} from '@material-ui/lab';
import clsx from 'clsx';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '10px',
            backgroundColor: theme.palette.gray.light,
            '&::after': {
                background: `linear-gradient(90deg, transparent, ${theme.palette.gray.veryLight}, transparent)`
            }
        }
    });

export interface WgSkeletonProps extends SkeletonProps {
    classes: any;
}

function WgSkeleton({classes, className, ...other}: WgSkeletonProps) {
    return <Skeleton className={clsx(classes['root'], className)} {...other} />;
}

export default withStyles(styles)(WgSkeleton);

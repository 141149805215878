import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {formatMoney, formatPercent} from '../../utils/Format';
import {AmountForm, DepotListItem} from '../../utils';
import {IAccount} from '../../../models/accountInterface';
import {ISecurity} from '../../../models/securityInterface';
import {
    allSecuritiesByAccountIdSelector,
    singleAccountSelector
} from '../../../store/reducers/selectors';
import {WgContainer, WgHeader} from '../../../styles/CustomComponents';

import {accountActions} from '../../../store/actions';
import useMediaQuery from '../../../hooks/useMediaQuery';

const {fetchDepotFromAccountId} = accountActions;

interface IProps {
    account?: IAccount;
    securities?: ISecurity[];
    fetchDepotFromAccountId?: Function;
    match: any;
}

function Depot({fetchDepotFromAccountId, account, ...props}: IProps) {
    const {isUp} = useMediaQuery('md');

    useEffect(() => {
        fetchDepotFromAccountId && fetchDepotFromAccountId(account?.id);
    }, [fetchDepotFromAccountId, account]);

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={account?.name ?? ''}
                hasBackButton
                backText="Meine Konten"
            />

            <WgContainer bodyContent disableGutters={false}>
                {account?.balance && (
                    <AmountForm
                        m="1.5rem 1.25rem 3rem 1.25rem"
                        amount={account.balance}
                        text={calculateAmountText()}
                        isColored
                    />
                )}
                <Grid container spacing={isUp ? 2 : 0}>
                    {props.securities?.map(mapDepotListItem)}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function mapDepotListItem(depotItem: ISecurity) {
        return (
            <Grid
                item
                xs={12}
                md={6}
                key={depotItem.securityId + depotItem.isin + depotItem.name}>
                <DepotListItem
                    key={depotItem.securityId}
                    header={depotItem.name || depotItem.currency}
                    amountMultiple={formatMoney(
                        depotItem.totalCurrentValue,
                        depotItem.currency
                    )}
                    amountSingleStock={formatMoney(
                        depotItem.currentValuePerUnit,
                        depotItem.currency
                    )}
                    countMultiple={
                        Math.round(depotItem.currentUnitCount * 100) / 100
                    }
                    percentageDifference={depotItem.percentDifference}
                />
            </Grid>
        );
    }

    function calculateAmountText() {
        let amountText = '';
        if (typeof account !== 'undefined') {
            const amount =
                typeof account.absoluteDifference !== 'undefined'
                    ? formatMoney(
                          account.absoluteDifference,
                          props.securities && props.securities?.length > 0
                              ? props.securities[0].currency
                              : 'EUR'
                      )
                    : '';
            amountText += amount;
            const percent =
                typeof account.percentageDifference !== 'undefined'
                    ? formatPercent(account.percentageDifference)
                    : '';
            amountText += ' | ' + percent;
        }
        return amountText;
    }
}

function mapStateToProps(state: any, ownProps: IProps) {
    let accountId = Number(ownProps.match.params.depot);
    return {
        account: singleAccountSelector(state, {accountId}),
        securities: allSecuritiesByAccountIdSelector(state, {
            accountId: accountId
        })
    };
}

export default connect(mapStateToProps, {fetchDepotFromAccountId})(Depot);

import React, {useEffect, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {Redirect} from 'react-router';
import {IEnergyOfferContractValidation} from '../../../../../models/offerInterface';
import {IContactData} from '../../../../../models/userInterface';
import {
    ContractDataInputs,
    DeliveryDataInputs,
    LinkedAccountInputs,
    PersonalDataInputs
} from '../Components';
import {
    WgExpansionPanel,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {
    formatEcoOption,
    formatGender,
    formatMoney
} from '../../../../utils/Format';

declare type ContactType =
    | 'customer'
    | 'delivery'
    | 'accountHolder'
    | 'billing';

function contactInputFilled(
    name: ContactType,
    state?: IEnergyOfferContractValidation
): boolean {
    if (typeof state === 'undefined') return false;
    let validationFields: (keyof IContactData)[] = [];
    switch (name) {
        case 'customer':
            validationFields = [
                'firstName',
                'lastName',
                'dateOfBirth',
                'email',
                'telephoneNumber'
            ];
            break;
        case 'accountHolder':
            validationFields = ['iban', 'firstName', 'lastName'];
            break;
        case 'delivery':
        case 'billing':
            validationFields = [
                'firstName',
                'lastName',
                'street',
                'houseNumber',
                'postcode',
                'city'
            ];
            break;
    }
    if (state?.isCommercial && name !== 'billing')
        validationFields.push('companyName');
    return validationFields.every(
        (key: keyof IContactData) =>
            typeof state !== 'undefined' &&
            typeof state[name] !== 'undefined' &&
            typeof state[name][key] !== 'undefined' &&
            state[name][key] !== 'undefined' &&
            state[name][key] !== ''
    );
}

function allInputsFilled(
    hasOtherBilling: boolean,
    state?: IEnergyOfferContractValidation
) {
    if (typeof state === 'undefined') return false;
    let validationFields: ContactType[] = [
        'customer',
        'accountHolder',
        'delivery'
    ];
    if (hasOtherBilling) validationFields.push('billing');
    return validationFields.every((name: ContactType) =>
        contactInputFilled(name, state)
    );
}

interface ContractCompletionProps {
    contractData?: IEnergyOfferContractValidation;
    setContractData: React.Dispatch<
        React.SetStateAction<IEnergyOfferContractValidation | undefined>
    >;
    fetchRequiredInformations?: Function;
    handleChange: (
        name: keyof IEnergyOfferContractValidation | undefined
    ) => (event: React.ChangeEvent<any> | Date) => void;
    setInputValid: Function;
    inputValid: boolean;
}

interface EnergyExpansionPanelData {
    title: string;
    subTitle: string;
    headerImage: string;
    name?: ContactType;
    content: React.ReactNode;
}

export default function ContractCompletion({
    handleChange,
    setInputValid,
    inputValid,
    contractData,
    setContractData,
    ...props
}: ContractCompletionProps) {
    const {delivery} = contractData ?? {};

    const [hasOtherBilling, setHasOtherBilling] = useState(false);

    useEffect(() => {
        setInputValid(allInputsFilled(hasOtherBilling, contractData));
    }, [contractData, hasOtherBilling, setInputValid]);

    useEffect(() => {
        // billing = delivery if hasOtherBilling or delivery changes
        if (!hasOtherBilling) {
            setContractData(contractData =>
                typeof contractData !== 'undefined'
                    ? {...contractData, billing: {...contractData?.delivery}}
                    : undefined
            );
        }
    }, [hasOtherBilling, delivery, setContractData]);

    if (typeof contractData === 'undefined') {
        // TODO: this shouldn't really be the case so handle error gracefully
        return <Redirect to={'/recurringpayments'} />;
    }

    const expansionPanelsData: EnergyExpansionPanelData[] = [
        {
            title: 'Persönliche Daten',
            subTitle: getPersonalDataString(),
            headerImage: '/img/recurringpayment/icon-user-circle.svg',
            name: 'customer',
            content: (
                <PersonalDataInputs
                    advanced
                    {...contractData?.customer}
                    isCommercial={contractData?.isCommercial}
                    handleChange={handleChange('customer')}
                />
            )
        },
        {
            title: 'Vertragsdetails',
            subTitle: getContractDetailString(),
            headerImage:
                '/img/recurringpayment/icon-recurringpayment-circle.svg',
            content: (
                <ContractDataInputs
                    {...contractData}
                    handleChange={handleChange(undefined)}
                />
            )
        },
        {
            title: 'Lieferstelle',
            subTitle: hasOtherBilling
                ? getDeliveryDataString(contractData.billing)
                : getDeliveryDataString(contractData?.delivery),
            headerImage: '/img/recurringpayment/icon-house-circle.svg',
            name: hasOtherBilling ? 'billing' : 'delivery',
            content: (
                <DeliveryDataInputs
                    hasOtherBilling={hasOtherBilling}
                    setHasOtherBilling={setHasOtherBilling}
                    delivery={contractData?.delivery}
                    billing={contractData?.billing}
                    isCommercial={contractData?.isCommercial}
                    handleChange={handleChange('delivery')}
                    handleBillingChange={handleChange('billing')}
                />
            )
        },
        {
            title: 'Verknüpftes Konto',
            subTitle: getAccountHolderDataString(),
            headerImage: '/img/recurringpayment/icon-bankingcard-circle.svg',
            name: 'accountHolder',
            content: (
                <LinkedAccountInputs
                    {...contractData?.accountHolder}
                    isCommercial={contractData?.isCommercial}
                    handleChange={handleChange('accountHolder')}
                />
            )
        }
    ];

    return (
        <Box paddingX={{xs: '1rem', md: 0}} width="100%">
            <Box padding={{xs: '0 1rem 1rem 1rem', md: '0 0 1rem 0'}}>
                <WgTypography color="primary" text="contentInfo" align="center">
                    Bitte überprüfe zum Abschluss deine Daten:
                </WgTypography>
            </Box>
            <Grid container spacing={1}>
                {expansionPanelsData.map(renderExpansionPanel)}
            </Grid>

            {!inputValid && (
                <WgTypography
                    gutterTop="0.5rem"
                    gutterBottom
                    align="center"
                    color="error"
                    text="subContent">
                    Bitte überprüfe deine Daten auf Vollständigkeit.
                </WgTypography>
            )}
        </Box>
    );

    function renderExpansionPanel(item: any) {
        const {content, ...other} = item;
        return (
            <Grid key={item.title} item xs={12}>
                <WgExpansionPanel
                    defaultExpanded={
                        item.name &&
                        !contactInputFilled(item.name, contractData)
                    }
                    key={other.title}
                    {...other}>
                    {content}
                </WgExpansionPanel>
            </Grid>
        );
    }

    function getPersonalDataString() {
        const {
            companyName,
            firstName,
            lastName,
            gender,
            email,
            telephoneNumber
        } = contractData?.customer ?? {};

        return `${companyName ? companyName + ' | ' : ''} ${
            gender ? formatGender(gender, true) : ''
        } ${firstName} ${lastName} ${email && ' | ' + email} ${
            telephoneNumber ? ' | ' + telephoneNumber : ''
        }`;
    }

    function getContractDetailString() {
        return `${formatEcoOption(contractData?.eco)} | ${
            contractData?.betterProviderName
        } | Jährliche Kosten: ${formatMoney(
            contractData?.betterProviderYearlyCosts
        )} | Monatliche Kosten: ${formatMoney(
            contractData?.betterProviderMonthlyCosts
        )}`;
    }

    function getDeliveryDataString(data: IContactData) {
        const {
            gender,
            firstName,
            lastName,
            companyName,
            street,
            houseNumber,
            city,
            postcode
        } = data ?? {};
        return `${
            contractData?.isCommercial && companyName ? companyName + ' | ' : ''
        } ${gender && formatGender(gender, true)} ${firstName} ${lastName} ${
            street ? ' | ' + street : ''
        } ${houseNumber ? houseNumber + ', ' : ''} ${city ?? ''} ${postcode ??
            ''}`;
    }

    function getAccountHolderDataString() {
        const {companyName, firstName, lastName, iban} =
            contractData?.accountHolder ?? {};
        return `${
            contractData?.isCommercial && companyName ? companyName + ' | ' : ''
        } ${firstName} ${lastName} ${iban ? ' | ' + iban : ''}`;
    }
}

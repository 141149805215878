import React from 'react';

export function CustomTick(props: any) {
    const {x, y, payload, fill, currentDate} = props;
    const myDate = currentDate ? currentDate : new Date();
    myDate.setDate(parseInt(payload.value));

    if (myDate.getDay() === 0) {
        return (
            <React.Fragment>
                <circle
                    cx={x}
                    cy={y}
                    r={2}
                    fill={'#ffffff'}
                    stroke={fill}
                    strokeWidth="2"
                />
                <text
                    x={x}
                    y={y}
                    dy={20}
                    fill={fill}
                    fontSize={12}
                    textAnchor="middle">
                    {`${payload.value}`}
                </text>
            </React.Fragment>
        );
    } else {
        return <circle cx={x} cy={y} r={1} fill={fill} />;
    }
}

import React from 'react';
import PrivacyText from './PrivacyText';
import {WgContainer, WgHeader} from '../../../styles/CustomComponents';

export default function PrivacyLoggedIn() {
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Datenschutzerklärung"
                hasBackButton
                backText="Dokumente"
            />
            <WgContainer bodyContent disableGutters={false}>
                <PrivacyText />
            </WgContainer>
        </WgContainer>
    );
}

import MaskedInput from 'react-text-mask';
import * as React from 'react';

export interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

export default function TextMaskCustom(props: TextMaskCustomProps) {
    const {inputRef, ...other} = props;
    const mask = new Array(6).fill(/\d/);
    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={'\u2000'}
            guide={false}
        />
    );
}

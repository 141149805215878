import React, {useCallback, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
    WgButton,
    WgContainer,
    WgHeader
} from '../../../../../styles/CustomComponents';
import {Box, Grid} from '@material-ui/core';
import {push} from 'connected-react-router';
import {useHistory, useLocation, useParams} from 'react-router';
import {setNotification} from '../../../../../store/actions/notificationActions';
import {
    allCompaniesByTypeSelector,
    getCarInsuranceOptions,
    getVehicleData,
    getVehicleHolderData
} from '../../../../../store/reducers/selectors';
import {
    CarInsuranceOneProductType,
    getCarInsuranceWording,
    ICarInsuranceNoClaimDiscount,
    ICarInsuranceOptions,
    ICarInsurancePaymentAccount,
    ICarInsuranceVehicleDataDetails,
    IPolicyData,
    IPolicyFormData,
    IProposal,
    IProposalTariff,
    IVehicleData,
    IVehicleHolder,
    PaymentMethod
} from '../../../../../models/productInterface';
import AccountSelectionOne from '../../../../utils/AccountSelectionOne';
import {
    CarInsuranceCompletion,
    CarInsuranceNecessaryData,
    CarInsuranceNoClaimDiscount,
    CarInsuranceOfferSelection,
    CarInsurancePayment,
    CarInsuranceProductType,
    VehicleHolder
} from '../../..';
import VehicleData from './VehicleData';
import {
    getCarInsurancePayment,
    getCarInsuranceProposal,
    getNoClaimDiscount,
    getPolicyData,
    getVehicleDataDetails,
    policyFormDataSelector
} from '../../../../../store/reducers/selectors/carInsuranceSelector';
import {existsBrokermandate} from '../../../../../store/reducers/selectors/userSelector';
import {CompanyType, ICompany} from '../../../../../models/companyInterface';
import {
    fetchCarInsuranceVehicleHolderData,
    getCarInsuranceProposals,
    setCarInsuranceManualPayment,
    submitCarInsuranceRequest,
    updateCalculationId,
    updateCarInsuranceNoClaimDiscount,
    updateCarInsurancePolicyData,
    updateCarInsuranceVehicleData,
    updateCarInsuranceVehicleDataDetails,
    updateCarInsuranceVehicleHolder
} from '../../../../../store/actions/productActions';
import BrokerMandateEdit from '../../../profile/documents/BrokerMandateEdit';
import BrokerMandateNoMandateExistsText from '../../../profile/documents/BrokerMandateNoMandateExistsText';
import {fetchRequiredInformations} from '../../../../../store/actions/userActions';
import CarInsuranceNoClaimDiscountSelection from './directSale/CarInsuranceNoClaimDiscountSelection';
import {IAccount} from '../../../../../models/accountInterface';
import CompanySelection from '../../../../utils/CompanySelection';

export enum CarInsuranceStep {
    VEHICLE_DATA_SELECTION,
    VEHICLE_HOLDER_SELECTION,
    NECESSARY_DATA_SELECTION,
    NO_CLAIM_DISCOUNT,
    CAR_PRODUCT_TYPE_SELECTION,
    OFFER_SELECTION,
    PAYMENT_SELECTION,
    DATA_VALIDATION,
    NO_CLAIM_DISCOUNT_SELECTION,
    PREINSUREER_SELECTION,
    ACCOUNT_SELECTION
}

export interface CarInsuranceControllerProps {
    push: Function;
    formData: IPolicyFormData;
    policyData: IPolicyData;
    vehicleHolder: IVehicleHolder;
    options: ICarInsuranceOptions;
    setCarInsuranceManualPayment: Function;
    carInsuranceVehicleDataDetails: ICarInsuranceVehicleDataDetails;
    carInsuranceNoClaimDiscount: ICarInsuranceNoClaimDiscount;
    carInsurancePaymentAccount: ICarInsurancePaymentAccount;
    updateCarInsuranceNoClaimDiscount?: Function;
    updateCarInsurancePolicyData?: Function;
    updateCarInsuranceVehicleDataDetails?: Function;
    updateCarInsuranceVehicleData?: Function;
    updateCarInsuranceVehicleHolder?: Function;
    updateCalculationId?: Function;
    fetchCarInsuranceVehicleHolderData: Function;
    getCarInsuranceProposals?: Function;
    fetchRequiredInformations?: Function;
    vehicle: IVehicleData;
    proposalData: IProposal;
    hasBrokermandate: boolean;
    submitCarInsuranceRequest: Function;
    allCompanies: ICompany[];
}

function CarInsuranceController({
    push,
    options,
    fetchRequiredInformations,
    carInsuranceNoClaimDiscount,
    carInsuranceVehicleDataDetails,
    carInsurancePaymentAccount,
    fetchCarInsuranceVehicleHolderData,
    updateCarInsuranceNoClaimDiscount,
    updateCarInsurancePolicyData,
    updateCarInsuranceVehicleDataDetails,
    updateCarInsuranceVehicleData,
    updateCarInsuranceVehicleHolder,
    setCarInsuranceManualPayment,
    updateCalculationId,
    vehicleHolder,
    vehicle,
    policyData,
    proposalData,
    formData,
    hasBrokermandate,
    getCarInsuranceProposals,
    allCompanies,
    submitCarInsuranceRequest
}: CarInsuranceControllerProps) {
    const {currentStep} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const activeStep: CarInsuranceStep = currentStep
        ? Number(currentStep)
        : CarInsuranceStep.VEHICLE_DATA_SELECTION;
    const location = useLocation();

    const [isInit, setIsInit] = useState(true);

    const [vehicleData, setVehicleData] = useState<IVehicleData>(vehicle);
    const [vehicleHolderData, setVehicleHolderData] = useState<IVehicleHolder>(
        vehicleHolder
    );
    const [vehicleDataDetails, setVehicleDataDetails] = useState<
        ICarInsuranceVehicleDataDetails
    >(carInsuranceVehicleDataDetails);
    const [paymentData, setPaymentData] = useState<ICarInsurancePaymentAccount>(
        {
            ...carInsurancePaymentAccount,
            paymentMethod: PaymentMethod.InApp
        }
    );
    const [selectedAccount, setSelectedAccount] = useState<
        IAccount | undefined
    >(undefined);

    const [noClaimDiscount, setNoClaimDiscount] = useState<
        ICarInsuranceNoClaimDiscount
    >(carInsuranceNoClaimDiscount);

    const [routerHistoryIndex, setRouterHistoryIndex] = useState<number>();

    const [formDataScope] = useState<IPolicyFormData>(formData);
    const [proposalTariff, setProposalTarif] = useState<IProposalTariff>(
        proposalData.normal
    );
    const [eco, setEco] = useState<boolean>(false);
    const [policyDataScope, setPolicyData] = useState<IPolicyData>(policyData);
    const [, setProposal] = useState<IProposal>(proposalData);

    const [isValid, setIsValid] = useState(true);
    const [validationEnabled, setValidationEnabled] = useState(false);

    const saveRouterIndex = useCallback(() => {
        setRouterHistoryIndex(history.length + 2);
    }, [setRouterHistoryIndex, history]);

    function handleValidation(isValid: boolean) {
        setIsValid(isValid);
    }

    const handlePreInsurerSelection = (
        name: 'categoryId' | 'companyId' | any
    ) => (companyId: any) => {
        setNoClaimDiscount({
            ...noClaimDiscount,
            preInsurer: String(companyId)
        });
        handleBack();
    };

    useEffect(() => {
        if (isInit) {
            fetchCarInsuranceVehicleHolderData &&
                fetchCarInsuranceVehicleHolderData();
            saveRouterIndex();
            setIsInit(false);
        }
    }, [
        fetchCarInsuranceVehicleHolderData,
        isInit,
        setIsInit,
        saveRouterIndex
    ]);

    useEffect(() => {
        if (!hasBrokermandate) {
            fetchRequiredInformations && fetchRequiredInformations();
        }
    }, [hasBrokermandate, fetchRequiredInformations]);

    const steps = [
        <VehicleData
            validationCallback={handleValidation}
            vehicleData={vehicleData}
            setVehicleData={setVehicleData}
            validationEnabled={validationEnabled}
        />,
        <VehicleHolder
            options={options}
            validationCallback={handleValidation}
            vehicleHolderData={vehicleHolderData}
            setVehicleHolderData={setVehicleHolderData}
            validationEnabled={validationEnabled}
        />,
        <CarInsuranceNecessaryData
            validationCallback={handleValidation}
            carInsuranceVehicleDataDetails={vehicleDataDetails}
            updateCarInsuranceVehicleDataDetails={setVehicleDataDetails}
            validationEnabled={validationEnabled}
        />,
        <CarInsuranceNoClaimDiscount
            setIsValid={handleValidation}
            carInsuranceNoClaimDiscount={noClaimDiscount}
            updateCarInsuranceNoClaimDiscount={setNoClaimDiscount}
            getInsurerDataForListItem={getInsurerDataForListItem}
            validationEnabled={validationEnabled}
        />,
        <CarInsuranceProductType
            formData={formDataScope}
            policyData={policyDataScope}
            updatePolicyData={setPolicyData}
            validationCallback={handleValidation}
            validationEnabled={validationEnabled}
        />,
        <CarInsuranceOfferSelection
            proposal={proposalData}
            setProposalTarif={setProposalTarif}
            setProposal={setProposal}
            setEco={setEco}
            updateCalculationId={updateCalculationId}
            vehicleData={vehicleData}
            policyData={policyDataScope}
            setPolicyData={setPolicyData}
            updateCarInsurancePolicyData={updateCarInsurancePolicyData}
            productType={policyDataScope.productType}
            redirectLink={`/products/purchase/car/${CarInsuranceStep.PAYMENT_SELECTION}`}
            process={CarInsuranceOneProductType.NEW_VEHICLE}
        />,
        <CarInsurancePayment
            setIsValid={handleValidation}
            paymentData={paymentData}
            setPaymentData={setPaymentData}
            selectedAccount={selectedAccount}
            validationEnabled={validationEnabled}
        />,
        <CarInsuranceCompletion
            vehicleData={vehicleData}
            vehicleHolderData={vehicleHolderData}
            hasBrokermandate={hasBrokermandate}
            carInsuranceVehicleDataDetails={vehicleDataDetails}
            noClaimDiscount={noClaimDiscount}
            policyData={policyDataScope}
            fetchRequiredInformations={fetchRequiredInformations}
            eco={eco}
            proposalTariff={proposalTariff}
            paymentData={paymentData}
            location={location}
            selectedAccount={selectedAccount}
            process={CarInsuranceOneProductType.NEW_VEHICLE}
        />,
        <CarInsuranceNoClaimDiscountSelection
            handleSelection={handleNoClaimDiscountSelection}
        />,
        <CompanySelection
            handleChange={handlePreInsurerSelection}
            searchType={CompanyType.INSURANCE}
        />,
        <AccountSelectionOne handleChange={handleAccountSelection} />
    ];

    if (!hasBrokermandate && activeStep === CarInsuranceStep.DATA_VALIDATION) {
        return (
            <BrokerMandateEdit backNavigationOnSave={false}>
                <BrokerMandateNoMandateExistsText />
            </BrokerMandateEdit>
        );
    }

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={getCarInsuranceWording(activeStep)}
                save={handleSubmit}
                hasBackButton
                backText={getCarInsuranceWording(activeStep - 1)}
            />
            <WgContainer bodyContent disableGutters={false}>
                <Grid
                    container
                    item
                    direction="column"
                    //TODO this depends on WgContainer padding bottom - needs refactoring because ::after is garbage
                    style={{
                        height: 'calc(100% - 1.5rem)',
                        minHeight: 'fit-content'
                    }}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '1 1 80%', minHeight: 'fit-content'}}
                        container
                        justify="center"
                        wrap="nowrap">
                        {steps[activeStep]}
                    </Grid>
                    {showBaseButtons() && (
                        <Grid
                            item
                            style={{flex: 'auto'}}
                            container
                            direction="column"
                            justify="center">
                            <Box
                                display="flex"
                                flexWrap="nowrap"
                                paddingTop={{xs: '1rem', md: '1rem'}}
                                flex={1}
                                flexDirection={{xs: 'column', md: 'row'}}
                                justifyContent={{
                                    xs: 'flex-end',
                                    md: 'space-between'
                                }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-end',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    marginBottom={{xs: '1rem', md: 0}}
                                    order={{xs: 1, md: 2}}>
                                    <WgButton
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={validationEnabled && !isValid}
                                        onClick={handleSubmit}
                                        aria-label="next">
                                        {activeStep ===
                                        CarInsuranceStep.DATA_VALIDATION
                                            ? 'Kostenpflichtig abschließen'
                                            : 'Weiter'}
                                    </WgButton>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-start',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    order={{xs: 2, md: 1}}>
                                    <WgButton
                                        onClick={handleCancel}
                                        variant="contained"
                                        color="default"
                                        fullWidth
                                        aria-label="back">
                                        {'Abbrechen'}
                                    </WgButton>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function showBaseButtons(): boolean {
        switch (activeStep) {
            case CarInsuranceStep.OFFER_SELECTION:
            case CarInsuranceStep.NO_CLAIM_DISCOUNT_SELECTION:
            case CarInsuranceStep.PREINSUREER_SELECTION:
            case CarInsuranceStep.ACCOUNT_SELECTION:
                return false;
            default:
                return true;
        }
    }

    function handleCancel() {
        dispatch(
            setNotification({
                title: 'Bist du dir sicher?',
                text:
                    'Du musst diese Fragen nur einmalig beantworten! Anschließend wechseln wir für dich jährlich voll automatisiert. Bist du dir sicher, dass du abbrechen möchtest?',
                isAgreeable: true,
                onAgree: () => {
                    push('/products/new');
                },
                onDisagree: () => {},
                isDisagreeable: true,
                open: true,
                agreeText: 'JA',
                disagreeText: 'NEIN'
            })
        );
    }

    function handleBack() {
        history.goBack();
    }

    function handleSubmit() {
        if (!isValid) setValidationEnabled(true);
        else {
            if (activeStep === CarInsuranceStep.VEHICLE_DATA_SELECTION) {
                updateCarInsuranceVehicleData &&
                    updateCarInsuranceVehicleData(vehicleData);
                setVehicleHolderData(vehicleHolder);
            } else if (
                activeStep === CarInsuranceStep.VEHICLE_HOLDER_SELECTION
            ) {
                updateCarInsuranceVehicleHolder &&
                    updateCarInsuranceVehicleHolder(vehicleHolderData);
            } else if (
                activeStep === CarInsuranceStep.NECESSARY_DATA_SELECTION
            ) {
                updateCarInsuranceVehicleDataDetails &&
                    updateCarInsuranceVehicleDataDetails(vehicleDataDetails);
            } else if (activeStep === CarInsuranceStep.NO_CLAIM_DISCOUNT) {
                updateCarInsuranceNoClaimDiscount &&
                    updateCarInsuranceNoClaimDiscount(noClaimDiscount);
            } else if (
                activeStep === CarInsuranceStep.CAR_PRODUCT_TYPE_SELECTION
            ) {
                updateCarInsurancePolicyData &&
                    updateCarInsurancePolicyData(policyDataScope);
                getCarInsuranceProposals &&
                    getCarInsuranceProposals(vehicleData);
            } else if (activeStep === CarInsuranceStep.DATA_VALIDATION) {
                const callBack =
                    typeof routerHistoryIndex === 'undefined'
                        ? () => history.push('/products/new')
                        : () => history.go(-routerHistoryIndex);

                submitCarInsuranceRequest &&
                    submitCarInsuranceRequest(callBack);
                return;
            } else if (activeStep === CarInsuranceStep.PAYMENT_SELECTION) {
                setCarInsuranceManualPayment &&
                    setCarInsuranceManualPayment(paymentData);
            }

            handleStepChange(activeStep + 1);
            setValidationEnabled(false);
        }
    }

    function handleAccountSelection(account: IAccount) {
        setSelectedAccount(account);
        setPaymentData({
            accountId: account.id,
            accountHolderName: account.accountHolderName,
            iban: account.iban,
            paymentMethod: paymentData.paymentMethod
        });
        handleBack();
    }

    function handleNoClaimDiscountSelection(shortName: string) {
        setNoClaimDiscount({
            ...noClaimDiscount,
            noClaimDiscountValue: shortName
        });
        handleBack();
    }

    function getInsurerDataForListItem() {
        if (typeof noClaimDiscount?.preInsurer === 'undefined') return {};

        //ToDo useSelector
        const company = allCompanies.find(
            company => Number(company.id) === Number(noClaimDiscount.preInsurer)
        );

        return {
            name: company?.name ?? '',
            logoPath: company?.logoPath ?? ''
        };
    }

    function handleStepChange(nextStep: CarInsuranceStep) {
        push(setStepUrl(nextStep));
    }

    function setStepUrl(nextStep: number): string {
        const path = location.pathname.slice(0, -1);
        return path + nextStep;
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        formData: policyFormDataSelector(state),
        policyData: getPolicyData(state),
        vehicleHolder: getVehicleHolderData(state),
        options: getCarInsuranceOptions(state),
        carInsuranceVehicleDataDetails: getVehicleDataDetails(state),
        carInsuranceNoClaimDiscount: getNoClaimDiscount(state),
        vehicle: getVehicleData(state),
        proposalData: getCarInsuranceProposal(state),
        hasBrokermandate: existsBrokermandate(state),
        carInsurancePaymentAccount: getCarInsurancePayment(state),
        allCompanies: allCompaniesByTypeSelector(state, {
            companyType: CompanyType.INSURANCE
        })
    };
};

export default connect(mapStateToProps, {
    push,
    fetchRequiredInformations,
    fetchCarInsuranceVehicleHolderData,
    updateCarInsuranceNoClaimDiscount,
    updateCarInsurancePolicyData,
    updateCarInsuranceVehicleDataDetails,
    updateCarInsuranceVehicleData,
    updateCarInsuranceVehicleHolder,
    updateCalculationId,
    getCarInsuranceProposals,
    submitCarInsuranceRequest,
    setCarInsuranceManualPayment
})(CarInsuranceController);

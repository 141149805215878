import {
    ICarInsuranceCompletionResponse,
    ICarInsuranceOptions,
    ICarInsurancePaymentAccount,
    ICarInsuranceProductFullData,
    ICarInsuranceVehicleDataDetails,
    ICoverage,
    IInsuranceProduct,
    IPolicyData,
    IProposal,
    IProposalTariff,
    IVehicleData,
    IVehicleHolder,
    PaymentMethod
} from '../../models/productInterface';
import {
    SET_CAR_INSURANCE_MANUAL_PAYMENT,
    SET_CAR_INSURANCE_NO_CLAIM_DISCOUNT,
    SET_CAR_INSURANCE_POLICY_DATA,
    SET_CAR_INSURANCE_PROPOSAL,
    SET_CAR_INSURANCE_SPECIFIC_DATA,
    SET_CAR_INSURANCE_SUBMIT_RESPONSE,
    SET_CAR_INSURANCE_VEHICLE_HOLDER_DATA,
    SET_PREVIOUS_CAR_INSURANCE_POLICY_DATA,
    SET_USER_PROPOSAL_CHOICE,
    SET_VEHICLE_DATA
} from '../actions/actionTypes';
import get from 'lodash.get';
import {IntervalType} from '../../models/recurringpaymentInterface';

const temp = {}; //could be used for notifications form the BackEnd

export const initialState = get(temp, 'products.carInsurance', {
    mainData: {
        id: 7,
        name: 'Kfz Versicherung'
    } as IInsuranceProduct,
    vehicleData: {
        tsn: '',
        hsn: '',
        plateNumber: '',
        vehicleId: '',
        kmPerYear: 0,
        firstInsuredDate: undefined,
        firstRegistrationDate: undefined,
        area: ''
    } as IVehicleData,
    vehicleHolder: {
        birthDate: undefined,
        familyName: '',
        firstName: '',
        city: '',
        email: '',
        gender: '',
        houseNumber: '',
        postalCode: '',
        street: ''
    } as IVehicleHolder,
    options: {
        isVehicleHolderDataInit: false
    } as ICarInsuranceOptions,
    policyData: {
        deductibleFull: 0,
        deductiblePartial: 0,
        productType: '',
        packageType: 'Comfort',
        noClaimDiscountFull: '0',
        noClaimDiscountPartial: '0',
        paymentInterval: IntervalType.ANNUAL,
        amount: 0
    } as IPolicyData,
    previousPolicyData: {} as IPolicyData,
    vehicleDataDetails: {
        kmPerYear: undefined,
        otherDriver: false,
        startDate: undefined,
        youngestDriverAge: undefined
    } as ICarInsuranceVehicleDataDetails,
    proposal: {
        coverages: [] as ICoverage[],
        currency: '',
        expirationDatetime: new Date(),
        insurerName: 'ONE Versicherung AG',
        green: {
            calculationId: '',
            productId: '',
            tariffWithTax: 0
        } as IProposalTariff,
        normal: {
            calculationId: '',
            productId: '',
            tariffWithTax: 0
        } as IProposalTariff
    } as IProposal,
    noClaimDiscount: {
        preInsurer: '',
        noClaimDiscountValue: undefined,
        noClaimDiscountAvailable: true
    },
    submitResponse: {
        currency: '',
        calculationId: '',
        id: '',
        price: 0,
        status: ''
    } as ICarInsuranceCompletionResponse,
    paymentData: {
        paymentMethod: PaymentMethod?.InApp ?? 0,
        iban: '',
        accountHolderName: ''
    } as ICarInsurancePaymentAccount,
    proposalSelectedId: ''
} as ICarInsuranceProductFullData);

export default (state = initialState, action: any) => {
    switch (action.type) {
        case SET_VEHICLE_DATA:
            return {
                ...state,
                vehicleData: action.payload
            };
        case SET_CAR_INSURANCE_PROPOSAL:
            return {
                ...state,
                proposal: action.payload
            };
        case SET_USER_PROPOSAL_CHOICE:
            return {
                ...state,
                proposalSelectedId: action.payload
            };
        case SET_CAR_INSURANCE_VEHICLE_HOLDER_DATA:
            return {
                ...state,
                vehicleHolder: action.payload,
                options: {
                    ...state.options,
                    isVehicleHolderDataInit: true
                }
            };
        case SET_CAR_INSURANCE_POLICY_DATA:
            return {
                ...state,
                policyData: action.payload
            };
        case SET_CAR_INSURANCE_SUBMIT_RESPONSE:
            return {
                ...state,
                submitResponse: action.payload
            };
        case SET_PREVIOUS_CAR_INSURANCE_POLICY_DATA:
            return {
                ...state,
                previousPolicyData: action.payload
            };
        case SET_CAR_INSURANCE_SPECIFIC_DATA:
            return {
                ...state,
                vehicleDataDetails: action.payload
            };
        case SET_CAR_INSURANCE_NO_CLAIM_DISCOUNT:
            return {
                ...state,
                noClaimDiscount: action.payload
            };
        case SET_CAR_INSURANCE_MANUAL_PAYMENT: {
            return {
                ...state,
                paymentData: action.payload
            };
        }
        default:
            // need this for default case
            return state;
    }
};

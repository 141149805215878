import React, {useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {
    WgExpansionPanel,
    WgTypography
} from '../../../../../styles/CustomComponents';
import VehicleDataCompletion from './directSale/VehicleDataCompletion';
import PersonalDataCompletion from './Components/PersonalDataCompletion';
import ContractDataCompletion from './Components/ContractDataCompletion';
import AccountDataCompletion from './Components/AccountDataCompletion';
import {
    CarInsuranceOneProductType,
    ICarInsuranceNoClaimDiscount,
    ICarInsuranceVehicleDataDetails,
    IPaymentData,
    IPolicyData,
    IProposalTariff,
    IVehicleData,
    IVehicleHolder
} from '../../../../../models/productInterface';
import {IAccount} from '../../../../../models/accountInterface';
import {
    formatDateWithTime,
    formatGender,
    formatIBAN,
    formatONEInsuranceOption,
    formatPaymentInterval,
    formatProductType
} from '../../../../utils/Format';

declare type ContactType =
    | 'contractData'
    | 'contract'
    | 'vehicleData'
    | 'personalData'
    | 'accountMethod';

interface InsuranceExpansionPanelData {
    title: any;
    subTitle: any;
    headerImage: any;
    name?: ContactType;
    content: React.ReactNode;
}

interface CarInsuranceCompletionProps {
    fetchRequiredInformations?: Function;
    hasBrokermandate?: boolean;
    vehicleData: IVehicleData;
    vehicleHolderData: IVehicleHolder;
    proposalTariff: IProposalTariff;
    policyData: IPolicyData;
    carInsuranceVehicleDataDetails: ICarInsuranceVehicleDataDetails;
    noClaimDiscount?: ICarInsuranceNoClaimDiscount;
    eco: boolean;
    location: any;
    paymentData: IPaymentData;
    selectedAccount?: IAccount;
    process: CarInsuranceOneProductType;
}

interface IState {
    vehicleData: IVehicleData;
    vehicleHolderData: IVehicleHolder;
    policyData: IPolicyData;
    carInsuranceVehicleDataDetails: ICarInsuranceVehicleDataDetails;
    paymentData: IPaymentData;
}

export default function CarInsuranceCompletion(
    props: CarInsuranceCompletionProps
) {
    const {
        vehicleData,
        vehicleHolderData,
        policyData,
        carInsuranceVehicleDataDetails,
        noClaimDiscount,
        paymentData,
        selectedAccount,
        process
    } = props;
    const [state] = useState<IState | undefined>({
        vehicleData,
        vehicleHolderData,
        policyData,
        carInsuranceVehicleDataDetails,
        paymentData
    });
    let expansionPanelsData: InsuranceExpansionPanelData[] = [
        {
            title: 'Dein Vertrag',
            subTitle: getContractDetailString(),
            headerImage:
                '/img/recurringpayment/icon-recurringpayment-circle.svg',
            name: 'contractData',
            content: (
                <ContractDataCompletion
                    proposalTariff={props.proposalTariff}
                    policyData={props.policyData}
                    vehicleDataDetails={props.carInsuranceVehicleDataDetails}
                    noClaimDiscount={noClaimDiscount}
                    eco={props.eco}
                    insurerName={'ONE Versicherung AG'}
                    process={process}
                />
            )
        },
        {
            title: 'Fahrzeug',
            subTitle: getVehicleDataString(),
            headerImage: '/img/recurringpayment/icon-user-circle.svg',
            name: 'vehicleData',
            content: <VehicleDataCompletion vehicleData={state?.vehicleData} />
        },
        {
            title: 'Persönliche Daten',
            subTitle: getPersonalDataString(),
            headerImage: '/img/recurringpayment/icon-user-circle.svg',
            name: 'personalData',
            content: (
                <PersonalDataCompletion
                    vehicleHolder={state?.vehicleHolderData}
                />
            )
        },
        {
            title: 'Zahlungsart',
            subTitle: getPaymentMethodDataString(),
            headerImage: '/img/recurringpayment/icon-bankingcard-circle.svg',
            name: 'accountMethod',
            content: (
                <AccountDataCompletion
                    policyData={state?.policyData}
                    paymentData={paymentData}
                    account={selectedAccount}
                />
            )
        }
    ];

    if (process === CarInsuranceOneProductType.SWITCH_INSURANCE)
        expansionPanelsData.splice(1, 1);

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <Box padding={{xs: '0 1rem 1rem 1rem', md: '0 0 1rem 0'}}>
                <WgTypography color="primary" text="contentInfo">
                    Bitte überprüfe zum Abschluss deine Daten:
                </WgTypography>
            </Box>
            <Grid container spacing={1}>
                {expansionPanelsData.map(renderExpansionPanel)}
            </Grid>
        </Grid>
    );

    function renderExpansionPanel(item: any) {
        const {content, ...other} = item;
        return (
            <Grid key={item.title} item xs={12}>
                <WgExpansionPanel key={other.title} {...other}>
                    {content}
                </WgExpansionPanel>
            </Grid>
        );
    }

    function getContractDetailString() {
        const tariff = formatONEInsuranceOption(process, props.eco);
        const insuranceName = 'ONE Versicherung AG';
        const productType = policyData?.productType
            ? formatProductType(policyData?.productType)
            : '';
        const startDateFormatted = carInsuranceVehicleDataDetails?.startDate
            ? formatDateWithTime(carInsuranceVehicleDataDetails?.startDate)
            : '';

        return `${tariff} 
            ${insuranceName && ' | ' + insuranceName} 
            ${productType && ' | ' + productType} 
            ${startDateFormatted &&
                ' | Versicherungsbeginn ' + startDateFormatted}
        `;
    }

    function getVehicleDataString() {
        const {
            plateNumber,
            kmPerYear,
            firstInsuredDate,
            area
        } = state?.vehicleData ?? {
            plateNumber: '',
            kmPerYear: '',
            firstInsuredDate: new Date(),
            area: ''
        };

        const firstInsuredDateFormatted = firstInsuredDate
            ? formatDateWithTime(firstInsuredDate)
            : '';

        return `${plateNumber ? plateNumber : ''} 
            ${kmPerYear ? ' | ' + kmPerYear : ''}
            ${
                firstInsuredDateFormatted
                    ? ' | Erster Versicherungstag ' + firstInsuredDateFormatted
                    : ''
            }
            ${area ? ' | ' + area : ''}`;
    }

    function getPersonalDataString() {
        const {
            firstName,
            familyName,
            gender,
            email,
            street,
            houseNumber,
            city,
            postalCode
        } = state?.vehicleHolderData ?? {};

        return `${
            gender ? formatGender(gender, true) : ''
        } ${firstName} ${familyName} ${email && ' | ' + email} ${
            street ? ' | ' + street : ''
        } ${houseNumber ? houseNumber + ', ' : ''} ${city ?? ''} ${postalCode ??
            ''}`;
    }

    function getPaymentMethodDataString() {
        const {name} = props?.selectedAccount ?? {};
        const {accountHolderName, iban} = state?.paymentData ?? {};
        const {paymentInterval} = state?.policyData ?? {};

        return `${name ? name : ''} 
            ${iban ? (name ? ' | ' : '') + formatIBAN(iban) : ''}
            ${
                paymentInterval
                    ? ' | ' + formatPaymentInterval(paymentInterval)
                    : ''
            }
            ${accountHolderName ? ' | ' + accountHolderName : ''}`;
    }
}

import React from 'react';
import {Box, Grid, useMediaQuery as uMQ} from '@material-ui/core';
import {
    WgListItem,
    WgListItemContent,
    WgSelection,
    WgTypography
} from '../../../../styles/CustomComponents';
import {
    IntervalType,
    IRecurringPaymentUpdate
} from '../../../../models/recurringpaymentInterface';
import useMediaQuery from '../../../../hooks/useMediaQuery';

export interface RecurringPaymentAmountProps {
    amount?: number;
    subTitle?: string;
    info?: string;
    colored?: boolean;
    chipText?: string;
    chipDisabled?: boolean;
    disableInterval?: boolean;
    setUpdate?: Function;
}

export function RecurringPaymentAmount({
    amount,
    subTitle,
    info,
    colored,
    chipText,
    chipDisabled,
    disableInterval,
    setUpdate
}: RecurringPaymentAmountProps) {
    const {isMobile} = useMediaQuery('sm');
    const isSmallPhone = uMQ('(max-width: 320px)');
    return (
        <Grid item style={{position: 'relative'}}>
            <WgListItem>
                <WgListItemContent style={{maxWidth: '100%'}}>
                    <Box>
                        <WgTypography
                            money
                            colored={colored && 'rp'}
                            color="textPrimary"
                            number="formHeader"
                            style={{
                                maxWidth: isMobile ? '55%' : '100%',
                                fontSize: isSmallPhone ? '1.75rem' : '2rem'
                            }}>
                            {amount ?? 0}
                        </WgTypography>
                        {subTitle && (
                            <WgTypography
                                gutterTop="0.5rem"
                                color="textSecondary"
                                header="uppercaseSpacing">
                                {subTitle}
                            </WgTypography>
                        )}
                        {info && (
                            <WgTypography
                                color="textSecondary"
                                header="uppercaseSpacing">
                                {info}
                            </WgTypography>
                        )}
                    </Box>
                </WgListItemContent>
            </WgListItem>
            {!disableInterval && (
                <WgSelection
                    disabled={chipDisabled}
                    selectedItem={chipText}
                    onChange={handleChange}
                    items={Object.keys(IntervalType)}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '1rem',
                        top: isSmallPhone ? '1.25rem' : '1.5rem',
                        zIndex: 99,
                        minWidth: isSmallPhone ? '125px' : '150px',
                        maxWidth: isSmallPhone ? '160px' : 'fit-content'
                    }}
                />
            )}
        </Grid>
    );

    function handleChange(item: string) {
        setUpdate &&
            setUpdate((update: IRecurringPaymentUpdate) => ({
                ...update,
                paymentInterval: item
            }));
    }
}

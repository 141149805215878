import React from 'react';
import {createStyles, Grid, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = () =>
    createStyles({
        root: {
            maxWidth: 'fit-content',
            overflow: 'hidden',
            margin: '0 1rem',
            '&:first-child': {
                marginRight: 0
            }
        }
    });
export interface WgListItemContentProps {
    classes?: any;
    children: any;
    style: any;
    justifyContent?: any;
}

const WgListItemContent = ({
    classes,
    children,
    style,
    justifyContent
}: WgListItemContentProps) => {
    const mappedChildren = React.Children.toArray(children).map(mapChildren);
    return (
        <Grid
            container
            item
            zeroMinWidth
            xs="auto"
            wrap="nowrap"
            justify={justifyContent ?? 'space-between'}
            style={{padding: '1rem 0', minHeight: '4.538rem'}}>
            {mappedChildren || null}
        </Grid>
    );

    function mapChildren(child: any, index: number, initial: any[]) {
        return (
            <Grid
                style={style}
                className={classes['root']}
                key={`${child.type.displayName}-${index}`}
                item
                container
                xs
                zeroMinWidth={initial.length - 1 !== index} // last content item (amount) shouldn't have min width of 0
                wrap="nowrap"
                direction="column"
                justify="center">
                {child}
            </Grid>
        );
    }
};

WgListItemContent.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    style: PropTypes.object
};

WgListItemContent.defaultProps = {};

export default withStyles(styles)(WgListItemContent);

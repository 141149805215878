import React, {useState} from 'react';
import {connect} from 'react-redux';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography,
    WgInputField
} from '../../../../styles/CustomComponents';
import {
    Box,
    Paper,
    Divider,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import {getUserUserData} from '../../../../store/reducers/selectors';
import {IUserData} from '../../../../models/userInterface';
import {financialAnalysisActions} from '../../../../store/actions';
import {validateEmail} from '../../../utils/Validate';
import {displayErrorNotification} from '../../../../store/actions/notificationActions';

const {sendFinancialAnalysis} = financialAnalysisActions;

interface FinancialAnalysisProps {
    userData: IUserData;
    displayErrorNotification: Function;
    sendFinancialAnalysis: Function;
}

function FinancialAnalysis({
    userData: {email},
    displayErrorNotification,
    sendFinancialAnalysis
}: FinancialAnalysisProps) {
    const [newEmail, setEmail] = useState<string>('');
    const [useDefaultEMail, setUseDefaultEmail] = useState<boolean>(true);

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Finanzanalyse"
                hasBackButton
                backText="Dokumente"
            />

            <WgContainer bodyContent disableGutters={false}>
                <WgTypography gutterBottom color="textSecondary" text="info">
                    Hier kannst du eine automatisch generierte finanzielle
                    Aufstellung anfordern.
                    <br />
                    Darin findest du eine geordnete Übersicht deiner Einnahmen
                    und Ausgaben.
                </WgTypography>

                <Paper elevation={1} style={{marginTop: '4rem'}}>
                    <WgTypography
                        color="textSecondary"
                        text="sentenceSmall"
                        style={{marginLeft: '1.25rem', paddingTop: '0.45rem'}}>
                        Eigene E-Mail
                    </WgTypography>
                    <FormControlLabel
                        style={{paddingLeft: '0.5rem'}}
                        control={
                            <Checkbox
                                style={{padding: '3px 5px 5px 9px'}}
                                checked={useDefaultEMail}
                                onChange={(event: any) =>
                                    setUseDefaultEmail(event.target.checked)
                                }
                            />
                        }
                        label="An E-Mail aus Registrierung senden"
                    />
                    <Divider light variant="middle" style={{flex: 1}} />
                    <WgInputField
                        handleChange={handleChangeEMail}
                        defaultValue={
                            useDefaultEMail ? email ?? newEmail : newEmail
                        }
                        disabled={useDefaultEMail}
                        label="E-Mail-Adresse"
                        type="email"
                        name="email"
                    />
                </Paper>

                <Box
                    display="flex"
                    justifyContent={{xs: 'center', md: 'flex-end'}}
                    marginTop="1.5rem">
                    <WgButton
                        defaultButton
                        variant="contained"
                        color="primary"
                        aria-label="send"
                        fullWidth
                        onClick={() => sendMail(newEmail)}>
                        Kostenlos anfragen
                    </WgButton>
                </Box>
            </WgContainer>
        </WgContainer>
    );

    function handleChangeEMail(event: any) {
        setEmail(event.target.value);
    }

    function sendMail(email: string) {
        if (validateEmail(email) || useDefaultEMail) {
            sendFinancialAnalysis(email);
        } else {
            displayErrorNotification('Bitte überprüfe deine E-Mail-Adresse.');
        }
    }
}

function mapStateToProps(state: any) {
    return {
        userData: getUserUserData(state)
    };
}

export default connect(mapStateToProps, {
    displayErrorNotification,
    sendFinancialAnalysis
})(FinancialAnalysis);

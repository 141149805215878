import React from 'react';
import {FormControl} from '@material-ui/core';
import {SignupStep} from './SignupStep';
import {WgRadioGroup} from '../../../../styles/CustomComponents';

export default class SignupStep4 extends SignupStep {
    renderStep(): React.ReactNode {
        let genders = [
            {
                label: 'weiblich',
                value: 'female'
            },
            {
                label: 'männlich',
                value: 'male'
            },
            {
                label: 'divers',
                value: 'divers'
            }
        ];

        return (
            <React.Fragment>
                <FormControl>
                    <WgRadioGroup
                        aria-label="Gender"
                        name="gender"
                        background="paper"
                        selected={this.props.state.gender}
                        group={genders}></WgRadioGroup>
                </FormControl>
            </React.Fragment>
        );
    }
}

import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Divider} from '@material-ui/core';
import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../styles/CustomComponents';
import {connect, useStore} from 'react-redux';
import {updateTmpBrokermandate} from '../../../../store/actions/userActions';

interface BrokerMandateTextProps {
    updateTmpBrokermandate: Function;
}

function BrokerMandateText(props: BrokerMandateTextProps) {
    const store = useStore();
    const history = useHistory();

    /**
     * Upon Unmounting, update the reset-flag of tmpBrokermandate
     */
    useEffect(() => {
        // Mounting: Set the tmpBrokermandate-Reset-Flag to 0, which means it will not be reset.
        // This is necessary, as listening to the browser's back-button will only be fired after this component has unmounted.
        props.updateTmpBrokermandate(undefined, undefined, 0);

        // The history listener will fire after BrokermandateText has been rendered.
        const stopListening = history.listen(location => {
            const locationString: string = location.pathname;
            if (locationString !== '/profile/brokermandatetext') {
                // This action will not lead back to the brokermandatetext, so the reset-flag is set to 1, which means "force reset"
                props.updateTmpBrokermandate(undefined, undefined, 1);
            }

            // unregister the history-listener
            stopListening();
        });
    }, [props, store, history]);

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Maklermandat lesen"
                hasBackButton
                backText="Maklermandat"
            />

            <WgContainer bodyContent disableGutters={false} maxWidth="sm">
                <Box paddingY="2rem" fontSize="0.75rem !important">
                    <WgTypography
                        component="span"
                        color="textSecondary"
                        text="sentenceSmall">
                        <WgTypography align="center" text="sentenceSmall">
                            <b>Maklermandat und Vermittlerauftrag</b>
                        </WgTypography>
                        <br />
                        <br />
                        <br />
                        <Divider style={{width: '60%', height: '2px'}} />
                        <b>
                            <i>
                                Vorname, Name, Straße Hausnr., PLZ Ort,
                                Geburtsdatum:
                            </i>
                        </b>
                        <br />
                        <WgTypography text="sentenceSmall" align="right">
                            – nachfolgend{' '}
                            <b>
                                <i>Kunde</i>
                            </b>{' '}
                            genannt –
                        </WgTypography>
                        und
                        <br />
                        Wechselgott GmbH, Spinnereistr. 7, 04179 Leipzig
                        <br />
                        <WgTypography text="sentenceSmall" align="right">
                            – nachfolgend{' '}
                            <b>
                                <i>Wechselgott</i>
                            </b>{' '}
                            genannt –
                        </WgTypography>
                        <br />
                        <br />
                        Der Kunde beauftragt Wechselgott, ihm Strom-,
                        Gaslieferungs-, Versicherungs-, und Haushaltsverträge
                        (nachfolgend: Verträge) zu vermitteln. Die
                        Vertragsvermittlung umfasst unter anderem die Mitwirkung
                        beim Wechsel des Energieanbieters, bei
                        Versicherungsverträgen die Verwaltung und Erfüllung,
                        insbesondere im Schadensfall, sowie die Vorbereitung und
                        den Abschluss von Haushaltsverträgen, wie
                        Mobilfunkverträge.
                        <br />
                        <br />
                        <b>1. Rechtliche Stellung des Maklers / Dauer</b>
                        <br />
                        <br />
                        Wechselgott ist selbstständiger Versicherungsvermittler
                        sowie Wechseldienstleister bei Strom- und
                        Gaslieferungsverträgen (nachfolgend
                        Energielieferungsverträge) und Haushaltsverträgen und
                        steht rechtlich und wirtschaftlich auf der Seite seines
                        Kunden. Wechselgott ist weder an eine
                        Versicherungsgesellschaft noch an einen Energieanbieter
                        noch an einen Anbieter von Haushaltsverträgen gebunden
                        und nimmt die Interessen seiner Kunden weisungsgemäß
                        wahr. Wechselgott selbst ist weder ein
                        Versicherungsunternehmen noch liefert Wechselgott
                        Energie.
                        <br />
                        <br />
                        Der vorliegende Vermittlungsauftrag bezieht sich auf
                        alle neu abzuschließenden und bestehenden
                        Versicherungs-, Energielieferungs-, und
                        Haushaltsverträge, die Wechselgott in den
                        Kontoumsatzdaten des Kunden erkennt, oder der Kunde
                        gegenüber Wechselgott in der Wechselgott-App durch
                        Angabe der Policennummer bzw. der Adresse und der
                        Zählernummer für Energielieferungsverträge, oder in
                        sonst wie hinreichend bestimmt benennt oder bei denen er
                        im Bereich Versicherung einen entsprechenden
                        Vorsorgebedarf erklärt. Der Kunde beauftragt
                        Wechselgott, ihm in sämtlichen danach für den Kunden
                        relevanten Versicherungssparten Versicherungsschutz zu
                        vermitteln.
                        <br />
                        <br />
                        Der Kunde beauftragt weiterhin Wechselgott damit, in
                        seinem Namen und für seine Rechnung jeweils zum
                        nächstmöglich zulässigen Zeitpunkt, gegebenenfalls mit
                        der Einschaltung von weiteren Dienstleistern, seine
                        Energieverträge zu wechseln und die erforderlichen
                        Wechselprozesse durchzuführen, um den vom Kunden in der
                        Wechselgott-App gewünschten Energielieferungsvertrag
                        bzw. -tarif für den Kunden zu erhalten. Dazu zählen
                        insbesondere die Kündigung bestehender Energieverträge
                        sowie der Abschluss neuer sowie die Kommunikation mit
                        Energielieferanten. Zur Einschaltung von weiteren
                        Dienstleistern wird Wechselgott berechtigt,
                        Untervollmachten zu erteilen.
                        <br />
                        <br />
                        Der Makler- und Vermittlerauftrag ist auf unbestimmte
                        Zeit geschlossen und kann vom Kunden jederzeit ohne
                        Einhaltung einer Frist in Textform gekündigt werden.
                        Wechselgott kann den Vertrag mit einer Frist von einem
                        Monat in Textform an die vom Kunden benannte
                        E-Mail-Adresse kündigen.
                        <br />
                        <br />
                        <b>2. Pflichten des Kunden</b>
                        <br />
                        <br />
                        Der Kunde hat alle Änderungen in seinen persönlichen
                        Verhältnissen und seiner Risikosphäre dem Makler
                        unverzüglich mitzuteilen, soweit diese vertrags- und
                        risikorelevant sind. Dazu zählen insbesondere die
                        Änderungen, die in der Aufstellung in der Anlage
                        enthalten sind.
                        <br />
                        <br />
                        Für den Bereich Wechsel von Energielieferungsverträge
                        ist der Kunde verpflichtet, Wechselgott die zur
                        Vertragsoptimierung erforderlichen Daten (u.a. Kopie der
                        letzten Vertragsabrechnung) zur Verfügung zu stellen,
                        erhaltene Bestätigungen über Vertragswechsel und
                        Vertragsunterlagen unverzüglich auf deren Korrektheit zu
                        überprüfen und Wechselgott unzutreffende Inhalte
                        unverzüglich mitzuteilen. Sofern Informationen direkt
                        vom Lieferanten kommen, teilt der Kunde den Inhalt
                        Wechselgott mit. Der Kunde stellt sicher, dass er über
                        die Wechselgott-App Nachrichten von Wechselgott erhalten
                        kann.
                        <br />
                        <br />
                        <b>3. Pflichten von Wechselgott</b>
                        <br />
                        <br />
                        Wechselgott stellt dem Kunden im Rahmen seiner Tätigkeit
                        Fragen nach seinen Wünschen und Bedürfnissen und
                        überprüft dahingehend die bestehenden und/oder neu
                        abzuschließenden Versicherungsverträge des Kunden. Bei
                        dieser Überprüfung wahrt Wechselgott die Interessen des
                        Kunden gegenüber den Versicherungsgesellschaften. Sollte
                        es notwendig sein, wird Wechselgott bei der
                        Versicherungsvermittlung sowohl die Komplexität der
                        angebotenen Versicherung als auch die jeweilige
                        Situation des Kunden berücksichtigt. Wechselgott wird
                        seinen Rat zum Versicherungsabschluss auf eine objektive
                        und ausgewogene Marktuntersuchung stützen, soweit im
                        Einzelfall nicht etwas anders zwischen mit dem Kunden
                        vereinbart wurde. Bei der Marktuntersuchung finden nur
                        die von der Bundesanstalt für
                        Finanzdienstleistungsaufsicht zugelassenen Versicherer,
                        die eine Niederlassung in der Bundesrepublik Deutschland
                        unterhalten und Vertragsbedingungen in deutscher Sprache
                        anbieten, Berücksichtigung.
                        <br />
                        <br />
                        Der Kunde willigt ein, dass auf ein Beratungsprotokoll
                        verzichtet wird. Der Kunde ist darauf hingewiesen
                        worden, dass er dadurch seine Ansprüche auf
                        Schadensersatz nach § 63 VVG gegenüber Wechselgott im
                        Falle einer Verletzung der Beratungs- und
                        Dokumentationspflichten verlieren kann. Wechselgott
                        wirkt aber bei der Verwaltung, Betreuung und Erfüllung
                        des Versicherungsvertrages, z.B. im Schadensfall, im
                        Rahmen der erteilten Maklervollmacht mit.
                        <br />
                        <br />
                        <b>4. Vergütung</b>
                        <br />
                        <br />
                        Die Leistungen von Wechselgott werden bei
                        Versicherungsverträgen durch die, vom Versicherer zu
                        tragende, Courtage abgegolten; sie ist Bestandteil der
                        Versicherungsprämie.
                        <br />
                        <br />
                        Bei Energielieferungsverträgen erhält Wechselgott eine
                        Courtage in Höhe von 20 % der Einsparungen des Kunden im
                        Vergleich zu seinem bestehenden
                        Energielieferungsvertrag. Diese wird durch den
                        Technologiepartner Wechselpilot eingezogen und
                        abgegolten. Die Vergütung ermittelt sich aus dem
                        finanziellen Vorteil des Kunden, den dieser durch den
                        Energielieferungsvertrag bzw. -tarif erzielt. Die
                        Berechnung erfolgt aus der Differenz zwischen den
                        Energiebezugskosten (Grundpreis und Arbeitspreis
                        einschließlich aller Abgaben, Steuern und Gebühren) für
                        den Altanbieter und dem Neuanbieter. Ausgezahlte Boni
                        reduzieren die Energiebezugskosten des Neuanbieters.
                        Sofern die Energiebezugskosten des Altanbieters nicht
                        bekannt sind, wird der jeweilige Grundversorgungstarif
                        herangezogen. Zeitraum für die Ermittlung der Einsparung
                        des Kunden ist der Liefervertragszeitraum. Dieser
                        umfasst i.d.R. einen Zeitraum von 12 Monaten. Die
                        Geltendmachung der Provision erfolgt durch Übersendung
                        einer Rechnung nach Abschluss der Lieferperiode.
                        <br />
                        <br />
                        <b>5. Haftung</b>
                        <br />
                        <br />
                        Wechselgott erfüllt seine Verpflichtungen mit der
                        Sorgfalt eines ordentlichen Kaufmanns. Die Haftung im
                        Bereich Versicherungsvermittlung ist auf die in § 9 der
                        Verordnung über die Versicherungsvermittlung und
                        -beratung (VersVermV) angegebenen
                        Mindestversicherungssummen (zurzeit 1.230.000 Euro pro
                        Versicherungsfall und 1.850.000 Euro für alle
                        Versicherungsfälle eines Jahres) beschränkt. Im Bereich
                        Energielieferungsverträge ist die Haftung von
                        Wechselgott für leicht fahrlässige Verletzungen
                        vertragswesentlicher Pflichten ist begrenzt auf den
                        vertragstypischen und vorhersehbaren Schaden.
                        Wechselgott haftet nicht für fehlerhafte oder
                        unvollständige Angaben des Kunden, die unmittelbare
                        Auswirkungen für den Energielieferungsvertrag bzw. den
                        Neuabschluss eines Energielieferungsvertrages haben.
                        Eine Haftung für die Verfügbarkeit der am Markt
                        befindlichen Energielieferungsverträge übernimmt
                        Wechselgott nicht.
                        <br />
                        <br />
                        Die vorgenannte Haftungsbeschränkung gilt nicht, wenn
                        die Haftung von Wechselgott auf einer Verletzung des
                        Lebens, des Körpers oder der Gesundheit beruht,
                        Wechselgott eine Kardinalspflicht oder vorsätzlich oder
                        grob fahrlässig eine Nebenpflicht verletzt.
                        Kardinalspflichten sind diejenigen Pflichten, deren
                        Erfüllung die ordnungsgemäße Durchführung des Vertrags
                        überhaupt erst ermöglichen oder auf deren Einhaltung der
                        Kunde regelmäßig vertrauen kann.
                        <br />
                        <br />
                        <b>6. Vertragsdurchführung / Datenschutz</b>
                        <br />
                        <br />
                        Die für die Auftragsdurchführung erforderlichen
                        Vertretungsbefugnisse von Wechselgott gegenüber den
                        Versicherungsunternehmen ergeben sich aus der vom Kunden
                        gesondert erteilten Maklervollmacht.
                        <br />
                        <br />
                        Zur bestmöglichen Durchführung und Erfüllung des
                        Maklerauftrages darf der Makler Dritte einbeziehen.
                        Insbesondere darf er den Maklerpool Jung, DMS & Cie.
                        Pool GmbH, Kormoranweg 1, 65201 Wiesbaden zur
                        Vertragsabwicklung und zur Verwaltung des vom Makler
                        betreuten Vertragsbestands nutzen.
                        <br />
                        <br />
                        <b>
                            <u>
                                Für Einzelheiten zur Verwendung der Kundendaten
                                durch den Makler und der von ihm eingesetzten
                                Dritten wird auf die Datenschutzerklärung, die
                                Kommunikationserklärung und die Maklervollmacht
                                verwiesen.{' '}
                            </u>
                        </b>
                        <br />
                        <br />
                        <b>7. Rechtsnachfolge</b>
                        <br />
                        <br />
                        Der Kunde willigt bereits jetzt in eine etwaige
                        Vertragsübernahme im Weg der Rechtsnachfolge (z.B.
                        Verkauf des Geschäftsbetriebes des Maklers, Tod des
                        Maklers) ein. Der Makler wird dem Kunden eine evtl.
                        geplante Rechtsnachfolge rechtzeitig mitteilen. Sofern
                        der Kunde hiergegen nicht innerhalb angemessener Frist
                        widerspricht, ist der Rechtsnachfolger berechtigt, das
                        Vertragsverhältnis fortzuführen.
                        <br />
                        <br />
                        <b>8. Schlussbestimmungen</b>
                        <br />
                        <br />
                        Nebenabreden zu diesem Vertrag wurden nicht getroffen,
                        Änderungen oder Ergänzungen bedürfen unabdingbar der
                        Textform. Sollten eine oder mehrere Bestimmungen dieses
                        Vertrages unwirksam oder undurchführbar sein oder
                        werden, bleibt die Wirksamkeit der übrigen Bestimmungen
                        unberührt. Die Parteien verpflichten sich, die
                        undurchführbare oder unwirksame Regelung durch eine
                        solche zulässige Bestimmung zu ersetzen, die dem
                        angestrebten wirtschaftlichen Zweck möglichst nahe
                        kommt. Entsprechendes gilt für eine etwaige
                        Vertragslücke.
                        <br />
                        <br />
                        Die notwendige Erstinformation gemäß § 11 VersVermV
                        wurde beim Erstkontakt übergeben.
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <WgTypography align="center">
                            <b>
                                Anlage zur Übersicht über vertrags- und
                                risikorelevanter Änderungen
                            </b>
                        </WgTypography>
                        <br />
                        Bitte teilen Sie Wechselgott vertrags- und
                        risikorelevante Änderungen, die Ihren
                        Versicherungsschutz betreffen unverzüglich mit. Diese
                        Liste hat keinen Anspruch auf Vollständigkeit.
                        <br />
                        <br />
                        <b>Änderung Ihrer Lebens- und Wohnsituation</b>
                        <ul>
                            <li>Hochzeit</li>
                            <li>Zusammenlegung zweier Haushalte</li>
                            <li>Scheidung / Trennung vom Ehepartner</li>
                            <li>Geburt / Adoption eines Kindes</li>
                            <li>Tod eines Familienmitglieds</li>
                            <li>Eintritt der Berufsunfähigkeit</li>
                            <li>Umzug</li>
                            <li>Immobilienerwerb oder –verkauf</li>
                            <li>Deutliche Veränderung des Hausratswertes</li>
                            <li>
                                Aufnahme riskanter Freizeitbeschäftigungen (z.B.
                                Fallschirmspringen, Fliegen, Paragliding,
                                Jagdschein, Motorsport etc.)
                            </li>
                            <li>Krankheit</li>
                            <li>
                                Erwerb eines Haustieres (vor allem Hund oder
                                Pferd)
                            </li>
                            <li>Kauf / Verkauf eines Kraftfahrzeuges</li>
                        </ul>
                        <b>Änderung Ihrer Beschäftigungssituation</b>
                        <ul>
                            <li>
                                Änderung des Einkommens (z.B. durch Beförderung,
                                Gehaltserhöhung)
                            </li>
                            <li>Unternehmenswechsel</li>
                            <li>Eintritt in den Altersruhestand</li>
                            <li>Arbeitslosigkeit</li>
                            <li>Reduktion der Arbeitstätigkeit (Teilzeit)</li>
                            <li>
                                Wechsel von Bürotätigkeit zu körperlicher
                                Tätigkeit und umgekehrt
                            </li>
                            <li>Entsendung ins Ausland</li>
                            <li>
                                Wegfall der Sozialversicherungspflicht bei
                                Aufnahme einer selbstständigen Tätigkeit
                            </li>
                            <li>
                                Beendigung einer selbstständigen Tätigkeit und
                                Aufnahme einer sozialversicherungspflichtigen
                                Beschäftigung
                            </li>
                            <li>Übergang in den Beamtenstatus</li>
                        </ul>
                        <b>Änderung Ihrer finanziellen Verhältnisse</b>
                        <ul>
                            <li>Erbschaften</li>
                            <li>Aufnahme von Darlehen</li>
                        </ul>
                        <b>
                            Gravierende Änderungen Ihrer Vermögensverhältnisse
                        </b>
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <WgTypography text="sentenceSmall" align="center">
                            <b>Maklermandat</b>
                        </WgTypography>
                        <br />
                        Hiermit erteile ich (Vollmachtgeber)
                        <br />
                        <br />
                        <br />
                        <Divider style={{width: '60%', height: '2px'}} />
                        <b>
                            <i>
                                Vorname, Name, Straße Hausnr., PLZ Ort,
                                Geburtsdatum:
                            </i>
                        </b>
                        <br />
                        <WgTypography text="sentenceSmall" align="right">
                            – nachfolgend{' '}
                            <b>
                                <i>Kunde</i>
                            </b>{' '}
                            genannt –
                        </WgTypography>
                        dem Versicherungsmakler
                        <br />
                        Wechselgott GmbH, Spinnereistr. 7, 04179 Leipzig
                        <br />
                        <WgTypography text="sentenceSmall" align="right">
                            – nachfolgend{' '}
                            <b>
                                <i>Wechselgott</i>
                            </b>{' '}
                            genannt –
                        </WgTypography>
                        <br />
                        oder seinem Rechtsnachfolger{' '}
                        <b>
                            <i>Vollmacht</i>
                        </b>
                        , zum Handeln in meinem Namen
                        <ul>
                            <li>
                                bei Abschluss, Änderung oder Kündigung von
                                Versicherungsverträgen,
                            </li>
                            <li>zum Ausstellen von Untervollmachten,</li>
                            <li>
                                zur Abgabe oder Entgegennahme von Erklärungen zu
                                Versicherungsverträgen,
                            </li>
                            <li>
                                zur Mitwirkung bei der Schadensabwicklung für
                                von Wechselgott vermittelten oder betreuten
                                Versicherungen, sowie
                            </li>
                            <li>
                                bei der Einholung von Auskünften bei
                                Sozialversicherungsträgern.
                            </li>
                        </ul>
                        Darüber hinaus willige ich in folgende{' '}
                        <b>
                            <i>Nutzung meiner Daten</i>
                        </b>{' '}
                        ein:
                        <br />
                        <br />
                        Ich willige ein, dass Daten an Wechselgott übermittelt
                        werden können und befreie Mitarbeiter der
                        Versicherungsunternehmen insoweit von ihrer
                        Schweigepflicht. Bei Kranken-, Unfall- oder
                        Lebensversicherungsverträgen umfasst dies ausdrücklich
                        auch gesundheitliche Daten.
                        <br />
                        <br />
                        Die gesamte Korrespondenz der Versicherungsunternehmen
                        ist mit dem Kunden im Original und mit Wechselgott in
                        Kopie zu führen.
                        <br />
                        <br />
                        Bei der Vermittlung von Versicherungen und
                        Finanzdienstleistungen muss ein Makler Daten
                        verarbeiten, um das Zustandekommen der gewünschten
                        Verträge und Ihre Betreuung zu ermöglichen. Die
                        Datenschutzgrundverordnung erlaubt die Verarbeitung
                        personenbezogener Daten unter anderem dann, wenn dies im
                        Rahmen der Erfüllung eines Vertragsverhältnisses
                        erforderlich ist.
                        <br />
                        <br />
                        Der Kunde willigt ein, dass
                        <ol>
                            <li>
                                Wechselgott zur Durchführung und Erfüllung des
                                o.g. Auftrags Dritte (insbesondere Maklerpools,
                                Anbieter von Vergleichsrechnern, Haupt- bzw.
                                Untervermittler) einbeziehen darf,{' '}
                            </li>
                            <li>
                                personenbezogene Daten (insbesondere auch solche
                                zu den finanziellen Verhältnissen und zum
                                Gesundheitszustand), die im Rahmen von
                                Datenaufnahmen, Beratungsdokumentationen,
                                Vertragsabschlüssen oder der Betreuung der
                                Verträge gegenüber Wechselgott offenbart werden,
                                bei diesem und den von ihm zur Durchführung und
                                Erfüllung eingesetzten Dritten in einer
                                Datensammlung (insbes. auch elektronisch)
                                verarbeitet werden,{' '}
                            </li>
                            <li>
                                Wechselgott personenbezogene Daten an die Jung,
                                DMS & Cie. Pool GmbH, Kormoranweg 1, 65201
                                Wiesbaden, nachfolgend kurz „JDC“ genannt,
                                übermittelt, die diese Daten bei sich speichert
                                und verarbeitet. Die JDC ist ein sog.
                                Maklerpool, der vom Makler technisch und
                                organisatorisch in den Vermittlungsprozess und
                                in die Vertragsbetreuung einbezogen wird.
                            </li>
                            <li>
                                die bestehenden Versicherungsverträge zu dem
                                Zweck in Ziffer 3 genannten Zweck in den Bestand
                                der JDC übernommen werden und dem Makler
                                zugeordnet werden.
                            </li>
                            <li>
                                der Makler und die JDC personenbezogene Daten
                                an, die vom Makler oder dem Maklerpool
                                empfohlenen Produktanbieter übermitteln, die
                                diese Daten bei sich für die Antragsprüfung,
                                Vertragserstellung und -verwaltung speichern und
                                verarbeiten.
                            </li>
                            <li>
                                die Produktanbieter, mit denen durch die
                                Vermittlung des Maklers und der JDC eine
                                Vertragsbeziehung besteht, die zur Betreuung des
                                Vertrages erforderlichen Daten an den Makler und
                                die JDC übermitteln, und dass diese Daten bei
                                dem Makler und bei der JDC gespeichert werden
                                dürfen.
                            </li>
                        </ol>
                        Wechselgott wird hinsichtlich der Verhandlungsführung
                        Rücksprache mit dem Kunden halten und ihn jeweils
                        entsprechend unterrichten. Der Kunde erlaubt Wechselgott
                        ausdrücklich, im Namen des Kunden mit sich oder in
                        eigenem Namen oder als Vertreter eines Dritten
                        Rechtsgeschäfte vorzunehmen (Befreiung von den
                        Beschränkungen des § 181 BGB).
                        <br />
                        <br />
                        Die Vollmacht ist zeitlich nicht befristet. Sie kann vom
                        Vollmachtgeber jederzeit schriftlich widerrufen werden.
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <br />
                        <WgTypography text="sentenceSmall" align="center">
                            <b>Vollmacht</b>
                        </WgTypography>
                        <br />
                        Hiermit erteile ich (Vollmachtgeber)
                        <br />
                        <br />
                        <br />
                        <Divider style={{width: '60%', height: '2px'}} />
                        <b>
                            <i>
                                Vorname, Name, Straße Hausnr., PLZ Ort,
                                Geburtsdatum:
                            </i>
                        </b>
                        <br />
                        <WgTypography text="sentenceSmall" align="right">
                            – nachfolgend{' '}
                            <b>
                                <i>Kunde</i>
                            </b>{' '}
                            genannt –
                        </WgTypography>
                        dem Dienstleister
                        <br />
                        Wechselgott GmbH, Spinnereistr. 7, 04179 Leipzig
                        <br />
                        <WgTypography text="sentenceSmall" align="right">
                            – nachfolgend{' '}
                            <b>
                                <i>Wechselgott</i>
                            </b>{' '}
                            genannt –
                        </WgTypography>
                        <br />
                        <br />
                        oder seinem Rechtsnachfolger{' '}
                        <b>
                            <i>Vollmacht</i>
                        </b>
                        , zum Handeln in meinem Namen und auf meine Rechnung,
                        <br />
                        <ul>
                            <li>
                                zur vollständigen Umsetzung und Abwicklung der
                                von Wechselgott angebotenen
                                Wechseldienstleistungen.
                            </li>
                            <li>
                                zur Weiterleitung aller erforderlichen Daten,{' '}
                            </li>
                            <li>
                                zum Abschluss, Kündigung, Änderung von
                                Energielieferungsverträgen,
                            </li>
                            <li>
                                zum Abschluss, Kündigung, Änderung von
                                Haushaltsverträgen,
                            </li>
                            <li>
                                zur Einsichtnahme von Wechsel- und Vertragsdaten
                                (Verbrauch, Laufzeit, Preis, Rechnungen,
                                Online-Zugänge und Benachrichtigungen des
                                Lieferanten) und Kommunikation mit
                                Energielieferanten und anderen für
                                Haushaltsverträge relevanten Anbietern,
                            </li>
                            <li>
                                sowie zur Weitergabe der Bankverbindung an
                                ausgewählte Lieferanten zur Abwicklung der
                                Lieferbeziehung.
                                <br />
                                Insbesondere erteilt der Kunde Wechselgott eine
                                gesonderte Vollmacht , um gegenüber der
                                Wechselpilot GmbH ein SEPA-Lastschriftmandat für
                                den Kunden zum Einzug der Courtage zu erteilen
                                und zugleich, dass die Wechselpilot GmbH
                                gegenüber dem Energielieferanten ein
                                SEPA-Lastschriftmandat für den Kunden erteilt.
                            </li>
                        </ul>
                        <br />
                        Darüber hinaus willige ich in folgende{' '}
                        <b>
                            <i>Nutzung meiner Daten</i>
                        </b>{' '}
                        ein:
                        <br />
                        <br />
                        Bei der Vermittlung von Energielieferungsverträgen und
                        Haushaltsverträgen muss ein Dienstleister Daten
                        verarbeiten, um das Zustandekommen der gewünschten
                        Verträge zu ermöglichen. Die Datenschutzgrundverordnung
                        erlaubt die Verarbeitung personenbezogener Daten unter
                        anderem dann, wenn dies im Rahmen der Erfüllung eines
                        Vertragsverhältnisses erforderlich ist.
                        <br />
                        <br />
                        Der Kunde willigt ein, dass
                        <br />
                        <ol>
                            <li>
                                Wechselgott zur Durchführung und Erfüllung des
                                o.g. Auftrags Dritte (insbesondere, Anbieter von
                                Vergleichsrechnern, Haupt- bzw. Untervermittler)
                                einbeziehen darf,
                            </li>
                            <li>
                                personenbezogene Daten (insbesondere auch solche
                                zu den finanziellen Verhältnissen), die im
                                Rahmen von Datenaufnahmen,
                                Beratungsdokumentationen, Vertragsabschlüssen
                                oder der Betreuung der Verträge gegenüber
                                Wechselgott offenbart werden, bei diesem und den
                                von ihm zur Durchführung und Erfüllung
                                eingesetzten Dritten in einer Datensammlung
                                (insbes. auch elektronisch) verarbeitet werden.
                            </li>
                            <li>
                                der Makler zur Durchführung und Erfüllung des
                                o.g. Vertrags Dritte (insbesondere Maklerpools,
                                Anbieter von Vergleichsrechnern, Haupt- bzw.
                                Untervermittler) einbeziehen darf.
                            </li>
                            <li>
                                die bestehenden Versicherungsverträge zu diesem
                                Zweck in den Bestand der Jung, DMS & Cie. Pool
                                GmbH (Söhnleinstr. 8, 65201 Wiesbaden)
                                übernommen und dem Makler zugeordnet werden. Die
                                Jung, DMS & Cie. Pool GmbH ist ein sog.
                                Maklerpool, der vom Makler technisch und
                                organisatorisch in den Vermittlungsprozess und
                                in die Vertragsbetreuung einbezogen wird.
                            </li>
                        </ol>
                        <br />
                        Wechselgott wird hinsichtlich der Verhandlungsführung
                        Rücksprache mit dem Kunden halten und ihn jeweils
                        entsprechend unterrichten. Der Kunde erlaubt Wechselgott
                        ausdrücklich, im Namen des Kunden mit sich oder in
                        eigenem Namen oder als Vertreter eines Dritten
                        Rechtsgeschäfte vorzunehmen (Befreiung von den
                        Beschränkungen des § 181 BGB).
                        <br />
                        <br />
                        Die Vollmacht ist zeitlich nicht befristet. Sie kann vom
                        Vollmachtgeber jederzeit schriftlich widerrufen werden.
                    </WgTypography>
                </Box>
            </WgContainer>
        </WgContainer>
    );
}

export default connect(undefined, {
    updateTmpBrokermandate
})(BrokerMandateText);

import {useHistory} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {
    WgEnumerationList,
    WgExpansionPanel,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {
    CarInsuranceOneProductType,
    IPolicyData,
    IProposal,
    IProposalTariff,
    isCarOfferEco,
    IVehicleData
} from '../../../../../models/productInterface';
import {formatMoney, formatONEInsuranceOption} from '../../../../utils/Format';
import {CarInsuranceStep} from './CarInsuranceController';
import {MyLink} from '../../../../utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        detailBox: {
            backgroundColor: theme.palette.background.default
        },
        allDetailLink: {
            textDecorationColor: theme.palette.gray.normal
        }
    })
);

interface CarInsuranceOfferSelectionProps {
    getCarInsuranceProposals?: Function;
    setPolicyData?: Function;
    updateCarInsurancePolicyData?: Function;
    policyData: IPolicyData;
    proposal: IProposal;
    updateCalculationId?: Function;
    setProposal: Function;
    setEco?: Function;
    setProposalTarif?: Function;
    vehicleData: IVehicleData;
    productType: string;
    redirectLink?: string;
    process: CarInsuranceOneProductType;
}

export default function CarInsuranceOfferSelection(
    props: CarInsuranceOfferSelectionProps
) {
    const [isInit, setIsInit] = useState(false);
    const {proposal} = props;

    const savings =
        props.proposal.green.tariffWithTax -
        props.proposal.normal.tariffWithTax;

    const defaultLink = `/products/purchase/car/${CarInsuranceStep.PAYMENT_SELECTION}`;
    const link = props.redirectLink ? props.redirectLink : defaultLink;

    const history = useHistory();

    useEffect(() => {
        if (
            !isInit &&
            props.process === CarInsuranceOneProductType.NEW_VEHICLE
        ) {
            const {getCarInsuranceProposals, vehicleData} = props;
            getCarInsuranceProposals && getCarInsuranceProposals(vehicleData);

            setIsInit(true);
        }
    }, [isInit, props]);

    const classes = useStyles();

    const detailListNormal = getDetailList(props.process);
    const detailListGreen = getDetailList(props.process, true);

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Wähle einen Tarif:
            </WgTypography>
            <Box marginTop="1rem">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {renderOffer(proposal.normal, false, detailListNormal)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {renderOffer(proposal.green, true, detailListGreen)}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );

    function renderOffer(
        proposalTariff: IProposalTariff,
        eco: boolean,
        detailListArray: any
    ) {
        const ersparnis =
            !eco &&
            props.process === CarInsuranceOneProductType.SWITCH_INSURANCE
                ? {amount: savings, text: 'Ersparnis: '}
                : undefined;
        return (
            <WgExpansionPanel
                title={formatONEInsuranceOption(props.process, eco, true)}
                subTitle="ONE Versicherung AG"
                headerImage="/img/one-insurance.webp"
                currentAmount={
                    formatMoney(Math.abs(proposalTariff.tariffWithTax)) +
                    ' / Jahr'
                }
                footerContent={{
                    buttonText: 'Tarif wählen',
                    handleButtonClick: () => {
                        handleProposalSelection(eco);
                        history.push(link);
                    },
                    ...ersparnis,
                    componentProps: {
                        proposalTariff: proposalTariff,
                        eco: eco
                    }
                }}>
                <Box paddingY="0.75rem" className={classes.detailBox}>
                    {detailListArray}
                    {props.process ===
                        CarInsuranceOneProductType.NEW_VEHICLE && (
                        <Box
                            className={classes.allDetailLink}
                            display="flex"
                            marginLeft="4.5rem"
                            component={MyLink(
                                '/products/purchase/car/offerdetails',
                                undefined,
                                {
                                    productName: formatONEInsuranceOption(
                                        props.process,
                                        eco
                                    ),
                                    productType: props.productType,
                                    proposalTariff: proposalTariff,
                                    eco: eco
                                }
                            )}>
                            <WgTypography
                                gutterTop="0.5rem"
                                color="textSecondary"
                                style={{fontWeight: 800}}
                                text="content">
                                Alle Leistungen
                            </WgTypography>
                        </Box>
                    )}
                </Box>
            </WgExpansionPanel>
        );
    }

    function getDetailList(
        process: CarInsuranceOneProductType,
        green?: boolean
    ) {
        var detailListNormal, detailListGreen, detailListGreenAddition;

        switch (process) {
            case CarInsuranceOneProductType.NEW_VEHICLE:
                detailListNormal = [
                    '24-Stunden Schadenhotline',
                    'Voll digital',
                    'Deckungssumme pauschal 100\u00a0Mio\u00a0€'
                ];
                detailListGreen = detailListNormal;
                detailListGreenAddition = ['CO2 neutraler Vertrag'];
                if (!green) return renderDetailList(detailListNormal);
                else
                    return [
                        renderDetailList(detailListGreenAddition, true),
                        renderDetailList(detailListGreen)
                    ];

            case CarInsuranceOneProductType.SWITCH_INSURANCE:
                detailListNormal = [
                    'Gleich oder mehr Leistung als in deinem bisherigen Vertrag',
                    'Unkomplizierter Wechsel'
                ];
                detailListGreen = [...detailListNormal];
                detailListNormal?.push(
                    '5 % von deinem aktuellen Beitrag sparen'
                );
                detailListGreenAddition = [
                    'CO2 neutraler Vertrag',
                    'Deine Beträge werden nachhaltig investiert',
                    'Aufforstung von Regenwäldern'
                ];
                if (!green) return renderDetailList(detailListNormal);
                else
                    return [
                        renderDetailList(detailListGreen),
                        renderDetailList(detailListGreenAddition, true)
                    ];
        }
    }

    function renderDetailList(detailList: any, colored?: boolean) {
        return (
            <WgEnumerationList
                enumerationIcon={
                    <img src="/img/icon-check-rounded.svg" alt="✓" />
                }
                background="default"
                dense={true}
                content={detailList}
                fullWidth
                textColor={colored ? 'secondary' : undefined}
            />
        );
    }

    function handleProposalSelection(eco: boolean) {
        const {
            setPolicyData,
            setProposalTarif,
            setEco,
            updateCalculationId,
            policyData
        } = props;

        const updatedPolicyData = {
            ...policyData,
            packageType: isCarOfferEco(eco)
        };

        updateCalculationId &&
            updateCalculationId(
                eco
                    ? proposal.green.calculationId
                    : proposal.normal.calculationId
            );
        setEco && setEco(eco);
        setProposalTarif &&
            setProposalTarif(eco ? proposal.green : proposal.normal);
        setPolicyData && setPolicyData(updatedPolicyData);
        props.updateCarInsurancePolicyData &&
            props.updateCarInsurancePolicyData(updatedPolicyData);
    }
}

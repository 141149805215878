import React, {Component} from 'react';
import {Box, Grid} from '@material-ui/core';
import {IStateSignup} from '../IStateSignup';
import './SignupStep.scss';
import {
    WgButton,
    WgContainer,
    WgSpeechBubble
} from '../../../../styles/CustomComponents';

interface IProps {
    text: React.ReactNode;
    step: number;
    setState: Function;
    state: IStateSignup;
    requirements?: string[];
    buttonText?: string;
}

interface IState extends IStateSignup {
    isInputValid: boolean;
}

export class SignupStep extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            ...props.state,
            isInputValid: this.areRequirementsMet(props)
        };
    }

    render(): React.ReactNode {
        return (
            <React.Fragment>
                <WgContainer
                    publicBodyContent
                    onChange={this.handleInput.bind(this)}
                    style={{flex: 1, marginTop: 0}}>
                    <Grid
                        item
                        xs
                        container
                        direction="column"
                        style={{maxWidth: '600px', alignSelf: 'center'}}>
                        <Grid
                            item
                            xs
                            container
                            direction="column"
                            wrap="nowrap">
                            <Grid item style={{flex: '0 1 50%'}}>
                                {this.renderStepText(this.props.text)}
                            </Grid>
                            <Grid item xs container style={{flex: '1 0 auto'}}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    paddingX="1.5rem"
                                    justifyContent="center"
                                    flex={1}>
                                    {this.renderStep()}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </WgContainer>
                <Grid item id="footer">
                    {this.renderNextButton(this.props.buttonText)}
                </Grid>
            </React.Fragment>
        );
    }

    renderStep(): React.ReactNode {
        throw new Error('Method not implemented.');
    }

    handleInput(event: any) {
        this.handleChange(event.target.name)(event, this.validate.bind(this));
    }
    validate() {
        return this.areRequirementsMet(this.props)
            ? this.setState({isInputValid: true})
            : this.setState({isInputValid: false});
    }

    areRequirementsMet(props: IProps) {
        return (
            !props.requirements ||
            props.requirements
                // @ts-ignore
                .map((key: string) => props.state[key])
                .reduce(
                    (previousValue: boolean, currentValue: boolean) =>
                        previousValue && currentValue
                )
        );
    }

    private renderNextButton(value = 'Weiter') {
        return (
            <Box p="1.5rem" display="flex" justifyContent="center">
                <WgButton
                    style={{alignSelf: 'center', maxWidth: '420px'}}
                    variant="contained"
                    color="primary"
                    fullWidth
                    aria-label="next"
                    type="submit"
                    disabled={!this.state.isInputValid}>
                    {value}
                </WgButton>
            </Box>
        );
    }

    renderStepText(text: React.ReactNode, isError = false) {
        return (
            <Box display="flex" flexDirection="column" p="1rem 1.5rem">
                <img
                    src="/img/logowechselgott.svg"
                    className="signupStep-img"
                    alt="wechselGott Logo"
                />
                <WgSpeechBubble isError={isError} text={text} />
            </Box>
        );
    }

    handleChange = (
        prop:
            | 'title'
            | 'firstName'
            | 'lastName'
            | 'birthDate'
            | 'gender'
            | 'street'
            | 'houseNumber'
            | 'addressAdditional'
            | 'postcode'
            | 'city'
            | 'country'
            | 'email'
            | 'password'
            | 'password_confirm'
            | 'phone'
            | 'code'
            | 'energy'
            | 'conditions'
    ) => (event: {target: {value?: any; name?: any}}, callback?: () => void) =>
        this.props.setState({[prop]: event.target.value}, callback);
}

import React from 'react';
import {IMessage} from '../../models/notificationInterface';
import {
    Box,
    Container,
    createStyles,
    IconButton,
    makeStyles,
    Paper,
    Theme
} from '@material-ui/core';
import {WgButtonBox, WgTypography} from '../../styles/CustomComponents';
import MyLink from './MyLink';
import Logo from './Logo';
import {ChevronRight, Clear} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        description: {
            color: theme.palette.common.white,
            marginLeft: '0.75rem',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical'
        },
        messageImage: {
            filter: 'grayscale(1) brightness(2)'
        }
    })
);

interface IProps {
    message: IMessage;
    onClose: any;
}

const Message = ({message, onClose}: IProps) => {
    const classes = useStyles();
    return (
        <Container>
            <Paper>
                <Box padding="1rem">
                    <IconButton
                        onClick={onClose}
                        style={{
                            float: 'right',
                            padding: '0',
                            marginTop: '-5px',
                            marginRight: '-5px'
                        }}>
                        <Clear />
                    </IconButton>

                    <WgTypography component="span">
                        <Box display="contents" fontWeight="900">
                            Hey {message.userName},
                        </Box>{' '}
                        {message.text}
                        <br />
                        {message.subtext}
                    </WgTypography>

                    {message.estimatedSavings && (
                        <WgTypography noWrap colored money number="formHeader">
                            {message.estimatedSavings}
                        </WgTypography>
                    )}
                </Box>
                <WgButtonBox
                    bgColor="secondary"
                    component={MyLink(message.link)}
                    clone={false}
                    borderRadius="bottom"
                    style={{justifyContent: 'space-between'}}
                    height="6.125rem">
                    <Box
                        marginLeft="-0.5rem"
                        color="common.white"
                        style={{
                            transform: 'rotate(-90deg)',
                            minWidth: 'max-content'
                        }}>
                        <WgTypography fontSize="0.75rem" header="uppercase">
                            VERTRAG
                        </WgTypography>
                    </Box>
                    <Box
                        display="flex"
                        flexGrow="1"
                        justifyContent="center"
                        alignItems="center"
                        color="White"
                        height="inherit">
                        <Logo
                            className={classes.messageImage}
                            logoPath={message.logoPath}
                            defaultImage={
                                message.defaultImage ??
                                '/img/icon-recurringpayment-circle.svg'
                            }
                        />

                        <WgTypography className={classes.description}>
                            {message.linkText}
                        </WgTypography>
                    </Box>
                    <IconButton component="div">
                        <ChevronRight style={{color: 'white'}} />
                    </IconButton>
                </WgButtonBox>
            </Paper>
        </Container>
    );
};

export default Message;

import {createAction} from 'redux-actions';
import {api, getAuthConfig, performApiCall} from './api';
import {updateRecurringpaymentSuccess} from './recurringpaymentActions';
import {FETCH_CANCELLATION_URL_SUCCESS} from './actionTypes';
import {recurringPaymentByIdSelector} from '../reducers/selectors';

export const fetchCancellationUrlSuccess = createAction(
    FETCH_CANCELLATION_URL_SUCCESS
);

export const fetchContractCancellation: any = (recurringPaymentId: number) =>
    performApiCall(
        performFetchContractCancellation(recurringPaymentId),
        'Kündigungsfunktion ist vorübergehend nicht verfügbar. Versuche es später noch einmal.'
    );

export const performFetchContractCancellation: Function = (
    recurringPaymentId: number
) => async (dispatch: Function, getState: Function) => {
    if (!getState()?.user?.token) {
        return;
    }

    const token = getState().user.token;
    const response = await api.get(
        `/contracts/${recurringPaymentId}/cancellation/iframe`,
        getAuthConfig(token, {Accept: 'application/json'})
    );
    dispatch(
        fetchCancellationUrlSuccess({
            url: response.data,
            recurringPaymentId
        })
    );
};

export const submitContractCancellation: any = (recurringPaymentId: number) =>
    performApiCall(
        performSubmitContractCancellation(
            recurringPaymentId,
            recurringPaymentByIdSelector
        ),
        'Kündigungsfunktion ist vorübergehend nicht verfügbar. Versuche es später noch einmal.'
    );

export const performSubmitContractCancellation: any = (
    recurringPaymentId: number,
    recurringPaymentByIdSelector: Function
) => async (dispatch: Function, getState: Function) => {
    if (!getState()?.user?.token) {
        return;
    }
    const token = getState().user.token;
    await api.post(
        `/contracts/${recurringPaymentId}/cancellation`,
        {},
        getAuthConfig(token, {Accept: 'application/json'})
    );

    const recurringpayment = recurringPaymentByIdSelector(getState(), {
        recurringPaymentId
    });
    dispatch(
        updateRecurringpaymentSuccess({
            ...recurringpayment,
            cancellationStatus: 'Canceled'
        })
    );
};

import React from 'react';
import {Box, Grid} from '@material-ui/core';
import WgSkeleton from '../WgSkeleton';

const DashboardHeaderSkeleton = () => {
    return (
        <Box
            p="0 2vh"
            display="flex"
            justifyContent="space-between"
            alignContent="flex-end">
            <Grid item xs={8} container direction="column">
                <Grid item container direction="row" alignContent="center">
                    <WgSkeleton
                        animation="wave"
                        style={{marginTop: '3px', marginBottom: '3px'}}
                        width="70%"
                        height={20}
                    />
                </Grid>
                <Box
                    m="0.5vh 0"
                    display="flex"
                    minWidth="fit-content"
                    justifyContent="flex-start"
                    alignItems="center">
                    <WgSkeleton
                        animation="wave"
                        style={{marginTop: '4px', marginBottom: '4px'}}
                        width="50%"
                        height={16}
                    />
                </Box>
            </Grid>
            <Grid item xs container direction="column" alignItems="flex-end">
                <WgSkeleton
                    animation="wave"
                    style={{marginTop: '3px', marginBottom: '3px'}}
                    width="50%"
                    height={20}
                />
            </Grid>
        </Box>
    );
};

export default DashboardHeaderSkeleton;

import {CompanyType, ICompany} from '../../models/companyInterface';
import {
    IProposal,
    IVehicleHolder,
    ICarInsuranceCompletionResponse,
    ICarInsuranceFinalResult
} from '../../models/productInterface';

export const fetchAllInsuranceProductsData = [
    {
        name: 'Haftpflicht',
        id: 1,
        logoPath: '5.svg'
    },
    {
        name: 'Pflegeversicherung',
        id: 2,
        logoPath: '5.svg'
    },
    {
        name: 'Altersvorsorge',
        id: 3,
        logoPath: '5.svg'
    },
    {
        name: 'Rechtsschutz',
        id: 4,
        logoPath: '5.svg'
    },
    {
        name: 'Haftpflicht',
        id: 5,
        logoPath: '5.svg'
    },
    {
        name: 'Wohngebaeude',
        id: 6,
        logoPath: '5.svg'
    },
    {
        name: 'Kfz Versicherung',
        id: 7,
        logoPath: '5.svg',
        url: '/products/purchase/car'
    }
];
export const testPolicies = [
    {
        insuranceType: 'Haftpflicht',
        id: 5,
        logoPath: '23.svg',
        amount: 25.3,
        recurringPaymentId: 3432,
        paymentInterval: 'MONTHLY',
        insuranceNumber: 2342523543645,
        tariffName: 'bliblablub',
        startDate: '23.02.1995',
        endDate: '29.01.2040',
        insurant: ['David Schemm'],
        companyId: 8712
    },
    {
        insuranceType: 'Rechtsschutz',
        id: 4,
        logoPath: '24.svg',
        amount: 22.3,
        recurringPaymentId: 32,
        paymentInterval: 'MONTHLY',
        insuranceNumber: 23422323543645,
        tariffName: 'dies das anannas',
        startDate: '23.02.1995',
        endDate: '29.01.2040',
        insurant: ['David Ittner'],
        companyId: 8712
    },
    {
        insuranceType: 'Pflegeversicherung',
        id: 8,
        logoPath: '24.svg',
        amount: 55.3,
        recurringPaymentId: 32,
        paymentInterval: 'MONTHLY',
        insuranceNumber: 23400235437238,
        tariffName: 'Pfleg mich!',
        startDate: '23.02.1995',
        endDate: '29.01.2040',
        insurant: ['Patrick Ittner'],
        companyId: 8712
    },
    {
        insuranceType: 'Unfallversicherung',
        id: 8,
        logoPath: '24.svg',
        amount: 55.3,
        recurringPaymentId: 32,
        paymentInterval: 'MONTHLY',
        insuranceNumber: 23439235437238,
        tariffName: 'autschi',
        startDate: '23.02.1995',
        endDate: '29.01.2040',
        insurant: ['Patrick Schemm'],
        companyId: 8712
    },
    {
        insuranceType: 'Rechtsschutz',
        id: 8,
        logoPath: '24.svg',
        amount: 55.3,
        recurringPaymentId: 32,
        paymentInterval: 'MONTHLY',
        insuranceNumber: 23422235937238,
        tariffName: 'Hau mich raus pls lelelel',
        startDate: '23.02.1995',
        endDate: '29.01.2040',
        insurant: ['Mike Ittner'],
        companyId: 8712
    },
    {
        insuranceType: 'Kfz-Versicherung',
        id: 9,
        logoPath: '24.svg',
        status: 'pending',
        amount: 89.3,
        paymentInterval: 'ANNUAL',
        insuranceNumber: 23422235937238,
        tariffName: 'Comfort',
        startDate: '2020-01-04T19:51:18.863Z',
        insuredRisk: ['XX XX 1234'],
        insurant: ['Mike Ittner'],
        companyId: 8712,
        vbNumber: 'AB15RT7'
    },
    {
        insuranceType: 'ROLLER-KFZ',
        id: 10,
        logoPath: '24.svg',
        status: 'available',
        insuranceNumber: 23422235937238,
        companyId: 8712
    }
];
export const allInsuranceCompanies: ICompany[] = [
    {
        id: 8401,
        name: 'Zwitserleven',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8452,
        name: 'Zusatzversorgungskasse Thüringen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7923,
        name: 'Zusatzversorgungskasse Hannover',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7773,
        name: 'Zürich Versicherungen über',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8185,
        name: 'Zurich Life Assurance Plc',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8082,
        name: 'Zurich Invest AG',
        logoPath: '4323.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8127,
        name: 'Zurich Insurance plc Niederlassung für Deutschland',
        logoPath: '4532.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8590,
        name: 'Zürich Gruppe Deutschland (S)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9044,
        name: 'Zurich Gruppe Deutschland',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7535,
        name: 'Zürich Agrippina Versicherung AG',
        logoPath: '4530.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7329,
        name: 'Zeus Vermittlungsgesellschaft mbH',
        logoPath: '4533.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8049,
        name: 'Zentral Boden Immobilien AG',
        logoPath: '4955.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8942,
        name: 'Zalando',
        logoPath: '4954.svg',
        companyType: CompanyType.MISC
    },
    {id: 8984, name: 'Yello Strom GmbH', companyType: CompanyType.MISC},
    {
        id: 8730,
        name: 'Yefa.de',
        logoPath: '4952.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8908,
        name: 'Yefa',
        logoPath: '4953.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 9045,
        name: 'WWK',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8217,
        name: 'Wüstenrot Bausparkasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6942,
        name: 'Würzburger Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8485,
        name: 'Württembergische Vertriebs-Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9001,
        name: 'Württembergische Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8857,
        name: 'Württembergische Gemeinde-Versicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7452,
        name: 'Wunderlich Financial Consulting GmbH',
        logoPath: '4538.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7883,
        name: 'Wunderlich & Partner Wirtschaftsberatung für den Mittelstand',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7728,
        name: 'WPD AG',
        logoPath: '4548.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7728,
        name: 'WPD AG',
        logoPath: '4548.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7236,
        name: 'worldfunds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8121, name: 'Wölbern Invest KG', companyType: CompanyType.INSURANCE},
    {
        id: 8318,
        name: 'WMF Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7628,
        name: 'WL BANK AG Westfälische Landschaft Bodenkreditbank',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7245,
        name: 'WirtschaftsHaus Service GmbH',
        logoPath: '4539.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6977,
        name: 'winsecura Pensionskasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8328,
        name: 'Windwärts Energie GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8827,
        name: 'Wikimedia Foerdergesellschaft',
        companyType: CompanyType.MISC
    },
    {
        id: 7067,
        name: 'Wieland Betriebskrankenkasse',
        logoPath: '4541.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7637,
        name: 'White Owl Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9019,
        name: 'WGV-Versicherungen',
        companyType: CompanyType.MISC
    },
    {id: 7058, name: 'WGV-Versicherung AG', companyType: CompanyType.INSURANCE},
    {
        id: 8491,
        name: 'WGV-SCHWÄBISCHE ALLGEMEINE VERSICHERUNG AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8484,
        name: 'WGV-Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7819,
        name: 'WGF Westfälische Grundbesitz und Finanzverwaltung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8359,
        name: 'WestInvest Gesellschaft für Investmentfonds mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8575,
        name: 'Westfälische Provinzial',
        logoPath: '4542.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8939, name: 'Westfalen AG', companyType: CompanyType.MISC},
    {
        id: 8982,
        name: 'Westdeutsche Lotterie',
        companyType: CompanyType.MISC
    },
    {
        id: 7865,
        name: 'Westdeutsche ImmobilienBank',
        logoPath: '4544.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7300,
        name: 'WERTGARANTIE AG',
        logoPath: '4551.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 87121235082,
        name: 'Wechselgott GmbH',
        logoPath: '4060.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 6990,
        name: 'Wealth Management Capital Holding GmbH',
        logoPath: '4545.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8102,
        name: 'WCP Dr. Westerhoff Capital Partners GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7829,
        name: 'Wattner AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7629,
        name: 'Watson Wyatt Heissmann GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7617,
        name: 'Warburg Invest Kapitalanlagegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8493, name: 'Walton Europe GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8064,
        name: 'Wallberg Invest S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8632,
        name: 'Waldenburger Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8343,
        name: 'Wald:Energie Verwaltungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7893, name: 'Wahl + Partner GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7204,
        name: 'Wacker Chemie AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7310,
        name: 'W.R. Berkley Insurance (Europe), Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8364,
        name: 'VSW Versicherergem. für Steuerberater und Wirtschaftsprüfer',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7649,
        name: 'VRK VVaG im Raum der Kirchen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8507,
        name: 'VR-Bank Bad Salzungen Schmalkalden eG',
        logoPath: '4569.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8786,
        name: 'VR-Bank',
        logoPath: '170.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8758,
        name: 'VR Payment GmbH',
        logoPath: '4570.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8826,
        name: 'VR Payment Abrechnungskonten (ehemals CardProcess)',
        companyType: CompanyType.MISC
    },
    {
        id: 8742,
        name: 'VR Meine Bank',
        logoPath: '4571.svg',
        companyType: CompanyType.MISC
    },
    {id: 9050, name: 'VPV Versicherung', companyType: CompanyType.INSURANCE},
    {
        id: 7772,
        name: 'VPV - Vereinigte Post. Die Makler-AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7418, name: 'VOV GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8007,
        name: 'Vorsorgekasse Hosch Dortmund VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7571,
        name: 'VORSORGE Luxemburg Lebensversicherung S.A',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8239,
        name: 'Vorarlberger Landesversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7379,
        name: 'Vorarlberger Landes- und Hypothekenbank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7843,
        name: 'Vontobel Fund',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8789,
        name: 'VonRueden, Prohaska and Bailey',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7756,
        name: 'Von Essen GmbH & Co KG Bankgesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8509,
        name: 'von der Heydt Invest SA',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7978,
        name: 'VOLKSWOHL BUND Sachversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8641,
        name: 'VOLKSWOHL BUND Lebensversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7077,
        name: 'Volkswohl Bund',
        logoPath: '4554.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6982,
        name: 'Volkswagen Versicherungsdienst VVD',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7020,
        name: 'Volkswagen Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7626,
        name: 'Volkswagen Autoversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7976,
        name: 'Volksfürsorge Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7397,
        name: 'Volksfürsorge Deutsche Sachversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7986,
        name: 'Volksfürsorge Deutsche Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8936, name: 'Volksbank Mittweida eG', companyType: CompanyType.MISC},
    {
        id: 7389,
        name: 'Volksbank Gescher eG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8966,
        name: 'Volksbank Dortmund-Nordwest eG',
        companyType: CompanyType.MISC
    },
    {
        id: 8986,
        name: 'Volksbank Bochum Witten eG',
        companyType: CompanyType.MISC
    },
    {id: 7130, name: 'Voigt & Coll. GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7596,
        name: 'VÖDAG, Zweigniederlassung der ADLER Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7095,
        name: 'VÖDAG Signal Iduna Gruppe',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8717,
        name: 'Vodafone GmbH',
        logoPath: '4334.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8532,
        name: 'Vivacon AG',
        logoPath: '4556.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8532,
        name: 'Vivacon AG',
        logoPath: '4556.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8349,
        name: 'Vitruvius Investmentgesellschaft (Sicav)',
        companyType: CompanyType.INSURANCE
    },
    {id: 8810, name: 'VISA', companyType: CompanyType.MISC},
    {
        id: 7615,
        name: 'VIP Vermögensberatung München GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7188,
        name: 'vigo Krankenversicherung VVaG',
        logoPath: '1683.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8330,
        name: 'Vienna-Life Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7542,
        name: 'VICTORIA Versicherung AG Dir. GStelle Fin. Sales Services',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7746,
        name: 'Victoria Versicherung AG',
        companyType: CompanyType.INSURANCE,
        logoPath: '4558.svg'
    },
    {
        id: 7392,
        name: 'Victoria Pensionskasse AG',
        logoPath: '4564.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8281,
        name: 'Victoria Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7238,
        name: 'Victoria Krankenversicherung AG',
        logoPath: '4559.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7174,
        name: 'VIACTIV Krankenkasse',
        logoPath: '4576.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8689,
        name: 'VHV Lebensversicherung AG',
        logoPath: '4331.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8164,
        name: 'VHV Allgemeine Versicherungen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8367,
        name: 'VHV Allgemeine Versicherung AG',
        logoPath: '4332.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8749,
        name: 'VGN',
        companyType: CompanyType.MISC
    },
    {
        id: 8266,
        name: 'VGH Versicherungen Hannover',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7187,
        name: 'VGH Landw. Brandkasse Osnabrück',
        logoPath: '4578.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9060,
        name: 'VGH - Provinzial Lebensversicherung Hannover',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9062,
        name: 'VGH - Landschaftliche Brandkasse Hannover',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7380,
        name: 'Verwaltungs-Berufsgenossenschaft (VBG)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8389,
        name: 'Verti Versicherung AG',
        logoPath: '4560.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7523,
        name: 'Versorgungswerk der Presse GmbH',
        logoPath: '4561.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7027,
        name: 'Versorgungskasse Energie (EON)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7650,
        name: 'Versicherungsverband Deutscher Eisenbahnen VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7212,
        name: 'Versicherungsstelle Wiesbaden',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8799,
        name: 'Versicherungskammer Bayern - VdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9009,
        name: 'Versicherungskammer Bayern',
        companyType: CompanyType.MISC
    },
    {
        id: 7105,
        name: 'Vers. Gem. f.d. wirtsch. Prüf.-und Treuhandwesen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7039,
        name: 'VERKA Kirchliche Pensionskasse VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8227,
        name: 'Veritas SG Investment Trust GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7964,
        name: 'Vereinte Spezial Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7425,
        name: 'Vereinte Spezial Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8940,
        name: 'Vereinte Dienstleistungsgewerkschaft',
        companyType: CompanyType.MISC
    },
    {
        id: 7526,
        name: 'Vereinsbank Victoria Bauspar AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7178,
        name: 'Vereins- und Westbank Hannover',
        logoPath: '4565.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8296,
        name: 'Vereinigte Versicherungsgesellschaft von Amerika',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7552,
        name: 'VEREINIGTE TIERVERSICHERUNG GESELLSCHAFT a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7348,
        name: 'Vereinigte Schiffs-Versicherung V.a.G.',
        logoPath: '4562.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6961,
        name: 'Vereinigte Hagelversicherung VVaG',
        logoPath: '4563.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7083, name: 'Ventafonds GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8639,
        name: 'VenGrow Corporate Finance AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8563,
        name: 'VdVA - Versicherungsservice',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8017,
        name: 'VCH Investment Group AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7886,
        name: 'VBV - Vorsorgekasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9064,
        name: 'VBL - Versorgungsanstalt des Bundes und der Länder',
        companyType: CompanyType.INSURANCE
    },
    {id: 8235, name: 'VAV Versicherungen', companyType: CompanyType.INSURANCE},
    {
        id: 8846,
        name: 'Vattenfall',
        companyType: CompanyType.MISC
    },
    {id: 8950, name: 'Vapiano', companyType: CompanyType.MISC},
    {
        id: 8613,
        name: 'Value Management & Research AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8651,
        name: 'VALORSOL Solarfonds GmbH & Co. KG, Köln',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7962,
        name: 'VALERUM Invest AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8134, name: 'Valartis Bank', companyType: CompanyType.INSURANCE},
    {
        id: 8790,
        name: 'VAG Verkehrs-Aktiengesellschaft',
        companyType: CompanyType.MISC
    },
    {id: 8937, name: 'VAG Verkehrs-AG', companyType: CompanyType.MISC},
    {
        id: 8547,
        name: 'V&F Vermögens- und Finanzierungs-Treuhand AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7075,
        name: 'US Treuhand Verwaltungsges. f. US ImmoFonds mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7918,
        name:
            'Unterstützungskasse der Bayrischen Steine- und Erden-Industrie e.V',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8115,
        name: 'Universal-Investment-Gesellschaft mbH',
        logoPath: '4580.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9027,
        name: 'uniVersa',
        companyType: CompanyType.INSURANCE
    },
    {id: 8959, name: 'Unitymedia', companyType: CompanyType.MISC},
    {
        id: 8497,
        name: 'United Services Automobile Association Direktion Deutschland',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7765,
        name: 'United Investors & Cie Gesellschaft zur Emission von Beteili',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7124,
        name: 'UNIQA Versicherungen AG',
        logoPath: '4584.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8011,
        name: 'Union Reiseversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8070,
        name: 'Union Investment Service Bank AG',
        logoPath: '4581.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8541,
        name: 'Union Investment Luxemburg S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8317,
        name: 'Union Invesment GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8996,
        name: 'UNION',
        companyType: CompanyType.INSURANCE
    },
    {id: 8960, name: 'UniCredit Bank AG', companyType: CompanyType.MISC},
    {
        id: 7324,
        name: 'ungültige PG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7914,
        name: 'ungültige Gesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7242,
        name: 'UNED c/o Nemian Life',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8194,
        name: 'UNBEKANNTE GESELLSCHAFT',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7721,
        name: 'UKW - Gruppenunterstützungskasse',
        companyType: CompanyType.INSURANCE
    },
    {id: 7849, name: 'Ufba e.V.', companyType: CompanyType.INSURANCE},
    {
        id: 7992,
        name: 'Uelzener Lebensversicherungs- AG',
        logoPath: '4583.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8223,
        name: 'Uelzener Allgemeine Versicherungs-Gesellschaft a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8125,
        name: 'UBS(Luxemburg) S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7430,
        name: 'UBS Invest Kapitalanlagegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8517,
        name: 'UBS Global Asset Management',
        companyType: CompanyType.INSURANCE
    },
    {id: 7022, name: 'UBAM (SICAV)', companyType: CompanyType.INSURANCE},
    {
        id: 8811,
        name: 'TÜV Süd',
        companyType: CompanyType.MISC
    },
    {id: 7205, name: 'TÜRKISFUND SICAV', companyType: CompanyType.INSURANCE},
    {
        id: 7561,
        name: 'TUI Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7768,
        name: 'TSO Europe Funds, Inc.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8695,
        name: 'TRIAS Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7307,
        name: 'Trendinvest Beratungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8072,
        name: 'Trend Capital Unternehmensberatung für Finanzen & Immobilien',
        companyType: CompanyType.INSURANCE
    },
    {id: 7722, name: 'TravelProtect GmBH', companyType: CompanyType.INSURANCE},
    {
        id: 8951,
        name: 'transact Elektronische Zahlungssysteme GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7253,
        name: 'Traditional Funds PLC',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8225,
        name: 'Toyota Versicherungsdienst',
        companyType: CompanyType.INSURANCE
    },
    {id: 8650, name: 'Towers Watson', companyType: CompanyType.INSURANCE},
    {
        id: 8920,
        name: 'TOTAL Deutschland GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7651,
        name: 'Tomorrow Fund Management (Germany) GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8774,
        name: 'Todoist.com',
        companyType: CompanyType.MISC
    },
    {
        id: 8210,
        name: 'TMW Pramerica Property Investment GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8157,
        name: 'TKK',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8567,
        name: 'Tierärzteversorgung Niedersachsen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7013,
        name: 'Thüringer Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {id: 8783, name: 'Thuisbezorgd.nl', companyType: CompanyType.MISC},
    {
        id: 7857,
        name: 'Thüga Schadenausgleichskasse München VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7255,
        name: 'Threadneedle Investment Services Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8098,
        name: 'Threadneedle (LUX)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7668,
        name: 'ThomasLloyd Private Wealth Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8152,
        name: 'Thieme Asset Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8196,
        name: 'The Global Fine Art GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8348,
        name: 'TEXXOL Verwaltungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8520,
        name: 'Testanbieter gut billig fix toll',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7377,
        name: 'TerraMetal',
        logoPath: '4586.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7206, name: 'Templeton Inc.', companyType: CompanyType.INSURANCE},
    {
        id: 7149,
        name: 'Templeton Global Strategic Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8686,
        name: 'Tellit Direct Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8513,
        name: 'Telekom-Pensionsfonds a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8706,
        name: 'Telekom Deutschland GmbH',
        logoPath: '4587.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8887,
        name: 'Telefonica Germany',
        companyType: CompanyType.MISC
    },
    {id: 8766, name: 'Telefonica', companyType: CompanyType.MISC},
    {
        id: 7422,
        name: 'telcon Vers.-AG/DBV',
        companyType: CompanyType.INSURANCE
    },
    {id: 8917, name: 'Tedi', companyType: CompanyType.MISC},
    {
        id: 8664,
        name: 'tecis Asset Management AG',
        logoPath: '4935.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7664,
        name: 'TECHNO VersicherungsdienstGgmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7922,
        name: 'Techniker Krankenkasse',
        logoPath: '4588.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8991, name: 'TeamBank AG', companyType: CompanyType.MISC},
    {
        id: 8993,
        name: 'Tchibo',
        companyType: CompanyType.MISC
    },
    {id: 8962, name: 'Targobank AG', companyType: CompanyType.MISC},
    {
        id: 8162,
        name: 'TARGO Versicherung AG',
        logoPath: '4591.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8366,
        name: 'TARGO Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7801,
        name: 'SYNCRO24-assekuradeur GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8159, name: 'SWORN Capital GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8888,
        name: 'SWM Versorgungs GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7345,
        name: 'SWISSCANTO ASSET MANAGEMENT INTERNATIONAL S.A.',
        logoPath: '4633.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 9032, name: 'Swiss Life', companyType: CompanyType.INSURANCE},
    {
        id: 7308,
        name: 'Swiss Investors Capital PLC',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7980,
        name: 'Swiss Invest Group (Deutschland) GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8498,
        name: 'SVF Service für Vermögensplanung und Finanzen AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7641,
        name: 'SV SparkassenVersicherung Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8800,
        name: 'SV SparkassenVersicherung Holding AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8137,
        name: 'SV SparkassenVersicherung Gebäudeversicherung AG',
        logoPath: '4634.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7702,
        name: 'SV Sparkassen Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7560,
        name: 'Sutor Bank',
        logoPath: '4598.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8768, name: 'Sushi Edo Nürnberg', companyType: CompanyType.MISC},
    {
        id: 8765,
        name: 'Sushi Akimoto Nürnberg',
        companyType: CompanyType.MISC
    },
    {id: 8817, name: 'Supol', companyType: CompanyType.MISC},
    {
        id: 8293,
        name: 'Superfund Asset Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6966,
        name: 'Super Web Fund Emissionshaus GmbH',
        logoPath: '4599.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7133,
        name: 'SunProjekt WM UG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8850, name: 'SumUp', companyType: CompanyType.MISC},
    {
        id: 7165,
        name: 'Südzucker Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7753,
        name: 'Süddeutsche Lebensversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7805,
        name: 'Süddeutsche Krankenversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7511,
        name: 'Süddeutsche Allgemeine Versicherung a.G.',
        logoPath: '4592.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9025,
        name: 'Stuttgarter Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7718,
        name: 'Stuttgarter Lebensversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6943,
        name: 'Studio Hamburg WorldWide Pictures GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7456,
        name: 'Structured Invest S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8972,
        name: 'Stromio GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7096,
        name: 'Stromenger Consulting Allinvest Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8151,
        name: 'Stonebridge International Insurance',
        companyType: CompanyType.INSURANCE
    },
    {id: 8941, name: 'Steuerverwaltung NRW', companyType: CompanyType.MISC},
    {
        id: 8068,
        name: 'Steuerberaterversorgung Niedersachsen',
        logoPath: '4600.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7983,
        name: 'Sterling Insurance Company Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7630,
        name: 'Steiner + Company Gmbh & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7638,
        name: 'State Street Global Advisors France S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7284,
        name: 'start:bausparkasse AG',
        logoPath: '4933.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7723,
        name: 'Starpool Finanz GmbH',
        logoPath: '4603.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7494,
        name: 'StarCapital S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7011,
        name: 'Standard Life Investments Global SICAV',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8895,
        name: 'Stadtwerke Neustadt an der Aisch',
        companyType: CompanyType.MISC
    },
    {
        id: 9004,
        name: 'Stadtwerke Flensburg GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8985,
        name: 'Stadtwerke Bochum',
        companyType: CompanyType.MISC
    },
    {
        id: 8821,
        name: 'Stadtsparkasse München',
        logoPath: '4933.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8987,
        name: 'Stadt Bochum',
        companyType: CompanyType.MISC
    },
    {id: 8933, name: 'Staatsoberkasse Bayern', companyType: CompanyType.MISC},
    {
        id: 7533,
        name: 'St. Paul Travelers Insurance Company Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7816,
        name: 'St. Martinus Priesterverein',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8005,
        name: 'St. Martinus - verbundene Hausratversicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7211,
        name: 'St. Martinus - Kranken und Sterbekasse - VVAG Stutttgart',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7935,
        name: 'SSI Renditelager GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7738, name: 'SRQ PMA', companyType: CompanyType.INSURANCE},
    {
        id: 8830,
        name: 'Spotify',
        companyType: CompanyType.MISC
    },
    {
        id: 7335,
        name: 'Sparkassen-Versicherung Sachsen',
        logoPath: '4607.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7856,
        name: 'Sparkassen Pensionskasse AG',
        logoPath: '4608.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8224,
        name: 'Sparkassen DirektVersicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8390,
        name: 'Sparkasse Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8458,
        name: 'Sparkasse Nürnberg - Anstalt des öffentlichen Rechts',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8709,
        name: 'Sparkasse Nürnberg',
        logoPath: '4933.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8715,
        name: 'Sparkasse Neustadt a. d. Aisch',
        companyType: CompanyType.MISC
    },
    {
        id: 8772,
        name: 'Sparkasse Mainfranken Würzburg',
        logoPath: '4933.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8974,
        name: 'Sparkasse KölnBonn',
        logoPath: '4933.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8723,
        name: 'Sparkasse Fürth',
        logoPath: '4933.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7867,
        name: 'Sparkasse Fulda',
        logoPath: '4933.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8696,
        name: 'Sparkasse Erlangen',
        companyType: CompanyType.INSURANCE
    },
    {id: 7538, name: 'Sparkasse Bregenz', companyType: CompanyType.INSURANCE},
    {
        id: 8781,
        name: 'Sparkasse Bamberg',
        logoPath: '4933.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8559,
        name: 'Sparkasse (Allgemein)',
        logoPath: '4609.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7959,
        name: 'Sparinvest S.A.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8981, name: 'Sparda Bank West', companyType: CompanyType.MISC},
    {
        id: 8977,
        name: 'Sparda Bank München eG',
        companyType: CompanyType.MISC
    },
    {
        id: 8308,
        name: 'Sparda Bank Berlin eG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8608,
        name: 'Spängler IQAM Invest GmbH',
        logoPath: '4605.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7443,
        name: 'Sozialversicherung für Landwirtschaft, Forsten und Gartenbau',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7166,
        name: 'Sovag Versicherung',
        logoPath: '4615.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8038,
        name: 'SOVAG - SCHWARZMEER UND OSTSEE Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8362,
        name: 'Sony Deutschland GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8496,
        name: 'Sontowski & Partner gmbH , Erlangen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7943,
        name: 'Sonstige Kreditinstitute (Allgemein)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7911,
        name: 'Sonstige Fondsgesellschaft (Allgemein)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7438,
        name: 'Sonstige Dienstleistungen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7658,
        name: 'Sonstige Banken',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8405,
        name: 'SONO Krankenversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7340,
        name: 'Solvium Capital GmbH',
        logoPath: '4616.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7126, name: 'SOLVAY GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8612,
        name: 'SOLIT Kapital GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8808,
        name: 'SOLIDAR Versicherungsgemeinschaft SterbegeldversicherungVVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7361,
        name: 'Solidar Sterbegeldversicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8620,
        name: 'SOLID VALUE GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7609, name: 'SolEs GmbH & Co. KG', companyType: CompanyType.INSURANCE},
    {
        id: 7415,
        name: 'Solarparc AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8410, name: 'Solarcomplex GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7306,
        name: 'SOKA-BAU',
        logoPath: '4635.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6999,
        name: 'SOGECAP RISQUES DIVERS',
        logoPath: '4637.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7826,
        name: 'Societé Generale',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8006,
        name: 'smava GmbH',
        logoPath: '4934.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8006,
        name: 'smava GmbH',
        logoPath: '4934.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8929,
        name: 'smartvillage GmbH',
        companyType: CompanyType.MISC
    },
    {id: 8892, name: 'Sky Deutschland GmbH', companyType: CompanyType.MISC},
    {
        id: 8771,
        name: 'Sky Deutschland',
        logoPath: '4330.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7295,
        name: 'Skoda Versicherungs Service',
        logoPath: '4617.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8132,
        name: 'SKG Bank AG',
        logoPath: '4636.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8415,
        name: 'SKD Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8119,
        name: 'SKANDIA Sachversicherung AG',
        logoPath: '4638.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8133,
        name: 'Skandia PortfolioManagement GmbH',
        logoPath: '4620.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7557,
        name: 'Skandia Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8726, name: 'Sixt SE', companyType: CompanyType.MISC},
    {
        id: 7503,
        name: 'SIP Grundbesitz & Anlagen AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8998, name: 'Singh, Kumar Ram GbR', companyType: CompanyType.MISC},
    {
        id: 7195,
        name: 'SIGNAL Unfallversicherung a.G.',
        logoPath: '4639.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 9000, name: 'SIGNAL IDUNA', companyType: CompanyType.INSURANCE},
    {
        id: 8669,
        name: 'Siemens Pensionsfonds Aktiengesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8220,
        name: 'Siemens Kapitalanlagegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7273,
        name: 'SIEMAG Betriebskrankenkasse',
        logoPath: '4643.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8094,
        name: 'Sielhorster Feuerversicherungsverein a. G',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8778,
        name: 'Shift Technologies Inc.',
        logoPath: '4622.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8697,
        name: 'Shell Deutschland Oil GmbH',
        logoPath: '4328.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8014,
        name: 'Shedlin Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7197,
        name: 'SHB Innovative Fondskonzepte AG',
        logoPath: '4645.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7283,
        name: 'SHB Allgemeine Versicherung VVaG',
        logoPath: '4644.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8660, name: 'SG-Treuhand GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8719,
        name: 'SelfStorage Dein Lagerraum GmbH',
        logoPath: '4625.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8736,
        name: 'Selfstorage Dein Lagerraum GmbH',
        logoPath: '4624.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7795,
        name: 'Selfmade Capital Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7140,
        name: 'Sekur Assekuranz GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7616,
        name: 'SECURVITA Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8201,
        name: 'Securitas Bremer Allgemeine Versicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7131,
        name: 'SecuRat Die Versicherungsmakler',
        logoPath: '4626.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8903, name: 'secupay AG', companyType: CompanyType.MISC},
    {
        id: 7769,
        name: 'SEB Invest',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7312,
        name: 'SEAT Versicherungsservice',
        logoPath: '4651.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8177,
        name: 'SDI Vertriebs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7465,
        name: 'Schweizer-National Versicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8010,
        name: 'Schwarzwälder Versicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7584, name: 'Schwäbisch Hall', companyType: CompanyType.INSURANCE},
    {
        id: 8301,
        name: 'Schutzverein Deutscher Rheder V.a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7268,
        name: 'Schutzbrief 24',
        logoPath: '4627.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8746,
        name: 'SCHUFA Holding AG',
        logoPath: '4074.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 6953,
        name: 'Schroder Investment Management',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8580,
        name: 'Schröder & Co. Gesellschaft für Beteiligungen mbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6985,
        name: 'Schneverdinger Versicherungsverein a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7897,
        name: 'Schleswiger Versicherungsverein a.G.',
        logoPath: '4629.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7327,
        name: 'Schleswiger Versicherungsservice  AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9053,
        name: 'SBK - Siemens-Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7708,
        name: 'SBAI S.A. Deutsche Niederlassung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9013,
        name: 'SB Tankstelle',
        companyType: CompanyType.MISC
    },
    {id: 8967, name: 'SB Tank', companyType: CompanyType.MISC},
    {
        id: 7316,
        name: 'Sauren Fonds-Service AG',
        logoPath: '4631.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8952, name: 'Saturn', companyType: CompanyType.MISC},
    {
        id: 8044,
        name: 'Sarasin Investmentfonds SICAV',
        companyType: CompanyType.INSURANCE
    },
    {id: 7487, name: 'Sarasin & Cie AG', companyType: CompanyType.INSURANCE},
    {
        id: 7824,
        name: 'SAP AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8820,
        name: 'Santander Consumer Bank AG',
        companyType: CompanyType.MISC
    },
    {
        id: 7161,
        name: 'Salus Betriebskrankenkasse',
        logoPath: '4650.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8483,
        name: 'Salomon & Partner Invest GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8655,
        name: 'SALI Management Services Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {id: 7018, name: 'Salamon AG', companyType: CompanyType.INSURANCE},
    {
        id: 7896,
        name: 'SafetyCard Reiseversicherungen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8378,
        name: 'SachWert Vermögensaufbau Fonds Nr.1 GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7045,
        name: 'SachsenFonds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7880,
        name: 'SACHPOOL Service GbR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8672,
        name: 'Sach-und Haftpflichtversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8522,
        name: 'SAB Spar- und Anlageberatung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7509,
        name: 'Saarland Versicherungen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8665,
        name: 'SAARLAND Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8059,
        name: 'SAARLAND Feuerversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8057,
        name: 'S-Pensionsmanagement GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7954,
        name: 'S direkt Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7906, name: 'RWB AG', companyType: CompanyType.INSURANCE},
    {
        id: 8825,
        name: 'Rundfunk ARD, ZDF, DRadio',
        companyType: CompanyType.MISC
    },
    {id: 8837, name: "Ruff's Burger", companyType: CompanyType.MISC},
    {
        id: 8710,
        name: 'RSG Group GmbH',
        logoPath: '4076.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8503,
        name: 'RS Reiseschutz Versicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7225,
        name: 'RREEF Investment GmbH',
        logoPath: '4662.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7371,
        name: 'Royal International Insurance Holdings Ltd.',
        logoPath: '4655.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8104,
        name: 'Rothschild Investment',
        logoPath: '4656.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8259,
        name: 'Rothmann & Cie. AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8911, name: 'Rossmann Drogeriemarkt', companyType: CompanyType.MISC},
    {
        id: 8449,
        name: 'Rosenheimer Unterstützungskasse e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6955,
        name: 'ROLAND Schutzbrief-Versicherung AG',
        logoPath: '4077.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7362,
        name: 'ROLAND Rechtsschutz-Versicherungs-AG',
        logoPath: '4078.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 9010, name: 'ROLAND Rechtsschutz AG', companyType: CompanyType.MISC},
    {
        id: 8303,
        name: 'Rodenbach Projektentwicklung GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7134, name: 'robstus GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7653,
        name: 'Robinia Invest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7021,
        name: 'Robeco',
        logoPath: '4075.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7021,
        name: 'Robeco',
        logoPath: '4075.jpeg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8172,
        name: 'RMV Reise Mobil Versicherungs-Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8729, name: 'RITUALS Cosmetics', companyType: CompanyType.MISC},
    {
        id: 8907,
        name: 'Rituals',
        companyType: CompanyType.MISC
    },
    {id: 7396, name: 'Rising STAR AG', companyType: CompanyType.INSURANCE},
    {
        id: 7041,
        name: 'Rhion Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7435,
        name: 'Rhion Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7645,
        name: 'RheinLand Versicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8594,
        name: 'RheinLand Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8316,
        name: 'Rheinische Versorgungskassen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7510,
        name: 'Rheinisch-Westfälische Sterbekasse Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8731,
        name: 'Rheinadler Fashipon',
        companyType: CompanyType.MISC
    },
    {id: 8909, name: 'Rheinadler Fashion', companyType: CompanyType.MISC},
    {
        id: 8777,
        name: 'REWE',
        logoPath: '4663.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7965,
        name: 'Resticon GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7441,
        name: 'reskap GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8302, name: 'Renk Hannover', companyType: CompanyType.INSURANCE},
    {
        id: 8847,
        name: 'Registeranzeiger GmbH',
        companyType: CompanyType.MISC
    },
    {id: 7473, name: 'Reconcept GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8533,
        name: 'Realkonzepte GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7834,
        name: 'Real I.S. AG, Gesellschaft für Immobilien Assetmanagement',
        logoPath: '4658.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7181,
        name: 'Real Garant Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8956,
        name: 'Real',
        companyType: CompanyType.MISC
    },
    {
        id: 8216,
        name: 'RBS (Luxembourg) S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8795,
        name: 'Rath LLC',
        companyType: CompanyType.INSURANCE
    },
    {id: 8923, name: 'RatePAY GmbH', companyType: CompanyType.MISC},
    {
        id: 8833,
        name: 'Raiffeisenbank',
        logoPath: '177.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7640,
        name: 'Raiffeisen-Kapitalanlage-Gesellschaft m.b.H',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7963,
        name: 'R+V24 Direktversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8190,
        name: 'R+V RECHTSSCHUTZVERSICHERUNG AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8588,
        name: 'R+V Pensionskasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7156,
        name: 'R+V LEBENSVERSICHERUNG AG',
        logoPath: '4664.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8505,
        name: 'R+V KRANKENVERSICHERUNG AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7910,
        name: 'R+V Direktversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8291,
        name: 'R+V Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8256,
        name: 'R+V Allgemeine Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7122,
        name: 'R+P Dr. Röhr Corporate Finance AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7375,
        name: 'R+ V Gruppenpensionsfonds AG',
        logoPath: '4666.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7589,
        name: 'R-Quadrat Capital Gamma GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7410,
        name: 'Quint:Essence Capital S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8145,
        name: 'Quelle Bauspar AG',
        logoPath: '4667.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8019, name: 'Quantum Leben AG', companyType: CompanyType.INSURANCE},
    {
        id: 7010,
        name: 'PVAG Polizeiversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8092,
        name: 'Putnam',
        logoPath: '4668.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8092,
        name: 'Putnam',
        logoPath: '4668.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8166,
        name: 'Pure Blue GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8577,
        name: 'Publity Investor GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8569,
        name: 'PT Erneuerbare Energien GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9029,
        name: 'PSD Bank',
        logoPath: '4683.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9029,
        name: 'PSD Bank',
        logoPath: '4683.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7515,
        name: 'PRUDENTIAL  Int. Ass. plc.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7613,
        name: 'PRUDENTIA-Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7434,
        name: 'ProzessGarant AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8876,
        name: 'Proxalto Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7545,
        name: 'Provinzial Rheinland Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7169,
        name: 'Provinzial Rheinland Lebensversicherung AG',
        logoPath: '4669.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8275,
        name: 'Provinzial NordWest Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7150,
        name: 'Provinzial Nord Brandkasse AG',
        logoPath: '4670.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7594,
        name: 'Provinzial Krankenversicherung Hannover AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7305,
        name: 'Protektor Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7811,
        name: 'ProTect Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7899,
        name: 'pronova Betriebskrankenkasse',
        logoPath: '4517.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7160, name: 'Prokon Capital GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7338,
        name: 'Project Vermittlungs GmbH',
        logoPath: '4671.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8592, name: 'Project Fonds GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7572,
        name: 'Prohyp GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7891,
        name: 'Procron GmbH',
        logoPath: '4672.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7891,
        name: 'Procron GmbH',
        logoPath: '4672.jpeg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8413,
        name: 'Procheck24 AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7705,
        name: 'Proba Private Equity Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8644,
        name: 'Pro bAV Pensionskasse AG',
        logoPath: '4673.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7699,
        name: 'Private FLEX Fonds GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8435,
        name: 'Privatconsult Vermögensverwaltung GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8501, name: 'PrismaLife AG', companyType: CompanyType.INSURANCE},
    {
        id: 8823,
        name: 'Printy',
        companyType: CompanyType.MISC
    },
    {
        id: 7486,
        name: 'Primus Valor AG',
        logoPath: '4675.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8265,
        name: 'PRIMEA INVEST Vertriebs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8123,
        name: 'Prima Management AG (Luxemburg)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8178,
        name: 'prevet OHG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7657, name: 'Pretagus GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7089,
        name: 'Presse Versorgung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7740,
        name: 'Premicon AG',
        logoPath: '4676.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7740,
        name: 'Premicon AG',
        logoPath: '4676.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7968,
        name: 'praenatura Versicherungsverein auf Gegenseitigkeit',
        companyType: CompanyType.INSURANCE
    },
    {id: 9003, name: 'PPRO Financial Ltd', companyType: CompanyType.MISC},
    {
        id: 8886,
        name: 'Postbank',
        logoPath: '140.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7001,
        name: 'Porsche Versicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8535,
        name: 'Policen Direkt Versicherungsvermittlung GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8243,
        name: 'POC - Proven Oil Canada GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7931,
        name: 'PMI Mortgage Insurance Co.Ltd.',
        logoPath: '4685.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8999, name: 'PM INTERNATIONAL AG', companyType: CompanyType.MISC},
    {
        id: 7744,
        name: 'PM Fonds Verwaltungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7381, name: 'plusForta GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 9054,
        name: 'PLUS Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7139,
        name: 'Playland Virtual Games Fonds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8130,
        name: 'PlasticsEurope Deutschland e.V.',
        logoPath: '4678.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8835, name: 'Plan International', companyType: CompanyType.MISC},
    {
        id: 7775,
        name: 'PIMCO Funds: Global Investors Series plc',
        companyType: CompanyType.INSURANCE
    },
    {id: 8273, name: 'Pictet Funds GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7009,
        name: 'Picard Solar GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8545, name: 'Piamo Invest AG', companyType: CompanyType.INSURANCE},
    {
        id: 8183,
        name: 'PI Pro-Investor GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8437,
        name: 'Photovoltaikversicherung24',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8600,
        name: 'Phoenix Investor SK, s.r.o.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7927,
        name: 'Phoenix Investor CZ, s.r.o.',
        logoPath: '4679.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8386,
        name: 'Phoenix Investor AT, s.r.o.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8430, name: 'PHARMAssec GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8976,
        name: 'PHANtastic Asian Cuisine Nürnberg',
        companyType: CompanyType.MISC
    },
    {
        id: 7355,
        name: 'PENSOR Pensionsfonds AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7985,
        name: 'Pensionsversicherungsanstalt - Landesstelle Wien',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8054,
        name: 'Pensionskasse für Ernährung und Genuss',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7758,
        name: 'Pensionskasse für die Deutsche Wirtschaft VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8527,
        name: 'PENSIONS-SICHERUNGS-VEREIN VVaG',
        logoPath: '4688.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8685,
        name: 'PEH-Quintessenz Sicav',
        companyType: CompanyType.INSURANCE
    },
    {id: 8607, name: 'PEH Wertpapier AG', companyType: CompanyType.INSURANCE},
    {
        id: 7448,
        name: 'PCE Premium Capital Emissionshaus GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8203,
        name: 'PBV Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9063,
        name: 'PBeaKK - Postbeamtenkrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8253,
        name: 'pbb Deutsche Pfandbriefbank AG',
        logoPath: '4518.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7501,
        name: 'PB Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8051,
        name: 'PB Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8721,
        name: 'PayPal',
        logoPath: '4958.svg',
        companyType: CompanyType.MISC
    },
    {id: 8924, name: 'PAYONE GmbH', companyType: CompanyType.MISC},
    {
        id: 7385,
        name: 'PAX-FAMILIENFÜRSORGE Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7585,
        name: 'PAX Schweizerische LV-Gesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8369,
        name: 'Patrizia Grundinvest',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7040,
        name: 'Patriarch Multi-Manager GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7627,
        name: 'Patria/Zürich Versicherung',
        logoPath: '4680.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7810,
        name: 'PAS Dr. Hammerl GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8255,
        name: 'Partnerco GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7779, name: 'Partner in Life', companyType: CompanyType.INSURANCE},
    {
        id: 8926,
        name: 'Parkraumgesellschaft Baden-Württemberg mbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8556,
        name: 'Paribus Capital GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7926,
        name: 'Parelli Beteiligungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7872,
        name: 'PANTHERA Asset Management Consulting GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8078,
        name: 'pantera AG',
        logoPath: '4646.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8240,
        name: 'Pantaenius GmbH',
        logoPath: '4681.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7219,
        name: 'Pallas Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8358,
        name: 'P&R Container Vertriebs- und Verwaltungs-GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7200,
        name: 'Oyster Asset Management S.A.',
        logoPath: '4690.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8372,
        name: 'OYAK ANKER Bank GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7937,
        name: 'Ownership Emissionshaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7961,
        name: 'OVD/GMAC VersicherungsService GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7813,
        name: 'OVB',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7463,
        name: 'OVAG Ostdeutsche Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8807,
        name: 'ottonova Krankenversicherung AG',
        logoPath: '4929.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7553,
        name: 'Ostsächsische Sparkasse Dresden',
        logoPath: '4933.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7401,
        name: 'Ostfriesische Versicherungsbörse GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7036,
        name: 'Ostfriesische Landschaftliche Brandkasse - AdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7724,
        name: 'Österreichischer Beamtenversicherung',
        logoPath: '4514.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7216,
        name: 'Österreichische Volksbanken-AG',
        logoPath: '4336.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7583,
        name: 'Ostbeverner VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7508,
        name: 'Ostangler Brandgilde VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8099,
        name: 'ÖSA Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {id: 8921, name: 'Orlen Deutschland GmbH', companyType: CompanyType.MISC},
    {
        id: 7894,
        name: 'Orbitex Investment Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7081,
        name: 'ÖRAG Rechtsschutzversicherungs-AG',
        logoPath: '4515.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7568,
        name: 'Optima Versicherung-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7606,
        name: 'Optima Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7700,
        name: 'OPS Ocean Partners Shipping GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7000, name: 'Oppenheim', companyType: CompanyType.INSURANCE},
    {
        id: 7830,
        name: 'Opel Aktiv Plus',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7988,
        name: 'ONTOS Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8805,
        name: 'ONE Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7129,
        name: 'One Capital Emissionshaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8701,
        name: 'OMV Deutschland GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8528,
        name: 'Oldenburgische Landesbrandkasse - AdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8290,
        name: 'Old Mutual Wealth Life Assurance Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {id: 7563, name: 'OLB Bank', companyType: CompanyType.INSURANCE},
    {
        id: 8055,
        name: 'OKV - Ostdeutsche Kommunalversicherung auf Gegenseitigkeit',
        logoPath: '4695.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8653,
        name: 'ÖKORENTA Neue Energien Deutschland GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8626,
        name: 'ohne Anbieter',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7356,
        name: 'Öffentliche Versicherung Bremen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7974,
        name: 'Öffentliche Sachversicherung Braunschweig - AdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8101,
        name: 'Öffentliche Lebensversicherungsanstalt Oldenburg - AdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6984,
        name: 'Öffentliche Lebensversicherung Sachsen-Anhalt - AdöR',
        logoPath: '1681.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7360,
        name: 'Öffentliche Lebensversicherung Braunschweig - AdöR',
        logoPath: '1682.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8467,
        name: 'Öffentliche Lebensversicherung Berlin Brandenburg AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7639,
        name: 'Öffentliche LandesbrandkasseVersicherung Oldenburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6981,
        name: 'Öffentliche Feuerversicherung Sachsen-Anhalt - AdöR',
        logoPath: '4928.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8581,
        name: 'Öffentl. Versicherungen Sachsen-An',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8360,
        name: 'OekoWorld Lux S.A',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7632,
        name: 'Oeffentliche Lebensversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7566,
        name: 'oeco capital Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8445,
        name: 'OCC Assekuradeur GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8699,
        name: 'OBI',
        companyType: CompanyType.MISC
    },
    {
        id: 7949,
        name: 'Oberösterreichische Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8466,
        name: 'Oberbank AG',
        logoPath: '4091.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7163,
        name: 'NV-Versicherungen VVaG',
        logoPath: '4708.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8542,
        name: 'Nürnberger Versicherungsgruppe',
        logoPath: '4697.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7250,
        name: 'NÜRNBERGER Pensionskasse AG',
        logoPath: '4698.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8222,
        name: 'Nürnberger Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8500,
        name: 'Nürnberger Beamten Allgemeine Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7450,
        name: 'NÜRNBERGER Allgemeine Versicherungs-AG',
        logoPath: '4092.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8688,
        name: 'NRV Neue Rechtschutz Versicherungsgesellschaft AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7544,
        name: 'NP Capital GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7600,
        name: 'Novitas Betriebskrankenkasse',
        logoPath: '4093.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7748,
        name: 'Nova Allgemeine Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8587,
        name: 'Notarversicherungsverein a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8734,
        name: 'Norma Lebensmittel-Discounter',
        companyType: CompanyType.MISC
    },
    {id: 7347, name: 'Nordvers GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7234,
        name: 'Nordrheinische Ärzteversorgung',
        companyType: CompanyType.INSURANCE
    },
    {id: 7432, name: 'Nordkontor AG', companyType: CompanyType.INSURANCE},
    {
        id: 8199,
        name: 'NordInvest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7955,
        name: 'NORDIC OIL Beteiligungs KG (GmbH & Co.)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7689,
        name: 'Nordhemmer VVaG',
        logoPath: '4700.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7351,
        name: 'Nordea SA',
        logoPath: '4094.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7351,
        name: 'Nordea SA',
        logoPath: '4094.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7391,
        name: 'Norddeutsche Vermögensanlage GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8084,
        name: 'NordDeutsche Projektentwicklungs- und Handels GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7244,
        name: 'NORDCAPITAL Holding GmbH & Cie. KG',
        logoPath: '4711.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7393,
        name: 'NORD/LB Norddeutsche Landesbank Girozentrale',
        logoPath: '4710.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8141, name: 'Noramco AG', companyType: CompanyType.INSURANCE},
    {
        id: 7518,
        name: 'Nomura Bank (Deutschland) GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7680,
        name: 'Nomura Asset Management Deutschland KAG mbH',
        logoPath: '4703.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7531,
        name: 'Niedersächsische Versicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8570,
        name: 'Next Generation Funds München GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8915, name: 'New Work SE', companyType: CompanyType.MISC},
    {
        id: 8250,
        name: 'Neuendorfer Brand-Bau-Gilde VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8363,
        name: 'neue leben Unfallversicherung AG',
        logoPath: '4936.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8311,
        name: 'neue Leben Pensionskasse AG',
        logoPath: '4937.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7358,
        name: 'neue leben Lebensversicherung AG',
        logoPath: '4939.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8089,
        name: 'Neue Bayerische Beamten Lebensversicherung AG',
        logoPath: '4705.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8745,
        name: 'Netto Marken-Discount',
        logoPath: '4706.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8916,
        name: 'Netflix International B.V.',
        companyType: CompanyType.MISC
    },
    {
        id: 8174,
        name: 'netbank AG',
        logoPath: '4519.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8174,
        name: 'netbank AG',
        logoPath: '4519.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8232,
        name: 'Nestor Investment Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7790,
        name: 'Nestle Pensionskasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8351,
        name: 'Neodigital Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8179,
        name: 'Neitzel & Cie. Gesellschaft für Beteiligungen mbH & CO. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7342, name: 'Neckura', companyType: CompanyType.INSURANCE},
    {
        id: 7318,
        name: 'Neckermann Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8656,
        name: 'Neckermann Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8029,
        name: 'Neckermann Kranken',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8279,
        name: 'NCI New Capital Invest Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7797,
        name: 'Nature Capital GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7859,
        name: 'Nationale Nederlanden',
        logoPath: '4707.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8964,
        name: 'Nanu-Nana',
        companyType: CompanyType.MISC
    },
    {
        id: 7913,
        name: 'NAFI-Dummy <bitte löschen>',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7343,
        name: 'Nadorster Feuer VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8773,
        name: 'N26 Bank',
        logoPath: '141.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 9006,
        name: 'N-ERGIE Aktiengesellschaft',
        companyType: CompanyType.MISC
    },
    {
        id: 8511,
        name: 'myLife Lebensversicherung AG',
        logoPath: '4938.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7989,
        name: 'my-teakinvest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7475,
        name: 'my green invest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8822,
        name: 'MVG Verkehrsgesellschaft',
        companyType: CompanyType.MISC
    },
    {
        id: 8954,
        name: 'Münchner Verkehrsgesellschaft mbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8538,
        name: 'Münchner Kapitalanlage AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8831, name: 'Münchner Bank eG', companyType: CompanyType.MISC},
    {
        id: 9046,
        name: 'Münchener Verein Versicherungsgruppe',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8387,
        name: 'Münchener Hypothekenbank e.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7741,
        name: 'Münchener Begräbnisverein e. V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7202,
        name: 'Münchener & Magdeburger Agrar AG',
        logoPath: '4714.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7706,
        name: 'Multi-Invest Sachwerte GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7249,
        name: 'Multi-Invest Gesellschaft für Vermögensbildung mbH',
        logoPath: '4717.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8704,
        name: 'Müller Handels GmbH & Co. KG',
        logoPath: '4715.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7227,
        name: 'MTV Capital Invest AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7831,
        name: 'MSIG Insurance Europe AG',
        logoPath: '4732.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8973,
        name: 'Mr. Wash Autoservice AG',
        companyType: CompanyType.MISC
    },
    {
        id: 8264,
        name: 'MPC Münchmeyer Petersen Capital Vermittlung GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7444,
        name: 'MPC Capital Concepts GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7953, name: 'Moventum S.C.A.', companyType: CompanyType.INSURANCE},
    {
        id: 7400,
        name: 'MOTION 8 Consulting',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7845,
        name: 'Morgan Stanley SICAV',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8230,
        name: 'Morgan Price International Healthcare',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6954,
        name: 'Monuta Verzekeringen N.V. - Zweigniederlassung Deutschland',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8938,
        name: 'MONTANA Energieversorgung GmbH & Co. KG',
        companyType: CompanyType.MISC
    },
    {
        id: 7975,
        name: 'MONEYMAXX Lebensversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8638,
        name: 'Moneyfix Assekuranz GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7429,
        name: 'Monega Kapitalanlagegesellschaft mbH',
        logoPath: '4100.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8093,
        name: 'Mondial Assistance International S.A.',
        logoPath: '4718.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8969,
        name: 'Mobilcom-Debitel GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8061,
        name: 'MMV Versicherungs-Service GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8661,
        name: 'MM Warburg & Co Fonds Vertrieb GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8428, name: 'MLP AG', companyType: CompanyType.INSURANCE},
    {
        id: 7110,
        name: 'MK LuxInvest S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7895,
        name: 'Mirae Asset Global Investments (UK) Limited',
        logoPath: '4720.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7387,
        name: 'Minerva Versicherungs-AG',
        logoPath: '4719.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7258,
        name: 'MIG Verwaltungs AG',
        logoPath: '4734.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8274,
        name: 'Middle East Best Select GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7065,
        name: 'MIDAS Venture Management AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8854, name: 'Michel Cigarren', companyType: CompanyType.MISC},
    {
        id: 7482,
        name: 'mhplus Betriebskrankenkasse',
        logoPath: '4940.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9058,
        name: 'MHC - Marble House Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8032,
        name: 'MFS International (U.K.) Ltd',
        companyType: CompanyType.INSURANCE
    },
    {id: 7936, name: 'MFonds AG', companyType: CompanyType.INSURANCE},
    {
        id: 8071,
        name: 'MeVoTest GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6945,
        name: 'Metzler Servicegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8073,
        name: 'Metzler Investment - Vorsorgeplan',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8576,
        name: 'Metzler Fundservices GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8583,
        name: 'Metzinger Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8165,
        name: 'Metro Gruppe',
        logoPath: '4722.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8165,
        name: 'Metro Gruppe',
        logoPath: '4722.png',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8442,
        name: 'Metlife Europe Limited (UK Branch)',
        companyType: CompanyType.INSURANCE
    },
    {id: 7145, name: 'MetallRente GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8002,
        name: 'Metacon Projektentwicklungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7019,
        name: 'Merck Betriebskrankenkasse',
        logoPath: '4724.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7828,
        name: 'Mercedes-Benz Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7662,
        name: 'Mercedes-Benz Bank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8087,
        name: 'Menzis Krankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7317,
        name: 'Mellon Global Funds, PLC',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7072,
        name: 'MeinPlus Servicegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7223,
        name: 'Meinl Success Finanz AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8824,
        name: 'Megara GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7837,
        name: 'Mediolanum International Life Ltd.',
        logoPath: '4726.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8566,
        name: 'Mediolanum International Funds Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7982,
        name: 'Medien-Versicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8703,
        name: 'MediaMarkt',
        logoPath: '4727.svg',
        companyType: CompanyType.MISC
    },
    {id: 8905, name: 'Media Markt', companyType: CompanyType.MISC},
    {
        id: 8635,
        name: 'Mecklenburgische VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9048,
        name: 'MDT - Makler der Touristik',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8193,
        name: 'MDO Management Company S.A.',
        logoPath: '4735.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8714,
        name: 'MD Ausbaugesellschaft mbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8740,
        name: 'MD Ausbaugesellschaft',
        companyType: CompanyType.MISC
    },
    {id: 8812, name: 'McFit', companyType: CompanyType.MISC},
    {
        id: 7541,
        name: 'MCE Schiffskapital AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8725, name: 'McDonalds', companyType: CompanyType.MISC},
    {
        id: 7686,
        name: 'Mazda Autoversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8036,
        name: 'maxPool GmbH',
        logoPath: '4941.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8114,
        name: 'Max Heinrich Sutor ohG',
        logoPath: '4513.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8117,
        name: 'Max Bögl Versicherungs- und Finanzdienstleistungs',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8925,
        name: 'Mathäser Filmpalast',
        companyType: CompanyType.MISC
    },
    {
        id: 8453,
        name: 'Markel International Insurance Company Limited, NL Dtld.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8558,
        name: 'Maritim Invest Beteiligungsgesellschaft mbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8211,
        name: 'Mapfre Asistencia S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8392,
        name: 'Manufaktur Augsburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6989,
        name: 'Mannheimer Versicherung AG',
        logoPath: '4728.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7833,
        name: 'Mannheimer Krankenversicherung AG',
        logoPath: '4729.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8088,
        name: 'MAN Pensionsfonds Aktiengesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7966,
        name: 'Man Investments (Luxembourg) S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6949,
        name: 'mamax Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7860,
        name: 'Maklerexperten/PKV Tarifoptimierung',
        companyType: CompanyType.INSURANCE
    },
    {id: 7155, name: 'Maklerexperten GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 6986,
        name: 'MainFirst SICAV',
        logoPath: '4102.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7618,
        name: 'Magna Umbrella Fund plc',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8076,
        name: 'Magellan Maritime Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8035,
        name: 'Magelan Investmentfonds (Sicav)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7068,
        name: 'Magdeburger Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7516,
        name: 'Macquarie Investment Management',
        logoPath: '4103.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8411,
        name: 'Macquarie Europe limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7944,
        name: 'Macif - Sitz der Gesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8851,
        name: 'Machhörndl Kaffee GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7282,
        name: 'M1 Factoring GmbH',
        logoPath: '4737.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7691,
        name: 'M&G Investment Funds',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8832,
        name: 'M-net Telekommunikations GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8406,
        name: 'M-Immo-Objekt GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6978,
        name: 'Lyxor International Asset Management',
        logoPath: '4738.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9028,
        name: 'LVM',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8292,
        name: 'Lupus alpha Asset Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7621,
        name: 'Lucura Versicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8146,
        name: 'Luana Capital New Energy Concepts GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7395,
        name: 'LSH Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {id: 7419, name: 'LRI Invest S.A.', companyType: CompanyType.INSURANCE},
    {
        id: 9007,
        name: 'Lotto24 AG',
        companyType: CompanyType.MISC
    },
    {
        id: 7633,
        name: 'London General Life Company Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8112,
        name: 'London General Insurance Company Limited/ AON',
        logoPath: '4739.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6963,
        name: 'Lombard Odier Funds',
        logoPath: '4740.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7623,
        name: 'Lohnfortzahlungskasse Leer VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7383,
        name: 'Lohnfortzahlungskasse Aurich VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8705,
        name: 'LogPay Financial Services GmbH',
        logoPath: '4741.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8683,
        name: 'Lloyd´s Versicherer Niederlassung für Deutschland',
        logoPath: '4742.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7605,
        name: 'Lloyd Fonds AG',
        logoPath: '4107.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8630,
        name: 'LLB Fund Services AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8204,
        name: 'LKK - Krankenversicherung der Landwirte',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8128,
        name: 'Lippische Landes-Brandversicherungsanstalt - AdöR',
        logoPath: '4743.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7252,
        name: 'Lighthouse Structured Finance GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8565,
        name: 'LIGA KV katholischer Priester VVaG Regensburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8523,
        name: 'Lifestyle Protection AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7246,
        name: 'Lifecard-Travel-Assistance',
        logoPath: '4744.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8785,
        name: 'Lieferando.de',
        logoPath: '4745.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7364,
        name: 'Liechtenstein Life Assurance AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8700,
        name: 'Lidl',
        logoPath: '4746.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8426,
        name: 'Liberty Europe ZN von Liberty Seguros',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7932,
        name: 'LHI Leasing GmbH',
        logoPath: '4756.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7969,
        name: 'LGT Capital Management AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8693,
        name: 'Leonidas Associates GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8288,
        name: 'Leonberger Bausparkasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7878,
        name: 'Lehrer-Feuerversicherungsverein auf Gegenseitigkeit',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7278,
        name: 'Legg Mason Global Funds PLC',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7229,
        name: 'Legal & General Deutschland Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7862,
        name: 'Lebensversicherung von 1871 a.G. München',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8297,
        name: 'leasconcept GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7171,
        name: 'LBS',
        logoPath: '4761.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8663,
        name: 'LBN Versicherungsverein a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8241,
        name: 'LBBW Asset Management Investmentgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7226,
        name: 'Lazard Global Active Funds plc',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7442,
        name: 'Landwirtschaftliche Solzialversicherug',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8037,
        name: 'Landwirtschaftliche Krankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8309,
        name: 'Landeszahnärztekammer Thüringen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8429,
        name: 'Landessparkasse zu Oldenburg',
        logoPath: '4933.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7157,
        name: 'Landesschadenhilfe VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8465,
        name: 'Landeslebenshilfe VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8868,
        name: 'Landeskrankenhilfe VVaG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8990, name: 'Landeskasse Düsseldorf', companyType: CompanyType.MISC},
    {
        id: 8992,
        name: 'Landeshauptkasse NRW',
        companyType: CompanyType.MISC
    },
    {
        id: 7062,
        name: 'Landesbank Berlin AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7709,
        name: 'Landesbank Baden-Württemberg Vermittlervertrieb, Freiburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7408,
        name: 'Lacuna Fondsverwaltungs GmbH & Co. Vertriebs KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7825,
        name: "L'or Aktiengesellschaft",
        companyType: CompanyType.INSURANCE
    },
    {id: 7712, name: 'KZVK Darmstadt', companyType: CompanyType.INSURANCE},
    {
        id: 7685,
        name: 'KWA Contracting AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8636, name: 'KVM Consult GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 9061,
        name: 'KVB - Krankenversorgung der Bundesbahnbeamten',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8930,
        name: 'Kuzbari - Zentrum für ästhetische Medizin',
        companyType: CompanyType.MISC
    },
    {
        id: 7995,
        name: 'Künstlersozialkasse',
        companyType: CompanyType.INSURANCE
    },
    {id: 8754, name: 'Kultfaktor GmbH', companyType: CompanyType.MISC},
    {
        id: 9059,
        name: 'KUK - Krankenunterstützungskasse der Berufsfeuerwehr Hannover',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7522,
        name: 'Kuba Investment GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8161,
        name: 'KSH Capital Partners AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7688,
        name: 'KS Versicherungs-AG',
        logoPath: '4775.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7987,
        name: 'KS Kraftfahrer-Schutz e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8562,
        name: 'Krones Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8463,
        name: 'Kristensen INVEST GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7530,
        name: 'Kristall-Klar-Energie eG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8440,
        name: 'Kreissparkasse Köln',
        logoPath: '4933.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8692,
        name: 'KRAVAG-SACH Versicherung des Deutschen Kraftverkehrs VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7785,
        name: 'KRAVAG-LOGISTIC Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7757,
        name: 'KRAVAG Allgemeine Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7398,
        name: 'Kraus Grundbesitz AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8848, name: 'Kraftwerk Karlsfeld GmbH', companyType: CompanyType.MISC},
    {
        id: 7026,
        name: 'Konzept & Marketing',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7037,
        name: 'König & Cie GmbH und Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7634,
        name: 'Kommunaler Versorungsverband BW',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7468,
        name: 'Kommunaler Versorgungsverband Baden-Württemberg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8654,
        name: 'Kommunaler Versorgungsverband  Sachsen-Anhalt',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8521,
        name: 'Kommunaler Schadenausgleich',
        logoPath: '4769.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7094,
        name: 'Kommunale Versorgungskassen für Westfalen-Lippe',
        logoPath: '4770.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7331,
        name: 'Kölnische Hagel-Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8307,
        name: 'Kölner Postversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7281,
        name: 'Kölner Pensionskasse VVaG',
        logoPath: '4764.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7016,
        name: 'Knappschaft',
        companyType: CompanyType.INSURANCE
    },
    {id: 8896, name: 'Klinikum München', companyType: CompanyType.MISC},
    {
        id: 8872,
        name: 'Klemmer International Versicherungsmakler GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7136,
        name: 'Klaus Wunderlich Sport Assekuranz',
        logoPath: '4771.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8784,
        name: 'Klarna',
        companyType: CompanyType.MISC
    },
    {id: 8852, name: 'Klarmobil GmbH', companyType: CompanyType.MISC},
    {
        id: 7734,
        name: 'KiriFonds Deutschland GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8221,
        name: 'Kirchliche Zusatzversorgungskasse Baden',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8018,
        name: 'Kirchliche Zusastzversorgungskasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8737,
        name: 'Kirchensteueramt Nürnberg',
        companyType: CompanyType.MISC
    },
    {
        id: 8894,
        name: 'Kirchensteueramt München',
        companyType: CompanyType.MISC
    },
    {
        id: 8809,
        name: 'King, Buckridge and Kiehn',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8988,
        name: 'KIK',
        companyType: CompanyType.MISC
    },
    {
        id: 7194,
        name: 'KG Allgemeine Leasing GmbH & Co',
        logoPath: '4777.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7004,
        name: 'KEPLER-FONDS Kapitalanlagegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8989, name: 'Kentucky Fried Chicken', companyType: CompanyType.MISC},
    {
        id: 7270,
        name: 'KEH Ersatzkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7453,
        name: 'KBC Asset Management SA',
        logoPath: '4119.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7104,
        name: 'Kaufmännische Krankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {id: 8753, name: 'Kaufland', companyType: CompanyType.MISC},
    {
        id: 7727,
        name: 'KAS Investment Servicing GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8379,
        name: 'Karstadt Quelle Versicherungsmakler GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8728,
        name: 'Karstadt',
        companyType: CompanyType.MISC
    },
    {
        id: 7674,
        name: 'Karlsruher Versicherung AG',
        logoPath: '4773.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7635,
        name: 'Karlsruher Lebensversicherung AG',
        logoPath: '4774.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8365,
        name: 'Kapitalpartner Konzept GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7015,
        name: 'Kapitalanlage Immobilien ( Steinruck )',
        companyType: CompanyType.INSURANCE
    },
    {id: 8412, name: 'KanAm', companyType: CompanyType.INSURANCE},
    {
        id: 8906,
        name: 'Kafein Nürnberg',
        companyType: CompanyType.MISC
    },
    {
        id: 8595,
        name: 'KAERA Industrie-und Touristik Versicherungsmakler GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7770,
        name: 'KAB Versicherungsmakler',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8345,
        name: 'Jyske Invest International',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7012,
        name: 'juwi GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7803,
        name: 'Jurpartner Rechtsschutz-Versicherung AG',
        logoPath: '4779.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8350,
        name: 'Jurinvest KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7558, name: 'Juragent AG', companyType: CompanyType.INSURANCE},
    {
        id: 7043,
        name: 'JURA DIREKT GMBH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8506,
        name: 'Jura Direkt',
        logoPath: '4780.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7108,
        name: 'Jupiter Global Fund, The',
        logoPath: '4781.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7853,
        name: 'Jung, DMS & Cie',
        logoPath: '4782.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9011,
        name: 'Jumpers Fitness GmbH',
        companyType: CompanyType.MISC
    },
    {id: 8001, name: 'Julius Baer', companyType: CompanyType.INSURANCE},
    {
        id: 7409,
        name: 'JP Morgan Fleming Asset Management',
        logoPath: '4121.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7993,
        name: 'JK Wohnbau Fonds I. Beteiligungs GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8205,
        name: 'Jeversche Versicherungs- Gesellschaft VVaG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8836, name: 'JET Tankstellen', companyType: CompanyType.MISC},
    {
        id: 7725,
        name: 'JDC Baugeld Spezialisten AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8200,
        name: 'Janus International (UK) Limited',
        logoPath: '4783.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7614,
        name: 'Janitos Versicherung AG',
        logoPath: '4784.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8682,
        name: 'Jamestown US-Immobilien GmbH',
        logoPath: '4785.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8456,
        name: 'Jaguar Financial Services',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7581,
        name: 'IVG Immobilien AG',
        logoPath: '4798.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7363,
        name: 'Itzehoer Versicherung/Brandgilde von 1691 VVaG',
        logoPath: '4122.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8353,
        name: 'Itzehoer Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8536,
        name: 'Isselhorster Versicherung V.a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7654,
        name: 'iShares Public Limited Company',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8671,
        name: 'IPConcept Fund Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7874,
        name: 'investmentgate GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8262,
        name: 'Investeringsforeningen Sydinvest International',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7684,
        name: 'Invesco Deutschland Konzept 8 Tarife',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7467,
        name: 'Invesco Asset Management Irland Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7796,
        name: 'Invesco Asset Management Deutschland GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9020,
        name: 'INTERSPORT',
        companyType: CompanyType.MISC
    },
    {
        id: 8551,
        name: 'InterRisk Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7118,
        name: 'InterRisk Lebensversicherung-AG',
        logoPath: '4790.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8433, name: 'Internet Stars GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 6964,
        name: 'Internationales Immobilien-Institut GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7024,
        name: 'Internationale Grundwert Beteiligungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7925,
        name: 'International Insurance Company of Hannover SE',
        logoPath: '4788.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7998,
        name: 'Interlloyd Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7476,
        name: 'Inter Lebensversicherung aG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8539,
        name: 'INTER Krankenversicherung aG',
        logoPath: '4799.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7863,
        name: 'INTER Allgemeine Versicherung AG',
        logoPath: '4800.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7119,
        name: 'insuro Maklerservice',
        logoPath: '4521.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8798,
        name: 'Insurance Hero GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8610, name: 'InRIS UCITS plc', companyType: CompanyType.INSURANCE},
    {
        id: 7359,
        name: 'inpunkto AG',
        logoPath: '4522.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8150,
        name: 'INP Finanzconsult GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7267,
        name: 'Inora Life Ltd. Direktion Deutschland',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8110,
        name: 'InoBROKER',
        logoPath: '4791.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7604,
        name: 'Innungskrankenkasse Südwest',
        logoPath: '4794.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8388,
        name: 'Innungskrankenkasse Nord',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7256,
        name: 'Innungskrankenkasse gesund plus',
        logoPath: '4792.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8674,
        name: 'Innungskrankenkasse classic',
        logoPath: '4793.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7536,
        name: 'Innungskrankenkasse Brandenburg und Berlin',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9005,
        name: 'innogy',
        logoPath: '4942.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7704,
        name: 'INNOFINANCE GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7808,
        name: 'INKA INVEST Mezzanine GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8531,
        name: 'ING-DiBa AG',
        logoPath: '4802.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7071, name: 'ING (L) Invest', companyType: CompanyType.INSURANCE},
    {
        id: 7554,
        name: 'Infraplan Gesellschaft für Infrastrukturplanung,  Gewerbe- u',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7610,
        name: 'INEAS',
        logoPath: '4803.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7766,
        name: 'Industrie-Pensions-Verein-E.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7112,
        name: 'IMMOVATION Immobilien Handels AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7844,
        name: 'Immovaria GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7128,
        name: 'Immobilienhandelsfonds Verwaltungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8041,
        name: 'Immobilien',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7233,
        name: 'Immac Immobilienfonds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7146,
        name: 'IMB Vermögensverwaltungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7729, name: 'IKK', companyType: CompanyType.INSURANCE},
    {
        id: 8732,
        name: 'IKEA',
        logoPath: '4804.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8207,
        name: 'IGB Union Investment',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8557,
        name: 'If Schadenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8197,
        name: 'Iduna Vereinigte Lebensversicherung aG',
        logoPath: '4796.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8272,
        name: 'Ideenkapital AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7495, name: 'IDEAL Vorsorge GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7663,
        name: 'IDEAL Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8510,
        name: 'Ideal Versicherung',
        logoPath: '4797.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8375,
        name: 'IDEAL Rechtschutzversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7505,
        name: 'IDEAL Lebensversicherung a.G.',
        logoPath: '4805.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8080,
        name: 'Ideal Invest Sicav',
        companyType: CompanyType.INSURANCE
    },
    {id: 7032, name: 'IC Fonds GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8629,
        name: 'i4r Bioenergiepark Hof Beteiligungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7433, name: 'I-Plus', companyType: CompanyType.INSURANCE},
    {
        id: 7798,
        name: 'HZK - Die Profikrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7750,
        name: 'HypoVereinsbank Karlsruhe, Karlsruhe',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8086,
        name: 'HypoVereinsbank Frankfurt',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8519,
        name: 'Hypoport Mortgage Market Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6951,
        name: 'Hypo-Kapitalanlage-Gesellschaft m.b.H.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8549,
        name: 'Hypo Alpe-Adria-Bank AG (Austria)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8797,
        name: 'HVS Hamburger Versicherungs-Service AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8571,
        name: 'HVR Hamburger Vermögensschaden-Haftpflicht Risikomanagement',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8171,
        name: 'HVAG Hamburger Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7158,
        name: 'HUK24 AG',
        logoPath: '4130.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8997, name: 'HUK-COBURG', companyType: CompanyType.INSURANCE},
    {
        id: 7517,
        name: 'Hübener Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7871,
        name: 'HTB Schiffsfonds GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7762,
        name: 'HSC US Leben',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8337,
        name: 'HSC Hanseatische Sachwert Concept GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6975,
        name: 'HSBC Trinkaus & Burkhardt Immobilien GmbH',
        logoPath: '4834.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 9055, name: 'HSBC INKA', companyType: CompanyType.INSURANCE},
    {
        id: 8668,
        name: 'HSBC Gruppe',
        companyType: CompanyType.INSURANCE
    },
    {id: 7960, name: 'HPC Capital GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8928,
        name: 'Hotel & Restaurant Goldener Falke',
        companyType: CompanyType.MISC
    },
    {
        id: 8438,
        name: 'Hornblower Fischer AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9024,
        name: 'Hornbach',
        companyType: CompanyType.MISC
    },
    {id: 8861, name: 'HonorarKonzept GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7994,
        name: 'Höchster Pensionskasse VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8955,
        name: 'hobex AG',
        logoPath: '4943.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7224,
        name: 'HMW Emissionshaus AG',
        logoPath: '4835.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8679, name: 'HJM Altlast', companyType: CompanyType.INSURANCE},
    {
        id: 9021,
        name: 'HIT',
        companyType: CompanyType.MISC
    },
    {
        id: 8490,
        name: 'Hiscox Europe Underwriting Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7035,
        name: 'HGA Capital Grundbesitz und Anlage GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8469,
        name: 'HEUTER-Consulting e.K.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7085,
        name: 'Hesse Newmann Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7624, name: 'HEP capital AG', companyType: CompanyType.INSURANCE},
    {
        id: 8176,
        name: 'Henkel AG  Co. KGaA',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7529,
        name: 'Henderson Horizon Fund SICAV',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8289,
        name: 'HELVETIA schweizerische Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7290,
        name: 'HELVETIA INTERNATIONAL Versicherungs-AG',
        logoPath: '4837.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9049,
        name: 'Helvetia',
        companyType: CompanyType.INSURANCE
    },
    {id: 8806, name: 'helpcheck GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8100,
        name: 'Hello bank!',
        logoPath: '4810.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7451, name: 'Heimat Krankenkasse', companyType: CompanyType.INSURANCE},
    {
        id: 7048,
        name: 'Heidelberger Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7607,
        name: 'Heidelberger Leben Service Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9043,
        name: 'HDI',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7079,
        name: 'HCI Capital AG',
        logoPath: '4841.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6956,
        name: 'HBOS European Financial Services Vertriebs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8680,
        name: 'Hauck & Aufhäuser Investmentgesellschaft S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7528,
        name: 'Harsewinkeler VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8460,
        name: 'HARREN & PARTNER Ship Management GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7593,
        name: 'Hardy (Underwriting Agencies) Limited',
        companyType: CompanyType.INSURANCE
    },
    {id: 9026, name: 'HanseMerkur', companyType: CompanyType.INSURANCE},
    {
        id: 7302,
        name: 'Hanseatische Krankenkasse',
        logoPath: '4128.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8126,
        name: 'Hanseatische Investment-GmbH',
        logoPath: '4814.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7304,
        name: 'Hanseatic Bank GmbH & Co KG',
        logoPath: '4815.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7576,
        name: 'Hanse-Marine-Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8374,
        name: 'Hanse Capital Emissionshaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8677,
        name: 'Hansa Treuhand Schiffsbeteiligungs AG & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8138,
        name: 'Hansa Hamburg Shipping International GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7823,
        name: 'Hans L. Grauerholz GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8757,
        name: 'Hans im Glück',
        logoPath: '4820.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7297,
        name: 'Hannoversche Lebensversicherung AG',
        logoPath: '4821.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7070,
        name: 'Hannoversche Kassen',
        logoPath: '4822.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7176,
        name: 'Hannoversche Direktversicherung AG',
        logoPath: '4823.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7781,
        name: 'Hannover Leasing GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8034,
        name: 'Handelskrankenkasse hkk',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7111,
        name: 'Hammer-Projekt-Fonds GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7908,
        name: 'Hamburgische Seehandlung Gesellschaft für Schiffsbeteiligung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8361,
        name: 'Hamburger Yacht-Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8934,
        name: 'Hamburger Sparkasse AG',
        companyType: CompanyType.MISC
    },
    {
        id: 7006,
        name: 'Hamburger Pensionsverwaltung e.G.',
        logoPath: '4824.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8312,
        name: 'Hamburger Lehrer-Feuerkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8475,
        name: 'Hamburger Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8202,
        name: 'HAMBURGER HOF Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7103,
        name: 'Hamburger Feuerkasse Versicherungs-AG',
        logoPath: '4825.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8431,
        name: 'Hamburger EmissionsHaus GmbH & Cie KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8280,
        name: 'Hamburger Beamten-Feuer-und Einbruchskasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7240,
        name: 'Hamburg-Mannheimer Pensionskasse-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8623,
        name: 'Hamburg Trust Grundvermögen und Anlage GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7431,
        name: 'Hallesche Krankenversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8446, name: 'HAHN Fonds GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7321,
        name: 'HÄGER Versicherungsverein a.G.',
        logoPath: '4807.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7667,
        name: 'Hagelgilde Versicherungs-Verein a. G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8231,
        name: 'Haftpflichtgemeinschaft Deutscher Nahverkehrs-',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7761,
        name: 'Haftpflichtgem. Dt. Nahverkehrs- und Versorgungsungsuntern.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7875,
        name: 'Habona Invest Vertriebs GmbH',
        logoPath: '4827.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8953,
        name: 'H&M',
        companyType: CompanyType.MISC
    },
    {
        id: 8252,
        name: 'H.F.S. HYPO-Fondsbeteiligungen für Sachwerte GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8008,
        name: 'GVV-Privatversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7701,
        name: 'GVV-Kommunalversicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7052,
        name: 'GVO - Gegenseitigkeit Versicherung Oldenburg VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8418,
        name: 'Gutmann Kapitalanlageaktiengesellschaft',
        logoPath: '4845.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7646,
        name: 'GUTINGIA Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7673,
        name: 'Gut_ Verbunden - Telekommunikations GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7749,
        name: 'GSI Fonds GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7029,
        name: 'GS&P Kapitalanlagegesellschaft S. A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7334,
        name: 'Grundeigentümer-Versicherung VVaG',
        logoPath: '4843.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8218,
        name: 'Griffin Capital Management Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8979,
        name: 'Gries Deco Holding GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7904,
        name: 'GreenPower CityResort Dresden Nachhaltigkeitsfonds UG&Co KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8139, name: 'Green Investors AG', companyType: CompanyType.INSURANCE},
    {
        id: 8095,
        name: 'Green Energy Emissionshaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7490,
        name: 'Gothaer Versicherungsbank VVaG',
        logoPath: '4847.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9018,
        name: 'Gothaer Versicherungen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7679,
        name: 'Gothaer Pensionskasse AG',
        logoPath: '4848.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7428,
        name: 'Gothaer Krankenversicherung AG',
        logoPath: '4511.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7314,
        name: 'Gothaer Allgemeine Versicherung AG',
        logoPath: '4850.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8743,
        name: 'Google Play',
        logoPath: '4851.svg',
        companyType: CompanyType.MISC
    },
    {id: 8780, name: 'Google', companyType: CompanyType.MISC},
    {
        id: 7154,
        name: 'Goldprofessionell Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8278, name: 'Goldpartner24', companyType: CompanyType.INSURANCE},
    {
        id: 7403,
        name: 'Goldmann Sachs Funds',
        logoPath: '4133.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8233, name: 'GNI', companyType: CompanyType.INSURANCE},
    {
        id: 7666,
        name: 'Gmünder ErsatzKasse GEK',
        companyType: CompanyType.INSURANCE
    },
    {id: 7755, name: 'GMAC RFC Bank GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7215,
        name: 'GLS Gemeinschaftsbanken eG',
        logoPath: '4505.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7050,
        name: 'GLOBALVEST LIFE GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7190,
        name: 'GLOBALITY S.A. Luxemburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7550,
        name: 'Global Invest Marketing GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8060,
        name: 'Global Advantage Fund (SICAV)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7866,
        name: 'Glasschutzkasse a.G. von 1923 zu Hamburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8170,
        name: 'Gladbacher Bank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7123,
        name: 'GKK Österreich',
        logoPath: '4853.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7562,
        name: 'GIP',
        companyType: CompanyType.INSURANCE
    },
    {id: 8561, name: 'GHV Darmstadt', companyType: CompanyType.INSURANCE},
    {
        id: 7881,
        name: 'GHF Gesellschaft für Handel und Finanz mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7322,
        name: 'GGG Kraftfahrzeug-Reparaturkosten-Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7180,
        name: 'GFU Gesellschaft für Fonds und Unternehmensverwaltungen mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8767,
        name: 'GEZ',
        logoPath: '4503.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7540,
        name: 'Gewerbeversicherung24',
        companyType: CompanyType.INSURANCE
    },
    {id: 8801, name: 'Getsurance GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8803,
        name: 'Getsafe Digital GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7106,
        name: 'Gesundheitsmobil GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8305,
        name: 'German Underwriting GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8153,
        name: 'german Broker net AG',
        logoPath: '4946.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7973,
        name: 'German Assistance Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8083,
        name: 'Gerling Investment Kapitalanlagegesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7073,
        name: 'Gerling G & A',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8052,
        name: 'Genossenschaftsbank (Allgemein)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9030,
        name: 'Generali',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8657,
        name: 'General Accident Life Assurance Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8321,
        name: 'Gemeinnützige Haftpflichtversicherungsanstalt Kassel - AdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6992,
        name: 'Gemeinnützige Haftpflichtversicherungsanstalt Darmstadt AdöR',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7287,
        name: 'Gemeinde-Unfallversicherungsverband Hannover',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8596,
        name: 'GEFA-Leasing GmbH',
        logoPath: '4855.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7548,
        name: 'Gebser und Partner AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7263,
        name: 'Gebäudeversicherungsgilde',
        companyType: CompanyType.INSURANCE
    },
    {id: 8020, name: 'Gebau AG', companyType: CompanyType.INSURANCE},
    {
        id: 8189,
        name: 'GEBAB',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7846,
        name: 'GE·BE·IN Bestattungsinstitut Bremen GmbH',
        logoPath: '4854.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8219,
        name: 'GE-BE-IN Versicherungen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8983,
        name: 'gas.de Versorgungsgesellschaft mbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8287,
        name: 'Gartmore Fund Managers International Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8444,
        name: 'Gartenbau-Versicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7455,
        name: 'Gartenbau-Berufsgenossenschaft',
        logoPath: '4507.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8420,
        name: 'Garching SLC 2 GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8069,
        name: 'GARANTA Versicherungs-AG',
        logoPath: '4856.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7924, name: 'Gamax Management AG', companyType: CompanyType.INSURANCE},
    {
        id: 8552,
        name: 'GAM Fonds Marketing GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8814, name: 'Galeria Kaufhof', companyType: CompanyType.MISC},
    {
        id: 7889,
        name: 'GAEDE & GLAUERDT Assecuradeur GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7777, name: 'FXFiin GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7293,
        name: 'FWU Life Insurance Lux S.A. - NL Deutschland',
        logoPath: '4859.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8186,
        name: 'Futures Team',
        logoPath: '4501.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7595,
        name: 'Futura',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7990,
        name: 'Fundus Fonds-Verwaltungen GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6994,
        name: 'Fundsaccess Service GmbH',
        logoPath: '4858.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7466,
        name: 'Fundamental Garantie Trust Emissionshaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8206,
        name: 'Friends Provident Life Assurance Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7399,
        name: 'FRIDAY Deutsche NL der FRIDAY Insurance S.A.',
        logoPath: '4491.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7655, name: 'Fremdbank', companyType: CompanyType.INSURANCE},
    {
        id: 7815,
        name: 'Freie Heilfürsorge',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7586,
        name: 'Freie Arzt- und Medizinkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7480,
        name: 'Fred Alger Management Inc.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7524,
        name: 'Franz&Team Financial Services GmbH, Celle',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8815,
        name: 'Fränky Getränkemarkt',
        companyType: CompanyType.MISC
    },
    {
        id: 8489,
        name: 'Franklin Templeton Investment Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7492,
        name: 'Frankfurter Service Kapitalanlage-Gesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9057,
        name: 'Frankfurter Münchener Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8300,
        name: 'FRANKFURT-TRUST-Investment Gesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7788,
        name: 'FRANKFURT-TRUST Invest Luxemburg AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8877,
        name: 'Frankfurt Münchener Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8855,
        name: 'Franken-Therme Bad Windsheim',
        companyType: CompanyType.MISC
    },
    {
        id: 7474,
        name: 'Franken-Invest',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7484,
        name: 'Fortuna Lebensversicherungs AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7038,
        name: 'Fortis L Fund Sicav',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8009,
        name: 'Forest Finance Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8251,
        name: 'Ford Auto Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7101,
        name: 'FondsServiceBank - ein Service der DAB bank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7330,
        name: 'Fondsdepot Bank GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7193,
        name: 'Fondsbörse Deutschland Beteiligungsmakler AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7059,
        name: 'Fonds Management Consulting',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7384,
        name: 'FM Insurance Company Limited London',
        logoPath: '4490.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8927,
        name: 'Flughafen München GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7472,
        name: 'Flossbach von Storch Invest S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8432,
        name: 'Flex Fonds Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8769, name: 'Flessabank', companyType: CompanyType.MISC},
    {
        id: 7898,
        name: 'Fl Frankfurter Investitionsgesellschaft Fonds mbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8839, name: 'Five Guys Germany GmbH', companyType: CompanyType.MISC},
    {
        id: 8849,
        name: 'FIT/ONE',
        companyType: CompanyType.MISC
    },
    {
        id: 6960,
        name: 'FIT Fondsinitiator und Treuhand GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7266,
        name: 'First State Investments ICVC',
        logoPath: '4142.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8025,
        name: 'First Private Investment Management KAG mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8131,
        name: 'FIRST INVEST Immobilien GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8889,
        name: 'First Data Deutschland GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7357,
        name: 'FiNUM.FINANZHAUS GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8478, name: 'FiNUM.Finanzhaus AG', companyType: CompanyType.INSURANCE},
    {
        id: 8079,
        name: 'Finum Private Finance',
        logoPath: '4498.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7376,
        name: 'FiNUM AT',
        logoPath: '4495.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7289,
        name: 'FINOVA GmbH',
        logoPath: '4487.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6957,
        name: 'Fingro AG',
        logoPath: '4499.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8295,
        name: 'Finanzplan Bonusgesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {id: 8744, name: 'Finanzkasse Nürnberg-Süd', companyType: CompanyType.MISC},
    {
        id: 8609,
        name: 'FinanzCenter Hude, Ronald Borstelmann',
        companyType: CompanyType.INSURANCE
    },
    {id: 8884, name: 'Finanzamt Nürnberg-Süd', companyType: CompanyType.MISC},
    {
        id: 8893,
        name: 'Finanzamt Bayern',
        companyType: CompanyType.MISC
    },
    {
        id: 7864,
        name: 'Financiere de l echiquier',
        logoPath: '4497.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7683,
        name: 'Financial Assurance Company Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7264,
        name: 'Finance System GmbH & Co. KG',
        logoPath: '4496.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8481,
        name: 'Finance life',
        companyType: CompanyType.INSURANCE
    },
    {id: 7107, name: 'fin4you', companyType: CompanyType.INSURANCE},
    {
        id: 8544,
        name: 'FIL Fondsbank GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8315,
        name: 'FIDURA Fonds GmbH & Co. KG, München',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8135,
        name: 'Fidentum GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8424, name: 'Fidelity USA', companyType: CompanyType.INSURANCE},
    {
        id: 7672,
        name: 'Fidelity Investment Services GmbH',
        logoPath: '4494.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7056,
        name: 'FHH Fondshaus Hamburg Gesellschaft für Unternehmensbeteiligu',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7591,
        name: 'Feuersozietät Berlin Brandenburg Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8261,
        name: 'Feri Trust (Luxembourg) S.A.',
        companyType: CompanyType.INSURANCE
    },
    {id: 7354, name: 'Fellow Home AG', companyType: CompanyType.INSURANCE},
    {
        id: 8050,
        name: 'Fehlerliste Sach',
        companyType: CompanyType.INSURANCE
    },
    {id: 7186, name: 'Fehlerliste Leben', companyType: CompanyType.INSURANCE},
    {
        id: 7445,
        name: 'Fehlerliste Kranken',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6967,
        name: 'FAMK',
        logoPath: '4489.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7507,
        name: 'Familienschutz Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7230,
        name: 'Familienschutz Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7848,
        name: 'Familienfürsorge Lebensversicherung AG im Raum der Kirchen',
        logoPath: '4493.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7203,
        name: 'FAMILIENFÜRSORGE Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7437,
        name: 'fairvesta Holding AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8619,
        name: 'Fahrlehrerversicherung Verein a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7890,
        name: 'FAFA Capital GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8598,
        name: 'F+V Vermögensverwaltung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7365,
        name: 'F&C Management Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8470,
        name: 'F.I.P. Finanzmarketing GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7900,
        name: 'F. Laeisz Versicherung AG',
        logoPath: '4485.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7947,
        name: 'EXTREMUS Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8107,
        name: 'ExtraHyp.de GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8339, name: 'Exporo AG', companyType: CompanyType.INSURANCE},
    {
        id: 8649,
        name: 'EXPAT SERVICES',
        companyType: CompanyType.INSURANCE
    },
    {id: 7730, name: 'EXCALIBUR', companyType: CompanyType.INSURANCE},
    {
        id: 7612,
        name: 'EWR Umwelttechnik GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7850, name: 'Evonik Degussa GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8816,
        name: 'Eventim',
        companyType: CompanyType.MISC
    },
    {
        id: 8212,
        name: 'EventAssec GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7958,
        name: 'EuroSwitch Vermögensmanagement GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7218,
        name: 'EuropLeasing AG, Hamburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7002,
        name: 'Europleasing AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8331,
        name: 'European Warranty Partners SE',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8450,
        name: 'European Wagon Lease Asset GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7485,
        name: 'European Broker System GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7301,
        name: 'European Bank for Fund Services GmbH (ebase)',
        logoPath: '4484.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8675,
        name: 'European Bank for Financial Services GmbH (ebase)',
        logoPath: '4483.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8944,
        name: 'European Bank for Financial Services GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8408,
        name: 'Europäische Bio-Raffinerie GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8687,
        name: 'EUROPA Versicherung AG',
        logoPath: '4476.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7313,
        name: 'EUROPA Lebensversicherung AG',
        logoPath: '4151.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7179,
        name: 'EUROPA Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8154,
        name: 'Europa Assistance Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8143,
        name: 'EUROP ASSISTANCE Versicherungs-AG',
        logoPath: '4475.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7114,
        name: 'Euro-Aviation Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 6970, name: 'Euro Invest', companyType: CompanyType.INSURANCE},
    {
        id: 7151,
        name: 'EURO GRUNDINVEST Objekt Holding GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8074,
        name: 'Euro American Investors Group Vermittlungs - GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8518,
        name: 'Euler Hermes Kreditversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7577,
        name: 'Euler Hermes Deutschland - Niederlassung der Euler Hermes SA',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8747,
        name: 'eToro',
        logoPath: '4322.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7839,
        name: 'ETHENEA Independent Investors S.A.',
        logoPath: '4473.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8702,
        name: 'Esso Deutschland GmbH',
        logoPath: '4481.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8899,
        name: 'ESSO',
        companyType: CompanyType.MISC
    },
    {id: 9016, name: 'Esprit', companyType: CompanyType.MISC},
    {
        id: 6987,
        name: 'ESA-EuroShip Assekuranzen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8282,
        name: 'ERV - Europäische Reiseversicherung Aktiengesellschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7235,
        name: 'Erste Sparinvest Kapitalanlagegesellschaft MbH (Östereich)',
        logoPath: '4480.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8003,
        name: 'Ernst & Young Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9040,
        name: 'ERGO',
        companyType: CompanyType.INSURANCE
    },
    {id: 7336, name: 'Equity Pictures AG', companyType: CompanyType.INSURANCE},
    {
        id: 8370,
        name: 'EQUAL Real Estate GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8819, name: 'EQS Group AG', companyType: CompanyType.MISC},
    {
        id: 8968,
        name: 'eprimo GmbH',
        logoPath: '4945.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8599,
        name: 'Epicon Financial Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8113,
        name: 'ENVIVAS Krankenversicherung AG',
        logoPath: '4464.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7619,
        name: 'Entis Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8733,
        name: 'ENTEGA Plus GmbH',
        logoPath: '4463.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8698,
        name: 'Eni Deutschland GmbH',
        logoPath: '4479.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7339,
        name: 'Engel & Völkers Capital AG',
        logoPath: '4150.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7855,
        name: 'Energy Capital Invest Verwaltungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8479,
        name: 'Energiekontor AG',
        logoPath: '4478.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7262,
        name: 'energie-Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8978,
        name: 'EnBW Energie AG',
        companyType: CompanyType.MISC
    },
    {id: 8591, name: 'Emsländer Volksbank', companyType: CompanyType.INSURANCE},
    {
        id: 7117,
        name: 'EMIL Deutschland AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7957, name: 'EMF AG', companyType: CompanyType.INSURANCE},
    {
        id: 7210,
        name: 'Embdena Partnership AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8075,
        name: 'Elvia Reiseversicherungs-Gesellschaft AG in Zürich',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8870,
        name: 'Elips Life AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8890,
        name: 'Electric Mobility Concepts GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8637,
        name: 'Elbufer Invest GmbH & Cie. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7421, name: 'elbfonds GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8834,
        name: 'Elavon',
        companyType: CompanyType.MISC
    },
    {
        id: 7092,
        name: 'Eigentums-Immobilien',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7460,
        name: 'EH1 Emissionshaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8228, name: 'eFonds AG', companyType: CompanyType.INSURANCE},
    {
        id: 8716,
        name: 'Edeka',
        companyType: CompanyType.MISC
    },
    {id: 8948, name: 'eBay GmbH', companyType: CompanyType.MISC},
    {
        id: 8380,
        name: 'easylife care ',
        logoPath: '4944.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8097,
        name: 'easy Login',
        logoPath: '4526.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7525,
        name: 'East-West Assekuranz AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8949, name: 'E.ON', companyType: CompanyType.MISC},
    {
        id: 8163,
        name: 'E.F.I. European Fund Investment Vertriebsmanagement GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8738, name: 'E-Plus Service GmbH', companyType: CompanyType.MISC},
    {
        id: 7462,
        name: 'E-Loan, München',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8722,
        name: 'E-Center',
        logoPath: '4462.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7248,
        name: 'dzt Deutsche Zeichentrick',
        companyType: CompanyType.INSURANCE
    },
    {id: 8417, name: 'DZ BANK AG', companyType: CompanyType.INSURANCE},
    {
        id: 7325,
        name: 'DWS Investments Schweiz',
        logoPath: '4159.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8180, name: 'DWS Investment S.A.', companyType: CompanyType.INSURANCE},
    {
        id: 8022,
        name: 'DWS Finanz-Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7291, name: 'DVB Altlast', companyType: CompanyType.INSURANCE},
    {
        id: 7782,
        name: 'DV AG',
        logoPath: '4441.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7047,
        name: 'Düsseldorfer Krankenversicherungsverein a. G',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8759,
        name: 'Dunkin Donuts',
        companyType: CompanyType.MISC
    },
    {
        id: 7892,
        name: 'DUK Versorgungswerk e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6998,
        name: 'Dubai Select Immobilienfonds GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7286,
        name: 'Dubai Invest Immobilienfonds GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7498,
        name: 'DTF - Der Transport Fonds GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8314,
        name: 'DSV aktiv/Freunde des Skisports e. V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8464,
        name: 'DSV Aktiv',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7752,
        name: 'DSL Bank',
        logoPath: '4442.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8901,
        name: 'Drogeriemarkt Müller',
        companyType: CompanyType.MISC
    },
    {
        id: 7647,
        name: 'DRMM Maklermanagement AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8787,
        name: 'Drillisch Online GmbH',
        logoPath: '4865.svg',
        companyType: CompanyType.MISC
    },
    {id: 9065, name: 'Dresdner Bank', companyType: CompanyType.INSURANCE},
    {
        id: 7402,
        name: 'Dresden Nachhaltigkeitsfonds UG & Co KG',
        logoPath: '4461.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7390,
        name: 'Dr. Walter GmbH',
        logoPath: '4460.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7337,
        name: 'Dr. Peters GmbH & Co. Emissionshaus KG',
        logoPath: '4459.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7884, name: 'DR. AMANN AG', companyType: CompanyType.INSURANCE},
    {
        id: 8056,
        name: 'Dortmunder Lebensversicherung',
        logoPath: '4458.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7144, name: 'Doric GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7087,
        name: 'Donau Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7207,
        name: 'Domcura AG',
        logoPath: '4457.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8670,
        name: 'Dolleruper Freie Brandgilde VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7547,
        name: 'DOCURA VVaG',
        logoPath: '4440.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8779,
        name: 'docufy GmbH',
        logoPath: '4947.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7888,
        name: 'DOBA Grund Beteiligungs GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8325,
        name: 'DMU - Deutsche Makler Union GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7575,
        name: 'DMO Deutsche Modellsport Organisation',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8564,
        name: 'DMB Rechtschutz-Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8760, name: 'DM-Drogerie Markt', companyType: CompanyType.MISC},
    {
        id: 7506,
        name: 'DKV Deutsche Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8931, name: 'DKB AG', companyType: CompanyType.MISC},
    {
        id: 8404,
        name: 'DJE Advisor Funds',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7774,
        name: 'diverse sonstige Wertpapiere',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7296,
        name: 'diverse Hersteller für nicht Wertpapiere',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7543,
        name: 'diverse Fondshersteller',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7822,
        name: 'DIV Deutsche Immobilienfonds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8676,
        name: 'Direkte Leben Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8284,
        name: 'direkt Assekuranz Service GmbH',
        logoPath: '4948.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8918, name: 'Digistore24 GmbH', companyType: CompanyType.MISC},
    {
        id: 7483,
        name: 'diehanauer24 Versicherungsvertriebs-GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8195,
        name: 'Die Vorsorge Sterbekasse der Werkangehörigen der',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6997,
        name: 'Die Sport Assekuranz Financial & Insurance Broker',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7951,
        name: 'Die Schwenninger Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8283,
        name: 'die Hypotheker',
        logoPath: '4949.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7057,
        name: 'Die Haftpflichtkasse VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8627,
        name: 'DIE BERGISCHE KRANKENKASSE',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7080,
        name: 'die Bayerische',
        logoPath: '4950.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8878,
        name: 'Dialog Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7074,
        name: 'Dialog Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7806,
        name: 'DFV Deutsche Fondsvermögen AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7590,
        name: 'DFV Deutsche Familienversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7764,
        name: 'DF Deutsche Finance AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8065,
        name: 'Dexia Asset Management Luxembourg S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9014,
        name: 'DEVK',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8116,
        name: 'DEUTSCHER RING Sachversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6971,
        name: 'DEUTSCHER RING Lebensversicherungs- AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8242,
        name: 'Deutscher Reisepreis-Sicherungsverein VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7903,
        name: 'Deutscher Mieterbund e.V.',
        logoPath: '4455.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7173,
        name: 'Deutscher Lloyd GmbH',
        logoPath: '4456.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8175,
        name: 'Deutscher Herold',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7882,
        name: 'Deutscher Hebammenverband e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7549,
        name: 'Deutscher Altas',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8403,
        name: 'Deutsche Zweitmarkt AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7143,
        name: 'Deutsche Yacht-Pool Versicherungs-Service GmbH',
        logoPath: '4867.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8487,
        name: 'Deutsche Vermögensberatung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8690,
        name: 'Deutsche Telekom',
        companyType: CompanyType.INSURANCE
    },
    {id: 8912, name: 'Deutsche Tamoil GmbH', companyType: CompanyType.MISC},
    {
        id: 7599,
        name: 'Deutsche Structured Finance GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8904,
        name: 'Deutsche Postcode Lotterie',
        companyType: CompanyType.MISC
    },
    {
        id: 8841,
        name: 'Deutsche Post AG',
        companyType: CompanyType.MISC
    },
    {
        id: 8457,
        name: 'Deutsche Pflegeheim Herkenrath GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7243,
        name: 'Deutsche Lichtmiete Vertriebsgesellschaft GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7950,
        name: 'Deutsche Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8574,
        name: 'Deutsche Kreditbank AG',
        logoPath: '4453.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7120,
        name: 'Deutsche Kautionskasse AG',
        logoPath: '4868.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7905,
        name: 'Deutsche Kameradschaft Solingen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7835,
        name: 'Deutsche Investitions- und Vermögens-Treuhand AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7742,
        name: 'deutsche internet versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7060,
        name: 'Deutsche Immobilienrendite GmbH & Co. KG, Berlin',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7217,
        name: 'Deutsche Immobilien Invest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7814,
        name: 'Deutsche Genossenschafts-Hypothekenbank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8271,
        name: 'Deutsche BP AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7841,
        name: 'Deutsche Biomassekraftwerk I GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7030,
        name: 'Deutsche Bausparkasse Badenia AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 9039, name: 'Deutsche Bank', companyType: CompanyType.INSURANCE},
    {
        id: 8751,
        name: 'Deutsche Bahn',
        companyType: CompanyType.MISC
    },
    {
        id: 8945,
        name: 'Deutsche AVIA Mineralöl-GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8322,
        name: 'Deutsche Assistance Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7578,
        name: 'Deutsche Asset & Wealth Management International GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8277,
        name: 'Deutsche Ärzteversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8169,
        name: 'Deutsche Apotheker- und Ärztebank',
        logoPath: '4451.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8249,
        name: 'Deutsche Anwalt- und Notar-Versicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8419,
        name: 'Deutsch Siedlungs- und Landesrentenbank',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8611,
        name: 'DEURAG Deutsche Rechtschutz-Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7294, name: 'DEUFINANZ AG', companyType: CompanyType.INSURANCE},
    {
        id: 8902,
        name: 'Der Beck GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8748,
        name: 'Der Beck',
        logoPath: '4447.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7821,
        name: 'DEPOSIT SOLUTIONS GmbH (Zinspilot)',
        companyType: CompanyType.INSURANCE
    },
    {id: 8399, name: 'Demafair GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7876,
        name: 'DEMA Deutsche Versicherungsmakler AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7279,
        name: 'Delvag Versicherungs-AG',
        logoPath: '4158.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8245,
        name: 'Delta Lloyd Vorsorgemanagement e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8324,
        name: 'Delta Lloyd Pensionskasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8422,
        name: 'Delta Lloyd Asset Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6944,
        name: 'Delta Direkt Lebensversicherung AG',
        logoPath: '4445.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6968,
        name: 'DekaBank Deutsche Girozentrale',
        logoPath: '4444.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8333,
        name: 'Deka Investment GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7643,
        name: 'Deka International S.A.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8963, name: 'Deichmann', companyType: CompanyType.MISC},
    {
        id: 8198,
        name: 'DEGI Deutsche Gesellschaft für Immobilienfonds mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7567,
        name: 'Degenia Versicherungsdienst AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7556,
        name: 'DEF Verwaltungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8681, name: 'DEF Management GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8935,
        name: 'Decathlon',
        companyType: CompanyType.MISC
    },
    {
        id: 8327,
        name: 'Debi Select Classic Fonds Gmbh & CO. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7869,
        name: 'Debeka Lebensversicherungsverein a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7153,
        name: 'Debeka Krankenversicherungsverein a.G.',
        logoPath: '4443.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7997,
        name: 'Debeka Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7804,
        name: 'Debeka Bausparkasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8215,
        name: 'Debeka Allgemeine Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7479,
        name: 'DCM Deutsche Capital Management AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7745,
        name: 'DBV-Winterthur Fund Management Company (Luxembourg) S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9052,
        name: 'DBV - Deutsche Beamtenversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8066,
        name: 'DBD Deutsche Biodiesel GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7051,
        name: 'dbb Vorsorgewerk',
        logoPath: '4951.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7469,
        name: 'DB-Deutsche Bank AG - Lebensversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7220,
        name: 'db x-trackers',
        logoPath: '4647.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7192,
        name: 'DB Vita S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8707,
        name: 'DB Vertrieb GmbH',
        logoPath: '4433.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 6993,
        name: 'DB Platinum Advisors',
        logoPath: '4435.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8791,
        name: 'DB Energie GmbH',
        logoPath: '4434.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8158,
        name: 'Davis Funds Sicav',
        companyType: CompanyType.INSURANCE
    },
    {id: 8885, name: 'DATEV eG', companyType: CompanyType.MISC},
    {
        id: 8739,
        name: 'DATEV',
        logoPath: '4338.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7565,
        name: 'DasEmissionsHaus GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7439,
        name: 'darta saving life assurance ltd',
        companyType: CompanyType.INSURANCE
    },
    {id: 8042, name: 'DARAG', companyType: CompanyType.INSURANCE},
    {
        id: 7520,
        name: 'Danske Invest Management Company S.A.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8975, name: 'Dänisches Bettenlager', companyType: CompanyType.MISC},
    {
        id: 7851,
        name: 'DAK',
        logoPath: '4431.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8395,
        name: 'DaimlerChrysler Financial Services AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7919,
        name: 'Daimler Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8621,
        name: 'Dachverband der Unterstützungskassen für',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7382,
        name: 'DAB Bank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7214,
        name: 'DA Direkt über',
        logoPath: '4430.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7184,
        name: 'DA direkt - Deutsche Allgemeine Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8515, name: 'D.A.S. Leben', companyType: CompanyType.INSURANCE},
    {
        id: 7276,
        name: 'D.A.S. Deutscher Automobil Schutz',
        companyType: CompanyType.INSURANCE
    },
    {id: 8813, name: 'CVJM', companyType: CompanyType.MISC},
    {
        id: 8606,
        name: 'Credura Kreditversicherungskonzepte',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7909,
        name: 'Credit Suisse Schweiz',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7984,
        name: 'Credit Suisse Asset Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7326, name: 'Credit Life AG', companyType: CompanyType.INSURANCE},
    {
        id: 7093,
        name: 'Credit Europe Bank',
        logoPath: '4429.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7656,
        name: 'Crédit Agricole Asset Management',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8625,
        name: 'CreaCon Vermögensverwaltung',
        companyType: CompanyType.INSURANCE
    },
    {id: 8881, name: 'Coya AG', companyType: CompanyType.INSURANCE},
    {
        id: 7090,
        name: 'Covomo Versicherungsvergleich GmbH',
        logoPath: '4428.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6996,
        name: 'Côte d´Azur Invest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7967,
        name: 'Cosmos Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7025,
        name: 'Cosmos Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7309,
        name: 'Convest 21 Services',
        companyType: CompanyType.INSURANCE
    },
    {id: 7601, name: 'Conversio e.K.', companyType: CompanyType.INSURANCE},
    {
        id: 7222,
        name: 'Contrium Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9017,
        name: 'Continentale Versicherungsbund',
        companyType: CompanyType.MISC
    },
    {
        id: 9047,
        name: 'Continentale',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8368,
        name: 'CONTI CORONA Anlageberatungsgesellschaft mbH & Co. Vertriebs',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7671,
        name: 'Consulting & Marketing GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8244,
        name: 'CONSTANTIA Versicherungen a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8454,
        name: 'Constantia Privatbank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8965,
        name: 'Consors Finanz',
        companyType: CompanyType.MISC
    },
    {id: 8796, name: 'Considine Inc', companyType: CompanyType.INSURANCE},
    {
        id: 8462,
        name: 'CONSAL MaklerService GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7620,
        name: 'ConRendit Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8910,
        name: 'Conrad Electronic SE',
        companyType: CompanyType.MISC
    },
    {id: 8735, name: 'Conrad', companyType: CompanyType.MISC},
    {
        id: 7167,
        name: 'Condor Lebensversicherung AG',
        logoPath: '4337.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8226,
        name: 'Condor Allgemeine Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7489,
        name: 'CONCORDIA Versicherungs-Gesellschaft a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8371,
        name: 'Concordia Rechtsschutz-Versicherungs-AG',
        logoPath: '4426.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7470,
        name: 'CONCORDIA oeco Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7170,
        name: 'CONCORDIA Krankenversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7277,
        name: 'Conceptif Pro GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8021, name: 'ConceptIF AG', companyType: CompanyType.INSURANCE},
    {
        id: 8334,
        name: 'Conceptass Versicherungsmakler GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7147,
        name: 'Commodity Trade GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7109,
        name: 'Commerzbank AG',
        logoPath: '4162.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8761,
        name: 'Commerzbank',
        logoPath: '65.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 9038,
        name: 'Commerz Real',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6980,
        name: 'Commerz Funds Solutions S.A.',
        logoPath: '4424.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8192,
        name: 'COMINVEST Asset Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8016,
        name: 'cominvest Asset Management GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8357,
        name: 'Comgest Asset Management International Limited',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8046,
        name: 'comdirect bank AG',
        logoPath: '4527.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8472,
        name: 'Combined',
        companyType: CompanyType.INSURANCE
    },
    {id: 8840, name: 'Coffee Fellows', companyType: CompanyType.MISC},
    {
        id: 7784,
        name: 'CLF Car Lease und Factoring AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8828,
        name: 'clever fit Betriebs GmbH + Co.KG',
        companyType: CompanyType.MISC
    },
    {
        id: 7979,
        name: 'Clerical Medical Investment Group Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8476,
        name: 'Clean Water Fund GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7446,
        name: 'Clean Planet GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8167,
        name: 'Citibank International Plc',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8720,
        name: "CINECITTA' Multiplexkino GmbH & Co. KG",
        companyType: CompanyType.MISC
    },
    {
        id: 8459,
        name: 'CIGNA Life Insurance Company',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8058,
        name: 'Cigna Europe Insurance Company S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8081,
        name: 'Chubb European Group SE',
        logoPath: '4420.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8188,
        name: 'Chorus Vertriebs-GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8782,
        name: 'Chili S.p.A.',
        logoPath: '4873.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 8691,
        name: 'Chemie Pensionsfonds AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8845, name: 'Check24', companyType: CompanyType.MISC},
    {
        id: 8943,
        name: 'Chase Paymentech Europe Limited',
        companyType: CompanyType.MISC
    },
    {
        id: 7496,
        name: 'CH2 Contorhaus Hansestadt Hamburg AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7597,
        name: 'CG Car-Garantie Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7053,
        name: 'CFB Commerz Fonds Beteiligungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7232,
        name: 'CF Fonds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7259,
        name: 'Ceros Financial Services AG',
        logoPath: '4872.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8263,
        name: 'Central Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7539,
        name: 'cash.life Vertriebsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7648,
        name: 'Carmignac Gestion',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7260,
        name: 'Carlson Investment Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7055,
        name: 'CARL RiECK ASSECURADEUR GmbH u. Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7023, name: 'Care Concept AG', companyType: CompanyType.INSURANCE},
    {
        id: 8439,
        name: 'Cardea Life (Prisma Life)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7420,
        name: 'Capital International Fund',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7014,
        name: 'Capital Bank',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9042,
        name: 'Canada Life Assurance Europe',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8329,
        name: 'Canada Gold Trust GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8494, name: 'Callander Fund', companyType: CompanyType.INSURANCE},
    {
        id: 7369,
        name: 'C-Quadrat Kapitalanlage AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7791, name: 'BVV', companyType: CompanyType.INSURANCE},
    {
        id: 7237,
        name: 'BVUK',
        logoPath: '4386.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8486,
        name: 'BVMW Versorgungswerke',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8187,
        name: 'BVK - Zusatzversorgungskasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8260,
        name: 'BVAG Berliner Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7551,
        name: 'BUSS Capital GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7887, name: 'Bundeswehr', companyType: CompanyType.INSURANCE},
    {
        id: 8421,
        name: 'Bundesverband der landwirtschaftlichen',
        companyType: CompanyType.INSURANCE
    },
    {id: 7555, name: 'Bundesknappschaft', companyType: CompanyType.INSURANCE},
    {
        id: 8932,
        name: 'Bundeskasse Weiden',
        companyType: CompanyType.MISC
    },
    {id: 8919, name: 'Bundeskasse Trier', companyType: CompanyType.MISC},
    {
        id: 8914,
        name: 'Bundesagentur für Arbeit',
        companyType: CompanyType.MISC
    },
    {
        id: 7008,
        name: 'BÜFA Chemikalien GmbH & Co. KG',
        logoPath: '4419.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8023,
        name: 'BSQ Bauspar AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7861,
        name: 'Bruderhilfe Sachversicherung AG im Raum der Kirchen',
        logoPath: '4880.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8298,
        name: 'Brokers Conzept GmbH & Co KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9002,
        name: 'BREUNINGER',
        companyType: CompanyType.MISC
    },
    {
        id: 7759,
        name: 'Brandenburgische Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8385,
        name: 'Bouwfonds Real Estate Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8853,
        name: 'Boulderhalle E4 GmbH&Co.KG',
        companyType: CompanyType.MISC
    },
    {
        id: 7061,
        name: 'Bosch Pensionfonds',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8616,
        name: 'Bosch Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8961,
        name: 'Bondora Capital OÜ',
        companyType: CompanyType.MISC
    },
    {id: 7694, name: 'BOLL KG', companyType: CompanyType.INSURANCE},
    {
        id: 7854,
        name: 'Bochumer Verband',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8423,
        name: 'BNP Paribas S.A. Niederlassung Deutschland (DAB)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8477,
        name: 'BNP Paribas Fondsmarketing GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8238,
        name: 'BNP Paribas Cardif Deutschland Lebensversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7017,
        name: 'BNP Paribas (Suisse) SA',
        logoPath: '4180.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7917,
        name: 'BMW Betriebskrankenkasse',
        logoPath: '4904.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8898,
        name: 'BMW Bank',
        logoPath: '47.svg',
        companyType: CompanyType.MISC
    },
    {id: 8634, name: 'Blue Capital GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7183,
        name: 'BlackRock (Luxembourg) S.A.',
        logoPath: '4416.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8842, name: 'Black Bean GmbH', companyType: CompanyType.MISC},
    {
        id: 7754,
        name: 'BKK',
        companyType: CompanyType.INSURANCE
    },
    {id: 7084, name: 'BIW Bank AG', companyType: CompanyType.INSURANCE},
    {
        id: 8530,
        name: 'BIT - Beteiligungs- & Investitions- Treuhand AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8891, name: 'Billpay GmbH', companyType: CompanyType.MISC},
    {
        id: 8543,
        name: 'BIG-Bundesinnungskrankenkasse Gesundheit',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6965,
        name: 'BIG direkt gesund',
        logoPath: '4181.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7182,
        name: 'BHW Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8603, name: 'BHW Bausparkasse AG', companyType: CompanyType.INSURANCE},
    {
        id: 8605,
        name: 'BGV-Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 9022, name: 'BFS health finance GmbH', companyType: CompanyType.MISC},
    {
        id: 7832,
        name: 'BetriebskrankenkassePublic',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7681,
        name: 'Betriebskrankenkasse ZF & Partner',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7598,
        name: 'Betriebskrankenkasse Würth',
        logoPath: '4165.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7500,
        name: 'Betriebskrankenkasse Wirtschaft & Finanzen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8468,
        name: 'Betriebskrankenkasse Werra-Meissner',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7660,
        name: 'Betriebskrankenkasse Voralb HELLER*INDEX*LEUZE',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8602,
        name: 'Betriebskrankenkasse Verkehrsbau Union',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8013,
        name: 'Betriebskrankenkasse VerbundPlus',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7999,
        name: 'Betriebskrankenkasse VDN',
        logoPath: '4414.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8601,
        name: 'Betriebskrankenkasse Textilgruppe Hof',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8514,
        name: 'Betriebskrankenkasse Technoform',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7493,
        name: 'Betriebskrankenkasse STADT AUGSBURG',
        logoPath: '4168.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7168,
        name: 'Betriebskrankenkasse Schwarzwald-Baar-Heuberg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7049,
        name: 'Betriebskrankenkasse Scheufelen',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7063,
        name: 'Betriebskrankenkasse Salzgitter',
        logoPath: '4413.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8299,
        name: 'Betriebskrankenkasse RWE',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8155,
        name: 'Betriebskrankenkasse Rieker.RICOSTA.Weisser',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7532,
        name: 'Betriebskrankenkasse ProVita',
        logoPath: '4167.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8597,
        name: 'Betriebskrankenkasse PricewaterhouseCoopers',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7271,
        name: 'Betriebskrankenkasse Pfalz',
        logoPath: '4166.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8604,
        name: 'Betriebskrankenkasse PFAFF',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7877,
        name: 'Betriebskrankenkasse MTU',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8033,
        name: 'Betriebskrankenkasse Mobil Oil',
        logoPath: '4412.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7414,
        name: 'Betriebskrankenkasse Miele',
        logoPath: '4411.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7427,
        name: 'Betriebskrankenkasse Melitta Plus',
        logoPath: '4405.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7970,
        name: 'Betriebskrankenkasse MAHLE',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7579,
        name: 'Betriebskrankenkasse Linde',
        logoPath: '4404.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7033,
        name: 'Betriebskrankenkasse KBA',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7659,
        name: 'Betriebskrankenkasse KARL MAYER',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7175,
        name: 'Betriebskrankenkasse Herkules',
        logoPath: '4406.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8160,
        name: 'Betriebskrankenkasse Herford Minden Ravensberg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7715,
        name: 'Betriebskrankenkasse HENSCHEL Plus',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8214,
        name: 'Betriebskrankenkasse Groz-Beckert',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8268,
        name: 'Betriebskrankenkasse GRILLO-WERKE AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8427,
        name: 'Betriebskrankenkasse Gildemeister Seidensticker',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8173,
        name: 'Betriebskrankenkasse Freudenberg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7767,
        name: 'Betriebskrankenkasse firmus',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8111,
        name: 'Betriebskrankenkasse Faber-Castell & Partner',
        logoPath: '4407.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7436,
        name: 'Betriebskrankenkasse exklusiv',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7159,
        name: 'Betriebskrankenkasse EWE',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7209,
        name: 'Betriebskrankenkasse EVM',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7807,
        name: 'Betriebskrankenkasse EUREGIO',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7254,
        name: 'Betriebskrankenkasse DürkoppAdler',
        logoPath: '4408.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8103,
        name: 'Betriebskrankenkasse Diakonie',
        logoPath: '4410.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8208,
        name: 'Betriebskrankenkasse BPW Bergische Achsen KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8662,
        name: 'Betriebskrankenkasse Akzo Nobel Bayern',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7349,
        name: 'Betriebskrankenkasse Aesculap',
        logoPath: '4409.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8398,
        name: 'Betriebskrankenkasse Achenbach Buschhütten',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7478,
        name: 'Betriebskrankenkasse 24',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8946,
        name: 'Best Secret GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 7751,
        name:
            'Berufsgenossenschaft für Gesundheitsdienst und Wohlfahrtspflege (BGW)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8381,
        name: 'Bertelsmann Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7311,
        name: 'Berlinische Lebensversicherung / delta Lloyd',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8866,
        name: 'Berlin-Kölnische Krankenversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8802,
        name: "Bergnaum - D'Amore",
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6962,
        name: 'Bergische Brandversicherung Allgemeine Feuerversicherung V.a',
        companyType: CompanyType.INSURANCE
    },
    {id: 7800, name: 'Berenberg Universal', companyType: CompanyType.INSURANCE},
    {
        id: 8622,
        name: 'Berenberg Lux Invest S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7459,
        name: 'Beratungs-, Verwaltungs- Treuhand GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8540,
        name: 'Bendschneider Finanzmanagement GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7341,
        name: 'Benchmark Financial Services GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7527,
        name: 'Beloxx GmbH & Co KG',
        logoPath: '4170.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7344,
        name: 'BDAE Holding GmbH',
        logoPath: '4906.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7915,
        name: 'BD24 Berlin Direkt Versicherung AG',
        logoPath: '4905.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7570, name: 'BCM Altlast', companyType: CompanyType.INSURANCE},
    {
        id: 8030,
        name: 'BC-Geschäft Finanzierungen',
        companyType: CompanyType.INSURANCE
    },
    {id: 7977, name: 'BBV Versicherung AG', companyType: CompanyType.INSURANCE},
    {
        id: 9041,
        name: 'BBBank',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7587,
        name: 'BB-Wertpapier-Verwaltungsgesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8958,
        name: 'BayWa Bau & Garten',
        companyType: CompanyType.MISC
    },
    {id: 8727, name: 'Bayrisches Finanzamt', companyType: CompanyType.MISC},
    {
        id: 8258,
        name: 'Bayrische Versorgungskammer',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7669,
        name: 'Bayern-Versicherung Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8063,
        name: 'Bayerischer Versicherungsverband Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7573,
        name: 'Bayerischer Blinden und Sehbehindertenbund',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7546,
        name: 'Bayerische Landesbrandversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8336,
        name: 'Bayerische Hypo- und Vereinsbank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9056,
        name: 'Bayerische Hausbesitzer VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7707,
        name: 'Bayerische Beamtenkrankenkasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9008,
        name: 'Bayerische Beamtenkasse AG',
        companyType: CompanyType.MISC
    },
    {
        id: 7682,
        name: 'Bayerische Beamten Versicherung AG',
        logoPath: '4403.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8495,
        name: 'BAYERISCHE BEAMTEN Lebensversicherung a.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7625,
        name: 'Bayerische Ärzteversicherung',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8400,
        name: 'BavariaDirekt',
        companyType: CompanyType.INSURANCE
    },
    {id: 7121, name: 'BAVARIA', companyType: CompanyType.INSURANCE},
    {
        id: 7661,
        name: 'Bausparkasse Mainz AG',
        logoPath: '4402.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7920,
        name: 'BauFinanzierung Direct AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8269,
        name: 'Baufi-Center-Borstelmann',
        companyType: CompanyType.INSURANCE
    },
    {id: 7930, name: 'BauFi c/o tecis AG', companyType: CompanyType.INSURANCE},
    {
        id: 8085,
        name: 'Basler Sachversicherungs-AG',
        logoPath: '4400.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8471,
        name: 'Basler Lebensversicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7285,
        name: 'BASF Pensionskasse VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7763,
        name: 'Barmer Ersatzkasse',
        logoPath: '4401.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9031,
        name: 'Barmenia',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7670,
        name: 'Baring Fonds Service GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8913,
        name: 'Barclaycard',
        companyType: CompanyType.MISC
    },
    {id: 7513, name: 'Bantleon Invest S.A', companyType: CompanyType.INSURANCE},
    {
        id: 7423,
        name: 'Banque Generale Luxembourg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7580,
        name: 'Banque de Luxembourg Sicav',
        logoPath: '4172.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6974,
        name: 'Bankhaus Wölbern & Co (AG & Co KG)',
        companyType: CompanyType.INSURANCE
    },
    {id: 8922, name: 'Bankhaus August Lenz', companyType: CompanyType.MISC},
    {
        id: 7411,
        name: 'Bankges. Berlin Investment GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 7132, name: 'bank zweiplus ag', companyType: CompanyType.INSURANCE},
    {
        id: 8320,
        name: 'Bank für Gemeinwirtschaft',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8346,
        name: 'Baloise Life (Liechtenstein) AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8447,
        name: 'Baloise Fund Invest (Lux)',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8047,
        name: 'BAHN Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7323,
        name: 'Badischer Gemeinde-Versicherungs-Verband',
        logoPath: '4177.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7298,
        name: 'Badische Rechtsschutzversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7303,
        name: 'Badische Allgemeine Versicherung AG',
        logoPath: '4388.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8090,
        name: 'Baden-Würtembergische Investment mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8402,
        name: 'Baden-Badener Versicherung AG',
        logoPath: '4387.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8838, name: 'BackWerk', companyType: CompanyType.MISC},
    {
        id: 7996,
        name: 'Bachthaler Assecuranz GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7412,
        name: 'BAC Berlin Atlantic Capital AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8775,
        name: 'B&M Bamberg',
        companyType: CompanyType.MISC
    },
    {
        id: 8332,
        name: 'B.I.A.C. Immobilien GmbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7697,
        name: 'B. Braun Melsungen AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7350,
        name: 'Axxion S.A.',
        logoPath: '4379.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8818,
        name: 'Axosoft',
        companyType: CompanyType.MISC
    },
    {
        id: 8546,
        name: 'Axa Equity & Law Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9033,
        name: 'AXA',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7320,
        name: 'AWP P&C S.A:, Niederlassung für Deutschland',
        logoPath: '4352.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7406,
        name: 'AVOLO Aviation GmbH & Co Geschlossene Investment KG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8526,
        name: 'AVK',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7426,
        name: 'Aviva Life & Pensions UK Limited',
        logoPath: '4378.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7652,
        name: 'Aviva Funds Services S.A.',
        logoPath: '4377.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7504,
        name: 'AVD Allgemeiner Versicherungsdienst Gesellschaft m. b. H.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7820,
        name: 'AVANA Invest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8488,
        name: 'Auxilia Rechtschutz-Versicherungs-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8843,
        name: 'Autohaus Pröschel GmbH',
        companyType: CompanyType.MISC
    },
    {
        id: 8844,
        name: 'Autohaus & Tankstelle Schroth',
        companyType: CompanyType.MISC
    },
    {
        id: 7116,
        name: 'Augsburger Aktienbank',
        logoPath: '4376.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8666,
        name: 'Aufwind Schmack GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8764,
        name: 'Audible',
        companyType: CompanyType.MISC
    },
    {
        id: 8615,
        name: 'Audi Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7776,
        name: 'Audi Bank',
        companyType: CompanyType.INSURANCE
    },
    {id: 7794, name: 'ATS FinanzService', companyType: CompanyType.INSURANCE},
    {
        id: 7461,
        name: 'atlas Betriebskrankenkasse ahlmann',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8184,
        name: 'Atlantic Gesellschaft zur Vermittlung Internationaler Invest',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8397,
        name: 'Athora Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8209,
        name: 'Athene Lebensversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8270, name: 'AT-Select-Prov', companyType: CompanyType.INSURANCE},
    {
        id: 8585,
        name: 'asuco Fonds GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7731,
        name: 'ASTRA S.A. Versicherung i.G.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8015,
        name: 'Astoria Invest AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8658,
        name: 'Assurant General Insurance Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7299,
        name: 'ASSTEL Sachversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7675,
        name: 'ASSTEL Lebensversicherung AG',
        logoPath: '4916.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8550,
        name: 'ASSTEL Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8640,
        name: 'asspario Versicherungsdienst AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8396,
        name: 'assona GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8377,
        name: 'Assenagon Asset Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8028,
        name: 'Aspro AG Aegon Service Center',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8122,
        name: 'ASPECTA Versicherung AG',
        logoPath: '4917.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8347,
        name: 'Aspecta Assurance Int. Luxemburg S.A,',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7873,
        name: 'ASKUMA AG',
        logoPath: '4918.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7066,
        name: 'ASC Assekuranz Service Center GmbH',
        logoPath: '4919.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 8875, name: 'asano AG', companyType: CompanyType.INSURANCE},
    {
        id: 8980,
        name: 'Arvato',
        companyType: CompanyType.MISC
    },
    {
        id: 7464,
        name: 'ARISA Assurances S.A.,',
        logoPath: '4920.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7228,
        name: 'Arcturus AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8248,
        name: 'ARCD Auto- und Reiseclub',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8755,
        name: 'Aral',
        companyType: CompanyType.MISC
    },
    {id: 8763, name: 'ARAG', companyType: CompanyType.INSURANCE},
    {
        id: 7416,
        name: 'Aquila Capital Structured Assets GmbH',
        logoPath: '4184.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8648,
        name: 'Aquila Capital Concepts GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7948,
        name: 'APRIL Deutschland AG',
        companyType: CompanyType.INSURANCE
    },
    {id: 8971, name: 'Apple', companyType: CompanyType.MISC},
    {
        id: 8436,
        name: 'Apox Immobilien + Finanzservice',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8474,
        name: 'ApolloMedia Filmmanagement GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7971,
        name: 'Apano GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7838,
        name: 'AOK',
        logoPath: '4350.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8534,
        name: 'Anglo Unterwriting GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7608,
        name: 'Angebot Kompetenzcenter Sach',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7747,
        name: 'Angebot Kompetenzcenter Leben',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7367,
        name: 'Angebot Kompetenzcenter Kranken',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8642,
        name: 'Angebot Dummy',
        companyType: CompanyType.INSURANCE
    },
    {id: 8794, name: 'andsafe AG', companyType: CompanyType.INSURANCE},
    {
        id: 7231,
        name: 'Anaxis Asset Management',
        logoPath: '4908.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 9051, name: 'Amundi Pioneer', companyType: CompanyType.INSURANCE},
    {
        id: 8129,
        name: 'Amundi Luxembourg S.A.',
        logoPath: '4375.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8624,
        name: 'Amundi Investment Solutions',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7933,
        name: 'Amundi Deutschland GmbH',
        logoPath: '4374.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7696,
        name: 'Ampega Investment GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7413,
        name: 'Ampas A/M-Heilwesenverträge',
        companyType: CompanyType.INSURANCE
    },
    {id: 8340, name: 'AMOTIS', companyType: CompanyType.INSURANCE},
    {
        id: 8448,
        name: 'Ammerländer Versicherung VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7164,
        name: 'Amex Versicherungsmakler',
        logoPath: '4909.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6947,
        name: 'American Growth Fund',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7726,
        name: 'American Global Investment Fund',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8724,
        name: 'Amazon',
        companyType: CompanyType.MISC
    },
    {
        id: 6973,
        name: 'AlzChem Trostberg GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7719,
        name: 'Alternative Capital Invest GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6972,
        name: 'ALTE OLDENBURGER Krankenversicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7127,
        name: 'ALTE LEIPZIGER Versicherung AG',
        logoPath: '4345.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8762,
        name: 'Alte Leipziger',
        logoPath: '4372.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7288,
        name: 'ALT HUK Coburg Allgemeine Versicherungs AG',
        logoPath: '4351.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7733,
        name: 'ALPINE TRUST MANAGEMENT AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7739,
        name: 'AllSecur Deutschland AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7424,
        name: 'ALLRECHT Rechtsschutzversicherungen, ZN der DEURAG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9037,
        name: 'Allianz',
        companyType: CompanyType.INSURANCE
    },
    {id: 8756, name: 'Allguth GmbH', companyType: CompanyType.MISC},
    {
        id: 7372,
        name: 'Allgemeine Rentenanstalt Pensionskasse',
        logoPath: '4364.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8124,
        name: 'Allcura Versicherungs-',
        logoPath: '4363.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8067,
        name: 'Alken Asset Management Ltd.',
        logoPath: '4371.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6959,
        name: 'Alico Life International Ltd',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7046,
        name: 'Alhermij',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7885,
        name: 'Alger Management Ltd.',
        logoPath: '4362.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8461,
        name: 'Algebris Investments',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8711,
        name: 'Aldi Süd',
        logoPath: '4361.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 9023,
        name: 'ALDI Nord',
        companyType: CompanyType.MISC
    },
    {
        id: 7676,
        name: 'Alceda Fund Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7034,
        name: 'Albingia Versicherung-AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7611,
        name: 'AL Deutsche Beteiligung GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7199,
        name: 'AKTIVA Verwaltungs GmbH',
        logoPath: '4921.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8105,
        name: 'Aktiv Assekuranz Makler GmbH',
        logoPath: '4911.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8957,
        name: 'Aktion Mensch',
        companyType: CompanyType.MISC
    },
    {id: 8900, name: 'Akimoto Sushi Nürnberg', companyType: CompanyType.MISC},
    {
        id: 7912,
        name: 'AKA - Augenoptiker Ausgleichskasse VVaG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8285,
        name: 'Aioi Nissay Dowa Life Insurance of Europe AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8236,
        name: 'AIG Privat Bank AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8156,
        name: 'AIG Life Wealth Management',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8628,
        name: 'AIG Life (Ireland) Ltd.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7404,
        name: 'AIG Europe S.A., Direktion für Deutschland',
        logoPath: '4342.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7319,
        name: 'AIB Fund Managers (CI) Limited',
        logoPath: '4343.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7088,
        name: 'AIA AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7939,
        name: 'Ahead Wealth Solutions AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7720,
        name: 'Agrofinanz GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7097,
        name: 'AGILA Haustierversicherung AG',
        logoPath: '4923.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8631,
        name: 'AGEAS',
        logoPath: '4924.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7191,
        name: 'AGA Unternehmensverband e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8077,
        name: 'AFS - Allgemeiner FondsService',
        companyType: CompanyType.INSURANCE
    },
    {id: 8326, name: 'Aeternitas e.V.', companyType: CompanyType.INSURANCE},
    {
        id: 7078,
        name: 'Aegon Niederlassung Deutschland der Aegon Ireland plc',
        companyType: CompanyType.INSURANCE
    },
    {id: 8792, name: 'Adyen B.V.', companyType: CompanyType.MISC},
    {
        id: 8897,
        name: 'Adyen',
        companyType: CompanyType.MISC
    },
    {
        id: 7368,
        name: 'AdvoCard Rechtsschutzversicherung AG',
        logoPath: '4358.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 9015,
        name: 'AdvoCard',
        companyType: CompanyType.MISC
    },
    {
        id: 8356,
        name: 'Advigon Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 6946,
        name: 'AdmiralDirekt.de GmbH',
        logoPath: '4912.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8247,
        name: 'ADLER Versicherung AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7394,
        name: 'Adler Signal Iduna',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7370,
        name: 'Adig Altverträge',
        logoPath: '4914.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7564,
        name: 'Adepa Asset Management S.A.',
        companyType: CompanyType.INSURANCE
    },
    {id: 8508, name: 'Adcuri GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8643,
        name: 'Adam Riese GmbH',
        companyType: CompanyType.INSURANCE
    },
    {id: 8970, name: 'ADAC e.V.', companyType: CompanyType.INSURANCE},
    {
        id: 8473,
        name: 'ACV Automobil-Club Verkehr e.V.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8776,
        name: 'Action Deutschland GmbH',
        logoPath: '4913.svg',
        companyType: CompanyType.MISC
    },
    {
        id: 7956,
        name: 'actimonda Betriebskrankenkasse',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8091,
        name: 'ACE European Group Limited',
        logoPath: '4925.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8246,
        name: 'ACE Auto Club Europa e.V.',
        logoPath: '4960.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7991,
        name: 'Accuro Fund Solutions AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8770,
        name: 'ABZ Zahnärztliches Rechenzentrum',
        companyType: CompanyType.MISC
    },
    {
        id: 6952,
        name: 'ABO Wind  AG',
        logoPath: '4959.svg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8667,
        name: 'ABN Amro Investment Funds S.A. Luxemburg',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7743,
        name: 'Aberdeen Global Services S.A.',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7847,
        name: 'Aberdeen Asset Management Deutschland AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7076,
        name: 'abakus Konzepte GmbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7642,
        name: 'AB Europe GmbH (AllianceBernstein)',
        logoPath: '4196.svg',
        companyType: CompanyType.INSURANCE
    },
    {id: 9012, name: 'AachenMünchener', companyType: CompanyType.INSURANCE},
    {
        id: 8618,
        name: 'Aachener Bausparkasse AG',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 8659,
        name: 'AAA Capital - Atlantic Investitionsgesellschaft mbH & Co. KG',
        companyType: CompanyType.INSURANCE
    },
    {id: 7440, name: 'A-Invest GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 7502,
        name: 'A & O Autoversicherung Oldenburg',
        companyType: CompanyType.INSURANCE
    },
    {id: 7602, name: '7x7finanz GmbH', companyType: CompanyType.INSURANCE},
    {
        id: 8947,
        name: '365 AG',
        companyType: CompanyType.MISC
    },
    {
        id: 8024,
        name: '3 Banken-Generali Investment-Gesellschaft mbH',
        companyType: CompanyType.INSURANCE
    },
    {
        id: 7714,
        name: '1741 Fund Solutions AG',
        companyType: CompanyType.INSURANCE
    },
    {
        companyType: CompanyType.MISC,
        logoPath: '4329.svg',
        name: '1&1 Telecom GmbH',
        id: 8713
    },
    {
        id: 8829,
        name: '1&1 Ionos SE',
        companyType: CompanyType.MISC
    }
];
export const testVehicleHolder: IVehicleHolder = {
    birthDate: new Date('1990-03-15'),
    familyName: 'Dow', //lastName
    firstName: 'John',
    city: 'TestCity',
    email: 'test@test.com',
    gender: 'female',
    houseNumber: '12',
    postalCode: '90489', //postcode
    street: 'Test-Str.'
};

export const testCarInsuranceProposal: IProposal = {
    currency: 'EUR',
    expirationDatetime: new Date(2021, 11, 20),
    insurerName: 'ONE Versicherung AG',
    green: {
        calculationId: 'testGreenId',
        productId: 'testGreenProduct',
        tariffWithTax: 20
    },
    normal: {
        calculationId: 'testNormalId',
        productId: 'testNormalProduct',
        tariffWithTax: 10
    },
    coverages: [
        {
            label: 'Better performance guarantee',
            name: 'car.better-performance-guarantee',
            lineId: 0,
            type: 'dynamic',
            value: 0
        },
        {
            label: 'Abroad protection',
            name: 'car.coverage.abroad-protection',
            lineId: 0,
            type: 'dynamic',
            value: 100000000,
            comment: 'Geographical Europe and EU, up to 100.000.000 €'
        }
    ]
};

export const carSwitchTemplate: IProposal = {
    currency: 'EUR',
    expirationDatetime: new Date(2021, 11, 20),
    insurerName: 'ONE Versicherung AG',
    green: {
        calculationId: 'testGreenId',
        productId: 'testGreenProduct',
        tariffWithTax: 20
    },
    normal: {
        calculationId: 'testNormalId',
        productId: 'testNormalProduct',
        tariffWithTax: 10
    },
    coverages: [
        {
            label: 'Better performance guarantee',
            name: 'car.better-performance-guarantee',
            lineId: 0,
            type: 'dynamic',
            value: 0
        },
        {
            label: 'Abroad protection',
            name: 'car.coverage.abroad-protection',
            lineId: 0,
            type: 'dynamic',
            value: 100000000,
            comment: 'Geographical Europe and EU, up to 100.000.000 €'
        }
    ]
};

export const carInsuranceSubmitResponse: ICarInsuranceCompletionResponse = {
    currency: 'EUR',
    price: 198.34,
    id: 'IOhj3cN5W',
    calculationId: '5f19535b0becb8001c17f846',
    status: 'pending',
    paymentPeriod: 'Annual',
    tariffDescription: 'Comfort',
    insuranceStartDate: new Date('2020-08-12T00:00:00'),
    insuredRisk: 'XX XX 1234',
    insuranceNumber: '-',
    contractId: 1772,
    vbNumber: 'AB15RT7'
};

export const carInsuranceResultResponse: ICarInsuranceFinalResult = {
    accountId: 1000,
    categoryId: 235259,
    companyId: 8712,
    insurant: ['John Dow'],
    logoPath: '23.svg',
    interval: 'ANNUAL'
};

import {
    FETCH_ALL_CONTRACT_SUCCESS,
    FETCH_CANCELLATION_URL_SUCCESS,
    FETCH_INSURANCE_POLICIES,
    SUBMIT_NEW_CONTRACT_SUCCESS,
    UPDATE_RECURRINGPAYMENT_SUCCESS,
    SET_CAR_INSURANCE_SUMMARY_DATA,
    DELETE_RECURRINGPAYMENT,
    UPDATE_CONTRACT_SUCCESS
} from '../actions/actionTypes';
import get from 'lodash.get';
import {
    IRecurringpayment,
    isRecurringPayment
} from '../../models/recurringpaymentInterface';
import {IExistingInsuranceProduct} from '../../models/productInterface';

const temp = {}; //could be used for notifications form the BackEnd

export const initialState = get(temp, 'recurringpayments', {
    allRecurringpayments: [],
    cancellation: {},
    policies: []
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_RECURRINGPAYMENT_SUCCESS:
            if (typeof action.payload !== 'object') return state;
            if (
                !isRecurringPayment(action.payload) ||
                state.allRecurringpayments.length === 0
            )
                return state;
            return {
                ...state,
                allRecurringpayments: [
                    ...updateRecurringPaymentsWithNew(action.payload)
                ]
            };

        case FETCH_ALL_CONTRACT_SUCCESS:
            if (!(action.payload instanceof Array)) return state;
            return {
                ...state,
                allRecurringpayments: action.payload
            };

        case FETCH_CANCELLATION_URL_SUCCESS:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.url !== 'string' ||
                typeof action.payload.recurringPaymentId !== 'number'
            ) {
                return state;
            }

            return {
                ...state,
                cancellation: action.payload
            };

        case SUBMIT_NEW_CONTRACT_SUCCESS:
            if (typeof action.payload !== 'object') return state;
            if (!isRecurringPayment(action.payload)) return state;

            return {
                ...state,
                allRecurringpayments: [
                    ...state.allRecurringpayments,
                    action.payload
                ]
            };
        case UPDATE_CONTRACT_SUCCESS:
            if (typeof action.payload !== 'object') return state;

            return {
                ...state,
                policies: [...updateContract(action.payload)]
            };

        case FETCH_INSURANCE_POLICIES:
            return {
                ...state,
                policies: action.payload instanceof Array ? action.payload : []
            };
        case SET_CAR_INSURANCE_SUMMARY_DATA:
            return {
                ...state,
                policies: [...state.policies, action.payload.policy]
            };
        case DELETE_RECURRINGPAYMENT:
            const filteredPayments = state.allRecurringpayments.filter(
                (x: any) => x.recurringPaymentId !== action.payload
            );
            return {
                ...state,
                allRecurringpayments: [...filteredPayments]
            };
        default:
            return state;
    }

    /* IMPORTANT: immutable update, see https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns */
    function updateRecurringPaymentsWithNew(
        updatedRecurringpayment: IRecurringpayment
    ): IRecurringpayment[] {
        const allRecurringpayments = [...state.allRecurringpayments];

        const index = allRecurringpayments.findIndex(
            (r: IRecurringpayment) =>
                Number(r.recurringPaymentId) ===
                Number(updatedRecurringpayment.recurringPaymentId)
        );
        if (index === -1) return allRecurringpayments;

        // remove rp regardless of isArchived
        allRecurringpayments.splice(index, 1);

        // add updated rp again if it's not archived
        if (!updatedRecurringpayment.isArchived) {
            allRecurringpayments.push(updatedRecurringpayment);
        }

        return allRecurringpayments;
    }
    /* IMPORTANT: immutable update, see https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns */
    //Merge with updateRecurringPayment
    function updateContract(
        contract: IExistingInsuranceProduct
    ): IExistingInsuranceProduct[] {
        const allPolicies = [...state.policies];
        const index = allPolicies.findIndex(
            (r: IExistingInsuranceProduct) =>
                Number(r.id) === Number(contract.id)
        );
        if (index === -1) return allPolicies;

        // remove rp regardless of isArchived
        allPolicies.splice(index, 1);
        allPolicies.push(contract);

        return allPolicies;
    }
};

import React, {useEffect} from 'react';
import {Box, Card, Grid} from '@material-ui/core';
import {
    EnergyStep,
    IEnergyContractPreferences
} from '../../../../../models/offerInterface';
import {WgTypography} from '../../../../../styles/CustomComponents';
import {inputNotFilled} from '../Components/';
import AmountIntervalInput from '../Components/AmountIntervalInput';
import {IntervalType} from '../../../../../models/recurringpaymentInterface';
import InsuranceListItem from '../../../insurance/components/InsuranceListItem';
import {IAccount} from '../../../../../models/accountInterface';

export interface CostOrUsageInputProps {
    handleChange: (
        name: keyof IEnergyContractPreferences
    ) => (value: any) => void;
    handleAmountChange: Function;
    handleStepChange: Function;
    amount?: number;
    paymentInterval?: IntervalType;
    setInputValid: Function;
    isManualAddition?: boolean;
    account?: IAccount;
}

export default function CostOrUsageInput({
    handleChange,
    handleAmountChange,
    handleStepChange,
    amount,
    paymentInterval,
    setInputValid,
    isManualAddition,
    account
}: CostOrUsageInputProps) {
    useEffect(() => {
        setInputValid(
            !inputNotFilled(amount) &&
                typeof amount !== 'undefined' &&
                Math.abs(amount) > 0
        );
    }, [setInputValid, amount]);

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            justify="center"
            style={{flex: 1}}>
            <Card style={{padding: '1rem'}}>
                <WgTypography color="textPrimary" text="content">
                    Dein aktueller Beitrag
                </WgTypography>
                <WgTypography
                    gutterBottom
                    gutterTop="1.5rem"
                    color="textSecondary"
                    text="subContent">
                    {isManualAddition
                        ? 'Bitte nenne uns deinen aktuellen Beitrag.'
                        : 'Bitte bestätige kurz deinen Beitrag.'}
                </WgTypography>
                <Grid container spacing={1} style={{marginTop: '0.5rem'}}>
                    <Grid item xs={12} style={{paddingTop: '0.5rem'}}>
                        <AmountIntervalInput
                            amount={
                                typeof amount !== 'undefined'
                                    ? Math.abs(amount)
                                    : 0
                            }
                            paymentInterval={paymentInterval}
                            handleAmountChange={handleAmountChange}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Card>
            {isManualAddition && (
                <Box paddingTop="2rem">
                    <WgTypography
                        gutterBottom
                        gutterTop="1.5rem"
                        color="textSecondary"
                        text="subContent">
                        Verknüpfe ein Konto mit deinem Vertrag.
                    </WgTypography>
                    {renderAccountListItem()}
                </Box>
            )}
        </Grid>
    );

    function renderAccountListItem() {
        return (
            <InsuranceListItem
                placeholder="Bankkonto verknüpfen"
                shadow
                onClick={() => handleStepChange(EnergyStep.ACCOUNT_SELECTION)}
                data={account}
                hasIcon
            />
        );
    }
}

import React from 'react';
import PrivacyText from './PrivacyText';
import {WgContainer, WgHeader} from '../../../styles/CustomComponents';
import {Box} from '@material-ui/core';

export default function PrivacyNotLoggedIn() {
    return (
        <WgContainer publicBodyApp>
            <WgHeader
                isPublic
                headerText="Datenschutzerklärung"
                hasBackButton
            />
            <Box
                width="100%"
                overflow="auto"
                marginTop={{xs: '56px', sm: '64px'}}>
                <WgContainer
                    publicBodyContent
                    disableGutters={false}
                    style={{marginTop: '0px'}}>
                    <Box paddingY="1.5rem">
                        <PrivacyText />
                    </Box>
                </WgContainer>
            </Box>
        </WgContainer>
    );
}

import React from 'react';
import {connect} from 'react-redux';
import IFrameWrapper from '../../../utils/IFrameWrapper';

interface ICancellationProps {
    src: string;
}

const Cancellation = (props: ICancellationProps) => (
    <IFrameWrapper
        src={props.src}
        id="cancellationFrame"
        title={'Kündigung'}
        headerText={'Kündigung'}
        backButtonText="Vertragsdetails"
    />
);

function mapStateToProps(state: any) {
    return {
        src: state.recurringpayments.cancellation?.url ?? ''
    };
}
export default connect(mapStateToProps, {})(Cancellation);

import {
    accountByIdBalanceSelector,
    accountsBankConnectionJoinSelector,
    accountsIdSelector,
    accountByIdSelector,
    singleAccountIdSelector,
    allAccountsSelector,
    allHiddenAccountsSelector,
    allSecuritiesByAccountIdSelector,
    allVisibleAccountsSelector,
    allBankConnectionSelector,
    getAccounts,
    hiddenAccountsGroupedByBankConnectionSelector,
    hiddenNormalAccountsSelector,
    hiddenSavingsAccountsSelector,
    singleAccountSelector,
    visibleAccountsGroupedByBankConnectionSelector,
    visibleNormalAccountsSelector,
    visibleSavingsAccountsSelector
} from './accountSelector';

import {
    accByRecurringPaymentSelector,
    activeRecurringpaymentSelector,
    allContractWithoutInsurancesSelector,
    allRecurringPaymentContractSelector,
    allRecurringPaymentMiscSelector,
    allRecurringPaymentWithoutInsurancesSelector,
    recurringPaymentByIdNameSelector,
    recurringPaymentByIdSelector,
    recurringPaymentCategoryJoinSelector,
    recurringPaymentWithoutAccountSelector
} from './recurringpaymentSelector';

import {
    dateSelector,
    makeAllTransactionsFromAccountSelector,
    makeAllTransactionsFromRecurringpaymentSelector,
    allTransactionsFromAccountGroupedByDaySelector,
    singleTransactionByIdSelector,
    stepSelector
} from './transactionSelectors';

import {dashboardChartDataSelector} from './statisticSelector';
//@ts-ignore
import {registerSelectors} from 'reselect-tools';
import {
    allCategorySelector,
    categoryByIdSelector,
    categoryByLevelSelector,
    categoryByMeterTypeSelector,
    categoryByNameSelector,
    categoryColorByIdSelector,
    categoryInsuranceSelector,
    categoryIsEnergySelector,
    categoryTransactionsSelector,
    initialCategoriesByTypeSelector,
    topLevelCategoryByChildIdSelector
} from './categorySelectors';

import {
    allCompaniesByTypeSelector,
    companyByIdSelector,
    companyByNameSelector,
    getAllCompanies,
    initialCompaniesByTypeSelector
} from './companySelector';
import {
    getNotification,
    queueItemByIdSelector,
    queueSelector
} from './notificationSelector';
import {getUser, getUserIsLoggedIn, getUserUserData} from './userSelector';
import {
    allBanksSelector,
    bankBySearchStringSelector,
    bankSelector
} from './bankSelectors';

import {
    allInsuranceProductsSelector,
    policySelector,
    productByIdSelector
} from './productSelector';

import {offerByIdSelector} from './offerSelector';

import {
    getCarInsurance,
    getVehicleData,
    carInsuranceRequestDataSelector,
    policyFormDataSelector,
    getPolicyData,
    getCarInsuranceOptions,
    getVehicleHolderData,
    getCarInsuranceProposal,
    carInsuranceProposalRequestDataSelector,
    carInsuranceSummarySelector,
    getCarInsurancePayment
} from './carInsuranceSelector';

const selectors = {
    dateSelector,
    stepSelector,
    accountsIdSelector,
    accountByIdSelector,
    singleAccountIdSelector,
    allBankConnectionSelector,
    accountByIdBalanceSelector,
    activeRecurringpaymentSelector,
    allRecurringPaymentContractSelector,
    allRecurringPaymentMiscSelector,
    recurringPaymentCategoryJoinSelector,
    recurringPaymentByIdSelector,
    allRecurringPaymentWithoutInsurancesSelector,
    allContractWithoutInsurancesSelector,
    recurringPaymentWithoutAccountSelector,
    accByRecurringPaymentSelector,
    makeAllTransactionsFromAccountSelector,
    makeAllTransactionsFromRecurringpaymentSelector,
    allTransactionsFromAccountGroupedByDaySelector,
    allCategorySelector,
    singleTransactionByIdSelector,
    categoryTransactionsSelector,
    categoryByLevelSelector,
    categoryInsuranceSelector,
    categoryByIdSelector,
    categoryIsEnergySelector,
    categoryColorByIdSelector,
    categoryByNameSelector,
    categoryByMeterTypeSelector,
    getNotification,
    queueSelector,
    queueItemByIdSelector,
    getUser,
    getUserIsLoggedIn,
    getUserUserData,
    getAccounts,
    allAccountsSelector,
    accountsBankConnectionJoinSelector,
    allSecuritiesByAccountIdSelector,
    allVisibleAccountsSelector,
    allHiddenAccountsSelector,
    visibleSavingsAccountsSelector,
    visibleNormalAccountsSelector,
    hiddenSavingsAccountsSelector,
    hiddenNormalAccountsSelector,
    visibleAccountsGroupedByBankConnectionSelector,
    hiddenAccountsGroupedByBankConnectionSelector,
    bankSelector,
    allBanksSelector,
    bankBySearchStringSelector,
    dashboardChartDataSelector,
    productByIdSelector,
    policySelector,
    allInsuranceProductsSelector,
    companyByIdSelector,
    getAllCompanies,
    companyByNameSelector,
    allCompaniesByTypeSelector,
    initialCompaniesByTypeSelector,
    topLevelCategoryByChildIdSelector,
    initialCategoriesByTypeSelector,
    offerByIdSelector,
    carInsuranceDataSelector: getCarInsurance,
    getVehicleData: getVehicleData,
    carInsuranceRequestDataSelector,
    policyFormDataSelector,
    getPolicyData,
    getCarInsuranceOptions,
    getVehicleHolderData,
    getCarInsuranceProposal,
    carInsuranceProposalRequestDataSelector,
    carInsuranceSummarySelector,
    getCarInsurancePayment
};
registerSelectors(selectors);

export {
    dateSelector,
    stepSelector,
    accountsIdSelector,
    accountByIdSelector,
    singleAccountIdSelector,
    allBankConnectionSelector,
    accountByIdBalanceSelector,
    activeRecurringpaymentSelector,
    allSecuritiesByAccountIdSelector,
    dashboardChartDataSelector,
    allRecurringPaymentContractSelector,
    allRecurringPaymentMiscSelector,
    allContractWithoutInsurancesSelector,
    allRecurringPaymentWithoutInsurancesSelector,
    recurringPaymentCategoryJoinSelector,
    recurringPaymentByIdSelector,
    recurringPaymentByIdNameSelector,
    accByRecurringPaymentSelector,
    singleAccountSelector,
    singleTransactionByIdSelector,
    makeAllTransactionsFromAccountSelector,
    makeAllTransactionsFromRecurringpaymentSelector,
    allTransactionsFromAccountGroupedByDaySelector,
    allCategorySelector,
    categoryTransactionsSelector,
    categoryByLevelSelector,
    categoryInsuranceSelector,
    categoryByIdSelector,
    categoryByNameSelector,
    categoryColorByIdSelector,
    categoryIsEnergySelector,
    categoryByMeterTypeSelector,
    getNotification,
    queueSelector,
    queueItemByIdSelector,
    getUser,
    getUserIsLoggedIn,
    getUserUserData,
    getAccounts,
    allAccountsSelector,
    accountsBankConnectionJoinSelector,
    allVisibleAccountsSelector,
    allHiddenAccountsSelector,
    visibleSavingsAccountsSelector,
    visibleNormalAccountsSelector,
    hiddenSavingsAccountsSelector,
    hiddenNormalAccountsSelector,
    visibleAccountsGroupedByBankConnectionSelector,
    hiddenAccountsGroupedByBankConnectionSelector,
    bankSelector,
    allBanksSelector,
    bankBySearchStringSelector,
    productByIdSelector,
    policySelector,
    allInsuranceProductsSelector,
    companyByIdSelector,
    getAllCompanies,
    companyByNameSelector,
    allCompaniesByTypeSelector,
    initialCompaniesByTypeSelector,
    topLevelCategoryByChildIdSelector,
    offerByIdSelector,
    initialCategoriesByTypeSelector,
    getCarInsurance,
    getVehicleData,
    carInsuranceRequestDataSelector,
    policyFormDataSelector,
    getPolicyData,
    getCarInsurancePayment,
    getCarInsuranceOptions,
    getVehicleHolderData,
    getCarInsuranceProposal,
    carInsuranceProposalRequestDataSelector,
    carInsuranceSummarySelector
};

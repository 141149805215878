import {createDeepEqualSelector} from './utils';
import {CompanyType, ICompany} from '../../../models/companyInterface';

const invalidId = -1;

export const getAllCompanies = (state: any) => {
    return state?.companies ?? [];
};

export const companyByIdSelector = createDeepEqualSelector(
    [
        getAllCompanies,
        (state: any, props: {companyId: number | undefined}) =>
            props.companyId ?? invalidId
    ],
    (companies: ICompany[], id: number): ICompany | undefined => {
        if (!Array.isArray(companies)) {
            return undefined;
        }

        return companies.find((company: ICompany) => company.id === Number(id));
    }
);

export const allCompaniesByTypeSelector = createDeepEqualSelector(
    [
        getAllCompanies,
        (_: any, props: {companyType?: CompanyType}) => props.companyType
    ],
    (companies: ICompany[], companyType?: CompanyType) => {
        if (!Array.isArray(companies)) {
            return [];
        }
        if (typeof companyType === 'undefined') return companies;
        return companies
            .filter((company: ICompany) => company.companyType === companyType)
            .sort(
                (a: ICompany, b: ICompany) =>
                    a.name?.localeCompare(b.name ?? '') ?? -1
            );
    }
);

export const initialCompaniesByTypeSelector = createDeepEqualSelector(
    [allCompaniesByTypeSelector],
    (companies: ICompany[]) => {
        if (!Array.isArray(companies)) {
            return [];
        }
        return companies.filter((company: ICompany) => company.isInitial);
    }
);

export const companyByNameSelector = createDeepEqualSelector(
    [getAllCompanies, (_: any, props: {name?: string}) => props.name],
    (companies: ICompany[], name?: string): ICompany | undefined => {
        if (!Array.isArray(companies)) {
            return undefined;
        }

        return companies.find(
            (company: ICompany) =>
                name && company.name?.toUpperCase().includes(name.toUpperCase())
        );
    }
);

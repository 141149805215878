import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    createStyles,
    DialogActions,
    DialogContent,
    makeStyles,
    Theme
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {WgTypography} from '../../../styles/CustomComponents';
import {connect} from 'react-redux';
import {removeLoading} from '../../../store/actions/notificationActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        OKbutton: {
            color: theme.palette.secondary.main
        }
    })
);

interface WgLoadingGifsProps {
    loadingIdList: string[];
    removeLoading?: Function;
    content?: string | React.ReactElement;
    hasGif?: boolean;
    hasAgreeButton?: boolean;
}

function WgLoadingDialog({
    loadingIdList,
    removeLoading,
    content,
    hasGif = true,
    hasAgreeButton
}: WgLoadingGifsProps) {
    const classes = useStyles();
    return (
        <Dialog
            open
            disableBackdropClick
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogContent>
                {hasGif && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center">
                        <img
                            src="/img/gif/gif-waiting-mrbean.gif"
                            alt="loading..."
                            style={{borderRadius: '5px'}}
                        />
                    </Box>
                )}
                <Box marginTop={hasGif ? '1.5rem' : undefined}>
                    <WgTypography
                        gutterBottom
                        align="center"
                        color="textPrimary"
                        style={{fontWeight: 800}}>
                        Einen Moment bitte...
                    </WgTypography>
                    {typeof content === 'string' && (
                        <WgTypography
                            text="content"
                            align="center"
                            color="textSecondary">
                            {content}
                        </WgTypography>
                    )}
                </Box>
                {typeof content !== 'undefined' &&
                    typeof content !== 'string' &&
                    content}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    p="1rem 0">
                    <CircularProgress />
                </Box>
            </DialogContent>
            {hasAgreeButton && (
                <DialogActions>
                    <Button
                        className={classes.OKbutton}
                        onClick={() =>
                            loadingIdList.forEach(
                                id => removeLoading && removeLoading(id)
                            )
                        }
                        autoFocus>
                        OKAY
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {removeLoading})(WgLoadingDialog);

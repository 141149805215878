import React from 'react';
import {
    Box,
    BoxProps,
    createStyles,
    LinearProgress,
    Theme,
    withStyles
} from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        progressPoint: {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '2px',
            width: '5px',
            backgroundColor: theme.palette.common.white,
            marginTop: '-2px',
            marginLeft: '-2px'
        }
    });

export interface WgProgressStepperProps extends BoxProps {
    step: number;
    steps: React.ReactNodeArray;
    classes: any;
}

function WgProgressStepper({step, steps, classes}: WgProgressStepperProps) {
    return (
        <Box flex={1}>
            <LinearProgress
                variant="determinate"
                className="signup-progressbar-line"
                value={((step - 1) * 100) / (steps.length - 1)}
            />
            <div className="signup-progressbar-point-container">
                {getDashedLine(steps)}
            </div>
        </Box>
    );

    function getScaleOfPoint(steps: any, step: number) {
        return ((step - 1) * 100) / (steps.length - 1) + '%';
    }

    function getDashedLine(steps: any) {
        let dots = [];

        for (let i = 2; i < steps.length; i++) {
            dots.push(
                <div
                    key={i}
                    style={{left: getScaleOfPoint(steps, i)}}
                    className={classes.progressPoint}
                />
            );
        }
        return dots;
    }
}

export default withStyles(styles)(WgProgressStepper);

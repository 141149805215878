import React from 'react';
import {Box, Grid, IconButton} from '@material-ui/core';
import {IDashboard} from '../../../../../models/statistics/dashBoardInterface';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import {WgTypography} from '../../../../../styles/CustomComponents';

export interface DashboardTimeSwitchProps {
    handleMonthChange: Function;
    dashboardData: IDashboard[];
    activeIndex: number;
}

function DashboardTimeSwitch({
    dashboardData,
    activeIndex,
    handleMonthChange
}: DashboardTimeSwitchProps) {
    return (
        <Grid container direction="row" justify="center">
            <IconButton
                disabled={activeIndex === 0}
                onClick={() => handleMonthChange(-1)}>
                <ChevronLeft />
            </IconButton>
            <Box
                minWidth="128px"
                display="flex"
                justifyContent="center"
                alignItems="center">
                <WgTypography color="textPrimary" header="subTitle">
                    {dashboardData[activeIndex].currentDate.toLocaleString(
                        'DE-de',
                        {
                            month: 'long'
                        }
                    )}
                </WgTypography>
            </Box>
            <IconButton
                disabled={activeIndex === dashboardData.length - 1}
                onClick={() => handleMonthChange(1)}>
                <ChevronRight />
            </IconButton>
        </Grid>
    );
}

export default DashboardTimeSwitch;

import React from 'react';
import {createStyles, Grid, withStyles} from '@material-ui/core';
import {WgButtonBoxProps} from '../WgButtonBox';

const styles = () =>
    createStyles({
        root: {
            maxHeight: '100%'
        }
    });

const WgListItemAction = ({classes, children}: WgButtonBoxProps) => {
    return (
        <Grid
            key="list-item-action"
            className={classes['root']}
            item
            wrap="nowrap"
            xs
            container>
            {children}
        </Grid>
    );
};

export default withStyles(styles)(WgListItemAction);

export function validateEmail(email: string) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isDigitsOnlySameLength(value: string, numberLength: number) {
    const isCorrect = /^\d+$/.test(value) && value.length === numberLength;

    return isCorrect;
}
export function isDigitLetterOnlySameLength(value: string, length: number) {
    const isCorrect = /^[\d\w]+$/.test(value) && value.length === length;

    return isCorrect;
}
export function isDigitsOnlySameLessLength(
    value: string,
    numberLength: number
) {
    const isCorrect = /^\d+$/.test(value) && value.length <= numberLength;

    return isCorrect;
}

export function isStringLengthLessThanOrEqual(value: string, length: number) {
    const isCorrect = value.length <= length;

    return isCorrect;
}

export function isInputNotFilled(value: any) {
    const isCorrect = typeof value === 'undefined' || value === '';
    return isCorrect;
}

export function isPattern(value: string, pattern: RegExp) {
    const isCorrect = pattern.test(value);

    return isCorrect;
}

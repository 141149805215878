import React, {Component} from 'react';
import {Grid, IconButton} from '@material-ui/core';
import {ReactSVG} from 'react-svg';
import {
    SignupStep1,
    SignupStep10,
    SignupStep12,
    SignupStep2,
    SignupStep3,
    SignupStep4,
    SignupStep5,
    SignupStep7,
    SignupStep8,
    SignupStep9
} from './steps';
import {connect} from 'react-redux';
import {IStateSignup} from './IStateSignup';

import './Signup.scss';
import {push} from 'connected-react-router';
import {getUserUserData} from '../../../store/reducers/selectors';
import {getCookie, setCookie} from '../../utils/Cookie';
import {encryptor} from '../../../rootReducer';
import {WgContainer, WgProgressStepper} from '../../../styles/CustomComponents';
import {displayErrorNotification} from '../../../store/actions/notificationActions';
import {
    addUserData,
    registerUser,
    sendAgain,
    verifyUser
} from '../../../store/actions/userActions';

// @ts-ignore
export default ({match}) => <ConnectSignup step={Number(match.params.step)} />;

interface IProps {
    step: number;
    sendAgain: Function;
    registerUser: Function;
    displayErrorNotification: Function;
    addUserData: Function;
    verifyUser: Function;
    userData: IStateSignup;
}

class Signup extends Component<IProps, IStateSignup> {
    constructor(props: IProps) {
        super(props);
        const cookie = getCookie('userData');
        const state = cookie
            ? {...encryptor.out(cookie), ...props.userData}
            : props.userData;
        this.state = {...state, codeTries: 0};
    }

    render(): React.ReactNode {
        let steps = [
            <SignupStep1
                text="Hallo, schön dich kennenzulernen, ich bin WechselGott. Ich helfe dir bei der Erstellung deines Kontos."
                buttonText="Okay, los geht's"
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep2
                text="Wie lautet dein Vor- und Nachname?"
                requirements={['firstName', 'lastName']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep3
                text={'Wann wurdest du geboren, ' + this.state.firstName + '?'}
                requirements={['birthDate']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep4
                text={
                    'Ich weiß, es ist ' +
                    new Date().getFullYear() +
                    ', aber wir müssen dich fragen. Was ist dein Geschlecht, ' +
                    this.state.firstName +
                    '?'
                }
                requirements={['gender']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep5
                text="Nun benötige ich noch deine Adresse."
                requirements={[
                    'street',
                    'houseNumber',
                    'postcode',
                    'city',
                    'country'
                ]}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep7
                text="Unter welcher E-Mail Adresse erreiche ich dich am Besten?"
                requirements={['email']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep8
                text={
                    <span>
                        Wähle ein Passwort mit min. acht Zeichen - davon min.
                        ein Kleinbuchstabe und eine Ziffer. <br />
                        Unsere Empfehlung: Min. elf Zeichen, Groß- und
                        Kleinschreibung und Sonderzeichen.
                    </span>
                }
                requirements={['password']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep12
                text="Bitte akzeptiere noch die AGB, bevor wir dein WechselGott Konto eröffnen."
                requirements={['agb', 'dsgvo']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep9
                text={
                    <span>
                        Bitte gib deine Handynummer ein, damit wir dir per SMS
                        einen 6-stelligen Verifizierungscode zusenden können.
                    </span>
                }
                requirements={['phone']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />,
            <SignupStep10
                text={
                    <span>
                        Ich habe dir zur Verifizierung eine SMS an{' '}
                        <strong>{this.state.phone}</strong> geschickt. Sollte
                        der Code nicht bei dir angekommen sein, kann ich dir
                        gerne einen{' '}
                        <span
                            style={{
                                color: '#1dbab1',
                                textDecoration: 'underline'
                            }}
                            onClick={this.sendAgain.bind(this)}>
                            neuen Code zusenden
                        </span>
                        .
                    </span>
                }
                requirements={['code']}
                state={this.state}
                step={this.props.step}
                setState={this.change.bind(this)}
            />
        ];

        return (
            <WgContainer
                publicBodyApp
                backgroundImage
                style={{height: '100%', maxHeight: '100vh'}}>
                <Grid id="header" container item justify="space-between">
                    <Grid item xs={2} container justify="center">
                        <IconButton
                            color="inherit"
                            onClick={() => window.history.back()}>
                            <ReactSVG src="/img/navigation/back.svg" />
                        </IconButton>
                    </Grid>
                    <Grid item xs container alignItems="center">
                        <WgProgressStepper
                            display="flex"
                            m="0 5rem 0 2rem"
                            step={this.props.step}
                            steps={steps}
                        />
                    </Grid>
                    <Grid item xs={2} />
                </Grid>
                <form
                    onSubmit={this.handleSubmit.bind(this)}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        overflow: 'auto',
                        width: '100%'
                    }}>
                    {this.getStep(steps)}
                </form>
            </WgContainer>
        );
    }

    handleSubmit(event: any) {
        event.preventDefault();

        if (Number(this.props.step) === 10) {
            this.props.verifyUser(this.state.code);
            return;
        }
        if (Number(this.props.step) === 9) {
            this.props.registerUser(this.state);
        }

        // @ts-ignore
        this.props.push('/signup/' + (Number(this.props.step) + 1));
    }

    getStep(steps: any) {
        try {
            return steps[this.props.step - 1];
        } catch (e) {
            return <React.Fragment />;
        }
    }

    change(params: any, callback?: () => void) {
        this.setState(params, callback);
        setCookie(
            'userData',
            encryptor.in(
                JSON.stringify({...this.state, ...params, password: undefined})
            ),
            30
        );
        this.props.addUserData(params);
    }

    private sendAgain(event: any) {
        event.preventDefault();

        this.setState(p => ({codeTries: p.codeTries + 1}));
        if (this.state.codeTries >= 2) {
            this.setState({codeTries: 0});
            this.props.displayErrorNotification(
                'Die Zusendung des Codes funktioniert nicht? Überprüfe die Korrektheit deiner Telefonnummer oder versuche es mit einer anderen.'
            );
        }

        this.props.sendAgain();
    }
}

function mapStateToProps(state: any) {
    return {userData: getUserUserData(state)};
}

const ConnectSignup = connect(mapStateToProps, {
    addUserData,
    sendAgain,
    push,
    displayErrorNotification,
    verifyUser,
    registerUser
})(Signup);

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MaskedInputCode from './MaskedInputCode';
import {Paper, TextField} from '@material-ui/core';

interface IProps {
    name?: string;
    autoFocus?: boolean;
    onChange?: Function;
    defaultValue?: number | string;
}

export default function VerificationCodeField(props: IProps) {
    return (
        <Paper elevation={2} style={{width: '100%', padding: '0 1.125rem'}}>
            <FormControl>
                <TextField
                    defaultValue={props.defaultValue}
                    label="Code"
                    autoFocus={props.autoFocus}
                    name={props.name}
                    variant="filled"
                    onChange={onChange}
                    id="code-input"
                    InputProps={{
                        inputComponent: MaskedInputCode as any,
                        autoComplete: 'one-time-code'
                    }}
                />
            </FormControl>
        </Paper>
    );

    function onChange(event: any) {
        props.onChange && props.onChange(event);
    }
}

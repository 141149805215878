import React from 'react';
import ObjectSearch from '../views/insurance/views/AddExisting/ObjectSearch';
import {
    allCompaniesByTypeSelector,
    initialCompaniesByTypeSelector
} from '../../store/reducers/selectors';
import {useSelector} from 'react-redux';
import {CompanyType, ICompany} from '../../models/companyInterface';

export interface CompanySelectionProps {
    notFoundComponent?: React.ReactNode;
    companies?: ICompany[];
    searchType?: CompanyType;
    handleChange?: (
        name: 'categoryId' | 'companyId' | any
    ) => (value: any) => void;
    setNotFound?: Function;
    setInputValid?: Function;
}

export default function CompanySelection({
    searchType,
    handleChange,
    notFoundComponent,
    setNotFound,
    setInputValid
}: CompanySelectionProps) {
    const isEnergy = searchType === CompanyType.ENERGY;
    const {companies, initialCompanies} = useSelector(reduxState => ({
        companies: allCompaniesByTypeSelector(reduxState, {
            companyType: searchType ?? CompanyType.MISC
        }),
        initialCompanies: initialCompaniesByTypeSelector(reduxState, {
            companyType: searchType
        })
    }));
    return (
        <ObjectSearch
            handleChange={handleChange}
            backText={'Meine Verträge'}
            headerText={
                isEnergy ? 'Versorger wählen' : 'Zahlungsempfänger wählen'
            }
            objects={companies}
            initialObjects={initialCompanies}
            initialText={
                isEnergy
                    ? 'Bei welchem Versorger hast du deinen Vertrag?'
                    : 'Wer ist Zahlungsempfänger oder -sender?'
            }
            notFoundText={
                isEnergy
                    ? 'Keinen Versorger gefunden...'
                    : 'Keinen Empfänger/Sender gefunden...'
            }
            setNotFound={setNotFound}
            notFoundComponent={notFoundComponent}
            searchBarPlaceholder={
                isEnergy
                    ? 'Energieversorger suchen...'
                    : 'Zahlungsempfänger/-sender suchen...'
            }
            setInputValid={setInputValid}
        />
    );
}

import {Box, ButtonBase, useTheme} from '@material-ui/core';
import React from 'react';
import {WgTypography} from './index';

export interface WgSelectorButtonProps {
    value: any;
    handleChange: Function;
    text: string;
    active: boolean;
}

export default function WgSelectorButton({
    value,
    text,
    active,
    handleChange
}: WgSelectorButtonProps) {
    const theme = useTheme();
    return (
        <Box
            clone
            style={{
                backgroundColor: active
                    ? theme.palette.primary.main
                    : theme.palette.action.disabledBackground
            }}
            p="0.5rem 1rem"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="5px"
            height="64px"
            width="100%"
            onClick={() => handleChange(value)}>
            <ButtonBase>
                <WgTypography
                    align="center"
                    style={{
                        color: active
                            ? theme.palette.highlight.main
                            : theme.palette.text.secondary,
                        whiteSpace: 'pre-line'
                    }}
                    text="content">
                    {text}
                </WgTypography>
            </ButtonBase>
        </Box>
    );
}

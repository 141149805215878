import {WgRadioGroup} from '../../../../../styles/CustomComponents';
import React from 'react';

export interface GenderRadioGroupProps {
    handleChange?: (event: React.ChangeEvent<any>) => void;
    gender?: string;
    disabled?: boolean;
    salutation?: boolean;
    noDivers?: boolean;
    helperText?: React.ReactNode;
    error?: boolean;
}

export function GenderRadioGroup(props: GenderRadioGroupProps) {
    let radioGroup;

    if (props.salutation) {
        radioGroup = [
            {
                label: 'Frau',
                value: 'female'
            },
            {
                label: 'Herr',
                value: 'male'
            }
        ];

        if (props.noDivers === false || props.noDivers === undefined) {
            radioGroup.push({label: 'Divers', value: 'divers'});
        }
    } else {
        radioGroup = [
            {
                label: 'weiblich',
                value: 'female'
            },
            {
                label: 'männlich',
                value: 'male'
            }
        ];

        if (props.noDivers === false || props.noDivers === undefined) {
            radioGroup.push({
                label: 'divers',
                value: 'divers'
            });
        }
    }

    return (
        <WgRadioGroup
            paddingX="0.5rem"
            row
            disabled={props.disabled}
            handleChange={props.handleChange}
            name="gender"
            selected={props.gender?.toLowerCase() ?? ''}
            group={radioGroup}
            helperText={props.helperText}
        />
    );
}

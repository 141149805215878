import MockAdapter from 'axios-mock-adapter';
import {auth} from './api';
import {store} from '../../store';
import {getBrokermandate} from '../reducers/selectors/userSelector';

export const mock = new MockAdapter(auth, {delayResponse: 2000});

mock.onPost('/authentication').reply(200, 'token123');
mock.onGet('/authentication').reply(200, {
    id: 'b04f4b54-c593-4772-a1b7-70428b0f87a9'
});
mock.onDelete('/authentication').reply(200, '');

mock.onPost('/users').reply(200, {id: 'b04f4b54-c593-4772-a1b7-70428b0f87a9'});
mock.onGet(/\/users\/\S+\/requiredinformations/).reply(200, ['']);
mock.onPut(/\/users\/\S+\/verify/).reply(200, '');
mock.onDelete(/\/users\/\S+\/verify/).reply(200, '');
mock.onPut(/\/users\/\S+\/brokermandate/).reply(200, '');
mock.onGet(/\/users\/\S+\/brokermandate/).reply(
    200,
    getBrokermandate(store.getState())
);
mock.onDelete(/\/users\/by-email\/\S+\/password/).reply(200, '');
mock.onPut(/\/users\/by-email\/\S+\/password/).reply(200, '');
mock.onPut(/\/users\/\S+\/password/).reply(200, '');
mock.onGet(/\/users\/\S+/).reply(200, {
    email: 'Max.Mustermann@gmail.com',
    title: 'Dr.',
    firstName: 'Max',
    lastName: 'Mustermann',
    password: Math.random().toString(),
    postcode: '90533',
    city: 'Gotham City',
    street: 'Musterstraße',
    houseNumber: '42',
    addressAdditional: 'a',
    phone: '+49157342133742',
    newsletter: false,
    dsgvo: false,
    agb: false,
    gender: 'divers',
    birthDate: '2000-01-01T00:00:00',
    country: 'Deutschland'
});
mock.onPut(/\/users\/\w+\/financialanalysis/).reply(200, '');
mock.onPut(/\/users\/\S+/).reply(200, '');
mock.onPut(/\/\S+\/password/).reply(200, '');

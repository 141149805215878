import {ICompany} from './companyInterface';
import {IAccount} from './accountInterface';

export enum CategoryColor {
    FINANCE = '#4677FF',
    LEISURE = '#F859DF',
    HEALTH = '#E15B5B',
    HOME = '#F5D222',
    LIFE = '#FFA05D',
    MOBILITY = '#80E150',
    MISC = '#818181'
}

export function getCategoryColor(name?: string): CategoryColor {
    switch (name) {
        case 'Finanzen & Vorsorge':
            return CategoryColor.FINANCE;
        case 'Freizeit':
            return CategoryColor.LEISURE;
        case 'Gesundheit & Pflege':
            return CategoryColor.HEALTH;
        case 'Haus & Garten':
            return CategoryColor.HOME;
        case 'Lebenshaltung':
            return CategoryColor.LIFE;
        case 'Mobilität & Reisen':
            return CategoryColor.MOBILITY;
        case 'Sonstige Transaktionen':
            return CategoryColor.FINANCE;
        default:
            return CategoryColor.MISC;
    }
}

export interface ICategory {
    id: number;
    parentId?: number;
    parentName?: string;
    name: string;
    isCustom?: boolean;
    children?: ICategory;
    isInsurance?: boolean;
    isInitial?: boolean;
    level: number;
    logoPath?: string;
}

export function isCategory(
    object:
        | ICategory
        | ICompany
        | IAccount
        | ICategory[]
        | ICompany[]
        | IAccount[]
): object is ICategory | ICategory[] {
    const obj: ICategory =
        object instanceof Array
            ? (object[0] as ICategory)
            : (object as ICategory);
    return (
        typeof obj?.level !== 'undefined' ||
        typeof obj?.parentName !== 'undefined' ||
        typeof obj?.parentId !== 'undefined' ||
        typeof obj?.children !== 'undefined' ||
        typeof obj?.isCustom !== 'undefined' ||
        typeof obj?.isInsurance !== 'undefined'
    );
}

import React from 'react';
import {Box, BoxProps, Divider, Hidden} from '@material-ui/core';
import {formatDateWithTime} from './Format';
import {WgTypography} from '../../styles/CustomComponents';

export interface AmountFormProps extends BoxProps {
    amount: number;
    secondAmount?: number;
    text: string;
    secondText?: string;
    isColored?: boolean;
    isRecurringPayment?: boolean;
    date?: string;
}

export default function AmountForm({
    amount,
    secondAmount,
    text,
    secondText,
    isColored,
    isRecurringPayment,
    date,
    ...other
}: AmountFormProps) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            minHeight="fit-content"
            {...other}>
            <Box
                display="flex"
                flexDirection={{xs: 'column', md: 'row'}}
                justifyContent={
                    secondAmount && secondText ? 'space-between' : 'center'
                }
                alignItems="center"
                width="90%">
                {mapAmountAndText(amount, text)}
                {secondAmount && secondText && (
                    <React.Fragment>
                        <Hidden smDown>
                            <Divider
                                light
                                orientation="vertical"
                                style={{height: '10vh'}}
                            />
                        </Hidden>
                        <Hidden mdUp>
                            <Divider light style={{width: '60%'}} />
                        </Hidden>
                        {mapAmountAndText(secondAmount, secondText)}
                    </React.Fragment>
                )}
            </Box>
            {date && (
                <WgTypography
                    gutterBottom
                    color="textSecondary"
                    header="uppercaseSpacing"
                    align="center">
                    {formatDateWithTime(date)}
                </WgTypography>
            )}
        </Box>
    );

    function mapAmountAndText(mappedAmount: number, mappedText: string) {
        return (
            <Box display="flex" flexDirection="column" minWidth="fit-content">
                <WgTypography
                    noWrap
                    align="center"
                    colored={isColored}
                    money
                    number="formHeader">
                    {mappedAmount}
                </WgTypography>
                <WgTypography
                    align="center"
                    gutterBottom
                    color="textSecondary"
                    header="uppercaseSpacing">
                    {mappedText}
                </WgTypography>
            </Box>
        );
    }
}

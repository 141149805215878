import {LoadingPriorityCodes} from '../reducers/notification';
import {addLoadingDialog} from './notificationActions';

export const notificationsArray = [
    {
        recurringPaymentId: 34324123451234508,
        id: 78,
        notificationType: 'EnergyChangeAvailable'
    }
];

export const notificationsMessages = [
    {
        notificationId: 78,
        text: 'ein Vertrag von dir ist zu teuer.',
        link: '/recurringpayments/details/34324123451234508?categoryId=4350',
        categoryId: 4350,
        linkText: 'Strom',
        logoId: 514,
        defaultImage: '/img/icon-recurringpayment-circle.svg',
        estimatedSavings: 300,
        userName: 'Max',
        subtext: 'Du kannst Geld sparen:'
    }
];
export const dialogTestData = {
    title: 'Konto löschen',
    text: 'Wenn du dein Konto löschen möchtest',
    isAgreeable: true,
    open: true
};
export const snackbarTestData = {
    text:
        'Unser Server ist zurzeit nicht erreichbar. Bitte versuche es später noch einmal.',
    open: true,
    variant: 'success'
};

export const loadingTestData = {
    id: 'TEST',
    priority: LoadingPriorityCodes.GIFLOADING_PROGRESS,
    component: addLoadingDialog,
    props: {
        recurringpaymentId: 123,
        loadingIdList: ['TEST']
    }
};
export const loadingTestData2 = {
    id: 'AS-DF',
    priority: LoadingPriorityCodes.GIFLOADING_PROGRESS,
    component: addLoadingDialog,
    props: {
        recurringpaymentId: 123,
        loadingIdList: ['TEST']
    }
};

import React from 'react';
import {Box, Chip, createStyles, makeStyles, Theme} from '@material-ui/core';
import Logo from '../../../utils/Logo';
import {IBank} from '../../../../models/bankInterface';
import {
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from '../../../../styles/CustomComponents';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.main
        }
    })
);

export interface BankSearchListProps {
    onClick: (...args: [React.MouseEvent<HTMLElement>]) => void;
    bank: IBank;
}

export function BankListItem({bank, onClick}: BankSearchListProps) {
    const classes = useStyles();
    return (
        <WgListItem
            square
            shadow={false}
            style={{width: '100%'}}
            onClick={onClick}>
            <WgListItemIcon>
                <Logo logoPath={bank.logoPath} defaultImage={'/img/bank.svg'} />
            </WgListItemIcon>
            <WgListItemContent style={{maxWidth: '100%'}}>
                <Box>
                    <WgTypography color="textPrimary" text="content">
                        {bank.name}
                    </WgTypography>
                    <WgTypography color="textSecondary" text="subContent">
                        {bank.blz}
                    </WgTypography>
                    <WgTypography color="textSecondary" text="subContent">
                        {bank.bic}
                    </WgTypography>
                    {!bank.available && (
                        <Chip
                            className={classes.chip}
                            label="Nicht unterstützt"
                        />
                    )}
                </Box>
            </WgListItemContent>
        </WgListItem>
    );
}

import React from 'react';
import {Box, Grid} from '@material-ui/core';
import WgSkeleton from '../WgSkeleton';

export default function DashboardTimeSwitchSkeleton() {
    return (
        <Grid container direction="row" justify="center">
            <WgSkeleton
                style={{margin: '12px'}}
                animation="wave"
                width="24px"
                height="24px"
            />
            <Box
                minWidth="128px"
                display="flex"
                justifyContent="center"
                alignItems="center">
                <WgSkeleton animation="wave" width="40%" height={10} />
            </Box>
            <WgSkeleton
                style={{margin: '12px'}}
                animation="wave"
                width="24px"
                height="24px"
            />
        </Grid>
    );
}

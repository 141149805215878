import React, {useEffect} from 'react';
import {Box, Grid, Paper} from '@material-ui/core';
import {
    WgEnumerationList,
    WgInputField,
    WgTypography
} from '../../../../../styles/CustomComponents';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

interface IMeterNumberInputProps {
    handleChange: (value: any) => void;
    meterNumber: string;
    setInputValid: Function;
    isOnlyMeterNumberSubmission?: boolean;
}

export default function MeterNumberInput({
    handleChange,
    meterNumber,
    isOnlyMeterNumberSubmission,
    setInputValid
}: IMeterNumberInputProps) {
    useEffect(() => {
        if (isOnlyMeterNumberSubmission) {
            setInputValid(meterNumber.length > 0);
        }
    }, [setInputValid, isOnlyMeterNumberSubmission, meterNumber]);

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="space-between">
            <WgTypography gutterBottom color="textSecondary" text="info">
                Für einen erfolgreichen Wechsel benötigen wir noch deine
                Zählernummer:
            </WgTypography>
            <WgEnumerationList
                background="default"
                dense={true}
                enumerationIcon={<ErrorOutlineOutlinedIcon />}
                headline="Wo finde ich meine Zählernummer?"
                boldHeadline
                content={[
                    'Direkt auf deinem Zähler oder auf einer deiner Rechnungen',
                    'Zählernummern haben keine feste Länge'
                ]}
            />
            <Paper elevation={1} style={{padding: '0 1rem'}}>
                <WgInputField
                    fullWidth
                    name="meterNumber"
                    type="text"
                    label="Zählernummer"
                    handleChange={(event: any) =>
                        handleChange(event.target.value)
                    }
                    defaultValue={meterNumber}
                />
            </Paper>
            <Box display="flex" marginY="1rem" justifyContent="center">
                <img
                    style={{objectFit: 'cover'}}
                    src="/img/recurringpayment/energy/meter-number-info.png"
                    alt="meternumber"
                />
            </Box>
        </Grid>
    );
}

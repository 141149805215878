import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {
    WgPaperArea,
    WgTypography
} from '../../../../../../styles/CustomComponents';
import {
    CarInsuranceOneProductType,
    IExistingInsuranceProduct,
    IPolicyData,
    IPolicyFormData
} from '../../../../../../models/productInterface';
import CarInsuranceExistingContractCompletion from './CarInsuranceExistingContractCompletion';
import {IRecurringpayment} from '../../../../../../models/recurringpaymentInterface';

interface CarInsuranceExistingContractProps {
    policyData: IPolicyData;
    formData: IPolicyFormData;
    setContract: Function;
    recurringPayment?: IRecurringpayment;
    contract: IExistingInsuranceProduct;
    updatePolicyData: Function;
    setIsValid: Function;
    validationEnabled: boolean;
    process: CarInsuranceOneProductType;
}

export default function CarInsuranceExistingContract({
    recurringPayment,
    policyData,
    contract,
    setContract,
    formData,
    updatePolicyData,
    setIsValid,
    validationEnabled,
    process
}: CarInsuranceExistingContractProps) {
    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Überprüfe die Daten deiner bestehenden Versicherung.
            </WgTypography>
            <Box marginTop="1rem">
                <WgPaperArea
                    header="Bestehender Vertrag"
                    elevation={1}
                    paperStyle={{padding: '1rem 1.25rem'}}>
                    <Box marginX="-1.25rem">
                        <CarInsuranceExistingContractCompletion
                            setContract={setContract}
                            updatePolicyData={updatePolicyData}
                            contract={contract}
                            recurringPayment={recurringPayment}
                            formData={formData}
                            policyData={policyData}
                            setIsValid={setIsValid}
                            process={process}
                            validationEnabled={validationEnabled}
                        />
                    </Box>
                </WgPaperArea>
            </Box>
        </Grid>
    );
}

import {ICompany} from '../../models/companyInterface';

export const companiesData: ICompany[] = [
    {id: 8712, name: 'Wechselgott GmbH', logoPath: '4060.svg', companyType: 0},
    {id: 8766, name: 'Telefonica', companyType: 0},
    {
        id: 8721,
        name: 'PayPal',
        logoPath: '108.svg',
        companyType: 0
    },
    {id: 8767, name: 'GEZ', companyType: 0},
    {
        id: 8764,
        name: 'Audible',
        isInitial: true,
        companyType: 0
    },
    {
        id: 8763,
        name: 'ARAG',
        isInitial: true,
        companyType: 1
    },
    {
        id: 8724,
        name: 'Amazon',
        isInitial: true,
        companyType: 2
    },
    {
        id: 8762,
        name: 'Alte Leipziger',
        isInitial: true,
        companyType: 1
    }
];

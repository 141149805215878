import React, {useState} from 'react';
import {
    Box,
    Button,
    Collapse,
    Container,
    Divider,
    Grid
} from '@material-ui/core';
import {IAccount} from '../../../models/accountInterface';
import {connect} from 'react-redux';
import {
    calculateAccountsBalance,
    formatDateFromString
} from '../../utils/Format';
import {WgTypography} from '../../../styles/CustomComponents';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {loadingIdAllAccounts} from '../../../store/actions/loadingIds';
import AccountListItemHeaderSkeleton from '../../../styles/CustomComponents/Skeletons/AccountListItemHeaderSkeleton';
import {
    allHiddenAccountsSelector,
    allVisibleAccountsSelector,
    hiddenAccountsGroupedByBankConnectionSelector,
    queueItemByIdSelector,
    visibleAccountsGroupedByBankConnectionSelector
} from '../../../store/reducers/selectors';
import BankConnectionAccountGroup from './components/BankConnectionAccountGroup';

export interface AccountListProps {
    visibleGroupedAccounts?: [number, IAccount[]][];
    hiddenGroupedAccounts?: [number, IAccount[]][];
    isCustomizable: boolean;
    loadingState?: boolean;
    hiddenAccounts: IAccount[];
    accounts: IAccount[];
}

function AccountList({
    visibleGroupedAccounts,
    hiddenGroupedAccounts,
    hiddenAccounts,
    accounts,
    isCustomizable,
    loadingState
}: AccountListProps) {
    const [open, setOpen] = useState(isCustomizable);

    return (
        <React.Fragment>
            {accounts?.length > 0 && renderNormalAccounts()}
            {hiddenAccounts?.length > 0 && (
                <React.Fragment>
                    <Container style={{marginTop: '2vh'}}>
                        <Button
                            fullWidth
                            style={{maxWidth: '100%'}}
                            onClick={() => setOpen(!open)}>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignContent="flex-end">
                                <WgTypography
                                    style={{alignSelf: 'center'}}
                                    gutterBottom
                                    header="uppercase">
                                    Ausgeblendete Konten & Depots
                                </WgTypography>
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </Grid>
                        </Button>
                    </Container>
                    <Collapse in={open} timeout={1000}>
                        {hiddenGroupedAccounts &&
                            hiddenGroupedAccounts.map(
                                ([id, values]: [number, IAccount[]]) =>
                                    renderBankConnectionGroup(id, values)
                            )}
                    </Collapse>
                </React.Fragment>
            )}
        </React.Fragment>
    );

    function renderNormalAccounts() {
        return (
            <Container disableGutters style={{marginTop: '1rem'}}>
                <Box
                    marginX="1rem"
                    display="flex"
                    justifyContent="space-between"
                    paddingBottom="4px">
                    {loadingState ? (
                        <AccountListItemHeaderSkeleton />
                    ) : (
                        <React.Fragment>
                            <WgTypography header="uppercase">
                                Alle Konten
                            </WgTypography>
                            <Box display="flex">
                                <WgTypography header="uppercase">
                                    {'Gesamtguthaben:'} &nbsp;
                                </WgTypography>
                                <WgTypography money header="uppercase">
                                    {calculateAccountsBalance(accounts)}
                                </WgTypography>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
                <Divider variant="middle" light />
                <Grid
                    container
                    direction="column"
                    style={{marginTop: '0.5rem'}}>
                    {visibleGroupedAccounts &&
                        visibleGroupedAccounts.map(
                            ([id, values]: [number, IAccount[]]) =>
                                renderBankConnectionGroup(id, values)
                        )}
                </Grid>
            </Container>
        );
    }

    function renderBankConnectionGroup(id: number, accounts: IAccount[]) {
        if (accounts.length === 0) return null;
        const bankConnection = accounts[0].bankConnection;
        return (
            <BankConnectionAccountGroup
                key={bankConnection?.bankConnectionId ?? '' + id}
                bankConnection={bankConnection}
                accountBalance={calculateAccountsBalance(accounts)}
                accounts={accounts}
                header={bankConnection?.displayName ?? ''}
                subTitle={
                    bankConnection?.lastUpdated
                        ? `Zuletzt aktualisiert am ${formatDateFromString(
                              bankConnection?.lastUpdated
                          )}`
                        : ''
                }
                isCustomizable={isCustomizable}
            />
        );
    }
}

function mapStateToProps(state: any) {
    return {
        loadingState: queueItemByIdSelector(state, {
            queueId: loadingIdAllAccounts
        }),
        visibleGroupedAccounts: visibleAccountsGroupedByBankConnectionSelector(
            state
        ),
        hiddenGroupedAccounts: hiddenAccountsGroupedByBankConnectionSelector(
            state
        ),
        accounts: allVisibleAccountsSelector(state),
        hiddenAccounts: allHiddenAccountsSelector(state)
    };
}

export default connect(mapStateToProps, {})(AccountList);

import React from 'react';
import {Box, BoxProps, Divider, Grid, Paper} from '@material-ui/core';
import {WgInputFieldBaseProps} from '../../styles/CustomComponents/WgInputField';
import {WgInputField} from '../../styles/CustomComponents';
import {inputNotFilled} from '../views/recurringpayments/change/Components';

export interface AddressInputFieldsProps extends BoxProps {
    elevation?: number;
    street?: string;
    houseNumber?: string;
    addressAdditional?: string;
    postcode: string;
    city: string;
    cityPattern?: RegExp;
    handleChange?: (event: any) => void;
    showCountry?: boolean;
    validation?: boolean;
    required?: boolean;
}

export default function AddressInputFields({
    street,
    houseNumber, // if street and houseNumber are set, those two fields are splittet
    addressAdditional,
    postcode,
    city,
    cityPattern,
    handleChange,
    elevation,
    showCountry,
    validation,
    required,
    ...other
}: AddressInputFieldsProps) {
    return (
        <Box {...other}>
            <Paper elevation={elevation ?? 1}>
                <Grid container>
                    {typeof street !== 'undefined' && (
                        <Grid container wrap="nowrap">
                            <Grid style={{width: '100%'}} item>
                                {renderDetail({
                                    label: 'Straße',
                                    type: 'text',
                                    defaultValue: street,
                                    name: 'street'
                                })}
                            </Grid>
                            <Box display="flex" alignItems="center">
                                <Divider
                                    orientation="vertical"
                                    light
                                    style={{height: '75%'}}
                                />
                            </Box>
                            <Grid style={{width: '100%'}} item xs={6}>
                                {renderDetail({
                                    label: 'Nr.',
                                    type: 'text',
                                    defaultValue: houseNumber,
                                    name: 'houseNumber'
                                })}
                            </Grid>
                        </Grid>
                    )}
                    <Divider light variant="middle" style={{flex: 1}} />
                    {typeof addressAdditional !== 'undefined' && (
                        <React.Fragment>
                            {renderDetail({
                                label: 'Adresszusatz (optional)',
                                type: 'text',
                                defaultValue: addressAdditional,
                                name: 'addressAdditional'
                            })}
                            <Divider light variant="middle" style={{flex: 1}} />
                        </React.Fragment>
                    )}
                    <Grid container wrap="nowrap">
                        <Grid style={{width: '100%'}} item xs={6}>
                            {renderDetail(
                                {
                                    label: 'PLZ',
                                    type: 'text',
                                    defaultValue: postcode,
                                    name: 'postcode'
                                },
                                /\d{0,5}/
                            )}
                        </Grid>
                        <Box display="flex" alignItems="center">
                            <Divider
                                orientation="vertical"
                                light
                                style={{height: '75%'}}
                            />
                        </Box>
                        <Grid style={{width: '100%'}} item>
                            {renderDetail(
                                {
                                    label: 'Wohnort',
                                    type: 'text',
                                    defaultValue: city,
                                    name: 'city'
                                },
                                cityPattern
                            )}
                        </Grid>
                    </Grid>
                    {showCountry && (
                        <React.Fragment>
                            <Divider variant="middle" light style={{flex: 1}} />
                            {renderDetail({
                                label: 'Land',
                                type: 'text',
                                defaultValue: 'Deutschland',
                                name: 'country'
                            })}
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </Box>
    );

    function areRequirementsMet(item: WgInputFieldBaseProps) {
        return (
            item.name === 'addressAdditional' ||
            !inputNotFilled(item.defaultValue)
        );
    }

    function renderDetail(item: WgInputFieldBaseProps, pattern?: RegExp) {
        return (
            <WgInputField
                shrinkLabel
                required={required && item.name !== 'addressAdditional'}
                error={validation && !areRequirementsMet(item)}
                helperText={
                    validation &&
                    !areRequirementsMet(item) &&
                    'Diese Eingabe ist erforderlich.'
                }
                key={item.name}
                {...item}
                pattern={pattern}
                handleChange={handleChange}
            />
        );
    }
}

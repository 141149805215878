import {createAction} from 'redux-actions';
import {api, getAuthConfig, performApiCall} from './api';
import {FETCH_COMPANIES_SUCCESS} from './actionTypes';

export const fetchCompaniesSuccess = createAction(FETCH_COMPANIES_SUCCESS);

export const fetchAllCompanies: any = () =>
    performApiCall(
        performFetchAllCompanies(),
        'Versicherungsfirmen konnten nicht geladen werden'
    );

export const performFetchAllCompanies: Function = () => async (
    dispatch: Function,
    getState: Function
) => {
    if (!getState()?.user?.token) {
        return;
    }
    const token = getState().user.token;
    const response = await api.get(
        `/companies`,
        getAuthConfig(token, {Accept: 'application/json'})
    );

    dispatch(fetchCompaniesSuccess(response.data));
};

import React, {useEffect, useState} from 'react';
import {Box, Grid, Paper} from '@material-ui/core';
import {connect, useDispatch} from 'react-redux';
import {setNotification} from '../../../../store/actions/notificationActions';
import {IBank} from '../../../../models/bankInterface';
import {
    allBanksSelector,
    bankSelector
} from '../../../../store/reducers/selectors';

import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../styles/CustomComponents';
import BankLogo from './BankLogo';
import {BankListItem} from './BankListItem';
import {SearchBar} from '../../../utils';
import {useParams} from 'react-router';
import useDebounceSearch, {
    setSearchBankParams
} from '../../../../hooks/useDebounceSearch';
import {
    fetchAllBanks,
    fetchFinApiDialogFromBank
} from '../../../../store/actions/bankActions';

interface IProps {
    allBanks: IBank[];
    initialBanks: IBank[];
    fetchAllBanks?: Function;
    fetchFinApiDialogFromBank?: Function;
}

export function AddBank({
    allBanks,
    initialBanks,
    fetchAllBanks,
    fetchFinApiDialogFromBank
}: IProps) {
    const {bankSearchString, id} = useParams();
    const [selectedBank, setSelectedBank] = useState<IBank | undefined>(
        undefined
    );
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const {
        debouncedSearchTerm,
        isSearching,
        results,
        setResults
    } = useDebounceSearch(searchTerm, 500);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (!allBanks || allBanks.length <= 0) {
            fetchAllBanks && fetchAllBanks();
        }
    }, [allBanks, fetchAllBanks]);

    useEffect(
        () => {
            setResults(
                setSearchBankParams(allBanks, bankSearchString, searchTerm)
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allBanks, bankSearchString, setResults]
    );

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={bankSearchString || 'Bankkonto hinzufügen'}
                hasBackButton
                backText="Meine Konten"
            />

            <WgContainer bodyContent>
                {renderSearchBar()}
                <Box marginTop="1rem" marginX={{xs: '1rem', md: 0}}>
                    {renderContent()}
                </Box>
            </WgContainer>
        </WgContainer>
    );

    function renderInitial() {
        return (
            <React.Fragment>
                <Box
                    display="flex"
                    marginTop="5vh"
                    flexDirection="column"
                    alignItems="center">
                    <WgTypography color="primary" text="contentInfo">
                        Wo hast du dein Konto?
                    </WgTypography>
                    <Grid
                        style={{marginTop: '1.5rem', maxWidth: '240px'}}
                        container
                        spacing={2}>
                        {renderInitialBanks()}
                    </Grid>
                </Box>

                <Box marginTop="3rem">
                    <WgTypography
                        color="textSecondary"
                        text="info"
                        style={{fontWeight: 'bold'}}>
                        Hinweis
                    </WgTypography>
                    <WgTypography color="textSecondary" text="info">
                        Aufgrund der laufenden technischen Umstellung der Banken
                        durch die PSD 2 Verordnung kann es in manchen Fällen zu
                        Problemen beim Verknüpfen von Bankkonten kommen.
                    </WgTypography>
                </Box>
            </React.Fragment>
        );
    }

    function renderBankList(results: IBank[]) {
        return (
            <Paper
                elevation={1}
                style={{
                    borderRadius: '5px',
                    overflow: 'hidden'
                }}>
                {renderBankItems(results)}
            </Paper>
        );
    }

    function renderBankItems(results: IBank[]) {
        return [...results]
            .splice(0, 60)
            .map((bank, index) => (
                <BankListItem
                    key={String(index)}
                    onClick={() => callFinAPI(bank.id, bank.available)}
                    bank={bank}
                />
            ));
    }

    function renderInitialBanks() {
        return initialBanks?.map((bank: IBank, index) => (
            <Grid item xs={4} container justify="center" key={index}>
                {typeof bank?.searchString !== 'undefined' ? (
                    <BankLogo
                        key={index}
                        hasSelectorLink
                        handleClick={setSelectedBank}
                        bank={bank}
                        callFinAPI={callFinAPI}
                    />
                ) : (
                    typeof bank?.id !== 'undefined' && (
                        <BankLogo
                            key={bank.id}
                            bank={bank}
                            callFinAPI={callFinAPI}
                        />
                    )
                )}
            </Grid>
        ));
    }

    function callFinAPI(id: number, isAvailable: boolean) {
        if (isAvailable) {
            // @ts-ignore
            fetchFinApiDialogFromBank(id);
        } else {
            setNotification &&
                dispatch(
                    setNotification({
                        title: 'Bank nicht unterstützt',
                        text:
                            'Schade! Deine Bank unterstützt noch nicht den PSD2 Standard.',
                        isAgreeable: true,
                        open: true
                    })
                );
        }
    }

    function renderSearchBar() {
        return (
            <Box paddingX="1rem">
                <SearchBar
                    open={open}
                    setOpen={setOpen}
                    searchString={searchTerm}
                    isLoading={isSearching}
                    placeholder="Name, BLZ oder BIC suchen"
                    handleChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                    ) => setSearchTerm(event.target.value)}
                />
            </Box>
        );
    }

    function renderBankSelector() {
        if (bankSearchString && typeof selectedBank !== 'undefined') {
            return (
                <Box
                    display="flex"
                    marginTop="4rem"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <BankLogo m="1.5rem 0" key={id} bank={selectedBank} />
                    <WgTypography
                        align="center"
                        color="textSecondary"
                        text="sentence">
                        Suche nach der {bankSearchString}
                        <br />
                        in deiner Ortschaft
                    </WgTypography>
                </Box>
            );
        } else {
            return null;
        }
    }

    function renderContent() {
        const errorMessage = (
            <Box
                marginX="1.5rem"
                marginTop="4rem"
                display="flex"
                justifyContent="center">
                <WgTypography color="textSecondary" text="sentence">
                    Keine Bank gefunden.
                </WgTypography>
            </Box>
        );

        if (open && searchTerm.length > 0 && results.length > 0) {
            return renderBankList(results);
        } else if (
            open &&
            searchTerm.length > 0 &&
            debouncedSearchTerm.length > 0 &&
            !isSearching
        ) {
            return errorMessage;
        } else if (bankSearchString) {
            return renderBankSelector();
        } else {
            return renderInitial();
        }
    }
}

export function mapStateToProps(state: any) {
    return {
        allBanks: allBanksSelector(state),
        initialBanks: bankSelector(state)?.initialBanks
    };
}

export default connect(mapStateToProps, {
    fetchAllBanks,
    fetchFinApiDialogFromBank
})(AddBank);

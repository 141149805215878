import {IRecurringpayment} from '../models/recurringpaymentInterface';

export const aboalarmLink =
    'https://www.aboalarm.de/widget/letter?address=436996&partner=384';

export const singleRecurringPayment: IRecurringpayment = {
    companyId: 8712,
    transactionIds: [6000, 6001, 6099],
    recurringPaymentId: 3427,
    currency: 'EUR',
    accountId: 1009,
    active: true,
    averageAmount: 187.95,
    estimatedNextAmount: 187.95,
    estimatedNextBookingDate: '2019-01-29T00:00:00',
    firstKnownBookingDate: '2019-07-12T00:00:00',
    lastKnownBookingDate: '2019-12-29T00:00:00',
    paymentInterval: 'MONTHLY',
    receiverName: 'Wechselgott GmbH',
    purpose: 'Restgehalt 2019',
    categoryId: 4133,
    isInsurance: false,
    categoryName: 'Lohn & Gehalt (Minijob)',
    isContract: false,
    cancellationStatus: 'Permanent',
    lastAmount: 194.6,
    changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
};
export const updatedRecurringPayment: IRecurringpayment = {
    isArchived: false,
    companyId: 8712,
    transactionIds: [6000, 6001, 6099],
    recurringPaymentId: 3427,
    currency: 'EUR',
    accountId: 1009,
    active: true,
    averageAmount: 187.95,
    estimatedNextAmount: 187.95,
    estimatedNextBookingDate: '2019-01-29T00:00:00',
    firstKnownBookingDate: '2019-07-12T00:00:00',
    lastKnownBookingDate: '2019-12-29T00:00:00',
    paymentInterval: 'ANNUAL',
    receiverName: 'Wechselgott GmbH',
    purpose: 'Restgehalt 2019',
    categoryId: 4350,
    isInsurance: false,
    categoryName: 'Lohn & Gehalt (Minijob)',
    isContract: false,
    cancellationStatus: 'Permanent',
    lastAmount: 194.6,
    changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
};
export const existingRps: IRecurringpayment[] = [
    {
        transactionIds: [65541647, 74741297],
        recurringPaymentId: 34324123451234507,
        currency: 'EUR',
        accountId: 1005,
        active: true,
        averageAmount: -19.0,
        estimatedNextAmount: -19.0,
        estimatedNextBookingDate: '2020-01-01T12:00:00',
        firstKnownBookingDate: '2019-08-05T00:00:00',
        lastKnownBookingDate: '2019-09-05T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'VATTENFALL EUROPE SALES',
        receiverIBAN: 'DE00000RECEIVER000IBAN',
        purpose: 'Strom Abschlag',
        categoryId: 4350,
        categoryName: 'Strom',
        isContract: true,
        isInsurance: false,
        cancellationStatus: 'Cancellable',
        lastAmount: -19.0,
        changeData: {
            changeState: 'available'
        }
    },
    {
        transactionIds: [65541647, 74741297],
        recurringPaymentId: 32,
        currency: 'EUR',
        accountId: 1005,
        active: true,
        averageAmount: -190.0,
        estimatedNextAmount: -190.0,
        estimatedNextBookingDate: '2019-10-04T00:00:00',
        firstKnownBookingDate: '2019-08-05T00:00:00',
        lastKnownBookingDate: '2019-09-05T00:00:00',
        paymentInterval: 'MONTHLY',
        receiverName: 'Alter Strom Anbieter',
        receiverIBAN: 'DE00000RECEIVER000IBAN',
        purpose: 'Strom Abschlag',
        categoryId: 4350,
        categoryName: 'Strom',
        isContract: true,
        isInsurance: false,
        cancellationStatus: 'Cancellable',
        lastAmount: -190.0,
        changeData: {
            changeState: 'success',
            startDate: '2020-05-29T00:00:00',
            providerName: 'Vattenfall',
            yearlyCosts: 395.39,
            estimatedYearlySavings: 50.98
        }
    }
];
export const newRecurringPaymentData: IRecurringpayment = {
    companyId: 8712,
    transactionIds: [6000, 6001, 6099],
    recurringPaymentId: 3427,
    currency: 'EUR',
    accountId: 1009,
    active: true,
    averageAmount: 187.95,
    estimatedNextAmount: 187.95,
    estimatedNextBookingDate: '2019-01-29T00:00:00',
    firstKnownBookingDate: '2019-07-12T00:00:00',
    lastKnownBookingDate: '2019-12-29T00:00:00',
    paymentInterval: 'MONTHLY',
    receiverName: 'Wechselgott GmbH',
    purpose: 'Restgehalt 2019',
    categoryId: 4133,
    isInsurance: false,
    categoryName: 'Lohn & Gehalt (Minijob)',
    isContract: false,
    cancellationStatus: 'Permanent',
    lastAmount: 194.6,
    changeData: {changeState: 'none', startDate: '0001-01-01T00:00:00'}
};

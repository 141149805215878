import React from 'react';
import {SignupStep} from './SignupStep';
import {AddressInputFields} from '../../../utils';

export default class SignupStep5 extends SignupStep {
    // @ts-ignore
    state = {
        ...this.state,
        isCountryValid: true
    };

    renderStep(): React.ReactNode {
        let {
            street,
            houseNumber,
            addressAdditional,
            city,
            postcode
        } = this.props.state;
        return (
            <AddressInputFields
                addressAdditional={addressAdditional ?? ''}
                city={city ?? ''}
                postcode={postcode ?? ''}
                street={street ?? ''}
                houseNumber={houseNumber ?? ''}
                showCountry
            />
        );
    }

    areRequirementsMet(props: any) {
        const isCountryValid = props.state.country === 'Deutschland';

        if (typeof props.isCountryValid !== 'undefined') {
            this.setState({
                // @ts-ignore
                isCountryValid
            });
        }

        // @ts-ignore
        return super.areRequirementsMet(props) && isCountryValid;
    }

    renderStepText(value = '') {
        if (!this.state.isCountryValid) {
            return super.renderStepText(
                'WechselGott ist in deinem Land noch nicht verfügbar.',
                true
            );
        } else {
            return super.renderStepText(value);
        }
    }
}

import React, {useEffect} from 'react';
import {
    Box,
    Card,
    CardActions,
    Grid,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import {setNotification} from '../../../../store/actions/notificationActions';
import {connect, useDispatch} from 'react-redux';
import {userActions} from '../../../../store/actions';
import {getUser} from '../../../../store/reducers/selectors';
import {getAuthConfig} from '../../../../store/actions/api';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../styles/CustomComponents';
import {ProfileListItem} from '../../../utils';
import {existsBrokermandate} from '../../../../store/reducers/selectors/userSelector';

const {fetchBrokermandate, updateTmpBrokermandate} = userActions;

interface BrokerMandateProps {
    displayErrorNotification: Function;
    fetchBrokermandate: Function;
    updateTmpBrokermandate: Function;
    token: string;
    userId: string;
    hasBrokermandate: boolean;
}

function BrokerMandate(props: BrokerMandateProps) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props.hasBrokermandate) {
            props.fetchBrokermandate && props.fetchBrokermandate();
        }
    });

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Maklermandat"
                hasBackButton
                backText="Dokumente"
            />

            <WgContainer bodyContent disableGutters={false}>
                {props.hasBrokermandate
                    ? renderMandateExists()
                    : renderOptions('Unterschrift erteilen')}
            </WgContainer>
        </WgContainer>
    );

    function renderMandateExists() {
        return (
            <Box>
                <WgTypography
                    gutterBottom
                    color="textSecondary"
                    header="uppercase">
                    Maklermandat
                </WgTypography>
                <Box maxWidth={{md: '420px'}}>
                    <Card>
                        <img
                            alt="Maklermandat"
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                            src="/img/brokermandate.png"
                        />
                        <CardActions disableSpacing>
                            <WgButton
                                fullWidth
                                variant="text"
                                onClick={clickDownload}>
                                <WgTypography color="primary" text="content">
                                    PDF herunterladen
                                </WgTypography>
                            </WgButton>
                        </CardActions>
                    </Card>
                </Box>
                <Box marginTop="3rem">
                    {renderOptions('Mandat erneut erteilen')}
                </Box>
            </Box>
        );
    }

    function renderOptions(text: string) {
        return (
            <React.Fragment>
                <WgTypography
                    gutterBottom
                    color="textSecondary"
                    header="uppercase">
                    Optionen
                </WgTypography>

                <Grid container spacing={isDesktop ? 2 : 0}>
                    <Grid item xs={12} md={6}>
                        <ProfileListItem
                            text={text}
                            icon="/img/profile/icon-pen.svg"
                            link="/profile/brokermandateedit"
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    async function clickDownload() {
        try {
            let {headers} = getAuthConfig(props.token, {
                Accept: 'application/pdf'
            });
            const response = await fetch(
                `/authapi/v1/users/${props.userId}/brokermandate.pdf`,
                {
                    credentials: 'include',
                    headers,
                    method: 'GET',
                    mode: 'cors'
                }
            );
            let blob = new Blob([await response.blob()], {
                type: 'application/pdf'
            });
            const fileName = 'Maklermandat.pdf';
            if (navigator.msSaveBlob) {
                // IE11 and Edge 17-
                navigator.msSaveBlob(blob, fileName);
            } else {
                // every other browser
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result) {
                        const a = document.createElement('a');
                        a.href = reader.result as string;
                        a.style.display = 'none';
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.parentNode && a.parentNode.removeChild(a);
                    }
                };
                reader.readAsDataURL(blob);
            }
        } catch (e) {
            console.error(e);
            dispatch(
                setNotification({
                    title: 'Es ist ein Fehler aufgetreten',
                    text:
                        'Das Maklermandat konnte nicht heruntergeladen werden.',
                    isAgreeable: true,
                    open: true
                })
            );
        }
    }
}

function mapStateToProps(state: any) {
    return {
        hasBrokermandate: existsBrokermandate(state),
        token: getUser(state)?.token,
        userId: getUser(state)?.id
    };
}

export default connect(mapStateToProps, {
    fetchBrokermandate,
    updateTmpBrokermandate
})(BrokerMandate);

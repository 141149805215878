import React from 'react';
import {ITransaction} from '../../../models/transactionInterface';
import {IAccount} from '../../../models/accountInterface';
import {AmountForm} from '../../utils';
import {
    WgContainer,
    WgHeader,
    WgPaperList
} from '../../../styles/CustomComponents';
import InsuranceListItem from '../insurance/components/InsuranceListItem';

export interface ITurnoverProps {
    accountId: number;
    turnoverID: number;
    turnover?: ITransaction;
    account?: IAccount;
    categoryName?: string;
}

export function Turnover(props: ITurnoverProps) {
    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={
                    props.turnover?.counterpartName
                        ? props.turnover?.counterpartName
                        : props.turnover?.purpose
                }
                hasBackButton
                backText={props.account && props.account.name}
            />

            <WgContainer bodyContent disableGutters>
                {props.turnover &&
                    props.account &&
                    renderDetails(props.turnover, props.account)}
            </WgContainer>
        </WgContainer>
    );

    function renderDetails(turnover: ITransaction, account: IAccount) {
        const isTakein: boolean = turnover.amount >= 0;
        const counterpartRole = isTakein ? 'Absender' : 'Empfänger';

        let keyValueMapping: any = {};
        keyValueMapping[counterpartRole] = turnover.counterpartName;
        keyValueMapping['Zweck'] = turnover.purpose;
        keyValueMapping['Kategorie'] = props.categoryName;
        keyValueMapping['Verknüpftes Konto'] = account && (
            <InsuranceListItem
                key={account?.id ?? 'Verknüpftes Konto'}
                hasIcon
                data={account}
                link={`/accounts/details/${account?.id ?? ''}`}
            />
        );

        return (
            <WgContainer disableGutters>
                <AmountForm
                    m="1.5rem 1.25rem 3rem 1.25rem"
                    amount={turnover.amount}
                    text="Abgeschlossene Transaktion"
                    isColored
                    date={turnover.valueDate}
                />
                <WgPaperList
                    shadow
                    link={''}
                    keyValueMapping={keyValueMapping}
                />
            </WgContainer>
        );
    }
}

import React, {useState} from 'react';
import {
    Box,
    Collapse,
    createStyles,
    Divider,
    Grid,
    Grow,
    ListItem,
    makeStyles,
    Paper,
    Theme,
    useMediaQuery
} from '@material-ui/core';
import {WgTypography} from './index';
import {formatPaymentInterval} from '../../containers/utils/Format';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            backgroundColor: theme.palette.text.hint,
            borderRadius: '16px',
            border: `1px solid ${theme.palette.text.disabled}`,
            minHeight: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                boxShadow: theme.shadows[2]
            }
        },
        closed: {
            '&:not(:focus):hover': {
                boxShadow: theme.shadows[0]
            }
        },
        listItemContainer: {
            '& div:last-child > li': {
                borderRadius: '0px 0px 16px 16px'
            }
        },
        listItem: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                '& > p': {
                    color: theme.palette.highlight.main
                }
            }
        }
    })
);

export interface WgSelectionProps {
    style: any;
    items: string[];
    selectedItem?: string;
    onChange: Function;
    disabled?: boolean;
}

export default function WgSelection({
    style,
    items,
    selectedItem,
    disabled,
    onChange
}: WgSelectionProps) {
    const classes = useStyles();
    const [selected, setSelected] = useState(selectedItem);
    const [open, setOpen] = useState(false);
    const isSmallPhone = useMediaQuery('(max-width: 320px)');

    return (
        <Paper
            elevation={0}
            style={style}
            onClick={() => (!disabled ? setOpen(!open) : {})}
            className={clsx(classes.root, {[classes['closed']]: !open})}>
            {renderSelected()}
            <Collapse in={open} timeout="auto">
                {renderContent()}
            </Collapse>
        </Paper>
    );

    function renderSelected() {
        return (
            <Box padding={isSmallPhone ? '0.25rem' : '0.5rem'}>
                <WgTypography
                    align="center"
                    header="uppercase"
                    color="textSecondary"
                    style={{
                        fontWeight: 600,
                        fontSize: isSmallPhone ? '0.5rem' : '0.625rem'
                    }}>
                    {(selected &&
                        formatPaymentInterval(selected).toUpperCase()) ??
                        'ZAHLUNGSINTERVALL'}
                </WgTypography>
            </Box>
        );
    }

    function renderContent() {
        return (
            <Grid
                container
                direction="column"
                className={classes.listItemContainer}>
                <Grow in={open} timeout={500}>
                    <Divider light style={{marginBottom: '2px'}} />
                </Grow>
                {items.map((value: string) => (
                    <Grid key={value} item style={{margin: '0 -8px'}}>
                        <ListItem
                            className={classes.listItem}
                            onClick={() => handleChange(value)}
                            style={{padding: '10px 8px'}}>
                            <WgTypography
                                header="uppercase"
                                color="textSecondary"
                                style={{
                                    fontWeight: 600,
                                    letterSpacing: '1.5px',
                                    fontSize: isSmallPhone
                                        ? '0.5rem'
                                        : '0.625rem'
                                }}>
                                {formatPaymentInterval(value).toUpperCase()}
                            </WgTypography>
                        </ListItem>
                    </Grid>
                ))}
            </Grid>
        );
    }

    function handleChange(value: string) {
        onChange(value);
        setSelected(value);
        setOpen(false);
    }
}

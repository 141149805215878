import React, {useEffect, useState} from 'react';
import {FormHelperText, Grid, Paper, TextField, Box} from '@material-ui/core';
import {
    calculateAge,
    dateString,
    isDateInFuture,
    isValidDateString,
    splitDate,
    isDateInPast
} from './Format';
import {parseISO} from 'date-fns';
import {WgTypography} from '../../styles/CustomComponents';

interface DateFormProps {
    value?: string | Date | number | null;
    onChange: (date?: Date) => void;
    onChangeStatus?: (status: DateFormStatus) => void;
    autoFocus?: boolean;
    dayLabel?: string;
    label?: string;
    error?: boolean;
    helperText?: React.ReactNode;
    required?: boolean;
    disabled?: boolean;
    background?: 'paper' | 'default';
    birthDate?: boolean;
    disableFuture?: boolean;
    disablePast?: boolean;
}

export interface DateFormStatus {
    text: string;
    code: number;
}
export interface DateFormState {
    year?: string | null;
    month?: string | null;
    day?: string | null;
}

export const DATE_STATUS_CODE = {
    EMPTY: 0,
    VALID_DATE: 1,
    INVALID_DATE: 2
};

function splitDateToObject(value: string | Date | number | undefined | null) {
    return {
        year: value ? splitDate(value)[0] : null,
        month: value ? splitDate(value)[1] : null,
        day: value ? splitDate(value)[2] : null
    };
}

export default function DateForm({
    value,
    onChange,
    onChangeStatus,
    autoFocus,
    dayLabel,
    label,
    error,
    helperText,
    required,
    disabled,
    background,
    birthDate,
    disableFuture,
    disablePast
}: DateFormProps) {
    const [status, setStatus] = useState<DateFormStatus>({
        code: DATE_STATUS_CODE.EMPTY,
        text: ''
    });
    const [state, setState] = useState(splitDateToObject(value));
    const focusInputs: any[] = [];

    useEffect(() => {
        if (!state.year || !state.month || !state.day) {
            setState(splitDateToObject(value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(
        () => {
            try {
                const status = updateState(
                    state,
                    setStatus,
                    onChangeStatus,
                    birthDate,
                    disableFuture,
                    disablePast
                );
                if (status.code === DATE_STATUS_CODE.VALID_DATE) {
                    onChange(parseISO(dateString(state)));
                } else {
                    onChange(undefined);
                }
            } catch (e) {
                console.error('Error in DateForm: ', e);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    return background === 'paper' ? renderDateFormOnPaper() : renderDateForm();

    function renderDateForm() {
        return (
            <Box onChange={handleChange} marginX={'1.25rem'} width={'100%'}>
                {label && (
                    <WgTypography
                        style={{marginBottom: '-0.5rem', marginTop: '0.5rem'}}
                        color={error ? 'error' : 'textSecondary'}
                        text="sentenceSmall">
                        {label + (required ? '*' : '')}
                    </WgTypography>
                )}
                <Grid container wrap="nowrap" justify="space-between">
                    <Grid item xs={3}>
                        <TextField
                            autoFocus={autoFocus}
                            label={dayLabel ?? 'Tag'}
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder={'TT'}
                            autoComplete={'bday-day'}
                            name={'day'}
                            type="number"
                            inputProps={{
                                min: 1,
                                max: 31
                            }}
                            value={state.day}
                            inputRef={input => (focusInputs[0] = input)}
                            onChange={event =>
                                event.target.value.length >= 2 &&
                                focusInputs[1].focus()
                            }
                            error={error}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Monat"
                            type="number"
                            inputProps={{
                                min: 1,
                                max: 12
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder={'MM'}
                            autoComplete={'bday-month'}
                            name={'month'}
                            value={state.month}
                            inputRef={input => (focusInputs[1] = input)}
                            onChange={event =>
                                event.target.value.length >= 2 &&
                                focusInputs[2].focus()
                            }
                            error={error}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Jahr"
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder={'JJJJ'}
                            autoComplete={'bday-year'}
                            name={'year'}
                            inputProps={{
                                min: 1900,
                                max: new Date().getFullYear()
                            }}
                            type="number"
                            value={state.year}
                            inputRef={input => (focusInputs[2] = input)}
                            error={error}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
                {typeof onChangeStatus === 'undefined' &&
                status.code === DATE_STATUS_CODE.INVALID_DATE &&
                status.text.length > 0
                    ? renderHelperText(status.text)
                    : helperText
                    ? renderHelperText(helperText)
                    : ''}
            </Box>
        );
    }

    function renderHelperText(text: React.ReactNode) {
        return (
            <FormHelperText id="component-error-text">{text}</FormHelperText>
        );
    }

    function renderDateFormOnPaper() {
        return (
            <Paper style={{padding: '0 1.125rem'}}>{renderDateForm()}</Paper>
        );
    }

    function handleChange(event: any) {
        const {value, name} = event.target;

        if (
            (name === 'day' && value <= 31 && value >= 0) ||
            (name === 'month' && value <= 12 && value >= 0) ||
            (name === 'year' && value >= 0)
        ) {
            setState(prevState => ({...prevState, [name]: value}));
        }
    }
}

/**
 * Returns true if at least one of @param state's fields is empty
 * The year counts as empty if there are less than 4 digits
 */
function isAnyDateFormStateEmpty(state: DateFormState): boolean {
    const dayIsEmpty = state.day ? state.day.length === 0 : true;
    const monthIsEmpty = state.month ? state.month.length === 0 : true;
    const yearIsEmpty = state.year ? state.year.length < 4 : true;

    return dayIsEmpty || monthIsEmpty || yearIsEmpty;
}

function updateState(
    state: DateFormState,
    setStatus: Function,
    onChangeStatus?: Function,
    birthDate?: boolean,
    disableFuture?: boolean,
    disablePast?: boolean
) {
    if (birthDate)
        return updateStateBirthDate(state, setStatus, onChangeStatus);
    else
        return updateStateNormal(
            state,
            setStatus,
            onChangeStatus,
            disableFuture,
            disablePast
        );
}

function updateStateBirthDate(
    state: DateFormState,
    setStatus: Function,
    onChangeStatus?: Function
) {
    let status;

    if (isAnyDateFormStateEmpty(state)) {
        status = {
            code: DATE_STATUS_CODE.EMPTY
        };
    } else if (!isValidDateString(dateString(state))) {
        status = {
            text: 'Bitte gib ein gültiges Geburtsdatum ein.',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else if (isDateInFuture(new Date(dateString(state)))) {
        status = {
            text: 'Das Datum darf nicht in der Zukunft liegen.',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else if (
        !state.day?.length ||
        !state.month?.length ||
        !state?.year ||
        state.year.length < 4
    ) {
        status = {
            text: '',
            code: DATE_STATUS_CODE.EMPTY
        };
    } else if (calculateAge(new Date(dateString(state))) < 18) {
        status = {
            text:
                'Du musst mindestens 18 Jahre alt sein, um dich bei WechselGott registrieren zu können.',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else if (Number(state.year) <= 1900) {
        status = {
            text: 'Das ist ein sehr hohes Alter. Bist du dir sicher?',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else {
        status = {
            text: '',
            code: DATE_STATUS_CODE.VALID_DATE
        };
    }

    setStatus(status);
    onChangeStatus && onChangeStatus(status);

    return status;
}

function updateStateNormal(
    state: DateFormState,
    setStatus: Function,
    onChangeStatus?: Function,
    disableFuture?: boolean,
    disablePast?: boolean
) {
    let status;

    if (isAnyDateFormStateEmpty(state)) {
        status = {
            code: DATE_STATUS_CODE.EMPTY
        };
    } else if (!isValidDateString(dateString(state))) {
        status = {
            text: 'Bitte gib ein gültiges Datum ein.',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else if (disableFuture && isDateInFuture(new Date(dateString(state)))) {
        status = {
            text: 'Das Datum darf nicht in der Zukunft liegen.',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else if (
        !state.day?.length ||
        !state.month?.length ||
        !state?.year ||
        state.year.length < 4
    ) {
        status = {
            text: '',
            code: DATE_STATUS_CODE.EMPTY
        };
    } else if (disablePast && isDateInPast(new Date(dateString(state)))) {
        status = {
            text: 'Das Datum darf nicht in der Vergangenheit liegen.',
            code: DATE_STATUS_CODE.INVALID_DATE
        };
    } else {
        status = {
            text: '',
            code: DATE_STATUS_CODE.VALID_DATE
        };
    }

    setStatus(status);
    onChangeStatus && onChangeStatus(status);

    return status;
}

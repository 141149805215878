import React from 'react';
import {formatMoney} from '../../../../utils/Format';
import {Close, InfoOutlined} from '@material-ui/icons/';
import {
    Box,
    createStyles,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Popover
} from '@material-ui/core';
import {WgTypography} from '../../../../../styles/CustomComponents';

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            width: '1px',
            height: '1.125rem',
            margin: '0 8px',
            '@media (max-width: 320px)': {
                height: '0.875rem'
            }
        },
        infoIcon: {
            marginLeft: '1vw',
            padding: 0
        },
        popoverCloseButton: {
            padding: '8px',
            position: 'absolute',
            top: '0',
            right: '0'
        }
    })
);

interface DashboardHeaderProps {
    income: number;
    outgoing: number;
    disposable: number;
    savingsTarget?: number;
    selectedStep: string;
    selectedDate: Date;
    handleStepChange: Function;
}

export default function DashboardHeader(props: DashboardHeaderProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'info-popover' : undefined;
    return (
        <Box
            p="0 2vh"
            display="flex"
            justifyContent="space-between"
            alignContent="flex-end">
            <Grid item container alignContent="center">
                <Grid item xs={8} container direction="column">
                    <Grid item container alignContent="center">
                        <WgTypography
                            header="headerDefault"
                            color="textPrimary">
                            Cashflow
                        </WgTypography>
                        <IconButton
                            aria-owns="info-popover"
                            aria-haspopup="true"
                            onClick={handlePopoverOpen}
                            className={classes.infoIcon}>
                            <InfoOutlined fontSize={'small'} />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            onBackdropClick={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}>
                            {getTooltipText()}
                        </Popover>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    alignItems="flex-end">
                    <WgTypography header="headerDefault">
                        {props.selectedDate.getFullYear()}
                    </WgTypography>
                </Grid>

                <Box
                    m="0.5vh 0"
                    display="flex"
                    minWidth="fit-content"
                    justifyContent="flex-start"
                    alignItems="center">
                    <WgTypography color="secondary">
                        {formatMoney(props.income)}
                    </WgTypography>
                    <Divider
                        light
                        orientation="vertical"
                        className={classes.divider}
                    />
                    <WgTypography color="error">
                        {formatMoney(props.outgoing)}
                    </WgTypography>
                    <Divider
                        light
                        orientation="vertical"
                        className={classes.divider}
                    />
                    <WgTypography>
                        {formatMoney(props.outgoing + props.income)}
                    </WgTypography>
                </Box>
            </Grid>
        </Box>
    );

    function handlePopoverOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    function getTooltipText() {
        return (
            <Box p="16px 24px">
                <IconButton
                    className={classes.popoverCloseButton}
                    onClick={handlePopoverClose}>
                    <Close color="disabled" fontSize={'small'} />
                </IconButton>
                <Box p="16px 0 0 0">
                    <Grid item xs={12} container direction="column">
                        <WgTypography
                            gutterBottom
                            text="tooltip"
                            color="textPrimary">
                            Hier kannst du deine täglichen <br />
                            und monatlichen Konto- <br />
                            bewegungen beobachten.
                            <br />
                            <br />
                            Wenn du im roten Bereich bist, <br />
                            hast du in diesem Monat mehr <br />
                            ausgegeben als eingenommen.
                            <br />
                            <br />
                            Deine Kontostände findest <br />
                            du unten.
                        </WgTypography>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

export interface IBank {
    id: number;
    name: string;
    bic: string;
    blz: string;
    location: string;
    city: string;
    searchString?: string;
    additionalSearchString?: string;
    logoPath: string;
    available: boolean;
}

export interface IBankLink {
    name: string;
    searchString?: string;
    logoPath: string;
    available: boolean;
}

export interface IBankConnection {
    displayName: string;
    bankId: number;
    bankConnectionId: number;
    lastUpdated?: string;
    accountIds: number[];
}

export interface IBankingState {
    message: string;
    successCode: AddBankAccountSuccessCodes;
}

export enum AddBankAccountSuccessCodes {
    Unknown = 0,
    Success = 1,
    BankingConnectionAlreadyExists = 2,
    InvalidInput = 3,
    RejectedByBank = 4,
    NoAccountsExists = 5,
    Technical = 6
}

export function isBankingState(object: any): object is IBankingState {
    return (
        typeof (object as IBankingState)?.message !== 'undefined' &&
        typeof (object as IBankingState)?.successCode !== 'undefined'
    );
}

export function isBankConnection(
    object: IBankConnection | any
): object is IBankConnection {
    return typeof (object as IBankConnection)?.bankConnectionId !== 'undefined';
}

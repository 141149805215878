import React, {useCallback, useEffect, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {
    WgPaperArea,
    WgTypography
} from '../../../../../styles/CustomComponents';
import VehicleHolderData from './directSale/VehicleHolderData';
import {IDateFieldExtended} from '../../../../../models/sharedInterface';
import {
    ICarInsuranceOptions,
    IVehicleHolder
} from '../../../../../models/productInterface';
import {
    isDigitsOnlySameLength,
    isInputNotFilled,
    isStringLengthLessThanOrEqual,
    validateEmail
} from '../../../../utils/Validate';

interface VehicleHolderProps {
    vehicleHolderData: IVehicleHolder;
    setVehicleHolderData: Function;
    options: ICarInsuranceOptions;
    validationCallback: (isValid: boolean) => void;
    fetchCarInsuranceVehicleHolderData?: Function;
    updateCarInsuranceVehicleHolder?: Function;
    validationEnabled: boolean;
}

export default function VehicleHolder({
    setVehicleHolderData,
    vehicleHolderData,
    validationCallback,
    fetchCarInsuranceVehicleHolderData,
    options,
    validationEnabled
}: VehicleHolderProps) {
    const validateAll = useCallback(
        (data: IVehicleHolder) => {
            const isValid = checkIsValid(data);

            validationCallback(isValid);
        },
        [validationCallback]
    );

    useEffect(() => {
        validateAll(vehicleHolderData);
    }, [validateAll, vehicleHolderData]);

    const [isInit, setIsInit] = useState(options.isVehicleHolderDataInit);

    useEffect(() => {
        if (!isInit) {
            fetchCarInsuranceVehicleHolderData &&
                fetchCarInsuranceVehicleHolderData();

            setIsInit(true);
        } else {
            setVehicleHolderData(vehicleHolderData);
            validateAll(vehicleHolderData);
        }
    }, [
        isInit,
        fetchCarInsuranceVehicleHolderData,
        setVehicleHolderData,
        vehicleHolderData,
        validateAll
    ]);

    function handleChange(event: any | IDateFieldExtended) {
        let dataForValidation = {...vehicleHolderData};

        if (event?.fieldName) {
            const {date, fieldName} = event;

            setVehicleHolderData({...vehicleHolderData, [fieldName]: date});

            dataForValidation = {
                ...dataForValidation,
                [fieldName]: date
            };
        } else {
            let {value, name} = event.target;
            if (name === 'postcode') {
                name = 'postalCode';
            }
            if (name === 'lastName') {
                name = 'familyName';
            }
            setVehicleHolderData({...vehicleHolderData, [name]: value});
            dataForValidation = {
                ...dataForValidation,
                [name]: value
            };
            if (isInit) {
                validateAll(dataForValidation);
            }
        }
    }

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Überprüfe deine persönlichen Angaben:
            </WgTypography>
            <Box marginTop="1rem">
                <WgPaperArea
                    header="Angaben zum Versicherungsnehmer"
                    elevation={1}
                    paperStyle={{padding: '1rem 1.25rem'}}>
                    <Box marginX="-1.25rem">
                        <VehicleHolderData
                            handleChange={handleChange}
                            vehicleHolder={vehicleHolderData}
                            validationEnabled={validationEnabled}
                        />
                    </Box>
                </WgPaperArea>
            </Box>
        </Grid>
    );
}

function validateEmailField(value?: string) {
    return !isInputNotFilled(value) && (value ? validateEmail(value) : false);
}

function validatePostalCode(value?: string) {
    return !isInputNotFilled(value) && isDigitsOnlySameLength(value ?? '', 5);
}

function validateCity(value?: string) {
    return (
        !isInputNotFilled(value) &&
        isStringLengthLessThanOrEqual(value ?? '', 40)
    );
}

function checkIsValid(data: IVehicleHolder) {
    let isValid = true;
    isValid = !isValid ? isValid : !isInputNotFilled(data.firstName);
    isValid = !isValid ? isValid : !isInputNotFilled(data.familyName);
    isValid = !isValid ? isValid : !isInputNotFilled(data.birthDate);
    isValid = !isValid ? isValid : !isInputNotFilled(data.gender);
    isValid = !isValid ? isValid : validateEmailField(data.email);
    isValid = !isValid ? isValid : !isInputNotFilled(data.street);
    isValid = !isValid ? isValid : !isInputNotFilled(data.houseNumber);
    isValid = !isValid ? isValid : validatePostalCode(data.postalCode ?? '');
    isValid = !isValid ? isValid : validateCity(data.city ?? '');

    return isValid;
}

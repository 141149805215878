import React, {useEffect, useState} from 'react';
import {Box, Card, Grid} from '@material-ui/core';
import {
    formatMeterType,
    MeterType
} from '../../../../../models/productInterface';
import {
    EnergyStep,
    IEnergyContractPreferences
} from '../../../../../models/offerInterface';
import {
    WgSelectorButton,
    WgTypography
} from '../../../../../styles/CustomComponents';
import InsuranceListItem from '../../../insurance/components/InsuranceListItem';
import {ICompany} from '../../../../../models/companyInterface';

export interface MeterTypeInputProps {
    handleChange: (
        name: keyof IEnergyContractPreferences
    ) => (value: any) => void;
    meterType: MeterType;
    company?: ICompany;
    oldProviderName?: string;
    setInputValid: Function;
    handleStepChange: Function;
    isManualAddition?: boolean;
}

export default function MeterTypeInput({
    handleChange,
    meterType,
    company,
    oldProviderName,
    setInputValid,
    handleStepChange,
    isManualAddition
}: MeterTypeInputProps) {
    const isCustomProvider =
        typeof company === 'undefined' &&
        typeof oldProviderName !== 'undefined';
    const [activeMeterType, setActiveMeterType] = useState<
        MeterType | undefined
    >(
        meterType === MeterType.Electricity
            ? MeterType.ElectricityHeatingWithoutOwnMeter
            : meterType
    );

    useEffect(() => {
        if (activeMeterType === MeterType.Electricity) {
            setActiveMeterType(MeterType.ElectricityHeatingWithoutOwnMeter);
        }
    }, [activeMeterType]);

    useEffect(() => {
        setInputValid(
            typeof activeMeterType !== 'undefined' &&
                (typeof oldProviderName !== 'undefined' ||
                    typeof company !== 'undefined')
        );
    }, [activeMeterType, oldProviderName, company, setInputValid]);

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <Card style={{padding: '1rem'}}>
                <WgTypography color="textPrimary" text="content">
                    Vertragsart
                </WgTypography>
                <WgTypography
                    gutterBottom
                    gutterTop="1.5rem"
                    color="textSecondary"
                    text="subContent">
                    {isManualAddition
                        ? 'Bitte wähle die Vertragsart.'
                        : 'Bitte bestätige die Vertragsart.'}
                </WgTypography>
                <Grid container spacing={1} style={{marginTop: '0.5rem'}}>
                    {renderButton(MeterType.Electricity)}
                    {renderButton(MeterType.Gas)}
                </Grid>
            </Card>
            <Box paddingTop="2rem">
                {isManualAddition ? (
                    <WgTypography
                        gutterBottom
                        gutterTop="1.5rem"
                        color="textSecondary"
                        text="subContent">
                        Bitte nenne uns deinen Anbieter.
                    </WgTypography>
                ) : (
                    <WgTypography
                        gutterBottom
                        gutterTop="1.5rem"
                        color="textSecondary"
                        text="subContent">
                        Bitte überprüfe deinen Anbieter.
                    </WgTypography>
                )}
                <InsuranceListItem
                    placeholder={oldProviderName ?? 'Versorger hinzufügen'}
                    data={company}
                    shadow
                    hasIcon
                    onClick={() => handleStepChange(EnergyStep.PROVIDER_INPUT)}
                    warningFlag={
                        isCustomProvider || typeof company === 'undefined'
                            ? 'left'
                            : undefined
                    }
                />
                {isCustomProvider && (
                    <WgTypography
                        gutterTop="0.5rem"
                        gutterBottom
                        color="error"
                        text="subContent">
                        Die manuelle Eingabe des Versorgers kann zu ungenauen
                        Berechnungen der Angebote führen. Bist du dir sicher,
                        dass dein Versorger nicht gefunden werden kann?
                    </WgTypography>
                )}
            </Box>
        </Grid>
    );

    function renderButton(type: MeterType, fullWidth = false) {
        return (
            <Grid item xs={fullWidth ? 12 : 6}>
                <WgSelectorButton
                    handleChange={handleChange('meterType')}
                    active={
                        meterType === type ||
                        (type === MeterType.Electricity &&
                            meterType > MeterType.Gas)
                    }
                    value={type}
                    text={formatMeterType(type)}
                />
            </Grid>
        );
    }
}

import React from 'react';
import {
    Box,
    Button,
    createStyles,
    DialogActions,
    DialogContent,
    makeStyles,
    Theme
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import {WgTypography} from '../../../styles/CustomComponents';
import {useDispatch} from 'react-redux';
import {removeLoading} from '../../../store/actions/notificationActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        agreeButton: {
            color: theme.palette.secondary.main
        }
    })
);

interface WgNotificationDialogProps {
    title: string;
    description: string;
    hasAgreeButton?: boolean;
    id: string;
}

export default function WgNotificationDialog({
    title,
    description,
    id,
    hasAgreeButton
}: WgNotificationDialogProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    return (
        <Dialog
            open
            disableBackdropClick
            aria-labelledby="alert-dialog-title-notification"
            aria-describedby="alert-dialog-description-notification">
            <DialogContent>
                <Box marginTop="1.5rem">
                    {typeof title !== 'undefined' && (
                        <WgTypography
                            gutterBottom
                            color="textPrimary"
                            style={{fontWeight: 800}}>
                            {title}
                        </WgTypography>
                    )}
                    {typeof description !== 'undefined' && (
                        <WgTypography text="content" color="textSecondary">
                            {description}
                        </WgTypography>
                    )}
                </Box>
            </DialogContent>
            {hasAgreeButton && (
                <DialogActions>
                    <Button
                        className={classes.agreeButton}
                        onClick={() => dispatch(removeLoading(id))}>
                        OKAY
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

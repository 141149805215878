import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {showCookieBanner} from '../../store/actions/notificationActions';
import useAuth from '../../hooks/useAuth';

interface RedirectorProps {
    protectedViews: any;
    publicViews: any;
    showCookieBanner: Function;
}

function Redirector({
    showCookieBanner,
    protectedViews,
    publicViews
}: RedirectorProps) {
    const {isAuthenticated} = useAuth();
    useEffect(() => {
        setTimeout(showCookieBanner, 0);
    }, [showCookieBanner]);

    return isAuthenticated ? protectedViews : publicViews;
}

function mapStateToProps(state: any) {
    return {
        user: state.user
    };
}

export default React.memo(
    connect(mapStateToProps, {
        showCookieBanner
    })(Redirector)
);

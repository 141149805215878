import React from 'react';
import {Paper} from '@material-ui/core';
import {SignupStep} from './SignupStep';
import {validateEmail} from '../../../utils/Validate';
import {WgInputField} from '../../../../styles/CustomComponents';

export default class SignupStep7 extends SignupStep {
    // @ts-ignore
    state = {
        ...this.state,
        isEmailValid: false
    };

    renderStep(): React.ReactNode {
        return (
            <React.Fragment>
                <Paper elevation={1}>
                    <WgInputField
                        autoFocus
                        label="E-Mail"
                        type="email"
                        defaultValue={this.props.state.email}
                        name="email"
                        autoComplete="email"
                    />
                </Paper>
            </React.Fragment>
        );
    }

    areRequirementsMet(props: any) {
        // @ts-ignore
        return (
            super.areRequirementsMet(props) && validateEmail(props.state.email)
        );
    }
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fillProductGrid} from '../components/ProductUtils';
import {productActions} from '../../../../store/actions';
import ProductsGrid from '../components/ProductsGrid';
import {WgContainer, WgHeader} from '../../../../styles/CustomComponents';

const {fetchExistingProductFiles} = productActions;

interface IProps {
    products: any[];
    match: any;
    fetchExistingProductFiles: Function;
}
//ToDo: Refactoring as Functional Component
class ProductDocuments extends Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);

        const {id} = this.props.match.params;
        this.props.fetchExistingProductFiles &&
            this.props.fetchExistingProductFiles(id, 0);
    }

    render() {
        return (
            <WgContainer bodyApp>
                <WgHeader
                    headerText={'Versicherungsunterlagen'}
                    hasBackButton
                    backText="Versicherungswelt"
                />

                <WgContainer bodyContent>
                    <ProductsGrid
                        products={fillProductGrid(this.props.products)}
                    />
                </WgContainer>
            </WgContainer>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        products: state.products.existingProductFiles
    };
}

export default connect(mapStateToProps, {fetchExistingProductFiles})(
    ProductDocuments
);

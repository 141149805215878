import {
    AddBankAccountSuccessCodes,
    IBankConnection,
    IBankingState
} from '../../models/bankInterface';

export const singleBankConnection: IBankConnection = {
    displayName: 'Volksbank im Landkreis Neustadt an der Aisch 91413',
    bankId: 123,
    bankConnectionId: 6969,
    lastUpdated: '2020-05-07T19:51:18.863Z',
    accountIds: [1000, 1001, 1002, 1003]
};

export const bankConnections: IBankConnection[] = [
    {
        displayName: 'Volksbank im Landkreis Neustadt an der Aisch 91413',
        bankId: 123,
        bankConnectionId: 6969,
        lastUpdated: '2020-05-07T19:51:18.863Z',
        accountIds: [1000, 1001, 1002, 1003]
    },
    {
        displayName: 'ING Diba',
        bankId: 123,
        bankConnectionId: 696969,
        lastUpdated: '2020-05-17T09:51:18.863Z',
        accountIds: [1004, 1005]
    },
    {
        displayName: 'Sparkasse',
        bankId: 123,
        bankConnectionId: 6769420,
        lastUpdated: '2020-04-07T17:51:18.863Z',
        accountIds: [1006, 1007, 1008, 1009]
    },
    {
        displayName: 'Commerzbank',
        bankId: 123,
        bankConnectionId: 420,
        lastUpdated: '2020-05-22T10:51:18.863Z',
        accountIds: [1000, 1001, 1002, 1003]
    }
];

export const successBankAddingState: IBankingState = {
    message:
        'Hinzufügen erfolgreich! Deine Konten werden von nun an in der Kontenübersicht angezeigt. Du kannst weniger genutzte Konten jederzeit aus- und einblenden!',
    successCode: AddBankAccountSuccessCodes.Success
};

export const failureBankAddingState: IBankingState = {
    message:
        'Hinzufügen nicht erfolgreich! Deine Bank konnte nicht hinzugefügt werden. Die angeforderte Bank hat die Kontenabfrage abgelehnt. Bitte setze dich mit deiner Bank in Verbindung!',
    successCode: AddBankAccountSuccessCodes.RejectedByBank
};

export const initialBanks = [
    {
        id: 0, //For testing
        name: 'Volks- und Raiffeisenbanken',
        searchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        name: 'Sparkasse',
        searchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        name: 'Postbank',
        searchString: 'Postbank',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19978,
        name: 'N26 Bank',
        bic: 'NTSBDEB1XXX',
        blz: '10011001',
        city: 'Berlin',
        logoPath: '141.svg',
        available: true
    },
    {
        id: 15807,
        name: 'ING (ehem. ING-DiBa)',
        bic: 'INGDDEFFXXX',
        blz: '50010517',
        city: 'Frankfurt am Main',
        logoPath: '142.svg',
        available: true
    },
    {
        id: 15800,
        name: 'Deutsche Kreditbank Berlin (DKB)',
        bic: 'BYLADEM1001',
        blz: '12030000',
        city: 'Berlin',
        logoPath: '143.svg',
        available: true
    },
    {
        name: 'Deutsche Bank',
        searchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        name: 'Commerzbank',
        searchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15804,
        name: 'comdirect bank',
        bic: 'COBADEHDXXX',
        blz: '20041111',
        city: 'Quickborn',
        logoPath: '167.svg',
        available: true
    }
];
export const allBanks = [
    {
        id: 15807,
        name: 'ING (ehem. ING-DiBa)',
        bic: 'INGDDEFFXXX',
        blz: '50010517',
        city: 'Frankfurt am Main',
        logoPath: '142.svg',
        available: true
    },
    {
        id: 19978,
        name: 'N26 Bank',
        bic: 'NTSBDEB1XXX',
        blz: '10011001',
        city: 'Berlin',
        logoPath: '141.svg',
        available: true
    },
    {
        id: 15806,
        name: 'comdirect bank',
        bic: 'COBADEHD055',
        blz: '20041155',
        city: 'Quickborn',
        logoPath: '167.svg',
        available: true
    },
    {
        id: 15817,
        name: 'VR-Bank Werdenfels',
        bic: 'GENODEF1GAP',
        blz: '70390000',
        city: 'Garmisch-Partenkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15800,
        name: 'Deutsche Kreditbank Berlin (DKB)',
        bic: 'BYLADEM1001',
        blz: '12030000',
        city: 'Berlin',
        logoPath: '143.svg',
        available: true
    },
    {
        id: 15818,
        name: 'Consorsbank - BNP Paribas S.A. Niederlassung Deutschland',
        bic: 'CSDBDE71XXX',
        blz: '76030080',
        city: 'Nürnberg',
        logoPath: '185.svg',
        available: true
    },
    {
        id: 15997,
        name: 'comdirect bank',
        bic: 'COBADEHD001',
        blz: '20041133',
        city: 'Quickborn',
        logoPath: '167.svg',
        available: true
    },
    {
        id: 17124,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B416',
        blz: '41670027',
        city: 'Lippstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19251,
        name: 'Sparda-Bank Nürnberg',
        bic: 'GENODEF1S06',
        blz: '76090500',
        city: 'Nürnberg',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 19241,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB760',
        blz: '76070024',
        city: 'Nürnberg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15796,
        name: 'Bundesbank',
        bic: 'MARKDEF1100',
        blz: '10000000',
        city: 'Berlin',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 15797,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '10010010',
        city: 'Berlin',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 15798,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F100',
        blz: '10010111',
        city: 'Berlin',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 15799,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM488',
        blz: '10020890',
        city: 'Berlin',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 15803,
        name: 'Commerzbank',
        bic: 'COBADEHHXXX',
        blz: '20040000',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15801,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F200',
        blz: '20010111',
        city: 'Hamburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 15802,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM300',
        blz: '20030000',
        city: 'Hamburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 15820,
        name: 'Sparkasse Fürth',
        bic: 'BYLADEM1SFU',
        blz: '76250000',
        city: 'Fürth',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15805,
        name: 'comdirect bank',
        bic: 'COBADEHD044',
        blz: '20041144',
        city: 'Quickborn',
        logoPath: '167.svg',
        available: true
    },
    {
        id: 15808,
        name: 'Sparda-Bank Baden-Württemberg',
        bic: 'GENODEF1S02',
        blz: '60090800',
        city: 'Stuttgart',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 15809,
        name: 'Bundesbank',
        bic: 'MARKDEF1700',
        blz: '70000000',
        city: 'München',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 15810,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMMXXX',
        blz: '70020270',
        city: 'München',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 15811,
        name: 'Fürst Fugger Privatbank',
        bic: 'FUBKDE71MUC',
        blz: '70030014',
        city: 'Augsburg',
        available: true
    },
    {
        id: 15816,
        name: 'Genossenschaftsbank München',
        bic: 'GENODEF1M07',
        blz: '70169464',
        city: 'München',
        available: true
    },
    {
        id: 15821,
        name: 'Bankhaus Max Flessa',
        bic: 'FLESDEMMXXX',
        blz: '79330111',
        city: 'Schweinfurt',
        available: true
    },
    {
        id: 15822,
        name: 'The Royal Bank of Scotland, Niederlassung Deutschland',
        bic: 'ABNADEFFBER',
        blz: '10010222',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 15823,
        name: 'Aareal Bank',
        bic: 'AARBDE5W100',
        blz: '10010424',
        city: 'Berlin',
        logoPath: '188.svg',
        available: false
    },
    {id: 15825, name: 'Berliner Bank -alt-', blz: '10020000', available: false},
    {
        id: 15826,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF100',
        blz: '10020200',
        city: 'Berlin',
        available: true
    },
    {
        id: 15827,
        name: 'Citadele Bank Zndl Deutschland',
        blz: '10020400',
        available: false
    },
    {
        id: 15828,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33BER',
        blz: '10020500',
        city: 'Berlin',
        available: false
    },
    {
        id: 15829,
        name: 'Berlin Hyp',
        bic: 'BHYPDEB2XXX',
        blz: '10030200',
        city: 'Berlin',
        logoPath: '60.svg',
        available: false
    },
    {
        id: 15830,
        name: 'ABK Allgemeine Beamten Bank',
        bic: 'ABKBDEB1XXX',
        blz: '10030400',
        city: 'Berlin',
        available: false
    },
    {
        id: 15831,
        name: 'Bankhaus Löbbecke',
        bic: 'LOEBDEBBXXX',
        blz: '10030500',
        city: 'Berlin',
        logoPath: '58.svg',
        available: true
    },
    {
        id: 15832,
        name: 'North Channel Bank',
        bic: 'GENODEF1OGK',
        blz: '10030600',
        city: 'Mainz',
        logoPath: '101.svg',
        available: true
    },
    {
        id: 15833,
        name: 'Eurocity Bank',
        bic: 'DLGHDEB1XXX',
        blz: '10030700',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 15834,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '10033300',
        city: 'Berlin',
        logoPath: '175.svg',
        available: true
    },
    {
        id: 15844,
        name: 'Landesbank Berlin - E 1 -',
        bic: 'BELADEB1DB5',
        blz: '10050005',
        city: 'Berlin',
        available: false
    },
    {
        id: 15845,
        name: 'Landesbank Berlin - E 2 -',
        bic: 'BELADEB1DB6',
        blz: '10050006',
        city: 'Berlin',
        available: false
    },
    {
        id: 15846,
        name: 'Landesbank Berlin - E 3 -',
        bic: 'BELADEB1DB7',
        blz: '10050007',
        city: 'Berlin',
        available: false
    },
    {
        id: 15847,
        name: 'Landesbank Berlin - E 4 -',
        bic: 'BELADEB1DB8',
        blz: '10050008',
        city: 'Berlin',
        available: false
    },
    {
        id: 15848,
        name: 'LBB S-Kreditpartner, Berlin',
        bic: 'SKPADEB1XXX',
        blz: '10050020',
        city: 'Berlin',
        available: false
    },
    {
        id: 15849,
        name: 'LBB S-Kreditpartner Bad Homburg v d Höhe',
        bic: 'SKPADEB1HOM',
        blz: '10050021',
        available: false
    },
    {
        id: 15850,
        name: 'LBS Ost Berlin',
        bic: 'LBSODEB1BLN',
        blz: '10050500',
        city: 'Berlin',
        available: false
    },
    {
        id: 15851,
        name: 'WestLB Berlin',
        bic: 'WELADEBBXXX',
        blz: '10050600',
        logoPath: '160.svg',
        available: false
    },
    {
        id: 15852,
        name: 'DekaBank',
        bic: 'DGZFDEFFBER',
        blz: '10050999',
        city: 'Frankfurt am Main',
        logoPath: '75.svg',
        available: true
    },
    {
        id: 15853,
        name: 'Pax-Bank',
        bic: 'GENODED1PA6',
        blz: '10060198',
        city: 'Berlin',
        logoPath: '106.svg',
        available: true
    },
    {
        id: 15854,
        name: 'Evangelische Darlehnsgenossenschaft',
        bic: 'GENODEF1EDG',
        blz: '10060237',
        available: false
    },
    {
        id: 15855,
        name: 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
        bic: 'GENODED1KDB',
        blz: '10061006',
        city: 'Berlin',
        available: false
    },
    {
        id: 15815,
        name: 'VR Bank München Land',
        bic: 'GENODEF1OHC',
        blz: '70166486',
        city: 'Deisenhofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15812,
        name: 'Sparkasse Fürstenfeldbruck',
        bic: 'BYLADEM1FFB',
        blz: '70053070',
        city: 'Fürstenfeldbruck',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15856,
        name: 'Deutsche Bank Fil Berlin',
        bic: 'DEUTDEBBXXX',
        blz: '10070000',
        city: 'Berlin',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15814,
        name: 'Stadtsparkasse München',
        bic: 'SSKMDEMMXXX',
        blz: '70150000',
        city: 'München',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15835,
        name: 'Commerzbank, Filiale Berlin 1',
        bic: 'COBADEBBXXX',
        blz: '10040000',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15836,
        name: 'Commerzbank, CC SP',
        bic: 'COBADEFFXXX',
        blz: '10040010',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 15837,
        name: 'Commerzbank GF-B48',
        bic: 'COBADEFFXXX',
        blz: '10040048',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15838,
        name: 'Commerzbank Gf 160',
        bic: 'COBADEFFXXX',
        blz: '10040060',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15839,
        name: 'Commerzbank Gf 161',
        bic: 'COBADEFFXXX',
        blz: '10040061',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15840,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '10040062',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15841,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '10040063',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15813,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF700',
        blz: '70080000',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15858,
        name: 'Deutsche Bank Fil Berlin II',
        bic: 'DEUTDEBB101',
        blz: '10070100',
        city: 'Berlin',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18845,
        name: 'Freisinger Bank Volksbank-Raiffeisenbank',
        bic: 'GENODEF1FSR',
        blz: '70169614',
        city: 'Freising',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15819,
        name: 'Sparkasse Nürnberg',
        bic: 'SSKNDE77XXX',
        blz: '76050101',
        city: 'Nürnberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15859,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK) Berlin',
        bic: 'DEUTDEDB101',
        blz: '10070124',
        city: 'Berlin',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15824,
        name: 'KOFIBA-Kommunalfinanzierungsbank (ehem. Dexia)',
        bic: 'DXIADEBBXXX',
        blz: '10019610',
        city: 'Berlin',
        available: false
    },
    {
        id: 15866,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF114',
        blz: '10080057',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15861,
        name: 'norisbank',
        bic: 'NORSDE51XXX',
        blz: '10077777',
        city: 'Berlin',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 15875,
        name: 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
        bic: 'GENODEF1BSB',
        blz: '10090300',
        city: 'Berlin',
        available: false
    },
    {
        id: 15877,
        name: 'Westend Bank',
        bic: 'MACODEB1XXX',
        blz: '10110300',
        city: 'Berlin',
        logoPath: '36.svg',
        available: false
    },
    {
        id: 15878,
        name: 'Investitionsbank Berlin',
        bic: 'IBBBDEBBXXX',
        blz: '10110400',
        city: 'Berlin',
        available: false
    },
    {
        id: 15879,
        name: 'Quirin Privatbank',
        bic: 'QUBKDEBBXXX',
        blz: '10110600',
        city: 'Berlin',
        logoPath: '110.svg',
        available: false
    },
    {
        id: 15880,
        name: 'Weberbank',
        bic: 'WELADED1WBB',
        blz: '10120100',
        city: 'Berlin',
        logoPath: '37.svg',
        available: true
    },
    {
        id: 15881,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 260 BIn',
        bic: 'HYVEDEM1079',
        blz: '10120760',
        available: false
    },
    {
        id: 15882,
        name: 'BNP Paribas Ndl. Deutschland (ehem. VON ESSEN Bank)',
        bic: 'VONEDE33BLN',
        blz: '10120800',
        city: 'Berlin',
        available: false
    },
    {id: 15883, name: 'Readybank', blz: '10120900', available: false},
    {
        id: 15884,
        name: 'Readybank Gf GAA',
        blz: '10120999',
        available: false
    },
    {
        id: 15885,
        name: 'Isbank Fil Berlin',
        bic: 'ISBKDEFXXXX',
        blz: '10130600',
        city: 'Berlin',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 15886,
        name: 'FinTech Group Bank AG (auch biw Bank, flatex, brokerport)',
        bic: 'BIWBDE33XXX',
        blz: '10130800',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 15887,
        name: 'Sydbank Filiale Berlin',
        bic: 'SYBKDE22BER',
        blz: '10220600',
        city: 'Berlin',
        logoPath: '173.svg',
        available: false
    },
    {
        id: 15888,
        name: 'Tradegate Wertpapierhandelsbank Berlin',
        bic: 'TRDADEB1PBK',
        blz: '10310600',
        city: 'Berlin',
        available: false
    },
    {
        id: 15889,
        name: 'KfW Kreditanstalt für Wiederaufbau',
        bic: 'KFWIDEFF100',
        blz: '12016836',
        city: 'Berlin',
        logoPath: '180.svg',
        available: false
    },
    {
        id: 15890,
        name: 'Merck Finck Privatbankiers',
        bic: 'MEFIDEMM100',
        blz: '12030900',
        city: 'Berlin',
        available: true
    },
    {
        id: 15892,
        name: 'ZVA Norddeutsche Landesbank Gf SA',
        bic: 'NOLADE21DVS',
        blz: '12050555',
        city: 'Hannover',
        available: false
    },
    {
        id: 15893,
        name: 'DZ BANK',
        bic: 'GENODEFF120',
        blz: '12060000',
        city: 'Berlin',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 15867,
        name: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 3',
        bic: 'DRESDEFFI53',
        blz: '10080085',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15899,
        name: 'Sparda-Bank Berlin',
        bic: 'GENODEF1S10',
        blz: '12096597',
        city: 'Berlin',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 15900,
        name: 'Bundesbank',
        bic: 'MARKDEF1130',
        blz: '13000000',
        city: 'Rostock',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 15901,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F130',
        blz: '13010111',
        city: 'Rostock',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 15916,
        name: 'Bundesbank eh Schwerin',
        bic: 'MARKDEF1140',
        blz: '14000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 15868,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFI71',
        blz: '10080086',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15869,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFI72',
        blz: '10080087',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15870,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
        bic: 'DRESDEFFI73',
        blz: '10080089',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15871,
        name: 'Commerzbank vormals Dresdner Bank Filiale Berlin III',
        bic: 'DRESDEFF199',
        blz: '10080900',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15872,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI14',
        blz: '10089260',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15873,
        name: 'Commerzbank vormals Dresdner Bank ITGK 2',
        bic: 'DRESDEFFI99',
        blz: '10089999',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15874,
        name: 'Berliner Volksbank',
        bic: 'BEVODEBBXXX',
        blz: '10090000',
        city: 'Berlin',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15891,
        name: 'Commerzbank Filiale Berlin 2',
        bic: 'COBADEBB120',
        blz: '12040000',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15894,
        name: 'Deutsche Bank Ld Brandenburg',
        bic: 'DEUTDEBB160',
        blz: '12070000',
        city: 'Potsdam',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15896,
        name: 'Deutsche Bank (Gf intern)',
        bic: 'DEUTDEFFVAC',
        blz: '12070070',
        city: 'Potsdam',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 15897,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK) intern',
        bic: 'DEUTDEDBPAL',
        blz: '12070088',
        city: 'Potsdam',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 15898,
        name: 'Commerzbank vormals Dresdner Bank Filiale Berlin II',
        bic: 'DRESDEFF120',
        blz: '12080000',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15902,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '13040000',
        city: 'Rostock',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15919,
        name: 'Sparkasse Parchim-Lübz',
        bic: 'NOLADE21PCH',
        blz: '14051362',
        city: 'Parchim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15905,
        name: 'Volksbank Wolgast',
        bic: 'GENODEF1WOG',
        blz: '13061008',
        city: 'Wolgast',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15906,
        name: 'Volksbank Raiffeisenbank ehem VB Greifswald',
        bic: 'GENODEF1GW1',
        blz: '13061028',
        city: 'Greifswald, Hansestadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15907,
        name: 'Volks- und Raiffeisenbank',
        bic: 'GENODEF1HWI',
        blz: '13061078',
        city: 'Wismar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15908,
        name: 'Raiffeisenbank Wismar -alt-',
        bic: 'GENODEF1HWR',
        blz: '13061088',
        city: 'Wismar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15909,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1DBR',
        blz: '13061128',
        city: 'Bad Doberan',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15910,
        name: 'Deutsche Bank',
        bic: 'DEUTDEBRXXX',
        blz: '13070000',
        city: 'Rostock',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15911,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBROS',
        blz: '13070024',
        city: 'Rostock',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15912,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF130',
        blz: '13080000',
        city: 'Rostock',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15913,
        name: 'Rostocker Volks- und Raiffeisenbank',
        bic: 'GENODEF1HR1',
        blz: '13090000',
        city: 'Rostock',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15914,
        name: 'Pommersche Volksbank',
        bic: 'GENODEF1HST',
        blz: '13091054',
        city: 'Stralsund',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15915,
        name: 'Volksbank Wismar -alt-',
        bic: 'GENODEF1HWV',
        blz: '13091084',
        city: 'Wismar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15917,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '14040000',
        city: 'Schwerin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15903,
        name: 'Ostseesparkasse Rostock',
        bic: 'NOLADE21ROS',
        blz: '13050000',
        city: 'Rostock',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15918,
        name: 'Sparkasse Mecklenburg-Nordwest',
        bic: 'NOLADE21WIS',
        blz: '14051000',
        city: 'Wismar',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15862,
        name: 'Commerzbank vormals Dresdner Bank Filiale Berlin I',
        bic: 'DRESDEFF100',
        blz: '10080000',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15863,
        name: 'Commerzbank vormals Dresdner Bank Zw A',
        bic: 'DRESDEFFI26',
        blz: '10080005',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15864,
        name: 'Commerzbank vormals Dresdner Bank Zw B',
        bic: 'DRESDEFFXXX',
        blz: '10080006',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20078,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39117',
        available: false
    },
    {
        id: 15865,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF112',
        blz: '10080055',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15876,
        name: 'PSD Bank Berlin-Brandenburg',
        bic: 'GENODEF1P01',
        blz: '10090900',
        city: 'Berlin',
        available: true
    },
    {
        id: 15904,
        name: 'Sparkasse Vorpommern auf Rügen',
        bic: 'NOLADE21RUE',
        blz: '13051042',
        city: 'Bergen auf Rügen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 15950,
        name: 'LBS Ostdeutsche Landesbausparkasse',
        bic: 'LBSODEB1XXX',
        blz: '16050500',
        city: 'Potsdam',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15974,
        name: 'VR Bank Lausitz',
        bic: 'GENODEF1FWA',
        blz: '18062678',
        city: 'Cottbus',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15927,
        name: 'Bundesbank',
        bic: 'MARKDEF1150',
        blz: '15000000',
        city: 'Neubrandenburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 15975,
        name: 'VR Bank Forst',
        bic: 'GENODEF1FOR',
        blz: '18062758',
        city: 'Forst (Lausitz)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15976,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF180',
        blz: '18080000',
        city: 'Cottbus',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15978,
        name: 'Volksbank Spree-Neiße',
        bic: 'GENODEF1SPM',
        blz: '18092744',
        city: 'Spremberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15947,
        name: 'Mittelbrandenburgische Sparkasse in Potsdam',
        bic: 'WELADED1PMB',
        blz: '16050000',
        city: 'Potsdam',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15922,
        name: 'Volks- und Raiffeisenbank',
        bic: 'GENODEF1GUE',
        blz: '14061308',
        city: 'Güstrow',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15938,
        name: 'Neubrandenburger Bank -alt-',
        blz: '15061758',
        available: false
    },
    {
        id: 15923,
        name: 'Raiffeisen-Volksbank -alt-',
        bic: 'GENODEF1GDB',
        blz: '14061438',
        city: 'Gadebusch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15942,
        name: 'Bundesbank eh Potsdam',
        bic: 'MARKDEF1160',
        blz: '16000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 15943,
        name: 'SEB',
        bic: 'ESSEDE5F160',
        blz: '16010111',
        city: 'Potsdam',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 15944,
        name: 'Investitionsbank des Landes Brandenburg',
        bic: 'ILBXDE8XXXX',
        blz: '16010300',
        city: 'Potsdam',
        available: false
    },
    {
        id: 15945,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM470',
        blz: '16020086',
        city: 'Potsdam',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 15954,
        name: 'Brandenburger Bank',
        bic: 'GENODEF1BRB',
        blz: '16062073',
        city: 'Brandenburg an der Havel',
        available: true
    },
    {
        id: 15957,
        name: 'Bundesbank eh Frankfurt (Oder)',
        bic: 'MARKDEF1170',
        blz: '17000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 15958,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM471',
        blz: '17020086',
        city: 'Frankfurt (Oder)',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 15968,
        name: 'Bundesbank',
        bic: 'MARKDEF1180',
        blz: '18000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 15969,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM472',
        blz: '18020086',
        city: 'Cottbus',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 15977,
        name: 'Spreewaldbank',
        bic: 'GENODEF1LN1',
        blz: '18092684',
        city: 'Lübben (Spreewald)',
        available: true
    },
    {
        id: 15979,
        name: 'Bundesbank',
        bic: 'MARKDEF1200',
        blz: '20000000',
        city: 'Hamburg',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 15980,
        name: 'Aareal Bank',
        bic: 'AARBDE5W200',
        blz: '20010424',
        city: 'Hamburg',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 15981,
        name: 'Skandinaviska Enskilda Banken (publ) Hamburg Branch',
        bic: 'ESSEDEFFHAM',
        blz: '20020200',
        city: 'Hamburg',
        available: false
    },
    {
        id: 15924,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF140',
        blz: '14080000',
        city: 'Schwerin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15925,
        name: 'Commerzbank vormals Dresdner Bank Zw W',
        bic: 'DRESDEFFI27',
        blz: '14080011',
        city: 'Schwerin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15948,
        name: 'Sparkasse Prignitz',
        bic: 'WELADED1PRP',
        blz: '16050101',
        city: 'Pritzwalk',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15934,
        name: 'Raiffeisenbank Mecklenburger Seenplatte',
        bic: 'GENODEF1WRN',
        blz: '15061618',
        city: 'Waren (Müritz)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15935,
        name: 'Volksbank Raiffeisenbank',
        bic: 'GENODEF1ANK',
        blz: '15061638',
        city: 'Greifswald, Hansestadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15936,
        name: 'Raiffeisenbank Pasewalk-Strasburg -alt-',
        blz: '15061658',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15937,
        name: 'Raiffeisenbank Malchin',
        bic: 'GENODEF1MAL',
        blz: '15061698',
        city: 'Malchin',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15940,
        name: 'Volksbank Demmin',
        bic: 'GENODEF1DM1',
        blz: '15091674',
        city: 'Demmin, Hansestadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15941,
        name: 'VR-Bank Uckermark-Randow',
        bic: 'GENODEF1PZ1',
        blz: '15091704',
        city: 'Prenzlau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15946,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '16040000',
        city: 'Potsdam',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 21022,
        name: 'ZVC FK 58 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010058',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 15949,
        name: 'Sparkasse Ostprignitz-Ruppin',
        bic: 'WELADED1OPR',
        blz: '16050202',
        city: 'Neuruppin',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20061,
        name: 'Erste Bank der oesterreichischen Sparkassen AG',
        bic: 'GIBAATWWXXX',
        blz: '18700',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 15960,
        name: 'Sparkasse Barnim',
        bic: 'WELADED1GZE',
        blz: '17052000',
        city: 'Eberswalde',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15951,
        name: 'Volks- und Raiffeisenbank Prignitz',
        bic: 'GENODEF1PER',
        blz: '16060122',
        city: 'Perleberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15952,
        name: 'Raiffeisenbank Ostprignitz-Ruppin',
        bic: 'GENODEF1NPP',
        blz: '16061938',
        city: 'Neuruppin',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15953,
        name: 'VR-Bank Fläming',
        bic: 'GENODEF1LUK',
        blz: '16062008',
        city: 'Luckenwalde',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15955,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF160',
        blz: '16080000',
        city: 'Potsdam',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15956,
        name: 'Volksbank Rathenow',
        bic: 'GENODEF1RN1',
        blz: '16091994',
        city: 'Rathenow',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15959,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '17040000',
        city: 'Frankfurt (Oder)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15962,
        name: 'Sparkasse Märkisch-Oderland',
        bic: 'WELADED1MOL',
        blz: '17054040',
        city: 'Strausberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19278,
        name: 'VR-Bank Mittelfranken West',
        bic: 'GENODEF1ANS',
        blz: '76560060',
        city: 'Ansbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15963,
        name: 'Sparkasse Oder-Spree',
        bic: 'WELADED1LOS',
        blz: '17055050',
        city: 'Frankfurt (Oder)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15964,
        name: 'Sparkasse Uckermark',
        bic: 'WELADED1UMP',
        blz: '17056060',
        city: 'Prenzlau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15843,
        name:
            'Landesbank Berlin - Berliner Sparkasse (auch ADAC, Amazon, airberlin Kreditkarten)',
        bic: 'BELADEBEXXX',
        blz: '10050000',
        city: 'Berlin',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15971,
        name: 'Sparkasse Spree-Neiße',
        bic: 'WELADED1CBN',
        blz: '18050000',
        city: 'Cottbus',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15965,
        name: 'Raiffeisenbank-Volksbank Oder-Spree',
        bic: 'GENODEF1BKW',
        blz: '17062428',
        city: 'Beeskow',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15842,
        name: 'Commerzbank Service-BZ',
        bic: 'COBADEFFXXX',
        blz: '10045050',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15967,
        name: 'VR Bank Fürstenwalde Seelow Wriezen',
        bic: 'GENODEF1FW1',
        blz: '17092404',
        city: 'Fürstenwalde/Spree',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15972,
        name: 'Sparkasse Elbe-Elster',
        bic: 'WELADED1EES',
        blz: '18051000',
        city: 'Finsterwalde',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15970,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '18040000',
        city: 'Cottbus',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15973,
        name: 'Sparkasse Niederlausitz',
        bic: 'WELADED1OSL',
        blz: '18055000',
        city: 'Senftenberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15961,
        name: 'Stadtsparkasse Schwedt',
        bic: 'WELADED1UMX',
        blz: '17052302',
        city: 'Schwedt/Oder',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15921,
        name: 'Sparkasse Mecklenburg-Schwerin',
        bic: 'NOLADE21LWL',
        blz: '14052000',
        city: 'Schwerin',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15933,
        name: 'Sparkasse Mecklenburg-Strelitz',
        bic: 'NOLADE21MST',
        blz: '15051732',
        city: 'Neustrelitz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15982,
        name: 'Jyske Bank Fil Hamburg',
        bic: 'JYBADEHHXXX',
        blz: '20020500',
        city: 'Hamburg',
        available: false
    },
    {
        id: 15983,
        name: 'Signal Iduna Bauspar',
        bic: 'SIBSDEHHXXX',
        blz: '20020900',
        city: 'Hamburg',
        available: false
    },
    {
        id: 15984,
        name: 'DONNER & REUSCHEL',
        bic: 'CHDBDEHHXXX',
        blz: '20030300',
        city: 'Hamburg',
        logoPath: '67.svg',
        available: true
    },
    {
        id: 15985,
        name: 'Marcard, Stein & Co Bankiers',
        bic: 'MCRDDEHHXXX',
        blz: '20030400',
        city: 'Hamburg',
        available: true
    },
    {
        id: 15986,
        name: 'Sydbank Fil Hamburg',
        bic: 'SYBKDE22HAM',
        blz: '20030600',
        city: 'Hamburg',
        logoPath: '173.svg',
        available: true
    },
    {
        id: 15987,
        name: 'Merck Finck Privatbankiers',
        bic: 'MEFIDEMM200',
        blz: '20030700',
        city: 'Hamburg',
        available: true
    },
    {
        id: 15988,
        name: 'Bankhaus Wölbern & Co',
        bic: 'WOELDEHHXXX',
        blz: '20030900',
        available: false
    },
    {
        id: 15990,
        name: 'Commerzbank GF RME',
        bic: 'COBADEFFXXX',
        blz: '20040040',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15991,
        name: 'Commerzbank GF-H48',
        bic: 'COBADEFFXXX',
        blz: '20040048',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16000,
        name: 'DZ BANK',
        bic: 'GENODEFF200',
        blz: '20060000',
        city: 'Hamburg',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 16001,
        name: 'Norderstedter Bank',
        bic: 'GENODEF1NDR',
        blz: '20069111',
        city: 'Norderstedt',
        available: true
    },
    {
        id: 16002,
        name: 'Kaltenkirchener Bank',
        bic: 'GENODEF1KLK',
        blz: '20069125',
        city: 'Kaltenkirchen',
        available: true
    },
    {
        id: 16013,
        name: 'Spar- und Kreditbank',
        bic: 'GENODEF1HAA',
        blz: '20069800',
        city: 'Hammah',
        logoPath: '170.svg',
        available: true
    },
    {
        id: 15992,
        name: 'Commerzbank GF COC',
        bic: 'COBADEFFXXX',
        blz: '20040050',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16036,
        name: 'DZ HYP',
        bic: 'DGHYDEH1XXX',
        blz: '20090400',
        city: 'Hamburg',
        logoPath: '71.svg',
        available: true
    },
    {
        id: 16037,
        name: 'Augsburger Aktienbank (netbank)',
        bic: 'AUGBDE71NET',
        blz: '20090500',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16038,
        name: 'Edekabank',
        bic: 'EDEKDEHHXXX',
        blz: '20090700',
        city: 'Hamburg',
        logoPath: '78.svg',
        available: true
    },
    {
        id: 16039,
        name: 'EBANK Gf Cash',
        bic: 'EDEKDEHHXXX',
        blz: '20090745',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16041,
        name: 'Eurohypo ehem Deutsche Hypothekenbank Hamburg',
        blz: '20110401',
        logoPath: '184.svg',
        available: false
    },
    {
        id: 16042,
        name: 'MUFG Bank (Europe) Germany Branch',
        bic: 'BOTKDEH1XXX',
        blz: '20110700',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16043,
        name: 'Bank of China Fil Hamburg',
        bic: 'BKCHDEFFHMB',
        blz: '20110800',
        city: 'Hamburg',
        logoPath: '54.svg',
        available: false
    },
    {
        id: 16044,
        name: 'Joh. Berenberg, Gossler & Co',
        bic: 'BEGODEHHXXX',
        blz: '20120000',
        city: 'Hamburg',
        available: true
    },
    {
        id: 15993,
        name: 'Commerzbank Gf 260',
        bic: 'COBADEFFXXX',
        blz: '20040060',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15994,
        name: 'Commerzbank Gf 261',
        bic: 'COBADEFFXXX',
        blz: '20040061',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15995,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '20040062',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15996,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '20040063',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16003,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1BBR',
        blz: '20069130',
        city: 'Bad Bramstedt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16004,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1SST',
        blz: '20069144',
        city: 'Seestermühe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16005,
        name: 'Raiffeisenbank Südstormarn Mölln',
        bic: 'GENODEF1GRS',
        blz: '20069177',
        city: 'Mölln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16006,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1STV',
        blz: '20069232',
        city: 'Struvenhütten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16007,
        name: 'Volksbank',
        blz: '20069625',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16008,
        name: 'Raiffeisenbank Owschlag',
        bic: 'GENODEF1OWS',
        blz: '20069641',
        city: 'Owschlag',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16009,
        name: 'Volksbank',
        blz: '20069659',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16010,
        name: 'Volksbank Ahlerstedt',
        bic: 'GENODEF1AST',
        blz: '20069780',
        city: 'Ahlerstedt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16011,
        name: 'Volksbank Geest',
        bic: 'GENODEF1APE',
        blz: '20069782',
        city: 'Apensen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16012,
        name: 'Volksbank Kehdingen Zndl. der Ostfriesischen Volksbank',
        bic: 'GENODEF1DRO',
        blz: '20069786',
        city: 'Drochtersen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16014,
        name: 'Volksbank Fredenbeck',
        bic: 'GENODEF1FRB',
        blz: '20069812',
        city: 'Fredenbeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16015,
        name: 'Volksbank',
        bic: 'GENODEF1815',
        blz: '20069815',
        city: 'Oldendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16016,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1RRZ',
        blz: '20069861',
        city: 'Ratzeburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17340,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3BXXX',
        blz: '48070020',
        city: 'Bielefeld',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16018,
        name: 'Volksbank Winsener Marsch',
        bic: 'GENODEF1WIM',
        blz: '20069965',
        city: 'Marschacht',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16019,
        name: 'Volksbank Wulfsen',
        bic: 'GENODEF1WUL',
        blz: '20069989',
        city: 'Wulfsen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16020,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHHXXX',
        blz: '20070000',
        city: 'Hamburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16021,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBHAM',
        blz: '20070024',
        city: 'Hamburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16022,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF200',
        blz: '20080000',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16023,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF207',
        blz: '20080055',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16024,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF208',
        blz: '20080057',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16025,
        name: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 2',
        bic: 'DRESDEFFI56',
        blz: '20080085',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16026,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
        bic: 'DRESDEFFI63',
        blz: '20080086',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16027,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFI64',
        blz: '20080087',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16029,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
        bic: 'DRESDEFFI75',
        blz: '20080089',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16030,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
        bic: 'DRESDEFFJ33',
        blz: '20080091',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16031,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
        bic: 'DRESDEFFJ34',
        blz: '20080092',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16032,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
        bic: 'DRESDEFFJ35',
        blz: '20080093',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16033,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
        bic: 'DRESDEFFJ36',
        blz: '20080094',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16034,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
        bic: 'DRESDEFFJ37',
        blz: '20080095',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16040,
        name: 'PSD Bank Nord',
        bic: 'GENODEF1P08',
        blz: '20090900',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16035,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI06',
        blz: '20089200',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15998,
        name: 'Hamburg Commercial Bank (ehem. HSH Nordbank Hamburg)',
        bic: 'HSHNDEHHXXX',
        blz: '20050000',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16045,
        name: 'M.M.Warburg & CO',
        bic: 'WBWCDEHHXXX',
        blz: '20120100',
        city: 'Hamburg',
        logoPath: '97.svg',
        available: true
    },
    {
        id: 16046,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF200',
        blz: '20120200',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16047,
        name: 'start:bausparkasse',
        bic: 'DRBKDEH1XXX',
        blz: '20120400',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16048,
        name: 'Goyer & Göppel',
        bic: 'GOGODEH1XXX',
        blz: '20120600',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16049,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEHHXXX',
        blz: '20120700',
        city: 'Hamburg',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 16050,
        name: 'Hanseatic Bank Zw Nord',
        bic: 'HSTBDEH1OV1',
        blz: '20120701',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 16051,
        name: 'Hanseatic Bank Filiale Ost',
        bic: 'HSTBDEH1NKO',
        blz: '20120744',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 16052,
        name: 'Hanseatic Bank Filiale Süd',
        bic: 'HSTBDEH1NKS',
        blz: '20120766',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 16053,
        name: 'GRENKE BANK',
        bic: 'GREBDEH1XXX',
        blz: '20130400',
        city: 'Baden-Baden',
        available: true
    },
    {
        id: 16054,
        name: 'GRENKE BANK Asset Backed Securities',
        blz: '20130412',
        available: false
    },
    {
        id: 16055,
        name: 'Barclaycard (Eurowings / Web.de / GMX / Amway / O2)',
        bic: 'BARCDEHAXXX',
        blz: '20130600',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16059,
        name: 'Vierländer Volksbank',
        bic: 'GENODEF1HH3',
        blz: '20190301',
        city: 'Hamburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16060,
        name: 'MKB Mittelstandskreditbank',
        bic: 'GENODEF1MKB',
        blz: '20190800',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16061,
        name: 'Bank Melli Iran',
        bic: 'MELIDEHHXXX',
        blz: '20210200',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16062,
        name: 'Bank Saderat Iran',
        bic: 'SIHRDEH1HAM',
        blz: '20210300',
        city: 'Hamburg',
        logoPath: '51.svg',
        available: false
    },
    {
        id: 16063,
        name: 'DNB Bank ASA - Filiale Deutschland',
        bic: 'DNBADEHXXXX',
        blz: '20220100',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16064,
        name: 'Warburg, M.M. - Hypothekenbank',
        bic: 'MMWHDEH1XXX',
        blz: '20220400',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16065,
        name: 'Otto M. Schröder Bank',
        bic: 'OSCBDEH1XXX',
        blz: '20230300',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16066,
        name: 'Isbank Hamburg',
        bic: 'ISBKDEFXXXX',
        blz: '20230600',
        city: 'Hamburg',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 16067,
        name: 'Max Heinr. Sutor',
        bic: 'MHSBDEHBXXX',
        blz: '20230800',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16068,
        name: 'Europäisch-Iranische Handelsbank',
        bic: 'EIHBDEHHXXX',
        blz: '20310300',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16069,
        name: 'The Royal Bank of Scotland, Niederlassung Deutschland',
        bic: 'ABNADEFFHAM',
        blz: '20310600',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 16070,
        name: 'Danske Bank',
        bic: 'DABADEHHXXX',
        blz: '20320500',
        city: 'Hamburg',
        logoPath: '72.svg',
        available: false
    },
    {
        id: 16071,
        name: 'Danske Bank Zweigniederlassung Hamburg',
        blz: '20320585',
        logoPath: '72.svg',
        available: false
    },
    {
        id: 16072,
        name: 'WestLB Hamburg',
        bic: 'WELADEHHXXX',
        blz: '20350000',
        logoPath: '159.svg',
        available: false
    },
    {
        id: 16073,
        name: 'Sparda-Bank Hamburg',
        bic: 'GENODEF1S11',
        blz: '20690500',
        city: 'Hamburg',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 16074,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM314',
        blz: '20730000',
        available: false
    },
    {
        id: 16075,
        name: 'UniCredit Bank - HVB Settlement EAC01',
        bic: 'HYVEDEMME01',
        blz: '20730001',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16076,
        name: 'UniCredit Bank - HVB Settlement EAC02',
        bic: 'HYVEDEMME02',
        blz: '20730002',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16077,
        name: 'UniCredit Bank - HVB Settlement EAC03',
        bic: 'HYVEDEMME03',
        blz: '20730003',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16078,
        name: 'UniCredit Bank - HVB Settlement EAC04',
        bic: 'HYVEDEMME04',
        blz: '20730004',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16079,
        name: 'UniCredit Bank - HVB Settlement EAC05',
        bic: 'HYVEDEMME05',
        blz: '20730005',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16080,
        name: 'UniCredit Bank - HVB Settlement EAC06',
        bic: 'HYVEDEMME06',
        blz: '20730006',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16081,
        name: 'UniCredit Bank - HVB Settlement EAC07',
        bic: 'HYVEDEMME07',
        blz: '20730007',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16082,
        name: 'UniCredit Bank - HVB Settlement EAC08',
        bic: 'HYVEDEMME08',
        blz: '20730008',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16083,
        name: 'UniCredit Bank - HVB Settlement EAC09',
        bic: 'HYVEDEMME09',
        blz: '20730009',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16084,
        name: 'UniCredit Bank - HVB Settlement EAC10',
        bic: 'HYVEDEMME10',
        blz: '20730010',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16085,
        name: 'UniCredit Bank - HVB Settlement EAC11',
        bic: 'HYVEDEMME11',
        blz: '20730011',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16086,
        name: 'UniCredit Bank - HVB Settlement EAC12',
        bic: 'HYVEDEMME12',
        blz: '20730012',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16087,
        name: 'UniCredit Bank - HVB Settlement EAC13',
        bic: 'HYVEDEMME13',
        blz: '20730013',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16088,
        name: 'UniCredit Bank - HVB Settlement EAC14',
        bic: 'HYVEDEMME14',
        blz: '20730014',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16089,
        name: 'UniCredit Bank - HVB Settlement EAC15',
        bic: 'HYVEDEMME15',
        blz: '20730015',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16090,
        name: 'UniCredit Bank - HVB Settlement EAC16',
        bic: 'HYVEDEMME16',
        blz: '20730016',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16091,
        name: 'UniCredit Bank - HVB Settlement EAC17',
        bic: 'HYVEDEMME17',
        blz: '20730017',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16092,
        name: 'UniCredit Bank - HVB Settlement EAC18',
        bic: 'HYVEDEMME18',
        blz: '20730018',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16093,
        name: 'UniCredit Bank - HVB Settlement EAC19',
        bic: 'HYVEDEMME19',
        blz: '20730019',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16094,
        name: 'UniCredit Bank - HVB Settlement EAC20',
        bic: 'HYVEDEMME20',
        blz: '20730020',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16095,
        name: 'UniCredit Bank - HVB Settlement EAC21',
        bic: 'HYVEDEMME21',
        blz: '20730021',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16096,
        name: 'UniCredit Bank - HVB Settlement EAC22',
        bic: 'HYVEDEMME22',
        blz: '20730022',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16097,
        name: 'UniCredit Bank - HVB Settlement EAC23',
        bic: 'HYVEDEMME23',
        blz: '20730023',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16098,
        name: 'UniCredit Bank - HVB Settlement EAC24',
        bic: 'HYVEDEMME24',
        blz: '20730024',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16099,
        name: 'UniCredit Bank - HVB Settlement EAC25',
        bic: 'HYVEDEMME25',
        blz: '20730025',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16100,
        name: 'UniCredit Bank - HVB Settlement EAC26',
        bic: 'HYVEDEMME26',
        blz: '20730026',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16101,
        name: 'UniCredit Bank - HVB Settlement EAC27',
        bic: 'HYVEDEMME27',
        blz: '20730027',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16102,
        name: 'UniCredit Bank - HVB Settlement EAC28',
        bic: 'HYVEDEMME28',
        blz: '20730028',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16103,
        name: 'UniCredit Bank - HVB Settlement EAC29',
        bic: 'HYVEDEMME29',
        blz: '20730029',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16104,
        name: 'UniCredit Bank - HVB Settlement EAC30',
        bic: 'HYVEDEMME30',
        blz: '20730030',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16105,
        name: 'UniCredit Bank - HVB Settlement EAC31',
        bic: 'HYVEDEMME31',
        blz: '20730031',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16106,
        name: 'UniCredit Bank - HVB Settlement EAC32',
        bic: 'HYVEDEMME32',
        blz: '20730032',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16057,
        name: 'Volksbank VBS',
        bic: 'GENODEF1HH4',
        blz: '20190109',
        city: 'Bad Oldesloe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16058,
        name: 'Volksbank Hamburg Ost-West -alt-',
        bic: 'GENODEF1HH1',
        blz: '20190206',
        city: 'Hamburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16107,
        name: 'UniCredit Bank - HVB Settlement EAC33',
        bic: 'HYVEDEMME33',
        blz: '20730033',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16108,
        name: 'UniCredit Bank - HVB Settlement EAC34',
        bic: 'HYVEDEMME34',
        blz: '20730034',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16109,
        name: 'UniCredit Bank - HVB Settlement EAC35',
        bic: 'HYVEDEMME35',
        blz: '20730035',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16110,
        name: 'UniCredit Bank - HVB Settlement EAC36',
        bic: 'HYVEDEMME36',
        blz: '20730036',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16111,
        name: 'UniCredit Bank - HVB Settlement EAC37',
        bic: 'HYVEDEMME37',
        blz: '20730037',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16112,
        name: 'UniCredit Bank - HVB Settlement EAC38',
        bic: 'HYVEDEMME38',
        blz: '20730038',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16113,
        name: 'UniCredit Bank - HVB Settlement EAC39',
        bic: 'HYVEDEMME39',
        blz: '20730039',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16114,
        name: 'UniCredit Bank - HVB Settlement EAC40',
        bic: 'HYVEDEMME40',
        blz: '20730040',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 16115,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM316',
        blz: '20730051',
        available: false
    },
    {
        id: 16116,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM330',
        blz: '20730053',
        available: false
    },
    {
        id: 16117,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM324',
        blz: '20730054',
        available: false
    },
    {
        id: 16138,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI07',
        blz: '21089201',
        city: 'Kiel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16119,
        name: 'Bundesbank eh Kiel',
        bic: 'MARKDEF1210',
        blz: '21000000',
        city: 'Hamburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16120,
        name: 'SEB',
        bic: 'ESSEDE5F210',
        blz: '21010111',
        city: 'Kiel',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16121,
        name: 'Sydbank Filiale Kiel',
        bic: 'SYBKDE22KIE',
        blz: '21020600',
        city: 'Kiel',
        logoPath: '173.svg',
        available: true
    },
    {
        id: 16122,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM372',
        blz: '21030000',
        available: false
    },
    {
        id: 16123,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM373',
        blz: '21030092',
        available: false
    },
    {
        id: 16124,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM379',
        blz: '21030093',
        available: false
    },
    {
        id: 16125,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM382',
        blz: '21030094',
        available: false
    },
    {
        id: 16126,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM375',
        blz: '21030095',
        available: false
    },
    {
        id: 16129,
        name: 'Hamburg Commercial Bank (ehem. HSH Nordbank)',
        bic: 'HSHNDEHHXXX',
        blz: '21050000',
        city: 'Hamburg',
        available: true
    },
    {
        id: 16139,
        name: 'Kieler Volksbank',
        bic: 'GENODEF1KIL',
        blz: '21090007',
        city: 'Kiel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16141,
        name: 'Eckernförder Bank Volksbank-Raiffeisenbank',
        bic: 'GENODEF1EFO',
        blz: '21092023',
        city: 'Eckernförde',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16142,
        name: 'SEB',
        bic: 'ESSEDE5F212',
        blz: '21210111',
        city: 'Neumünster',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16143,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM380',
        blz: '21230085',
        available: false
    },
    {
        id: 16144,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM384',
        blz: '21230086',
        available: false
    },
    {
        id: 16148,
        name: 'Raiffbk Kl-Kummerfeld -alt-',
        bic: 'GENODEF1BOO',
        blz: '21261227',
        city: 'Groß Kummerfeld',
        available: true
    },
    {
        id: 16157,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM387',
        blz: '21430070',
        available: false
    },
    {
        id: 16166,
        name: 'Bundesbank',
        bic: 'MARKDEF1215',
        blz: '21500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16167,
        name: 'Sydbank Filiale Flensburg',
        bic: 'SYBKDE22XXX',
        blz: '21510600',
        city: 'Flensburg',
        logoPath: '173.svg',
        available: true
    },
    {
        id: 16168,
        name: 'Union-Bank Flensburg',
        bic: 'UNBNDE21XXX',
        blz: '21520100',
        city: 'Flensburg',
        logoPath: '162.svg',
        available: true
    },
    {
        id: 16145,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21240040',
        city: 'Neumünster',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16146,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21241540',
        city: 'Bad Bramstedt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16147,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1WAS',
        blz: '21261089',
        city: 'Wasbek',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16149,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHH212',
        blz: '21270020',
        city: 'Neumünster',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16151,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF212',
        blz: '21280002',
        city: 'Neumünster',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16152,
        name: 'VR Bank Neumünster',
        bic: 'GENODEF1NMS',
        blz: '21290016',
        city: 'Neumünster',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16153,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21340010',
        city: 'Neustadt in Holstein',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16160,
        name: 'Sparkasse Büdelsdorf -alt-',
        bic: 'NOLADE21BDF',
        blz: '21451205',
        city: 'Büdelsdorf',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17341,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBBIE',
        blz: '48070024',
        city: 'Bielefeld',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16155,
        name: 'VR Bank Ostholstein Nord-Plön',
        bic: 'GENODEF1NSH',
        blz: '21390008',
        city: 'Neustadt in Holstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16156,
        name: 'Volksbank Eutin Raiffeisenbank',
        bic: 'GENODEF1EUT',
        blz: '21392218',
        city: 'Eutin',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16158,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21440045',
        city: 'Rendsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16161,
        name: 'Sparkasse Hohenwestedt',
        bic: 'NOLADE21HWS',
        blz: '21452030',
        city: 'Hohenwestedt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16130,
        name: 'Förde Sparkasse',
        bic: 'NOLADE21KIE',
        blz: '21050170',
        city: 'Kiel',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16131,
        name: 'Bordesholmer Sparkasse',
        bic: 'NOLADE21BOR',
        blz: '21051275',
        city: 'Bordesholm',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16162,
        name: 'Raiffeisenbank Jevenstedt -alt-',
        blz: '21461627',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16163,
        name: 'Volksbank-Raiffeisenbank im Kreis Rendsburg',
        bic: 'GENODEF1NTO',
        blz: '21463603',
        city: 'Rendsburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16164,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1TOB',
        blz: '21464671',
        city: 'Todenbüttel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16165,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF214',
        blz: '21480003',
        city: 'Rendsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16127,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21040010',
        city: 'Kiel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16128,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21042076',
        city: 'Eckernförde',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16132,
        name: 'Sparkasse Kreis Plön -alt-',
        bic: 'NOLADE21PLN',
        blz: '21051580',
        city: 'Plön',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16154,
        name: 'Sparkasse Holstein',
        bic: 'NOLADE21HOL',
        blz: '21352240',
        city: 'Eutin',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16159,
        name: 'Sparkasse Mittelholstein',
        bic: 'NOLADE21RDB',
        blz: '21450000',
        city: 'Rendsburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16134,
        name: 'Raiffeisenbank im Kreis Plön -alt-',
        blz: '21064045',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16135,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHH210',
        blz: '21070020',
        city: 'Kiel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16140,
        name: 'PSD Bank Kiel',
        bic: 'GENODEF1P11',
        blz: '21090900',
        city: 'Kiel',
        available: true
    },
    {
        id: 16137,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF210',
        blz: '21080050',
        city: 'Kiel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16169,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM334',
        blz: '21530080',
        available: false
    },
    {
        id: 16213,
        name: 'Raiffeisenbank Heide',
        bic: 'GENODEF1RHE',
        blz: '21860418',
        city: 'Heide',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16214,
        name: 'Dithmarscher Volks- und Raiffeisenbank',
        bic: 'GENODEF1DVR',
        blz: '21890022',
        city: 'Heide',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16178,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM393',
        blz: '21630060',
        available: false
    },
    {
        id: 16179,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM394',
        blz: '21630061',
        available: false
    },
    {
        id: 16180,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM395',
        blz: '21630062',
        available: false
    },
    {
        id: 16181,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM397',
        blz: '21630063',
        available: false
    },
    {
        id: 16185,
        name: 'Bundesbank eh Husum',
        bic: 'MARKDEF1217',
        blz: '21700000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16186,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM348',
        blz: '21730040',
        available: false
    },
    {
        id: 16187,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM353',
        blz: '21730042',
        available: false
    },
    {
        id: 16188,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM355',
        blz: '21730043',
        available: false
    },
    {
        id: 16189,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM358',
        blz: '21730044',
        available: false
    },
    {
        id: 16190,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM359',
        blz: '21730045',
        available: false
    },
    {
        id: 16191,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM361',
        blz: '21730046',
        available: false
    },
    {
        id: 16196,
        name: 'Spar- und Leihkasse zu Bredstedt',
        bic: 'NOLADE21BRD',
        blz: '21751230',
        city: 'Bredstedt',
        available: false
    },
    {
        id: 16201,
        name: 'Sylter Bank',
        bic: 'GENODEF1SYL',
        blz: '21791805',
        city: 'Sylt',
        available: true
    },
    {
        id: 16202,
        name: 'Föhr-Amrumer Bank',
        bic: 'GENODEF1WYK',
        blz: '21791906',
        city: 'Wyk auf Föhr',
        available: true
    },
    {
        id: 16203,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM340',
        blz: '21830030',
        available: false
    },
    {
        id: 16204,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM341',
        blz: '21830032',
        available: false
    },
    {
        id: 16205,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM342',
        blz: '21830033',
        available: false
    },
    {
        id: 16206,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM343',
        blz: '21830034',
        available: false
    },
    {
        id: 16207,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM346',
        blz: '21830035',
        available: false
    },
    {
        id: 16215,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM331',
        blz: '22130075',
        available: false
    },
    {
        id: 16227,
        name: 'Bundesbank eh Itzehoe',
        bic: 'MARKDEF1222',
        blz: '22200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16228,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM370',
        blz: '22230020',
        available: false
    },
    {
        id: 16229,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM363',
        blz: '22230022',
        available: false
    },
    {
        id: 16230,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM367',
        blz: '22230023',
        available: false
    },
    {
        id: 16231,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM369',
        blz: '22230025',
        available: false
    },
    {
        id: 16216,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '22140028',
        city: 'Elmshorn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16219,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '22141628',
        city: 'Uetersen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16182,
        name: 'Sparkasse Schleswig-Flensburg -alt-',
        bic: 'NOLADE21SLN',
        blz: '21650110',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16222,
        name: 'Raiffeisenbank Elbmarsch',
        bic: 'GENODEF1HTE',
        blz: '22163114',
        city: 'Heist',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16193,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21741674',
        city: 'Niebüll',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16223,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF221',
        blz: '22180000',
        city: 'Elmshorn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16194,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21741825',
        city: 'Sylt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16224,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF206',
        blz: '22181400',
        city: 'Pinneberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16210,
        name: 'Alte Marner Sparkasse -alt-',
        bic: 'NOLADE21MRN',
        blz: '21851720',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16175,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHH215',
        blz: '21570011',
        city: 'Flensburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16197,
        name: 'VR Bank Westküste',
        bic: 'GENODEF1HUM',
        blz: '21762550',
        city: 'Husum',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16177,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF215',
        blz: '21580000',
        city: 'Flensburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16198,
        name: 'VR Bank Nord',
        bic: 'GENODEF1BDS',
        blz: '21763542',
        city: 'Niebüll',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16212,
        name: 'Sparkasse Hennstedt-Wesselburen',
        bic: 'NOLADE21WEB',
        blz: '21852310',
        city: 'Wesselburen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16199,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHH217',
        blz: '21770011',
        city: 'Husum',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16183,
        name: 'VR Bank Flensburg-Schleswig',
        bic: 'GENODEF1RSL',
        blz: '21661719',
        city: 'Schleswig',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16200,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB217',
        blz: '21770024',
        city: 'Husum',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16184,
        name: 'Schleswiger Volksbank, Volksbank Raiffeisenbank',
        bic: 'GENODEF1SLW',
        blz: '21690020',
        city: 'Schleswig',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16208,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21840078',
        city: 'Heide',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16192,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21740043',
        city: 'Husum',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16209,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21841328',
        city: 'Brunsbüttel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16211,
        name: 'Verbandssparkasse Meldorf -alt-',
        bic: 'NOLADE21MLD',
        blz: '21851830',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16217,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '22141028',
        city: 'Kaltenkirchen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16195,
        name: 'Nord-Ostsee Sparkasse',
        bic: 'NOLADE21NOS',
        blz: '21750000',
        city: 'Schleswig',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16221,
        name: 'Stadtsparkasse Wedel',
        bic: 'NOLADE21WED',
        blz: '22151730',
        city: 'Wedel',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16220,
        name: 'Sparkasse Elmshorn',
        bic: 'NOLADE21ELH',
        blz: '22150000',
        city: 'Elmshorn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16218,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '22141428',
        city: 'Pinneberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16242,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '23040022',
        city: 'Lübeck',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16247,
        name: 'Kreissparkasse Herzogtum Lauenburg',
        bic: 'NOLADE21RZB',
        blz: '23052750',
        city: 'Ratzeburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16262,
        name: 'Sparkasse Lüneburg',
        bic: 'NOLADE21LBG',
        blz: '24050110',
        city: 'Lüneburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16239,
        name: 'Bundesbank eh Lübeck',
        bic: 'MARKDEF1230',
        blz: '23000000',
        city: 'Hamburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16240,
        name: 'SEB',
        bic: 'ESSEDE5F230',
        blz: '23010111',
        city: 'Lübeck',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16241,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM237',
        blz: '23030000',
        available: false
    },
    {
        id: 16259,
        name: 'Bundesbank eh Lüneburg',
        bic: 'MARKDEF1240',
        blz: '24000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16260,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM318',
        blz: '24030000',
        available: false
    },
    {
        id: 16269,
        name: 'Ritterschaftliches Kreditinstitut Stade',
        bic: 'GENODED1RKI',
        blz: '24121000',
        city: 'Stade',
        available: true
    },
    {
        id: 16270,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM327',
        blz: '24130000',
        available: false
    },
    {
        id: 16277,
        name: 'Spar- u Darlehnskasse Börde Lamstedt-Hechthausen',
        bic: 'GENODEF1LAS',
        blz: '24162898',
        city: 'Lamstedt',
        available: true
    },
    {
        id: 16282,
        name: 'Bundesbank',
        bic: 'MARKDEF1250',
        blz: '25000000',
        city: 'Hannover',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16283,
        name: 'SEB',
        bic: 'ESSEDE5F250',
        blz: '25010111',
        city: 'Hannover',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16284,
        name: 'Aareal Bank',
        bic: 'AARBDE5W250',
        blz: '25010424',
        city: 'Hannover',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 16285,
        name: 'Deutsche Hypothekenbank',
        bic: 'DEHYDE2HXXX',
        blz: '25010600',
        city: 'Hannover',
        logoPath: '184.svg',
        available: false
    },
    {
        id: 16286,
        name: 'Berlin-Hannoversche Hypothekenbank',
        bic: 'BHYPDEB1HAN',
        blz: '25010700',
        available: false
    },
    {
        id: 16287,
        name: 'Calenberger Kreditverein',
        bic: 'CKVHDE21XXX',
        blz: '25010900',
        city: 'Hannover',
        available: true
    },
    {
        id: 16288,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF250',
        blz: '25020200',
        city: 'Hannover',
        available: true
    },
    {
        id: 16289,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1HAN',
        blz: '25020700',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 16290,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM210',
        blz: '25030000',
        available: false
    },
    {
        id: 16294,
        name: 'Norddeutsche Landesbank Girozentrale',
        bic: 'NOLADE2HXXX',
        blz: '25050000',
        city: 'Hannover',
        logoPath: '178.svg',
        available: true
    },
    {
        id: 16295,
        name: 'ZVA Norddeutsche Landesbank SH',
        bic: 'NOLADE21CSH',
        blz: '25050055',
        city: 'Hannover',
        available: false
    },
    {
        id: 16273,
        name: 'Sparkasse Stade-Altes Land',
        bic: 'NOLADE21STS',
        blz: '24151005',
        city: 'Stade',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16248,
        name: 'Raiffeisenbank Leezen',
        bic: 'GENODEF1LZN',
        blz: '23061220',
        city: 'Leezen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16249,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1BAR',
        blz: '23062124',
        city: 'Bargteheide',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16250,
        name: 'Volks- und Raiffeisenbank Mölln -alt-',
        bic: 'GENODEF1MOE',
        blz: '23062807',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16251,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1RLB',
        blz: '23063129',
        city: 'Lauenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16252,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1BCH',
        blz: '23064107',
        city: 'Büchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16253,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB237',
        blz: '23070700',
        city: 'Lübeck',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16254,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHH222',
        blz: '23070710',
        city: 'Lübeck',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16255,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF230',
        blz: '23080040',
        city: 'Lübeck',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16256,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI08',
        blz: '23089201',
        city: 'Lübeck',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16257,
        name: 'Volksbank Lübeck',
        bic: 'GENODEF1HLU',
        blz: '23090142',
        city: 'Lübeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16258,
        name: 'Volksbank Lauenburg -alt-',
        bic: 'GENODEF1VLB',
        blz: '23092502',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16261,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '24040000',
        city: 'Lüneburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16275,
        name: 'Sparkasse Rotenburg Osterholz',
        bic: 'BRLADE21ROB',
        blz: '24151235',
        city: 'Zeven',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16263,
        name: 'Volksbank Lüneburger Heide',
        bic: 'GENODEF1NBU',
        blz: '24060300',
        city: 'Winsen (Luhe)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16264,
        name: 'Volksbank Bleckede-Dahlenburg -alt-',
        bic: 'GENODEF1DAB',
        blz: '24061392',
        city: 'Dahlenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16265,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB240',
        blz: '24070024',
        city: 'Lüneburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16266,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H240',
        blz: '24070075',
        city: 'Lüneburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16267,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF240',
        blz: '24080000',
        city: 'Lüneburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16268,
        name: 'Volksbank Lüneburg -alt-',
        bic: 'GENODEF1LUE',
        blz: '24090041',
        city: 'Lüneburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16271,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '24140041',
        city: 'Cuxhaven',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16233,
        name: 'Sparkasse Westholstein',
        bic: 'NOLADE21WHO',
        blz: '22250020',
        city: 'Itzehoe',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16234,
        name: 'Landsparkasse Schenefeld',
        bic: 'NOLADE21SHF',
        blz: '22251580',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16276,
        name: 'Zevener Volksbank',
        bic: 'GENODEF1SIT',
        blz: '24161594',
        city: 'Zeven',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16278,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF242',
        blz: '24180000',
        city: 'Otterndorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16279,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF241',
        blz: '24180001',
        city: 'Cuxhaven',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16280,
        name: 'Volksbank Stade-Cuxhaven',
        bic: 'GENODEF1SDE',
        blz: '24191015',
        city: 'Stade',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16281,
        name: 'Volksbank Bremervörde',
        blz: '24191255',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16291,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '25040060',
        city: 'Hannover',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16292,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '25040061',
        city: 'Hannover',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16293,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25040066',
        city: 'Hannover',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16245,
        name: 'Sparkasse Südholstein',
        bic: 'NOLADE21SHO',
        blz: '23051030',
        city: 'Neumünster',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16235,
        name: 'Verbandssparkasse Wilster -alt-',
        blz: '22252050',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16238,
        name: 'Volksbank Raiffeisenbank Itzehoe Norderstedt Hohenwestedt',
        bic: 'GENODEF1VIT',
        blz: '22290031',
        city: 'Itzehoe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16244,
        name: 'Sparkasse zu Lübeck',
        bic: 'NOLADE21SPL',
        blz: '23050101',
        city: 'Lübeck',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16272,
        name: 'Stadtsparkasse Cuxhaven',
        bic: 'BRLADE21CUX',
        blz: '24150001',
        city: 'Cuxhaven',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16243,
        name: 'Hamburg Commercial Bank (ehem. HSH Nordbank Hamburg)',
        bic: 'HSHNDEHH230',
        blz: '23050000',
        city: 'Hamburg',
        available: false
    },
    {
        id: 16274,
        name: 'Kreissparkasse Stade',
        bic: 'NOLADE21STK',
        blz: '24151116',
        city: 'Stade',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16246,
        name: 'Sparkasse Stormarn -alt-',
        bic: 'NOLADE21STO',
        blz: '23051610',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16356,
        name: 'Volksbank Nordschaumburg -alt-',
        blz: '25562308',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16299,
        name: 'DZ BANK',
        bic: 'GENODEFF250',
        blz: '25060000',
        city: 'Hannover',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 16300,
        name: 'Bankhaus Hallbaum',
        bic: 'HALLDE2HXXX',
        blz: '25060180',
        city: 'Hannover',
        available: true
    },
    {
        id: 16301,
        name: 'Evangelische Kreditgenossenschaft -Filiale Hannover-',
        bic: 'GENODEF1EK3',
        blz: '25060701',
        available: false
    },
    {
        id: 16357,
        name: 'Volksbank Kirchhorsten -alt-',
        blz: '25562604',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16358,
        name: 'Volksbank in Schaumburg',
        bic: 'GENODEF1BCK',
        blz: '25591413',
        city: 'Rinteln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16318,
        name: 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
        bic: 'GENODEF1BFS',
        blz: '25090300',
        city: 'Hannover',
        available: true
    },
    {
        id: 16319,
        name: 'Sparda-Bank Hannover',
        bic: 'GENODEF1S09',
        blz: '25090500',
        city: 'Hannover',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 16321,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33HAN',
        blz: '25120510',
        city: 'Hannover',
        available: false
    },
    {
        id: 16334,
        name: 'Bundesbank eh Hameln',
        bic: 'MARKDEF1254',
        blz: '25400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16335,
        name: 'SEB',
        bic: 'ESSEDE5F254',
        blz: '25410111',
        city: 'Hameln',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16336,
        name: 'BHW Bausparkasse',
        bic: 'BHWBDE2HXXX',
        blz: '25410200',
        city: 'Hameln',
        logoPath: '46.svg',
        available: true
    },
    {
        id: 16337,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM217',
        blz: '25430000',
        available: false
    },
    {
        id: 16342,
        name: 'Sparkasse Weserbergland',
        bic: 'NOLADE21BOW',
        blz: '25451450',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16302,
        name: 'Volks- und Raiffeisenbank Leinebergland',
        bic: 'GENODEF1DES',
        blz: '25069168',
        city: 'Delligsen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16303,
        name: 'Raiffeisen-Volksbank Neustadt',
        bic: 'GENODEF1NST',
        blz: '25069262',
        city: 'Neustadt am Rübenberge',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16304,
        name: 'Volksbank Aller-Oker',
        bic: 'GENODEF1MUA',
        blz: '25069270',
        city: 'Müden (Aller)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16305,
        name: 'Volksbank Vechelde-Wendeburg',
        bic: 'GENODEF1WBU',
        blz: '25069370',
        city: 'Vechelde',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16306,
        name: 'Volksbank Lehre -alt-',
        blz: '25069384',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16307,
        name: 'Volksbank Diepholz-Barnstorf',
        bic: 'GENODEF1BNT',
        blz: '25069503',
        city: 'Diepholz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16308,
        name: 'Volksbank Derental -alt-',
        blz: '25069830',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16310,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H265',
        blz: '25070066',
        city: 'Stadthagen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16311,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2HXXX',
        blz: '25070070',
        city: 'Hannover',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16312,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H256',
        blz: '25070077',
        city: 'Nienburg (Weser)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16313,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H258',
        blz: '25070084',
        city: 'Soltau',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16314,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H251',
        blz: '25070086',
        city: 'Holzminden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16315,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF250',
        blz: '25080020',
        city: 'Hannover',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16317,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI09',
        blz: '25089220',
        city: 'Hannover',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16326,
        name: 'Volksbank Burgdorf-Celle -alt-',
        blz: '25161322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16327,
        name: 'Volksbank Garbsen -alt-',
        blz: '25162563',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16328,
        name: 'Hannoversche Volksbank',
        bic: 'VOHADE2HXXX',
        blz: '25190001',
        city: 'Hannover',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16329,
        name: 'Lindener Volksbank -alt-',
        blz: '25190101',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16330,
        name: 'Volksbank -alt-',
        blz: '25191510',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16331,
        name: 'Volksbank',
        bic: 'GENODEF1PAT',
        blz: '25193331',
        city: 'Pattensen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17342,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B480',
        blz: '48070040',
        city: 'Gütersloh',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16333,
        name: 'Volksbank Peine',
        bic: 'GENODEF1PEV',
        blz: '25260010',
        city: 'Peine',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16338,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25440047',
        city: 'Hameln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16343,
        name: 'Sparkasse Weserbergland',
        bic: 'NOLADE21HEO',
        blz: '25451655',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16355,
        name: 'Sparkasse Schaumburg',
        bic: 'NOLADE21SHG',
        blz: '25551480',
        city: 'Rinteln',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16298,
        name: 'LBS-Norddeutsche Landesbausparkasse',
        bic: 'NOLADE21LBS',
        blz: '25055500',
        city: 'Hannover',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16344,
        name: 'Volksbank Hameln-Stadthagen',
        bic: 'GENODEF1HMP',
        blz: '25462160',
        city: 'Hameln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16345,
        name: 'Volksbank im Wesertal',
        bic: 'GENODEF1COP',
        blz: '25462680',
        city: 'Coppenbrügge',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16346,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB254',
        blz: '25470024',
        city: 'Hameln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16347,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H254',
        blz: '25470073',
        city: 'Hameln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16348,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB264',
        blz: '25471024',
        city: 'Rinteln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16349,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H264',
        blz: '25471073',
        city: 'Rinteln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16350,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF254',
        blz: '25480021',
        city: 'Hameln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16351,
        name: 'Volksbank Bückeburg-Rinteln ehem VB Rinteln',
        blz: '25491071',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16352,
        name: 'Volksbank Aerzen',
        bic: 'GENODED1AEZ',
        blz: '25491273',
        city: 'Aerzen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16353,
        name: 'Volksbank Bad Münder',
        bic: 'GENODED1BMU',
        blz: '25491744',
        city: 'Bad Münder am Deister',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16354,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25541426',
        city: 'Bückeburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16320,
        name: 'PSD Bank',
        bic: 'GENODEF1P09',
        blz: '25090900',
        city: 'Hannover',
        available: true
    },
    {
        id: 16340,
        name: 'Sparkasse Hameln-Weserbergland',
        bic: 'NOLADE21SWB',
        blz: '25450110',
        city: 'Hameln',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16322,
        name: 'Stadtsparkasse Barsinghausen',
        bic: 'NOLADE21BAH',
        blz: '25151270',
        city: 'Barsinghausen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16323,
        name: 'Stadtsparkasse Burgdorf',
        bic: 'NOLADE21BUF',
        blz: '25151371',
        city: 'Burgdorf',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16324,
        name: 'Kreissparkasse Fallingbostel in Walsrode',
        bic: 'NOLADE21WAL',
        blz: '25152375',
        city: 'Walsrode',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16325,
        name: 'Stadtsparkasse Wunstorf',
        bic: 'NOLADE21WST',
        blz: '25152490',
        city: 'Wunstorf',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16332,
        name: 'Kreissparkasse Peine',
        bic: 'NOLADE21PEI',
        blz: '25250001',
        city: 'Peine',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16339,
        name: 'Stadtsparkasse Hameln',
        bic: 'NOLADE21HMS',
        blz: '25450001',
        city: 'Hameln',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16341,
        name: 'Stadtsparkasse Bad Pyrmont',
        bic: 'NOLADE21PMT',
        blz: '25451345',
        city: 'Bad Pyrmont',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16297,
        name: 'Sparkasse Hannover -alt-',
        blz: '25050299',
        city: 'Hannover',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16416,
        name: 'VR-Bank in Südniedersachsen',
        bic: 'GENODEF1DRA',
        blz: '26062433',
        city: 'Dransfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16360,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '25621327',
        city: 'Diepholz',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 16417,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1RDO',
        blz: '26062575',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16368,
        name: 'Bundesbank eh Celle',
        bic: 'MARKDEF1257',
        blz: '25700000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16369,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM211',
        blz: '25730000',
        available: false
    },
    {
        id: 16378,
        name: 'Bundesbank eh Uelzen',
        bic: 'MARKDEF1258',
        blz: '25800000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16391,
        name: 'Bundesbank eh Hildesheim',
        bic: 'MARKDEF1259',
        blz: '25900000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16392,
        name: 'SEB',
        bic: 'ESSEDE5F259',
        blz: '25910111',
        city: 'Hildesheim',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16393,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM214',
        blz: '25930000',
        available: false
    },
    {id: 16397, name: 'Bankhaus Hallbaum', blz: '25960192', available: false},
    {
        id: 16418,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB260',
        blz: '26070024',
        city: 'Göttingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16406,
        name: 'Bundesbank',
        bic: 'MARKDEF1260',
        blz: '26000000',
        city: 'Göttingen',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16407,
        name: 'SEB',
        bic: 'ESSEDE5F260',
        blz: '26010111',
        city: 'Göttingen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16408,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM213',
        blz: '26030000',
        available: false
    },
    {id: 16413, name: 'Bankhaus Hallbaum', blz: '26060184', available: false},
    {
        id: 16419,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H260',
        blz: '26070072',
        city: 'Göttingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16420,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF260',
        blz: '26080024',
        city: 'Göttingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16361,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25641302',
        city: 'Diepholz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16364,
        name: 'Volksbank',
        bic: 'GENODEF1STY',
        blz: '25662540',
        city: 'Steyerberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16365,
        name: 'Volksbank Aller-Weser',
        bic: 'GENODEF1HOY',
        blz: '25663584',
        city: 'Hoya',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16366,
        name: 'Volksbank Nienburg',
        bic: 'GENODEF1NIN',
        blz: '25690009',
        city: 'Nienburg (Weser)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16367,
        name: 'Volksbank Sulingen',
        bic: 'GENODEF1SUL',
        blz: '25691633',
        city: 'Sulingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16370,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25740061',
        city: 'Celle',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16383,
        name: 'Sparkasse Uelzen Lüchow-Dannenberg -alt-',
        bic: 'NOLADE21DAN',
        blz: '25851335',
        city: 'Dannenberg (Elbe)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16372,
        name: 'Volksbank Wittingen-Klötze',
        bic: 'GENODEF1WIK',
        blz: '25761894',
        city: 'Wittingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16373,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB257',
        blz: '25770024',
        city: 'Celle',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16374,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H257',
        blz: '25770069',
        city: 'Celle',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16375,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF257',
        blz: '25780022',
        city: 'Celle',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16376,
        name: 'Volksbank Hankensbüttel-Wahrenholz',
        bic: 'GENODEF1HKB',
        blz: '25791516',
        city: 'Hankensbüttel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16377,
        name: 'Volksbank Südheide - Isenhagener Land - Altmark',
        bic: 'GENODEF1HMN',
        blz: '25791635',
        city: 'Celle',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16379,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25840048',
        city: 'Uelzen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16380,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25841403',
        city: 'Lüchow (Wendland)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16381,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25841708',
        city: 'Schneverdingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16396,
        name: 'Sparkasse Hildesheim Goslar Peine',
        bic: 'NOLADE21HIK',
        blz: '25950130',
        city: 'Hildesheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16410,
        name: 'Sparkasse Göttingen',
        bic: 'NOLADE21GOE',
        blz: '26050001',
        city: 'Göttingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16385,
        name: 'Volksbank Dannenberg -alt-',
        bic: 'GENODEF1DAN',
        blz: '25861395',
        city: 'Dannenberg (Elbe)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16386,
        name: 'Volksbank Clenze-Hitzacker',
        bic: 'GENODEF1CLZ',
        blz: '25861990',
        city: 'Clenze',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16387,
        name: 'Volksbank Uelzen-Salzwedel',
        bic: 'GENODEF1EUB',
        blz: '25862292',
        city: 'Uelzen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16388,
        name: 'VR PLUS Altmark-Wendland',
        bic: 'GENODEF1WOT',
        blz: '25863489',
        city: 'Dannenberg (Elbe)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16389,
        name: 'Volksbank Osterburg-Lüchow-Dannenberg -alt-',
        bic: 'GENODEF1LCH',
        blz: '25891483',
        city: 'Lüchow (Wendland)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16390,
        name: 'Volksbank Lüneburger Heide -alt-',
        bic: 'GENODEF1SOL',
        blz: '25891636',
        city: 'Soltau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16394,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '25940033',
        city: 'Hildesheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16411,
        name: 'Sparkasse Duderstadt',
        bic: 'NOLADE21DUD',
        blz: '26051260',
        city: 'Duderstadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16399,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H259',
        blz: '25970074',
        city: 'Hildesheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16400,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB253',
        blz: '25971024',
        city: 'Alfeld (Leine)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16401,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H253',
        blz: '25971071',
        city: 'Alfeld (Leine)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16402,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF259',
        blz: '25980027',
        city: 'Hildesheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16403,
        name: 'Volksbank Hildesheim',
        bic: 'GENODEF1HIH',
        blz: '25990011',
        city: 'Hildesheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16404,
        name: 'Volksbank Hildesheimer Börde',
        bic: 'GENODEF1SLD',
        blz: '25991528',
        city: 'Söhlde',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16405,
        name: 'Volksbank Sarstedt -alt-',
        bic: 'GENODEF1SAR',
        blz: '25991911',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16409,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26040030',
        city: 'Göttingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16363,
        name: 'Kreissparkasse Grafschaft Diepholz',
        bic: 'BRLADE21DHZ',
        blz: '25651325',
        city: 'Diepholz',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16382,
        name: 'Sparkasse Uelzen Lüchow-Dannenberg',
        bic: 'NOLADE21UEL',
        blz: '25850110',
        city: 'Uelzen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16414,
        name: 'Volksbank Mitte',
        bic: 'GENODEF1DUD',
        blz: '26061291',
        city: 'Duderstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20079,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39130',
        available: false
    },
    {
        id: 16415,
        name: 'Volksbank',
        bic: 'GENODEF1ADE',
        blz: '26061556',
        city: 'Adelebsen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16384,
        name: 'Kreissparkasse Soltau',
        bic: 'NOLADE21SOL',
        blz: '25851660',
        city: 'Soltau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16395,
        name: 'Stadtsparkasse Hildesheim -alt-',
        bic: 'NOLADE21HIS',
        blz: '25950001',
        city: 'Hildesheim',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16412,
        name: 'Kreis- und Stadtsparkasse Münden',
        bic: 'NOLADE21HMU',
        blz: '26051450',
        city: 'Hann. Münden',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16371,
        name: 'Sparkasse Celle',
        bic: 'NOLADE21CEL',
        blz: '25750001',
        city: 'Celle',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16434,
        name: 'Stadtsparkasse Osterode',
        bic: 'NOLADE21OHA',
        blz: '26350001',
        city: 'Osterode am Harz',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16424,
        name: 'Sparkasse Einbeck',
        bic: 'NOLADE21EIN',
        blz: '26251425',
        city: 'Einbeck',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16438,
        name: 'Bundesbank',
        bic: 'MARKDEF1265',
        blz: '26500000',
        city: 'Osnabrück',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16439,
        name: 'SEB',
        bic: 'ESSEDE5F265',
        blz: '26510111',
        city: 'Osnabrück',
        logoPath: '114.svg',
        available: true
    },
    {id: 16444, name: 'Bankhaus Hallbaum', blz: '26560189', available: false},
    {
        id: 16456,
        name: 'Bundesbank eh Lingen (Ems)',
        bic: 'MARKDEF1266',
        blz: '26600000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16475,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB268',
        blz: '26870024',
        city: 'Goslar',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16470,
        name: 'Bundesbank eh Halberstadt',
        bic: 'MARKDEF1268',
        blz: '26800000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16476,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H268',
        blz: '26870032',
        city: 'Goslar',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16477,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF268',
        blz: '26880063',
        city: 'Goslar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16478,
        name: 'Volksbank Nordharz',
        bic: 'GENODEF1VNH',
        blz: '26890019',
        city: 'Goslar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16479,
        name: 'Volksbank im Harz',
        bic: 'GENODEF1OHA',
        blz: '26891484',
        city: 'Osterode am Harz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16480,
        name: 'Commerzbank Wolfsburg',
        bic: 'COBADEFFXXX',
        blz: '26941053',
        city: 'Wolfsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16435,
        name: 'Sparkasse Osterode am Harz',
        bic: 'NOLADE21HZB',
        blz: '26351015',
        city: 'Osterode am Harz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16605,
        name: 'Volksbank Westrhauderfehn',
        bic: 'GENODEF1WRH',
        blz: '28591654',
        city: 'Rhauderfehn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16422,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26240039',
        city: 'Northeim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16441,
        name: 'Sparkasse Osnabrück',
        bic: 'NOLADE22XXX',
        blz: '26550105',
        city: 'Osnabrück',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16425,
        name: 'Volksbank Dassel',
        bic: 'GENODEF1VDA',
        blz: '26261396',
        city: 'Dassel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16426,
        name: 'Volksbank Einbeck',
        bic: 'GENODEF1EIN',
        blz: '26261492',
        city: 'Einbeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16427,
        name: 'Volksbank Solling',
        bic: 'GENODEF1HDG',
        blz: '26261693',
        city: 'Hardegsen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16428,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB262',
        blz: '26271424',
        city: 'Einbeck',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16429,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H262',
        blz: '26271471',
        city: 'Einbeck',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16430,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF261',
        blz: '26280020',
        city: 'Northeim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16431,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF262',
        blz: '26281420',
        city: 'Einbeck',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16432,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26340056',
        city: 'Osterode am Harz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16433,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26341072',
        city: 'Herzberg am Harz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16458,
        name: 'Sparkasse Emsland',
        bic: 'NOLADE21EMS',
        blz: '26650001',
        city: 'Meppen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16437,
        name: 'Volksbank Oberharz',
        bic: 'GENODEF1GOH',
        blz: '26361299',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16440,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26540070',
        city: 'Osnabrück',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16472,
        name: 'Sparkasse Goslar/Harz',
        bic: 'NOLADE21GSL',
        blz: '26850001',
        city: 'Goslar',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16445,
        name: 'Volksbank Bad Laer-Borgloh-Hilter-Melle',
        bic: 'GENODEF1HTR',
        blz: '26562490',
        city: 'Hilter am Teutoburger Wald',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16446,
        name: 'Volksbank Wittlage -alt-',
        bic: 'GENODEF1WTB',
        blz: '26562694',
        city: 'Bohmte',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16447,
        name: 'Volksbank Bramgau-Wittlage',
        bic: 'GENODEF1WHO',
        blz: '26563960',
        city: 'Bramsche',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16448,
        name: 'Volksbank GMHütte-Hagen-Bissendorf',
        bic: 'GENODEF1HGM',
        blz: '26565928',
        city: 'Georgsmarienhütte',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16449,
        name: 'Volksbank Osnabrücker Nordland',
        bic: 'GENODEF1MRZ',
        blz: '26566939',
        city: 'Fürstenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16450,
        name: 'VR-Bank Osnabrücker Nordland',
        bic: 'GENODEF1NOP',
        blz: '26567943',
        city: 'Neuenkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16451,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB265',
        blz: '26570024',
        city: 'Osnabrück',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16452,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B265',
        blz: '26570090',
        city: 'Osnabrück',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16453,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF265',
        blz: '26580070',
        city: 'Osnabrück',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16454,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI10',
        blz: '26589210',
        city: 'Osnabrück',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16455,
        name: 'Volksbank Osnabrück',
        bic: 'GENODEF1OSV',
        blz: '26590025',
        city: 'Osnabrück',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16457,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26640049',
        city: 'Lingen (Ems)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16474,
        name: 'Sparkasse Salzgitter',
        bic: 'NOLADE21SZG',
        blz: '26851620',
        city: 'Salzgitter',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16459,
        name: 'Volksbank Lingen',
        bic: 'GENODEF1LIG',
        blz: '26660060',
        city: 'Lingen (Ems)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16460,
        name: 'Volksbank Haselünne',
        bic: 'GENODEF1HLN',
        blz: '26661380',
        city: 'Haselünne',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16461,
        name: 'Emsländische Volksbank Meppen',
        bic: 'GENODEF1MEP',
        blz: '26661494',
        city: 'Meppen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16462,
        name: 'Volksbank Süd-Emsland -alt-',
        blz: '26661912',
        city: 'Emsbüren',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16463,
        name: 'Volksbank',
        bic: 'GENODEF1LEN',
        blz: '26662932',
        city: 'Lengerich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16465,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26740044',
        city: 'Nordhorn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16467,
        name: 'Raiffeisen- und Volksbank Nordhorn -alt-',
        bic: 'GENODEF1NDH',
        blz: '26760005',
        city: 'Nordhorn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16468,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB267',
        blz: '26770024',
        city: 'Nordhorn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16469,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B267',
        blz: '26770095',
        city: 'Nordhorn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16436,
        name: 'Stadtsparkasse Bad Sachsa',
        bic: 'NOLADE21SAC',
        blz: '26351445',
        city: 'Bad Sachsa',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16471,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '26840032',
        city: 'Goslar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16442,
        name: 'Kreissparkasse Bersenbrück',
        bic: 'NOLADE21BEB',
        blz: '26551540',
        city: 'Bersenbrück',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16443,
        name: 'Kreissparkasse Melle',
        bic: 'NOLADE21MEL',
        blz: '26552286',
        city: 'Melle',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16466,
        name: 'Kreissparkasse Grafschaft Bentheim zu Nordhorn',
        bic: 'NOLADE21NOH',
        blz: '26750001',
        city: 'Nordhorn',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16473,
        name: 'Kreissparkasse Clausthal-Zellerfeld',
        bic: 'NOLADE21CLZ',
        blz: '26851410',
        city: 'Clausthal-Zellerfeld',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16481,
        name: 'Sparkasse Celle-Gifhorn-Wolfsburg',
        bic: 'NOLADE21GFW',
        blz: '26951311',
        city: 'Gifhorn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16538,
        name: 'Volksbank Bösel',
        bic: 'GENODEF1BSL',
        blz: '28062913',
        city: 'Bösel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16487,
        name: 'Bundesbank eh Braunschweig',
        bic: 'MARKDEF1270',
        blz: '27000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16488,
        name: 'SEB',
        bic: 'ESSEDE5F270',
        blz: '27010111',
        city: 'Braunschweig',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16489,
        name: 'BNP Paribas Ndl. Deutschland (ehem. VON ESSEN Bank)',
        bic: 'VONEDE33BRA',
        blz: '27010200',
        city: 'Braunschweig',
        available: false
    },
    {
        id: 16490,
        name: 'Volkswagen Bank (VW-Bank)',
        bic: 'VOWADE2BXXX',
        blz: '27020000',
        city: 'Braunschweig',
        available: true
    },
    {
        id: 16491,
        name: 'Audi Bank Zndl d Volkswagen Bank',
        bic: 'AUDFDE21XXX',
        blz: '27020001',
        city: 'Braunschweig',
        available: true
    },
    {
        id: 16492,
        name: 'Skoda Bank',
        bic: 'SKODDE21XXX',
        blz: '27020003',
        city: 'Braunschweig',
        available: false
    },
    {
        id: 16493,
        name: 'AutoEuropa Bank',
        bic: 'ECBKDE21XXX',
        blz: '27020004',
        city: 'Braunschweig',
        available: false
    },
    {
        id: 16494,
        name: 'Seat Bank Zndl d Volkswagen Bank',
        bic: 'SEATDE21XXX',
        blz: '27020800',
        city: 'Braunschweig',
        available: false
    },
    {
        id: 16495,
        name: 'UniCredit Bank - HypoVereinsbank (ex VereinWest)',
        bic: 'HYVEDEMM212',
        blz: '27030000',
        available: false
    },
    {
        id: 16496,
        name: 'Bankhaus C. L. Seeliger',
        bic: 'BCLSDE21XXX',
        blz: '27032500',
        city: 'Wolfenbüttel',
        available: true
    },
    {
        id: 16539,
        name: 'Volksbank Westerstede',
        bic: 'GENODEF1WRE',
        blz: '28063253',
        city: 'Westerstede',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16516,
        name: 'Bankhaus Rautenschlein',
        bic: 'GENODEF1RTS',
        blz: '27131300',
        city: 'Schöningen',
        available: true
    },
    {
        id: 16523,
        name: 'Bundesbank',
        bic: 'MARKDEF1280',
        blz: '28000000',
        city: 'Oldenburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16524,
        name: 'SEB',
        bic: 'ESSEDE5F280',
        blz: '28010111',
        city: 'Oldenburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16525,
        name: 'Oldenburgische Landesbank (vormals W. Fortmann & Söhne)',
        bic: 'FORTDEH4XXX',
        blz: '28030300',
        city: 'Oldenburg',
        logoPath: '104.svg',
        available: true
    },
    {
        id: 16540,
        name: 'Volksbank Essen-Cappeln',
        bic: 'GENODEF1ESO',
        blz: '28063526',
        city: 'Cappeln (Oldenburg)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16541,
        name: 'Volksbank Bakum',
        bic: 'GENODEF1BAM',
        blz: '28063607',
        city: 'Bakum',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16542,
        name: 'VR Bank Dinklage-Steinfeld -alt-',
        bic: 'GENODEF1SEI',
        blz: '28064090',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16543,
        name: 'Volksbank Vechta',
        bic: 'GENODEF1VEC',
        blz: '28064179',
        city: 'Vechta',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16483,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H269',
        blz: '26971038',
        city: 'Wolfsburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16484,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF269',
        blz: '26981062',
        city: 'Wolfsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16485,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI11',
        blz: '26989221',
        city: 'Wolfsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16486,
        name: 'Volksbank Braunschweig Wolfsburg',
        bic: 'GENODEF1WOB',
        blz: '26991066',
        city: 'Wolfsburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16497,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '27040080',
        city: 'Braunschweig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16498,
        name: 'Volksbank Börßum-Hornburg',
        bic: 'GENODEF1BOH',
        blz: '27062290',
        city: 'Börßum',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16499,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB270',
        blz: '27070024',
        city: 'Braunschweig',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16500,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H270',
        blz: '27070030',
        city: 'Braunschweig',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16501,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H271',
        blz: '27070031',
        city: 'Gifhorn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16502,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H274',
        blz: '27070034',
        city: 'Osterode am Harz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16503,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H279',
        blz: '27070041',
        city: 'Bad Sachsa',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16504,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H272',
        blz: '27070042',
        city: 'Bad Harzburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16505,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H273',
        blz: '27070043',
        city: 'Helmstedt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16506,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H275',
        blz: '27070079',
        city: 'Peine',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16508,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H277',
        blz: '27072537',
        city: 'Wolfenbüttel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16509,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB276',
        blz: '27072724',
        city: 'Salzgitter',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16510,
        name: 'Deutsche Bank',
        bic: 'DEUTDE2H276',
        blz: '27072736',
        city: 'Salzgitter',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16511,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF270',
        blz: '27080060',
        city: 'Braunschweig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16512,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI12',
        blz: '27089221',
        city: 'Braunschweig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16513,
        name: 'Volksbank Braunschweig -alt-',
        bic: 'GENODEF1BSG',
        blz: '27090077',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16515,
        name: 'Volksbank',
        bic: 'GENODEF1WFV',
        blz: '27092555',
        city: 'Wolfenbüttel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16517,
        name: 'Volksbank Helmstedt',
        bic: 'GENODEF1HMS',
        blz: '27190082',
        city: 'Helmstedt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16518,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '27240004',
        city: 'Holzminden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16519,
        name: 'Volksbank Weserbergland',
        bic: 'GENODEF1HMV',
        blz: '27290087',
        city: 'Holzminden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16520,
        name: 'Vereinigte Volksbank',
        bic: 'GENODEF1BHA',
        blz: '27893215',
        city: 'Wernigerode',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16521,
        name: 'Volksbank Braunlage',
        bic: 'GENODEF1BLG',
        blz: '27893359',
        city: 'Braunlage',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16522,
        name: 'Volksbank',
        bic: 'GENODEF1SES',
        blz: '27893760',
        city: 'Seesen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16526,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '28040046',
        city: 'Oldenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16527,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '28042865',
        city: 'Vechta',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16529,
        name: 'Raiffeisenbank Oldenburg',
        bic: 'GENODEF1OL2',
        blz: '28060228',
        city: 'Oldenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16530,
        name: 'Raiffeisenbank Wesermarsch-Süd',
        bic: 'GENODEF1BRN',
        blz: '28061410',
        city: 'Brake (Unterweser)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16531,
        name: 'VR-Bank in Südoldenburg',
        bic: 'GENODEF1CLP',
        blz: '28061501',
        city: 'Cloppenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16532,
        name: 'Volksbank Dammer Berge',
        bic: 'GENODEF1DAM',
        blz: '28061679',
        city: 'Damme',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16533,
        name: 'Volksbank Oldenburg',
        bic: 'GENODEF1EDE',
        blz: '28061822',
        city: 'Oldenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16534,
        name: 'Raiffeisenbank Rastede',
        bic: 'GENODEF1RSE',
        blz: '28062165',
        city: 'Rastede',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16535,
        name: 'Vereinigte Volksbank',
        bic: 'GENODEF1HUD',
        blz: '28062249',
        city: 'Hude',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16536,
        name: 'Volksbank Lohne-Mühlen',
        bic: 'GENODEF1LON',
        blz: '28062560',
        city: 'Lohne (Oldenburg)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16514,
        name: 'PSD Bank',
        bic: 'GENODEF1P02',
        blz: '27090900',
        city: 'Braunschweig',
        available: true
    },
    {
        id: 16537,
        name: 'Volksbank Bookholzberg-Lemwerder',
        bic: 'GENODEF1GBH',
        blz: '28062740',
        city: 'Ganderkesee',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16528,
        name: 'Landessparkasse zu Oldenburg',
        bic: 'SLZODE22XXX',
        blz: '28050100',
        city: 'Oldenburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16587,
        name: 'Volksbank Esens',
        bic: 'GENODEF1ESE',
        blz: '28291551',
        city: 'Esens',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16550,
        name: 'Spar- und Darlehnskasse Friesoythe',
        bic: 'GENODEF1FOY',
        blz: '28066620',
        city: 'Friesoythe',
        available: true
    },
    {
        id: 16596,
        name: 'Sparkasse LeerWittmund',
        bic: 'BRLADE21LER',
        blz: '28550000',
        city: 'Leer (Ostfriesland)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16575,
        name: 'Bundesbank eh Wilhelmshaven',
        bic: 'MARKDEF1282',
        blz: '28200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16594,
        name: 'Bundesbank eh Leer',
        bic: 'MARKDEF1285',
        blz: '28500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16589,
        name: 'Raiffeisen-Volksbank Fresena',
        bic: 'GENODEF1MAR',
        blz: '28361592',
        city: 'Norden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16590,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '28440037',
        city: 'Emden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16577,
        name: 'Sparkasse Wilhelmshaven',
        bic: 'BRLADE21WHV',
        blz: '28250110',
        city: 'Wilhelmshaven',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16592,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB284',
        blz: '28470024',
        city: 'Emden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16593,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB284',
        blz: '28470091',
        city: 'Emden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16595,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '28540034',
        city: 'Leer (Ostfriesland)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16588,
        name: 'Sparkasse Aurich-Norden',
        bic: 'BRLADE21ANO',
        blz: '28350000',
        city: 'Aurich',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16597,
        name: 'Raiffeisen-Volksbank',
        bic: 'GENODEF1UPL',
        blz: '28562297',
        city: 'Aurich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16604,
        name: 'Volksbank Papenburg Fil d. Ostfries. VB Leer',
        bic: 'GENODEF1PAP',
        blz: '28591579',
        city: 'Papenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16545,
        name: 'Volksbank Löningen',
        bic: 'GENODEF1LOG',
        blz: '28065061',
        city: 'Löningen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16546,
        name: 'VR-Bank Dinklage-Steinfeld',
        bic: 'GENODEF1DIK',
        blz: '28065108',
        city: 'Dinklage',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16547,
        name: 'Raiffeisenbank Scharrel',
        bic: 'GENODEF1SAN',
        blz: '28065286',
        city: 'Saterland',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16548,
        name: 'Volksbank Visbek',
        bic: 'GENODEF1VIS',
        blz: '28066103',
        city: 'Visbek',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16549,
        name: 'Volksbank Wildeshauser Geest',
        bic: 'GENODEF1WDH',
        blz: '28066214',
        city: 'Wildeshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16551,
        name: 'Volksbank Neuenkirchen-Vörden',
        bic: 'GENODEF1NEO',
        blz: '28067068',
        city: 'Neuenkirchen-Vörden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16552,
        name: 'Volksbank Delmenhorst Schierbrok',
        bic: 'GENODEF1GSC',
        blz: '28067170',
        city: 'Delmenhorst',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16553,
        name: 'Volksbank Lastrup',
        bic: 'GENODEF1LAP',
        blz: '28067257',
        city: 'Lastrup',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16554,
        name: 'Raiffeisenbank Butjadingen-Abbehausen',
        bic: 'GENODEF1BUT',
        blz: '28068218',
        city: 'Butjadingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16555,
        name: 'Raiffeisenbank Strücklingen-Idafehn',
        bic: 'GENODEF1ORF',
        blz: '28069052',
        city: 'Ostrhauderfehn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16556,
        name: 'VR Bank Oldenburg Land West',
        bic: 'GENODEF1HAT',
        blz: '28069092',
        city: 'Hatten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16557,
        name: 'Volksbank Emstek',
        bic: 'GENODEF1EMK',
        blz: '28069109',
        city: 'Emstek',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16558,
        name: 'Raiffeisenbank Garrel',
        bic: 'GENODEF1GRR',
        blz: '28069128',
        city: 'Garrel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16559,
        name: 'VR Bank Oldenburg Land West',
        bic: 'GENODEF1VAG',
        blz: '28069138',
        city: 'Hatten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16560,
        name: 'Volksbank Obergrafschaft -alt-',
        bic: 'GENODEF1BBH',
        blz: '28069293',
        city: 'Schüttorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16561,
        name: 'Hümmlinger Volksbank',
        bic: 'GENODEF1WLT',
        blz: '28069381',
        city: 'Werlte',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16562,
        name: 'Volksbank Nordhümmling',
        bic: 'GENODEF1BOG',
        blz: '28069706',
        city: 'Börger',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16563,
        name: 'Raiffeisenbank Oldersum',
        bic: 'GENODEF1MLO',
        blz: '28069755',
        city: 'Moormerland',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16565,
        name: 'Raiffeisenbank Emsland-Mitte',
        bic: 'GENODEF1KBL',
        blz: '28069878',
        city: 'Klein Berßen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16566,
        name: 'Volksbank Niedergrafschaft',
        bic: 'GENODEF1HOO',
        blz: '28069926',
        city: 'Uelsen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16567,
        name: 'Volksbank Langen-Gersten',
        bic: 'GENODEF1LAG',
        blz: '28069930',
        city: 'Langen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17343,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B484',
        blz: '48070042',
        city: 'Harsewinkel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16568,
        name: 'Raiffeisenbank Lorup',
        bic: 'GENODEF1LRU',
        blz: '28069935',
        city: 'Lorup',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16569,
        name: 'Volksbank Uelsen',
        bic: 'GENODEF1UEL',
        blz: '28069955',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16570,
        name: 'Grafschafter Volksbank',
        bic: 'GENODEF1NEV',
        blz: '28069956',
        city: 'Nordhorn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16571,
        name: 'Volksbank Emstal',
        bic: 'GENODEF1LTH',
        blz: '28069991',
        city: 'Lathen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16572,
        name: 'Volksbank Süd-Emsland',
        bic: 'GENODEF1SPL',
        blz: '28069994',
        city: 'Spelle',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16573,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB280',
        blz: '28070024',
        city: 'Oldenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16574,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB280',
        blz: '28070057',
        city: 'Oldenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16576,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '28240023',
        city: 'Wilhelmshaven',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16578,
        name: 'Kreissparkasse Wittmund',
        bic: 'BRLADE21WTM',
        blz: '28252760',
        city: 'Wittmund',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16579,
        name: 'Raiffeisenbank Sande-Wangerland',
        blz: '28261946',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16580,
        name: 'Volksbank Jever',
        bic: 'GENODEF1JEV',
        blz: '28262254',
        city: 'Jever',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16581,
        name: 'Raiffeisenbank Sande-Wangerland -alt-',
        blz: '28262481',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16582,
        name: 'Raiffeisen-Volksbank Varel-Nordenham',
        bic: 'GENODEF1VAR',
        blz: '28262673',
        city: 'Varel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16583,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB282',
        blz: '28270024',
        city: 'Wilhelmshaven',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16584,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB282',
        blz: '28270056',
        city: 'Wilhelmshaven',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16585,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF282',
        blz: '28280012',
        city: 'Wilhelmshaven',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16586,
        name: 'Volksbank Wilhelmshaven',
        bic: 'GENODEF1WHV',
        blz: '28290063',
        city: 'Wilhelmshaven',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16591,
        name: 'Sparkasse Emden',
        bic: 'BRLADE21EMD',
        blz: '28450000',
        city: 'Emden',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16606,
        name: 'Bundesbank eh Bremen',
        bic: 'MARKDEF1290',
        blz: '29000000',
        city: 'Hannover',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16607,
        name: 'SEB',
        bic: 'ESSEDE5F290',
        blz: '29010111',
        city: 'Bremen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16608,
        name: 'Deutsche Schiffsbank',
        bic: 'COBADEFFDSB',
        blz: '29010400',
        city: 'Bremen',
        available: false
    },
    {
        id: 16609,
        name: 'Bankhaus Neelmeyer',
        bic: 'NEELDE22XXX',
        blz: '29020000',
        city: 'Bremen',
        available: true
    },
    {
        id: 16610,
        name: 'Oldenburgische Landesbank',
        bic: 'BANVDEHBXXX',
        blz: '29020100',
        city: 'Bremen',
        logoPath: '104.svg',
        available: true
    },
    {
        id: 16611,
        name: 'Greensill Bank',
        bic: 'NFHBDE21XXX',
        blz: '29020200',
        city: 'Bremen',
        available: true
    },
    {
        id: 16612,
        name: 'Deutsche Factoring Bank',
        bic: 'DFABDE21XXX',
        blz: '29020400',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 16613,
        name: 'Bankhaus Carl F. Plump & CO',
        bic: 'PLUMDE29XXX',
        blz: '29030400',
        city: 'Bremen',
        available: true
    },
    {
        id: 16626,
        name: 'Commerzbank vormals Bremer Bank (Dresdner Bank) ITGK',
        bic: 'DRESDEFFI13',
        blz: '29089210',
        city: 'Bremen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16617,
        name: 'Norddeutsche Landesbank - Girozentrale -',
        bic: 'BRLADE22XXX',
        blz: '29050000',
        city: 'Bremen',
        logoPath: '178.svg',
        available: true
    },
    {
        id: 16632,
        name: 'Volksbank',
        bic: 'GENODEF1OHZ',
        blz: '29162394',
        city: 'Osterholz-Scharmbeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16643,
        name: 'Bundesbank eh Bremerhaven',
        bic: 'MARKDEF1292',
        blz: '29200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16644,
        name: 'SEB',
        bic: 'ESSEDE5F292',
        blz: '29210111',
        city: 'Bremerhaven',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16652,
        name: 'Bundesbank',
        bic: 'MARKDEF1300',
        blz: '30000000',
        city: 'Düsseldorf',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16653,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F300',
        blz: '30010111',
        city: 'Düsseldorf',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16654,
        name: 'IKB Deutsche Industriebank',
        bic: 'IKBDDEDDXXX',
        blz: '30010400',
        city: 'Düsseldorf',
        logoPath: '87.svg',
        available: false
    },
    {
        id: 16655,
        name: 'IKB Privatkunden - IKB Deutsche Industriebank',
        bic: 'IKBDDEDDDIR',
        blz: '30010444',
        city: 'Düsseldorf',
        logoPath: '87.svg',
        available: false
    },
    {
        id: 16656,
        name: 'MUFG Bank (Europe) Germany Branch',
        bic: 'BOTKDEDXXXX',
        blz: '30010700',
        city: 'Düsseldorf',
        available: true
    },
    {
        id: 16657,
        name: 'Santander Consumer Bank',
        bic: 'CCBADE31RBS',
        blz: '30020300',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 16658,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF300',
        blz: '30020500',
        city: 'Düsseldorf',
        available: true
    },
    {
        id: 16659,
        name: 'Mizuho Bank Filiale Düsseldorf',
        bic: 'MHCBDEDDXXX',
        blz: '30020700',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16660,
        name: 'TARGOBANK',
        bic: 'CMCIDEDDXXX',
        blz: '30020900',
        city: 'Düsseldorf',
        logoPath: '123.svg',
        available: true
    },
    {
        id: 16661,
        name: 'NRW.BANK',
        bic: 'NRWBDEDMXXX',
        blz: '30022000',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16662,
        name: 'Portigon',
        bic: 'PORTDEDDXXX',
        blz: '30025500',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16663,
        name: 'S Broker Wiesbaden',
        bic: 'PULSDEDDXXX',
        blz: '30030100',
        city: 'Wiesbaden',
        available: true
    },
    {
        id: 16664,
        name: 'FXdirekt Bank',
        bic: 'FXDBDE31XXX',
        blz: '30030400',
        logoPath: '183.svg',
        available: false
    },
    {
        id: 16665,
        name: 'Bank11direkt',
        bic: 'CUABDED1XXX',
        blz: '30030500',
        city: 'Neuss',
        available: true
    },
    {
        id: 16666,
        name: 'ETRIS Bank',
        bic: 'ETRIDE31XXX',
        blz: '30030600',
        city: 'Wuppertal',
        available: false
    },
    {
        id: 16667,
        name: 'HSBC Trinkaus & Burkhardt',
        bic: 'TUBDDEDDXXX',
        blz: '30030880',
        city: 'Düsseldorf',
        logoPath: '182.svg',
        available: true
    },
    {
        id: 16668,
        name: 'Merck Finck Privatbankiers',
        bic: 'MEFIDEMM300',
        blz: '30030900',
        city: 'Düsseldorf',
        available: true
    },
    {
        id: 16633,
        name: 'Volksbank Schwanewede',
        bic: 'GENODEF1SWW',
        blz: '29162453',
        city: 'Schwanewede',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16634,
        name: 'Volksbank Aller-Weser',
        bic: 'GENODEF1VER',
        blz: '29162697',
        city: 'Verden (Aller)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16635,
        name: 'Volksbank Oyten',
        bic: 'GENODEF1OYT',
        blz: '29165545',
        city: 'Oyten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16636,
        name: 'Volksbank Sottrum',
        bic: 'GENODEF1SUM',
        blz: '29165681',
        city: 'Sottrum',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16637,
        name: 'Volksbank',
        bic: 'GENODEF1WOP',
        blz: '29166568',
        city: 'Worpswede',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16638,
        name: 'Volksbank Syke',
        bic: 'GENODEF1SHR',
        blz: '29167624',
        city: 'Syke',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16639,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB291',
        blz: '29172624',
        city: 'Verden (Aller)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16640,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB291',
        blz: '29172655',
        city: 'Verden (Aller)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16641,
        name: 'Bremische Volksbank',
        bic: 'GENODEF1HB1',
        blz: '29190024',
        city: 'Bremen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16642,
        name: 'Volksbank Bremen-Nord',
        bic: 'GENODEF1HB2',
        blz: '29190330',
        city: 'Bremen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16645,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '29240024',
        city: 'Bremerhaven',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16628,
        name: 'Kreissparkasse Syke',
        bic: 'BRLADE21SYK',
        blz: '29151700',
        city: 'Syke',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16648,
        name: 'Volksbank Geeste-Nord',
        bic: 'GENODEF1BRV',
        blz: '29262722',
        city: 'Geestland',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16649,
        name: 'Volksbank Bremerhaven-Cuxland',
        bic: 'GENODEF1BEV',
        blz: '29265747',
        city: 'Beverstedt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16650,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF292',
        blz: '29280011',
        city: 'Bremerhaven',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16651,
        name: 'Volksbank Bremerhaven-Wesermünde -alt-',
        bic: 'GENODEF1HBV',
        blz: '29290034',
        city: 'Bremerhaven',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16669,
        name: 'Commerzbank',
        bic: 'COBADEDDXXX',
        blz: '30040000',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16670,
        name: 'Commerzbank GF-D48',
        bic: 'COBADEFFXXX',
        blz: '30040048',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16615,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '29040061',
        city: 'Bremen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16616,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '29040090',
        city: 'Bremen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16618,
        name: 'Sparkasse Bremen',
        bic: 'SBREDE22XXX',
        blz: '29050101',
        city: 'Bremen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16620,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHBXXX',
        blz: '29070050',
        city: 'Bremen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16621,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB292',
        blz: '29070051',
        city: 'Bremerhaven',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16622,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB294',
        blz: '29070052',
        city: 'Delmenhorst',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16623,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB293',
        blz: '29070058',
        city: 'Cloppenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16624,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB290',
        blz: '29070059',
        city: 'Vechta',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16627,
        name: 'PSD Bank Nord',
        bic: 'GENODEF1P03',
        blz: '29090900',
        city: 'Bremen',
        available: true
    },
    {
        id: 16625,
        name: 'Commerzbank vormals Bremer Bank (Dresdner Bank)',
        bic: 'DRESDEFF290',
        blz: '29080010',
        city: 'Bremen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16629,
        name: 'Kreissparkasse Osterholz',
        bic: 'BRLADE21OHZ',
        blz: '29152300',
        city: 'Osterholz-Scharmbeck',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16630,
        name: 'Zweckverbandssparkasse Scheeßel',
        bic: 'BRLADE21SHL',
        blz: '29152550',
        city: 'Scheeßel',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16631,
        name: 'Kreissparkasse Verden',
        bic: 'BRLADE21VER',
        blz: '29152670',
        city: 'Verden (Aller)',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16647,
        name: 'Kreissparkasse Wesermünde-Hadeln',
        bic: 'BRLADE21BRK',
        blz: '29250150',
        city: 'Bremerhaven',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16646,
        name: 'Weser-Elbe Sparkasse',
        bic: 'BRLADE21BRS',
        blz: '29250000',
        city: 'Bremerhaven',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16726,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '31040015',
        city: 'Mönchengladbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16675,
        name: 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
        bic: 'WELADEDDXXX',
        blz: '30050000',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16677,
        name: 'DZ BANK',
        bic: 'GENODEDDXXX',
        blz: '30060010',
        city: 'Düsseldorf',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 16678,
        name: 'apoBank',
        bic: 'DAAEDEDDXXX',
        blz: '30060601',
        city: 'Düsseldorf',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 16674,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '30040063',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16672,
        name: 'Commerzbank Gf 661',
        bic: 'COBADEFFXXX',
        blz: '30040061',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16682,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF300',
        blz: '30080000',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16680,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDDXXX',
        blz: '30070010',
        city: 'Düsseldorf',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16681,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBDUE',
        blz: '30070024',
        city: 'Düsseldorf',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17349,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF480',
        blz: '48080020',
        city: 'Bielefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17351,
        name: 'Volksbank Brackwede -alt-',
        bic: 'GENODEM1BRA',
        blz: '48091315',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16703,
        name: 'Sumitomo Mitsui Banking Corporation',
        bic: 'SMBCDEDDXXX',
        blz: '30110300',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16704,
        name: 'The Royal Bank of Scotland, Niederlassung Deutschland',
        bic: 'ABNADEFFDUS',
        blz: '30120400',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 16705,
        name: 'Oldenburgische Landesbank',
        bic: 'BANVDEHB300',
        blz: '30120500',
        city: 'Bremen',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 16706,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 450 Düs',
        bic: 'HYVEDEM1086',
        blz: '30120764',
        available: false
    },
    {
        id: 16707,
        name: 'Demir-Halk Bank (Nederland)',
        bic: 'DHBNDEDDXXX',
        blz: '30130100',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16708,
        name: 'GarantiBank International',
        bic: 'UGBIDEDDXXX',
        blz: '30130200',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16709,
        name: 'Isbank Fil Düsseldorf',
        bic: 'ISBKDEFXDUS',
        blz: '30130600',
        city: 'Düsseldorf',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 16710,
        name: 'Düsseldorfer Hypothekenbank',
        bic: 'DHYPDEDDXXX',
        blz: '30130800',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16711,
        name: 'Helaba Düsseldorf Gf Verrechnung FI-Dus',
        blz: '30150001',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 16714,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM414',
        blz: '30220190',
        city: 'Düsseldorf',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 16716,
        name: 'RCI Banque Niederlassung Deutschland',
        bic: 'RCIDDE3NXXX',
        blz: '30520000',
        city: 'Neuss',
        available: false
    },
    {
        id: 16717,
        name: 'Bankhaus Werhahn',
        bic: 'WERHDED1XXX',
        blz: '30530000',
        city: 'Neuss',
        available: true
    },
    {
        id: 16718,
        name: 'Bank11 für Privatkunden und Handel, Neuss',
        bic: 'WEFZDED1XXX',
        blz: '30530500',
        city: 'Neuss',
        available: true
    },
    {
        id: 16723,
        name: 'Bundesbank eh Mönchengladbach',
        bic: 'MARKDEF1310',
        blz: '31000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16724,
        name: 'SEB',
        bic: 'ESSEDE5F310',
        blz: '31010111',
        city: 'Mönchengladbach',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16725,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '31010833',
        city: 'Mönchengladbach',
        logoPath: '175.svg',
        available: true
    },
    {
        id: 16712,
        name: 'Kreissparkasse Düsseldorf',
        bic: 'WELADED1KSD',
        blz: '30150200',
        city: 'Düsseldorf',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17353,
        name: 'Volksbank Nordlippe -alt-',
        bic: 'GENODEM1NLE',
        blz: '48262248',
        city: 'Extertal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16687,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF316',
        blz: '30080057',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16688,
        name: 'Commerzbank vormals Dresdner Bank Zw 61',
        bic: 'DRESDEFFI31',
        blz: '30080061',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16689,
        name: 'Commerzbank vormals Dresdner Bank Zw 74',
        bic: 'DRESDEFFI32',
        blz: '30080074',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16690,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
        bic: 'DRESDEFFI76',
        blz: '30080080',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16691,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFI77',
        blz: '30080081',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16694,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
        bic: 'DRESDEFFI80',
        blz: '30080084',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16701,
        name: 'Commerzbank vormals Dresdner Bank ITGK I',
        bic: 'DRESDEFFI02',
        blz: '30089300',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16693,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
        bic: 'DRESDEFFI79',
        blz: '30080083',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16713,
        name: 'Volksbank Düsseldorf Neuss',
        bic: 'GENODED1DNE',
        blz: '30160213',
        city: 'Düsseldorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17354,
        name: 'Volksbank Bad Salzuflen',
        bic: 'GENODEM1BSU',
        blz: '48291490',
        city: 'Bad Salzuflen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16715,
        name: 'Stadt-Sparkasse Haan',
        bic: 'WELADED1HAA',
        blz: '30351220',
        city: 'Haan',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16702,
        name: 'Commerzbank vormals Dresdner Bank ITGK II',
        bic: 'DRESDEFFI03',
        blz: '30089302',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17356,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '49040043',
        city: 'Minden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16721,
        name: 'Volksbank Neuss -alt-',
        bic: 'GENODED1NSS',
        blz: '30560090',
        city: 'Neuss',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16676,
        name: 'Stadtsparkasse Düsseldorf',
        bic: 'DUSSDEDDXXX',
        blz: '30050110',
        city: 'Düsseldorf',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16722,
        name: 'VR Bank',
        bic: 'GENODED1NLD',
        blz: '30560548',
        city: 'Dormagen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16727,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '31040060',
        city: 'Mönchengladbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16679,
        name: 'PSD Bank Rhein-Ruhr',
        bic: 'GENODEF1P05',
        blz: '30060992',
        city: 'Düsseldorf',
        available: true
    },
    {
        id: 16720,
        name: 'Stadtsparkasse Kaarst-Büttgen -alt-',
        bic: 'WELADED1KST',
        blz: '30551240',
        city: 'Kaarst',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16728,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '31040061',
        city: 'Mönchengladbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16673,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '30040062',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16729,
        name: 'Stadtsparkasse Mönchengladbach',
        bic: 'MGLSDE33XXX',
        blz: '31050000',
        city: 'Mönchengladbach',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17352,
        name: 'Sparkasse Lemgo',
        bic: 'WELADED1LEM',
        blz: '48250110',
        city: 'Lemgo',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16719,
        name: 'Sparkasse Neuss',
        bic: 'WELADEDNXXX',
        blz: '30550000',
        city: 'Neuss',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16730,
        name: 'Gladbacher Bank von 1922',
        bic: 'GENODED1GBM',
        blz: '31060181',
        city: 'Mönchengladbach',
        available: true
    },
    {
        id: 16746,
        name: 'Sparkasse Krefeld',
        bic: 'SPKRDE33XXX',
        blz: '32050000',
        city: 'Krefeld',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16783,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '34040049',
        city: 'Remscheid',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16744,
        name: 'Bundesbank eh Krefeld',
        bic: 'MARKDEF1320',
        blz: '32000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16756,
        name: 'Bundesbank eh Kleve, Niederrhein',
        bic: 'MARKDEF1324',
        blz: '32400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16762,
        name: 'Bundesbank eh Wuppertal',
        bic: 'MARKDEF1330',
        blz: '33000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16763,
        name: 'SEB',
        bic: 'ESSEDE5F330',
        blz: '33010111',
        city: 'Wuppertal',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16764,
        name: 'akf bank',
        bic: 'AKFBDE31XXX',
        blz: '33020000',
        city: 'Wuppertal',
        available: true
    },
    {
        id: 16765,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM809',
        blz: '33020190',
        city: 'Wuppertal',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 16766,
        name: 'GEFA BANK',
        bic: 'GGABDE31XXX',
        blz: '33030000',
        city: 'Wuppertal',
        available: true
    },
    {
        id: 16771,
        name: 'Sparda-Bank West',
        bic: 'GENODED1SPW',
        blz: '33060592',
        city: 'Wuppertal',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 16747,
        name: 'Sparkasse Geldern -alt-',
        blz: '32051370',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16787,
        name: 'Volksbank im Bergischen Land',
        bic: 'VBRSDE33XXX',
        blz: '34060094',
        city: 'Remscheid',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16789,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDW340',
        blz: '34070093',
        city: 'Remscheid',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16790,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF340',
        blz: '34080031',
        city: 'Remscheid',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16732,
        name: 'Volksbank Brüggen-Nettetal',
        bic: 'GENODED1KBN',
        blz: '31062154',
        city: 'Brüggen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16733,
        name: 'Volksbank Schwalmtal',
        bic: 'GENODED1NKR',
        blz: '31062553',
        city: 'Niederkrüchten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16734,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDD310',
        blz: '31070001',
        city: 'Mönchengladbach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16736,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF310',
        blz: '31080015',
        city: 'Mönchengladbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16737,
        name: 'Commerzbank vormals Dresdner Bank Zw 61',
        bic: 'DRESDEFFI34',
        blz: '31080061',
        city: 'Mönchengladbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16739,
        name: 'Volksbank Erkelenz',
        bic: 'GENODED1EHE',
        blz: '31261282',
        city: 'Erkelenz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16740,
        name: 'Raiffeisenbank Erkelenz',
        bic: 'GENODED1LOE',
        blz: '31263359',
        city: 'Erkelenz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16741,
        name: 'Volksbank Viersen',
        bic: 'GENODED1VSN',
        blz: '31460290',
        city: 'Viersen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16742,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDD314',
        blz: '31470004',
        city: 'Viersen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16743,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB314',
        blz: '31470024',
        city: 'Viersen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16745,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '32040024',
        city: 'Krefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16748,
        name: 'Sparkasse der Stadt Straelen',
        bic: 'WELADED1STR',
        blz: '32051996',
        city: 'Straelen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16758,
        name: 'Sparkasse Rhein-Maas',
        bic: 'WELADED1KLE',
        blz: '32450000',
        city: 'Kleve',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16781,
        name: 'Sparkasse Hilden-Ratingen-Velbert',
        bic: 'WELADED1VEL',
        blz: '33450000',
        city: 'Velbert',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16749,
        name: 'Volksbank Krefeld',
        bic: 'GENODED1HTK',
        blz: '32060362',
        city: 'Krefeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16750,
        name: 'Volksbank an der Niers',
        bic: 'GENODED1GDL',
        blz: '32061384',
        city: 'Kevelaer',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16751,
        name: 'Volksbank Kempen-Grefrath',
        bic: 'GENODED1KMP',
        blz: '32061414',
        city: 'Kempen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16752,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB320',
        blz: '32070024',
        city: 'Krefeld',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16753,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDD320',
        blz: '32070080',
        city: 'Krefeld',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16754,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF320',
        blz: '32080010',
        city: 'Krefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16757,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '32440023',
        city: 'Kleve',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17296,
        name: 'Vereinigte Volksbank',
        bic: 'GENODEM1STM',
        blz: '47264367',
        city: 'Brakel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16782,
        name: 'Sparkasse Heiligenhaus -alt-',
        bic: 'WELADED1HGH',
        blz: '33451220',
        city: 'Heiligenhaus',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16759,
        name: 'Volksbank Kleverland',
        bic: 'GENODED1KLL',
        blz: '32460422',
        city: 'Kleve',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16760,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB324',
        blz: '32470024',
        city: 'Kleve',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16761,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDD324',
        blz: '32470077',
        city: 'Kleve',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16767,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '33040001',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16768,
        name: 'Commerzbank Zw 117',
        bic: 'COBADEDHXXX',
        blz: '33040310',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16770,
        name: 'Credit- und Volksbank Wuppertal',
        bic: 'GENODED1CVW',
        blz: '33060098',
        city: 'Wuppertal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16772,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBWUP',
        blz: '33070024',
        city: 'Wuppertal',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16773,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDWXXX',
        blz: '33070090',
        city: 'Wuppertal',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16774,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFI86',
        blz: '33080001',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16775,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF332',
        blz: '33080030',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16776,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFI87',
        blz: '33080085',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16777,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
        bic: 'DRESDEFFI88',
        blz: '33080086',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16778,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFI89',
        blz: '33080087',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16779,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFI90',
        blz: '33080088',
        city: 'Wuppertal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16780,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '33440035',
        city: 'Velbert',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16755,
        name: 'Verbandssparkasse Goch',
        bic: 'WELADED1GOC',
        blz: '32250050',
        city: 'Goch',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16738,
        name: 'Kreissparkasse Heinsberg in Erkelenz',
        bic: 'WELADED1ERK',
        blz: '31251220',
        city: 'Erkelenz',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16769,
        name: 'Stadtsparkasse Wuppertal',
        bic: 'WUPSDE33XXX',
        blz: '33050000',
        city: 'Wuppertal',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16784,
        name: 'Stadtsparkasse Remscheid',
        bic: 'WELADEDRXXX',
        blz: '34050000',
        city: 'Remscheid',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16786,
        name: 'Stadtsparkasse Wermelskirchen',
        bic: 'WELADED1WMK',
        blz: '34051570',
        city: 'Wermelskirchen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16785,
        name: 'Sparkasse Radevormwald-Hückeswagen',
        bic: 'WELADED1RVW',
        blz: '34051350',
        city: 'Radevormwald',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16805,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF350',
        blz: '35080070',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16806,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFI91',
        blz: '35080085',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16796,
        name: 'Bundesbank',
        bic: 'MARKDEF1350',
        blz: '35000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16797,
        name: 'SEB',
        bic: 'ESSEDE5F350',
        blz: '35010111',
        city: 'Duisburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16798,
        name: 'National-Bank',
        bic: 'NBAGDE3EXXX',
        blz: '35020030',
        available: false
    },
    {
        id: 16801,
        name: 'Bank für Kirche und Diakonie - KD-Bank',
        bic: 'GENODED1DKD',
        blz: '35060190',
        city: 'Dortmund',
        available: true
    },
    {
        id: 16811,
        name: 'Bank für Schiffahrt (BFS) Fil d Ostfr VB Leer',
        bic: 'GENODEF1BSD',
        blz: '35090300',
        city: 'Duisburg',
        available: false
    },
    {
        id: 16812,
        name: 'SEB',
        bic: 'ESSEDE5F352',
        blz: '35211012',
        city: 'Dinslaken',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16807,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFI92',
        blz: '35080086',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16808,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
        bic: 'DRESDEFFI93',
        blz: '35080087',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16819,
        name: 'Bundesbank eh Wesel',
        bic: 'MARKDEF1356',
        blz: '35600000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16809,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFI94',
        blz: '35080088',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16810,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFI95',
        blz: '35080089',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16816,
        name: 'Sparkasse Neukirchen-Vluyn -alt-',
        bic: 'WELADED1NVL',
        blz: '35451460',
        city: 'Neukirchen-Vluyn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16825,
        name: 'Bundesbank',
        bic: 'MARKDEF1360',
        blz: '36000000',
        city: 'Essen',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16826,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F360',
        blz: '36010111',
        city: 'Essen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16828,
        name: 'Aareal Bank',
        bic: 'AARBDE5W360',
        blz: '36010424',
        city: 'Essen',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 16829,
        name: 'NIBC Bank Deutschland',
        bic: 'GABKDE3EXXX',
        blz: '36010600',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 16830,
        name: 'NIBC Bank Deutschland Asset Backed Securities',
        bic: 'GABKDE31699',
        blz: '36010699',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16831,
        name: 'AKBANK N.V. Essen',
        blz: '36010800',
        logoPath: '40.svg',
        available: false
    },
    {
        id: 16832,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM360',
        blz: '36020186',
        city: 'Essen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 16833,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1ESS',
        blz: '36020700',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 16834,
        name: 'VALOVIS Bank',
        bic: 'VABKDE3EXXX',
        blz: '36036000',
        city: 'Essen',
        available: false
    },
    {
        id: 16838,
        name: 'Westdeutsche Landesbank Girozentrale',
        bic: 'WELADE3EXXX',
        blz: '36050000',
        available: false
    },
    {
        id: 16840,
        name: 'Pax-Bank',
        bic: 'GENODED1PA2',
        blz: '36060192',
        city: 'Essen',
        logoPath: '106.svg',
        available: false
    },
    {
        id: 16841,
        name: 'Bank im Bistum Essen',
        bic: 'GENODED1BBE',
        blz: '36060295',
        city: 'Essen',
        available: true
    },
    {
        id: 16842,
        name: 'GENO BANK ESSEN',
        bic: 'GENODEM1GBE',
        blz: '36060488',
        city: 'Essen',
        available: true
    },
    {
        id: 16843,
        name: 'Sparda-Bank West',
        bic: 'GENODED1SPE',
        blz: '36060591',
        city: 'Düsseldorf',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 16849,
        name: 'Bundesbank eh Mülheim an der Ruhr',
        bic: 'MARKDEF1362',
        blz: '36200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16850,
        name: 'SEB',
        bic: 'ESSEDE5F362',
        blz: '36210111',
        city: 'Mülheim an der Ruhr',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16814,
        name: 'Volksbank Dinslaken',
        bic: 'GENODED1DLK',
        blz: '35261248',
        city: 'Dinslaken',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16839,
        name: 'Sparkasse Essen',
        bic: 'SPESDE3EXXX',
        blz: '36050105',
        city: 'Essen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16852,
        name: 'Sparkasse Mülheim an der Ruhr',
        bic: 'SPMHDE3EXXX',
        blz: '36250000',
        city: 'Mülheim an der Ruhr',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16824,
        name: 'Volksbank Emmerich-Rees',
        bic: 'GENODED1EMR',
        blz: '35860245',
        city: 'Emmerich am Rhein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16835,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '36040039',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17297,
        name: 'Volksbank Wewelsburg-Ahden',
        bic: 'GENODEM1WAH',
        blz: '47265383',
        city: 'Büren',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16836,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '36040060',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17298,
        name: 'Volksbank Borgentreich -alt-',
        bic: 'GENODEM1BOT',
        blz: '47267216',
        city: 'Borgentreich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17300,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B472',
        blz: '47270029',
        city: 'Paderborn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17302,
        name: 'Volksbank Warburger Land -alt-',
        bic: 'GENODEM1WBG',
        blz: '47460028',
        city: 'Warburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17303,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '47640051',
        city: 'Detmold',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16837,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '36040061',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16792,
        name: 'Stadt-Sparkasse Solingen',
        bic: 'SOLSDE33XXX',
        blz: '34250000',
        city: 'Solingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16845,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDEXXX',
        blz: '36070050',
        city: 'Essen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16846,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF360',
        blz: '36080080',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16847,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK  2',
        bic: 'DRESDEFFI66',
        blz: '36080085',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16848,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI17',
        blz: '36089321',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16851,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '36240045',
        city: 'Mülheim an der Ruhr',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16800,
        name: 'Sparkasse Duisburg',
        bic: 'DUISDE33XXX',
        blz: '35050000',
        city: 'Duisburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16917,
        name: 'Volksbank Köln-Nord',
        bic: 'GENODED1KNL',
        blz: '37069429',
        city: 'Köln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16813,
        name: 'Sparkasse Dinslaken-Voerde-Hünxe',
        bic: 'WELADED1DIN',
        blz: '35251000',
        city: 'Dinslaken',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16794,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDW342',
        blz: '34270094',
        city: 'Solingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16795,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF342',
        blz: '34280032',
        city: 'Solingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16799,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '35040038',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16823,
        name: 'Stadtsparkasse Emmerich-Rees',
        bic: 'WELADED1EMR',
        blz: '35850000',
        city: 'Emmerich am Rhein',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16802,
        name: 'Volksbank Rhein-Ruhr',
        bic: 'GENODED1VRR',
        blz: '35060386',
        city: 'Duisburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16803,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB350',
        blz: '35070024',
        city: 'Duisburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16815,
        name: 'Sparkasse am Niederrhein',
        bic: 'WELADED1MOR',
        blz: '35450000',
        city: 'Moers',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16804,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE350',
        blz: '35070030',
        city: 'Duisburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16827,
        name: 'BNP Paribas Ndl. Deutschland (ehem. VON ESSEN BANK)',
        bic: 'VONEDE33XXX',
        blz: '36010200',
        city: 'Essen',
        available: false
    },
    {
        id: 16884,
        name: 'Commerzbank GF-K48',
        bic: 'COBADEFFXXX',
        blz: '37040048',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16885,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '37040060',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16856,
        name: 'Bundesbank eh Oberhausen',
        bic: 'MARKDEF1365',
        blz: '36500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16862,
        name: 'Bundesbank',
        bic: 'MARKDEF1370',
        blz: '37000000',
        city: 'Köln',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 16863,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F370',
        blz: '37010111',
        city: 'Köln',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16864,
        name: 'The Royal Bank of Scotland, Niederlassung Deutschland',
        bic: 'ABNADEFFKOE',
        blz: '37010222',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 16865,
        name: 'BNP Paribas Niederlassung Deutschland',
        bic: 'BNPADEFFXXX',
        blz: '37010600',
        city: 'Frankfurt am Main',
        logoPath: '185.svg',
        available: true
    },
    {
        id: 16866,
        name: 'Deutsche Post Zahlungsdienste',
        bic: 'DEZMDE31XXX',
        blz: '37013030',
        city: 'Bonn',
        available: false
    },
    {
        id: 16867,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM429',
        blz: '37020090',
        city: 'Köln',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 16868,
        name: 'AXA Bank',
        bic: 'AXABDE31XXX',
        blz: '37020200',
        city: 'Köln',
        logoPath: '42.svg',
        available: false
    },
    {
        id: 16869,
        name: 'TOYOTA Kreditbank',
        bic: 'TOBADE33XXX',
        blz: '37020400',
        city: 'Köln',
        available: false
    },
    {
        id: 16870,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33XXX',
        blz: '37020500',
        city: 'Köln',
        available: false
    },
    {
        id: 16871,
        name: 'Bank für Sozialwirtschaft Köln Gf',
        bic: 'BFSWDE33BEK',
        blz: '37020599',
        available: false
    },
    {
        id: 16872,
        name: 'Santander Consumer Bank MG',
        bic: 'AKBCDE31XXX',
        blz: '37020600',
        city: 'Köln',
        logoPath: '176.svg',
        available: false
    },
    {
        id: 16873,
        name: 'Ford Bank Ndl. der FCE Bank',
        bic: 'FDBADE3KXXX',
        blz: '37020900',
        city: 'Köln',
        available: false
    },
    {
        id: 16874,
        name: 'Ford Bank Niederlassung der FCE Bank (vorm. Mazda Bank)',
        bic: 'MAZDDED1XXX',
        blz: '37021100',
        city: 'Köln',
        available: false
    },
    {
        id: 16875,
        name: 'Volvo Auto Bank',
        bic: 'VOLVDE31XXX',
        blz: '37021200',
        available: false
    },
    {
        id: 16876,
        name: 'Volvo Auto Bank - Direktbank',
        bic: 'VOLVDE3K001',
        blz: '37021201',
        available: false
    },
    {
        id: 16877,
        name: 'Ford Bank Niederl. der FCE Bank (vorm. Jaguar FS)',
        bic: 'JAGUDE31XXX',
        blz: '37021300',
        city: 'Köln',
        available: false
    },
    {
        id: 16878,
        name: 'Ford Bank (Gf EG)',
        bic: 'LRFSDE31XXX',
        blz: '37021400',
        city: 'Köln',
        available: false
    },
    {
        id: 16879,
        name: 'Oppenheim, Sal - jr & Cie',
        bic: 'SOPPDE3KXXX',
        blz: '37030200',
        city: 'Köln',
        available: false
    },
    {
        id: 16880,
        name: 'abcbank',
        bic: 'WWBADE3AXXX',
        blz: '37030700',
        city: 'Köln',
        available: false
    },
    {
        id: 16881,
        name: 'Isbank Fil Köln',
        bic: 'ISBKDEFXKOL',
        blz: '37030800',
        city: 'Köln',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 16889,
        name: 'Pax-Bank Gf MHD',
        bic: 'GENODED1PA7',
        blz: '37060120',
        city: 'Köln',
        logoPath: '106.svg',
        available: false
    },
    {
        id: 16890,
        name: 'Pax-Bank',
        bic: 'GENODED1PAX',
        blz: '37060193',
        city: 'Köln',
        logoPath: '106.svg',
        available: true
    },
    {
        id: 16891,
        name: 'Sparda-Bank West',
        bic: 'GENODED1SPK',
        blz: '37060590',
        city: 'Köln',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 16893,
        name: 'Bensberger Bank',
        bic: 'GENODED1BGL',
        blz: '37062124',
        city: 'Bergisch Gladbach',
        available: true
    },
    {
        id: 16897,
        name: 'Spar- und Darlehnskasse Aegidienberg',
        bic: 'GENODED1AEG',
        blz: '37069101',
        city: 'Bad Honnef',
        available: true
    },
    {
        id: 16900,
        name: 'Spar- und Darlehnskasse Brachelen',
        bic: 'GENODED1HCK',
        blz: '37069153',
        city: 'Hückelhoven',
        available: true
    },
    {
        id: 16911,
        name: 'Spar- und Darlehnskasse Hoengen',
        bic: 'GENODED1AHO',
        blz: '37069355',
        city: 'Alsdorf',
        available: true
    },
    {
        id: 16886,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '37040061',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16858,
        name: 'Stadtsparkasse Oberhausen',
        bic: 'WELADED1OBH',
        blz: '36550000',
        city: 'Oberhausen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16894,
        name: 'Raiffeisenbank Frechen-Hürth',
        bic: 'GENODED1FHH',
        blz: '37062365',
        city: 'Hürth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16895,
        name: 'VR Bank Bergisch Gladbach-Leverkusen',
        bic: 'GENODED1PAF',
        blz: '37062600',
        city: 'Bergisch Gladbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16896,
        name: 'Raiffeisenbank Fischenich-Kendenich',
        bic: 'GENODED1FKH',
        blz: '37063367',
        city: 'Hürth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16898,
        name: 'Raiffeisenbank Aldenhoven',
        bic: 'GENODED1ALD',
        blz: '37069103',
        city: 'Aldenhoven',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16899,
        name: 'Volksbank Berg',
        bic: 'GENODED1RKO',
        blz: '37069125',
        city: 'Kürten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16901,
        name: 'Volksbank Meerbusch',
        bic: 'GENODED1MBU',
        blz: '37069164',
        city: 'Meerbusch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16902,
        name: 'Volksbank Erft',
        bic: 'GENODED1ERE',
        blz: '37069252',
        city: 'Elsdorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16903,
        name: 'Raiffeisenbank',
        bic: 'GENODED1GLK',
        blz: '37069302',
        city: 'Geilenkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17304,
        name: 'Sparkasse Paderborn-Detmold',
        bic: 'WELADE3LXXX',
        blz: '47650130',
        city: 'Detmold',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16904,
        name: 'Volksbank Gemünd-Kall -alt-',
        bic: 'GENODED1GKK',
        blz: '37069303',
        city: 'Kall',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16906,
        name: 'Raiffeisenbank Gymnich',
        bic: 'GENODED1EGY',
        blz: '37069322',
        city: 'Erftstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16907,
        name: 'Volksbank Haaren',
        bic: 'GENODED1HAW',
        blz: '37069330',
        city: 'Waldfeucht',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16908,
        name: 'Raiffeisenbank von 1895 Zw Horrem -alt-',
        bic: 'GENODED1KHO',
        blz: '37069331',
        city: 'Kerpen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16909,
        name: 'Volksbank Heimbach',
        bic: 'GENODED1HMB',
        blz: '37069342',
        city: 'Heimbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16910,
        name: 'Raiffeisenbank Selfkant  -alt-',
        bic: 'GENODED1SEG',
        blz: '37069354',
        city: 'Gangelt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16912,
        name: 'VR-Bank Rur-Wurm',
        bic: 'GENODED1IMM',
        blz: '37069381',
        city: 'Geilenkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16913,
        name: 'Raiffeisenbank Junkersdorf',
        bic: 'GENODED1JUK',
        blz: '37069401',
        city: 'Köln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16914,
        name: 'Raiffeisenbank Kaarst',
        bic: 'GENODED1KAA',
        blz: '37069405',
        city: 'Kaarst',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16915,
        name: 'Volksbank Heinsberg',
        bic: 'GENODED1HRB',
        blz: '37069412',
        city: 'Heinsberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16916,
        name: 'Volksbank Dünnwald-Holweide',
        bic: 'GENODED1DHK',
        blz: '37069427',
        city: 'Köln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16855,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF362',
        blz: '36280071',
        city: 'Mülheim an der Ruhr',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16857,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '36540046',
        city: 'Oberhausen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16859,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB365',
        blz: '36570024',
        city: 'Oberhausen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16860,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE365',
        blz: '36570049',
        city: 'Oberhausen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16861,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF365',
        blz: '36580072',
        city: 'Oberhausen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16882,
        name: 'Commerzbank, CC SP',
        bic: 'COBADEFFXXX',
        blz: '37040037',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 16892,
        name: 'PSD Bank Köln',
        bic: 'GENODEF1P13',
        blz: '37060993',
        city: 'Köln',
        available: true
    },
    {
        id: 16883,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '37040044',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16888,
        name: 'Kreissparkasse Köln',
        bic: 'COKSDE33XXX',
        blz: '37050299',
        city: 'Köln',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16887,
        name: 'Sparkasse KölnBonn',
        bic: 'COLSDE33XXX',
        blz: '37050198',
        city: 'Köln',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16955,
        name: 'Stadt-Sparkasse Düsseldorf Fil Monheim',
        bic: 'DUSSDED1MON',
        blz: '37551670',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16919,
        name: 'VR-Bank Rhein-Sieg',
        bic: 'GENODED1RST',
        blz: '37069520',
        city: 'Siegburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16931,
        name: 'Brühler Bank',
        bic: 'GENODED1BRL',
        blz: '37069991',
        city: 'Brühl',
        available: true
    },
    {
        id: 16950,
        name: 'Kölner Bank',
        bic: 'GENODED1CGN',
        blz: '37160087',
        city: 'Köln',
        available: true
    },
    {
        id: 16921,
        name: 'Raiffeisenbank Much-Ruppichteroth',
        bic: 'GENODED1MUC',
        blz: '37069524',
        city: 'Much',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16960,
        name: 'Bundesbank eh Bonn',
        bic: 'MARKDEF1380',
        blz: '38000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16961,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF380',
        blz: '38010053',
        city: 'Bonn',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 16962,
        name: 'SEB',
        bic: 'ESSEDE5F380',
        blz: '38010111',
        city: 'Bonn',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16963,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFDSL',
        blz: '38010700',
        city: 'Bonn',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 16964,
        name: 'KfW Ndl Bonn',
        bic: 'DTABDED1XXX',
        blz: '38010900',
        city: 'Bonn',
        logoPath: '180.svg',
        available: false
    },
    {
        id: 16965,
        name: 'KfW Ausbildungsförderung Bonn',
        bic: 'DTABDED1AUS',
        blz: '38010999',
        city: 'Bonn',
        logoPath: '180.svg',
        available: false
    },
    {
        id: 16966,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1XXX',
        blz: '38011000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16967,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1001',
        blz: '38011001',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16968,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1002',
        blz: '38011002',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16969,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1003',
        blz: '38011003',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16970,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1004',
        blz: '38011004',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16971,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1005',
        blz: '38011005',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16972,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1006',
        blz: '38011006',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16973,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1007',
        blz: '38011007',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16974,
        name: 'VÖB-ZVD Processing',
        bic: 'VZVDDED1008',
        blz: '38011008',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 16975,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM402',
        blz: '38020090',
        city: 'Bonn',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18341,
        name: 'Creglinger Bank',
        bic: 'GENODES1CRB',
        blz: '62361274',
        available: false
    },
    {
        id: 16922,
        name: 'Raiffeisenbank Odenthal -alt-',
        bic: 'GENODED1ODT',
        blz: '37069577',
        city: 'Odenthal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16923,
        name: 'Raiffeisenbank Voreifel',
        bic: 'GENODED1RBC',
        blz: '37069627',
        city: 'Rheinbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16924,
        name: 'Rosbacher Raiffeisenbank',
        bic: 'GENODED1WND',
        blz: '37069639',
        city: 'Windeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16925,
        name: 'Raiffeisenbank',
        bic: 'GENODED1SMR',
        blz: '37069642',
        city: 'Simmerath',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16926,
        name: 'Raiffeisenbank Sankt Augustin',
        bic: 'GENODED1SAM',
        blz: '37069707',
        city: 'Sankt Augustin',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16927,
        name: 'VR-Bank Nordeifel',
        bic: 'GENODED1SLE',
        blz: '37069720',
        city: 'Schleiden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16928,
        name: 'Volksbank Wachtberg',
        bic: 'GENODED1WVI',
        blz: '37069805',
        city: 'Wachtberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16929,
        name: 'Raiffeisenbk Wesseling -alt-',
        bic: 'GENODED1WSL',
        blz: '37069833',
        city: 'Wesseling',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16930,
        name: 'Volksbank Wipperfürth-Lindlar',
        bic: 'GENODED1WPF',
        blz: '37069840',
        city: 'Wipperfürth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16932,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBKOE',
        blz: '37070024',
        city: 'Köln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16933,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDKXXX',
        blz: '37070060',
        city: 'Köln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16934,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF370',
        blz: '37080040',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16935,
        name: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 1',
        bic: 'DRESDEFFI51',
        blz: '37080085',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16936,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFI67',
        blz: '37080086',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16937,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFI96',
        blz: '37080087',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16938,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
        bic: 'DRESDEFFI97',
        blz: '37080088',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16939,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
        bic: 'DRESDEFFI98',
        blz: '37080089',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16940,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
        bic: 'DRESDEFFJ01',
        blz: '37080090',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16941,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
        bic: 'DRESDEFFJ02',
        blz: '37080091',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16942,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
        bic: 'DRESDEFFJ03',
        blz: '37080092',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16943,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
        bic: 'DRESDEFFJ04',
        blz: '37080093',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16944,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 12',
        bic: 'DRESDEFFJ05',
        blz: '37080094',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16945,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 13',
        bic: 'DRESDEFFJ06',
        blz: '37080095',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16946,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 14',
        bic: 'DRESDEFFJ07',
        blz: '37080098',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16947,
        name: 'Commerzbank vormals Dresdner Bank Zw 99',
        bic: 'DRESDEFFI36',
        blz: '37080099',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16948,
        name: 'Commerzbank vormals Dresdner Bank ITGK I',
        bic: 'DRESDEFFI04',
        blz: '37089340',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16949,
        name: 'Commerzbank vormals Dresdner Bank ITGK II',
        bic: 'DRESDEFFI05',
        blz: '37089342',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16951,
        name: 'VR-Bank Rhein-Erft',
        bic: 'GENODED1BRH',
        blz: '37161289',
        city: 'Brühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16952,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '37540050',
        city: 'Leverkusen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16956,
        name: 'Stadt-Sparkasse Langenfeld',
        bic: 'WELADED1LAF',
        blz: '37551780',
        city: 'Langenfeld (Rheinland)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16977,
        name: 'Sparkasse Bonn -alt-',
        bic: 'COLSDE33BON',
        blz: '38050000',
        city: 'Bonn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16954,
        name: 'Sparkasse Leverkusen',
        bic: 'WELADEDLLEV',
        blz: '37551440',
        city: 'Leverkusen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16957,
        name: 'Volksbank Rhein-Wupper',
        bic: 'GENODED1RWL',
        blz: '37560092',
        city: 'Leverkusen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16958,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB375',
        blz: '37570024',
        city: 'Leverkusen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16959,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDK375',
        blz: '37570064',
        city: 'Leverkusen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16976,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '38040007',
        city: 'Bonn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17041,
        name: 'Volksbank Baumberge',
        bic: 'GENODEM1BAU',
        blz: '40069408',
        city: 'Billerbeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16981,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDK380',
        blz: '38070059',
        city: 'Bonn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16983,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK) F 950',
        bic: 'DEUTDEDB383',
        blz: '38077724',
        city: 'Königswinter',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16994,
        name: 'Bundesbank eh Siegburg',
        bic: 'MARKDEF1386',
        blz: '38600000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16995,
        name: 'Steyler Bank',
        bic: 'GENODED1STB',
        blz: '38621500',
        city: 'Sankt Augustin',
        logoPath: '144.svg',
        available: true
    },
    {
        id: 16998,
        name: 'Bundesbank',
        bic: 'MARKDEF1390',
        blz: '39000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 16999,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F390',
        blz: '39010111',
        city: 'Aachen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17003,
        name: 'Aachener Bank',
        bic: 'GENODED1AAC',
        blz: '39060180',
        city: 'Aachen',
        available: true
    },
    {
        id: 17010,
        name: 'Pax-Bank',
        bic: 'GENODED1PA1',
        blz: '39160191',
        city: 'Aachen',
        logoPath: '106.svg',
        available: false
    },
    {
        id: 17014,
        name: 'Bundesbank eh Düren',
        bic: 'MARKDEF1395',
        blz: '39500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17021,
        name: 'Bundesbank eh Münster',
        bic: 'MARKDEF1400',
        blz: '40000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17022,
        name: 'SEB',
        bic: 'ESSEDE5F400',
        blz: '40010111',
        city: 'Münster',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17023,
        name: 'NRW.BANK',
        bic: 'NRWBDEDMMST',
        blz: '40022000',
        city: 'Münster',
        available: false
    },
    {
        id: 17024,
        name: 'Münsterländische Bank Thie & Co',
        bic: 'MLBKDEH1MUE',
        blz: '40030000',
        city: 'Münster',
        available: true
    },
    {
        id: 17026,
        name: 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
        bic: 'WELADE3MXXX',
        blz: '40050000',
        city: 'Münster',
        available: false
    },
    {
        id: 17029,
        name: 'DZ BANK',
        bic: 'GENODEMSXXX',
        blz: '40060000',
        city: 'Münster',
        logoPath: '69.svg',
        available: true
    },
    {
        id: 17030,
        name: 'DKM Darlehnskasse Münster',
        bic: 'GENODEM1DKM',
        blz: '40060265',
        city: 'Münster',
        available: true
    },
    {
        id: 17031,
        name: 'DZ HYP - Münster',
        bic: 'GENODEM1WLM',
        blz: '40060300',
        city: 'Münster',
        logoPath: '71.svg',
        available: true
    },
    {
        id: 17032,
        name: 'Sparda-Bank Münster',
        bic: 'GENODEF1S08',
        blz: '40060560',
        city: 'Münster',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 16984,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF380',
        blz: '38080055',
        city: 'Bonn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16985,
        name: 'VR-Bank Bonn',
        bic: 'GENODED1HBO',
        blz: '38160220',
        city: 'Bonn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16987,
        name: 'Volksbank Euskirchen',
        bic: 'GENODED1EVB',
        blz: '38260082',
        city: 'Euskirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16988,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '38440016',
        city: 'Gummersbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16997,
        name: 'Sparkasse Hennef',
        bic: 'WELADED1HEN',
        blz: '38651390',
        city: 'Hennef (Sieg)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17002,
        name: 'Sparkasse Aachen',
        bic: 'AACSDE33XXX',
        blz: '39050000',
        city: 'Aachen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16991,
        name: 'Volksbank Oberberg',
        bic: 'GENODED1WIL',
        blz: '38462135',
        city: 'Wiehl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16992,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB384',
        blz: '38470024',
        city: 'Gummersbach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16993,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDW384',
        blz: '38470091',
        city: 'Gummersbach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17016,
        name: 'Sparkasse Düren',
        bic: 'SDUEDE33XXX',
        blz: '39550110',
        city: 'Düren',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17001,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '39040013',
        city: 'Aachen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17027,
        name: 'Sparkasse Münsterland Ost',
        bic: 'WELADED1MST',
        blz: '40050150',
        city: 'Münster',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17004,
        name: 'Heinsberger Volksbank',
        bic: 'GENODED1HNB',
        blz: '39061981',
        city: 'Heinsberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17005,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDK390',
        blz: '39070020',
        city: 'Aachen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17006,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB390',
        blz: '39070024',
        city: 'Aachen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17007,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF390',
        blz: '39080005',
        city: 'Aachen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17008,
        name: 'Commerzbank vormals Dresdner Bank Zw 98',
        bic: 'DRESDEFFI37',
        blz: '39080098',
        city: 'Aachen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17009,
        name: 'Commerzbank vormals Dresdner Bank Zw 99',
        bic: 'DRESDEFFI38',
        blz: '39080099',
        city: 'Aachen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17306,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B476',
        blz: '47670023',
        city: 'Detmold',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17011,
        name: 'Volksbank Aachen Süd',
        bic: 'GENODED1AAS',
        blz: '39161490',
        city: 'Aachen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17012,
        name: 'VR-Bank',
        bic: 'GENODED1WUR',
        blz: '39162980',
        city: 'Würselen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17013,
        name: 'Raiffeisen-Bank Eschweiler',
        bic: 'GENODED1RSC',
        blz: '39362254',
        city: 'Eschweiler',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17015,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '39540052',
        city: 'Düren',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16978,
        name: 'Stadtsparkasse Bad Honnef',
        bic: 'WELADED1HON',
        blz: '38051290',
        city: 'Bad Honnef',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17017,
        name: 'Volksbank Düren',
        bic: 'GENODED1DUE',
        blz: '39560201',
        city: 'Düren',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17019,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDK395',
        blz: '39570061',
        city: 'Düren',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17020,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF395',
        blz: '39580041',
        city: 'Düren',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17025,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '40040028',
        city: 'Münster',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16990,
        name: 'Sparkasse der Homburgischen Gemeinden',
        bic: 'WELADED1WIE',
        blz: '38452490',
        city: 'Wiehl',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17033,
        name: 'Volksbank Greven',
        bic: 'GENODEM1GRV',
        blz: '40061238',
        city: 'Greven',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17034,
        name: 'Volksbank Lette-Darup-Rorup',
        bic: 'GENODEM1CND',
        blz: '40069226',
        city: 'Coesfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17035,
        name: 'Volksbank Marsberg',
        bic: 'GENODEM1MAS',
        blz: '40069266',
        city: 'Marsberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17036,
        name: 'Volksbank Schlangen',
        bic: 'GENODEM1SLN',
        blz: '40069283',
        city: 'Schlangen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17037,
        name: 'Volksbank Medebach -alt-',
        bic: 'GENODEM1MDB',
        blz: '40069348',
        city: 'Medebach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17038,
        name: 'Volksbank',
        bic: 'GENODEM1SAE',
        blz: '40069362',
        city: 'Saerbeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17039,
        name: 'Volksbank Schermbeck',
        bic: 'GENODEM1SMB',
        blz: '40069363',
        city: 'Schermbeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16986,
        name: 'Kreissparkasse Euskirchen',
        bic: 'WELADED1EUS',
        blz: '38250110',
        city: 'Euskirchen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17040,
        name: 'Volksbank Thülen',
        bic: 'GENODEM1BTH',
        blz: '40069371',
        city: 'Brilon',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16996,
        name: 'Kreissparkasse Siegburg',
        bic: 'WELADED1SGB',
        blz: '38650000',
        city: 'Siegburg',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17000,
        name: 'Aachener Bausparkasse',
        bic: 'AABSDE31XXX',
        blz: '39020000',
        city: 'Aachen',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17028,
        name: 'LBS Westdeutsche Landesbausparkasse',
        bic: 'LBSWDE31XXX',
        blz: '40055555',
        city: 'Münster',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16989,
        name: 'Sparkasse Gummersbach',
        bic: 'WELADED1GMB',
        blz: '38450000',
        city: 'Gummersbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17102,
        name: 'Volksbank Oelde-Ennigerloh-Neubeckum',
        bic: 'GENODEM1OEN',
        blz: '41261419',
        city: 'Ennigerloh',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17103,
        name: 'Volksbank',
        bic: 'GENODEM1AHL',
        blz: '41262501',
        city: 'Warendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17057,
        name: 'Helaba Düsseldorf Gf Verrechnung FI-Münster',
        blz: '40150001',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 17075,
        name: 'Bundesbank eh Rheine',
        bic: 'MARKDEF1403',
        blz: '40300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17104,
        name: 'Vereinigte Volksbank Telgte',
        bic: 'GENODEM1SDH',
        blz: '41262621',
        city: 'Telgte',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17085,
        name: 'Bundesbank eh Hamm',
        bic: 'MARKDEF1410',
        blz: '41000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17086,
        name: 'SEB',
        bic: 'ESSEDE5F410',
        blz: '41010111',
        city: 'Hamm',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17093,
        name: 'Spar- und Darlehnskasse Bockum-Hövel',
        bic: 'GENODEM1HBH',
        blz: '41061011',
        city: 'Hamm',
        available: true
    },
    {
        id: 17094,
        name: 'BAG Bankaktiengesellschaft',
        bic: 'GENODEM1BAG',
        blz: '41061903',
        city: 'Hamm',
        available: true
    },
    {
        id: 17043,
        name: 'Volksbank Wulfen -alt-',
        bic: 'GENODEM1DWU',
        blz: '40069477',
        city: 'Dorsten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17044,
        name: 'Volksbank Senden',
        bic: 'GENODEM1SDN',
        blz: '40069546',
        city: 'Senden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17045,
        name: 'Volksbank Amelsbüren',
        bic: 'GENODEM1MAB',
        blz: '40069600',
        city: 'Münster',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17046,
        name: 'Volksbank Ascheberg-Herbern',
        bic: 'GENODEM1CAN',
        blz: '40069601',
        city: 'Ascheberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17047,
        name: 'Volksbank Erle',
        bic: 'GENODEM1ERR',
        blz: '40069606',
        city: 'Raesfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17049,
        name: 'Volksbank Lette -alt-',
        bic: 'GENODEM1CLE',
        blz: '40069636',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17050,
        name: 'Volksbank in der Hohen Mark',
        bic: 'GENODEM1DLR',
        blz: '40069709',
        city: 'Dorsten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17051,
        name: 'Volksbank Südkirchen-Capelle-Nordkirchen',
        bic: 'GENODEM1SCN',
        blz: '40069716',
        city: 'Nordkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17052,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB400',
        blz: '40070024',
        city: 'Münster',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17053,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B400',
        blz: '40070080',
        city: 'Münster',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17054,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF400',
        blz: '40080040',
        city: 'Münster',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17055,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFI68',
        blz: '40080085',
        city: 'Münster',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17079,
        name: 'Sparkasse Steinfurt -alt-',
        bic: 'WELADED1IBB',
        blz: '40351220',
        city: 'Steinfurt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17089,
        name: 'Sparkasse Hamm',
        bic: 'WELADED1HAM',
        blz: '41050095',
        city: 'Hamm',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17092,
        name: 'Volksbank Hamm',
        bic: 'GENODEM1HMM',
        blz: '41060120',
        city: 'Hamm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17063,
        name: 'Vereinigte Volksbank Münster',
        bic: 'GENODEM1MSC',
        blz: '40160050',
        city: 'Münster',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17064,
        name: 'Volksbank Coesfeld -alt-',
        blz: '40163123',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17065,
        name: 'Volksbank Nordmünsterland',
        bic: 'GENODEM1SEE',
        blz: '40163720',
        city: 'Rheine',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17066,
        name: 'Volksbank Gronau-Ahaus',
        bic: 'GENODEM1GRN',
        blz: '40164024',
        city: 'Gronau (Westf.)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17067,
        name: 'Volksbank Laer-Horstmar-Leer',
        bic: 'GENODEM1LAE',
        blz: '40164256',
        city: 'Laer',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17068,
        name: 'Volksbank Nottuln',
        bic: 'GENODEM1CNO',
        blz: '40164352',
        city: 'Nottuln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17069,
        name: 'Volksbank Lüdinghausen-Olfen',
        bic: 'GENODEM1LHN',
        blz: '40164528',
        city: 'Lüdinghausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17307,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB476',
        blz: '47670024',
        city: 'Detmold',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17308,
        name: 'Volksbank Detmold -alt-',
        blz: '47690080',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17309,
        name: 'Volksbank Ostlippe',
        bic: 'GENODEM1OLB',
        blz: '47691200',
        city: 'Blomberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17311,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '47840065',
        city: 'Gütersloh',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17312,
        name: 'Commerzbank Zw 80',
        bic: 'COBADEFFXXX',
        blz: '47840080',
        city: 'Gütersloh',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17070,
        name: 'Volksbank Ochtrup-Laer',
        bic: 'GENODEM1OTR',
        blz: '40164618',
        city: 'Ochtrup',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17071,
        name: 'Volksbank Gescher',
        bic: 'GENODEM1GE1',
        blz: '40164901',
        city: 'Gescher',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17072,
        name: 'Volksbank Selm-Bork',
        bic: 'GENODEM1SEM',
        blz: '40165366',
        city: 'Selm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17073,
        name: 'Volksbank Lengerich/Lotte -alt-',
        bic: 'GENODEM1LLE',
        blz: '40166439',
        city: 'Lengerich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17074,
        name: 'Volksbank Buldern -alt-',
        bic: 'GENODEM1BUL',
        blz: '40166800',
        city: 'Dülmen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17076,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '40340030',
        city: 'Rheine',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17091,
        name: 'Sparkasse Bergkamen-Bönen',
        bic: 'WELADED1BGK',
        blz: '41051845',
        city: 'Bergkamen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17080,
        name: 'Volksbank Westerkappeln-Wersen',
        bic: 'GENODEM1WKP',
        blz: '40361627',
        city: 'Westerkappeln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17081,
        name: 'VR-Bank Kreis Steinfurt',
        bic: 'GENODEM1IBB',
        blz: '40361906',
        city: 'Rheine',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17082,
        name: 'Volksbank Hörstel -alt-',
        bic: 'GENODEM1HRL',
        blz: '40363433',
        city: 'Hörstel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17084,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B403',
        blz: '40370079',
        city: 'Rheine',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17087,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '41040018',
        city: 'Hamm',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17088,
        name: 'ZTB der Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '41041000',
        city: 'Hamm',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17058,
        name: 'Verbundsparkasse Emsdetten Ochtrup',
        bic: 'WELADED1EMS',
        blz: '40153768',
        city: 'Emsdetten',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17061,
        name: 'Sparkasse Westmünsterland',
        bic: 'WELADE3WXXX',
        blz: '40154530',
        city: 'Ahaus',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17100,
        name: 'Volksbank Beckum',
        bic: 'GENODEM1BEK',
        blz: '41260006',
        city: 'Beckum',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17056,
        name: 'PSD Bank Westfalen-Lippe',
        bic: 'GENODEF1P15',
        blz: '40090900',
        city: 'Münster',
        available: true
    },
    {
        id: 17101,
        name: 'Volksbank Enniger-Ostenfelde-Westkirchen',
        bic: 'GENODEM1EOW',
        blz: '41261324',
        city: 'Ennigerloh',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17060,
        name: 'Stadtsparkasse Lengerich',
        bic: 'WELADED1LEN',
        blz: '40154476',
        city: 'Lengerich',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17062,
        name: 'Stadtsparkasse Stadtlohn',
        bic: 'WELADED1STL',
        blz: '40154702',
        city: 'Stadtlohn',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17077,
        name: 'Stadtsparkasse Rheine',
        bic: 'WELADED1RHN',
        blz: '40350005',
        city: 'Rheine',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17078,
        name: 'Kreissparkasse Steinfurt',
        bic: 'WELADED1STF',
        blz: '40351060',
        city: 'Ibbenbüren',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17090,
        name: 'Stadtsparkasse Werne -alt-',
        bic: 'WELADED1WRN',
        blz: '41051605',
        city: 'Werne',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17059,
        name: 'Sparkasse Gronau',
        bic: 'WELADED1GRO',
        blz: '40154006',
        city: 'Gronau (Westf.)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17155,
        name: 'VR-Bank Westmünsterland',
        bic: 'GENODEM1BOB',
        blz: '42861387',
        city: 'Coesfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17156,
        name: 'Volksbank',
        bic: 'GENODEM1HDI',
        blz: '42861416',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17128,
        name: 'Bundesbank eh Gelsenkirchen',
        bic: 'MARKDEF1420',
        blz: '42000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17129,
        name: 'SEB',
        bic: 'ESSEDE5F420',
        blz: '42010111',
        city: 'Gelsenkirchen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17130,
        name: 'Isbank Fil Gelsenkirchen',
        bic: 'ISBKDEFXGEL',
        blz: '42030600',
        city: 'Gelsenkirchen',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 17140,
        name: 'Bundesbank eh Recklinghausen',
        bic: 'MARKDEF1426',
        blz: '42600000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17141,
        name: 'SEB',
        bic: 'ESSEDE5F426',
        blz: '42610112',
        city: 'Recklinghausen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17157,
        name: 'Volksbank Gemen',
        bic: 'GENODEM1BOG',
        blz: '42861515',
        city: 'Borken',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17154,
        name: 'Spar- und Darlehnskasse',
        bic: 'GENODEM1RKN',
        blz: '42861239',
        city: 'Reken',
        logoPath: '170.svg',
        available: true
    },
    {
        id: 17163,
        name: 'Bundesbank',
        bic: 'MARKDEF1430',
        blz: '43000000',
        city: 'Bochum',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17164,
        name: 'SEB',
        bic: 'ESSEDE5F430',
        blz: '43010111',
        city: 'Bochum',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17158,
        name: 'Volksbank Heiden',
        bic: 'GENODEM1HEI',
        blz: '42861608',
        city: 'Heiden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17159,
        name: 'Volksbank Rhede',
        bic: 'GENODEM1RHD',
        blz: '42861814',
        city: 'Rhede',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17160,
        name: 'Volksbank Raesfeld',
        bic: 'GENODEM1RAE',
        blz: '42862451',
        city: 'Raesfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17161,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB428',
        blz: '42870024',
        city: 'Bocholt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17162,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B428',
        blz: '42870077',
        city: 'Bocholt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17165,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '43040036',
        city: 'Bochum',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17107,
        name: 'Sparkasse SoestWerl',
        bic: 'WELADED1SOS',
        blz: '41450075',
        city: 'Soest',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17108,
        name: 'Sparkasse Werl',
        bic: 'WELADED1WRL',
        blz: '41451750',
        city: 'Werl',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17168,
        name: 'Volksbank Bochum Witten',
        bic: 'GENODEM1BOC',
        blz: '43060129',
        city: 'Bochum',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17106,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '41440018',
        city: 'Soest',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17111,
        name: 'Sparkasse Lippstadt',
        bic: 'WELADED1LIP',
        blz: '41650001',
        city: 'Lippstadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17112,
        name: 'Sparkasse Hochsauerland',
        bic: 'WELADED1HSL',
        blz: '41651770',
        city: 'Brilon',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17109,
        name: 'Volksbank Hellweg',
        bic: 'GENODEM1SOE',
        blz: '41460116',
        city: 'Soest',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17113,
        name: 'Sparkasse Erwitte-Anröchte',
        bic: 'WELADED1ERW',
        blz: '41651815',
        city: 'Erwitte',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17114,
        name: 'Sparkasse Geseke',
        bic: 'WELADED1GES',
        blz: '41651965',
        city: 'Geseke',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17115,
        name: 'Sparkasse Warstein-Rüthen -alt-',
        bic: 'WELADED1WAR',
        blz: '41652560',
        city: 'Warstein',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17138,
        name: 'Sparkasse Bottrop',
        bic: 'WELADED1BOT',
        blz: '42451220',
        city: 'Bottrop',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17143,
        name: 'Sparkasse Vest Recklinghausen',
        bic: 'WELADED1REK',
        blz: '42650150',
        city: 'Recklinghausen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17116,
        name: 'Volksbank Beckum-Lippstadt',
        bic: 'GENODEM1LPS',
        blz: '41660124',
        city: 'Lippstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17117,
        name: 'Volksbank Anröchte',
        bic: 'GENODEM1ANR',
        blz: '41661206',
        city: 'Anröchte',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17118,
        name: 'Volksbank Benninghausen',
        bic: 'GENODEM1LBH',
        blz: '41661504',
        city: 'Lippstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17119,
        name: 'Volksbank Brilon',
        bic: 'GENODEM1BRI',
        blz: '41661719',
        city: 'Brilon',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17120,
        name: 'Volksbank Störmede-Hörste',
        bic: 'GENODEM1SGE',
        blz: '41662465',
        city: 'Geseke',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17121,
        name: 'Volksbank Warstein-Belecke -alt-',
        bic: 'GENODEM1WST',
        blz: '41662557',
        city: 'Warstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17122,
        name: 'Volksbank Hörste',
        bic: 'GENODEM1HOE',
        blz: '41663335',
        city: 'Lippstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17123,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB416',
        blz: '41670024',
        city: 'Lippstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17125,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B417',
        blz: '41670028',
        city: 'Brilon',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17126,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B414',
        blz: '41670029',
        city: 'Soest',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17127,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B418',
        blz: '41670030',
        city: 'Werl',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17135,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF420',
        blz: '42080082',
        city: 'Gelsenkirchen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17136,
        name: 'Volksbank Ruhr Mitte',
        bic: 'GENODEM1GBU',
        blz: '42260001',
        city: 'Gelsenkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17137,
        name: 'Stadtsparkasse Gladbeck',
        bic: 'WELADED1GLA',
        blz: '42450040',
        city: 'Gladbeck',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17139,
        name: 'Vereinte Volksbank',
        bic: 'GENODEM1KIH',
        blz: '42461435',
        city: 'Dorsten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17142,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '42640048',
        city: 'Recklinghausen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17167,
        name: 'Sparkasse Hattingen',
        bic: 'WELADED1HTG',
        blz: '43051040',
        city: 'Hattingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17145,
        name: 'Volksbank Marl-Recklinghausen',
        bic: 'GENODEM1MRL',
        blz: '42661008',
        city: 'Marl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17146,
        name: 'Volksbank Haltern',
        bic: 'GENODEM1HLT',
        blz: '42661330',
        city: 'Haltern am See',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17148,
        name: 'Volksbank Waltrop',
        bic: 'GENODEM1WLW',
        blz: '42661717',
        city: 'Waltrop',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17149,
        name: 'Volksbank Dorsten',
        bic: 'GENODEM1DST',
        blz: '42662320',
        city: 'Dorsten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17150,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF426',
        blz: '42680081',
        city: 'Recklinghausen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17151,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '42840005',
        city: 'Bocholt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17144,
        name: 'Stadtsparkasse Haltern am See',
        bic: 'WELADED1HAT',
        blz: '42651315',
        city: 'Haltern am See',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17153,
        name: 'Volksbank Bocholt',
        bic: 'GENODEM1BOH',
        blz: '42860003',
        city: 'Bocholt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17152,
        name: 'Stadtsparkasse Bocholt',
        bic: 'WELADED1BOH',
        blz: '42850035',
        city: 'Bocholt',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17166,
        name: 'Sparkasse Bochum',
        bic: 'WELADED1BOC',
        blz: '43050001',
        city: 'Bochum',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17169,
        name: 'GLS Gemeinschaftsbank',
        bic: 'GENODEM1GLS',
        blz: '43060967',
        city: 'Bochum',
        available: true
    },
    {
        id: 17226,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '45240056',
        city: 'Witten',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17174,
        name: 'Bundesbank',
        bic: 'MARKDEF1440',
        blz: '44000000',
        city: 'Dortmund',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17175,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F440',
        blz: '44010111',
        city: 'Dortmund',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17176,
        name: 'BHW Bausparkasse',
        bic: 'BHWXDE31XXX',
        blz: '44010200',
        logoPath: '46.svg',
        available: false
    },
    {
        id: 17177,
        name: 'The Royal Bank of Scotland, Niederlassung Deutschland',
        bic: 'ABNADEFFDOR',
        blz: '44010300',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 17178,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM808',
        blz: '44020090',
        city: 'Dortmund',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17182,
        name: 'Landesbank Hessen-Thüringen Girozentrale NL. Düsseldorf',
        bic: 'WELADE3DXXX',
        blz: '44050000',
        city: 'Dortmund',
        available: false
    },
    {
        id: 17185,
        name: 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
        bic: 'GENODED1KDD',
        blz: '44064406',
        city: 'Dortmund',
        available: false
    },
    {
        id: 17183,
        name: 'Sparkasse Dortmund',
        bic: 'DORTDE33XXX',
        blz: '44050199',
        city: 'Dortmund',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {id: 17194, name: 'HKB Bank', blz: '44130000', available: false},
    {
        id: 17214,
        name: 'Mendener Bank',
        bic: 'GENODEM1MEN',
        blz: '44761312',
        city: 'Menden (Sauerland)',
        available: true
    },
    {
        id: 17216,
        name: 'Bundesbank',
        bic: 'MARKDEF1450',
        blz: '45000000',
        city: 'Hagen',
        logoPath: '63.svg',
        available: true
    },
    {id: 17217, name: 'HKB Bank', blz: '45030000', available: false},
    {
        id: 17221,
        name: 'Märkische Bank',
        bic: 'GENODEM1HGN',
        blz: '45060009',
        city: 'Hagen',
        available: true
    },
    {
        id: 17231,
        name: 'Spar- u Kreditbank d Bundes Fr ev Gemeinden',
        bic: 'GENODEM1BFG',
        blz: '45260475',
        city: 'Witten',
        available: true
    },
    {
        id: 17230,
        name: 'Volksbank Witten -alt-',
        bic: 'GENODEM1WTN',
        blz: '45260041',
        city: 'Witten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17171,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE430',
        blz: '43070061',
        city: 'Bochum',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17172,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF430',
        blz: '43080083',
        city: 'Bochum',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17195,
        name: 'Sparkasse an der Lippe',
        bic: 'WELADED1LUN',
        blz: '44152370',
        city: 'Lünen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17179,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '44040037',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17180,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '44040060',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17181,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '44040061',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17199,
        name: 'Sparkasse UnnaKamen',
        bic: 'WELADED1UNN',
        blz: '44350060',
        city: 'Unna',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17184,
        name: 'Volksbank Dortmund-Nordwest',
        bic: 'GENODEM1DNW',
        blz: '44060122',
        city: 'Dortmund',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17187,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE440',
        blz: '44070050',
        city: 'Dortmund',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17188,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF440',
        blz: '44080050',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17189,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF446',
        blz: '44080055',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17190,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF447',
        blz: '44080057',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17191,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFI69',
        blz: '44080085',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17192,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI18',
        blz: '44089320',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17200,
        name: 'Sparkasse Kamen',
        bic: 'WELADED1KAM',
        blz: '44351380',
        city: 'Kamen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17197,
        name: 'Dortmunder Volksbank',
        bic: 'GENODEM1DOR',
        blz: '44160014',
        city: 'Dortmund',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17198,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '44340037',
        city: 'Unna',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17201,
        name: 'Sparkasse Fröndenberg',
        bic: 'WELADED1FRN',
        blz: '44351740',
        city: 'Fröndenberg/Ruhr',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17205,
        name: 'Sparkasse der Stadt Iserlohn',
        bic: 'WELADED1ISL',
        blz: '44550045',
        city: 'Iserlohn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17206,
        name: 'Sparkasse Märkisches Sauerland Hemer-Menden',
        bic: 'WELADED1HEM',
        blz: '44551210',
        city: 'Hemer',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17202,
        name: 'Volksbank Unna Schwerte -alt-',
        bic: 'GENODEM1UNA',
        blz: '44360002',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17203,
        name: 'Volksbank Kamen-Werne',
        bic: 'GENODEM1KWK',
        blz: '44361342',
        city: 'Kamen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17204,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '44540022',
        city: 'Iserlohn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17212,
        name: 'Sparkasse Menden -alt-',
        bic: 'WELADED1MEN',
        blz: '44750065',
        city: 'Menden (Sauerland)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17219,
        name: 'Sparkasse HagenHerdecke',
        bic: 'WELADE3HXXX',
        blz: '45050001',
        city: 'Hagen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17207,
        name: 'Volksbank Letmathe -alt-',
        blz: '44561102',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17209,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB445',
        blz: '44570024',
        city: 'Iserlohn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17210,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF445',
        blz: '44580070',
        city: 'Iserlohn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17211,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFI70',
        blz: '44580085',
        city: 'Iserlohn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17227,
        name: 'Sparkasse Witten',
        bic: 'WELADED1WTN',
        blz: '45250035',
        city: 'Witten',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17213,
        name: 'Volksbank Menden -alt-',
        blz: '44760037',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17215,
        name: 'Volksbank in Südwestfalen',
        bic: 'GENODEM1NRD',
        blz: '44761534',
        city: 'Lüdenscheid',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17218,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '45040042',
        city: 'Hagen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17196,
        name: 'Stadtsparkasse Schwerte',
        bic: 'WELADED1SWT',
        blz: '44152490',
        city: 'Schwerte',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17222,
        name: 'Volksbank Hohenlimburg',
        bic: 'GENODEM1HLH',
        blz: '45061524',
        city: 'Hagen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17223,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDW450',
        blz: '45070002',
        city: 'Hagen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17224,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB450',
        blz: '45070024',
        city: 'Hagen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17193,
        name: 'PSD Bank Dortmund -alt-',
        bic: 'GENODEF1P04',
        blz: '44090920',
        city: 'Dortmund',
        available: true
    },
    {
        id: 17225,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF450',
        blz: '45080060',
        city: 'Hagen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17220,
        name: 'Stadtsparkasse Herdecke',
        bic: 'WELADED1HER',
        blz: '45051485',
        city: 'Herdecke',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17228,
        name: 'Stadtsparkasse Wetter',
        bic: 'WELADED1WET',
        blz: '45251480',
        city: 'Wetter (Ruhr)',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17229,
        name: 'Stadtsparkasse Sprockhövel',
        bic: 'SPSHDE31XXX',
        blz: '45251515',
        city: 'Sprockhövel',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17173,
        name: 'Herner Sparkasse',
        bic: 'WELADED1HRN',
        blz: '43250030',
        city: 'Herne',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17280,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDW466',
        blz: '46670007',
        city: 'Arnsberg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17281,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB961',
        blz: '46670024',
        city: 'Arnsberg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17245,
        name: 'Bundesbank eh Siegen',
        bic: 'MARKDEF1460',
        blz: '46000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17246,
        name: 'SEB',
        bic: 'ESSEDE5F460',
        blz: '46010111',
        city: 'Siegen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17270,
        name: 'Bundesbank eh Arnsberg',
        bic: 'MARKDEF1464',
        blz: '46400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17275,
        name: 'Spar- und Darlehnskasse Oeventrop',
        bic: 'GENODEM1ANO',
        blz: '46462271',
        city: 'Arnsberg',
        available: true
    },
    {
        id: 17283,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '47240047',
        city: 'Paderborn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17282,
        name: 'Bundesbank eh Paderborn',
        bic: 'MARKDEF1472',
        blz: '47200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17289,
        name: 'Bank für Kirche und Caritas',
        bic: 'GENODEM1BKC',
        blz: '47260307',
        city: 'Paderborn',
        available: true
    },
    {
        id: 17233,
        name: 'Sparkasse Gevelsberg-Wetter',
        bic: 'WELADED1GEV',
        blz: '45450050',
        city: 'Gevelsberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17235,
        name: 'Städtische Sparkasse zu Schwelm',
        bic: 'WELADED1SLM',
        blz: '45451555',
        city: 'Schwelm',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17287,
        name: 'VerbundVolksbank OWL',
        bic: 'DGPBDE3MXXX',
        blz: '47260121',
        city: 'Paderborn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17288,
        name: 'Volksbank Elsen-Wewer-Borchen',
        bic: 'GENODEM1EWB',
        blz: '47260234',
        city: 'Paderborn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17290,
        name: 'Volksbank Haaren -alt-',
        bic: 'GENODEM1WNH',
        blz: '47261429',
        city: 'Bad Wünnenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17291,
        name: 'Volksbank Brilon-Büren-Salzkotten',
        bic: 'GENODEM1BUS',
        blz: '47261603',
        city: 'Salzkotten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17292,
        name: 'Volksbank Höxter-Beverungen -alt-',
        blz: '47262406',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17293,
        name: 'Volksbank Westenholz',
        bic: 'GENODEM1WDE',
        blz: '47262626',
        city: 'Delbrück',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17294,
        name: 'Volksbank Delbrück-Hövelhof',
        bic: 'GENODEM1DLB',
        blz: '47262703',
        city: 'Delbrück',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17239,
        name: 'Sparkasse Lüdenscheid',
        bic: 'WELADED1LSD',
        blz: '45850005',
        city: 'Lüdenscheid',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17240,
        name: 'Vereinigte Sparkasse im Märkischen Kreis',
        bic: 'WELADED1PLB',
        blz: '45851020',
        city: 'Plettenberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17236,
        name: 'Volksbank Altena -alt-',
        bic: 'GENODEM1ALA',
        blz: '45660029',
        city: 'Altena',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17237,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '45840026',
        city: 'Lüdenscheid',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17238,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '45841031',
        city: 'Plettenberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17241,
        name: 'Sparkasse Kierspe-Meinerzhagen',
        bic: 'WELADED1KMZ',
        blz: '45851665',
        city: 'Meinerzhagen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17248,
        name: 'Sparkasse Siegen',
        bic: 'WELADED1SIE',
        blz: '46050001',
        city: 'Siegen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17249,
        name: 'Sparkasse Burbach-Neunkirchen',
        bic: 'WELADED1BUB',
        blz: '46051240',
        city: 'Burbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17242,
        name: 'Volksbank Lüdenscheid -alt-',
        bic: 'GENODEM1LHA',
        blz: '45860033',
        city: 'Lüdenscheid',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17243,
        name: 'Volksbank Kierspe',
        bic: 'GENODEM1KIE',
        blz: '45861434',
        city: 'Kierspe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17244,
        name: 'Volksbank Meinerzhagen -alt-',
        bic: 'GENODEM1MOM',
        blz: '45861617',
        city: 'Meinerzhagen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17247,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '46040033',
        city: 'Siegen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17253,
        name: 'Sparkasse Wittgenstein',
        bic: 'WELADED1BEB',
        blz: '46053480',
        city: 'Bad Berleburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17262,
        name: 'Sparkasse Olpe-Drolshagen-Wenden',
        bic: 'WELADED1OPE',
        blz: '46250049',
        city: 'Olpe',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17263,
        name: 'Sparkasse Finnentrop',
        bic: 'WELADED1FTR',
        blz: '46251590',
        city: 'Finnentrop',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17254,
        name: 'Volksbank Siegerland',
        bic: 'GENODEM1SNS',
        blz: '46060040',
        city: 'Siegen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17255,
        name: 'VR-Bank Freudenberg-Niederfischbach',
        bic: 'GENODEM1FRF',
        blz: '46061724',
        city: 'Freudenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17256,
        name: 'Volksbank Bigge-Lenne',
        bic: 'GENODEM1SMA',
        blz: '46062817',
        city: 'Schmallenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17257,
        name: 'Volksbank Wittgenstein',
        bic: 'GENODEM1BB1',
        blz: '46063405',
        city: 'Bad Berleburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17258,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB460',
        blz: '46070024',
        city: 'Siegen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17259,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDK460',
        blz: '46070090',
        city: 'Siegen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17260,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF460',
        blz: '46080010',
        city: 'Siegen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17261,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '46240016',
        city: 'Olpe',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17264,
        name: 'Sparkasse Attendorn-Lennestadt-Kirchhundem',
        bic: 'WELADED1ALK',
        blz: '46251630',
        city: 'Attendorn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17272,
        name: 'Sparkasse Meschede',
        bic: 'WELADED1MES',
        blz: '46451012',
        city: 'Meschede',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17273,
        name: 'Sparkasse Bestwig -alt-',
        bic: 'WELADED1BST',
        blz: '46451250',
        city: 'Bestwig',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17265,
        name: 'Volksbank Olpe -alt-',
        bic: 'GENODEM1OLP',
        blz: '46260023',
        city: 'Olpe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17266,
        name: 'Volksbank Attendorn -alt-',
        bic: 'GENODEM1ATT',
        blz: '46261306',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17267,
        name: 'Volksbank Grevenbrück -alt',
        bic: 'GENODEM1GLG',
        blz: '46261607',
        city: 'Lennestadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17268,
        name: 'Volksbank Olpe-Wenden-Drolshagen',
        bic: 'GENODEM1WDD',
        blz: '46261822',
        city: 'Olpe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17269,
        name: 'Volksbank Bigge-Lenne -alt-',
        bic: 'GENODEM1HUL',
        blz: '46262456',
        city: 'Lennestadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17271,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '46441003',
        city: 'Meschede',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17278,
        name: 'Sparkasse Arnsberg-Sundern',
        bic: 'WELADED1ARN',
        blz: '46650005',
        city: 'Arnsberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17250,
        name: 'Stadtsparkasse Freudenberg',
        bic: 'WELADED1FRE',
        blz: '46051733',
        city: 'Freudenberg',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17274,
        name: 'Volksbank Sauerland -alt-',
        bic: 'GENODEM1SRL',
        blz: '46461126',
        city: 'Meschede',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17277,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '46640018',
        city: 'Arnsberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17285,
        name: 'Sparkasse Höxter',
        bic: 'WELADED1HXB',
        blz: '47251550',
        city: 'Brakel',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17251,
        name: 'Stadtsparkasse Hilchenbach',
        bic: 'WELADED1HIL',
        blz: '46051875',
        city: 'Hilchenbach',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17279,
        name: 'Volksbank Sauerland',
        bic: 'GENODEM1NEH',
        blz: '46660022',
        city: 'Arnsberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17252,
        name: 'Stadtsparkasse Schmallenberg',
        bic: 'WELADED1SMB',
        blz: '46052855',
        city: 'Schmallenberg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17286,
        name: 'Stadtsparkasse Delbrück',
        bic: 'WELADED1DEL',
        blz: '47251740',
        city: 'Delbrück',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17284,
        name: 'Sparkasse Paderborn',
        bic: 'WELADED1PBN',
        blz: '47250101',
        city: 'Paderborn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17345,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B487',
        blz: '48070044',
        city: 'Rheda-Wiedenbrück',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20858,
        name: 'Oberbank AG (15011)',
        bic: 'OBKLAT2LXXX',
        blz: '15011',
        available: false
    },
    {
        id: 17310,
        name: 'Bundesbank eh Gütersloh',
        bic: 'MARKDEF1478',
        blz: '47800000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17326,
        name: 'Bundesbank',
        bic: 'MARKDEF1480',
        blz: '48000000',
        city: 'Bielefeld',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17327,
        name: 'SEB',
        bic: 'ESSEDE5F480',
        blz: '48010111',
        city: 'Bielefeld',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17328,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM344',
        blz: '48020086',
        city: 'Bielefeld',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17329,
        name: 'Bankhaus Lampe',
        bic: 'LAMPDEDDXXX',
        blz: '48020151',
        city: 'Bielefeld',
        available: true
    },
    {
        id: 17334,
        name: 'Westdeutsche Landesbank',
        bic: 'WELADE3BXXX',
        blz: '48050000',
        available: false
    },
    {
        id: 17339,
        name: 'Spar-u Darlehnskasse Schloß Holte-Stukenbrock',
        bic: 'GENODEM1SHS',
        blz: '48062466',
        city: 'Schloß Holte-Stukenbrock',
        available: true
    },
    {
        id: 17346,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B413',
        blz: '48070045',
        city: 'Beckum',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17355,
        name: 'Bundesbank eh Minden',
        bic: 'MARKDEF1490',
        blz: '49000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17347,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B481',
        blz: '48070050',
        city: 'Herford',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17301,
        name: 'Stadtsparkasse Marsberg -alt-',
        blz: '47451235',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17305,
        name: 'Stadtsparkasse Blomberg',
        bic: 'WELADED1BLO',
        blz: '47651225',
        city: 'Blomberg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17315,
        name: 'Stadtsparkasse Versmold',
        bic: 'WELADED1VSM',
        blz: '47853355',
        city: 'Versmold',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17316,
        name: 'Kreissparkasse Wiedenbrück',
        bic: 'WELADED1WDB',
        blz: '47853520',
        city: 'Rheda-Wiedenbrück',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17336,
        name: 'Kreissparkasse Halle',
        bic: 'WELADED1HAW',
        blz: '48051580',
        city: 'Halle (Westf.)',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17335,
        name: 'Sparkasse Bielefeld',
        bic: 'SPBIDE3BXXX',
        blz: '48050161',
        city: 'Bielefeld',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17348,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B492',
        blz: '48070052',
        city: 'Bünde',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17321,
        name: 'Volksbank Langenberg -alt-',
        bic: 'GENODEM1LNB',
        blz: '47861907',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17338,
        name: 'Volksbank Halle/Westf',
        bic: 'GENODEM1HLW',
        blz: '48062051',
        city: 'Halle (Westf.)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17322,
        name: 'Volksbank Marienfeld -alt-',
        bic: 'GENODEM1MFD',
        blz: '47862261',
        city: 'Marienfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17323,
        name: 'Volksbank Rietberg',
        bic: 'GENODEM1RNE',
        blz: '47862447',
        city: 'Rietberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17324,
        name: 'Volksbank Versmold',
        bic: 'GENODEM1VMD',
        blz: '47863373',
        city: 'Versmold',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17325,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF478',
        blz: '47880031',
        city: 'Gütersloh',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17330,
        name: 'Bankverein Werther Zw Ndl der VerbundVolksbank OWL',
        bic: 'DGPBDE3MBVW',
        blz: '48021900',
        city: 'Werther (Westf.)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17331,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '48040035',
        city: 'Bielefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17332,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '48040060',
        city: 'Bielefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17333,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '48040061',
        city: 'Bielefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17337,
        name: 'Bielefelder Volksbank',
        bic: 'GENODEM1BIE',
        blz: '48060036',
        city: 'Bielefeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17344,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B489',
        blz: '48070043',
        city: 'Verl',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17365,
        name: 'Volksbank Eisbergen -alt-',
        bic: 'GENODEM1EPW',
        blz: '49061510',
        city: 'Porta Westfalica',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17379,
        name: 'Bundesbank',
        bic: 'MARKDEF1500',
        blz: '50000000',
        city: 'Frankfurt am Main',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17380,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5FXXX',
        blz: '50010111',
        city: 'Frankfurt am Main',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17381,
        name: 'AKBANK',
        bic: 'AKBKDEFFXXX',
        blz: '50010200',
        city: 'Frankfurt am Main',
        logoPath: '40.svg',
        available: false
    },
    {
        id: 17382,
        name: 'Aareal Bank',
        bic: 'AARBDE5W500',
        blz: '50010424',
        city: 'Frankfurt am Main',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17383,
        name: 'Degussa Bank',
        bic: 'DEGUDEFFXXX',
        blz: '50010700',
        city: 'Frankfurt am Main',
        logoPath: '73.svg',
        available: true
    },
    {
        id: 17384,
        name: 'Bank of America',
        bic: 'BOFADEFXXXX',
        blz: '50010900',
        city: 'Frankfurt am Main',
        logoPath: '53.svg',
        available: false
    },
    {
        id: 17385,
        name: 'ALTE LEIPZIGER Bauspar',
        bic: 'ALTEDEFAXXX',
        blz: '50012800',
        city: 'Oberursel (Taunus)',
        available: false
    },
    {
        id: 17386,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 427 Ffm',
        bic: 'HYVEDEM1084',
        blz: '50020160',
        available: false
    },
    {
        id: 17387,
        name: 'ODDO BHF',
        bic: 'BHFBDEFF500',
        blz: '50020200',
        city: 'Frankfurt am Main',
        logoPath: '102.svg',
        available: true
    },
    {
        id: 17388,
        name: 'Oldenburgische Landesbank',
        bic: 'BANVDEHB500',
        blz: '50020300',
        city: 'Bremen',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 17389,
        name: 'KfW Kreditanstalt für Wiederaufbau Frankfurt',
        bic: 'KFWIDEFFXXX',
        blz: '50020400',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17390,
        name: 'Landwirtschaftliche Rentenbank',
        bic: 'LAREDEFFXXX',
        blz: '50020500',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17391,
        name: 'Credit Europe Bank Ndl. Deutschland',
        bic: 'FBHLDEFFXXX',
        blz: '50020700',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17392,
        name: 'Intesa Sanpaolo Frankfurt',
        bic: 'BCITDEFFXXX',
        blz: '50020800',
        city: 'Frankfurt am Main',
        logoPath: '88.svg',
        available: false
    },
    {
        id: 17393,
        name: 'COREALCREDIT BANK',
        bic: 'AHYBDEFFXXX',
        blz: '50020900',
        available: false
    },
    {
        id: 17394,
        name: 'ING Bank',
        bic: 'INGBDEFFXXX',
        blz: '50021000',
        city: 'Frankfurt am Main',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 17395,
        name: 'FIL Fondsbank',
        bic: 'FFBKDEFFKRN',
        blz: '50021100',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17396,
        name: 'Bank of Beirut Ndl Frankfurt',
        bic: 'BABEDEFFXXX',
        blz: '50023400',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17397,
        name: 'PSA Bank Deutschland',
        bic: 'PSADDEF1XXX',
        blz: '50030000',
        city: 'Neu-Isenburg',
        available: false
    },
    {
        id: 17398,
        name: 'HKB Bank Frankfurt',
        bic: 'HKBBDEF1FRA',
        blz: '50030100',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17399,
        name: 'BNP PARIBAS Securities Services',
        bic: 'PARBDEFFXXX',
        blz: '50030500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17400,
        name: 'Deutsche WertpapierService Bank',
        bic: 'DWPBDEFFXXX',
        blz: '50030600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17401,
        name: 'DenizBank (Wien) Zw Frankfurt',
        bic: 'ESBKDEFFXXX',
        blz: '50030700',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17402,
        name: 'LHB Internationale Handelsbank',
        blz: '50030800',
        available: false
    },
    {
        id: 17403,
        name: 'Lehman Brothers Bankhaus Frankfurt',
        blz: '50030900',
        available: false
    },
    {
        id: 17404,
        name: 'Triodos Bank Deutschland',
        bic: 'TRODDEF1XXX',
        blz: '50031000',
        city: 'Frankfurt am Main',
        available: true
    },
    {id: 17405, name: 'Bankhaus Main', blz: '50031100', available: false},
    {
        id: 17378,
        name: 'Volksbank Bad Oeynhausen-Herford',
        bic: 'GENODEM1HFV',
        blz: '49490070',
        city: 'Herford',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17367,
        name: 'Volksbank Hille -alt-',
        blz: '49063338',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17373,
        name: 'Volksbank Schnathorst',
        bic: 'GENODEM1SNA',
        blz: '49262364',
        city: 'Hüllhorst',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17406,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '50040000',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17361,
        name: 'Volksbank Mindener Land',
        bic: 'GENODEM1MPW',
        blz: '49060127',
        city: 'Minden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17374,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '49440043',
        city: 'Herford',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17372,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '49240096',
        city: 'Bünde',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17364,
        name: 'Volksbank Stemweder Berg -alt-',
        bic: 'GENODEM1STR',
        blz: '49061470',
        city: 'Rahden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17375,
        name: 'Sparkasse Herford',
        bic: 'WLAHDE44XXX',
        blz: '49450120',
        city: 'Herford',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17366,
        name: 'Volksbank Petershagen -alt-',
        blz: '49063296',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17362,
        name: 'Volksbank Minden',
        bic: 'GENODEM1MND',
        blz: '49060392',
        city: 'Minden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17377,
        name: 'Volksbank Enger-Spenge -alt-',
        bic: 'GENODEM1ESP',
        blz: '49461323',
        city: 'Enger',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17371,
        name: 'Volksbank Lübbecker Land',
        bic: 'GENODEM1LUB',
        blz: '49092650',
        city: 'Lübbecke',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17369,
        name: 'Deutsche Bank',
        bic: 'DEUTDE3B490',
        blz: '49070028',
        city: 'Minden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17358,
        name: 'Stadtsparkasse Rahden',
        bic: 'WELADED1RHD',
        blz: '49051065',
        city: 'Rahden',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17370,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF491',
        blz: '49080025',
        city: 'Minden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17360,
        name: 'Stadtsparkasse Porta Westfalica',
        bic: 'WELADED1PWF',
        blz: '49051990',
        city: 'Porta Westfalica',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17376,
        name: 'Sparkasse Bad Salzuflen -alt-',
        blz: '49451210',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17359,
        name: 'Sparkasse Bad Oeynhausen-Porta Westfalica',
        bic: 'WELADED1OEH',
        blz: '49051285',
        city: 'Bad Oeynhausen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17363,
        name: 'Volksbank Bad Oeynhausen -alt-',
        bic: 'GENODEM1UBO',
        blz: '49061298',
        city: 'Bad Oeynhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17313,
        name: 'Sparkasse Gütersloh-Rietberg',
        bic: 'WELADED1GTL',
        blz: '47850065',
        city: 'Gütersloh',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17472,
        name: 'Volksbank Main-Taunus -alt-',
        bic: 'GENODE51KEL',
        blz: '50092200',
        city: 'Kelkheim (Taunus)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17423,
        name: 'Landesbank Hessen-Thür Girozentrale',
        bic: 'HELADEFFXXX',
        blz: '50050000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17425,
        name: 'DekaBank Frankfurt',
        bic: 'DGZFDEFFXXX',
        blz: '50050999',
        city: 'Frankfurt am Main',
        logoPath: '75.svg',
        available: true
    },
    {
        id: 17426,
        name: 'DZ Bank',
        bic: 'GENODE55XXX',
        blz: '50060000',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: true
    },
    {
        id: 17427,
        name: 'DZ BANK (auch Union-Investment Depots)',
        bic: 'GENODEFFXXX',
        blz: '50060400',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: true
    },
    {
        id: 17428,
        name: 'First Cash DZ BANK Frankfurt',
        bic: 'GENODEF1FCS',
        blz: '50060411',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 17429,
        name: 'DZ BANK Gf vK',
        bic: 'GENODEF1VK1',
        blz: '50060412',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 17430,
        name: 'Evangelische Kreditgenossenschaft -Filiale Frankfurt-',
        bic: 'GENODEF1EK4',
        blz: '50060500',
        available: false
    },
    {
        id: 17473,
        name: 'Volksbank Usinger Land Ndl d Frankfurter VB',
        bic: 'GENODE51USI',
        blz: '50092900',
        city: 'Usingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17474,
        name: 'Rüsselsheimer Volksbank',
        bic: 'GENODE51RUS',
        blz: '50093000',
        city: 'Rüsselsheim am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17437,
        name: 'Hüttenberger Bank',
        bic: 'GENODE51HUT',
        blz: '50069455',
        city: 'Hüttenberg',
        available: true
    },
    {
        id: 17469,
        name: 'Sparda-Bank Hessen',
        bic: 'GENODEF1S12',
        blz: '50090500',
        city: 'Frankfurt am Main',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 17471,
        name: 'Spar- u Kreditbank ev-freikirchl Gemeinden',
        bic: 'GENODE51BH2',
        blz: '50092100',
        city: 'Bad Homburg',
        available: true
    },
    {
        id: 17421,
        name: 'Commerzbank Vermögensverwaltung',
        bic: 'COBADEFFXXX',
        blz: '50044444',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17422,
        name: 'Commerzbank Service - BZ',
        bic: 'COBADEFFXXX',
        blz: '50047010',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17424,
        name: '1822direkt - Frankfurter Sparkasse',
        bic: 'HELADEF1822',
        blz: '50050222',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17431,
        name: 'Raiffeisenbank Oberursel',
        bic: 'GENODE51OBU',
        blz: '50061741',
        city: 'Oberursel (Taunus)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17433,
        name: 'Volksbank Grebenhain',
        bic: 'GENODE51GRC',
        blz: '50069146',
        city: 'Grebenhain',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17434,
        name: 'Volksbank Egelsbach -alt-',
        bic: 'GENODE51EGE',
        blz: '50069187',
        city: 'Egelsbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17436,
        name: 'Raiffeisenbank',
        bic: 'GENODE51GWB',
        blz: '50069345',
        city: 'Grävenwiesbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17438,
        name: 'Volksbank Inheiden-Villingen -alt-',
        bic: 'GENODE51HUI',
        blz: '50069464',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17439,
        name: 'Raiffeisenbank Kirtorf',
        bic: 'GENODE51KIF',
        blz: '50069477',
        city: 'Kirtorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17440,
        name: 'Raiffeisenbank Bad Homburg Ndl d FrankfurterVB',
        bic: 'GENODE51BH1',
        blz: '50069693',
        city: 'Bad Homburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17441,
        name: 'Raiffeisenbank Mücke -alt-',
        bic: 'GENODE51MRU',
        blz: '50069828',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17442,
        name: 'Raiffeisen Volksbank',
        bic: 'GENODE51SWB',
        blz: '50069842',
        city: 'Schwabenheim an der Selz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17443,
        name: 'Volksbank Wißmar',
        bic: 'GENODE51WWI',
        blz: '50069976',
        city: 'Wettenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17444,
        name: 'Deutsche Bank Filiale',
        bic: 'DEUTDEFFXXX',
        blz: '50070010',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17445,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBFRA',
        blz: '50070024',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17446,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF502',
        blz: '50073019',
        city: 'Rüsselsheim am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17447,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB502',
        blz: '50073024',
        city: 'Rüsselsheim am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17448,
        name: 'Deutsche Bank Europe',
        bic: 'DEUTDE5XXXX',
        blz: '50073081',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 17449,
        name: 'Commerzbank vormals Dresdner Bank Zw 15',
        bic: 'DRESDEFFI39',
        blz: '50080015',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17314,
        name: 'Sparkasse Rietberg',
        bic: 'WELADED1RTG',
        blz: '47852760',
        city: 'Rietberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17451,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF516',
        blz: '50080055',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17317,
        name: 'Volksbank Bielefeld-Gütersloh',
        bic: 'GENODEM1GTL',
        blz: '47860125',
        city: 'Gütersloh',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17452,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF522',
        blz: '50080057',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17453,
        name: 'Commerzbank vormals Dresdner Bank Gf DrKWSL',
        bic: 'DRESDEFFLDG',
        blz: '50080061',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17454,
        name: 'Commerzbank, GF Wüstenrot BSPK',
        bic: 'DRESDEFFBSP',
        blz: '50080077',
        city: 'Bad Vilbel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17455,
        name: 'Commerzbank vormals Dresdner Bank Bs 80',
        bic: 'DRESDEFFI41',
        blz: '50080080',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17456,
        name: 'Commerzbank vormals Dresdner Bank Gf AVB',
        bic: 'DRESDEFFAVB',
        blz: '50080082',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17457,
        name: 'Commerzbank vormals Dresdner Bank ITGK 3',
        bic: 'DRESDEFFI49',
        blz: '50080086',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17458,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFJ08',
        blz: '50080087',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17459,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFJ09',
        blz: '50080088',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17460,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
        bic: 'DRESDEFFJ10',
        blz: '50080089',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17461,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 7',
        bic: 'DRESDEFFJ11',
        blz: '50080091',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17462,
        name: 'Commerzbank vormals Dresdner Bank Finance and Controlling',
        bic: 'DRESDEFFFCO',
        blz: '50080092',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17463,
        name: 'Commerzbank vormals Dresdner Bank Zw 99',
        bic: 'DRESDEFFI42',
        blz: '50080099',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17464,
        name: 'Commerzbank vormals Dresdner Bank Private Banking Inland',
        bic: 'DRESDEFF500',
        blz: '50080300',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17465,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF502',
        blz: '50083007',
        city: 'Rüsselsheim am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17466,
        name: 'Commerzbank vormals Dresdner Bank in Frankfurt MBP',
        bic: 'DRESDEFFMBP',
        blz: '50083838',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17467,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI01',
        blz: '50089400',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20859,
        name: 'Oberbank AG (15012)',
        bic: 'OBKLAT2LXXX',
        blz: '15012',
        available: false
    },
    {
        id: 17468,
        name: 'VR Smart Finanz Bank',
        bic: 'VRDIDEFFXXX',
        blz: '50090200',
        city: 'Eschborn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17470,
        name: 'PSD Bank Hessen-Thüringen',
        bic: 'GENODEF1P06',
        blz: '50090900',
        city: 'Eschborn',
        available: true
    },
    {
        id: 17502,
        name: 'Volksbank Griesheim',
        bic: 'GENODE51FGH',
        blz: '50190400',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17477,
        name: 'Industrial and Commercial Bank of China',
        bic: 'ICBKDEFFXXX',
        blz: '50110200',
        city: 'Frankfurt am Main',
        logoPath: '90.svg',
        available: false
    },
    {
        id: 17478,
        name: 'DVB Bank',
        bic: 'DVKBDEFFXXX',
        blz: '50110300',
        city: 'Frankfurt am Main',
        logoPath: '68.svg',
        available: false
    },
    {
        id: 17479,
        name: 'AKA Ausfuhrkredit GmbH',
        bic: 'AUSKDEFFXXX',
        blz: '50110400',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17480,
        name: 'NATIXIS Zweigniederlassung Deutschland',
        bic: 'NATXDEFFXXX',
        blz: '50110500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17482,
        name: 'Frankfurter Bankgesellschaft (Deutschland)',
        bic: 'FBGADEF1XXX',
        blz: '50110700',
        city: 'Frankfurt am Main',
        logoPath: '83.svg',
        available: true
    },
    {
        id: 17483,
        name: 'J.P. Morgan',
        bic: 'CHASDEFXXXX',
        blz: '50110800',
        city: 'Frankfurt am Main',
        logoPath: '92.svg',
        available: false
    },
    {
        id: 17484,
        name: 'J.P. Morgan, Internal Reference',
        bic: 'CHASDEFXVR1',
        blz: '50110801',
        city: 'Frankfurt am Main',
        logoPath: '92.svg',
        available: false
    },
    {
        id: 17485,
        name: 'Bank of America N.A. Military Bank',
        bic: 'MNBIDEF1XXX',
        blz: '50110900',
        city: 'Wiesbaden',
        logoPath: '53.svg',
        available: false
    },
    {
        id: 17486,
        name: 'MAINFIRST BANK',
        bic: 'MAIFDEFFXXX',
        blz: '50120000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17487,
        name: 'ICICI Bank UK, Germany Branch',
        bic: 'ICICDEFFXXX',
        blz: '50120100',
        city: 'Eschborn',
        available: false
    },
    {
        id: 17488,
        name: 'Bethmann Bank',
        bic: 'DELBDE33XXX',
        blz: '50120383',
        city: 'Frankfurt am Main',
        logoPath: '62.svg',
        available: true
    },
    {
        id: 17489,
        name: 'Credit Suisse (Deutschland)',
        bic: 'CRESDE55XXX',
        blz: '50120500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17490,
        name: 'Bank of Communications Frankfurt branch',
        bic: 'COMMDEFFXXX',
        blz: '50120600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17491,
        name: 'VakifBank International Wien Zndl Deutschland',
        bic: 'TVBADEFFXXX',
        blz: '50120900',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17492,
        name: 'VTB Bank (Europe) Zndl Frankfurt am Main (Direktbank)',
        bic: 'DOBADEF1XXX',
        blz: '50123400',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17493,
        name: 'National Bank of Pakistan Zndl Frankfurt',
        bic: 'NBPADEFFXXX',
        blz: '50130000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17494,
        name: 'Oppenheim, Sal - jr & Cie',
        bic: 'SOPPDEFFXXX',
        blz: '50130200',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17495,
        name: 'First International Bank',
        bic: 'MASFDEF1XXX',
        blz: '50130300',
        logoPath: '81.svg',
        available: false
    },
    {
        id: 17496,
        name: 'Merck Finck Privatbankiers',
        bic: 'MEFIDEMM501',
        blz: '50130400',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17497,
        name: 'UBS Europe',
        bic: 'UBSWDEFFXXX',
        blz: '50130600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17498,
        name: 'Vietnam Joint Stock Commercial Bank for Industry and Trade',
        bic: 'ICBVDEFFXXX',
        blz: '50131000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17499,
        name: 'Westdeutsche Landesbank Ndl Frankfurt',
        bic: 'WELADEFFXXX',
        blz: '50150000',
        available: false
    },
    {
        id: 17318,
        name: 'Volksbank im Ostmünsterland',
        bic: 'GENODEM1CLL',
        blz: '47861317',
        city: 'Harsewinkel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17503,
        name: 'SEB TZN Clearing',
        bic: 'ESSEDE51SCH',
        blz: '50210111',
        city: 'Frankfurt am Main',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 17504,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE51TZN',
        blz: '50210112',
        city: 'Frankfurt am Main',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 17505,
        name: 'Rabobank International Frankfurt Branch',
        bic: 'RABODEFFTAR',
        blz: '50210200',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17508,
        name: 'Eurohypo ehem Rheinische Hypothekenbank',
        blz: '50210400',
        available: false
    },
    {
        id: 17509,
        name: 'equinet Bank',
        bic: 'EQUNDEFFXXX',
        blz: '50210600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17510,
        name: 'ProCredit Bank, Frankfurt am Main',
        bic: 'PRCBDEFFXXX',
        blz: '50210800',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17511,
        name: 'Citibank Europe Germany Branch',
        bic: 'CITIDEFFXXX',
        blz: '50210900',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17512,
        name: 'UBS Europe',
        bic: 'SMHBDEFFXXX',
        blz: '50220085',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17514,
        name: 'Hauck & Aufhäuser Privatbankiers',
        bic: 'HAUKDEFFXXX',
        blz: '50220900',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17515,
        name: 'ABC International Bank Frankfurt am Main',
        bic: 'ABCADEFFXXX',
        blz: '50230000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17516,
        name: 'Morgan Stanley Bank Internaional',
        bic: 'MSPCDEF1XXX',
        blz: '50230100',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17517,
        name: 'FCB Firmen-Credit Bank',
        bic: 'FCFBDEFFXXX',
        blz: '50230300',
        available: false
    },
    {
        id: 17518,
        name: 'The Royal Bank of Scotland Niederlassung Frankfurt',
        bic: 'ABNADEFFFRA',
        blz: '50230400',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 17519,
        name: 'Isbank',
        bic: 'ISBKDEFXXXX',
        blz: '50230600',
        city: 'Frankfurt am Main',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 17520,
        name: 'Metzler, B. - seel Sohn & Co',
        bic: 'METZDEFFXXX',
        blz: '50230700',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17521,
        name: 'Ikano Bank',
        bic: 'PLFGDE5AXXX',
        blz: '50230800',
        city: 'Wiesbaden',
        logoPath: '89.svg',
        available: false
    },
    {
        id: 17522,
        name: 'Deutsche Leasing Finance',
        bic: 'DLFGDE51XXX',
        blz: '50250200',
        city: 'Bad Homburg',
        available: false
    },
    {
        id: 17523,
        name: 'Barclays Bank Ireland Frankfurt Branch',
        bic: 'BARCDEFFXXX',
        blz: '50310400',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17524,
        name: 'Reiseschecks - Barclays Bank Frankfurt',
        bic: 'BARCDEF1TCS',
        blz: '50310455',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17525,
        name: 'China Construction Bank Ndl Frankfurt',
        bic: 'PCBCDEFFXXX',
        blz: '50310900',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17526,
        name: 'VTB Bank (Europe)',
        bic: 'OWHBDEFFXXX',
        blz: '50320000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17527,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM430',
        blz: '50320191',
        city: 'Frankfurt am Main',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17528,
        name: 'Banco Santander Filiale Frankfurt',
        bic: 'BSCHDEFFXXX',
        blz: '50320500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17529,
        name: 'Attijariwafa bank Europa ZNdl. Frankfurt',
        bic: 'BCMADEFFXXX',
        blz: '50320600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17530,
        name: 'Pictet & Cie (Europe) Ndl Deutschland',
        bic: 'PICTDEFFXXX',
        blz: '50320900',
        city: 'Frankfurt am Main',
        logoPath: '109.svg',
        available: false
    },
    {
        id: 17531,
        name: 'ABN AMRO Bank, Frankfurt Branch',
        bic: 'FTSBDEFAXXX',
        blz: '50324000',
        city: 'Frankfurt am Main',
        logoPath: '189.svg',
        available: false
    },
    {
        id: 17532,
        name: 'ABN AMRO Bank, MoneYou',
        bic: 'FTSBDEFAMYO',
        blz: '50324040',
        city: 'Frankfurt am Main',
        logoPath: '189.svg',
        available: true
    },
    {
        id: 17533,
        name: 'State Bank of India',
        bic: 'SBINDEFFXXX',
        blz: '50330000',
        city: 'Frankfurt am Main',
        logoPath: '121.svg',
        available: false
    },
    {
        id: 17534,
        name: 'MHB-Bank',
        bic: 'MHBFDEFFXXX',
        blz: '50330200',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17476,
        name: 'Volksbank Kelsterbach Ndl d Frankfurter VB',
        bic: 'GENODE51KBH',
        blz: '50093400',
        city: 'Kelsterbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17481,
        name:
            'American Express - Standard Chartered Bank Germany branch, Frankfurt (AMEX)',
        bic: 'SCBLDEFXXXX',
        blz: '50110636',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17500,
        name: 'Frankfurter Volksbank',
        bic: 'FFVBDEFFXXX',
        blz: '50190000',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20860,
        name: 'Oberbank AG (15013)',
        bic: 'OBKLAT2LXXX',
        blz: '15013',
        available: false
    },
    {
        id: 17513,
        name: 'Bank of Scotland (Lloyds Bank)',
        bic: 'BOFSDEF1XXX',
        blz: '50220500',
        city: 'Frankfurt am Main',
        logoPath: '55.svg',
        available: true
    },
    {
        id: 17506,
        name: 'RaboDirect',
        bic: 'RABODEFFDIR',
        blz: '50210212',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17535,
        name: 'The Bank of New York Mellon',
        bic: 'IRVTDEFXXXX',
        blz: '50330300',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17536,
        name: 'BANQUE CHAABI DU MAROC Agentur Frankfurt Ndl. Deutschland',
        bic: 'BCDMDEF1XXX',
        blz: '50330500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17537,
        name: 'Bank Sepah-Iran',
        bic: 'SEPBDEFFXXX',
        blz: '50330600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17538,
        name: 'Valovis Bank',
        bic: 'VCBADEF1XXX',
        blz: '50330700',
        available: false
    },
    {
        id: 17539,
        name: 'The Bank of New York Mellon NL Frankfurt',
        bic: 'BNYMDEF1XXX',
        blz: '50334400',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17540,
        name: 'Bundesbank Zentrale',
        bic: 'MARKDEFFXXX',
        blz: '50400000',
        city: 'Frankfurt am Main',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17541,
        name: 'SEB',
        bic: 'ESSEDE5F505',
        blz: '50510111',
        city: 'Offenbach am Main',
        logoPath: '114.svg',
        available: true
    },
    {id: 17542, name: 'Honda Bank', blz: '50520000', available: false},
    {
        id: 17543,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM467',
        blz: '50520190',
        city: 'Offenbach am Main',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17544,
        name: 'FIDOR Bank Zndl Frankfurt am Main',
        bic: 'FDORDEFFXXX',
        blz: '50522222',
        city: 'Frankfurt am Main',
        logoPath: '80.svg',
        available: false
    },
    {
        id: 17545,
        name: 'Cronbank',
        bic: 'GENODE51CRO',
        blz: '50530000',
        city: 'Dreieich',
        available: true
    },
    {id: 17546, name: 'CRONBANK Zw CS', blz: '50530001', available: false},
    {
        id: 17551,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF505',
        blz: '50570018',
        city: 'Offenbach am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17552,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB505',
        blz: '50570024',
        city: 'Offenbach am Main',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17557,
        name: 'Bundesbank eh Hanau',
        bic: 'MARKDEF1506',
        blz: '50600000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17558,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1HAU',
        blz: '50620700',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 17572,
        name: 'DZB BANK',
        bic: 'DZBMDEF1XXX',
        blz: '50691300',
        city: 'Mainhausen',
        logoPath: '165.svg',
        available: false
    },
    {
        id: 17583,
        name: 'Bundesbank eh Darmstadt',
        bic: 'MARKDEF1508',
        blz: '50800000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17584,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM487',
        blz: '50820292',
        city: 'Darmstadt',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17585,
        name: 'MCE Bank',
        bic: 'MKGMDE51XXX',
        blz: '50835800',
        city: 'Flörsheim am Main',
        available: false
    },
    {
        id: 17587,
        name: 'Landesbank Hessen-Thür Girozentrale',
        bic: 'HELADEFF508',
        blz: '50850049',
        city: 'Darmstadt',
        available: false
    },
    {
        id: 17592,
        name: 'Spar- und Darlehnskasse Zell -alt-',
        bic: 'GENODE51BKZ',
        blz: '50861393',
        city: 'Bad König',
        available: true
    },
    {
        id: 17553,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF505',
        blz: '50580005',
        city: 'Offenbach am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17554,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ12',
        blz: '50580085',
        city: 'Offenbach am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17555,
        name: 'Offenbacher Volksbank -alt-',
        bic: 'GENODE51OF1',
        blz: '50590000',
        city: 'Offenbach am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17556,
        name: 'Volksbank Dreieich',
        bic: 'GENODE51DRE',
        blz: '50592200',
        city: 'Dreieich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17559,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '50640015',
        city: 'Hanau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17589,
        name: 'Sparkasse Odenwaldkreis',
        bic: 'HELADEF1ERB',
        blz: '50851952',
        city: 'Erbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17562,
        name: 'VR Bank Main-Kinzig-Büdingen',
        bic: 'GENODEF1LSR',
        blz: '50661639',
        city: 'Büdingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17563,
        name: 'Volksbank Heldenbergen Ndl d Frankfurter VB',
        bic: 'GENODE51NIH',
        blz: '50661816',
        city: 'Nidderau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17564,
        name: 'Raiffeisenbank Bruchköbel -alt-',
        bic: 'GENODEF1BKO',
        blz: '50662299',
        city: 'Bruchköbel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17565,
        name: 'Raiffeisenbank Maintal Ndl d Frankfurter VB',
        bic: 'RBMFDEF1XXX',
        blz: '50662669',
        city: 'Maintal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17566,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1RDB',
        blz: '50663699',
        city: 'Rodenbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17567,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF506',
        blz: '50670009',
        city: 'Hanau',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17568,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB506',
        blz: '50670024',
        city: 'Hanau',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17569,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF506',
        blz: '50680002',
        city: 'Hanau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17570,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ13',
        blz: '50680085',
        city: 'Hanau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17319,
        name: 'Volksbank Harsewinkel',
        bic: 'GENODEM1HWI',
        blz: '47861518',
        city: 'Harsewinkel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17573,
        name: 'Volksbank Seligenstadt',
        bic: 'GENODE51SEL',
        blz: '50692100',
        city: 'Seligenstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17320,
        name: 'Volksbank Kaunitz',
        bic: 'GENODEM1VKA',
        blz: '47861806',
        city: 'Verl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17574,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '50740048',
        city: 'Gelnhausen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17576,
        name: 'Volksbank -alt-',
        bic: 'GENODE51BUE',
        blz: '50761333',
        city: 'Büdingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17577,
        name: 'Volksbank Büdingen -alt-',
        bic: 'GENODE51NID',
        blz: '50761613',
        city: 'Nidda',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17578,
        name: 'Raiffeisenbank Vogelsberg',
        bic: 'GENODEF1BIR',
        blz: '50763319',
        city: 'Birstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17579,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF524',
        blz: '50780006',
        city: 'Gelnhausen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17580,
        name: 'VR Bank Bad Orb-Gelnhausen',
        bic: 'GENODE51GEL',
        blz: '50790000',
        city: 'Gelnhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17581,
        name: 'Birsteiner Volksbank',
        bic: 'GENODE51BIV',
        blz: '50793300',
        city: 'Birstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17582,
        name: 'VR Bank Wächtersbach/Bad Soden-Salmünster -alt',
        bic: 'GENODE51WBH',
        blz: '50794300',
        city: 'Wächtersbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17586,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '50840005',
        city: 'Darmstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17591,
        name: 'Sparkasse Dieburg',
        bic: 'HELADEF1DIE',
        blz: '50852651',
        city: 'Groß-Umstadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17560,
        name: 'SPARKASSE HANAU',
        bic: 'HELADEF1HAN',
        blz: '50650023',
        city: 'Hanau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17588,
        name: 'Stadt- und Kreis-Sparkasse Darmstadt',
        bic: 'HELADEF1DAS',
        blz: '50850150',
        city: 'Darmstadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17594,
        name: 'Volksbank Gräfenhausen -alt-',
        bic: 'GENODE51WGH',
        blz: '50862311',
        city: 'Weiterstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17595,
        name: 'Vereinigte Volksbank Griesheim-Weiterstadt',
        bic: 'GENODE51GRI',
        blz: '50862408',
        city: 'Griesheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17596,
        name: 'Volksbank Gersprenztal-Otzberg',
        bic: 'GENODE51REI',
        blz: '50862703',
        city: 'Reinheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17549,
        name: 'Raiffeisenbank Offenbach/M.-Bieber',
        bic: 'GENODE51OF2',
        blz: '50560102',
        city: 'Offenbach am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17575,
        name: 'Kreissparkasse Gelnhausen',
        bic: 'HELADEF1GEL',
        blz: '50750094',
        city: 'Gelnhausen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17550,
        name: 'Vereinigte Volksbank Maingau',
        bic: 'GENODE51OBH',
        blz: '50561315',
        city: 'Obertshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17590,
        name: 'Kreissparkasse Groß-Gerau',
        bic: 'HELADEF1GRG',
        blz: '50852553',
        city: 'Groß-Gerau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17561,
        name: 'Sparkasse Langen-Seligenstadt',
        bic: 'HELADEF1SLS',
        blz: '50652124',
        city: 'Seligenstadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17650,
        name: 'Volks- und Raiffeisenbank Weilmünster -alt-',
        bic: 'GENODE51WEM',
        blz: '51192200',
        city: 'Weilmünster',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17599,
        name: 'Volksbank Seeheim-Jugenheim',
        bic: 'GENODE51SJ1',
        blz: '50863317',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17604,
        name: 'VB Mörfelden-Walldorf Ndl d Frankfurter VB',
        bic: 'GENODE51MWA',
        blz: '50865224',
        city: 'Mörfelden-Walldorf',
        available: true
    },
    {
        id: 17621,
        name: 'Bundesbank eh Wiesbaden',
        bic: 'MARKDEF1510',
        blz: '51000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17622,
        name: 'SEB',
        bic: 'ESSEDE5F510',
        blz: '51010111',
        city: 'Wiesbaden',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17623,
        name: 'Aareal Bank',
        bic: 'AARBDE5WXXX',
        blz: '51010400',
        city: 'Wiesbaden',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17624,
        name: 'Aareal Bank Zw L',
        bic: 'AARBDE5W108',
        blz: '51010800',
        city: 'Wiesbaden',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17625,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF510',
        blz: '51020000',
        city: 'Wiesbaden',
        available: true
    },
    {
        id: 17626,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM478',
        blz: '51020186',
        city: 'Wiesbaden',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17638,
        name: 'vr bank Untertaunus',
        bic: 'VRBUDE51XXX',
        blz: '51091700',
        city: 'Idstein',
        logoPath: '171.svg',
        available: true
    },
    {
        id: 17639,
        name: 'vr bank Untertaunus',
        bic: 'VRBUDE51XXX',
        blz: '51091711',
        city: 'Idstein',
        logoPath: '171.svg',
        available: true
    },
    {
        id: 17600,
        name: 'Vereinigte Volksbank Raiffeisenbank',
        bic: 'GENODE51MIC',
        blz: '50863513',
        city: 'Reinheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17651,
        name: 'BNP Paribas Niederlassung Deutschland',
        bic: 'BNPADEFFXXX',
        blz: '51210600',
        city: 'Frankfurt am Main',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 17652,
        name: 'NIBC Bank Zndl Frankfurt am Main',
        bic: 'NZFMDEF1XXX',
        blz: '51210700',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17653,
        name: 'Societe Generale',
        bic: 'SOGEDEFFXXX',
        blz: '51210800',
        city: 'Frankfurt am Main',
        logoPath: '174.svg',
        available: false
    },
    {
        id: 17654,
        name: 'Skandinaviska Enskilda Banken (publ) Frankfurt Branch',
        bic: 'ESSEDEFFXXX',
        blz: '51220200',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17655,
        name: 'SEB Frankfurt SAP (DSK Hyp)',
        bic: 'ESSEDE5F512',
        blz: '51220211',
        city: 'Frankfurt am Main',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 17656,
        name: 'Bank Saderat Iran',
        bic: 'SIHRDEH1FFM',
        blz: '51220400',
        city: 'Frankfurt am Main',
        logoPath: '51.svg',
        available: false
    },
    {
        id: 17601,
        name: 'Volksbank Modautal Modau',
        bic: 'GENODE51ORM',
        blz: '50863906',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17602,
        name: 'Volksbank Modau',
        bic: 'GENODE51ORA',
        blz: '50864322',
        city: 'Ober-Ramstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17603,
        name: 'Volksbank Seeheim-Jugenheim',
        bic: 'GENODE51SJ2',
        blz: '50864808',
        city: 'Seeheim-Jugenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17605,
        name: 'Volksbank',
        bic: 'GENODE51EPT',
        blz: '50865503',
        city: 'Eppertshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17606,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF508',
        blz: '50870005',
        city: 'Darmstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17607,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB508',
        blz: '50870024',
        city: 'Darmstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17608,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF508',
        blz: '50880050',
        city: 'Darmstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17609,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ14',
        blz: '50880085',
        city: 'Darmstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17610,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFJ15',
        blz: '50880086',
        city: 'Darmstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17611,
        name: 'Volksbank Darmstadt - Südhessen',
        bic: 'GENODEF1VBD',
        blz: '50890000',
        city: 'Darmstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17612,
        name: 'Groß-Gerauer Volksbank',
        bic: 'GENODE51GG1',
        blz: '50892500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17641,
        name: 'Kreissparkasse Limburg',
        bic: 'HELADEF1LIM',
        blz: '51150018',
        city: 'Limburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17614,
        name: 'Sparkasse Starkenburg',
        bic: 'HELADEF1HEP',
        blz: '50951469',
        city: 'Heppenheim (Bergstraße)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17615,
        name: 'Raiffeisenbank Ried',
        bic: 'GENODE51RBU',
        blz: '50961206',
        city: 'Bürstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17616,
        name: 'Raiffeisenbank Groß-Rohrheim',
        bic: 'GENODE51GRM',
        blz: '50961312',
        city: 'Groß-Rohrheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17617,
        name: 'Volksbank Weschnitztal',
        bic: 'GENODE51FHO',
        blz: '50961592',
        city: 'Rimbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17618,
        name: 'Volksbank Überwald-Gorxheimertal',
        bic: 'GENODE51ABT',
        blz: '50961685',
        city: 'Abtsteinach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17631,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF510',
        blz: '51080060',
        city: 'Wiesbaden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17632,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ16',
        blz: '51080085',
        city: 'Wiesbaden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17633,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK2',
        bic: 'DRESDEFFJ17',
        blz: '51080086',
        city: 'Wiesbaden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17634,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI20',
        blz: '51089410',
        city: 'Wiesbaden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17635,
        name: 'Wiesbadener Volksbank',
        bic: 'WIBADE5WXXX',
        blz: '51090000',
        city: 'Wiesbaden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17636,
        name: 'Volksbank Eltville -alt-',
        bic: 'GENODE51ELV',
        blz: '51091400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17407,
        name: 'Commerzbank Gf BRS',
        bic: 'COBADEF1BRS',
        blz: '50040033',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17637,
        name: 'Rheingauer Volksbank',
        bic: 'GENODE51RGG',
        blz: '51091500',
        city: 'Geisenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17640,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '51140029',
        city: 'Limburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17643,
        name: 'Volksbank Langendernbach',
        bic: 'GENODE51LDD',
        blz: '51161606',
        city: 'Dornburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17644,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF511',
        blz: '51170010',
        city: 'Limburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17646,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF511',
        blz: '51180041',
        city: 'Limburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17647,
        name: 'Vereinigte Volksbank Limburg',
        bic: 'GENODE51LIM',
        blz: '51190000',
        city: 'Limburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17648,
        name: 'Volksbank Goldner Grund',
        bic: 'GENODE51CAM',
        blz: '51191200',
        city: 'Bad Camberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17649,
        name: 'Volksbank Schupbach',
        bic: 'GENODE51SBH',
        blz: '51191800',
        city: 'Beselich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17642,
        name: 'Kreissparkasse Weilburg',
        bic: 'HELADEF1WEI',
        blz: '51151919',
        city: 'Weilburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17613,
        name: 'Sparkasse Bensheim',
        bic: 'HELADEF1BEN',
        blz: '50950068',
        city: 'Bensheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20861,
        name: 'Oberbank AG (15021)',
        bic: 'OBKLAT2LXXX',
        blz: '15021',
        available: false
    },
    {
        id: 17657,
        name: 'ZIRAAT BANK International',
        bic: 'TCZBDEFFXXX',
        blz: '51220700',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17658,
        name: 'Banco do Brasil',
        bic: 'BRASDEFFXXX',
        blz: '51220800',
        city: 'Frankfurt am Main',
        logoPath: '49.svg',
        available: false
    },
    {
        id: 17659,
        name: 'Morgan Stanley Bank',
        bic: 'MSFFDEFPXXX',
        blz: '51220900',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17660,
        name: 'Morgan Stanley Bank',
        bic: 'MSFFDEFXCND',
        blz: '51220910',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17661,
        name: 'Eurohypo ehem. Deutsche Hypothekenbank',
        blz: '51230100',
        logoPath: '184.svg',
        available: false
    },
    {
        id: 17662,
        name: 'RBS ( Deutschland ) Frankfurt am Main',
        bic: 'ABNADE55FRA',
        blz: '51230400',
        available: false
    },
    {
        id: 17663,
        name:
            'American Express - Standard Chartered Bank Germany branch, Frankfurt (AMEX)',
        bic: 'SCBLDEFXXXX',
        blz: '51230500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17665,
        name: 'Europe ARAB Bank',
        bic: 'ARABDEFFXXX',
        blz: '51230600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17666,
        name: 'Wirecard Bank',
        bic: 'WIREDEMMXXX',
        blz: '51230800',
        city: 'Aschheim',
        available: true
    },
    {
        id: 17667,
        name: 'Wirecard Bank',
        bic: 'WIREDEMMXXX',
        blz: '51230801',
        city: 'Aschheim',
        available: false
    },
    {
        id: 17668,
        name: 'Wirecard Bank',
        bic: 'WIREDEMMXXX',
        blz: '51230802',
        city: 'Aschheim',
        available: false
    },
    {
        id: 17669,
        name: 'Wirecard Bank',
        bic: 'WIREDEMMXXX',
        blz: '51230805',
        city: 'Aschheim',
        available: false
    },
    {
        id: 17671,
        name: 'Bundesbank eh Gießen',
        bic: 'MARKDEF1513',
        blz: '51300000',
        city: 'Frankfurt am Main',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17672,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F513',
        blz: '51310111',
        city: 'Gießen',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17687,
        name: 'Santander Bank',
        bic: 'ESSEDE5F514',
        blz: '51410111',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 17688,
        name: 'Bank of America Merrill Lynch International Zndl Frankfurt',
        bic: 'MELBDEF1XXX',
        blz: '51410600',
        city: 'Frankfurt am Main',
        logoPath: '53.svg',
        available: false
    },
    {
        id: 17689,
        name: 'Bank of China',
        bic: 'BKCHDEFFXXX',
        blz: '51410700',
        city: 'Frankfurt am Main',
        logoPath: '54.svg',
        available: false
    },
    {
        id: 17690,
        name: 'OnVista Bank',
        bic: 'BOURDEFFXXX',
        blz: '51410800',
        city: 'Quickborn',
        available: true
    },
    {
        id: 17691,
        name: 'Misr Bank-Europe',
        bic: 'MIBEDEFFXXX',
        blz: '51420200',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17692,
        name: 'Bank Julius Bär Deutschland',
        bic: 'BAERDEF1XXX',
        blz: '51420300',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17693,
        name: 'Svenska Handelsbanken Deutschland',
        bic: 'HANDDEFFXXX',
        blz: '51420600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17694,
        name: 'Nordea Bank, Niederlassung Frankfurt',
        bic: 'NDEADEFFXXX',
        blz: '51430300',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17695,
        name: 'Nordea Bank, Niederlassung Frankfurt',
        bic: 'NDEADEFF321',
        blz: '51430321',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17696,
        name: 'Goldman Sachs',
        bic: 'GOLDDEFFXXX',
        blz: '51430400',
        city: 'Frankfurt am Main',
        logoPath: '85.svg',
        available: false
    },
    {
        id: 17713,
        name: 'Landbank Horlofftal',
        bic: 'GENODE51REW',
        blz: '51861616',
        city: 'Reichelsheim (Wetterau)',
        logoPath: '96.svg',
        available: true
    },
    {
        id: 17717,
        name: 'Spar- und Darlehnskasse Stockhausen',
        bic: 'GENODE51HSH',
        blz: '51961515',
        city: 'Herbstein',
        available: true
    },
    {
        id: 17712,
        name: 'Volksbank Butzbach',
        bic: 'GENODE51BUT',
        blz: '51861403',
        city: 'Butzbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17664,
        name: 'Standard Chartered Bank Germany Branch',
        bic: 'SCBLDEFXXXX',
        blz: '51230502',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17720,
        name: 'Bundesbank',
        bic: 'MARKDEF1520',
        blz: '52000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17721,
        name: 'SEB',
        bic: 'ESSEDE5F520',
        blz: '52010111',
        city: 'Kassel',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17746,
        name: 'Raiffeisenbank Trendelburg',
        bic: 'GENODEF1TBG',
        blz: '52069103',
        city: 'Trendelburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17723,
        name: 'Landeskreditkasse Kassel',
        bic: 'HELADEFF520',
        blz: '52050000',
        city: 'Kassel',
        available: false
    },
    {
        id: 17730,
        name: 'DZ BANK',
        bic: 'GENODEFF520',
        blz: '52060000',
        city: 'Kassel',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 17731,
        name: 'Kurhessische Landbank',
        bic: 'GENODEF1KS2',
        blz: '52060208',
        city: 'Kassel',
        available: true
    },
    {
        id: 17732,
        name: 'Evangelische Bank Gf',
        bic: 'EKKBDE52XXX',
        blz: '52060400',
        city: 'Kassel',
        available: true
    },
    {
        id: 17733,
        name: 'Evangelische Bank',
        bic: 'GENODEF1EK1',
        blz: '52060410',
        city: 'Kassel',
        available: true
    },
    {
        id: 17734,
        name: 'Genossenschaftsbank Bad Wildungen -alt-',
        blz: '52061210',
        available: false
    },
    {
        id: 17747,
        name: 'Raiffeisenbank Freienhagen-Höringhausen -alt-',
        blz: '52069129',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17744,
        name: 'Spar-u. Kredit-Bank',
        bic: 'GENODEF1GMD',
        blz: '52069029',
        city: 'Gemünden (Wohra)',
        available: true
    },
    {
        id: 17748,
        name: 'Raiffeisenbank Volkmarsen',
        bic: 'GENODEF1VLM',
        blz: '52069149',
        city: 'Volkmarsen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17765,
        name: 'Waldecker Bank',
        bic: 'GENODEF1KBW',
        blz: '52360059',
        city: 'Korbach',
        logoPath: '38.svg',
        available: true
    },
    {
        id: 17766,
        name: 'ReiseBank',
        bic: 'RBAGDEF1XXX',
        blz: '52410300',
        city: 'Frankfurt am Main',
        logoPath: '113.svg',
        available: false
    },
    {
        id: 17767,
        name: 'ReiseBank Gf2',
        bic: 'RBAGDEF1CMI',
        blz: '52410310',
        city: 'Frankfurt am Main',
        logoPath: '113.svg',
        available: false
    },
    {
        id: 17768,
        name: 'KEB Hana Bank (Deutschland)',
        bic: 'KOEXDEFAXXX',
        blz: '52410400',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17769,
        name: 'NEWEDGE GROUP (Frankfurt Branch) Zndl d NewedgeGroup',
        blz: '52410600',
        available: false
    },
    {
        id: 17770,
        name: 'ABN AMRO Clearing Bank, Frankfurt Branch',
        bic: 'FTSBDEFFXXX',
        blz: '52410700',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 17771,
        name: 'Maple Bank',
        bic: 'MPBKDEFFXXX',
        blz: '52410900',
        available: false
    },
    {
        id: 17772,
        name: 'ReiseBank Gf3 vormals Cash Express',
        bic: 'CAGBDEF1XXX',
        blz: '52411000',
        city: 'Frankfurt am Main',
        logoPath: '113.svg',
        available: true
    },
    {
        id: 17773,
        name: 'ReiseBank Gf4 vormals Cash Express',
        bic: 'CAGBDEF1CMI',
        blz: '52411010',
        city: 'Frankfurt am Main',
        logoPath: '113.svg',
        available: false
    },
    {
        id: 17774,
        name: 'Credit Agricole CIB Deutschland',
        bic: 'BSUIDEFFXXX',
        blz: '52420000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17775,
        name: 'SHINHAN BANK EUROPE',
        bic: 'SHBKDEFFXXX',
        blz: '52420300',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17776,
        name: 'Piraeus Bank Frankfurt Branch',
        bic: 'ABGRDEFFXXX',
        blz: '52420600',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17777,
        name: 'SECB Swiss Euro Clearing Bank',
        bic: 'SECGDEFFXXX',
        blz: '52420700',
        city: 'Frankfurt am Main',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 17778,
        name: 'Credit Mutuel - BECM - Ndl Deutschland',
        bic: 'CMCIDEF1XXX',
        blz: '52430000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 17779,
        name: 'Banque Federative Credit Mutuel Ndl Deutschl',
        bic: 'CMCIDEFFXXX',
        blz: '52430100',
        available: false
    },
    {
        id: 17780,
        name: 'Bundesbank eh Fulda',
        bic: 'MARKDEF1530',
        blz: '53000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17749,
        name: 'Raiffeisenbank Bottendorf -alt-',
        blz: '52069183',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17750,
        name: 'Raiffeisenbank Ulmbach -alt-',
        bic: 'GENODEF1STU',
        blz: '52069503',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17751,
        name: 'Frankenberger Bank Raiffeisenbank',
        bic: 'GENODEF1FBK',
        blz: '52069519',
        city: 'Frankenberg (Eder)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17752,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF520',
        blz: '52070012',
        city: 'Kassel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17753,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB520',
        blz: '52070024',
        city: 'Kassel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17754,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF521',
        blz: '52071212',
        city: 'Bad Wildungen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17415,
        name: 'Commerzbank Gf 461',
        bic: 'COBADEFFXXX',
        blz: '50040061',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17757,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK1',
        bic: 'DRESDEFFJ19',
        blz: '52080085',
        city: 'Kassel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17416,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '50040062',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17758,
        name: 'Volksbank Kassel Göttingen',
        bic: 'GENODE51KS1',
        blz: '52090000',
        city: 'Kassel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17759,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '52240006',
        city: 'Eschwege',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17725,
        name: 'Stadtsparkasse Borken (Hessen)',
        bic: 'HELADEF1BOR',
        blz: '52051373',
        city: 'Borken (Hessen)',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17761,
        name: 'VR-Bank Werra-Meißner',
        bic: 'GENODEF1ESW',
        blz: '52260385',
        city: 'Eschwege',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17762,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF522',
        blz: '52270012',
        city: 'Eschwege',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17763,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB522',
        blz: '52270024',
        city: 'Eschwege',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17764,
        name: 'Sparkasse Waldeck-Frankenberg',
        bic: 'HELADEF1KOR',
        blz: '52350005',
        city: 'Korbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17781,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '53040012',
        city: 'Fulda',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17736,
        name: 'VR-Bank Chattengau',
        bic: 'GENODEF1GUB',
        blz: '52062200',
        city: 'Gudensberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17737,
        name: 'VR PartnerBank Chattengau-Schwalm-Eder',
        bic: 'GENODEF1HRV',
        blz: '52062601',
        city: 'Homberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17738,
        name: 'Volks- Raiffeisenbank Gf GAA',
        blz: '52062699',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17739,
        name: 'VR-Bank Spangenberg-Morschen',
        bic: 'GENODEF1SPB',
        blz: '52063369',
        city: 'Spangenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17740,
        name: 'Raiffeisenbank HessenNord',
        bic: 'GENODEF1WOH',
        blz: '52063550',
        city: 'Wolfhagen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17741,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1BTA',
        blz: '52064156',
        city: 'Baunatal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17742,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1CAL',
        blz: '52065220',
        city: 'Calden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17743,
        name: 'Raiffeisenbank Burghaun',
        bic: 'GENODEF1BHN',
        blz: '52069013',
        city: 'Burghaun',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17745,
        name: 'Raiffeisenbank Langenschwarz',
        bic: 'GENODEF1BUR',
        blz: '52069065',
        city: 'Burghaun',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17726,
        name: 'Stadtsparkasse Felsberg',
        bic: 'HELADEF1FEL',
        blz: '52051555',
        city: 'Felsberg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17727,
        name: 'Stadtsparkasse Grebenstein',
        bic: 'HELADEF1GRE',
        blz: '52051877',
        city: 'Grebenstein',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17728,
        name: 'Kreissparkasse Schwalm-Eder',
        bic: 'HELADEF1MEG',
        blz: '52052154',
        city: 'Melsungen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17729,
        name: 'Stadtsparkasse Schwalmstadt',
        bic: 'HELADEF1SWA',
        blz: '52053458',
        city: 'Schwalmstadt',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17760,
        name: 'Sparkasse Werra-Meißner',
        bic: 'HELADEF1ESW',
        blz: '52250030',
        city: 'Eschwege',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17793,
        name: 'VR Bank HessenLand',
        bic: 'GENODE51ALS',
        blz: '53093200',
        city: 'Alsfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17794,
        name: 'AgrarBank',
        bic: 'GENODE51AGR',
        blz: '53093255',
        city: 'Alsfeld',
        available: true
    },
    {
        id: 17795,
        name: 'Bundesbank eh Bad Hersfeld',
        bic: 'MARKDEF1532',
        blz: '53200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17796,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '53240048',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17799,
        name: 'Bankverein Bebra',
        bic: 'GENODEF1BEB',
        blz: '53261202',
        city: 'Bebra',
        available: true
    },
    {
        id: 17824,
        name: 'Sparkasse Donnersberg',
        bic: 'MALADE51ROK',
        blz: '54051990',
        city: 'Rockenhausen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17803,
        name: 'Raiffeisenbank Ronshausen-Marksuhl',
        bic: 'GENODEF1ROH',
        blz: '53262455',
        city: 'Ronshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17804,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF532',
        blz: '53270012',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17806,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF532',
        blz: '53280081',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17815,
        name: 'Bundesbank eh Kaiserslautern',
        bic: 'MARKDEF1540',
        blz: '54000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17816,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM482',
        blz: '54020090',
        city: 'Kaiserslautern',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17817,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 697 Kais',
        bic: 'HYVEDEM1105',
        blz: '54020474',
        available: false
    },
    {
        id: 17818,
        name: 'Service Credit Union Overseas Headquarters',
        bic: 'SCRUDE51XXX',
        blz: '54030011',
        city: 'Sembach',
        available: false
    },
    {
        id: 17834,
        name: 'SEB',
        bic: 'ESSEDE5F542',
        blz: '54210111',
        city: 'Pirmasens',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17835,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM485',
        blz: '54220091',
        city: 'Pirmasens',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17836,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 358 Pirm',
        bic: 'HYVEDEM1081',
        blz: '54220576',
        available: false
    },
    {
        id: 17807,
        name: 'VR-Bankverein Bad Hersfeld-Rotenburg',
        bic: 'GENODE51BHE',
        blz: '53290000',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17830,
        name: 'Volksbank Kaiserslautern',
        bic: 'GENODE61KL1',
        blz: '54090000',
        city: 'Kaiserslautern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17831,
        name: 'Volksbank Lauterecken',
        bic: 'GENODE61LEK',
        blz: '54091700',
        city: 'Lauterecken',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17832,
        name: 'VR Bank Nordwestpfalz -alt-',
        bic: 'GENODE61OB1',
        blz: '54091800',
        city: 'Rockenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17833,
        name: 'Volksbank Glan-Münchweiler',
        bic: 'GENODE61GLM',
        blz: '54092400',
        city: 'Glan-Münchweiler',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17417,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '50040063',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17783,
        name: 'Kreissparkasse Schlüchtern',
        bic: 'HELADEF1SLU',
        blz: '53051396',
        city: 'Schlüchtern',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17418,
        name: 'Commerzbank Gf ZCM',
        bic: 'COBADEFFXXX',
        blz: '50040075',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17419,
        name: 'Commerzbank INT',
        bic: 'COBADEFFXXX',
        blz: '50040099',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 17450,
        name: 'Commerzbank vormals Dresdner Bank Zw 35',
        bic: 'DRESDEFFI40',
        blz: '50080035',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17507,
        name: 'Commerzbank (ehem. Hypothekenbank Frankfurt)',
        bic: 'EHYPDEFFXXX',
        blz: '50210300',
        city: 'Eschborn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 17571,
        name: 'Volksbank Raiffeisenbank Hanau Ndl d Frankf VB',
        bic: 'GENODEF1HUV',
        blz: '50690000',
        city: 'Hanau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17619,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF509',
        blz: '50970004',
        city: 'Bensheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17810,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1EBG',
        blz: '53361724',
        city: 'Ebsdorfergrund',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17811,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF533',
        blz: '53370008',
        city: 'Marburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17813,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF533',
        blz: '53380042',
        city: 'Marburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17814,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF568',
        blz: '53381843',
        city: 'Stadtallendorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17819,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '54040042',
        city: 'Kaiserslautern',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17820,
        name: 'Stadtsparkasse Kaiserslautern',
        bic: 'MALADE51KLS',
        blz: '54050110',
        city: 'Kaiserslautern',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17825,
        name: 'VR-Bank Westpfalz',
        bic: 'GENODE61LAN',
        blz: '54061650',
        city: 'Landstuhl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17826,
        name: 'Raiffeisenbank Donnersberg -alt-',
        bic: 'GENODE61ALB',
        blz: '54062027',
        city: 'Albisheim (Pfrimm)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17827,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB540',
        blz: '54070024',
        city: 'Kaiserslautern',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17828,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM540',
        blz: '54070092',
        city: 'Kaiserslautern',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17829,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF540',
        blz: '54080021',
        city: 'Kaiserslautern',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17839,
        name: 'VR-Bank Südwestpfalz Pirmasens-Zweibrücken',
        bic: 'GENODE61ROA',
        blz: '54261700',
        city: 'Pirmasens',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17840,
        name: 'Raiffeisenbank Vinningen -alt-',
        bic: 'GENODE61VIN',
        blz: '54262330',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17841,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB542',
        blz: '54270024',
        city: 'Pirmasens',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17790,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF530',
        blz: '53070007',
        city: 'Fulda',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17842,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM542',
        blz: '54270096',
        city: 'Pirmasens',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17791,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB530',
        blz: '53070024',
        city: 'Fulda',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17792,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF530',
        blz: '53080030',
        city: 'Fulda',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17797,
        name: 'Sparkasse Bad Hersfeld-Rotenburg',
        bic: 'HELADEF1HER',
        blz: '53250000',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17821,
        name: 'Kreissparkasse Kaiserslautern',
        bic: 'MALADE51KLK',
        blz: '54050220',
        city: 'Kaiserslautern',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17822,
        name: 'Kreissparkasse Kusel',
        bic: 'MALADE51KUS',
        blz: '54051550',
        city: 'Kusel',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17823,
        name: 'Stadtsparkasse Landstuhl -alt-',
        bic: 'MALADE51LAS',
        blz: '54051660',
        city: 'Landstuhl',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17809,
        name: 'Sparkasse Marburg-Biedenkopf',
        bic: 'HELADEF1MAR',
        blz: '53350000',
        city: 'Marburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17846,
        name: 'Bundesbank',
        bic: 'MARKDEF1545',
        blz: '54500000',
        city: 'Ludwigshafen am Rhein',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17847,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 650 Lu',
        bic: 'HYVEDEM1094',
        blz: '54520071',
        available: false
    },
    {
        id: 17848,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM483',
        blz: '54520194',
        city: 'Ludwigshafen am Rhein',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17853,
        name: 'RV Bank Rhein-Haardt',
        bic: 'GENODE61LBS',
        blz: '54561310',
        city: 'Lambsheim',
        available: true
    },
    {
        id: 17857,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM620',
        blz: '54620093',
        city: 'Neustadt an der Weinstraße',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17858,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 660 Ne W',
        bic: 'HYVEDEM1096',
        blz: '54620574',
        available: false
    },
    {
        id: 17868,
        name: 'Evangelische Kreditgenossenschaft - Filiale Speyer-',
        bic: 'GENODEF1EK6',
        blz: '54760900',
        available: false
    },
    {
        id: 17871,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 659 LanP',
        bic: 'HYVEDEM1095',
        blz: '54820674',
        available: false
    },
    {
        id: 17878,
        name: 'Bundesbank',
        bic: 'MARKDEF1550',
        blz: '55000000',
        city: 'Mainz',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17879,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F550',
        blz: '55010111',
        city: 'Mainz',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17880,
        name: 'Aareal Bank GF - BK01 -',
        bic: 'AARBDE5WDOM',
        blz: '55010400',
        city: 'Wiesbaden',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17881,
        name: 'Aareal Bank',
        bic: 'AARBDE5W550',
        blz: '55010424',
        city: 'Mainz',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17882,
        name: 'Aareal Bank Clearing Wiesbaden',
        bic: 'AARBDE5WCLE',
        blz: '55010625',
        city: 'Wiesbaden',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17883,
        name: 'Investitions- und Strukturbank RP',
        bic: 'ISBRDE55XXX',
        blz: '55010800',
        city: 'Mainz',
        available: false
    },
    {
        id: 17884,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF550',
        blz: '55020000',
        city: 'Mainz',
        available: true
    },
    {
        id: 17885,
        name: 'Bausparkasse Mainz',
        bic: 'BKMZDE51XXX',
        blz: '55020100',
        city: 'Mainz',
        logoPath: '59.svg',
        available: false
    },
    {
        id: 17886,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM486',
        blz: '55020486',
        city: 'Mainz',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17887,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33MNZ',
        blz: '55020500',
        city: 'Mainz',
        available: false
    },
    {
        id: 17888,
        name: 'Aareal Bank (vormals Westdeutsche ImmobilienBank)',
        bic: 'IMMODE5MXXX',
        blz: '55020600',
        city: 'Wiesbaden',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 17889,
        name: 'Süd-West-Kreditbank Finanzierung',
        bic: 'SUFGDE51XXX',
        blz: '55020700',
        city: 'Bingen am Rhein',
        available: false
    },
    {
        id: 17890,
        name: 'TARGOBANK Gf FG',
        bic: 'CPLADE55XXX',
        blz: '55030500',
        city: 'Mainz',
        logoPath: '123.svg',
        available: false
    },
    {
        id: 17891,
        name: 'TARGOBANK Direkt',
        bic: 'CPDIDE51XXX',
        blz: '55030533',
        city: 'Mainz',
        logoPath: '123.svg',
        available: false
    },
    {
        id: 17895,
        name: 'ZV Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST550',
        blz: '55050000',
        city: 'Mainz',
        available: false
    },
    {
        id: 17899,
        name: 'Genobank Mainz',
        bic: 'GENODE51MZ6',
        blz: '55060611',
        city: 'Mainz',
        available: true
    },
    {
        id: 17896,
        name: 'Sparkasse Mainz',
        bic: 'MALADE51MNZ',
        blz: '55050120',
        city: 'Mainz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17850,
        name: 'Sparkasse Vorderpfalz',
        bic: 'LUHSDE6AXXX',
        blz: '54550010',
        city: 'Ludwigshafen am Rhein',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17851,
        name: 'Kreissparkasse Rhein-Pfalz',
        bic: 'MALADE51LUH',
        blz: '54550120',
        city: 'Ludwigshafen am Rhein',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17867,
        name: 'Kreis- und Stadtsparkasse Speyer',
        bic: 'MALADE51SPY',
        blz: '54750010',
        city: 'Speyer',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20862,
        name: 'Oberbank AG (15022)',
        bic: 'OBKLAT2LXXX',
        blz: '15022',
        available: false
    },
    {
        id: 17897,
        name: 'VR-Bank Mainz',
        bic: 'GENODE51MZ4',
        blz: '55060321',
        city: 'Mainz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17902,
        name: 'Volksbank Rhein-Selz -alt-',
        bic: 'GENODE51NIS',
        blz: '55061907',
        city: 'Nierstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17844,
        name: 'VR-Bank Pirmasens',
        bic: 'GENODE61PS1',
        blz: '54290000',
        city: 'Pirmasens',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17845,
        name: 'Raiffeisen- u Volksbank Dahn',
        bic: 'GENODE61DAH',
        blz: '54291200',
        city: 'Dahn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17894,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '55040061',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17905,
        name: 'Commerzbank, TF MZ 1',
        bic: 'COBADEFFXXX',
        blz: '55080044',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17910,
        name: 'Sparda-Bank Südwest',
        bic: 'GENODEF1S01',
        blz: '55090500',
        city: 'Mainz',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 17912,
        name: 'Clearingkonto LRP-SI',
        bic: 'MALADE51EMZ',
        blz: '55150098',
        city: 'Mainz',
        available: false
    },
    {
        id: 17913,
        name: 'Pax-Bank',
        bic: 'GENODED1PA4',
        blz: '55160195',
        city: 'Mainz',
        logoPath: '106.svg',
        available: false
    },
    {
        id: 17921,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51094',
        blz: '55190094',
        city: 'Saulheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17928,
        name: 'Bundesbank eh Bad Kreuznach',
        bic: 'MARKDEF1560',
        blz: '56000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17929,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM515',
        blz: '56020086',
        city: 'Bad Kreuznach',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17945,
        name: 'Bundesbank',
        bic: 'MARKDEF1570',
        blz: '57000000',
        city: 'Koblenz',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 17946,
        name: 'SEB',
        bic: 'ESSEDE5F570',
        blz: '57010111',
        city: 'Koblenz',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 17947,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM401',
        blz: '57020086',
        city: 'Koblenz',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 17948,
        name: 'MMV Bank',
        bic: 'MKBKDE51XXX',
        blz: '57020301',
        city: 'Koblenz',
        available: false
    },
    {
        id: 17949,
        name: 'Oyak Anker Bank',
        bic: 'OYAKDE5KXXX',
        blz: '57020500',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 17955,
        name: 'DZ BANK',
        bic: 'GENODEDD570',
        blz: '57060000',
        city: 'Koblenz',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 17922,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '55340041',
        city: 'Worms',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17924,
        name: 'Volksbank Rheindürkheim -alt-',
        blz: '55360784',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17925,
        name: 'VR Bank',
        bic: 'GENODE51AHM',
        blz: '55361202',
        city: 'Alsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17926,
        name: 'Volksbank Bechtheim',
        bic: 'GENODE51BEC',
        blz: '55362071',
        city: 'Bechtheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17927,
        name: 'Volksbank Worms-Wonnegau',
        bic: 'GENODE61WO1',
        blz: '55390000',
        city: 'Worms',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17756,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF520',
        blz: '52080080',
        city: 'Kassel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17932,
        name: 'Raiffeisenbank Kastellaun',
        bic: 'GENODED1KSL',
        blz: '56061151',
        city: 'Kastellaun',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17933,
        name: 'Volksbank Hunsrück-Nahe',
        bic: 'GENODED1KHK',
        blz: '56061472',
        city: 'Simmern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17934,
        name: 'Volksbank Rheinböllen',
        bic: 'GENODED1RBO',
        blz: '56062227',
        city: 'Rheinböllen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17935,
        name: 'Vereinigte Raiffeisenkassen -alt-',
        bic: 'GENODED1VRK',
        blz: '56062577',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17909,
        name: 'Commerzbank, TF MZ 2',
        bic: 'DRESDEFF555',
        blz: '55080088',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17911,
        name: 'Volksbank Alzey-Worms',
        bic: 'GENODE61AZY',
        blz: '55091200',
        city: 'Worms',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17931,
        name: 'Kreissparkasse Rhein-Hunsrück',
        bic: 'MALADE51SIM',
        blz: '56051790',
        city: 'Simmern',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17940,
        name: 'Kreissparkasse Birkenfeld',
        bic: 'BILADE55XXX',
        blz: '56250030',
        city: 'Idar-Oberstein',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17950,
        name: 'Debeka Bausparkasse',
        bic: 'DEBKDE51XXX',
        blz: '57020600',
        city: 'Koblenz',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17953,
        name: 'Kreissparkasse Westerwald',
        bic: 'MALADE51BMB',
        blz: '57051001',
        city: 'Montabaur',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17954,
        name: 'Kreissparkasse Cochem-Zell -alt-',
        bic: 'MALADE51COC',
        blz: '57051870',
        city: 'Cochem',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17914,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE55XXX',
        blz: '55190000',
        city: 'Mainz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17915,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51028',
        blz: '55190028',
        city: 'Bingen am Rhein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17916,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51050',
        blz: '55190050',
        city: 'Oppenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17918,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51065',
        blz: '55190065',
        city: 'Ingelheim am Rhein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17919,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51068',
        blz: '55190068',
        city: 'Nieder-Olm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17920,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51088',
        blz: '55190088',
        city: 'Sprendlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17966,
        name: 'Raiffeisenbank Idarwald -alt-',
        bic: 'GENODED1IDW',
        blz: '57069526',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17965,
        name: 'Raiffeisenbank Welling',
        bic: 'GENODED1WLG',
        blz: '57069361',
        city: 'Welling',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17936,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB560',
        blz: '56070024',
        city: 'Bad Kreuznach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17906,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF550',
        blz: '55080065',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17907,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ20',
        blz: '55080085',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17908,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFJ21',
        blz: '55080086',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17923,
        name: 'Sparkasse Worms-Alzey-Ried',
        bic: 'MALADE51WOR',
        blz: '55350010',
        city: 'Worms',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18025,
        name: 'Vereinigte Volksbank',
        blz: '58790100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17968,
        name: 'VR-Bank Hunsrück-Mosel',
        bic: 'GENODED1MBA',
        blz: '57069806',
        city: 'Morbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17985,
        name: 'Westerwald Bank',
        bic: 'GENODE51WW1',
        blz: '57391800',
        city: 'Hachenburg',
        logoPath: '135.svg',
        available: true
    },
    {
        id: 17986,
        name: 'Bundesbank eh Neuwied',
        bic: 'MARKDEF1574',
        blz: '57400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17998,
        name: 'Bundesbank eh Trier',
        bic: 'MARKDEF1585',
        blz: '58500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 17999,
        name: 'SEB',
        bic: 'ESSEDE5F585',
        blz: '58510111',
        city: 'Trier',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18000,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM437',
        blz: '58520086',
        city: 'Trier',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18004,
        name: 'Pax-Bank',
        bic: 'GENODED1PA3',
        blz: '58560294',
        city: 'Trier',
        logoPath: '106.svg',
        available: true
    },
    {
        id: 17969,
        name: 'Raiffeisenbank Pronsfeld-Waxweiler Gs -alt-',
        blz: '57069858',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18026,
        name: 'Bundesbank',
        bic: 'MARKDEF1590',
        blz: '59000000',
        city: 'Saarbrücken',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18027,
        name: 'SEB',
        bic: 'ESSEDE5F590',
        blz: '59010111',
        city: 'Saarbrücken',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18028,
        name: 'Saarl Investitionskreditbank',
        bic: 'SIKBDE55XXX',
        blz: '59010400',
        city: 'Saarbrücken',
        available: false
    },
    {
        id: 17971,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5M570',
        blz: '57070045',
        city: 'Koblenz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17972,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF570',
        blz: '57080070',
        city: 'Koblenz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17973,
        name: 'Volksbank Koblenz Mittelrhein',
        bic: 'GENODE51KOB',
        blz: '57090000',
        city: 'Koblenz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17975,
        name: 'Volksbank Montabaur-Höhr-Grenzhausen',
        bic: 'GENODE51MON',
        blz: '57091000',
        city: 'Montabaur',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17976,
        name: 'Volksbank Höhr-Grenzhausen -alt-',
        bic: 'GENODE51HGR',
        blz: '57091100',
        city: 'Höhr-Grenzhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17977,
        name: 'Volksbank Boppard -alt-',
        blz: '57091500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17978,
        name: 'Volksbank Rhein-Lahn-Limburg',
        bic: 'GENODE51DIE',
        blz: '57092800',
        city: 'Diez',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17979,
        name: 'Raiffeisenbank Unterwesterwald',
        bic: 'GENODE51ARZ',
        blz: '57263015',
        city: 'Arzbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18002,
        name: 'Sparkasse Trier',
        bic: 'TRISDE55XXX',
        blz: '58550130',
        city: 'Trier',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17981,
        name: 'Volksbank Gebhardshain',
        bic: 'GENODED1GBS',
        blz: '57361476',
        city: 'Gebhardshain',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17982,
        name: 'Raiffeisenbank Niederfischbach -alt-',
        bic: 'GENODED1NFB',
        blz: '57363243',
        city: 'Niederfischbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17983,
        name: 'Volksbank Daaden',
        bic: 'GENODE51DAA',
        blz: '57391200',
        city: 'Daaden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17984,
        name: 'Volksbank Hamm, Sieg',
        bic: 'GENODE51HAM',
        blz: '57391500',
        city: 'Hamm (Sieg)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18020,
        name: 'Sparkasse Mittelmosel-Eifel Mosel Hunsrück',
        bic: 'MALADE51BKS',
        blz: '58751230',
        city: 'Bernkastel-Kues',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17988,
        name: 'Volks- und Raiffeisenbank Neuwied-Linz',
        bic: 'GENODED1NWD',
        blz: '57460117',
        city: 'Neuwied',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17989,
        name: 'Raiffeisenbank Mittelrhein',
        bic: 'GENODED1MRW',
        blz: '57461759',
        city: 'Weißenthurm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17990,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB574',
        blz: '57470024',
        city: 'Neuwied',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17808,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '53340024',
        city: 'Marburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17993,
        name: 'Raiffeisenbank',
        bic: 'GENODED1KEH',
        blz: '57661253',
        city: 'Kehrig',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17812,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB533',
        blz: '53370024',
        city: 'Marburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17994,
        name: 'VR Bank Rhein-Mosel',
        bic: 'GENODED1MPO',
        blz: '57662263',
        city: 'Ochtendung',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17996,
        name: 'Volksbank RheinAhrEifel',
        bic: 'GENODED1BNA',
        blz: '57761591',
        city: 'Bad Neuenahr-Ahrweiler',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17997,
        name: 'Raiffeisenbank Grafschaft-Wachtberg',
        bic: 'GENODED1GRO',
        blz: '57762265',
        city: 'Grafschaft',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18001,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '58540035',
        city: 'Trier',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17992,
        name: 'Kreissparkasse Mayen',
        bic: 'MALADE51MYN',
        blz: '57650010',
        city: 'Mayen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18003,
        name: 'Volksbank Trier',
        bic: 'GENODED1TVB',
        blz: '58560103',
        city: 'Trier',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18005,
        name: 'Volksbank Hermeskeil -alt-',
        bic: 'GENODED1HER',
        blz: '58561250',
        city: 'Hermeskeil',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18006,
        name: 'Volksbank Saarburg -alt-',
        bic: 'GENODED1SRB',
        blz: '58561626',
        city: 'Saarburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18007,
        name: 'Raiffeisenbank Mehring-Leiwen',
        bic: 'GENODED1MLW',
        blz: '58561771',
        city: 'Leiwen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18008,
        name: 'Volksbank Hochwald-Saarburg',
        bic: 'GENODED1HWM',
        blz: '58564788',
        city: 'Saarburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18010,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5M585',
        blz: '58570048',
        city: 'Trier',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18011,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF585',
        blz: '58580074',
        city: 'Trier',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18015,
        name: 'Volksbank Eifel',
        bic: 'GENODED1BIT',
        blz: '58660101',
        city: 'Bitburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17987,
        name: 'Sparkasse Neuwied',
        bic: 'MALADE51NWD',
        blz: '57450120',
        city: 'Neuwied',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18016,
        name: 'Raiffeisenbank Westeifel',
        bic: 'GENODED1WSC',
        blz: '58661901',
        city: 'Arzfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18021,
        name: 'Vereinigte Volksbank Raiffeisenbank',
        bic: 'GENODED1WTL',
        blz: '58760954',
        city: 'Wittlich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18022,
        name: 'Raiffeisenbank Zeller Land',
        bic: 'GENODED1BPU',
        blz: '58761343',
        city: 'Briedel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17974,
        name: 'PSD Bank Koblenz',
        bic: 'GENODEF1P12',
        blz: '57090900',
        city: 'Koblenz',
        available: true
    },
    {
        id: 18024,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5M587',
        blz: '58771242',
        city: 'Bernkastel-Kues',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18012,
        name: 'PSD Bank Trier Ndl der PSD Bank Köln',
        bic: 'GENODEF1P21',
        blz: '58590900',
        city: 'Trier',
        available: true
    },
    {
        id: 17995,
        name: 'Kreissparkasse Ahrweiler',
        bic: 'MALADE51AHR',
        blz: '57751310',
        city: 'Bad Neuenahr-Ahrweiler',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18013,
        name: 'Kreissparkasse Bitburg-Prüm',
        bic: 'MALADE51BIT',
        blz: '58650030',
        city: 'Bitburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18014,
        name: 'Kreissparkasse Vulkaneifel',
        bic: 'MALADE51DAU',
        blz: '58651240',
        city: 'Daun',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17980,
        name: 'Sparkasse Westerwald-Sieg',
        bic: 'MALADE51AKI',
        blz: '57351030',
        city: 'Bad Marienberg (Westerwald)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18029,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM432',
        blz: '59020090',
        city: 'Saarbrücken',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18030,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1SAA',
        blz: '59020700',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 18045,
        name: 'Volksbank Nahe-Schaumberg',
        bic: 'GENODE51NOH',
        blz: '59099550',
        city: 'Nohfelden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18032,
        name: 'Landesbank Saar',
        bic: 'SALADE55XXX',
        blz: '59050000',
        city: 'Saarbrücken',
        logoPath: '95.svg',
        available: true
    },
    {
        id: 18047,
        name: 'VVBS Ver. Volksbanken Saarbrücken-St Ingbert',
        bic: 'GENODE51SB1',
        blz: '59190100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18035,
        name: 'SKG BANK Niederlassung der Deutsche Kreditbank',
        bic: 'SALADE51SKG',
        blz: '59052020',
        available: false
    },
    {
        id: 18048,
        name: 'Volksbank Westliche Saar plus',
        bic: 'GENODE51SLS',
        blz: '59190200',
        city: 'Saarlouis',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18046,
        name: 'Bank 1 Saar',
        bic: 'SABADE5SXXX',
        blz: '59190000',
        city: 'Saarbrücken',
        logoPath: '50.svg',
        available: true
    },
    {
        id: 18056,
        name: 'Bundesbank eh Saarlouis',
        bic: 'MARKDEF1593',
        blz: '59300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18057,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM838',
        blz: '59320087',
        city: 'Saarlouis',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18067,
        name: 'levoBank',
        bic: 'GENODE51LEB',
        blz: '59393000',
        city: 'Lebach',
        available: true
    },
    {
        id: 18074,
        name: 'Bundesbank',
        bic: 'MARKDEF1600',
        blz: '60000000',
        city: 'Stuttgart',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18075,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F600',
        blz: '60010111',
        city: 'Stuttgart',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18076,
        name: 'Aareal Bank',
        bic: 'AARBDE5W600',
        blz: '60010424',
        city: 'Stuttgart',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 18077,
        name: 'Landeskreditbank Baden-Württemberg Förderbank -alt-',
        bic: 'LKBWDE6K600',
        blz: '60010700',
        city: 'Karlsruhe',
        available: false
    },
    {
        id: 18078,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST601',
        blz: '60020030',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18079,
        name: 'Schwäbische Bank',
        bic: 'SCHWDESSXXX',
        blz: '60020100',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18080,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM473',
        blz: '60020290',
        city: 'Stuttgart',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18081,
        name: 'BNP Paribas Ndl. Deutschland (ehem. VON ESSEN Bank)',
        bic: 'VONEDE33STG',
        blz: '60020300',
        city: 'Stuttgart',
        available: false
    },
    {
        id: 18082,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1STU',
        blz: '60020700',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 18083,
        name: 'Mercedes-Benz Bank',
        bic: 'MEBEDE6SDCB',
        blz: '60030000',
        city: 'Stuttgart',
        logoPath: '99.svg',
        available: true
    },
    {
        id: 18084,
        name: 'Bankhaus Bauer, Stuttgart',
        bic: 'BHBADES1XXX',
        blz: '60030100',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18085,
        name: 'Bankhaus Ellwanger & Geiger',
        bic: 'ELGEDES1XXX',
        blz: '60030200',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18086,
        name: 'CreditPlus Bank',
        bic: 'CPLUDES1XXX',
        blz: '60030600',
        city: 'Stuttgart',
        logoPath: '66.svg',
        available: false
    },
    {
        id: 18087,
        name: 'AKTIVBANK',
        bic: 'AKBADES1XXX',
        blz: '60030700',
        city: 'Pforzheim',
        logoPath: '190.svg',
        available: true
    },
    {
        id: 18088,
        name: 'Isbank Fil Stuttgart',
        bic: 'ISBKDEFXSTU',
        blz: '60030900',
        city: 'Stuttgart',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 18090,
        name: 'IBM Deutschland Kreditbank',
        bic: 'IBKBDES1XXX',
        blz: '60035810',
        city: 'Ehningen',
        available: false
    },
    {
        id: 18034,
        name: 'Stadtsparkasse Völklingen',
        bic: 'SALADE51VKS',
        blz: '59051090',
        city: 'Völklingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18051,
        name: 'Volksbank Neunkirchen',
        bic: 'GENODE51NES',
        blz: '59290100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18052,
        name: 'Unsere Volksbank St. Wendeler Land',
        bic: 'GENODE51WEN',
        blz: '59291000',
        city: 'St. Wendel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18053,
        name: 'Volks- und Raiffeisenbank Saarpfalz',
        bic: 'GENODE51BEX',
        blz: '59291200',
        city: 'Homburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18054,
        name: 'Volksbank Spiesen-Elversberg -alt-',
        bic: 'GENODE51SPI',
        blz: '59291300',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18055,
        name: 'Eppelborner Volksbank -alt-',
        bic: 'GENODE51EPP',
        blz: '59292400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18059,
        name: 'Sparkasse Merzig-Wadern',
        bic: 'MERZDE55XXX',
        blz: '59351040',
        city: 'Merzig',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18060,
        name: 'Volksbank Saarlouis',
        bic: 'GENODE51SLF',
        blz: '59390100',
        city: 'Saarlouis',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17874,
        name: 'Raiffeisenbank Oberhaardt-Gäu -alt-',
        bic: 'GENODE61EDH',
        blz: '54861190',
        city: 'Edesheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18062,
        name: 'Volksbank Überherrn',
        bic: 'GENODE51UBH',
        blz: '59391200',
        city: 'Überherrn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18061,
        name: 'Volksbank Untere Saar',
        blz: '59391100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18063,
        name: 'Volksbank Wadgassen',
        blz: '59391800',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18064,
        name: 'Volksbank Dillingen',
        bic: 'GENODE51DSA',
        blz: '59392000',
        city: 'Dillingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18066,
        name: 'Volksbank',
        blz: '59392400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18068,
        name: 'Volksbank Schmelz-Hüttersdorf',
        blz: '59394200',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18070,
        name: 'Volksbank Homburg -alt-',
        blz: '59491000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18071,
        name: 'VR Bank Saarpfalz',
        bic: 'GENODE51HOM',
        blz: '59491114',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18072,
        name: 'Volksbank Blieskastel -alt-',
        blz: '59491200',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18073,
        name: 'VR Bank Saarpfalz',
        bic: 'GENODE51MBT',
        blz: '59491300',
        city: 'Homburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18091,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '60040060',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18092,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '60040061',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18037,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB595',
        blz: '59070070',
        city: 'Saarbrücken',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18038,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF590',
        blz: '59080090',
        city: 'Saarbrücken',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18040,
        name: 'Volksbank Völklingen-Warndt',
        blz: '59091000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18041,
        name: 'Volksbank Sulzbachtal -alt-',
        bic: 'GENODE51SUZ',
        blz: '59091500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18042,
        name: 'Volksbank Quierschied -alt-',
        bic: 'GENODE51QUI',
        blz: '59091800',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18043,
        name: 'Vereinigte Volksbank Saarlouis-Losheim-Sulzbach/Saar',
        bic: 'GENODE51SB2',
        blz: '59092000',
        city: 'Sulzbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18039,
        name: 'PSD Bank RheinNeckarSaar',
        bic: 'GENODEF1P19',
        blz: '59090900',
        city: 'Saarbrücken',
        available: true
    },
    {
        id: 18044,
        name: 'Raiffeisenkasse Wiesbach -alt-',
        bic: 'GENODE51EWI',
        blz: '59099530',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18049,
        name: 'Kreissparkasse St. Wendel',
        bic: 'SALADE51WND',
        blz: '59251020',
        city: 'St. Wendel',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18058,
        name: 'Kreissparkasse Saarlouis',
        bic: 'KRSADE55XXX',
        blz: '59350110',
        city: 'Saarlouis',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18069,
        name: 'Kreissparkasse Saarpfalz',
        bic: 'SALADE51HOM',
        blz: '59450010',
        city: 'Homburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18089,
        name: 'Wüstenrot Bausparkasse',
        bic: 'BSWLDE61XXX',
        blz: '60033000',
        city: 'Ludwigsburg',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18050,
        name: 'Sparkasse Neunkirchen',
        bic: 'SALADE51NKS',
        blz: '59252046',
        city: 'Neunkirchen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18110,
        name: 'Raiffeisenbank',
        bic: 'GENODES1AID',
        blz: '60069206',
        city: 'Aidlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18094,
        name: 'Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADESTXXX',
        blz: '60050000',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18095,
        name: 'ZV Landesbank Baden-Württemberg ISE (LBBW)',
        blz: '60050009',
        city: 'Stuttgart',
        available: false
    },
    {
        id: 18096,
        name:
            'Landesbank Baden-Württemberg/Baden-Württembergische Bank (LBBW/BW-Bank)',
        bic: 'SOLADEST600',
        blz: '60050101',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18097,
        name: 'DZ BANK',
        bic: 'GENODESGXXX',
        blz: '60060000',
        city: 'Stuttgart',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 18098,
        name: 'DZ PRIVATBANK Ndl. Stuttgart',
        bic: 'GENODESTXXX',
        blz: '60060202',
        city: 'Stuttgart',
        available: false
    },
    {
        id: 18100,
        name: 'Evangelische Kreditgenossenschaft -Filiale Stuttgart-',
        bic: 'GENODES1EK2',
        blz: '60060606',
        available: false
    },
    {
        id: 18102,
        name: 'Echterdinger Bank',
        bic: 'GENODES1ECH',
        blz: '60062775',
        city: 'Leinfelden-Echterdingen',
        available: true
    },
    {
        id: 18112,
        name: 'Raiffeisenbank Zndl VB Nordschwarzwald',
        bic: 'GENODES1WAT',
        blz: '60069235',
        city: 'Waldachtal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18111,
        name: 'Genossenschaftsbank Weil im Schönbuch',
        bic: 'GENODES1GWS',
        blz: '60069224',
        city: 'Weil im Schönbuch',
        available: true
    },
    {
        id: 18113,
        name: 'Bopfinger Bank Sechta-Ries',
        bic: 'GENODES1BPF',
        blz: '60069239',
        city: 'Bopfingen',
        available: true
    },
    {
        id: 18114,
        name: 'Raiffeisenbank Gruibingen',
        bic: 'GENODES1RGR',
        blz: '60069242',
        city: 'Gruibingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18115,
        name: 'Raiffeisenbank Bühlertal',
        bic: 'GENODES1RVG',
        blz: '60069245',
        city: 'Bühlertann',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18116,
        name: 'Raiffeisenbank Donau-Iller',
        bic: 'GENODES1RDI',
        blz: '60069251',
        city: 'Erbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18124,
        name: 'Raiffeisenbank Ehingen-Hochsträß',
        bic: 'GENODES1REH',
        blz: '60069346',
        city: 'Ehingen (Donau)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18121,
        name: 'Hegnacher Bank -alt-',
        bic: 'GENODES1HEG',
        blz: '60069325',
        available: false
    },
    {
        id: 18126,
        name: 'VR-Bank Ehningen-Nufringen',
        bic: 'GENODES1EHN',
        blz: '60069355',
        city: 'Ehningen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18125,
        name: 'Raiffeisenbank Reute-Gaisbeuren',
        bic: 'GENODES1RRG',
        blz: '60069350',
        city: 'Bad Waldsee',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18129,
        name: 'Dettinger Bank',
        bic: 'GENODES1DBE',
        blz: '60069387',
        city: 'Dettingen an der Erms',
        available: true
    },
    {
        id: 18131,
        name: 'Uhlbacher Bank',
        bic: 'GENODES1UHL',
        blz: '60069419',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18138,
        name: 'Winterbacher Bank',
        bic: 'GENODES1WBB',
        blz: '60069462',
        city: 'Winterbach',
        logoPath: '34.svg',
        available: true
    },
    {
        id: 18143,
        name: 'Genossenschaftsbank Honhardt',
        blz: '60069511',
        available: false
    },
    {
        id: 18144,
        name: 'Scharnhauser Bank',
        bic: 'GENODES1SCA',
        blz: '60069517',
        city: 'Ostfildern',
        available: true
    },
    {
        id: 18128,
        name: 'Volksbank Dettenhausen',
        bic: 'GENODES1DEH',
        blz: '60069378',
        city: 'Dettenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18147,
        name: 'Löchgauer Bank',
        bic: 'GENODES1LOC',
        blz: '60069538',
        city: 'Löchgau',
        available: true
    },
    {id: 18150, name: 'Walheimer Bank', blz: '60069549', available: false},
    {
        id: 18127,
        name: 'Raiffbk Neukirch Ndl d Volksbank Tettnang',
        bic: 'GENODES1RNK',
        blz: '60069371',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18132,
        name: 'Raiffeisenbank Mittelbiberach',
        bic: 'GENODES1MBI',
        blz: '60069420',
        city: 'Mittelbiberach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18130,
        name: 'Raiffeisenbank Kirchheim-Walheim',
        bic: 'GENODES1KIB',
        blz: '60069417',
        city: 'Kirchheim am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18134,
        name: 'Raiffeisenbank Frankenhardt-Stimpfach',
        bic: 'GENODES1RFS',
        blz: '60069442',
        city: 'Stimpfach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18133,
        name: 'Raiffeisenbank Oberessendorf',
        bic: 'GENODES1OED',
        blz: '60069431',
        city: 'Eberhardzell',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18136,
        name: 'Raiffeisenbank Ottenbach',
        bic: 'GENODES1OTT',
        blz: '60069457',
        city: 'Ottenbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18135,
        name: 'Raiffeisenbank Vordersteinenberg',
        bic: 'GENODES1RVS',
        blz: '60069455',
        city: 'Alfdorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18139,
        name: 'Raiffeisenbank Geislingen-Rosenfeld',
        bic: 'GENODES1RKH',
        blz: '60069463',
        city: 'Rosenfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17877,
        name: 'VR Bank Südliche Weinstraße-Wasgau',
        bic: 'GENODE61BZA',
        blz: '54891300',
        city: 'Bad Bergzabern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18140,
        name: 'Raiffeisenbank Heidenheimer Alb',
        bic: 'GENODES1DEA',
        blz: '60069476',
        city: 'Gerstetten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17930,
        name: 'Sparkasse Rhein-Nahe',
        bic: 'MALADE51KRE',
        blz: '56050180',
        city: 'Bad Kreuznach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18141,
        name: 'Raiffeisenbank Oberer Wald',
        bic: 'GENODES1ROW',
        blz: '60069485',
        city: 'Simmersfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17991,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5M574',
        blz: '57470047',
        city: 'Neuwied',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18009,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB585',
        blz: '58570024',
        city: 'Trier',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18142,
        name: 'Volksbank Murgtal',
        bic: 'GENODES1VMT',
        blz: '60069505',
        city: 'Baiersbronn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18146,
        name: 'Volksbank Brenztal',
        bic: 'GENODES1RNS',
        blz: '60069527',
        city: 'Giengen an der Brenz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18148,
        name: 'Raiffeisenbank Westhausen',
        bic: 'GENODES1RWN',
        blz: '60069544',
        city: 'Westhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18149,
        name: 'Nufringer Bank -Raiffeisen-',
        bic: 'GENODES1NUF',
        blz: '60069545',
        city: 'Nufringen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18151,
        name: 'Raiffeisenbank Aichhalden-Hardt-Sulgen',
        bic: 'GENODES1HAR',
        blz: '60069553',
        city: 'Hardt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18152,
        name: 'Raiffeisenbank Vordere Alb',
        bic: 'GENODES1RVA',
        blz: '60069564',
        city: 'Hülben',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18153,
        name: 'Raiffeisenbank Oberes Schlichemtal',
        bic: 'GENODES1ROS',
        blz: '60069593',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18386,
        name: 'Volksbank Mössingen',
        bic: 'GENODES1VMO',
        blz: '64161956',
        city: 'Mössingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18446,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '65341204',
        city: 'Balingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18449,
        name: 'Sparkasse Zollernalb',
        bic: 'SOLADES1BAL',
        blz: '65351260',
        city: 'Balingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18154,
        name: 'Raiffeisenbank Schrozberg-Rot am See',
        bic: 'GENODES1SBB',
        blz: '60069595',
        city: 'Schrozberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18099,
        name: 'Volksbank am Württemberg',
        bic: 'GENODES1UTV',
        blz: '60060396',
        city: 'Fellbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18101,
        name: 'VR-Bank Stuttgart',
        bic: 'GENODES1FIL',
        blz: '60060893',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18103,
        name: 'Volksbank Strohgäu',
        bic: 'GENODES1MCH',
        blz: '60062909',
        city: 'Korntal-Münchingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18104,
        name: 'Raiffeisenbank Dellmensingen',
        bic: 'GENODES1DMS',
        blz: '60069017',
        city: 'Erbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18106,
        name: 'Raiffeisenbank Bühlertal',
        bic: 'GENODES1RVG',
        blz: '60069075',
        city: 'Vellberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18107,
        name: 'Raiffeisenbank Römerstein -alt-',
        bic: 'GENODES1ROM',
        blz: '60069126',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18108,
        name: 'Raiffeisenbank Sondelfingen',
        bic: 'GENODES1RSF',
        blz: '60069147',
        city: 'Reutlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18109,
        name: 'Raiffeisenbank Steinheim',
        bic: 'GENODES1SAA',
        blz: '60069158',
        city: 'Steinheim am Albuch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15999,
        name: 'Hamburger Sparkasse (Haspa)',
        bic: 'HASPDEHHXXX',
        blz: '20050550',
        city: 'Hamburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18158,
        name: 'Erligheimer Bank -alt-',
        bic: 'GENODES1EHB',
        blz: '60069669',
        city: 'Erligheim',
        available: true
    },
    {
        id: 18174,
        name: 'Genossenschaftsbank Grabenstetten',
        bic: 'GENODES1GBG',
        blz: '60069780',
        available: false
    },
    {
        id: 18179,
        name: 'Darmsheimer Bank',
        bic: 'GENODES1DHB',
        blz: '60069842',
        city: 'Sindelfingen',
        available: true
    },
    {
        id: 18180,
        name: 'Enztalbank',
        bic: 'GENODES1VAI',
        blz: '60069858',
        city: 'Vaihingen an der Enz',
        available: true
    },
    {
        id: 18181,
        name: 'Federseebank',
        bic: 'GENODES1FED',
        blz: '60069860',
        city: 'Bad Buchau',
        available: true
    },
    {
        id: 18188,
        name: 'Berkheimer Bank',
        bic: 'GENODES1BHB',
        blz: '60069927',
        city: 'Esslingen am Neckar',
        available: true
    },
    {
        id: 18207,
        name: 'Südwestbank',
        bic: 'SWBSDESSXXX',
        blz: '60090700',
        city: 'Stuttgart',
        logoPath: '122.svg',
        available: true
    },
    {
        id: 18209,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 434 Stgt',
        bic: 'HYVEDEM1085',
        blz: '60120050',
        available: false
    },
    {
        id: 18210,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF600',
        blz: '60120200',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18211,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33STG',
        blz: '60120500',
        city: 'Stuttgart',
        available: false
    },
    {
        id: 18212,
        name: 'FFS Bank',
        bic: 'GENODED1FFS',
        blz: '60130100',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 18213,
        name: 'Bundesbank eh Waiblingen',
        bic: 'MARKDEF1602',
        blz: '60200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18214,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST602',
        blz: '60220030',
        available: false
    },
    {
        id: 21005,
        name: 'VR Bank (Gf GAA)',
        bic: 'GENODED1NLD',
        blz: '30560591',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21008,
        name: 'Volksbank Düsseldorf Neuss (Gf GAA)',
        bic: 'GENODED1DNE',
        blz: '30160266',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19279,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1UFF',
        blz: '76561979',
        city: 'Uffenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20983,
        name: 'Raiffeisen Kapitalanlage Gesellschaft mit beschränkter Haftung',
        blz: '76280',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19283,
        name: 'Commerzbank Bamberg',
        bic: 'COBADEFFXXX',
        blz: '77040080',
        city: 'Bamberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 21058,
        name: 'Volksbank Koblenz Mittelrhein GAA',
        bic: 'GENODE51KOB',
        blz: '57090010',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15860,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK) F 848',
        bic: 'DEUTDEDB110',
        blz: '10070848',
        city: 'Berlin',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 21016,
        name: 'ZVC FK 51 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010051',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 15895,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB160',
        blz: '12070024',
        city: 'Potsdam',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20991,
        name: 'Sparkasse Oberösterreich Kapitalanlagegesellschaft m.b.H.',
        blz: '76244',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 15939,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF150',
        blz: '15080000',
        city: 'Neubrandenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 15989,
        name: 'Commerzbank, CC SP',
        bic: 'COBADEFFXXX',
        blz: '20040020',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 18216,
        name: 'Kreissparkasse Waiblingen',
        bic: 'SOLADES1WBN',
        blz: '60250010',
        city: 'Waiblingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 21015,
        name: 'ZVC FK 52 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010052',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21018,
        name: 'ZVC FK 56 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010056',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21019,
        name: 'ZVC FK 55 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010055',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21020,
        name: 'ZVC FK 54 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010054',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21021,
        name: 'ZVC FK 59 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010059',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21026,
        name: 'ZVC FK 63 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010063',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21027,
        name: 'ZVC FK 62 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010062',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21028,
        name: 'ZVC FK 61 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010061',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21030,
        name: 'ZVC FK 68 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010068',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21031,
        name: 'ZVC FK 65 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010065',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21032,
        name: 'ZVC FK 69 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010069',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21033,
        name: 'Vereinigte Volksbank GAA',
        bic: 'GENODEF1HUD',
        blz: '28062299',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21034,
        name: 'Volksbank Raiffeisenbank Nürnberg (Gf GAA)',
        bic: 'GENODEF1N02',
        blz: '76060620',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21035,
        name: 'Kieler Volksbank (Gf GAA)',
        bic: 'GENODEF1KIL',
        blz: '21090099',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18186,
        name: 'Raiffeisenbank',
        bic: 'GENODES1ERL',
        blz: '60069911',
        city: 'Erlenbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 21038,
        name: 'ZVC FK 74 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010074',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21039,
        name: 'ZVC FK 73 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010073',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 18450,
        name: 'Volksbank Heuberg',
        bic: 'GENODES1HBM',
        blz: '65361469',
        city: 'Meßstetten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18184,
        name: 'VR-Bank Alb',
        bic: 'GENODES1RUW',
        blz: '60069904',
        city: 'Sonnenbühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18187,
        name: 'Volksbank Glatten-Wittendorf -alt-',
        bic: 'GENODES1VBG',
        blz: '60069926',
        city: 'Glatten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18189,
        name: 'Raiffeisenbank',
        bic: 'GENODES1BGH',
        blz: '60069931',
        city: 'Berghülen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16017,
        name: 'Raiffeisenbank Travemünde',
        bic: 'GENODEF1RLT',
        blz: '20069882',
        city: 'Lübeck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18183,
        name: 'Volksbank Freiberg und Umgebung',
        bic: 'GENODES1PLE',
        blz: '60069896',
        city: 'Pleidelsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 21036,
        name: 'Volksbank Bremen-Nord (Gf GAA)',
        bic: 'GENODEF1HB2',
        blz: '29190399',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20824,
        name: 'Deutsche Bank Österreich AG',
        bic: 'SOPPATWWXXX',
        blz: '19999',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 18208,
        name: 'PSD Bank RheinNeckarSaar',
        bic: 'GENODEF1P20',
        blz: '60090900',
        city: 'Stuttgart',
        available: true
    },
    {
        id: 20987,
        name: 'Raiffeisen Kredit Garantiegesellschaft m.b.H.',
        blz: '34799',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15920,
        name: 'Sparkasse Schwerin -alt-',
        bic: 'NOLADE21SNS',
        blz: '14051462',
        city: 'Schwerin',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18217,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST820',
        blz: '60250184',
        available: false
    },
    {
        id: 18218,
        name: 'Fellbacher Bank',
        bic: 'GENODES1FBB',
        blz: '60261329',
        city: 'Fellbach',
        available: true
    },
    {
        id: 18227,
        name: 'Volksbank Winnenden',
        bic: 'GENODES1WIN',
        blz: '60291510',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18231,
        name: 'Commerzbank Sindelfingen',
        bic: 'COBADEFFXXX',
        blz: '60340071',
        city: 'Sindelfingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18221,
        name: 'Korber Bank',
        bic: 'GENODES1KOR',
        blz: '60262063',
        city: 'Korb',
        available: true
    },
    {
        id: 18228,
        name: 'Bundesbank eh Sindelfingen',
        bic: 'MARKDEF1603',
        blz: '60300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18229,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST607',
        blz: '60320030',
        available: false
    },
    {
        id: 18230,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM858',
        blz: '60320291',
        city: 'Böblingen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18239,
        name: 'Bundesbank eh Ludwigsburg',
        bic: 'MARKDEF1604',
        blz: '60400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18240,
        name: 'Wüstenrot Bank Pfandbriefbank',
        bic: 'WUEHDE61XXX',
        blz: '60410600',
        city: 'Ludwigsburg',
        available: false
    },
    {
        id: 18241,
        name: 'Wüstenrot Bank Pfandbriefbank',
        bic: 'WBAGDE61XXX',
        blz: '60420000',
        city: 'Ludwigsburg',
        available: true
    },
    {
        id: 18242,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM860',
        blz: '60420186',
        city: 'Ludwigsburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18243,
        name: 'RSB Retail+Service Bank',
        bic: 'SABUDES1XXX',
        blz: '60422000',
        city: 'Kornwestheim',
        available: true
    },
    {
        id: 18244,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST604',
        blz: '60430060',
        available: false
    },
    {
        id: 18245,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST605',
        blz: '60431061',
        available: false
    },
    {
        id: 18248,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST800',
        blz: '60450193',
        available: false
    },
    {
        id: 18263,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST610',
        blz: '61020030',
        available: false
    },
    {
        id: 18264,
        name: 'Bankhaus Gebr. Martin',
        bic: 'MARBDE6GXXX',
        blz: '61030000',
        city: 'Göppingen',
        available: false
    },
    {
        id: 18267,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST870',
        blz: '61050181',
        available: false
    },
    {
        id: 18233,
        name: 'Raiffeisenbank Weissach',
        bic: 'GENODES1WES',
        blz: '60361923',
        city: 'Weissach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18273,
        name: 'Bundesbank eh Esslingen',
        bic: 'MARKDEF1611',
        blz: '61100000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18274,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST611',
        blz: '61120030',
        available: false
    },
    {
        id: 18275,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM859',
        blz: '61120286',
        city: 'Esslingen am Neckar',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18278,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST840',
        blz: '61150185',
        available: false
    },
    {
        id: 18234,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF601',
        blz: '60380002',
        city: 'Böblingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18235,
        name: 'Vereinigte Volksbank',
        bic: 'GENODES1BBV',
        blz: '60390000',
        city: 'Sindelfingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18236,
        name: 'Volksbank Leonberg-Strohgäu',
        bic: 'GENODES1LEO',
        blz: '60390300',
        city: 'Leonberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18237,
        name: 'Volksbank Herrenberg-Nagold-Rottenburg',
        bic: 'GENODES1VBH',
        blz: '60391310',
        city: 'Herrenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18238,
        name: 'VR-Bank Magstadt-Weissach',
        bic: 'GENODES1MAG',
        blz: '60391420',
        city: 'Magstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18246,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '60440073',
        city: 'Ludwigsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18249,
        name: 'Volksbank Freiberg und Umgebung',
        bic: 'GENODES1EGL',
        blz: '60460142',
        city: 'Ludwigsburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18250,
        name: 'Volksbank Markgröningen',
        blz: '60461809',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18251,
        name: 'VR-Bank Asperg-Markgröningen',
        bic: 'GENODES1AMT',
        blz: '60462808',
        city: 'Möglingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18252,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB604',
        blz: '60470024',
        city: 'Ludwigsburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18253,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS604',
        blz: '60470082',
        city: 'Ludwigsburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18254,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF604',
        blz: '60480008',
        city: 'Ludwigsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16150,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB212',
        blz: '21270024',
        city: 'Neumünster',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18256,
        name: 'VR-Bank Neckar-Enz',
        bic: 'GENODES1VBB',
        blz: '60491430',
        city: 'Bönnigheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18255,
        name: 'Volksbank Ludwigsburg',
        bic: 'GENODES1LBG',
        blz: '60490150',
        city: 'Ludwigsburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18257,
        name: 'Raiffeisenbank',
        bic: 'GENODES1BIF',
        blz: '60661369',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18259,
        name: 'Raiffeisenbank im Kreis Calw',
        bic: 'GENODES1RCW',
        blz: '60663084',
        city: 'Neubulach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18260,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB659',
        blz: '60670024',
        city: 'Stuttgart',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18261,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS659',
        blz: '60670070',
        city: 'Stuttgart',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18262,
        name: 'Volksbank Maulbronn-Oberderdingen -alt-',
        bic: 'GENODES1VMB',
        blz: '60691440',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18265,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '61040014',
        city: 'Göppingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18268,
        name: 'Volksbank Göppingen',
        bic: 'GENODES1VGP',
        blz: '61060500',
        city: 'Göppingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18270,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS610',
        blz: '61070078',
        city: 'Göppingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18271,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF610',
        blz: '61080006',
        city: 'Göppingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18272,
        name: 'Volksbank-Raiffeisenbank Deggingen',
        bic: 'GENODES1DGG',
        blz: '61091200',
        city: 'Deggingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18276,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '61140071',
        city: 'Esslingen am Neckar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18222,
        name: 'Kerner Volksbank',
        bic: 'GENODES1KRN',
        blz: '60262693',
        city: 'Kernen im Remstal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18223,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB606',
        blz: '60270024',
        city: 'Waiblingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18224,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS606',
        blz: '60270073',
        city: 'Waiblingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18225,
        name: 'Volksbank Rems -alt-',
        bic: 'GENODES1VWN',
        blz: '60290110',
        city: 'Waiblingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18226,
        name: 'Volksbank Backnang',
        bic: 'GENODES1VBK',
        blz: '60291120',
        city: 'Backnang',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18247,
        name: 'Kreissparkasse Ludwigsburg',
        bic: 'SOLADES1LBG',
        blz: '60450050',
        city: 'Ludwigsburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18266,
        name: 'Kreissparkasse Göppingen',
        bic: 'GOPSDE6GXXX',
        blz: '61050000',
        city: 'Göppingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18277,
        name: 'Kreissparkasse Esslingen-Nürtingen',
        bic: 'ESSLDE66XXX',
        blz: '61150020',
        city: 'Esslingen am Neckar',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18232,
        name: 'Kreissparkasse Böblingen',
        bic: 'BBKRDE6BXXX',
        blz: '60350130',
        city: 'Böblingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18282,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF611',
        blz: '61180004',
        city: 'Esslingen am Neckar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18283,
        name: 'Volksbank Esslingen',
        bic: 'GENODES1ESS',
        blz: '61190110',
        city: 'Esslingen am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18285,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST612',
        blz: '61220030',
        available: false
    },
    {
        id: 18289,
        name: 'Genossenschaftsbank Wolfschlugen',
        bic: 'GENODES1WLF',
        blz: '61262258',
        city: 'Wolfschlugen',
        available: true
    },
    {
        id: 18290,
        name: 'Bernhauser Bank',
        bic: 'GENODES1BBF',
        blz: '61262345',
        city: 'Filderstadt',
        logoPath: '61.svg',
        available: true
    },
    {
        id: 18284,
        name: 'Volksbank Plochingen',
        bic: 'GENODES1VBP',
        blz: '61191310',
        city: 'Plochingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18300,
        name: 'Bundesbank eh Aalen',
        bic: 'MARKDEF1614',
        blz: '61400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18301,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM272',
        blz: '61420086',
        city: 'Aalen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18302,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST614',
        blz: '61430000',
        available: false
    },
    {
        id: 18305,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST420',
        blz: '61450191',
        available: false
    },
    {
        id: 18309,
        name: 'Bundesbank eh Heilbronn',
        bic: 'MARKDEF1620',
        blz: '62000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18310,
        name: 'Hoerner-Bank',
        bic: 'HOEBDE61XXX',
        blz: '62020000',
        city: 'Heilbronn',
        available: true
    },
    {
        id: 18311,
        name: 'FCA Bank Deutschland',
        bic: 'FBHNDE61XXX',
        blz: '62020100',
        city: 'Heilbronn',
        available: false
    },
    {
        id: 18312,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST620',
        blz: '62030050',
        available: false
    },
    {
        id: 18313,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST624',
        blz: '62030058',
        available: false
    },
    {
        id: 18314,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST625',
        blz: '62030059',
        available: false
    },
    {
        id: 18315,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST626',
        blz: '62030060',
        available: false
    },
    {
        id: 18318,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST400',
        blz: '62050181',
        available: false
    },
    {
        id: 18330,
        name: 'Bundesbank eh Schwäbisch Hall',
        bic: 'MARKDEF1622',
        blz: '62200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18332,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST622',
        blz: '62230050',
        available: false
    },
    {
        id: 18335,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST416',
        blz: '62250182',
        available: false
    },
    {
        id: 18286,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '61240048',
        city: 'Nürtingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18287,
        name: 'Raiffeisenbank Teck',
        bic: 'GENODES1TEC',
        blz: '61261213',
        city: 'Owen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18288,
        name: 'VR Bank Hohenneuffen-Teck',
        bic: 'GENODES1HON',
        blz: '61261339',
        city: 'Frickenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18292,
        name: 'Volksbank Kirchheim-Nürtingen',
        bic: 'GENODES1NUE',
        blz: '61290120',
        city: 'Nürtingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18293,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '61340079',
        city: 'Schwäbisch Gmünd',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18294,
        name: 'Raiffeisenbank Rosenstein',
        bic: 'GENODES1HEU',
        blz: '61361722',
        city: 'Heubach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18295,
        name: 'Raiffeisenbank Mutlangen',
        bic: 'GENODES1RML',
        blz: '61361975',
        city: 'Mutlangen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18296,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB613',
        blz: '61370024',
        city: 'Schwäbisch Gmünd',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18297,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS613',
        blz: '61370086',
        city: 'Schwäbisch Gmünd',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18298,
        name: 'Volksbank Schwäbisch Gmünd',
        bic: 'GENODES1VGD',
        blz: '61390140',
        city: 'Schwäbisch Gmünd',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18299,
        name: 'Volksbank Welzheim',
        bic: 'GENODES1WEL',
        blz: '61391410',
        city: 'Welzheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18303,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '61440086',
        city: 'Aalen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18306,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF614',
        blz: '61480001',
        city: 'Aalen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18307,
        name: 'VR-Bank Ostalb',
        bic: 'GENODES1AAV',
        blz: '61490150',
        city: 'Aalen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18308,
        name: 'VR-Bank Ellwangen',
        bic: 'GENODES1ELL',
        blz: '61491010',
        city: 'Ellwangen (Jagst)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16844,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBESS',
        blz: '36070024',
        city: 'Essen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18319,
        name: 'Volksbank Sulmtal',
        bic: 'GENODES1VOS',
        blz: '62061991',
        city: 'Obersulm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16905,
        name: 'Raiffeisenbank Grevenbroich',
        bic: 'GENODED1GRB',
        blz: '37069306',
        city: 'Grevenbroich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18320,
        name: 'Volksbank Beilstein-Ilsfeld-Abstatt',
        bic: 'GENODES1BIA',
        blz: '62062215',
        city: 'Beilstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18321,
        name: 'Volksbank Flein-Talheim',
        bic: 'GENODES1VFT',
        blz: '62062643',
        city: 'Flein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18322,
        name: 'VBU Volksbank im Unterland',
        bic: 'GENODES1VLS',
        blz: '62063263',
        city: 'Brackenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18324,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS620',
        blz: '62070081',
        city: 'Heilbronn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18325,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF620',
        blz: '62080012',
        city: 'Heilbronn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18326,
        name: 'Volksbank Heilbronn',
        bic: 'GENODES1VHN',
        blz: '62090100',
        city: 'Heilbronn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18327,
        name: 'Volksbank Brackenheim-Güglingen',
        bic: 'GENODES1VBR',
        blz: '62091400',
        city: 'Brackenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18328,
        name: 'Volksbank Möckmühl',
        bic: 'GENODES1VMN',
        blz: '62091600',
        city: 'Möckmühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18329,
        name: 'Volksbank Hohenlohe',
        bic: 'GENODES1VHL',
        blz: '62091800',
        city: 'Öhringen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18333,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '62240048',
        city: 'Schwäbisch Hall',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18304,
        name: 'Kreissparkasse Ostalb',
        bic: 'OASPDE6AXXX',
        blz: '61450050',
        city: 'Aalen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18336,
        name: 'Sparkasse Hohenlohekreis',
        bic: 'SOLADES1KUN',
        blz: '62251550',
        city: 'Künzelsau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18337,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF622',
        blz: '62280012',
        city: 'Schwäbisch Hall',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18338,
        name: 'VR Bank Schwäbisch Hall-Crailsheim',
        bic: 'GENODES1SHA',
        blz: '62290110',
        city: 'Schwäbisch Hall',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18339,
        name: 'Crailsheimer Volksbank -alt-',
        bic: 'GENODES1CRV',
        blz: '62291020',
        city: 'Crailsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18281,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS611',
        blz: '61170076',
        city: 'Esslingen am Neckar',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18317,
        name: 'Kreissparkasse Heilbronn',
        bic: 'HEISDE66XXX',
        blz: '62050000',
        city: 'Heilbronn',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18331,
        name: 'Bausparkasse Schwäbisch Hall',
        bic: 'BSHHDE61XXX',
        blz: '62220000',
        city: 'Schwäbisch Hall',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18340,
        name: 'Kreissparkasse Mergentheim -alt-',
        blz: '62351060',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18334,
        name: 'Sparkasse Schwäbisch Hall-Crailsheim',
        bic: 'SOLADES1SHA',
        blz: '62250030',
        city: 'Schwäbisch Hall',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18343,
        name: 'Volksbank Vorbach-Tauber',
        bic: 'GENODES1VVT',
        blz: '62391420',
        city: 'Weikersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18344,
        name: 'Bundesbank',
        bic: 'MARKDEF1630',
        blz: '63000000',
        city: 'Ulm',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18345,
        name: 'SEB',
        bic: 'ESSEDE5F630',
        blz: '63010111',
        city: 'Ulm',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18346,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM461',
        blz: '63020086',
        city: 'Ulm',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18347,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST630',
        blz: '63020130',
        available: false
    },
    {
        id: 18348,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 274 Ulm',
        bic: 'HYVEDEM1080',
        blz: '63020450',
        available: false
    },
    {
        id: 18351,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST440',
        blz: '63050181',
        available: false
    },
    {
        id: 18349,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '63040053',
        city: 'Ulm',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18350,
        name: 'Sparkasse Ulm',
        bic: 'SOLADES1ULM',
        blz: '63050000',
        city: 'Ulm',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18358,
        name: 'Donau-Iller Bank',
        bic: 'GENODES1EHI',
        blz: '63091010',
        city: 'Ehingen (Donau)',
        available: true
    },
    {
        id: 18361,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM271',
        blz: '63220090',
        city: 'Heidenheim an der Brenz',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18366,
        name: 'Bundesbank',
        bic: 'MARKDEF1640',
        blz: '64000000',
        city: 'Reutlingen',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18367,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST640',
        blz: '64020030',
        available: false
    },
    {
        id: 18368,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM374',
        blz: '64020186',
        city: 'Reutlingen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18372,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST460',
        blz: '64050181',
        available: false
    },
    {
        id: 18380,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST641',
        blz: '64120030',
        available: false
    },
    {
        id: 18383,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST470',
        blz: '64150182',
        available: false
    },
    {id: 18393, name: 'Bankhaus J. Faißt', blz: '64232000', available: false},
    {
        id: 18399,
        name: 'Murgtalbank Mitteltal - Obertal -alt-',
        bic: 'GENODES1MMO',
        blz: '64261626',
        city: 'Baiersbronn',
        available: true
    },
    {
        id: 18527,
        name: 'Bundesbank eh Offenburg',
        bic: 'MARKDEF1664',
        blz: '66400000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18352,
        name: 'VR-Bank Langenau-Ulmer Alb',
        bic: 'GENODES1LBK',
        blz: '63061486',
        city: 'Langenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18354,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS630',
        blz: '63070088',
        city: 'Ulm',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18355,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF630',
        blz: '63080015',
        city: 'Ulm',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18357,
        name: 'Volksbank Ulm-Biberach',
        bic: 'ULMVDE66XXX',
        blz: '63090100',
        city: 'Ulm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18359,
        name: 'Volksbank Blaubeuren',
        bic: 'GENODES1BLA',
        blz: '63091200',
        city: 'Blaubeuren',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18360,
        name: 'Volksbank Laichinger Alb',
        bic: 'GENODES1LAI',
        blz: '63091300',
        city: 'Laichingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18362,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '63240016',
        city: 'Heidenheim an der Brenz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18364,
        name: 'Heidenheimer Volksbank',
        bic: 'GENODES1HDH',
        blz: '63290110',
        city: 'Heidenheim an der Brenz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18365,
        name: 'Giengener Volksbank -alt-',
        bic: 'GENODES1GVB',
        blz: '63291210',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18369,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '64040033',
        city: 'Reutlingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18370,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '64040045',
        city: 'Metzingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18373,
        name: 'VR Bank Tübingen',
        bic: 'GENODES1STW',
        blz: '64061854',
        city: 'Tübingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18375,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS640',
        blz: '64070085',
        city: 'Reutlingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18376,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF640',
        blz: '64080014',
        city: 'Reutlingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18377,
        name: 'Volksbank Reutlingen',
        bic: 'VBRTDE6RXXX',
        blz: '64090100',
        city: 'Reutlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18378,
        name: 'Volksbank Ermstal-Alb',
        bic: 'GENODES1MTZ',
        blz: '64091200',
        city: 'Metzingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17018,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB395',
        blz: '39570024',
        city: 'Düren',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18381,
        name: 'Commerzbank Tübingen',
        bic: 'COBADEFFXXX',
        blz: '64140036',
        city: 'Tübingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18379,
        name: 'Volksbank Münsingen',
        bic: 'GENODES1MUN',
        blz: '64091300',
        city: 'Münsingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18384,
        name: 'Volksbank Ammerbuch',
        bic: 'GENODES1AMM',
        blz: '64161397',
        city: 'Ammerbuch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18387,
        name: 'Volksbank Hohenzollern-Balingen',
        bic: 'GENODES1VHZ',
        blz: '64163225',
        city: 'Balingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18388,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF641',
        blz: '64180014',
        city: 'Tübingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18385,
        name: 'Raiffeisenbank Härten -alt-',
        bic: 'GENODES1RHK',
        blz: '64161608',
        city: 'Kusterdingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18389,
        name: 'Volksbank Tübingen',
        bic: 'GENODES1TUE',
        blz: '64190110',
        city: 'Tübingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18390,
        name: 'Volksbank Nagoldtal',
        bic: 'GENODES1NAG',
        blz: '64191030',
        city: 'Nagold',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18391,
        name: 'Volksbank Altensteig -alt-',
        bic: 'GENODES1PGW',
        blz: '64191210',
        city: 'Altensteig',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18392,
        name: 'Volksbank Horb -alt-',
        bic: 'GENODES1HOR',
        blz: '64191700',
        city: 'Horb am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18394,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '64240048',
        city: 'Trossingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18395,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '64240071',
        city: 'Rottweil',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18398,
        name: 'Volksbank Baiersbronn Murgtal',
        bic: 'GENODES1BAI',
        blz: '64261363',
        city: 'Baiersbronn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18400,
        name: 'Volksbank Nordschwarzwald',
        bic: 'GENODES1PGW',
        blz: '64261853',
        city: 'Pfalzgrafenweiler',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18401,
        name: 'VR-Bank Dornstetten-Horb',
        bic: 'GENODES1VDS',
        blz: '64262408',
        city: 'Dornstetten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18402,
        name: 'Volksbank Bösingen Dunningen Fluorn-Winzeln -alt-',
        blz: '64263273',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18371,
        name: 'Kreissparkasse Reutlingen',
        bic: 'SOLADES1REU',
        blz: '64050000',
        city: 'Reutlingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18403,
        name: 'Volksbank Rottweil',
        bic: 'GENODES1VRW',
        blz: '64290120',
        city: 'Rottweil',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18382,
        name: 'Kreissparkasse Tübingen',
        bic: 'SOLADES1TUB',
        blz: '64150020',
        city: 'Tübingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18396,
        name: 'Kreissparkasse Rottweil',
        bic: 'SOLADES1RWL',
        blz: '64250040',
        city: 'Rottweil',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18397,
        name: 'Kreissparkasse Freudenstadt',
        bic: 'SOLADES1FDS',
        blz: '64251060',
        city: 'Freudenstadt',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18363,
        name: 'Kreissparkasse Heidenheim',
        bic: 'SOLADES1HDH',
        blz: '63250030',
        city: 'Heidenheim an der Brenz',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18405,
        name: 'Volksbank Deisslingen',
        bic: 'GENODES1VDL',
        blz: '64291420',
        city: 'Deißlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18412,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST644',
        blz: '64420030',
        available: false
    },
    {
        id: 18413,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST480',
        blz: '64450288',
        available: false
    },
    {
        id: 18414,
        name: 'Bundesbank eh Ravensburg',
        bic: 'MARKDEF1650',
        blz: '65000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18415,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST650',
        blz: '65020030',
        available: false
    },
    {
        id: 18416,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM588',
        blz: '65020186',
        city: 'Ravensburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18419,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST450',
        blz: '65050281',
        available: false
    },
    {
        id: 18429,
        name: 'Bad Waldseer Bank',
        bic: 'GENODES1BWB',
        blz: '65091300',
        city: 'Bad Waldsee',
        available: true
    },
    {
        id: 18435,
        name: 'Internationales Bankhaus Bodensee',
        bic: 'IBBFDE81XXX',
        blz: '65110200',
        city: 'Friedrichshafen',
        logoPath: '94.svg',
        available: true
    },
    {
        id: 18436,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM586',
        blz: '65120091',
        logoPath: '181.svg',
        available: false
    },
    {
        id: 18438,
        name: 'Genossenschaftsbank Meckenbeuren',
        bic: 'GENODES1GMB',
        blz: '65161497',
        city: 'Meckenbeuren',
        available: true
    },
    {
        id: 18406,
        name: 'Volksbank Schwarzwald-Neckar',
        bic: 'GENODES1SBG',
        blz: '64292020',
        city: 'Schramberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18443,
        name: 'Bundesbank eh Albstadt',
        bic: 'MARKDEF1653',
        blz: '65300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18444,
        name: 'SEB',
        bic: 'ESSEDE5F653',
        blz: '65310111',
        city: 'Albstadt',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18447,
        name: 'Baden-Württemb.Bank Landesbank Baden-Württemb.',
        bic: 'SOLADEST476',
        blz: '65350186',
        available: false
    },
    {
        id: 18452,
        name: 'Onstmettinger Bank',
        bic: 'GENODES1ONS',
        blz: '65361989',
        city: 'Albstadt',
        available: true
    },
    {
        id: 18407,
        name: 'Volksbank Trossingen',
        bic: 'GENODES1TRO',
        blz: '64292310',
        city: 'Trossingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18409,
        name: 'Raiffeisenbank Donau-Heuberg',
        bic: 'GENODES1RDH',
        blz: '64361359',
        city: 'Mühlheim an der Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18410,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF643',
        blz: '64380011',
        city: 'Tuttlingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18411,
        name: 'Volksbank Schwarzwald-Donau-Neckar',
        bic: 'GENODES1TUT',
        blz: '64390130',
        city: 'Tuttlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18417,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '65040073',
        city: 'Ravensburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18420,
        name: 'Raiffeisenbank Aulendorf',
        bic: 'GENODES1AUL',
        blz: '65061219',
        city: 'Aulendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18421,
        name: 'VR Bank Ravensburg-Weingarten',
        bic: 'GENODES1RRV',
        blz: '65062577',
        city: 'Ravensburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18422,
        name: 'Raiffeisenbank Vorallgäu',
        bic: 'GENODES1AMZ',
        blz: '65062793',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18423,
        name: 'Raiffeisenbank Bad Saulgau',
        bic: 'GENODES1SAG',
        blz: '65063086',
        city: 'Bad Saulgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18424,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB650',
        blz: '65070024',
        city: 'Ravensburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18425,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS650',
        blz: '65070084',
        city: 'Ravensburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18426,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF650',
        blz: '65080009',
        city: 'Ravensburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18427,
        name: 'Volksbank Ulm-Biberach',
        bic: 'ULMVDE66XXX',
        blz: '65090100',
        city: 'Ravensburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18428,
        name: 'Volksbank Allgäu-Oberschwaben',
        bic: 'GENODES1LEU',
        blz: '65091040',
        city: 'Leutkirch im Allgäu',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18430,
        name: 'Isnyer Volksbank -alt-',
        bic: 'GENODES1ISN',
        blz: '65091400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18431,
        name: 'Volksbank Weingarten',
        bic: 'GENODES1VWG',
        blz: '65091600',
        city: 'Weingarten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18432,
        name: 'Volksbank Allgäu-West',
        bic: 'GENODES1WAN',
        blz: '65092010',
        city: 'Wangen im Allgäu',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18433,
        name: 'Volksbank Altshausen',
        bic: 'GENODES1VAH',
        blz: '65092200',
        city: 'Altshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18182,
        name: 'Raiffeisenbank Oberes Gäu Ergenzingen',
        bic: 'GENODES1ROG',
        blz: '60069876',
        city: 'Rottenburg am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18437,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '65140072',
        city: 'Friedrichshafen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18440,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF651',
        blz: '65180005',
        city: 'Friedrichshafen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18441,
        name: 'Volksbank Friedrichshafen',
        bic: 'GENODES1VFN',
        blz: '65190110',
        city: 'Friedrichshafen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18442,
        name: 'Volksbank Friedrichshafen-Tettnang',
        bic: 'GENODES1TET',
        blz: '65191500',
        city: 'Tettnang',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18445,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '65340004',
        city: 'Albstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18451,
        name: 'VR Bank Heuberg-Winterlingen',
        bic: 'GENODES1WLB',
        blz: '65361898',
        city: 'Winterlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18453,
        name: 'Raiffeisenbank Geislingen-Rosenfeld',
        bic: 'GENODES1GEI',
        blz: '65362499',
        city: 'Geislingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18454,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB653',
        blz: '65370024',
        city: 'Albstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18455,
        name: 'Deutsche Bank',
        bic: 'DEUTDESS653',
        blz: '65370075',
        city: 'Albstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18456,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF653',
        blz: '65380003',
        city: 'Albstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18457,
        name: 'Volksbank Albstadt',
        bic: 'GENODES1EBI',
        blz: '65390120',
        city: 'Albstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18458,
        name: 'Volksbank Balingen',
        bic: 'GENODES1BAL',
        blz: '65391210',
        city: 'Balingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18459,
        name: 'Volksbank Tailfingen',
        bic: 'GENODES1TAI',
        blz: '65392030',
        city: 'Albstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18460,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '65440087',
        city: 'Biberach an der Riß',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18462,
        name: 'Raiffeisenbank Biberach',
        bic: 'GENODES1WAR',
        blz: '65461878',
        city: 'Ummendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18463,
        name: 'Raiffeisenbank Illertal',
        bic: 'GENODES1ERO',
        blz: '65462231',
        city: 'Erolzheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18418,
        name: 'Kreissparkasse Ravensburg',
        bic: 'SOLADES1RVB',
        blz: '65050110',
        city: 'Ravensburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18464,
        name: 'Volksbank Ulm-Biberach',
        bic: 'GENODES1VBC',
        blz: '65490130',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18448,
        name: 'Hohenz Landesbank Kreissparkasse Sigmaringen',
        bic: 'SOLADES1SIG',
        blz: '65351050',
        city: 'Sigmaringen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18461,
        name: 'Kreissparkasse Biberach',
        bic: 'SBCRDE66XXX',
        blz: '65450070',
        city: 'Biberach an der Riß',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18408,
        name: 'Kreissparkasse Tuttlingen',
        bic: 'SOLADES1TUT',
        blz: '64350070',
        city: 'Tuttlingen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18466,
        name: 'Volksbank-Raiffeisenbank Riedlingen',
        bic: 'GENODES1VRR',
        blz: '65491510',
        city: 'Riedlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18467,
        name: 'Bundesbank',
        bic: 'MARKDEF1660',
        blz: '66000000',
        city: 'Karlsruhe',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18468,
        name: 'SEB',
        bic: 'ESSEDE5F660',
        blz: '66010111',
        city: 'Karlsruhe',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18470,
        name: 'Landeskreditbank Baden-Württemberg Förderbank',
        bic: 'LKBWDE6KXXX',
        blz: '66010700',
        city: 'Karlsruhe',
        available: false
    },
    {
        id: 18471,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST663',
        blz: '66020020',
        city: 'Karlsruhe',
        available: true
    },
    {
        id: 18472,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 145 Kruh',
        bic: 'HYVEDEM1076',
        blz: '66020150',
        available: false
    },
    {
        id: 18473,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM475',
        blz: '66020286',
        city: 'Karlsruhe',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18474,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33KRL',
        blz: '66020500',
        city: 'Karlsruhe',
        available: false
    },
    {
        id: 18475,
        name: 'Isbank Mannheim',
        bic: 'ISBKDEFXXXX',
        blz: '66030600',
        city: 'Karlsruhe',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 18478,
        name: 'Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST660',
        blz: '66050000',
        city: 'Karlsruhe',
        available: true
    },
    {
        id: 18481,
        name: 'DZ BANK',
        bic: 'GENODE6KXXX',
        blz: '66060000',
        city: 'Karlsruhe',
        logoPath: '69.svg',
        available: true
    },
    {
        id: 18482,
        name: 'Spar- und Kreditbank',
        bic: 'GENODE61KA3',
        blz: '66060300',
        city: 'Karlsruhe',
        logoPath: '170.svg',
        available: true
    },
    {
        id: 18483,
        name: 'Evangelische Kreditgenossenschaft -Filiale Karlsruhe-',
        bic: 'GENODEF1EK5',
        blz: '66060800',
        available: false
    },
    {
        id: 18485,
        name: 'Spar- und Kreditbank',
        bic: 'GENODE61RH2',
        blz: '66061407',
        city: 'Rheinstetten',
        logoPath: '170.svg',
        available: true
    },
    {
        id: 18487,
        name: 'Spar- und Kreditbank Hardt',
        bic: 'GENODE61EGG',
        blz: '66062138',
        city: 'Eggenstein-Leopoldshafen',
        available: true
    },
    {
        id: 18490,
        name: 'Spar- und Kreditbank',
        bic: 'GENODE61DAC',
        blz: '66069104',
        city: 'Dauchingen',
        logoPath: '170.svg',
        available: true
    },
    {
        id: 18493,
        name: 'Spar- und Kreditbank Daxlanden Karlsruhe -alt-',
        blz: '66069323',
        available: false
    },
    {
        id: 18476,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '66040018',
        city: 'Karlsruhe',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18499,
        name: 'BBBank',
        bic: 'GENODE61BBB',
        blz: '66090800',
        city: 'Karlsruhe',
        logoPath: '45.svg',
        available: true
    },
    {
        id: 18505,
        name: 'Bundesbank eh Baden-Baden',
        bic: 'MARKDEF1662',
        blz: '66200000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18506,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST662',
        blz: '66220020',
        available: false
    },
    {
        id: 18510,
        name: 'Spar- und Kreditbank',
        bic: 'GENODE61BHT',
        blz: '66261092',
        city: 'Bühlertal',
        logoPath: '170.svg',
        available: true
    },
    {
        id: 18477,
        name: 'Commerzbank/Kreditcenter Badenia',
        bic: 'COBADEFFXXX',
        blz: '66040026',
        city: 'Karlsruhe',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18509,
        name: 'Sparkasse Bühl',
        bic: 'SOLADES1BHL',
        blz: '66251434',
        city: 'Bühl',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18519,
        name: 'Sparkasse Kraichgau Bruchsal-Bretten-Sinsheim',
        bic: 'BRUSDE66XXX',
        blz: '66350036',
        city: 'Bruchsal',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18484,
        name: 'Volksbank Stutensee Hardt -alt-',
        bic: 'GENODE61SBA',
        blz: '66061059',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18486,
        name: 'Volksbank Stutensee-Weingarten',
        bic: 'GENODE61WGA',
        blz: '66061724',
        city: 'Stutensee',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18488,
        name: 'Raiffeisenbank Hardt-Bruhrain',
        bic: 'GENODE61DET',
        blz: '66062366',
        city: 'Dettenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18489,
        name: 'Raiffeisenbank Elztal',
        bic: 'GENODE61ELZ',
        blz: '66069103',
        city: 'Elztal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18491,
        name: 'Raiffeisenbank Döggingen-Mundelfingen -alt-',
        blz: '66069117',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18492,
        name: 'Raiffeisenbank Hilsbach -alt-',
        blz: '66069265',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18494,
        name: 'Volksbank Krautheim',
        bic: 'GENODE61KTH',
        blz: '66069342',
        city: 'Krautheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18495,
        name: 'Raiffeisenbank Sexau -alt-',
        bic: 'GENODE61SEX',
        blz: '66069573',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18496,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM660',
        blz: '66070004',
        city: 'Karlsruhe',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18498,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF660',
        blz: '66080052',
        city: 'Karlsruhe',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18501,
        name: 'Volksbank Ettlingen',
        bic: 'GENODE61ETT',
        blz: '66091200',
        city: 'Ettlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18502,
        name: 'Volksbank Neureut -alt-',
        blz: '66091500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18503,
        name: 'Volksbank Karlsruhe',
        bic: 'GENODE61KA1',
        blz: '66190000',
        city: 'Karlsruhe',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18504,
        name: 'Volksbank Durlach -alt-',
        bic: 'GENODE61KA2',
        blz: '66190100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18507,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '66240002',
        city: 'Baden-Baden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18258,
        name: 'Raiffeisenbank Wimsheim-Mönsheim',
        bic: 'GENODES1WIM',
        blz: '60661906',
        city: 'Wimsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18469,
        name: 'Deutsche Bausparkasse Badenia',
        bic: 'BBSPDE6KXXX',
        blz: '66010200',
        city: 'Karlsruhe',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18511,
        name: 'Raiffeisenbank Altschweier',
        bic: 'GENODE61ALR',
        blz: '66261416',
        city: 'Bühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18512,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM662',
        blz: '66270001',
        city: 'Baden-Baden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18513,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB662',
        blz: '66270024',
        city: 'Baden-Baden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18514,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF662',
        blz: '66280053',
        city: 'Baden-Baden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18515,
        name: 'Volksbank Baden-Baden Rastatt',
        bic: 'VBRADE6KXXX',
        blz: '66290000',
        city: 'Rastatt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18516,
        name: 'Volksbank Achern',
        bic: 'GENODE61ACH',
        blz: '66291300',
        city: 'Achern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18517,
        name: 'Volksbank Bühl',
        bic: 'GENODE61BHL',
        blz: '66291400',
        city: 'Bühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18518,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '66340018',
        city: 'Bruchsal',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18480,
        name: 'Sparkasse Ettlingen -alt-',
        bic: 'SOLADES1ETT',
        blz: '66051220',
        city: 'Ettlingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18520,
        name: 'Raiffeisenbank Kraich-Hardt -alt-',
        bic: 'GENODE61FST',
        blz: '66361178',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18521,
        name: 'Volksbank Kirrlach -alt-',
        bic: 'GENODE61WAG',
        blz: '66361335',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18522,
        name: 'Raiffeisenbank Kronau -alt-',
        bic: 'GENODE61KRO',
        blz: '66362345',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18523,
        name: 'Raiffeisenbank Odenheim-Tiefenbach -alt-',
        bic: 'GENODE61OOM',
        blz: '66363487',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18524,
        name: 'Volksbank Bruchsal-Bretten -alt-',
        bic: 'GENODE61BRU',
        blz: '66390000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18525,
        name: 'Volksbank Bruchsal-Bretten',
        bic: 'GENODE61BTT',
        blz: '66391200',
        city: 'Bretten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18526,
        name: 'Volksbank Bruhrain-Kraich-Hardt',
        bic: 'GENODE61ORH',
        blz: '66391600',
        city: 'Oberhausen-Rheinhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18500,
        name: 'PSD Bank Karlsruhe-Neustadt',
        bic: 'GENODEF1P10',
        blz: '66090900',
        city: 'Karlsruhe',
        available: true
    },
    {
        id: 18479,
        name: 'Sparkasse Karlsruhe',
        bic: 'KARSDE66XXX',
        blz: '66050101',
        city: 'Karlsruhe',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18528,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST664',
        blz: '66420020',
        available: false
    },
    {
        id: 18529,
        name: 'Bankhaus J. Faißt',
        bic: 'FAITDE66XXX',
        blz: '66432700',
        city: 'Wolfach',
        available: true
    },
    {
        id: 18581,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI22',
        blz: '67089440',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18582,
        name: 'VR Bank Rhein-Neckar',
        bic: 'GENODE61MA2',
        blz: '67090000',
        city: 'Mannheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18583,
        name: 'Volksbank Kurpfalz H+G Bank',
        bic: 'GENODE61LDB',
        blz: '67091500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18546,
        name: 'Bundesbank eh Pforzheim',
        bic: 'MARKDEF1666',
        blz: '66600000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18547,
        name: 'SEB',
        bic: 'ESSEDE5F666',
        blz: '66610111',
        city: 'Pforzheim',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18548,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST666',
        blz: '66620020',
        available: false
    },
    {
        id: 18564,
        name: 'Bundesbank eh Mannheim',
        bic: 'MARKDEF1670',
        blz: '67000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18565,
        name: 'SEB',
        bic: 'ESSEDE5F670',
        blz: '67010111',
        city: 'Mannheim',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18566,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST671',
        blz: '67020020',
        available: false
    },
    {
        id: 18567,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM489',
        blz: '67020190',
        city: 'Mannheim',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18568,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 681 Mnh',
        bic: 'HYVEDEM1103',
        blz: '67020259',
        available: false
    },
    {
        id: 18572,
        name: 'Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST670',
        blz: '67050000',
        available: false
    },
    {
        id: 18585,
        name: 'SEB',
        bic: 'ESSEDE5F672',
        blz: '67210111',
        city: 'Heidelberg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18586,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST672',
        blz: '67220020',
        available: false
    },
    {
        id: 18587,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM479',
        blz: '67220286',
        city: 'Heidelberg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18588,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 488 Hd',
        bic: 'HYVEDEM1087',
        blz: '67220464',
        available: false
    },
    {
        id: 18589,
        name: 'MLP Banking',
        bic: 'MLPBDE61XXX',
        blz: '67230000',
        city: 'Wiesloch',
        available: true
    },
    {
        id: 18584,
        name: 'Volksbank Weinheim',
        bic: 'GENODE61WNM',
        blz: '67092300',
        city: 'Weinheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18533,
        name: 'Sparkasse Haslach-Zell',
        bic: 'SOLADES1HAL',
        blz: '66451548',
        city: 'Haslach im Kinzigtal',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18534,
        name: 'Sparkasse Hanauerland',
        bic: 'SOLADES1KEL',
        blz: '66451862',
        city: 'Kehl',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18535,
        name: 'Sparkasse Wolfach',
        bic: 'SOLADES1WOF',
        blz: '66452776',
        city: 'Wolfach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18542,
        name: 'Sparkasse Rastatt-Gernsbach',
        bic: 'SOLADES1RAS',
        blz: '66550070',
        city: 'Rastatt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18543,
        name: 'Sparkasse Gaggenau-Kuppenheim -alt-',
        bic: 'SOLADES1GAG',
        blz: '66551290',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18537,
        name: 'Deutsche Bank',
        bic: 'DEUTDE6F664',
        blz: '66470035',
        city: 'Offenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18538,
        name: 'Volksbank in der Ortenau',
        bic: 'GENODE61OG1',
        blz: '66490000',
        city: 'Offenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18539,
        name: 'Volksbank Bühl Fil Kehl',
        bic: 'GENODE61BHL',
        blz: '66491800',
        city: 'Kehl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18540,
        name: 'Volksbank Appenweier-Urloffen Appenweier -alt-',
        bic: 'GENODE61APP',
        blz: '66492600',
        city: 'Appenweier',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18541,
        name: 'Volksbank Mittlerer Schwarzwald',
        bic: 'GENODE61KZT',
        blz: '66492700',
        city: 'Wolfach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18550,
        name: 'Sparkasse Pforzheim Calw',
        bic: 'PZHSDE66XXX',
        blz: '66650085',
        city: 'Pforzheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18573,
        name: 'Sparkasse Rhein Neckar Nord',
        bic: 'MANSDE66XXX',
        blz: '67050505',
        city: 'Mannheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18545,
        name: 'VR-Bank in Mittelbaden',
        bic: 'GENODE61IFF',
        blz: '66562300',
        city: 'Iffezheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18549,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '66640035',
        city: 'Pforzheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18574,
        name: 'Sparkasse Hockenheim',
        bic: 'SOLADES1HOC',
        blz: '67051203',
        city: 'Hockenheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18551,
        name: 'Raiffeisenbank Bauschlott',
        bic: 'GENODE61NBT',
        blz: '66661244',
        city: 'Neulingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18552,
        name: 'Raiffeisenbank Kieselbronn',
        bic: 'GENODE61KBR',
        blz: '66661329',
        city: 'Kieselbronn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18553,
        name: 'VR Bank im Enzkreis',
        bic: 'GENODE61NFO',
        blz: '66661454',
        city: 'Niefern-Öschelbronn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18554,
        name: 'Raiffeisenbank Ersingen',
        bic: 'GENODE61ERS',
        blz: '66662155',
        city: 'Kämpfelbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18555,
        name: 'Volksbank Stein Eisingen',
        bic: 'GENODE61KBS',
        blz: '66662220',
        city: 'Königsbach-Stein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18316,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '62040060',
        city: 'Heilbronn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18557,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM666',
        blz: '66670006',
        city: 'Pforzheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18323,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB620',
        blz: '62070024',
        city: 'Heilbronn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18558,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB666',
        blz: '66670024',
        city: 'Pforzheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18559,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF666',
        blz: '66680013',
        city: 'Pforzheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18560,
        name: 'Volksbank Pforzheim',
        bic: 'VBPFDE66XXX',
        blz: '66690000',
        city: 'Pforzheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18561,
        name: 'VR Bank Enz plus',
        bic: 'GENODE61WIR',
        blz: '66692300',
        city: 'Remchingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18562,
        name: 'Raiffeisenbank Kraichgau',
        bic: 'GENODE61KIR',
        blz: '66762332',
        city: 'Kirchardt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18563,
        name: 'Raiffeisenbank Neudenau-Stein-Herbolzheim',
        bic: 'GENODE61NEU',
        blz: '66762433',
        city: 'Neudenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18569,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '67040031',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18570,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '67040060',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18571,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '67040061',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18531,
        name: 'Sparkasse Offenburg/Ortenau',
        bic: 'SOLADES1OFG',
        blz: '66450050',
        city: 'Offenburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18532,
        name: 'Sparkasse Gengenbach',
        bic: 'SOLADES1GEB',
        blz: '66451346',
        city: 'Gengenbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18575,
        name: 'Volksbank Sandhofen',
        bic: 'GENODE61MA3',
        blz: '67060031',
        city: 'Mannheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18576,
        name: 'Deutsche Bank',
        bic: 'DEUTDESMXXX',
        blz: '67070010',
        city: 'Mannheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18578,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF670',
        blz: '67080050',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18579,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFI60',
        blz: '67080085',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18580,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 3',
        bic: 'DRESDEFFI61',
        blz: '67080086',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18590,
        name: 'MLP Banking Zw CS',
        bic: 'MLPBDE61001',
        blz: '67230001',
        city: 'Wiesloch',
        available: true
    },
    {
        id: 18643,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFJ22',
        blz: '68080086',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18644,
        name: 'Volksbank Freiburg',
        bic: 'GENODE61FR1',
        blz: '68090000',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18646,
        name: 'Volksbank Müllheim',
        bic: 'GENODE61MHL',
        blz: '68091900',
        city: 'Müllheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18606,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST623',
        blz: '67320020',
        available: false
    },
    {
        id: 18607,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST675',
        blz: '67332551',
        available: false
    },
    {
        id: 18647,
        name: 'Volksbank Breisgau Nord',
        bic: 'GENODE61EMM',
        blz: '68092000',
        city: 'Emmendingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18617,
        name: 'Bundesbank',
        bic: 'MARKDEF1680',
        blz: '68000000',
        city: 'Freiburg im Breisgau',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18618,
        name: 'SEB',
        bic: 'ESSEDE5F680',
        blz: '68010111',
        city: 'Freiburg im Breisgau',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18619,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST682',
        blz: '68020020',
        available: false
    },
    {
        id: 18620,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM357',
        blz: '68020186',
        city: 'Freiburg im Breisgau',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18621,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 405 Frb',
        bic: 'HYVEDEM1082',
        blz: '68020460',
        available: false
    },
    {
        id: 18622,
        name: 'Bankhaus E. Mayer',
        bic: 'BKMADE61XXX',
        blz: '68030000',
        city: 'Freiburg im Breisgau',
        available: true
    },
    {
        id: 18624,
        name: 'Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST680',
        blz: '68050000',
        available: false
    },
    {
        id: 18635,
        name: 'Spar- u Kreditbank Bad Krozingen-Heitersheim',
        bic: 'GENODE61HHM',
        blz: '68063254',
        available: false
    },
    {
        id: 18648,
        name: 'Volksbank Staufen',
        bic: 'GENODE61STF',
        blz: '68092300',
        city: 'Staufen im Breisgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18608,
        name: 'Sparkasse Tauberfranken',
        bic: 'SOLADES1TBB',
        blz: '67352565',
        city: 'Tauberbischofsheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18611,
        name: 'Sparkasse Neckartal-Odenwald',
        bic: 'SOLADES1MOS',
        blz: '67450048',
        city: 'Mosbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18593,
        name: 'Raiffeisenbank Steinsberg -alt-',
        blz: '67261909',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18594,
        name: 'Raiffeisen Privatbank',
        bic: 'GENODE61WIB',
        blz: '67262243',
        city: 'Wiesloch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18595,
        name: 'Volksbank Schwarzbachtal -alt-',
        blz: '67262402',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18596,
        name: 'Volksbank Rot',
        bic: 'GENODE61LRO',
        blz: '67262550',
        city: 'St. Leon-Rot',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18597,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM672',
        blz: '67270003',
        city: 'Heidelberg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18598,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB672',
        blz: '67270024',
        city: 'Heidelberg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18599,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF672',
        blz: '67280051',
        city: 'Heidelberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18600,
        name: 'Heidelberger Volksbank',
        bic: 'GENODE61HD1',
        blz: '67290000',
        city: 'Heidelberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18601,
        name: 'Volksbank Kurpfalz',
        bic: 'GENODE61HD3',
        blz: '67290100',
        city: 'Heidelberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18605,
        name: 'Volksbank Kraichgau',
        bic: 'GENODE61WIE',
        blz: '67292200',
        city: 'Wiesloch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18508,
        name: 'Sparkasse Baden-Baden Gaggenau',
        bic: 'SOLADES1BAD',
        blz: '66250030',
        city: 'Baden-Baden',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18602,
        name: 'Volksbank f d Angelbachtal -alt-',
        bic: 'GENODE61MHS',
        blz: '67291500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18609,
        name: 'Volksbank Tauber -alt-',
        bic: 'GENODE61TBB',
        blz: '67362560',
        city: 'Tauberbischofsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18610,
        name: 'Volksbank Main-Tauber',
        bic: 'GENODE61WTH',
        blz: '67390000',
        city: 'Wertheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18625,
        name: 'Sparkasse Freiburg-Nördlicher Breisgau',
        bic: 'FRSPDE66XXX',
        blz: '68050101',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18612,
        name: 'Volksbank Mosbach',
        bic: 'GENODE61MOS',
        blz: '67460041',
        city: 'Mosbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18613,
        name: 'Volksbank Franken',
        bic: 'GENODE61BUC',
        blz: '67461424',
        city: 'Buchen (Odenwald)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18374,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB640',
        blz: '64070024',
        city: 'Reutlingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18615,
        name: 'Volksbank Limbach',
        bic: 'GENODE61LMB',
        blz: '67462368',
        city: 'Limbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18434,
        name: 'Volksbank Bad Saulgau',
        bic: 'GENODES1SLG',
        blz: '65093020',
        city: 'Bad Saulgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18626,
        name: 'Sparkasse Hochschwarzwald',
        bic: 'SOLADES1HSW',
        blz: '68051004',
        city: 'Titisee-Neustadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18556,
        name: 'Raiffeisen-Gebietsbank',
        bic: 'GENODE61NHS',
        blz: '66663439',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18623,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '68040007',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18627,
        name: 'Sparkasse Bonndorf-Stühlingen',
        bic: 'SOLADES1BND',
        blz: '68051207',
        city: 'Bonndorf im Schwarzwald',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18628,
        name: 'Sparkasse Breisach -alt-',
        bic: 'SOLADES1BRS',
        blz: '68051310',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18629,
        name: 'Sparkasse St. Blasien',
        bic: 'SOLADES1STB',
        blz: '68052230',
        city: 'St. Blasien',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18630,
        name: 'Sparkasse Staufen-Breisach',
        bic: 'SOLADES1STF',
        blz: '68052328',
        city: 'Staufen im Breisgau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18631,
        name: 'Sparkasse Schönau-Todtnau',
        bic: 'SOLADES1SCH',
        blz: '68052863',
        city: 'Todtnau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18649,
        name: 'Sparkasse Lahr -alt-',
        blz: '68250040',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18592,
        name: 'Sparkasse Heidelberg',
        bic: 'SOLADES1HDB',
        blz: '67250020',
        city: 'Heidelberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18632,
        name: 'Volksbank Breisgau-Markgräflerland',
        bic: 'GENODE61IHR',
        blz: '68061505',
        city: 'Heitersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18633,
        name: 'Raiffeisenbank Denzlingen-Sexau',
        bic: 'GENODE61DEN',
        blz: '68062105',
        city: 'Denzlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18634,
        name: 'Raiffeisenbank Wyhl',
        bic: 'GENODE61WYH',
        blz: '68062730',
        city: 'Wyhl am Kaiserstuhl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18636,
        name: 'Raiffeisenbank Kaiserstuhl',
        bic: 'GENODE61VOK',
        blz: '68063479',
        city: 'Vogtsburg im Kaiserstuhl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18637,
        name: 'Raiffeisenbank im Breisgau',
        bic: 'GENODE61GUN',
        blz: '68064222',
        city: 'Gundelfingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18639,
        name: 'Deutsche Bank',
        bic: 'DEUTDE6FXXX',
        blz: '68070030',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18640,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF680',
        blz: '68080030',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18642,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFI62',
        blz: '68080085',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18645,
        name: 'PSD Bank RheinNeckarSaar',
        bic: 'GENODEF1P07',
        blz: '68090900',
        city: 'Freiburg im Breisgau',
        available: true
    },
    {
        id: 18651,
        name: 'Deutsche Bank',
        bic: 'DEUTDE6F682',
        blz: '68270033',
        city: 'Lahr',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18653,
        name: 'Bundesbank eh Lörrach',
        bic: 'MARKDEF1683',
        blz: '68300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18654,
        name: 'SEB',
        bic: 'ESSEDE5F683',
        blz: '68310111',
        city: 'Lörrach',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18655,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST683',
        blz: '68320020',
        available: false
    },
    {
        id: 18652,
        name: 'Volksbank Lahr',
        bic: 'GENODE61LAH',
        blz: '68290000',
        city: 'Lahr',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18656,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '68340058',
        city: 'Lörrach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18671,
        name: 'Bundesbank eh Konstanz',
        bic: 'MARKDEF1690',
        blz: '69000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18672,
        name: 'SEB',
        bic: 'ESSEDE5F690',
        blz: '69010111',
        city: 'Konstanz',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18673,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST690',
        blz: '69020020',
        available: false
    },
    {
        id: 18674,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM591',
        blz: '69020190',
        city: 'Konstanz',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18659,
        name: 'Sparkasse Markgräflerland',
        bic: 'SOLADES1MGL',
        blz: '68351865',
        city: 'Weil am Rhein',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18685,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST692',
        blz: '69220020',
        available: false
    },
    {
        id: 18686,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM590',
        blz: '69220186',
        city: 'Singen (Hohentwiel)',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18697,
        name: 'Bundesbank',
        bic: 'MARKDEF1694',
        blz: '69400000',
        city: 'Villingen-Schwenningen',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18698,
        name: 'Baden-Württembergische Bank (BW-Bank)',
        bic: 'SOLADEST694',
        blz: '69421020',
        available: false
    },
    {
        id: 18706,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F700',
        blz: '70010111',
        city: 'München',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18707,
        name: 'Aareal Bank',
        bic: 'AARBDE5W700',
        blz: '70010424',
        city: 'München',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 18708,
        name: 'Deutsche Pfandbriefbank',
        bic: 'REBMDEMMXXX',
        blz: '70010500',
        city: 'Unterschleißheim',
        logoPath: '76.svg',
        available: false
    },
    {
        id: 18709,
        name: 'Deutsche Pfandbriefbank - Einlagengeschäfte',
        bic: 'REBMDEMM555',
        blz: '70010555',
        city: 'Unterschleißheim',
        logoPath: '76.svg',
        available: false
    },
    {
        id: 18710,
        name: 'Deutsche Handelsbank',
        bic: 'DEKTDE7GXXX',
        blz: '70011100',
        city: 'Grünwald',
        available: false
    },
    {
        id: 18660,
        name: 'Sparkasse Zell i W -alt-',
        bic: 'SOLADES1ZLW',
        blz: '68351976',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18666,
        name: 'Sparkasse Hochrhein',
        bic: 'SKHRDE6WXXX',
        blz: '68452290',
        city: 'Waldshut-Tiengen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18676,
        name: 'Sparkasse Bodensee',
        bic: 'SOLADES1KNZ',
        blz: '69050001',
        city: 'Konstanz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18661,
        name: 'Raiffeisenbank Maulburg -alt-',
        bic: 'GENODE61MAU',
        blz: '68361394',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18663,
        name: 'Deutsche Bank',
        bic: 'DEUTDE6F683',
        blz: '68370034',
        city: 'Lörrach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18664,
        name: 'Volksbank Dreiländereck',
        bic: 'VOLODE66XXX',
        blz: '68390000',
        city: 'Lörrach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18665,
        name: 'VR Bank',
        bic: 'GENODE61SPF',
        blz: '68391500',
        city: 'Schopfheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18678,
        name: 'Sparkasse Pfullendorf-Meßkirch',
        bic: 'SOLADES1PFD',
        blz: '69051620',
        city: 'Pfullendorf',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18667,
        name: 'Volksbank Klettgau-Wutöschingen',
        bic: 'GENODE61WUT',
        blz: '68462427',
        city: 'Wutöschingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18668,
        name: 'Volksbank Rhein-Wehra',
        bic: 'GENODE61BSK',
        blz: '68490000',
        city: 'Bad Säckingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18675,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '69040045',
        city: 'Konstanz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18669,
        name: 'Volksbank Jestetten',
        bic: 'GENODE61JES',
        blz: '68491500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18679,
        name: 'Sparkasse Salem-Heiligenberg',
        bic: 'SOLADES1SAL',
        blz: '69051725',
        city: 'Salem',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18688,
        name: 'Sparkasse Hegau-Bodensee',
        bic: 'SOLADES1SNG',
        blz: '69250035',
        city: 'Singen (Hohentwiel)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18689,
        name: 'Sparkasse Engen-Gottmadingen',
        bic: 'SOLADES1ENG',
        blz: '69251445',
        city: 'Engen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18680,
        name: 'Volksbank Überlingen',
        bic: 'GENODE61UBE',
        blz: '69061800',
        city: 'Überlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18682,
        name: 'Deutsche Bank',
        bic: 'DEUTDE6F690',
        blz: '69070032',
        city: 'Konstanz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18577,
        name: 'Deutsche Bank Privat und Geschäftskunden (auch maxblue Depots)',
        bic: 'DEUTDEDBMAN',
        blz: '67070024',
        city: 'Mannheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18684,
        name: 'Volksbank Pfullendorf',
        bic: 'GENODE61PFD',
        blz: '69091600',
        city: 'Pfullendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18614,
        name: 'Volksbank Kirnau',
        bic: 'GENODE61RNG',
        blz: '67461733',
        city: 'Rosenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18683,
        name: 'Hagnauer Volksbank',
        bic: 'GENODE61HAG',
        blz: '69091200',
        city: 'Hagnau am Bodensee',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18687,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '69240075',
        city: 'Singen (Hohentwiel)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18690,
        name: 'Sparkasse Stockach',
        bic: 'SOLADES1STO',
        blz: '69251755',
        city: 'Stockach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18700,
        name: 'Sparkasse Schwarzwald-Baar',
        bic: 'SOLADES1VSS',
        blz: '69450065',
        city: 'Villingen-Schwenningen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18701,
        name: 'Sparkasse Donaueschingen -alt-',
        bic: 'SOLADES1DOE',
        blz: '69451070',
        city: 'Donaueschingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18692,
        name: 'Deutsche Bank',
        bic: 'DEUTDE6F692',
        blz: '69270038',
        city: 'Singen (Hohentwiel)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18693,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF692',
        blz: '69280035',
        city: 'Singen (Hohentwiel)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18694,
        name: 'Volksbank Hegau',
        bic: 'GENODE61SIN',
        blz: '69290000',
        city: 'Singen (Hohentwiel)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18695,
        name: 'Volksbank',
        bic: 'GENODE61RAD',
        blz: '69291000',
        city: 'Konstanz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18696,
        name: 'Volksbank Meßkirch Raiffeisenbank',
        bic: 'GENODE61MES',
        blz: '69362032',
        city: 'Meßkirch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18699,
        name: 'Commerzbank Villingen u Schwenningen',
        bic: 'COBADEFFXXX',
        blz: '69440007',
        city: 'Villingen-Schwenningen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18677,
        name: 'Bezirkssparkasse Reichenau',
        bic: 'SOLADES1REN',
        blz: '69051410',
        city: 'Reichenau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18658,
        name: 'Sparkasse Wiesental',
        bic: 'SOLADES1SFH',
        blz: '68351557',
        city: 'Schopfheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18702,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB694',
        blz: '69470024',
        city: 'Villingen-Schwenningen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18703,
        name: 'Deutsche Bank Villingen u Schwenningen',
        bic: 'DEUTDE6F694',
        blz: '69470039',
        city: 'Villingen-Schwenningen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18704,
        name: 'Volksbank Schwarzwald Baar Hegau',
        bic: 'GENODE61VS1',
        blz: '69490000',
        city: 'Villingen-Schwenningen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18657,
        name: 'Sparkasse Lörrach-Rheinfelden',
        bic: 'SKLODE66XXX',
        blz: '68350048',
        city: 'Lörrach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18705,
        name: 'Volksbank Triberg',
        bic: 'GENODE61TRI',
        blz: '69491700',
        city: 'Triberg im Schwarzwald',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18711,
        name: 'Deutsche Handelsbank',
        bic: 'DEKTDE7GXXX',
        blz: '70011110',
        city: 'Grünwald',
        available: true
    },
    {
        id: 18712,
        name: 'Bank Vontobel Europe',
        bic: 'VONTDEM1XXX',
        blz: '70011200',
        city: 'München',
        available: false
    },
    {
        id: 18713,
        name: 'Autobank',
        bic: 'AUZDDEM1XXX',
        blz: '70011300',
        city: 'Oberhaching',
        logoPath: '44.svg',
        available: false
    },
    {
        id: 18714,
        name: 'BfW - Bank für Wohnungswirtschaft',
        bic: 'BFWODE71XXX',
        blz: '70011400',
        city: 'Mannheim',
        available: false
    },
    {
        id: 18715,
        name: 'SIEMENS BANK',
        bic: 'SIBADEMMXXX',
        blz: '70011500',
        city: 'München',
        available: false
    },
    {
        id: 18716,
        name: 'Bankhaus von der Heydt',
        bic: 'BVDHDEMMXXX',
        blz: '70011700',
        city: 'München',
        available: false
    },
    {
        id: 18717,
        name: 'InterCard',
        bic: 'ICRDDE71XXX',
        blz: '70011900',
        city: 'Taufkirchen',
        logoPath: '91.svg',
        available: false
    },
    {
        id: 18718,
        name: 'InterCard Cash Services 10',
        bic: 'ICRDDE71010',
        blz: '70011910',
        logoPath: '91.svg',
        available: false
    },
    {
        id: 18719,
        name: 'InterCard Cash Services 20',
        bic: 'ICRDDE71020',
        blz: '70011920',
        logoPath: '91.svg',
        available: false
    },
    {
        id: 18720,
        name: 'UniCredit Family Financing Bank, Ndl der UniCredit',
        bic: 'CLABDEM1XXX',
        blz: '70012000',
        city: 'München',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 18721,
        name: 'Dero Bank',
        bic: 'VEAKDEMMXXX',
        blz: '70012100',
        city: 'München',
        available: false
    },
    {
        id: 18722,
        name: 'Bank J. Safra Sarasin (Deutschland)',
        bic: 'SRRADEM1BBK',
        blz: '70012200',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 18723,
        name: 'V-Bank',
        bic: 'VBANDEMMXXX',
        blz: '70012300',
        city: 'München',
        logoPath: '124.svg',
        available: false
    },
    {
        id: 18768,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI23',
        blz: '70089470',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18725,
        name: 'European Bank for Financial Services (ebase)',
        bic: 'EBSGDEMXXXX',
        blz: '70013000',
        city: 'Aschheim',
        available: true
    },
    {
        id: 18726,
        name: 'net-m privatbank 1891',
        bic: 'BVWBDE2WXXX',
        blz: '70013100',
        city: 'München',
        logoPath: '149.svg',
        available: true
    },
    {
        id: 18727,
        name: 'net-m privatbank 1891 (Gf TRAXPAY)',
        bic: 'BVWBDE2TRAX',
        blz: '70013155',
        logoPath: '149.svg',
        available: false
    },
    {
        id: 18728,
        name: 'Bankhaus Herzogpark',
        bic: 'HERZDEM1XXX',
        blz: '70013500',
        city: 'München',
        available: false
    },
    {
        id: 18729,
        name: 'transact Elektronische Zahlungssysteme',
        bic: 'TEZGDEB1XXX',
        blz: '70015000',
        city: 'Planegg',
        available: false
    },
    {
        id: 18730,
        name: 'transact Elektronische Zahlungssysteme',
        bic: 'TEZGDEB1001',
        blz: '70015015',
        city: 'Planegg',
        available: false
    },
    {
        id: 18731,
        name: 'transact Elektronische Zahlungssysteme',
        bic: 'TEZGDEB1002',
        blz: '70015025',
        city: 'Planegg',
        available: false
    },
    {
        id: 18732,
        name: 'transact Elektronische Zahlungssysteme',
        bic: 'TEZGDEB1003',
        blz: '70015035',
        city: 'Planegg',
        available: false
    },
    {
        id: 18733,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 645 M',
        bic: 'HYVEDEM1092',
        blz: '70020001',
        available: false
    },
    {
        id: 18734,
        name: 'BNP Paribas NL Deutschland',
        bic: 'WKVBDEM1XXX',
        blz: '70020300',
        city: 'München',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 18735,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33MUE',
        blz: '70020500',
        city: 'München',
        available: false
    },
    {
        id: 18736,
        name: 'INTESA SANPAOLO',
        bic: 'BCITDEFFMUC',
        blz: '70020800',
        city: 'München',
        logoPath: '88.svg',
        available: false
    },
    {
        id: 18737,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM418',
        blz: '70021180',
        city: 'Freising',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18738,
        name: 'Fidor Bank',
        bic: 'FDDODEMMXXX',
        blz: '70022200',
        city: 'München',
        logoPath: '80.svg',
        available: false
    },
    {
        id: 18739,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM643',
        blz: '70025175',
        city: 'Bad Tölz',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18740,
        name: 'Merck Finck Privatbankiers',
        bic: 'MEFIDEMMXXX',
        blz: '70030400',
        city: 'München',
        available: true
    },
    {
        id: 18741,
        name: 'Bankhaus Ludwig Sperrer',
        bic: 'BHLSDEM1XXX',
        blz: '70031000',
        city: 'Freising',
        available: true
    },
    {
        id: 18742,
        name: 'St.Galler Kantonalbank Deutschland',
        bic: 'GAKDDEM1XXX',
        blz: '70032500',
        city: 'München',
        available: true
    },
    {
        id: 18743,
        name: 'Baader Bank',
        bic: 'BDWBDEMMXXX',
        blz: '70033100',
        city: 'Unterschleißheim',
        logoPath: '48.svg',
        available: true
    },
    {
        id: 18744,
        name: 'Oldenburgische Landesbank (vormals Allianz Bank)',
        bic: 'OLBODEH2700',
        blz: '70035000',
        city: 'Oldenburg',
        logoPath: '104.svg',
        available: true
    },
    {
        id: 18769,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI45',
        blz: '70089472',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18756,
        name: 'Kreissparkasse München Starnberg Ebersberg',
        bic: 'BYLADEM1EBE',
        blz: '70051805',
        city: 'Ebersberg',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18755,
        name: 'Sparkasse Dachau',
        bic: 'BYLADEM1DAH',
        blz: '70051540',
        city: 'Dachau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18773,
        name: 'Volksbank Raiffeisenbank Dachau',
        bic: 'GENODEF1DCA',
        blz: '70091500',
        city: 'Dachau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 21040,
        name: 'ZVC FK 72 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010072',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 18752,
        name: 'Commerzbank Service-BZ',
        bic: 'COBADEFFXXX',
        blz: '70045050',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 21041,
        name: 'Rheingauer Volksbank (Gf GAA)',
        bic: 'GENODE51RGG',
        blz: '51091501',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18753,
        name: 'Bayerische Landesbank',
        bic: 'BYLADEMMXXX',
        blz: '70050000',
        city: 'München',
        available: false
    },
    {
        id: 21043,
        name: 'Commerzbank, Filiale Stuttgart 2',
        bic: 'COBADEFFXXX',
        blz: '60040075',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 20396,
        name: 'Raiffeisenbank Piestingtal eGen',
        bic: 'RLNWATWW642',
        blz: '32642',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18770,
        name: 'Hausbank München',
        bic: 'GENODEF1M04',
        blz: '70090100',
        city: 'München',
        available: true
    },
    {
        id: 18771,
        name: 'Hausbank München',
        bic: 'GENODEF1MU4',
        blz: '70090124',
        city: 'München',
        available: false
    },
    {
        id: 18772,
        name: 'Sparda-Bank München',
        bic: 'GENODEF1S04',
        blz: '70090500',
        city: 'München',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 18754,
        name: 'Sparkasse Freising',
        bic: 'BYLADEM1FSI',
        blz: '70051003',
        city: 'Freising',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18758,
        name: 'Sparkasse Landsberg-Dießen',
        bic: 'BYLADEM1LLD',
        blz: '70052060',
        city: 'Landsberg am Lech',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18641,
        name: 'Commerzbank vormals Dresdner Bank Zw Münsterstraße',
        bic: 'DRESDEFFI44',
        blz: '68080031',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18764,
        name: 'Commerzbank vormals Dresdner Bank Gf PCC DCC-ITGK 3',
        bic: 'DRESDEFFI55',
        blz: '70080085',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18765,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 4',
        bic: 'DRESDEFFJ23',
        blz: '70080086',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18760,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMMXXX',
        blz: '70070010',
        city: 'München',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18766,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFJ24',
        blz: '70080087',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18767,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 6',
        bic: 'DRESDEFFJ25',
        blz: '70080088',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18691,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB692',
        blz: '69270024',
        city: 'Singen (Hohentwiel)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18757,
        name: 'Kreis- und Stadtsparkasse Erding-Dorfen',
        bic: 'BYLADEM1ERD',
        blz: '70051995',
        city: 'Erding',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18762,
        name: 'Commerzbank vormals Dresdner Bank Zw 56',
        bic: 'DRESDEFF714',
        blz: '70080056',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19276,
        name: 'Vereinigte Sparkassen Gunzenhausen',
        bic: 'BYLADEM1GUN',
        blz: '76551540',
        city: 'Gunzenhausen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18801,
        name: 'Raiffeisenbank südöstl. Starnberger See',
        bic: 'GENODEF1SSB',
        blz: '70169331',
        city: 'Seeshaupt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18802,
        name: 'Raiffeisenbank Beuerberg-Eurasburg',
        bic: 'GENODEF1EUR',
        blz: '70169333',
        city: 'Eurasburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18803,
        name: 'Raiffeisenbank Nordkreis Landsberg',
        bic: 'GENODEF1ELB',
        blz: '70169351',
        city: 'Egling a.d.Paar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18778,
        name: 'Münchener Hypothekenbank',
        bic: 'MHYPDEMMXXX',
        blz: '70110500',
        city: 'München',
        available: false
    },
    {
        id: 18779,
        name: 'UBI BANCA Niederlassung München',
        bic: 'BEPODEMMXXX',
        blz: '70110600',
        city: 'München',
        available: true
    },
    {
        id: 18780,
        name: 'State Street Bank International',
        bic: 'SBOSDEMXXXX',
        blz: '70120100',
        city: 'München',
        available: false
    },
    {
        id: 18781,
        name: 'The Royal Bank of Scotland, Niederlassung Deutschland',
        bic: 'ABNADEFFMUN',
        blz: '70120200',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 18782,
        name: 'BNP Paribas Niederlassung Deutschland',
        bic: 'DABBDEMMXXX',
        blz: '70120400',
        city: 'München',
        logoPath: '185.svg',
        available: true
    },
    {
        id: 18783,
        name: 'CACEIS Bank, Germany Branch',
        bic: 'FMBKDEMMXXX',
        blz: '70120500',
        city: 'München',
        available: false
    },
    {
        id: 18784,
        name: 'Airbus Bank',
        bic: 'AGBMDEMMXXX',
        blz: '70120600',
        city: 'München',
        available: true
    },
    {
        id: 18785,
        name: 'Oberbank Ndl Deutschland',
        bic: 'OBKLDEMXXXX',
        blz: '70120700',
        city: 'München',
        available: true
    },
    {
        id: 18786,
        name: 'UniCredit Bank - HypoVereinsbank Ndl BACA',
        bic: 'HYVEDEM1107',
        blz: '70120900',
        available: false
    },
    {
        id: 18787,
        name: 'Bankhaus August Lenz & Co',
        bic: 'LENZDEM1XXX',
        blz: '70130700',
        city: 'München',
        logoPath: '56.svg',
        available: false
    },
    {
        id: 18788,
        name: 'Merkur Bank',
        bic: 'GENODEF1M06',
        blz: '70130800',
        city: 'München',
        logoPath: '100.svg',
        available: true
    },
    {
        id: 18789,
        name: 'DZ BANK',
        bic: 'GENODEFF701',
        blz: '70160000',
        city: 'München',
        logoPath: '69.svg',
        available: true
    },
    {
        id: 18804,
        name: 'Raiffeisenbank Erding',
        bic: 'GENODEF1EDR',
        blz: '70169356',
        city: 'Erding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18805,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1GIL',
        blz: '70169382',
        city: 'Gilching',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18806,
        name: 'Raiffeisenbank Gmund am Tegernsee',
        bic: 'GENODEF1GMU',
        blz: '70169383',
        city: 'Gmund a. Tegernsee',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18807,
        name: 'Raiffeisenbank Haag-Gars-Maitenbeth',
        bic: 'GENODEF1HMA',
        blz: '70169388',
        city: 'Haag i.OB',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18799,
        name: 'Alxing-Brucker Genossenschaftsbank',
        bic: 'GENODEF1ALX',
        blz: '70169310',
        city: 'Bruck',
        available: true
    },
    {
        id: 18808,
        name: 'Raiffeisenbank Höhenkirchen und Umgebung',
        bic: 'GENODEF1HHK',
        blz: '70169402',
        city: 'Höhenkirchen-Siegertsbrunn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18065,
        name: 'Volksbank Untere Saar',
        bic: 'GENODE51LOS',
        blz: '59392200',
        city: 'Losheim am See',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18137,
        name: 'Raiffeisenbank Rottumtal',
        bic: 'GENODES1RRE',
        blz: '60069461',
        city: 'Ochsenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18185,
        name: 'Volksbank Remseck',
        bic: 'GENODES1REM',
        blz: '60069905',
        city: 'Remseck am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18190,
        name: 'Raiffeisenbank Tüngental',
        bic: 'GENODES1TUN',
        blz: '60069950',
        city: 'Schwäbisch Hall',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20072,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39100',
        available: false
    },
    {
        id: 18820,
        name: 'Raiffbk Neumarkt-St. Veit - Niederbergkirchen',
        bic: 'GENODEF1NSV',
        blz: '70169474',
        city: 'Neumarkt-Sankt Veit',
        available: true
    },
    {
        id: 18191,
        name: 'Raiffeisenbank Ehingen-Hochsträß',
        bic: 'GENODES1ODD',
        blz: '60069971',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18192,
        name: 'Raiffeisenbank Sechta-Ries',
        blz: '60069972',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18193,
        name: 'Raiffeisenbank Böllingertal',
        bic: 'GENODES1BOE',
        blz: '60069976',
        city: 'Heilbronn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18194,
        name: 'Raiffeisenbank Maselheim-Äpfingen',
        bic: 'GENODES1RMH',
        blz: '60069980',
        city: 'Maselheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18810,
        name: 'Raiffeisenbank Singoldtal',
        bic: 'GENODEF1HUA',
        blz: '70169413',
        city: 'Hurlach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18877,
        name: 'VR meine Raiffeisenbank',
        bic: 'GENODEF1AOE',
        blz: '71061009',
        city: 'Altötting',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18879,
        name: 'Volksbank Raiffeisenbank Oberbayern Südost',
        bic: 'GENODEF1BGL',
        blz: '71090000',
        city: 'Bad Reichenhall',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18878,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1AGE',
        blz: '71062802',
        city: 'Anger',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18885,
        name: 'Commerzbank Rosenheim',
        bic: 'COBADEFFXXX',
        blz: '71140041',
        city: 'Rosenheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18196,
        name: 'Deutsche Bank',
        bic: 'DEUTDESSXXX',
        blz: '60070070',
        city: 'Stuttgart',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18887,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '71142041',
        city: 'Waldkraiburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18197,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF600',
        blz: '60080000',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18849,
        name: 'Münchner Bank',
        bic: 'GENODEF1M01',
        blz: '70190000',
        city: 'München',
        available: true
    },
    {
        id: 18850,
        name: 'GLS Gemeinschaftsbank',
        bic: 'GENODEF1M09',
        blz: '70190200',
        available: false
    },
    {
        id: 18851,
        name: 'LfA Förderbank Bayern',
        bic: 'LFFBDEMMXXX',
        blz: '70220000',
        city: 'München',
        logoPath: '179.svg',
        available: false
    },
    {
        id: 18852,
        name: 'BHF-BANK',
        bic: 'BHFBDEFF700',
        blz: '70220200',
        city: 'München',
        available: true
    },
    {
        id: 18853,
        name: 'BMW Bank',
        bic: 'BMWBDEMUXXX',
        blz: '70220300',
        city: 'München',
        logoPath: '47.svg',
        available: true
    },
    {
        id: 18854,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1MUE',
        blz: '70220400',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 18855,
        name: 'Vereinsbank Victoria Bauspar',
        bic: 'VVAGDEM1XXX',
        blz: '70220800',
        city: 'München',
        available: false
    },
    {
        id: 18857,
        name: 'Isbank Fil München',
        bic: 'ISBKDEFXMUN',
        blz: '70230600',
        city: 'München',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 18859,
        name: 'Bundesbank eh Garmisch-Partenkirchen',
        bic: 'MARKDEF1703',
        blz: '70300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18860,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM654',
        blz: '70320090',
        city: 'Garmisch-Partenkirchen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18861,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 635 Gar',
        bic: 'HYVEDEM1091',
        blz: '70320305',
        available: false
    },
    {
        id: 18862,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM466',
        blz: '70321194',
        city: 'Weilheim i. OB',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18863,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM664',
        blz: '70322192',
        city: 'Murnau a. Staffelsee',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18886,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '71141041',
        city: 'Mühldorf a. Inn',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18869,
        name: 'Bundesbank eh Bad Reichenhall',
        bic: 'MARKDEF1710',
        blz: '71000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18870,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM410',
        blz: '71020072',
        city: 'Bad Reichenhall',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18871,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM629',
        blz: '71021270',
        city: 'Neuötting',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18872,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM453',
        blz: '71022182',
        city: 'Traunstein',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18873,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM632',
        blz: '71023173',
        city: 'Burghausen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18880,
        name: 'Bundesbank eh Rosenheim',
        bic: 'MARKDEF1711',
        blz: '71100000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18881,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM448',
        blz: '71120077',
        city: 'Rosenheim',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18882,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM644',
        blz: '71120078',
        city: 'Rottach-Egern',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18883,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM438',
        blz: '71121176',
        city: 'Mühldorf a. Inn',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18884,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM457',
        blz: '71122183',
        city: 'Waldkraiburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18198,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF608',
        blz: '60080055',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18199,
        name: 'Commerzbank vormals Dresdner Bank Gf Zw 57',
        bic: 'DRESDEFF609',
        blz: '60080057',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18200,
        name: 'Commerzbank vormals Dresdner Bank ITGK 2',
        bic: 'DRESDEFFI50',
        blz: '60080085',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18201,
        name: 'Commerzbank vormals Dresdner Bank Gf PCC-ITGK 3',
        bic: 'DRESDEFFI54',
        blz: '60080086',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18865,
        name: 'Sparkasse Oberland',
        bic: 'BYLADEM1WHM',
        blz: '70351030',
        city: 'Weilheim i. OB',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18874,
        name: 'Sparkasse Berchtesgadener Land',
        bic: 'BYLADEM1BGL',
        blz: '71050000',
        city: 'Bad Reichenhall',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18834,
        name: 'Raiffeisenbank Taufkirchen-Oberneukirchen',
        bic: 'GENODEF1TAE',
        blz: '70169568',
        city: 'Oberneukirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18835,
        name: 'Raiffeisenbank Thalheim -alt-',
        bic: 'GENODEF1FTH',
        blz: '70169570',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18836,
        name: 'Raiffeisenbank Tölzer Land',
        bic: 'GENODEF1DTZ',
        blz: '70169571',
        city: 'Bad Tölz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18837,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1TRH',
        blz: '70169575',
        city: 'Türkheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18838,
        name: 'Raiffeisen-Volksbank Tüßling-Unterneukirchen',
        bic: 'GENODEF1TUS',
        blz: '70169576',
        city: 'Tüßling',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18839,
        name: 'Raiffeisenbank Unterschleißheim-Haimhn -alt-',
        bic: 'GENODEF1UNS',
        blz: '70169585',
        city: 'Unterschleißheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18846,
        name: 'Raiffeisenbank Zorneding',
        bic: 'GENODEF1ZOR',
        blz: '70169619',
        city: 'Zorneding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18847,
        name: 'Raiffeisenbank Aiglsbach',
        bic: 'GENODEF1AIG',
        blz: '70169653',
        city: 'Aiglsbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18848,
        name: 'Raiffeisenbank Hallertau',
        bic: 'GENODEF1RHT',
        blz: '70169693',
        city: 'Rudelzhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18856,
        name: 'Wüstenrot Bausparkasse',
        bic: 'DRESDEFFBFC',
        blz: '70220900',
        city: 'München',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18866,
        name: 'Raiffeisenbank Wallgau-Krün',
        bic: 'GENODEF1WAK',
        blz: '70362595',
        city: 'Wallgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18868,
        name: 'Volksbank-Raiffeisenbank Penzberg',
        bic: 'GENODEF1PZB',
        blz: '70391800',
        city: 'Penzberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18858,
        name: 'Kreissparkasse München Starnberg Ebersberg',
        bic: 'BYLADEM1KMS',
        blz: '70250150',
        city: 'München',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18889,
        name: 'Sparkasse Altötting-Mühldorf',
        bic: 'BYLADEM1MDF',
        blz: '71151020',
        city: 'Mühldorf a. Inn',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18864,
        name: 'Kreissparkasse Garmisch-Partenkirchen',
        bic: 'BYLADEM1GAP',
        blz: '70350000',
        city: 'Garmisch-Partenkirchen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18875,
        name: 'Kreissparkasse Altötting-Burghausen -alt-',
        bic: 'BYLADEM1AOE',
        blz: '71051010',
        city: 'Altötting',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18876,
        name: 'Kreissparkasse Traunstein-Trostberg',
        bic: 'BYLADEM1TST',
        blz: '71052050',
        city: 'Traunstein',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18890,
        name: 'Kreissparkasse Bad Aibling -alt-',
        bic: 'BYLADEM1BAB',
        blz: '71151240',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18891,
        name: 'Kreissparkasse Miesbach-Tegernsee',
        bic: 'BYLADEM1MIB',
        blz: '71152570',
        city: 'Miesbach',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18892,
        name: 'Kreis- und Stadtsparkasse Wasserburg',
        bic: 'BYLADEM1WSB',
        blz: '71152680',
        city: 'Wasserburg a. Inn',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18888,
        name: 'Sparkasse Rosenheim-Bad Aibling',
        bic: 'BYLADEM1ROS',
        blz: '71150000',
        city: 'Rosenheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18947,
        name: 'Raiffeisen-Volksbank Ries',
        bic: 'GENODEF1NOE',
        blz: '72069329',
        city: 'Nördlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18902,
        name: 'Bundesbank',
        bic: 'MARKDEF1720',
        blz: '72000000',
        city: 'Augsburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 18903,
        name: 'SEB',
        bic: 'ESSEDE5F720',
        blz: '72010111',
        city: 'Augsburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 18904,
        name: 'Bank für Tirol und Vorarlberg Deutschland',
        bic: 'BTVADE61XXX',
        blz: '72012300',
        city: 'Memmingen',
        available: true
    },
    {
        id: 18905,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM408',
        blz: '72020070',
        city: 'Augsburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18906,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 677 Agsb',
        bic: 'HYVEDEM1102',
        blz: '72020240',
        available: false
    },
    {
        id: 18907,
        name: 'Augsburger Aktienbank',
        bic: 'AUGBDE77XXX',
        blz: '72020700',
        city: 'Augsburg',
        available: true
    },
    {
        id: 18908,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM236',
        blz: '72021271',
        city: 'Aichach',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18909,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM259',
        blz: '72021876',
        city: 'Günzburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18910,
        name: 'Fürst Fugger Privatbank',
        bic: 'FUBKDE71XXX',
        blz: '72030014',
        city: 'Augsburg',
        available: true
    },
    {
        id: 18911,
        name: 'Bankhaus Anton Hafner',
        bic: 'ANHODE77XXX',
        blz: '72030227',
        city: 'Augsburg',
        available: true
    },
    {
        id: 18917,
        name: 'Handels- und Gewerbebank Augsburg -alt-',
        bic: 'GENODEF1A03',
        blz: '72060300',
        available: false
    },
    {
        id: 18948,
        name: 'Raiffeisenbank Schwaben Mitte',
        bic: 'GENODEF1BLT',
        blz: '72069736',
        city: 'Bellenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18949,
        name: 'Raiffeisenbank Pfaffenhausen',
        bic: 'GENODEF1PFA',
        blz: '72069789',
        city: 'Pfaffenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18950,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMM720',
        blz: '72070001',
        city: 'Augsburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18952,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF720',
        blz: '72080001',
        city: 'Augsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18953,
        name: 'Augusta-Bank Raiffeisen-Volksbank',
        bic: 'GENODEF1AUB',
        blz: '72090000',
        city: 'Augsburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18894,
        name: 'VR Bank Rosenheim-Chiemsee',
        bic: 'GENODEF1ROR',
        blz: '71160161',
        city: 'Rosenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18895,
        name: 'Volksbank-Raiffeisenbank Chiemsee -alt-',
        bic: 'GENODEF1PRV',
        blz: '71161964',
        city: 'Prien a. Chiemsee',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18896,
        name: 'Raiffeisenbank Oberaudorf',
        bic: 'GENODEF1OBD',
        blz: '71162355',
        city: 'Oberaudorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18897,
        name: 'Raiffeisenbank Aschau-Samerberg',
        bic: 'GENODEF1ASU',
        blz: '71162804',
        city: 'Aschau i. Chiemgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18898,
        name: 'Raiffeisenbank Mangfalltal -alt-',
        bic: 'GENODEF1AIB',
        blz: '71165150',
        city: 'Bad Aibling',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18899,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF711',
        blz: '71180005',
        city: 'Rosenheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18900,
        name: 'Volksbank Rosenheim -alt-',
        bic: 'GENODEF1ROV',
        blz: '71190000',
        city: 'Rosenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18901,
        name: 'VR-Bank Burghausen-Mühldorf',
        bic: 'GENODEF1MUL',
        blz: '71191000',
        city: 'Mühldorf a. Inn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18912,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '72040046',
        city: 'Augsburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18913,
        name: 'Stadtsparkasse Augsburg',
        bic: 'AUGSDE77XXX',
        blz: '72050000',
        city: 'Augsburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18926,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1HTF',
        blz: '72069105',
        city: 'Hiltenfingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18928,
        name: 'Raiffeisenbank Aschberg',
        bic: 'GENODEF1HZH',
        blz: '72069113',
        city: 'Holzheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18202,
        name: 'Commerzbank vormals Dresdner Bank, PCC DC-ITGK 4',
        bic: 'DRESDEFFI57',
        blz: '60080087',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18931,
        name: 'Raiffeisenbank Jettingen-Scheppach',
        bic: 'GENODEF1JET',
        blz: '72069123',
        city: 'Jettingen-Scheppach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18203,
        name: 'Commerzbank vormals Dresdner Bank, PCC DC-ITGK 5',
        bic: 'DRESDEFFI58',
        blz: '60080088',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18204,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI21',
        blz: '60089450',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18932,
        name: 'Raiffeisenbank Mittelschwaben',
        bic: 'GENODEF1BBT',
        blz: '72069126',
        city: 'Roggenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18205,
        name: 'Volksbank Stuttgart',
        bic: 'VOBADESSXXX',
        blz: '60090100',
        city: 'Stuttgart',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18929,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1HZR',
        blz: '72069114',
        city: 'Holzheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18933,
        name: 'Raiffeisenbank Krumbach/Schwaben',
        bic: 'GENODEF1KRR',
        blz: '72069132',
        city: 'Krumbach (Schwaben)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18934,
        name: 'Raiffeisenbank Stauden',
        bic: 'GENODEF1LST',
        blz: '72069135',
        city: 'Langenneufnach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18935,
        name: 'Raiffeisenbank Langweid-Achsheim -alt-',
        blz: '72069139',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18936,
        name: 'Raiffeisenbank -alt-',
        blz: '72069141',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18937,
        name: 'Raiffeisenbank Kissing-Mering',
        bic: 'GENODEF1MRI',
        blz: '72069155',
        city: 'Mering',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18938,
        name: 'Raiffeisenbank Unteres Zusamtal',
        bic: 'GENODEF1BWI',
        blz: '72069179',
        city: 'Buttenwiesen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18939,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1OFF',
        blz: '72069181',
        city: 'Offingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18940,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1RLI',
        blz: '72069193',
        city: 'Rehling',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18941,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1RGB',
        blz: '72069209',
        city: 'Roggenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18942,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1SMU',
        blz: '72069220',
        city: 'Schwabmünchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18943,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1THS',
        blz: '72069235',
        city: 'Thannhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18944,
        name: 'Raiffeisenbank Wittislingen',
        bic: 'GENODEF1WTS',
        blz: '72069263',
        city: 'Wittislingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18920,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1AIL',
        blz: '72069005',
        city: 'Aindling',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18945,
        name: 'Raiffeisenbank Augsburger Land West',
        bic: 'GENODEF1ZUS',
        blz: '72069274',
        city: 'Zusmarshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18946,
        name: 'Raiffeisen-Volksbank Wemding',
        bic: 'GENODEF1WDN',
        blz: '72069308',
        city: 'Wemding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18914,
        name: 'Kreissparkasse Augsburg',
        bic: 'BYLADEM1AUG',
        blz: '72050101',
        city: 'Augsburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18915,
        name: 'Sparkasse Aichach-Schrobenhausen',
        bic: 'BYLADEM1AIC',
        blz: '72051210',
        city: 'Aichach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18954,
        name: 'Sparda-Bank Augsburg',
        bic: 'GENODEF1S03',
        blz: '72090500',
        city: 'Augsburg',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 18992,
        name: 'Kreis- und Stadtsparkasse Dillingen',
        bic: 'BYLADEM1DLG',
        blz: '72251520',
        city: 'Dillingen a.d.Donau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18957,
        name: 'Bundesbank eh Ingolstadt',
        bic: 'MARKDEF1721',
        blz: '72100000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 18958,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM426',
        blz: '72120078',
        city: 'Ingolstadt',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18959,
        name: 'UniCredit Bank - HypoVereinsbank',
        blz: '72120079',
        logoPath: '181.svg',
        available: false
    },
    {
        id: 18960,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 648 Ing',
        bic: 'HYVEDEM1093',
        blz: '72120207',
        available: false
    },
    {
        id: 18961,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM665',
        blz: '72122181',
        city: 'Neuburg a.d.Donau',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18991,
        name: 'Sparkasse Donauwörth',
        bic: 'BYLADEM1DON',
        blz: '72250160',
        city: 'Donauwörth',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18972,
        name: 'Schrobenhausener Bank',
        bic: 'GENODEF1SBN',
        blz: '72169218',
        city: 'Schrobenhausen',
        logoPath: '131.svg',
        available: true
    },
    {
        id: 18968,
        name: 'Volksbank Raiffeisenbank Bayern Mitte',
        bic: 'GENODEF1INP',
        blz: '72160818',
        city: 'Ingolstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18988,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM255',
        blz: '72220074',
        city: 'Donauwörth',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18989,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM263',
        blz: '72223182',
        city: 'Nördlingen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18995,
        name: 'Genossenschaftsbank Wertingen -alt-',
        bic: 'GENODEF1WTG',
        blz: '72262901',
        available: false
    },
    {
        id: 19000,
        name: 'Bundesbank eh Memmingen',
        bic: 'MARKDEF1731',
        blz: '73100000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19001,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM436',
        blz: '73120075',
        city: 'Memmingen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18969,
        name: 'Raiffeisenbank Aresing-Hörzhausen-Schiltberg -alt-',
        bic: 'GENODEF1ARH',
        blz: '72169013',
        city: 'Aresing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19004,
        name: 'Genossenschaftsbank Unterallgäu',
        bic: 'GENODEF1MIR',
        blz: '73160000',
        city: 'Mindelheim',
        available: true
    },
    {
        id: 19009,
        name: 'Bundesbank eh Kempten',
        bic: 'MARKDEF1733',
        blz: '73300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19010,
        name: 'Hypo Vorarlberg Bank',
        blz: '73311600',
        city: 'Riezlern',
        available: false
    },
    {
        id: 19011,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM428',
        blz: '73320073',
        city: 'Kempten (Allgäu)',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19012,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 669 Kpt',
        bic: 'HYVEDEM1097',
        blz: '73320442',
        available: false
    },
    {
        id: 19013,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM567',
        blz: '73321177',
        city: 'Oberstdorf',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 18970,
        name: 'Raiffeisenbank Aresing-Gerolsbach',
        bic: 'GENODEF1GSB',
        blz: '72169080',
        city: 'Gerolsbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18971,
        name: 'Raiffeisenbank Hohenwart -alt-',
        bic: 'GENODEF1HHW',
        blz: '72169111',
        city: 'Hohenwart',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18973,
        name: 'Raiffeisenbank Schrobenhausener Land',
        bic: 'GENODEF1WFN',
        blz: '72169246',
        city: 'Langenmosen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18974,
        name: 'Raiffeisenbank Beilngries',
        bic: 'GENODEF1BLN',
        blz: '72169380',
        city: 'Beilngries',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18975,
        name: 'Raiffeisenbank Berg im Gau-Langenmosen -alt-',
        bic: 'GENODEF1LBE',
        blz: '72169733',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18976,
        name: 'Raiffeisenbank Ehekirchen-Oberhausen',
        bic: 'GENODEF1WDF',
        blz: '72169745',
        city: 'Ehekirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18977,
        name: 'Raiffeisenbank Ober-Unterhausen-Sinning -alt-',
        bic: 'GENODEF1OUS',
        blz: '72169753',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18978,
        name: 'VR Bank Neuburg-Rain',
        bic: 'GENODEF1ND2',
        blz: '72169756',
        city: 'Neuburg a.d.Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18979,
        name: 'Raiffeisenbank Donaumooser Land',
        bic: 'GENODEF1WRI',
        blz: '72169764',
        city: 'Karlshuld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18980,
        name: 'Raiffeisenbank im Donautal',
        bic: 'GENODEF1GAH',
        blz: '72169812',
        city: 'Gaimersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18981,
        name: 'Raiffeisenbank Riedenburg-Lobsing',
        bic: 'GENODEF1RBL',
        blz: '72169831',
        city: 'Riedenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18983,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB721',
        blz: '72170024',
        city: 'Ingolstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18984,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF721',
        blz: '72180002',
        city: 'Ingolstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18985,
        name: 'Volksbank Raiffeisenbank Eichstätt',
        bic: 'GENODEF1EIH',
        blz: '72191300',
        city: 'Eichstätt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18206,
        name: 'Volksbank Zuffenhausen m Zndl Stammheimer VB',
        bic: 'GENODES1ZUF',
        blz: '60090300',
        city: 'Stuttgart',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18987,
        name: 'Volksbank Schrobenhausen -alt-',
        bic: 'GENODEF1SHV',
        blz: '72191800',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18215,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '60241074',
        city: 'Backnang',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18156,
        name: 'Ebhauser Bank -Raiffeisenbank -alt-',
        blz: '60069647',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18997,
        name: 'Sparkasse Neu-Ulm-Illertissen',
        bic: 'BYLADEM1NUL',
        blz: '73050000',
        city: 'Neu-Ulm',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18964,
        name: 'Sparkasse Eichstätt',
        bic: 'BYLADEM1EIS',
        blz: '72151340',
        city: 'Eichstätt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18993,
        name: 'Raiffeisenbank Rain am Lech',
        bic: 'GENODEF1RLH',
        blz: '72261754',
        city: 'Rain',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18996,
        name: 'Raiffeisen-Volksbank Donauwörth',
        bic: 'GENODEF1DON',
        blz: '72290100',
        city: 'Donauwörth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18965,
        name: 'Sparkasse Pfaffenhofen',
        bic: 'BYLADEM1PAF',
        blz: '72151650',
        city: 'Pfaffenhofen a.d.Ilm',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18998,
        name: 'VR-Bank Neu-Ulm',
        bic: 'GENODEF1NU1',
        blz: '73061191',
        city: 'Neu-Ulm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18999,
        name: 'Volksbank Neu-Ulm',
        bic: 'GENODEF1NUV',
        blz: '73090000',
        city: 'Neu-Ulm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19002,
        name: 'Commerzbank Memmingen',
        bic: 'COBADEFFXXX',
        blz: '73140046',
        city: 'Memmingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19005,
        name: 'Raiffeisenbank Bad Grönenbach',
        blz: '73161455',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19006,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF731',
        blz: '73180011',
        city: 'Memmingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19007,
        name: 'VR-Bank Memmingen',
        bic: 'GENODEF1MM1',
        blz: '73190000',
        city: 'Memmingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19008,
        name: 'Volksbank Ulm-Biberach',
        bic: 'ULMVDE66XXX',
        blz: '73191500',
        city: 'Illertissen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18962,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '72140052',
        city: 'Ingolstadt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18966,
        name: 'Sparkasse Aichach-Schrobenhausen',
        bic: 'BYLADEM1SSH',
        blz: '72151880',
        city: 'Aichach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20073,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39101',
        available: false
    },
    {
        id: 18967,
        name: 'Sparkasse Neuburg-Rain',
        bic: 'BYLADEM1NEB',
        blz: '72152070',
        city: 'Neuburg a.d.Donau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18955,
        name: 'PSD Bank München',
        bic: 'GENODEF1P14',
        blz: '72090900',
        city: 'Augsburg',
        available: true
    },
    {
        id: 18990,
        name: 'Sparkasse Nördlingen',
        bic: 'BYLADEM1NLG',
        blz: '72250000',
        city: 'Nördlingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19014,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM570',
        blz: '73322380',
        city: 'Sonthofen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19015,
        name: 'Gabler-Saliter Bankgeschäft',
        bic: 'GABLDE71XXX',
        blz: '73331700',
        city: 'Obergünzburg',
        available: true
    },
    {
        id: 19038,
        name: 'Raiffeisenbank Kempten-Oberallgäu',
        bic: 'GENODEF1SFO',
        blz: '73369920',
        city: 'Sonthofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19020,
        name: 'Walser Privatbank',
        blz: '73361592',
        logoPath: '39.svg',
        available: false
    },
    {
        id: 19044,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF733',
        blz: '73380004',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19024,
        name: 'BodenseeBank',
        bic: 'GENODEF1LBB',
        blz: '73369821',
        city: 'Lindau (Bodensee)',
        available: true
    },
    {
        id: 19045,
        name: 'Allgäuer Volksbank Kempten-Sonthofen',
        bic: 'GENODEF1KEV',
        blz: '73390000',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19047,
        name: 'Volksbank Immenstadt',
        bic: 'GENODEF1IMV',
        blz: '73392000',
        city: 'Immenstadt i. Allgäu',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19056,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF734',
        blz: '73480013',
        city: 'Kaufbeuren',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19057,
        name: 'Volksbank Ostallgäu -alt-',
        bic: 'GENODEF1OAL',
        blz: '73491300',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19060,
        name: 'Raiffeisenlandesbank OÖ Zndl Süddeutschland',
        bic: 'RZOODE77XXX',
        blz: '74020100',
        city: 'München',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19040,
        name: 'Raiffeisenbank Seeg -alt-',
        bic: 'GENODEF1SER',
        blz: '73369936',
        city: 'Seeg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19041,
        name: 'Raiffeisenbank Wald-Görisried',
        bic: 'GENODEF1WGO',
        blz: '73369954',
        city: 'Wald',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19042,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMM733',
        blz: '73370008',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19062,
        name: 'Commerzbank Passau',
        bic: 'COBADEFFXXX',
        blz: '74040082',
        city: 'Passau',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19017,
        name: 'Sparkasse Allgäu',
        bic: 'BYLADEM1ALG',
        blz: '73350000',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19049,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM427',
        blz: '73420071',
        city: 'Kaufbeuren',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19050,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 693 Kaufb',
        bic: 'HYVEDEM1104',
        blz: '73420546',
        available: false
    },
    {
        id: 19051,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM666',
        blz: '73421478',
        city: 'Schongau',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19058,
        name: 'Bundesbank eh Passau',
        bic: 'MARKDEF1740',
        blz: '74000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19059,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM445',
        blz: '74020074',
        city: 'Passau',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19061,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 672 Pass',
        bic: 'HYVEDEM1100',
        blz: '74020414',
        available: false
    },
    {
        id: 19018,
        name: 'Sparkasse Riezlern, Kleinwalsertal',
        bic: 'BYLADEM1ALR',
        blz: '73351635',
        city: 'Riezlern',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19022,
        name: 'Raiffeisen-Landesbank Tirol',
        bic: 'RZTIDE71XXX',
        blz: '73362500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19033,
        name: 'Raiffeisenbank Haldenwang',
        bic: 'GENODEF1HWG',
        blz: '73369881',
        city: 'Haldenwang',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19070,
        name: 'Raiffeisenbank i Lkr Passau-Nord',
        bic: 'GENODEF1TIE',
        blz: '74062786',
        city: 'Tiefenbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18157,
        name: 'Raiffeisenbank',
        bic: 'GENODES1EHZ',
        blz: '60069648',
        city: 'Ummendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19052,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '73440048',
        city: 'Kaufbeuren',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18166,
        name: 'Raiffeisenbank Gammesfeld',
        bic: 'GENODES1RGF',
        blz: '60069710',
        city: 'Blaufelden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18160,
        name: 'Abtsgmünder Bank -Raiffeisen-',
        bic: 'GENODES1ABR',
        blz: '60069673',
        city: 'Abtsgmünd',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18161,
        name: 'Raiffeisenbank Bretzfeld-Neuenstein',
        bic: 'GENODES1BRZ',
        blz: '60069680',
        city: 'Bretzfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18162,
        name: 'Raiffeisenbank Wangen',
        bic: 'GENODES1RWA',
        blz: '60069685',
        city: 'Wangen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18163,
        name: 'Raiffeisenbank Enzberg',
        bic: 'GENODES1ENZ',
        blz: '60069692',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18164,
        name: 'Raiffeisenbank Schlat -alt-',
        bic: 'GENODES1SLA',
        blz: '60069705',
        city: 'Schlat',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18165,
        name: 'Raiffeisenbank',
        bic: 'GENODES1MEH',
        blz: '60069706',
        city: 'Mehrstetten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19048,
        name: 'Volksbank Tirol Jungholz',
        bic: 'VBOEDE71XXX',
        blz: '73392400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18167,
        name: 'Raiffeisenbank Kocher-Jagst',
        bic: 'GENODES1IBR',
        blz: '60069714',
        city: 'Ingelfingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18168,
        name: 'Raiffeisenbank Nattheim -alt-',
        blz: '60069716',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18169,
        name: 'Raiffeisenbank Heroldstatt -alt-',
        bic: 'GENODES1RHS',
        blz: '60069724',
        city: 'Heroldstatt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18170,
        name: 'Raiffeisenbank Oberstenfeld -alt-',
        bic: 'GENODES1ROF',
        blz: '60069727',
        city: 'Oberstenfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19055,
        name: 'VR Bank Kaufbeuren-Ostallgäu',
        bic: 'GENODEF1KFB',
        blz: '73460046',
        city: 'Kaufbeuren',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19065,
        name: 'Raiffeisenbank Am Goldenen Steig',
        bic: 'GENODEF1RGS',
        blz: '74061101',
        city: 'Röhrnbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19066,
        name: 'Raiffeisenbank Unteres Inntal',
        bic: 'GENODEF1NUI',
        blz: '74061564',
        city: 'Neuhaus a.Inn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19067,
        name: 'Raiffeisenbank Ortenburg-Kirchberg',
        bic: 'GENODEF1ORT',
        blz: '74061670',
        city: 'Ortenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19068,
        name: 'VR-Bank Rottal-Inn',
        bic: 'GENODEF1PFK',
        blz: '74061813',
        city: 'Pfarrkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19069,
        name: 'Volksbank - Raiffeisenbank Vilshofen',
        bic: 'GENODEF1VIR',
        blz: '74062490',
        city: 'Vilshofen an der Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19019,
        name: 'Dornbirner Sparkasse',
        blz: '73351840',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19063,
        name: 'Sparkasse Passau',
        bic: 'BYLADEM1PAS',
        blz: '74050000',
        city: 'Passau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19053,
        name: 'Kreis- und Stadtsparkasse Kaufbeuren',
        bic: 'BYLADEM1KFB',
        blz: '73450000',
        city: 'Kaufbeuren',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19021,
        name: 'Bankhaus Jungholz Zndl der Raiffeisenbank Reutte',
        bic: 'RBRTAT22BHJ',
        blz: '73362421',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19034,
        name: 'Raiffeisenbank Irsee-Pforzen-Rieden -alt-',
        bic: 'GENODEF1PIR',
        blz: '73369888',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19035,
        name: 'Raiffeisenbank Kempten',
        bic: 'GENODEF1KM1',
        blz: '73369902',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19046,
        name: 'Volksbank im Kleinwalsertal',
        bic: 'VOVBAT2B',
        blz: '73391600',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19036,
        name: 'Raiffeisenbank Obergermaringen',
        bic: 'GENODEF1GMG',
        blz: '73369915',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19054,
        name: 'Kreissparkasse Schongau',
        bic: 'BYLADEM1SOG',
        blz: '73451450',
        city: 'Schongau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19037,
        name: 'Raiffeisenbank Kirchweihtal',
        bic: 'GENODEF1OKI',
        blz: '73369918',
        city: 'Pforzen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19064,
        name: 'Sparkasse Freyung-Grafenau',
        bic: 'BYLADEM1FRG',
        blz: '74051230',
        city: 'Freyung',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 21052,
        name: 'Deutsche Bank GF F-SIP',
        bic: 'DEUTDEFFSIP',
        blz: '50070011',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 18178,
        name: 'Raiffeisenbank Urbach',
        bic: 'GENODES1URB',
        blz: '60069832',
        city: 'Urbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19098,
        name: 'VR-Bank Landau-Mengkofen',
        bic: 'GENODEF1LND',
        blz: '74191000',
        city: 'Landau a.d.Isar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19096,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF741',
        blz: '74180009',
        city: 'Deggendorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19084,
        name: 'Bundesbank eh Deggendorf',
        bic: 'MARKDEF1741',
        blz: '74100000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19085,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM415',
        blz: '74120071',
        city: 'Deggendorf',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19086,
        name: 'UniCredit Bank - HypoVereinsbank 674 Dgdf',
        bic: 'HYVEDEM1101',
        blz: '74120514',
        available: false
    },
    {
        id: 19087,
        name: 'TEBA Kreditbank',
        bic: 'TEKRDE71XXX',
        blz: '74131000',
        city: 'Landau a.d.Isar',
        available: false
    },
    {
        id: 19097,
        name: 'VR GenoBank DonauWald',
        bic: 'GENODEF1DGV',
        blz: '74190000',
        city: 'Viechtach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19099,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM452',
        blz: '74220075',
        city: 'Straubing',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19100,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM675',
        blz: '74221170',
        city: 'Cham',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19104,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1SR2',
        blz: '74260110',
        city: 'Straubing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19107,
        name: 'CB Bank',
        bic: 'CBSRDE71XXX',
        blz: '74290100',
        city: 'Straubing',
        logoPath: '64.svg',
        available: true
    },
    {
        id: 19108,
        name: 'Bundesbank eh Landshut',
        bic: 'MARKDEF1743',
        blz: '74300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19109,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM433',
        blz: '74320073',
        city: 'Landshut',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19110,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 601 Ldsht',
        bic: 'HYVEDEM1090',
        blz: '74320307',
        available: false
    },
    {
        id: 19082,
        name: 'VR-Bank Passau',
        bic: 'GENODEF1PA1',
        blz: '74090000',
        city: 'Passau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19102,
        name: 'Sparkasse Niederbayern-Mitte',
        bic: 'BYLADEM1SRG',
        blz: '74250000',
        city: 'Straubing',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18177,
        name: 'Raiffeisenbank',
        bic: 'GENODES1RMO',
        blz: '60069817',
        city: 'Mötzingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18173,
        name: 'Raiffeisenbank Kreßberg -alt-',
        blz: '60069773',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19095,
        name: 'Raiffeisenbank Metten',
        blz: '74167099',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15857,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK) F 700',
        bic: 'DEUTDEDBBER',
        blz: '10070024',
        city: 'Berlin',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 15966,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF170',
        blz: '17080000',
        city: 'Frankfurt (Oder)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17133,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB420',
        blz: '42070024',
        city: 'Gelsenkirchen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19088,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '74140048',
        city: 'Deggendorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19090,
        name: 'Sparkasse Regen-Viechtach',
        bic: 'BYLADEM1REG',
        blz: '74151450',
        city: 'Regen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19092,
        name: 'Raiffeisenbank Hengersberg-Schöllnach',
        bic: 'GENODEF1HBW',
        blz: '74161608',
        city: 'Hengersberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18930,
        name: 'Raiffeisenbank Ichenhausen',
        bic: 'GENODEF1ICH',
        blz: '72069119',
        city: 'Ichenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18986,
        name: 'Hallertauer Volksbank',
        bic: 'GENODEF1PFI',
        blz: '72191600',
        city: 'Pfaffenhofen a.d.Ilm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18994,
        name: 'Raiffeisen-Volksbank Dillingen',
        bic: 'GENODEF1DLG',
        blz: '72262401',
        city: 'Dillingen a.d.Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16683,
        name: 'Commerzbank vormals Dresdner Bank Ztv 22',
        bic: 'DRESDEFFI28',
        blz: '30080022',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19134,
        name: 'Bundesbank',
        bic: 'MARKDEF1750',
        blz: '75000000',
        city: 'Regensburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19135,
        name: 'SEB',
        bic: 'ESSEDE5F750',
        blz: '75010111',
        city: 'Regensburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 16684,
        name: 'Commerzbank vormals Dresdner Bank Zw 41',
        bic: 'DRESDEFFI29',
        blz: '30080041',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16685,
        name: 'Commerzbank vormals Dresdner Bank Zw 53',
        bic: 'DRESDEFFI30',
        blz: '30080053',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16686,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF309',
        blz: '30080055',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18017,
        name: 'Raiffeisenbank östl Südeifel',
        bic: 'GENODED1OSE',
        blz: '58662653',
        city: 'Dudeldorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18018,
        name: 'Raiffeisenbank Neuerburg-Land -alt-',
        bic: 'GENODED1NBL',
        blz: '58668818',
        city: 'Neuerburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18175,
        name: 'Volksbank Freiberg und Umgebung',
        bic: 'GENODES1HHB',
        blz: '60069795',
        city: 'Hessigheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19101,
        name: 'Commerzbank Straubing',
        bic: 'COBADEFFXXX',
        blz: '74240062',
        city: 'Straubing',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19089,
        name: 'Sparkasse Deggendorf',
        bic: 'BYLADEM1DEG',
        blz: '74150000',
        city: 'Deggendorf',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19115,
        name: 'Stadt- und Kreissparkasse Moosburg',
        bic: 'BYLADEM1MSB',
        blz: '74351740',
        city: 'Moosburg a.d.Isar',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19093,
        name: 'VR-Bank',
        bic: 'GENODEF1RGE',
        blz: '74164149',
        city: 'Regen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19094,
        name: 'Raiffeisenbank Sonnenwald',
        bic: 'GENODEF1AUS',
        blz: '74165013',
        city: 'Auerbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19083,
        name: 'Volksbank Vilshofen',
        bic: 'GENODEF1VIV',
        blz: '74092400',
        city: 'Vilshofen an der Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19079,
        name: 'Raiffeisenbank Kirchberg v. Wald',
        bic: 'GENODEF1TKI',
        blz: '74069758',
        city: 'Tiefenbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19105,
        name: 'Raiffeisenbank Chamer Land',
        bic: 'GENODEF1CHA',
        blz: '74261024',
        city: 'Cham',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18171,
        name: 'Volksbank Freiberg und Umgebung',
        bic: 'GENODES1FAN',
        blz: '60069738',
        city: 'Freiberg am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18172,
        name: 'Volks- und Raiffeisenbank Boll -alt-',
        bic: 'GENODES1BBO',
        blz: '60069766',
        city: 'Bad Boll',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19078,
        name: 'Raiffeisenbank Hohenau-Mauth -alt-',
        bic: 'GENODEF1HHU',
        blz: '74069752',
        city: 'Hohenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19103,
        name: 'Sparkasse im Landkreis Cham',
        bic: 'BYLADEM1CHM',
        blz: '74251020',
        city: 'Cham',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19136,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM447',
        blz: '75020073',
        city: 'Regensburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19137,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 670 Rgsb',
        bic: 'HYVEDEM1099',
        blz: '75020314',
        available: false
    },
    {
        id: 19138,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM804',
        blz: '75021174',
        city: 'Schwandorf',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19180,
        name: 'Sparkasse Oberpfalz Nord',
        bic: 'BYLADEM1WEN',
        blz: '75350000',
        city: 'Weiden i.d.OPf.',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19143,
        name: 'Raiffeisenbank Regensburg-Wenzenbach',
        bic: 'GENODEF1R02',
        blz: '75060150',
        city: 'Regensburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19168,
        name: 'LIGA Bank',
        bic: 'GENODEF1M05',
        blz: '75090300',
        city: 'Regensburg',
        available: true
    },
    {
        id: 19169,
        name: 'Sparda-Bank Ostbayern',
        bic: 'GENODEF1S05',
        blz: '75090500',
        city: 'Regensburg',
        logoPath: '137.svg',
        available: true
    },
    {
        id: 19172,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM405',
        blz: '75220070',
        city: 'Amberg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19177,
        name: 'Bundesbank eh Weiden Oberpf',
        bic: 'MARKDEF1753',
        blz: '75300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19178,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM454',
        blz: '75320075',
        city: 'Weiden i.d.OPf.',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19144,
        name: 'Raiffeisenbank Schwandorf-Nittenau',
        bic: 'GENODEF1SWN',
        blz: '75061168',
        city: 'Schwandorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19186,
        name: 'Bundesbank',
        bic: 'MARKDEF1760',
        blz: '76000000',
        city: 'Nürnberg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19187,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F760',
        blz: '76010111',
        city: 'Nürnberg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 19188,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM460',
        blz: '76020070',
        city: 'Nürnberg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19189,
        name: 'UniCredit Bank - HypoVereinsbank Prepaid Card',
        bic: 'HYVEDEMMCAR',
        blz: '76020099',
        city: 'Nürnberg',
        available: true
    },
    {
        id: 19190,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 156 Nbg',
        bic: 'HYVEDEM1078',
        blz: '76020214',
        available: false
    },
    {
        id: 19191,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1NUE',
        blz: '76020600',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 19192,
        name: 'Entrium Direct Bankers -alt-',
        blz: '76020900',
        available: false
    },
    {
        id: 19193,
        name: 'norisbank',
        bic: 'NORSDE71XXX',
        blz: '76026000',
        city: 'Berlin',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 19194,
        name: 'Isbank Fil Nürnberg',
        bic: 'ISBKDEFXNUR',
        blz: '76030600',
        city: 'Nürnberg',
        logoPath: '93.svg',
        available: true
    },
    {
        id: 19195,
        name: 'TeamBank Nürnberg',
        bic: 'TEAMDE71XXX',
        blz: '76032000',
        city: 'Nürnberg',
        available: false
    },
    {
        id: 19145,
        name: 'Raiffeisenbank Regenstauf',
        bic: 'GENODEF1REF',
        blz: '75061851',
        city: 'Regenstauf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19146,
        name: 'Raiffeisenbank Oberpfalz Süd',
        bic: 'GENODEF1DST',
        blz: '75062026',
        city: 'Donaustauf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19147,
        name: 'Raiffeisenbank Kreis Kelheim',
        bic: 'GENODEF1ABS',
        blz: '75069014',
        city: 'Bad Abbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19148,
        name: 'Raiffeisenbank Bad Gögging',
        bic: 'GENODEF1NGG',
        blz: '75069015',
        city: 'Neustadt a.d.Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19149,
        name: 'Raiffeisenbank Bruck',
        bic: 'GENODEF1BUK',
        blz: '75069020',
        city: 'Bruck i.d.OPf.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19150,
        name: 'Raiffeisenbank Falkenstein-Wörth',
        bic: 'GENODEF1FKS',
        blz: '75069038',
        city: 'Falkenstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19151,
        name: 'Raiffeisen-Bank -alt-',
        bic: 'GENODEF1FUW',
        blz: '75069043',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19152,
        name: 'Raiffeisenbank Grafenwöhr-Kirchenthumbach',
        bic: 'GENODEF1GRW',
        blz: '75069050',
        city: 'Grafenwöhr',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19153,
        name: 'Raiffeisenbank Alteglofsheim-Hagelstadt',
        bic: 'GENODEF1HGA',
        blz: '75069055',
        city: 'Hagelstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19154,
        name: 'Raiffeisenbank Hemau-Kallmünz',
        bic: 'GENODEF1HEM',
        blz: '75069061',
        city: 'Hemau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19155,
        name: 'Raiffeisenbank Herrnwahlthann-Teugn-Dünzling -alt-',
        bic: 'GENODEF1HTD',
        blz: '75069062',
        city: 'Hausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19156,
        name: 'Raiffeisenbank Inkofen-Eggmühl -alt-',
        blz: '75069074',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19157,
        name: 'Raiffeisenbank Kallmünz -alt-',
        bic: 'GENODEF1KLM',
        blz: '75069076',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19158,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1SZV',
        blz: '75069078',
        city: 'Sinzing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19159,
        name: 'Raiffeisenbank Bad Kötzting',
        bic: 'GENODEF1KTZ',
        blz: '75069081',
        city: 'Bad Kötzting',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19160,
        name: 'Raiffeisenbank Parsberg-Velburg',
        bic: 'GENODEF1PAR',
        blz: '75069094',
        city: 'Parsberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19161,
        name: 'Raiffeisenbank Eschlkam-Lam-Lohberg-Neukirchen b Hl Blut',
        bic: 'GENODEF1NKN',
        blz: '75069110',
        city: 'Neukirchen b.Hl.Blut',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19162,
        name: 'Raiffeisenbank Schierling-Obertraubling -alt-',
        blz: '75069164',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19163,
        name: 'VR Bank Mittlere Oberpfalz',
        bic: 'GENODEF1SWD',
        blz: '75069171',
        city: 'Nabburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19164,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMM750',
        blz: '75070013',
        city: 'Regensburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19165,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB750',
        blz: '75070024',
        city: 'Regensburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17898,
        name: 'VR-Bank Mainz',
        bic: 'GENODE51MZ2',
        blz: '55060417',
        city: 'Mainz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19167,
        name: 'Volksbank Raiffeisenbank Regensburg-Schwandorf',
        bic: 'GENODEF1R01',
        blz: '75090000',
        city: 'Regensburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17900,
        name: 'Budenheimer Volksbank',
        bic: 'GENODE51BUD',
        blz: '55061303',
        city: 'Budenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19170,
        name: 'VR Bank Niederbayern-Oberpfalz',
        bic: 'GENODEF1P18',
        blz: '75090900',
        city: 'Regensburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19171,
        name: 'VR Bank Burglengenfeld',
        bic: 'GENODEF1BLF',
        blz: '75091400',
        city: 'Burglengenfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19173,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '75240000',
        city: 'Amberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19140,
        name: 'Sparkasse Regensburg',
        bic: 'BYLADEM1RBG',
        blz: '75050000',
        city: 'Regensburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19175,
        name: 'Raiffeisenbank Sulzbach-Rosenberg',
        bic: 'GENODEF1SZH',
        blz: '75261700',
        city: 'Sulzbach-Rosenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19176,
        name: 'Volksbank-Raiffeisenbank Amberg',
        bic: 'GENODEF1AMV',
        blz: '75290000',
        city: 'Amberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19179,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '75340090',
        city: 'Weiden i.d.OPf.',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19142,
        name: 'Kreissparkasse Kelheim',
        bic: 'BYLADEM1KEH',
        blz: '75051565',
        city: 'Kelheim',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19182,
        name: 'Raiffeisenbank Weiden',
        bic: 'GENODEF1WEO',
        blz: '75360011',
        city: 'Weiden i.d.OPf.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19183,
        name: 'Raiffeisenbank Floß',
        bic: 'GENODEF1FLS',
        blz: '75362039',
        city: 'Floß',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19184,
        name: 'Raiffeisenbank Neustadt-Vohenstrauß',
        bic: 'GENODEF1NEW',
        blz: '75363189',
        city: 'Vohenstrauß',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19185,
        name: 'Volksbank Raiffeisenbank Nordoberpfalz',
        bic: 'GENODEF1WEV',
        blz: '75390000',
        city: 'Weiden i.d.OPf.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20074,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39102',
        available: false
    },
    {
        id: 19174,
        name: 'Sparkasse Amberg-Sulzbach',
        bic: 'BYLADEM1ABG',
        blz: '75250000',
        city: 'Amberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19196,
        name: 'TeamBank Nürnberg GF Austria',
        bic: 'TEAMDE71TAT',
        blz: '76032001',
        city: 'Nürnberg',
        available: false
    },
    {
        id: 19197,
        name: 'UmweltBank',
        bic: 'UMWEDE7NXXX',
        blz: '76035000',
        city: 'Nürnberg',
        logoPath: '139.svg',
        available: true
    },
    {
        id: 19242,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF760',
        blz: '76080040',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19201,
        name: 'Bayerische Landesbank',
        bic: 'BYLADEMMXXX',
        blz: '76050000',
        city: 'Nürnberg',
        available: false
    },
    {
        id: 19243,
        name: 'Commerzbank vormals Dresdner Bank Zw 53',
        bic: 'DRESDEFFAGI',
        blz: '76080053',
        city: 'Hof',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19203,
        name: 'DZ BANK',
        bic: 'GENODEFF760',
        blz: '76060000',
        city: 'Nürnberg',
        logoPath: '69.svg',
        available: false
    },
    {
        id: 19204,
        name: 'ACREDOBANK',
        bic: 'GENODEF1N05',
        blz: '76060561',
        available: false
    },
    {
        id: 19244,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFFI25',
        blz: '76080055',
        city: 'Hof',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19249,
        name: 'Bäcker-Bank Nürnberg',
        bic: 'GENODEF1N04',
        blz: '76090300',
        available: false
    },
    {
        id: 19250,
        name: 'Evenord-Bank',
        bic: 'GENODEF1N03',
        blz: '76090400',
        city: 'Nürnberg',
        logoPath: '79.svg',
        available: true
    },
    {
        id: 19253,
        name: 'Sparda-Bank Nürnberg Zw Sonnenstraße',
        bic: 'GENODEF1S14',
        blz: '76091000',
        logoPath: '137.svg',
        available: false
    },
    {
        id: 19254,
        name: 'CVW-Privatbank',
        bic: 'GENODEF1WHD',
        blz: '76211900',
        city: 'Wilhermsdorf',
        available: true
    },
    {
        id: 19255,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM419',
        blz: '76220073',
        city: 'Fürth',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19256,
        name: 'BSQ Bauspar',
        bic: 'QUBADE71XXX',
        blz: '76230000',
        city: 'Nürnberg',
        available: false
    },
    {
        id: 19245,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ26',
        blz: '76080085',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19246,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFJ27',
        blz: '76080086',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19247,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI24',
        blz: '76089480',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19248,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI46',
        blz: '76089482',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19199,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '76040061',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19200,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '76040062',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19205,
        name: 'Volksbank Raiffeisenbank',
        bic: 'GENODEF1N02',
        blz: '76060618',
        city: 'Nürnberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19206,
        name: 'Raiffeisen Spar+Kreditbank Lauf a d Pegnitz',
        bic: 'GENODEF1LAU',
        blz: '76061025',
        city: 'Lauf a.d.Pegnitz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19207,
        name: 'Raiffeisenbank Hersbruck',
        bic: 'GENODEF1HSB',
        blz: '76061482',
        city: 'Hersbruck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19208,
        name: 'Raiffeisenbank am Rothsee',
        blz: '76069359',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19209,
        name: 'Raiffeisenbank Auerbach-Freihung',
        bic: 'GENODEF1AUO',
        blz: '76069369',
        city: 'Auerbach i.d.OPf.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19210,
        name: 'Raiffeisenbank Bad Windsheim',
        bic: 'GENODEF1WDS',
        blz: '76069372',
        city: 'Bad Windsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19211,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1BEH',
        blz: '76069378',
        city: 'Bechhofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19212,
        name: 'Raiffeisenbank Uehlfeld-Dachsbach',
        bic: 'GENODEF1DSB',
        blz: '76069404',
        city: 'Dachsbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19213,
        name: 'Raiffeisenbank Dietenhofen',
        bic: 'GENODEF1DIH',
        blz: '76069409',
        city: 'Dietenhofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19214,
        name: 'Raiffeisenbank Dietersheim und Umgebung',
        bic: 'GENODEF1DIM',
        blz: '76069410',
        city: 'Dietersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19215,
        name: 'Raiffeisenbank Dietfurt',
        blz: '76069411',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19216,
        name: 'Raiffeisenbank Dinkelsbühl-Hesselberg -alt-',
        blz: '76069412',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19217,
        name: 'Raiffeisenbank Altdorf-Feucht',
        bic: 'GENODEF1FEC',
        blz: '76069440',
        city: 'Feucht',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19218,
        name: 'VR-Bank Feuchtwangen-Limes',
        bic: 'GENODEF1FEW',
        blz: '76069441',
        city: 'Feuchtwangen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19219,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1FRD',
        blz: '76069448',
        city: 'Freudenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19220,
        name: 'Raiffeisen - meine Bank',
        bic: 'GENODEF1FYS',
        blz: '76069449',
        city: 'Berching',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19221,
        name: 'Raiffeisenbank Greding - Thalmässing',
        bic: 'GENODEF1GDG',
        blz: '76069462',
        city: 'Greding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19222,
        name: 'Raiffeisenbank Weißenburg-Gunzenhausen',
        bic: 'GENODEF1GU1',
        blz: '76069468',
        city: 'Weißenburg i.Bay.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19223,
        name: 'Raiffeisenbank Herzogenaurach -alt-',
        bic: 'GENODEF1HZA',
        blz: '76069483',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19224,
        name: 'Raiffeisenbank Hirschau',
        bic: 'GENODEF1HSC',
        blz: '76069486',
        city: 'Hirschau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19225,
        name: 'Raiffeisenbank Knoblauchsland Nürnberg-Buch',
        bic: 'GENODEF1N08',
        blz: '76069512',
        city: 'Nürnberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19226,
        name: 'Raiffeisenbank Markt Erlbach-Linden -alt-',
        blz: '76069539',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17901,
        name: 'VR-Bank Mainz',
        bic: 'GENODE51HDS',
        blz: '55061507',
        city: 'Mainz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19228,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1NHF',
        blz: '76069552',
        city: 'Neuhof a.d.Zenn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17852,
        name: 'VR Bank Ludwigshafen -alt-',
        blz: '54560320',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19229,
        name: 'Raiffeisenbank Neumarkt',
        bic: 'GENODEF1NM1',
        blz: '76069553',
        city: 'Neumarkt i.d.OPf.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19230,
        name: 'VR meine Bank',
        bic: 'GENODEF1NEA',
        blz: '76069559',
        city: 'Neustadt a.d.Aisch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19231,
        name: 'Raiffeisenbank Oberferrieden-Burgthann',
        bic: 'GENODEF1BTO',
        blz: '76069564',
        city: 'Burgthann',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19232,
        name: 'Raiffeisenbank Plankstetten',
        bic: 'GENODEF1BPL',
        blz: '76069576',
        city: 'Berching',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19233,
        name: 'Raiffeisenbank Großhabersdorf-Roßtal',
        bic: 'GENODEF1RSS',
        blz: '76069598',
        city: 'Roßtal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19234,
        name: 'VR-Bank Rothenburg',
        bic: 'GENODEF1RT2',
        blz: '76069601',
        city: 'Rothenburg ob der Tauber',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19235,
        name: 'Raiffeisenbank Seebachgrund',
        bic: 'GENODEF1HSE',
        blz: '76069602',
        city: 'Heßdorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19236,
        name: 'Raiffeisenbank Unteres Vilstal',
        bic: 'GENODEF1SDM',
        blz: '76069611',
        city: 'Schmidmühlen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19237,
        name: 'Raiffeisenbank Ursensollen-Ammerthal-Hohenburg',
        bic: 'GENODEF1URS',
        blz: '76069635',
        city: 'Ursensollen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19238,
        name: 'Raiffeisenbank Heilsbronn-Windsbach',
        bic: 'GENODEF1WBA',
        blz: '76069663',
        city: 'Windsbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19239,
        name: 'Raiffeisenbank Bibertgrund',
        bic: 'GENODEF1ZIR',
        blz: '76069669',
        city: 'Zirndorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19240,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMM760',
        blz: '76070012',
        city: 'Nürnberg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19252,
        name: 'PSD Bank',
        bic: 'GENODEF1P17',
        blz: '76090900',
        city: 'Nürnberg',
        available: true
    },
    {
        id: 19310,
        name: 'Raiffeisenbank -alt-',
        blz: '77069893',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19260,
        name: 'Bundesbank eh Erlangen',
        bic: 'MARKDEF1763',
        blz: '76300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19261,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM417',
        blz: '76320072',
        city: 'Erlangen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19268,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM065',
        blz: '76420080',
        city: 'Schwabach',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19272,
        name: 'Bundesbank eh Ansbach',
        bic: 'MARKDEF1765',
        blz: '76500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19273,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM406',
        blz: '76520071',
        city: 'Ansbach',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19281,
        name: 'Bundesbank eh Bamberg',
        bic: 'MARKDEF1770',
        blz: '77000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19282,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM411',
        blz: '77020070',
        city: 'Bamberg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19312,
        name: 'Raiffeisenbank Sparneck-Stammbach-Zell -alt-',
        bic: 'GENODEF1ZSP',
        blz: '77069908',
        city: 'Stammbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19314,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM289',
        blz: '77120073',
        city: 'Kulmbach',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19313,
        name: 'VR-Bank Lichtenfels-Ebern',
        bic: 'GENODEF1LIF',
        blz: '77091800',
        city: 'Lichtenfels',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19315,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '77140061',
        city: 'Kulmbach',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19269,
        name: 'Sparkasse Mittelfranken-Süd',
        bic: 'BYLADEM1SRS',
        blz: '76450000',
        city: 'Roth',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19259,
        name: 'Raiffeisen-Volksbank Fürth',
        bic: 'GENODEF1FUE',
        blz: '76260451',
        city: 'Fürth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19262,
        name: 'Commerzbank Erlangen',
        bic: 'COBADEFFXXX',
        blz: '76340061',
        city: 'Erlangen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19274,
        name: 'Sparkasse Ansbach',
        bic: 'BYLADEM1ANS',
        blz: '76550000',
        city: 'Ansbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19266,
        name: 'VR-Bank Erlangen-Höchstadt-Herzogenaurach',
        bic: 'GENODEF1ER1',
        blz: '76360033',
        city: 'Erlangen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19267,
        name: 'Volksbank Forchheim',
        bic: 'GENODEF1FOH',
        blz: '76391000',
        city: 'Forchheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19316,
        name: 'Sparkasse Kulmbach-Kronach',
        bic: 'BYLADEM1KUB',
        blz: '77150000',
        city: 'Kulmbach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19270,
        name: 'Raiffeisenbank Roth-Schwabach',
        bic: 'GENODEF1SWR',
        blz: '76460015',
        city: 'Roth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19271,
        name: 'Raiffeisenbank am Rothsee',
        bic: 'GENODEF1HPN',
        blz: '76461485',
        city: 'Hilpoltstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17628,
        name: 'Nassauische Sparkasse',
        bic: 'NASSDE55XXX',
        blz: '51050015',
        city: 'Wiesbaden',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19280,
        name: 'VR-Bank Feuchtwangen-Dinkelsbühl',
        bic: 'GENODEF1DKV',
        blz: '76591000',
        city: 'Dinkelsbühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19287,
        name: 'Raiffeisen-Volksbank',
        bic: 'GENODEF1EBR',
        blz: '77061425',
        city: 'Ebern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19288,
        name: 'Raiffeisenbank Burgebrach-Stegaurach',
        bic: 'GENODEF1BGB',
        blz: '77062014',
        city: 'Burgebrach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19290,
        name: 'Raiffeisenbank Hallstadt -alt-',
        bic: 'GENODEF1HLD',
        blz: '77063048',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19291,
        name: 'Raiffeisenbank Stegaurach -alt-',
        bic: 'GENODEF1STC',
        blz: '77065141',
        city: 'Stegaurach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19292,
        name: 'Raiffeisenbank Gößweinstein -alt-',
        bic: 'GENODEF1GWE',
        blz: '77069042',
        city: 'Gößweinstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19293,
        name: 'Raiffeisenbank Küps-Mitwitz-Stockheim',
        bic: 'GENODEF1KC2',
        blz: '77069044',
        city: 'Küps',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19294,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1HIS',
        blz: '77069051',
        city: 'Heiligenstadt i.OFr.',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19295,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1HOB',
        blz: '77069052',
        city: 'Heroldsbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19296,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1MEM',
        blz: '77069084',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19298,
        name: 'Raiffeisenbank Pretzfeld -alt-',
        bic: 'GENODEF1PRE',
        blz: '77069110',
        city: 'Pretzfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19311,
        name: 'Raiffeisenbank Wüstenselbitz',
        bic: 'GENODEF1WSZ',
        blz: '77069906',
        city: 'Helmbrechts',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19297,
        name: 'Raiffeisenbank Ebrachgrund',
        bic: 'GENODEF1SFD',
        blz: '77069091',
        city: 'Mühlhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19299,
        name: 'Raiffeisenbank Scheßlitz-Zapfendorf -alt-',
        bic: 'GENODEF1STZ',
        blz: '77069128',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19265,
        name: 'Kreissparkasse Höchstadt',
        bic: 'BYLADEM1HOS',
        blz: '76351560',
        city: 'Höchstadt a.d.Aisch',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17627,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '51040038',
        city: 'Wiesbaden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19264,
        name: 'Sparkasse Forchheim',
        bic: 'BYLADEM1FOR',
        blz: '76351040',
        city: 'Forchheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19301,
        name: 'VR-Bank Erlangen-Höchstadt-Herzogenaurach',
        bic: 'GENODEF1NNK',
        blz: '77069556',
        city: 'Neunkirchen a. Brand',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17408,
        name: 'Commerzbank, MBP',
        bic: 'COBADEFFXXX',
        blz: '50040038',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19300,
        name: 'Vereinigte Raiffeisenbanken',
        bic: 'GENODEF1GBF',
        blz: '77069461',
        city: 'Gräfenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17629,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF510',
        blz: '51070021',
        city: 'Wiesbaden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17630,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB510',
        blz: '51070024',
        city: 'Wiesbaden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17714,
        name: 'Volksbank Ober-Mörlen',
        bic: 'GENODE51OBM',
        blz: '51861806',
        city: 'Ober-Mörlen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17715,
        name: 'Weiseler Volksbank -alt-',
        blz: '51862677',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19302,
        name: 'Raiffeisenbank Thurnauer Land',
        bic: 'GENODEF1THA',
        blz: '77069739',
        city: 'Thurnau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19303,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1ETB',
        blz: '77069746',
        city: 'Emtmannsberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19304,
        name: 'Raiffeisenbank Oberpfalz NordWest',
        bic: 'GENODEF1KEM',
        blz: '77069764',
        city: 'Kemnath',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19305,
        name: 'Raiffeisenbank am Kulm',
        bic: 'GENODEF1SPK',
        blz: '77069782',
        city: 'Speichersdorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19306,
        name: 'Raiffeisenbank Berg-Bad Steben',
        bic: 'GENODEF1BGO',
        blz: '77069836',
        city: 'Berg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19307,
        name: 'Raiffeisenbank Oberland',
        bic: 'GENODEF1MGA',
        blz: '77069868',
        city: 'Marktleugast',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19308,
        name: 'Raiffeisenbank Hochfranken West',
        bic: 'GENODEF1SZF',
        blz: '77069870',
        city: 'Stammbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19309,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1OKZ',
        blz: '77069879',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19275,
        name: 'Kreis- und Stadtsparkasse Dinkelsbühl',
        bic: 'BYLADEM1DKB',
        blz: '76551020',
        city: 'Dinkelsbühl',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19277,
        name: 'Stadt- und Kreissparkasse Rothenburg',
        bic: 'BYLADEM1ROT',
        blz: '76551860',
        city: 'Rothenburg ob der Tauber',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19258,
        name: 'Sparkasse i Landkreis Neustadt a d Aisch',
        bic: 'BYLADEM1NEA',
        blz: '76251020',
        city: 'Neustadt a.d.Aisch',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19374,
        name: 'Raiffeisenbank Maßbach',
        bic: 'GENODEF1RNM',
        blz: '79069213',
        city: 'Rannungen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19318,
        name: 'Bundesbank eh Bayreuth',
        bic: 'MARKDEF1773',
        blz: '77300000',
        city: 'München',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19319,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM412',
        blz: '77320072',
        city: 'Bayreuth',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19320,
        name: 'Fondsdepot Bank',
        bic: 'FODBDE77XXX',
        blz: '77322200',
        city: 'Hof',
        logoPath: '82.svg',
        available: false
    },
    {
        id: 19327,
        name: 'Sparda-Bank Nürnberg',
        bic: 'GENODEF1S13',
        blz: '77390500',
        logoPath: '137.svg',
        available: false
    },
    {
        id: 19328,
        name: 'Bundesbank eh Hof',
        bic: 'MARKDEF1780',
        blz: '78000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19329,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM424',
        blz: '78020070',
        city: 'Hof',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19330,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 128 Hof',
        bic: 'HYVEDEM1074',
        blz: '78020429',
        available: false
    },
    {
        id: 19331,
        name: 'Archon Capital Bank Deutschland',
        bic: 'DELMDE71XXX',
        blz: '78030080',
        available: false
    },
    {
        id: 19332,
        name: 'Archon Capital Bank Deutschland Servicing',
        bic: 'DESVDE71XXX',
        blz: '78030081',
        available: false
    },
    {
        id: 19342,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM480',
        blz: '78320076',
        city: 'Coburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19347,
        name: 'Bundesbank',
        bic: 'MARKDEF1790',
        blz: '79000000',
        city: 'Würzburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19348,
        name: 'SEB',
        bic: 'ESSEDE5F790',
        blz: '79010111',
        city: 'Würzburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 19349,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM455',
        blz: '79020076',
        city: 'Würzburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19350,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 149 Wzb',
        bic: 'HYVEDEM1077',
        blz: '79020325',
        available: false
    },
    {
        id: 19351,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1WUE',
        blz: '79020700',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 19352,
        name: 'Fürstlich Castellsche Bank Credit-Casse',
        bic: 'FUCEDE77XXX',
        blz: '79030001',
        city: 'Würzburg',
        available: true
    },
    {
        id: 19353,
        name: 'Bank Schilling & Co',
        bic: 'BSHADE71XXX',
        blz: '79032038',
        city: 'Hammelburg',
        logoPath: '52.svg',
        available: true
    },
    {
        id: 19375,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1THH',
        blz: '79069271',
        city: 'Thüngersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19376,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMM790',
        blz: '79070016',
        city: 'Würzburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19377,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB790',
        blz: '79070024',
        city: 'Würzburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19378,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF790',
        blz: '79080052',
        city: 'Würzburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19321,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '77340076',
        city: 'Bayreuth',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19335,
        name: 'Sparkasse Hochfranken -alt-',
        bic: 'BYLADEM1FIG',
        blz: '78055050',
        city: 'Selb',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19323,
        name: 'Raiffeisen-Volksbank Kronach-Ludwigsstadt',
        bic: 'GENODEF1KC1',
        blz: '77361600',
        city: 'Kronach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19324,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1GFS',
        blz: '77363749',
        city: 'Gefrees',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19325,
        name: 'Raiffeisenbank Hollfeld-Waischenfeld-Aufseß',
        bic: 'GENODEF1HWA',
        blz: '77365792',
        city: 'Hollfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19326,
        name: 'Volksbank-Raiffeisenbank Bayreuth',
        bic: 'GENODEF1BT1',
        blz: '77390000',
        city: 'Bayreuth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19333,
        name: 'Commerzbank Hof Saale',
        bic: 'COBADEFFXXX',
        blz: '78040081',
        city: 'Hof',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19339,
        name: 'Sparkasse Tirschenreuth -alt-',
        blz: '78151080',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19344,
        name: 'Sparkasse Coburg-Lichtenfels',
        bic: 'BYLADEM1COB',
        blz: '78350000',
        city: 'Coburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19336,
        name: 'VR Bank Bayreuth-Hof',
        bic: 'GENODEF1HO1',
        blz: '78060896',
        city: 'Bayreuth',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19337,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1SMM',
        blz: '78062488',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19338,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '78140000',
        city: 'Tirschenreuth',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19322,
        name: 'Sparkasse Bayreuth',
        bic: 'BYLADEM1SBT',
        blz: '77350110',
        city: 'Bayreuth',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19340,
        name: 'VR-Bank Fichtelgebirge-Frankenwald',
        bic: 'GENODEF1MAK',
        blz: '78160069',
        city: 'Marktredwitz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19341,
        name: 'Raiffeisenbank im Stiftland',
        bic: 'GENODEF1WSS',
        blz: '78161575',
        city: 'Waldsassen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19343,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '78340091',
        city: 'Coburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19334,
        name: 'Sparkasse Hochfranken',
        bic: 'BYLADEM1HOF',
        blz: '78050000',
        city: 'Hof',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19345,
        name: 'VR-Bank Coburg',
        bic: 'GENODEF1COS',
        blz: '78360000',
        city: 'Coburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19346,
        name: 'VR-Bank Coburg',
        blz: '78390000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19354,
        name: 'Commerzbank Würzburg',
        bic: 'COBADEFFXXX',
        blz: '79040047',
        city: 'Würzburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19356,
        name: 'Raiffeisenbank-alt-',
        bic: 'GENODEF1OCH',
        blz: '79061000',
        city: 'Ochsenfurt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19357,
        name: 'Raiffeisenbank Lohr, Main -alt-',
        bic: 'GENODEF1LOH',
        blz: '79061153',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19358,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1HAB',
        blz: '79062106',
        city: 'Hammelburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19359,
        name: 'Raiffeisenbank Estenfeld-Bergtheim',
        bic: 'GENODEF1EFD',
        blz: '79063060',
        city: 'Estenfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19360,
        name: 'Raiffeisenbank Höchberg',
        bic: 'GENODEF1HBG',
        blz: '79063122',
        city: 'Höchberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19361,
        name: 'VR-Bank Bad Kissingen',
        bic: 'GENODEF1BRK',
        blz: '79065028',
        city: 'Bad Kissingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19362,
        name: 'Raiffeisenbank Marktheidenfeld -alt-',
        bic: 'GENODEF1MHF',
        blz: '79065160',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19363,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1AHE',
        blz: '79066082',
        city: 'Altertheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17716,
        name: 'Volksbank Ulrichstein',
        bic: 'GENODE51ULR',
        blz: '51961023',
        city: 'Ulrichstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19365,
        name: 'VR-Bank Schweinfurt',
        bic: 'GENODEF1ATE',
        blz: '79069010',
        city: 'Schweinfurt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19366,
        name: 'Raiffeisenbank Bütthard-Gaukönigshofen',
        bic: 'GENODEF1BHD',
        blz: '79069031',
        city: 'Bütthard',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19367,
        name: 'Raiffeisenbank Ulsenheim-Gollhofen -alt-',
        bic: 'GENODEF1GOU',
        blz: '79069090',
        city: 'Gollhofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19368,
        name: 'Raiffeisenbank Kreuzwertheim-Hasloch -alt-',
        bic: 'GENODEF1KRW',
        blz: '79069145',
        city: 'Kreuzwertheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19369,
        name: 'Raiffeisenbank Main-Spessart',
        bic: 'GENODEF1GEM',
        blz: '79069150',
        city: 'Lohr a. Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19370,
        name: 'Volksbank Raiffeisenbank Rhön-Grabfeld',
        bic: 'GENODEF1MLV',
        blz: '79069165',
        city: 'Bad Neustadt a.d.Saale',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19371,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1NDL',
        blz: '79069181',
        city: 'Nüdlingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19372,
        name: 'Raiffeisenbank im Grabfeld',
        bic: 'GENODEF1SLZ',
        blz: '79069188',
        city: 'Sulzdorf a.d.Lederhecke',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19373,
        name: 'Raiffeisenbank Obernbreit und Umgebung',
        bic: 'GENODEF1ROU',
        blz: '79069192',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19382,
        name: 'Raiffeisenbank Kitzinger Land',
        bic: 'GENODEF1OBR',
        blz: '79161499',
        city: 'Obernbreit',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19384,
        name: 'Bundesbank eh Schweinfurt',
        bic: 'MARKDEF1793',
        blz: '79300000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19385,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM451',
        blz: '79320075',
        city: 'Schweinfurt',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19386,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 137 Schft',
        bic: 'HYVEDEM1075',
        blz: '79320432',
        available: false
    },
    {
        id: 19383,
        name: 'VR Bank Kitzingen',
        bic: 'GENODEF1KT1',
        blz: '79190000',
        city: 'Kitzingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19399,
        name: 'Bundesbank eh Aschaffenburg',
        bic: 'MARKDEF1795',
        blz: '79500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19400,
        name: 'SEB',
        bic: 'ESSEDE5F795',
        blz: '79510111',
        city: 'Aschaffenburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 19401,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM407',
        blz: '79520070',
        city: 'Aschaffenburg',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19402,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 125 Aschb',
        bic: 'HYVEDEM1106',
        blz: '79520533',
        available: false
    },
    {
        id: 19387,
        name: 'Commerzbank Schweinfurt',
        bic: 'COBADEFFXXX',
        blz: '79340054',
        city: 'Schweinfurt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19423,
        name: 'Bundesbank eh Halle',
        bic: 'MARKDEF1800',
        blz: '80000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19424,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM440',
        blz: '80020086',
        city: 'Halle (Saale)',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19425,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM462',
        blz: '80020087',
        city: 'Dessau-Roßlau',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19426,
        name: 'ZV Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST802',
        blz: '80020130',
        available: false
    },
    {
        id: 19390,
        name: 'Sparkasse Bad Kissingen',
        bic: 'BYLADEM1KIS',
        blz: '79351010',
        city: 'Bad Kissingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19391,
        name: 'Sparkasse Ostunterfranken',
        bic: 'BYLADEM1HAS',
        blz: '79351730',
        city: 'Haßfurt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19404,
        name: 'Sparkasse Aschaffenburg Alzenau',
        bic: 'BYLADEM1ASA',
        blz: '79550000',
        city: 'Aschaffenburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19417,
        name: 'Sparkasse Miltenberg-Obernburg',
        bic: 'BYLADEM1MIL',
        blz: '79650000',
        city: 'Miltenberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19393,
        name: 'VR-Bank Gerolzhofen',
        bic: 'GENODEF1GZH',
        blz: '79362081',
        city: 'Gerolzhofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19394,
        name: 'Volksbank Raiffeisenbank Rhön-Grabfeld',
        bic: 'GENODEF1NDT',
        blz: '79363016',
        city: 'Bad Neustadt a.d.Saale',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19395,
        name: 'Raiffeisen-Volksbank Haßberge',
        bic: 'GENODEF1HAS',
        blz: '79363151',
        city: 'Haßfurt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19396,
        name: 'Raiffeisenbank Frankenwinheim und Umgebung',
        bic: 'GENODEF1FWH',
        blz: '79364069',
        city: 'Frankenwinheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19397,
        name: 'VR-Bank Schweinfurt Land -alt-',
        bic: 'GENODEF1BGF',
        blz: '79364406',
        city: 'Sennfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19398,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF793',
        blz: '79380051',
        city: 'Schweinfurt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19403,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '79540049',
        city: 'Aschaffenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19429,
        name: 'Sparkasse Burgenlandkreis',
        bic: 'NOLADE21BLK',
        blz: '80053000',
        city: 'Zeitz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19405,
        name: 'Raiffeisenbank Bachgau -alt-',
        bic: 'GENODEF1BAG',
        blz: '79561348',
        city: 'Großostheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19406,
        name: 'Raiffeisenbank Kahl am Main -alt-',
        blz: '79562225',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19407,
        name: 'Raiffeisen-Volksbank Aschaffenburg',
        bic: 'GENODEF1AB1',
        blz: '79562514',
        city: 'Aschaffenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19408,
        name: 'Raiffeisenbank Waldaschaff-Heigenbrücken',
        bic: 'GENODEF1WAA',
        blz: '79565568',
        city: 'Waldaschaff',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19409,
        name: 'Raiffeisenbank Heimbuchenthal -alt-',
        bic: 'GENODEF1HTH',
        blz: '79566545',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19410,
        name: 'VR-Bank',
        bic: 'GENODEF1ALZ',
        blz: '79567531',
        city: 'Alzenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19411,
        name: 'Raiffeisenbank Haibach-Obernau',
        bic: 'GENODEF1HAC',
        blz: '79568518',
        city: 'Haibach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17854,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB545',
        blz: '54570024',
        city: 'Ludwigshafen am Rhein',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19414,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF795',
        blz: '79580099',
        city: 'Aschaffenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17855,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM545',
        blz: '54570094',
        city: 'Ludwigshafen am Rhein',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19416,
        name: 'Volksbank Aschaffenburg',
        bic: 'GENODEF1AB2',
        blz: '79590000',
        city: 'Aschaffenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19438,
        name: 'Sparkasse Mansfeld-Südharz',
        bic: 'NOLADE21EIL',
        blz: '80055008',
        city: 'Lutherstadt Eisleben',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19418,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1SKP',
        blz: '79662558',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19419,
        name: 'Raiffeisenbank Elsavatal',
        bic: 'GENODEF1EAU',
        blz: '79665540',
        city: 'Eschau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19420,
        name: 'Raiffeisenbank Großostheim-Obernburg',
        bic: 'GENODEF1OBE',
        blz: '79666548',
        city: 'Großostheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19421,
        name: 'Raiffeisenbank Eichenbühl und Umgebung',
        bic: 'GENODEF1ENB',
        blz: '79668509',
        city: 'Eichenbühl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19422,
        name: 'Raiffeisen-Volksbank Miltenberg',
        bic: 'GENODEF1MIL',
        blz: '79690000',
        city: 'Miltenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19427,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '80040000',
        city: 'Halle (Saale)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19428,
        name: 'Kreissparkasse Merseburg-Querfurt -alt-',
        bic: 'NOLADE21MQU',
        blz: '80050500',
        city: 'Merseburg',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19389,
        name: 'Sparkasse Schweinfurt-Haßberge',
        bic: 'BYLADEM1KSW',
        blz: '79350101',
        city: 'Schweinfurt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19380,
        name: 'Volksbank Raiffeisenbank Würzburg',
        bic: 'GENODEF1WU1',
        blz: '79090000',
        city: 'Würzburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19381,
        name: 'Raiffeisenbank Fränkisches Weinland',
        bic: 'GENODEF1ERN',
        blz: '79161058',
        city: 'Nordheim a. Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19430,
        name: 'Kreissparkasse Quedlinburg -alt-',
        blz: '80053502',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19431,
        name: 'Kreissparkasse Sangerhausen -alt-',
        bic: 'NOLADE21SGH',
        blz: '80053552',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19432,
        name: 'Stadtsparkasse Dessau',
        bic: 'NOLADE21DES',
        blz: '80053572',
        city: 'Dessau-Roßlau',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19433,
        name: 'Kreissparkasse Bernburg -alt-',
        blz: '80053612',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19434,
        name: 'Kreissparkasse Köthen -alt-',
        bic: 'NOLADE21KOT',
        blz: '80053622',
        city: 'Köthen (Anhalt)',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19435,
        name: 'Kreissparkasse Anhalt-Bitterfeld',
        bic: 'NOLADE21BTF',
        blz: '80053722',
        city: 'Bitterfeld-Wolfen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19436,
        name: 'Saalesparkasse',
        bic: 'NOLADE21HAL',
        blz: '80053762',
        city: 'Halle (Saale)',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19437,
        name: 'Kreissparkasse Weißenfels -alt-',
        bic: 'NOLADE21WSF',
        blz: '80054000',
        city: 'Weißenfels',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19388,
        name: 'Städtische Sparkasse Schweinfurt -alt-',
        bic: 'BYLADEM1SSW',
        blz: '79350000',
        city: 'Schweinfurt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19449,
        name: 'Volksbank Dessau-Anhalt',
        bic: 'GENODEF1DS1',
        blz: '80093574',
        city: 'Dessau-Roßlau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19450,
        name: 'Volksbank Halle, Saale',
        bic: 'GENODEF1HAL',
        blz: '80093784',
        city: 'Halle (Saale)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19451,
        name: 'Bundesbank eh Dessau',
        bic: 'MARKDEF1805',
        blz: '80500000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19454,
        name: 'Bundesbank',
        bic: 'MARKDEF1810',
        blz: '81000000',
        city: 'Magdeburg',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19455,
        name: 'SEB',
        bic: 'ESSEDE5F810',
        blz: '81010111',
        city: 'Magdeburg',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 19456,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33MAG',
        blz: '81020500',
        city: 'Magdeburg',
        available: false
    },
    {
        id: 19471,
        name: 'Bank für Kirche und Diakonie - KD-Bank Gf Sonder-BLZ',
        bic: 'GENODED1KDM',
        blz: '81068106',
        city: 'Magdeburg',
        available: false
    },
    {
        id: 19468,
        name: 'Sparkasse Altmark West',
        bic: 'NOLADE21SAW',
        blz: '81055555',
        city: 'Salzwedel, Hansestadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19481,
        name: 'Bundesbank',
        bic: 'MARKDEF1820',
        blz: '82000000',
        city: 'Erfurt',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19482,
        name: 'SEB',
        bic: 'ESSEDE5F820',
        blz: '82010111',
        city: 'Erfurt',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 19483,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM498',
        blz: '82020086',
        city: 'Erfurt',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19484,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM098',
        blz: '82020087',
        city: 'Weimar',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19485,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM824',
        blz: '82020088',
        city: 'Eisenach',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19487,
        name: 'Landesbank Hessen-Thür Girozentrale Erfurt',
        bic: 'HELADEFF820',
        blz: '82050000',
        city: 'Erfurt',
        available: false
    },
    {
        id: 19494,
        name: 'Pax-Bank',
        bic: 'GENODED1PA5',
        blz: '82060197',
        city: 'Erfurt',
        logoPath: '106.svg',
        available: false
    },
    {
        id: 19495,
        name: 'Evangelische Kreditgenossenschaft -Filiale Eisenach-',
        bic: 'GENODEF1EK7',
        blz: '82060800',
        available: false
    },
    {
        id: 19500,
        name: 'Erfurter Bank',
        bic: 'ERFBDE8EXXX',
        blz: '82064228',
        city: 'Erfurt',
        available: true
    },
    {
        id: 19457,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '81040000',
        city: 'Magdeburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19492,
        name: 'Sparkasse Unstrut-Hainich',
        bic: 'HELADEF1MUE',
        blz: '82056060',
        city: 'Mühlhausen/Thüringen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19439,
        name: 'Salzlandsparkasse',
        bic: 'NOLADE21SES',
        blz: '80055500',
        city: 'Staßfurt',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19469,
        name: 'Raiffeisenbank Kalbe-Bismark',
        bic: 'GENODEF1KAB',
        blz: '81063028',
        city: 'Kalbe (Milde)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19470,
        name: 'Volksbank Jerichower Land',
        bic: 'GENODEF1BRG',
        blz: '81063238',
        city: 'Burg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19472,
        name: 'Volksbank Jerichower Land',
        bic: 'GENODEF1GNT',
        blz: '81069048',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19473,
        name: 'Volksbank Börde-Bernburg',
        bic: 'GENODEF1WZL',
        blz: '81069052',
        city: 'Wanzleben-Börde',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19474,
        name: 'Deutsche Bank',
        bic: 'DEUTDE8MXXX',
        blz: '81070000',
        city: 'Magdeburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19476,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF810',
        blz: '81080000',
        city: 'Magdeburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19477,
        name: 'Volksbank',
        bic: 'GENODEF1GA1',
        blz: '81093034',
        city: 'Gardelegen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19478,
        name: 'Volksbank Osterburg-Lüchow-Dannenberg -alt-',
        bic: 'GENODEF1OBG',
        blz: '81093044',
        city: 'Osterburg (Altmark)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19479,
        name: 'Volksbank Stendal',
        bic: 'GENODEF1SDL',
        blz: '81093054',
        city: 'Stendal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19480,
        name: 'Volksbank Magdeburg',
        bic: 'GENODEF1MD1',
        blz: '81093274',
        city: 'Magdeburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19486,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '82040000',
        city: 'Erfurt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17856,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF545',
        blz: '54580020',
        city: 'Ludwigshafen am Rhein',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19489,
        name: 'Kreissparkasse Gotha',
        bic: 'HELADEF1GTH',
        blz: '82052020',
        city: 'Gotha',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17859,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '54640035',
        city: 'Neustadt an der Weinstraße',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19496,
        name: 'VR Bank Westthüringen',
        bic: 'GENODEF1MU2',
        blz: '82064038',
        city: 'Mühlhausen/Thüringen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19497,
        name: 'VR-Bank Eisenach-Ronshausen',
        bic: 'GENODEF1ESA',
        blz: '82064088',
        city: 'Eisenach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19498,
        name: 'Raiffeisenbank Gotha',
        bic: 'GENODEF1GTH',
        blz: '82064168',
        city: 'Gotha',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19499,
        name: 'VR Bank Weimar',
        bic: 'GENODEF1WE1',
        blz: '82064188',
        city: 'Weimar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19501,
        name: 'Deutsche Bank',
        bic: 'DEUTDE8EXXX',
        blz: '82070000',
        city: 'Erfurt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19441,
        name: 'Harzer Volksbank',
        bic: 'GENODEF1QLB',
        blz: '80063508',
        city: 'Wernigerode',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19442,
        name: 'Volksbank',
        bic: 'GENODEF1SGH',
        blz: '80063558',
        city: 'Sangerhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19443,
        name: 'Volksbank Wittenberg',
        bic: 'GENODEF1WB1',
        blz: '80063598',
        city: 'Lutherstadt Wittenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19444,
        name: 'Volksbank',
        bic: 'GENODEF1KOE',
        blz: '80063628',
        city: 'Köthen (Anhalt)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19446,
        name: 'VR-Bank Zeitz',
        bic: 'GENODEF1ZTZ',
        blz: '80063678',
        city: 'Zeitz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19447,
        name: 'Volks- und Raiffeisenbank Eisleben',
        bic: 'GENODEF1EIL',
        blz: '80063718',
        city: 'Lutherstadt Eisleben',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19448,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF800',
        blz: '80080000',
        city: 'Halle (Saale)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19453,
        name: 'Kreissparkasse Anhalt-Zerbst -alt-',
        bic: 'NOLADE21ZER',
        blz: '80550200',
        city: 'Zerbst/Anhalt',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19458,
        name: 'Kreissparkasse Aschersleben-Staßfurt -alt-',
        blz: '81050000',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19459,
        name: 'Kreissparkasse Stendal',
        bic: 'NOLADE21SDL',
        blz: '81050555',
        city: 'Stendal',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19460,
        name: 'Bördesparkasse Oschersleben -alt-',
        bic: 'NOLADE21OCL',
        blz: '81051000',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19461,
        name: 'Harzsparkasse',
        bic: 'NOLADE21HRZ',
        blz: '81052000',
        city: 'Wernigerode',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19462,
        name: 'Kreissparkasse Wernigerode -alt-',
        blz: '81053112',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19463,
        name: 'Kreissparkasse Halberstadt -alt-',
        blz: '81053132',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19464,
        name: 'Kreissparkasse Schönebeck -alt-',
        blz: '81053242',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19465,
        name: 'Stadtsparkasse Magdeburg',
        bic: 'NOLADE21MDG',
        blz: '81053272',
        city: 'Magdeburg',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19467,
        name: 'Kreissparkasse Börde',
        bic: 'NOLADE21HDL',
        blz: '81055000',
        city: 'Oschersleben (Bode)',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19452,
        name: 'Sparkasse Wittenberg',
        bic: 'NOLADE21WBL',
        blz: '80550101',
        city: 'Lutherstadt Wittenberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19490,
        name: 'Kreissparkasse Nordhausen',
        bic: 'HELADEF1NOR',
        blz: '82054052',
        city: 'Nordhausen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19491,
        name: 'Kyffhäusersparkasse',
        bic: 'HELADEF1KYF',
        blz: '82055000',
        city: 'Sondershausen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19466,
        name: 'Sparkasse Jerichower Land',
        bic: 'NOLADE21JEL',
        blz: '81054000',
        city: 'Burg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19562,
        name: 'Volksbank Riesa',
        bic: 'GENODEF1RIE',
        blz: '85094984',
        city: 'Riesa',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19507,
        name: 'Bundesbank eh Gera',
        bic: 'MARKDEF1830',
        blz: '83000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19508,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM468',
        blz: '83020086',
        city: 'Gera',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19509,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM463',
        blz: '83020087',
        city: 'Jena',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19510,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM484',
        blz: '83020088',
        city: 'Saalfeld/Saale',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19503,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF827',
        blz: '82080000',
        city: 'Weimar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19518,
        name: 'Geraer Bank',
        bic: 'GENODEF1GEV',
        blz: '83064568',
        city: 'Gera',
        available: true
    },
    {
        id: 19526,
        name: 'Bundesbank',
        bic: 'MARKDEF1840',
        blz: '84000000',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19527,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM477',
        blz: '84020086',
        logoPath: '181.svg',
        available: false
    },
    {
        id: 19528,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM458',
        blz: '84020087',
        city: 'Suhl',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19535,
        name: 'Genobank Rhön-Grabfeld',
        bic: 'GENODEF1MLF',
        blz: '84064798',
        city: 'Meiningen',
        available: true
    },
    {
        id: 19540,
        name: 'Bundesbank eh Dresden',
        bic: 'MARKDEF1850',
        blz: '85000000',
        city: 'Leipzig',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19541,
        name: 'Sächsische Aufbaubank -Förderbank-',
        bic: 'SABDDE81XXX',
        blz: '85010500',
        city: 'Dresden',
        available: false
    },
    {
        id: 19542,
        name: 'ZV Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST850',
        blz: '85020030',
        available: false
    },
    {
        id: 19543,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM496',
        blz: '85020086',
        city: 'Dresden',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19544,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33DRE',
        blz: '85020500',
        city: 'Dresden',
        available: false
    },
    {
        id: 19545,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 536 Dre',
        bic: 'HYVEDEM1089',
        blz: '85020890',
        available: false
    },
    {
        id: 19504,
        name: 'Volksbank Heiligenstadt',
        bic: 'GENODEF1HIG',
        blz: '82094004',
        city: 'Heilbad Heiligenstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19505,
        name: 'Nordthüringer Volksbank',
        bic: 'GENODEF1NDS',
        blz: '82094054',
        city: 'Nordhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19506,
        name: 'Volksbank Erfurt -alt-',
        blz: '82094224',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19511,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '83040000',
        city: 'Gera',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19516,
        name: 'Sparkasse Jena-Saale-Holzland',
        bic: 'HELADEF1JEN',
        blz: '83053030',
        city: 'Jena',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19530,
        name: 'Rhön-Rennsteig-Sparkasse',
        bic: 'HELADEF1RRS',
        blz: '84050000',
        city: 'Meiningen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19531,
        name: 'Sparkasse Arnstadt-Ilmenau',
        bic: 'HELADEF1ILK',
        blz: '84051010',
        city: 'Ilmenau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19519,
        name: 'VR-Bank Altenburger Land / Deutsche Skatbank',
        bic: 'GENODEF1SLR',
        blz: '83065408',
        city: 'Schmölln',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19520,
        name: 'Deutsche Skatbank Zndl VR Bank Altenburger Land',
        bic: 'GENODEF1ALT',
        blz: '83065410',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19521,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF830',
        blz: '83080000',
        city: 'Gera',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19522,
        name: 'Raiffeisen-Volksbank Saale-Orla',
        bic: 'GENODEF1PN1',
        blz: '83094444',
        city: 'Pößneck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19523,
        name: 'Volksbank Gera-Jena-Rudolstadt',
        bic: 'GENODEF1RUJ',
        blz: '83094454',
        city: 'Jena',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19524,
        name: 'Volksbank Eisenberg',
        bic: 'GENODEF1ESN',
        blz: '83094494',
        city: 'Eisenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19525,
        name: 'EthikBank, Zndl der Volksbank Eisenberg',
        bic: 'GENODEF1ETK',
        blz: '83094495',
        city: 'Eisenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19529,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '84040000',
        city: 'Meiningen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19533,
        name: 'Sparkasse Sonneberg',
        bic: 'HELADEF1SON',
        blz: '84054722',
        city: 'Sonneberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19534,
        name: 'Wartburg-Sparkasse',
        bic: 'HELADEF1WAK',
        blz: '84055050',
        city: 'Eisenach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17860,
        name: 'Sparkasse Rhein-Haardt',
        bic: 'MALADE51DKH',
        blz: '54651240',
        city: 'Bad Dürkheim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19549,
        name: 'Sparkasse Oberlausitz-Niederschlesien',
        bic: 'WELADED1GRL',
        blz: '85050100',
        city: 'Zittau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19536,
        name: 'Raiffeisenbank Schleusingen',
        bic: 'GENODEF1SSG',
        blz: '84069065',
        city: 'Schleusingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19537,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF843',
        blz: '84080000',
        city: 'Meiningen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19538,
        name: 'VR-Bank Bad Salzungen Schmalkalden',
        bic: 'GENODEF1SAL',
        blz: '84094754',
        city: 'Bad Salzungen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19539,
        name: 'VR Bank Südthüringen',
        bic: 'GENODEF1SHL',
        blz: '84094814',
        city: 'Suhl',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19551,
        name: 'Ostsächsische Sparkasse Dresden',
        bic: 'OSDDDE81XXX',
        blz: '85050300',
        city: 'Dresden',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19547,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '85040060',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17861,
        name: 'Raiffeisenbank Freinsheim',
        bic: 'GENODE61FSH',
        blz: '54661800',
        city: 'Freinsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19548,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '85040061',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19553,
        name: 'Sparkasse Meißen',
        bic: 'SOLADES1MEI',
        blz: '85055000',
        city: 'Riesa',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19514,
        name: 'Kreissparkasse Saalfeld-Rudolstadt',
        bic: 'HELADEF1SAR',
        blz: '83050303',
        city: 'Saalfeld/Saale',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19513,
        name: 'Sparkasse Altenburger Land',
        bic: 'HELADEF1ALT',
        blz: '83050200',
        city: 'Altenburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19515,
        name: 'Kreissparkasse Saale-Orla',
        bic: 'HELADEF1SOK',
        blz: '83050505',
        city: 'Schleiz',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19554,
        name: 'Volksbank Pirna',
        bic: 'GENODEF1PR2',
        blz: '85060000',
        city: 'Pirna',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19555,
        name: 'Raiffeisenbank Neustadt, Sachs -alt-',
        bic: 'GENODEF1SEB',
        blz: '85065028',
        city: 'Sebnitz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19556,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF850',
        blz: '85080000',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19558,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFJ30',
        blz: '85080086',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19559,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF857',
        blz: '85080200',
        city: 'Hoyerswerda',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19560,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI15',
        blz: '85089270',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19561,
        name: 'Volksbank Dresden-Bautzen',
        bic: 'GENODEF1DRS',
        blz: '85090000',
        city: 'Dresden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20075,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39104',
        available: false
    },
    {
        id: 19532,
        name: 'Kreissparkasse Hildburghausen',
        bic: 'HELADEF1HIL',
        blz: '84054040',
        city: 'Hildburghausen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19550,
        name: 'Kreissparkasse Riesa-Großenhain -alt-',
        bic: 'SOLADES1RGA',
        blz: '85050200',
        city: 'Riesa',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19512,
        name: 'Sparkasse Gera-Greiz',
        bic: 'HELADEF1GER',
        blz: '83050000',
        city: 'Gera',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19568,
        name: 'Volksbank Löbau-Zittau',
        bic: 'GENODEF1NGS',
        blz: '85590100',
        city: 'Ebersbach-Neugersdorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19564,
        name: 'Landeskirchliche Kredit-Genossenschaft Sachsen -alt-',
        bic: 'GENODEF1LKG',
        blz: '85095164',
        available: false
    },
    {
        id: 19571,
        name: 'Bundesbank',
        bic: 'MARKDEF1860',
        blz: '86000000',
        city: 'Leipzig',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19572,
        name: 'SEB (DSK Hyp)',
        bic: 'ESSEDE5F860',
        blz: '86010111',
        city: 'Leipzig',
        logoPath: '114.svg',
        available: true
    },
    {
        id: 19573,
        name: 'Aareal Bank',
        bic: 'AARBDE5W860',
        blz: '86010424',
        city: 'Leipzig',
        logoPath: '188.svg',
        available: false
    },
    {
        id: 19574,
        name: 'ZV Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST860',
        blz: '86020030',
        available: false
    },
    {
        id: 19575,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM495',
        blz: '86020086',
        city: 'Leipzig',
        logoPath: '181.svg',
        available: true
    },
    {id: 19576, name: 'BHF-BANK', blz: '86020200', available: false},
    {
        id: 19577,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33LPZ',
        blz: '86020500',
        city: 'Leipzig',
        available: false
    },
    {
        id: 19578,
        name: 'Hanseatic Bank',
        bic: 'HSTBDEH1LEI',
        blz: '86020600',
        logoPath: '86.svg',
        available: false
    },
    {
        id: 19579,
        name: 'UniCredit Bank - HypoVereinsbank Ndl 508 Lei',
        bic: 'HYVEDEM1088',
        blz: '86020880',
        available: false
    },
    {
        id: 19583,
        name: 'ZV Landesbank Baden-Württemberg (LBBW)',
        bic: 'SOLADEST861',
        blz: '86050000',
        city: 'Leipzig',
        available: false
    },
    {
        id: 19569,
        name: 'Volksbank Raiffeisenbank Niederschlesien',
        bic: 'GENODEF1GR1',
        blz: '85591000',
        city: 'Görlitz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19605,
        name: 'Bundesbank',
        bic: 'MARKDEF1870',
        blz: '87000000',
        city: 'Chemnitz',
        logoPath: '63.svg',
        available: true
    },
    {
        id: 19606,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM497',
        blz: '87020086',
        city: 'Chemnitz',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19607,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM481',
        blz: '87020087',
        city: 'Plauen',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19608,
        name: 'UniCredit Bank - HypoVereinsbank',
        bic: 'HYVEDEMM441',
        blz: '87020088',
        city: 'Zwickau',
        logoPath: '181.svg',
        available: true
    },
    {
        id: 19570,
        name: 'Volksbank Westlausitz -alt-',
        bic: 'GENODEF1BIW',
        blz: '85595500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19580,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '86040000',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19581,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '86040060',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19582,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '86040061',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19610,
        name: 'Sparkasse Chemnitz',
        bic: 'CHEKDE81XXX',
        blz: '87050000',
        city: 'Chemnitz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19611,
        name: 'Sparkasse Mittelsachsen',
        bic: 'WELADED1MTW',
        blz: '87051000',
        city: 'Mittweida',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19589,
        name: 'VR Bank Leipziger Land',
        bic: 'GENODEF1BOA',
        blz: '86065448',
        city: 'Borna',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19590,
        name: 'VR-Bank Mittelsachsen',
        bic: 'GENODEF1DL1',
        blz: '86065468',
        city: 'Freiberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19591,
        name: 'Raiffeisenbank Grimma',
        bic: 'GENODEF1GMR',
        blz: '86065483',
        city: 'Grimma',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19593,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1TGB',
        blz: '86069070',
        city: 'Torgau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19594,
        name: 'Deutsche Bank',
        bic: 'DEUTDE8LXXX',
        blz: '86070000',
        city: 'Leipzig',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19595,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBLEG',
        blz: '86070024',
        city: 'Leipzig',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19596,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF860',
        blz: '86080000',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19597,
        name: 'Commerzbank vormals Dresdner Bank Zw 55',
        bic: 'DRESDEFF862',
        blz: '86080055',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19598,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF867',
        blz: '86080057',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19599,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ31',
        blz: '86080085',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19600,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFJ32',
        blz: '86080086',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19601,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI16',
        blz: '86089280',
        city: 'Leipzig',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19602,
        name: 'Volks- und Raiffeisenbank Muldental',
        bic: 'GENODEF1GMV',
        blz: '86095484',
        city: 'Grimma',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19603,
        name: 'Volksbank Delitzsch',
        bic: 'GENODEF1DZ1',
        blz: '86095554',
        city: 'Delitzsch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19604,
        name: 'Leipziger Volksbank',
        bic: 'GENODEF1LVB',
        blz: '86095604',
        city: 'Leipzig',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17862,
        name: 'Raiffeisenbank Friedelsheim-Rödersheim',
        bic: 'GENODE61FHR',
        blz: '54663270',
        city: 'Friedelsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19612,
        name: 'Sparkasse Mittelsachsen',
        bic: 'WELADED1FGX',
        blz: '87052000',
        city: 'Freiberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19615,
        name: 'Sparkasse Zwickau',
        bic: 'WELADED1ZWI',
        blz: '87055000',
        city: 'Zwickau',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19617,
        name: 'Sparkasse Vogtland',
        bic: 'WELADED1PLX',
        blz: '87058000',
        city: 'Plauen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19565,
        name: 'Kreissparkasse Bautzen',
        bic: 'SOLADES1BAT',
        blz: '85550000',
        city: 'Bautzen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19586,
        name: 'Sparkasse Delitzsch-Eilenburg -alt-',
        bic: 'SOLADES1DES',
        blz: '86055002',
        city: 'Delitzsch',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19618,
        name: 'Volksbank Erzgebirge -alt-',
        bic: 'GENODEF1AUE',
        blz: '87065893',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19619,
        name: 'Raiffeisenbank Werdau-Zwickau',
        bic: 'GENODEF1LGH',
        blz: '87065918',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19620,
        name: 'Volksbank Mittleres Erzgebirge',
        bic: 'GENODEF1MBG',
        blz: '87069075',
        city: 'Olbernhau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19621,
        name: 'Vereinigte Raiffeisenbank Burgstädt',
        bic: 'GENODEF1BST',
        blz: '87069077',
        city: 'Burgstädt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19622,
        name: 'Deutsche Bank',
        bic: 'DEUTDE8CXXX',
        blz: '87070000',
        city: 'Chemnitz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19623,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBCHE',
        blz: '87070024',
        city: 'Chemnitz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19624,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF870',
        blz: '87080000',
        city: 'Chemnitz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19567,
        name: 'Volksbank Bautzen',
        bic: 'GENODEF1BZV',
        blz: '85590000',
        city: 'Bautzen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19566,
        name: 'Kreissparkasse Löbau-Zittau -alt-',
        bic: 'SOLADES1ZIT',
        blz: '85550200',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19585,
        name: 'Kreissparkasse Torgau-Oschatz -alt-',
        bic: 'WELADED1TGU',
        blz: '86050600',
        city: 'Oschatz',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19587,
        name: 'Kreissparkasse Döbeln',
        bic: 'SOLADES1DLN',
        blz: '86055462',
        city: 'Döbeln',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19588,
        name: 'Stadt- und Kreissparkasse Leipzig',
        bic: 'WELADE8LXXX',
        blz: '86055592',
        city: 'Leipzig',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19614,
        name: 'Erzgebirgssparkasse',
        bic: 'WELADED1STB',
        blz: '87054000',
        city: 'Annaberg-Buchholz',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19616,
        name: 'Kreissparkasse Aue-Schwarzenberg',
        bic: 'WELADED1AUS',
        blz: '87056000',
        city: 'Aue',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19584,
        name: 'Sparkasse Muldental',
        bic: 'SOLADES1GRM',
        blz: '86050200',
        city: 'Grimma',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19629,
        name: 'Volksbank Erzgebirge',
        bic: 'GENODEF1ANA',
        blz: '87096034',
        city: 'Annaberg-Buchholz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19631,
        name: 'Volksbank Mittweida',
        bic: 'GENODEF1MIW',
        blz: '87096124',
        city: 'Mittweida',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19630,
        name: 'Freiberger Bank -alt-',
        bic: 'GENODEF1FBV',
        blz: '87096074',
        available: false
    },
    {
        id: 19633,
        name: 'RCI Banque Direkt (Renault Bank direkt)',
        bic: 'RCIDDE3NPAY',
        blz: '30520037',
        city: 'Neuss',
        available: true
    },
    {
        id: 19635,
        name: 'PayCenter',
        bic: 'PAGMDEM1XXX',
        blz: '70017000',
        city: 'Freising',
        logoPath: '107.svg',
        available: true
    },
    {
        id: 19636,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '20010020',
        city: 'Hamburg',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19637,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '20110022',
        city: 'Hamburg',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19638,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '25010030',
        city: 'Hannover',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19639,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '36010043',
        city: 'Essen',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19640,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '37010050',
        city: 'Köln',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19641,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '37011000',
        city: 'Köln',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19642,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '44010046',
        city: 'Dortmund',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19643,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '50010060',
        city: 'Frankfurt am Main',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19644,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '54510067',
        city: 'Ludwigshafen am Rhein',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19645,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010011',
        city: 'St. Ingbert',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19646,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010012',
        city: 'Neunkirchen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19647,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010013',
        city: 'Dillingen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19648,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010014',
        city: 'Völklingen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19649,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF015',
        blz: '59010015',
        city: 'Horb am Neckar',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19650,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010016',
        city: 'Bruchsal',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19651,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF017',
        blz: '59010017',
        city: 'Berlin',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19652,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010018',
        city: 'Wedel',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19653,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF019',
        blz: '59010019',
        city: 'Ipsheim',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19654,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010020',
        city: 'Rellingen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19655,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF021',
        blz: '59010021',
        city: 'Nauen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19656,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF022',
        blz: '59010022',
        city: 'Rahden',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19657,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF023',
        blz: '59010023',
        city: 'Dorsten',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19658,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010024',
        city: 'Brühl',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19659,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF025',
        blz: '59010025',
        city: 'Mengen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19660,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010026',
        city: 'Kaufbeuren',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19661,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010027',
        city: 'Pinneberg',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19662,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010028',
        city: 'Alzey',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19663,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010029',
        city: 'Bad Vilbel',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19664,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010031',
        city: 'Bretten',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19665,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF032',
        blz: '59010032',
        city: 'Wilster',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19666,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF033',
        blz: '59010033',
        city: 'Garding',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19667,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010034',
        city: 'Soltau',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19668,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010035',
        city: 'Delmenhorst',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19669,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010036',
        city: 'Cham',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19670,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF037',
        blz: '59010037',
        city: 'Geretsried',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19671,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010038',
        city: 'Aichach',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19672,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF039',
        blz: '59010039',
        city: 'Ditzingen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19673,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010040',
        city: 'Leonberg',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19674,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010041',
        city: 'Germersheim',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19675,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF042',
        blz: '59010042',
        city: 'Rhens',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19676,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF044',
        blz: '59010044',
        city: 'Obertshausen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19677,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010045',
        city: 'Maintal',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19678,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF047',
        blz: '59010047',
        city: 'Pulheim',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19679,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010048',
        city: 'Frechen',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19632,
        name: 'Volksbank Chemnitz',
        bic: 'GENODEF1CH1',
        blz: '87096214',
        city: 'Chemnitz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19634,
        name: 'Frankfurter Sparkasse',
        bic: 'HELADEF1822',
        blz: '50050201',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17863,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB546',
        blz: '54670024',
        city: 'Neustadt an der Weinstraße',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19627,
        name: 'Volksbank Zwickau',
        bic: 'GENODEF1Z01',
        blz: '87095934',
        city: 'Zwickau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19680,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFF049',
        blz: '59010049',
        city: 'Brakel',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 19681,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '59010066',
        city: 'Saarbrücken',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19682,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '60010070',
        city: 'Stuttgart',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19683,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '66010075',
        city: 'Karlsruhe',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19684,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '70010080',
        city: 'München',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19685,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '70110088',
        city: 'München',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19686,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '76010085',
        city: 'Nürnberg',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19687,
        name: 'POSTBANK NDL DER DB PRIVAT- UND FIRMENKUNDENBANK',
        bic: 'PBNKDEFFXXX',
        blz: '86010090',
        city: 'Leipzig',
        logoPath: '140.svg',
        available: true
    },
    {
        id: 19688,
        name: 'Bankhaus Max Flessa',
        bic: 'FLESDEMMXXX',
        blz: '70030111',
        city: 'München',
        available: true
    },
    {
        id: 19689,
        name: 'Bankhaus Max Flessa',
        bic: 'FLESDEMMXXX',
        blz: '76330111',
        city: 'Erlangen',
        available: false
    },
    {
        id: 19690,
        name: 'Bankhaus Max Flessa',
        bic: 'FLESDEMMXXX',
        blz: '77030111',
        city: 'Bamberg',
        available: true
    },
    {
        id: 19691,
        name: 'Bankhaus Max Flessa',
        bic: 'FLESDEMMXXX',
        blz: '78330111',
        city: 'Coburg',
        available: true
    },
    {
        id: 19692,
        name: 'Bankhaus Max Flessa',
        bic: 'FLESDEMMXXX',
        blz: '84030111',
        city: 'Meiningen',
        available: true
    },
    {
        id: 19693,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '10120600',
        city: 'Frankfurt am Main',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19694,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '20133300',
        city: 'Hamburg',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19695,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '25020600',
        city: 'Hannover',
        logoPath: '175.svg',
        available: true
    },
    {
        id: 19696,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '36033300',
        city: 'Essen',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19697,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '50033300',
        city: 'Frankfurt am Main',
        logoPath: '175.svg',
        available: true
    },
    {
        id: 19698,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '55033300',
        city: 'Mainz',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19699,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '60133300',
        city: 'Stuttgart',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19700,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '70133300',
        city: 'München',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19701,
        name: 'Santander Consumer Bank',
        bic: 'SCFBDE33XXX',
        blz: '86033300',
        city: 'Leipzig',
        logoPath: '175.svg',
        available: false
    },
    {
        id: 19702,
        name: 'Evangelische Darlehnsgenossenschaft',
        bic: 'GENODEF1EDG',
        blz: '21060237',
        available: false
    },
    {
        id: 17866,
        name: 'VR Bank Mittelhaardt',
        bic: 'GENODE61DUW',
        blz: '54691200',
        city: 'Bad Dürkheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17869,
        name: 'Raiffeisenbank Schifferstadt -alt-',
        bic: 'GENODE61SFS',
        blz: '54761411',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17870,
        name: 'Volksbank Kur- und Rheinpfalz',
        bic: 'GENODE61SPE',
        blz: '54790000',
        city: 'Speyer',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19712,
        name: 'Bankhaus Reuschel & Co -alt-',
        bic: 'CHDBDEHHXXX',
        blz: '70030300',
        city: 'München',
        available: true
    },
    {
        id: 19713,
        name: 'BHW Bausparkasse',
        bic: 'BHWBDE2HXXX',
        blz: '25410300',
        logoPath: '46.svg',
        available: false
    },
    {
        id: 19714,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '26520017',
        city: 'Osnabrück',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19715,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '26521703',
        city: 'Bramsche',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19716,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '26522319',
        city: 'Quakenbrück',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19717,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '26620010',
        city: 'Lingen (Ems)',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19718,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '26621413',
        city: 'Meppen',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19719,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '26720028',
        city: 'Nordhorn',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19720,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28020050',
        city: 'Oldenburg',
        logoPath: '104.svg',
        available: true
    },
    {
        id: 19721,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28021002',
        city: 'Brake (Unterweser)',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19722,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28021301',
        city: 'Bad Zwischenahn',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19723,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28021504',
        city: 'Cloppenburg',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19724,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28021623',
        city: 'Damme',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19725,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28021705',
        city: 'Delmenhorst',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19726,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28021906',
        city: 'Elsfleth',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19727,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28022015',
        city: 'Nordenham',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19728,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28022412',
        city: 'Löningen',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19729,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28022511',
        city: 'Lohne (Oldenburg)',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19730,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28022620',
        city: 'Rastede',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19731,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28022822',
        city: 'Vechta',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19732,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28023224',
        city: 'Westerstede',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19733,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28023325',
        city: 'Wildeshausen',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19734,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28220026',
        city: 'Wilhelmshaven',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19735,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28222208',
        city: 'Jever',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19736,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28222621',
        city: 'Varel',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19737,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28320014',
        city: 'Norden',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19738,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28321816',
        city: 'Norderney',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 17872,
        name: 'Sparkasse Südliche Weinstraße in Landau',
        bic: 'SOLADES1SUW',
        blz: '54850010',
        city: 'Landau in der Pfalz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19707,
        name: 'Commerzbank vormals Dresdner Bank Zw 97',
        bic: 'DRESDEFFXXX',
        blz: '37080097',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17864,
        name: 'Deutsche Bank',
        bic: 'DEUTDESM546',
        blz: '54670095',
        city: 'Neustadt an der Weinstraße',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19705,
        name: 'Commerzbank vormals Dresdner Bank Zw 38',
        bic: 'DRESDEFFXXX',
        blz: '30080038',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17865,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF546',
        blz: '54680022',
        city: 'Neustadt an der Weinstraße',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20076,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39109',
        available: false
    },
    {
        id: 19739,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28420007',
        city: 'Emden',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19740,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28421030',
        city: 'Aurich',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19741,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28520009',
        city: 'Leer (Ostfriesland)',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19742,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '28521518',
        city: 'Papenburg',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19743,
        name: 'Oldenburgische Landesbank AG',
        bic: 'OLBODEH2XXX',
        blz: '29121731',
        city: 'Syke',
        logoPath: '104.svg',
        available: false
    },
    {
        id: 19744,
        name: 'apoBank',
        bic: 'DAAEDED1003',
        blz: '10090603',
        city: 'Berlin',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19745,
        name: 'apoBank',
        bic: 'DAAEDED1040',
        blz: '12090640',
        city: 'Berlin',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19746,
        name: 'apoBank',
        bic: 'DAAEDED1002',
        blz: '20090602',
        city: 'Hamburg',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19747,
        name: 'apoBank',
        bic: 'DAAEDED1019',
        blz: '21090619',
        city: 'Kiel',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19748,
        name: 'apoBank',
        bic: 'DAAEDED1020',
        blz: '23092620',
        city: 'Lübeck',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19749,
        name: 'apoBank',
        bic: 'DAAEDED1008',
        blz: '25090608',
        city: 'Hannover',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19750,
        name: 'apoBank',
        bic: 'DAAEDED1025',
        blz: '26560625',
        city: 'Osnabrück',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19751,
        name: 'apoBank',
        bic: 'DAAEDED1018',
        blz: '27090618',
        city: 'Braunschweig',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19752,
        name: 'apoBank',
        bic: 'DAAEDED1033',
        blz: '28090633',
        city: 'Oldenburg',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19753,
        name: 'apoBank',
        bic: 'DAAEDED1005',
        blz: '29090605',
        city: 'Bremen',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19754,
        name: 'apoBank',
        bic: 'DAAEDED1016',
        blz: '33060616',
        city: 'Wuppertal',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19755,
        name: 'apoBank',
        bic: 'DAAEDED1032',
        blz: '35060632',
        city: 'Duisburg',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19756,
        name: 'apoBank',
        bic: 'DAAEDED1010',
        blz: '36060610',
        city: 'Essen',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19757,
        name: 'apoBank',
        bic: 'DAAEDED1015',
        blz: '37060615',
        city: 'Köln',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19758,
        name: 'apoBank',
        bic: 'DAAEDED1030',
        blz: '39060630',
        city: 'Aachen',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19759,
        name: 'apoBank',
        bic: 'DAAEDED1014',
        blz: '40060614',
        city: 'Münster',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19760,
        name: 'apoBank',
        bic: 'DAAEDED1004',
        blz: '44060604',
        city: 'Dortmund',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19761,
        name: 'apoBank',
        bic: 'DAAEDED1007',
        blz: '50090607',
        city: 'Frankfurt am Main',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19762,
        name: 'apoBank',
        bic: 'DAAEDED1034',
        blz: '50890634',
        city: 'Darmstadt',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19763,
        name: 'apoBank',
        bic: 'DAAEDED1036',
        blz: '51090636',
        city: 'Wiesbaden',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19764,
        name: 'apoBank',
        bic: 'DAAEDED1011',
        blz: '52090611',
        city: 'Kassel',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19765,
        name: 'apoBank',
        bic: 'DAAEDED1035',
        blz: '53390635',
        city: 'Gießen',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19766,
        name: 'apoBank',
        bic: 'DAAEDED1023',
        blz: '54690623',
        city: 'Neustadt an der Weinstraße',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19767,
        name: 'apoBank',
        bic: 'DAAEDED1031',
        blz: '55060831',
        city: 'Mainz',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19768,
        name: 'apoBank',
        bic: 'DAAEDED1012',
        blz: '57060612',
        city: 'Koblenz',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19769,
        name: 'apoBank',
        bic: 'DAAEDED1026',
        blz: '59090626',
        city: 'Saarbrücken',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19770,
        name: 'apoBank',
        bic: 'DAAEDED1009',
        blz: '60090609',
        city: 'Stuttgart',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19771,
        name: 'apoBank',
        bic: 'DAAEDED1021',
        blz: '66090621',
        city: 'Karlsruhe',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19772,
        name: 'apoBank',
        bic: 'DAAEDED1017',
        blz: '67090617',
        city: 'Mannheim',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19773,
        name: 'apoBank',
        bic: 'DAAEDED1022',
        blz: '68090622',
        city: 'Freiburg im Breisgau',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19774,
        name: 'apoBank',
        bic: 'DAAEDED1006',
        blz: '70090606',
        city: 'München',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19775,
        name: 'apoBank',
        bic: 'DAAEDED1029',
        blz: '75090629',
        city: 'Regensburg',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19776,
        name: 'apoBank',
        bic: 'DAAEDED1013',
        blz: '76090613',
        city: 'Nürnberg',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19777,
        name: 'apoBank',
        bic: 'DAAEDED1028',
        blz: '77390628',
        city: 'Bayreuth',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19778,
        name: 'apoBank',
        bic: 'DAAEDED1024',
        blz: '79090624',
        city: 'Würzburg',
        logoPath: '31.svg',
        available: true
    },
    {
        id: 19779,
        name: 'National-Bank Essen',
        bic: 'NBAGDE3EXXX',
        blz: '36020030',
        city: 'Essen',
        available: true
    },
    {
        id: 19780,
        name: 'National-Bank',
        bic: 'NBAGDE3EXXX',
        blz: '36220030',
        available: false
    },
    {
        id: 19781,
        name: 'National-Bank',
        bic: 'NBAGDE3EXXX',
        blz: '36520030',
        available: false
    },
    {
        id: 19782,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210130',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19783,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210131',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19784,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210132',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19785,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210133',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19786,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210134',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19787,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210135',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19788,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210136',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19789,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210137',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19790,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210138',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19791,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210139',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19792,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210140',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19793,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210141',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19794,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210142',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19795,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210143',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19796,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210144',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19797,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210145',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19798,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210146',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19799,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210147',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19800,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210148',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19801,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210149',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19802,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210150',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19803,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210151',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19804,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210152',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19805,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210153',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19806,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210154',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19807,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210155',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19808,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210156',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19809,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210157',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19810,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210158',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19811,
        name: 'SEB TZN MB Ffm.',
        bic: 'ESSEDE5FXXX',
        blz: '50210159',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19812,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210160',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19813,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210161',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19814,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210162',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19815,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210163',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19816,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210164',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19817,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210165',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19818,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210166',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19819,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210167',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19820,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210168',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19821,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210169',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19822,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210170',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19823,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210171',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19824,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210172',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19825,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210173',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19826,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210174',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19827,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210175',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19828,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210176',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19829,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210177',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19830,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210178',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19831,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210179',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19832,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210180',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19833,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210181',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19834,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210182',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19835,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210183',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19836,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210184',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19837,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210185',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19838,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210186',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19839,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210187',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19840,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210188',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19841,
        name: 'SEB TZN MB Frankfurt',
        bic: 'ESSEDE5FXXX',
        blz: '50210189',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19842,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510120',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19843,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510121',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19844,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510122',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19845,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510123',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19846,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510124',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19847,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510125',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19848,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510126',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19849,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510127',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19850,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510128',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19851,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510129',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19852,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510130',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19853,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510131',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19854,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510132',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19855,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510133',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19856,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510134',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19857,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510135',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19858,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510136',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19859,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510137',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19860,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510138',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19861,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510139',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19862,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510140',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19863,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510141',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19864,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510142',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19865,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510143',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19866,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510144',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19867,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510145',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19868,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510146',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19869,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510147',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19870,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510148',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19871,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510149',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19872,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510150',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19873,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510151',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19874,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510152',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19875,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510153',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19876,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510154',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19877,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510155',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19878,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510156',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19879,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510157',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19880,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510158',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19881,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510159',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19882,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510160',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19883,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510161',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19884,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510162',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19885,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510163',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19886,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510164',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19887,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510165',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19888,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510166',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19889,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510167',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19890,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510168',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19891,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510169',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19892,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510170',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19893,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510171',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19894,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510172',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19895,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510173',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19896,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510174',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19897,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510175',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19898,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510176',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19899,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510177',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19900,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510178',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19901,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510179',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19902,
        name: 'SEB TZN MB Ffm',
        bic: 'ESSEDE5FXXX',
        blz: '50510180',
        logoPath: '114.svg',
        available: false
    },
    {
        id: 19916,
        name: 'Volksbank Stuttgart GAA',
        bic: 'VOBADESSXXX',
        blz: '60090133',
        city: 'Stuttgart',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19904,
        name: 'BethmannMaffei Bank -alt-',
        bic: 'DELBDE33XXX',
        blz: '50130100',
        available: false
    },
    {
        id: 19905,
        name: 'Bethmann Bank (Vormals LGT Bank Deutschland)',
        bic: 'DELBDE33XXX',
        blz: '50220200',
        logoPath: '62.svg',
        available: false
    },
    {
        id: 19906,
        name: 'Bethmann Bank (vormals Bankhaus Maffei & Co.)',
        bic: 'DELBDE33XXX',
        blz: '70030800',
        logoPath: '62.svg',
        available: false
    },
    {
        id: 19907,
        name: 'CreditPlus Bank',
        bic: 'CPLUDES1666',
        blz: '60030666',
        city: 'Stuttgart',
        logoPath: '66.svg',
        available: false
    },
    {
        id: 19910,
        name: 'net-m privatbank 1891',
        bic: 'BVWBDE2WXXX',
        blz: '70013199',
        city: 'München',
        logoPath: '149.svg',
        available: true
    },
    {
        id: 19911,
        name: 'Opel Bank',
        bic: 'GMGGDE51XXX',
        blz: '50025000',
        city: 'Rüsselsheim am Main',
        available: true
    },
    {
        id: 19912,
        name: 'Sberbank Europe Zndl Deutschland',
        bic: 'SEZDDEF1XXX',
        blz: '50020000',
        city: 'Frankfurt am Main',
        available: true
    },
    {
        id: 19913,
        name: 'DZ BANK, Deutsche Zentral-Genossenschaftsbank (GENO Broker)',
        bic: 'GENODEFFBRO',
        blz: '50060474',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: true
    },
    {
        id: 19914,
        name: 'European Bank for Financial Services',
        bic: 'EFSGDEM1XXX',
        blz: '70013010',
        city: 'Aschheim',
        available: true
    },
    {
        id: 19917,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33KRL',
        blz: '66020566',
        city: 'Karlsruhe',
        available: false
    },
    {
        id: 19919,
        name: 'FIL Fondsbank',
        bic: 'FFBKDEFFTHK',
        blz: '50021120',
        city: 'Kronberg im Taunus',
        available: true
    },
    {
        id: 19920,
        name: 'EIS Einlagensicherungsbank',
        bic: 'EIEGDEB1XXX',
        blz: '10031000',
        city: 'Berlin',
        available: false
    },
    {
        id: 19921,
        name: 'Societe Generale',
        bic: 'SOGEDEFFXXX',
        blz: '51210801',
        city: 'Frankfurt am Main',
        logoPath: '174.svg',
        available: false
    },
    {
        id: 19923,
        name: 'Agricultural Bank of China, Frankfurt Branch',
        bic: 'ABOCDEFFXXX',
        blz: '50038800',
        city: 'Frankfurt am Main',
        logoPath: '54.svg',
        available: false
    },
    {
        id: 19924,
        name: 'KBC Bank Ndl Deutschland',
        bic: 'KREDDEDDXXX',
        blz: '30524400',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 19925,
        name: 'Bankhaus August Lenz & Co Gf GAA',
        bic: 'LENZDEM1XXX',
        blz: '70130799',
        city: 'München',
        logoPath: '56.svg',
        available: false
    },
    {
        id: 19926,
        name: 'Deutsche Pfandbriefbank',
        bic: 'REBMDE7CXXX',
        blz: '70010570',
        city: 'Unterschleißheim',
        logoPath: '76.svg',
        available: false
    },
    {
        id: 19927,
        name: 'WEG Bank',
        bic: 'WEGBDE77XXX',
        blz: '70011600',
        city: 'Ottobrunn',
        available: false
    },
    {
        id: 19929,
        name: 'Rabobank International Frankfurt Branch',
        bic: 'RABODEFFXXX',
        blz: '50210295',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19930,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33MNZ',
        blz: '55020555',
        city: 'Mainz',
        available: false
    },
    {
        id: 19931,
        name: 'FinTech Group Bank AG (biw Bank)',
        bic: 'BIWBDE33303',
        blz: '30330800',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19932,
        name: 'Bankhaus Obotritia',
        bic: 'FLGMDE77XXX',
        blz: '70012400',
        city: 'Kulmbach',
        available: false
    },
    {
        id: 19922,
        name: 'Commerzbank, Filiale Nürnberg 2',
        bic: 'COBADEFFXXX',
        blz: '76040065',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19928,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '67040085',
        city: 'Mannheim',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19936,
        name: 'HSBC Trinkaus VAC',
        bic: 'TUBDDEDDXXX',
        blz: '30030889',
        city: 'Düsseldorf',
        logoPath: '182.svg',
        available: false
    },
    {
        id: 19937,
        name: 'Bankhaus Anton Hafner (Gf PayCenter)',
        bic: 'ANHODE77PAY',
        blz: '72030260',
        city: 'Augsburg',
        logoPath: '107.svg',
        available: false
    },
    {
        id: 19938,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33BER',
        blz: '10020510',
        city: 'Berlin',
        available: false
    },
    {
        id: 19939,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33BER',
        blz: '10020520',
        city: 'Berlin',
        available: false
    },
    {
        id: 19908,
        name: 'Deutsche Bausparkasse Badenia (ehem. DBS Bausparkasse)',
        bic: 'BBSPDE6KXXX',
        blz: '50810900',
        city: 'Karlsruhe',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19941,
        name: 'J.P. Morgan',
        bic: 'JPMGDEFFXXX',
        blz: '50110855',
        city: 'Frankfurt am Main',
        logoPath: '92.svg',
        available: false
    },
    {
        id: 19933,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '44040085',
        city: 'Dortmund',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19943,
        name: 'IC Cash Services',
        bic: 'CSHHDE71XXX',
        blz: '70013400',
        city: 'Taufkirchen',
        logoPath: '164.svg',
        available: false
    },
    {
        id: 19944,
        name: 'NATIXIS Pfandbriefbank',
        bic: 'NATXDEFPXXX',
        blz: '51211000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19945,
        name: 'BNP Paribas Niederlassung Deutschland',
        bic: 'BNPADEFFXXX',
        blz: '37010699',
        city: 'Frankfurt am Main',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 19946,
        name: 'Ikano Bank',
        bic: 'PLFGDE5AIKB',
        blz: '51230555',
        city: 'Wiesbaden',
        logoPath: '89.svg',
        available: false
    },
    {
        id: 19947,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33MUE',
        blz: '70020570',
        city: 'München',
        available: false
    },
    {
        id: 19948,
        name: 'Ikano Bank',
        bic: 'PLFGDE5AIKB',
        blz: '50230888',
        city: 'Wiesbaden',
        logoPath: '89.svg',
        available: false
    },
    {
        id: 17620,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB509',
        blz: '50970024',
        city: 'Bensheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17875,
        name: 'Raiffeisenbank',
        bic: 'GENODE61HXH',
        blz: '54862390',
        city: 'Herxheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17703,
        name: 'Volksbank Brandoberndorf',
        bic: 'GENODE51WBO',
        blz: '51591300',
        city: 'Waldsolms',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19915,
        name: 'Commerzbank, Filiale München 2',
        bic: 'COBADEFFXXX',
        blz: '70040045',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19918,
        name: 'Commerzbank, Filiale Düsseldorf 2',
        bic: 'COBADEDDXXX',
        blz: '30040005',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19909,
        name: 'Kreissparkasse Calw',
        bic: 'PZHSDE66XXX',
        blz: '60651070',
        city: 'Calw',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17873,
        name: 'Sparkasse Germersheim-Kandel',
        bic: 'MALADE51KAD',
        blz: '54851440',
        city: 'Kandel',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19950,
        name:
            'FinTech Group Bank AG (biw Bank für Investments und Wertpapiere)',
        bic: 'BIWBDE33760',
        blz: '76030800',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19953,
        name: 'KT Bank',
        bic: 'KTAGDEFFXXX',
        blz: '50234500',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19956,
        name: 'PKO Bank Polski Niederlassung Deutschland',
        bic: 'BPKODEFFXXX',
        blz: '50127000',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19957,
        name: 'S Broker Wiesbaden',
        bic: 'PULSDE5WXXX',
        blz: '51051000',
        city: 'Wiesbaden',
        available: true
    },
    {
        id: 19958,
        name: 'BNP Paribas Niederlassung Deutschland',
        bic: 'BNPADEFFXXX',
        blz: '51210699',
        city: 'Frankfurt am Main',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 19959,
        name: 'Bank of America, Filiale Frankfurt',
        bic: 'BOFADEFXVAM',
        blz: '50010910',
        city: 'Frankfurt am Main',
        logoPath: '53.svg',
        available: false
    },
    {
        id: 19955,
        name: 'Volksbank Gf GA',
        bic: 'GENODE61RAD',
        blz: '69291099',
        city: 'Konstanz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19961,
        name: 'TRUMPF Financial Services',
        bic: 'TRUFDE66XXX',
        blz: '60031000',
        city: 'Ditzingen',
        available: false
    },
    {
        id: 19963,
        name: 'solarisBank',
        bic: 'SOBKDEBBXXX',
        blz: '11010100',
        city: 'Berlin',
        logoPath: '168.svg',
        available: false
    },
    {
        id: 19964,
        name: 'Varengold Bank',
        bic: 'VGAGDEHHXXX',
        blz: '20030133',
        city: 'Hamburg',
        logoPath: '126.svg',
        available: false
    },
    {
        id: 19965,
        name: 'PSA Bank Deutschland',
        bic: 'BPNDDE52XXX',
        blz: '50030010',
        city: 'Neu-Isenburg',
        available: false
    },
    {
        id: 19972,
        name: 'DZ BANK Gf VK 2',
        bic: 'GENODEF1VK2',
        blz: '50060413',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 19973,
        name: 'Nordea Bank, Niederlassung Frankfurt',
        bic: 'NDEADEFFDKK',
        blz: '51430345',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 19974,
        name: 'DZ BANK für Bausparkasse Schwäbisch Hall',
        bic: 'GENODEF1VK3',
        blz: '50060414',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 19976,
        name: 'Deutsche Bundesbank Filiale Dortmund (Bargeldzentrum)',
        bic: 'MARKDEF1470',
        blz: '47000000',
        city: 'Dortmund',
        logoPath: '63.svg',
        available: false
    },
    {
        id: 19977,
        name: 'DZ BANK für Bausparkasse Schwäbisch Hall',
        bic: 'GENODEF1VK4',
        blz: '50060415',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 19962,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '10040085',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19979,
        name: 'PayPal (Web Login)',
        blz: 'LYT-PAYP',
        logoPath: '108.svg',
        available: false
    },
    {
        id: 19980,
        name: 'Wüstenrot Bank AG',
        bic: 'WBAGDEA1XXX',
        blz: '60420020',
        city: 'Ludwigsburg',
        available: true
    },
    {
        id: 19982,
        name: 'HUK Coburg VISA-Karte',
        blz: '99959980',
        available: false
    },
    {
        id: 19983,
        name: 'Tchibo / eBay / BahnCard / Tui Kreditkarte',
        blz: '99959997',
        available: true
    },
    {
        id: 19984,
        name: 'ipagoo - German Branch',
        bic: 'IPAGDEDFXXX',
        blz: '30019000',
        city: 'Düsseldorf',
        logoPath: '150.svg',
        available: false
    },
    {
        id: 19985,
        name: 'SAXO PAYMENTS',
        bic: 'SXPYDEHHXXX',
        blz: '20220800',
        city: 'Hamburg',
        available: false
    },
    {
        id: 19986,
        name: 'Wüstenrot Bank Pfandbriefbank',
        bic: 'WBAGDEA1XXX',
        blz: '60420021',
        city: 'Ludwigsburg',
        available: false
    },
    {
        id: 19987,
        name: 'PayPal (API)',
        blz: 'LYT-PAPI',
        logoPath: '108.svg',
        available: true
    },
    {
        id: 19988,
        name: 'Miles & More - Kreditkarte',
        blz: '99931401',
        available: true
    },
    {
        id: 19989,
        name: 'easybank Niederlassung Deutschland',
        bic: 'QLCKDED1XXX',
        blz: '30015500',
        city: 'Düsseldorf',
        available: false
    },
    {
        id: 19990,
        name: 'DZ BANK Gf VK 7',
        bic: 'GENODEF1VK7',
        blz: '50060417',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 19991,
        name: 'DZ BANK Gf VK 6',
        bic: 'GENODEF1VK6',
        blz: '50060416',
        city: 'Frankfurt am Main',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 19992,
        name: 'UniCredit Bank - HVB Settlement EAC94',
        bic: 'HYVEDEMME94',
        blz: '20730094',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19993,
        name: 'UniCredit Bank - HVB Settlement EAC93',
        bic: 'HYVEDEMME93',
        blz: '20730093',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19994,
        name: 'UniCredit Bank - HVB Settlement EAC96',
        bic: 'HYVEDEMME96',
        blz: '20730096',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19995,
        name: 'UniCredit Bank - HVB Settlement EAC95',
        bic: 'HYVEDEMME95',
        blz: '20730095',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19996,
        name: 'UniCredit Bank - HVB Settlement EAC90',
        bic: 'HYVEDEMME90',
        blz: '20730090',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19997,
        name: 'UniCredit Bank - HVB Settlement EAC92',
        bic: 'HYVEDEMME92',
        blz: '20730092',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19998,
        name: 'UniCredit Bank - HVB Settlement EAC91',
        bic: 'HYVEDEMME91',
        blz: '20730091',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19999,
        name: 'UniCredit Bank - HVB Settlement EAC98',
        bic: 'HYVEDEMME98',
        blz: '20730098',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20000,
        name: 'UniCredit Bank - HVB Settlement EAC97',
        bic: 'HYVEDEMME97',
        blz: '20730097',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20001,
        name: 'UniCredit Bank - HVB Settlement EAC99',
        bic: 'HYVEDEMME99',
        blz: '20730099',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20002,
        name: 'UniCredit Bank - HVB Settlement EAC83',
        bic: 'HYVEDEMME83',
        blz: '20730083',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20003,
        name: 'UniCredit Bank - HVB Settlement EAC82',
        bic: 'HYVEDEMME82',
        blz: '20730082',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20004,
        name: 'UniCredit Bank - HVB Settlement EAC85',
        bic: 'HYVEDEMME85',
        blz: '20730085',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20005,
        name: 'UniCredit Bank - HVB Settlement EAC84',
        bic: 'HYVEDEMME84',
        blz: '20730084',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20006,
        name: 'UniCredit Bank - HVB Settlement EAC81',
        bic: 'HYVEDEMME81',
        blz: '20730081',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20007,
        name: 'UniCredit Bank - HVB Settlement EAC80',
        bic: 'HYVEDEMME80',
        blz: '20730080',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20008,
        name: 'UniCredit Bank - HVB Settlement EAC87',
        bic: 'HYVEDEMME87',
        blz: '20730087',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20009,
        name: 'UniCredit Bank - HVB Settlement EAC86',
        bic: 'HYVEDEMME86',
        blz: '20730086',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20010,
        name: 'UniCredit Bank - HVB Settlement EAC89',
        bic: 'HYVEDEMME89',
        blz: '20730089',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 19966,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '50040085',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19967,
        name: 'Commerzbank, GF Web-K CMTS',
        bic: 'COBADEFFXXX',
        blz: '50040086',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19968,
        name: 'Commerzbank, Gf Web-K CMTS3',
        bic: 'COBADEFFXXX',
        blz: '50040087',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19969,
        name: 'Commerzbank, GF Web-K CMTS2',
        bic: 'COBADEFFXXX',
        blz: '50040084',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19970,
        name: 'VR-Bank Rottal-Inn Gf GAA',
        bic: 'GENODEF1PFK',
        blz: '74061814',
        city: 'Pfarrkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19971,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '35040085',
        city: 'Duisburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19975,
        name: 'Commerzbank, Filiale Berlin 3',
        bic: 'COBADEBBXXX',
        blz: '10040005',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19981,
        name: 'DWS Investments (Deutsche Bank Gruppe)',
        blz: '99971300',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17876,
        name: 'VR Bank Südpfalz',
        bic: 'GENODE61SUW',
        blz: '54862500',
        city: 'Landau in der Pfalz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19954,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '69440060',
        city: 'Villingen-Schwenningen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 20077,
        name: 'Posojilnica Bank eGen',
        bic: 'VSGKAT2KXXX',
        blz: '39116',
        available: false
    },
    {
        id: 19952,
        name: 'Bank of Scotland (Lloyds Bank)',
        bic: 'BOFSDEB1XXX',
        blz: '10220500',
        city: 'Berlin',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 20011,
        name: 'UniCredit Bank - HVB Settlement EAC88',
        bic: 'HYVEDEMME88',
        blz: '20730088',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20012,
        name: 'UniCredit Bank - HVB Settlement EAC72',
        bic: 'HYVEDEMME72',
        blz: '20730072',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20013,
        name: 'UniCredit Bank - HVB Settlement EAC71',
        bic: 'HYVEDEMME71',
        blz: '20730071',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20014,
        name: 'UniCredit Bank - HVB Settlement EAC74',
        bic: 'HYVEDEMME74',
        blz: '20730074',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20015,
        name: 'UniCredit Bank - HVB Settlement EAC73',
        bic: 'HYVEDEMME73',
        blz: '20730073',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20016,
        name: 'UniCredit Bank - HVB Settlement EAC70',
        bic: 'HYVEDEMME70',
        blz: '20730070',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20017,
        name: 'UniCredit Bank - HVB Settlement EAC79',
        bic: 'HYVEDEMME79',
        blz: '20730079',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20018,
        name: 'UniCredit Bank - HVB Settlement EAC76',
        bic: 'HYVEDEMME76',
        blz: '20730076',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20019,
        name: 'UniCredit Bank - HVB Settlement EAC75',
        bic: 'HYVEDEMME75',
        blz: '20730075',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20020,
        name: 'UniCredit Bank - HVB Settlement EAC78',
        bic: 'HYVEDEMME78',
        blz: '20730078',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20021,
        name: 'UniCredit Bank - HVB Settlement EAC77',
        bic: 'HYVEDEMME77',
        blz: '20730077',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20022,
        name: 'VZ Depotbank Deutschland',
        bic: 'DEPDDEMMXXX',
        blz: '70012700',
        city: 'München',
        available: false
    },
    {
        id: 20023,
        name: 'Kommunalkredit Austria, Zweigstelle Deutschland',
        bic: 'INVODEF2XXX',
        blz: '50220101',
        city: 'Frankfurt am Main',
        available: false
    },
    {
        id: 20024,
        name: 'UniCredit Bank - HVB Settlement EAC61',
        bic: 'HYVEDEMME61',
        blz: '20730061',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20025,
        name: 'UniCredit Bank - HVB Settlement EAC60',
        bic: 'HYVEDEMME60',
        blz: '20730060',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20026,
        name: 'UniCredit Bank - HVB Settlement EAC63',
        bic: 'HYVEDEMME63',
        blz: '20730063',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20027,
        name: 'UniCredit Bank - HVB Settlement EAC62',
        bic: 'HYVEDEMME62',
        blz: '20730062',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20028,
        name: 'UniCredit Bank - HVB Settlement EAC69',
        bic: 'HYVEDEMME69',
        blz: '20730069',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20029,
        name: 'UniCredit Bank - HVB Settlement EAC68',
        bic: 'HYVEDEMME68',
        blz: '20730068',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20030,
        name: 'UniCredit Bank - HVB Settlement EAC65',
        bic: 'HYVEDEMME65',
        blz: '20730065',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20031,
        name: 'UniCredit Bank - HVB Settlement EAC64',
        bic: 'HYVEDEMME64',
        blz: '20730064',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20032,
        name: 'UniCredit Bank - HVB Settlement EAC67',
        bic: 'HYVEDEMME67',
        blz: '20730067',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20033,
        name: 'UniCredit Bank - HVB Settlement EAC66',
        bic: 'HYVEDEMME66',
        blz: '20730066',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20060,
        name: 'Erste Bank der oesterreichischen Sparkassen AG',
        bic: 'GIBAATWWXXX',
        blz: '20111',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20035,
        name: 'ZVA Norddeutsche Landesbank Gf MV',
        bic: 'NOLADE21CMV',
        blz: '25050066',
        city: 'Hannover',
        available: false
    },
    {
        id: 20036,
        name: 'UniCredit Bank - HVB Settlement EAC50',
        bic: 'HYVEDEMME50',
        blz: '20730050',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20037,
        name: 'UniCredit Bank - HVB Settlement EAC52',
        bic: 'HYVEDEMME52',
        blz: '20730052',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20038,
        name: 'UniCredit Bank - HVB Settlement EAC58',
        bic: 'HYVEDEMME58',
        blz: '20730058',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20039,
        name: 'UniCredit Bank - HVB Settlement EAC57',
        bic: 'HYVEDEMME57',
        blz: '20730057',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20040,
        name: 'UniCredit Bank - HVB Settlement EAC59',
        bic: 'HYVEDEMME59',
        blz: '20730059',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20041,
        name: 'UniCredit Bank - HVB Settlement EAC56',
        bic: 'HYVEDEMME56',
        blz: '20730056',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20042,
        name: 'UniCredit Bank - HVB Settlement EAC55',
        bic: 'HYVEDEMME55',
        blz: '20730055',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20043,
        name: 'UniCredit Bank - HVB Settlement EAC41',
        bic: 'HYVEDEMME41',
        blz: '20730041',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20044,
        name: 'UniCredit Bank - HVB Settlement EAC47',
        bic: 'HYVEDEMME47',
        blz: '20730047',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20045,
        name: 'Bank für Sozialwirtschaft',
        bic: 'BFSWDE33HAN',
        blz: '20120520',
        city: 'Hamburg',
        available: false
    },
    {
        id: 20046,
        name: 'UniCredit Bank - HVB Settlement EAC46',
        bic: 'HYVEDEMME46',
        blz: '20730046',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20047,
        name: 'UniCredit Bank - HVB Settlement EAC49',
        bic: 'HYVEDEMME49',
        blz: '20730049',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20048,
        name: 'UniCredit Bank - HVB Settlement EAC48',
        bic: 'HYVEDEMME48',
        blz: '20730048',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20049,
        name: 'UniCredit Bank - HVB Settlement EAC43',
        bic: 'HYVEDEMME43',
        blz: '20730043',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20050,
        name: 'UniCredit Bank - HVB Settlement EAC42',
        bic: 'HYVEDEMME42',
        blz: '20730042',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20051,
        name: 'UniCredit Bank - HVB Settlement EAC45',
        bic: 'HYVEDEMME45',
        blz: '20730045',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20052,
        name: 'UniCredit Bank - HVB Settlement EAC44',
        bic: 'HYVEDEMME44',
        blz: '20730044',
        city: 'Hamburg',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20062,
        name: 'Erste Group Bank AG',
        bic: 'GIBAATWGXXX',
        blz: '20100',
        available: false
    },
    {
        id: 20056,
        name: 'DolomitenBank Osttirol-Westkärnten eG',
        bic: 'OVLIAT21XXX',
        blz: '40730',
        available: false
    },
    {
        id: 20058,
        name: 'DolomitenBank Osttirol-Westkärnten eG',
        bic: 'OVLIAT21XXX',
        blz: '44400',
        available: false
    },
    {
        id: 20059,
        name: 'Dornbirner Sparkasse Bank AG',
        bic: 'DOSPAT2DXXX',
        blz: '20602',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20070,
        name: 'Marchfelder Bank eG',
        bic: 'MVOGAT22XXX',
        blz: '42110',
        available: false
    },
    {
        id: 17892,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '55040022',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20066,
        name: 'Kärntner Sparkasse AG',
        bic: 'KSPKAT2KXXX',
        blz: '20706',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17893,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '55040060',
        city: 'Mainz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20055,
        name: 'Die Zweite Wiener Vereins-Sparcasse',
        bic: 'GIBAAT21XXX',
        blz: '20112',
        available: false
    },
    {
        id: 20057,
        name: 'DolomitenBank Osttirol-Westkärnten eG',
        bic: 'OVLIAT21XXX',
        blz: '43380',
        available: false
    },
    {
        id: 20069,
        name: 'Lienzer Sparkasse AG',
        bic: 'LISPAT21XXX',
        blz: '20507',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20063,
        name: 'Erste Group Bank AG',
        bic: 'GIBAATWGXXX',
        blz: '75800',
        available: false
    },
    {
        id: 20064,
        name: 'HYPO NOE Gruppe Bank AG',
        bic: 'HYINAT22XXX',
        blz: '53100',
        available: false
    },
    {
        id: 20065,
        name: 'HYPO NOE Landesbank für Niederösterreich und Wien AG',
        bic: 'HYPNATWWXXX',
        blz: '53000',
        available: false
    },
    {
        id: 20068,
        name: 'Landes-Hypothekenbank Steiermark AG',
        bic: 'HYSTAT2GXXX',
        blz: '56000',
        available: false
    },
    {
        id: 20067,
        name: 'KREMSER BANK UND SPARKASSEN AG',
        bic: 'SPKDAT21XXX',
        blz: '20228',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20071,
        name: 'Oberösterreichische Landesbank AG',
        bic: 'OBLAAT2LXXX',
        blz: '54000',
        available: false
    },
    {
        id: 20122,
        name: 'Raiffeisenbank Aspach-Wildenau eGen',
        bic: 'RZOOAT2L016',
        blz: '34016',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20123,
        name: 'Raiffeisenbank Attergau eGen',
        bic: 'RZOOAT2L523',
        blz: '34523',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20124,
        name: 'Raiffeisenbank Attersee-Nord eGen',
        bic: 'RZOOAT2L608',
        blz: '34608',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20125,
        name: 'Raiffeisenbank Attersee-Süd eGen',
        bic: 'RZOOAT2L363',
        blz: '34363',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20126,
        name: 'Raiffeisenbank Au eGen',
        bic: 'RVVGAT2B405',
        blz: '37405',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20127,
        name: 'Raiffeisenbank Auersthal-Bockfließ-Groß Schweinbarth eGen',
        bic: 'RLNWATWWAUE',
        blz: '32039',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20128,
        name: 'Raiffeisenbank Bad Radkersburg-Klöch eGen',
        bic: 'RZSTAT2G312',
        blz: '38312',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20129,
        name: 'Raiffeisenbank Bad Wimsbach-Neydharting eGen',
        bic: 'RZOOAT2L750',
        blz: '34750',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20130,
        name: 'Raiffeisenbank Bergheim eGen',
        bic: 'RVSAAT2S008',
        blz: '35008',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20131,
        name: 'Raiffeisenbank Bergheim eGen',
        bic: 'RVSAAT2S008',
        blz: '35108',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20132,
        name: 'Raiffeisenbank Bernhardsthal - Großkrut - Altlichtenwarth eGen',
        bic: 'RLNWATWWBGA',
        blz: '32227',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20133,
        name: 'Raiffeisenbank Bezau-Mellau-Bizau reg.Gen.m.b.H.',
        bic: 'RVVGAT2B406',
        blz: '37406',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20081,
        name: 'Raiffeisen Bank International AG',
        bic: 'RZBAATWWXXX',
        blz: '52200',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20110,
        name: 'Raiffeisenbank Bodensee-Leiblachtal eGen',
        bic: 'RVVGAT2B431',
        blz: '37431',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20083,
        name: 'Raiffeisen Bank Villach reg.Gen.m.b.H.',
        bic: 'RZKTAT2K496',
        blz: '39496',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20084,
        name: 'Raiffeisen Bank Villach reg.Gen.m.b.H.',
        bic: 'RZKTAT2K496',
        blz: '39483',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20086,
        name: 'Raiffeisen Bezirksbank Kufstein eGen',
        bic: 'RZTIAT22358',
        blz: '36358',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20085,
        name: 'Raiffeisen Bausparkasse Ges. m.b.H.',
        bic: 'RBSKATW1XXX',
        blz: '31303',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20087,
        name: 'Raiffeisen Bezirksbank Kufstein eGen',
        bic: 'RZTIAT22358',
        blz: '36216',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20088,
        name: 'Raiffeisen Bezirksbank Kufstein eGen',
        bic: 'RZTIAT22358',
        blz: '36236',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20090,
        name: 'Raiffeisen Regionalbank Hall in Tirol eGen',
        bic: 'RZTIAT22362',
        blz: '36362',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20089,
        name: 'Raiffeisen Centrobank AG',
        bic: 'CENBATWWXXX',
        blz: '19930',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20091,
        name: 'Raiffeisen Regionalbank Mödling eGen',
        bic: 'RLNWATWWGTD',
        blz: '32250',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20092,
        name: 'Raiffeisen Regionalbank Mödling eGen',
        bic: 'RLNWATWWGTD',
        blz: '32633',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20134,
        name: 'RAIFFEISENBANK BISCHOFSHOFEN eGen',
        bic: 'RVSAAT2S010',
        blz: '35010',
        logoPath: '177.svg',
        available: false
    },
    {
        id: 20093,
        name: 'Raiffeisen Regionalbank Schwaz eGen',
        bic: 'RZTIAT22322',
        blz: '36322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20094,
        name: 'Raiffeisen Regionalbank Schwaz eGen',
        bic: 'RZTIAT22322',
        blz: '36265',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20095,
        name: 'Raiffeisen Zentralbank Österreich AG',
        bic: 'RZBHATWWXXX',
        blz: '30000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20096,
        name: 'Raiffeisenbank Absam eGen',
        bic: 'RZTIAT22200',
        blz: '36200',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20097,
        name: 'Raiffeisenbank Abtenau-Rußbach reg.Gen.m.b.H.',
        bic: 'RVSAAT2S001',
        blz: '35001',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20098,
        name: 'Raiffeisenbank Abtenau-Rußbach reg.Gen.m.b.H.',
        bic: 'RVSAAT2S001',
        blz: '35101',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20099,
        name: 'Raiffeisenbank Achenkirch und Umgebung eGen',
        bic: 'RZTIAT22201',
        blz: '36201',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20100,
        name: 'Raiffeisenbank Admont eGen',
        bic: 'RZSTAT2G001',
        blz: '38001',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20101,
        name: 'Raiffeisenbank Aichfeld eGen',
        bic: 'RZSTAT2G346',
        blz: '38346',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20102,
        name: 'Raiffeisenbank Aichfeld eGen',
        bic: 'RZSTAT2G346',
        blz: '38076',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20104,
        name: 'Raiffeisenbank Alpbachtal eGen',
        bic: 'RZTIAT22203',
        blz: '36203',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20105,
        name: 'Raiffeisenbank Altenmarkt-Flachau-Eben eGen',
        bic: 'RVSAAT2S004',
        blz: '35004',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20107,
        name: 'Raiffeisenbank Altenmarkt-Flachau-Eben eGen',
        bic: 'RVSAAT2S004',
        blz: '35204',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17937,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5M560',
        blz: '56070040',
        city: 'Bad Kreuznach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20108,
        name: 'Raiffeisenbank Altenmarkt-Flachau-Eben eGen',
        bic: 'RVSAAT2S004',
        blz: '35404',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17938,
        name: 'Volksbank Rhein-Nahe-Hunsrück',
        bic: 'GENODE51KRE',
        blz: '56090000',
        city: 'Bad Kreuznach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20082,
        name: 'Raiffeisen Bank Lurnfeld-Reißeck reg.Gen.m.b.H.',
        bic: 'RZKTAT2K412',
        blz: '39412',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20137,
        name: 'Raiffeisenbank Bleiburg reg.Gen.m.b.H.',
        bic: 'RZKTAT2K272',
        blz: '39272',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20111,
        name: 'Raiffeisenbank am Hofsteig reg.Gen.m.b.H.',
        bic: 'RVVGAT2B482',
        blz: '37482',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20112,
        name: 'Raiffeisenbank amKumma eGen',
        bic: 'RVVGAT2B429',
        blz: '37429',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20113,
        name: 'Raiffeisenbank amKumma eGen',
        bic: 'RVVGAT2B429',
        blz: '37402',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20114,
        name: 'Raiffeisenbank Anif-Niederalm eGen',
        bic: 'RVSAAT2S005',
        blz: '35005',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20115,
        name: 'Raiffeisenbank Anif-Niederalm eGen',
        bic: 'RVSAAT2S005',
        blz: '35105',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20116,
        name: 'Raiffeisenbank Annaberg-Lungötz reg.Gen.m.b.H.',
        bic: 'RVSAAT2S006',
        blz: '35006',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20117,
        name: 'Raiffeisenbank Anthering - Elixhausen eGen',
        bic: 'RVSAAT2S007',
        blz: '35007',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20118,
        name: 'Raiffeisenbank Anthering - Elixhausen eGen',
        bic: 'RVSAAT2S007',
        blz: '35014',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20119,
        name: 'Raiffeisenbank Apetlon eGen',
        bic: 'RLBBAT2E004',
        blz: '33004',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20120,
        name: 'Raiffeisenbank Arnoldstein-Fürnitz eG',
        bic: 'RZKTAT2K257',
        blz: '39257',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20135,
        name: 'RAIFFEISENBANK BISCHOFSHOFEN eGen',
        bic: 'RVSAAT2S010',
        blz: '35110',
        logoPath: '177.svg',
        available: false
    },
    {
        id: 20136,
        name: 'RAIFFEISENBANK BISCHOFSHOFEN eGen',
        bic: 'RVSAAT2S010',
        blz: '35310',
        logoPath: '177.svg',
        available: false
    },
    {
        id: 20121,
        name: 'Raiffeisenbank Arzl im Pitztal und Imsterberg reg.Gen.m.b.H.',
        bic: 'RZTIAT22207',
        blz: '36207',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20182,
        name: 'Raiffeisenbank Fulpmes-Telfes im Stubai eGen',
        bic: 'RZTIAT22230',
        blz: '36230',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20183,
        name: 'Raiffeisenbank Fürnitz, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K305',
        blz: '39305',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20184,
        name: 'Raiffeisenbank Fürstenfeld eGen',
        bic: 'RZSTAT2G077',
        blz: '38077',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20185,
        name: 'Raiffeisenbank Gamlitz eGen',
        bic: 'RZSTAT2G094',
        blz: '38094',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20186,
        name: 'Raiffeisenbank Gampern eGen',
        bic: 'RZOOAT2L113',
        blz: '34113',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20187,
        name: 'Raiffeisenbank Gastein eGen',
        bic: 'RVSAAT2S026',
        blz: '35026',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20188,
        name: 'Raiffeisenbank Gastein eGen',
        bic: 'RVSAAT2S026',
        blz: '35126',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20189,
        name: 'Raiffeisenbank Gastein eGen',
        bic: 'RVSAAT2S026',
        blz: '35226',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20190,
        name: 'Raiffeisenbank Geretsberg eGen',
        bic: 'RZOOAT2L118',
        blz: '34118',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20177,
        name: 'RAIFFEISENBANK FRASTANZ-SATTEINS reg.Gen.m.b.H.',
        bic: 'RVVGAT2B424',
        blz: '37424',
        logoPath: '177.svg',
        available: false
    },
    {
        id: 20191,
        name: 'Raiffeisenbank Gleinstätten-Leutschach eGen',
        bic: 'RZSTAT2G102',
        blz: '38102',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20192,
        name: 'Raiffeisenbank Gleinstätten-Leutschach eGen',
        bic: 'RZSTAT2G102',
        blz: '38262',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20193,
        name: 'RaiffeisenBank Going eGen',
        bic: 'RZTIAT22232',
        blz: '36232',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20194,
        name: 'Raiffeisenbank Golling-Scheffau-Kellau reg.Gen.m.b.H.',
        bic: 'RVSAAT2S017',
        blz: '35017',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20195,
        name:
            'Raiffeisenbank Grafenstein-Magdalensberg und Umgebung, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K320',
        blz: '39320',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20141,
        name: 'Raiffeisenbank Bramberg eGen',
        bic: 'RVSAAT2S011',
        blz: '35111',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20167,
        name: 'Raiffeisenbank Erl eGen',
        bic: 'RZTIAT22223',
        blz: '36223',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20142,
        name: 'Raiffeisenbank Brandenberg eGen',
        bic: 'RZTIAT22213',
        blz: '36213',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20143,
        name: 'Raiffeisenbank Breitenau eGen',
        bic: 'RZSTAT2G026',
        blz: '38026',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20144,
        name: 'Raiffeisenbank Brixen im Thale eGen',
        bic: 'RZTIAT22215',
        blz: '36215',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20145,
        name: 'Raiffeisenbank Bruck-Carnuntum eGen',
        bic: 'RLNWATWWBRL',
        blz: '32073',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20146,
        name: 'Raiffeisenbank Bruck-Fusch-Kaprun eGen',
        bic: 'RVSAAT2S012',
        blz: '35012',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20147,
        name: 'Raiffeisenbank Bruck-Fusch-Kaprun eGen',
        bic: 'RVSAAT2S012',
        blz: '35112',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20148,
        name: 'Raiffeisenbank Bruck-Fusch-Kaprun eGen',
        bic: 'RVSAAT2S012',
        blz: '35212',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20149,
        name: 'Raiffeisenbank Bruck-Fusch-Kaprun eGen',
        bic: 'RVSAAT2S012',
        blz: '35312',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20150,
        name:
            'Raiffeisenbank Brückl-Eberstein-Klein St. Paul-Waisenberg, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K271',
        blz: '39271',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20151,
        name: 'Raiffeisenbank Buch, Gallzein und Strass reg.Gen.m.b.H.',
        bic: 'RZTIAT22272',
        blz: '36272',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20152,
        name: 'Raiffeisenbank Defereggental eGen',
        bic: 'RZTIAT22367',
        blz: '36367',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20153,
        name: 'Raiffeisenbank Schilcherland eGen',
        bic: 'RZSTAT2G043',
        blz: '38043',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20155,
        name: 'Raiffeisenbank Draßmarkt-Kobersdorf-St. Martin eGen',
        bic: 'RLBBAT2E014',
        blz: '33014',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20156,
        name: 'Raiffeisenbank Drautal reg.Gen.m.b.H.',
        bic: 'RZKTAT2K442',
        blz: '39442',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20157,
        name: 'Raiffeisenbank Dreiländereck Bgld-Nord eGen',
        bic: 'RLBBAT2E038',
        blz: '33038',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20158,
        name: 'Raiffeisenbank Dreiländereck Bgld-Nord eGen',
        bic: 'RLBBAT2E038',
        blz: '33063',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20159,
        name: 'Raiffeisenbank Dreiländereck Bgld-Nord eGen',
        bic: 'RLBBAT2E038',
        blz: '33114',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20160,
        name: 'Raiffeisen Regionalbank Achensee eGen',
        bic: 'RZTIAT22218',
        blz: '36218',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20161,
        name: 'Raiffeisenbank Eberndorf reg.Gen.m.b.H.',
        bic: 'RZKTAT2K288',
        blz: '39288',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20162,
        name: 'Raiffeisenbank Eberschwang eGen',
        bic: 'RZOOAT2L081',
        blz: '34081',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20163,
        name: 'Raiffeisenbank Edt-Lambach eGen',
        bic: 'RZOOAT2L083',
        blz: '34083',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20165,
        name: 'Raiffeisenbank Ehrwald-Lermoos-Biberwier eGen',
        bic: 'RZTIAT22219',
        blz: '36219',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17939,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '56240050',
        city: 'Idar-Oberstein',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20166,
        name: 'Raiffeisenbank Ennstal eGen',
        bic: 'RZOOAT2L080',
        blz: '34080',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17941,
        name: 'Raiffeisenbank Nahe',
        bic: 'GENODED1FIN',
        blz: '56261735',
        city: 'Fischbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20140,
        name: 'Raiffeisenbank Bramberg eGen',
        bic: 'RVSAAT2S011',
        blz: '35011',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20178,
        name: 'Raiffeisenbank Frauenkirchen-Podersdorf am See eGen',
        bic: 'RLBBAT2E116',
        blz: '33116',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20168,
        name: 'Raiffeisenbank Flachgau Mitte eGen',
        bic: 'RVSAAT2S015',
        blz: '35015',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20169,
        name: 'Raiffeisenbank Eugendorf-Plainfeld eGen',
        bic: 'RVSAAT2S015',
        blz: '35115',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20170,
        name: 'Raiffeisenbank Eugendorf-Plainfeld eGen',
        bic: 'RVSAAT2S015',
        blz: '35215',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20171,
        name: 'Raiffeisenbank Faistenau-Hintersee eGen',
        bic: 'RVSAAT2S016',
        blz: '35016',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20172,
        name: 'Raiffeisenbank Region Feldbach eGen',
        bic: 'RZSTAT2G497',
        blz: '38497',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20174,
        name: 'Raiffeisenbank Feldkirch, reg.Gen.m.b.H.',
        bic: 'RVVGAT2B422',
        blz: '37422',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20175,
        name: 'Raiffeisenbank Feldkirchen-Goldwörth eGen',
        bic: 'RZOOAT2L100',
        blz: '34100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20176,
        name: 'Raiffeisenbank Finkenstein - Faaker See, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K383',
        blz: '39383',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20179,
        name: 'Raiffeisenbank Frauenkirchen-Podersdorf am See eGen',
        bic: 'RLBBAT2E116',
        blz: '33074',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20180,
        name: 'Raiffeisenbank Freistadt Rust eGen',
        bic: 'RLBBAT2E085',
        blz: '33085',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20181,
        name: 'Raiffeisenbank Friesach-Metnitztal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K501',
        blz: '39501',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20244,
        name: 'Raiffeisenbank Hof-Koppl-Ebenau eGen',
        bic: 'RVSAAT2S025',
        blz: '35225',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20246,
        name: 'Raiffeisenbank Hollabrunn eGen',
        bic: 'RLNWATW1322',
        blz: '32322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20247,
        name: 'Raiffeisenbank Hollabrunn eGen',
        bic: 'RLNWATW1322',
        blz: '32198',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20248,
        name: 'Raiffeisenbank Hopfgarten im Brixental eGen',
        bic: 'RZTIAT22245',
        blz: '36245',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20249,
        name: 'Raiffeisenbank Horitschon und Umgebung eGen',
        bic: 'RLBBAT2E031',
        blz: '33031',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20250,
        name: 'Raiffeisenbank Hörsching-Thening eGen',
        bic: 'RZOOAT2L170',
        blz: '34170',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20251,
        name: 'Raiffeisenbank Hüttau-St. Martin-Niedernfritz reg.Gen.m.b.H.',
        bic: 'RVSAAT2S027',
        blz: '35027',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20252,
        name: 'Raiffeisenbank Hüttau-St. Martin-Niedernfritz reg.Gen.m.b.H.',
        bic: 'RVSAAT2S027',
        blz: '35127',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20253,
        name: 'Raiffeisenbank Hüttenberg - Wieting, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K340',
        blz: '39340',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20198,
        name: 'Raiffeisenbank Gratwein eGen',
        bic: 'RZSTAT2G112',
        blz: '38112',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20199,
        name: 'Raiffeisenbank Graz-Andritz eGen',
        bic: 'RZSTAT2G377',
        blz: '38377',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20200,
        name: 'Raiffeisenbank Graz-Mariatrost eGen',
        bic: 'RZSTAT2G223',
        blz: '38223',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20201,
        name: 'Raiffeisenbank Graz-St. Peter eGen',
        bic: 'RZSTAT2G367',
        blz: '38367',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20202,
        name: 'Raiffeisenbank Graz-Straßgang eGen',
        bic: 'RZSTAT2G439',
        blz: '38439',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20203,
        name: 'Raiffeisenbank Grein eGen',
        bic: 'RZOOAT2L068',
        blz: '34068',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20204,
        name: 'Raiffeisenbank Schladming-Gröbming eGen',
        bic: 'RZSTAT2G113',
        blz: '38113',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20205,
        name: 'Raiffeisenbank Grödig eGen',
        bic: 'RVSAAT2S018',
        blz: '35018',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20206,
        name: 'Raiffeisenbank Grödig eGen',
        bic: 'RVSAAT2S018',
        blz: '35118',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20208,
        name: 'Raiffeisenbank Großarl-Hüttschlag eGen',
        bic: 'RVSAAT2S019',
        blz: '35019',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20209,
        name: 'Raiffeisenbank Großarl-Hüttschlag eGen',
        bic: 'RVSAAT2S019',
        blz: '35119',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20210,
        name: 'Raiffeisenbank Großgmain eGen',
        bic: 'RVSAAT2S020',
        blz: '35020',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20211,
        name: 'Raiffeisenbank Großraming eGen',
        bic: 'RZOOAT2L126',
        blz: '34126',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20212,
        name: 'Raiffeisenbank Gross Gerungs eGen',
        bic: 'RLNWATW1589',
        blz: '32589',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20213,
        name: 'Raiffeisenbank Großwilfersdorf eGen',
        bic: 'RZSTAT2G122',
        blz: '38122',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20863,
        name: 'Oberbank AG (15023)',
        bic: 'OBKLAT2LXXX',
        blz: '15023',
        available: false
    },
    {
        id: 20218,
        name: 'Raiffeisenbank Halbenrain-Tieschen eGen',
        bic: 'RZSTAT2G128',
        blz: '38128',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20219,
        name: 'Raiffeisenbank Hallein eGen',
        bic: 'RVSAAT2S022',
        blz: '35022',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20220,
        name: 'Raiffeisenbank Hallein eGen',
        bic: 'RVSAAT2S022',
        blz: '35122',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20221,
        name: 'Raiffeisenbank Hallein eGen',
        bic: 'RVSAAT2S022',
        blz: '35322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20222,
        name: 'Raiffeisenbank Hallein eGen',
        bic: 'RVSAAT2S022',
        blz: '35422',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20223,
        name: 'Raiffeisenbank Hallein eGen',
        bic: 'RVSAAT2S022',
        blz: '35522',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20224,
        name: 'Raiffeisenbank Handenberg - St. Georgen a. F. eGen',
        bic: 'RZOOAT2L155',
        blz: '34155',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20225,
        name: 'Raiffeisenbank Hartberg eGen',
        bic: 'RZSTAT2G403',
        blz: '38403',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20227,
        name: 'Raiffeisenbank Heideboden eGen',
        bic: 'RLBBAT2E090',
        blz: '33090',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17942,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB562',
        blz: '56270024',
        city: 'Idar-Oberstein',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20228,
        name: 'Raiffeisenbank Heideboden eGen',
        bic: 'RLBBAT2E090',
        blz: '33028',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17943,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5M562',
        blz: '56270044',
        city: 'Idar-Oberstein',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17944,
        name: 'Volksbank-Raiffeisenbank Naheland -alt-',
        bic: 'GENODE51IDO',
        blz: '56290000',
        city: 'Idar-Oberstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17951,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '57040044',
        city: 'Koblenz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20233,
        name: 'Raiffeisenbank Hermagor, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K543',
        blz: '39543',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20234,
        name: 'Raiffeisenbank Herzogenburg-Kapelln eGen',
        bic: 'RLNWATWW769',
        blz: '32769',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20235,
        name: 'Raiffeisenbank Herzogenburg-Kapelln eGen',
        bic: 'RLNWATWW769',
        blz: '32356',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20236,
        name: 'Raiffeisenbank Hinterstoder und Vorderstoder eGen',
        bic: 'RZOOAT2L165',
        blz: '34165',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20237,
        name: 'Raiffeisenbank Hippach und Umgebung eGen',
        bic: 'RZTIAT22241',
        blz: '36241',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20238,
        name: 'Raiffeisenbank Hitzendorf-Rein eGen',
        bic: 'RZSTAT2G138',
        blz: '38138',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20239,
        name: 'Raiffeisenbank Hitzendorf-Rein eGen',
        bic: 'RZSTAT2G138',
        blz: '38333',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20240,
        name: 'Raiffeisenbank Hochkönig eGen',
        bic: 'RVSAAT2S003',
        blz: '35003',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20242,
        name: 'Raiffeisenbank Hof-Koppl-Ebenau eGen',
        bic: 'RVSAAT2S025',
        blz: '35025',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20241,
        name: 'Raiffeisenbank Hochkönig eGen',
        bic: 'RVSAAT2S003',
        blz: '35013',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20243,
        name: 'Raiffeisenbank Hof-Koppl-Ebenau eGen',
        bic: 'RVSAAT2S025',
        blz: '35125',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20310,
        name: 'Raiffeisenbank Lochen am See eGen',
        bic: 'RZOOAT2L290',
        blz: '34290',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20311,
        name: 'Raiffeisenbank Lohnsburg eGen',
        bic: 'RZOOAT2L284',
        blz: '34284',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20298,
        name: 'RAIFFEISENBANK LEIBLACHTAL reg.Gen.m.b.H.',
        bic: 'RVVGAT2B439',
        blz: '37439',
        logoPath: '177.svg',
        available: false
    },
    {
        id: 20256,
        name: 'Raiffeisenbank Ilz-Großsteinbach-Riegersburg eGen',
        bic: 'RZSTAT2G151',
        blz: '38120',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20295,
        name: 'Raiffeisenbank Launsdorf, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K384',
        blz: '39384',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20258,
        name: 'Raiffeisenbank im Mostviertel Aschbach eGen',
        bic: 'RLNWATWWASC',
        blz: '32033',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20259,
        name: 'Raiffeisenbank Im Rheintal eGen',
        bic: 'RVVGAT2B420',
        blz: '37420',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20260,
        name: 'Raiffeisenbank Im Rheintal eGen',
        bic: 'RVVGAT2B420',
        blz: '37453',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20262,
        name: 'Raiffeisenbank im Weinviertel eGen',
        bic: 'RLNWATWWMIB',
        blz: '32501',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20263,
        name: 'Raiffeisenbank Inneres Salzkammergut eGen',
        bic: 'RZOOAT2L545',
        blz: '34545',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20264,
        name: 'Raiffeisenbank Innkreis Mitte eGen',
        bic: 'RZOOAT2L200',
        blz: '34200',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20265,
        name: 'Raiffeisenbank Jenbach - Wiesing eGen',
        bic: 'RZTIAT22249',
        blz: '36249',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20266,
        name: 'Raiffeisenbank Kematen eGen',
        bic: 'RZTIAT22260',
        blz: '36260',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20267,
        name: 'Raiffeisenbank Kematen-Neuhofen eGen',
        bic: 'RZOOAT2L214',
        blz: '34214',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20268,
        name: 'Raiffeisenbank Keutschach-Maria Wörth, e. Gen. m.b.H.',
        bic: 'RZKTAT2K355',
        blz: '39355',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20269,
        name: 'Raiffeisenbank Kirchdorf Tirol reg.Gen.m.b.H.',
        bic: 'RZTIAT22262',
        blz: '36262',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20270,
        name: 'Raiffeisenbank Kitzbühel - St. Johann eGen',
        bic: 'RZTIAT22263',
        blz: '36263',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20271,
        name: 'Raiffeisenbank Kitzbühel - St. Johann eGen',
        bic: 'RZTIAT22263',
        blz: '36254',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20272,
        name: 'Raiffeisenbank Kleinmünchen/Linz eGen',
        bic: 'RZOOAT2L226',
        blz: '34226',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20273,
        name: 'Raiffeisenbank Klosterneuburg eGen',
        bic: 'RLNWATWW367',
        blz: '32367',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20274,
        name: 'Raiffeisenbank Kollerschlag eGen',
        bic: 'RZOOAT2L231',
        blz: '34231',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20275,
        name: 'Raiffeisenbank Königsdorf eGen',
        bic: 'RLBBAT2E041',
        blz: '33041',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20276,
        name: 'Raiffeisenbank Korneuburg eGen',
        bic: 'RLNWATWWKOR',
        blz: '32395',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20277,
        name: 'Raiffeisenbank Kössen-Schwendt eGen',
        bic: 'RZTIAT22264',
        blz: '36264',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20278,
        name: 'Raiffeisenbank Köstendorf-Neumarkt-Schleedorf reg.Gen.m.b.H.',
        bic: 'RVSAAT2S021',
        blz: '35021',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20285,
        name: 'Raiffeisenbank Krimml reg.Gen.m.b.H.',
        bic: 'RVSAAT2S028',
        blz: '35028',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20286,
        name: 'Raiffeisenbank Kundl eGen',
        bic: 'RZTIAT22267',
        blz: '36267',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20287,
        name: 'Raiffeisenbank Laa/Thaya eGen',
        bic: 'RLNWATWWLAA',
        blz: '32413',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20288,
        name: 'Raiffeisenbank Lamprechtshausen-Bürmoos eGen',
        bic: 'RVSAAT2S030',
        blz: '35030',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20289,
        name: 'Raiffeisenbank Lamprechtshausen-Bürmoos eGen',
        bic: 'RVSAAT2S030',
        blz: '35130',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20290,
        name: 'Raiffeisenbank Landskron - Gegendtal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K381',
        blz: '39381',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20291,
        name: 'Raiffeisenbank Längenfeld eGen',
        bic: 'RZTIAT22268',
        blz: '36268',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20293,
        name: 'Raiffeisenbank Langen-Thal reg.Gen.m.b.H.',
        bic: 'RVVGAT2B445',
        blz: '37445',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17956,
        name: 'Raiffeisenbank',
        bic: 'GENODE51NWA',
        blz: '57062675',
        city: 'Niederwallmenach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20294,
        name: 'Raiffeisenbank Langkampfen eGen',
        bic: 'RZTIAT22269',
        blz: '36269',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17957,
        name: 'Volksbank Vallendar-Niederwerth',
        bic: 'GENODED1VAN',
        blz: '57063478',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17958,
        name: 'Volksbank Mülheim-Kärlich',
        bic: 'GENODED1MKA',
        blz: '57064221',
        city: 'Mülheim-Kärlich',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17959,
        name: 'Raiffeisenbank Lutzerather Höhe',
        bic: 'GENODED1LUH',
        blz: '57069067',
        city: 'Lutzerath',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17960,
        name: 'Raiffeisenbank Moselkrampen',
        bic: 'GENODED1MOK',
        blz: '57069081',
        city: 'Ernst',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17961,
        name: 'Raiffeisenbank Eifeltor',
        bic: 'GENODED1KAI',
        blz: '57069144',
        city: 'Kaisersesch',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17962,
        name: 'Raiffeisenbank Neustadt',
        bic: 'GENODED1ASN',
        blz: '57069238',
        city: 'Neustadt (Wied)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17964,
        name: 'Raiffeisenbank Straßenhaus -alt-',
        bic: 'GENODED1SRH',
        blz: '57069315',
        city: 'Straßenhaus',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20255,
        name: 'Raiffeisenbank Ilz-Großsteinbach-Riegersburg eGen',
        bic: 'RZSTAT2G151',
        blz: '38151',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20299,
        name: 'Raiffeisenbank Leibnitz eGen',
        bic: 'RZSTAT2G206',
        blz: '38206',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20297,
        name: 'Raiffeisenbank Lech/Arlberg, reg.Gen.m.b.H.',
        bic: 'RVVGAT2B449',
        blz: '37449',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20305,
        name: 'Raiffeisenbank Liesingtal eGen',
        bic: 'RZSTAT2G227',
        blz: '38227',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20306,
        name: 'Raiffeisenbank Liezen-Rottenmann-Trieben eGen',
        bic: 'RZSTAT2G215',
        blz: '38215',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20300,
        name: 'Raiffeisenbank Leoben-Bruck eGen',
        bic: 'RZSTAT2G460',
        blz: '38460',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20307,
        name: 'Raiffeisenbank Ligist-St.Johann eGen',
        bic: 'RZSTAT2G211',
        blz: '38211',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20308,
        name: 'Raiffeisenbank Lipizzanerheimat eGen',
        bic: 'RZSTAT2G487',
        blz: '38487',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20309,
        name: 'Raiffeisenbank Lipizzanerheimat eGen',
        bic: 'RZSTAT2G487',
        blz: '38053',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20106,
        name: 'Raiffeisenbank Altenmarkt-Flachau-Eben eGen',
        bic: 'RVSAAT2S004',
        blz: '35104',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20164,
        name: 'Raiffeisenbank Eggenburg eGen',
        bic: 'RLNWATWW123',
        blz: '32123',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20226,
        name: 'Raiffeisenbank Hausmannstätten eGen',
        bic: 'RZSTAT2G133',
        blz: '38133',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19166,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF750',
        blz: '75080003',
        city: 'Regensburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19227,
        name: 'Raiffeisenbank Münchaurach -alt-',
        bic: 'GENODEF1AUT',
        blz: '76069549',
        city: 'Aurachtal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19413,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF795',
        blz: '79570051',
        city: 'Aschaffenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19415,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI47',
        blz: '79589402',
        city: 'Aschaffenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19613,
        name: 'Sparkasse Mittleres Erzgebirge',
        bic: 'SOLADES1SME',
        blz: '87053000',
        city: 'Marienberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19546,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '85040000',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19609,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '87040000',
        city: 'Chemnitz',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17679,
        name: 'Volksbank Holzheim -alt-',
        bic: 'GENODE51PLH',
        blz: '51361704',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20336,
        name:
            'Raiffeisenbank Mittersill-Hollersbach-Stuhlfelden reg.Gen.m.b.H.',
        bic: 'RVSAAT2S039',
        blz: '35339',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20338,
        name: 'Raiffeisenbank Mittleres Lavanttal eGen',
        bic: 'RZKTAT2K481',
        blz: '39465',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20339,
        name: 'Raiffeisenbank Mittleres Mölltal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K436',
        blz: '39436',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20340,
        name: 'Raiffeisenbank Mittleres Mostviertel eGen',
        bic: 'RLNWATWW939',
        blz: '32939',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20341,
        name: 'Raiffeisenbank Mittleres Mostviertel eGen',
        bic: 'RLNWATWW939',
        blz: '32651',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20349,
        name: 'Raiffeisenbank Rust-Mörbisch eGen',
        bic: 'RLBBAT2E055',
        blz: '33055',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20350,
        name: 'Raiffeisenbank Mühlviertler Alm eGen',
        bic: 'RZOOAT2L330',
        blz: '34330',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20351,
        name: 'Raiffeisenbank Münster eGen',
        bic: 'RZTIAT22279',
        blz: '36279',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20352,
        name: 'Raiffeisenbank Murau eGen',
        bic: 'RZSTAT2G238',
        blz: '38238',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20353,
        name: 'Raiffeisenbank Mureck eGen',
        bic: 'RZSTAT2G370',
        blz: '38370',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19951,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDK402',
        blz: '37070000',
        city: 'Köln',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 17682,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF513',
        blz: '51370008',
        city: 'Gießen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17683,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB513',
        blz: '51370024',
        city: 'Gießen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17718,
        name: 'Volksbank Feldatal',
        bic: 'GENODE51FEL',
        blz: '51961801',
        city: 'Feldatal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19704,
        name: 'Commerzbank vormals Dresdner Bank Zw 05',
        bic: 'DRESDEFFXXX',
        blz: '30080005',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17680,
        name: 'VR Bank Mücke -alt-',
        bic: 'GENODE51MNO',
        blz: '51362514',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17681,
        name: 'Volksbank Garbenteich -alt-',
        bic: 'GENODE51PMG',
        blz: '51363407',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20292,
        name: 'Raiffeisenbank Langenlois eGen',
        bic: 'RLNWATWW426',
        blz: '32426',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20343,
        name: 'Raiffeisenbank Mittleres Raabtal eGen',
        bic: 'RZSTAT2G075',
        blz: '38171',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17684,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF513',
        blz: '51380040',
        city: 'Gießen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17685,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ18',
        blz: '51380085',
        city: 'Gießen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17686,
        name: 'Volksbank Mittelhessen',
        bic: 'VBMHDE5FXXX',
        blz: '51390000',
        city: 'Gießen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17697,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '51540037',
        city: 'Wetzlar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20864,
        name: 'Oberbank AG (15024)',
        bic: 'OBKLAT2LXXX',
        blz: '15024',
        available: false
    },
    {
        id: 20053,
        name: 'Allgemeine Sparkasse Oberösterreich BankAG',
        bic: 'ASPKAT2LXXX',
        blz: '20320',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19488,
        name: 'Sparkasse Mittelthüringen',
        bic: 'HELADEF1WEM',
        blz: '82051000',
        city: 'Erfurt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17699,
        name: 'Volksbank Wetzlar-Weilburg -alt-',
        bic: 'GENODE51WZ1',
        blz: '51560231',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17700,
        name: 'Deutsche Bank',
        bic: 'DEUTDEFF515',
        blz: '51570008',
        city: 'Wetzlar',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17701,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB515',
        blz: '51570024',
        city: 'Wetzlar',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17702,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF515',
        blz: '51580044',
        city: 'Wetzlar',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17409,
        name: 'Commerzbank Gf ZRK',
        bic: 'COBADEFFXXX',
        blz: '50040040',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17704,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '51640043',
        city: 'Dillenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17410,
        name: 'Commerzbank GF-F48',
        bic: 'COBADEFFXXX',
        blz: '50040048',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17411,
        name: 'Commerzbank, CC SP',
        bic: 'COBADEFFXXX',
        blz: '50040050',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 17412,
        name: 'Commerzbank Center Dresdner Bank Frankfurt',
        bic: 'COBADEFFXXX',
        blz: '50040051',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17413,
        name: 'Commerzbank Service - BZ Frankfurt',
        bic: 'COBADEFFXXX',
        blz: '50040052',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17414,
        name: 'Commerzbank Gf 460',
        bic: 'COBADEFFXXX',
        blz: '50040060',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19949,
        name: 'VR-Bank Werdenfels',
        bic: 'GENODEF1GAP',
        blz: '70390010',
        city: 'Garmisch-Partenkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20354,
        name: 'Raiffeisenbank Mürztal eGen',
        bic: 'RZSTAT2G186',
        blz: '38186',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20355,
        name: 'Raiffeisenbank Mürztal eGen',
        bic: 'RZSTAT2G186',
        blz: '38240',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20342,
        name: 'Raiffeisenbank Mittleres Raabtal eGen',
        bic: 'RZSTAT2G075',
        blz: '38075',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20344,
        name: 'Raiffeisenbank Mittleres Rodltal eGen',
        bic: 'RZOOAT2L383',
        blz: '34383',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20345,
        name: 'Raiffeisenbank Mönchhof eGen',
        bic: 'RLBBAT2E054',
        blz: '33054',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20348,
        name: 'Raiffeisenbank Mooskirchen-Söding eGen',
        bic: 'RZSTAT2G235',
        blz: '38235',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17698,
        name: 'Sparkasse Wetzlar',
        bic: 'HELADEF1WET',
        blz: '51550035',
        city: 'Wetzlar',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20411,
        name: 'Raiffeisenbank Rankweil reg.Gen.m.b.H.',
        bic: 'RVVGAT2B461',
        blz: '37461',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20412,
        name: 'Raiffeisenbank Rauris-Bucheben reg.Gen.m.b.H.',
        bic: 'RVSAAT2S051',
        blz: '35051',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20413,
        name: 'Raiffeisenbank Region Altheim eGen',
        bic: 'RZOOAT2L030',
        blz: '34030',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20414,
        name: 'Raiffeisenbank Region Amstetten eGen',
        bic: 'RLNWATWWAMS',
        blz: '32025',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20415,
        name: 'Raiffeisenbank Region Amstetten eGen',
        bic: 'RLNWATWWAMS',
        blz: '32778',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20416,
        name: 'Raiffeisenbank Region Amstetten eGen',
        bic: 'RLNWATWWAMS',
        blz: '32789',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20417,
        name: 'Raiffeisenbank Region Bad Leonfelden eGen',
        bic: 'RZOOAT2L277',
        blz: '34277',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20418,
        name: 'Raiffeisenbank Region Baden eGen',
        bic: 'RLNWATWWBAD',
        blz: '32045',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20419,
        name: 'Raiffeisenbank Region Baden eGen',
        bic: 'RLNWATWWBAD',
        blz: '32118',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20420,
        name: 'Raiffeisenbank Region Braunau eGen',
        bic: 'RZOOAT2L060',
        blz: '34060',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20421,
        name: 'Raiffeisenbank Region Eferding eGen',
        bic: 'RZOOAT2L180',
        blz: '34180',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20422,
        name: 'Raiffeisenbank Region Fehring eGen',
        bic: 'RZSTAT2G071',
        blz: '38071',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20423,
        name: 'Raiffeisenbank Region Fehring eGen',
        bic: 'RZSTAT2G071',
        blz: '38132',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20424,
        name: 'Raiffeisenbank Region Freistadt eGen',
        bic: 'RZOOAT2L110',
        blz: '34110',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20425,
        name: 'Raiffeisenbank Region Gallneukirchen eGen',
        bic: 'RZOOAT2L111',
        blz: '34111',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20426,
        name: 'Raiffeisenbank Region Gleisdorf eGen',
        bic: 'RZSTAT2G103',
        blz: '38103',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20371,
        name: 'Raiffeisenbank NÖ-Süd Alpin eGen',
        bic: 'RLNWATWWASP',
        blz: '32631',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20398,
        name: 'Raiffeisenbank Pittental/Bucklige Welt eGen',
        bic: 'RLNWATW1647',
        blz: '32647',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20372,
        name: 'Raiffeisenbank Nußdorf eGen',
        bic: 'RVSAAT2S044',
        blz: '35044',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20373,
        name: 'Raiffeisenbank Oberalm-Puch reg.Gen.m.b.H.',
        bic: 'RVSAAT2S045',
        blz: '35045',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20375,
        name: 'Raiffeisenbank Oberdrautal-Weissensee, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K322',
        blz: '39322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20376,
        name: 'Raiffeisenbank Oberes Innviertel eGen',
        bic: 'RZOOAT2L370',
        blz: '34370',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20377,
        name: 'Raiffeisenbank Oberes Lavanttal reg.Gen.m.b.H.',
        bic: 'RZKTAT2K491',
        blz: '39491',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20378,
        name: 'Raiffeisenbank Oberes Mölltal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K561',
        blz: '39561',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20379,
        name: 'Raiffeisenbank Oberes Triestingtal eGen',
        bic: 'RLNWATWWWEB',
        blz: '32930',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20380,
        name: 'Raiffeisenbank Oberes Waldviertel eGen',
        bic: 'RLNWATWWOWS',
        blz: '32415',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20381,
        name: 'Raiffeisenbank Oberes Waldviertel eGen',
        bic: 'RLNWATWWOWS',
        blz: '32936',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20382,
        name: 'Raiffeisenbank Oberland eGen',
        bic: 'RZTIAT22359',
        blz: '36359',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20383,
        name: 'Raiffeisenbank Oberlechtal eGen',
        bic: 'RZTIAT22220',
        blz: '36220',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20384,
        name: 'Raiffeisenbank Öblarn eGen',
        bic: 'RZSTAT2G266',
        blz: '38266',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20385,
        name: 'Raiffeisenbank Ohlsdorf eGen',
        bic: 'RZOOAT2L390',
        blz: '34390',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20386,
        name: 'Raiffeisenbank Ossiacher See, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K510',
        blz: '39510',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20387,
        name: 'Raiffeisenbank Ottenschlag-Martinsberg eGen',
        bic: 'RLNWATW1615',
        blz: '32615',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20388,
        name: 'Raiffeisenbank Ottnang-Wolfsegg eGen',
        bic: 'RZOOAT2L400',
        blz: '34400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20389,
        name: 'Raiffeisenbank Pabneukirchen eGen',
        bic: 'RZOOAT2L420',
        blz: '34420',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20390,
        name: 'Raiffeisenbank Pamhagen eGen',
        bic: 'RLBBAT2E071',
        blz: '33071',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20391,
        name: 'Raiffeisenbank Passail eGen',
        bic: 'RZSTAT2G282',
        blz: '38282',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20392,
        name: 'Raiffeisenbank Paznaun eGen',
        bic: 'RZTIAT22248',
        blz: '36248',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20393,
        name: 'Raiffeisenbank Perg eGen',
        bic: 'RZOOAT2L777',
        blz: '34777',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20394,
        name: 'Raiffeisenbank Peuerbach eGen',
        bic: 'RZOOAT2L442',
        blz: '34442',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20395,
        name: 'Raiffeisenbank Piesendorf reg.Gen.m.b.H.',
        bic: 'RVSAAT2S048',
        blz: '35048',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20397,
        name: 'Raiffeisenbank Pischelsdorf-Stubenberg eGen',
        bic: 'RZSTAT2G285',
        blz: '38285',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20370,
        name: 'Raiffeisenbank NÖ-Süd Alpin eGen',
        bic: 'RLNWATWWASP',
        blz: '32195',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16225,
        name: 'Volksbank Elmshorn',
        bic: 'GENODEF1ELM',
        blz: '22190030',
        city: 'Elmshorn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20399,
        name: 'Raiffeisenbank Pitztal eGen',
        bic: 'RZTIAT22353',
        blz: '36353',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20400,
        name: 'Raiffeisenbank Pöllau-Birkfeld eGen',
        bic: 'RZSTAT2G023',
        blz: '38023',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20401,
        name: 'Raiffeisenbank Pöllau-Birkfeld eGen',
        bic: 'RZSTAT2G023',
        blz: '38311',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20402,
        name: 'Raiffeisenbank Pöndorf - Frankenmarkt eGen',
        bic: 'RZOOAT2L434',
        blz: '34434',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20403,
        name: 'Raiffeisenbank Prambachkirchen eGen',
        bic: 'RZOOAT2L437',
        blz: '34437',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20404,
        name: 'Raiffeisenbank Pramet eGen',
        bic: 'RZOOAT2L438',
        blz: '34438',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20405,
        name: 'Raiffeisenbank Preding-Hengsberg-St. Nikolai i. S. eGen',
        bic: 'RZSTAT2G292',
        blz: '38292',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20406,
        name: 'Raiffeisenbank Purbach eGen',
        bic: 'RLBBAT2E078',
        blz: '33078',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20408,
        name:
            'Raiffeisenbank Radstadt-Untertauern-Filzmoos-Forstau reg.Gen.m.b.H.',
        bic: 'RVSAAT2S049',
        blz: '35049',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20409,
        name:
            'Raiffeisenbank Radstadt-Untertauern-Filzmoos-Forstau reg.Gen.m.b.H.',
        bic: 'RVSAAT2S049',
        blz: '35149',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20410,
        name:
            'Raiffeisenbank Radstadt-Untertauern-Filzmoos-Forstau reg.Gen.m.b.H.',
        bic: 'RVSAAT2S049',
        blz: '35249',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20481,
        name: 'Raiffeisenbank Schladming-Ramsau-Haus eGen',
        bic: 'RZSTAT2G481',
        blz: '38481',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20483,
        name: 'Raiffeisenbank Seefeld-Hadres eGen',
        bic: 'RLNWATW1275',
        blz: '32275',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20601,
        name: 'Raiffeisenbank Zirbenland eGen',
        bic: 'RZSTAT2G368',
        blz: '38368',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20430,
        name: 'Raiffeisenbank Region Grieskirchen eGen',
        bic: 'RZOOAT2L736',
        blz: '34736',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20438,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34481',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20456,
        name: 'Raiffeisenbank Region Wagram eGen',
        bic: 'RLNWATW1002',
        blz: '32002',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20458,
        name: 'Raiffeisenbank Region Waldviertel Mitte eGen',
        bic: 'RLNWATWWZWE',
        blz: '32990',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20439,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34606',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20440,
        name: 'Raiffeisenbank Region Neufelden eGen',
        bic: 'RZOOAT2L300',
        blz: '34300',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20441,
        name: 'Raiffeisenbank Region Parndorf eGen',
        bic: 'RLBBAT2E072',
        blz: '33072',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20442,
        name: 'Raiffeisenbank Region Pregarten eGen',
        bic: 'RZOOAT2L460',
        blz: '34460',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20443,
        name: 'Raiffeisenbank Region Ried i.I. eGen',
        bic: 'RZOOAT2L450',
        blz: '34450',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20444,
        name: 'Raiffeisenbank Region Rohrbach eGen',
        bic: 'RZOOAT2L410',
        blz: '34410',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20445,
        name: 'Raiffeisenbank Region Schallaburg eGen',
        bic: 'RLNWATW1477',
        blz: '32477',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20446,
        name: 'Raiffeisenbank Region Schallaburg eGen',
        bic: 'RLNWATW1477',
        blz: '32368',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20447,
        name: 'Raiffeisenbank Region Schallaburg eGen',
        bic: 'RLNWATW1477',
        blz: '32455',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20448,
        name: 'Raiffeisenbank Region Schallaburg eGen',
        bic: 'RLNWATW1477',
        blz: '32679',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20449,
        name: 'Raiffeisenbank Region Schärding eGen',
        bic: 'RZOOAT2L455',
        blz: '34455',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20450,
        name: 'Raiffeisenbank Region Schwanenstadt eGen',
        bic: 'RZOOAT2L630',
        blz: '34630',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20451,
        name: 'Raiffeisenbank Region Schwechat eGen',
        bic: 'RLNWATWW823',
        blz: '32823',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20452,
        name: 'Raiffeisenbank Region Sierning-Enns eGen',
        bic: 'RZOOAT2L560',
        blz: '34560',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20453,
        name: 'Raiffeisenbank Region Sierning-Enns eGen',
        bic: 'RZOOAT2L560',
        blz: '34157',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20455,
        name: 'Raiffeisenbank Region Vöcklabruck eGen',
        bic: 'RZOOAT2L710',
        blz: '34710',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20457,
        name: 'Raiffeisenbank Region Wagram eGen',
        bic: 'RLNWATW1002',
        blz: '32291',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20428,
        name: 'Raiffeisenbank Region Graz-Thalerhof eGen',
        bic: 'RZSTAT2G477',
        blz: '38477',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20429,
        name: 'Raiffeisenbank Region Graz-Thalerhof eGen',
        bic: 'RZSTAT2G477',
        blz: '38072',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16226,
        name: 'Volksbank Pinneberg-Elmshorn',
        bic: 'GENODEF1PIN',
        blz: '22191405',
        city: 'Pinneberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20459,
        name: 'Raiffeisenbank Reichenau eGen',
        bic: 'RZOOAT2L475',
        blz: '34475',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20461,
        name: 'Raiffeisenbank Reith im Alpbachtal eGen',
        bic: 'RZTIAT22303',
        blz: '36303',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20460,
        name: 'Raiffeisenbank Reichenau-Gnesau, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K461',
        blz: '39461',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20462,
        name: 'Raiffeisenbank Reutte reg.Gen.m.b.H.',
        bic: 'RBRTAT22XXX',
        blz: '36990',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20463,
        name: 'Raiffeisenbank Reutte reg.Gen.m.b.H.',
        bic: 'RBRTAT22XXX',
        blz: '36305',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20466,
        name: 'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
        bic: 'RVSAAT2S052',
        blz: '35052',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20465,
        name: 'Raiffeisenbank Rosental, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K487',
        blz: '39487',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20467,
        name: 'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
        bic: 'RVSAAT2S052',
        blz: '35152',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20468,
        name: 'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
        bic: 'RVSAAT2S052',
        blz: '35352',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20470,
        name: 'Raiffeisenbank Saalfelden reg.Gen.m.b.H.',
        bic: 'RVSAAT2S053',
        blz: '35053',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20469,
        name: 'Raiffeisenbank Saalbach-Hinterglemm-Viehhofen eGen',
        bic: 'RVSAAT2S052',
        blz: '35452',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20471,
        name: 'Raiffeisenbank Salzburg Maxglan-Siezenheim eGen',
        bic: 'RVSAAT2S054',
        blz: '35054',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20472,
        name: 'Raiffeisenbank Salzburg Maxglan-Siezenheim eGen',
        bic: 'RVSAAT2S054',
        blz: '35154',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20474,
        name: 'Raiffeisenbank Salzburger Seenland eGen',
        bic: 'RVSAAT2S047',
        blz: '35009',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20473,
        name: 'Raiffeisenbank Salzburger Seenland eGen',
        bic: 'RVSAAT2S047',
        blz: '35047',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20475,
        name: 'Raiffeisenbank Salzburger Seenland eGen',
        bic: 'RVSAAT2S047',
        blz: '35075',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20476,
        name: 'Raiffeisenbank Salzburger Seenland eGen',
        bic: 'RVSAAT2S047',
        blz: '35109',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20478,
        name: 'Raiffeisenbank Salzburg-Liefering eGen',
        bic: 'RVSAAT2S034',
        blz: '35034',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20477,
        name: 'Raiffeisenbank Salzburger Seenland eGen',
        bic: 'RVSAAT2S047',
        blz: '35147',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20479,
        name: 'Raiffeisenbank Salzkammergut eGen',
        bic: 'RZOOAT2L510',
        blz: '34510',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20480,
        name: 'Raiffeisenbank Schilcherland eGen',
        bic: 'RZSTAT2G375',
        blz: '38375',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20482,
        name: 'Raiffeisenbank Schwertberg eGen',
        bic: 'RZOOAT2L613',
        blz: '34613',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20525,
        name: 'Raiffeisenbank St.Michael - Oberlungau eGen',
        bic: 'RVSAAT2S061',
        blz: '35261',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20526,
        name: 'Raiffeisenbank St.Michael - Oberlungau eGen',
        bic: 'RVSAAT2S061',
        blz: '35361',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20527,
        name: 'Raiffeisenbank Stockerau eGen',
        bic: 'RLNWATWWSTO',
        blz: '32842',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20528,
        name: 'Raiffeisenbank Straden eGen',
        bic: 'RZSTAT2G436',
        blz: '38436',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20529,
        name: 'Raiffeisenbank Straß-Spielfeld eGen',
        bic: 'RZSTAT2G420',
        blz: '38420',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20530,
        name: 'Raiffeisenbank Straßwalchen eGen',
        bic: 'RVSAAT2S062',
        blz: '35062',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20531,
        name: 'Raiffeisenbank St.Stefan-Kraubath eGen',
        bic: 'RZSTAT2G373',
        blz: '38373',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20532,
        name: 'Raiffeisenbank Süd-Weststeiermark eGen',
        bic: 'RZSTAT2G056',
        blz: '38056',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20533,
        name: 'Raiffeisenbank Süd-Weststeiermark eGen',
        bic: 'RZSTAT2G056',
        blz: '38407',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20534,
        name: 'Raiffeisenbank Süd-Weststeiermark eGen',
        bic: 'RZSTAT2G056',
        blz: '38503',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20535,
        name: 'Raiffeisenbank Lungau eGen',
        bic: 'RVSAAT2S063',
        blz: '35063',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20536,
        name: 'Raiffeisenbank Tamsweg eGen',
        bic: 'RVSAAT2S063',
        blz: '35050',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20537,
        name: 'Raiffeisenbank Tamsweg eGen',
        bic: 'RVSAAT2S063',
        blz: '35163',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20538,
        name: 'Raiffeisenbank Tamsweg eGen',
        bic: 'RVSAAT2S063',
        blz: '35263',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20539,
        name: 'Raiffeisenbank Tannheimertal eGen',
        bic: 'RZTIAT22333',
        blz: '36333',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20540,
        name: 'Raiffeisenbank Tarsdorf eGen',
        bic: 'RZOOAT2L661',
        blz: '34661',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20486,
        name: 'Raiffeisenbank Seewinkel-Hansag eGen',
        bic: 'RLBBAT2E002',
        blz: '33104',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20513,
        name: 'Raiffeisenbank Steinbach-Grünburg eGen',
        bic: 'RZOOAT2L633',
        blz: '34633',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20487,
        name: 'Raiffeisenbank Seewinkel-Hansag eGen',
        bic: 'RLBBAT2E002',
        blz: '33118',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20488,
        name: 'Raiffeisenbank Serfaus-Fiss eGen',
        bic: 'RZTIAT22315',
        blz: '36315',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20489,
        name: 'Raiffeisenbank Sillian eGen',
        bic: 'RZTIAT22368',
        blz: '36368',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20490,
        name: 'Raiffeisenbank Sillian eGen',
        bic: 'RZTIAT22368',
        blz: '36374',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20491,
        name: 'Raiffeisenbank Sillian eGen',
        bic: 'RZTIAT22368',
        blz: '36387',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20492,
        name: 'Raiffeisenbank Silz-Haiming und Umgebung eGen',
        bic: 'RZTIAT22316',
        blz: '36316',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20493,
        name:
            'Raiffeisenbank Sirnitz-Himmelberg-Deutsch-Griffen, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K254',
        blz: '39254',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20494,
        name: 'Raiffeisenbank Sölden eGen',
        bic: 'RZTIAT22324',
        blz: '36324',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20495,
        name: 'Raiffeisenbank Söll - Scheffau reg.Gen.m.b.H.',
        bic: 'RZTIAT22318',
        blz: '36318',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20496,
        name: 'Raiffeisenbank St. Agatha eGen',
        bic: 'RZOOAT2L521',
        blz: '34521',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20497,
        name: 'Raiffeisenbank St. Anton am Arlberg eGen',
        bic: 'RZTIAT22252',
        blz: '36252',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20498,
        name: 'Raiffeisenbank St. Georgen a. d. Stiefing eGen',
        bic: 'RZSTAT2G335',
        blz: '38335',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20499,
        name: 'Raiffeisenbank St. Johann im Pongau reg.Gen.m.b.H.',
        bic: 'RVSAAT2S057',
        blz: '35057',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20500,
        name: 'Raiffeisenbank St. Koloman reg.Gen.m.b.H.',
        bic: 'RVSAAT2S058',
        blz: '35058',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20501,
        name: 'Raiffeisenbank St. Marien eGen',
        bic: 'RZOOAT2L530',
        blz: '34530',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20502,
        name: 'Raiffeisenbank St. Martin i.M.-Kleinzell eGen',
        bic: 'RZOOAT2L535',
        blz: '34535',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20503,
        name: 'Raiffeisenbank St. Martin-Lofer-Weißbach eGen',
        bic: 'RVSAAT2S060',
        blz: '35060',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20504,
        name: 'Raiffeisenbank St. Martin-Lofer-Weißbach eGen',
        bic: 'RVSAAT2S060',
        blz: '35160',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20506,
        name: 'Raiffeisenbank St. Roman eGen',
        bic: 'RZOOAT2L540',
        blz: '34540',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20507,
        name: 'Raiffeisenbank St. Stefan-Jagerberg-Wolfsberg eGen',
        bic: 'RZSTAT2G374',
        blz: '38374',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20508,
        name: 'RaiffeisenBank St. Ulrich - Waidring eGen',
        bic: 'RZTIAT22349',
        blz: '36349',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20509,
        name: 'Raiffeisenbank St. Veit-Schwarzach-Goldegg reg.Gen.m.b.H.',
        bic: 'RVSAAT2S055',
        blz: '35055',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20510,
        name: 'Raiffeisenbank St. Veit-Schwarzach-Goldegg reg.Gen.m.b.H.',
        bic: 'RVSAAT2S055',
        blz: '35155',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20512,
        name: 'Raiffeisenbank Stallhofen eGen',
        bic: 'RZSTAT2G433',
        blz: '38433',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20485,
        name: 'Raiffeisenbank Seewinkel-Hansag eGen',
        bic: 'RLBBAT2E002',
        blz: '33002',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16171,
        name: 'Nord-Ostsee Sparkasse',
        bic: 'NOLADE21FLS',
        blz: '21550050',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20514,
        name: 'Raiffeisenbank Steirisches Salzkammergut-Öblarn eGen',
        bic: 'RZSTAT2G249',
        blz: '38249',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20515,
        name: 'Raiffeisenbank Steyr eGen',
        bic: 'RZOOAT2L114',
        blz: '34114',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20516,
        name: 'Raiffeisenbank Steyr eGen',
        bic: 'RZOOAT2L114',
        blz: '34543',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20517,
        name: 'Raiffeisenbank Steyregg eGen',
        bic: 'RZOOAT2L637',
        blz: '34637',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20518,
        name: 'Raiffeisenbank St.Florian am Inn eGen',
        bic: 'RZOOAT2L522',
        blz: '34522',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20519,
        name: 'Raiffeisenbank St.Georgen eGen',
        bic: 'RVSAAT2S046',
        blz: '35046',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20520,
        name: 'Raiffeisenbank St.Gilgen-Fuschl-Strobl eGen',
        bic: 'RVSAAT2S056',
        blz: '35056',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20521,
        name: 'Raiffeisenbank St.Gilgen-Fuschl-Strobl eGen',
        bic: 'RVSAAT2S056',
        blz: '35156',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20522,
        name: 'Raiffeisenbank St.Gilgen-Fuschl-Strobl eGen',
        bic: 'RVSAAT2S056',
        blz: '35256',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20523,
        name: 'Raiffeisenbank St.Michael - Oberlungau eGen',
        bic: 'RVSAAT2S061',
        blz: '35061',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20524,
        name: 'Raiffeisenbank St.Michael - Oberlungau eGen',
        bic: 'RVSAAT2S061',
        blz: '35161',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20584,
        name: 'Raiffeisenbank Weiz-Anger eGen',
        bic: 'RZSTAT2G187',
        blz: '38010',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20585,
        name: 'Raiffeisenbank Wels eGen',
        bic: 'RZOOAT2L680',
        blz: '34680',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20586,
        name: 'Raiffeisenbank Wels eGen',
        bic: 'RZOOAT2L680',
        blz: '34234',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20587,
        name: 'Raiffeisenbank Wels Süd eGen',
        bic: 'RZOOAT2L770',
        blz: '34770',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20574,
        name: 'RAIFFEISENBANK im Walgau eGen',
        bic: 'RVVGAT2B458',
        blz: '37458',
        logoPath: '177.svg',
        available: false
    },
    {
        id: 20589,
        name: 'Raiffeisenbank Westendorf eGen',
        bic: 'RZTIAT22354',
        blz: '36354',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20590,
        name: 'Raiffeisenbank Westliches Mittelgebirge eGen',
        bic: 'RZTIAT22209',
        blz: '36209',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20591,
        name: 'Raiffeisenbank Weyer eGen',
        bic: 'RZOOAT2L747',
        blz: '34747',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20592,
        name: 'Raiffeisenbank Wienerwald eGen',
        bic: 'RLNWATWWPRB',
        blz: '32667',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20593,
        name: 'Raiffeisenbank Wienerwald eGen',
        bic: 'RLNWATWWPRB',
        blz: '32414',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20594,
        name: 'Raiffeisenbank Wildon-Preding eGen',
        bic: 'RZSTAT2G499',
        blz: '38499',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20595,
        name: 'Raiffeisenbank Wildschönau eGen',
        bic: 'RZTIAT22357',
        blz: '36357',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20596,
        name: 'Raiffeisenbank Windischgarsten eGen',
        bic: 'RZOOAT2L491',
        blz: '34491',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20597,
        name: 'Raiffeisenbank Wipptal eGen',
        bic: 'RZTIAT22329',
        blz: '36329',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20598,
        name: 'Raiffeisenbank Wolkersdorf eGen',
        bic: 'RLNWATWWWDF',
        blz: '32951',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20599,
        name: 'Raiffeisenbank Ybbstal eGen',
        bic: 'RLNWATWWWHY',
        blz: '32906',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20600,
        name: 'Raiffeisenbank Zell am Ziller und Umgebung eGen',
        bic: 'RZTIAT22360',
        blz: '36360',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20543,
        name: 'Raiffeisenbank Telfs-Mieming eGen',
        bic: 'RZTIAT22336',
        blz: '36336',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20570,
        name: 'Raiffeisenbank Wald reg.Gen.m.b.H.',
        bic: 'RVSAAT2S070',
        blz: '35070',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20544,
        name: 'Raiffeisenbank Telfs-Mieming eGen',
        bic: 'RZTIAT22336',
        blz: '36276',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20545,
        name: 'Raiffeisenbank Thalgau eGen',
        bic: 'RVSAAT2S065',
        blz: '35065',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20546,
        name: 'Raiffeisenbank Thaur eGen',
        bic: 'RZTIAT22337',
        blz: '36337',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20547,
        name: 'Raiffeisenbank Thayatal-Mitte eGen',
        bic: 'RLNWATW1127',
        blz: '32127',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20548,
        name: 'Raiffeisenbank Thermenland eGen',
        bic: 'RZSTAT2G411',
        blz: '38411',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20549,
        name: 'Raiffeisenbank Thiersee eGen',
        bic: 'RZTIAT22339',
        blz: '36339',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20551,
        name: 'Raiffeisenbank Traisen-Gölsental eGen',
        bic: 'RLNWATWWLFD',
        blz: '32447',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20552,
        name: 'Raiffeisenbank Traisen-Gölsental eGen',
        bic: 'RLNWATWWLFD',
        blz: '32944',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20553,
        name: 'Raiffeisenbank Trieben eGen',
        bic: 'RZSTAT2G348',
        blz: '38348',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20554,
        name: 'Raiffeisenbank Tulln eGen',
        bic: 'RLNWATW1880',
        blz: '32880',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20555,
        name: 'Raiffeisenbank Turnau-St. Lorenzen eGen',
        bic: 'RZSTAT2G347',
        blz: '38347',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20556,
        name: 'Raiffeisenbank Turnau-St. Lorenzen eGen',
        bic: 'RZSTAT2G347',
        blz: '38462',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20557,
        name: 'Raiffeisenbank Tux eGen',
        bic: 'RZTIAT22342',
        blz: '36342',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20558,
        name: 'Raiffeisenbank Unken eGen',
        bic: 'RVSAAT2S066',
        blz: '35066',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20559,
        name: 'Raiffeisenbank Uttendorf eGen',
        bic: 'RVSAAT2S068',
        blz: '35068',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20560,
        name: 'Raiffeisenbank Region Wörthersee eG',
        bic: 'RZKTAT2K390',
        blz: '39390',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20561,
        name: 'Raiffeisenbank Vils und Umgebung eGen',
        bic: 'RZTIAT22345',
        blz: '36345',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20562,
        name: 'Raiffeisenbank Vitis eGen',
        bic: 'RLNWATW1901',
        blz: '32901',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20563,
        name: 'Raiffeisenbank Völkermarkt, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K546',
        blz: '39546',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20564,
        name: 'Raiffeisenbank Vorderbregenzerwald reg.Gen.m.b.H.',
        bic: 'RVVGAT2B435',
        blz: '37435',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20565,
        name: 'Raiffeisenbank Vorderes Ötztal eGen',
        bic: 'RZTIAT22291',
        blz: '36291',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20566,
        name: 'Raiffeisenbank Vorderland reg.Gen.m.b.H.',
        bic: 'RVVGAT2B475',
        blz: '37475',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20567,
        name: 'Raiffeisenbank Wagrain-Kleinarl eGen',
        bic: 'RVSAAT2S069',
        blz: '35069',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20569,
        name: 'Raiffeisenbank Waidhofen a. d. Thaya eGen',
        bic: 'RLNWATWWWTH',
        blz: '32904',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20542,
        name: 'Raiffeisenbank Taxenbach reg.Gen.m.b.H.',
        bic: 'RVSAAT2S064',
        blz: '35164',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16172,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1HDW',
        blz: '21565316',
        city: 'Handewitt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20575,
        name: 'Raiffeisenbank Wals-Himmelreich reg.Gen.m.b.H.',
        bic: 'RVSAAT2S071',
        blz: '35071',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20571,
        name: 'Raiffeisenbank Wald reg.Gen.m.b.H.',
        bic: 'RVSAAT2S070',
        blz: '35170',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20572,
        name: 'Raiffeisenbank Walding-Ottensheim eGen',
        bic: 'RZOOAT2L732',
        blz: '34732',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20573,
        name: 'Raiffeisenbank Waldzell eGen',
        bic: 'RZOOAT2L735',
        blz: '34735',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20576,
        name: 'Raiffeisenbank Wals-Himmelreich reg.Gen.m.b.H.',
        bic: 'RVSAAT2S071',
        blz: '35371',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20577,
        name: 'Raiffeisenbank Wartberg an der Krems eGen',
        bic: 'RZOOAT2L738',
        blz: '34738',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20578,
        name: 'Raiffeisenbank Wattens und Umgebung, reg.Gen.m.b.H.',
        bic: 'RZTIAT22351',
        blz: '36351',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20579,
        name: 'Raiffeisenbank Wechselland eGen',
        bic: 'RZSTAT2G041',
        blz: '38041',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20580,
        name: 'Raiffeisenbank Weiden am See eGen',
        bic: 'RLBBAT2E106',
        blz: '33106',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20581,
        name: 'Raiffeisenbank Weißkirchen a.d. Traun eGen',
        bic: 'RZOOAT2L741',
        blz: '34741',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20582,
        name: 'Raiffeisenbank Weissachtal eGen',
        bic: 'RVVGAT2B474',
        blz: '37474',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20583,
        name: 'Raiffeisenbank Weiz-Anger eGen',
        bic: 'RZSTAT2G187',
        blz: '38187',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20618,
        name: 'Raiffeisenkasse Großweikersdorf-Wiesendorf-Ruppersthal eGen',
        bic: 'RLNWATW1242',
        blz: '32242',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20622,
        name: 'Raiffeisenkasse Hart eGen',
        bic: 'RZTIAT22240',
        blz: '36240',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20623,
        name: 'Raiffeisenkasse Heiligeneich eGen',
        bic: 'RLNWATW1286',
        blz: '32286',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20624,
        name: 'Raiffeisenkasse Kirchschlag in der Buckligen Welt eGen',
        bic: 'RLNWATWWKIG',
        blz: '32374',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20625,
        name: 'Raiffeisenkasse Kuchl reg.Gen.m.b.H.',
        bic: 'RVSAAT2S029',
        blz: '35029',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20626,
        name: 'Raiffeisenkasse Lienzer Talboden eGen',
        bic: 'RZTIAT22373',
        blz: '36373',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20627,
        name: 'Raiffeisenkasse Mieders-Schönberg reg.Gen.m.b.H.',
        bic: 'RZTIAT22275',
        blz: '36275',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20628,
        name: 'Raiffeisenkasse Neusiedl a.d.Zaya eGen',
        bic: 'RLNWATWWNSD',
        blz: '32551',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20629,
        name: 'Raiffeisenkasse Orth a.d. Donau eGen',
        bic: 'RLNWATWWODO',
        blz: '32614',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20631,
        name: 'Raiffeisenkasse Poysdorf eGen',
        bic: 'RLNWATWWPOY',
        blz: '32663',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20649,
        name: 'RAIFFEISENLANDESBANK NIEDERÖSTERREICH-WIEN AG',
        bic: 'RLNWATWWXXX',
        blz: '32000',
        available: false
    },
    {
        id: 20603,
        name: 'Raiffeisenbezirksbank Güssing eGen',
        bic: 'RLBBAT2E027',
        blz: '33027',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16173,
        name: 'Volks- und Raiffeisenbank',
        bic: 'GENODEF1SBR',
        blz: '21566356',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16174,
        name: 'Raiffeisenbank Kleinjörl -alt-',
        bic: 'GENODEF1KJO',
        blz: '21567360',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20635,
        name: 'Raiffeisenkasse Stumm, Stummerberg und Umgebung reg.Gen.m.b.H.',
        bic: 'RZTIAT22332',
        blz: '36332',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20636,
        name: 'Raiffeisenkasse Villgratental reg.Gen.m.b.H.',
        bic: 'RZTIAT22386',
        blz: '36386',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20646,
        name: 'Raiffeisenlandesbank Burgenland und Revisionsverband eGen',
        bic: 'RLBBAT2EXXX',
        blz: '33035',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20637,
        name: 'Raiffeisenkasse Volders und Umgebung reg.Gen.m.b.H.',
        bic: 'RZTIAT22347',
        blz: '36347',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20647,
        name: 'Raiffeisenlandesbank Burgenland und Revisionsverband eGen',
        bic: 'RLBBAT2EXXX',
        blz: '33135',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20648,
        name:
            'Raiffeisenlandesbank Kärnten - Rechenzentrum und Revisionsverband, reg.Gen.m.b.H.',
        bic: 'RZKTAT2KXXX',
        blz: '39000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20638,
        name: 'Raiffeisenkasse Weerberg reg.Gen.m.b.H.',
        bic: 'RZTIAT22352',
        blz: '36352',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20650,
        name: 'Raiffeisenlandesbank Oberösterreich AG',
        bic: 'RZOOAT2LXXX',
        blz: '34000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20651,
        name: 'Raiffeisenlandesbank Oberösterreich AG',
        bic: 'RZOOAT2LXXX',
        blz: '34795',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20652,
        name: 'Raiffeisenlandesbank Oberösterreich AG',
        bic: 'RZOOAT2LXXX',
        blz: '34796',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20656,
        name: 'Raiffeisen-Landesbank Steiermark AG',
        bic: 'RZSTAT2GXXX',
        blz: '38000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20654,
        name: 'Raiffeisenlandesbank Oberösterreich AG',
        bic: 'RZOOAT2LXXX',
        blz: '34921',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20657,
        name: 'Raiffeisen-Landesbank Tirol AG',
        bic: 'RZTIAT22XXX',
        blz: '36000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20655,
        name: 'Raiffeisenlandesbank Oberösterreich AG',
        bic: 'RZOOAT2LXXX',
        blz: '34922',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20632,
        name: 'Raiffeisenkasse Retz-Pulkautal reg.Gen.m.b.H.',
        bic: 'RLNWATW1715',
        blz: '32715',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20653,
        name: 'Raiffeisenlandesbank Oberösterreich AG',
        bic: 'RZOOAT2LXXX',
        blz: '34920',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20633,
        name: 'Raiffeisenkasse Rum-Innsbruck/Arzl eGen',
        bic: 'RZTIAT22310',
        blz: '36310',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20634,
        name: 'Raiffeisenkasse Schlitters, Bruck und Straß reg.Gen.m.b.H.',
        bic: 'RZTIAT22320',
        blz: '36320',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20604,
        name: 'Raiffeisenbezirksbank Jennersdorf eGen',
        bic: 'RLBBAT2E034',
        blz: '33034',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20605,
        name: 'Raiffeisen-Bezirksbank Klagenfurt, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K358',
        blz: '39358',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20608,
        name: 'Raiffeisenbezirksbank Oberpullendorf eGen',
        bic: 'RLBBAT2E065',
        blz: '33046',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20606,
        name: 'Raiffeisenbezirksbank Mattersburg eGen',
        bic: 'RLBBAT2E124',
        blz: '33124',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20609,
        name: 'Raiffeisenbezirksbank Oberwart eGen',
        bic: 'RLBBAT2E125',
        blz: '33125',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20610,
        name: 'Raiffeisenbezirksbank Oberwart eGen',
        bic: 'RLBBAT2E125',
        blz: '33067',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20607,
        name: 'Raiffeisenbank Burgenland Mitte eGen',
        bic: 'RLBBAT2E065',
        blz: '33065',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20613,
        name:
            'Raiffeisen-Bezirksbank St. Veit a.d. Glan-Feldkirchen, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K475',
        blz: '39505',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20614,
        name: 'Raiffeisenkasse Blindenmarkt eGen',
        bic: 'RLNWATW1059',
        blz: '32059',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20615,
        name: 'Raiffeisenkasse Dobersberg-Waldkirchen eGen',
        bic: 'RLNWATW1099',
        blz: '32099',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20616,
        name: 'Raiffeisenkasse Ernstbrunn eGen',
        bic: 'RLNWATW1145',
        blz: '32145',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20619,
        name: 'Raiffeisenkasse Günselsdorf eGen',
        bic: 'RLNWATWWGUE',
        blz: '32247',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20617,
        name: 'Raiffeisenkasse für Mutters, Natters und Kreith reg.Gen.m.b.H.',
        bic: 'RZTIAT22281',
        blz: '36281',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20620,
        name: 'Raiffeisenkasse Haidershofen eGen',
        bic: 'RLNWATWW278',
        blz: '32278',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20621,
        name: 'Raiffeisenkasse Hallwang eGen',
        bic: 'RVSAAT2S023',
        blz: '35023',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20668,
        name: 'Sparkasse Baden',
        bic: 'SPBDAT21XXX',
        blz: '20225',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20669,
        name: 'Sparkasse Bludenz Bank AG',
        bic: 'SSBLAT21XXX',
        blz: '20607',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20706,
        name: 'Volksbank Almtal e. Gen.',
        bic: 'AVOSAT21XXX',
        blz: '45330',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20707,
        name: 'Volksbank Bad Goisern e. Gen.',
        bic: 'VBGOAT21XXX',
        blz: '40720',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20708,
        name: 'Volksbank Bad Hall e.Gen.',
        bic: 'VBOEATWWBHA',
        blz: '43180',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20709,
        name: 'Volksbank Kärnten eG',
        bic: 'VBOEATWWKLA',
        blz: '42130',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20710,
        name: 'Volksbank Kärnten eG',
        bic: 'VBOEATWWKLA',
        blz: '42600',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20711,
        name: 'Volksbank Kärnten eG',
        bic: 'VBOEATWWKLA',
        blz: '42820',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20712,
        name: 'Volksbank Kärnten eG',
        bic: 'VBOEATWWKLA',
        blz: '46900',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20713,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '47150',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20715,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '40630',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20716,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '40850',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20717,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '41050',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20718,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '41210',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20719,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '43530',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20659,
        name: 'Raiffeisen-Leasing Bank AG',
        bic: 'RLBAATW1XXX',
        blz: '31600',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20661,
        name: 'Raiffeisenregionalbank Wiener Neustadt eGen',
        bic: 'RLNWATWWWRN',
        blz: '32937',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20662,
        name: 'Raiffeisenverband Salzburg eGen',
        bic: 'RVSAAT2SXXX',
        blz: '35000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20670,
        name: 'Sparkasse Bregenz Bank AG',
        bic: 'SPBRAT2BXXX',
        blz: '20601',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20671,
        name: 'Sparkasse der Gemeinde Egg',
        bic: 'SPEGAT21XXX',
        blz: '20603',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20672,
        name: 'Sparkasse der Stadt Amstetten AG',
        bic: 'SPAMAT21XXX',
        blz: '20202',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20673,
        name: 'Sparkasse der Stadt Feldkirch',
        bic: 'SPFKAT2BXXX',
        blz: '20604',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20674,
        name: 'Sparkasse der Stadt Kitzbühel',
        bic: 'SPKIAT2KXXX',
        blz: '20505',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20676,
        name: 'Sparkasse Feldkirchen/Kärnten',
        bic: 'SPFNAT21XXX',
        blz: '20702',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20679,
        name: 'Sparkasse Haugsdorf',
        bic: 'SPHAAT21XXX',
        blz: '20218',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20680,
        name: 'Sparkasse Herzogenburg-Neulengbach Bank AG',
        bic: 'SPHEAT21XXX',
        blz: '20219',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20681,
        name: 'Sparkasse Horn-Ravelsbach-Kirchberg AG',
        bic: 'SPHNAT21XXX',
        blz: '20221',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20856,
        name: 'Oberbank AG (15009)',
        bic: 'OBKLAT2LXXX',
        blz: '15009',
        available: false
    },
    {
        id: 20682,
        name: 'Sparkasse Imst AG',
        bic: 'SPIMAT21XXX',
        blz: '20502',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20683,
        name: 'Sparkasse Korneuburg AG',
        bic: 'SSKOAT21XXX',
        blz: '20227',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20684,
        name: 'Sparkasse Kufstein Tiroler Sparkasse von 1877',
        bic: 'SPKUAT22XXX',
        blz: '20506',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20663,
        name: 'SALZBURGER LANDES-HYPOTHEKENBANK AG',
        bic: 'SLHYAT2SXXX',
        blz: '55000',
        available: false
    },
    {
        id: 20665,
        name: 'SPARDA-BANK AUSTRIA eGen',
        bic: 'SVIEAT21XXX',
        blz: '46660',
        logoPath: '137.svg',
        available: false
    },
    {
        id: 20685,
        name: 'Sparkasse Lambach Bank AG',
        bic: 'SPLAAT21XXX',
        blz: '20317',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20686,
        name: 'Sparkasse Langenlois',
        bic: 'SPLSAT21XXX',
        blz: '20230',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20687,
        name: 'Sparkasse Mittersill Bank AG',
        bic: 'SPMIAT21XXX',
        blz: '20402',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20688,
        name: 'Sparkasse Mühlviertel-West Bank AG',
        bic: 'SMWRAT21XXX',
        blz: '20334',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20690,
        name: 'Sparkasse Neuhofen Bank AG',
        bic: 'SPNKAT21XXX',
        blz: '20326',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20677,
        name: 'SPARKASSE FRANKENMARKT AG',
        bic: 'SPFRAT21XXX',
        blz: '20306',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20667,
        name: 'Sparkasse Baden',
        bic: 'SPBDAT21XXX',
        blz: '20205',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20660,
        name: 'Raiffeisen-Regionalbank Gänserndorf eGen',
        bic: 'RLNWATWWGAE',
        blz: '32092',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20678,
        name: 'Sparkasse Hainburg-Bruck-Neusiedl AG',
        bic: 'SPHBAT21XXX',
        blz: '20216',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17131,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '42040040',
        city: 'Gelsenkirchen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20693,
        name: 'Sparkasse Pöllau AG',
        bic: 'SPPLAT21XXX',
        blz: '20833',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20691,
        name: 'Sparkasse Neunkirchen',
        bic: 'SPNGAT21XXX',
        blz: '20241',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20694,
        name: 'Sparkasse Pottenstein N.Ö.',
        bic: 'SPPOAT21XXX',
        blz: '20245',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20664,
        name: 'Salzburger Sparkasse Bank AG',
        bic: 'SBGSAT2SXXX',
        blz: '20404',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20695,
        name: 'Sparkasse Poysdorf AG',
        bic: 'SPPDAT21XXX',
        blz: '20246',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20696,
        name: 'Sparkasse Pregarten - Unterweißenbach AG',
        bic: 'SPPRAT21XXX',
        blz: '20331',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20697,
        name: 'Sparkasse Rattenberg Bank AG',
        bic: 'SPRTAT21XXX',
        blz: '20508',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20698,
        name: 'Sparkasse Reutte AG',
        bic: 'SPREAT21XXX',
        blz: '20509',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20699,
        name: 'Sparkasse Ried im Innkreis-Haag am Hausruck',
        bic: 'SPRHAT21XXX',
        blz: '20333',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20700,
        name: 'Sparkasse Salzkammergut AG',
        bic: 'SKBIAT21XXX',
        blz: '20314',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20701,
        name: 'Sparkasse Scheibbs AG',
        bic: 'SPSBAT21XXX',
        blz: '20257',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20702,
        name: 'Sparkasse Schwaz AG',
        bic: 'SPSCAT22XXX',
        blz: '20510',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20666,
        name: 'SPARDA-BANK AUSTRIA eGen',
        bic: 'SVIEAT21XXX',
        blz: '49460',
        logoPath: '137.svg',
        available: false
    },
    {
        id: 20703,
        name: 'Sparkasse Voitsberg-Köflach BankAG',
        bic: 'SPVOAT21XXX',
        blz: '20839',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20704,
        name: 'Steiermärkische Bank und Sparkassen AG',
        bic: 'STSPAT2GXXX',
        blz: '20815',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20705,
        name: 'Tiroler Sparkasse BankAG Innsbruck',
        bic: 'SPIHAT22XXX',
        blz: '20503',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20692,
        name: 'SPARKASSE NIEDERÖSTERREICH MITTE WEST AG',
        bic: 'SPSPAT21XXX',
        blz: '20256',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20722,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '44800',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20723,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '42550',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20724,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '42630',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20726,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '43210',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20728,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '44790',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20721,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '44820',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17132,
        name: 'Sparkasse Gelsenkirchen',
        bic: 'WELADED1GEK',
        blz: '42050001',
        city: 'Gelsenkirchen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20729,
        name: 'Volksbank Salzburg eG',
        bic: 'VBOEATWWSAL',
        blz: '45010',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20761,
        name: 'Walser Privatbank AG',
        bic: 'RANJAT2BXXX',
        blz: '37434',
        logoPath: '39.svg',
        available: false
    },
    {
        id: 20768,
        name: 'Allianz Investmentbank AG',
        bic: 'AIAGATWWXXX',
        blz: '19330',
        logoPath: '187.svg',
        available: false
    },
    {
        id: 20771,
        name: 'Autobank AG',
        bic: 'AUTOATW1XXX',
        blz: '19370',
        logoPath: '44.svg',
        available: false
    },
    {
        id: 20772,
        name: 'Banco do Brasil AG',
        bic: 'BRASATWWXXX',
        blz: '19970',
        logoPath: '49.svg',
        available: false
    },
    {
        id: 17134,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE420',
        blz: '42070062',
        city: 'Gelsenkirchen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20730,
        name: 'Volksbank Salzburg eG',
        bic: 'VBOEATWWSAL',
        blz: '44020',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20731,
        name: 'Volksbank Salzburg eG',
        bic: 'VBOEATWWSAL',
        blz: '44300',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20744,
        name: 'VOLKSBANK VORARLBERG e. Gen.',
        bic: 'VOVBAT2BXXX',
        blz: '45710',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17208,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDW445',
        blz: '44570004',
        city: 'Iserlohn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20763,
        name: 'easybank AG',
        bic: 'EASYATW1XXX',
        blz: '14200',
        available: false
    },
    {
        id: 20732,
        name: 'Volksbank Salzburg eG',
        bic: 'VBOEATWWSAL',
        blz: '44480',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20733,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '44770',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20734,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '42320',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20735,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '43030',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20736,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '44780',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20737,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '44810',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20745,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '43000',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20746,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '18160',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20747,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '40000',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20748,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '40100',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20749,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '40430',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20750,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '41600',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20751,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '42750',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20752,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '43610',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20753,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '43690',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20754,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '44430',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20755,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '44570',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20756,
        name: 'VOLKSBANK WIEN AG',
        bic: 'VBOEATWWXXX',
        blz: '49220',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20757,
        name: 'Hypo Vorarlberg Bank AG',
        bic: 'HYPVAT2BXXX',
        blz: '58000',
        available: false
    },
    {
        id: 20764,
        name: 'Hypo Tirol Bank AG',
        bic: 'HYPTAT22XXX',
        blz: '57000',
        available: false
    },
    {
        id: 20765,
        name: 'Hypo-Bank Burgenland AG',
        bic: 'EHBBAT2EXXX',
        blz: '51000',
        logoPath: '181.svg',
        available: false
    },
    {
        id: 20766,
        name: 'Österreichische Apothekerbank reg.Gen.m.b.H.',
        bic: 'VBOEATWWAPO',
        blz: '44220',
        logoPath: '156.svg',
        available: false
    },
    {
        id: 20767,
        name: 'Addiko Bank AG',
        bic: 'HSEEAT2KXXX',
        blz: '52300',
        available: false
    },
    {
        id: 20769,
        name: 'Alpenbank AG',
        bic: 'ALPEAT22XXX',
        blz: '36791',
        available: false
    },
    {
        id: 20770,
        name: 'Austrian Anadi Bank AG',
        bic: 'HAABAT2KXXX',
        blz: '52000',
        available: false
    },
    {
        id: 20773,
        name: 'Österreichische Ärzte- und Apothekerbank AG',
        bic: 'BWFBATW1XXX',
        blz: '18130',
        logoPath: '145.svg',
        available: false
    },
    {
        id: 20774,
        name: 'Bank für Tirol und Vorarlberg AG (16000)',
        bic: 'BTVAAT22XXX',
        blz: '16000',
        available: false
    },
    {
        id: 20775,
        name: 'Bank für Tirol und Vorarlberg AG (16300)',
        bic: 'BTVAAT22XXX',
        blz: '16300',
        available: false
    },
    {
        id: 20776,
        name: 'Bank für Tirol und Vorarlberg AG (16310)',
        bic: 'BTVAAT22XXX',
        blz: '16310',
        available: false
    },
    {
        id: 20777,
        name: 'Bank für Tirol und Vorarlberg AG (16320)',
        bic: 'BTVAAT22XXX',
        blz: '16320',
        available: false
    },
    {
        id: 20778,
        name: 'Bank für Tirol und Vorarlberg AG (16330)',
        bic: 'BTVAAT22XXX',
        blz: '16330',
        available: false
    },
    {
        id: 20779,
        name: 'Bank für Tirol und Vorarlberg AG (16340)',
        bic: 'BTVAAT22XXX',
        blz: '16340',
        available: false
    },
    {
        id: 20780,
        name: 'Bank für Tirol und Vorarlberg AG (16350)',
        bic: 'BTVAAT22XXX',
        blz: '16350',
        available: false
    },
    {
        id: 20781,
        name: 'Bank für Tirol und Vorarlberg AG (16360)',
        bic: 'BTVAAT22XXX',
        blz: '16360',
        available: false
    },
    {
        id: 20782,
        name: 'Bank für Tirol und Vorarlberg AG (16370)',
        bic: 'BTVAAT22XXX',
        blz: '16370',
        available: false
    },
    {
        id: 20783,
        name: 'Bank für Tirol und Vorarlberg AG (16380)',
        bic: 'BTVAAT22XXX',
        blz: '16380',
        available: false
    },
    {
        id: 20784,
        name: 'Bank für Tirol und Vorarlberg AG (16390)',
        bic: 'BTVAAT22XXX',
        blz: '16390',
        available: false
    },
    {
        id: 20785,
        name: 'Bank für Tirol und Vorarlberg AG (16400)',
        bic: 'BTVAAT22XXX',
        blz: '16400',
        available: false
    },
    {
        id: 20786,
        name: 'Bank für Tirol und Vorarlberg AG (16410)',
        bic: 'BTVAAT22XXX',
        blz: '16410',
        available: false
    },
    {
        id: 20857,
        name: 'Oberbank AG (15010)',
        bic: 'OBKLAT2LXXX',
        blz: '15010',
        available: false
    },
    {
        id: 20738,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '46590',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20739,
        name: 'Volksbank Steiermark AG',
        bic: 'VBOEATWWGRA',
        blz: '48150',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20762,
        name: 'Wiener Neustädter Sparkasse',
        bic: 'WINSATWNXXX',
        blz: '20267',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20792,
        name: 'Bank Gutmann AG',
        bic: 'GUTBATWWXXX',
        blz: '19140',
        available: false
    },
    {
        id: 20793,
        name: 'Bank of China (Hungary) Close Ltd. Vienna Branch',
        bic: 'BKCHATWWXXX',
        blz: '19540',
        logoPath: '54.svg',
        available: false
    },
    {
        id: 20796,
        name: 'Bankhaus Denzel AG',
        bic: 'DENEATW1XXX',
        blz: '19380',
        available: false
    },
    {
        id: 20803,
        name:
            'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse AG',
        bic: 'BAWAATWWXXX',
        blz: '18190',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20806,
        name: 'FinTech Group Bank AG Niederlassung Österreich (flatex)',
        bic: 'BIIWATWWXXX',
        blz: '19480',
        available: false
    },
    {
        id: 20813,
        name: 'BMW Austria Bank GmbH',
        bic: 'BMASAT21XXX',
        blz: '19390',
        logoPath: '47.svg',
        available: false
    },
    {
        id: 20814,
        name: 'BNP Paribas SA Niederlassung Österreich',
        bic: 'GEBAATWWXXX',
        blz: '18100',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 20817,
        name: 'card complete Service Bank AG',
        bic: 'VIAUATW1XXX',
        blz: '76040',
        logoPath: '155.svg',
        available: false
    },
    {
        id: 20818,
        name: 'Citibank Europe plc, Austria Branch',
        bic: 'CITIATWXXXX',
        blz: '18140',
        available: false
    },
    {
        id: 20820,
        name: 'Commerzialbank Mattersburg im Burgenland AG',
        bic: 'CBMUAT21XXX',
        blz: '19620',
        available: false
    },
    {
        id: 20821,
        name: 'Crédit Suisse (Luxembourg) S.A. Zweigniederlassung Österreich',
        bic: 'CRESATWWXXX',
        blz: '19470',
        available: false
    },
    {
        id: 20822,
        name: 'DenizBank AG',
        bic: 'ESBKATWWXXX',
        blz: '19650',
        available: false
    },
    {
        id: 20825,
        name: 'Europe Arab Bank plc',
        bic: 'ARABATWWXXX',
        blz: '19945',
        available: false
    },
    {
        id: 20826,
        name: 'European American Investment Bank AG',
        bic: 'EUAAATWWXXX',
        blz: '19340',
        available: false
    },
    {
        id: 20827,
        name: 'FIL Fondsbank GmbH Zweigniederlassung Wien',
        bic: 'FFBKDEFFAUT',
        blz: '19915',
        available: false
    },
    {
        id: 20828,
        name: 'Ford Bank Austria, Zweigniederlassung der FCE BANK PLC',
        bic: 'FAZFAT21XXX',
        blz: '19965',
        available: false
    },
    {
        id: 20830,
        name: 'Hellobank BNP Paribas Austria AG',
        bic: 'DIRAAT2SXXX',
        blz: '19250',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 20832,
        name: 'immigon portfolioabbau ag',
        bic: 'IMMIAT21XXX',
        blz: '40074',
        logoPath: '152.svg',
        available: false
    },
    {
        id: 20833,
        name: 'IMMO-BANK AG',
        bic: 'VOHGATW1XXX',
        blz: '47050',
        available: false
    },
    {
        id: 20836,
        name: 'KA Finanz AG',
        bic: 'OKOMATWWXXX',
        blz: '18170',
        available: false
    },
    {
        id: 20837,
        name: 'Kathrein Privatbank AG',
        bic: 'KTBKATWWXXX',
        blz: '19150',
        available: false
    },
    {
        id: 20838,
        name: 'Kommunalkredit Austria AG',
        bic: 'INVOATWWXXX',
        blz: '19660',
        available: false
    },
    {
        id: 20839,
        name: 'LEASFINANZ Bank GmbH',
        bic: 'LEGMATW1XXX',
        blz: '73800',
        available: false
    },
    {
        id: 20840,
        name: 'LGT Bank AG, Zweigniederlassung Österreich',
        bic: 'BLFLATWWXXX',
        blz: '19460',
        available: false
    },
    {
        id: 20841,
        name: 'Liechtensteinische Landesbank (Österreich) AG',
        bic: 'LILAATWWXXX',
        blz: '19450',
        available: false
    },
    {
        id: 20842,
        name: 'MEINL BANK AG',
        bic: 'MEINATWWXXX',
        blz: '19240',
        available: false
    },
    {
        id: 20843,
        name: 'Mercedes-Benz Bank GmbH',
        bic: 'WELCATW1XXX',
        blz: '19410',
        logoPath: '99.svg',
        available: false
    },
    {
        id: 20844,
        name: 'Mizuho Bank Europe N.V. Vienna Branch',
        bic: 'MHCBATWWXXX',
        blz: '19490',
        available: false
    },
    {
        id: 20845,
        name: 'MUFG Bank (Europe) N.V. Vienna Branch',
        bic: 'BOTKATWXXXX',
        blz: '19400',
        available: false
    },
    {
        id: 20846,
        name: 'NOTARTREUHANDBANK AG',
        bic: 'NTBAATWWXXX',
        blz: '31500',
        available: false
    },
    {
        id: 20819,
        name: 'Commerzbank AG Niederlassung Wien',
        bic: 'COBAATWXXXX',
        blz: '19675',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 20787,
        name: 'Bank für Tirol und Vorarlberg AG (16420)',
        bic: 'BTVAAT22XXX',
        blz: '16420',
        available: false
    },
    {
        id: 20788,
        name: 'Bank für Tirol und Vorarlberg AG (16430)',
        bic: 'BTVAAT22XXX',
        blz: '16430',
        available: false
    },
    {
        id: 20789,
        name: 'Bank für Tirol und Vorarlberg AG (16440)',
        bic: 'BTVAAT22XXX',
        blz: '16440',
        available: false
    },
    {
        id: 20790,
        name: 'Bank für Tirol und Vorarlberg AG (16450)',
        bic: 'BTVAAT22XXX',
        blz: '16450',
        available: false
    },
    {
        id: 20791,
        name: 'Bank für Tirol und Vorarlberg AG (16460)',
        bic: 'BTVAAT22XXX',
        blz: '16460',
        available: false
    },
    {
        id: 20794,
        name: 'Bank Winter & Co. AG',
        bic: 'WISMATWWXXX',
        blz: '19220',
        available: false
    },
    {
        id: 20795,
        name: 'Bankhaus Carl Spängler & Co. Aktiengesellschaft',
        bic: 'SPAEAT2SXXX',
        blz: '19530',
        available: false
    },
    {
        id: 20797,
        name: 'Bankhaus Krentschker & Co. AG',
        bic: 'KRECAT2GXXX',
        blz: '19520',
        city: 'Graz',
        available: false
    },
    {
        id: 20798,
        name: 'Bankhaus Schelhammer & Schattera AG',
        bic: 'BSSWATWWXXX',
        blz: '19190',
        available: false
    },
    {
        id: 20804,
        name:
            'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse AG',
        bic: 'BAWAATWWXXX',
        blz: '60000',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20802,
        name:
            'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse AG',
        bic: 'BAWAATWWXXX',
        blz: '14900',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20800,
        name: 'Bausparkasse Wüstenrot AG',
        bic: 'WUBUAT21XXX',
        blz: '19950',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20805,
        name: 'BAWAG P.S.K. Wohnbaubank AG',
        bic: 'BAWOATW1XXX',
        blz: '19750',
        available: false
    },
    {
        id: 20807,
        name: 'BKS Bank AG (17000)',
        bic: 'BFKKAT2KXXX',
        blz: '17000',
        logoPath: '186.svg',
        available: false
    },
    {
        id: 20808,
        name: 'BKS Bank AG (17100)',
        bic: 'BFKKAT2KXXX',
        blz: '17100',
        logoPath: '186.svg',
        available: false
    },
    {
        id: 20809,
        name: 'BKS Bank AG (17200)',
        bic: 'BFKKAT2KXXX',
        blz: '17200',
        logoPath: '186.svg',
        available: false
    },
    {
        id: 20810,
        name: 'BKS Bank AG (17300)',
        bic: 'BFKKAT2KXXX',
        blz: '17300',
        logoPath: '186.svg',
        available: false
    },
    {
        id: 20811,
        name: 'BKS Bank AG (17400)',
        bic: 'BFKKAT2KXXX',
        blz: '17400',
        logoPath: '186.svg',
        available: false
    },
    {
        id: 20812,
        name: 'BKS Bank AG (17500)',
        bic: 'BFKKAT2KXXX',
        blz: '17500',
        logoPath: '186.svg',
        available: false
    },
    {
        id: 20815,
        name: 'Brüll Kallmus Bank AG',
        bic: 'SPBAATWWXXX',
        blz: '19120',
        available: false
    },
    {
        id: 20816,
        name: 'CAPITAL BANK - GRAWE GRUPPE AG',
        bic: 'RSBUAT2KXXX',
        blz: '19600',
        available: false
    },
    {
        id: 20829,
        name: 'Generali Bank AG',
        bic: 'BGENATWWXXX',
        blz: '18400',
        available: false
    },
    {
        id: 20831,
        name: 'HYPO TIROL BANK AG',
        bic: 'HYPTAT22XXX',
        blz: '57100',
        available: false
    },
    {
        id: 20834,
        name: 'ING-DiBa Austria Niederlassung der ING-DiBa AG',
        bic: 'INGDAT21XXX',
        blz: '19210',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 20835,
        name: 'ING BANK N.V. Zweigniederlassung Wien',
        bic: 'INGBATWWXXX',
        blz: '19360',
        logoPath: '142.svg',
        available: false
    },
    {
        id: 20847,
        name: 'Oberbank AG (15000)',
        bic: 'OBKLAT2LXXX',
        blz: '15000',
        available: false
    },
    {
        id: 20848,
        name: 'Oberbank AG (15001)',
        bic: 'OBKLAT2LXXX',
        blz: '15001',
        available: false
    },
    {
        id: 20849,
        name: 'Oberbank AG (15002)',
        bic: 'OBKLAT2LXXX',
        blz: '15002',
        available: false
    },
    {
        id: 20850,
        name: 'Oberbank AG (15003)',
        bic: 'OBKLAT2LXXX',
        blz: '15003',
        available: false
    },
    {
        id: 20851,
        name: 'Oberbank AG (15004)',
        bic: 'OBKLAT2LXXX',
        blz: '15004',
        available: false
    },
    {
        id: 20852,
        name: 'Oberbank AG (15005)',
        bic: 'OBKLAT2LXXX',
        blz: '15005',
        available: false
    },
    {
        id: 20853,
        name: 'Oberbank AG (15006)',
        bic: 'OBKLAT2LXXX',
        blz: '15006',
        available: false
    },
    {
        id: 20854,
        name: 'Oberbank AG (15007)',
        bic: 'OBKLAT2LXXX',
        blz: '15007',
        available: false
    },
    {
        id: 20855,
        name: 'Oberbank AG (15008)',
        bic: 'OBKLAT2LXXX',
        blz: '15008',
        available: false
    },
    {
        id: 20897,
        name: 'OeKB CSD GmbH',
        bic: 'OCSDATWWXXX',
        blz: '10800',
        available: false
    },
    {
        id: 20898,
        name: 'Oesterreichische Kontrollbank AG',
        bic: 'OEKOATWWXXX',
        blz: '10000',
        available: false
    },
    {
        id: 20899,
        name: 'Oesterreichische Nationalbank',
        bic: 'NABAATWWXXX',
        blz: '100',
        logoPath: '103.svg',
        available: false
    },
    {
        id: 20901,
        name: 'PARTNER BANK AG',
        bic: 'IBAGAT21XXX',
        blz: '19170',
        available: false
    },
    {
        id: 20902,
        name: 'paybox Bank AG',
        blz: '18120',
        logoPath: '148.svg',
        available: false
    },
    {
        id: 20903,
        name: 'Porsche Bank AG',
        bic: 'PORCAT21XXX',
        blz: '76000',
        available: false
    },
    {
        id: 20904,
        name:
            'PSA Bank Österreich, Niederlassung der PSA Bank Deutschland GmbH',
        blz: '75910',
        available: false
    },
    {
        id: 20905,
        name: 'RCI Banque SA, Niederlassung Österreich',
        bic: 'RCNOATW1XXX',
        blz: '19420',
        available: false
    },
    {
        id: 20906,
        name: 'Republik Österreich',
        bic: 'BUNDATWWXXX',
        blz: '1000',
        available: false
    },
    {
        id: 20907,
        name: 's Wohnbaubank AG',
        bic: 'WOHBATWWXXX',
        blz: '19700',
        available: false
    },
    {
        id: 20908,
        name: 'Santander Consumer Bank GmbH',
        bic: 'SANTATWWXXX',
        blz: '19810',
        logoPath: '176.svg',
        available: false
    },
    {
        id: 20909,
        name: 'Sberbank Europe AG',
        bic: 'SABRATWWXXX',
        blz: '74000',
        available: false
    },
    {
        id: 20911,
        name: 'Liechtensteinische Landesbank (Österreich) AG',
        bic: 'COPRATWWXXX',
        blz: '76520',
        available: false
    },
    {
        id: 20912,
        name: 'SIX Payment Services (Austria) GmbH',
        bic: 'PLBKATWWXXX',
        blz: '76030',
        available: false
    },
    {
        id: 20913,
        name: 'Société Générale Zweigniederlassung Wien',
        bic: 'SGABATWWXXX',
        blz: '18150',
        available: false
    },
    {
        id: 20914,
        name:
            'Spar- und Vorschuß-Verein der Beamtenschaft der Österreichischen Nationalbank',
        blz: '40800',
        available: false
    },
    {
        id: 20915,
        name: 'Spar- und Vorschußverein Graphik" reg.Gen.m.b.H. in Liqu."',
        blz: '48940',
        available: false
    },
    {
        id: 20916,
        name: 'SPAR-FINANZ BANK AG',
        bic: 'SFIVATW1XXX',
        blz: '74200',
        available: false
    },
    {
        id: 20918,
        name: 'State Street Bank International GmbH Filiale Wien',
        bic: 'SBOSATWWXXX',
        blz: '75100',
        available: false
    },
    {
        id: 20919,
        name: 'Steyler Bank GmbH',
        bic: 'RVSAAT2SSTB',
        blz: '19685',
        logoPath: '144.svg',
        available: false
    },
    {
        id: 20920,
        name: 'TeamBank Österreich - Niederlassung der TeamBank AG Nürnberg',
        bic: 'TEAMDE71TAT',
        blz: '76032',
        available: false
    },
    {
        id: 20921,
        name: 'The Royal Bank of Scotland plc Filiale Wien',
        bic: 'ABNAATWWXXX',
        blz: '19985',
        logoPath: '55.svg',
        available: false
    },
    {
        id: 20922,
        name: 'UBS Europe SE Niederlassung Österreich',
        bic: 'UBSWATWWXXX',
        blz: '19430',
        available: false
    },
    {
        id: 20923,
        name: 'UniCredit Bank AG, Zweigniederlassung Wien',
        bic: 'CAIBATWWXXX',
        blz: '18250',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20926,
        name: 'VakifBank International AG',
        bic: 'TVBAATWWXXX',
        blz: '19690',
        available: false
    },
    {
        id: 20865,
        name: 'Oberbank AG (15025)',
        bic: 'OBKLAT2LXXX',
        blz: '15025',
        available: false
    },
    {
        id: 20930,
        name: 'VTB Bank (Europe) SE, Zweigniederlassung Wien',
        bic: 'DOBAATWWXXX',
        blz: '19940',
        available: false
    },
    {
        id: 20931,
        name: 'Western Union International Bank GmbH',
        bic: 'WUIBATWWXXX',
        blz: '19880',
        available: false
    },
    {
        id: 20932,
        name: 'Wiener Privatbank SE',
        bic: 'WIPBATWWXXX',
        blz: '19440',
        logoPath: '33.svg',
        available: false
    },
    {
        id: 20933,
        name: 'Wiener Privatbank SE',
        bic: 'WIPBATWWXXX',
        blz: '19160',
        logoPath: '33.svg',
        available: false
    },
    {
        id: 20934,
        name: 'WSK Bank AG',
        bic: 'WSPKATW1XXX',
        blz: '44960',
        available: false
    },
    {
        id: 20935,
        name: 'ZUNO BANK AG',
        bic: 'RIDBATWWXXX',
        blz: '31300',
        available: false
    },
    {
        id: 20936,
        name: 'Zürcher Kantonalbank Österreich AG',
        bic: 'PIAGAT2SXXX',
        blz: '19510',
        available: false
    },
    {
        id: 20937,
        name: 'UniCredit S.p.A. - Italia',
        bic: 'UNCRITMM',
        blz: '02008',
        available: false
    },
    {
        id: 20938,
        name: 'PayPal',
        blz: 'PAYPAL',
        logoPath: '108.svg',
        available: false
    },
    {
        id: 20866,
        name: 'Oberbank AG (15030)',
        bic: 'OBKLAT2LXXX',
        blz: '15030',
        available: false
    },
    {
        id: 20867,
        name: 'Oberbank AG (15031)',
        bic: 'OBKLAT2LXXX',
        blz: '15031',
        available: false
    },
    {
        id: 20868,
        name: 'Oberbank AG (15040)',
        bic: 'OBKLAT2LXXX',
        blz: '15040',
        available: false
    },
    {
        id: 20869,
        name: 'Oberbank AG (15041)',
        bic: 'OBKLAT2LXXX',
        blz: '15041',
        available: false
    },
    {
        id: 20870,
        name: 'Oberbank AG (15042)',
        bic: 'OBKLAT2LXXX',
        blz: '15042',
        available: false
    },
    {
        id: 20871,
        name: 'Oberbank AG (15060)',
        bic: 'OBKLAT2LXXX',
        blz: '15060',
        available: false
    },
    {
        id: 20872,
        name: 'Oberbank AG (15061)',
        bic: 'OBKLAT2LXXX',
        blz: '15061',
        available: false
    },
    {
        id: 20873,
        name: 'Oberbank AG (15062)',
        bic: 'OBKLAT2LXXX',
        blz: '15062',
        available: false
    },
    {
        id: 20874,
        name: 'Oberbank AG (15080)',
        bic: 'OBKLAT2LXXX',
        blz: '15080',
        available: false
    },
    {
        id: 20875,
        name: 'Oberbank AG (15081)',
        bic: 'OBKLAT2LXXX',
        blz: '15081',
        available: false
    },
    {
        id: 20876,
        name: 'Oberbank AG (15090)',
        bic: 'OBKLAT2LXXX',
        blz: '15090',
        available: false
    },
    {
        id: 20877,
        name: 'Oberbank AG (15091)',
        bic: 'OBKLAT2LXXX',
        blz: '15091',
        available: false
    },
    {
        id: 20878,
        name: 'Oberbank AG (15092)',
        bic: 'OBKLAT2LXXX',
        blz: '15092',
        available: false
    },
    {
        id: 20879,
        name: 'Oberbank AG (15093)',
        bic: 'OBKLAT2LXXX',
        blz: '15093',
        available: false
    },
    {
        id: 20880,
        name: 'Oberbank AG (15094)',
        bic: 'OBKLAT2LXXX',
        blz: '15094',
        available: false
    },
    {
        id: 20881,
        name: 'Oberbank AG (15095)',
        bic: 'OBKLAT2LXXX',
        blz: '15095',
        available: false
    },
    {
        id: 20882,
        name: 'Oberbank AG (15096)',
        bic: 'OBKLAT2LXXX',
        blz: '15096',
        available: false
    },
    {
        id: 20883,
        name: 'Oberbank AG (15097)',
        bic: 'OBKLAT2LXXX',
        blz: '15097',
        available: false
    },
    {
        id: 20884,
        name: 'Oberbank AG (15110)',
        bic: 'OBKLAT2LXXX',
        blz: '15110',
        available: false
    },
    {
        id: 20885,
        name: 'Oberbank AG (15120)',
        bic: 'OBKLAT2LXXX',
        blz: '15120',
        available: false
    },
    {
        id: 20886,
        name: 'Oberbank AG (15121)',
        bic: 'OBKLAT2LXXX',
        blz: '15121',
        available: false
    },
    {
        id: 20887,
        name: 'Oberbank AG (15122)',
        bic: 'OBKLAT2LXXX',
        blz: '15122',
        available: false
    },
    {
        id: 20888,
        name: 'Oberbank AG (15123)',
        bic: 'OBKLAT2LXXX',
        blz: '15123',
        available: false
    },
    {
        id: 20889,
        name: 'Oberbank AG (15130)',
        bic: 'OBKLAT2LXXX',
        blz: '15130',
        available: false
    },
    {
        id: 20890,
        name: 'Oberbank AG (15131)',
        bic: 'OBKLAT2LXXX',
        blz: '15131',
        available: false
    },
    {
        id: 20891,
        name: 'Oberbank AG (15132)',
        bic: 'OBKLAT2LXXX',
        blz: '15132',
        available: false
    },
    {
        id: 20892,
        name: 'Oberbank AG (15133)',
        bic: 'OBKLAT2LXXX',
        blz: '15133',
        available: false
    },
    {
        id: 20893,
        name: 'Oberbank AG (15134)',
        bic: 'OBKLAT2LXXX',
        blz: '15134',
        available: false
    },
    {
        id: 20894,
        name: 'Oberbank AG (15135)',
        bic: 'OBKLAT2LXXX',
        blz: '15135',
        available: false
    },
    {
        id: 20895,
        name: 'Oberbank AG (15150)',
        bic: 'OBKLAT2LXXX',
        blz: '15150',
        available: false
    },
    {
        id: 20896,
        name: 'Oberbank AG (15151)',
        bic: 'OBKLAT2LXXX',
        blz: '15151',
        available: false
    },
    {
        id: 20910,
        name: 'Schoellerbank AG',
        bic: 'SCHOATWWXXX',
        blz: '19200',
        available: false
    },
    {
        id: 20924,
        name: 'UniCredit Bank Austria AG',
        bic: 'BKAUATWWXXX',
        blz: '12000',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20925,
        name: 'UniCredit Bank Austria AG',
        bic: 'BKAUATWWXXX',
        blz: '11000',
        logoPath: '172.svg',
        available: false
    },
    {
        id: 20928,
        name: 'Volkskreditbank AG',
        bic: 'VKBLAT2LXXX',
        blz: '18600',
        available: false
    },
    {
        id: 20900,
        name: 'Österreichische Hotel- und Tourismusbank Ges. m.b.H.',
        bic: 'OEHTATWWXXX',
        blz: '73700',
        logoPath: '451.svg',
        available: false
    },
    {
        id: 20917,
        name: 'start:bausparkasse AG',
        bic: 'ABVRATW1XXX',
        blz: '43510',
        logoPath: '1154.svg',
        available: false
    },
    {id: 20939, name: 'LBS Saarland', blz: '99951100', available: true},
    {
        id: 20940,
        name: 'LBS Schleswig-Holstein-Hamburg',
        blz: '99951110',
        available: true
    },
    {id: 20941, name: 'LBS Hessen-Thüringen', blz: '99951060', available: true},
    {
        id: 20942,
        name: 'LBS Baden-Württemberg',
        blz: '99951010',
        available: true
    },
    {id: 20944, name: 'A.B.S. Factoring AG', blz: '74500', available: false},
    {
        id: 20945,
        name: 'Allianz Invest Kapitalanlagegesellschaft mbH',
        blz: '76257',
        logoPath: '187.svg',
        available: false
    },
    {
        id: 20946,
        name: 'Allianz Vorsorgekasse AG',
        blz: '71500',
        logoPath: '187.svg',
        available: false
    },
    {
        id: 20947,
        name: 'ALPENLÄNDISCHE GARANTIE GESELLSCHAFT m.b.H.',
        blz: '76310',
        available: false
    },
    {id: 20948, name: 'Amundi Austria GmbH', blz: '76225', available: false},
    {
        id: 20949,
        name: 'APK Vorsorgekasse AG',
        blz: '71100',
        available: false
    },
    {
        id: 20950,
        name: 'Bank Austria Real Invest Immobilien Kapitalanlage GmbH',
        blz: '75310',
        available: false
    },
    {
        id: 20951,
        name: 'Bank Austria Wohnbaubank AG',
        blz: '19720',
        available: false
    },
    {
        id: 20952,
        name: 'BNP PARIBAS PERSONAL FINANCE SA Niederlassung Österreich',
        blz: '19610',
        available: false
    },
    {id: 20953, name: 'BONUS Vorsorgekasse AG', blz: '71200', available: false},
    {
        id: 20954,
        name: 'BUAK Betriebliche Vorsorgekasse GesmbH',
        blz: '71900',
        available: false
    },
    {id: 20955, name: 'DC Bank AG', blz: '76022', available: false},
    {
        id: 20956,
        name: 'Deutsche Vermögensberatung Bank Aktiengesellschaft',
        blz: '76080',
        available: false
    },
    {
        id: 20957,
        name: 'Erste Asset Management GmbH',
        blz: '76263',
        available: false
    },
    {
        id: 20958,
        name: 'ERSTE Immobilien Kapitalanlagegesellschaft m.b.H.',
        blz: '76261',
        available: false
    },
    {
        id: 20959,
        name: 'EXC Exclusive Change GmbH',
        blz: '87521',
        available: false
    },
    {
        id: 20960,
        name: 'FactorBank Aktiengesellschaft',
        blz: '73100',
        available: false
    },
    {
        id: 20961,
        name: 'fair finance Vorsorgekasse AG',
        blz: '71150',
        available: false
    },
    {
        id: 20962,
        name: 'FCA Bank GmbH',
        blz: '19840',
        available: false
    },
    {
        id: 20963,
        name: 'GLOBAL BLUE AUSTRIA GMBH',
        blz: '87516',
        available: false
    },
    {
        id: 20964,
        name: 'Gutmann Kapitalanlageaktiengesellschaft',
        blz: '76290',
        available: false
    },
    {
        id: 20965,
        name: 'Hypo Wohnbaubank Aktiengesellschaft',
        blz: '19730',
        available: false
    },
    {
        id: 20966,
        name: 'ICBC Austria Bank GmbH',
        blz: '19888',
        available: false
    },
    {
        id: 20967,
        name: 'Interchange (Austria) GmbH.',
        blz: '87518',
        available: false
    },
    {
        id: 20968,
        name: 'Intermarket Bank AG',
        blz: '73600',
        available: false
    },
    {
        id: 20969,
        name: 'Joh. Berenberg, Gossler & Co. KG Zweigniederlassung Wien',
        blz: '19290',
        available: false
    },
    {
        id: 20970,
        name: 'JULIUS MEINL INVESTMENT Gesellschaft m.b.H.',
        blz: '76241',
        available: false
    },
    {
        id: 20971,
        name: 'KEPLER FONDS Kapitalanlagegesellschaft m.b.H.',
        blz: '34798',
        available: false
    },
    {
        id: 20972,
        name: 'LLB Immo Kapitalanlagegesellschaft m.b.H.',
        blz: '76420',
        available: false
    },
    {
        id: 20973,
        name: 'LLB Invest Kapitalanlagegesellschaft m.b.H.',
        blz: '76300',
        available: false
    },
    {
        id: 20974,
        name: 'Macquarie Bank International Limited, Vienna Branch',
        blz: '19885',
        available: false
    },
    {
        id: 20975,
        name: 'Macquarie Investment Management Austria Kapitalanlage AG',
        blz: '76262',
        available: false
    },
    {
        id: 20976,
        name: 'MASTERINVEST Kapitalanlage GmbH',
        blz: '76250',
        available: false
    },
    {
        id: 20977,
        name: 'Niederösterreichische Vorsorgekasse AG',
        blz: '71700',
        available: false
    },
    {
        id: 20978,
        name: 'NÖ Bürgschaften und Beteiligungen GmbH',
        blz: '76090',
        available: false
    },
    {
        id: 20979,
        name: 'Oberösterreichische Kreditgarantiegesellschaft m.b.H.',
        blz: '76120',
        available: false
    },
    {
        id: 20980,
        name: 'Oesterreichische Entwicklungsbank AG',
        blz: '10100',
        available: false
    },
    {
        id: 20985,
        name: 'Raiffeisen Wohnbaubank Aktiengesellschaft',
        blz: '19740',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20984,
        name: 'Raiffeisen Salzburg Invest Kapitalanlage GmbH',
        blz: '76258',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20986,
        name:
            'RAIFFEISEN HOLDING NIEDERÖSTERREICH WIEN registrierte Genossenschaft mit beschränkter Haftung',
        blz: '32300',
        available: false
    },
    {
        id: 20988,
        name: 'Schoellerbank Invest AG',
        blz: '76242',
        available: false
    },
    {
        id: 20989,
        name: 'Security Kapitalanlage Aktiengesellschaft',
        blz: '76254',
        available: false
    },
    {
        id: 20990,
        name: 'Spängler IQAM Invest GmbH',
        blz: '76243',
        available: false
    },
    {
        id: 20992,
        name: 'The Change Group Wechselstuben GmbH',
        blz: '87503',
        available: false
    },
    {
        id: 20993,
        name: 'Union Investment Real Estate Austria AG',
        blz: '76346',
        available: false
    },
    {
        id: 20994,
        name: 'Valida Plus AG',
        blz: '71300',
        available: false
    },
    {id: 20995, name: 'VBV Vorsorgekasse AG', blz: '71600', available: false},
    {
        id: 20996,
        name: 'WKBG Wiener Kreditbürgschafts und Beteiligungsbank AG',
        blz: '76180',
        available: false
    },
    {
        id: 20997,
        name: '3 Banken Generali Investment Gesellschaft m.b.H.',
        blz: '76330',
        available: false
    },
    {
        id: 20998,
        name: '3 Banken Wohnbaubank AG',
        blz: '19760',
        available: false
    },
    {
        id: 20999,
        name: 'comdirect bank',
        bic: 'COBADEHD099',
        blz: '20041199',
        logoPath: '167.svg',
        available: false
    },
    {
        id: 21000,
        name: 'mediserv Bank',
        bic: 'MEGHDE81XXX',
        blz: '59020400',
        available: false
    },
    {
        id: 21001,
        name: 'Eurocity Bank Gf GAA',
        bic: 'DLGHDEB1XXX',
        blz: '10030730',
        available: false
    },
    {
        id: 21002,
        name: 'Hyundai Capital Bank Europe',
        bic: 'HCSEDEF1XXX',
        blz: '50022200',
        available: false
    },
    {
        id: 21003,
        name: 'Holvi Payment Services Zweigniederlassung Deutschland',
        bic: 'HOLVDEB1XXX',
        blz: '10017997',
        available: false
    },
    {
        id: 21004,
        name: 'CAIXABANK Zweigniederlassung Deutschland',
        bic: 'CAIXDEFFXXX',
        blz: '50220707',
        available: false
    },
    {
        id: 21006,
        name: 'BNP Paribas Niederlassung Deutschland',
        bic: 'BNPADEFFXXX',
        blz: '51210606',
        logoPath: '185.svg',
        available: false
    },
    {
        id: 21007,
        name: 'WOORI BANK EUROPE',
        bic: 'HVBKDEFFXXX',
        blz: '50220300',
        available: false
    },
    {
        id: 21009,
        name: 'SMBC Bank EU',
        bic: 'SMBCDEFFXXX',
        blz: '50510300',
        available: false
    },
    {
        id: 20943,
        name: 'LBS Bayerische Landesbausparkasse (LBS Bayern)',
        blz: '99951020',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 21012,
        name: 'DZ BANK Gf VK9',
        bic: 'GENODEF1VK9',
        blz: '50060419',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 21013,
        name: 'DZ BANK Gf VK8',
        bic: 'GENODEF1VK8',
        blz: '50060418',
        logoPath: '70.svg',
        available: false
    },
    {
        id: 21029,
        name: 'ABN AMRO Bank Frankfurt Branch, Prospery',
        bic: 'FTSBDEFAPRO',
        blz: '50324015',
        logoPath: '189.svg',
        available: false
    },
    {
        id: 21037,
        name: 'MHB-Bank',
        bic: 'MHBFDE21XXX',
        blz: '50330201',
        available: false
    },
    {
        id: 21042,
        name: 'Ford Bank',
        bic: 'FDBADE8FXXX',
        blz: '37021500',
        available: false
    },
    {
        id: 21044,
        name: 'GLS Gemeinschaftsbank (GAA)',
        bic: 'GENODEM1GLS',
        blz: '43060988',
        available: false
    },
    {
        id: 21046,
        name: 'Middle East Bank, Munich Branch',
        bic: 'KHMIDEMMXXX',
        blz: '70010800',
        available: false
    },
    {
        id: 21047,
        name: 'comdirect bank',
        bic: 'COBADEHD066',
        blz: '20041166',
        logoPath: '167.svg',
        available: false
    },
    {
        id: 21048,
        name: 'Societe Generale',
        bic: 'SOGEDEFFXXX',
        blz: '51210802',
        logoPath: '174.svg',
        available: false
    },
    {
        id: 21049,
        name: 'Bank für Kirche und Diakonie - KD-Bank (Gf GAA RB)',
        bic: 'GENODED1DKD',
        blz: '35060199',
        available: false
    },
    {
        id: 21050,
        name: 'solarisBank Gf (S)',
        bic: 'SOBKDEB2XXX',
        blz: '11010101',
        logoPath: '93.svg',
        available: false
    },
    {
        id: 21051,
        name: 'comdirect bank',
        bic: 'COBADEHD077',
        blz: '20041177',
        logoPath: '167.svg',
        available: false
    },
    {
        id: 21054,
        name: 'comdirect bank',
        bic: 'COBADEHD088',
        blz: '20041188',
        logoPath: '167.svg',
        available: false
    },
    {
        id: 21055,
        name: 'Western Union Intl. Bank Niederlassung Deutschland',
        bic: 'WUIDDEF1XXX',
        blz: '50016600',
        available: false
    },
    {
        id: 21056,
        name: 'KOFIBA - Deckung Pfandbriefbank',
        bic: 'DXIADEB2COV',
        blz: '10019670',
        available: false
    },
    {
        id: 15804,
        name: 'comdirect bank',
        bic: 'COBADEHDXXX',
        blz: '20041111',
        city: 'Quickborn',
        logoPath: '167.svg',
        available: true
    },
    {
        id: 21053,
        name: 'Volksbank Rhein-Lippe (GAA)',
        bic: 'GENODED1RLW',
        blz: '35660501',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21057,
        name: 'Volksbank Marl-Recklinghausen (Gf GAA)',
        bic: 'GENODEM1MRL',
        blz: '42661088',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19285,
        name: 'VR Bank Bamberg Raiffeisen-Volksbank',
        bic: 'GENODEF1BA2',
        blz: '77060100',
        city: 'Bamberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19286,
        name: 'Raiffeisenbank Obermain Nord',
        bic: 'GENODEF1ALK',
        blz: '77061004',
        city: 'Altenkunstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19284,
        name: 'Sparkasse Bamberg',
        bic: 'BYLADEM1SKB',
        blz: '77050000',
        city: 'Bamberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17110,
        name: 'Volksbank Wickede (Ruhr)',
        bic: 'GENODEM1WRU',
        blz: '41462295',
        city: 'Wickede (Ruhr)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17147,
        name: 'Volksbank Herten-Westerholt -alt-',
        bic: 'GENODEM1HWE',
        blz: '42661522',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17170,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB430',
        blz: '43070024',
        city: 'Bochum',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17186,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB440',
        blz: '44070024',
        city: 'Dortmund',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17232,
        name: 'Volksbank Sprockhövel',
        bic: 'GENODEM1SPO',
        blz: '45261547',
        city: 'Sprockhövel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16118,
        name: 'Sparkasse Harburg-Buxtehude',
        bic: 'NOLADE21HAM',
        blz: '20750000',
        city: 'Hamburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17276,
        name: 'Volksbank Reiste-Eslohe',
        bic: 'GENODEM1RET',
        blz: '46464453',
        city: 'Eslohe (Sauerland)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17295,
        name: 'Volksbank Westerloh-Westerwiehe',
        bic: 'GENODEM1WWW',
        blz: '47263472',
        city: 'Delbrück',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17350,
        name: 'Commerzbank vormals Dresdner Bank ITGK',
        bic: 'DRESDEFFI19',
        blz: '48089350',
        city: 'Bielefeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16133,
        name: 'Sparkasse Eckernförde -alt-',
        bic: 'NOLADE21ECK',
        blz: '21052090',
        city: 'Eckernförde',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17368,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB490',
        blz: '49070024',
        city: 'Minden',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17420,
        name: 'Commerzbank Zw 425 - keine Auslandsbanken',
        bic: 'COBADEFFXXX',
        blz: '50042500',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17432,
        name: 'VR Bank Alzey-Land-Schwabenheim',
        bic: 'GENODE51ABO',
        blz: '50069126',
        city: 'Schwabenheim an der Selz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17475,
        name: 'Rüsselsheimer Volksbank GAA',
        bic: 'GENODE51GAA',
        blz: '50093010',
        city: 'Rüsselsheim am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17501,
        name: 'Volksbank Höchst a.M., ZwNdl. der Frankfurter VB',
        bic: 'GENODE51FHC',
        blz: '50190300',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17547,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '50540028',
        city: 'Offenbach am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16296,
        name: 'Sparkasse Hannover',
        bic: 'SPKHDE2HXXX',
        blz: '25050180',
        city: 'Hannover',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16362,
        name: 'Sparkasse Nienburg',
        bic: 'NOLADE21NIB',
        blz: '25650106',
        city: 'Nienburg (Weser)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16170,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '21540060',
        city: 'Flensburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16232,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '22240073',
        city: 'Itzehoe',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16237,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF201',
        blz: '22280000',
        city: 'Itzehoe',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16423,
        name: 'Kreis-Sparkasse Northeim',
        bic: 'NOLADE21NOM',
        blz: '26250001',
        city: 'Northeim',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16309,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBHAN',
        blz: '25070024',
        city: 'Hannover',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16359,
        name: 'Volksbank Obernkirchen -alt-',
        blz: '25591748',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17705,
        name: 'Sparkasse Dillenburg',
        bic: 'HELADEF1DIL',
        blz: '51650045',
        city: 'Dillenburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16398,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB259',
        blz: '25970024',
        city: 'Hildesheim',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16421,
        name: 'Volksbank Göttingen',
        bic: 'GENODEF1GOE',
        blz: '26090050',
        city: 'Göttingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16953,
        name: 'Stadt-Sparkasse Leichlingen',
        bic: 'WELADED1LEI',
        blz: '37551020',
        city: 'Leichlingen (Rheinland)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16464,
        name: 'Volksbank Haren Fil d Ostfriesischen VB',
        bic: 'GENODEF1HAR',
        blz: '26691213',
        city: 'Haren (Ems)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16482,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB269',
        blz: '26971024',
        city: 'Wolfsburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16507,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB277',
        blz: '27072524',
        city: 'Wolfenbüttel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16544,
        name: 'Raiffeisen-Volksbank Varel-Nordenham',
        bic: 'GENODEF1NHE',
        blz: '28064241',
        city: 'Nordenham',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16564,
        name: 'Raiffeisenbank Wiesedermeer-Wiesede-Marcardsm',
        bic: 'GENODEF1WWM',
        blz: '28069773',
        city: 'Friedeburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16601,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB285',
        blz: '28570024',
        city: 'Leer (Ostfriesland)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16614,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '29040060',
        city: 'Bremen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16619,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBBRE',
        blz: '29070024',
        city: 'Bremen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16671,
        name: 'Commerzbank Gf 660',
        bic: 'COBADEFFXXX',
        blz: '30040060',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16692,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFI78',
        blz: '30080082',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16731,
        name: 'Volksbank Mönchengladbach',
        bic: 'GENODED1MRB',
        blz: '31060517',
        city: 'Mönchengladbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16735,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB310',
        blz: '31070024',
        city: 'Mönchengladbach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20454,
        name: 'Raiffeisenbank Region St. Pölten eGen',
        bic: 'RLNWATWWOBG',
        blz: '32585',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16791,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '34240050',
        city: 'Solingen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16793,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB342',
        blz: '34270024',
        city: 'Solingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17234,
        name: 'Sparkasse Ennepetal-Breckerfeld',
        bic: 'WELADED1ENE',
        blz: '45451060',
        city: 'Ennepetal',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16918,
        name: 'Raiffeisenbk Erftstadt -alt-',
        bic: 'GENODED1ERF',
        blz: '37069472',
        city: 'Erftstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17706,
        name: 'Volksbank Dill',
        bic: 'GENODE51DIL',
        blz: '51690000',
        city: 'Dillenburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16920,
        name: 'Raiffeisenbank Rhein-Berg',
        bic: 'GENODED1MNH',
        blz: '37069521',
        city: 'Monheim am Rhein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17357,
        name: 'Sparkasse Minden-Lübbecke',
        bic: 'WELADED1MIN',
        blz: '49050101',
        city: 'Minden',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16979,
        name: 'Volksbank Köln Bonn',
        bic: 'GENODED1BRS',
        blz: '38060186',
        city: 'Bonn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16980,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB380',
        blz: '38070024',
        city: 'Bonn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17042,
        name: 'Volksbank Sprakel',
        bic: 'GENODEM1MSS',
        blz: '40069462',
        city: 'Münster',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17048,
        name: 'Volksbank Seppenrade',
        bic: 'GENODEM1LSP',
        blz: '40069622',
        city: 'Lüdinghausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17083,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB403',
        blz: '40370024',
        city: 'Rheine',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17105,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF413',
        blz: '41280043',
        city: 'Beckum',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17903,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBMAI',
        blz: '55070024',
        city: 'Mainz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17904,
        name: 'Deutsche Bank',
        bic: 'DEUTDE5MXXX',
        blz: '55070040',
        city: 'Mainz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17917,
        name: 'Mainzer Volksbank',
        bic: 'MVBMDE51064',
        blz: '55190064',
        city: 'Hochheim am Main',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18776,
        name: 'Volksbank Raiffeisenbank Starnberg-Herrsching-Landsberg',
        bic: 'GENODEF1STH',
        blz: '70093200',
        city: 'Starnberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18829,
        name: 'Raiffeisenbank St. Wolfgang-Schwindkirchen',
        bic: 'GENODEF1SWO',
        blz: '70169538',
        city: 'Sankt Wolfgang',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18833,
        name: 'VR-Bank Taufkirchen-Dorfen',
        bic: 'GENODEF1TAV',
        blz: '70169566',
        city: 'Taufkirchen (Vils)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17967,
        name: 'Raiffeisenbank Irrel',
        bic: 'GENODED1IRR',
        blz: '57069727',
        city: 'Irrel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17970,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB570',
        blz: '57070024',
        city: 'Koblenz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17843,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF542',
        blz: '54280023',
        city: 'Pirmasens',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18893,
        name: 'Volksbank Raiffeisenbank Rosenheim-Chiemsee',
        bic: 'GENODEF1VRR',
        blz: '71160000',
        city: 'Rosenheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18023,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB587',
        blz: '58771224',
        city: 'Bernkastel-Kues',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18195,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBSTG',
        blz: '60070024',
        city: 'Stuttgart',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18031,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '59040000',
        city: 'Saarbrücken',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18093,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '60040071',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18219,
        name: 'VR-Bank Weinstadt',
        bic: 'GENODES1WNS',
        blz: '60261622',
        city: 'Weinstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18404,
        name: 'Volksbank Horb-Freudenstadt',
        bic: 'GENODES1FDS',
        blz: '64291010',
        city: 'Freudenstadt',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18439,
        name: 'Raiffeisenbank Oberteuringen-Meckenbeuren',
        bic: 'GENODES1OTE',
        blz: '65162832',
        city: 'Oberteuringen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18159,
        name: 'Raiffeisenbank Ehingen-Hochsträß',
        bic: 'GENODES1HOC',
        blz: '60069670',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20431,
        name: 'Raiffeisenbank Region Hausruck eGen',
        bic: 'RZOOAT2L250',
        blz: '34250',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20433,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34233',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20434,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34318',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20435,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34321',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20436,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34362',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18220,
        name: 'Raiffeisenbank Weissacher Tal',
        bic: 'GENODES1RWT',
        blz: '60261818',
        city: 'Allmersbach im Tal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18155,
        name: 'Raiffeisenbank Ingersheim',
        bic: 'GENODES1RIH',
        blz: '60069639',
        city: 'Ingersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18342,
        name: 'Volksbank Bad Mergentheim -alt-',
        bic: 'GENODES1VBM',
        blz: '62391010',
        city: 'Bad Mergentheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18353,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB630',
        blz: '63070024',
        city: 'Ulm',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18269,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB610',
        blz: '61070024',
        city: 'Göppingen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20437,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34427',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18105,
        name: 'Raiffeisenbank Niedere Alb',
        bic: 'GENODES1RBA',
        blz: '60069066',
        city: 'Langenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18145,
        name: 'Raiffeisenbank Ehingen-Hochsträß',
        bic: 'GENODES1OMT',
        blz: '60069520',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18279,
        name: 'Volksbank Filder',
        bic: 'GENODES1NHB',
        blz: '61161696',
        city: 'Neuhausen auf den Fildern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18291,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF612',
        blz: '61281007',
        city: 'Kirchheim unter Teck',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18465,
        name: 'Volksbank Raiffeisenbank Laupheim-Illertal',
        bic: 'GENODES1VBL',
        blz: '65491320',
        city: 'Laupheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18497,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB660',
        blz: '66070024',
        city: 'Karlsruhe',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18867,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF703',
        blz: '70380006',
        city: 'Garmisch-Partenkirchen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18530,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '66440084',
        city: 'Offenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18774,
        name: 'VR-Bank Landsberg-Ammersee',
        bic: 'GENODEF1DSS',
        blz: '70091600',
        city: 'Landsberg am Lech',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17963,
        name: 'Raiffeisenbank Untermosel',
        bic: 'GENODED1UMO',
        blz: '57069257',
        city: 'Treis-Karden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17849,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '54540033',
        city: 'Ludwigshafen am Rhein',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18544,
        name: 'Raiffeisenbank Südhardt Durmersheim',
        bic: 'GENODE61DUR',
        blz: '66562053',
        city: 'Durmersheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17784,
        name: 'VR Bank Fulda',
        bic: 'GENODE51FUL',
        blz: '53060180',
        city: 'Fulda',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17805,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB518',
        blz: '53270024',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17735,
        name: 'Raiffeisenbank Borken Nordhessen',
        bic: 'GENODEF1BOR',
        blz: '52061303',
        city: 'Borken (Hessen)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18033,
        name: 'Sparkasse Saarbrücken',
        bic: 'SAKSDE55XXX',
        blz: '59050101',
        city: 'Saarbrücken',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20245,
        name: 'Raiffeisenbank Hohenems reg.Gen.m.b.H.',
        bic: 'RANMAT21XXX',
        blz: '37438',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20254,
        name: 'Raiffeisenbank Neusiedlersee-Seewinkel eGen',
        bic: 'RLBBAT2E033',
        blz: '33033',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20257,
        name: 'Raiffeisenbank Ilz-Großsteinbach-Riegersburg eGen',
        bic: 'RZSTAT2G151',
        blz: '38326',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20296,
        name: 'Raiffeisenbank Lavamünd, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K292',
        blz: '39292',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20312,
        name: 'Raiffeisenbank Lutzmannsburg - Frankenau eGen',
        bic: 'RLBBAT2E048',
        blz: '33048',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20321,
        name: 'Raiffeisenbank Matrei am Brenner und Umgebung eGen',
        bic: 'RZTIAT22273',
        blz: '36273',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20358,
        name: 'Raiffeisenbank Nestelbach-Eggersdorf eGen',
        bic: 'RZSTAT2G252',
        blz: '38252',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20369,
        name: 'Raiffeisenbank Nördliche Oststeiermark eGen',
        bic: 'RZSTAT2G108',
        blz: '38108',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20374,
        name: 'Raiffeisenbank Oberdrauburg, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K431',
        blz: '39431',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20407,
        name: 'Raiffeisenbank Radenthein-Bad Kleinkirchheim reg.Gen.m.b.H.',
        bic: 'RZKTAT2K457',
        blz: '39457',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20427,
        name: 'Raiffeisenbank Region Gleisdorf eGen',
        bic: 'RZSTAT2G103',
        blz: '38372',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20432,
        name: 'Raiffeisenbank Region Kirchdorf eGen',
        bic: 'RZOOAT2L380',
        blz: '34380',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20464,
        name:
            'Raiffeisenbank Ried in Tirol Fendels-Tösens und Umgebung reg.Gen.m.b.H.',
        bic: 'RZTIAT22306',
        blz: '36306',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20484,
        name: 'Raiffeisenbank Seefeld-Leutasch-Reith-Scharnitz reg.Gen.m.b.H.',
        bic: 'RZTIAT22314',
        blz: '36314',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20511,
        name: 'Raiffeisenbank St. Veit-Schwarzach-Goldegg reg.Gen.m.b.H.',
        bic: 'RVSAAT2S055',
        blz: '35255',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19181,
        name: 'Vereinigte Sparkassen Eschenbach i d Opf',
        bic: 'BYLADEM1ESB',
        blz: '75351960',
        city: 'Neustadt a.d.Waldnaab',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19016,
        name: 'Commerzbank Kempten Allgäu',
        bic: 'COBADEFFXXX',
        blz: '73340046',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19039,
        name: 'Raiffeisenbank Südliches Ostallgäu',
        bic: 'GENODEF1RHP',
        blz: '73369933',
        city: 'Seeg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19074,
        name: 'Raiffeisenbank Südl. Bayerischer Wald',
        bic: 'GENODEF1HZN',
        blz: '74066749',
        city: 'Hauzenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19091,
        name: 'Raiffeisenbank Deggendorf-Plattling-Sonnenwald',
        bic: 'GENODEF1DEG',
        blz: '74160025',
        city: 'Deggendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19139,
        name: 'Commerzbank Regensburg',
        bic: 'COBADEFFXXX',
        blz: '75040062',
        city: 'Regensburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19202,
        name: 'Sparkasse Neumarkt i d OPf-Parsberg',
        bic: 'BYLADEM1NMA',
        blz: '76052080',
        city: 'Neumarkt i.d.OPf.',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19355,
        name: 'Sparkasse Mainfranken Würzburg',
        bic: 'BYLADEM1SWU',
        blz: '79050000',
        city: 'Würzburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19198,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '76040060',
        city: 'Nürnberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19392,
        name: 'Sparkasse Bad Neustadt a d Saale',
        bic: 'BYLADEM1NES',
        blz: '79353090',
        city: 'Bad Neustadt a.d.Saale',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19257,
        name: 'Commerzbank Fürth Bayern',
        bic: 'COBADEFFXXX',
        blz: '76240011',
        city: 'Fürth',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19289,
        name: 'Raiffeisen-Volksbank Bad Staffelstein',
        bic: 'GENODEF1SFF',
        blz: '77062139',
        city: 'Bad Staffelstein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19317,
        name: 'VR Bank Oberfranken Mitte',
        bic: 'GENODEF1KU1',
        blz: '77190000',
        city: 'Kulmbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20054,
        name: 'Allgemeine Sparkasse Oberösterreich BankAG',
        bic: 'ASPKAT2LXXX',
        blz: '20315',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19379,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ28',
        blz: '79080085',
        city: 'Würzburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19003,
        name: 'Sparkasse Memmingen-Lindau-Mindelheim',
        bic: 'BYLADEM1MLM',
        blz: '73150000',
        city: 'Memmingen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19440,
        name: 'Volksbank Elsterland',
        bic: 'GENODEF1JE1',
        blz: '80062608',
        city: 'Jessen (Elster)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19445,
        name: 'Volks- und Raiffeisenbank Saale-Unstrut',
        bic: 'GENODEF1NMB',
        blz: '80063648',
        city: 'Merseburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19502,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBERF',
        blz: '82070024',
        city: 'Erfurt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19517,
        name: 'Raiffeisen-Volksbank Hermsdorfer Kreuz',
        bic: 'GENODEF1HMF',
        blz: '83064488',
        city: 'Hermsdorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19563,
        name: 'Volksbank Raiffeisenbank Meißen Großenhain',
        bic: 'GENODEF1MEI',
        blz: '85095004',
        city: 'Meißen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19592,
        name: 'Wurzener Bank (Raiffeisen-Volksbank) -alt-',
        bic: 'GENODEF1WUZ',
        blz: '86065548',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19625,
        name: 'Volksbank Vogtland',
        bic: 'GENODEF1PL1',
        blz: '87095824',
        city: 'Plauen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19141,
        name: 'Sparkasse im Landkreis Schwandorf',
        bic: 'BYLADEM1SAD',
        blz: '75051040',
        city: 'Schwandorf',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20568,
        name: 'Raiffeisenbank Wagrain-Kleinarl eGen',
        bic: 'RVSAAT2S069',
        blz: '35169',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19703,
        name: 'Commerzbank vormals Dresdner Bank IBLZ',
        bic: 'DRESDEFFXXX',
        blz: '10080088',
        city: 'Berlin',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19708,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFFXXX',
        blz: '50080000',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19903,
        name: 'Commerzbank, INT 1',
        bic: 'COBADEFFXXX',
        blz: '50040088',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19960,
        name: 'Commerzbank, Filiale Frankfurt 2',
        bic: 'COBADEFFXXX',
        blz: '50040005',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 20034,
        name: 'VR-Bank Bad Salzungen Schmalkalden GAA',
        bic: 'GENODEF1SAL',
        blz: '84094755',
        city: 'Bad Salzungen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20103,
        name: 'Raiffeisenbank Alberschwende reg.Gen.m.b.H.',
        bic: 'RVVGAT2B401',
        blz: '37401',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20080,
        name: 'Raiffeisen Bank International AG',
        bic: 'RZBAATWWXXX',
        blz: '31000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20138,
        name: 'Raiffeisenbank Bludenz-Montafon eGen',
        bic: 'RVVGAT2B468',
        blz: '37468',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20139,
        name: 'Raiffeisenbank Bludenz-Montafon eGen',
        bic: 'RVVGAT2B468',
        blz: '37410',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20173,
        name: 'Raiffeisenbank Feldbach-Gnas-Bad Gleichenberg eGen',
        bic: 'RZSTAT2G497',
        blz: '38104',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20196,
        name: 'Raiffeisenbank Gramastetten-Herzogsdorf eGen',
        bic: 'RZOOAT2L135',
        blz: '34135',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20207,
        name: 'Raiffeisenbank Groß St. Florian-Wettmannstätten eGen',
        bic: 'RZSTAT2G118',
        blz: '38118',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20602,
        name: 'Raiffeisenbank Zirbenland eGen',
        bic: 'RZSTAT2G368',
        blz: '38261',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17755,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB521',
        blz: '52071224',
        city: 'Bad Wildungen',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17299,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB472',
        blz: '47270024',
        city: 'Paderborn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17435,
        name: 'Raiffeisenkasse Erbes-Büdesheim und Umgebung',
        bic: 'GENODE51ERB',
        blz: '50069241',
        city: 'Erbes-Büdesheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18036,
        name: 'Deutsche Bank Saarbruecken',
        bic: 'DEUTDE5M555',
        blz: '59070000',
        city: 'Saarbrücken',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17645,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB511',
        blz: '51170024',
        city: 'Limburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18280,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB611',
        blz: '61170024',
        city: 'Esslingen am Neckar',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18536,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB968',
        blz: '66470024',
        city: 'Offenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20689,
        name: 'Sparkasse Mürzzuschlag AG',
        bic: 'SPMZAT21XXX',
        blz: '20828',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18356,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFI59',
        blz: '63080085',
        city: 'Stuttgart',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18681,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB690',
        blz: '69070024',
        city: 'Konstanz',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18662,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB683',
        blz: '68370024',
        city: 'Lörrach',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18638,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBFRE',
        blz: '68070024',
        city: 'Freiburg im Breisgau',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19118,
        name: 'Raiffeisenbank Pfeffenhausen-Rottenburg-Wildenberg',
        bic: 'GENODEF1PFF',
        blz: '74364689',
        city: 'Pfeffenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17708,
        name: 'Sparkasse Battenberg',
        bic: 'HELADEF1BAT',
        blz: '51752267',
        city: 'Battenberg (Eder)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19043,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB733',
        blz: '73370024',
        city: 'Kempten (Allgäu)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19412,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB795',
        blz: '79570024',
        city: 'Aschaffenburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18814,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1MTW',
        blz: '70169459',
        city: 'Mittenwald',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19475,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBMAG',
        blz: '81070024',
        city: 'Magdeburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20588,
        name: 'Raiffeisenbank Wernberg, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K559',
        blz: '39559',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18813,
        name: 'Raiffeisen-Volksbank Ebersberg',
        bic: 'GENODEF1ASG',
        blz: '70169450',
        city: 'Grafing b. München',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19557,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 1',
        bic: 'DRESDEFFJ29',
        blz: '85080085',
        city: 'Dresden',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20612,
        name:
            'Raiffeisen-Bezirksbank St. Veit a.d. Glan-Feldkirchen, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K475',
        blz: '39475',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20720,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '43920',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16056,
        name: 'Hamburger Volksbank',
        bic: 'GENODEF1HH2',
        blz: '20190003',
        city: 'Hamburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20630,
        name: 'Raiffeisenkasse Pottschach reg.Gen.m.b.H.',
        bic: 'RLNWATW1660',
        blz: '32660',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18812,
        name: 'Raiffeisenbank im Isarwinkel -alt-',
        bic: 'GENODEF1LGR',
        blz: '70169444',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20109,
        name: 'Raiffeisenbank Althofen - Guttaring, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K255',
        blz: '39255',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17548,
        name: 'Städtische Sparkasse Offenbach a.M.',
        bic: 'HELADEF1OFF',
        blz: '50550020',
        city: 'Offenbach am Main',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20823,
        name: 'Deutsche Bank AG Filiale Wien',
        bic: 'DEUTATWWXXX',
        blz: '19100',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: false
    },
    {
        id: 20714,
        name: 'Volksbank Niederösterreich AG',
        bic: 'VBOEATWWNOM',
        blz: '40170',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19940,
        name: 'Commerzbank, Filiale Hamburg 2',
        bic: 'COBADEHHXXX',
        blz: '20040005',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 20725,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '42830',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20929,
        name: 'VR-Bank Braunau Zweigniederlassung der VR-Bank Rottal-Inn eG',
        bic: 'VRBKAT21XXX',
        blz: '49500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21010,
        name: 'Hamburger Volksbank (Gf GAA)',
        bic: 'GENODEF1HH2',
        blz: '20190077',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21011,
        name: 'Volksbank Raiffeisenbank Dachau (Gf GAA)',
        bic: 'GENODEF1DCA',
        blz: '70091510',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19934,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '36040085',
        city: 'Essen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 17707,
        name: 'Volksbank Herborn-Eschenburg',
        bic: 'GENODE51HER',
        blz: '51691500',
        city: 'Herborn',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20981,
        name: 'Raiffeisen Factor Bank AG',
        blz: '31100',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18963,
        name: 'Sparkasse Ingolstadt Eichstätt',
        bic: 'BYLADEM1ING',
        blz: '72150000',
        city: 'Ingolstadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20982,
        name: 'Raiffeisen Immobilien Kapitalanlage Gesellschaft m.b.H.',
        blz: '76281',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19935,
        name: 'Hannoversche Volksbank GS nur für GAA',
        bic: 'VOHADE2HXXX',
        blz: '25190088',
        city: 'Lindwedel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21014,
        name: 'ZVC FK 53 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFF053',
        blz: '59010053',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 21017,
        name: 'ZVC FK 57 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010057',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 16316,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 2',
        bic: 'DRESDEFFI65',
        blz: '25080085',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16136,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB210',
        blz: '21070024',
        city: 'Kiel',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16982,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK) F 950',
        bic: 'DEUTDEDBXXX',
        blz: '38070724',
        city: 'Bonn',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 16236,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1RIT',
        blz: '22260136',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16854,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE362',
        blz: '36270048',
        city: 'Mülheim an der Ruhr',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18792,
        name: 'Raiffeisenbank Griesstätt-Halfing',
        bic: 'GENODEF1HFG',
        blz: '70169132',
        city: 'Halfing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18811,
        name: 'Raiffeisenbank Königsdorf-Gelting',
        bic: 'GENODEF1KOG',
        blz: '70169433',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18793,
        name: 'Raiffeisenbank Chiemgau-Nord - Obing',
        bic: 'GENODEF1SBC',
        blz: '70169165',
        city: 'Obing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18120,
        name: 'Volksbank Freiberg und Umgebung',
        bic: 'GENODES1MDH',
        blz: '60069315',
        city: 'Mundelsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20317,
        name: 'Raiffeisenbank Mariapfarr eGen',
        bic: 'RVSAAT2S036',
        blz: '35036',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20316,
        name: 'Raiffeisenbank Maria Schmolln-St. Johann eGen',
        bic: 'RZOOAT2L312',
        blz: '34312',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20318,
        name: 'Raiffeisenbank Mariazellerland eGen',
        bic: 'RZSTAT2G129',
        blz: '38129',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21045,
        name: 'Raiffeisenlandesbank OÖ Zndl Südde - für interne Zwecke',
        bic: 'RZOODE77050',
        blz: '74020150',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16788,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB340',
        blz: '34070024',
        city: 'Remscheid',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19628,
        name: 'Volksbank-Raiffeisenbank Glauchau',
        bic: 'GENODEF1GC1',
        blz: '87095974',
        city: 'Glauchau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15931,
        name: 'Sparkasse Uecker-Randow',
        bic: 'NOLADE21PSW',
        blz: '15050400',
        city: 'Pasewalk',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19942,
        name: 'Commerzbank, Gf Web-K',
        bic: 'COBADEFFXXX',
        blz: '82040085',
        city: 'Erfurt',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 19130,
        name: 'VR-Bank Landshut',
        bic: 'GENODEF1LH1',
        blz: '74390000',
        city: 'Landshut',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15926,
        name: 'VR-Bank',
        bic: 'GENODEF1SN1',
        blz: '14091464',
        city: 'Schwerin',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17098,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '41240048',
        city: 'Beckum',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17096,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB410',
        blz: '41070024',
        city: 'Hamm',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18724,
        name: 'Südtiroler Sparkasse Niederlassung München',
        bic: 'SUSKDEM1XXX',
        blz: '70012600',
        city: 'München',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19125,
        name: 'Raiffeisenbank Essenbach',
        bic: 'GENODEF1ENA',
        blz: '74369656',
        city: 'Essenbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17097,
        name: 'Deutsche Bank',
        bic: 'DEUTDEDE410',
        blz: '41070049',
        city: 'Hamm',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 18616,
        name: 'Raiffeisenbank Schefflenz-Seckach -alt-',
        bic: 'GENODE61SOB',
        blz: '67462480',
        city: 'Schefflenz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20319,
        name: 'Raiffeisenbank Markt Hartmannsdorf eGen',
        bic: 'RZSTAT2G131',
        blz: '38131',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20320,
        name: 'Raiffeisenbank Markt Neukirchen eGen',
        bic: 'RVSAAT2S042',
        blz: '35042',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20322,
        name:
            'Raiffeisenbank Matrei in Osttirol, Virgen-Prägraten-Kals am Großglockner reg.Gen.m.b.H.',
        bic: 'RZTIAT22378',
        blz: '36378',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20505,
        name:
            'Raiffeisenbank St. Paul im Lavanttal mit Zweiganstalten Maria Rojach und St. Georgen, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K500',
        blz: '39500',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17095,
        name: 'Volksbank Bönen',
        bic: 'GENODEM1BO1',
        blz: '41062215',
        city: 'Bönen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15929,
        name: 'Müritz-Sparkasse',
        bic: 'NOLADE21WRN',
        blz: '15050100',
        city: 'Waren (Müritz)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 15932,
        name: 'Sparkasse Vorpommern',
        bic: 'NOLADE21GRW',
        blz: '15050500',
        city: 'Greifswald, Hansestadt',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18591,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '67240039',
        city: 'Heidelberg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18123,
        name: 'Raiffeisenbank Rißtal',
        bic: 'GENODES1RRI',
        blz: '60069343',
        city: 'Laupheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 15928,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '15040068',
        city: 'Neubrandenburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18117,
        name: 'Raiffeisenbank Erlenmoos',
        bic: 'GENODES1ERM',
        blz: '60069302',
        city: 'Erlenmoos',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18122,
        name: 'Raiffeisenbank Maitis',
        bic: 'GENODES1RMA',
        blz: '60069336',
        city: 'Göppingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19127,
        name: 'Raiffeisenbank Mengkofen-Loiching',
        bic: 'GENODEF1MKO',
        blz: '74369704',
        city: 'Mengkofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17710,
        name: 'Sparkasse Oberhessen',
        bic: 'HELADEF1FRI',
        blz: '51850079',
        city: 'Friedberg (Hessen)',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18650,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB682',
        blz: '68270024',
        city: 'Lahr',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17709,
        name: 'VR Bank Lahn-Dill',
        bic: 'GENODE51BIK',
        blz: '51762434',
        city: 'Biedenkopf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17711,
        name: 'BVB Volksbank Ndl d Frankfurter Volksbank',
        bic: 'GENODEF1BVB',
        blz: '51861325',
        city: 'Bad Vilbel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18761,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDBMUC',
        blz: '70070024',
        city: 'München',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19129,
        name: 'Commerzbank vormals Dresdner Bank',
        bic: 'DRESDEFF743',
        blz: '74380007',
        city: 'Landshut',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18118,
        name: 'Raiffeisenbank Bad Schussenried',
        bic: 'GENODES1RBS',
        blz: '60069303',
        city: 'Bad Schussenried',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18119,
        name: 'Raiffeisenbank',
        bic: 'GENODES1RIN',
        blz: '60069308',
        city: 'Ingoldingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20658,
        name:
            'Raiffeisenlandesbank Vorarlberg Waren- und Revisionsverband reg.Gen.m.b.H.',
        bic: 'RVVGAT2BXXX',
        blz: '37000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17782,
        name: 'Sparkasse Fulda',
        bic: 'HELADEF1FDS',
        blz: '53050180',
        city: 'Fulda',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19128,
        name: 'Raiffeisenbank Wildenberg',
        bic: 'GENODEF1WIG',
        blz: '74369709',
        city: 'Wildenberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 15930,
        name: 'Sparkasse Neubrandenburg-Demmin',
        bic: 'NOLADE21NBS',
        blz: '15050200',
        city: 'Neubrandenburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19126,
        name: 'Raiffeisenbank Buch-Eching',
        bic: 'GENODEF1EBV',
        blz: '74369662',
        city: 'Eching',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20799,
        name: 'Bausparkasse der österreichischen Sparkassen AG',
        bic: 'BAOSATWWXXX',
        blz: '24012',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17099,
        name: 'Sparkasse Beckum-Wadersloh',
        bic: 'WELADED1BEK',
        blz: '41250035',
        city: 'Beckum',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18790,
        name: 'Raiffeisenbank München -alt-',
        bic: 'GENODEF1M02',
        blz: '70160300',
        city: 'München',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18791,
        name: 'Volksbank Raiffeisenbank Fürstenfeldbruck',
        bic: 'GENODEF1FFB',
        blz: '70163370',
        city: 'Fürstenfeldbruck',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17674,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '51343224',
        city: 'Alsfeld',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20758,
        name: 'Waldviertler Sparkasse Bank AG',
        bic: 'SPZWAT21XXX',
        blz: '20272',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 20759,
        name: 'Waldviertler Sparkasse Bank AG',
        bic: 'SPZWAT21XXX',
        blz: '20263',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 17678,
        name: 'Volksbank Heuchelheim',
        bic: 'GENODE51HHE',
        blz: '51361021',
        city: 'Heuchelheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18603,
        name: 'Volksbank Neckartal',
        bic: 'GENODE61NGD',
        blz: '67291700',
        city: 'Eberbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18604,
        name: 'Volksbank Kraichgau -alt-',
        bic: 'GENODE61SSH',
        blz: '67291900',
        city: 'Sinsheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18670,
        name: 'Volksbank Hochrhein',
        bic: 'GENODE61WT1',
        blz: '68492200',
        city: 'Waldshut-Tiengen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19710,
        name: 'Commerzbank vormals Dresdner Bank Gf DrKW',
        bic: 'DRESDEFFXXX',
        blz: '50080060',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19711,
        name: 'Commerzbank vormals Dresdner Bank ESOP',
        bic: 'DRESDEFFXXX',
        blz: '50080079',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20741,
        name: 'Volksbank Tirol AG',
        bic: 'VBOEATWWINN',
        blz: '42390',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20742,
        name: 'Volksbank Tirol AG',
        bic: 'VBOEATWWINN',
        blz: '43770',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20743,
        name: 'Volksbank Tirol AG',
        bic: 'VBOEATWWINN',
        blz: '45850',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18759,
        name: 'Sparkasse Bad Tölz-Wolfratshausen',
        bic: 'BYLADEM1WOR',
        blz: '70054306',
        city: 'Bad Tölz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20675,
        name: 'Sparkasse Eferding-Peuerbach-Waizenkirchen',
        bic: 'SPPBAT21XXX',
        blz: '20330',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19112,
        name: 'Sparkasse Landshut',
        bic: 'BYLADEM1LAH',
        blz: '74350000',
        city: 'Landshut',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19113,
        name: 'Sparkasse Dingolfing-Landau -alt-',
        bic: 'BYLADEM1DGF',
        blz: '74351310',
        city: 'Dingolfing',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 19072,
        name: 'Raiffeisenbank Fürstenzell -alt-',
        blz: '74064742',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18951,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB720',
        blz: '72070024',
        city: 'Augsburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19073,
        name: 'Raiffeisenbank Salzweg-Thyrnau',
        bic: 'GENODEF1SZT',
        blz: '74065782',
        city: 'Salzweg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19023,
        name: 'Raiffeisenbank im Allgäuer Land',
        bic: 'GENODEF1DTA',
        blz: '73369264',
        city: 'Dietmannsried',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19025,
        name: 'Raiffeisenbank Westallgäu',
        bic: 'GENODEF1WWA',
        blz: '73369823',
        city: 'Gestratz',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19026,
        name: 'Raiffeisenbank Heimenkirch-Ellhofen -alt-',
        bic: 'GENODEF1HKE',
        blz: '73369824',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19027,
        name: 'Volksbank',
        bic: 'GENODEF1LIA',
        blz: '73369826',
        city: 'Lindenberg i. Allgäu',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19028,
        name: 'Raiffeisenbank Aitrang-Ruderatshofen',
        bic: 'GENODEF1AIT',
        blz: '73369851',
        city: 'Aitrang',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19029,
        name: 'Raiffeisenbank Fuchstal-Denklingen',
        bic: 'GENODEF1FCH',
        blz: '73369854',
        city: 'Fuchstal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19030,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1BIN',
        blz: '73369859',
        city: 'Bidingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19031,
        name: 'Raiffeisenbank Baisweil-Eggenthal-Friesenried',
        bic: 'GENODEF1EGB',
        blz: '73369871',
        city: 'Eggenthal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19032,
        name: 'Raiffeisenbank Füssen-Pfronten-Nesselwang',
        bic: 'GENODEF1FPN',
        blz: '73369878',
        city: 'Füssen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19106,
        name: 'Volksbank Straubing',
        bic: 'GENODEF1SR1',
        blz: '74290000',
        city: 'Straubing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19111,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '74340077',
        city: 'Landshut',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19114,
        name: 'Sparkasse Rottal-Inn',
        bic: 'BYLADEM1EGF',
        blz: '74351430',
        city: 'Eggenfelden',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17670,
        name: 'Taunus-Sparkasse',
        bic: 'HELADEF1TSK',
        blz: '51250000',
        city: 'Bad Homburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17677,
        name: 'Sparkasse Laubach-Hungen',
        bic: 'HELADEF1LAU',
        blz: '51352227',
        city: 'Laubach',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 19116,
        name: 'Raiffeisenbank Arnstorf',
        bic: 'GENODEF1ARF',
        blz: '74361211',
        city: 'Arnstorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19117,
        name: 'Raiffeisenbank Altdorf-Ergolding',
        bic: 'GENODEF1ERG',
        blz: '74362663',
        city: 'Ergolding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16695,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 8',
        bic: 'DRESDEFFI81',
        blz: '30080085',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18956,
        name: 'Volksbank Günzburg',
        bic: 'GENODEF1GZ1',
        blz: '72091800',
        city: 'Günzburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17593,
        name: 'Raiffeisenbank Nördliche Bergstraße',
        bic: 'GENODE51ABH',
        blz: '50861501',
        city: 'Alsbach-Hähnlein',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17597,
        name: 'Raiffeisenbank Schaafheim',
        bic: 'GENODE51SHM',
        blz: '50862835',
        city: 'Schaafheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17598,
        name: 'Volksbank Mainspitze',
        bic: 'GENODE51GIN',
        blz: '50862903',
        city: 'Ginsheim-Gustavsburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17675,
        name: 'Sparkasse Gießen',
        bic: 'SKGIDE5FXXX',
        blz: '51350025',
        city: 'Gießen',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17676,
        name: 'Sparkasse Grünberg',
        bic: 'HELADEF1GRU',
        blz: '51351526',
        city: 'Grünberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17719,
        name: 'Volksbank Lauterbach-Schlitz',
        bic: 'GENODE51LB1',
        blz: '51990000',
        city: 'Lauterbach (Hessen)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17722,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '52040021',
        city: 'Kassel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20541,
        name: 'Raiffeisenbank Taxenbach reg.Gen.m.b.H.',
        bic: 'RVSAAT2S064',
        blz: '35064',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20550,
        name: 'Raiffeisenbank Timelkam-Lenzing-Puchkirchen eGen',
        bic: 'RZOOAT2L669',
        blz: '34669',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18809,
        name: 'Raiffeisenbank Holzkirchen-Otterfing',
        bic: 'GENODEF1HZO',
        blz: '70169410',
        city: 'Holzkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19706,
        name: 'Commerzbank vormals Dresdner Bank Zw 96',
        bic: 'DRESDEFFXXX',
        blz: '37080096',
        city: 'Köln',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20740,
        name: 'Volksbank Steirisches Salzkammergut, reg.Gen.m.b.H.',
        bic: 'VOSSAT2102G',
        blz: '42740',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19071,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1WSD',
        blz: '74064593',
        city: 'Wegscheid',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18927,
        name: 'Raiffeisenbank Höchstädt u. U. -alt-',
        blz: '72069108',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16696,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 9',
        bic: 'DRESDEFFI82',
        blz: '30080086',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16697,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 10',
        bic: 'DRESDEFFI83',
        blz: '30080087',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16698,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 11',
        bic: 'DRESDEFFI84',
        blz: '30080088',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16699,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 12',
        bic: 'DRESDEFFI85',
        blz: '30080089',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16700,
        name: 'Commerzbank vormals Dresdner Bank Zw 95',
        bic: 'DRESDEFFI33',
        blz: '30080095',
        city: 'Düsseldorf',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 17673,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '51340013',
        city: 'Gießen',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18750,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '70040063',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18751,
        name: 'Commerzbank, CC SP',
        bic: 'COBADEFFXXX',
        blz: '70040070',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: false
    },
    {
        id: 18815,
        name: 'Raiffeisenbank Westkreis Fürstenfeldbruck',
        bic: 'GENODEF1MOO',
        blz: '70169460',
        city: 'Moorenweis',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18816,
        name: 'Raiffeisenbank München-Nord',
        bic: 'GENODEF1M08',
        blz: '70169465',
        city: 'Unterschleißheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18817,
        name: 'Raiffeisenbank München-Süd',
        bic: 'GENODEF1M03',
        blz: '70169466',
        city: 'München',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18818,
        name: 'Raiffeisenbank München-Süd Gf GA',
        bic: 'GENODEF1GAA',
        blz: '70169470',
        city: 'München',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18819,
        name: 'Raiffeisenbank Hallbergmoos-Neufahrn',
        bic: 'GENODEF1NFA',
        blz: '70169472',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18821,
        name: 'Raiffeisenbank -alt-',
        bic: 'GENODEF1NBK',
        blz: '70169476',
        city: 'Niederbergkirchen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18822,
        name: 'Raiffeisenbank Oberschleißheim',
        bic: 'GENODEF1OBS',
        blz: '70169493',
        city: 'Oberschleißheim',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18823,
        name: 'Raiffeisenbank Buchbach-Schwindegg -alt-',
        bic: 'GENODEF1SGO',
        blz: '70169495',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18824,
        name: 'Raiffeisenbank Anzing-Forstern -alt-',
        bic: 'GENODEF1FOA',
        blz: '70169505',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18825,
        name: 'Raiffeisenbank Pfaffenwinkel',
        bic: 'GENODEF1PEI',
        blz: '70169509',
        city: 'Peiting',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18826,
        name: 'Raiffeisenbank Raisting',
        bic: 'GENODEF1RIG',
        blz: '70169521',
        city: 'Raisting',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18827,
        name: 'Raiffeisenbank RSA',
        bic: 'GENODEF1RME',
        blz: '70169524',
        city: 'Rechtmehring',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18828,
        name: 'Raiffeisenbank Neumarkt-St. Veit - Reischach',
        bic: 'GENODEF1RWZ',
        blz: '70169530',
        city: 'Reischach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18830,
        name: 'Raiffeisenbank Lech-Ammersee',
        bic: 'GENODEF1THG',
        blz: '70169541',
        city: 'Vilgertshofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18831,
        name: 'Raiffeisenbank Isar-Loisachtal',
        bic: 'GENODEF1HHS',
        blz: '70169543',
        city: 'Wolfratshausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18832,
        name: 'Raiffeisenbank Steingaden',
        bic: 'GENODEF1SGA',
        blz: '70169558',
        city: 'Steingaden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18775,
        name: 'VR-Bank Erding',
        bic: 'GENODEF1EDV',
        blz: '70091900',
        city: 'Erding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18794,
        name: 'VR-Bank Chiemgau-Süd -alt-',
        bic: 'GENODEF1RIW',
        blz: '70169168',
        city: 'Ruhpolding',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18795,
        name: 'Raiffeisenbank Pfaffenhofen a d Glonn',
        bic: 'GENODEF1ODZ',
        blz: '70169186',
        city: 'Odelzhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18797,
        name: 'Raiffeisenbank Rupertiwinkel',
        bic: 'GENODEF1TEI',
        blz: '70169191',
        city: 'Teisendorf',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18798,
        name: 'Raiffeisenbank Trostberg-Traunreut',
        bic: 'GENODEF1TRU',
        blz: '70169195',
        city: 'Traunreut',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18800,
        name: 'Raiffeisenbank Aufkirchen -alt-',
        bic: 'GENODEF1AFK',
        blz: '70169322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20214,
        name: 'Raiffeisenbank Almtal eGen',
        bic: 'RZOOAT2L127',
        blz: '34127',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20216,
        name: 'Raiffeisenbank Gunskirchen eGen',
        bic: 'RZOOAT2L129',
        blz: '34430',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20217,
        name: 'Raiffeisenbank Gurktal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K511',
        blz: '39511',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19119,
        name: 'Raiffeisenbank Geisenhausen',
        bic: 'GENODEF1GSH',
        blz: '74366666',
        city: 'Geisenhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19077,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1GRT',
        blz: '74069744',
        city: 'Grainet',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19121,
        name: 'Raiffeisenbank Geiselhöring-Pfaffenberg',
        bic: 'GENODEF1GPF',
        blz: '74369088',
        city: 'Geiselhöring',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19122,
        name: 'Raiffeisenbank Straubing',
        blz: '74369091',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19123,
        name: 'Raiffeisenbank Parkstetten',
        bic: 'GENODEF1PST',
        blz: '74369130',
        city: 'Parkstetten',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19131,
        name: 'Volksbank-Raiffeisenbank Dingolfing',
        bic: 'GENODEF1DGF',
        blz: '74391300',
        city: 'Dingolfing',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19132,
        name: 'VR-Bank Rottal-Inn',
        bic: 'GENODEF1EGR',
        blz: '74391400',
        city: 'Eggenfelden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19133,
        name: 'VR-Bank Isar-Vils',
        bic: 'GENODEF1VBV',
        blz: '74392300',
        city: 'Vilsbiburg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19075,
        name: 'Rottaler Raiffeisenbank',
        bic: 'GENODEF1POC',
        blz: '74067000',
        city: 'Pocking',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20154,
        name: 'Raiffeisenbank Donau-Ameisberg eGen',
        bic: 'RZOOAT2L075',
        blz: '34075',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20215,
        name: 'Raiffeisenbank Gunskirchen eGen',
        bic: 'RZOOAT2L129',
        blz: '34129',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20639,
        name: 'Raiffeisenkasse Werfen reg.Gen.m.b.H.',
        bic: 'RVSAAT2S072',
        blz: '35072',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20640,
        name: 'Raiffeisenkasse Werfen reg.Gen.m.b.H.',
        bic: 'RVSAAT2S072',
        blz: '35172',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20641,
        name: 'Raiffeisenkasse Werfen reg.Gen.m.b.H.',
        bic: 'RVSAAT2S072',
        blz: '35272',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18777,
        name: 'VR-Bank Ismaning Hallbergmoos Neufahrn',
        bic: 'GENODEF1ISV',
        blz: '70093400',
        city: 'Ismaning',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20642,
        name: 'Raiffeisenkasse Wiesmath-Hochwolkersdorf eGen',
        bic: 'RLNWATW1940',
        blz: '32940',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19709,
        name: 'Commerzbank vormals Dresdner Bank Zw 25',
        bic: 'DRESDEFFXXX',
        blz: '50080025',
        city: 'Frankfurt am Main',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19076,
        name: 'Raiffeisenbank Viechtach-Zwiesel',
        blz: '74069186',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17798,
        name: 'Raiffeisenbank Asbach-Sorga',
        bic: 'GENODEF1HFA',
        blz: '53260145',
        city: 'Bad Hersfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19120,
        name: 'Raiffeisenbank Hofkirchen-Bayerbach',
        bic: 'GENODEF1LWE',
        blz: '74369068',
        city: 'Laberweinting',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17800,
        name: 'Raiffeisenbank Werratal-Landeck',
        bic: 'GENODEF1RAW',
        blz: '53261342',
        city: 'Heringen (Werra)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17801,
        name: 'Raiffeisenbank Aulatal -alt-',
        bic: 'GENODEF1OAU',
        blz: '53261700',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17802,
        name: 'Raiffeisenbank Haunetal',
        bic: 'GENODEF1HNT',
        blz: '53262073',
        city: 'Haunetal',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18019,
        name: 'Volksbank Eifel Mitte',
        bic: 'GENODED1PRU',
        blz: '58691500',
        city: 'Prüm',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18746,
        name: 'Commerzbank GF-M48',
        bic: 'COBADEFFXXX',
        blz: '70040048',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 19124,
        name: 'Raiffeisenbank Rattiszell-Konzell',
        bic: 'GENODEF1RZK',
        blz: '74369146',
        city: 'Rattiszell',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18745,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '70040041',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18747,
        name: 'Commerzbank Gf 860',
        bic: 'COBADEFFXXX',
        blz: '70040060',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18748,
        name: 'Commerzbank Gf 861',
        bic: 'COBADEFFXXX',
        blz: '70040061',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18749,
        name: 'Commerzbank CC',
        bic: 'COBADEFFXXX',
        blz: '70040062',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18841,
        name: 'Raiffeisenbank im Oberland',
        bic: 'GENODEF1MIB',
        blz: '70169598',
        city: 'Miesbach',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18842,
        name: 'Raiffeisenbank Weil u Umgebung',
        bic: 'GENODEF1WEI',
        blz: '70169599',
        city: 'Weil',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18843,
        name: 'Raiffeisenbank Weilheim',
        bic: 'GENODEF1WM1',
        blz: '70169602',
        city: 'Weilheim i. OB',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18844,
        name: 'VR-Bank Erding',
        bic: 'GENODEF1ISE',
        blz: '70169605',
        city: 'Isen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20357,
        name: 'Raiffeisenbank Neckenmarkt eGen',
        bic: 'RLBBAT2E058',
        blz: '33058',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20359,
        name: 'Raiffeisenbank Nestelbach-Eggersdorf eGen',
        bic: 'RZSTAT2G252',
        blz: '38055',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20360,
        name: 'Raiffeisenbank Neukirchen an der Vöckla eGen',
        bic: 'RZOOAT2L356',
        blz: '34356',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20361,
        name: 'Raiffeisenbank Neumarkt-Oberwölz eGen',
        bic: 'RZSTAT2G402',
        blz: '38402',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20362,
        name: 'Raiffeisenbank Neumarkt-Oberwölz eGen',
        bic: 'RZSTAT2G402',
        blz: '38452',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20363,
        name: 'Raiffeisenbank Schneebergland eGen',
        bic: 'RLNWATWWNSM',
        blz: '32865',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20364,
        name: 'Raiffeisenbank Neusiedlersee-Hügelland eGen',
        bic: 'RLBBAT2E012',
        blz: '33012',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20365,
        name: 'Raiffeisenbank Neusiedlersee-Hügelland eGen',
        bic: 'RLBBAT2E012',
        blz: '33092',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20366,
        name: 'Raiffeisenbank Neustift-Mieders-Schönberg im Stubaital eGen',
        bic: 'RZTIAT22285',
        blz: '36285',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20367,
        name: 'Raiffeisenbank Niedernsill eGen',
        bic: 'RVSAAT2S043',
        blz: '35043',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20368,
        name: 'Raiffeisenbank Niederwaldkirchen eGen',
        bic: 'RZOOAT2L361',
        blz: '34361',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20314,
        name: 'Raiffeisenbank Maltatal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K400',
        blz: '39400',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18923,
        name: 'VR-Bank Donau-Mindel',
        bic: 'GENODEF1GZ2',
        blz: '72069043',
        city: 'Dillingen a.d.Donau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20315,
        name: 'Raiffeisenbank Maria Saal reg.Gen.m.b.H.',
        bic: 'RZKTAT2K404',
        blz: '39404',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20323,
        name: 'Raiffeisenbank Mattigtal eGen',
        bic: 'RZOOAT2L303',
        blz: '34303',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20324,
        name: 'Raiffeisenbank Mauterndorf-Tweng-Obertauern eGen',
        bic: 'RVSAAT2S037',
        blz: '35037',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20325,
        name: 'Raiffeisenbank Mayrhofen und Umgebung eGen',
        bic: 'RZTIAT22274',
        blz: '36274',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20326,
        name: 'Raiffeisenbank Meggenhofen-Kematen eGen',
        bic: 'RZOOAT2L313',
        blz: '34313',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20327,
        name: 'Raiffeisenbank Metnitz und Umgebung reg.Gen.m.b.H.',
        bic: 'RZKTAT2K407',
        blz: '39407',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20328,
        name: 'Raiffeisenbank Michaelbeuern eGen',
        bic: 'RVSAAT2S038',
        blz: '35038',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20330,
        name: 'Raiffeisenbank Millstättersee eG',
        bic: 'RZKTAT2K479',
        blz: '39479',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20331,
        name: 'Raiffeisenbank Mittelbregenzerwald reg.Gen.m.b.H.',
        bic: 'RVVGAT2B423',
        blz: '37423',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20329,
        name: 'Raiffeisenbank Michelhausen eGen',
        bic: 'RLNWATW1497',
        blz: '32497',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20332,
        name: 'Raiffeisenbank Mittelburgenland Ost eGen',
        bic: 'RLBBAT2E010',
        blz: '33010',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20335,
        name:
            'Raiffeisenbank Mittersill-Hollersbach-Stuhlfelden reg.Gen.m.b.H.',
        bic: 'RVSAAT2S039',
        blz: '35239',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20333,
        name: 'Raiffeisenbank Oberpinzgau eGen',
        bic: 'RVSAAT2S039',
        blz: '35039',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20334,
        name:
            'Raiffeisenbank Mittersill-Hollersbach-Stuhlfelden reg.Gen.m.b.H.',
        bic: 'RVSAAT2S039',
        blz: '35139',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20356,
        name: 'Raiffeisenbank Nauders eGen',
        bic: 'RZTIAT22283',
        blz: '36283',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20337,
        name: 'Raiffeisenbank Mittleres Lavanttal eGen',
        bic: 'RZKTAT2K481',
        blz: '39481',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20346,
        name: 'Raiffeisenbank Mondseeland eGen',
        bic: 'RZOOAT2L322',
        blz: '34322',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20347,
        name: 'Raiffeisenbank Moosburg-Tigring, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K411',
        blz: '39411',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21023,
        name: 'Volksbank Nienburg (Gf GAA)',
        bic: 'GENODEF1NIN',
        blz: '25690010',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 17838,
        name: 'Sparkasse Südwestpfalz',
        bic: 'MALADE51SWP',
        blz: '54250010',
        city: 'Pirmasens',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 16818,
        name: 'Volksbank Niederrhein',
        bic: 'GENODED1NRH',
        blz: '35461106',
        city: 'Alpen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16820,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '35640064',
        city: 'Wesel',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 16817,
        name: 'Sparkasse Rheinberg -alt-',
        bic: 'WELADED1RHB',
        blz: '35451775',
        city: 'Rheinberg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 16822,
        name: 'Volksbank Rhein-Lippe',
        bic: 'GENODED1RLW',
        blz: '35660599',
        city: 'Wesel',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18921,
        name: 'Raiffeisenbank Bissingen',
        bic: 'GENODEF1BSI',
        blz: '72069034',
        city: 'Bissingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18922,
        name: 'Raiffeisenbank Bobingen',
        bic: 'GENODEF1BOI',
        blz: '72069036',
        city: 'Bobingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20279,
        name: 'Raiffeisenbank Köstendorf-Neumarkt-Schleedorf reg.Gen.m.b.H.',
        bic: 'RVSAAT2S021',
        blz: '35121',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18982,
        name: 'Deutsche Bank',
        bic: 'DEUTDEMM721',
        blz: '72170007',
        city: 'Ingolstadt',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20280,
        name: 'Raiffeisenbank Köstendorf-Neumarkt-Schleedorf reg.Gen.m.b.H.',
        bic: 'RVSAAT2S021',
        blz: '35221',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20281,
        name: 'Raiffeisenbank Kötschach - Mauthen, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K364',
        blz: '39364',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20282,
        name: 'Raiffeisenbank Krems eGen',
        bic: 'RLNWATWWKRE',
        blz: '32397',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20283,
        name: 'Raiffeisenbank Kreuzenstein eGen',
        bic: 'RLNWATW1438',
        blz: '32438',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20284,
        name: 'Raiffeisenbank Kreuzenstein eGen',
        bic: 'RLNWATW1438',
        blz: '32731',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20313,
        name: 'Raiffeisenbank Maishofen eGen',
        bic: 'RVSAAT2S035',
        blz: '35035',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18796,
        name: 'Raiffeisenbank Tattenh-Großkarolinenf',
        bic: 'GENODEF1GKT',
        blz: '70169190',
        city: 'Großkarolinenfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16821,
        name: 'Niederrheinische Sparkasse RheinLippe',
        bic: 'WELADED1WES',
        blz: '35650000',
        city: 'Wesel',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17837,
        name: 'Commerzbank',
        bic: 'COBADEFFXXX',
        blz: '54240032',
        city: 'Pirmasens',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18840,
        name: 'Raiffeisenbank Walpertskirchen-Wörth-Hörlkofen',
        bic: 'GENODEF1WWO',
        blz: '70169596',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18176,
        name: 'Raiffeisenbank Horb',
        bic: 'GENODES1RHB',
        blz: '60069798',
        city: 'Horb am Neckar',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20301,
        name: 'Raiffeisenbank Leogang eGen',
        bic: 'RVSAAT2S031',
        blz: '35031',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16600,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1MML',
        blz: '28563749',
        city: 'Moormerland',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17785,
        name: 'VR-Bank NordRhön',
        bic: 'GENODEF1HUE',
        blz: '53061230',
        city: 'Hünfeld',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20304,
        name: 'Raiffeisenbank Liesertal, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K464',
        blz: '39464',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16028,
        name: 'Commerzbank vormals Dresdner Bank, PCC DCC-ITGK 5',
        bic: 'DRESDEFFI74',
        blz: '20080088',
        city: 'Hamburg',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 18763,
        name: 'Commerzbank vormals Dresdner Bank Gf ZW 57',
        bic: 'DRESDEFF724',
        blz: '70080057',
        city: 'München',
        additionalSearchString: 'Commerzbank',
        logoPath: '65.svg',
        available: true
    },
    {
        id: 20645,
        name: 'Raiffeisenlandesbank Burgenland und Revisionsverband eGen',
        bic: 'RLBBAT2EXXX',
        blz: '33000',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20760,
        name: 'Waldviertler Volksbank Horn reg.Gen.m.b.H.',
        bic: 'WVOHAT21XXX',
        blz: '43600',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19364,
        name: 'Raiffeisenbank Volkacher Mainschleife - Wiesentheid',
        bic: 'GENODEF1WED',
        blz: '79069001',
        city: 'Wiesentheid',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19626,
        name: 'Volksbank Vogtland GAA',
        bic: 'GENODEF1EXT',
        blz: '87095899',
        city: 'Plauen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20261,
        name:
            'Raiffeisenbank im Vorderen Zillertal, Fügen, Fügenberg, Kaltenbach, Ried und Uderns eGen',
        bic: 'RZTIAT22229',
        blz: '36229',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16602,
        name: 'Deutsche Bank',
        bic: 'DEUTDEHB285',
        blz: '28570092',
        city: 'Leer (Ostfriesland)',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 17786,
        name: 'VR Bank Schlüchtern-Birstein',
        bic: 'GENODE51SLU',
        blz: '53061313',
        city: 'Schlüchtern',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17787,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1GLU',
        blz: '53062035',
        city: 'Großenlüder',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17788,
        name: 'Raiffeisenbank Biebergrund-Petersberg',
        bic: 'GENODEF1PBG',
        blz: '53062350',
        city: 'Petersberg',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 17789,
        name: 'Raiffeisenbank',
        bic: 'GENODEF1FLN',
        blz: '53064023',
        city: 'Flieden',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16603,
        name: 'Ostfriesische Volksbank Leer',
        bic: 'GENODEF1LER',
        blz: '28590075',
        city: 'Leer (Ostfriesland)',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20230,
        name: 'Raiffeisenbank Helfenberg - St. Stefan a.W. eGen',
        bic: 'RZOOAT2L160',
        blz: '34160',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20231,
        name: 'Raiffeisenbank Hellmonsödt eGen',
        bic: 'RZOOAT2L161',
        blz: '34161',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20232,
        name: 'Raiffeisenbank Henndorf am Wallersee eGen',
        bic: 'RVSAAT2S024',
        blz: '35024',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16176,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB215',
        blz: '21570024',
        city: 'Flensburg',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 19263,
        name: 'Stadt- u. Kreissparkasse Erlangen Höchstadt Herzogenaurach',
        bic: 'BYLADEM1ERH',
        blz: '76350000',
        city: 'Erlangen',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18918,
        name: 'VR-Bank Handels- und Gewerbebank',
        bic: 'GENODEF1MTG',
        blz: '72062152',
        city: 'Gersthofen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20303,
        name: 'Raiffeisenbank Lieboch-Stainz eGen',
        bic: 'RZSTAT2G210',
        blz: '38210',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 18919,
        name: 'Raiffeisenbank Adelzhausen-Sielenbach',
        bic: 'GENODEF1ADZ',
        blz: '72069002',
        city: 'Adelzhausen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 18925,
        name: 'Raiffeisenbank Bibertal-Kötz',
        bic: 'GENODEF1KEZ',
        blz: '72069090',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19493,
        name: 'Kreissparkasse Eichsfeld',
        bic: 'HELADEF1EIC',
        blz: '82057070',
        city: 'Leinefelde-Worbis',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 20197,
        name: 'Raiffeisenbank Gratkorn eGen',
        bic: 'RZSTAT2G111',
        blz: '38111',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20229,
        name: 'Raiffeisenbank Heiligenkreuz-Kirchbach -St. Georgen eGen',
        bic: 'RZSTAT2G170',
        blz: '38170',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16853,
        name: 'DB Privat- und Firmenkundenbank (Deutsche Bank PGK)',
        bic: 'DEUTDEDB362',
        blz: '36270024',
        city: 'Mülheim an der Ruhr',
        additionalSearchString: 'Deutsche Bank',
        logoPath: '74.svg',
        available: true
    },
    {
        id: 20727,
        name: 'Volksbank Oberösterreich AG',
        bic: 'VBWEAT2WXXX',
        blz: '43830',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20927,
        name:
            'Volksbank Raiffeisenbank Oberbayern Südost eG-Zweigniederlassung VR-Bank Salzburg',
        bic: 'RVSAAT2S080',
        blz: '35080',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21024,
        name: 'ZVC FK 71 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010071',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 20611,
        name: 'Raiffeisen-Bezirksbank Spittal/Drau, reg.Gen.m.b.H.',
        bic: 'RZKTAT2K267',
        blz: '39267',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20644,
        name: 'Raiffeisenkasse Zistersdorf - Dürnkrut reg.Gen.m.b.H.',
        bic: 'RLNWATWWZDF',
        blz: '32985',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 21025,
        name: 'ZVC FK 64 Postbank Ndl der DB Privat- und Firmenkundenbank',
        bic: 'PBNKDEFFXXX',
        blz: '59010064',
        additionalSearchString: 'Postbank',
        logoPath: '140.svg',
        available: false
    },
    {
        id: 20643,
        name: 'Raiffeisenkasse Ziersdorf reg.Gen.m.b.H.',
        bic: 'RLNWATW1982',
        blz: '32982',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19081,
        name: 'Raiffeisenbank am Dreisessel',
        bic: 'GENODEF1NHD',
        blz: '74069768',
        city: 'Neureichenau',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 19552,
        name: 'Ostsächsische Sparkasse Dresden Gf OSD.Net',
        bic: 'OSDDDE81NET',
        blz: '85050350',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: false
    },
    {
        id: 18916,
        name: 'Sparkasse Günzburg-Krumbach',
        bic: 'BYLADEM1GZK',
        blz: '72051840',
        city: 'Günzburg',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17952,
        name: 'Sparkasse Koblenz',
        bic: 'MALADE51KOB',
        blz: '57050120',
        city: 'Koblenz',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 17724,
        name: 'Kasseler Sparkasse',
        bic: 'HELADEF1KAS',
        blz: '52050353',
        city: 'Kassel',
        additionalSearchString: 'Sparkasse',
        logoPath: '1154.svg',
        available: true
    },
    {
        id: 18924,
        name: 'Raiffeisenbank Gersthofen -alt-',
        bic: 'GENODEF1GHF',
        blz: '72069081',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 19080,
        name: 'Raiffeisenbank Mauth',
        bic: 'GENODEF1MTH',
        blz: '74069763',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 20302,
        name: 'Raiffeisenbank Leonding eGen',
        bic: 'RZOOAT2L276',
        blz: '34276',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: false
    },
    {
        id: 16598,
        name: 'Raiffeisenbank Flachsmeer',
        bic: 'GENODEF1WEF',
        blz: '28562716',
        city: 'Westoverledingen',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 16599,
        name: 'Raiffeisenbank Moormerland',
        bic: 'GENODEF1HTL',
        blz: '28562863',
        city: 'Holtland',
        additionalSearchString: 'Volksbank',
        logoPath: '169.svg',
        available: true
    },
    {
        id: 20801,
        name:
            'BAWAG P.S.K. Bank für Arbeit und Wirtschaft und Österreichische Postsparkasse AG',
        bic: 'BAWAATWWXXX',
        blz: '14000',
        logoPath: '1154.svg',
        available: false
    }
];

import React from 'react';
import WgSkeleton from '../WgSkeleton';

function AccountListItemHeaderSkeleton() {
    return (
        <React.Fragment>
            <WgSkeleton width={'20%'} animation="wave" />
            <WgSkeleton width={'30%'} animation="wave" />
        </React.Fragment>
    );
}

export default AccountListItemHeaderSkeleton;

import React, {useCallback, useEffect} from 'react';
import {Box, Divider, Grid} from '@material-ui/core';
import {WgInputField} from '../../../../../../styles/CustomComponents';
import WgCurrencyInput from '../../../../../../styles/CustomComponents/WgCurrencyInput';
import {
    IntervalTypeOne,
    IRecurringpayment
} from '../../../../../../models/recurringpaymentInterface';
import {formatPaymentInterval} from '../../../../../utils/Format';
import {
    CarInsuranceOneProductType,
    IExistingInsuranceProduct,
    IPolicyData,
    IPolicyFormData
} from '../../../../../../models/productInterface';
import InsuranceListItem from '../../../components/InsuranceListItem';
import {isInputNotFilled} from '../../../../../utils/Validate';
import ProductTypeSelection from '../Components/ProductTypeSelection';

export interface CarInsuranceExistingContractCompletionProps {
    recurringPayment?: IRecurringpayment;
    contract: IExistingInsuranceProduct;
    policyData: IPolicyData;
    formData: IPolicyFormData;
    setIsValid: Function;
    updatePolicyData: Function;
    setContract: Function;
    validationEnabled: boolean;
    process: CarInsuranceOneProductType;
}

export default function CarInsuranceExistingContractCompletion({
    policyData,
    formData,
    setContract,
    updatePolicyData,
    contract,
    setIsValid,
    validationEnabled,
    process
}: CarInsuranceExistingContractCompletionProps) {
    const checkValidation = useCallback(() => {
        return (
            !isInputNotFilled(contract.amount) &&
            !isInputNotFilled(contract.paymentInterval) &&
            !isInputNotFilled(policyData.productType)
        );
    }, [contract, policyData]);

    useEffect(() => {
        setIsValid(checkValidation());
    }, [checkValidation, setIsValid]);

    const selection = {
        name: 'paymentInterval',
        label: 'Zahlungsintervall',
        type: 'text',
        defaultValue: contract?.paymentInterval ?? '',
        options: Object.keys(IntervalTypeOne).map((key: string) => {
            return {
                label: formatPaymentInterval(key),
                value: key
            };
        })
    };

    function handlePaymentDataChange(event: React.ChangeEvent<any>) {
        const {value} = event.target;
        setIsValid(checkValidation());
        setContract({...contract, paymentInterval: value});
    }

    const handleAmountChange = useCallback(
        (amount: number) => {
            setContract((contract: IExistingInsuranceProduct) => ({
                ...contract,
                amount: amount
            }));
        },
        [setContract]
    );

    return (
        <React.Fragment>
            <Grid container direction="column" spacing={1}>
                <InsuranceListItem
                    data={contract.company}
                    background={'transparent'}
                />
            </Grid>
            <Grid
                item
                container
                justify="space-between"
                wrap="nowrap"
                alignItems="center">
                <Grid item xs={6}>
                    <Box paddingLeft={'1.25rem'}>
                        <WgCurrencyInput
                            amount={contract?.amount ?? 0}
                            handleAmountChange={handleAmountChange}
                        />
                    </Box>
                </Grid>
                <Box display="flex" alignItems="center">
                    <Divider
                        orientation="vertical"
                        light
                        style={{height: '75%'}}
                    />
                </Box>
                <Grid style={{width: '100%'}} item>
                    <WgInputField
                        required
                        error={
                            validationEnabled &&
                            isInputNotFilled(contract.paymentInterval)
                        }
                        helperText={
                            validationEnabled &&
                            isInputNotFilled(contract.paymentInterval) &&
                            'Diese Eingabe ist erforderlich.'
                        }
                        handleChange={handlePaymentDataChange}
                        {...selection}
                    />
                </Grid>
            </Grid>
            <Divider variant="middle" light />
            <Box p={'1rem 1.5rem 0.5rem'}>
                <ProductTypeSelection
                    formData={formData}
                    process={process}
                    policyData={policyData}
                    updatePolicyData={updatePolicyData}
                    helperText={
                        validationEnabled &&
                        isInputNotFilled(policyData.productType) &&
                        'Bitte überprüfe deine Eingabe.'
                    }
                    error={
                        validationEnabled &&
                        isInputNotFilled(policyData.productType)
                    }
                />
            </Box>
        </React.Fragment>
    );
}

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Grid, Paper} from '@material-ui/core';
import {iswindowSizeMobile} from '../../../utils/HandleSize';
import {userActions} from '../../../../store/actions';
import {getUser, getUserUserData} from '../../../../store/reducers/selectors';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgInputField,
    WgTypography
} from '../../../../styles/CustomComponents';
import {WgInputFieldBaseProps} from '../../../../styles/CustomComponents/WgInputField';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {AddressInputFields} from '../../../utils';
import {IAllUserData} from '../../../../models/userInterface';
import {inputNotFilled} from '../../recurringpayments/change/Components';

const {updateUser, deleteWechselGottAccount} = userActions;

interface PrivateEditProps extends IAllUserData {
    updateUser: Function;
    deleteWechselGottAccount: Function;
    wechselgottId: string;
}

function extractUserData(props: PrivateEditProps) {
    return {
        wechselgottId: props.wechselgottId,
        country: props.country,
        email: props.email,
        title: props.title,
        firstName: props.firstName,
        lastName: props.lastName,
        password: props.password,
        postcode: props.postcode,
        city: props.city,
        street: props.street,
        houseNumber: props.houseNumber,
        addressAdditional: props.addressAdditional,
        phone: props.phone,
        newsletter: props.newsletter,
        dsgvo: props.dsgvo,
        agb: props.agb,
        gender: props.gender,
        birthDate: props.birthDate
    };
}

function PrivateEdit(props: PrivateEditProps) {
    const [state, setState] = useState<IAllUserData>(extractUserData(props));
    const [valid, setValid] = useState(false);
    const [hasAskToReturn, setHasAskToReturn] = useState(false);

    useEffect(() => {
        setState(p => ({...p, ...extractUserData(props)}));
    }, [props]);

    useEffect(() => {
        const validationFields = [
            state.email,
            state.phone,
            state.firstName,
            state.lastName,
            state.gender,
            state.birthDate,
            state.street,
            state.houseNumber,
            state.city,
            state.postcode
        ];

        const areInputsFilled = validationFields.every(
            (val: any) => typeof val !== 'undefined' && val !== ''
        );
        setValid(areInputsFilled);
    }, [state]);

    const detailMapping: WgInputFieldBaseProps[] = [
        {
            label: 'E-Mail',
            type: 'email',
            defaultValue: state.email,
            name: 'email',
            disabled: true
        },
        {
            label: 'Telefon',
            type: 'tel',
            defaultValue: state.phone,
            name: 'phone',
            disabled: true
        },
        {
            label: 'Namenszusatz (optional)',
            type: 'text',
            defaultValue: state.title,
            name: 'title'
        },
        {
            label: 'Vorname',
            type: 'text',
            defaultValue: state.firstName,
            name: 'firstName',
            required: true
        },
        {
            label: 'Nachname',
            type: 'text',
            defaultValue: state.lastName,
            name: 'lastName',
            required: true
        },
        {
            label: 'Geschlecht',
            type: 'text',
            defaultValue: state.gender,
            name: 'gender',
            options: [
                {
                    value: 'male',
                    label: 'männlich'
                },
                {
                    value: 'female',
                    label: 'weiblich'
                },
                {
                    value: 'divers',
                    label: 'divers'
                }
            ]
        },
        {
            dayLabel: 'Geburtsdatum',
            type: 'date',
            defaultValue: state.birthDate,
            name: 'birthDate',
            background: 'paper',
            birthDate: true
        }
    ];

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <WgContainer bodyApp>
                <WgHeader
                    headerText={
                        iswindowSizeMobile()
                            ? 'Daten bearbeiten'
                            : 'Persönliche Daten bearbeiten'
                    }
                    hasBackButton
                    backText="Persönliche Daten"
                    hasAskToReturn={hasAskToReturn}
                    save={save}
                />

                <WgContainer
                    bodyContent
                    disableGutters={false}
                    onChange={handleChange}>
                    <Grid container spacing={2}>
                        {detailMapping.map((item: any) => (
                            <Grid key={item.name} item xs={12} md={6}>
                                <Paper>{renderDetail(item)}</Paper>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <AddressInputFields
                                validation
                                required
                                handleChange={handleChange}
                                street={state.street ?? ''}
                                houseNumber={state.houseNumber ?? ''}
                                addressAdditional={
                                    state.addressAdditional ?? ''
                                }
                                city={state.city ?? ''}
                                postcode={state.postcode ?? ''}
                                showCountry
                            />
                        </Grid>
                    </Grid>

                    <Box marginY="1rem" display="flex" justifyContent="center">
                        <WgButton
                            disabled={!valid}
                            defaultButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            aria-label="save"
                            onClick={save}>
                            Änderungen speichern
                        </WgButton>
                    </Box>

                    <Box marginTop={{xs: '1.5rem', md: '3rem'}}>
                        <WgTypography
                            align="center"
                            text="link"
                            color="error"
                            onClick={() => deleteAccount()}>
                            WechselGott Konto löschen
                        </WgTypography>
                    </Box>
                </WgContainer>
            </WgContainer>
        </MuiPickersUtilsProvider>
    );

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
        setHasAskToReturn(true);
    }

    function handleDateChange(date: string) {
        setState({
            ...state,
            birthDate: date
        });

        setHasAskToReturn(true);
    }

    function save() {
        props.updateUser(state);
        setHasAskToReturn(false);
        window.history.back();
    }

    function deleteAccount() {
        props.deleteWechselGottAccount(state);
    }

    function areRequirementsMet(item: WgInputFieldBaseProps) {
        return !item.required || !inputNotFilled(item.defaultValue);
    }

    function renderDetail(item: WgInputFieldBaseProps) {
        return (
            <WgInputField
                shrinkLabel
                key={item.name}
                {...item}
                handleChange={
                    item.type === 'date' ? handleDateChange : handleChange
                }
                error={!areRequirementsMet(item)}
                helperText={
                    !areRequirementsMet(item) &&
                    'Diese Eingabe ist erforderlich.'
                }
            />
        );
    }
}

function mapStateToProps(state: any) {
    return {
        wechselgottId: getUser(state)?.id,
        ...getUserUserData(state)
    };
}

export default connect(mapStateToProps, {updateUser, deleteWechselGottAccount})(
    PrivateEdit
);

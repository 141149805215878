import {Divider, Grid} from '@material-ui/core';
import {
    WgCurrencyInput,
    WgInputField
} from '../../../../../styles/CustomComponents';
import React from 'react';
import {IntervalType} from '../../../../../models/recurringpaymentInterface';
import {formatPaymentInterval} from '../../../../utils/Format';
import AmountSignInput from '../../../../utils/AmountSignInput';

export interface AmountIntervalInputProps {
    handleChange: (name: any) => (value: any) => void;
    handleAmountChange: Function;
    amount: number;
    paymentInterval?: IntervalType;
    signButton?: boolean;
}

export default function AmountIntervalInput({
    amount,
    paymentInterval,
    handleChange,
    handleAmountChange,
    signButton
}: AmountIntervalInputProps) {
    const selection = {
        name: 'paymentInterval',
        label: 'Zahlungsintervall',
        type: 'text',
        defaultValue: paymentInterval ?? IntervalType.MONTHLY,
        options: Object.keys(IntervalType).map((key: string) => {
            return {
                label: formatPaymentInterval(key),
                value: key
            };
        })
    };

    return (
        <Grid
            container
            wrap="nowrap"
            style={{padding: '0 0.5rem'}}
            justify="space-between"
            alignItems="center">
            <Grid item xs={5}>
                {signButton ? (
                    <AmountSignInput
                        amount={amount}
                        handleAmountChange={handleAmountChange}
                    />
                ) : (
                    <WgCurrencyInput
                        zeroNotAllowed
                        amount={amount}
                        handleAmountChange={handleAmountChange}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={1}
                container
                justify="center"
                alignItems="center"
                style={{alignSelf: 'stretch'}}>
                <Divider light orientation="vertical" style={{height: '75%'}} />
            </Grid>
            <Grid item xs={6}>
                <WgInputField
                    fullWidth
                    required
                    {...selection}
                    handleChange={(event: any) =>
                        handleChange('paymentInterval')(event.target.value)
                    }
                />
            </Grid>
        </Grid>
    );
}

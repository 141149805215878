import {ITransaction} from './transactionInterface';
import {IExistingInsuranceProduct} from './productInterface';

export enum IntervalType {
    WEEKLY = 'WEEKLY',
    BIWEEKLY = 'BIWEEKLY',
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    SEMIANNUAL = 'SEMIANNUAL',
    ANNUAL = 'ANNUAL',
    BIANNUAL = 'BIANNUAL'
}

export enum IntervalTypeOne {
    MONTHLY = 'MONTHLY',
    ANNUAL = 'ANNUAL'
}

export interface IRecurringpayment {
    recurringPaymentId: number;
    currency: string;
    accountId: number;
    active: boolean;
    averageAmount: number;
    estimatedNextAmount: number;
    estimatedNextBookingDate?: string;
    firstKnownBookingDate: string;
    lastKnownBookingDate: string;
    receiverName: string;
    purpose?: string;
    categoryId: number;
    companyId?: number;
    logoPath?: string;
    categoryName: string;
    isContract: boolean;
    isInsurance?: boolean;
    cancellationStatus: 'Permanent' | 'Cancellable' | 'Canceled';
    changeData: IRecurringPaymentChangeData;
    transactionIds: number[];
    lastAmount: number;
    paymentInterval?: string;
    receiverIBAN?: string;
    isArchived?: boolean;
}

export interface IRecurringPaymentChangeData {
    changeState:
        | 'available'
        | 'pending'
        | 'onGoing'
        | 'success'
        | 'failure'
        | 'meterNumberMissing'
        | 'none';
    //if onGoing; success or failure
    startDate?: string;
    providerName?: string;
    yearlyCosts?: number;
    estimatedYearlySavings?: number;
}

export interface IContractManualAddition {
    name?: string;
    categoryId?: number;
    companyId?: number;
    accountId?: number;
    iban?: string;
    paymentInterval: IntervalType;
    amount: number;
    currency?: string;
}

export interface IRecurringPaymentUpdate {
    paymentInterval?: string;
    isArchived?: boolean;
    categoryId?: number;
}

export function isInChangeState(
    changeData?: IRecurringPaymentChangeData
): boolean {
    if (typeof changeData === 'undefined') return false;
    return changeData.changeState === ('onGoing' || 'success' || 'failure');
}

export function isRecurringPayment(
    object: ITransaction | IRecurringpayment | IExistingInsuranceProduct
): object is IRecurringpayment {
    return (
        typeof (object as IRecurringpayment)?.recurringPaymentId !==
            'undefined' &&
        typeof (object as IRecurringpayment)?.active !== 'undefined'
    );
}

import React from 'react';
import {SignupStep} from './SignupStep';
import DateForm, {
    DATE_STATUS_CODE,
    DateFormStatus
} from '../../../utils/DateForm';
import {formatISO} from 'date-fns';

export default class SignupStep3 extends SignupStep {
    // @ts-ignore
    state = {
        status: {
            code: DATE_STATUS_CODE.EMPTY,
            text: ''
        }
    };

    renderStep(): React.ReactNode {
        return (
            <DateForm
                dayLabel="Geburtstag"
                value={this.props.state.birthDate}
                onChange={this.onChange.bind(this)}
                onChangeStatus={this.onChangeStatus.bind(this)}
                background="paper"
            />
        );
    }

    handleInput(event: any) {}

    onChange(date?: Date) {
        const value = date
            ? formatISO(date, {representation: 'date'})
            : undefined;
        if (date) {
            let event: {target: {name: string; value?: string}} = {
                target: {name: 'birthDate', value}
            };
            this.handleChange('birthDate')(event, this.validate.bind(this));
        }
    }

    onChangeStatus(status: DateFormStatus) {
        this.setState({
            // @ts-ignore
            status: status,
            isInputValid: status.code === DATE_STATUS_CODE.VALID_DATE
        });
    }

    renderStepText(value = '') {
        if (this.state?.status.code === DATE_STATUS_CODE.INVALID_DATE) {
            return super.renderStepText(this.state.status.text, true);
        } else {
            return super.renderStepText(value);
        }
    }
}

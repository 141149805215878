import React, {useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
// @ts-ignore
import {autoPlay} from 'react-swipeable-views-utils';
import MyLink from '../../utils/MyLink';
import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../styles/CustomComponents';
import {
    Box,
    Button,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    MobileStepper,
    Theme
} from '@material-ui/core';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        swipeable: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        stepperButton: {
            [theme.breakpoints.down('sm')]: {
                visibility: 'hidden'
            }
        }
    })
);

export default function Info() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);

    const slides = [
        renderSlide(
            'Alle deine Konten in einer App',
            '/img/info/info-bank-account.svg',
            'Vollautomatischer Wechselservice deines Strom- und Gastarifs'
        ),
        renderSlide(
            'Volle Kontrolle über deine Finanzen',
            '/img/info/info-tarif-optimization.svg',
            'Unsere App zeigt dir all deine Einnahmen und Ausgaben. Somit behältst du immer den Überblick über deinen Kontostand.'
        ),
        renderSlide(
            'Keine versteckten Kosten',
            '/img/info/info-free.svg',
            'Unser Service ist völlig kostenlos. Für unsere App zahlst du keinen Cent.'
        )
    ];

    const maxSteps = slides.length;

    return (
        <WgContainer publicBodyApp>
            <WgHeader isPublic hasBackButton headerText="Anmelden" />
            <WgContainer
                publicBodyContent
                style={{padding: '0 0.5rem', width: '100vw'}}>
                <Grid
                    item
                    xs
                    container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Grid item xs container justify="center">
                        <AutoPlaySwipeableViews /*For Designing: 'SwipeableViews'*/
                            axis={'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            interval={8000}
                            className={classes.swipeable}>
                            {slides.map((slide, index) => (
                                <Grid
                                    key={index}
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center">
                                    {Math.abs(activeStep - index) <= 2
                                        ? slide
                                        : null}
                                </Grid>
                            ))}
                        </AutoPlaySwipeableViews>
                    </Grid>
                    <Grid item container justify="center">
                        <Button
                            style={{margin: '0 1.5rem', maxWidth: '600px'}}
                            variant="contained"
                            color="primary"
                            fullWidth
                            aria-label="signup"
                            component={MyLink('/signup/1')}>
                            Jetzt starten
                        </Button>
                    </Grid>
                    <Grid item container justify="center">
                        <Box
                            p={{xs: '0.25rem', md: 0}}
                            maxWidth="640px"
                            width="100%">
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <IconButton
                                        className={classes.stepperButton}
                                        size="small"
                                        onClick={() =>
                                            setActiveStep(activeStep + 1)
                                        }
                                        color="primary"
                                        disabled={activeStep === maxSteps - 1}>
                                        <ChevronRight />
                                    </IconButton>
                                }
                                backButton={
                                    <IconButton
                                        className={classes.stepperButton}
                                        size="small"
                                        color="primary"
                                        onClick={() =>
                                            setActiveStep(activeStep - 1)
                                        }
                                        disabled={activeStep === 0}>
                                        <ChevronLeft />
                                    </IconButton>
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function handleStepChange(activeStep: number) {
        setActiveStep(activeStep);
    }

    function renderSlide(header: string, img: string, description: string) {
        return (
            <Grid
                item
                container
                direction="column"
                spacing={4}
                style={{width: '100%', margin: 0}}>
                <Grid item>
                    <WgTypography
                        align="center"
                        color="primary"
                        text="contentInfo">
                        {header}
                    </WgTypography>
                </Grid>
                <Grid item container justify="center">
                    <img
                        src={img}
                        style={{height: '100px', verticalAlign: 'middle'}}
                        alt="Icon"
                    />
                </Grid>
                <Grid item>
                    <WgTypography
                        align="center"
                        color="textPrimary"
                        text="sentence">
                        {description}
                    </WgTypography>
                </Grid>
            </Grid>
        );
    }
}

import React from 'react';
import {IRecurringpayment} from '../../../../models/recurringpaymentInterface';
import {WgButton, WgTypography} from '../../../../styles/CustomComponents';
import {Box, Divider, Grid, Paper} from '@material-ui/core';
import {EnergyStep} from '../../../../models/offerInterface';

export interface IMissingMeterNumberContract {
    recurringPayment: IRecurringpayment;
}

export default function MissingMeterNumberContract({
    recurringPayment
}: IMissingMeterNumberContract) {
    if (recurringPayment.changeData.changeState === 'meterNumberMissing') {
        return (
            <React.Fragment>
                <Divider light />
                <Grid item>
                    <Paper>
                        <Box paddingX="1rem" paddingTop="1rem">
                            <WgTypography text="content" color="textPrimary">
                                Wir benötigen nur noch deine Zählernummer, um
                                deinen Vertragswechsel durchzuführen
                            </WgTypography>
                            {renderButtons()}
                        </Box>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    } else {
        return null;
    }

    function renderButtons() {
        return (
            <Box
                p="1rem 0"
                display="flex"
                flexWrap="nowrap"
                flex={1}
                flexDirection={{xs: 'column', md: 'row'}}
                justifyContent={{
                    xs: 'center',
                    md: 'space-between'
                }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems={{xs: 'flex-end', md: 'center'}}
                    flex={1}>
                    <WgButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        componentProps={{isOnlyMeterNumberSubmission: true}}
                        link={`/recurringpayments/${recurringPayment.recurringPaymentId}/add/energy/${EnergyStep.METER_NUMBER}`}
                        aria-label="next">
                        Zählernummer eingeben
                    </WgButton>
                </Box>
            </Box>
        );
    }
}

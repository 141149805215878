import React from 'react';
import {IContactData} from '../../../../../models/userInterface';
import {Divider, Grid, Paper} from '@material-ui/core';
import {WgInputField} from '../../../../../styles/CustomComponents';
import {NameInputs} from './NameInputs';
import {inputNotFilled} from './index';

export interface LinkedAccountInputsProps extends IContactData {
    handleChange: (event: React.ChangeEvent<any>) => void;
    isCommercial?: boolean;
}

export function LinkedAccountInputs(props: LinkedAccountInputsProps) {
    const {handleChange, companyName, firstName, lastName, iban} = props;

    return (
        <Paper elevation={0}>
            <Grid container direction="column">
                {props.isCommercial && (
                    <React.Fragment>
                        <WgInputField
                            required
                            error={inputNotFilled(companyName)}
                            helperText={
                                inputNotFilled(companyName) &&
                                'Diese Eingabe ist erforderlich.'
                            }
                            name="companyName"
                            label="Firmenname"
                            type="text"
                            defaultValue={companyName ?? ''}
                            handleChange={handleChange}
                        />
                        <Divider variant="middle" light />
                    </React.Fragment>
                )}
                <Grid item container>
                    <NameInputs
                        firstName={firstName}
                        lastName={lastName}
                        handleChange={handleChange}
                    />
                </Grid>
                <Divider variant="middle" light />
                <WgInputField
                    required
                    error={inputNotFilled(iban)}
                    helperText={
                        inputNotFilled(iban) &&
                        'Diese Eingabe ist erforderlich.'
                    }
                    name="iban"
                    label="IBAN"
                    type="text"
                    defaultValue={iban ?? ''}
                    handleChange={handleChange}
                />
            </Grid>
        </Paper>
    );
}

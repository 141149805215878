import React from 'react';
import {SignupStep} from './SignupStep';
import PasswordForm, {passwordStatus} from '../../../utils/PasswordForm';

export default class SignupStep8 extends SignupStep {
    // @ts-ignore
    state = {
        password: '',
        status: passwordStatus.EMPTY
    };

    renderStep(): React.ReactNode {
        return (
            <React.Fragment>
                <PasswordForm
                    autoFocus
                    onChange={(password?: string) => {
                        // @ts-ignore
                        this.setState({password});
                        this.props.setState({password});
                    }}
                    setStatus={this.handleSetStatus.bind(this)}
                />
            </React.Fragment>
        );
    }

    handleSetStatus(status: number) {
        // @ts-ignore
        this.setState({status});
    }

    renderStepText(value = '') {
        // @ts-ignore
        if (this.state.status === passwordStatus.NOT_STRONG_ENOUGH)
            return super.renderStepText(
                <React.Fragment>
                    Das Passwort ist zu schwach.
                    <br />
                    Benutze min. acht Zeichen, min. einen Kleinbuchstaben, min.
                    eine Ziffer und keine Leerzeichen
                </React.Fragment>,
                true
            );
        // @ts-ignore
        if (this.state.status === passwordStatus.DIFFERENT)
            return super.renderStepText(
                'Die Passwörter müssen übereinstimmen.',
                true
            );

        return super.renderStepText(value);
    }

    areRequirementsMet() {
        // @ts-ignore
        return this.state && this.state.status === passwordStatus.SUCCESS;
    }
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {setOpenDialog} from '../../store/actions/notificationActions';
import Notification from './Notification';
import './Dialog.scss';
import {IDialog} from '../../models/notificationInterface';
import {getNotification} from '../../store/reducers/selectors';
import {ReactGA} from '../../tracking';

interface IProps extends IDialog {
    setOpenNotification: Function;
}

class ViewDialog extends Component<IProps, {}> {
    handleClose = () => {
        this.props.setOpenNotification(false);
        this.props.afterClose && this.props.afterClose();
    };

    render() {
        if (this.props.open) {
            const trackingText = `Dialog: ${JSON.stringify(this.props)}`;
            ReactGA.modalview(trackingText);
        }

        const DialogComponent = this.props.component || Notification;
        return (
            <Dialog
                className={this.props.className}
                open={this.props.open || false}
                disableBackdropClick={this.props.disableBackdropClick || false}
                onClose={this.handleClose.bind(this)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogComponent
                    onClose={this.handleClose.bind(this)}
                    {...this.props}
                />
            </Dialog>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        ...getNotification(state)?.dialog
    };
}

export default connect(mapStateToProps, {setOpenNotification: setOpenDialog})(
    ViewDialog
);

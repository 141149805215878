import accounts from './accounts';
import banks from './banks';
import user from './user';
import notification from './notification';
import recurringpayments from './recurringpayments';
import offers from './offers';
import categories from './categories';
import companies from './companies';
import products from './products';
import theme from './theme';
import tutorials from './tutorials';
import carInsurance from './carInsurance';

export const otherReducers = {
    accounts,
    banks,
    user,
    recurringpayments,
    offers,
    notification,
    products,
    tutorials,
    theme,
    categories,
    companies,
    carInsurance
};

import React from 'react';
import {AddressInputFields} from '../../../../utils';
import {IContactData} from '../../../../../models/userInterface';
import {
    Checkbox,
    Collapse,
    Divider,
    FormControlLabel,
    Grid,
    Paper
} from '@material-ui/core';
import {GenderRadioGroup, NameInputs} from './';
import {CompanyDataInput} from './CompanyDataInput';

export interface DeliveryDataInputsProps {
    handleChange: (event: React.ChangeEvent<any>) => void;
    handleBillingChange: (event: React.ChangeEvent<any>) => void;
    delivery: IContactData;
    billing: IContactData;
    hasOtherBilling: boolean;
    setHasOtherBilling: Function;
    isCommercial?: boolean;
}

export function DeliveryDataInputs(props: DeliveryDataInputsProps) {
    const {
        delivery,
        handleChange,
        billing,
        handleBillingChange,
        hasOtherBilling,
        setHasOtherBilling,
        isCommercial
    } = props;
    // used for 'Abweichende Rechnungsadresse'

    return (
        <Paper elevation={0}>
            {renderInputs(delivery, handleChange)}
            <FormControlLabel
                style={{paddingLeft: '0.5rem'}}
                control={
                    <Checkbox
                        checked={hasOtherBilling}
                        onChange={(event: any) =>
                            setHasOtherBilling(Boolean(event.target.checked))
                        }
                    />
                }
                label="Abweichende Rechnungsadresse"
            />
            <Collapse in={hasOtherBilling} timeout={500}>
                {renderInputs(billing, handleBillingChange)}
            </Collapse>
        </Paper>
    );

    function renderInputs(
        items: IContactData,
        handleChange: (event: React.ChangeEvent<any>) => void
    ) {
        const {
            street,
            postcode,
            houseNumber,
            city,
            gender,
            firstName,
            lastName,
            companyName
        } = items;
        return (
            <Grid container direction="column">
                <GenderRadioGroup
                    gender={gender}
                    salutation
                    handleChange={handleChange}
                />
                <Divider variant="middle" light />
                {isCommercial && (
                    <CompanyDataInput
                        companyName={companyName}
                        handleChange={handleChange}
                    />
                )}
                <Grid item container>
                    <NameInputs
                        handleChange={handleChange}
                        firstName={firstName}
                        lastName={lastName}
                    />
                </Grid>
                <Divider variant="middle" light />
                <AddressInputFields
                    width="100%"
                    validation
                    required
                    handleChange={handleChange}
                    street={street ?? ''}
                    houseNumber={houseNumber ?? ''}
                    postcode={postcode ?? ''}
                    city={city ?? ''}
                    elevation={0}
                />
            </Grid>
        );
    }
}

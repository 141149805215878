import React from 'react';
import {
    IRecurringpayment,
    isInChangeState,
    IRecurringPaymentChangeData
} from '../../../../models/recurringpaymentInterface';
import {EnergyStep} from '../../../../models/offerInterface';
import {
    WgButton,
    WgChip,
    WgEnumerationList,
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from '../../../../styles/CustomComponents';
import {Box, Divider, Grid, Paper, useMediaQuery} from '@material-ui/core';
import {RecurringPaymentAmount} from './RecurringPaymentAmount';
import {
    formatDateFromString,
    formatMoney,
    formatRecurringpaymentChangeState
} from '../../../utils/Format';
import Logo from '../../../utils/Logo';
import {useSelector} from 'react-redux';
import {companyByNameSelector} from '../../../../store/reducers/selectors';
import {
    getAvailableOffer,
    IOffer,
    OfferState
} from '../../../../models/offerInterface';
import {CheckCircle} from '@material-ui/icons';
import {IExistingInsuranceProduct} from '../../../../models/productInterface';

interface CompareableContractProps {
    offer?: IOffer;
    recurringPayment?: IRecurringpayment;
    product?: IExistingInsuranceProduct;
    redirectLink?: string;
    content: string[];
}

export default function CompareableContract({
    recurringPayment,
    offer,
    product,
    redirectLink,
    content
}: CompareableContractProps) {
    const energyProduct = getAvailableOffer(offer);
    const isSmallPhone = useMediaQuery('(max-width: 320px)');
    const changeCompany = useSelector(state =>
        companyByNameSelector(state, {
            name: recurringPayment?.changeData.providerName
        })
    );

    if (
        typeof recurringPayment === 'undefined' &&
        typeof product === 'undefined'
    )
        return null;

    const productChangeData: IRecurringPaymentChangeData = {
        changeState: product?.status ?? 'none'
    };

    const change = recurringPayment?.changeData ?? productChangeData;
    if (typeof change === 'undefined') return null;

    const defaultLink = `/recurringpayments/${recurringPayment?.recurringPaymentId ??
        0}/add/energy/${EnergyStep.METER_TYPE_INPUT}`;
    const link = redirectLink ? redirectLink : defaultLink;
    const contractIsInChangeState = isInChangeState(change);

    if (change.changeState === 'available') {
        return (
            <React.Fragment>
                <Divider light />
                <Grid item>
                    <Paper>
                        {energyProduct && (
                            <RecurringPaymentAmount
                                disableInterval
                                colored
                                amount={energyProduct?.estimatedSavings}
                                subTitle="Mögliche jährliche Ersparnis"
                            />
                        )}
                        <Box
                            p={
                                typeof energyProduct === 'undefined'
                                    ? '1rem 1.25rem'
                                    : '0 1rem'
                            }>
                            {typeof offer !== 'undefined' &&
                            offer.offerState === OfferState.NOT_FOUND ? (
                                <Grid container direction="column">
                                    <Grid
                                        container
                                        wrap="nowrap"
                                        style={{marginBottom: '0.25rem'}}>
                                        <CheckCircle
                                            color="secondary"
                                            style={{marginRight: '1rem'}}
                                        />
                                        <WgTypography
                                            gutterTop="2px"
                                            text="content"
                                            color="textPrimary">
                                            Dein Vertrag ist bereits optimal!
                                        </WgTypography>
                                    </Grid>
                                    <WgTypography
                                        text="info"
                                        color="textSecondary">
                                        Wir konnten kein günstigeres Angebot für
                                        dich finden. Trotzdem sind wir weiterhin
                                        auf der Suche!
                                    </WgTypography>
                                </Grid>
                            ) : (
                                <React.Fragment>
                                    <WgEnumerationList
                                        dense={true}
                                        enumerationIcon={
                                            <img
                                                src="/img/icon-check-rounded.svg"
                                                alt="✓"
                                            />
                                        }
                                        background="paper"
                                        headline="Du kannst deinen Vertrag jetzt vergleichen!"
                                        content={content}
                                        textColor={'textPrimary'}
                                    />
                                </React.Fragment>
                            )}
                            {renderButtons()}
                        </Box>
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    } else if (contractIsInChangeState) {
        return (
            <React.Fragment>
                <Divider light />
                <Grid item>
                    <Paper style={{position: 'relative'}}>
                        <RecurringPaymentAmount
                            disableInterval
                            colored
                            amount={change?.estimatedYearlySavings ?? 0}
                            subTitle="Jährliche Ersparnis"
                            info={
                                typeof change.startDate === 'undefined'
                                    ? 'Wechseltermin ausstehend'
                                    : `Wechsel zum ${formatDateFromString(
                                          change.startDate
                                      )}`
                            }
                        />
                        {renderChangeListItem()}
                        <WgChip
                            style={{
                                position: 'absolute',
                                right: '1rem',
                                top: isSmallPhone ? '1.25rem' : '1.75rem',
                                minWidth: '150px',
                                maxWidth: 'fit-content'
                            }}
                            customColor="secondaryOutlined"
                            label={formatRecurringpaymentChangeState(
                                change.changeState
                            )}
                        />
                    </Paper>
                </Grid>
            </React.Fragment>
        );
    } else {
        return null;
    }

    function renderButtons() {
        return (
            <Box
                p="1rem 0"
                display="flex"
                flexWrap="nowrap"
                flex={1}
                flexDirection={{xs: 'column', md: 'row'}}
                justifyContent={{
                    xs: 'center',
                    md: 'space-between'
                }}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems={{xs: 'flex-end', md: 'center'}}
                    flex={1}>
                    <WgButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        link={link}
                        aria-label="next">
                        {typeof offer !== 'undefined' &&
                        offer.offerState === OfferState.NOT_FOUND
                            ? 'Vertragsdaten ändern'
                            : 'Jetzt vergleichen'}
                    </WgButton>
                </Box>
            </Box>
        );
    }

    function renderChangeListItem() {
        return (
            <WgListItem noHover background="paper">
                <WgListItemIcon>
                    <Logo
                        rounded="5px"
                        defaultImage="/img/bank.svg"
                        logoPath={changeCompany?.logoPath}
                    />
                </WgListItemIcon>
                <WgListItemContent>
                    <Box>
                        <WgTypography color="textSecondary" text="info">
                            Neuer Versorger
                        </WgTypography>
                        <WgTypography color="textPrimary" text="content">
                            {change.providerName}
                        </WgTypography>
                    </Box>
                    <Divider orientation="vertical" light />
                    <Box paddingRight={{xs: 0, md: '1rem'}}>
                        <WgTypography color="textSecondary" text="info">
                            Jährliche Kosten
                        </WgTypography>
                        <WgTypography color="textPrimary" text="content">
                            {formatMoney(change.yearlyCosts)}
                        </WgTypography>
                    </Box>
                </WgListItemContent>
            </WgListItem>
        );
    }
}

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {CompanyType, ICompany} from '../../../../../models/companyInterface';
import {fetchAllCompanies} from '../../../../../store/actions/companyActions';
import {
    WgButton,
    WgContainer,
    WgHeader
} from '../../../../../styles/CustomComponents';
import CategorySelection from '../../../../utils/CategorySelection';
import CompanySelection from '../../../../utils/CompanySelection';
import InsuranceNumberEntry from './InsuranceNumberEntry';
import {IPolicyManualAddition} from '../../../../../models/productInterface';
import {Box, Grid} from '@material-ui/core';
import {submitPolicy} from '../../../../../store/actions/recurringpaymentActions';
import {getAllCompanies} from '../../../../../store/reducers/selectors';
import {useLocation, useParams} from 'react-router';
import {push} from 'connected-react-router';
import {existsBrokermandate} from '../../../../../store/reducers/selectors/userSelector';
import {fetchRequiredInformations} from '../../../../../store/actions/userActions';
import BrokerMandateEdit from '../../../profile/documents/BrokerMandateEdit';
import BrokerMandateNoMandateExistsText from '../../../profile/documents/BrokerMandateNoMandateExistsText';

const thresholdForCompanyFetching = 300;

export enum AddPolicyStep {
    CATEGORY_SELECTION,
    COMPANY_SELECTION,
    INSURANCE_NUMBER_INPUT
}

interface AddPolicyControllerProps {
    hasBrokermandate: boolean;
    allCompanies?: ICompany[];
    fetchAllCompanies?: Function;
    push: Function;
    fetchRequiredInformations: Function;
    submitPolicy: Function;
}

function AddPolicyController({
    allCompanies,
    fetchAllCompanies,
    push,
    hasBrokermandate,
    fetchRequiredInformations,
    submitPolicy
}: AddPolicyControllerProps) {
    const {currentStep, categoryId} = useParams();
    const location = useLocation();
    const activeStep: AddPolicyStep = currentStep
        ? Number(currentStep)
        : AddPolicyStep.CATEGORY_SELECTION;
    const [policy, setPolicy] = useState<IPolicyManualAddition>({
        insuranceNumber: '',
        categoryId:
            typeof categoryId !== 'undefined' ? Number(categoryId) : undefined
    });

    useEffect(() => {
        if (
            typeof allCompanies === 'undefined' ||
            allCompanies.length < thresholdForCompanyFetching
        ) {
            fetchAllCompanies && fetchAllCompanies();
        }
    }, [fetchAllCompanies, allCompanies]);

    useEffect(() => {
        if (!hasBrokermandate) {
            fetchRequiredInformations();
        }
    }, [hasBrokermandate, fetchRequiredInformations]);

    const handleChange = (name: keyof IPolicyManualAddition) => (
        value: any
    ) => {
        setPolicy({
            ...policy,
            [name]: value
        });
        if (activeStep < AddPolicyStep.INSURANCE_NUMBER_INPUT) {
            handleStepChange(activeStep + 1);
        }
    };

    if (
        activeStep === AddPolicyStep.INSURANCE_NUMBER_INPUT &&
        !hasBrokermandate
    ) {
        return (
            <BrokerMandateEdit backNavigationOnSave={false}>
                <BrokerMandateNoMandateExistsText />
            </BrokerMandateEdit>
        );
    }

    const steps = [
        <CategorySelection
            initialText="Häufig gewählte Versicherungen"
            handleChange={handleChange}
            searchType={CompanyType.INSURANCE}
        />,
        <CompanySelection
            handleChange={handleChange}
            searchType={CompanyType.INSURANCE}
        />,
        <InsuranceNumberEntry
            handleChange={handleChange('insuranceNumber')}
            insuranceNumber={policy.insuranceNumber}
        />
    ];

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={'Versicherung hinzufügen'}
                hasBackButton
                backText={formatBackText(activeStep)}
                save={handleSubmit}
            />
            <WgContainer
                bodyContent
                disableGutters={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                <Grid
                    container
                    item
                    direction="column"
                    style={{flex: 1}}
                    wrap="nowrap">
                    <Grid
                        item
                        xs
                        container
                        direction="column"
                        justify="center"
                        wrap="nowrap">
                        {steps[activeStep]}
                    </Grid>
                    {activeStep === AddPolicyStep.INSURANCE_NUMBER_INPUT && (
                        <Grid
                            item
                            container
                            direction="column"
                            justify="center">
                            <Box
                                display="flex"
                                flexWrap="nowrap"
                                flex={1}
                                flexDirection={{xs: 'column', md: 'row'}}
                                justifyContent={{
                                    xs: 'flex-end',
                                    md: 'space-between'
                                }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{xs: 'flex-end', md: 'center'}}
                                    flex={1}
                                    marginBottom={{xs: '1rem', md: 0}}
                                    order={{xs: 1, md: 2}}>
                                    <WgButton
                                        disabled={
                                            policy.insuranceNumber.length === 0
                                        }
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleSubmit}
                                        aria-label="next">
                                        Weiter
                                    </WgButton>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-start',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    order={{xs: 2, md: 1}}>
                                    <WgButton
                                        onClick={handleSubmit}
                                        disabled={
                                            policy.insuranceNumber.length > 0
                                        }
                                        variant="contained"
                                        color="default"
                                        fullWidth
                                        aria-label="skip">
                                        Überspringen
                                    </WgButton>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function handleStepChange(nextStep: number) {
        push(setStepUrl(nextStep));
    }

    function setStepUrl(nextStep: number): string {
        const path = location.pathname.slice(0, -1);
        return path + nextStep;
    }

    function handleSubmit() {
        submitPolicy({
            ...policy,
            insuranceNumber:
                policy.insuranceNumber.length === 0
                    ? undefined
                    : policy.insuranceNumber
        });
    }

    function formatBackText(step: AddPolicyStep) {
        switch (step) {
            case AddPolicyStep.CATEGORY_SELECTION:
                return 'Versicherungswelt';
            case AddPolicyStep.COMPANY_SELECTION:
                return 'Versicherungskategorie wählen';
            case AddPolicyStep.INSURANCE_NUMBER_INPUT:
                return 'Versicherungsgesellschaft wählen';
        }
    }
}

function mapStateToProps(state: any) {
    return {
        allCompanies: getAllCompanies(state),
        hasBrokermandate: existsBrokermandate(state)
    };
}

export default connect(mapStateToProps, {
    fetchAllCompanies,
    submitPolicy,
    push,
    fetchRequiredInformations
})(AddPolicyController);

import React, {Component} from 'react';
import IFrameWrapper from '../../../utils/IFrameWrapper';
import {connect} from 'react-redux';
import {allInsuranceProductsSelector} from '../../../../store/reducers/selectors';
import {productActions} from '../../../../store/actions';
import {push} from 'connected-react-router';
import {fetchPolicies} from '../../../../store/actions/recurringpaymentActions';

const {fetchInsuranceProductURL, submitProductInformation} = productActions;

interface IProps {
    products: any[];
    url: string;
    match: any;
    fetchInsuranceProductURL?: Function;
    submitProductInformation?: Function;
    fetchPolicies?: Function;
}
interface IState {
    id: number;
    name: string;
    iFrame: any;
    isLoaded: boolean;
    iFrameHeight: string;
    isStepConfirmend: boolean; //Workaround Action got called multiple times in stepConfirm
}
//ToDo: Refactoring as Functional Component
class ProductForm extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const {product} = this.props.match.params;
        const productName = this.props.products.find(
            element => element.id === Number(product)
        )?.name;

        this.state = {
            id: product,
            name: productName,
            iFrame: undefined,
            isLoaded: false,
            isStepConfirmend: false,
            iFrameHeight: '100%'
        };

        this.props.fetchInsuranceProductURL &&
            this.props.fetchInsuranceProductURL(product);
    }

    frameListener() {
        window.addEventListener('message', this.eventHandler.bind(this), false);
    }

    componentWillUnmount() {
        window.removeEventListener(
            'message',
            this.eventHandler.bind(this),
            false
        );
    }

    //  Geld.de
    // •    stepIndex : Formularschritte vor der Tarifliste,
    // •	result : Tarifliste,
    // •	comparison : Tarifvergleich,
    // •	stepContractIndex : Antragsschritte (nach der Tarifliste),
    // •	stepConfirm : Bestätigungsschritt (Das ist der Schritt auf den Sie reagieren wollen)
    eventHandler(e: MessageEvent) {
        const iFrameId = 'insuranceFrame';
        const doc = document.getElementById(iFrameId);

        if (doc) {
            let eventName = e.data[0],
                messageValue = e.data[1];

            if (eventName && eventName.includes('step')) {
                switch (messageValue) {
                    case 'setIframeHeight':
                        this.setState({iFrameHeight: messageValue});
                        break;
                    case 'stepIndex':
                        break;
                    case 'stepConfirm':
                        this.props.submitProductInformation &&
                            this.props.submitProductInformation(this.state.id);
                        push('/products/new');
                        this.props.fetchPolicies && this.props.fetchPolicies();
                        break;
                    default:
                        break;
                }
            }
        }
    }

    render() {
        let IFrameSrc = undefined;
        if (this.props.url) IFrameSrc = this.props.url[this.state.id];

        return (
            <React.Fragment>
                {IFrameSrc ? (
                    <IFrameWrapper
                        src={IFrameSrc}
                        id="insuranceFrame"
                        title={'Versicherungswelt'}
                        onLoaded={this.frameListener.bind(this)}
                        headerText={this.state.name}
                        backButtonText="Versicherungsauswahl"
                    />
                ) : (
                    <div />
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        products: allInsuranceProductsSelector(state),
        url: state.products?.url
    };
}

export default connect(
    mapStateToProps,
    {
        fetchInsuranceProductURL,
        submitProductInformation,
        fetchPolicies
    }
    //@ts-ignore
)(ProductForm);

import React, {useCallback, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
    WgButton,
    WgContainer,
    WgHeader
} from '../../../../../styles/CustomComponents';
import {Box, Grid} from '@material-ui/core';
import {push} from 'connected-react-router';
import {useHistory, useLocation, useParams} from 'react-router';
import {setNotification} from '../../../../../store/actions/notificationActions';
import {
    allCompaniesByTypeSelector,
    getCarInsuranceOptions,
    getVehicleData,
    getVehicleHolderData,
    productByIdSelector,
    recurringPaymentByIdSelector
} from '../../../../../store/reducers/selectors';
import {
    CarInsuranceOneProductType,
    getCarSwitchInsuranceWording,
    ICarInsurancePaymentAccount,
    ICarInsuranceVehicleDataDetails,
    IExistingInsuranceProduct,
    IPaymentData,
    IPolicyData,
    IPolicyFormData,
    IPreviousPolicyData,
    IProposal,
    IProposalTariff,
    IVehicleData,
    IVehicleHolder,
    PaymentMethod
} from '../../../../../models/productInterface';
import {carSwitchTemplate} from '../../../../../store/actions/productActionsDEVData';
import {submitRecurringPayment} from '../../../../../store/actions/recurringpaymentActions';
import {
    CarInsuranceCompletion,
    CarInsuranceOfferSelection,
    CarInsurancePayment
} from '../../..';
import {
    getCarInsurancePayment,
    getCarInsuranceProposal,
    getNoClaimDiscount,
    getPolicyData,
    getPreviousPolicyData,
    getVehicleDataDetails,
    policyFormDataSelector
} from '../../../../../store/reducers/selectors/carInsuranceSelector';
import {existsBrokermandate} from '../../../../../store/reducers/selectors/userSelector';
import {
    fetchCarInsuranceVehicleHolderData,
    setCarInsuranceManualPayment,
    setPreviousCarInsurancePolicyData,
    submitCarInsuranceSwitchRequest,
    updateCalculationId,
    updateCarInsuranceNoClaimDiscount,
    updateCarInsurancePolicyData,
    updateCarInsuranceVehicleData,
    updateCarInsuranceVehicleDataDetails,
    updateCarInsuranceVehicleHolder
} from '../../../../../store/actions/productActions';
import {fetchRequiredInformations} from '../../../../../store/actions/userActions';
import {IAccount} from '../../../../../models/accountInterface';
import CarInsuranceExistingContract from './Switch/CarInsuranceExistingContract';
import CarInsuranceSwitchDate from './Switch/CarInsuranceSwitchDate';
import BrokerMandateEdit from '../../../profile/documents/BrokerMandateEdit';
import BrokerMandateNoMandateExistsText from '../../../profile/documents/BrokerMandateNoMandateExistsText';
import {
    IntervalType,
    IRecurringpayment
} from '../../../../../models/recurringpaymentInterface';
import {singleAccountSelector} from '../../../../../store/reducers/selectors/accountSelector';
import {CompanyType} from '../../../../../models/companyInterface';
import AccountSelection from '../../../../utils/AccountSelection';

export enum CarSwitchInsuranceStep {
    EXISTING_CONTRACT_VALIDATION,
    START_DATE_VALIDATION,
    OFFER_SELECTION,
    PAYMENT_SELECTION,
    DATA_VALIDATION,
    ACCOUNT_SELECTION
}

export interface CarSwitchInsuranceControllerProps {
    push: Function;
    formData: IPolicyFormData;
    policyData: IPolicyData;
    vehicleHolderData: IVehicleHolder;
    setCarInsuranceManualPayment: Function;
    vehicleDataDetails: ICarInsuranceVehicleDataDetails;
    updateCarInsurancePolicyData: Function;
    updateCalculationId: Function;
    carInsurancePaymentAccount: ICarInsurancePaymentAccount;
    fetchCarInsuranceVehicleHolderData: Function;
    fetchRequiredInformations?: Function;
    setPreviousCarInsurancePolicyData?: Function;
    vehicleData: IVehicleData;
    proposalData: IProposal;
    hasBrokermandate: boolean;
    submitCarInsuranceSwitchRequest: Function;
    previousPolicyData: IPreviousPolicyData;
    previousContract?: IExistingInsuranceProduct;
    recurringPayment?: IRecurringpayment;
    usedBankAccount?: IAccount;
}

function CarSwitchInsuranceController({
    push,
    vehicleDataDetails,
    carInsurancePaymentAccount,
    fetchRequiredInformations,
    fetchCarInsuranceVehicleHolderData,
    setCarInsuranceManualPayment,
    updateCarInsurancePolicyData,
    setPreviousCarInsurancePolicyData,
    vehicleHolderData,
    vehicleData,
    policyData,
    updateCalculationId,
    proposalData,
    formData,
    hasBrokermandate,
    submitCarInsuranceSwitchRequest,
    previousContract,
    recurringPayment,
    previousPolicyData,
    usedBankAccount
}: CarSwitchInsuranceControllerProps) {
    const {currentStep, productId} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const activeStep: CarSwitchInsuranceStep = currentStep
        ? Number(currentStep)
        : CarSwitchInsuranceStep.EXISTING_CONTRACT_VALIDATION;
    const location = useLocation();
    const [paymentData, setPaymentData] = useState<ICarInsurancePaymentAccount>(
        {
            ...carInsurancePaymentAccount,
            paymentMethod: PaymentMethod.InApp
        }
    );
    const [contract, setContract] = useState<
        IExistingInsuranceProduct | undefined
    >(previousContract);

    const [selectedAccount, setSelectedAccount] = useState<
        IAccount | undefined
    >(usedBankAccount);

    const [formDataScoped] = useState<IPolicyFormData>(formData);
    const [policyDataScope, setPolicyData] = useState<IPolicyData>(policyData);
    const [previousPolicyDataScope, setPreviousPolicyData] = useState<
        IPreviousPolicyData
    >(previousPolicyData);
    const [proposal, setProposal] = useState<IProposal>(proposalData);

    const [proposalTariff, setProposalTarif] = useState<IProposalTariff>(
        proposalData.normal
    );
    const [eco, setEco] = useState<boolean>(false);
    const [routerHistoryIndex, setRouterHistoryIndex] = useState<number>();

    const [isValid, setIsValid] = useState(true);
    const [validationEnabled, setValidationEnabled] = useState(false);

    //Two Steps back to
    const saveRouterIndex = useCallback(() => {
        setRouterHistoryIndex(history.length + 1);
    }, [setRouterHistoryIndex, history]);

    function handleValidation(isValid: boolean) {
        setIsValid(isValid);
    }

    const [isInit, setIsInit] = useState(true);

    useEffect(() => {
        if (isInit) {
            fetchCarInsuranceVehicleHolderData &&
                fetchCarInsuranceVehicleHolderData();
            saveRouterIndex();
            setPreviousPolicyData({
                ...previousPolicyDataScope,
                contractId: Number(productId) ?? 0
            });
            if (
                typeof recurringPayment !== 'undefined' &&
                typeof contract !== 'undefined'
            ) {
                const contractPaymentData = {
                    amount: recurringPayment.lastAmount,
                    paymentInterval: recurringPayment.paymentInterval
                };
                setContract({...contract, ...contractPaymentData});
            }

            setIsInit(false);
        }
    }, [
        fetchCarInsuranceVehicleHolderData,
        productId,
        recurringPayment,
        previousPolicyDataScope,
        setPreviousPolicyData,
        isInit,
        contract,
        setIsInit,
        saveRouterIndex
    ]);

    useEffect(() => {
        if (!hasBrokermandate) {
            fetchRequiredInformations && fetchRequiredInformations();
        }
    }, [hasBrokermandate, fetchRequiredInformations]);

    if (typeof contract === 'undefined') return null;

    const steps = [
        <CarInsuranceExistingContract
            recurringPayment={recurringPayment}
            contract={contract}
            setContract={setContract}
            formData={formDataScoped}
            policyData={policyDataScope}
            updatePolicyData={setPolicyData}
            setIsValid={handleValidation}
            validationEnabled={validationEnabled}
            process={CarInsuranceOneProductType.SWITCH_INSURANCE}
        />,
        <CarInsuranceSwitchDate
            policyData={policyDataScope}
            updatePolicyData={setPolicyData}
            validationCallback={handleValidation}
            validationEnabled={validationEnabled}
        />,
        <CarInsuranceOfferSelection
            proposal={proposal}
            productType={policyDataScope.productType}
            setProposal={setProposal}
            vehicleData={vehicleData}
            setPolicyData={setPolicyData}
            policyData={policyDataScope}
            updateCalculationId={updateCalculationId}
            updateCarInsurancePolicyData={updateCarInsurancePolicyData}
            redirectLink={`/products/${contract.id}/purchase/car/switch/${CarSwitchInsuranceStep.PAYMENT_SELECTION}`}
            process={CarInsuranceOneProductType.SWITCH_INSURANCE}
            setEco={setEco}
            setProposalTarif={setProposalTarif}
        />,
        <CarInsurancePayment
            setIsValid={handleValidation}
            paymentData={paymentData}
            setPaymentData={setPaymentData}
            selectedAccount={selectedAccount}
            validationEnabled={validationEnabled}
            redirectLink={`/products/${contract.id}/purchase/car/switch/${CarSwitchInsuranceStep.ACCOUNT_SELECTION}`}
        />,
        <CarInsuranceCompletion
            vehicleData={vehicleData}
            vehicleHolderData={vehicleHolderData}
            hasBrokermandate={hasBrokermandate}
            carInsuranceVehicleDataDetails={vehicleDataDetails}
            policyData={policyDataScope}
            fetchRequiredInformations={fetchRequiredInformations}
            eco={eco}
            proposalTariff={proposalTariff}
            paymentData={paymentData}
            location={location}
            selectedAccount={selectedAccount}
            process={CarInsuranceOneProductType.SWITCH_INSURANCE}
        />,
        <AccountSelection handleChange={handleAccountSelection} />
    ];

    if (
        !hasBrokermandate &&
        activeStep === CarSwitchInsuranceStep.DATA_VALIDATION
    ) {
        return (
            <BrokerMandateEdit backNavigationOnSave={false}>
                <BrokerMandateNoMandateExistsText />
            </BrokerMandateEdit>
        );
    }

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={getCarSwitchInsuranceWording(activeStep)}
                save={handleSubmit}
                hasBackButton
                backText={getCarSwitchInsuranceWording(activeStep - 1)}
            />
            <WgContainer bodyContent disableGutters={false}>
                <Grid
                    container
                    item
                    direction="column"
                    //TODO this depends on WgContainer padding bottom - needs refactoring because ::after is garbage
                    style={{
                        height: 'calc(100% - 1.5rem)',
                        minHeight: 'fit-content'
                    }}
                    wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '1 1 80%', minHeight: 'fit-content'}}
                        container
                        justify="center"
                        wrap="nowrap">
                        {steps[activeStep]}
                    </Grid>
                    {showBaseButtons() && (
                        <Grid
                            item
                            style={{flex: 'auto'}}
                            container
                            direction="column"
                            justify="center">
                            <Box
                                display="flex"
                                flexWrap="nowrap"
                                paddingTop={{xs: '1rem', md: '1rem'}}
                                flex={1}
                                flexDirection={{xs: 'column', md: 'row'}}
                                justifyContent={{
                                    xs: 'flex-end',
                                    md: 'space-between'
                                }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-end',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    marginBottom={{xs: '1rem', md: 0}}
                                    order={{xs: 1, md: 2}}>
                                    <WgButton
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={validationEnabled && !isValid}
                                        onClick={handleSubmit}
                                        aria-label="next">
                                        {activeStep ===
                                        CarSwitchInsuranceStep.DATA_VALIDATION
                                            ? 'Kostenpflichtig abschließen'
                                            : 'Weiter'}
                                    </WgButton>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxHeight="fit-content"
                                    alignItems={{
                                        xs: 'flex-start',
                                        md: 'center'
                                    }}
                                    flex={1}
                                    order={{xs: 2, md: 1}}>
                                    <WgButton
                                        onClick={handleCancel}
                                        variant="contained"
                                        color="default"
                                        fullWidth
                                        aria-label="back">
                                        {'Abbrechen'}
                                    </WgButton>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function showBaseButtons(): boolean {
        switch (activeStep) {
            case CarSwitchInsuranceStep.OFFER_SELECTION:
            case CarSwitchInsuranceStep.ACCOUNT_SELECTION:
                return false;
            default:
                return true;
        }
    }

    function handleAccountSelection(account: IAccount) {
        setSelectedAccount(account);
        const newPaymentData = {
            accountHolderName: account.accountHolderName,
            iban: account.iban,
            paymentMethod: paymentData.paymentMethod
        };
        setPaymentData({...newPaymentData, accountId: account.id});
        setCarInsuranceManualPayment &&
            setCarInsuranceManualPayment(newPaymentData);
        handleBack();
    }

    function handleBack() {
        history.goBack();
    }

    function handleCancel() {
        dispatch(
            setNotification({
                title: 'Bist du dir sicher?',
                text:
                    'Du musst diese Fragen nur einmalig beantworten! Anschließend wechseln wir für dich jährlich voll automatisiert. Bist du dir sicher, dass du abbrechen möchtest?',
                isAgreeable: true,
                onAgree: () => {
                    push('/products');
                },
                onDisagree: () => {},
                isDisagreeable: true,
                open: true,
                agreeText: 'JA',
                disagreeText: 'NEIN'
            })
        );
    }

    function handleSubmit() {
        if (!isValid) setValidationEnabled(true);
        else {
            if (activeStep === CarSwitchInsuranceStep.DATA_VALIDATION) {
                const callBack =
                    typeof routerHistoryIndex === 'undefined'
                        ? () => history.push('/products')
                        : () => {
                              //   history.go(-routerHistoryIndex);
                              if (typeof contract !== 'undefined')
                                  history.push(
                                      `/products/details/${contract.id}`
                                  );
                              else history.push('/products');
                          };
                submitCarInsuranceSwitchRequest &&
                    submitCarInsuranceSwitchRequest(contract, callBack);
                return;
            } else if (
                activeStep ===
                CarSwitchInsuranceStep.EXISTING_CONTRACT_VALIDATION
            ) {
                updateCarInsurancePolicyData &&
                    updateCarInsurancePolicyData({
                        ...policyDataScope,
                        noClaimDiscount: '',
                        amount: contract?.amount,
                        paymentInterval: contract?.paymentInterval
                    });
            } else if (
                activeStep === CarSwitchInsuranceStep.START_DATE_VALIDATION
            ) {
                updateCarInsurancePolicyData &&
                    updateCarInsurancePolicyData(policyDataScope);
                const tmpPaymentData: IPaymentData = {
                    iban: usedBankAccount?.iban ?? '',
                    paymentMethod: PaymentMethod.InApp,
                    accountHolderName: usedBankAccount?.accountHolderName ?? ''
                };
                setPaymentData(tmpPaymentData);
                setCarInsuranceManualPayment &&
                    setCarInsuranceManualPayment(tmpPaymentData);

                setPreviousPolicyData({
                    ...previousPolicyDataScope,
                    insurer: contract?.company?.name ?? ''
                });
                setPreviousCarInsurancePolicyData &&
                    setPreviousCarInsurancePolicyData({
                        ...previousPolicyDataScope,
                        insurer: contract?.company?.name ?? ''
                    });

                setProposal({
                    ...carSwitchTemplate,
                    green: {
                        ...carSwitchTemplate.green,
                        tariffWithTax: calculateAmount(contract)
                    },
                    normal: {
                        ...carSwitchTemplate.normal,
                        tariffWithTax: calculateAmount(contract, 0.95)
                    }
                });
                dispatch(
                    setNotification({
                        title: 'Kündigung deines bisherigen Vertrags',
                        text:
                            'Wenn du den Wechsel abschließt, kümmern wir uns um die Kündigung deines bisherigen Vertrages!',
                        isAgreeable: true,
                        open: true,
                        agreeText: 'OKAY'
                    })
                );
            }

            handleStepChange(activeStep + 1);
            setValidationEnabled(false);
        }
    }

    function calculateAmount(
        contract?: IExistingInsuranceProduct,
        multiplicationFactor?: number
    ) {
        if (contract?.amount === undefined) return 0;

        let amount = contract.amount;

        if (contract?.paymentInterval === IntervalType.MONTHLY)
            amount = amount * 12;

        return amount * (multiplicationFactor ?? 1);
    }

    function handleStepChange(nextStep: CarSwitchInsuranceStep) {
        push(setStepUrl(nextStep));
    }

    function setStepUrl(nextStep: number): string {
        const path = location.pathname.slice(0, -1);
        return path + nextStep;
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    const {productId} = ownProps.match.params;
    const product = productByIdSelector(state, {productId});

    return {
        formData: policyFormDataSelector(state),
        policyData: getPolicyData(state),
        previousPolicyData: getPreviousPolicyData(state) ?? {insurer: ''},
        vehicleHolderData: getVehicleHolderData(state),
        options: getCarInsuranceOptions(state),
        carInsuranceVehicleDataDetails: getVehicleDataDetails(state),
        carInsuranceNoClaimDiscount: getNoClaimDiscount(state),
        vehicle: getVehicleData(state),
        proposalData: getCarInsuranceProposal(state),
        hasBrokermandate: existsBrokermandate(state),
        carInsurancePaymentAccount: getCarInsurancePayment(state),
        allCompanies: allCompaniesByTypeSelector(state, {
            companyType: CompanyType.INSURANCE
        }),
        previousContract: product,
        recurringPayment: recurringPaymentByIdSelector(state, {
            recurringPaymentId: product?.recurringPaymentId
        }),
        usedBankAccount: singleAccountSelector(state, {
            accountId: product?.accountId
        })
    };
};

export default connect(mapStateToProps, {
    push,
    submitRecurringPayment,
    fetchRequiredInformations,
    fetchCarInsuranceVehicleHolderData,
    updateCarInsuranceNoClaimDiscount,
    updateCarInsurancePolicyData,
    updateCarInsuranceVehicleDataDetails,
    updateCarInsuranceVehicleData,
    updateCarInsuranceVehicleHolder,
    submitCarInsuranceSwitchRequest,
    setPreviousCarInsurancePolicyData,
    updateCalculationId,
    setCarInsuranceManualPayment
})(CarSwitchInsuranceController);

import {createDeepEqualSelector} from './utils';
import {IBank, IBankLink} from '../../../models/bankInterface';

export const bankSelector = (state: any) => state?.banks;
export const allBanksSelector = (state: any) =>
    bankSelector(state)?.allBanks || [];

export const bankBySearchStringSelector = createDeepEqualSelector(
    [allBanksSelector, (state: any, props: any) => props.searchString],
    (banks: (IBank | IBankLink)[], searchString: string) => {
        if (!Array.isArray(banks) || !searchString) {
            return undefined;
        }
        return banks.find(
            (bank: IBank | IBankLink) => bank.searchString === searchString
        );
    }
);

import React, {Component} from 'react';
import {Button} from '@material-ui/core';
import {connect} from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {getNotification} from '../../store/reducers/selectors';
import {WgTypography} from '../../styles/CustomComponents';

export class Notification extends Component<any, {}> {
    render() {
        return (
            <React.Fragment>
                {this.props.image
                    ? this.renderImage(
                          this.props.image,
                          this.props.title,
                          this.props.text
                      )
                    : this.renderDefault(
                          this.props.title,
                          this.props.text,
                          this.props.content
                      )}
                {this.renderDialogActions()}
            </React.Fragment>
        );
    }

    private renderDefault(title?: string, text?: string, content?: any) {
        return (
            <React.Fragment>
                {title && (
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                )}
                {content && <DialogContent>{content}</DialogContent>}
                {text && (
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            style={{whiteSpace: 'pre-line'}}>
                            {text}
                        </DialogContentText>
                    </DialogContent>
                )}
            </React.Fragment>
        );
    }

    private renderImage(image?: any, title?: string, text?: string) {
        return (
            <React.Fragment>
                {typeof image === 'string' && (
                    <img
                        alt=""
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                        src={image}
                    />
                )}
                <DialogContent>
                    {title && (
                        <DialogContentText>
                            <WgTypography
                                color="textPrimary"
                                style={{
                                    fontWeight: 'bolder',
                                    textAlign: 'center'
                                }}>
                                {title}
                            </WgTypography>
                        </DialogContentText>
                    )}
                    {typeof image !== 'string' && (
                        <DialogContent
                            style={{display: 'flex', justifyContent: 'center'}}>
                            {image}
                        </DialogContent>
                    )}
                    {text && (
                        <DialogContentText
                            color="textPrimary"
                            style={{textAlign: 'center'}}>
                            {text}
                        </DialogContentText>
                    )}
                </DialogContent>
            </React.Fragment>
        );
    }

    private renderDialogActions() {
        return (
            <React.Fragment>
                {(this.props.isDisagreeable || this.props.isAgreeable) && (
                    <DialogActions>
                        {this.props.isDisagreeable && (
                            <Button
                                onClick={() =>
                                    this.props.onClose() ||
                                    (this.props.onDisagree &&
                                        this.props.onDisagree())
                                }
                                style={{
                                    color: this.props.disagreeColor
                                        ? this.props.disagreeColor
                                        : '#818181'
                                }}>
                                {this.props.disagreeText || 'ABLEHNEN'}
                            </Button>
                        )}
                        {this.props.isAgreeable && (
                            <Button
                                onClick={() =>
                                    this.props.onClose() ||
                                    (this.props.onAgree && this.props.onAgree())
                                }
                                style={{
                                    color: this.props.agreeColor
                                        ? this.props.agreeColor
                                        : '#3fd298'
                                }}
                                autoFocus>
                                {this.props.agreeText || 'OK'}
                            </Button>
                        )}
                    </DialogActions>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        ...getNotification(state)?.dialog
    };
}

export default connect(mapStateToProps)(Notification);

import React from 'react';
import {Box, Grid} from '@material-ui/core';
import AccountListItem from '../../utils/AccountListItem';
import {connect} from 'react-redux';
import {formatPaymentInterval} from '../../utils/Format';
import {IRecurringpayment} from '../../../models/recurringpaymentInterface';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgSplitButton,
    WgTypography
} from '../../../styles/CustomComponents';
import {loadingIdAllRecurringPayments} from '../../../store/actions/loadingIds';
import {allRecurringPaymentWithoutInsurancesSelector} from '../../../store/reducers/selectors';

interface IProps {
    allRecurringpayments?: IRecurringpayment[];
}

const warningSigns = ['available', 'meterNumberMissing'];

function Recurringpayments({allRecurringpayments}: IProps) {
    return (
        <WgContainer bodyApp>
            <WgHeader headerText="Vertragswelt" />
            {recurringpaymentsAvailable()
                ? renderRecurringpaymentsAvailable()
                : renderRecurringpaymentsNotAvailable()}
        </WgContainer>
    );

    function renderRecurringpaymentsAvailable() {
        return (
            <WgContainer bodyContent disableGutters={false}>
                <WgSplitButton
                    disabled="right"
                    leftText="Bestehenden Vertrag hinzufügen"
                    rightText="Neuen Vertrag abschließen"
                    leftLink="/recurringpayments/add/0"
                    rightLink=""
                />
                <Box paddingTop="4px">
                    {allRecurringpayments && allRecurringpayments.length > 0 && (
                        <React.Fragment>
                            <Box marginTop={{xs: '2vh', md: '1rem'}}>
                                <WgTypography
                                    gutterBottom
                                    header="uppercase"
                                    color="textSecondary">
                                    Verträge
                                </WgTypography>
                            </Box>
                            {renderRecurringpayments(allRecurringpayments)}
                        </React.Fragment>
                    )}
                </Box>
            </WgContainer>
        );
    }

    function recurringpaymentsAvailable() {
        return allRecurringpayments && allRecurringpayments.length > 0;
    }

    function renderRecurringpayments(recPayments: IRecurringpayment[]) {
        return (
            <Grid container justify="space-between" spacing={1}>
                {recPayments.map(mapRecurringpayments)}
            </Grid>
        );
    }

    function mapRecurringpayments(rec: IRecurringpayment, index: number) {
        const hasWarning = warningSigns.includes(
            rec.changeData?.changeState ?? ''
        );
        return (
            <Grid item xs={12} md={6} key={index}>
                <AccountListItem
                    active
                    warning={hasWarning}
                    loadingId={loadingIdAllRecurringPayments}
                    key={rec.recurringPaymentId}
                    link={`/recurringpayments/details/${rec.recurringPaymentId}?categoryId=${rec.categoryId}`}
                    logoPath={rec.logoPath}
                    defaultImage={'/img/icon-recurringpayment-circle.svg'}
                    header={rec.categoryName}
                    subHeader={rec.receiverName || rec.purpose}
                    amount={rec.lastAmount}
                    subAmountString={formatPaymentInterval(rec.paymentInterval)}
                />
            </Grid>
        );
    }

    function renderRecurringpaymentsNotAvailable() {
        return (
            <WgContainer
                bodyContent
                disableGutters={false}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                }}>
                <Grid container direction="column" item xs wrap="nowrap">
                    <Grid
                        item
                        style={{flex: '0.5 1 0%'}}
                        container
                        alignItems="flex-end"
                        justify="center">
                        <Box m="1rem 0" paddingX="4px">
                            <WgTypography
                                color="primary"
                                text="contentInfo"
                                align="center">
                                Noch keine Verträge hier...
                            </WgTypography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        style={{flex: '0.5 1 0%'}}
                        container
                        alignItems="flex-start">
                        <WgTypography
                            color="textSecondary"
                            text="content"
                            align="center">
                            WechselGott erkennt aus deinen Umsatzdaten welche
                            Verträge du hast und wo du zu viel Geld ausgibst.
                            <br />
                            <br />
                            Verknüpfe jetzt dein Konto und finde heraus, wie
                            viel du sparen kannst!
                        </WgTypography>
                    </Grid>
                    <Grid item container xs>
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent={{xs: 'flex-end', md: 'flex-start'}}>
                            <WgButton
                                aria-label="signup"
                                variant="contained"
                                color="primary"
                                fullWidth
                                link={'/accounts/add'}>
                                <img
                                    style={{marginRight: '0.5rem'}}
                                    src="/img/navigation/add-white.svg"
                                    alt=""
                                />
                                Konto verknüpfen
                            </WgButton>
                            <WgButton
                                style={{marginTop: '1rem'}}
                                aria-label="signup"
                                variant="contained"
                                color="primary"
                                fullWidth
                                link={'/recurringpayments/add/0'}>
                                <img
                                    style={{marginRight: '0.5rem'}}
                                    src="/img/navigation/add-white.svg"
                                    alt=""
                                />
                                Vertrag hinzufügen
                            </WgButton>
                        </Box>
                    </Grid>
                </Grid>
            </WgContainer>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        allRecurringpayments: allRecurringPaymentWithoutInsurancesSelector(
            state,
            {}
        )
    };
}

export default connect(mapStateToProps, {})(Recurringpayments);

import {
    FETCH_ACCOUNTS_BY_ID_SUCCESS,
    FETCH_ALL_ACCOUNTS_SUCCESS,
    FETCH_DEPOTS_FROM_ACCOUNT_ID_SUCCESS,
    FETCH_TRANSACTIONS_FROM_ACCOUNT_ID_SUCCESS,
    SET_ACCOUNT_VISIBILITY_SUCCESS
} from '../actions/actionTypes';
import get from 'lodash.get';
import {IAccount} from '../../models/accountInterface';

const temp = {}; //could be used for notifications form the BackEnd

export const initialState = get(temp, 'accounts', {
    allAccounts: [],
    transactions: {},
    depots: {}
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ALL_ACCOUNTS_SUCCESS:
            if (!Array.isArray(action.payload)) {
                return state;
            }

            return {
                ...state,
                allAccounts: action.payload
            };

        case FETCH_ACCOUNTS_BY_ID_SUCCESS:
            if (!(action.payload instanceof Array)) return state;
            return {
                ...state,
                allAccounts: updateAccounts(state, action.payload)
            };

        case SET_ACCOUNT_VISIBILITY_SUCCESS:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.visible !== 'boolean'
            ) {
                return state;
            }

            return {
                ...state,
                allAccounts: state.allAccounts.map((account: IAccount) => {
                    if (account.id === action.payload.accountId) {
                        return {
                            ...account,
                            visible: action.payload.visible
                        };
                    } else {
                        return account;
                    }
                })
            };

        case FETCH_TRANSACTIONS_FROM_ACCOUNT_ID_SUCCESS:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.id !== 'number' ||
                !Array.isArray(action.payload.data)
            ) {
                return state;
            }

            const response = action.payload.data;
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    [action.payload.id]:
                        response instanceof Array ? response : []
                }
            };
        case FETCH_DEPOTS_FROM_ACCOUNT_ID_SUCCESS:
            if (
                typeof action.payload !== 'object' ||
                typeof action.payload.id !== 'number' ||
                !Array.isArray(action.payload.data)
            ) {
                return state;
            }

            return {
                ...state,
                depots: {
                    ...state.depots,
                    [action.payload.id]: action.payload.data
                }
            };
        default:
            return state;
    }
};

export function updateAccounts(state: any, payload: IAccount[]): IAccount[] {
    const allAccounts: IAccount[] = [...state.allAccounts];
    const updatedAccounts: IAccount[] = [];

    for (const oldAccount of allAccounts) {
        const updated = payload.find(
            newAccount => newAccount.id === oldAccount.id
        );
        if (typeof updated === 'undefined') {
            updatedAccounts.push(oldAccount);
        } else {
            updatedAccounts.push(updated);
        }
    }
    return updatedAccounts;
}

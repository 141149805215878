import React from 'react';
import {
    WgListItem,
    WgListItemContent,
    WgTypography
} from '../../../../../../styles/CustomComponents';
import {Divider, Grid} from '@material-ui/core';
import {noClaimDiscountList} from './NoClaimDiscountList';
import {INoClaimDiscount} from '../../../../../../models/productInterface';

interface CarInsuranceNoClaimDiscountSelectionProps {
    handleSelection: (shortName: string) => any;
}

export default function CarInsuranceNoClaimDiscountSelection({
    handleSelection
}: CarInsuranceNoClaimDiscountSelectionProps) {
    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center"
            spacing={1}>
            {renderNoClaimDiscount()}
        </Grid>
    );

    function renderNoClaimDiscount() {
        return noClaimDiscountList.map(
            (item: INoClaimDiscount, index: number) => (
                <React.Fragment>
                    <WgListItem
                        key={`noClaimDisount-${index}`}
                        style={{width: '100%'}}
                        square
                        onClick={() => handleSelection(item.shortName)}>
                        <WgListItemContent>
                            <WgTypography
                                text="content"
                                color="textPrimary"
                                style={{minWidth: '4rem'}}>
                                {item.shortName}
                            </WgTypography>
                            <WgTypography text="content" color="textPrimary">
                                {item.name}
                            </WgTypography>
                        </WgListItemContent>
                    </WgListItem>
                    {index !== noClaimDiscountList.length - 1 && (
                        <Divider light />
                    )}
                </React.Fragment>
            )
        );
    }
}

import React from 'react';
import {Grid} from '@material-ui/core';
import {
    WgEnumerationList,
    WgTypography
} from '../../../../../styles/CustomComponents';

export default function FetchOfferLoadingContent() {
    return (
        <Grid container>
            <Grid item xs={12} style={{paddingBottom: '1rem'}}>
                <WgTypography
                    text="content"
                    align="center"
                    color="textSecondary">
                    WechselGott vergleicht gerade alle Verträge, um den Besten
                    für dich herauszusuchen.
                    <br />
                    Dies kann einen Moment dauern.
                </WgTypography>
            </Grid>
            <Grid item xs={12}>
                <WgEnumerationList
                    centered
                    dense={true}
                    enumerationIcon={
                        <img src="/img/icon-check-rounded.svg" alt="✓" />
                    }
                    background="paper"
                    content={[
                        'Immer den besten Tarif – vollautomatisch und ohne Aufwand.',
                        'Wir prüfen kontinuierlich, ob es bessere Angebote gibt.',
                        'Wir empfehlen nur Versorger, die unseren strengen Anforderungen genügen.'
                    ]}
                />
            </Grid>
        </Grid>
    );
}

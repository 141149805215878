import {
    createDeepEqualSelector,
    formatDateWithoutTime,
    getLastDayOfMonthDate
} from './utils';

import {
    accountByIdBalanceSelector,
    activeRecurringpaymentSelector,
    dateSelector,
    makeAllTransactionsFromAccountSelector,
    stepSelector
} from '.';
import {
    IChartData,
    IDashboard
} from '../../../models/statistics/dashBoardInterface';
import {ITransaction} from '../../../models/transactionInterface';
import {
    IRecurringpayment,
    isRecurringPayment
} from '../../../models/recurringpaymentInterface';
import {changeMonthBy, getFirstDayDate} from './utils/transactionUtils';
import {ICategory} from '../../../models/categoryInterface';

const incomeCategoryNames = [
    'Lohn & Gehalt (Geldeingang)',
    'Lohn & Gehalt (Minijob, Geldeingang)',
    'Lohn & Gehalt (Ausbildung)',
    'Haushaltsgeld & Taschengeld (Einnahme)',
    'Unterhaltseinnahmen',
    'Arbeitslosengeld I',
    'Arbeitslosengeld II',
    'Urlaubs- & Weihnachtsgeld'
];

export const possibleIncomeSelector = createDeepEqualSelector(
    [
        activeRecurringpaymentSelector,
        dateSelector,
        (state: any) => state.categories
    ],
    (
        recPayments: IRecurringpayment[],
        date: Date,
        categories: ICategory[] | any
    ) => {
        const minDateOfLastMonth = changeMonthBy(date, -1);
        const possibleRecs = recPayments.filter((rec: IRecurringpayment) => {
            const lastKnownDate = new Date(rec.lastKnownBookingDate);
            const category =
                rec.categoryId &&
                categories instanceof Array &&
                categories.find(
                    (category: ICategory) => category.id === rec.categoryId
                );
            return (
                lastKnownDate.getTime() >= minDateOfLastMonth.getTime() &&
                category &&
                incomeCategoryNames.includes(category.name)
            );
        });
        if (possibleRecs.length === 0) return undefined;
        return possibleRecs;
    }
);

export const salaryTimeFrameSelector = createDeepEqualSelector(
    [
        makeAllTransactionsFromAccountSelector(),
        stepSelector,
        possibleIncomeSelector
    ],
    (
        transactions: ITransaction[],
        step: string,
        recurringPayments?: IRecurringpayment[]
    ): Date[][] | null => {
        if (typeof recurringPayments === 'undefined') return null;
        const maxRecPayment = recurringPayments.reduce(
            (prev: IRecurringpayment, current: IRecurringpayment) =>
                prev.lastAmount > current.lastAmount ? prev : current
        ); // get the recurring payment with the highest amount
        const recurringTransactions = maxRecPayment.transactionIds.reduce(
            (arr: ITransaction[], id: number) => {
                const transaction = transactions.find(
                    (transaction: ITransaction) => transaction.id === id
                );
                return transaction ? [...arr, transaction] : arr;
            },
            []
        );

        // create array of dates from transactions
        const transactionValueDates = recurringTransactions
            .map((transaction: ITransaction) =>
                formatDateWithoutTime(transaction.valueDate)
            )
            .sort((x: Date, y: Date) => x.getTime() - y.getTime()); // sort ascending so that last date is future

        return createTimeFrames(transactionValueDates, step);
    }
);

const monthTimeFrameSelector = createDeepEqualSelector(
    [
        makeAllTransactionsFromAccountSelector(),
        activeRecurringpaymentSelector,
        stepSelector
    ],
    (
        transactions: ITransaction[],
        recurringPayments: IRecurringpayment[],
        step: string
    ): Date[][] => {
        // get one transaction for each month available
        const transactionsAvailableByMonth = transactions
            .reduce((arr: Date[], transaction: ITransaction) => {
                const valueDate = getFirstDayDate(transaction.valueDate);
                return arr.findIndex(
                    (date: Date) => date.getTime() === valueDate.getTime()
                ) > -1
                    ? arr
                    : [...arr, valueDate];
            }, [])
            .sort((x: Date, y: Date) => x.getTime() - y.getTime());
        if (transactionsAvailableByMonth.length === 0) return [];

        return createTimeFrames(transactionsAvailableByMonth, step);
    }
);

const timeFrameByStepSelector = createDeepEqualSelector(
    [salaryTimeFrameSelector, monthTimeFrameSelector, stepSelector],
    (
        salaryTimeFrames: Date[][] | null,
        monthTimeFrames: Date[][],
        step: string
    ) => {
        switch (step) {
            case 'last-salary':
                if (salaryTimeFrames == null) return null; // this means that no salary was found. Dashboard should switch to 'month'
                return salaryTimeFrames;
            case 'month':
                return monthTimeFrames;
            default:
                throw new Error('Selected step ' + step + ' is unknown!');
        }
    }
);

export const dashboardChartDataSelector = createDeepEqualSelector(
    [
        timeFrameByStepSelector,
        activeRecurringpaymentSelector,
        makeAllTransactionsFromAccountSelector(),
        accountByIdBalanceSelector
    ],
    (
        timeFrames: Date[][] | null,
        recurringPayments: IRecurringpayment[],
        transactions: ITransaction[],
        selectedAccountBalance: number
    ) => {
        if (timeFrames == null) return null;

        const dashboard: IDashboard[] = [];
        timeFrames.forEach((frame: Date[]) => {
            const [start, end, current] = frame;
            const today = formatDateWithoutTime(new Date());
            let pastStart, futureStart, pastEnd, futureEnd;

            const [income, outgoing] = calculateIncomingOutgoing(
                start,
                end,
                transactions
            ); // income, outgoing and disposable per month/salary timeframe

            // income, outgoing and disposable of starting day
            const [initialIncome, initialOutgoing] = calculateIncomingOutgoing(
                start,
                start,
                transactions
            );
            const initialDisposable = initialIncome + initialOutgoing;

            if (typeof current !== 'undefined') {
                // we are in present!
                pastStart = start;
                pastEnd = current;
                futureStart = current;
                futureEnd = end;
            } else if (end.getTime() > today.getTime()) {
                // now we're in the future
                futureStart = start;
                futureEnd = end;
            } else {
                // meh, now past
                pastStart = start;
                pastEnd = end;
            }

            const pastData =
                (pastStart &&
                    pastEnd &&
                    generateChartData(
                        pastStart,
                        pastEnd,
                        transactions,
                        initialDisposable
                    )) ||
                [];

            // if there is past data we need to use the last disposable for our first future disposable. otherwise the blue line is not continuous
            const futureDisposable =
                pastData.length > 0
                    ? pastData[pastData.length - 1].balance
                    : initialDisposable;
            const futureData =
                (futureStart &&
                    futureEnd &&
                    generateChartData(
                        futureStart,
                        futureEnd,
                        recurringPayments,
                        futureDisposable
                    )) ||
                [];

            dashboard.push({
                income: income,
                outgoing: outgoing,
                disposable: income + outgoing,
                balance: selectedAccountBalance,
                currentDate: pastEnd || futureEnd || current, // current shouldn't be applied because it's either past or future end
                savingsTarget: 20, // Wert vom Slider per Referenz oder ähnliches abfragen ==> geringer Zeitaufwand
                pastData: pastData,
                futureData: futureData
            });
        });
        return dashboard;
    }
);

// ######### HELPER FUNCTIONS

export const generateChartData = (
    startDate: Date,
    endDate: Date,
    objects: (ITransaction | IRecurringpayment)[],
    currentDisposable: number
) => {
    const dateMap = createDateMap(startDate, endDate);
    const amountPerDay = calculateAmountPerDay(dateMap, objects);
    return calculateDisposablePerDay(amountPerDay, currentDisposable);
};

const createDateMap = (start: Date, end: Date) => {
    const dateMap = [];
    let date = new Date(start.getTime());
    while (date.getTime() <= end.getTime()) {
        dateMap.push(date);
        date = new Date(date.getTime() + 1000 * 60 * 60 * 24); // add one day in miliseconds (month/year change secured)
    }
    return dateMap;
};

const createTimeFrames = (
    transactionValueDates: Date[],
    step: string
): Date[][] => {
    // if today is not in the transactions add it as last element
    const today = formatDateWithoutTime(new Date());
    if (
        transactionValueDates.length &&
        transactionValueDates[transactionValueDates.length - 1].getTime() <
            getFirstDayDate(today).getTime()
    ) {
        transactionValueDates.push(getFirstDayDate(today));
    }
    // create a two dimensional array for time frames, e.g. [[start, end], ...]
    const dateMap = transactionValueDates.reduce(
        (arr: Date[][], valueDate: Date) => [
            ...arr,
            [
                valueDate,
                step === 'last-salary'
                    ? changeMonthBy(valueDate, 1)
                    : getLastDayOfMonthDate(valueDate)
            ]
        ],
        []
    );

    // for the current month add todays date
    return dateMap.map((frame: Date[]) => {
        const [start, end] = frame;
        return start.getTime() <= today.getTime() &&
            end.getTime() > today.getTime()
            ? [start, end, today]
            : frame;
    });
};

export const calculateIncomingOutgoing = (
    start: Date,
    end: Date,
    transactions: ITransaction[]
) => {
    let income = 0;
    let outgoing = 0;
    const filteredTransactions = transactions.filter(
        (transaction: ITransaction) => {
            const valueDate = formatDateWithoutTime(transaction.valueDate);
            return (
                valueDate.getTime() >= start.getTime() &&
                valueDate.getTime() <= end.getTime()
            );
        }
    );
    if (filteredTransactions.length === 0) return [0, 0];
    filteredTransactions.forEach((element: ITransaction) => {
        if (element.amount > 0) income += element.amount;
        else outgoing += element.amount;
    });
    return [income, outgoing];
};

// returns for instance [{ valueDate: 2020-01-01, amount: 100.0, name: '01' }, ...]
// each object tells us the added amount of all transactions per day
const calculateAmountPerDay = (
    dateMap: Date[],
    objects: (ITransaction | IRecurringpayment)[]
) => {
    return dateMap.reduce((arr: any[], date: Date) => {
        const currentDateString = date.toLocaleString('DE-de', {
            // month: '2-digit',
            day: '2-digit'
        });

        // get all transactions on current day (date)
        const filteredObjects = objects.reduce(
            (
                arr: (ITransaction | IRecurringpayment)[],
                item: ITransaction | IRecurringpayment
            ) => {
                let itemDate;
                if (isRecurringPayment(item)) {
                    if (typeof item.estimatedNextBookingDate === 'undefined')
                        return arr;
                    itemDate = formatDateWithoutTime(
                        item.estimatedNextBookingDate
                    );
                } else {
                    itemDate = formatDateWithoutTime(item.valueDate);
                }
                return itemDate.getTime() === date.getTime()
                    ? [...arr, item]
                    : arr;
            },
            []
        );

        // calculate incoming and outgoing of the day
        let incomeOfDay = 0;
        let outgoingOfDay = 0;
        if (filteredObjects.length > 0) {
            filteredObjects.forEach(
                (element: ITransaction | IRecurringpayment) => {
                    const amount = isRecurringPayment(element)
                        ? element.lastAmount
                        : element.amount;
                    if (amount > 0) incomeOfDay += amount;
                    else outgoingOfDay += amount;
                }
            );
        }

        return [
            ...arr,
            {
                valueDate: date,
                amount: Math.round((incomeOfDay + outgoingOfDay) * 100) / 100,
                name: currentDateString,
                incomeOfDay: Math.round(incomeOfDay * 100) / 100,
                outgoingOfDay: Math.round(outgoingOfDay * 100) / 100
            }
        ];
    }, []);
};

// based on the disposable we calculate each day in order to get a continuous line for our chart
const calculateDisposablePerDay = (
    amountPerDay: any[],
    currentDisposable: number
): IChartData[] => {
    return amountPerDay.reduce(
        (prev: any[], element: any, currIndex: number) => {
            let balance = currIndex
                ? prev[currIndex - 1].balance
                : currentDisposable;
            if (prev.length > 0 && currIndex > 0) {
                balance = prev[currIndex - 1].balance + element.amount;
            }
            return [
                ...prev,
                {
                    ...element,
                    amount: element.amount,
                    balance: Math.round(balance * 100) / 100,
                    name: element.name,
                    incomeOfDay: element.incomeOfDay,
                    outgoingOfDay: element.outgoingOfDay
                }
            ];
        },
        []
    );
};

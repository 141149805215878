import React, {ReactElement, ReactNode} from 'react';
import {StylesOptions, StylesProvider} from '@material-ui/styles/';
import {MuiThemeProvider, Theme} from '@material-ui/core';

const generateClassName: StylesOptions['generateClassName'] = (
    rule,
    sheet
): string => `${sheet!.options.classNamePrefix}-${rule.key}`;

export const ThemeProvider = ({
    theme,
    children
}: {
    theme: Theme;
    children: ReactNode;
}): ReactElement => {
    const testing = typeof jest !== 'undefined';
    return (
        <StylesProvider
            generateClassName={testing ? generateClassName : undefined}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </StylesProvider>
    );
};

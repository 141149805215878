import React from 'react';
import {connect} from 'react-redux';
import {setOpenSnackbar} from '../../store/actions/notificationActions';
import {getNotification} from '../../store/reducers/selectors';
import {Snackbar} from '@material-ui/core';
import {ISnackbar} from '../../models/notificationInterface';
import {WgToast} from '../../styles/CustomComponents';
import {ReactGA} from '../../tracking';

interface IProps extends ISnackbar {
    setOpenSnackbar: Function;
}

function WgSnackBar({
    component,
    setOpenSnackbar,
    afterClose,
    autoHideDuration,
    open,
    ...other
}: IProps) {
    if (open) {
        const trackingText = `WgSnackBar: ${JSON.stringify(arguments)}`;
        ReactGA.modalview(trackingText);
    }

    const SnackbarComponent = component || WgToast;
    return (
        <Snackbar
            open={open || false}
            autoHideDuration={autoHideDuration || 6000}
            onClose={handleClose}>
            <SnackbarComponent {...other} handleClose={handleClose} />
        </Snackbar>
    );

    function handleClose() {
        setOpenSnackbar(false);
        afterClose && afterClose();
    }
}

function mapStateToProps(state: any) {
    return {
        ...getNotification(state)?.snackbar
    };
}

export default connect(mapStateToProps, {setOpenSnackbar})(WgSnackBar);

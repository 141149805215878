import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    makeStyles,
    Theme
} from '@material-ui/core';
import {ReactSVG} from 'react-svg';
// @ts-ignore
import DrawableCanvas from 'react-drawable-canvas';
import {WgTypography} from '../../../../styles/CustomComponents';
import {withStyles} from '@material-ui/core/styles';

const SignatureDialog = withStyles(() => ({
    container: {
        width: '100%'
    },
    paper: {
        width: '100%',
        overflowY: 'unset',
        maxWidth: '420px',
        maxHeight: '100%',
        '@media (max-width: 420px)': {
            borderRadius: '0'
        }
    }
}))(Dialog);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        signatureDialogContent: {
            overflowY: 'unset',
            padding: '0'
        },
        signatureDrawableCanvasCard: {
            position: 'relative',
            height: '120px',
            width: '420px'
        },
        signatureLeftBtn: {
            backgroundColor: theme.palette.secondary.main,
            width: '3rem',
            height: '3rem',
            padding: 0,
            '&:hover': {
                backgroundColor: theme.palette.primary.main
            }
        },
        signatureRefreshIcon: {
            '& > div': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& > svg': {
                    color: theme.palette.common.white
                }
            }
        }
    })
);

interface IProps {
    openSignature: boolean;
    setOpenSignature: Function;
    dateAndName: Function;
    saveTmpImage: Function;
}

export default function BrokerMandateSignature({
    openSignature,
    setOpenSignature,
    dateAndName,
    saveTmpImage
}: IProps) {
    const [clear, setClear] = useState<boolean>(false);
    const classes = useStyles();

    useEffect(() => {
        if (clear) {
            setClear(false);
        }
    }, [clear]);

    return (
        <SignatureDialog
            open={openSignature}
            style={{display: openSignature ? 'flex' : 'none'}}
            onClose={() => setOpenSignature(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Zeichne deine Unterschrift
            </DialogTitle>
            <DialogContent className={classes.signatureDialogContent}>
                <Box display="flex" justifyContent="flex-end">
                    <Box className={classes.signatureDrawableCanvasCard}>
                        <DrawableCanvas lineWidth={2} clear={clear} />{' '}
                    </Box>
                    <Box position="absolute" paddingRight="0.5rem">
                        <IconButton
                            className={classes.signatureLeftBtn}
                            color="primary"
                            aria-label="Clear"
                            onClick={() => setClear(true)}>
                            <ReactSVG
                                className={classes.signatureRefreshIcon}
                                src="/img/refresh.svg"
                            />
                        </IconButton>
                    </Box>
                </Box>
                <Divider variant="middle" light />
                <Box p="0.5rem 1.25rem">
                    <WgTypography color="textSecondary" text="subContent">
                        {dateAndName()}
                    </WgTypography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setClear(true);
                        setOpenSignature(false);
                    }}
                    style={{color: '#212121'}}>
                    ABBRECHEN
                </Button>
                <Button
                    onClick={() => {
                        saveTmpImage();
                        setOpenSignature(false);
                    }}
                    color="primary"
                    autoFocus>
                    FERTIG
                </Button>
            </DialogActions>
        </SignatureDialog>
    );
}

// returns true if every pixel's uint32 representation is 0 (or "blank")
export function isCanvasBlank(canvas: any) {
    const context = canvas.getContext('2d');

    const pixelBuffer = new Uint32Array(
        context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    return !pixelBuffer.some(color => color !== 0);
}

export function resizeImageOnCanvas(
    image: HTMLImageElement,
    width: number = 420,
    height: number = 120
) {
    // create an off-screen canvas
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    let resize = Math.max(image.width / width, image.height / height);

    let newWidth = image.width / resize;
    let newHeight = image.height / resize;

    // draw source image into the off-screen canvas:
    context?.drawImage(image, 0, 0, newWidth, newHeight);
    return canvas;
}

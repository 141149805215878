import React from 'react';
import {connect} from 'react-redux';
import {
    makeAllTransactionsFromRecurringpaymentSelector,
    recurringPaymentByIdSelector,
    singleAccountSelector
} from '../../../store/reducers/selectors';
import {ITurnoverProps, Turnover} from '../accounts/Turnover';

// @ts-ignore
export default ({match}) => (
    <ConnectTurnover
        accountId={Number(match.params.recurringpaymentId)}
        turnoverID={Number(match.params.turnover)}
    />
);

function makeMapStateToProps() {
    const transactionsFromAccountSelector = makeAllTransactionsFromRecurringpaymentSelector();
    return mapStateToProps;

    function mapStateToProps(state: any, ownProps: ITurnoverProps) {
        const recurringpayment = recurringPaymentByIdSelector(state, {
            recurringPaymentId: ownProps.accountId
        });

        const accountIds = recurringpayment ? [recurringpayment.accountId] : [];
        const account =
            recurringpayment &&
            singleAccountSelector(state, {
                accountId: recurringpayment.accountId
            });

        const turnover = transactionsFromAccountSelector(state, {
            selectedAccounts: accountIds,
            transactionIds: [ownProps.turnoverID]
        })[0];

        return {
            turnover,
            account: account
        };
    }
}

const ConnectTurnover = connect(makeMapStateToProps, {})(Turnover);

import {FETCH_ALL_ACCOUNTS_SUCCESS} from '../actions/actionTypes';
import {allAccountsSelector} from '../reducers/selectors';
import {getSearchObject} from '../../containers/utils/Cookie';
import {ReactGA} from '../../tracking';
import {
    buildTrackingMessage,
    getUserDeviceInfo
} from '../../containers/app/ErrorBoundary';

let currentPage = '';

export default (store: any) => (next: any) => (action: any) => {
    function getAdditions(original: any[], comparator: any[]) {
        return comparator.filter(l => original.indexOf(l) < 0);
    }

    if (action.type === 'persist/REHYDRATE' && action?.payload?.router) {
        action.payload.router.location.search = mergeSearches(
            action.payload.router.location.search
        );
    }

    if (action.type === '@@router/LOCATION_CHANGE') {
        const search = mergeSearches(action.payload.location.search);
        action.payload.location.search = search;
        const nextPage = `${action.payload.location.pathname}${search}`;

        if (currentPage !== nextPage) {
            currentPage = nextPage;
            ReactGA.pageview(nextPage);
        }
    } else if (action.type === FETCH_ALL_ACCOUNTS_SUCCESS) {
        const state = store.getState();
        const oldAllAccounts = allAccountsSelector(state);
        const newAllAccounts = action.payload;
        // TODO: replace the following, because it runs every time the user logs in
        // getAdditions(oldAllAccounts, newAllAccounts).forEach((account: any) =>
        //     ReactGA.event({
        //         category: 'Add Account',
        //         action: account.iban,
        //         label: JSON.stringify(getUserDeviceInfo()),
        //         nonInteraction: true,
        //         value: 1
        //     })
        // );
        getAdditions(newAllAccounts, oldAllAccounts).forEach((account: any) =>
            ReactGA.event({
                category: 'Remove Account',
                action: account.iban,
                label: JSON.stringify(getUserDeviceInfo()),
                nonInteraction: true,
                value: 1
            })
        );
    }

    try {
        ReactGA.event({
            category: 'Redux',
            action: JSON.stringify(buildTrackingMessage(action)),
            label: JSON.stringify(getUserDeviceInfo()),
            nonInteraction: true,
            value: 1
        });

        return next(action);
    } catch (error) {
        console.error(error);
        ReactGA.exception({
            description: JSON.stringify(
                buildTrackingMessage(
                    action,
                    store.getState(),
                    error,
                    undefined,
                    true
                )
            ),
            fatal: false
        });
        return {type: 'ERROR', payload: error};
    }

    function mergeSearches(payloadSearch: any = '') {
        const storeSearch = Object.entries(
            getSearchObject(store.getState().router.location.search)
        )
            .filter(keyValue => keyValue[0].startsWith('utm_'))
            .map(keyValue => keyValue.join('='))
            .join('&');
        const searches = [storeSearch, payloadSearch, window.location.search];
        const joinedSearch =
            '?' +
            searches
                .map(search => search.replace('?', ''))
                .filter(search => search.length)
                .join('&');
        const searchEntries = Object.entries(getSearchObject(joinedSearch))
            .map(keyValue => keyValue.join('='))
            .join('&');
        return searchEntries ? '?' + searchEntries : '';
    }
};

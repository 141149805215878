import React from 'react';
import {WgContainer, WgHeader} from '../../styles/CustomComponents';
import useMediaQuery from '../../hooks/useMediaQuery';

interface IIFrameWraperProps {
    title: string;
    id: string;
    src: string;
    headerText: string;
    backButtonText: string;
    width?: string;
    heigth?: string;
    crawler?: string[];
    onLoaded?: any;
}

export default function IFrameWrapper(props: IIFrameWraperProps) {
    const {isUp} = useMediaQuery('md');
    const customHeight = isUp ? '140%' : '100%';

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={props.headerText}
                hasBackButton
                backText={props.backButtonText}
            />

            <WgContainer bodyContent>
                <iframe
                    frameBorder="0"
                    id={props.id}
                    title={props.title}
                    src={props.src}
                    height={props.heigth || customHeight}
                    width={props.width || '100%'}
                    onLoad={props.onLoaded}
                />
            </WgContainer>
        </WgContainer>
    );
}

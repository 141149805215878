import React from 'react';
import {Box, IconButton, withStyles} from '@material-ui/core';
import Logo from '../../../utils/Logo';
import {
    WgListItem,
    WgListItemContent,
    WgListItemIcon,
    WgTypography
} from '../../../../styles/CustomComponents';
import {ICategory, isCategory} from '../../../../models/categoryInterface';
import {ICompany} from '../../../../models/companyInterface';
import {WgListItemProps} from '../../../../styles/CustomComponents/WgList/WgListItem';
import {ChevronRight} from '@material-ui/icons';
import {IAccount, isAccount} from '../../../../models/accountInterface';
import {formatIBAN} from '../../../utils/Format';

export interface InsuranceProductListItemProps extends WgListItemProps {
    onClick?: (...args: [React.MouseEvent<HTMLElement>]) => void;
    data?: ICategory | ICompany | IAccount;
    link?: string;
    logoPath?: string;
    linkState?: any;
    placeholder?: string;
    hasIcon?: boolean;
    icon?: React.ReactNode;
    disableStartIcon?: boolean;
    disabled?: boolean;
    textColor?:
        | 'initial'
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'error';
}

function InsuranceListItem({
    data,
    onClick,
    link,
    linkState,
    logoPath,
    placeholder,
    hasIcon,
    icon,
    textColor,
    disabled = false,
    disableStartIcon,
    ...listItemProps
}: InsuranceProductListItemProps) {
    if (typeof data === 'undefined') {
        return (
            <WgListItem
                disabled={disabled}
                link={link}
                linkState={linkState}
                onClick={onClick}
                {...listItemProps}>
                {!disableStartIcon && (
                    <WgListItemIcon>
                        <Logo
                            width="42px"
                            height="42px"
                            logoPath={logoPath}
                            defaultImage="/img/recurringpayment/favicon-add.svg"
                        />
                    </WgListItemIcon>
                )}
                <WgListItemContent>
                    <Box>
                        <WgTypography
                            noWrap
                            color={textColor ?? 'textPrimary'}
                            text="content">
                            {placeholder}
                        </WgTypography>
                    </Box>
                    <IconButton style={{padding: 0}}>
                        {icon ?? (
                            <ChevronRight fontSize="large" color="disabled" />
                        )}
                    </IconButton>
                </WgListItemContent>
            </WgListItem>
        );
    }
    return (
        <WgListItem
            disabled={disabled}
            link={link}
            linkState={linkState}
            onClick={onClick}
            {...listItemProps}
            paddingRight={!hasIcon ? '1rem' : ''}>
            <WgListItemIcon>
                {isCategory(data) ? (
                    <Logo
                        rounded="5px"
                        logoPath={data.logoPath}
                        defaultImage={'/img/bank.svg'}
                    />
                ) : isAccount(data) ? (
                    <Logo
                        rounded="5px"
                        logoPath={data.logoPath}
                        defaultImage={'/img/bank.svg'}
                    />
                ) : (
                    <Logo
                        rounded="5px"
                        logoPath={data.logoPath}
                        defaultImage={'/img/insurance.svg'}
                    />
                )}
            </WgListItemIcon>
            <WgListItemContent>
                <Box maxWidth="70vw">
                    {isAccount(data) && (
                        <WgTypography
                            gutterBottom
                            color="textSecondary"
                            text="subContent"
                            noWrap>
                            Verknüpftes Konto:
                        </WgTypography>
                    )}
                    <WgTypography color="textPrimary" text="content">
                        {data.name}
                    </WgTypography>
                    {isCategory(data) && (
                        <WgTypography color="textSecondary" text="subContent">
                            {data.parentName}
                        </WgTypography>
                    )}
                    {isAccount(data) && (
                        <WgTypography
                            color="textSecondary"
                            text="subContent"
                            noWrap>
                            {formatIBAN(data.iban)}
                        </WgTypography>
                    )}
                </Box>
                {hasIcon && (
                    <IconButton style={{padding: 0}}>
                        {icon ?? (
                            <ChevronRight fontSize="large" color="disabled" />
                        )}
                    </IconButton>
                )}
            </WgListItemContent>
        </WgListItem>
    );
}

export default withStyles({})(InsuranceListItem);

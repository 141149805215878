import React from 'react';
import {Collapse, Divider, Grid} from '@material-ui/core';
import {
    formatPaymentMethod,
    IPaymentData,
    PaymentMethod
} from '../../models/productInterface';
import {WgInputField, WgTypography} from '../../styles/CustomComponents';
import WgSelectorButton from '../../styles/CustomComponents/WgSelectorButton';
import {IAccount} from '../../models/accountInterface';
import InsuranceListItem from '../views/insurance/components/InsuranceListItem';
import {CarInsuranceStep} from '../views/insurance/views/kfz/CarInsuranceController';
import {isInputNotFilled} from './Validate';

export interface PaymentMethodInputProps {
    handleChange: (
        name: keyof IPaymentData
    ) => (value: PaymentMethod | React.ChangeEvent<any>) => void;
    paymentMethod: PaymentMethod;
    account?: IAccount;
    iban: string;
    accountHolderName: string;
    validationEnabled: boolean;
    redirectLink?: string;
}
export default function PaymentMethodInput({
    handleChange,
    paymentMethod,
    account,
    iban,
    accountHolderName,
    validationEnabled,
    redirectLink
}: PaymentMethodInputProps) {
    return (
        <React.Fragment>
            <WgTypography
                gutterBottom
                gutterTop="1.5rem"
                color="textSecondary"
                text="subContent">
                Konto wählen
            </WgTypography>
            <Grid container spacing={1} style={{marginTop: '0.5rem'}}>
                {renderButton(PaymentMethod.InApp)}
                {renderButton(PaymentMethod.ManualIban)}
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <Grid item xs={12} style={{paddingTop: '0.5rem'}}>
                    <Collapse in={paymentMethod === PaymentMethod.InApp}>
                        <Grid container spacing={3}>
                            {renderInApp()}
                        </Grid>
                    </Collapse>
                    <Collapse in={paymentMethod === PaymentMethod.ManualIban}>
                        <Grid container spacing={2}>
                            {renderIBAN()}
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
        </React.Fragment>
    );

    function renderButton(method: PaymentMethod) {
        return (
            <Grid key={`paymentMethod-${method}`} item xs={6}>
                <WgSelectorButton
                    handleChange={handleChange('paymentMethod')}
                    active={method === paymentMethod}
                    value={method}
                    text={formatPaymentMethod(method)}
                />
            </Grid>
        );
    }

    function renderIBAN() {
        return (
            <React.Fragment>
                <WgInputField
                    required
                    name="name"
                    label="Kontoinhaber"
                    type="text"
                    defaultValue={accountHolderName}
                    error={
                        validationEnabled &&
                        !validateAccountHolderName(accountHolderName)
                    }
                    helperText={
                        validationEnabled &&
                        !validateAccountHolderName(accountHolderName) &&
                        'Bitte überprüfe deine Eingabe.'
                    }
                    handleChange={handleChange('accountHolderName')}
                />
                <Grid item xs={12}>
                    <Divider light />
                </Grid>
                <WgInputField
                    required
                    name="iban"
                    label="IBAN"
                    type="text"
                    defaultValue={iban}
                    error={validationEnabled && !validateIBAN(iban)}
                    helperText={
                        validationEnabled &&
                        !validateIBAN(iban) &&
                        'Bitte überprüfe deine Eingabe.'
                    }
                    handleChange={handleChange('iban')}
                />
            </React.Fragment>
        );
    }

    function renderInApp() {
        return (
            <InsuranceListItem
                warningFlag={
                    validationEnabled && isInputNotFilled(account)
                        ? 'left'
                        : undefined
                }
                placeholder="Bankkonto auswählen"
                link={
                    redirectLink ??
                    `/products/purchase/car/${CarInsuranceStep.ACCOUNT_SELECTION}`
                }
                data={account}
                background={'transparent'}
                hasIcon
            />
        );
    }
}

function validateAccountHolderName(value: string) {
    return !isInputNotFilled(value);
}

function validateIBAN(value: string) {
    return !isInputNotFilled(value);
}

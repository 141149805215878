import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Box} from '@material-ui/core';
import {allInsuranceProductsSelector} from '../../../../../store/reducers/selectors';
import {fillProductGrid} from '../../components/ProductUtils';
import {
    WgContainer,
    WgHeader,
    WgTypography
} from '../../../../../styles/CustomComponents';

import ProductsGrid from '../../components/ProductsGrid';
import BrokerMandateEdit from '../../../profile/documents/BrokerMandateEdit';
import BrokerMandateNoMandateExistsText from '../../../profile/documents/BrokerMandateNoMandateExistsText';
import {existsBrokermandate} from '../../../../../store/reducers/selectors/userSelector';

interface IProps {
    products: any[];
    hasBrokermandate: boolean;
}
//ToDo: Refactoring as Functional Component
class ProductsNew extends Component<IProps, {}> {
    render() {
        if (!this.props.hasBrokermandate) {
            return (
                <BrokerMandateEdit backNavigationOnSave={false}>
                    <BrokerMandateNoMandateExistsText />
                </BrokerMandateEdit>
            );
        }

        return (
            <WgContainer bodyApp>
                <WgHeader
                    headerText={'Versicherungsauswahl'}
                    hasBackButton
                    backText="Versicherungswelt"
                />

                <WgContainer bodyContent>
                    <Box m="0 1rem 1rem 1rem">
                        <WgTypography
                            align="center"
                            text="contentInfo"
                            color="primary">
                            Welche Versicherung möchtest du hinzufügen?
                        </WgTypography>
                    </Box>
                    <Box marginX="1rem">
                        <ProductsGrid
                            products={fillProductGrid(this.props.products)}
                            isPriceVisible
                        />
                    </Box>
                </WgContainer>
            </WgContainer>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        products: allInsuranceProductsSelector(state),
        hasBrokermandate: existsBrokermandate(state)
    };
}

export default connect(mapStateToProps, {})(ProductsNew);

import React, {useState} from 'react';
import {Box, Grid, Paper} from '@material-ui/core';
import PasswordForm, {passwordStatus} from '../../../utils/PasswordForm';
import {connect} from 'react-redux';
import {userActions} from '../../../../store/actions';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgPasswordField,
    WgTypography
} from '../../../../styles/CustomComponents';

const {performChangePassword} = userActions;

interface IState {
    oldPassword: string;
    showPassword: boolean;
    showOldPassword: boolean;
    status: number;
}

interface PasswordProps {
    performChangePassword: Function;
}

export function Password({performChangePassword}: PasswordProps) {
    const [values, setValues] = React.useState<IState>({
        oldPassword: '',
        showPassword: false,
        showOldPassword: false,
        status: passwordStatus.EMPTY
    });

    const [hasAskToReturn, setHasAskToReturn] = useState(false);

    const [password, setPassword] = useState<string | undefined>(undefined);

    const handleChange = (prop: keyof IState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValues({...values, [prop]: event.target.value});
        setHasAskToReturn(true);
    };

    const areRequirementsMet = () => {
        return (
            values.status === passwordStatus.SUCCESS &&
            password &&
            values.oldPassword
        );
    };

    const submit = (event: any) => {
        event.preventDefault();
        if (areRequirementsMet()) {
            performChangePassword({
                oldpassword: values.oldPassword,
                newpassword: password
            });
            setHasAskToReturn(false);
        }
    };

    const handleSetStatus = (status: number) => {
        setValues({...values, status});
    };

    const getStatusMessage = () => {
        // @ts-ignore
        if (values.status === passwordStatus.NOT_STRONG_ENOUGH)
            return 'Das Passwort ist zu schwach.';
        // @ts-ignore
        if (values.status === passwordStatus.DIFFERENT)
            return 'Die Passwörter müssen übereinstimmen.';
    };

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Passwort ändern"
                hasBackButton
                backText="Mein Profil"
                hasAskToReturn={hasAskToReturn}
                save={submit}
            />

            <WgContainer bodyContent disableGutters={false}>
                <WgTypography color="textSecondary" text="info">
                    Wir empfehlen dir ein sicheres Passwort zu verwenden. Ein
                    gutes Passwort besteht aus mindestens einer Zahl und einem
                    Sonderzeichen. Benutze keine Leerzeichen.
                </WgTypography>
                <Box marginTop="2rem">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={2}>
                                <WgPasswordField
                                    autoFocus
                                    label="Altes Passwort"
                                    value={values.oldPassword}
                                    onChange={handleChange('oldPassword')}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <PasswordForm
                                onChange={password => setPassword(password)}
                                setStatus={handleSetStatus}
                            />
                            <WgTypography color="textSecondary" text="info">
                                {getStatusMessage()}
                            </WgTypography>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    display="flex"
                    justifyContent={{xs: 'center', md: 'flex-end'}}
                    marginTop="1.5rem">
                    <WgButton
                        disabled={!areRequirementsMet()}
                        defaultButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        aria-label="save"
                        onClick={submit}>
                        Speichern
                    </WgButton>
                </Box>
            </WgContainer>
        </WgContainer>
    );
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps, {performChangePassword})(Password);

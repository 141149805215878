import {UPDATE_THEME} from '../actions/actionTypes';
import {lightTheme} from '../../styles/theme';

export const initialState = lightTheme;

export default (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_THEME:
            if (
                typeof action.payload !== 'object' ||
                Object.keys(action.payload).length === 0
            ) {
                return state;
            }
            return {
                ...action.payload
            };
        default:
            return state;
    }
};

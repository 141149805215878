import React, {useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import {
    CarInsuranceOneProductType,
    IPolicyData,
    IPolicyFormData
} from '../../../../../models/productInterface';
import {
    WgPaperArea,
    WgTypography
} from '../../../../../styles/CustomComponents';
import ProductTypeSelection from './Components/ProductTypeSelection';
import {isInputNotFilled} from '../../../../utils/Validate';

interface CarInsuranceProductTypeProps {
    formData: IPolicyFormData;
    policyData: IPolicyData;
    updatePolicyData: Function;
    validationCallback: (isValid: boolean) => void;
    validationEnabled: boolean;
}

export default function CarInsuranceProductType({
    formData,
    policyData,
    updatePolicyData,
    validationCallback,
    validationEnabled
}: CarInsuranceProductTypeProps) {
    useEffect(() => {
        validateAll(policyData);
    });

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Welche Art von KFZ-Versicherung möchtest du abschließen?
            </WgTypography>
            <Box marginTop="1rem">
                <WgPaperArea
                    header="Versicherungsart"
                    elevation={1}
                    paperStyle={{padding: '1rem 1rem 0.5rem'}}>
                    <ProductTypeSelection
                        formData={formData}
                        policyData={policyData}
                        process={CarInsuranceOneProductType.NEW_VEHICLE}
                        updatePolicyData={updatePolicyData}
                        helperText={
                            validationEnabled &&
                            isInputNotFilled(policyData.productType) &&
                            'Bitte überprüfe deine Eingabe.'
                        }
                        error={
                            validationEnabled &&
                            isInputNotFilled(policyData.productType)
                        }
                    />
                </WgPaperArea>
            </Box>
        </Grid>
    );

    function validateAll(data: IPolicyData) {
        const isValid = checkIsValid(data);

        validationCallback(isValid);
    }
}

function checkIsValid(data: IPolicyData) {
    let isValid = true;
    isValid = !isValid ? isValid : !isInputNotFilled(data.productType);

    return isValid;
}

import React, {useEffect} from 'react';
import {ICarInsuranceVehicleDataDetails} from '../../../../../models/productInterface';
import {Box, Divider, Grid} from '@material-ui/core';
import {
    WgInputField,
    WgPaperArea,
    WgRadioGroup,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {IDateFieldExtended} from '../../../../../models/sharedInterface';
import {
    isDigitsOnlySameLessLength,
    isInputNotFilled
} from '../../../../utils/Validate';

interface CarInsuranceNecessaryDataProps {
    carInsuranceVehicleDataDetails: ICarInsuranceVehicleDataDetails;
    updateCarInsuranceVehicleDataDetails: Function;
    validationEnabled: boolean;
    validationCallback: (isValid: boolean) => void;
}

export default function CarInsuranceNecessaryData({
    carInsuranceVehicleDataDetails,
    updateCarInsuranceVehicleDataDetails,
    validationEnabled,
    validationCallback
}: CarInsuranceNecessaryDataProps) {
    useEffect(() => {
        validateAll(carInsuranceVehicleDataDetails);
    });

    const otherDriversSelection = [
        {
            label: 'Ja',
            value: 'true',
            child: (
                <Box>
                    <Divider light />
                    <WgInputField
                        fullWidth
                        label="Alter des jüngsten Fahrers"
                        name="youngestDriverAge"
                        type="text"
                        handleChange={handleChange}
                        defaultValue={
                            carInsuranceVehicleDataDetails.youngestDriverAge
                        }
                        required
                        error={
                            validationEnabled &&
                            !validateYoungestAge(
                                carInsuranceVehicleDataDetails.youngestDriverAge
                            )
                        }
                        helperText={
                            validationEnabled &&
                            !validateYoungestAge(
                                carInsuranceVehicleDataDetails.youngestDriverAge
                            ) &&
                            'Diese Eingabe ist erforderlich.'
                        }
                        pattern={/\d{0,3}/}
                    />
                </Box>
            )
        },
        {
            label: 'Nein',
            value: 'false'
        }
    ];

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Die folgenden Angaben werden noch benötigt:
            </WgTypography>
            <Box marginTop="1rem">
                <WgPaperArea
                    elevation={1}
                    paperStyle={{padding: '1rem 1rem 0.5rem'}}>
                    <Grid container direction="column" spacing={1}>
                        <WgRadioGroup
                            header="Andere Fahrer versichern"
                            aria-label="Other drivers"
                            name="otherDriver"
                            row
                            defaultValue={true.toString()}
                            selected={
                                carInsuranceVehicleDataDetails.otherDriver?.toString() ??
                                'false'
                            }
                            handleChange={handleChange}
                            group={otherDriversSelection}
                        />
                        <Divider light />
                        <WgInputField
                            fullWidth
                            label="Laufleistung pro Jahr in km (Schätzung)"
                            name="kmPerYear"
                            type="text"
                            defaultValue={
                                carInsuranceVehicleDataDetails.kmPerYear || ''
                            }
                            required
                            error={
                                validationEnabled &&
                                !validateKmPerYear(
                                    carInsuranceVehicleDataDetails.kmPerYear
                                )
                            }
                            helperText={
                                validationEnabled &&
                                !validateKmPerYear(
                                    carInsuranceVehicleDataDetails.kmPerYear
                                ) &&
                                'Diese Eingabe ist erforderlich.'
                            }
                            pattern={/\d{0,6}/}
                            handleChange={handleChange}
                        />
                    </Grid>
                </WgPaperArea>
            </Box>
            <Box marginTop="1rem">
                <WgPaperArea
                    elevation={1}
                    paperStyle={{padding: '0 1rem 0.5rem 1rem'}}>
                    <Grid container direction="column" spacing={1}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <WgInputField
                                fullWidth
                                disablePast
                                label="Beginn des Versicherungsschutzes"
                                name="startDate"
                                type="date"
                                defaultValue={
                                    carInsuranceVehicleDataDetails.startDate ||
                                    null
                                }
                                isDateExtendedDataNeeded
                                required
                                error={
                                    validationEnabled &&
                                    !validateDate(
                                        carInsuranceVehicleDataDetails.startDate
                                    )
                                }
                                helperText={
                                    validationEnabled &&
                                    !validateDate(
                                        carInsuranceVehicleDataDetails.startDate
                                    ) &&
                                    'Diese Eingabe ist erforderlich.'
                                }
                                handleChange={handleDateChange}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </WgPaperArea>
            </Box>
        </Grid>
    );

    function handleDateChange(date: Date) {
        updateCarInsuranceVehicleDataDetails({
            ...carInsuranceVehicleDataDetails,
            startDate: date
        });
    }

    function handleChange(event: any | IDateFieldExtended) {
        const {value, name} = event.target;

        updateCarInsuranceVehicleDataDetails({
            ...carInsuranceVehicleDataDetails,
            [name]: value
        });

        validateAll(carInsuranceVehicleDataDetails);
    }

    function validateAll(data: ICarInsuranceVehicleDataDetails) {
        const isValid = checkIsValid(data);

        validationCallback(isValid);
    }
}

function validateKmPerYear(value?: number) {
    if (value === undefined) return false;

    const stringValue = value.toString();
    return (
        isDigitsOnlySameLessLength(stringValue, 6) &&
        !isInputNotFilled(stringValue)
    );
}

function validateYoungestAge(value?: number) {
    if (value === undefined) return false;

    const stringValue = value.toString();
    return (
        isDigitsOnlySameLessLength(stringValue, 3) &&
        !isInputNotFilled(stringValue)
    );
}

function validateDate(value?: Date) {
    return !isInputNotFilled(value);
}

function checkIsValid(data: ICarInsuranceVehicleDataDetails) {
    let isValid = true;
    isValid = !isValid ? isValid : validateKmPerYear(data.kmPerYear);
    isValid = !isValid
        ? isValid
        : data.otherDriver.toString() === 'false'
        ? isValid
        : validateYoungestAge(data.youngestDriverAge);
    isValid = !isValid ? isValid : validateDate(data.startDate);

    return isValid;
}

import React, {useState} from 'react';
import {
    Box,
    createStyles,
    Divider,
    LinearProgress,
    makeStyles,
    Paper,
    Theme
} from '@material-ui/core';
import {WgPasswordField, WgTypography} from '../../styles/CustomComponents/';
import zxcvbn from 'zxcvbn';
import clsx from 'clsx';

const scoreColor1 = '#ff0000';
const scoreColor2 = '#ff870f';
const scoreColor3 = '#ffc30f';
const scoreColor4 = '#3fd298';
const scoreColor5 = '#00b32d';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scoreText: {
            marginBottom: '0.5rem'
        },
        scoreLine: {
            backgroundColor: theme.palette.text.hint,
            maxWidth: '260px',
            height: '2px',
            borderRadius: '1px'
        },
        score1: {
            color: scoreColor1,
            '& div': {
                backgroundColor: scoreColor1
            }
        },
        score2: {
            color: scoreColor2,
            '& div': {
                backgroundColor: scoreColor2
            }
        },
        score3: {
            color: scoreColor3,
            '& div': {
                backgroundColor: scoreColor3
            }
        },
        score4: {
            color: scoreColor4,
            '& div': {
                backgroundColor: scoreColor4
            }
        },
        score5: {
            color: scoreColor5,
            '& div': {
                backgroundColor: scoreColor5
            }
        }
    })
);

interface IProps {
    password?: string;
    passwordConfirm?: string;
    onChange: (password?: string) => void;
    setScore?: (score: number) => void;
    setStatus?: (score: number) => void;
    autoFocus?: boolean;
}

export const passwordStatus = {
    EMPTY: 0,
    SUCCESS: 1,
    DIFFERENT: 2,
    NOT_STRONG_ENOUGH: 3
};

export default function PasswordForm(props: IProps) {
    const classes = useStyles();
    const [rating, setRating] = useState({score: 0});
    const [, setStatus] = useState(passwordStatus.EMPTY);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const scoreStyle = [
        {text: 'Passwortstärke'},
        {text: 'Extrem schwaches Passwort'},
        {text: 'Sehr schwaches Passwort'},
        {text: 'Schwaches Passwort'},
        {text: 'Sicheres Passwort'},
        {text: 'Sehr sicheres Passwort'}
    ];
    return (
        <React.Fragment>
            <WgTypography
                // @ts-ignore
                className={clsx(
                    classes.scoreText,
                    // @ts-ignore
                    classes['score' + rating.score]
                )}>
                {scoreStyle[rating.score].text}
            </WgTypography>
            <LinearProgress
                variant="determinate"
                className={clsx(
                    classes.scoreLine,
                    // @ts-ignore
                    classes['score' + rating.score]
                )}
                value={(rating.score * 100) / 5}
            />
            <Box marginTop="1.5rem">
                <Paper elevation={2}>
                    <WgPasswordField
                        autoFocus={props.autoFocus}
                        label="Passwort"
                        value={password}
                        onChange={handleChangePassword(false)}
                        name="password"
                    />
                    <Divider variant="middle" light />
                    <WgPasswordField
                        label="Passwort bestätigen"
                        value={passwordConfirm}
                        onChange={handleChangePassword(true)}
                        name="password"
                    />
                </Paper>
            </Box>
        </React.Fragment>
    );

    function setScore(event: any) {
        const password = event.target.value;
        const rating =
            password === '' ? {score: 0} : {score: zxcvbn(password).score + 1};
        const score =
            getStatus(password) === passwordStatus.NOT_STRONG_ENOUGH
                ? 1
                : rating.score;

        setRating({score});
        return rating;
    }

    function handleChangePassword(isConfirm: boolean = false) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            let {score} = setScore(event);
            let pw = isConfirm ? password : passwordConfirm;
            let setFunction = isConfirm ? setPasswordConfirm : setPassword;
            setFunction(event.target.value);

            props.onChange(
                pw === event.target.value ? event.target.value : undefined
            );

            props.setScore && props.setScore(score);

            const updatedStatus = isConfirm
                ? getStatus(password, event.target.value)
                : getStatus(event.target.value, passwordConfirm);
            setStatus(updatedStatus);
            props.setStatus && props.setStatus(updatedStatus);
        };
    }

    function getStatus(password?: string, passwordConfirm?: string) {
        if (!password || password === '') return passwordStatus.EMPTY;

        if (
            password.length < 8 ||
            !/\d/.test(password) ||
            / /.test(password) ||
            !/[a-z]/.test(password)
        ) {
            return passwordStatus.NOT_STRONG_ENOUGH;
        }

        if (password !== passwordConfirm) return passwordStatus.DIFFERENT;

        return passwordStatus.SUCCESS;
    }
}

import React from 'react';
import {Divider, Box} from '@material-ui/core';

import {
    GenderRadioGroup,
    NameInputs
} from '../../../../recurringpayments/change/Components';
import {AddressInputFields} from '../../../../../utils';
import {WgInputField} from '../../../../../../styles/CustomComponents';
import {IVehicleHolder} from '../../../../../../models/productInterface';
import {isInputNotFilled, validateEmail} from '../../../../../utils/Validate';

export interface VehicleHolderDataProps {
    vehicleHolder: IVehicleHolder;
    handleChange: (event: React.ChangeEvent<any>) => void;
    validationEnabled: boolean;
}

export default function VehicleHolderData({
    vehicleHolder,
    handleChange,
    validationEnabled
}: VehicleHolderDataProps) {
    vehicleHolder.birthDate = vehicleHolder.birthDate
        ? new Date(vehicleHolder.birthDate)
        : vehicleHolder.birthDate;

    const maxCityNameLength = 40;

    const city = vehicleHolder.city ?? '';

    return (
        <React.Fragment>
            <NameInputs
                handleChange={handleChange}
                firstName={vehicleHolder.firstName}
                lastName={vehicleHolder.familyName}
                validationEnabled={validationEnabled}
            />
            <Divider variant="middle" light />
            <Box paddingX="1.25rem">
                <WgInputField
                    label="Geburtsdatum"
                    type="date"
                    name="birthDate"
                    defaultValue={vehicleHolder.birthDate ?? null}
                    required
                    isDateExtendedDataNeeded
                    error={
                        validationEnabled &&
                        isInputNotFilled(vehicleHolder.birthDate)
                    }
                    helperText={
                        validationEnabled &&
                        isInputNotFilled(vehicleHolder.birthDate) &&
                        'Bitte überprüfe deine Eingabe.'
                    }
                    handleChange={handleChange}
                />
            </Box>
            <Divider variant="middle" light />
            <GenderRadioGroup
                gender={vehicleHolder.gender}
                handleChange={handleChange}
                helperText={
                    validationEnabled &&
                    isInputNotFilled(vehicleHolder.gender) &&
                    'Bitte überprüfe deine Eingabe.'
                }
                error={
                    validationEnabled && isInputNotFilled(vehicleHolder.gender)
                }
            />
            <Divider variant="middle" light />
            <Box paddingX="1.25rem">
                <WgInputField
                    fullWidth
                    label="E-Mail-Adresse"
                    name="email"
                    type="text"
                    defaultValue={vehicleHolder.email ?? ''}
                    required
                    handleChange={handleChange}
                    error={
                        validationEnabled &&
                        !validateEmailField(vehicleHolder.email)
                    }
                    helperText={
                        validationEnabled &&
                        !validateEmailField(vehicleHolder.email) &&
                        'Bitte überprüfe deine Eingabe.'
                    }
                />
            </Box>
            <Divider variant="middle" light />
            <AddressInputFields
                width="100%"
                validation={validationEnabled}
                required
                handleChange={handleChange}
                street={vehicleHolder.street ?? ''}
                houseNumber={vehicleHolder.houseNumber ?? ''}
                postcode={vehicleHolder.postalCode ?? ''}
                city={city.length > maxCityNameLength ? '' : city}
                cityPattern={/[\u00C0-\u017F\d\w]{0,40}/}
                elevation={0}
            />
        </React.Fragment>
    );
}

function validateEmailField(value?: string) {
    return !isInputNotFilled(value) && (value ? validateEmail(value) : false);
}

import {formatEcoOption, formatMoney} from '../../../../utils/Format';
import {Divider, Grid, Paper} from '@material-ui/core';
import {WgTypography} from '../../../../../styles/CustomComponents';
import {WgExpansionPanelHeader} from '../../../../../styles/CustomComponents/WgExpansionPanel';
import React from 'react';
import EnergyRadios from './EnergyRadios';
import {IEnergyOfferContractValidation} from '../../../../../models/offerInterface';

export interface ContractDataInputsProps
    extends IEnergyOfferContractValidation {
    handleChange: (event: React.ChangeEvent<any>) => void;
}

export function ContractDataInputs(props: ContractDataInputsProps) {
    const {
        handleChange,
        eco,
        isCommercial,
        meterType,
        betterProviderName,
        betterProviderMonthlyCosts,
        betterProviderYearlyCosts,
        estimatedSavings
    } = props;

    return (
        <Paper elevation={0} style={{padding: '0 1rem 1.125rem'}}>
            <Grid container spacing={2}>
                <EnergyRadios
                    disabled
                    meterType={meterType}
                    isCommercial={isCommercial ?? false}
                    handleChange={handleChange}
                />
                <Grid item xs={12} style={{marginLeft: '-1rem', paddingTop: 0}}>
                    <WgExpansionPanelHeader
                        title={formatEcoOption(eco)}
                        subTitle={betterProviderName}
                        headerImage="/img/recurringpayment/icon-house-circle.svg"
                    />
                </Grid>
                <Grid item xs={12}>
                    <WgTypography
                        gutterBottom
                        color="textPrimary"
                        text="content">
                        Kosten bei gleichem Verbrauch:
                    </WgTypography>
                    <Grid item container justify="space-evenly" wrap="nowrap">
                        <Grid
                            item
                            xs={5}
                            container
                            direction="column"
                            justify="center">
                            <WgTypography
                                noWrap
                                align="center"
                                money
                                color="textPrimary"
                                number="amount">
                                {betterProviderYearlyCosts}
                            </WgTypography>
                            <WgTypography
                                align="center"
                                gutterBottom
                                color="textSecondary"
                                header="uppercaseSpacing">
                                Jährliche Kosten
                            </WgTypography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            container
                            justify="center"
                            alignItems="center">
                            <Divider
                                orientation="vertical"
                                light
                                style={{height: '75%'}}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            container
                            direction="column"
                            justify="center">
                            <WgTypography
                                noWrap
                                align="center"
                                colored
                                money
                                number="amount">
                                {estimatedSavings}
                            </WgTypography>
                            <WgTypography
                                align="center"
                                gutterBottom
                                color="textSecondary"
                                header="uppercaseSpacing">
                                {estimatedSavings >= 0
                                    ? 'Ersparnis'
                                    : 'Zu teuer'}
                            </WgTypography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} container direction="column">
                    <WgTypography
                        gutterBottom
                        color="textPrimary"
                        text="content">
                        Monatliche Kosten:
                    </WgTypography>
                    <WgTypography color="textSecondary" text="content">
                        {formatMoney(betterProviderMonthlyCosts) + ' / Mon.'}
                    </WgTypography>
                </Grid>
            </Grid>
        </Paper>
    );
}

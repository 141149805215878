import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

const MyLink = (to?: string, pathname?: string, linkProps?: any) =>
    React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>((props, ref) => {
        if (!to) {
            return <React.Fragment />;
        }

        const propsWithPathClassName = {
            ...props,
            className:
                props.className +
                (pathname && pathname.startsWith(to) ? ' active' : '')
        };
        return (
            (linkProps && (
                <Link
                    to={{pathname: to, state: linkProps}}
                    {...propsWithPathClassName}
                    ref={ref as any}
                />
            )) || <Link to={to} {...propsWithPathClassName} ref={ref as any} />
        );
    });

export default MyLink;

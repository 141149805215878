import React from 'react';
import {connect} from 'react-redux';
import {formatDateFromString, formatGender} from '../../../utils/Format';
import {getUser, getUserUserData} from '../../../../store/reducers/selectors';
import {
    WgContainer,
    WgHeader,
    WgPaperList
} from '../../../../styles/CustomComponents';
import {Box} from '@material-ui/core';
import {IUserData} from '../../../../models/userInterface';

interface PrivateProps extends IUserData {
    wechselgottId: string;
}

function Private(props: PrivateProps) {
    const detailMapping = {
        'WechselGott-ID': props.wechselgottId,
        'E-Mail': props.email,
        Telefon: props.phone,
        Namenszusatz: props.title?.length === 0 ? undefined : props.title,
        Vorname: props.firstName,
        Nachname: props.lastName,
        Geschlecht: formatGender(props.gender ?? ''),
        Geburtsdatum: formatDateFromString(props.birthDate),
        Anschrift: getAddress()
    };

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText="Persönliche Daten"
                hasBackButton
                backText="Mein Profil"
                buttonRight={{
                    icon: '/img/navigation/edit.svg',
                    link: 'profile/private/edit'
                }}
            />

            <WgContainer bodyContent>
                <Box margin={{xs: '-1rem 0', md: '0'}}>
                    <WgPaperList
                        shadow
                        background="default"
                        keyValueMapping={detailMapping}
                        link={''}
                    />
                </Box>
            </WgContainer>
        </WgContainer>
    );

    function getAddress(): React.ReactNode {
        return (
            <React.Fragment>
                {`${props.street ?? ''} ${props.houseNumber ?? ''}`} <br />
                {props.addressAdditional && (
                    <React.Fragment>
                        {props.addressAdditional} <br />
                    </React.Fragment>
                )}
                {`${props.postcode ?? ''} ${props.city ?? ''}`} <br />
                {props.country ?? ''}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        wechselgottId: getUser(state)?.id,
        ...getUserUserData(state)
    };
}

export default connect(mapStateToProps, {})(Private);

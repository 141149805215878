import React from 'react';
import {useHistory, useLocation} from 'react-router';
import {ICarInsuranceOfferDetails} from '../../../../../../models/productInterface';
import {
    WgContainer,
    WgHeader,
    WgTypography,
    WgPaperArea,
    WgEnumerationList,
    WgButton
} from '../../../../../../styles/CustomComponents';
import {Grid, Box} from '@material-ui/core';
import {WgExpansionPanelHeader} from '../../../../../../styles/CustomComponents/WgExpansionPanel';
import {formatMoney} from '../../../../../utils/Format';
import {CarInsuranceStep} from '../CarInsuranceController';

interface CarInsuranceOfferDetailsProps {
    setUserChoice?: Function;
}

export default function CarInsuranceOfferDetails(
    props: CarInsuranceOfferDetailsProps
) {
    const location = useLocation<ICarInsuranceOfferDetails>();
    const offerDetails = location.state;
    const history = useHistory();

    const detailListGreen = [
        'Deine Beträge werden nachhaltig investiert',
        'Aufforstung von Regenwäldern'
    ];
    const detailListBasicCoverage = [
        'Personenschäden pro Person bis zu 15\u00a0Mio.\u00a0€',
        'Sachschäden pro Person bis zu 100\u00a0Mio.\u00a0€',
        'Erweiterter Mietwagenschutz im Ausland für Personenschäden (Mallorca Klausel)',
        'Erweiterter Mietwagenschutz im Ausland für Sachschäden (Mallorca Klausel)',
        'Eigenschadendeckung mit 500\u00a0€ SB und Werkstattbindung'
    ];
    const detailListPartialComprehensive = [
        'Durch Tierbisse verursachte Schäden (z.B. Marder)',
        'Kurzschlussschäden bis 5.000\u00a0€',
        'Absicherung von Sonderausstattung',
        'Schutz vor allgemeinen Tierschäden',
        'Registrierungs- und Übertragungskosten',
        'Ersatz von Betriebsmitteln',
        'Erstattung des Kaufpreises',
        'Entschädigung des Fahrzeugneuwertes',
        'Ersatz von Teilen ohne Selbstbeteiligung',
        'Grobe Fahrlässigkeit',
        'Elementarschäden',
        'Erweiterter Schutz bei Elementarschäden',
        'Besser-Leistungsgarantie im Vergleich zur Vorversicherung',
        'Glasschäden ohne Selbstbeteiligung'
    ];
    const detailListFullyComprehensive = [
        'Selbstverschuldete Schäden am eigenen Fahrzeug',
        'Deckung bei Vandalismus',
        'Größere Schäden bei der Nutzung von PKW-Fähren oder Schiffen'
    ];

    return (
        <WgContainer bodyApp>
            <WgHeader
                headerText={offerDetails.productName}
                hasBackButton
                backText="Vergleich"
            />

            <WgContainer bodyContent disableGutters zeroPaddingTopMobile>
                <WgPaperArea
                    marginTop="1rem"
                    paperStyle={{padding: '0 1rem 0.5rem 1rem'}}>
                    <Grid
                        item
                        xs={12}
                        style={{marginLeft: '-1rem', paddingTop: 0}}>
                        <WgExpansionPanelHeader
                            title={offerDetails.productName}
                            subTitle="ONE Versicherung AG"
                            headerImage="/img/one-insurance.webp"
                        />
                    </Grid>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="1rem">
                        <WgTypography color="textSecondary" text="subContent">
                            Gesamtkosten
                        </WgTypography>
                        <WgTypography money>
                            {formatMoney(
                                offerDetails.proposalTariff.tariffWithTax
                            ) + ' / Jahr'}
                        </WgTypography>
                    </Box>
                </WgPaperArea>

                <WgPaperArea marginY="1rem" paperStyle={{padding: '0 1rem'}}>
                    <Grid
                        item
                        xs={12}
                        style={{marginLeft: '-1rem', paddingTop: 0}}>
                        <WgExpansionPanelHeader
                            title="Deine Absicherung"
                            headerImage="/img/icon-insurance.svg"
                        />
                    </Grid>
                    {offerDetails.eco && (
                        <Box marginTop="-1rem">
                            {renderDetailList(
                                detailListGreen,
                                false,
                                undefined,
                                true
                            )}
                        </Box>
                    )}
                    <Box marginTop="1rem">
                        {renderDetailList(
                            detailListBasicCoverage,
                            false,
                            'Deckung in der Haftpflicht enthalten'
                        )}
                    </Box>
                    <Box marginTop="1rem">
                        {renderDetailList(
                            detailListPartialComprehensive,
                            offerDetails.productType === 'vehicle-liability',
                            'Zusätzliche Deckungen im Teilkasko-Tarif enthalten'
                        )}
                    </Box>
                    <Box marginTop="1rem">
                        {renderDetailList(
                            detailListFullyComprehensive,
                            offerDetails.productType !== 'fully-comprehensive',
                            'Zusätzliche Deckungen: \n Nur im Vollkasko-Tarif enthalten'
                        )}
                    </Box>
                </WgPaperArea>

                <Grid item container direction="column" justify="center">
                    <Box
                        display="flex"
                        flexWrap="nowrap"
                        paddingX="1rem"
                        paddingTop={{xs: 0, md: '1rem'}}
                        flex={1}
                        flexDirection={{xs: 'column', md: 'row'}}
                        justifyContent={{
                            xs: 'flex-end',
                            md: 'space-between'
                        }}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            maxHeight="fit-content"
                            alignItems={{
                                xs: 'flex-end',
                                md: 'center'
                            }}
                            flex={1}
                            marginBottom={{xs: '1rem', md: 0}}
                            order={{xs: 1, md: 2}}>
                            <WgButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSubmit}
                                aria-label="next">
                                {'Tarif auswählen'}
                            </WgButton>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            maxHeight="fit-content"
                            alignItems={{
                                xs: 'flex-start',
                                md: 'center'
                            }}
                            flex={1}
                            order={{xs: 2, md: 1}}>
                            <WgButton
                                onClick={handleCancel}
                                variant="contained"
                                color="default"
                                fullWidth
                                aria-label="back">
                                {'Zurück'}
                            </WgButton>
                        </Box>
                    </Box>
                </Grid>
            </WgContainer>
        </WgContainer>
    );

    function renderDetailList(
        detailList: any,
        disabled: boolean,
        header?: string,
        colored?: boolean
    ) {
        return (
            <Box style={{opacity: disabled ? '0.2' : 'undefined'}}>
                {header && (
                    <WgTypography
                        color="textPrimary"
                        text="content"
                        style={{whiteSpace: 'pre-line'}}>
                        {header}
                    </WgTypography>
                )}
                <Box paddingY="0.75rem">
                    <WgEnumerationList
                        enumerationIcon={
                            <img src="/img/icon-check-rounded.svg" alt="✓" />
                        }
                        dense={true}
                        textColor={colored ? 'secondary' : undefined}
                        content={detailList}
                    />
                </Box>
            </Box>
        );
    }

    function handleSubmit() {
        handleProposalSelection(offerDetails.proposalTariff.calculationId);
        history.push(
            `/products/purchase/car/${CarInsuranceStep.PAYMENT_SELECTION}`
        );
    }

    function handleProposalSelection(calculationId: string) {
        const {setUserChoice} = props;

        setUserChoice && setUserChoice(calculationId);
    }

    function handleCancel() {
        history.push(
            `/products/purchase/car/${CarInsuranceStep.OFFER_SELECTION}`
        );
    }
}

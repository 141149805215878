import React from 'react';
import {useDispatch} from 'react-redux';
import {
    AppBar,
    AppBarProps,
    Box,
    Button,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Toolbar
} from '@material-ui/core';
import {WgTypography} from './index';
import {MyLink} from '../../containers/utils';
import {ReactSVG} from 'react-svg';
import clsx from 'clsx';
import {IDialog} from '../../models/notificationInterface';
import {setNotification} from '../../store/actions/notificationActions';
import useMediaQuery from '../../hooks/useMediaQuery';
import {useHistory} from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            boxShadow: theme.shadows[1],
            minWidth: '3rem',
            minHeight: '3rem',
            backgroundColor: theme.palette.background.paper
        },
        iconNoShadow: {
            boxShadow: theme.shadows[0]
        }
    })
);

export interface WgHeaderProps extends AppBarProps {
    headerText?: string;
    hasBackButton?: boolean;
    historyState?: any;
    backText?: string;
    onBackExtra?: Function; // This function will be called before navigating back (if hasBackButton is set!)
    buttonRight?: {
        link: string;
        icon: string;
    };
    buttonRightPopup?: {
        icon: string;
        close: Function;
    };
    isPublic?: boolean;
    hasAskToReturn?: boolean;
    setNotification?: (dialog: IDialog) => any;
    save?: Function;
    elevation?: number;
}

export default function WgHeader(props: WgHeaderProps) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {isUp} = useMediaQuery('md');
    const history = useHistory();

    return isUp && !props.isPublic ? renderDesktop() : renderMobile();

    function askToReturn() {
        dispatch(
            setNotification({
                text: 'Willst du die Änderungen speichern?',
                isAgreeable: true,
                onAgree: () => {
                    props.save && props.save() && history.goBack();
                },
                onDisagree: () => history.goBack(),
                isDisagreeable: true,
                open: true
            })
        );
    }

    function renderDesktop() {
        return (
            <Box
                paddingTop="2rem"
                width="100%"
                maxWidth="860px"
                paddingBottom="2rem">
                <Grid container direction="column" spacing={1}>
                    {props.hasBackButton && (
                        <Grid item md={12} container alignItems="center">
                            <Button
                                onClick={() => handleBack()}
                                startIcon={
                                    <ReactSVG src="/img/navigation/back.svg" />
                                }>
                                {props?.backText && (
                                    <WgTypography
                                        color="primary"
                                        text="sentence"
                                        gutterTop="1px">
                                        {props.backText}
                                    </WgTypography>
                                )}
                            </Button>
                        </Grid>
                    )}
                    <Grid item md={12} container alignItems="center">
                        <Grid item md container>
                            {props.headerText && (
                                <WgTypography
                                    noWrap
                                    color="primary"
                                    text="contentInfo">
                                    {props.headerText}
                                </WgTypography>
                            )}
                        </Grid>
                        <Grid item md={1} container justify="flex-end">
                            {props.buttonRight && (
                                <IconButton
                                    className={classes.icon}
                                    component={MyLink(
                                        '/' + props.buttonRight.link
                                    )}>
                                    <img src={props.buttonRight.icon} alt="" />
                                </IconButton>
                            )}
                            {props.buttonRightPopup && (
                                <IconButton
                                    className={classes.icon}
                                    onClick={() =>
                                        props.buttonRightPopup &&
                                        props.buttonRightPopup.close()
                                    }>
                                    <img
                                        src={props.buttonRightPopup.icon}
                                        alt=""
                                    />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    function renderMobile() {
        return (
            <AppBar
                id="header"
                position="fixed"
                color="default"
                elevation={props.elevation ?? 1}>
                <Toolbar style={{padding: '0 8px'}}>
                    <Grid
                        container
                        justify="space-evenly"
                        alignItems="center"
                        wrap="nowrap">
                        <Grid item xs={3} container justify="flex-start">
                            {props.hasBackButton && (
                                <IconButton onClick={() => handleBack()}>
                                    <img
                                        src="/img/navigation/back.svg"
                                        alt=""
                                    />
                                </IconButton>
                            )}
                        </Grid>
                        <Grid item xs>
                            <WgTypography
                                noWrap
                                align="center"
                                color="primary"
                                style={{
                                    fontWeight: 700,
                                    maxWidth: 'calc(100vw - 58px)'
                                }}>
                                {props.headerText}
                            </WgTypography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            container
                            justify="flex-end"
                            style={{minWidth: '1rem'}}>
                            {props.buttonRight && (
                                <IconButton
                                    component={MyLink(
                                        '/' + props.buttonRight.link
                                    )}>
                                    <img src={props.buttonRight.icon} alt="" />
                                </IconButton>
                            )}
                            {props.buttonRightPopup && (
                                <IconButton
                                    className={clsx(
                                        classes.icon,
                                        classes.iconNoShadow
                                    )}
                                    onClick={() =>
                                        props.buttonRightPopup &&
                                        props.buttonRightPopup.close()
                                    }>
                                    <img
                                        src={props.buttonRightPopup.icon}
                                        alt=""
                                    />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    function handleBack() {
        if (props.onBackExtra) props.onBackExtra();
        if (props.hasAskToReturn) {
            askToReturn();
        } else {
            if (props.historyState?.redirectLink) {
                history.push(
                    props.historyState.redirectLink,
                    props.historyState
                );
            } else {
                history.goBack();
            }
        }
    }
}

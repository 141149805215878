import {
    Box,
    BoxProps,
    CircularProgress,
    createStyles,
    InputAdornment,
    TextField
} from '@material-ui/core';
import React from 'react';
import {Search} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            '& .MuiInput-root': {
                margin: '4px 0',
                '@media (max-width: 320px)': {
                    fontSize: '0.875rem'
                }
            }
        }
    })
);

export interface SearchBarProps extends BoxProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    open: boolean;
    setOpen: Function;
    searchString: string;
    isLoading?: boolean;
    placeholder: string;
}

export default function SearchBar({
    handleChange,
    open,
    setOpen,
    searchString,
    isLoading,
    placeholder,
    ...other
}: SearchBarProps) {
    const classes = useStyles();
    return (
        <Box
            borderRadius={5}
            boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.05)"
            bgcolor="common.white"
            display="flex"
            alignItems="center"
            height="3.75rem"
            {...other}>
            <Box marginLeft="0.75rem" marginRight="0.75rem">
                <Search color="disabled" />
            </Box>
            <TextField
                inputProps={{style: {padding: '0.5rem 0'}}}
                className={classes.textField}
                fullWidth
                placeholder={placeholder}
                autoFocus={open}
                onFocus={() => setOpen(true)}
                // onBlur={() => setOpen(false)}
                value={searchString}
                onChange={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {isLoading ? (
                                <CircularProgress color="primary" size={20} />
                            ) : (
                                <React.Fragment />
                            )}
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
}

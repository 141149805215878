import React from 'react';
import {Box, CircularProgress, Grid} from '@material-ui/core';
import WgSkeleton from '../WgSkeleton';

export default function AccountGroupDetailsSkeleton() {
    return (
        <Grid
            style={{paddingTop: '1rem', flex: 1}}
            container
            wrap="nowrap"
            justify="space-between"
            alignItems="center">
            <Grid
                zeroMinWidth
                item
                xs
                container
                direction="column"
                style={{marginLeft: '4px', overflow: 'hidden'}}>
                <WgSkeleton width={'50%'} animation="wave" />
                <WgSkeleton width={'80%'} animation="wave" />
            </Grid>
            <Grid item xs={2} container justify="flex-end" alignItems="center">
                <Box flex={1} p="4px" display="flex" justifyContent="flex-end">
                    <CircularProgress size="1.25rem" color="secondary" />
                </Box>
            </Grid>
        </Grid>
    );
}

import React, {useCallback, useEffect} from 'react';
import {
    ICarInsurancePaymentAccount,
    IPaymentData,
    PaymentMethod
} from '../../../../../models/productInterface';

import {Box, Grid} from '@material-ui/core';
import {
    WgPaperArea,
    WgTypography
} from '../../../../../styles/CustomComponents';
import PaymentMethodInput from '../../../../utils/PaymentMethodInput';
import {IAccount, isAccount} from '../../../../../models/accountInterface';
import {isInputNotFilled} from '../../../../utils/Validate';

interface CarInsurancePaymentProps {
    setPaymentData: Function;
    paymentData: ICarInsurancePaymentAccount;
    setIsValid: Function;
    selectedAccount?: IAccount;
    validationEnabled: boolean;
    redirectLink?: string;
}

export default function CarInsurancePayment(props: CarInsurancePaymentProps) {
    const {
        paymentData,
        setPaymentData,
        selectedAccount,
        setIsValid,
        validationEnabled,
        redirectLink
    } = props;

    const handleChange = (name: keyof ICarInsurancePaymentAccount) => (
        value: PaymentMethod | any
    ) => {
        validateFields();
        setPaymentData({
            ...paymentData,
            [name]: value instanceof Object ? value.target.value : value
        });
    };

    const validateFields = useCallback(() => {
        if (
            validateAccountPaymentMethod(paymentData, selectedAccount) ||
            validateManualInputPaymentMethod(paymentData)
        )
            setIsValid(true);
        else {
            setIsValid(false);
        }
    }, [setIsValid, paymentData, selectedAccount]);

    useEffect(() => {
        validateFields();
    }, [validateFields]);

    return (
        <Grid
            item
            xs={12}
            container
            direction="column"
            wrap="nowrap"
            style={{flex: 1}}
            justify="center">
            <WgTypography color="textSecondary" text="info">
                Bitte wähle ein Konto von dem die Beiträge gezahlt werden
                sollen.
            </WgTypography>
            <Box marginTop="1rem">
                <WgPaperArea
                    header="Kontodaten angeben"
                    elevation={1}
                    paperStyle={{padding: '1rem 1rem 1rem'}}>
                    <Grid container direction="column" spacing={1}>
                        <PaymentMethodInput
                            account={selectedAccount}
                            iban={paymentData.iban ?? ''}
                            accountHolderName={
                                paymentData.accountHolderName ?? ''
                            }
                            handleChange={handleChange}
                            paymentMethod={paymentData.paymentMethod}
                            validationEnabled={validationEnabled}
                            redirectLink={redirectLink}
                        />
                    </Grid>
                </WgPaperArea>
            </Box>
        </Grid>
    );
}
function validateAccountPaymentMethod(
    paymentData: IPaymentData,
    account?: IAccount
) {
    if (typeof account === 'undefined') {
        return false;
    } else if (
        isAccount(account) &&
        paymentData.paymentMethod === PaymentMethod.InApp
    )
        return true;

    return false;
}

function validateManualInputPaymentMethod(paymentData: IPaymentData) {
    if (
        paymentData.paymentMethod === PaymentMethod.ManualIban &&
        !isInputNotFilled(paymentData.accountHolderName) &&
        !isInputNotFilled(paymentData.iban)
    )
        return true;
    else return false;
}

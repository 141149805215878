import React from 'react';
import {
    WgInputField,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {Box, Grid, Paper} from '@material-ui/core';

export interface InsuranceNumberEntryProps {
    insuranceNumber: string;
    handleChange: (value: any) => void;
}

export default function InsuranceNumberEntry({
    handleChange,
    insuranceNumber
}: InsuranceNumberEntryProps) {
    return (
        <Grid container item direction="column" style={{flex: 1}} wrap="nowrap">
            <Grid
                item
                xs
                container
                direction="column"
                justify="center"
                wrap="nowrap">
                <Grid container direction="column">
                    <WgTypography
                        gutterBottom
                        color="primary"
                        text="contentInfo">
                        Versicherungsnummer
                    </WgTypography>
                    <WgTypography color="textPrimary" text="content">
                        Für eine{' '}
                        <strong>
                            schnellere Bereitstellung deiner
                            Vertragsinformationen
                        </strong>
                        , empfiehlt WechselGott die Angabe deiner
                        Versicherungsnummer.
                    </WgTypography>
                    <Box marginY="1rem">
                        <Paper elevation={1}>
                            <WgInputField
                                handleChange={(event: any) =>
                                    handleChange(event.target.value)
                                }
                                type="text"
                                defaultValue={insuranceNumber}
                                name="insuranceNumber"
                                label="Versicherungsnummer eingeben..."
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

import React, {Component, useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {queueSelector} from '../../store/reducers/selectors';
import {ILoading} from '../../models/notificationInterface';
import {cleanLoadingQueue} from '../../store/actions/notificationActions';

class EmptyLoadingComponent extends Component<any, {}> {
    render(): React.ReactNode {
        return <React.Fragment />;
    }
}

function Loading(props: ILoading) {
    const dispatch = useDispatch();

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(cleanLoadingQueue());
        }, 1000);
        return () => clearInterval(interval);
    }, [dispatch]);

    let LoadingComponent = props.component ?? EmptyLoadingComponent;
    return <LoadingComponent {...props.props} />;
}

function mapStateToProps(state: any) {
    return {
        ...queueSelector(state)[0]
    };
}

export default connect(mapStateToProps, {})(Loading);

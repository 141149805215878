import {
    SET_REQUIRED_INFORMATIONS,
    SET_TOKEN,
    SET_USER_BROKERMANDATE,
    SET_USER_TMP_BROKERMANDATE,
    SET_USER_DATA,
    SET_USER_ID
} from '../actions/actionTypes';
import get from 'lodash.get';
import {setCookie} from '../../containers/utils/Cookie';

const temp = {}; //could be used for notifications form the BackEnd

export const initialState = get(temp, 'user', {
    id: null,
    token: null,
    userData: {country: 'Deutschland'},
    requiredInformations: ['BrokerMandate'],
    brokermandate: null,
    tmpBrokermandate: {image: null, isChecked: false, resetOnReenter: 1}
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            };

        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            };

        case SET_USER_ID:
            setCookie('uuid', action.payload, 30);
            return {
                ...state,
                id: action.payload
            };

        case SET_REQUIRED_INFORMATIONS:
            if (!Array.isArray(action.payload)) return state;

            return {
                ...state,
                requiredInformations: action.payload
            };

        case SET_USER_BROKERMANDATE:
            return {
                ...state,
                brokermandate: action.payload
            };

        case SET_USER_TMP_BROKERMANDATE:
            return {
                ...state,
                tmpBrokermandate: action.payload
            };
        default:
            return state;
    }
};

import {Divider, Grid, Box} from '@material-ui/core';
import React from 'react';
import {IVehicleHolder} from '../../../../../../models/productInterface';
import ListItemCompletion from './ListItemCompletion';
import {GenderRadioGroup} from '../../../../recurringpayments/change/Components';
import {formatDateWithTime} from '../../../../../utils/Format';

export interface PersonalDataCompletionProps {
    vehicleHolder?: IVehicleHolder;
}

export default function PersonalDataCompletion(
    props: PersonalDataCompletionProps
) {
    const {vehicleHolder} = props;

    let birthDate = vehicleHolder?.birthDate
        ? formatDateWithTime(new Date(vehicleHolder?.birthDate), false)
        : vehicleHolder?.birthDate?.toString();

    return (
        <Box margin="0 1rem 1rem 1rem">
            <Grid container wrap="nowrap">
                <Grid style={{width: '100%'}} item>
                    <ListItemCompletion
                        primary="Vorname"
                        secondary={vehicleHolder?.firstName}
                        lastElement
                    />
                </Grid>
                <Box display="flex" alignItems="center">
                    <Divider
                        orientation="vertical"
                        light
                        style={{height: '50%'}}
                    />
                </Box>
                <Grid style={{width: '100%', paddingLeft: '1rem'}} item xs={12}>
                    <ListItemCompletion
                        primary="Nachname"
                        secondary={vehicleHolder?.familyName}
                        lastElement
                    />
                </Grid>
            </Grid>
            <Divider light />
            <ListItemCompletion
                primary="Geburtsdatum"
                secondary={birthDate}
                lastElement={false}
            />
            <GenderRadioGroup gender={vehicleHolder?.gender} disabled />
            <Divider light />
            <ListItemCompletion
                primary="E-Mail-Adresse"
                secondary={vehicleHolder?.email}
                lastElement={false}
            />
            <Grid container wrap="nowrap">
                <Grid style={{width: '100%'}} item>
                    <ListItemCompletion
                        primary="Straße"
                        secondary={vehicleHolder?.street}
                        lastElement
                    />
                </Grid>
                <Box display="flex" alignItems="center">
                    <Divider
                        orientation="vertical"
                        light
                        style={{height: '50%'}}
                    />
                </Box>
                <Grid
                    style={{width: '100%', paddingLeft: '1rem'}}
                    item
                    md={12}
                    xs={6}>
                    <ListItemCompletion
                        primary="Hausnummer"
                        secondary={vehicleHolder?.houseNumber}
                        lastElement
                    />
                </Grid>
            </Grid>
            <Divider light />
            <Grid container wrap="nowrap">
                <Grid style={{width: '100%'}} item>
                    <ListItemCompletion
                        primary="PLZ"
                        secondary={vehicleHolder?.postalCode}
                        lastElement
                    />
                </Grid>
                <Box display="flex" alignItems="center">
                    <Divider
                        orientation="vertical"
                        light
                        style={{height: '50%'}}
                    />
                </Box>
                <Grid style={{width: '100%', paddingLeft: '1rem'}} item xs={12}>
                    <ListItemCompletion
                        primary="Wohnort"
                        secondary={vehicleHolder?.city}
                        lastElement
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default class Logger {
    static isEnabled() {
        return process.env.NODE_ENV === 'development';
    }

    static log(message?: any, ...optionalParams: any[]): void {
        if (this.isEnabled()) {
            console.log(message, ...optionalParams);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    static warn(message?: any, ...optionalParams: any[]): void {
        if (this.isEnabled()) {
            console.warn(message, ...optionalParams);
        }
    }

    static assert(
        value: any,
        message?: string,
        ...optionalParams: any[]
    ): void {
        console.assert(value, message, ...optionalParams);
    }

    // noinspection JSUnusedGlobalSymbols
    static clear(): void {
        console.clear();
    }

    // noinspection JSUnusedGlobalSymbols
    static count(label?: string): void {
        console.count(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static countReset(label?: string): void {
        console.countReset(label);
    }

    static debug(message?: any, ...optionalParams: any[]): void {
        console.debug(message, ...optionalParams);
    }

    static dir(obj: any, options?: NodeJS.InspectOptions): void {
        console.dir(obj, options);
    }

    // noinspection JSUnusedGlobalSymbols
    static dirxml(...data: any[]): void {
        console.dirxml(...data);
    }

    static error(message?: any, ...optionalParams: any[]): void {
        console.error(message, ...optionalParams);
    }

    static group(...label: any[]): void {
        console.group(...label);
    }

    // noinspection JSUnusedGlobalSymbols
    static groupCollapsed(): void {
        console.groupCollapsed();
    }

    // noinspection JSUnusedGlobalSymbols
    static groupEnd(): void {
        console.groupEnd();
    }

    static info(message?: any, ...optionalParams: any[]): void {
        console.info(message, ...optionalParams);
    }

    static table(tabularData: any, properties?: string[]): void {
        console.table(tabularData, properties);
    }

    static time(label?: string): void {
        console.time(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static timeEnd(label?: string): void {
        console.timeEnd(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static timeLog(label?: string, ...data: any[]): void {
        console.timeLog(label, ...data);
    }

    static trace(message?: any, ...optionalParams: any[]): void {
        console.trace(message, ...optionalParams);
    }

    static profile(label?: string): void {
        console.profile(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static profileEnd(label?: string): void {
        console.profileEnd(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static timeStamp(label?: string): void {
        console.timeStamp(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static timeline(label?: string): void {
        console.timeline(label);
    }

    // noinspection JSUnusedGlobalSymbols
    static timelineEnd(label?: string): void {
        console.timelineEnd(label);
    }
}

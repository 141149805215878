import React from 'react';
import {FormControl, FormGroup} from '@material-ui/core';
import {SignupStep} from './SignupStep';
import MyLink from '../../../utils/MyLink';
import {WgCheckbox} from '../../../../styles/CustomComponents';

export default class SignupStep12 extends SignupStep {
    handleInput(event: any) {
        let name = event.target.value;
        let value = event.target.checked;
        super.handleInput({target: {name, value}});
        // @ts-ignore
        this.setState({[name]: value});
    }

    renderStep(): React.ReactNode {
        let LinkTerms = MyLink('/info/terms');
        let LinkPrivacy = MyLink('/info/privacy');

        let content = [
            {
                checked: this.state.agb,
                name: 'agb',
                label: (
                    <span>
                        * Ich akzeptiere die <LinkTerms>AGB</LinkTerms>
                    </span>
                )
            },
            {
                checked: this.state.dsgvo,
                name: 'dsgvo',
                label: (
                    <span>
                        * Ich akzeptiere die{' '}
                        <LinkPrivacy>Datenschutzbestimmungen</LinkPrivacy>
                    </span>
                )
            },
            {
                checked: this.state.newsletter,
                name: 'newsletter',
                label:
                    'Ich möchte Produktinformationen und Neuigkeiten der WechselGott GmbH per E-Mail erhalten'
            }
        ];

        return (
            <React.Fragment>
                <FormControl onChange={this.handleInput.bind(this)}>
                    <FormGroup>{content.map(this.renderCheckBox)}</FormGroup>
                </FormControl>
            </React.Fragment>
        );
    }

    private renderCheckBox({checked, name, label}: any) {
        return (
            <WgCheckbox
                key={name}
                label={label}
                checked={checked}
                value={name}
                background="paper"
            />
        );
    }
}

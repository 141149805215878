import React from 'react';
import {
    WgListItem,
    WgListItemContent,
    WgTypography
} from '../../styles/CustomComponents';
import {Box, Grid} from '@material-ui/core';
import DepotYieldLabel from './DepotYieldLabel';

export interface DepotListItemProps {
    header: string;
    countMultiple: number;
    amountMultiple: string;
    amountSingleStock: string;
    percentageDifference: number;
}

export default function DepotListItem({
    header,
    percentageDifference,
    amountMultiple,
    countMultiple,
    amountSingleStock
}: DepotListItemProps) {
    return (
        <WgListItem shadow style={{marginBottom: '0.5rem'}}>
            <WgListItemContent style={{maxWidth: '100%'}}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start">
                    <WgTypography
                        fitContent
                        noWrap
                        gutterBottom
                        color="textPrimary">
                        {header}
                    </WgTypography>
                    <Grid container justify="space-between" wrap="nowrap">
                        {mapToTypography(
                            countMultiple + ' Stück',
                            amountMultiple
                        )}
                        {mapToTypography('1 Stück', amountSingleStock)}
                        <Grid
                            item
                            xs={4}
                            container
                            justify="flex-end"
                            alignItems="center">
                            <Box marginRight="0.5rem">
                                <DepotYieldLabel
                                    filled
                                    value={percentageDifference}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </WgListItemContent>
        </WgListItem>
    );

    function mapToTypography(description: string, value: string) {
        return (
            <Grid wrap="nowrap" container item xs={4} direction="column">
                <WgTypography
                    fitContent
                    noWrap
                    fontSize="0.75rem"
                    color="textSecondary">
                    {description}
                </WgTypography>
                <WgTypography
                    fitContent
                    noWrap
                    number="list"
                    color="textPrimary">
                    {value}
                </WgTypography>
            </Grid>
        );
    }
}

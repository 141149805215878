import React from 'react';
import {Divider, Paper} from '@material-ui/core';
import {SignupStep} from './SignupStep';
import {WgInputField} from '../../../../styles/CustomComponents';

export default class SignupStep2 extends SignupStep {
    renderStep(): React.ReactNode {
        return (
            <React.Fragment>
                <Paper elevation={2}>
                    <WgInputField
                        label="Namenszusatz (optional)"
                        type="text"
                        defaultValue={this.props.state.title}
                        name="title"
                    />
                </Paper>

                <Paper elevation={2} style={{marginTop: '1.25rem'}}>
                    <WgInputField
                        autoFocus
                        label="Vorname"
                        type="text"
                        defaultValue={this.props.state.firstName}
                        name="firstName"
                        autoComplete="fname"
                    />
                    <Divider
                        light
                        variant="middle"
                        style={{marginLeft: '1.25rem', marginRight: '1.25rem'}}
                    />
                    <WgInputField
                        label="Nachname"
                        type="text"
                        defaultValue={this.props.state.lastName}
                        name="lastName"
                        autoComplete="lname"
                    />
                </Paper>
            </React.Fragment>
        );
    }
}

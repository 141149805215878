import React from 'react';
import {
    IRecurringpayment,
    IRecurringPaymentChangeData
} from '../../../../models/recurringpaymentInterface';
import {
    formatDateFromString,
    formatEcoOption,
    formatMoney,
    formatRecurringpaymentChangeState
} from '../../../utils/Format';
import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import {WgTypography} from '../../../../styles/CustomComponents';
import {formatMeterType} from '../../../../models/productInterface';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editIcon: {
            boxShadow: theme.shadows[1],
            minWidth: '3rem',
            minHeight: '3rem',
            backgroundColor: theme.palette.background.paper,
            position: 'absolute',
            right: 0,
            top: 0,
            marginTop: '-12px'
        },
        infoSubtitle: {
            minWidth: '8rem',
            fontSize: '0.75rem'
        },
        info: {
            '@media (max-width: 320px)': {
                fontSize: '0.875rem'
            }
        }
    })
);

export interface RecurringPaymentDetailsProps {
    recurringPayment?: IRecurringpayment;
    changeValues?: any;
}

export default function RecurringPaymentDetails({
    recurringPayment,
    changeValues
}: RecurringPaymentDetailsProps) {
    const classes = useStyles();

    return (
        <React.Fragment>
            {recurringPayment && isChangeState() && (
                <Box marginTop="4vh">
                    {renderChangeData(recurringPayment.changeData)}
                </Box>
            )}

            {recurringPayment &&
                recurringPayment.cancellationStatus === 'Canceled' && (
                    <React.Fragment>
                        {mapToTypography({
                            label: 'Kündigungsstatus',
                            value: 'Wird gekündigt'
                        })}
                    </React.Fragment>
                )}
            {changeValues && renderEnergyDetails(changeValues)}
        </React.Fragment>
    );
    function isChangeState() {
        return (
            recurringPayment &&
            recurringPayment.changeData.changeState ===
                ('onGoing' || 'success' || ' failure')
        );
    }

    function renderEnergyDetails(changeValues: any) {
        let keyValueMapping = {
            Vertragsart: formatMeterType(changeValues.meterType),
            Gewerblich: changeValues.isCommercial ? 'Ja' : 'Nein',
            'Alter Anbieter': changeValues.oldProviderName,
            Lieferstelle: changeValues.deliveryPostcode,
            Vertragsoptimierung: formatEcoOption(changeValues.eco)
        };

        return (
            <Box position="relative" marginY="4vh">
                <Grid container direction="column">
                    {mapToTypography(keyValueMapping)}
                </Grid>
            </Box>
        );
    }

    function renderChangeData({
        changeState,
        startDate,
        estimatedYearlySavings,
        providerName,
        yearlyCosts
    }: IRecurringPaymentChangeData) {
        let keyValueMapping = {
            Wechselstatus: formatRecurringpaymentChangeState(changeState),
            Wechselzeitpunkt: formatDateFromString(startDate),
            'Neuer Anbieter': providerName,
            'Jährliche Kosten': yearlyCosts && formatMoney(yearlyCosts),
            'Jährliche Ersparnis':
                estimatedYearlySavings && formatMoney(estimatedYearlySavings)
        };
        return mapToTypography(keyValueMapping);
    }

    function mapToTypography(keyValueMapping: any) {
        return Object.keys(keyValueMapping).map((key: string) => {
            if (typeof keyValueMapping[key] === 'undefined') return null;
            return (
                <Box
                    key={key + keyValueMapping[key]}
                    display="flex"
                    flexWrap="nowrap"
                    paddingY="4px"
                    alignItems="baseline">
                    <WgTypography
                        noWrap
                        className={classes.infoSubtitle}
                        color="textSecondary">
                        {key}:
                    </WgTypography>
                    <WgTypography
                        className={classes.info}
                        text="sentence"
                        color="textPrimary">
                        {keyValueMapping[key]}
                    </WgTypography>
                </Box>
            );
        });
    }
}

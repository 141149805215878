import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import WechselCard from './WechselCard';
import {IInsuranceProduct} from '../../../../models/productInterface';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            justifyContent: 'center',
            margin: '2vh 0',
            overflow: 'hidden',
            textAlign: 'center'
        },
        paper: {
            padding: theme.spacing(1),
            color: theme.palette.text.secondary
        }
    })
);

//max 3x3... change xs value to expand the grid
export default function NestedGrid(props: any) {
    const classes = useStyles(undefined);

    const {products, isPriceVisible} = props;
    //ToDo: replace wechselcard with a universal component
    return (
        <div className={classes.root}>
            <Grid container spacing={2} justify="center">
                {products &&
                    products.map(
                        (productRow: IInsuranceProduct[], index: number) => (
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                key={index}>
                                {productRow &&
                                    productRow.map(
                                        (
                                            product: IInsuranceProduct,
                                            index: number
                                        ) => (
                                            <Grid item xs={4} key={index}>
                                                {product && (
                                                    <WechselCard
                                                        logoPath={
                                                            product.logoPath
                                                        }
                                                        id={product.id}
                                                        name={product.name}
                                                        isPriceVisible={
                                                            isPriceVisible
                                                        }
                                                        price={product.price}
                                                        redirectLink={
                                                            product.url ||
                                                            '/products/available/' +
                                                                product.id
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        )
                                    )}
                            </Grid>
                        )
                    )}
            </Grid>
        </div>
    );
}

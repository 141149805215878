import React from 'react';
import {WgListItem, WgListItemContent, WgListItemIcon} from '../index';
import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import WgSkeleton from '../WgSkeleton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        skeleton: {
            height: '10px',
            backgroundColor: theme.palette.gray.light,
            '&::after': {
                background: `linear-gradient(90deg, transparent, ${theme.palette.gray.veryLight}, transparent)`
            }
        },
        small: {
            width: '20vw',
            '@media (max-width: 320px)': {
                width: '12vw'
            },
            [theme.breakpoints.up('sm')]: {
                width: '100px'
            }
        },
        big: {
            width: '30vw',
            '@media (max-width: 320px)': {
                width: '20vw'
            },
            [theme.breakpoints.up('sm')]: {
                width: '180px'
            }
        }
    })
);

function AccountListItemSkeleton() {
    const classes = useStyles();
    return (
        <WgListItem style={{marginBottom: '0.5rem'}} shadow background="paper">
            <WgListItemIcon>
                <Box width={42} height={42}>
                    <WgSkeleton
                        variant="circle"
                        width={42}
                        height={42}
                        animation="wave"
                    />
                </Box>
            </WgListItemIcon>
            <WgListItemContent>
                <div>
                    <WgSkeleton
                        animation="wave"
                        className={classes.small}
                        style={{marginBottom: 6}}
                    />
                    <WgSkeleton animation="wave" className={classes.big} />
                </div>
                <div>
                    <WgSkeleton animation="wave" width={32} />
                </div>
            </WgListItemContent>
        </WgListItem>
    );
}

export default AccountListItemSkeleton;

import React, {useEffect} from 'react';
import {Card, Divider, Grid} from '@material-ui/core';
import {
    WgInputField,
    WgSelectorButton,
    WgTypography
} from '../../../../../styles/CustomComponents';
import {IEnergyContractPreferences} from '../../../../../models/offerInterface';
import {inputNotFilled} from '../Components';

export interface AreaInputProps {
    handleChange: (
        name: keyof IEnergyContractPreferences
    ) => (value: any) => void;
    isCommercial: boolean;
    deliveryPostcode: string;
    setInputValid: Function;
    isManualAddition?: boolean;
}

export default function AreaInput({
    handleChange,
    isCommercial,
    deliveryPostcode,
    setInputValid
}: AreaInputProps) {
    useEffect(() => {
        setInputValid(!inputNotFilled(deliveryPostcode));
    }, [setInputValid, deliveryPostcode]);

    return (
        <Grid item xs={12} container direction="column" justify="center">
            <Card style={{padding: '1rem', maxHeight: 'fit-content'}}>
                <WgTypography color="textPrimary" text="content">
                    Tarifnutzung
                </WgTypography>
                <WgTypography
                    gutterBottom
                    gutterTop="1.5rem"
                    color="textSecondary"
                    text="subContent">
                    Bitte wähle die Art der Tarifnutzung.
                </WgTypography>
                <Grid
                    container
                    spacing={2}
                    style={{marginTop: '0.5rem', paddingBottom: '1rem'}}>
                    {renderButton(false)}
                    {renderButton(true)}
                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>
                </Grid>
                <WgInputField
                    required
                    fullWidth
                    error={inputNotFilled(deliveryPostcode)}
                    helperText={
                        inputNotFilled(deliveryPostcode) &&
                        'Diese Eingabe ist erforderlich.'
                    }
                    name="deliveryPostcode"
                    label="Postleitzahl der Lieferadresse"
                    type="text"
                    defaultValue={deliveryPostcode}
                    handleChange={(event: any) =>
                        handleChange('deliveryPostcode')(event.target.value)
                    }
                />
            </Card>
        </Grid>
    );

    function renderButton(commercial: boolean) {
        return (
            <Grid item xs={6}>
                <WgSelectorButton
                    handleChange={handleChange('isCommercial')}
                    active={isCommercial === commercial}
                    text={commercial ? 'Gewerblich' : 'Privat'}
                    value={commercial}
                />
            </Grid>
        );
    }
}

import React, {Component} from 'react';
import {connect, Provider} from 'react-redux';
import {configureStore, history, persistor} from './store';
import {lightTheme} from './styles/theme';
import {PersistGate} from 'redux-persist/integration/react';
import ErrorBoundary, {ErrorComponent} from './containers/app/ErrorBoundary';
import {ConnectedRouter} from 'connected-react-router';
import {ThemeProvider} from './styles/ThemeProvider';
import {Theme} from '@material-ui/core';

class App extends Component<{theme: Theme}, {}> {
    render() {
        return (
            <ThemeProvider theme={lightTheme}>
                <ErrorBoundary>
                    <PersistGate persistor={persistor}>
                        {this.props.children}
                    </PersistGate>
                </ErrorBoundary>
            </ThemeProvider>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        theme: state.theme
    };
}

export const ConnectedApp = connect(mapStateToProps, {})(App);

export default class Setup extends Component<
    {Router?: any; history?: any; store?: any},
    {}
> {
    render() {
        const thisHistory = this.props.history ?? history;
        const store = this.props.store ?? configureStore(thisHistory);
        const Router = this.props.Router ?? ConnectedRouter;
        return (
            <ErrorComponent>
                <Provider store={store}>
                    <Router history={thisHistory}>
                        <ConnectedApp>{this.props.children}</ConnectedApp>
                    </Router>
                </Provider>
            </ErrorComponent>
        );
    }
}

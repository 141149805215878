import React from 'react';
import TermsText from './TermsText';
import {WgContainer, WgHeader} from '../../../styles/CustomComponents';
import {Box} from '@material-ui/core';

export default function Terms() {
    return (
        <WgContainer publicBodyApp>
            <WgHeader
                isPublic
                hasBackButton
                headerText="Allgemeine Geschäftsbedingungen"
            />
            <Box
                width="100%"
                overflow="auto"
                marginTop={{xs: '56px', sm: '64px'}}>
                <WgContainer
                    publicBodyContent
                    disableGutters={false}
                    style={{marginTop: '0px'}}>
                    <Box paddingY="1.5rem">
                        <TermsText />
                    </Box>
                </WgContainer>
            </Box>
        </WgContainer>
    );
}

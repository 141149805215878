import {accountsIdSelector, getAccounts} from './';
import {
    ITransaction,
    ITransactionTree
} from '../../../models/transactionInterface';
import {createDeepEqualSelector, formatDateWithoutTime} from './utils';
import {groupBy} from '../../../containers/utils/Format';

export const stepSelector = (_: any, props: any): string =>
    typeof props?.selectedStep === 'string' ? props?.selectedStep : 'month';

export const dateSelector = (_: any, props: any) =>
    props?.selectedDate
        ? formatDateWithoutTime(props.selectedDate)
        : formatDateWithoutTime(new Date());

export const transactionsTreeSelector = (state: any): ITransactionTree =>
    getAccounts(state)?.transactions;

export const makeAllTransactionsFromAccountSelector = () =>
    createDeepEqualSelector(
        [accountsIdSelector, transactionsTreeSelector],
        (accountIds: number[], tree: ITransactionTree): ITransaction[] => {
            try {
                return accountIds.reduce((obj: ITransaction[], key: number) => {
                    if (
                        typeof tree[key] === 'undefined' ||
                        (tree[key] && Object.values(tree[key]).length === 0)
                    ) {
                        return obj;
                    } else {
                        return tree[key] instanceof Array
                            ? obj.concat(tree[key])
                            : obj;
                    }
                }, []);
            } catch (e) {
                return [];
            }
        }
    );

export const allTransactionsFromAccountGroupedByDaySelector = createDeepEqualSelector(
    [makeAllTransactionsFromAccountSelector()],
    (transactions: ITransaction[]) => {
        return groupTransactions(transactions).sort(
            ([aDate, aValues], [bDate, _]) =>
                new Date(bDate).getTime() - new Date(aDate).getTime()
        );
    }
);

export const getTransactionIds = (_: any, props: any) =>
    props?.transactionIds ?? [];

export const makeAllTransactionsFromRecurringpaymentSelector = () =>
    createDeepEqualSelector(
        [makeAllTransactionsFromAccountSelector(), getTransactionIds],
        (
            transactions: ITransaction[],
            transactionIds: number[]
        ): ITransaction[] =>
            Array.isArray(transactionIds) && Array.isArray(transactions)
                ? transactions.filter(transaction =>
                      transactionIds.includes(transaction.id)
                  )
                : []
    );

export const singleTransactionByIdSelector = createDeepEqualSelector(
    [
        makeAllTransactionsFromAccountSelector(),
        (_: any, props: any) => props.turnoverID
    ],
    (transactions: ITransaction[], id: number): ITransaction | undefined => {
        return Array.isArray(transactions)
            ? transactions.find(
                  (transaction: ITransaction) => transaction.id === id
              )
            : undefined;
    }
);

function groupTransactions(transactions: ITransaction[]) {
    const grouped = groupBy(transactions, (transaction: ITransaction) =>
        formatDateWithoutTime(transaction.valueDate).toISOString()
    );
    return Array.from(grouped.entries());
}

import React, {useState} from 'react';
import CustomAreaChart from '../../statistics/Charts/lineCharts/CustomAreaChart';
import {IDashboard} from '../../../../models/statistics/dashBoardInterface';
import {Box, createStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '-ms-touch-action': 'none',
            'touch-action': 'none'
        }
    })
);

interface IProps {
    dashboard: IDashboard;
    activeIndex: number;
    initialBalance: number;
}

export default function Dashboard(props: IProps) {
    const classes = useStyles();
    //subtract 2vw from viewport because of margin
    const verticalWidth = window.innerWidth / 100;
    //max viewport in windows mode
    const maxWidth = 860 - verticalWidth * 4;

    const scaleFactor = 0.25;
    const width =
        window.innerWidth > maxWidth
            ? maxWidth
            : window.innerWidth - verticalWidth * 4; //max size of the viewport

    const height = window.innerHeight * scaleFactor;

    const [chartWidth] = useState(width);
    const [chartHeight] = useState(height);
    return (
        <Box className={classes.root} display="flex" justifyContent="center">
            <CustomAreaChart
                key={props.activeIndex}
                activeIndex={props.activeIndex}
                pastData={props.dashboard.pastData}
                forecastData={props.dashboard.futureData}
                height={chartHeight}
                width={chartWidth}
                currentDate={props.dashboard.currentDate}
                initialBalance={props.initialBalance}
            />
        </Box>
    );
}

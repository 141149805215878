import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {cancellationContractActions} from '../../../../store/actions';

const {submitContractCancellation} = cancellationContractActions;

interface ICancelContractProps {
    submitContractCancellation?: Function;
    recurringPaymentId: number;
}

function CancelledContract({
    submitContractCancellation,
    recurringPaymentId
}: ICancelContractProps) {
    submitContractCancellation &&
        submitContractCancellation(recurringPaymentId);

    return <Redirect to={`/recurringpayments/details/${recurringPaymentId}`} />;
}

function mapStateToProps(state: any) {
    return {
        recurringPaymentId:
            state.recurringpayments?.cancellation.recurringPaymentId
    };
}

export default connect(mapStateToProps, {
    submitContractCancellation
})(CancelledContract);

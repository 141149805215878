import {Divider} from '@material-ui/core';
import {WgInputField} from '../../../../../styles/CustomComponents';
import React from 'react';
import {inputNotFilled} from './index';

export interface CompanyDataInputProps {
    handleChange: (event: React.ChangeEvent<any>) => void;
    companyName?: string;
}

export function CompanyDataInput({
    companyName,
    handleChange
}: CompanyDataInputProps) {
    return (
        <React.Fragment>
            <WgInputField
                required
                error={inputNotFilled(companyName)}
                helperText={
                    inputNotFilled(companyName) &&
                    'Diese Eingabe ist erforderlich.'
                }
                handleChange={handleChange}
                type="text"
                defaultValue={companyName ?? ''}
                name="companyName"
                label="Firmenname"
            />
            <Divider variant="middle" light />
        </React.Fragment>
    );
}

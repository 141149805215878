import React, {useState} from 'react';
import {Box, Divider, Grid, Paper} from '@material-ui/core';
import {connect} from 'react-redux';
import {userActions} from '../../../store/actions';
import {
    WgButton,
    WgContainer,
    WgHeader,
    WgInputField,
    WgPasswordField,
    WgTypography
} from '../../../styles/CustomComponents';

const {performLoginOnLogin, performResetPassword} = userActions;

interface LoginProps {
    performLoginOnLogin: Function;
    performResetPassword: Function;
}

function Login(props: LoginProps) {
    const [formValues, setFormValues] = useState({email: '', password: ''});

    return (
        <WgContainer publicBodyApp backgroundImage>
            <WgHeader headerText="Login" hasBackButton backText="Startseite" />
            <WgContainer publicBodyContent disableGutters={false}>
                <form
                    onSubmit={submit}
                    onChange={(event: any) => {
                        setFormValues(prevState => ({
                            ...prevState,
                            [event.target?.name]: event.target?.value
                        }));
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center'
                    }}>
                    <Grid
                        container
                        justify="center"
                        direction="column"
                        style={{
                            maxWidth: '600px',
                            flex: 1
                        }}
                        wrap="nowrap">
                        <Grid
                            item
                            xs
                            container
                            justify="space-between"
                            alignItems="center"
                            style={{maxWidth: '100%'}}>
                            <Grid item xs={12}>
                                <Paper elevation={2}>
                                    <WgInputField
                                        autoFocus
                                        label="E-Mail"
                                        type="email"
                                        name="email"
                                    />
                                    <Divider
                                        variant="middle"
                                        style={{height: '1px'}}
                                        light
                                    />
                                    <WgPasswordField
                                        label="Passwort"
                                        name="password"
                                        onPaste={pasteSubmit}
                                    />
                                </Paper>
                                <Box
                                    marginTop={{xs: '1.25rem', md: '5rem'}}
                                    textAlign={{xs: 'center', md: 'left'}}>
                                    <WgTypography
                                        display="inline"
                                        color="textSecondary"
                                        style={{cursor: 'pointer'}}
                                        text="info"
                                        onClick={performResetPassword}>
                                        Passwort zurücksetzen
                                    </WgTypography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            <Box
                                display="flex"
                                justifyContent="center"
                                m="1.5rem 0"
                                flex={1}>
                                <WgButton
                                    style={{
                                        maxWidth: '100%'
                                    }}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    aria-label="next"
                                    disabled={
                                        !(
                                            formValues.email &&
                                            formValues.password
                                        )
                                    }
                                    type="submit">
                                    Login
                                </WgButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </WgContainer>
        </WgContainer>
    );

    function submit(event: any) {
        event.preventDefault && event.preventDefault();

        const email = event.target?.email?.value;
        const password = event.target?.password?.value;

        if (Boolean(email && password)) {
            props.performLoginOnLogin({email, password});
        }
    }

    function pasteSubmit() {
        try {
            setTimeout(() => {
                const formElement = document.getElementsByTagName('form')[0];
                submit({
                    target: {
                        email: formElement?.email,
                        password: formElement?.password
                    }
                });
            }, 0);
        } catch (e) {
            console.error(e);
        }
    }

    function performResetPassword() {
        return props.performResetPassword();
    }
}

export default connect(() => ({}), {
    performLoginOnLogin,
    performResetPassword
})(Login);

import {createDeepEqualSelector, getBool} from './utils';
import {ITransaction} from '../../../models/transactionInterface';
import {makeAllTransactionsFromAccountSelector} from './index';
import {
    CategoryColor,
    getCategoryColor,
    ICategory
} from '../../../models/categoryInterface';
import {MeterType} from '../../../models/productInterface';

export const allCategorySelector = (state: any) => state?.categories ?? [];

export const categoryLevelSelector = (_: any, props: any) =>
    props?.selectedLevel ?? 3;

export const categoryIdSelector = (_: any, props: any) =>
    props?.selectedIds ?? [];

export const categoryTransactionIdSelector = createDeepEqualSelector(
    [
        makeAllTransactionsFromAccountSelector(),
        allCategorySelector,
        categoryIdSelector
    ],
    (transactions: ITransaction[], categories: ICategory[], ids: number[]) => {
        if (!Array.isArray(transactions) || !Array.isArray(ids)) {
            return [];
        }

        return transactions
            .filter(
                (transaction: ITransaction) =>
                    transaction.categoryId &&
                    ids.includes(transaction.categoryId)
            )
            .map((transaction: ITransaction) => transaction.id);
    }
);

export const categoryTransactionsSelector = createDeepEqualSelector(
    [makeAllTransactionsFromAccountSelector(), categoryTransactionIdSelector],
    (transactions: ITransaction[], ids: number[]) => {
        if (!Array.isArray(transactions) || !Array.isArray(ids)) {
            return [];
        }
        return ids.map((id: number) =>
            transactions.find(
                (transaction: ITransaction) => transaction.id === id
            )
        );
    }
);

export const categoryByLevelSelector = createDeepEqualSelector(
    [allCategorySelector, categoryLevelSelector],
    (categories: ICategory[], level: number) => {
        if (!Array.isArray(categories)) {
            return [];
        }

        return categories.filter(
            (category: ICategory) => category.level === level
        );
    }
);

export const categoryByIdSelector = createDeepEqualSelector(
    [
        allCategorySelector,
        (state: any, props: {id: number | undefined}) => props.id
    ],
    (categories: ICategory[], id?: number): ICategory | undefined => {
        if (typeof id === 'undefined' || !Array.isArray(categories))
            return undefined;
        return categories.find(
            (category: ICategory) => category.id === Number(id)
        );
    }
);

export const allCategoryEnergySelector = createDeepEqualSelector(
    [allCategorySelector],
    (categories?: ICategory[]): ICategory[] => {
        if (typeof categories === 'undefined' || categories.length === 0)
            return [];
        return categories.filter(
            category =>
                category?.name === 'Strom' ||
                category?.name === 'Gas' ||
                category?.name === 'Energie'
        );
    }
);

export const categoryByMeterTypeSelector = createDeepEqualSelector(
    [
        allCategoryEnergySelector,
        (_: any, props: {meterType: MeterType}) => props.meterType
    ],
    (
        energyCategories: ICategory[],
        meterType: MeterType
    ): ICategory | undefined => {
        if (
            typeof energyCategories === 'undefined' ||
            energyCategories.length === 0
        )
            return;
        return energyCategories.find(category =>
            meterType === MeterType.Gas
                ? category.name === 'Gas'
                : category.name === 'Strom'
        );
    }
);

export const categoryIsEnergySelector = createDeepEqualSelector(
    [categoryByIdSelector],
    (category?: ICategory) =>
        category?.name === 'Strom' ||
        category?.name === 'Gas' ||
        category?.name === 'Energie'
);

export const categoryByNameSelector = createDeepEqualSelector(
    [allCategorySelector, (state: any, props: {name: string}) => props.name],
    (categories: ICategory[], name: string): ICategory | undefined => {
        if (!Array.isArray(categories)) {
            return undefined;
        }

        return categories.find((category: ICategory) => category.name === name);
    }
);

export const categoryInsuranceSelector = createDeepEqualSelector(
    [allCategorySelector],
    (categories: ICategory[]) => {
        if (!Array.isArray(categories)) {
            return [];
        }
        return categories.filter((category: ICategory) =>
            getBool(category.isInsurance)
        );
    }
);

export const topLevelCategoryByChildIdSelector = createDeepEqualSelector(
    [categoryByIdSelector, state => state],
    (category: ICategory | undefined, state: any): ICategory | undefined => {
        if (typeof category === 'undefined') return undefined;
        let parentCategory: ICategory | undefined = category;
        while (parentCategory?.level !== 3) {
            parentCategory = categoryByIdSelector(state, {
                id: parentCategory?.parentId
            });
            if (typeof parentCategory === 'undefined') break;
        }
        return parentCategory;
    }
);

export const categoryColorByIdSelector = createDeepEqualSelector(
    [topLevelCategoryByChildIdSelector],
    (category: ICategory | undefined): CategoryColor => {
        return getCategoryColor(category?.name);
    }
);

export const initialCategoriesByTypeSelector = createDeepEqualSelector(
    [
        allCategorySelector,
        (_: any, props: {isInsurance?: boolean}) => props.isInsurance
    ],
    (categories: ICategory[], isInsurance?: boolean) => {
        if (!Array.isArray(categories)) {
            return [];
        }

        return categories.filter(
            (category: ICategory) =>
                getBool(category.isInsurance) === isInsurance &&
                getBool(category.isInitial)
        );
    }
);

import React from 'react';
import {
    createStyles,
    Grid,
    IconButton,
    Theme,
    withStyles
} from '@material-ui/core';
import {WgTypography} from './index';
import {MyLink} from '../../containers/utils';
import clsx from 'clsx';
import Logo from '../../containers/utils/Logo';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            boxShadow: theme.shadows[1],
            width: '4rem',
            height: '4rem',
            '@media (max-width: 320px)': {
                height: '3.5rem',
                width: '3.5rem'
            }
        },
        paper: {
            background: theme.palette.background.paper
        },
        default: {
            background: theme.palette.background.paper
        },
        primary: {
            background: theme.palette.primary.main
        },
        secondary: {
            background: theme.palette.secondary.main,
            '&:hover': {
                background: theme.palette.primary.main
            }
        },
        error: {
            background: theme.palette.error.light,
            '&:hover': {
                background: theme.palette.error.main
            }
        },
        primaryGradient: {
            background: `linear-gradient(120deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 64%, #fff 100%)`
        },
        secondaryGradient: {
            background: `linear-gradient(120deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`
        }
    });

export interface WgIconButtonProps {
    onClick: Function;
    src: string;
    classes: any;
    text?: string;
    subText?: string;
    link?: string;
    backgroundColor?:
        | 'paper'
        | 'default'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'primaryGradient'
        | 'secondaryGradient';
}

function WgIconButton({
    text,
    subText,
    link,
    classes,
    src,
    backgroundColor,
    onClick
}: WgIconButtonProps) {
    return (
        <Grid container direction="column" alignItems="center">
            <IconButton
                onClick={() => onClick()}
                component={link ? MyLink(link) : 'span'}
                className={clsx(classes['root'], {
                    [classes[`${backgroundColor}`]]: backgroundColor,
                    [classes['paper']]: typeof backgroundColor === 'undefined'
                })}>
                <Logo defaultImage={src} />
            </IconButton>
            {text && (
                <WgTypography
                    align="center"
                    gutterBottom
                    noWrap
                    text="content"
                    color="textPrimary">
                    {text}
                </WgTypography>
            )}
            {subText && (
                <WgTypography
                    align="center"
                    noWrap
                    color="textSecondary"
                    text="subContent">
                    {subText}
                </WgTypography>
            )}
        </Grid>
    );
}

export default withStyles(styles)(WgIconButton);

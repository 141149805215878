import {formatPercent} from './Format';
import React from 'react';
import {ChipProps} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {WgChip} from '../../styles/CustomComponents';
import {WgChipColors} from '../../styles/CustomComponents/WgChip';

export interface DepotYieldLabelProps extends ChipProps {
    value: number;
    filled?: boolean;
}

const DepotYieldLabel = ({value, ...other}: DepotYieldLabelProps) => {
    let customColor: WgChipColors =
        value < 0 ? 'warning' : value > 0 ? 'secondary' : 'brightDisabled';
    return (
        <WgChip
            number
            size="small"
            customColor={customColor}
            icon={
                value < 0 ? (
                    <ExpandMore />
                ) : value > 0 ? (
                    <ExpandLess />
                ) : (
                    undefined
                )
            }
            label={(value > 0 ? '+' : '  ') + formatPercent(value)}
            color={value < 0 ? 'primary' : value > 0 ? 'secondary' : 'default'}
            {...other}
        />
    );
};

export default DepotYieldLabel;

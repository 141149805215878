import React from 'react';
import {
    Box,
    BoxProps,
    Divider,
    Paper,
    makeStyles,
    createStyles,
    Theme,
    useMediaQuery,
    Grid
} from '@material-ui/core';
import {WgTypography} from '../../styles/CustomComponents';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'Muli',
            fontWeight: 600,
            color: theme.palette.highlight.main,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '4px',
            border: `1px solid ${theme.palette.highlight.main}`,
            minHeight: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                boxShadow: theme.shadows[2]
            }
        }
    })
);

export interface AmountFormProps extends BoxProps {
    state?: any;
}

export default function ContractState({state, ...other}: AmountFormProps) {
    const classes = useStyles();
    const isSmallPhone = useMediaQuery('(max-width: 320px)');

    if (typeof state === 'undefined' || state !== 'pending') return null;

    return (
        <Grid item xs={12}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                minHeight="fit-content"
                paddingX="1rem"
                paddingY="0.5rem"
                {...other}>
                <WgTypography
                    color="textPrimary"
                    fontSize="1.5rem"
                    header="subHeader"
                    align="left">
                    Status
                </WgTypography>
                <Paper
                    elevation={0}
                    style={{
                        right: '1rem',
                        top: isSmallPhone ? '1.25rem' : '1.5rem',
                        minWidth: isSmallPhone ? '125px' : '150px',
                        maxWidth: isSmallPhone ? '160px' : 'fit-content'
                    }}
                    className={clsx(classes.root)}>
                    In Bearbeitung
                </Paper>
            </Box>
            <Divider light />
        </Grid>
    );
}

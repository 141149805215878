import React from 'react';

import {Box, Grid} from '@material-ui/core';
import {WgTypography} from '../../styles/CustomComponents';
import {IAccount} from '../../models/accountInterface';
import {allVisibleAccountsSelector} from '../../store/reducers/selectors';
import {connect} from 'react-redux';
import InsuranceListItem from '../views/insurance/components/InsuranceListItem';

interface AccountSelectionProps {
    accounts: IAccount[];
    handleChange: (account: IAccount) => void;
}

function AccountSelection({accounts, handleChange}: AccountSelectionProps) {
    return (
        <Box>
            <Box marginBottom="1rem">
                <WgTypography color="textSecondary" text="info">
                    Wähle das Bankkonto, mit dem Dein Vertrag in Zukunft
                    verknüpft sein soll.
                </WgTypography>
            </Box>
            <Grid container spacing={1}>
                {renderAccountList()}
            </Grid>
        </Box>
    );

    function renderAccountList() {
        return accounts.map((account: IAccount) => (
            <Grid key={account.id} item xs={12}>
                <InsuranceListItem
                    placeholder="Bankkonto hinzufügen"
                    onClick={() => handleChange(account)}
                    shadow
                    data={account}
                    hasIcon
                />
            </Grid>
        ));
    }
}

function mapStateToProps(state: any) {
    return {
        accounts: allVisibleAccountsSelector(state)
    };
}

export default connect(mapStateToProps, {})(AccountSelection);

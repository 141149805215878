import axios, {AxiosError} from 'axios';
import {
    addLoading,
    addLoadingDialog,
    displayErrorNotification,
    removeLoading
} from './notificationActions';
import {
    fetchRequiredInformations,
    fetchUserData,
    logout,
    userAuthentication
} from './userActions';
import {push} from 'connected-react-router';
import {
    accountActions,
    bankActions,
    categoryActions,
    notificationActions,
    productActions,
    recurringpaymentActions
} from './index';
import {ILoading} from '../../models/notificationInterface';
import {DEFAULT_TIMOUT, LoadingPriorityCodes} from '../reducers/notification';
import WgLinearProgress from '../../containers/app/loading_components/WgLinearProgress';
import {fetchAllCompanies} from './companyActions';
import {getCookie} from '../../containers/utils/Cookie';

interface IQueue401Element {
    id: string;
    call: Function;
}

const queue401: IQueue401Element[] = [];
let isQueue401Working = false;

function addUniqueRequestCallToQueue401({id, call}: IQueue401Element) {
    if (call && !queue401.map(q => q.id).includes(id)) {
        queue401.push({id, call});
    }
}

export const api = axios.create({
    baseURL: '/dataapi/v1',
    timeout: DEFAULT_TIMOUT
});

export function getIsTokenValid(): boolean {
    return Boolean(getCookie('tokenValid'));
}

export const auth = axios.create({
    baseURL: '/authapi/v1'
});

if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_DEMO === 'true' || true
) {
    require('./authRequestMockAdapter');
    require('./apiRequestMockAdapter');
}

export async function refreshToken(getState: Function, dispatch: Function) {
    try {
        await dispatch(userAuthentication());
        return true;
    } catch (e) {
        console.log('refreshToken', e);
    }
    await dispatch(logout());
    await dispatch(push('/login'));
    return false;
}

export const performApiCall: any = (
    call?: (dispatch: Function, getState: Function) => Promise<void>,
    errorMessage?: string,
    onError?: Function,
    isSynchronizing = true,
    loadingId?: string,
    loading?: ILoading,
    waitForToken: boolean = true,
    hasRetry: boolean = true
) => {
    return async (dispatch: Function, getState: Function) => {
        if (!Object.keys(getState()).length) {
            return;
        }
        const id =
            loading?.id ??
            loadingId ??
            `performApiCall: ${call?.toString() || ''}`;

        dispatch(
            addLoading({
                priority: LoadingPriorityCodes.LINEAR_PROGRESS,
                component: WgLinearProgress,
                ...loading,
                id
            })
        );
        try {
            await callWhenReady(
                async () => call && (await call(dispatch, getState)),
                () =>
                    !waitForToken ||
                    (getIsTokenValid() && getState()?.user?.token)
            );
        } catch (error) {
            if (error.response?.status === 401) {
                call && addUniqueRequestCallToQueue401({call, id});
                if (queue401.length && !isQueue401Working) {
                    isQueue401Working = true;

                    if (!(await refreshToken(getState, dispatch))) {
                        dispatch(removeLoading(id));
                        queue401.splice(0);
                        return;
                    }
                    while (queue401.length) {
                        const requestCall = queue401.shift();
                        requestCall &&
                            (await dispatch(
                                process401RequestCall(requestCall)
                            ));
                    }

                    isQueue401Working = false;
                }
            }
            if (hasRetry && error.response?.status === 504) {
                dispatch(removeLoading(id));
                return await dealWith504(id, dispatch);
            }
            await displayError(error);
        }

        dispatch(removeLoading(id));

        async function displayError(error: any) {
            onError && (await onError(dispatch, getState, error));
            typeof errorMessage !== 'undefined' &&
                dispatch(
                    displayErrorNotification(errorMessage || error.message)
                );
        }
        function process401RequestCall({id, call}: IQueue401Element) {
            return async (dispatch: Function, getState: Function) => {
                try {
                    call && (await call(dispatch, getState));
                } catch (error) {
                    await displayError(error);
                    console.error(
                        'Error after successful TokenRefresh',
                        id,
                        call,
                        error
                    );
                }
                dispatch(removeLoading(id));
            };
        }
    };

    async function dealWith504(id: string, dispatch: Function) {
        const id504 = `${id}_504`;
        await dispatch(
            addLoadingDialog(
                id504,
                'Bei deiner Anfrage ist ein Fehler aufgetreten.'
            )
        );
        await timeout(DEFAULT_TIMOUT);
        await dispatch(removeLoading(id504));
        return await dispatch(
            performApiCall(
                call,
                errorMessage,
                onError,
                isSynchronizing,
                loadingId,
                loading,
                waitForToken,
                false
            )
        );
    }
};

export function isErrorResponseStatusTreatable(error: AxiosError) {
    return error?.response?.status === 504 || error?.response?.status === 401;
}

function callWhenReady(call: Function, ready: Function) {
    return new Promise(async function(resolve) {
        for (let i = 0; i < 100; i++) {
            if (ready()) {
                break;
            } else {
                await timeout(100);
            }
        }

        resolve(call());
    });
}

function timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getAuthConfig(token: string, additionalHeaders?: any) {
    return {
        headers: {
            Authorization: token,
            ...additionalHeaders
        }
    };
}

export const init: any = () => async (
    dispatch: Function,
    getState: Function
) => {
    const isTokenValid = getIsTokenValid();
    const token = getState().user.token;
    if (isTokenValid && token == null) {
        const isAuthenticated = await refreshToken(getState, dispatch);
        if (!isAuthenticated) return;
    }
    if (isTokenValid) {
        dispatch(fetchUserData());
        dispatch(fetchRequiredInformations());
        dispatch(accountActions.fetchAllAccounts());
        dispatch(bankActions.fetchAllBankConnections());
        dispatch(recurringpaymentActions.fetchAllRecurringpayments());
        dispatch(notificationActions.fetchMessages());
        dispatch(recurringpaymentActions.fetchPolicies());
        dispatch(categoryActions.fetchCategories());
        dispatch(productActions.fetchAllInsuranceProducts());
        dispatch(bankActions.fetchInitialBanks());
        dispatch(fetchAllCompanies());
    }
};

import {
    FETCH_ENERGY_OFFER_CONTRACT_VALIDATION_DATA_SUCCESS,
    FETCH_ENERGY_OFFER_SUCCESS
} from '../actions/actionTypes';
import get from 'lodash.get';
import {
    IOffer,
    isEnergyContractValidation,
    isOffer
} from '../../models/offerInterface';

const temp = {}; //could be used for notifications form the BackEnd

export interface IOffersReducerState {
    energy: IOffer[];
    // add insurance or similar object in the future
}
export const initialState: IOffersReducerState = get(temp, 'offers', {
    energy: []
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ENERGY_OFFER_SUCCESS:
            if (
                typeof action.payload === 'undefined' ||
                !(action.payload instanceof Object)
            )
                return state;
            if (!isOffer(action.payload)) return state;

            return {
                ...state,
                energy: addOrReplaceOffer(state, action.payload)
            };

        case FETCH_ENERGY_OFFER_CONTRACT_VALIDATION_DATA_SUCCESS:
            if (
                typeof action.payload === 'undefined' ||
                !(action.payload instanceof Object) ||
                typeof action.payload.recurringPaymentId !== 'number' ||
                !(action.payload.data instanceof Object)
            )
                return state;
            if (!isEnergyContractValidation(action.payload.data)) return state;
            if (state.energy.length === 0) return state;
            return {
                ...state,
                energy: buildOffer(state, action.payload)
            };
        default:
            return state;
    }
};

export function buildOffer(state: any, payload: any): IOffer[] {
    const {offers, existingIndex} = getIndexOfObject(state, payload);
    if (existingIndex < 0) throw new Error('unknown offer'); // TODO make sure this works

    const newOffer = {...offers[existingIndex], dataValidation: payload.data};
    offers.splice(existingIndex, 1, newOffer);
    return offers;
}

export function addOrReplaceOffer(state: any, payload: IOffer): IOffer[] {
    const {offers, existingIndex} = getIndexOfObject(state, payload);
    if (existingIndex < 0) return [...offers, payload];

    offers.splice(existingIndex, 1, payload);
    return offers;
}

function getIndexOfObject(state: any, payload: any) {
    const offers: IOffer[] = [...state.energy];
    const existingIndex = offers.findIndex(
        offer => offer.recurringPaymentId === payload.recurringPaymentId
    );

    return {offers, existingIndex};
}

import {ContractDataInputs} from './ContractDataInputs';
import {DeliveryDataInputs} from './DeliveryDataInputs';
import {GenderRadioGroup} from './GenderRadioGroup';
import {LinkedAccountInputs} from './LinkedAccountInputs';
import {NameInputs} from './NameInputs';
import {PersonalDataInputs} from './PersonalDataInputs';
import EnergyUsageSelection from './EnergyUsageSelection';
import FetchOfferLoadingContent from './FetchOfferLoadingContent';

function inputNotFilled(value: any) {
    return typeof value === 'undefined' || value === '';
}

export {
    ContractDataInputs,
    DeliveryDataInputs,
    GenderRadioGroup,
    LinkedAccountInputs,
    NameInputs,
    PersonalDataInputs,
    inputNotFilled,
    EnergyUsageSelection,
    FetchOfferLoadingContent
};

import React, {useState} from 'react';
import {
    createStyles,
    IconButton,
    InputAdornment,
    makeStyles,
    Theme
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import clsx from 'clsx';
import {WgInputField} from './index';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconRoot: {
            padding: 0
        },
        iconDisabled: {
            color: theme.palette.text.secondary
        },
        iconActive: {
            color: theme.palette.primary.main
        }
    })
);

interface IProps {
    label: string;
    value?: string;
    onChange?: any;
    onPaste?: any;
    name?: string;
    autoFocus?: boolean;
}

export default function WgPasswordField(props: IProps) {
    const classes = useStyles();
    const [showPassword, setVisibility] = useState(false);

    return (
        <WgInputField
            label={props.label}
            type={showPassword ? 'text' : 'password'}
            value={props.value}
            handleChange={props.onChange}
            onPaste={props.onPaste}
            name={props.name ?? ''}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <InputAdornment position="end">
                            <IconButton
                                tabIndex={-1}
                                className={clsx(
                                    classes.iconRoot,
                                    classes[
                                        showPassword
                                            ? 'iconActive'
                                            : 'iconDisabled'
                                    ]
                                )}
                                // color={showPassword ? "primary" : "default"}
                                aria-label="toggle password visibility"
                                onClick={() => setVisibility(!showPassword)}>
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    </InputAdornment>
                )
            }}
            autoFocus={props.autoFocus}
        />
    );
}

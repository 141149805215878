import {
    FETCH_ALL_BANK_CONNECTIONS_SUCCESS,
    FETCH_ALL_BANKS_SUCCESS,
    FETCH_INITIAL_BANKS_SUCCESS,
    UPDATE_BANK_CONNECTION_SUCCESS
} from '../actions/actionTypes';
import get from 'lodash.get';
import {IBankConnection, isBankConnection} from '../../models/bankInterface';

const temp = {}; //could be used for notifications form the BackEnd

export const initialState = get(temp, 'banks', {
    allBanks: [],
    initialBanks: [],
    bankConnections: []
});

export default (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ALL_BANKS_SUCCESS:
            if (!Array.isArray(action.payload)) {
                return state;
            }

            return {
                ...state,
                allBanks: action.payload
            };
        case FETCH_INITIAL_BANKS_SUCCESS:
            if (!Array.isArray(action.payload)) {
                return state;
            }

            return {
                ...state,
                initialBanks: action.payload
            };
        case FETCH_ALL_BANK_CONNECTIONS_SUCCESS:
            if (!(action.payload instanceof Array)) return state;
            return {
                ...state,
                bankConnections: action.payload
            };
        case UPDATE_BANK_CONNECTION_SUCCESS:
            if (
                !(
                    action.payload instanceof Object &&
                    isBankConnection(action.payload)
                )
            )
                return state;
            return {
                ...state,
                bankConnections: addOrReplaceBankConnection(
                    state,
                    action.payload
                )
            };
        default:
            return state;
    }
};

export function addOrReplaceBankConnection(
    state: any,
    payload: IBankConnection
): IBankConnection[] {
    if (!isBankConnection(payload)) return [];
    const {bankConnections, existingIndex} = getIndexOfObject(state, payload);
    if (existingIndex < 0) return [...bankConnections, payload];

    bankConnections.splice(existingIndex, 1, payload);
    return bankConnections;
}

function getIndexOfObject(state: any, payload: IBankConnection) {
    const bankConnections: IBankConnection[] = [...state.bankConnections];
    const existingIndex = bankConnections.findIndex(
        bankConnection =>
            bankConnection.bankConnectionId === payload.bankConnectionId
    );

    return {bankConnections, existingIndex};
}

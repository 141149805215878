export const getNumberOfWeek = (date: any) => {
    if (!(date instanceof Date)) date = new Date(date);
    const d: any = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const getLastDayOfMonthDate = (date: string | Date) => {
    date = formatDateWithoutTime(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const changeMonthBy = (date: Date, month: number) => {
    const temp = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    temp.setMonth(temp.getMonth() + month);
    return temp;
};

export const getFirstDayDate = (date: string | Date) => {
    date = formatDateWithoutTime(date);
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const formatDateWithoutTime = (date: string | Date): Date => {
    if (typeof date === 'string') {
        date = new Date(date);
    } else if (!(date instanceof Date)) {
        return new Date();
    }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const compareDateByDay = (x: string | Date, y: string | Date) => {
    x = formatDateWithoutTime(x);
    y = formatDateWithoutTime(y);
    return x.getTime() - y.getTime();
};

import React from 'react';
import {LinearProgress} from '@material-ui/core';
import './Loading.scss';

export default function WgLinearProgress() {
    if (isPathOnBlackList(getPath())) {
        return <React.Fragment />;
    } else {
        return (
            <LinearProgress
                className="loading-linearprogress"
                id="loading-linear"
            />
        );
    }
}

function getPath() {
    const paths = window.location.href.split('/').slice(3);
    return `/${paths.join('/')}`;
}

function isPathOnBlackList(name: string) {
    const blackList = ['/'];
    return blackList.includes(name);
}
